import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Container,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import CircularLoading from "components/circularProgress";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { makeStyles } from "@mui/styles";
import DashboardFunctions from "../../dashboardFunctions";
import {
  getSocialIcon,
  CheckValueLocale,
} from "../../../../utils/helpers/index.js";
import Snackbar from "components/snackBar";
import "../../style.scss";
import LucButton from "shared/lucButton/lucButton";
import CustomDashboardMonitors from "../createCustomDashboard/customDashboardMonitors";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    padding: "13px",
    maxWidth: "500px",
  },
}));

const DashboardMonitors = (props) => {
  const intl = useIntl();
  const {
    dashboardData,
    handleMonitorValueChange,
    selectedMonitors,
    handleSelectMonitor,
    exceedLimit,
    getMonitorsListForDashboard,
    monitorsList,
    showLessMonitors,
    handleShowAllMonitors,
    handleShowLessMonitors,
    displayMonitorNameAndUsername,
    dashboardMonitorsLoading,
    countMonitorsSelected,
    isEditDashboard,
    monitorTypeVal,
    countMonitorsSelectedEdit,
    selectedOptions,
    setSelectedOptions,
    isCustomDashboard,
    setIsCustomDashboard,
    loading,
    setLoading,
    selectedProduct,
    setSelectedProduct,
    slProduct,
    cxmProduct,
    snackBar,
    setSnackBar,
    isSMEnabled,
    isCxmEnabled,
  } = props;

  const classes = useStyles();

  const { tooltipText } = DashboardFunctions();

  const [OpenLimitMonitorsSnakbar, setOpenLimitMonitorsSnakbar] =
    useState(false);

  useEffect(() => {
    if (exceedLimit) {
      setOpenLimitMonitorsSnakbar(true);
    } else {
      setOpenLimitMonitorsSnakbar(false);
    }
  }, [exceedLimit, dashboardData?.monitorValue]);

  useEffect(() => {
    !isEditDashboard &&
      !isCustomDashboard &&
      dashboardData?.dashboardType === "AGGREGATE_DASHBOARD" &&
      getMonitorsListForDashboard(dashboardData?.monitorValue);
  }, [dashboardData?.monitorValue]);

  //in case of edit dashboard only
  useEffect(() => {
    isEditDashboard && getMonitorsListForDashboard(monitorTypeVal);
  }, []);

  const handleCloseSnackBar = () => {
    setOpenLimitMonitorsSnakbar(false);
  };

  return (
    <Box className={`monitor-type ${isEditDashboard && "monitor-types-edit"}`}>
      <Container
        maxWidth="xl"
        className={`${isEditDashboard && "monitor-types-container-edit"}`}
      >
        {dashboardData?.dashboardType == "AGGREGATE_DASHBOARD" ? (
          <>
            <Grid
              item
              xs={12}
              className={`social-media-field-grid ${
                isEditDashboard && "monitors-types-dashboard-edit"
              }`}
            >
              <Typography
                variant="caption"
                sx={{ display: "inline-block" }}
                className={`dashboard-title  ${
                  isEditDashboard && "dashboard-title-type-edit"
                }`}
              >
                {CheckValueLocale(
                  isEditDashboard ? "monitor_type_dash" : "select_monitor_type",
                  "",
                  {},
                  intl,
                )}

                {!isEditDashboard && (
                  <span className="dashboard-star"> * </span>
                )}
              </Typography>
              {!isEditDashboard && (
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                ></Grid>
              )}

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={dashboardData?.monitorValue}
                onChange={(e) => {
                  handleMonitorValueChange(e);
                }}
                className={isEditDashboard && "dashboard-types-group-edit"}
              >
                <FormControlLabel
                  value="MANAGED_ACCOUNT"
                  control={
                    <Radio
                      checked={
                        dashboardData?.monitorValue === "MANAGED_ACCOUNT"
                      }
                      disabled={
                        isEditDashboard &&
                        dashboardData?.monitorValue !== "MANAGED_ACCOUNT"
                      }
                    />
                  }
                  className={isEditDashboard && "dash-monitor-edit-type"}
                  label={CheckValueLocale("managed_accounts", "", {}, intl)}
                />
                <FormControlLabel
                  value="ACCOUNT_ANALYSIS"
                  control={
                    <Radio
                      checked={
                        dashboardData?.monitorValue === "ACCOUNT_ANALYSIS"
                      }
                      disabled={
                        isEditDashboard &&
                        dashboardData?.monitorValue !== "ACCOUNT_ANALYSIS"
                      }
                    />
                  }
                  className={isEditDashboard && "dash-monitor-edit-type"}
                  label={CheckValueLocale("public_accounts", "", {}, intl)}
                />
                <FormControlLabel
                  value="KEYWORD"
                  control={
                    <Radio
                      checked={dashboardData?.monitorValue === "KEYWORD"}
                      disabled={
                        isEditDashboard &&
                        dashboardData?.monitorValue !== "KEYWORD"
                      }
                    />
                  }
                  className={isEditDashboard && "dash-monitor-edit-type"}
                  label={CheckValueLocale("keywords_accounts", "", {}, intl)}
                />
              </RadioGroup>
            </Grid>

            <Divider id="dashboard-divider-info" sx={{ bgcolor: "#EAEDF1" }} />

            <Grid item xs={12} className="social-media-field-grid">
              <Typography
                variant="caption"
                sx={{ display: "inline-block" }}
                className="dashboard-sub-title-note dashboard-title"
              >
                {CheckValueLocale(
                  isEditDashboard ? "monitors" : "select_monitors",
                  "",
                  {},
                  intl,
                )}
                {!isEditDashboard && (
                  <span className="dashboard-star"> * </span>
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} className="social-media-field-grid">
              <Typography
                variant="caption"
                sx={{ display: "inline-block" }}
                className="dashboard-sub-title"
              >
                {dashboardData?.monitorValue === "KEYWORD"
                  ? CheckValueLocale(
                      "dashboard_select_monitor_kw",
                      "",
                      {},
                      intl,
                    )
                  : CheckValueLocale(
                      "dashboard_select_monitor_msg",
                      "",
                      {},
                      intl,
                    )}
              </Typography>
            </Grid>

            {!dashboardMonitorsLoading ? (
              monitorsList !== undefined && monitorsList?.length > 0 ? (
                monitorsList?.length > 6 && !showLessMonitors ? (
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="dashboard-monitors-list"
                  >
                    {/* list items */}
                    {monitorsList?.slice(0, 6)?.map((monitor, i) => (
                      <Grid rid item xs={4} key={i}>
                        <List
                          className={"account-item"}
                          onClick={() => {}}
                          sx={{
                            cursor: "default",
                          }}
                        >
                          <ListItem
                            className={`monitor-card-item ${
                              selectedMonitors.indexOf(
                                monitor?.attributes?.id,
                              ) > -1 && "monitor-card-selected"
                            }`}
                            sx={{ padding: "12px 10px" }}
                          >
                            <Checkbox
                              checked={
                                selectedMonitors.indexOf(
                                  monitor?.attributes?.id,
                                ) > -1
                              }
                              onChange={(e) =>
                                handleSelectMonitor(
                                  e,
                                  monitor?.attributes?.id,
                                  monitor?.attributes?.data_sources,
                                  isEditDashboard,
                                )
                              }
                              inputProps={{ "aria-label": "controlled" }}
                              className={`dashboard-monitors-checkbox ${
                                ((countMonitorsSelected >= 8 &&
                                  !isEditDashboard) ||
                                  (isEditDashboard &&
                                    countMonitorsSelectedEdit >= 8)) &&
                                "monitor-card-change-cursor"
                              }`}
                            />
                            <ListItemText
                              className="list-item-text-style"
                              primary={
                                <>
                                  {" "}
                                  <div className="dashboard-monitor-name">
                                    <span>
                                      <Tooltip
                                        title={
                                          monitor?.attributes
                                            ?.third_party_unique_id === null &&
                                          dashboardData?.monitorValue ===
                                            "KEYWORD"
                                            ? monitor?.attributes?.name
                                            : monitor?.attributes?.name +
                                              " " +
                                              `(@${monitor?.attributes?.third_party_unique_id})`
                                        }
                                        sx={{ cursor: "default" }}
                                        arrow
                                        placement="bottom"
                                      >
                                        <Typography variant="caption">
                                          {displayMonitorNameAndUsername(
                                            monitor?.attributes?.name,
                                            monitor?.attributes
                                              ?.third_party_unique_id ===
                                              null &&
                                              dashboardData?.monitorValue ===
                                                "KEYWORD"
                                              ? ""
                                              : `(@${monitor?.attributes?.third_party_unique_id})`,
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    </span>
                                    {monitor?.attributes?.monitor_warning !==
                                      "" && (
                                      <span className="monitor-tooltip">
                                        {" "}
                                        <Tooltip
                                          title={
                                            <Box>
                                              {monitor?.attributes
                                                ?.monitor_warning !== "" &&
                                                tooltipText(
                                                  monitor?.attributes?.name,
                                                  monitor?.attributes
                                                    ?.monitor_warning,
                                                  "dashboard-tooltip",
                                                )}
                                            </Box>
                                          }
                                          sx={{ cursor: "default" }}
                                          arrow
                                          placement="bottom"
                                          classes={{
                                            tooltip: classes.customWidth,
                                          }}
                                        >
                                          <ErrorOutlineIcon
                                            className="dashboard-warning-icon"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </div>
                                </>
                              }
                              secondary={
                                <div className="icons-dashboard-list">
                                  {monitor?.attributes?.data_sources !==
                                    undefined &&
                                    monitor?.attributes?.data_sources !== [] &&
                                    monitor?.attributes?.data_sources?.map(
                                      (source) => {
                                        return getSocialIcon(source);
                                      },
                                    )}
                                </div>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="dashboard-monitors-list"
                  >
                    {/* list items */}
                    {monitorsList?.map((monitor, i) => (
                      <Grid rid item xs={4} key={i}>
                        <List
                          className={"account-item"}
                          onClick={() => {}}
                          sx={{
                            cursor: "default",
                          }}
                        >
                          <ListItem
                            className={`monitor-card-item ${
                              selectedMonitors.indexOf(
                                monitor?.attributes?.id,
                              ) > -1 && "monitor-card-selected"
                            }`}
                            sx={{ padding: "12px 10px" }}
                          >
                            <Checkbox
                              checked={
                                selectedMonitors?.indexOf(
                                  monitor?.attributes?.id,
                                ) > -1
                              }
                              onChange={(e) =>
                                handleSelectMonitor(
                                  e,
                                  monitor?.attributes?.id,
                                  monitor?.attributes?.data_sources,
                                  isEditDashboard,
                                )
                              }
                              inputProps={{ "aria-label": "controlled" }}
                              className={`dashboard-monitors-checkbox ${
                                ((countMonitorsSelected >= 8 &&
                                  !isEditDashboard) ||
                                  (isEditDashboard &&
                                    countMonitorsSelectedEdit >= 8)) &&
                                "monitor-card-change-cursor"
                              }`}
                            />

                            <ListItemText
                              className="list-item-text-style"
                              primary={
                                <>
                                  <div className="dashboard-monitor-name">
                                    <span>
                                      <Tooltip
                                        title={
                                          monitor?.attributes
                                            ?.third_party_unique_id === null &&
                                          dashboardData?.monitorValue ===
                                            "KEYWORD"
                                            ? monitor?.attributes?.name
                                            : monitor?.attributes?.name +
                                              " " +
                                              `(@${monitor?.attributes?.third_party_unique_id})`
                                        }
                                        sx={{ cursor: "default" }}
                                        arrow
                                        placement="bottom"
                                      >
                                        <Typography variant="caption">
                                          {displayMonitorNameAndUsername(
                                            monitor?.attributes?.name,
                                            monitor?.attributes
                                              ?.third_party_unique_id ===
                                              null &&
                                              dashboardData?.monitorValue ===
                                                "KEYWORD"
                                              ? ""
                                              : `(@${monitor?.attributes?.third_party_unique_id})`,
                                          )}
                                        </Typography>
                                      </Tooltip>
                                    </span>
                                    {monitor?.attributes.monitor_warning !==
                                      "" && (
                                      <span className="monitor-tooltip">
                                        {" "}
                                        <Tooltip
                                          title={
                                            <Box>
                                              {monitor?.attributes
                                                ?.monitor_warning !== "" &&
                                                tooltipText(
                                                  monitor?.attributes?.name,
                                                  monitor?.attributes
                                                    ?.monitor_warning,
                                                  "dashboard-tooltip",
                                                )}
                                            </Box>
                                          }
                                          sx={{ cursor: "default" }}
                                          arrow
                                          placement="bottom"
                                        >
                                          <ErrorOutlineIcon
                                            className="dashboard-warning-icon"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </span>
                                    )}
                                  </div>
                                </>
                              }
                              secondary={
                                <div className="icons-dashboard-list">
                                  {monitor?.attributes?.data_sources !==
                                    undefined &&
                                    monitor?.attributes?.data_sources !== [] &&
                                    monitor?.attributes?.data_sources.map(
                                      (source) => {
                                        return getSocialIcon(source);
                                      },
                                    )}
                                </div>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    ))}
                  </Grid>
                )
              ) : (
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  className="dashboard-monitors-list"
                >
                  <Grid id="dashboard-empty-monitors-list" item xs={12}>
                    <div>
                      <div className="dashboard-empty-monitors-list-title">
                        {CheckValueLocale(
                          "dashboard_empty_monitor_list_title",
                          "",
                          {},
                          intl,
                        )}
                      </div>
                      <div className="dashboard-empty-monitors-list-text">
                        {CheckValueLocale(
                          "dashboard_empty_monitor_list_text",
                          "",
                          {},
                          intl,
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )
            ) : (
              <CircularLoading />
            )}

            {!dashboardMonitorsLoading && (
              <Grid container rowSpacing={1}>
                {!showLessMonitors && monitorsList?.length > 6 && (
                  <LucButton
                    variant="flat"
                    id="dashboard-show-all-monitors-btn"
                    onClick={(e) => handleShowAllMonitors(e)}
                  >
                    {CheckValueLocale("show_all_db_monitors", "", {}, intl)}
                  </LucButton>
                )}

                {showLessMonitors && (
                  <LucButton
                    variant="flat"
                    id="dashboard-show-less-monitors-btn"
                    onClick={(e) => handleShowLessMonitors(e)}
                  >
                    {CheckValueLocale("show_less_db_monitors", "", {}, intl)}
                  </LucButton>
                )}
              </Grid>
            )}
          </>
        ) : (
          <CustomDashboardMonitors
            isEditDashboard={isEditDashboard}
            dashboardData={dashboardData}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            setIsCustomDashboard={setIsCustomDashboard}
            loading={loading}
            setLoading={setLoading}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            slProduct={slProduct}
            cxmProduct={cxmProduct}
            snackBar={snackBar}
            setSnackBar={setSnackBar}
            isSMEnabled={isSMEnabled}
            isCxmEnabled={isCxmEnabled}
          />
        )}
      </Container>

      <Snackbar
        open={OpenLimitMonitorsSnakbar}
        severity={OpenLimitMonitorsSnakbar ? "info" : ""}
        message={CheckValueLocale(
          "dashabord_max_number_of_monitors_message",
          "",
          {},
          intl,
        )}
        title={CheckValueLocale(
          "dashabord_max_number_of_monitors_title",
          "",
          {},
          intl,
        )}
        handleClose={handleCloseSnackBar}
        alertStyle={localStorage?.lang === "en" && { width: "70%" }}
      />
    </Box>
  );
};

export default DashboardMonitors;
