import { useState } from "react";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormGroup,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";

const FormField = (props) => {
  const { name, label, type, forget, errorField, star, ...rest } = props;

  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormGroup className="group-field">
      <InputLabel className="label-field">
        <Box component="span">
          {label}
          {star ? (
            <Box component="span" className="label-field-required">
              {" "}
              *{" "}
            </Box>
          ) : null}
        </Box>
        {forget ? (
          <Box component="span" className="forget-span" onClick={forget}>
            {" "}
            {CheckValueLocale("forgot_password", "", {}, intl)}
          </Box>
        ) : null}
      </InputLabel>
      <TextField
        id={name + "-basic"}
        name={name}
        type={type !== "password" ? type : showPassword ? "text" : "password"}
        variant="outlined"
        fullWidth
        className="text-field"
        InputProps={{
          endAdornment:
            type === "password" || type === "confirmPassword" ? (
              <InputAdornment position="end">
                <Box
                  onClick={handleClickShowPassword}
                  className="show-pass-icon"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </Box>
              </InputAdornment>
            ) : null,
        }}
        {...rest}
      />
      {errorField ? (
        <Box component="p" className="error-text-msg">
          {" "}
          {CheckValueLocale(errorField, "", {}, intl)}{" "}
        </Box>
      ) : null}
    </FormGroup>
  );
};
export default FormField;
