import { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import TestYourKeyword from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/testYourKeyword/testYourKeyword";

import HashtagUsedNumber from "pages/socialListing/MonitorsOptions/createMonitor/MonitorTypes/KeywordsMonitor/components/keywordHeader/hashtagUsedNumber";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import CopyIcon from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import {
  checkLabelTextEdit,
  checkAllInputsField,
  checkKeywordPopup,
  checkDirectionLang,
  handleInputChange,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import {
  checkValue,
  handleKeywordsPopUp,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import MonitorDropdownsSearch from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/DropdownSearch/monitorDropdownsSearch";

const FacebookKeywords = (props) => {
  const intl = useIntl();
  const isRTL = intl?.locale === "ar";
  const {
    setSQuestion,
    handleKeywords,
    keywords,
    handleDelete,
    handleValidation,
    handleData,
    wordCountFacebook,
    setUpdatedParams,
    updatedParams,
    monitorDetails,
    indexes,
    monitorErrors,
    setMonitorErrors,
    selectedFacebookPages,
    setSelectedFacebookPages,
    facebookPages,
    setFacebookPages,
    getFacebookPages,
    isLoadingSelectList,
    setDisableSave,
  } = props;

  const [focused, setFocused] = useState(false);
  let sections = ["keywords", "spam_keywords"];
  const [err, setErr] = useState();
  const [optionPages, setOptionPages] = useState([]);
  const [limitErrorPages, setLimitErrorPages] = useState("");

  const handleKeyDown = (event, keywordType, index, clickType) => {
    if (event.keyCode === 13 || clickType === "btn") {
      if (keywordType === "keywords" || keywordType === "spam_keywords") {
        handleKeywords(
          event,
          keywordType,
          "facebook",
          index,
          clickType,
          props?.monitorFaceBookKeywordLimit,
        );
        handleUpdateParams(keywordType);
      }
    }
  };

  const handleUpdateParams = (keywordType, selectList) => {
    if (keywordType === "keywords") {
      setUpdatedParams({
        ...updatedParams,
        keywords: {
          ...updatedParams.keywords,
          FACEBOOK: Object.values(keywords[2])[0][0]?.keywords?.join(),
        },
      });
    }
    if (keywordType === "spam_keywords") {
      setUpdatedParams({
        ...updatedParams,
        spam_keywords: {
          ...updatedParams.spam_keywords,
          FACEBOOK: Object.values(keywords[2])[0][1].spam_keywords?.join(),
        },
      });
    }
    if (keywordType === "facebook_keywords_pages") {
      setUpdatedParams({
        ...updatedParams,
        facebook_keywords_pages: selectList,
      });
    }
  };

  const handleDeleteWord = (e, index, keywordType) => {
    handleDelete(e, index, keywordType, indexes, "facebook");
    if (keywordType === "keywords" || keywordType === "spam_keywords") {
      handleUpdateParams(keywordType);
    }
  };

  // Keep tracking for any error related to Facebook keyword
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      fb_keywords: keywords[indexes]?.facebook[0]?.errorMsg,
    });
  }, [keywords[indexes]?.facebook[0]?.errorMsg]);
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      fb_spam_keywords: keywords[indexes]?.facebook[1]?.errorMsg,
    });
  }, [keywords[indexes]?.facebook[1]?.errorMsg]);

  const onPagesChange = (newPages = []) => {
    let selectedList = optionPages?.filter((page) =>
      newPages?.includes(page.id),
    );
    setSelectedFacebookPages(selectedList);
    handleUpdateParams("facebook_keywords_pages", selectedList);
    setDisableSave(!newPages?.length);
  };

  useEffect(() => {
    let newList = [...selectedFacebookPages, ...facebookPages];
    const seen = new Set();
    const AllOptionList = newList.filter((el) => {
      const duplicate = seen.has(+el.id);
      seen.add(+el.id);
      return !duplicate;
    });
    setOptionPages(AllOptionList);
  }, [facebookPages]);

  useEffect(() => {
    if (
      selectedFacebookPages?.length === props?.monitorFaceBookOtherFieldLimit
    ) {
      setLimitErrorPages("max_FB_pages_limit_excceds");
      setDisableSave(false);
    } else if (
      selectedFacebookPages?.length > props?.monitorFaceBookOtherFieldLimit
    ) {
      setLimitErrorPages("error_FB_pages_limit_excceds");
      setDisableSave(true);
    } else {
      setLimitErrorPages("");
    }
  }, [selectedFacebookPages, props?.monitorFaceBookOtherFieldLimit]);

  return (
    <>
      <Box className="inline-align">
        <Box>
          <Typography className="section-header">
            {CheckValueLocale("facebook", "", {}, intl)}
          </Typography>
          <Typography className="posts-used">
            {CheckValueLocale("posts_used", "", {}, intl)}:{" "}
            {monitorDetails?.posts_count_stats?.FACEBOOK
              ? monitorDetails?.posts_count_stats?.FACEBOOK.toLocaleString()
              : 0}
          </Typography>
        </Box>
        {/* __________________________________________________________ */}

        <Box className="inline-flex data-source-setting">
          <TestYourKeyword
            keywords={monitorDetails.keywords.FACEBOOK}
            spamKeywords={monitorDetails.spam_keywords.FACEBOOK}
          />
        </Box>
      </Box>
      {/* __________________________________________________________ */}
      <Box className="section-box">
        {sections.map((keyword, index) => {
          const fbValues = Object.values(keywords[indexes]?.facebook[index]);
          return (
            <>
              <Box className="section-box-data-keyword-setting">
                <Tooltip
                  title={CheckValueLocale(
                    "you_can_use_connectiong_methods",
                    "",
                    {},
                    intl,
                  )}
                  arrow
                  placement="bottom"
                >
                  <Typography className="main-titles underlined">
                    {CheckValueLocale(keyword, "", {}, intl)}
                  </Typography>
                </Tooltip>
                {/* __________________________________________________________ */}
                <TextField
                  id="outlined-email-input"
                  label={CheckValueLocale(
                    checkLabelTextEdit(keyword),
                    "",
                    {},
                    intl,
                  )}
                  className={`
                  spam-text-field input-field-edit 
                  ${
                    keyword === "keywords"
                      ? "input-keyword-field"
                      : "input-field-spam"
                  }
                  ${checkDirectionLang(
                    keywords[indexes].facebook[index].answer,
                    "facebook",
                  )}`}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    endAdornment: focused && (
                      <InputAdornment onMouseDown={handleInputMouseDown}>
                        <Button
                          className="add-btn-with-plus add-btn-plus-setting"
                          onClick={(event) =>
                            handleKeyDown(event, keyword, index, "btn")
                          }
                          value={
                            Object.keys(
                              keywords[indexes]?.facebook[index],
                            )[0] === keyword
                              ? keywords[indexes].facebook[index].answer
                              : ""
                          }
                          id="monitor-options-fb-kw-add-keyword-btn"
                        >
                          {CheckValueLocale("add_plus", "", {}, intl)}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  error={
                    keywords[indexes]?.facebook[index]?.errorMsg !== "" &&
                    Object.keys(keywords[indexes]?.facebook[index])[0] ===
                      keyword
                  }
                  onChange={(e) => {
                    setSQuestion("facebook");
                    handleInputChange(
                      e,
                      keyword,
                      "facebook",
                      index,
                      indexes,
                      keywords,
                      setErr,
                      handleValidation,
                    );
                  }}
                  value={
                    Object.keys(keywords[indexes]?.facebook[index])[0] ===
                    keyword
                      ? keywords[indexes].facebook[index].answer
                      : ""
                  }
                  onKeyDown={(event) => handleKeyDown(event, keyword, index)}
                />
              </Box>

              {/* _________________________________Error Message _____________________________________________________ */}
              {keywords[indexes]?.facebook?.map((obj) => {
                if (
                  Object.values(obj)[1] !== "" &&
                  Object.keys(obj)[0] === keyword
                ) {
                  return (
                    <Box className="error-msg-keyword">
                      <Typography className="main-titles"></Typography>
                      <Box
                        component="p"
                        className="spam-text-field err-msg-text keywords-error-msg"
                      >
                        {CheckValueLocale(
                          Object.values(obj)[1],
                          "",
                          { limit: props?.monitorFaceBookKeywordLimit },
                          intl,
                        )}
                      </Box>
                    </Box>
                  );
                }
              })}
              {/* ______________________________________________________________________________________ */}
              <Box className="chip-keywords-wrapper">
                <Box></Box>
                <Box className="spam-keywords-area">
                  {keywords[indexes]?.facebook?.map((obj) => {
                    if (
                      Object.values(obj)[0].length > 0 &&
                      Object.keys(obj)[0] === keyword
                    ) {
                      return checkValue(Object.values(obj)[0]).map(
                        (labels, index) => (
                          <>
                            <KeywordTooltipChip
                              onDelete={(e) =>
                                handleDeleteWord(e, index, Object.keys(obj)[0])
                              }
                              dataSource={"facebook"}
                              index={index}
                              indexes={indexes}
                              labels={labels}
                              keywordSetting
                            />
                          </>
                        ),
                      );
                    }
                  })}
                  {checkAllInputsField(keyword, sections) && (
                    <KeywordPopupModal
                      keywords={handleKeywordsPopUp(
                        keyword,
                        keywords[2].facebook[0]?.keywords,
                        keywords[2].facebook[1]?.spam_keywords,
                      )}
                      handleDelete={handleDeleteWord}
                      dataSourceType={"facebook"}
                      keywordType={checkKeywordPopup(keyword)}
                      dataSourceIndex={2} // dataSource index to handle delete keyword
                    />
                  )}
                  {/* Copy Icon___________________________________________________________________________ */}
                  {fbValues[0].length !== 0 && (
                    <CopyIcon
                      checkCopyValue={fbValues[2]}
                      handleData={(e) =>
                        handleData(e, fbValues[0], indexes, index)
                      }
                    />
                  )}
                </Box>
              </Box>
              {/* _______________________________________________________________________________________ */}

              {/* Error Msg is fired in case of keywords input filed is empty and save btn will not work  */}
              {keyword === "keywords" &&
                isEmptyArray(keywords[indexes]?.facebook[0]?.keywords) && (
                  <Box className="section-box-data">
                    <Typography className="main-titles"></Typography>
                    <Box
                      component="p"
                      className="spam-text-field err-msg-text keywords-error-msg"
                    >
                      {CheckValueLocale("this_field_required", "", {}, intl)}
                    </Box>
                  </Box>
                )}

              <Divider />
            </>
          );
        })}

        <MonitorDropdownsSearch
          options={optionPages?.length ? optionPages : []}
          placeholder={CheckValueLocale("select", "", {}, intl)}
          label={CheckValueLocale("facebook_pages", "", {}, intl)}
          tooltip={CheckValueLocale("facebook_pages_tooltip", "", {}, intl)}
          onChange={onPagesChange}
          containerClassName="section-box-data-keyword-setting"
          selectWrapperClassName="select-wrapper-countries"
          tooltipPosition={isRTL ? "left" : "right"}
          getApiSearch={getFacebookPages}
          searchApi={true}
          defaultValue={
            selectedFacebookPages?.length
              ? selectedFacebookPages.map((page) => +page.id)
              : []
          }
          isLoadingSelectList={isLoadingSelectList}
          resetSearchList={setFacebookPages}
          limitError={limitErrorPages === "error_FB_pages_limit_excceds"}
        />
        <Box className="chip-keywords-wrapper">
          <Box></Box>
          <Box
            className={`note-limit-msg  ${limitErrorPages === "error_FB_pages_limit_excceds" ? "error-limit-pages" : ""}`}
          >
            {CheckValueLocale(
              limitErrorPages,
              "",
              { limit: props?.monitorFaceBookOtherFieldLimit },
              intl,
            )}
          </Box>
        </Box>
        <Box className="chip-keywords-wrapper" />
        <Divider />
      </Box>
    </>
  );
};

export default FacebookKeywords;
