import React from "react";
var Parser = require("jison").Parser;

var grammar = {
  lex: {
    rules: [
      ["\\s+", "/* skip whitespace */"],
      ["\\^", "return 'strict';"],
      [
        "([^,؛؟،±§!\\$%&\\^\\*\\(\\)\\+=\\[\\]{}\\\\|~<>\\/\\?\\.\"']+)",
        "return 'qm_keyword';",
      ],
      ['"', "return 'qm';"],
      ["\\|\\|", "return 'OR';"],
      ["\\|\\|", "return 'or';"],
      ["&&", "return 'AND';"],
      ["&&", "return 'and';"],
      ["~", "return 'NOT';"],
      ["~", "return 'not';"],
      ["\\(", "return 'l_bracket';"],
      ["\\)", "return 'r_bracket';"],
      ["$", "return 'EOF';"],
    ],
  },
  operators: [
    ["left", "NOT"],
    ["left", "AND", "OR"],
    ["left", "strict"],
    ["right", "r_bracket"],
    ["left", "l_bracket"],
  ],
  bnf: {
    expressions: [["term EOF", "return $1;"]],
    term: [
      ["factor", "$$ = $1"],
      ["l_bracket term r_bracket", "$$ = ['('].concat($2)"],
      [
        "qm term qm",
        "output = []; var array1 = $2; " +
          "for (i = 0; i < array1.length; i++) " +
          "output.push('\"'+array1[i].replace(/['\"]+/g,'')+'\"');$$ = output",
      ],
      [
        "term AND term",
        "output = []; var array1 = $1;var array2 = $3;" +
          "first_element = array1.shift(); if (first_element !== '(') array1.push(first_element);" +
          "first_element = array2.shift(); if (first_element !== '(') array2.push(first_element);" +
          "for (i = 0; i < array1.length; i++) " +
          "for (j = 0; j < array2.length; j++) " +
          "output.push(array1[i].replace(/['\"]+/g,'') + ' ' + array2[j].replace(/['\"]+/g,''));$$ = output",
      ],
      [
        "term OR term",
        "var array1 = $1; first_element = array1.shift();" +
          "if (first_element !== '(') array1.push(first_element);" +
          "var array2 = $3; first_element = array2.shift();" +
          "if (first_element !== '(') array2.push(first_element);" +
          "$$ = array1.concat(array2)",
      ],
      [
        "NOT term",
        ";output = []; var grouped = false; var array1 = $2; first_element = array1.shift();" +
          "if (first_element === '(') grouped = true; else array1.push(first_element);" +
          "for (i = 0; i < array1.length; i++) " +
          "if (array1[i].startsWith('\"')) output.push(('^'+array1[i]).replace(/[//^]+/g,'~'));" +
          "else if (grouped) " +
          "output.push(('^'+array1[i].replace(/ /g,' ^')).replace(/[//^]+/g,'~'));" +
          "else output.push(('^'+array1[i]).replace(/[//^]+/g,'~'));$$ = output",
      ],
      [
        "term NOT term",
        "var array1 = $1; first_element = array1.shift(); " +
          "if (first_element !== '(') array1.push(first_element);" +
          "var array2 = $3; first_element = array2.shift(); " +
          "if (first_element !== '(') array2.push(first_element);" +
          "$$ = array1.concat(array2.map(function addNeg(key) {return ('~'+key);}))",
      ],
    ],
    factor: [
      ["qm_keyword", "$$ = [$1]"],
      ["qm_keyword factor", "$$ = [$1.concat($2[0])]"],
      [
        "strict term",
        ";output = []; var grouped = false; var array1 = $2; first_element = array1.shift();" +
          "if (first_element === '(') grouped = true; else array1.push(first_element);" +
          "for (i = 0; i < array1.length; i++) " +
          "if (array1[i].startsWith('\"')) output.push(('^'+array1[i]).replace(/[//^]+/g,'^'));" +
          "else if (grouped) " +
          "output.push(('^'+array1[i].replace(/ /g,' ^')).replace(/[//^]+/g,'^'));" +
          "else output.push(('^'+array1[i]).replace(/[//^]+/g,'^'));$$ = output",
      ],
    ],
  },
};

export { Parser, grammar };
