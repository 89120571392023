import { Box, Typography, Stack, FormControl } from "@mui/material";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";

const Header = (props) => {
  const { title, sortByData, sortBy, handleChange, titleValue, isQuickSearch } =
    props;
  const intl = useIntl();

  return (
    <Box className={"flexStyle authors-margin"}>
      <Typography variant="body1" className="author-title">
        {`${CheckValueLocale(title, "", {}, intl)}`}
        {!isQuickSearch ? ":" : null}
        {!isQuickSearch && titleValue && commasAfterDigit(titleValue)}
      </Typography>

      <FormControl className="select-monitor">
        <Stack className="author-header-icon">
          <CompareArrowsIcon className="arrow-icon" />
        </Stack>
        <TextField
          select
          label={CheckValueLocale("sort_by", "", {}, intl)}
          value={sortBy}
          onChange={handleChange}
          className="author-dropdown"
        >
          {sortByData.map((option) => (
            <MenuItem
              key={option}
              value={option}
              id={`monitors-sort-box-${option ?? "option"}`}
            >
              <Box className="option-value">
                {CheckValueLocale(option, "", {}, intl)}
              </Box>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Box>
  );
};

export default Header;
