import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import CircularLoading from "components/circularProgress";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";

import { isEmptyObj, CheckValueLocale } from "utils/helpers";

//components
import DashboardHeader from "./components/dashbordHeader";
import DashboardInfo from "./components/dashboardInfo";
import DashboardFunctions from "../dashboardFunctions";
import DashboardGeneral from "./components/generalSection";
import MonitorsDatasourcesDashboard from "./components/monitorsDatasourcesSection";
import SaveChangesFooter from "./components/footerDashboard";

import dashboardController from "services/controllers/dashboardController";
import "./style.scss";

const EditDashboard = () => {
  const intl = useIntl();
  const urlParams = useParams();
  const { dashboardId } = urlParams;

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const {
    dashboardData,
    setdashboardData,
    dashboardErrors,
    handleMonitorValueChange,
    selectedMonitors,
    setSelectedMonitors,
    handleSelectMonitor,
    handleInputChange,
    exceedLimit,
    getMonitorsListForDashboard,
    monitorsList,
    showAllMonitors,
    showLessMonitors,
    handleShowAllMonitors,
    handleShowLessMonitors,
    displayMonitorNameAndUsername,
    displayMonitorNameTooltip,
    dashboardMonitorsLoading,
    countMonitorsSelected,
    updatedValuesParams,
    setUpdatedValuesParams,
    setSelectedDatasourcesMonitors,
    countMonitorsSelectedEdit,
    selectedDatasourcesMonitors,
    truncateName,
  } = DashboardFunctions();
  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDashboardInfo = () => {
    dashboardController.getDashboardInfo(dashboardId).then((data) => {
      setDashboardDetails(data?.data);
      setIsLoading(false);
    });
  };

  /*______________________________________________________________*/
  const handleEditDashboardInfo = () => {
    dashboardController
      .editDashboardInfo(dashboardId, updatedValuesParams)
      .then((data) => {
        if (data?.data?.status === 204 || data?.data?.status === 202) {
          setShowSaveModal(false);
          setUpdatedValuesParams({});
          setShowSnackBar(true);
          setSnackBarData({
            severity: "success",
            message: "your_changes_saved_successfully",
          });
          getDashboardInfo();
        } else {
          setUpdatedValuesParams({});
          setShowSaveModal(false);
          setShowSnackBar(true);
          setSnackBarData({
            severity: "error",
            message: data?.errorMsg?.response?.data?.exception?.message
              ? data?.errorMsg?.response?.data?.exception?.message
              : "failed_error",
          });
        }
      });
  };

  useEffect(() => {
    getDashboardInfo();
  }, []);

  return (
    <Container className="settings-container">
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <DashboardHeader
            dashboardDetails={dashboardDetails}
            dashboardId={dashboardId}
          />
          <DashboardInfo
            dashboardDetails={dashboardDetails}
            truncateName={truncateName}
          />
          <DashboardGeneral
            dashboardDetails={dashboardDetails}
            dashboardData={dashboardData}
            setdashboardData={setdashboardData}
            handleInputChange={handleInputChange}
            dashboardErrors={dashboardErrors}
            setSelectedMonitors={setSelectedMonitors}
            setUpdatedValuesParams={setUpdatedValuesParams}
            updatedValuesParams={updatedValuesParams}
            setSelectedDatasourcesMonitors={setSelectedDatasourcesMonitors}
          />
          <MonitorsDatasourcesDashboard
            dashboardData={dashboardData}
            setdashboardData={setdashboardData}
            handleMonitorValueChange={handleMonitorValueChange}
            selectedMonitors={selectedMonitors}
            setSelectedMonitors={setSelectedMonitors}
            handleSelectMonitor={handleSelectMonitor}
            exceedLimit={exceedLimit}
            getMonitorsListForDashboard={getMonitorsListForDashboard}
            monitorsList={monitorsList}
            showAllMonitors={showAllMonitors}
            showLessMonitors={showLessMonitors}
            handleShowAllMonitors={handleShowAllMonitors}
            handleShowLessMonitors={handleShowLessMonitors}
            displayMonitorNameAndUsername={displayMonitorNameAndUsername}
            displayMonitorNameTooltip={displayMonitorNameTooltip}
            dashboardMonitorsLoading={dashboardMonitorsLoading}
            countMonitorsSelected={countMonitorsSelected}
            isEditDashboard
            setUpdatedValuesParams={setUpdatedValuesParams}
            updatedValuesParams={updatedValuesParams}
            dashboardDetails={dashboardDetails}
            countMonitorsSelectedEdit={countMonitorsSelectedEdit}
          />
          {!isEmptyObj(updatedValuesParams) && ( //this section is hidden unless updatedValuesParams has new data inserted by user
            <SaveChangesFooter
              setShowSaveModal={setShowSaveModal}
              updatedValuesParams={updatedValuesParams}
              setUpdatedValuesParams={setUpdatedValuesParams}
              dashboardErrors={dashboardErrors}
              dashboardData={dashboardData}
              countMonitorsSelectedEdit={countMonitorsSelectedEdit}
              selectedDatasourcesMonitors={selectedDatasourcesMonitors}
            />
          )}

          {showSaveModal && (
            <PopupModal
              title={CheckValueLocale("save_changes", "", {}, intl)}
              body={CheckValueLocale("save_dashboard_changes", "", {}, intl)}
              leftBtn={CheckValueLocale("cancel", "", {}, intl)}
              rightBtn={CheckValueLocale("save", "", {}, intl)}
              open={() => setShowSaveModal(true)}
              close={() => setShowSaveModal(false)}
              accept={handleEditDashboardInfo}
              minWidth={600}
            />
          )}

          {showSnackBar && (
            <SnackBar
              open={showSnackBar}
              severity={snackBarData?.severity}
              message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
              title={snackBarData?.title}
              handleClose={() => {
                setShowSnackBar(false);
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default EditDashboard;
