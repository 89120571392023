import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CancelIcon from "@mui/icons-material/Cancel";
import "../../surveyBuilder.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const ChoiceSection = (props) => {
  const {
    rootQuestion,
    question,
    choices,
    setChoices,
    editable,
    surveySections,
    setSurveySections,
    filteredSections,
    questionIndex,
    sectionIndex,
    handleChangeProceed,
  } = props;

  const intl = useIntl();
  const optionNo = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];
  const [proceedSelects, setProceedSelects] = useState([]);
  const MenuProps = { PaperProps: { style: { maxHeight: 265 } } };

  useEffect(() => {
    if (filteredSections) {
      let selectsArray = [];
      filteredSections?.map((sec, index) => {
        if (index !== sectionIndex && index !== sectionIndex + 1)
          selectsArray?.push(sec);
      });
      setProceedSelects(selectsArray);
    }
  }, [filteredSections?.length]);

  let otherOption = {
    [filteredSections[sectionIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex]?.question?.items?.length + 1]: "Other",
  };

  const handleChangeChoice = (e, choice, index) => {
    let obj = { [index + 1]: e.target.value };
    const proceedOnAnswer = filteredSections[sectionIndex]?.questions?.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex]?.is_proceed_on_answer;
    if (proceedOnAnswer) {
      if (sectionIndex === filteredSections?.length - 1) obj.next_section = -1;
      else obj.next_section = filteredSections[sectionIndex]?.order + 1;
    }
    choices[index] = obj;
    filteredSections[sectionIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex].question.items[index] = obj;
    setSurveySections([...surveySections]);
  };

  const handleDeleteChoice = (index, type) => {
    if (editable !== false) {
      if (type === "other") {
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].question.constraints.other_option = false;
        setSurveySections([...surveySections]);
      }
      if (type !== "other" && choices?.length >= 3) {
        choices?.splice(index, 1);
        setChoices([...choices]);
        // Update keys of remaining objects in choices array to be as new index + 1
        choices?.forEach((choice, choiceIndex) => {
          const nIndex = choiceIndex + 1;
          const oldIndex = choiceIndex + 2;
          if (choice[oldIndex]) {
            choice[nIndex] = choice[oldIndex];
            delete choice[oldIndex];
          }
        });
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].question.items = choices;
        setSurveySections([...surveySections]);
      }
    } else return false;
  };
  let multipleSelection = question?.constraints?.multiple_selection;
  return (
    <>
      {choices?.map((choice, index) => {
        return (
          <Box className="proceed-choice mcq-shoice" key={index}>
            {multipleSelection ? (
              <FormControlLabel
                control={<Checkbox checked={choice?.selected} disabled />}
              />
            ) : (
              <Radio key={index} name="radio-buttons" disabled />
            )}
            <TextField
              className="mcq-option unSelected"
              id="outlined-basic"
              variant="outlined"
              autoFocus
              onFocus={(event) => {
                event.target.select();
              }}
              disabled={editable === false ? true : false}
              value={Object.values(choice)[0]}
              placeholder={`${CheckValueLocale(
                "dropdown_option",
                "",
                {},
                intl,
              )} ${index + 1}`}
              onChange={(e) => handleChangeChoice(e, choice, index)}
              InputProps={{
                endAdornment: optionNo[index] !== "a" &&
                  optionNo[index] !== "b" && (
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => handleDeleteChoice(index)}
                    />
                  ),
              }}
              inputProps={{ maxLength: 150 }}
              fullWidth
            />

            {rootQuestion?.is_proceed_on_answer && (
              <Select
                id={`mcq-next-section-select-${sectionIndex}`}
                className="next-section-select"
                variant="standard"
                MenuProps={MenuProps}
                fullWidth
                value={choice?.next_section}
                onChange={(e) =>
                  handleChangeProceed(e, sectionIndex, questionIndex, index)
                }
              >
                {sectionIndex !== filteredSections?.length - 1 && (
                  <MenuItem value={filteredSections[sectionIndex]?.order + 1}>
                    {`${CheckValueLocale(
                      "continue_next_section",
                      "",
                      {},
                      intl,
                    )} (${filteredSections[sectionIndex + 1]?.name})`}
                  </MenuItem>
                )}
                <MenuItem value={-1}>
                  {CheckValueLocale("submit_survey", "", {}, intl)}
                </MenuItem>
                {proceedSelects?.map((section, i) => {
                  return (
                    <MenuItem key={i} value={section?.order}>
                      {`${CheckValueLocale("go_to_section", "", {}, intl)} ${
                        section?.order
                      } (${section?.name})`}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </Box>
        );
      })}
      {question?.constraints?.other_option && (
        <Box className="proceed-choice mcq-shoice">
          {!multipleSelection ? <Radio name="radio-buttons" disabled /> : null}
          <TextField
            className="mcq-option unSelected mcq-option-other"
            id="outlined-basic"
            variant="outlined"
            disabled={editable === false ? true : false}
            value={
              Object.values(otherOption)[0]
                ? CheckValueLocale(Object.values(otherOption)[0], "", {}, intl)
                : ""
            }
            InputProps={{
              endAdornment: optionNo[question?.items?.length] !== "a" &&
                optionNo[question?.items?.length] !== "b" && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() =>
                      handleDeleteChoice(
                        optionNo[question?.items?.length],
                        "other",
                      )
                    }
                  />
                ),
            }}
            fullWidth
          />
        </Box>
      )}
    </>
  );
};

export default ChoiceSection;
