import { useIntl } from "react-intl";
import { Box, Container } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./details.scss";
import noDataDetailsImage from "images/engagements-images/no-data-details.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";

const NoDataDetails = ({ fetchData }) => {
  const intl = useIntl();
  return (
    <Container maxWidth="xl" className="no-data-details-container">
      <Box className="no-data-details-wrapper">
        {!fetchData ? (
          <Box className="no-data-text">
            <img src={noDataDetailsImage} alt="engagements" />
            <h2 className="text-heading">
              {CheckValueLocale(
                "engagement_no_data_details_heading",
                "",
                {},
                intl,
              )}
            </h2>
          </Box>
        ) : (
          <Box className="pagination-loader">
            <FontAwesomeIcon icon={faSpinnerThird} spin />
            {CheckValueLocale("loading_engagement", "", {}, intl)}
          </Box>
        )}
      </Box>
    </Container>
  );
};
export default NoDataDetails;
