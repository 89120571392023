import Services from "./Services";

var SummaryWidget = {};

SummaryWidget.getSummaryStaticQuestions = (queryData) => {
  return Services.getData(
    "api/v3/luci/questions/summary_static_questions",
    queryData,
  );
};
SummaryWidget.getLastQuestionAnswer = (queryData) => {
  return Services.getData(
    "api/v3/luci/questions/last_automated_question_answer",
    queryData,
  );
};

SummaryWidget.postQuestionStatus = (queryData) => {
  return Services.postData(
    "api/v3/luci/questions/toggle_question_thumb",
    queryData,
  );
};
SummaryWidget.getSummarySmTokens = () => {
  return Services.getData("api/v3/luci/questions/summary_sm_tokens");
};

export default SummaryWidget;
