import { Tabs, Tab, Box } from "@mui/material";
import { useIntl } from "react-intl";
import "../../createMonitor.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  checkActiveDatasource,
  checkDatasourceExist,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";

const SocialMediaTabs = (props) => {
  const {
    handleChange,
    value,
    categotyDataSources,
    icons,
    socialMediaCount,
    isPublicAccount,
  } = props;
  const intl = useIntl();
  return (
    <Box>
      <Tabs onChange={handleChange} className="social-media-tabs" value={value}>
        {categotyDataSources.map(
          (category, i) =>
            checkDatasourceExist(
              category.attributes.source,
              isPublicAccount,
            ) && (
              <Tab
                key={i}
                value={category.attributes.id}
                icon={
                  category.attributes.source === "TWITTER" ? (
                    <img
                      src={icons[category.attributes.id - socialMediaCount]}
                      alt="x-platform-logo"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={icons[category.attributes.id - socialMediaCount]}
                    />
                  )
                }
                label={
                  <Box component="span" className="tap-text">
                    {category.attributes.source
                      ? CheckValueLocale(
                          category.attributes.source.toLocaleLowerCase(),
                          "",
                          {},
                          intl,
                        )
                      : ""}
                  </Box>
                }
                className={checkActiveDatasource(
                  category.attributes.source,
                  value,
                )}
              />
            ),
        )}
      </Tabs>
    </Box>
  );
};

export default SocialMediaTabs;
