import { Box, Container, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import "./notFoundNotification.scss";

const NotFoundNotification = () => {
  const intl = useIntl();

  return (
    <Container maxWidth="xl" className="">
      <Box className="notfound-notification-view-wrapper">
        <FontAwesomeIcon icon={faBell} className="alert-ban-icon" />
        <Typography>
          {CheckValueLocale("notFound_notification", "", {}, intl)}
        </Typography>
        <Box className="notfound-notification-desc">
          {CheckValueLocale("notFound_notification_desc", "", {}, intl)}
          <Link className="notifications-link" to="/notification">
            {CheckValueLocale("notifications_center", "", {}, intl)}
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFoundNotification;
