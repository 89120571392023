import { Box, Skeleton } from "@mui/material";
import "./slaDetailsSideBar.scss";

const SlaLoader = () => {
  let arr = [1, 2, 3, 4];
  return (
    <Box className="main-sla-loader">
      <Box className="sla-loader-sec">
        <Skeleton animation="pulse" height="17px" width="68px" />
        {arr?.map((item) => (
          <Box className="sla-loader-sec-inner" key={item}>
            <Skeleton animation="pulse" height="17px" width="126px" />
            <Skeleton animation="pulse" height="17.5px" width="77px" />
          </Box>
        ))}
      </Box>
      <Box className="sla-loader-sec with-border">
        <Skeleton animation="pulse" height="17px" width="68px" />
        <Skeleton animation="pulse" height="67px" width="250px" />
        <Skeleton animation="pulse" height="67px" width="250px" />
      </Box>
      <Box className="sla-loader-sec">
        <Skeleton animation="pulse" height="17px" width="68px" />
        {arr?.map((item) => (
          <Box className="sla-loader-sec-inner" key={item}>
            <Skeleton animation="pulse" height="17px" width="126px" />
            <Skeleton animation="pulse" height="17.5px" width="77px" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default SlaLoader;
