import { styled } from "@mui/material/styles";
import {
  TextField,
  FormControl,
  Button,
  MenuItem,
  Select,
  SwipeableDrawer,
} from "@mui/material";

export const SwipeableDrawerWrapper = styled(SwipeableDrawer)`
  .MuiPaper-root {
    top: 60px;
  }
`;

export const FormTextField = styled(TextField)`
  display: flex;
  margin: 10px 0px 15px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  input {
    padding: 8px 0px;
    border: 1px solid #cbd5e1;
    font-size: 13px;
  }
  margin: 0;
  ::placeholder {
    font-size: 10px;
  }
`;

export const CustomFormControl = styled(FormControl)`
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-size: 14px !important;
  }
  .MuiSelect-select.MuiSelect-outlined {
    border: 1px solid #d9e0e6;
  }
`;
export const CustomSelect = styled(Select)`
  .MuiInputBase-input {
    padding: 10px;
  }
  .MuiSelect-select {
    font-weight: 400;
    font-size: 13px;
    color: #8d8d8d;
    padding: 8px;
  }
`;
export const CustomBtn = styled(Button)`
  border: 1px solid #cbd5e1;
  color: #64748b;
  border-radius: 0;
  height: 35px;
`;
export const CustomMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    padding: 0;
    padding-bottom: 5px;
  }
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-size: 14px;
    color: #334155;
    margin: 0px;
  }
  .MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 5px;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    font-size: 20px;
  }
  .MuiListItemText-root.css-tlelie-MuiListItemText-root {
    .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
      font-size: 14px !important;
    }
  }
`;
