import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Input,
  Checkbox,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import styles from "../commonStyle.module.css";

const MultipleChoiceQuestion = (props) => {
  const { question, questionNo, handleProceedAnswer } = props;

  const intl = useIntl();
  const [selectedValue, setSelectedValue] = useState([]);
  const [otherAnswer, setOtherAnswer] = useState("");
  const [options, setOptions] = useState([]);
  const optionNo = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

  useEffect(() => {
    if (question?.question?.constraints?.randomaize === true) {
      const shuffleArray = (array) => {
        for (let i = array?.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      };
      const randomizedItems = shuffleArray([...question?.question?.items]);
      if (randomizedItems?.length !== 0) setOptions(randomizedItems);
      return;
    } else return setOptions(question?.question?.items);
  }, [question]);

  const handleSelection = (selectedIndex) => {
    const { multiple_selection } = question.question.constraints;
    let arr = [];
    if (!multiple_selection) {
      arr?.push(selectedIndex);
      setSelectedValue(arr);
      if (question?.is_proceed_on_answer)
        handleProceedAnswer(options[selectedIndex]?.next_section);
    } else if (multiple_selection) {
      arr = [...selectedValue];
      if (arr?.includes(selectedIndex)) {
        const index = arr?.indexOf(selectedIndex);
        arr?.splice(index, 1);
        setSelectedValue(arr);
      } else {
        setSelectedValue(selectedValue?.concat(selectedIndex));
      }
      if (question?.is_proceed_on_answer) handleProceedAnswer(null);
    }
  };

  const handleOtherAnswer = (e) => setOtherAnswer(e.target.value);
  let multipleSelection = question?.question?.constraints?.multiple_selection;

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {question?.question?.text}
          {question?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{question?.question?.description}</Box>
        {options?.length !== 0 &&
          options?.map((val, index) => {
            return (
              <>
                {multipleSelection === true ? (
                  <Box className={styles.checkBoxRadioBtnBox}>
                    <FormControlLabel
                      label={CheckValueLocale(
                        Object.values(val)[0],
                        "",
                        {},
                        intl,
                      )}
                      control={
                        <Checkbox
                          onChange={() => handleSelection(index)}
                          className={`${styles.mcqOptions}`}
                          checked={val?.selected}
                        />
                      }
                    />
                  </Box>
                ) : (
                  <RadioGroup
                    key={`Radio${index}`}
                    aria-labelledby={`demo-controlled-radio-buttons-group-${index}`}
                    name={`controlled-radio-buttons-group-${index}`}
                    value={val}
                    className={styles.radioGroupBox}
                    onChange={(e) => handleSelection(index)}
                  >
                    <FormControlLabel
                      key={`Radio${index}`}
                      value={val}
                      control={
                        <Radio
                          checked={
                            selectedValue?.length && selectedValue[0] == index
                              ? true
                              : false
                          }
                        />
                      }
                      label={Object.values(val)[0]}
                    />
                  </RadioGroup>
                )}
              </>
            );
          })}
        {question?.question?.constraints?.other_option && (
          <Box
            className={`${styles.mcqOptions} ${!multipleSelection ? styles.mcqOptionOther : ""} ${
              selectedValue.includes("other")
                ? styles.selected
                : styles.unSelected
            }`}
            onClick={() => {
              handleSelection("other");
              handleProceedAnswer(null);
            }}
          >
            {!multipleSelection ? (
              <RadioGroup
                aria-labelledby={`demo-controlled-radio-buttons-group`}
                name={`controlled-radio-buttons-group`}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedValue?.includes("other") ? true : false}
                    />
                  }
                />
              </RadioGroup>
            ) : null}

            <Box className={styles.optionValue}>
              {CheckValueLocale("other", "", {}, intl)}
            </Box>
          </Box>
        )}
        {selectedValue?.includes("other") &&
        question?.question?.constraints?.other_option ? (
          <Box>
            <Input
              id="standard-basic"
              placeholder={CheckValueLocale(
                "type_your_answer_here",
                "",
                {},
                intl,
              )}
              variant="standard"
              className={styles.textFieldStyle}
              value={otherAnswer}
              onChange={handleOtherAnswer}
            />
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default MultipleChoiceQuestion;
