import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";

import { Typography, Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PageHeader from "components/pageHeader.js";

import ListSearchFilter from "./Search";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const ListHeader = ({
  handelTextSearch,
  handelFilterAction,
  searchText,
  setSearchText,
  reports,
  searchInput,
}) => {
  const intl = useIntl();
  const history = useHistory();
  return (
    <>
      <Box container className="title-header-box">
        <PageHeader title="reports" />
        <Box id="title-button-box">
          <LucButton
            startIcon={<AddIcon />}
            onClick={() => history.push("/reports/add-report")}
            id="report-create-new-report-btn"
          >
            {CheckValueLocale("new_report", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
      {reports?.length > 0 || searchInput ? (
        <Box>
          <ListSearchFilter
            handelTextSearch={handelTextSearch}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <LucButton
            className="filter-btn"
            variant="outline"
            onClick={() => handelFilterAction(true)}
            id="report-create-new-filters-btn"
            startIcon={<FontAwesomeIcon icon={faFilter} />}
          >
            {CheckValueLocale("filters", "", {}, intl)}
          </LucButton>
        </Box>
      ) : null}
    </>
  );
};

export default ListHeader;
