import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import Turnstile from "react-turnstile";
import { CheckValueLocale } from "utils/helpers";

const FormCloudFlare = ({
  handleVerifySuccess,
  handleVerifyExpire,
  errorField,
}) => {
  const intl = useIntl();
  return (
    <Box className="cloudflare-wrapper">
      <Turnstile
        className="cloudflare-box"
        theme="light"
        sitekey="0x4AAAAAAAPYnlA5vbwV-WV2"
        onVerify={handleVerifySuccess}
        onExpire={handleVerifyExpire}
        onTimeout={handleVerifyExpire}
      />
      {errorField ? (
        <Box component="p" className="error-text-msg">
          {" "}
          {CheckValueLocale(errorField, "", {}, intl)}{" "}
        </Box>
      ) : null}
    </Box>
  );
};
export default FormCloudFlare;
