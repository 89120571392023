import { useState } from "react";
import { Box, InputAdornment, Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { handleNbExcludedSourcesChange } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/newBlogsSharedFunctions";
import {
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";

const ExcludedInputFieldEdit = (props) => {
  const intl = useIntl();
  const {
    nbExcludedSource,
    excludedSourcesErrorMsg,
    excludedSource,
    handleSourceskeyboardPress,
    setExcludedSource,
    setExcludedSourcesErrorMsg,
    setNbExcludedSourceError,
  } = props;

  // input Foucsed Case
  const [focused, setFocused] = useState(false);

  return (
    <Box>
      <TextField
        id="outlined-email-input"
        label={CheckValueLocale("exlude_sources_titles", "", {}, intl)}
        className={`
             spam-text-field input-field-edit input-field-excluded-source  ${checkDirectionLang(
               excludedSource,
             )}`}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(true)}
        InputProps={{
          endAdornment: focused && (
            <InputAdornment onMouseDown={handleInputMouseDown}>
              <Button
                className="add-btn-with-plus add-btn-plus-setting"
                onClick={(event) => handleSourceskeyboardPress(event, "btn")}
                value={excludedSource}
                id="monitor-options-nb-kw-add-keyword-btn"
              >
                {CheckValueLocale("add_plus", "", {}, intl)}
              </Button>
            </InputAdornment>
          ),
        }}
        error={excludedSourcesErrorMsg !== "" ? excludedSourcesErrorMsg : ""}
        onKeyPress={handleSourceskeyboardPress}
        onChange={(e) =>
          handleNbExcludedSourcesChange(
            e,
            setExcludedSource,
            nbExcludedSource,
            setExcludedSourcesErrorMsg,
            setNbExcludedSourceError,
            intl,
          )
        }
        value={excludedSource}
      />
    </Box>
  );
};

export default ExcludedInputFieldEdit;
