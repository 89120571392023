import { useQuery } from "@tanstack/react-query";
import LiveDashboardController from "services/controllers/liveDashboardController";

export const useMonitorLiveDashboardDetails = (queryData, isEditDashbord) => {
  return useQuery({
    queryKey: [
      "liveDashboardDetails",
      queryData?.monitor_id,
      queryData?.dashboard_user_id,
    ],
    queryFn: () => {
      if (window?.location?.href?.includes("/live-dashboard/public/")) {
        return LiveDashboardController.getMonitorLiveDashboardPublicDetails(
          queryData,
        );
      }
      return LiveDashboardController.getMonitorLiveDashboardDetails(queryData);
    },
    enabled:
      !!queryData?.monitor_id &&
      !!queryData?.dashboard_user_id &&
      !!isEditDashbord,
    select: (data) => {
      if (data?.data?.monitor_user_live_dashboard)
        return data?.data?.monitor_user_live_dashboard;
      if (data?.errorMsg == "Unauthorized request") {
        localStorage.removeItem("challengeKey");
        window.location.reload(false);
      }
      return data;
    },
    retry: false,
  });
};
