import {
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const DropDownComponent = (props) => {
  const intl = useIntl();
  const {
    dropdownVal,
    language,
    countries,
    handleChangeDropdown,
    resp,
    monitorSetting,
  } = props;

  return (
    <>
      <FormControl className={!monitorSetting && "drop-down-field"}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          className="select-owner"
          value={dropdownVal === "languages_filter" ? language : countries}
          onChange={(e) => handleChangeDropdown(e, dropdownVal, resp)}
          multiple
          renderValue={(selected) =>
            selected?.length > 0
              ? CheckValueLocale(
                  "nb_selected_num",
                  "",
                  { num: selected?.length },
                  intl,
                )
              : dropdownVal === "languages_filter"
                ? ` ${CheckValueLocale("select_languages", "", {}, intl)}`
                : ` ${CheckValueLocale("select_countries", "", {}, intl)}`
          }
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          disabled={monitorSetting && !handlelUserRoles("SM", "EDIT_MONITOR")}
        >
          {dropdownVal === "languages_filter" &&
            resp?.languages?.map((val) => {
              return (
                <MenuItem
                  value={val.id}
                  className="nb_dropdown_style"
                  id={`monitor-keywords-name-${val?.id ?? "language-id"}`}
                >
                  <Checkbox
                    checked={language?.indexOf(val.id) > -1}
                    sx={{ mr: 1 }}
                  />
                  <ListItemText
                    primary={intl.locale === "en" ? val.name : val.ar_name}
                  />
                </MenuItem>
              );
            })}

          {dropdownVal === "countries" &&
            resp?.countries?.map((val) => {
              return (
                <MenuItem
                  value={val.id}
                  className="nb_dropdown_style"
                  id={`monitor-keywords-countries-${val?.id ?? "country-id"}`}
                >
                  <Checkbox
                    checked={countries?.indexOf(val.id) > -1}
                    sx={{ mr: 1 }}
                  />
                  <ListItemText
                    primary={intl.locale === "en" ? val.name : val.arabic_name}
                  />
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
};

export default DropDownComponent;
