import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChartColumn,
} from "@fortawesome/pro-regular-svg-icons";
import Services from "services/api/Services";

const GetStartedModal = (props) => {
  const { modalOpened, item, handleCloseModal } = props;
  const intl = useIntl();

  return (
    <Dialog
      className="get-started-dialog"
      open={modalOpened}
      onClose={handleCloseModal}
    >
      <DialogContent className="get-started-dialog-content">
        <img src={item?.modalImg} alt="get-started" />
        <Box className="get-started-information-box">
          <Typography variant="h6" className="item-title">
            {CheckValueLocale(item?.title, "", {}, intl)}
          </Typography>
          <Typography variant="p" className="item-description">
            {CheckValueLocale(item?.description, "", {}, intl)}
          </Typography>
          <Box className="desc-points">
            {item?.desc_points?.map((point, index) => {
              return (
                <Box key={index} className={"desc-point-box"}>
                  <Box component={"span"} className="desc-point">
                    {index + 1}
                  </Box>
                  <Box className="desc-point-text">
                    {CheckValueLocale(
                      point,
                      "",
                      {
                        link_1: (
                          <Box
                            component={"span"}
                            className="desc-point-link"
                            onClick={() => window.open(item?.link_1?.path)}
                          >
                            {CheckValueLocale(
                              item?.link_1?.title,
                              "",
                              {},
                              intl,
                            )}
                          </Box>
                        ),
                        icon_1: (
                          <FontAwesomeIcon
                            className="desc-point-icon"
                            icon={faChartColumn}
                          />
                        ),
                      },
                      intl,
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          {item?.note ? (
            <Box className="notes">
              <Typography variant="h6" className="title">
                {CheckValueLocale("note", "", {}, intl)}
              </Typography>
              <Box component={"ul"}>
                <Box component="li">
                  {CheckValueLocale(item?.note, "", {}, intl)}
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions className="get-started-dialog-action">
        {Services.showThirdCompany ? (
          <LucButton
            id="get-started-learn-more-btn"
            variant="flat"
            endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            onClick={() => window.open(item?.learn_more_url)}
          >
            {CheckValueLocale("get_started_learn_more_btn", "", {}, intl)}
          </LucButton>
        ) : null}
        <LucButton id="get-started-modal-got-it-btn" onClick={handleCloseModal}>
          {CheckValueLocale("get_started_got_it_btn", "", {}, intl)}
        </LucButton>
      </DialogActions>
    </Dialog>
  );
};

export default GetStartedModal;
