import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleAuthorActivity,
  handleXlsData,
  handleSortedCase,
} from "../excelCommonFn";

export const engagementAnalyticsSLAs = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    return [];
  }
};
