import { Box } from "@mui/material";
import DataSourceContent from "./dataSourceContent";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const FacebookDataSource = ({
  getSocialIcon,
  resetAllFields,
  response,
  preLoaderTrackerDataSources,
  unixDate,
  singleTweetAnalysis,
  facebookWidgetTypes,
  setFacebookWidgetTypes,
  sortWidgets,
  getNameKeyWidget,
  showEditBtn,
  setTotalStatisticWidgets,
  setLoadingStatisticWidgets,
  themesOptions,
}) => {
  const intl = useIntl();

  return (
    <>
      <Box className="live-dashboard-data-source">
        <Box className="data-source-heading">
          {CheckValueLocale("FACEBOOK", "", {}, intl)}
          {getSocialIcon("FACEBOOK")}
        </Box>
        <DataSourceContent
          resetAllFields={resetAllFields}
          response={response}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          unixDate={unixDate}
          singleTweetAnalysis={singleTweetAnalysis}
          facebookWidgetTypes={facebookWidgetTypes}
          setFacebookWidgetTypes={setFacebookWidgetTypes}
          sortWidgets={sortWidgets}
          getNameKeyWidget={getNameKeyWidget}
          showEditBtn={showEditBtn}
          setTotalStatisticWidgets={setTotalStatisticWidgets}
          setLoadingStatisticWidgets={setLoadingStatisticWidgets}
          themesOptions={themesOptions}
        />
      </Box>
    </>
  );
};
export default FacebookDataSource;
