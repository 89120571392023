// Dummy image
import dummyImage from "images/shared-images/dummy-image-for-media.svg";

// Dummy video
import dummyVideo from "images/shared-images/video-placeholder.svg";

// Fontawesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useIntl } from "react-intl";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";

const LinkedInMediaItem = (props) => {
  const intl = useIntl();

  const [imageIsReady, setImageIsReady] = useState(false);
  const img = new Image();
  img.onload = () => {
    setImageIsReady(true);
  };
  img.src = dummyImage;

  const handleImageError = (event) => {
    event.target.src = dummyImage;
  };

  const handleVideoError = (event) => {
    event.target.src = dummyVideo;
  };

  return (
    <div className="top-media-item">
      <div
        className={
          props?.isLinkedInImage
            ? "top-media__image image-linkedin"
            : "top-media__image"
        }
      >
        {props?.isLinkedInImage &&
          (imageIsReady ? (
            <img
              src={props?.media?.src}
              alt=""
              className="img-fluid"
              onError={handleImageError}
            />
          ) : (
            <img className="img-fluid" src={dummyImage} alt={"loading..."} />
          ))}

        {props?.isLinkedInVideo && (
          <video className="video-linkedin" controls>
            <source
              src={props?.media?.src}
              type="video/mp4"
              onError={handleVideoError}
            />
          </video>
        )}
      </div>

      <a
        href={props?.media?.id}
        className="top-media__link"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faLink} />
      </a>

      <div className="top-media__text">
        <div>
          <span>{CheckValueLocale("linkedin-reactions", "", {}, intl)}</span>
          <span>{props?.media?.stats_count}</span>
        </div>
        <div className="top-media__icon linkedin-icon">
          <FontAwesomeIcon icon={faLinkedin} />
        </div>
      </div>
    </div>
  );
};

export default LinkedInMediaItem;
