import { useIntl } from "react-intl";
import { Typography, Box, Button } from "@mui/material";
import { faQuestion, faPercent } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import BarChartWidget from "components/widgets/barChartWidget";
import InteractionsList from "components/interactions-list/InteractionsList";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const InstagramCustomerCareBody = (props) => {
  const intl = useIntl();
  let { instagramWidgets, dataSources, latestInteractionsCount } = props;

  const [isHiddenIg, setIsHiddenIg] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("customer_care")) {
      setIsHiddenIg(false);
    } else {
      setIsHiddenIg(true);
    }
  }, []);

  const handleClick = () => {
    localStorage.setItem("customer_care", false);
    setIsHiddenIg(false);
  };
  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("customer_care_title", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        {isHiddenIg ? (
          <Box className="customer-care-alert margin-bottom">
            <Box className="start-box">
              <InfoOutlinedIcon />
              <Typography>
                {CheckValueLocale("customer_care_alerts_channel", "", {}, intl)}
              </Typography>
            </Box>
            <Box className="end-box">
              <Button onClick={() => history.push("/manage-channels/list/")}>
                {CheckValueLocale("manage_channels", "", {}, intl)}
              </Button>
              <FontAwesomeIcon onClick={handleClick} icon={faTimes} />
            </Box>
          </Box>
        ) : null}
        <div className="chart-body">
          <BarChartWidget
            title={"customer_performance"}
            titleToolTip={"customer_performance_ig_tooltip"}
            data={instagramWidgets?.customerCareData}
            showAI={true}
            showDownloadIcon={true}
            showChartStatsWidget={true}
            showZoomPercentage
            customerCareChart
            gridHeight={"70%"}
            respTimeDistributionTooltip="respone_time_distribution_tooltip"
            statsData={[
              {
                name: "total_ques",
                value:
                  instagramWidgets?.customerCarePerformance
                    ?.total_number_of_questions &&
                  commasAfterDigit(
                    instagramWidgets?.customerCarePerformance
                      ?.total_number_of_questions,
                  ),
                icon: faQuestion,
                titleToolTip: "number_of_ques_tooltip",
              },
              {
                name: "number_of_answered_questions",
                value: isNaN(instagramWidgets?.respRate * 100)
                  ? 0 + "%"
                  : instagramWidgets?.respRate.toFixed(2) * 100 + "%",
                icon: faPercent,
                titleToolTip: "response_rate_tooltip",
              },
              {
                name: "avg_response_time_in_seconds",
                value:
                  instagramWidgets?.customerCarePerformance
                    ?.avg_response_time_in_seconds,
                icon: faClock,
                type: "time",
                titleToolTip: "avg_response_time_tooltip",
              },
            ]}
            dataColor="#2AAAE2"
            toolTipLabel={"posts"}
            isTwitterAccount
            barChartPreLoaderDataSources={
              instagramWidgets?.customerCarePreloader
            }
            preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
            iconAfterTitle={
              <FontAwesomeIcon
                icon={faInstagram}
                className="instagram-icon-style"
              />
            }
          />
        </div>
      </Box>
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={props.unixDate}
          data={instagramWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={dataSources}
          isPublicInstagram
          latestInteractionsPreLoaderDataSources={
            instagramWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showViewMoreButton={latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default InstagramCustomerCareBody;
