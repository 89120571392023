import { Box } from "@mui/material";
import DeletedAlertView from "pages/alerts/components/alertTypesView/components/deletedAlertView/deletedAlertView";

const DeleteAlertEngagement = () => {
  return (
    <Box>
      <DeletedAlertView />
    </Box>
  );
};

export default DeleteAlertEngagement;
