import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { handleActiveFeature } from "utils/helpers";

export const data = [
  // Twitter _____________________________________________________________
  {
    TWITTER_KEYWORD: [
      {
        analytics: [
          {
            tabName: "engagements",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
          {
            tabName: "authors",
          },
        ],
      },
    ],
  },
  {
    TWITTER_ACCOUNT_ANALYSIS: [
      {
        analytics: [
          {
            tabName: "account",
          },
          {
            tabName: "engagements",
          },
          {
            tabName: "customer_care",
          },

          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
          {
            tabName: "comments_mentions",
          },
          {
            tabName: "authors",
          },
          {
            tabName: "questions",
          },
        ],
      },
    ],
  },
  {
    TWITTER_MANAGED_PAGE: [
      {
        analytics: [
          {
            tabName: "engagements",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
          {
            tabName: "authors",
          },
        ],
      },
    ],
  },
  // Facebook _____________________________________________________________
  {
    FACEBOOK_KEYWORD: [
      {
        analytics: [
          {
            tabName: "engagements",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
          {
            tabName: "comments_mentions",
          },
        ],
      },
    ],
  },
  {
    FACEBOOK_ACCOUNT_ANALYSIS: [
      {
        analytics: [
          {
            tabName: "account",
          },
          {
            tabName: "engagements",
          },
          {
            tabName: "customer_care",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
          {
            tabName: "comments_mentions",
          },
          {
            tabName: "questions",
          },
        ],
      },
    ],
  },
  {
    FACEBOOK_MANAGED_PAGE: [
      {
        analytics: [
          {
            tabName: "account",
          },
          {
            tabName: "engagements",
          },
          {
            tabName: "customer_care",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
          {
            tabName: "comments_mentions",
          },
          {
            tabName: "questions",
          },
        ],
      },
    ],
  },
  // Instagram _____________________________________________________________
  {
    INSTAGRAM_KEYWORD: [
      {
        analytics: [
          {
            tabName: "engagements",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
        ],
      },
    ],
  },
  {
    INSTAGRAM_ACCOUNT_ANALYSIS: [
      {
        analytics: [
          {
            tabName: "account",
          },
          {
            tabName: "engagements",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
        ],
      },
    ],
  },
  {
    INSTAGRAM_MANAGED_ACCOUNT: [
      {
        analytics: [
          {
            tabName: "account",
          },
          {
            tabName: "engagements",
          },
          {
            tabName: "customer_care",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
          {
            tabName: "comments_mentions",
          },
          {
            tabName: "questions",
          },
        ],
      },
    ],
  },
  // NewsAndBlogs _____________________________________________________________
  {
    TALKWALKER_KEYWORD: [
      {
        analytics: [
          {
            tabName: "engagements",
          },
          handleActiveFeature("SM", "is_live_dashboard_supported") && {
            tabName: "live_dashboard",
          },
        ],
      },
      {
        list: [
          {
            tabName: "posts",
          },
        ],
      },
    ],
  },
];
