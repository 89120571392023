import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Radio, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

const GreetingFarewellMessage = ({
  message,
  isSelected,
  handleEditClick,
  handleSelectedMessage,
}) => {
  const intl = useIntl();
  return (
    <Box
      onClick={handleSelectedMessage}
      className="greeting-farewell-message-container"
    >
      <Box className="greeting-farewell-message-content">
        <Typography className="greeting-farewell-message-text">
          {message}
        </Typography>
        <Radio
          className="greeting-farewell-message-radio"
          size="small"
          checked={isSelected}
          onChange={handleSelectedMessage}
        />
      </Box>
      <Box className="greeting-farewell-message-footer">
        <LucButton
          startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
          variant="text"
          onClick={handleEditClick}
        >
          {CheckValueLocale("edit", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );
};

export default GreetingFarewellMessage;
