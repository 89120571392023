import { useCallback } from "react";
import {
  NonRepeatedKeywords,
  checkMatchedAdvancedKeywords,
  getBeforeNot,
} from "./advancedKeyword";
import {
  getKeywordsNewsLength,
  getKeywordsLength,
  checkKeywordLength,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordsLength";
import { advancedParser, convertedInputRule } from "./parserFunctions";
import { removeSpecialCharacters } from "./keywordLanguage";

const WordCount = () => {
  //check keyword limit in keywords with all dataSource
  const checkKeywordLimit = (word, keywords) => {
    let wordsCount = 0;
    let words = word.trim().split(/[,،]+/);
    words?.forEach((el) => {
      if (
        (el[0] === "'" && el[el.length - 1] === "'") ||
        (el[0] === '"' && el[el.length - 1] === '"') ||
        (el[0] === "^" && el[1] === '"' && el[el.length - 1] === '"') ||
        (el[el.length - 1] === "^" &&
          el[0] === '"' &&
          el[el.length - 2] === '"' &&
          !el.match(/[a-z]/i))
      ) {
        wordsCount += 1;
      } else {
        wordsCount += el.trim().split(/[\s]+/).length;
      }
    });

    wordsCount += getKeywordsLength(keywords);

    return wordsCount;
  };
  const getWordsCount = (word) => {
    let wordsCount = 0;
    let words = word.trim().split(/[,،]+/);
    if (
      words.toString()[words.toString().length - 2] === " " &&
      ((word.startsWith('"') && word.endsWith('"')) ||
        (word.startsWith("'") && word.endsWith("'")) ||
        (word.startsWith("‟") && word.endsWith("”")))
    ) {
      let arr = [];
      let key = words
        .toString()
        .split(/[ "']+/)
        .map((val) => val.trim())
        .filter((val) => val !== "");
      arr.push(key.join(" ").toString());
      words = arr;
    }

    words.forEach((el) => {
      if (
        (el[0] === "'" && el[el.length - 1] === "'") ||
        (el[0] === '"' && el[el.length - 1] === '"') ||
        (el[0] === "^" && el[1] === '"' && el[el.length - 1] === '"') ||
        (el[el.length - 1] === "^" &&
          el[0] === '"' &&
          el[el.length - 2] === '"' &&
          !el.match(/[a-z]/i))
      ) {
        wordsCount += 1;
      } else {
        wordsCount += el.trim().split(/[\s]+/).length;
      }
    });

    return wordsCount;
  };
  // check keyword limit of twitter public and twitter keywords
  const checkKeywordLimitTwitter = (word, value, keywordType) => {
    const keywords = value[0].keywords;
    const ex_spam_keywords = value[1].spam_keywords;
    const ex_collect_tweets = value[2].collect_tweets;
    const ex_ignore_tweets = value[3].ignore_tweets;
    let keywords_length = 0;
    let spam_keywords_length = 0;
    let collect_tweets_length = 0;
    let ignore_tweets_length = 0;
    const wordsCount = getWordsCount(word);

    keywords_length = getKeywordsLength(keywords);
    spam_keywords_length = getKeywordsLength(ex_spam_keywords);
    collect_tweets_length = getKeywordsLength(ex_collect_tweets);
    ignore_tweets_length = getKeywordsLength(ex_ignore_tweets);
    if (keywordType === "keywords") {
      keywords_length += wordsCount;
    } else if (keywordType === "spam_keywords") {
      spam_keywords_length += wordsCount;
    } else if (keywordType === "collect_tweets") {
      collect_tweets_length += wordsCount;
    } else if (keywordType === "ignore_tweets") {
      ignore_tweets_length += wordsCount;
    }
    return {
      keywordsLength: [keywords_length],
      otherLength: [
        spam_keywords_length,
        collect_tweets_length,
        ignore_tweets_length,
      ],
    };
  };

  const getTotalTwitterWordsCount = useCallback((twitterKeywords) => {
    const keywords = twitterKeywords[0].keywords;
    const spam_keywords = twitterKeywords[1].spam_keywords;
    const collect_tweets = twitterKeywords[2].collect_tweets;
    const ignore_tweets = twitterKeywords[3].ignore_tweets;
    const spam_keywords_length = getKeywordsLength(spam_keywords);
    const collect_tweets_length = getKeywordsLength(collect_tweets);
    const ignore_tweets_length = getKeywordsLength(ignore_tweets);
    const keywordsLength = getKeywordsLength(keywords);
    const otherLength =
      spam_keywords_length + collect_tweets_length + ignore_tweets_length;
    return {
      keywordsLength,
      otherLength,
    };
  }, []);
  //check keyword limit of News&Blogs [ keyword - spamKeyword - excludedKeywords]
  const checkKeywordLimitNews = (word, value, keywordType, spamWord) => {
    let keywords = value[0].keywords;
    let ex_keywords = value[1].spam_keywords;
    let keywords_length = 0;
    let spam_keywords_length = 0;
    const wordsCount = getWordsCount(word);

    keywords_length = getKeywordsLength(keywords);
    spam_keywords_length = getKeywordsLength(ex_keywords);
    if (keywordType === "keywords") {
      keywords_length += wordsCount;
    } else if (keywordType === "spam_keywords") {
      spam_keywords_length += wordsCount;
    }

    if (spamWord) {
      let spamWords = spamWord.trim().split(/[,،]+/);
      let spamWordsCount = 0;
      if (
        spamWords.toString()[spamWords.toString().length - 2] === " " &&
        ((spamWord.startsWith('"') && spamWord.endsWith('"')) ||
          (spamWord.startsWith("'") && spamWord.endsWith("'")) ||
          (spamWord.startsWith("‟") && spamWord.endsWith("”")))
      ) {
        let arr = [];
        let key = spamWords
          .toString()
          .split(/[ "']+/)
          .map((val) => val.trim())
          .filter((val) => val !== "");
        arr.push(key.join(" ").toString());
        spamWords = arr;
      }

      spamWords.forEach((el) => {
        if (!el) {
          return;
        } else if (
          (el[0] === "'" && el[el.length - 1] === "'") ||
          (el[0] === '"' && el[el.length - 1] === '"')
        ) {
          if (!checkKeywordLength(el)) {
            spamWordsCount += el.trim().split(/[\s]+/).length;
          }
        } else {
          if (!checkKeywordLength(el)) {
            spamWordsCount += el.trim().split(/[\s]+/).length;
          }
        }
      });

      if (keywordType === "spam_keywords") {
        keywords_length += spamWordsCount;
      } else if (keywordType === "keywords") {
        spam_keywords_length += spamWordsCount;
      }
    }
    return [keywords_length, spam_keywords_length];
  };

  // Ensure that each word after excluding special characters will not match existing keywords
  const checkKeywordMatch = (
    word,
    value,
    advanced,
    keywordType,
    specialType,
  ) => {
    let new_word = word;
    let keywords;
    let ex_keywords;
    new_word = checkMatchedAdvancedKeywords(new_word, advanced);

    if (keywordType === "keywords" || keywordType === "spam_keywords") {
      keywords = value[0].keywords;
      ex_keywords = value[1].spam_keywords;
    } else if (specialType) {
      keywords = value.keywords;
      ex_keywords = value.exclude_keywords;
    } else {
      keywords = value[2].collect_tweets;
      ex_keywords = value[3].ignore_tweets;
    }
    //checking validity for facebook

    if (
      keywords.includes(new_word) === true ||
      ex_keywords.includes(new_word) === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  //check if keyword is Exist or not in all dataSources
  const checkKeywordExist = (
    word,
    value,
    advanced,
    keywordType,
    specialType,
  ) => {
    var words;
    if (word.search(",")) {
      words = word.split(",");
    } else {
      words = word;
    }
    if (words.length > 1) {
      for (var i = 0; i < words.length; i++) {
        if (
          checkKeywordMatch(words[i], value, advanced, keywordType, specialType)
        ) {
          return true;
        }
      }
    } else {
      return checkKeywordMatch(word, value, advanced, keywordType, specialType);
    }
  };

  // Ensure that each word after excluding special characters will not match existing keywords
  const checkKeywordMatchInstagram = (word, value) => {
    word = word.replace(
      /[§±!@\$%\^&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.,؛؟،\"\']/g,
      "",
    );
    let keywords = value[0].hashtags;
    let ex_keywords = value[1].spam_hashtags;
    if (
      keywords.includes(word) === true ||
      ex_keywords.includes(word) === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  //check if keyword is Exist or not in Ig
  const checkKeywordExistInstagram = (word, value) => {
    var words = word.split(",");
    if (words.length > 1) {
      for (var i = 0; i < words.length; i++) {
        if (checkKeywordMatchInstagram(words[i], value)) {
          return true;
        }
      }
    } else {
      return checkKeywordMatchInstagram(word, value);
    }
  };

  //check IG keyword Limit
  const checkKeywordLimitInstagram = (word, keyword) => {
    var multiple_words = "",
      count = 0,
      str_length = word.length - 1;
    let words = word.trim().split(/[,،]+/);
    words.map((keyword) => {
      str_length = keyword.length - 1;
      if (
        (keyword[0] === "'" && keyword[str_length] === "'") ||
        (keyword[0] === '"' && keyword[str_length] === '"')
      ) {
        count++;
      } else {
        multiple_words = keyword.split(/[\s,]+/);
        count = count + multiple_words.length;
      }
    });
    count += getKeywordsLength(keyword);
    return count;
  };

  return {
    checkKeywordLimit,
    checkKeywordLimitNews,
    checkKeywordExist,
    checkKeywordMatch,
    checkKeywordMatchInstagram,
    checkKeywordExistInstagram,
    checkKeywordLimitInstagram,
    checkKeywordLimitTwitter,
    getTotalTwitterWordsCount,
  };
};
export default WordCount;
