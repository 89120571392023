import * as _ from "lodash";

//get the last id of each datasouce in Public Account only
export const getPublicAccountDatasourcesId = (authorizedAccounts) => {
  let twAccountId =
    authorizedAccounts?.twitterAccounts[
      authorizedAccounts?.twitterAccounts?.length - 1
    ]?.attributes?.id;
  let fbAccountId =
    authorizedAccounts?.facebookAccounts[
      authorizedAccounts?.facebookAccounts?.length - 1
    ]?.attributes?.id;
  let igAccountId =
    authorizedAccounts?.instagramAccounts[
      authorizedAccounts?.instagramAccounts?.length - 1
    ]?.attributes?.id;
  return { twAccountId, fbAccountId, igAccountId };
};

//__________________________get active monitorTypeId & monitorTypeName___________________________________

export const getMonitorTypeSelected = (
  companyMonitorTypes,
  selectedMonitorType,
) => {
  let monitorTypeId, monitorTypeName;
  companyMonitorTypes.map((monitorType) => {
    if (selectedMonitorType == monitorType.attributes.name[0]) {
      monitorTypeId = monitorType.attributes.id;
      monitorTypeName = selectedMonitorType;
    }
  });
  return { monitorTypeId, monitorTypeName };
};

//____________get active datasourceName__________________________
export const getActiveDatasourceName = (
  companyAllowedSources,
  activeDataSourceId,
) => {
  let activeDataSourceName = "";
  companyAllowedSources.map((src) => {
    let company_source = src.attributes.source;
    if (src.id == activeDataSourceId) {
      activeDataSourceName = company_source;
    }
  });
  return activeDataSourceName;
};

//____________________get params data _______________________________
export const getDataPublicAccountParams = (
  selectedMonitorType,
  activeDataSourceId,
  params,
  twAccountId,
  fbAccountId,
  igAccountId,
  userName,
  pageName,
  newSpamKeyword,
  selectedAccount,
) => {
  if (selectedMonitorType == "ACCOUNT_ANALYSIS") {
    switch (activeDataSourceId) {
      case 1:
        params.id = twAccountId;
        params.follow = userName.replace(/"/g, "");
        params.spam_keywords = newSpamKeyword;
        break;
      case 2:
        params.id = fbAccountId;
        params.follow = pageName;
        break;
      case 3:
        params.id = igAccountId;
        params.follow = userName.replace(/"/g, "");
        break;
      default:
    }
  } else if (
    selectedMonitorType == "MANAGED_ACCOUNT" ||
    selectedMonitorType == "MANAGED_PAGE"
  ) {
    params.id = parseInt(selectedAccount.id);
  }
};

//handle topics data
export const getTopicsData = (monitorTopics) => {
  const keywordsCopy = _.cloneDeep(monitorTopics);
  //here we filter topics
  var filteredTopics = keywordsCopy.filter((value) => {
    return value.name !== "";
  });
  // incase of at least one topic exist
  if (filteredTopics.length)
    filteredTopics?.map((item, i) => {
      filteredTopics[i].name = item.name.trim();
      filteredTopics[i].keywords = item.keywords
        ?.toString()
        ?.split(",")
        ?.join(",");
      filteredTopics[i].exclude_keywords.length === 0
        ? (filteredTopics[i].exclude_keywords = "")
        : (filteredTopics[i].exclude_keywords = item.exclude_keywords
            ?.toString()
            ?.split(",")
            ?.join(","));
    });
  return filteredTopics;
};
