import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { CheckValueLocale, getTimeZone } from "utils/helpers";
import PhoneMissedRoundedIcon from "@mui/icons-material/PhoneMissedRounded";
import { useState, useEffect } from "react";
import SnackBar from "components/snackBar.js";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import InsightsController from "services/controllers/insightsController.js";

const InteractionsListMessageGenesys = (props) => {
  const { audio, obj } = props;
  const intl = useIntl();
  const [dur, setDur] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, msg: "" });
  const [audioUrl, setAudioUrl] = useState(audio);

  let isFirefox = navigator.userAgent.indexOf("Firefox") != -1;

  let isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification),
    );

  let sound = document.createElement("audio");
  sound.id = "audio-player";
  sound.controls = "controls";
  sound.src = audioUrl;
  sound.type = "audio/ogg";

  function isValidUrl() {
    sound.onloadedmetadata = function () {
      setDur(sound.duration);
    };
  }

  useEffect(() => {
    if (sound) {
      isValidUrl();
    }
  }, [sound]);

  const updateAudio = () => {
    setDisableBtn(true);
    InsightsController.updateGenesysAudio(
      window.localStorage.cxm_id,
      obj?.channel_id,
      obj?.tracker_id,
      obj?.job_id,
    ).then((data) => {
      if (data?.errorMsg) {
        if (
          data?.errorMsg?.response?.data?.exception?.exception_class ==
          "Authentication_issue_in_Genesys_-_Invalid_Request"
        ) {
          setSnackBar({
            open: true,
            msg: "authentication_genesys_invalid_request",
          });
        } else if (
          data?.errorMsg?.response?.data?.exception?.exception_class ==
          "Genesys_account_missing_permissions"
        ) {
          setSnackBar({
            open: true,
            msg: "permissions_genesys_invalid_request",
          });
        } else {
          setSnackBar({ open: true, msg: "segments_failed_msg" });
        }
        setDisableBtn(false);
      } else if (data?.data) {
        sound.src = data?.data[0]?.resultUrl;
        setAudioUrl(data?.data[0]?.resultUrl);
        isValidUrl();
        setDisableBtn(false);
      }
    });
  };
  return (
    <>
      {!obj?.missed_call ? (
        <Box className="interaction-list-genesys-wrapper">
          <Box className="interaction-list-genesys-content">
            {dur == 0 ? (
              <>
                <IconButton
                  aria-label="reload"
                  className={
                    isFirefox
                      ? "reload-btn firefox"
                      : isSafari
                        ? "reload-btn isSafari"
                        : "reload-btn"
                  }
                  onClick={updateAudio}
                  disabled={disableBtn}
                >
                  <ReplayIcon />
                </IconButton>
                <audio controls className="interaction-audio">
                  <source src={audioUrl} type="audio/ogg" />
                </audio>
              </>
            ) : (
              <audio controls className="interaction-audio">
                <source src={audioUrl} type="audio/ogg" />
              </audio>
            )}
          </Box>
        </Box>
      ) : (
        <Box className="genesys-no-audio-section">
          <Box>
            <Box className="date-format-genesys">
              <PhoneMissedRoundedIcon />
              <Typography component="span">
                {CheckValueLocale("genesys_missed_audio", "", {}, intl)}
              </Typography>

              <Typography component="span">
                {obj?.created_at
                  ? moment.unix(obj?.created_at).format("YYYY/MM/DD ")
                  : ""}
                {intl.locale === "en" ? "at" : null}
                <Typography component="span" dir="ltr">
                  {obj?.created_at
                    ? moment.unix(obj?.created_at).format(" LT")
                    : ""}
                </Typography>
                <Typography component="span" className="time-zone-genesys">
                  {`(${CheckValueLocale("gmt", "", {}, intl)} ${
                    getTimeZone() > 0 ? "+" : "-"
                  }${getTimeZone()})`}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <SnackBar
        open={snackBar?.open}
        severity={"error"}
        message={CheckValueLocale(snackBar?.msg, "", {}, intl)}
        handleClose={() => setSnackBar({ open: false, msg: "" })}
      />
    </>
  );
};

export default InteractionsListMessageGenesys;
