import { useIntl } from "react-intl";
import { Tooltip, Typography, Zoom, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckValueLocale } from "utils/helpers";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    padding: "10px",
    width: 160,
    lineHeight: 1.5,
    borderRadius: "4px",
  },
}));

const ReportDetail = ({ reportData }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [dataSource, setDataSource] = useState(true);

  const showReportText = (text, style, arr, dataSource) => {
    const showAccountsArr = arr?.slice(0, 3);
    const showAccountLimit = arr?.slice(3, arr?.length);

    const checkReport = (report) =>
      dataSource
        ? CheckValueLocale(
            report?.type === "private"
              ? `${report?.name?.toLowerCase()}_dm`
              : report?.name?.toLowerCase(),
            "",
            {},
            intl,
          )
        : report?.name;

    return (
      <>
        {arr?.length >= 4 ? (
          <Tooltip
            title={text}
            arrow
            placement="bottom"
            TransitionComponent={Zoom}
            classes={{ tooltip: style }}
          >
            <Box>
              {showAccountsArr
                ?.map((reportName) => checkReport(reportName))
                ?.join(" , ")}
              {arr?.length >= 4 ? `... (+${showAccountLimit?.length})` : null}
            </Box>
          </Tooltip>
        ) : (
          <Box>
            {showAccountsArr
              ?.map((reportName) => checkReport(reportName))
              ?.join(" , ")}
          </Box>
        )}
      </>
    );
  };

  return (
    <Box className="view-report-card">
      <Box className="report-detail">
        <Typography variant="subtitle2">
          {CheckValueLocale("report_type", "", {}, intl).toUpperCase()}
        </Typography>
        <Typography variant="subtitle1">
          {CheckValueLocale(
            `${reportData?.attributes?.report_type}_report_type`,
            "",
            {},
            intl,
          )}
        </Typography>
      </Box>

      {reportData?.attributes?.monitor_type &&
      reportData?.attributes?.platform == "SM" ? (
        <Box className="report-detail">
          <Typography variant="subtitle2">
            {CheckValueLocale("monitor_type", "", {}, intl).toUpperCase()}
          </Typography>
          <Typography variant="subtitle1">
            {CheckValueLocale(
              reportData?.attributes?.monitor_type?.name,
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      ) : null}

      {reportData?.attributes?.monitors?.length !== 0 ? (
        <Box className="report-detail">
          <Typography variant="subtitle2">
            {reportData?.attributes?.platform == "SM"
              ? CheckValueLocale(
                  "report_monitor_name",
                  "",
                  {},
                  intl,
                ).toUpperCase()
              : CheckValueLocale(
                  "reports_view_accounts",
                  "",
                  {},
                  intl,
                ).toUpperCase()}
          </Typography>
          <Typography variant="subtitle1">
            {reportData?.attributes?.monitors &&
              showReportText(
                reportData?.attributes?.monitors
                  ?.map((val) => val?.name)
                  ?.join(" / "),
                classes.customWidth,
                reportData?.attributes?.monitors,
              )}
          </Typography>
        </Box>
      ) : null}

      {reportData?.attributes?.data_sources?.length !== 0 ? (
        <Box className="report-detail">
          <Typography variant="subtitle2">
            {reportData?.attributes?.platform == "SM"
              ? CheckValueLocale("data_sources", "", {}, intl).toUpperCase()
              : CheckValueLocale(
                  "reports_view_channels",
                  "",
                  {},
                  intl,
                ).toUpperCase()}
          </Typography>

          <Typography variant="subtitle1">
            {reportData?.attributes?.data_sources &&
              showReportText(
                reportData?.attributes?.data_sources
                  ?.map((val) =>
                    val?.name === "TALKWALKER" || val?.name === "NEWSBLOGS"
                      ? CheckValueLocale("news_blogs", "", {}, intl)
                      : CheckValueLocale(val?.name, "", {}, intl),
                  )
                  ?.join(", "),
                classes.customWidth,
                reportData?.attributes?.data_sources,
                dataSource,
              )}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default ReportDetail;
