import { useState } from "react";
import { useIntl } from "react-intl";
import UpdateTopicsIcon from "images/notification-icons/updateTopics.svg";
import Button from "@mui/material/Button";
import TopicsHeader from "components/widgets/topicsHeader/topicsHeader";
import SettingsIcon from "@mui/icons-material/Settings";
import SnackBar from "components/snackBar.js";
import CompaniesController from "services/controllers/companiesController";
import { CheckValueLocale } from "utils/helpers";

const UpdateTopics = (props) => {
  const intl = useIntl();
  const [success, setSuccess] = useState(null);

  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const sendTopicsUpgrade = () => {
    let queryData = {
      service_name: "request_manual_topics_feature",
    };
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
      setOpenSnackBar(true);
    });
  };
  return (
    <>
      {props?.showHeader && (
        <TopicsHeader
          title={"topics"}
          subtitle={"topics_collected"}
          topicsCount={0}
          btnText={"topics_settings"}
          btnIcon={<SettingsIcon />}
          disabled
        />
      )}
      <div
        className={
          props?.showHeader ? "upgrade-topics" : "upgrade-topics topics-margin"
        }
      >
        <img src={UpdateTopicsIcon} alt="Update Topics" />

        <div className="no-data-date">
          <span className="upgrade-topics-heading">
            {CheckValueLocale("upgrade_topics", "", {}, intl)}
          </span>
          <span className="upgrade-topics-content">
            {CheckValueLocale("upgrade_topics_text", "", {}, intl)}
          </span>
        </div>
        <Button
          variant="contained"
          onClick={() => sendTopicsUpgrade()}
          id="monitor-topics-upgrade-now-btn"
        >
          {CheckValueLocale("upgrade_now", "", {}, intl)}
        </Button>
      </div>

      <SnackBar
        open={openSnackBar}
        severity={success ? "success" : "error"}
        message={
          success
            ? CheckValueLocale("upgrade_msg_body", "", {}, intl)
            : CheckValueLocale("request_upgrade_failed", "", {}, intl)
        }
        title={
          success
            ? CheckValueLocale("request_upgrade_success", "", {}, intl)
            : CheckValueLocale("failed_error", "", {}, intl)
        }
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};

export default UpdateTopics;
