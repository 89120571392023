import { Divider, Tooltip, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BreadCrumb from "components/breadCrumb";
import { makeStyles } from "@mui/styles";
import "./dashboardHeader.scss";
import "App.scss";
import { CheckValueLocale } from "utils/helpers/index.js";
import DashboardFunctions from "../../../dashboardFunctions";
import { useIntl } from "react-intl";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker.js";
import Filter from "shared/general-filter/filter";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    padding: "13px",
    maxWidth: "500px",
  },
}));

const DashboardHeader = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  const { tooltipText } = DashboardFunctions();

  const {
    dashboardDynamicData,
    dashboardActiveTab,
    monitorType,
    dashboardID,
    showFilterBtn,
  } = props;

  const monitorsLength = dashboardDynamicData?.monitors?.data?.length;

  //Bread Crumb Links
  const links = [
    {
      name: "aggregate_dashboards",
      url: "dashboard/dashboard_list",
      addNameLocal: true,
    },
    {
      name: dashboardDynamicData?.name,
      url: `dashboard/${monitorType}/${dashboardActiveTab}/${dashboardID}`,
      addNameLocal: false,
    },
  ];
  /*______________________________________________________________*/

  const warningTooltip = (monitor, tooltipStyle) => {
    return (
      <div className={`display-in-row ${tooltipStyle}`}>
        <div className="tooltip-name">{`${monitor?.attributes?.name}`}</div>
        {monitor?.attributes?.data_sources?.length === 1 ? (
          <div className="header-tooltip-name">
            {`(${CheckValueLocale(
              monitor?.attributes?.data_sources[0]?.toLowerCase(),
              "",
              {},
              intl,
            )})`}
          </div>
        ) : (
          monitor?.attributes?.data_sources?.length > 1 &&
          monitor?.attributes?.data_sources?.map((dataSource, i) => {
            return (
              <div className="header-tooltip-name">
                {`${i === 0 ? "(" : ""}${CheckValueLocale(
                  dataSource?.toLowerCase(),
                  "",
                  {},
                  intl,
                )}${
                  i === monitor?.attributes?.data_sources?.length - 1
                    ? ")"
                    : ","
                } `}
              </div>
            );
          })
        )}
      </div>
    );
  };

  const showWarnings = (monitors) => {
    return (
      <Tooltip
        title={
          <Box>
            {monitors?.length === 1 ? (
              monitors?.map((monitor) => {
                if (monitor?.monitor_warning !== "") {
                  return tooltipText(
                    monitor?.name,
                    monitor?.monitor_warning,
                    "dashboard-tooltip",
                  );
                }
              })
            ) : (
              <div className="dashboard-tooltip-ul">
                {monitors?.map((monitor) => {
                  if (monitor?.monitor_warning !== "") {
                    return tooltipText(
                      monitor?.name,
                      monitor?.monitor_warning,
                      "dashboard-tooltip-li",
                    );
                  }
                })}
              </div>
            )}
          </Box>
        }
        arrow
        placement="bottom"
        classes={{ tooltip: classes.customWidth }}
      >
        <div>
          <ErrorOutlineIcon className="dashboard-header-warning" />
        </div>
      </Tooltip>
    );
  };

  let monitorStatus = [];

  return (
    <div className="view-monitor-detail">
      <div className="view-monitor-dasboard">
        <div className="view-monitor-header">
          <BreadCrumb links={links} />
        </div>
        <div className="display-in-row date-margin">
          {showFilterBtn && (
            <>
              <Filter
                isDashboardFilter
                {...props}
                filterButtonID={"dashboard-header-filter-btn"}
              />
              <Divider orientation="vertical" />
            </>
          )}
          <DateRangePicker
            {...props}
            openCustomDateLeft={true}
            datePickerPosition="monitorheader-datepicker-position"
            id="date-range-picker-dashboard-header"
            monitorActiveTabId="dashboard-header"
          />
        </div>
      </div>
      <div
        className={`display-in-row ${
          showFilterBtn ? "header-with-filter" : "header-without-filter"
        }`}
      >
        {dashboardDynamicData?.monitors?.data?.map((monitor) => {
          monitor?.attributes?.monitor_warning !== "" &&
            monitorStatus.push(monitor.attributes);
        })}
        {monitorStatus?.length !== 0 && showWarnings(monitorStatus)}
        <div className="main-pages-header">
          {CheckValueLocale(props.dashboardActiveTab, "", {}, intl)}
        </div>
        <Tooltip
          title={
            <div>
              {monitorsLength === 1 ? (
                dashboardDynamicData?.monitors?.data?.map((monitor) => {
                  return warningTooltip(monitor, "dashboard-tooltip");
                })
              ) : (
                <div className="dashboard-tooltip-ul">
                  {dashboardDynamicData?.monitors?.data?.map((monitor) => {
                    return warningTooltip(monitor, "dashboard-tooltip-li");
                  })}
                </div>
              )}
            </div>
          }
          arrow
          placement="bottom"
          classes={{ tooltip: classes.customWidth }}
        >
          <div className="header-monitors">
            ({monitorsLength} {CheckValueLocale("monitors", "", {}, intl)})
          </div>
        </Tooltip>
      </div>
      <Divider />
    </div>
  );
};
export default DashboardHeader;
