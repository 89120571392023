import { useEffect, useState } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import "./insightsSideMenu.scss";
import { List, AccordionDetails, Divider, Box } from "@mui/material";
import {
  DrawerContainer,
  AccordionSummaryCon,
  ListItemWrapper,
  ListItemTextBtn,
  AccordionWrapper,
} from "./SideMenu.styles";
//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faIntercom,
  faFacebookF,
  faInstagram,
  faWhatsapp,
  faFacebookMessenger,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faComments,
  faShareAlt,
  faChartColumn,
  faStar,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { faEnvelope as faEnvelopeSolid } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Gmail } from "images/social-icons/Gmail_icon.svg";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import InsightsController from "services/controllers/insightsController";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import publicTikTok from "images/shared-images/public-tikTok.svg";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
import privateMessenger from "images/social-icons/facebook-private-icon.svg";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import GoogleMyBusiness from "images/shared-images/gmb.svg";
import Genesys from "images/shared-images/genesys-icon.svg";
import { isEmptyArray } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers/index";

const InsightsSideMenu = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const [activeCategories, setActiveCategories] = useState([]);
  const [channels, setChannels] = useState({});
  const [socialLink, setSocialLInk] = useState("");
  const [chatLink, setChatLInk] = useState("");
  const [emailLink, setGmailLInk] = useState("");
  const [gmbLink, setGMBLInk] = useState("");
  const [genesysLink, setGenesysLInk] = useState("");

  useEffect(() => {
    getSidebarCategories();
  }, []);

  const getSidebarCategories = () => {
    CompaniesController.getSidebarCategories().then((data) => {
      let activeCategories = [];
      data?.data?.forEach((element) => {
        activeCategories.push(element.name);
        getDataSourcesForCategory(element);
        setActiveCategories(activeCategories);
      });
    });
  };

  let channel = {
    socialmedia: [],
    emails: [],
    chat: [],
    calls: [],
    ratings: [],
  };

  const getDataSourcesForCategory = (activeCategory) => {
    switch (activeCategory?.name) {
      case "Social Media":
        activeCategory?.data_sources?.map((source) => {
          if (source?.source === "TWITTER_PUBLIC") {
            channel["socialmedia"].push({
              className: "twitter__channel-insights",
              link: `/insights/social-media/x-platform-channel/${source.id}`,
              icon: (
                <img src={xPlatform} alt="x-platform-logo" width={"19px"} />
              ),
              interactionsLink: `/interactions/social-media/x-platform-public/${source.id}`,
            });
            setSocialLInk(
              `/insights/social-media/x-platform-channel/${source.id}`,
            );
          } else if (source?.source === "TWITTER_PRIVATE") {
            channel["socialmedia"].push({
              className: "twitter__channel-insights",
              link: `/insights/social-media/x-platform-private-channel/${source.id}`,
              icon: <FontAwesomeIcon icon={faEnvelopeSolid} />,
              interactionsLink: `/interactions/social-media/x-platform-private/${source.id}`,
            });
            setSocialLInk(
              `/insights/social-media/x-platform-private-channel/${source.id}`,
            );
          } else if (source?.source === "FACEBOOK_PUBLIC") {
            channel["socialmedia"].push({
              className: "facebbok__channel-insights",
              link: `/insights/social-media/facebook-channel/${source.id}`,
              icon: (
                <img src={publicFacebook} alt="icon-twitter" width={"18px"} />
              ),
              interactionsLink: `/interactions/social-media/facebook-public/${source.id}`,
            });
            setSocialLInk(
              `/insights/social-media/facebook-channel/${source.id}`,
            );
          } else if (source?.source === "FACEBOOK_PRIVATE") {
            channel["socialmedia"].push({
              className: "facebbok__channel-insights",
              link: `/insights/social-media/facebook-private-channel/${source.id}`,
              icon: (
                <img
                  src={privateMessenger}
                  alt="icon-messenger"
                  width={"23px"}
                />
              ),
              interactionsLink: `/interactions/social-media/facebook-private/${source.id}`,
            });
            setSocialLInk(
              `/insights/social-media/facebook-private-channel/${source.id}`,
            );
          } else if (source?.source === "INSTAGRAM_PUBLIC") {
            channel["socialmedia"].push({
              className: "instagram__channel-insights",
              link: `/insights/social-media/instagram-channel/${source.id}`,
              icon: (
                <img src={publicInstagram} alt="icon-twitter" width={"16px"} />
              ),
              interactionsLink: `/interactions/social-media/instagram-public/${source.id}`,
            });
            setSocialLInk(
              `/insights/social-media/instagram-channel/${source.id}`,
            );
          } else if (source?.source === "INSTAGRAM_PRIVATE") {
            channel["socialmedia"].push({
              className: "instagram__channel-insights",
              link: `/insights/social-media/instagram-private-channel/${source.id}`,
              icon: <FontAwesomeIcon icon={faFacebookMessenger} />,
              interactionsLink: `/interactions/social-media/instagram-private/${source.id}`,
            });
            setSocialLInk(
              `/insights/social-media/instagram-private-channel/${source.id}`,
            );
          } else if (source?.source === "LINKEDIN_PUBLIC") {
            channel["socialmedia"].push({
              className: "linkedIn__channel-insights",
              link: `/insights/social-media/linkedin-channel/${source.id}`,
              icon: <FontAwesomeIcon icon={faLinkedinIn} />,
              interactionsLink: `/interactions/social-media/linkedin-channel/${source.id}`,
            });
            setSocialLInk(
              `/insights/social-media/linkedin-channel/${source.id}`,
            );
          } else if (source?.source === "TIKTOK_PUBLIC") {
            channel["socialmedia"].push({
              className: "tiktok__channel-insights",
              link: `/insights/social-media/tikTok-channel/${source?.id}`,
              icon: <img src={publicTikTok} alt="icon-tikTok" width={"15px"} />,
              interactionsLink: `/interactions/social-media/tikTok-channel/${source?.id}`,
            });
            setSocialLInk(
              `/insights/social-media/tikTok-channel/${source?.id}`,
            );
          }
        });
        //check if returned one datasource or not
        if (channel?.socialmedia?.length !== 1) {
          setSocialLInk("/insights/social-media/");
        }
        break;
      case "chats":
        activeCategory.data_sources.map((source) => {
          if (source?.source === "INTERCOM") {
            channel["chat"].push({
              className: "chat__wrapper",
              link: `/insights/chat/intercom/${source.id}`,
              icon: (
                <FontAwesomeIcon icon={faIntercom} className="intercom__icon" />
              ),
              interactionsLink: `/interactions/chat/intercom/${source.id}`,
            });
            setChatLInk(`/insights/chat/intercom/${source.id}`);
          } else if (source?.source === "WHATSAPP") {
            channel["chat"].push({
              className: "chat__wrapper",
              link: `/insights/chat/whatsapp/${source.id}`,
              icon: (
                <FontAwesomeIcon icon={faWhatsapp} className="whatsapp__icon" />
              ),
              interactionsLink: `/interactions/chat/whatsapp/${source.id}`,
            });
            setChatLInk(`/insights/chat/whatsapp/${source.id}`);
          }
        });

        //check if returned one datasource or not
        if (channel?.chat?.length !== 1) {
          setChatLInk("/insights/chat/");
        }
        break;
      case "emails":
        activeCategory.data_sources.map((source) => {
          if (source?.source === "GMAIL") {
            channel["emails"].push({
              className: "email__wrapper",
              link: `/insights/email/gmail/${source.id}`,
              icon: <Gmail height={"22px"} />,
              interactionsLink: `/interactions/email/gmail/${source.id}`,
            });
            setGmailLInk(`/insights/email/gmail/${source.id}`);
          }
        });
        //check if returned one datasource or not
        if (channel?.emails?.length !== 1) {
          setGmailLInk("/insights/email/");
        }
        break;

      case "Calls":
        activeCategory?.data_sources?.map((source) => {
          if (source?.source === "GENESYS") {
            channel["calls"].push({
              className: "calls__wrapper",
              link: `/insights/calls/genesys/${source?.id}`,
              icon: <img src={Genesys} alt="icon-genesys" width={"16px"} />,
              interactionsLink: `/interactions/calls/genesys/${source?.id}`,
              subLink: "calls/genesys",
            });
            setGenesysLInk(`/insights/calls/genesys/${source?.id}`);
          }
        });
        //check if returned one datasource or not
        if (channel?.calls?.length !== 1) {
          setGenesysLInk("/insights/calls/");
        }
        break;

      case "Ratings":
        activeCategory.data_sources.map((source) => {
          if (source?.source === "GOOGLEMYBUSINESS") {
            channel["ratings"].push({
              className: "ratings__wrapper",
              link: `/insights/ratings/google-my-business/${source.id}`,
              icon: (
                <img
                  src={GoogleMyBusiness}
                  alt="icon-google-my-business"
                  width={"20px"}
                />
              ),
              interactionsLink: `/interactions/ratings/google-my-business/${source.id}`,
            });
            setGMBLInk(`/insights/ratings/google-my-business/${source.id}`);
          }
        });
        //check if returned one datasource or not
        if (channel?.ratings?.length !== 1) {
          setGMBLInk("/insights/ratings/");
        }
        break;
      default:
        break;
    }

    setActiveSidebar(channel);
    setChannels(channel);
  };

  const setActiveSidebar = (channel) => {
    if (channel) {
      if (channel.socialmedia) {
        if (channel.socialmedia.length > 0) {
          if (window.location.pathname.includes("social-media")) {
            setactiveAcordion(2);
          }
        }
      }
      if (channel.chat) {
        if (channel["chat"].length > 0) {
          if (window.location.pathname.includes("chat")) {
            setactiveAcordion(4);
          }
        }
      }
      if (channel.emails) {
        if (channel.emails.length > 0) {
          if (window.location.pathname.includes("email")) {
            setactiveAcordion(3);
          }
        }
      }

      if (channel.ratings) {
        if (channel.ratings.length > 0) {
          if (window.location.pathname.includes("ratings")) {
            setactiveAcordion(5);
          }
        }
      }
      if (channel?.calls) {
        if (channel?.calls?.length > 0) {
          if (window.location.pathname?.includes("calls")) {
            setactiveAcordion(6);
          }
        }
      }

      if (window.location.pathname === "/insights/main") {
        setactiveAcordion(1);
      }
    }
  };

  const [activeAcordion, setactiveAcordion] = useState(0);
  const isIconActive = (iconPath, interactionsLink, subLink) => {
    return (
      iconPath === pathname ||
      interactionsLink === pathname ||
      pathname?.includes(subLink)
    );
  };
  // To check each data category has only one datasource or not
  const hasDatasourceOnly = (dataCategory) => {
    let hasDatasourceOnly;
    if (dataCategory?.length === 1) {
      hasDatasourceOnly = true;
    } else {
      hasDatasourceOnly = false;
    }
    localStorage.setItem("has_datasource_only", hasDatasourceOnly);
  };

  useEffect(() => {
    window.location.pathname === "/insights/main"
      ? setactiveAcordion(1)
      : window.location.pathname.includes("social-media")
        ? setactiveAcordion(2)
        : window.location.pathname.includes("chat")
          ? setactiveAcordion(4)
          : window.location.pathname.includes("email")
            ? setactiveAcordion(3)
            : window.location.pathname.includes("calls")
              ? setactiveAcordion(6)
              : window.location.pathname.includes("ratings") &&
                setactiveAcordion(5);
  }, [window.location.href]);

  return (
    <div className="channel-analytics-sidemenu">
      {/* INSIGHTS SIDE MENU */}
      <DrawerContainer
        anchor={window.localStorage.lang !== "ar" ? "left" : "right"}
        open={true}
        variant="persistent"
        onClose={props.handleCloseInsightsDrawer}
        sx={{
          left: window.localStorage.lang !== "ar" ? "0" : "auto",
        }}
      >
        <>
          <List>
            {/* insights */}
            <ListItemWrapper button disableRipple>
              <AccordionWrapper
                expanded={activeAcordion === 1}
                onChange={() => setactiveAcordion(1)}
                sx={{ padding: "4px" }}
              >
                <AccordionSummaryCon className="insights-sidemenu">
                  <NavLink
                    to="/insights/main"
                    activeClassName="selected-route"
                    exact
                    style={{ textAlign: "center" }}
                    id={`channel-analytics-main-insights-tab`}
                  >
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className={activeAcordion === 1 ? "selected-route" : ""}
                    />
                    <ListItemTextBtn
                      primary={CheckValueLocale("analytics_tab", "", {}, intl)}
                    />
                  </NavLink>
                </AccordionSummaryCon>
              </AccordionWrapper>
            </ListItemWrapper>
            <Divider />
            {activeCategories?.map((i, index) => {
              if (i === "Social Media") {
                //  social media
                return (
                  <ListItemWrapper
                    button
                    // onClick={props.handleSocialAccordion}
                    focusVisible={false}
                    disableRipple
                    key={index}
                    className="category-tab"
                  >
                    <AccordionWrapper
                      expanded={activeAcordion === 2}
                      onChange={() => {
                        setactiveAcordion(2);
                        hasDatasourceOnly(channels?.socialmedia);
                      }}
                      sx={{ padding: "4px" }}
                      className={
                        channels.socialmedia &&
                        channels?.socialmedia?.length === 1 &&
                        "category-tab-box"
                      }
                      id={`channel-analytics-social-media-tab`}
                    >
                      <Link
                        to={channels.socialmedia && socialLink}
                        className={
                          channels.socialmedia &&
                          channels?.socialmedia?.length === 1 &&
                          "category-tab-link"
                        }
                      >
                        <AccordionSummaryCon className="insights-sidemenu">
                          <FontAwesomeIcon
                            icon={faShareAlt}
                            className={
                              activeAcordion === 2 ? "selected-route" : ""
                            }
                          />
                          <ListItemTextBtn
                            primary={CheckValueLocale("social", "", {}, intl)}
                          />
                        </AccordionSummaryCon>
                      </Link>
                      {!isEmptyArray(channels.socialmedia) && (
                        <Box
                          component="div"
                          className="social__box icon-category"
                        >
                          {channels.socialmedia?.map((channel, i) => {
                            return (
                              <Link to={channel.link}>
                                <AccordionDetails
                                  key={i}
                                  className={`${
                                    channel.className
                                  } social__insights ${
                                    isIconActive(
                                      channel.link,
                                      channel.interactionsLink,
                                    )
                                      ? "icon__active"
                                      : ""
                                  }`}
                                  id={`channel-analytics-social-media-${
                                    i ?? "channel-no"
                                  }-tab`}
                                >
                                  {channel.icon}
                                </AccordionDetails>
                              </Link>
                            );
                          })}
                        </Box>
                      )}
                    </AccordionWrapper>
                  </ListItemWrapper>
                );
              } else if (i === "chats") {
                //chat
                return (
                  <ListItemWrapper
                    button
                    disableRipple
                    className="category-tab"
                  >
                    <AccordionWrapper
                      expanded={activeAcordion === 4}
                      onChange={() => {
                        setactiveAcordion(4);
                        hasDatasourceOnly(channels?.chat);
                      }}
                      className={
                        channels.chat &&
                        channels?.chat?.length === 1 &&
                        "category-tab-box"
                      }
                      id={`channel-analytics-chats-tab`}
                    >
                      <Link
                        to={channels.chat && chatLink}
                        className={
                          channels.chat &&
                          channels?.chat?.length === 1 &&
                          "category-tab-link"
                        }
                      >
                        <AccordionSummaryCon className="insights-sidemenu">
                          <FontAwesomeIcon
                            icon={faComments}
                            className={
                              activeAcordion === 4 ? "selected-route" : ""
                            }
                          />
                          <ListItemTextBtn
                            primary={CheckValueLocale("chat", "", {}, intl)}
                          />
                        </AccordionSummaryCon>
                      </Link>
                      {!isEmptyArray(channels.chat) && (
                        <Box
                          component="div"
                          className="social__box icon-category"
                        >
                          {channels.chat?.map((channel, i) => {
                            return (
                              <Link to={channel.link}>
                                <AccordionDetails
                                  key={i}
                                  className={`${
                                    channel.className
                                  } social__insights ${
                                    isIconActive(
                                      channel.link,
                                      channel.interactionsLink,
                                    )
                                      ? "icon__active"
                                      : ""
                                  }`}
                                  id={`channel-analytics-chats-${
                                    i ?? "channel-no"
                                  }-tab`}
                                >
                                  {channel.icon}
                                </AccordionDetails>
                              </Link>
                            );
                          })}
                        </Box>
                      )}
                    </AccordionWrapper>
                  </ListItemWrapper>
                );
              } else if (i === "emails") {
                // email
                return (
                  <ListItemWrapper
                    button
                    disableRipple
                    className="category-tab"
                  >
                    <AccordionWrapper
                      expanded={activeAcordion === 3}
                      onChange={() => {
                        setactiveAcordion(3);
                        hasDatasourceOnly(channels?.emails);
                      }}
                      sx={{ padding: "4px" }}
                      className={
                        channels.emails &&
                        channels?.emails?.length === 1 &&
                        "category-tab-box"
                      }
                      id={`channel-analytics-emails-tab`}
                    >
                      <Link
                        to={channels.emails && emailLink}
                        className={
                          channels.emails &&
                          channels?.emails?.length === 1 &&
                          "category-tab-link"
                        }
                      >
                        <AccordionSummaryCon className="insights-sidemenu">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className={
                              activeAcordion === 3 ? "selected-route" : ""
                            }
                          />
                          <ListItemTextBtn
                            primary={CheckValueLocale("email", "", {}, intl)}
                          />
                        </AccordionSummaryCon>
                      </Link>
                      {!isEmptyArray(channels.emails) && (
                        <Box
                          component="div"
                          className="social__box icon-category"
                        >
                          {channels.emails?.map((channel, i) => {
                            return (
                              <Link to={channel.link}>
                                <AccordionDetails
                                  key={i}
                                  className={`social__insights ${
                                    isIconActive(
                                      channel.link,
                                      channel.interactionsLink,
                                    )
                                      ? "icon__active"
                                      : ""
                                  }`}
                                  id={`channel-analytics-emails-${
                                    i ?? "channel-no"
                                  }-tab`}
                                >
                                  {channel.icon}
                                </AccordionDetails>
                              </Link>
                            );
                          })}
                        </Box>
                      )}
                    </AccordionWrapper>
                  </ListItemWrapper>
                );
              } else if (i === "Calls") {
                return (
                  <ListItemWrapper
                    button
                    disableRipple
                    className="category-tab"
                  >
                    <AccordionWrapper
                      expanded={activeAcordion === 6}
                      onChange={() => {
                        setactiveAcordion(6);
                        hasDatasourceOnly(channels?.calls);
                      }}
                      sx={{ padding: "4px" }}
                      className={
                        channels.calls &&
                        channels?.calls?.length === 1 &&
                        "category-tab-box"
                      }
                      id={`channel-analytics-calls-tab`}
                    >
                      <Link
                        to={channels.calls && genesysLink}
                        className={
                          channels.calls &&
                          channels?.calls?.length === 1 &&
                          "category-tab-link"
                        }
                      >
                        <AccordionSummaryCon className="insights-sidemenu">
                          <FontAwesomeIcon
                            icon={faPhone}
                            className={`calls-category-icon ${
                              activeAcordion === 6 ? "selected-route" : ""
                            }`}
                          />
                          <ListItemTextBtn
                            primary={CheckValueLocale("calls", "", {}, intl)}
                          />
                        </AccordionSummaryCon>
                      </Link>
                      {!isEmptyArray(channels.calls) && (
                        <Box
                          component="div"
                          className="social__box icon-category"
                        >
                          {channels.calls?.map((channel, i) => {
                            return (
                              <Link to={channel.link}>
                                <AccordionDetails
                                  key={i}
                                  className={`social__insights ${
                                    isIconActive(
                                      channel.link,
                                      channel.interactionsLink,
                                      channel?.subLink,
                                    )
                                      ? "icon__active"
                                      : ""
                                  }`}
                                  id={`channel-analytics-calls-${
                                    i ?? "channel-no"
                                  }-tab`}
                                >
                                  {channel.icon}
                                </AccordionDetails>
                              </Link>
                            );
                          })}
                        </Box>
                      )}
                    </AccordionWrapper>
                  </ListItemWrapper>
                );
              } else if (i === "Ratings") {
                return (
                  <ListItemWrapper
                    button
                    disableRipple
                    className="category-tab"
                  >
                    <AccordionWrapper
                      expanded={activeAcordion === 5}
                      onChange={() => {
                        setactiveAcordion(5);
                        hasDatasourceOnly(channels?.ratings);
                      }}
                      sx={{ padding: "4px" }}
                      className={
                        channels.ratings &&
                        channels?.ratings?.length === 1 &&
                        "category-tab-box"
                      }
                      id={`channel-analytics-ratings-tab`}
                    >
                      <Link
                        to={channels.ratings && gmbLink}
                        className={
                          channels.ratings &&
                          channels?.ratings?.length === 1 &&
                          "category-tab-link"
                        }
                      >
                        <AccordionSummaryCon className="insights-sidemenu">
                          <FontAwesomeIcon
                            icon={faStar}
                            className={
                              activeAcordion === 5 ? "selected-route" : ""
                            }
                          />
                          <ListItemTextBtn
                            primary={CheckValueLocale("rating", "", {}, intl)}
                          />
                        </AccordionSummaryCon>
                      </Link>
                      {!isEmptyArray(channels.ratings) && (
                        <Box
                          component="div"
                          className="social__box icon-category"
                        >
                          {channels.ratings?.map((channel, i) => {
                            return (
                              <Link to={channel.link}>
                                <AccordionDetails
                                  key={i}
                                  className={`social__insights ${
                                    isIconActive(
                                      channel.link,
                                      channel.interactionsLink,
                                    )
                                      ? "icon__active"
                                      : ""
                                  }`}
                                  id={`channel-analytics-ratings-${
                                    i ?? "channel-no"
                                  }-tab`}
                                >
                                  {channel.icon}
                                </AccordionDetails>
                              </Link>
                            );
                          })}
                        </Box>
                      )}
                    </AccordionWrapper>
                  </ListItemWrapper>
                );
              }
            })}
          </List>
        </>
      </DrawerContainer>
    </div>
  );
};
export default InsightsSideMenu;
