import React, { useEffect, useState } from "react";
import { Box, Switch, TextField } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../surveyBuilder.scss";

const Number = (props) => {
  const {
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);
  const [switchReq, setSwitchReq] = useState(false);
  const [switchMin, setSwitchMin] = useState(false);
  const [switchMax, setSwitchMax] = useState(false);
  const [minNumber, setMinNumber] = useState(0);
  const [maxNumber, setMaxNumber] = useState(0);

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setSwitchReq(question?.constraints?.required);
      setSwitchMin(question?.constraints?.min_number["checked"]);
      setMinNumber(question?.constraints?.min_number["value"]);
      setSwitchMax(question?.constraints?.max_number["checked"]);
      setMaxNumber(question?.constraints?.max_number["value"]);
    }
  }, [question]);

  const handleRequiredSwitch = (e) => {
    setSwitchReq(e.target.checked);
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints["required"] = e.target.checked;
    setSurveySections([...surveySections]);
  };

  const handleMinSwitch = (e, value) => {
    if (e) {
      if (e.target.checked === true) {
        setSwitchMin(e.target.checked);
        setSurveySections([...surveySections]);
      } else {
        setSwitchMin(e.target.checked);
        setMinNumber("");
        filteredSections[sectionIndex].questions.filter(
          (question) => question?.is_deleted !== true,
        )[questionIndex].question.constraints["min_number"] = {
          checked: e.target.checked,
          value: "0",
        };
        setSurveySections([...surveySections]);
      }
    } else {
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints["min_number"] = {
        value: value ? value : "0",
        checked: true,
      };
      setSurveySections([...surveySections]);
    }
  };

  const handleMaxSwitch = (e, value) => {
    if (e) {
      if (e.target.checked === true) {
        setSwitchMax(e.target.checked);
        setSurveySections([...surveySections]);
      } else {
        setSwitchMax(e.target.checked);
        setMaxNumber("");
        filteredSections[sectionIndex].questions.filter(
          (question) => question?.is_deleted !== true,
        )[questionIndex].question.constraints["max_number"] = {
          value: "0",
          checked: e.target.checked,
        };
        setSurveySections([...surveySections]);
      }
    } else {
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints["max_number"] = {
        value: value,
        checked: true,
      };
      setSurveySections([...surveySections]);
    }
  };

  const handleValues = (e, type) => {
    if (e.target.value >= 0 && e.target.value < 999999) {
      if (type === "min") {
        setMinNumber(e.target.value);
        handleMinSwitch("", e.target.value);
      } else {
        setMaxNumber(e.target.value);
        handleMaxSwitch("", e.target.value);
      }
    }
  };

  return (
    <>
      <Box
        className={`constraints-text ${editable === false && `disabled-color`}`}
      >
        <Box>{CheckValueLocale("required", "", {}, intl)}</Box>
        <Switch
          data-testid="required_switch"
          onChange={(e) => handleRequiredSwitch(e)}
          checked={switchReq}
        />
      </Box>
      <Box
        className={`constraints-text ${editable === false && `disabled-color`}`}
      >
        <Box>{CheckValueLocale("min_number", "", {}, intl)}</Box>
        <Switch
          data-testid="required_switch"
          onChange={(e) => handleMinSwitch(e)}
          checked={switchMin}
          disabled={editable === false ? true : false}
        />
      </Box>
      {switchMin && (
        <Box className="max-chars-box">
          <TextField
            disabled={editable === false ? true : false}
            id="outlined-basic"
            name="min_chars"
            placeholder="0-999999"
            className="min-chars"
            type="number"
            onChange={(e) => handleValues(e, "min")}
            value={minNumber !== 0 ? minNumber : ""}
          />
        </Box>
      )}
      <Box
        className={`constraints-text ${editable === false && `disabled-color`}`}
      >
        <Box>{CheckValueLocale("max_number", "", {}, intl)}</Box>
        <Switch
          data-testid="required_switch"
          onChange={handleMaxSwitch}
          checked={switchMax}
          disabled={editable === false ? true : false}
        />
      </Box>
      {switchMax && (
        <Box className="max-chars-box">
          <TextField
            disabled={editable === false ? true : false}
            id="outlined-basic"
            name="max_chars"
            placeholder="0-999999"
            className="max-chars"
            type="number"
            value={maxNumber !== 0 ? maxNumber : ""}
            onChange={(e) => handleValues(e, "max")}
          />
        </Box>
      )}
    </>
  );
};

export default Number;
