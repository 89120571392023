import React, { useEffect, useState } from "react";
import { Box, Divider, Menu, Tooltip } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import excelImage from "images/excel-icon.svg";
import pdfImage from "images/pdf-icon.svg";
import DownloadIcon from "@mui/icons-material/Download";
import { ArrowDropDown } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import SnackBar from "components/snackBar";
import exportPDF from "./pdf/exportPDF";
import exportExcel from "./excel/exportExcel";
import "./exportButton.scss";

const ExportButton = (props) => {
  const intl = useIntl();
  const [isSafari, setIsSafari] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    msg: "",
    severity: "success",
  });
  const [anchorExport, setAnchorExport] = useState(null);
  const openExport = Boolean(anchorExport);
  let menuDirection = intl?.locale === "en" ? "right" : "left";

  const [selectedLanguage, setSelectedLanguage] = useState({
    id: intl?.locale === "en" ? "english" : "arabic",
    name: intl?.locale,
    disabled: false,
  });
  const [selectedType, setSelectedType] = useState({
    id: "pdf",
    name: "pdf",
    disabled: false,
  });
  const [monitorsNames, setMonitorsNames] = useState([]);
  const [fileName, setFileName] = useState("");
  const [pdfLoading, setPdfLoading] = useState(false);

  const dropDownTypes = [
    {
      label: "type",
      options: [
        { id: "pdf", name: "pdf", disabled: isSafari },
        { id: "excel", name: "excel", disabled: false },
      ],
      disabled: false,
    },
    {
      label: "language",
      options: [
        {
          id: "english",
          name: "en",
          disabled:
            selectedType?.name === "pdf" && intl?.locale !== "en"
              ? true
              : false,
        },
        {
          id: "arabic",
          name: "ar",
          disabled:
            selectedType?.name === "pdf" && intl?.locale !== "ar"
              ? true
              : false,
        },
      ],
      disabled: false,
    },
  ];

  const exportText = (type) => {
    const text = {
      pdf: (
        <Box className="export-file">
          <img src={pdfImage} alt="pdf" />
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      ),
      excel: (
        <Box className="export-file">
          <img src={excelImage} alt="excel" />
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      ),
      default: CheckValueLocale(type, "", {}, intl),
    };
    return text[type] || text["default"];
  };

  const handleOpenExport = (event) => {
    if (anchorExport !== event?.currentTarget)
      setAnchorExport(event?.currentTarget);
  };
  const handleCloseExport = () => setAnchorExport(null);

  const handleChecked = (type, val) => {
    let checked = false;
    if (type === "language") checked = selectedLanguage?.name === val;
    else checked = selectedType?.name === val;
    return checked;
  };

  const handleSelection = (label, option) => {
    label === "language" && setSelectedLanguage(option);
    label === "type" && setSelectedType(option);
  };

  useEffect(() => {
    if (selectedType?.name === "pdf" && selectedLanguage?.name !== intl?.locale)
      setSelectedLanguage({
        id: intl?.locale === "en" ? "english" : "arabic",
        name: intl?.locale,
        disabled: false,
      });
  }, [selectedType, selectedLanguage, intl?.locale]);

  const handleFileName = (monitors) => {
    const handleMonitorsNames = (monitors) => {
      if (monitors?.length > 1) {
        if (monitors?.length > 2)
          return `${monitors?.slice(0, 2)?.join("_")}(+${
            monitors?.length - 2
          })`;
        else return monitors?.join("_");
      } else return monitors?.toString();
    };

    let fileTitle = "";
    if (props?.monitorPublicType) {
      if (props?.monitorPublicType === "Monitor_vs_Monitors") {
        if (props?.typeTab === "cxm")
          fileTitle = `Channel_vs_Channel_${handleMonitorsNames(monitors)}`;
        else fileTitle = `Monitor_vs_Monitor_${handleMonitorsNames(monitors)}`;
      } else
        fileTitle = `${props?.monitorPublicType}_${monitors[0]?.replace(
          /\s/g,
          "",
        )}`;
    }

    return setFileName(fileTitle);
  };

  useEffect(() => {
    if (props?.benchmarkHeadData?.monitorData) {
      let tempArr = [];
      props?.benchmarkHeadData?.monitorData?.map((item) => {
        if (props?.monitorPublicType !== "Monitor_vs_Monitors")
          tempArr?.push(item?.monitor_name);
        else tempArr?.push(item?.monitor_name?.split(" ")[0]?.substring(0, 7));
      });
      setMonitorsNames(tempArr);
    }
  }, [props?.benchmarkHeadData?.monitorData]);

  useEffect(() => {
    if (monitorsNames?.length > 0) handleFileName(monitorsNames);
  }, [monitorsNames]);

  const handleClickExport = () => {
    if (selectedType?.name === "pdf")
      exportPDF(props, fileName, intl, setSnackBar, setPdfLoading);
    else
      exportExcel(props, fileName, selectedLanguage?.name, intl, setSnackBar);
  };

  useEffect(() => {
    if (
      /^(?! chrome|android).* safari/i.test(navigator?.userAgent) &&
      window?.safari !== undefined
    ) {
      setIsSafari(true);
      setSelectedLanguage({
        id: intl?.locale === "en" ? "english" : "arabic",
        name: intl?.locale,
        disabled: false,
      });
      setSelectedType({ id: "excel", name: "excel", disabled: false });
    } else setIsSafari(false);
  }, [navigator.userAgent, window.safari]);

  return (
    <Box>
      <LucButton
        variant="outline"
        aria-owns={anchorExport}
        aria-haspopup="true"
        aria-expanded={openExport}
        onClick={handleOpenExport}
        startIcon={<DownloadIcon />}
        endIcon={<ArrowDropDown />}
      >
        {CheckValueLocale("export", "", {}, intl)}
      </LucButton>
      <Menu
        id="export-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
          onMouseLeave: handleCloseExport,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuDirection,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuDirection,
        }}
        anchorEl={anchorExport}
        open={openExport}
        onClose={handleCloseExport}
      >
        <Box className="tabs-container">
          <Box className="export-options-title">
            {CheckValueLocale("export_options", "", {}, intl)}
          </Box>
          <Box className="radio-item export-item">
            {dropDownTypes?.map((type, index) => {
              return (
                <Box key={index}>
                  <Box className={`export-label ${type?.label}`}>
                    {type?.options?.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {!val?.disabled ? (
                            <label className="radio-item">
                              <input
                                type="radio"
                                name={`export-${type?.label}`}
                                onClick={() =>
                                  handleSelection(type?.label, val)
                                }
                                checked={handleChecked(type?.label, val?.name)}
                              />
                              <Box component="span" className="export-choose">
                                {exportText(val?.name)}
                              </Box>
                            </label>
                          ) : (
                            val?.id === "pdf" && (
                              <label className="radio-item">
                                <input
                                  type="radio"
                                  name={`export-${type?.label}`}
                                  onClick={() =>
                                    handleSelection(type?.label, val)
                                  }
                                  checked={handleChecked(
                                    type?.label,
                                    val?.name,
                                  )}
                                  disabled={true}
                                />
                                <Tooltip
                                  placement="top-end"
                                  arrow
                                  title={CheckValueLocale(
                                    "pdf_export_disabled_tooltip",
                                    "",
                                    {},
                                    intl,
                                  )}
                                >
                                  <Box
                                    component="span"
                                    className="export-choose disabled-choice"
                                  >
                                    {exportText(val?.name)}
                                  </Box>
                                </Tooltip>
                              </label>
                            )
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
            <Divider />
            <LucButton
              id="export-file-btn"
              variant="filled"
              loading={
                !props?.showPDFBtn ||
                (selectedType?.name === "pdf" && pdfLoading)
              }
              loadingPosition="start"
              disableRipple
              onClick={handleClickExport}
            >
              {CheckValueLocale("export_file", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </Menu>
      <SnackBar
        autoHideDuration={5000}
        message={snackBar?.message}
        title={snackBar?.title}
        open={snackBar?.open}
        severity={snackBar?.severity}
        handleClose={() => setSnackBar({ open: false, title: "", msg: "" })}
      />
    </Box>
  );
};

export default ExportButton;
