import { useState } from "react";
import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PopupModal from "components/popupModal.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import "./optionsDropDown.scss";
import { useHistory } from "react-router-dom";
import SnackBar from "components/snackBar";
import AudienceController from "../../../services/controllers/audienceController";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();
  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const [success, setSuccess] = useState(null);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const open = Boolean(stats.anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setStats({
      ...stats,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };

  const handleOpenDeleteModal = () => {
    const user_name = props.username;
    setStats({
      openDeleteModal: true,
      addClasses: "model-buttons",
      title: `${CheckValueLocale("delete_user_segment", "", {}, intl)}`,
      body: (
        <Box className="shared-dialog-body">
          <p>
            {CheckValueLocale(
              "are_you_sure_delete_user",
              "",
              {
                name: user_name
                  ? user_name
                  : CheckValueLocale("no_name", "", {}, intl),
              },
              intl,
            )}
          </p>

          <h2 className="sub-text-desc">
            {CheckValueLocale("deleting_msg", "", {}, intl)}
          </h2>
        </Box>
      ),
      modalRightBtn: CheckValueLocale("delete_confirm", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      warning: true,
    });
  };

  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
  };

  const handleAcceptDeleteModal = () => {
    setButtonLoading(true);
    AudienceController.deleteProfile(props.id).then((data) => {
      if (data.data.status === 204 || data.data.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(
          intl.formatMessage(
            { id: "remove_pofileuser" },
            {
              name: CheckValueLocale(
                props.username ? props.username : "no_name",
                "",
                {},
                intl,
              ),
            },
          ),
        );
        setSuccessStatus("success");
        showSnackBar("delete", "success");
        setTimeout(() => {
          props.getAudience(
            props.startDate,
            props.endDate,
            props?.filters,
            props?.page,
          );
        }, 3000);
      } else {
        setSuccess(true);
        setSuccessStatus("error");
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(CheckValueLocale("wrong_msg", "", {}, intl));
        setSnackBarTitle(CheckValueLocale("fail_req", "", {}, intl));
        showSnackBar("delete", "warning");
        setTimeout(() => {
          props.getAudience(
            props.startDate,
            props.endDate,
            props?.filters,
            props?.page,
          );
        }, 3000);
      }
      setButtonLoading(false);
    });
  };

  const showSnackBar = (action, severity) => {
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      openDeleteModal: false,
      openPauseResumeModal: false,
      snackBarMsg: "",
    });
    window.scrollTo(0, 0);
    if (action === "delete" && severity === "success") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: intl.formatMessage(
          { id: "remove_pofileuser" },
          {
            name: CheckValueLocale(
              props.username ? props.username : "no_name",
              "",
              {},
              intl,
            ),
          },
        ),
      });
    } else if (action === "delete" && severity === "warning") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: CheckValueLocale("wrong_msg", "", {}, intl),
        snackBarTitle: CheckValueLocale("fail_req", "", {}, intl),
      });
    }
  };

  return (
    <div className="menu-list">
      {(handlelUserRoles("CDP", "DESTROY_PROFILE") ||
        handlelUserRoles("CDP", "VIEW_PROFILE")) && (
        <CustomizedTooltip
          id="menu-item-list-tooltip"
          title={
            <Box className="item-title-tooltip">
              {CheckValueLocale("items_ellipsis", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <IconButton
            aria-label="more"
            id="audience-manage-audience-long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            onClose={handleClose}
            className={
              "menu-items menu-item-container " +
              (open ? "menu-item-active" : "")
            }
          >
            <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
          </IconButton>
        </CustomizedTooltip>
      )}

      {(handlelUserRoles("CDP", "DESTROY_PROFILE") ||
        handlelUserRoles("CDP", "VIEW_PROFILE")) && (
        <Menu
          id="audience-menu-dropdown"
          MenuListProps={{
            "aria-labelledby": "monitor-menu-button",
          }}
          anchorEl={stats.anchorEl}
          open={open}
          onClose={handleClose}
        >
          {handlelUserRoles("CDP", "VIEW_PROFILE") && (
            <MenuItem
              onClick={() => {
                history.push(`/audience/user-profile/${props.id}`);
              }}
              disableRipple
              id={`audience-menu-user-profile${props?.id ?? "id"}`}
            >
              <PermIdentityIcon />
              {CheckValueLocale("user_profile", "", {}, intl)}
            </MenuItem>
          )}
          {handlelUserRoles("CDP", "DESTROY_PROFILE") && (
            <MenuItem
              onClick={handleOpenDeleteModal}
              disableRipple
              id={`audience-menu-delete${props?.id ?? "id"}`}
            >
              <DeleteOutlineOutlinedIcon />
              {CheckValueLocale("delete", "", {}, intl)}
            </MenuItem>
          )}
        </Menu>
      )}

      {stats.openDeleteModal && (
        <PopupModal
          title={stats.title}
          body={stats.body}
          leftBtn={stats.modalLeftBtn}
          rightBtn={stats.modalRightBtn}
          open={stats.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={() => handleAcceptDeleteModal(props?.id)}
          warning={stats.warning}
          leftBtnId="audience-pop-up-cancel-left-btn"
          rightBtnId="audience-pop-up-delete-right-btn"
          minWidth="600px"
          classeName="actions-buttons-shared"
          isRightBtnLoading={buttonLoading}
          disabled={buttonLoading}
        />
      )}

      {success && (
        <SnackBar
          open={success != null ? true : false}
          title={snackBarTitle}
          severity={successStatus}
          message={snackBarMsg}
          showCloseBtn={true}
        />
      )}
    </div>
  );
};
export default OptionsDropDown;
