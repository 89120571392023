import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import MonitorsController from "services/controllers/monitorsController";
import { useIntl } from "react-intl";
import "./testYourKeyword.scss";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import TestKeywordShowMoreLess from "./components/testKeywordShowMoreLess";
import TestKeywordResultTesting from "./components/testKeywordResultTesting";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const TestYourKeyword = (props) => {
  const intl = useIntl();
  let { keywords, spamKeywords } = props;

  const [open, setOpen] = useState(false);
  const [testText, setTestText] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [keywordResult, setKeywordResult] = useState("");
  const [spamResult, setSpamResult] = useState("");

  // ____________________________________________________________________

  const handleClickOpen = () => {
    setOpen(true);
    setSpamResult("");
    setKeywordResult("");
  };

  const handleClose = () => {
    setTestText("");
    setLoading(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    setTestText(event.target.value);
  };

  const checkKeywordsLimit = (arr) => {
    let newArr = [];
    arr.map((key) => {
      if (key.length <= 60) {
        newArr.push(key);
      }
    });
    return newArr;
  };

  const handleTest = () => {
    setLoading(true);

    let newTestText = testText;
    newTestText = newTestText.replace(
      /[`§±!\$%&\*\\\+=\[\]{};:\\\|~<>\/\?\.؛,؟،]/g,
      " ",
    );

    let limitedKeywords = checkKeywordsLimit(keywords),
      limitedSpamKeywords = checkKeywordsLimit(spamKeywords);

    let newKeywords =
        limitedKeywords.toString() !== "" ? limitedKeywords.toString() : "",
      newSpamKeywords =
        limitedSpamKeywords.toString() !== ""
          ? limitedSpamKeywords.toString()
          : "";

    MonitorsController.testKeywords(
      newKeywords,
      newSpamKeywords,
      newTestText,
    ).then((data) => {
      setSpamResult(data?.data?.spam);
      setKeywordResult(data?.data?.keyword);
      setLoading(false);
    });
  };

  const handleAnotherBtn = () => {
    setSpamResult("");
    setKeywordResult("");
  };

  return (
    <Box className="test-keyword-box">
      <Box style={{ right: "6px" }}>
        <LucButton
          variant="outline"
          size="small"
          onClick={handleClickOpen}
          id="monitor-options-test-keyword-btn"
        >
          {CheckValueLocale("test_keyword", "", {}, intl)}
        </LucButton>
      </Box>

      <Dialog
        id="testKeywords"
        className="test-keyword-dialog"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className="keyword-tester-title">
          {CheckValueLocale("keywords_tester", "", {}, intl)}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box className="test-keyword-content">
          <DialogContent
            className={
              isLoading === true
                ? "testkeywordsLoaderBox testkeywordDialogContent"
                : spamResult !== "" && keywordResult !== ""
                  ? "testkeyword-dialog-content-with-msg"
                  : "testkeywordDialogContent"
            }
          >
            {spamResult !== "" || (false && keywordResult !== "") ? (
              // Result Message
              <TestKeywordResultTesting
                spam={spamResult}
                keyword={keywordResult}
              />
            ) : (
              // {/* test your keyword Show More Less */}
              <TestKeywordShowMoreLess {...props} />
            )}

            {/* input Field */}
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              className={
                isLoading === true
                  ? "dialogStatusLoading testkeywordDialogTextField"
                  : "testkeywordDialogTextField"
              }
              value={testText}
              onChange={handleChange}
              placeholder={CheckValueLocale("type_text_keyword", "", {}, intl)}
              disabled={isLoading}
            />
          </DialogContent>

          <DialogActions className="text-keyword-btns-actions">
            <Button
              color="inherit"
              variant="outlined"
              className="test-keyword-dialog-cancel-btn"
              onClick={handleClose}
              id="monitor-options-test-keyword-done-btn"
            >
              {CheckValueLocale("done", "", {}, intl)}
            </Button>
            {spamResult !== "" && keywordResult !== "" ? (
              <Button
                variant="contained"
                className={"dialogStatusLoadingBtn try-another-btn"}
                onClick={handleAnotherBtn}
                id="monitor-options-test-keyword-try-another-post-btn"
              >
                {CheckValueLocale("try_another_post", "", {}, intl)}
              </Button>
            ) : (
              <Button
                variant="contained"
                className={
                  isLoading === true
                    ? "dialogStatusLoadingBtn dialogStatusLoading testkeywordDialogTestBtn"
                    : "testkeywordDialogTestBtn"
                }
                onClick={handleTest}
                disabled={
                  testText === "" ||
                  testText === null ||
                  isLoading ||
                  (!keywords?.length && !spamKeywords?.length)
                }
                id="monitor-options-test-btn"
              >
                {isLoading === true ? (
                  <Box className="keyword-loading">
                    <CircularProgress />
                  </Box>
                ) : (
                  CheckValueLocale("test", "", {}, intl)
                )}
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default TestYourKeyword;
