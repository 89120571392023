import { useMutation, useQueryClient } from "@tanstack/react-query";
import ThemesController from "services/controllers/themesController";

export const useReorderThemes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => ThemesController.reorderThemes({ data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["themes"],
      });
    },
  });
};
