import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import OverView from "components/widgets/overViewWidget.js";
import { handleArraySum, aggregate } from "./insightsCommonFunctions";
import moment from "moment";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import { getNoOfDays } from "utils/helpers";
import _ from "lodash";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";

const InsightsBody = (props) => {
  const intl = useIntl();
  let {
    response,
    monitorId,
    preLoaderTrackerDataSources,
    startDate,
    endDate,
    dialectsSubDialectsData,
    setExcelSheetData,
  } = props;
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsArray, setTopkeywordsArray] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [themeData, setThemeData] = useState({});
  const [interactionsArray, setInteractionsArray] = useState([]);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [avgInteractions, setAvgInteraction] = useState(0);
  const [sentimentOvertime, setSentimentOvertime] = useState([]);
  const [sentimentPiechart, setSentimentPiechart] = useState([]);
  const [sentimentPieArray, setSentimentPieArray] = useState([]);
  const [dialectsArray, setDialectsArray] = useState([]);
  const [dialectsArrayXlsx, setDialectsArrayXlsx] = useState([]);
  const [subDialectsArray, setSubDialectsArray] = useState([]);
  const [subDialectsArrayXlsx, setSubDialectsArrayXlsx] = useState([]);
  const [dialectsXlxs, setDialectsXlsx] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [subDialectsXlsx, setSubDialectsXlsx] = useState([]);
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [categoriesArrayXlsx, setCategoriesArrayXlsx] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesXlsx, setCategoriesXlsx] = useState([]);
  const [channelsArray, setChannelsArray] = useState([]);
  const [channelsArrayXlsx, setChannelsArrayXlsx] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelsXlxs, setChannelsXlxs] = useState([]);
  const [genders, setGenders] = useState([]);
  const [gendersXlsx, setGendersXlsx] = useState([]);
  const [gendersArray, setGendersArray] = useState([]);
  const [gendersArrayXlsx, setGendersArrayXlsx] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);
  const [countriesArrayXlsx, setCountriesArrayXlsx] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesXlsx, setCountriesXlsx] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [citiesArrayXlsx, setCitiesArrayXlsx] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesXlsx, setCitiesXlsx] = useState([]);
  const [topCitiesXlxs, setTopCitiesXlxs] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [overviewArray, setOverviewArray] = useState([]);
  const [summedInteractions, setSummedInteractions] = useState([]);
  const [overview, setOverview] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [languagesArray, setLanguagesArray] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [contactActivity, setContactActivity] = useState([]);
  const [minValueContactActivity, setMinValueContactActivity] = useState(0);
  const [maxValueContactActivity, setMaxValueContactActivity] = useState(0);
  const [mostEngagedContacts, setMostEngagedContacts] = useState([]);
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [iconOnlyDatasource, setIconOnlyDatasource] = useState("");

  // Values of DataSources totrack each widget preloader
  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [
    interactionsPreLoaderDataSources,
    setInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    sentimentAnalysisPreLoaderDataSources,
    setSentimentAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [categoriesPreLoaderDataSources, setCategoriesPreLoaderDataSources] =
    useState([]);
  const [channelsPreLoaderDataSources, setChannelsPreLoaderDataSources] =
    useState([]);
  const [gendersPreLoaderDataSources, setGendersPreLoaderDataSources] =
    useState([]);
  const [dialectsPreLoaderDataSources, setDialectsPreLoaderDataSources] =
    useState([]);
  const [countriesPreLoaderDataSources, setCountriesPreLoaderDataSources] =
    useState([]);
  const [citiesPreLoaderDataSources, setCitiesPreLoaderDataSources] = useState(
    [],
  );
  const [topKeywordsPreLoaderDataSources, setTopKeywordsPreLoaderDataSources] =
    useState([]);
  const [themeTrendPreLoaderDataSources, setThemeTrendPreLoaderDataSources] =
    useState([]);
  const [
    themeAnalysisPreLoaderDataSources,
    setThemeAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [languagesPreLoaderDataSources, setLanguagesPreLoaderDataSources] =
    useState([]);
  const [
    contactActivityPreLoaderDataSources,
    setContactActivityPreLoaderDataSources,
  ] = useState([]);
  const [
    mostEngagedContactsPreLoaderDataSources,
    setMostEngagedContactsPreLoaderDataSources,
  ] = useState([]);
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  var gendersResult = [],
    gendersResultXlsx = [],
    countriesResult = [],
    countriesResultXlsx = [],
    citiesResult = [],
    citiesResultXlsx = [],
    dialectsResult = [],
    dialectsResultXlsx = [],
    subDialectsResult = [],
    subDialectsResultXlsx = [],
    channelsResult = [],
    channelsResultXlsx = [],
    categoriesResult = [],
    categoriesResultXlsx = [],
    sentimentPieResult = [],
    overviewResult = [],
    interactionsResult = [],
    interactionsArray2 = [],
    interactions2 = [],
    topKeywordsResult = [],
    updatedPreLoaderTracker = [...preLoaderTrackerDataSources],
    languagesResult = [];

  useEffect(() => {
    switch (response?.eventName) {
      case "top_keywords":
        //we made this condition because engine returned datasources not included in preLoaderTrackerDataSources
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData.top_keywords?.map((i) => {
            return topKeywordsArray.push(i);
          });
          handleArraySum(topKeywordsArray, topKeywordsResult, setTopKeywords);
          setTopKeywordsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "interactions":
        //START INTERACTIONS ARRAY
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData.interactions?.map((i) => {
            return interactionsArray.push(i);
          });
          interactionsArray.map((data) => [
            interactionsArray2.push({
              name: moment.unix(Number(data.name)).utc().format("YYYY-MM-DD"),
              value: data.value,
            }),
          ]);

          handleArraySum(
            interactionsArray2,
            interactionsResult,
            setInteractions,
            true,
          );
          setInteractionsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "genders":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData.genders.map((i) => {
            return gendersArray.push(i);
          });
          response?.eventData.genders.map((i) => {
            return gendersArrayXlsx.push(i);
          });
          handleArraySum(gendersArray, gendersResult, setGenders);
          handleArraySum(
            _.cloneDeep([...gendersArrayXlsx]),
            _.cloneDeep([...gendersResultXlsx]),
            setGendersXlsx,
          );
          setGendersPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "countries":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData.countries?.map((i) => {
            return countriesArray.push(i);
          });
          response?.eventData.countries?.map((i) => {
            return countriesArrayXlsx.push(i);
          });
          handleArraySum(countriesArray, countriesResult, setCountries);
          handleArraySum(
            _.cloneDeep([...countriesArrayXlsx]),
            _.cloneDeep([...countriesResultXlsx]),
            setCountriesXlsx,
          );
          setCountriesPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "cities":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData.cities?.map((i) => {
            citiesArray.push(i);
          });
          response?.eventData.cities?.map((i) => {
            citiesArrayXlsx.push(i);
          });
          handleArraySum(citiesArray, citiesResult, setCities);
          handleArraySum(
            _.cloneDeep([...citiesArrayXlsx]),
            _.cloneDeep([...citiesResultXlsx]),
            setCitiesXlsx,
          );
          setCitiesPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;

      case "dialects":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData?.dialects?.map((i) => {
            return dialectsArray.push(i);
          });
          response?.eventData?.sub_dialects?.map((i) => {
            return subDialectsArray.push(i);
          });
          response?.eventData?.dialects?.map((i) => {
            return dialectsArrayXlsx.push(i);
          });
          response?.eventData?.sub_dialects?.map((i) => {
            return subDialectsArrayXlsx.push(i);
          });

          handleArraySum(dialectsArray, dialectsResult, setDialects);
          handleArraySum(
            _.cloneDeep([...dialectsArrayXlsx]),
            _.cloneDeep([...dialectsResultXlsx]),
            setDialectsXlsx,
          );
          handleArraySum(subDialectsArray, subDialectsResult, setSubDialects);
          handleArraySum(
            _.cloneDeep([...subDialectsArrayXlsx]),
            _.cloneDeep([...subDialectsResultXlsx]),
            setSubDialectsXlsx,
          );
          setDialectsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "sentiment":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData.sentiment_piechart?.map((i) => {
            sentimentPieArray.push(i);
          });
          handleArraySum(
            sentimentPieArray,
            sentimentPieResult,
            setSentimentPiechart,
          );
          Object.entries(response?.eventData.sentiment_overtime).forEach(
            ([key, value]) => {
              switch (key) {
                case "negative":
                  value?.map((i) => {
                    return negativeArray?.push(i);
                  });
                  break;
                case "positive":
                  value?.map((i) => {
                    return positiveArray?.push(i);
                  });
                  break;
                case "neutral":
                  value?.map((i) => {
                    return neutralArray?.push(i);
                  });
                  break;
                default:
                  break;
              }
            },
          );
          setSentimentOvertime({
            negative: negativeArray,
            positive: positiveArray,
            neutral: neutralArray,
          });
          setSentimentAnalysisPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "channels":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          for (let [key, values] of Object.entries(
            response?.eventData.channels,
          )) {
            channelsArray.push({ name: key, value: values });
          }
          for (let [key, values] of Object.entries(
            response?.eventData.channels,
          )) {
            channelsArrayXlsx.push({ name: key, value: values });
          }
          handleArraySum(channelsArray, channelsResult, setChannels);
          handleArraySum(
            _.cloneDeep([...channelsArrayXlsx]),
            _.cloneDeep([...channelsResultXlsx]),
            setChannelsXlxs,
          );
          setChannelsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "categories":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          for (let [key, values] of Object.entries(
            response?.eventData?.categories,
          )) {
            categoriesArray.push({ name: key, value: values });
          }
          for (let [key, values] of Object.entries(
            response?.eventData?.categories,
          )) {
            categoriesArrayXlsx.push({ name: key, value: values });
          }
          handleArraySum(categoriesArray, categoriesResult, setCategories);
          handleArraySum(
            _.cloneDeep([...categoriesArrayXlsx]),
            _.cloneDeep([...categoriesResultXlsx]),
            setCategoriesXlsx,
          );
          setCategoriesPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      case "overview":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          if (response?.eventData?.overview) {
            Object.entries(response?.eventData?.overview)?.map((i) => {
              if (i[0] === "net_sentiment") {
                i[1]?.map((sentiment) => {
                  if (sentiment.name == "negative") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      negative: netSentiment["negative"] + sentiment.value,
                    }));
                  }
                  if (sentiment.name == "positive") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      positive: netSentiment["positive"] + sentiment.value,
                    }));
                  }
                  if (sentiment.name == "neutral") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      neutral: netSentiment["neutral"] + sentiment.value,
                    }));
                  }
                });
              } else {
                const condition =
                  i[1] === "None" || i[1] === null || i[1] === 0;
                if (i[0] === "average_waiting_time" && condition) {
                  overviewArray.push({ name: i[0], value: 0.0 });
                } else overviewArray.push({ name: i[0], value: i[1] });
              }
            });
            setOverViewPreLoaderDataSources((oldArray) => [
              ...oldArray,
              response?.data_source,
            ]);
          }
        }
        break;
      case "themes":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          //we make this condition for themes&themes trend to contain only firstly datasource once fix them later
          //themes & themes preloader contain only one data source ,we made it as themes & themes trend have problem of displaying all data sources data

          setThemeAnalysis((oldArray) => [
            ...oldArray,
            response?.eventData.themes,
          ]);
          setThemeTrendPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);

          setThemeAnalysisPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;

      // case "languages":
      //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
      //     response?.eventData.languages?.map((i) => {
      //       languagesArray.push(i);
      //     });
      //     handleArraySum(languagesArray, languagesResult, setLanguages);
      //     setLanguagesPreLoaderDataSources((oldArray) => [
      //       ...oldArray,
      //       response?.data_source,
      //     ]);
      //   }
      //   break;

      // case "channel_activity":
      //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
      //     if (isEmptyArray(contactActivityPreLoaderDataSources)) {
      //       response?.data_source.includes("twitter")
      //         ? setIconOnlyDatasource(twitterIcon)
      //         : response?.data_source.includes("facebook")
      //         ? setIconOnlyDatasource(facebookIcon)
      //         : response?.data_source.includes("instagram")
      //         ? setIconOnlyDatasource(instagramIcon)
      //         : response?.data_source.includes("intercom")
      //         ? setIconOnlyDatasource(intercomIcon)
      //         : response?.data_source.includes("whatsapp")
      //         ? setIconOnlyDatasource(whatsappIcon)
      //         : response?.data_source.includes("gmail")
      //         ? setIconOnlyDatasource(gmailIcon)
      //         : setIconOnlyDatasource("");
      //       setMinValueContactActivity(
      //         response?.eventData.channel_activity.min_value_client
      //       );
      //       setMaxValueContactActivity(
      //         response?.eventData.channel_activity.max_value_client
      //       );
      //       setContactActivity(response?.eventData.channel_activity.client);
      //     }

      //     setContactActivityPreLoaderDataSources((oldArray) => [
      //       ...oldArray,
      //       response?.data_source,
      //     ]);
      //   }
      //   break;
      // case "top_engaged_authors":
      //   if (preLoaderTrackerDataSources.includes(response?.data_source)) {
      //     response?.eventData?.top_engaged_authors?.map((author) => {
      //       //to handle different  datasources
      //       response?.data_source.includes("twitter")
      //         ? (author.sourceIcon = twitterIcon)
      //         : response?.data_source.includes("facebook")
      //         ? (author.sourceIcon = facebookIcon)
      //         : response?.data_source.includes("instagram")
      //         ? (author.sourceIcon = instagramIcon)
      //         : response?.data_source.includes("intercom")
      //         ? (author.sourceIcon = intercomIcon)
      //         : response?.data_source.includes("whatsapp")
      //         ? (author.sourceIcon = whatsappIcon)
      //         : response?.data_source.includes("gmail")
      //         ? (author.sourceIcon = gmailIcon)
      //         : (author.sourceIcon = "");

      //       setMostEngagedContacts((oldArray) => [...oldArray, author]);
      //     });
      //     setMostEngagedContactsPreLoaderDataSources((oldArray) => [
      //       ...oldArray,
      //       response?.data_source,
      //     ]);
      //   }

      //   break;
      case "latest_interactions":
        if (preLoaderTrackerDataSources?.includes(response?.data_source)) {
          response?.eventData.latest_interactions?.map((interaction) => {
            setLatestInteractions((oldArray) => [...oldArray, interaction]);
          });

          setLatestInteractionsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response?.data_source,
          ]);
        }
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Empty Array state
    let sum;
    let interactions_sum = 0;
    if (interactions.length > 0) {
      interactions.map((data) => {
        // Push data to interactions2
        interactions2.push({
          name: moment(data.name).unix(),
          value: data.value,
        });
        interactions_sum += data.value;
      });
      setSummedInteractions(interactions2);

      // Here we just need new Array of Refactor to be One Array
      setTotalInteractions(interactions_sum);
      const noOfDays = getNoOfDays(startDate, endDate);
      const avg = interactions_sum / noOfDays;
      setAvgInteraction(avg % 1 !== 0 ? avg.toFixed(2) : avg);
      //END TOTAL INTERACTIONS COUNT
    }
  }, [interactions]);

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment).reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverview,
      false,
      highest_sentiment,
    );
  };

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTopKeywords([]);
      setTopkeywordsArray([]);
      setInteractions([]);
      setInteractionsArray([]);
      setTotalInteractions(0);
      setAvgInteraction(0);
      setSentimentOvertime([]);
      setSentimentPiechart([]);
      setSentimentPieArray([]);
      setGenders([]);
      setGendersXlsx([]);
      setGendersArray([]);
      setGendersArrayXlsx([]);
      setCountriesArray([]);
      setCountriesArrayXlsx([]);
      setCountries([]);
      setCountriesXlsx([]);
      setCitiesArray([]);
      setCitiesArrayXlsx([]);
      setCities([]);
      setCitiesXlsx([]);
      setDialectsArray([]);
      setDialectsArrayXlsx([]);
      setSubDialectsArray([]);
      setSubDialectsArrayXlsx([]);
      setDialects([]);
      setDialectsXlsx([]);
      setSubDialectsXlsx([]);
      setChannelsArray([]);
      setChannels([]);
      setChannelsArrayXlsx([]);
      setChannelsXlxs([]);
      setCategoriesArray([]);
      setCategoriesArrayXlsx([]);
      setCategories([]);
      setCategoriesXlsx([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setOverviewArray([]);
      setOverview([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });
      setThemeAnalysis([]);
      setLanguagesArray([]);
      setLanguages([]);
      setContactActivity([]);
      setMinValueContactActivity([0]);
      setMaxValueContactActivity([0]);
      setMostEngagedContacts([]);
      setLatestInteractions([]);

      // SET all preloader data sources tracker to Empty Array
      setOverViewPreLoaderDataSources([]);
      setInteractionsPreLoaderDataSources([]);
      setSentimentAnalysisPreLoaderDataSources([]);
      setCategoriesPreLoaderDataSources([]);
      setChannelsPreLoaderDataSources([]);
      setGendersPreLoaderDataSources([]);
      setDialectsPreLoaderDataSources([]);
      setCountriesPreLoaderDataSources([]);
      setCitiesPreLoaderDataSources([]);
      setTopKeywordsPreLoaderDataSources([]);
      setThemeTrendPreLoaderDataSources([]);
      setThemeAnalysisPreLoaderDataSources([]);
      setLanguagesPreLoaderDataSources([]);
      setContactActivityPreLoaderDataSources([]);
      setMostEngagedContactsPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    if (
      _.isEqual(
        themeAnalysisPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) == true &&
      preLoaderTrackerDataSources.length &&
      themeAnalysisPreLoaderDataSources.length
    ) {
      aggregate(themeAnalysis, setThemeData);
    }
  }, [themeAnalysisPreLoaderDataSources]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: totalInteractions,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: avgInteractions,
    },
  ];

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  const [dialectsData, setDialectsData] = useState([]);
  const [subDialectsData, setSubDialectsData] = useState([]);
  let arrSubDialectsClone = [];
  useEffect(() => {
    if (
      _.isEqual(
        dialectsPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) == true
    ) {
      dialects?.map((item) => {
        subDialects?.map((item2) => {
          if (item?.name in dialectsSubDialectsData) {
            if (
              dialectsSubDialectsData[item?.name]?.includes(
                item2?.name?.toLowerCase(),
              )
            ) {
              arrSubDialectsClone?.push(item2);
            }
          }
        });
      });
      setSubDialectsData(arrSubDialectsClone);
      setDialectsData(dialects);
    }
  }, [dialects, subDialects, dialectsPreLoaderDataSources]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [
      overViewPreLoaderDataSources,
      interactionsPreLoaderDataSources,
      themeAnalysisPreLoaderDataSources,
      dialectsPreLoaderDataSources,
      categoriesPreLoaderDataSources,
      channelsPreLoaderDataSources,
      gendersPreLoaderDataSources,
      countriesPreLoaderDataSources,
      citiesPreLoaderDataSources,
      latestInteractionsPreLoaderDataSources,
    ];
    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      preLoaderTrackerDataSources,
    );
    props?.setCheckAllDataFetchingExport(result);
    let excelData = {
      preLoaderTrackerDataSources,
      overview,
      summedInteractions,
      themeData,
      dialectsXlxs,
      subDialectsXlsx,
      channelsXlxs,
      citiesXlsx,
      countriesXlsx,
      categoriesXlsx,
      gendersXlsx,
      latestInteractions,
    };
    setExcelSheetData([{ ...excelData }]);
  }, [
    preLoaderTrackerDataSources,
    overview,
    overViewPreLoaderDataSources,
    summedInteractions,
    interactionsPreLoaderDataSources,
    themeData,
    themeTrendPreLoaderDataSources,
    dialectsXlxs,
    dialectsPreLoaderDataSources,
    channelsXlxs,
    channelsPreLoaderDataSources,
    citiesXlsx,
    citiesPreLoaderDataSources,
    countriesXlsx,
    countriesPreLoaderDataSources,
    categoriesXlsx,
    categoriesPreLoaderDataSources,
    gendersXlsx,
    gendersPreLoaderDataSources,
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
  ]);
  const unixDate = {
    start: startDate,
    end: endDate,
  };

  return (
    <>
      <Box className="preloader-box"></Box>
      <OverView
        title={"Analytics"}
        data={overview}
        isInsightsPage={true}
        overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />

      <Box my={4}>
        <LineChartWidget
          title={"interactions"}
          titleToolTip="interaction_tooltip"
          data={summedInteractions}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={interactionsPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl
          .formatMessage({ id: "interactions_closed_conversation" })
          .toUpperCase()}
      </Typography>
      <Box>
        {/* <StackedLineWidget
          titleToolTip="sentiments_tooltip"
          title={"sentiment_analysis"}
          data={sentimentOvertime}
          dataPiechart={sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isSwitchCase
          showScales
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysis
        /> */}
      </Box>
      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          titleToolTip="themes_tooltip"
          title={"themes"}
          data={themeData?.theme_analysis ? themeData?.theme_analysis : []}
          showDownloadIcon
          bigImg
          showAI
          // we compare here preloader datasources length that contain one element only as to be fixed laster we will remove this conditions
          themeAnalysisPreLoaderDataSources={themeAnalysisPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          titleToolTip="themes_trend_tooltip"
          title={"themes_trend"}
          data={themeData?.theme_trend ? themeData?.theme_trend : []}
          showGroupBy={false}
          showDownloadIcon
          showAI
          bigImg
          themeTrendPreLoaderDataSources={themeTrendPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Grid container spacing={2} my={4}>
        <Grid item md={6}>
          <PieChartWidget
            titleToolTip="dialects_subdialects_tooltip"
            title={"dialects_subdialects"}
            data={dialectsData}
            subData={subDialectsData}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            bigImg
            showDownloadIcon
            pieChartPreLoaderDataSources={dialectsPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        {/* <Grid item md={6}>
          <PieChartWidget
            titleToolTip="language_distribution_tooltip"
            title={"language_distribution"}
            data={languages}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={languagesPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid> */}
      </Grid>
      {/* <Box my={4} className="channel-activity">
        <PunchCardWidget
          titleToolTip="channel_activity_tooltip"
          title="contact_activity"
          clientActivity={contactActivity}
          minValueClient={minValueContactActivity}
          maxValueClient={maxValueContactActivity}
          showDownloadIcon
          bigImg
          authorActivityPreLoaderDataSources={
            contactActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box> */}
      <Grid container spacing={2} my={4}>
        <Grid item md={6}>
          <PieChartWidget
            title={"categories"}
            data={categories}
            areaStyle={true}
            titleToolTip={"categories_tooltip"}
            showDownloadIcon
            bigImg
            color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
            pieChartPreLoaderDataSources={categoriesPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"channels"}
            titleToolTip="channels_tooltip"
            data={channels}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#009BDF", "#B233E2", "#006BFF", "#E24141", "#FF4C82"]}
            chartType={"channels"}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={channelsPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={4}>
        <Grid item md={4}>
          <PieChartWidget
            title={"genders_distribution"}
            titleToolTip="genders_tooltip"
            data={genders}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#2AAAE2", "#FF4081"]}
            chartType={"gender"}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={gendersPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"top_countries"}
            titleToolTip="top_countries_tooltip"
            data={countries}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={countriesPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"top_cities"}
            titleToolTip="top_cities_tooltip"
            data={cities}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={citiesPreLoaderDataSources}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={6}>
        <TableWidget
          itemsList
          titleToolTip="most_engaged_tooltip"
          title={"most_engaged_contacts"}
          data={mostEngagedContacts}
          leftColomnTitle={"user_author"}
          rightColomnTitle={"interactions"}
          bigImg
          tableChartPreLoaderDataSources={
            mostEngagedContactsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          tablePagination
          showDownloadIcon
          count={mostEngagedContacts.length}
          isSorted
        />
      </Grid> */}

      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={unixDate}
          monitorId={monitorId}
          data={latestInteractions.slice(0, 10)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isGeneralInteractionPage
          latestInteractionsPreLoaderDataSources={
            latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          showViewMoreButton={totalInteractions > 10}
        />
      </Box>
    </>
  );
};

export default InsightsBody;
