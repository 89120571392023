import { Box, Typography, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends.js";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";
import { CheckValueLocale } from "utils/helpers";

const EngagementsSection = ({
  faceBookWidgets,
  preLoaderTrackerDataSources,
  FacebookIcon,
}) => {
  const intl = useIntl();

  const chartStatsData = [
    {
      name: "total_comments",
      value: faceBookWidgets?.totalInteraction,
    },
    {
      name: "insights_avg_comments_per_day",
      value: faceBookWidgets?.avgInteractions,
    },
  ];

  return (
    <Box>
      <Typography variant="h6" className="ca-head">
        {CheckValueLocale("engagements", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"comments_volume"}
          titleToolTip="total_interactions_public_tooltip"
          data={faceBookWidgets?.commentsVolume}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/social-media/facebook-public/"}
          toolTipLabel={"total_interactions_public_tooltip"}
          showDownloadIcon
          bigImg
          iconAfterTitle={FacebookIcon}
          lineChartPreLoaderDataSources={
            faceBookWidgets?.commentsVolumePreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"comments_reactions"}
          titleToolTip={"comments_reactions_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.reactionsEngagement}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          labels={[
            "like_reaction",
            "love_reaction",
            "care_reaction",
            "haha_reaction",
            "wow_reaction",
            "angry_reaction",
            "sad_reaction",
          ]}
          hideLegend
          dataPiechart={faceBookWidgets?.pageReactionPieChartValueEngagement}
          colors={[
            "#1892F2",
            "#FB5C77",
            "#F7B94D",
            "#FEDC5F",
            "#00D7B5",
            "#EC8A92",
            "#1C404E",
          ]}
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.reactionsPreLoaderEngagement
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Box mt={3}>
        <ThemesAnalysis
          title={"monitor_theme_title"}
          titleToolTip="themes_tooltip"
          data={faceBookWidgets?.themeAnalysis}
          showAI
          showDownloadIcon
          bigImg
          iconAfterTitle={FacebookIcon}
          themeAnalysisPreLoaderDataSources={
            faceBookWidgets?.themeAnalysisTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"monitor_theme_trend_title"}
          titleToolTip="themes_trend_tooltip"
          data={faceBookWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          iconAfterTitle={FacebookIcon}
          themeTrendPreLoaderDataSources={
            faceBookWidgets?.themeAnalysisTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Box mt={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis"}
          titleToolTip={"comments_sentiment_analysis_tooltip"}
          data={faceBookWidgets?.sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          isSwitchCase
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          dataPiechart={faceBookWidgets?.sentimentPieChart}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          stackedLineWidgetPreLoaderDataSources={
            faceBookWidgets?.sentimentAnalysisPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isSentimentAnalysisShowCharts
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"comments_interactions"}
          titleToolTip={"comments_interactions_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.commentsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "likes"]}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          hideLegend
          dataPiechart={faceBookWidgets?.commentsInteractionsLegend}
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "max_interaction",
              value: faceBookWidgets?.maxValueCommentsInteractions,
              date: `(${faceBookWidgets?.maxValueCommentsInteractionsDate})`,
            },
            {
              name: "min_interaction",
              value: faceBookWidgets?.minValueCommentsInteractions,
              date: `(${faceBookWidgets?.minValueCommentsInteractionsDate})`,
            },
            {
              name: "avg_interaction_per_day",
              value: faceBookWidgets?.avgValueCommentsInteractions,
            },
          ]}
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.commentsInteractionsPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_keywords"}
              titleToolTip={"comments_top_keywords_tooltip"}
              data={faceBookWidgets?.engagamentsTopKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              keywords
              tablePagination
              count={faceBookWidgets?.engagamentsTopKeywords.length}
              iconAfterTitle={FacebookIcon}
              tableChartPreLoaderDataSources={
                faceBookWidgets?.engagamentsTopKeywordsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_hashtags"}
              titleToolTip={"comments_top_hashtags_tooltip"}
              data={faceBookWidgets?.engagamentsTopHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              hashtags
              tablePagination
              count={faceBookWidgets?.engagamentsTopHashtags?.length}
              iconAfterTitle={FacebookIcon}
              tableChartPreLoaderDataSources={
                faceBookWidgets?.engagamentsTopHashtagsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            />
          </Grid>
        </Grid>
      </Box>

      {/* // NOTE: we commented this code until BE fixed these widgets. */}
      {/* <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_associated_topics"}
              titleToolTip={"comments_associated_topics_tooltip"}
              data={faceBookWidgets?.associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              iconAfterTitle={FacebookIcon}
              pieChartPreLoaderDataSources={
                faceBookWidgets?.associatedTopicsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            />
          </Grid>
        </Grid>
      </Box> */}
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_associated_topics"}
              titleToolTip={"comments_associated_topics_tooltip"}
              data={faceBookWidgets?.associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              iconAfterTitle={FacebookIcon}
              pieChartPreLoaderDataSources={
                faceBookWidgets?.associatedTopicsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip={"dialects_subdialects_tooltip"}
              data={faceBookWidgets?.dialects}
              subData={faceBookWidgets?.subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              iconAfterTitle={FacebookIcon}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={
                faceBookWidgets?.dialectsSubDialetsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <LineChartWidget
          title={"engagers_volume"}
          titleToolTip={"engagers_volume_fb_tooltip"}
          totalLabel={"engagers"}
          totalValue={faceBookWidgets?.totalEngagersVolumeCount}
          data={faceBookWidgets?.engagersVolume}
          showGroupBy={true}
          toolTipLabel={"engagers"}
          showDownloadIcon
          bigImg
          hideLegend
          iconAfterTitle={FacebookIcon}
          labelColor={"#2AAAE2"}
          lineChartPreLoaderDataSources={
            faceBookWidgets?.engagersVolumePreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      {/* // NOTE: we commented this code until BE fixed these widgets. */}
      {/* <Box my={3}>
        <StackedBarWidget
          title={"gender_age_dist"}
          titleToolTip={"gender_distribution_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.genderDistribution}
          labels={["male", "female"]}
          hideLegend
          dataPiechart={faceBookWidgets?.genderDistributionPieChartValue}
          colors={["#2AAAE2", "#FF4081"]}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          reachFunnel
          isGenderAgeSorted
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.genderDistributionPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box> */}

      <Box my={3}>
        <PunchCardWidget
          title="engagers_activity"
          titleToolTip={"engagers_activity_fb_tooltip"}
          clientActivity={faceBookWidgets?.engagersActivity}
          minValueClient={faceBookWidgets?.minValueEngagersActivity}
          maxValueClient={faceBookWidgets?.maxValuEngagerActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={FacebookIcon}
          authorActivityPreLoaderDataSources={
            faceBookWidgets?.engagerActivityPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
    </Box>
  );
};

export default EngagementsSection;
