import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const InteractionsListMessageTiktok = (props) => {
  const { obj } = props;
  const intl = useIntl();

  return (
    <Box className="tiktok-main-interaction">
      <iframe
        id={`tiktok-iframe-${obj?.id}`}
        height="500px"
        width="325px"
        frameBorder="0"
        src={`https://www.tiktok.com/embed/v2/${obj?.videoId}`}
        sandbox
      ></iframe>
      <Box className="main-interaction-details">
        {obj?.created_by}
        <Box className="main-details-text">{obj?.text}</Box>
        <Box className="main-details-reaction">
          <FontAwesomeIcon icon={faHeart} />
          {obj?.like_count}
        </Box>
      </Box>
    </Box>
  );
};

export default InteractionsListMessageTiktok;
