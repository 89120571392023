import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList";
import OverView from "components/widgets/overViewWidget";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import { useEffect, useState } from "react";
import { handleArraySum } from "../../insightsCommonFunctions";

const ChatInteractionsBody = ({ response, ...props }) => {
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [overviewArray, setOverviewArray] = useState([]);
  const [overview, setOverview] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });
  const [latestInteractionsCount, setLatestInteractionsCount] = useState(0);
  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  var overviewResult = [];
  useEffect(() => {
    switch (response?.eventName) {
      case "overview":
        if (response?.eventData?.overview) {
          if (
            props?.preLoaderTrackerDataSources?.includes(response.data_source)
          ) {
            Object.entries(response?.eventData?.overview).map((i) => {
              if (i[0] === "net_sentiment") {
                i[1]?.map((sentiment) => {
                  if (sentiment?.name === "negative") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      negative: netSentiment["negative"] + sentiment?.value,
                    }));
                  }
                  if (sentiment?.name === "positive") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      positive: netSentiment["positive"] + sentiment?.value,
                    }));
                  }
                  if (sentiment?.name === "neutral") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      neutral: netSentiment["neutral"] + sentiment?.value,
                    }));
                  }
                });
              } else {
                overviewArray.push({ name: i[0], value: i[1] });
              }
            });
            setOverViewPreLoaderDataSources((oldArray) => [
              ...oldArray,
              response?.data_source,
            ]);
          }
        }
        break;
      case "latest_interactions":
        if (
          props?.preLoaderTrackerDataSources?.includes(response.data_source)
        ) {
          response?.eventData.latest_interactions?.map((interaction) => {
            setLatestInteractions((oldArray) => [...oldArray, interaction]);
          });
          // we use this case for [latestInteractionsCount Prop] as we use initial value to seperate Pages ,
          // because when move from page to another [latestInteractionsCount] get with value 0 .
          if (props?.page === 1) {
            if (
              response?.eventData?.interactions_count > latestInteractionsCount
            ) {
              setLatestInteractionsCount(
                response?.eventData?.interactions_count,
              );
            }
          }

          if (
            !latestInteractionsPreLoaderDataSources?.includes(
              response?.data_source,
            )
          ) {
            setLatestInteractionsPreLoaderDataSources((oldArray) => [
              ...oldArray,
              response?.data_source,
            ]);
          }
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [response]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment).reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverview,
      false,
      highest_sentiment,
    );
  };

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props?.resetAllFields !== 1) {
      setLatestInteractions([]);
      setOverview([]);
      setOverviewArray([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });
      if (props?.page === 1) setLatestInteractionsCount(0);
      // Values of DataSources totrack each widget preloader
      setOverViewPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
    }
  }, [props?.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionsPreLoaderDataSources,
      props?.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props?.setCheckAllDataExist(x);
    props?.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
    props?.preLoaderTrackerDataSources,
  ]);

  return (
    <>
      <OverView
        data={overview}
        isInteractionsPage={true}
        overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
      />
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          data={latestInteractions}
          dataSources={props?.dataSources}
          isInteractionPage={true}
          latestInteractionsPreLoaderDataSources={
            latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          pagenation={true}
          latestInteractionsCount={latestInteractionsCount}
          handleChangePage={props?.handleChangePage}
          page={props?.page}
          loader={props?.loader}
        />
      </Box>
    </>
  );
};

export default ChatInteractionsBody;
