import SummaryWidget from "../api/summaryWidget";

var SummaryWidgetController = {};

SummaryWidgetController.getSummaryStaticQuestions = (queryData) => {
  return new Promise(function (resolve) {
    SummaryWidget.getSummaryStaticQuestions(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SummaryWidgetController.getLastQuestionAnswer = (queryData) => {
  return new Promise(function (resolve) {
    SummaryWidget.getLastQuestionAnswer(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SummaryWidgetController.postQuestionStatus = (queryData) => {
  return new Promise(function (resolve) {
    SummaryWidget.postQuestionStatus(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SummaryWidgetController.getSummarySmTokens = () => {
  return new Promise(function (resolve) {
    SummaryWidget.getSummarySmTokens()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default SummaryWidgetController;
