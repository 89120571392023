import { Box, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import LuciHeader from "../luciHeader/luciHeader";
import LuciRequest from "./request/request";
import RequestFilter from "./components/requestFilter/requestFilter";
import LuciController from "services/controllers/luciController";
import CircularProgress from "@mui/material/CircularProgress";
import "./requests.scss";

const LuciRequests = ({
  handleCloseSideBar,
  setShowRequests,
  setShowConfirmationPopup,
  setQuestionIdAnswer,
  handleRequestClick,
  getLuciStaticQuestions,
  pageParams,
  isInactive,
}) => {
  const intl = useIntl();
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [requestsList, setRequestsList] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [page, setPage] = useState(1);
  const [requestSortBy, setRequestSortBy] = useState("all");
  const sortByData = ["all", "done"];

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    getRequests(page, requestSortBy === "done" ? "done" : "");
  };

  const getRequests = (page, status) => {
    setRequestsLoading(true);
    LuciController.getLuciRequests(page, status).then((result) => {
      setRequestsList(result?.data);
      setPagination(result?.total_count?.pages);
      setRequestsLoading(false);
    });
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <Box className="luci-requests-wrapper">
      <LuciHeader
        handleCloseSideBar={handleCloseSideBar}
        setShowPage={setShowRequests}
        sectionName="luci_requests"
        setShowConfirmationPopup={setShowConfirmationPopup}
        getLuciStaticQuestions={getLuciStaticQuestions}
        pageParams={pageParams}
        isRequests
        isInactive={isInactive}
      />

      {requestsList?.length == 0 && !requestsLoading ? (
        <Box className="luci-no-request-wrapper">
          <Box className="luci-no-request">
            {CheckValueLocale("luci_no_request_yet", "", {}, intl)}
          </Box>
          <Box className="luci-get-started">
            {CheckValueLocale("luci_to_get_started_request", "", {}, intl)}
          </Box>
        </Box>
      ) : (
        <>
          <RequestFilter
            getRequests={getRequests}
            setRequestSortBy={setRequestSortBy}
            requestSortBy={requestSortBy}
            sortByData={sortByData}
          />
          {requestsLoading ? (
            <Box className="luci-loading">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <LuciRequest
                requestsList={requestsList}
                setQuestionIdAnswer={setQuestionIdAnswer}
                handleRequestClick={handleRequestClick}
              />
              {pagination > 1 && requestsList?.length > 0 && (
                <Pagination
                  onChange={handleChangePage}
                  className="luci-requests-pagination"
                  count={pagination > 1 ? pagination : 1}
                  page={page}
                  variant="outlined"
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default LuciRequests;
