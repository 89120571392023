export const dummyQuestions = [
  {
    is_deleted: false,
    question: {
      text: "multiple_choice_question",
      description: "",
      type: "multiple_choice",
      constraints: {
        required: false,
        multiple_selection: false,
        randomaize: false,
        other_option: false,
      },
      items: [{ 1: "Option 1" }, { 2: "Option 2" }],
    },
    is_proceed_on_answer: false,
    is_proceed_editable: true,
  },

  {
    is_deleted: false,
    question: {
      text: "dropdown_question",
      description: "",
      type: "dropdown",
      constraints: {
        required: false,
        randomaize: false,
        alpabetical_order: false,
      },
      items: [{ 1: "Option 1" }],
    },
    is_proceed_on_answer: false,
    is_proceed_editable: true,
  },
  {
    is_deleted: false,
    question: {
      text: "what_is_your_name",
      description: "",
      type: "short_text",
      constraints: {
        required: false,
        max_chars: -1,
      },
    },
  },
  {
    is_deleted: false,
    question: {
      text: "question_title",
      description: "",
      type: "yes_no",
      constraints: {
        required: false,
      },
      items: [{ 1: "yes" }, { 2: "no_word" }],
    },
    is_proceed_on_answer: false,
    is_proceed_editable: true,
  },
  {
    is_deleted: false,
    question: {
      text: "question_number",
      description: "",
      type: "number",
      constraints: {
        required: false,
        min_number: { checked: false, value: 0 },
        max_number: { checked: false, value: 0 },
      },
    },
  },
  {
    is_deleted: false,
    question: {
      text: "enter_your_phone_number",
      description: "",
      type: "phone_number",
      constraints: { required: false, country_code: "sa" },
    },
  },
  {
    is_deleted: false,
    question: {
      text: "email_question_title",
      description: "",
      type: "email",
      constraints: { required: false },
    },
  },
  {
    is_deleted: false,
    question: {
      text: "enter_your_full_name",
      description: "",
      type: "name",
      constraints: { required: false },
    },
  },
  {
    is_deleted: false,
    question: {
      text: "add_your_social_accounts",
      description: "",
      type: "social_media",
      constraints: { required: false, platforms: [] },
    },
  },

  {
    is_deleted: false,
    question: {
      text: "nps_question",
      description: "",
      type: "nps",
      constraints: { required: false, colored_theme: false },
      items: [
        { 0: "0" },
        { 1: "1" },
        { 2: "2" },
        { 3: "3" },
        { 4: "4" },
        { 5: "5" },
        { 6: "6" },
        { 7: "7" },
        { 8: "8" },
        { 9: "9" },
        { 10: "10" },
      ],
    },
    is_proceed_on_answer: false,
    is_proceed_editable: true,
  },
  {
    is_deleted: false,
    question: {
      text: "nps_question",
      description: "",
      type: "scale",
      constraints: {
        required: false,
        min_value: 0,
        max_value: 10,
        low_value: "",
        high_value: "",
      },
      items: [
        { 0: "0" },
        { 1: "1" },
        { 2: "2" },
        { 3: "3" },
        { 4: "4" },
        { 5: "5" },
        { 6: "6" },
        { 7: "7" },
        { 8: "8" },
        { 9: "9" },
        { 10: "10" },
      ],
    },
    is_proceed_on_answer: false,
    is_proceed_editable: true,
  },
];
