import Typography from "@mui/material/Typography";
import { Grid, OutlinedInput, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { handleName } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";
import { checkDirectionLang } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
const InputFieldName = (props) => {
  const intl = useIntl();
  const {
    monitorData,
    setMonitorData,
    monitorErrors,
    setMonitorErrors,
    handleCreateMonitorBtn,
    name,
    nameInfoMsg,
    autoFocues,
    checkUserNameChar,
    isIgPublicAccount,
    maxNameLength,
    isSpecificFormat,
    isRequired,
  } = props;

  return (
    <Grid item xs={6} className="social-media-field-grid">
      <Typography
        variant="caption"
        className={isIgPublicAccount && "monitor-page-name"}
      >
        {CheckValueLocale(name, "", {}, intl)}
        {isRequired ? (
          <Typography component="span" className="required">
            *
          </Typography>
        ) : null}
      </Typography>

      <OutlinedInput
        svariant="outlined"
        className={`social-media-field ${monitorData?.userName ? checkDirectionLang(monitorData?.userName) : ""}`}
        autoFocus={autoFocues}
        value={monitorData?.userName}
        error={monitorErrors?.userName}
        onChange={(e) => {
          handleName(
            e,
            setMonitorErrors,
            monitorErrors,
            intl,
            isPublicSpecialCharactersOrEmojis,
            handleCreateMonitorBtn,
            setMonitorData,
            monitorData,
            checkUserNameChar,
            maxNameLength,
            isSpecificFormat,
          );
        }}
        placeholder={CheckValueLocale(nameInfoMsg, "", {}, intl)}
      />
      {monitorErrors?.userName && (
        <Box
          component="p"
          className="monitor-error-name input-msg-error-contaier"
        >
          {CheckValueLocale(monitorErrors?.userName, "", {}, intl)}
        </Box>
      )}
    </Grid>
  );
};

export default InputFieldName;
