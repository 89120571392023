import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, FormControl, Typography } from "@mui/material";
import "../surveyQuestion.scss";

const LinearScaleQuestion = (props) => {
  const { question, questionNo, questionAnswer, isCDP } = props;
  const [answers, setAnswers] = useState([]);
  const [lowValue, setLowValue] = useState("");
  const [highValue, setHighValue] = useState("");
  const [selected, setSelected] = useState();

  useEffect(() => {
    let tempAns = [];
    if (question) {
      if (isCDP) {
        setLowValue(question?.constraints?.low_value);
        setHighValue(question?.constraints?.high_value);
        setSelected(question?.answer);
        for (
          let i = question?.constraints?.min_value;
          i <= question?.constraints?.max_value;
          i++
        )
          tempAns?.push(i);
        setAnswers(tempAns);
      } else {
        setLowValue(question?.low_value);
        setHighValue(question?.high_value);
        setSelected(question?.answer_list);
        for (let i = question?.min_value; i <= question?.max_value; i++)
          tempAns?.push(i);
        setAnswers(tempAns);
      }
    }
  }, [question]);

  return (
    <Card className="cardStyle noError audience">
      <CardContent>
        <Typography className="questionStyle audience" mb={4}>
          <span>{`${questionNo}.`}</span>
          {question?.question}
        </Typography>
        <Box className="scaleBox">
          <FormControl className="scaleFormControl">
            <Box className="scaleContainer">
              {answers?.map((item, index) => {
                return (
                  <label
                    key={index}
                    className={`radio-item ${
                      (index === 0 || index === answers?.length - 1) &&
                      "valuesText"
                    }`}
                  >
                    <input
                      type="radio"
                      checked={item == selected}
                      value={item}
                      disabled
                    />
                    <span className={"choose"}>
                      <div>{item}</div>
                    </span>
                    {index === 0 && (
                      <Typography component={"p"} textAlign={"center"}>
                        {lowValue}
                      </Typography>
                    )}
                    {index === answers?.length - 1 && (
                      <Typography component={"p"} textAlign={"center"}>
                        {highValue}
                      </Typography>
                    )}
                  </label>
                );
              })}
            </Box>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LinearScaleQuestion;
