import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import { useThemesColors } from "pages/themes/hooks/useThemesColors";
import CircularLoading from "components/circularProgress";
import CustomizedTooltip from "components/customizedTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";

const ThemeDialogBody = ({
  formik,
  openDialog,
  themeDialogStats,
  setInitialValue,
  setSnackBarData,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const intl = useIntl();

  const [keywordFocused, setKeywordFocused] = useState(false);
  const [showKeyowrdErrors, setShowKeyowrdErrors] = useState(false);

  const {
    data: colors,
    isPending: isColorsPending,
    isError: isColorsError,
  } = useThemesColors();

  // To set color automatically with first unused color (NEW THEME CASE ONLY)
  useEffect(() => {
    if (openDialog === true) {
      if (themeDialogStats?.isEdit == true && themeDialogStats?.themeToEdit) {
        const themeToEdit = themeDialogStats?.themeToEdit;
        setInitialValue({
          sub_theme_color: themeToEdit?.sub_theme_color,
          sub_theme_en_name: themeToEdit?.sub_theme_en_name,
          sub_theme_ar_name: themeToEdit?.sub_theme_ar_name,
          sub_theme_description: themeToEdit?.sub_theme_description,
          keywordInput: "",
          sub_themes_topkeywords:
            themeToEdit?.sub_themes_topkeywords?.split(","),
        });
      } else if (colors?.length) {
        const notUsedColors = colors?.filter((item) => item?.status !== "used");
        setInitialValue({
          sub_theme_color: notUsedColors?.[0]?.value,
          sub_theme_en_name: "",
          sub_theme_ar_name: "",
          sub_theme_description: "",
          keywordInput: "",
          sub_themes_topkeywords: [],
        });
      }
    } else resetForm();
  }, [
    openDialog,
    themeDialogStats?.isEdit,
    themeDialogStats?.themeToEdit,
    colors,
  ]);

  const handleAddKeyword = async (keyword) => {
    if (
      !errors?.keywordInput?.length &&
      values?.sub_themes_topkeywords?.length < 10 &&
      keyword
    ) {
      const newKeywords = [...values?.sub_themes_topkeywords, keyword];
      await setFieldValue("sub_themes_topkeywords", newKeywords);
      setFieldValue("keywordInput", "");
      setFieldTouched("keywordInput", false);
      setShowKeyowrdErrors(true);
    }
  };

  const handleDeleteKeyword = (index) => {
    const newKeywords = values?.sub_themes_topkeywords?.filter(
      (item, i) => i !== index,
    );
    setFieldValue("sub_themes_topkeywords", newKeywords);
  };

  useEffect(() => {
    if (isColorsError)
      setSnackBarData({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
  }, [isColorsError]);

  // To out focus from keyword input field when reached limit
  useEffect(() => {
    if (values?.sub_themes_topkeywords?.length > 9) setKeywordFocused(false);
  }, [values?.sub_themes_topkeywords?.length]);

  return (
    <Box id="theme-dialog-body">
      {isColorsPending ? (
        <CircularLoading />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography className="theme-dialog-input-label">
              {CheckValueLocale("color", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </Typography>
            <FormControl className="theme-dialog-form-control">
              <Select
                id="theme-form-colors-select"
                value={values?.sub_theme_color}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onBlur={handleBlur}
                name="sub_theme_color"
                MenuProps={{ PaperProps: { sx: { maxHeight: 220 } } }}
                size="small"
                error={touched?.sub_theme_color && !!errors?.sub_theme_color}
                renderValue={(value) => (
                  <Box
                    className="select-color-box"
                    sx={{ backgroundColor: value }}
                  ></Box>
                )}
              >
                {colors?.map((color) => {
                  const disabledColor = color?.status === "used";
                  return (
                    <MenuItem
                      className="theme-color-select-item"
                      key={color?.value}
                      value={color?.value}
                      disabled={disabledColor}
                    >
                      <Box
                        component={"span"}
                        className={`select-color-box ${
                          disabledColor ? "used" : ""
                        }`}
                        sx={{ backgroundColor: color?.value }}
                      ></Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <Typography className="theme-dialog-input-label">
              {CheckValueLocale("english_label", "", {}, intl)}
              <Typography component="span" className="required">
                *
              </Typography>
            </Typography>
            <FormControl className="theme-dialog-form-control">
              <TextField
                id="theme-form-input-english-label"
                variant="outlined"
                size="small"
                placeholder={CheckValueLocale(
                  "english_label_placeholder",
                  "",
                  {},
                  intl,
                )}
                value={values?.sub_theme_en_name}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onBlur={handleBlur}
                name="sub_theme_en_name"
                error={
                  touched?.sub_theme_en_name && !!errors?.sub_theme_en_name
                }
                helperText={
                  touched?.sub_theme_en_name &&
                  CheckValueLocale(errors?.sub_theme_en_name, "", {}, intl)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl className="theme-dialog-form-control">
              <Typography className="theme-dialog-input-label">
                {CheckValueLocale("arabic_label", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
              </Typography>
              <TextField
                id="theme-form-input-arabic-label"
                variant="outlined"
                size="small"
                placeholder={CheckValueLocale(
                  "arabic_label_placeholder",
                  "",
                  {},
                  intl,
                )}
                value={values?.sub_theme_ar_name}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onBlur={handleBlur}
                name="sub_theme_ar_name"
                error={
                  touched?.sub_theme_ar_name && !!errors?.sub_theme_ar_name
                }
                helperText={
                  touched?.sub_theme_ar_name &&
                  CheckValueLocale(errors?.sub_theme_ar_name, "", {}, intl)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className="theme-dialog-input-label-wrapper">
              <Typography className="theme-dialog-input-label">
                {CheckValueLocale("describe_theme", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
                <CustomizedTooltip
                  placement={intl?.locale == "ar" ? "left" : "right"}
                  title={CheckValueLocale(
                    "describe_theme_tooltip_msg",
                    "",
                    {},
                    intl,
                  )}
                  arrow
                  styles={{ width: "250px", padding: "13px" }}
                >
                  <Box component="span" className="description-info-icon">
                    <FontAwesomeIcon icon={faCircleInfo} fixedWidth />
                  </Box>
                </CustomizedTooltip>
              </Typography>
              <Typography
                className={`max-char-counter ${
                  values?.sub_theme_description.length > 300 ? "reached" : ""
                }`}
              >
                {values?.sub_theme_description.length}/300
              </Typography>
            </Box>
            <FormControl className="theme-dialog-form-control">
              <TextField
                id="theme-form-input-describe-theme"
                variant="outlined"
                size="small"
                multiline
                minRows={5}
                placeholder={CheckValueLocale(
                  "describe_theme_placeholder",
                  "",
                  {},
                  intl,
                )}
                value={values?.sub_theme_description}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onBlur={handleBlur}
                disabled={themeDialogStats?.isEdit}
                name="sub_theme_description"
                error={
                  touched?.sub_theme_description &&
                  !!errors?.sub_theme_description
                }
                helperText={
                  touched?.sub_theme_description &&
                  CheckValueLocale(errors?.sub_theme_description, "", {}, intl)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className="theme-dialog-form-control">
              <Typography className="theme-dialog-input-label">
                {CheckValueLocale("keywords", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
                <Typography component="span" className="maximum-keywords">
                  {CheckValueLocale("maximum_10", "", {}, intl)}
                </Typography>
              </Typography>
              <OutlinedInput
                variant="outlined"
                className="keywords-field keyword-input-field"
                name="keywordInput"
                placeholder={CheckValueLocale("enter_keywords", "", {}, intl)}
                value={values?.keywordInput || ""}
                onChange={async (e) => {
                  await handleChange(e);
                  setFieldTouched(e?.target?.name, true);
                }}
                onFocus={() => setKeywordFocused(true)}
                onBlur={(e) => {
                  setShowKeyowrdErrors(true);
                  setKeywordFocused(false);
                  handleBlur(e);
                }}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") {
                    e?.preventDefault();
                    handleAddKeyword(values?.keywordInput);
                  }
                }}
                disabled={
                  themeDialogStats?.isEdit ||
                  values?.sub_themes_topkeywords?.length > 9
                }
                endAdornment={
                  keywordFocused ? (
                    <InputAdornment onMouseDown={(e) => e?.preventDefault()}>
                      <Button
                        id="theme-dialog-add-keyword-btn"
                        className="add-btn-with-plus"
                        disabled={
                          errors?.keywordInput ||
                          themeDialogStats?.isEdit ||
                          values?.sub_themes_topkeywords?.length > 9
                        }
                        onClick={() => handleAddKeyword(values?.keywordInput)}
                      >
                        {CheckValueLocale("add_plus", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  ) : null
                }
                error={
                  (touched?.keywordInput && errors?.keywordInput) ||
                  (showKeyowrdErrors && errors?.sub_themes_topkeywords)
                }
              />
              <FormHelperText className="error-helper-text">
                {CheckValueLocale(
                  (touched?.keywordInput && errors?.keywordInput) ||
                    (showKeyowrdErrors && errors?.sub_themes_topkeywords),
                  "",
                  {},
                  intl,
                )}
              </FormHelperText>
              <Box className="keyword-spam-wrapper">
                {values?.sub_themes_topkeywords?.map((item, index) => (
                  <KeywordTooltipChip
                    key={index}
                    onDelete={() => handleDeleteKeyword(index)}
                    disableChip={themeDialogStats?.isEdit}
                    index={index}
                    labels={item}
                  />
                ))}
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ThemeDialogBody;
