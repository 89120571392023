import React, { useCallback, useRef, useState } from "react";
import { Box } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../surveyBuilder.scss";
import { isValidFile } from "utils/helpers";
import SnackBar from "components/snackBar.js";

const LogoDropZone = (props) => {
  const intl = useIntl();
  const inputRefImage = useRef(null);
  const [returnMessage, setRreturnMessage] = useState("");
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    severity: "",
  });

  // Validate file type and size
  const checkValidity = (e, maxFileSize) => {
    if (e.target.files[0] !== undefined) {
      return isValidFile(e, maxFileSize);
    }
  };

  //This function is called when the user clicks on the upload button to initiate the input elemnt
  const handleButtonClick = () => {
    setRreturnMessage("");
    inputRefImage.current.click();
  };

  //This function is called when the user drops the file and validate if it is applicable or not.
  //if the file is valid, it will call the handleUploadLogo function, otherwise it will show a snackbar with the error message
  const onDrop = useCallback(
    async (event) => {
      // 5e6 is 5MB (5000000 bytes)
      const maxFileSize = 5e6;
      let [isValidFile, message] = [true, ""];
      if ((await checkValidity(event, maxFileSize)) !== undefined) {
        [isValidFile, message] = await checkValidity(event, maxFileSize);
        setRreturnMessage(message);
      }

      if (isValidFile == true && returnMessage == "") {
        props?.handleUploadLogo(event.target.files[0], returnMessage);
      } else if (message !== "" && message !== undefined) {
        setSnackBar({
          open: true,
          msg: message,
          severity: "error",
        });
        inputRefImage.current.value = "";
      }
    },
    [checkValidity],
  );

  return (
    <>
      {props?.logoData?.logoUploaded ? (
        <LucButton
          variant="outlined"
          size="small"
          id="logo-upload-done-btn"
          disabled
        >
          <DownloadDoneIcon fontSize="small" />
          {CheckValueLocale("uploaded", "", {}, intl)}
        </LucButton>
      ) : (
        <Box>
          <label
            data-testid="upload"
            for="image-input"
            onClick={handleButtonClick}
          >
            <input
              type="file"
              accept="image/png,image/jpg,image/jpeg"
              id="image-input"
              onChange={onDrop}
              className="modal-input-style"
              ref={inputRefImage}
            />
            <LucButton variant="outline" size="small" id="logo-upload-btn">
              <FileUploadIcon fontSize="small" />
              {props?.logoData?.logoUploading
                ? CheckValueLocale("uploading", "", {}, intl)
                : CheckValueLocale("upload", "", {}, intl)}
            </LucButton>
          </label>
        </Box>
      )}
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.msg, "", {}, intl)}
        handleClose={() => setSnackBar({ open: false, msg: "", severity: "" })}
      />
    </>
  );
};
export default LogoDropZone;
