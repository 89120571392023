import { useIntl } from "react-intl";
import HashtagsHeader from "./components/hashtagsHeader/hashtagsHeader";
import HashtagsTable, {
  getHashtagsListTableHeader,
} from "./components/hashtagsTable/hashtagsTable";
import "./style.scss";
import { useSortableTable } from "hooks/useSortableTable";
import { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import CircularLoading from "components/circularProgress";
import HashtagsFilter from "./components/hashtagsFilter/hashtagsFilter";
import InstagramHashtagsController from "services/controllers/instagramHashtagsController";
import SnackBar from "components/snackBar";
import NoDataFoundProducts from "components/no-Data";

const InstagramHashtags = () => {
  const intl = useIntl();
  const [loaderHeader, setLoaderHeader] = useState(true);
  const [hashtagsHeader, setHashtagsHeader] = useState(null);
  const [searchHashtags, setSearchHashtags] = useState("");
  const [statusHashtags, setStatusHashtags] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [snakBarDelete, setSnakBarDelete] = useState({
    open: false,
    title: "",
    message: "",
    severity: "error",
  });

  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getHashtagsListTableHeader(intl),
    onSortChangeCallback,
    defaultSelectedColumn: "id",
    defaultDirection: "desc",
  });

  useEffect(() => {
    getHashtags();
  }, [sortColumn, sortDirection, statusHashtags]);

  const getHashtags = (page = 1) => {
    if (!isSortableLoading) setLoading(true);
    setPage(page);
    InstagramHashtagsController.getHashtags(
      searchHashtags,
      statusHashtags,
      page,
      sortColumn,
      sortDirection,
    ).then((res) => {
      if (res?.errorMsg) {
        setHashtags([]);
        setPagination(1);
      } else {
        setHashtags(res?.data?.map((item) => item?.attributes));
        setPagination(res?.pagination);
      }
      setLoading(false);
      setIsSortableLoading(false);
    });
  };

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    getHashtags(page);
  };

  const getHashtagsHeader = () => {
    setLoaderHeader(true);
    InstagramHashtagsController?.getHashtagsHeader()?.then((res) => {
      if (res?.data) {
        setHashtagsHeader(res?.data);
      }
      setLoaderHeader(false);
    });
  };

  useEffect(() => {
    getHashtagsHeader();
  }, []);

  const hanldeSearchIconClick = () => {
    setSearchInput(searchHashtags);
    getHashtags();
  };

  const handleCloseSnackbar = () => {
    setSnakBarDelete({
      open: false,
      title: "",
      message: "",
      severity: "error",
    });
  };

  return (
    <>
      {loaderHeader ? (
        <CircularLoading />
      ) : (
        <>
          <HashtagsHeader
            hashtagsHeader={hashtagsHeader}
            hashtags={hashtags}
            searchInput={searchInput}
          />
          <>
            {hashtags?.length > 0 || searchInput ? (
              <HashtagsFilter
                hashtagsHeader={hashtagsHeader}
                searchHashtags={searchHashtags}
                setSearchHashtags={setSearchHashtags}
                hanldeSearchIconClick={hanldeSearchIconClick}
                statusHashtags={statusHashtags}
                setStatusHashtags={setStatusHashtags}
              />
            ) : null}
            <HashtagsTable
              hashtags={hashtags}
              tableHead={tableHead}
              loading={loading}
              isSortableLoading={isSortableLoading}
              searchText={searchHashtags}
              statusHashtags={statusHashtags}
              getHashtags={getHashtags}
              setSnakBarDelete={setSnakBarDelete}
              page={page}
            />
            {pagination?.pages > 1 && !isSortableLoading ? (
              <Pagination
                onChange={handleChangePage}
                page={page}
                className="pagination-monitor-list"
                count={pagination?.pages}
                variant="outlined"
                rowsPerPage={pagination?.items}
              />
            ) : (
              <div className="pagination-one-page"></div>
            )}
          </>
        </>
      )}
      <SnackBar
        open={snakBarDelete?.open}
        severity={snakBarDelete?.severity}
        message={snakBarDelete?.message}
        title={snakBarDelete?.title}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};
export default InstagramHashtags;
