import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  TextField,
  InputAdornment,
  InputLabel,
  Grid,
} from "@mui/material";
import "./engagementComplete.scss";
import PopupModal from "components/popupModal";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  faUserCheck,
  faTicketAlt,
  faCommentDots,
  faCommentMinus,
  faBan,
  faQuestionSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";

function EngagementComplete(props) {
  const intl = useIntl();
  const [hasError, setHasError] = useState(false);
  const [hasSpecialCharacters, sethasSpecialCharacters] = useState(false);
  let {
    updateStatus,
    openPopup,
    completionContent,
    setOpenPopup,
    completionReason,
    setCompletionReason,
    valueInput,
    setValueInput,
    handelClosePopup,
    disabledAction,
  } = props;
  const icons = (type) => {
    let iconVal = {
      resolved: (
        <div>
          <FontAwesomeIcon className="folder-icon" icon={faUserCheck} />
        </div>
      ),
      ticket_created: (
        <div>
          <FontAwesomeIcon className="folder-icon" icon={faTicketAlt} />
        </div>
      ),
      ticket_followup: (
        <div>
          <FontAwesomeIcon className="folder-icon" icon={faCommentDots} />
        </div>
      ),
      no_answer_needed: (
        <div>
          <FontAwesomeIcon className="folder-icon" icon={faCommentMinus} />
        </div>
      ),
      spam: (
        <div>
          <FontAwesomeIcon className="folder-icon" icon={faBan} />
        </div>
      ),
      other: (
        <div>
          <FontAwesomeIcon className="folder-icon" icon={faQuestionSquare} />
        </div>
      ),
    };
    return iconVal[type];
  };

  const handleChange = (event) => {
    setCompletionReason(event.target.value);
    if (completionReason.length !== 0) {
      setValueInput("");
    }
  };
  const handleChangeValue = (event) => {
    const value = event?.target?.value;
    const match =
      isPublicSpecialCharactersOrEmojis(value, undefined, true) ||
      removeSpecialCharacters(value)?.length === 0;

    if (!match || value?.length === 0) {
      sethasSpecialCharacters(false);
    } else {
      sethasSpecialCharacters(true);
    }
    setValueInput(value);

    if (value?.length > 40) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  let body = () => {
    return (
      <Box className="complete-body">
        <FormControl>
          <FormLabel
            className="engagement-marked"
            id="demo-controlled-radio-buttons-group"
          >
            {CheckValueLocale("why-marked-complete", "", {}, intl)}
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={completionReason}
            onChange={handleChange}
          >
            <Box className="radio-buttons-box">
              {completionContent?.map((item, index) => {
                return (
                  <Tooltip
                    title={CheckValueLocale(item?.tooltip, "", {}, intl)}
                    placement="top"
                    arrow
                    id="radio-buttons-box"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: "159px",
                        },
                      },
                    }}
                  >
                    <Box className="item-box">
                      <FormControlLabel
                        className="item-check"
                        value={item?.name}
                        control={<Radio className="radio-icon" />}
                        label={
                          <Box className="item-content">
                            <Typography component={"span"}>
                              {icons(item?.name)}
                            </Typography>
                            <Typography className="tooltip-btn">
                              {CheckValueLocale(item?.name, "", {}, intl)}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          </RadioGroup>
        </FormControl>
        <Box>
          {completionReason == "other" ? (
            <TextField
              error={hasError ? "error" : null}
              className="type-reason"
              onChange={(e) => handleChangeValue(e)}
              InputProps={{
                maxLength: 40,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={!hasError ? "input-adornment" : "err-msg"}
                  >
                    {valueInput?.length}
                    {"/" + 40}
                  </InputAdornment>
                ),
              }}
              id="standard-error-helper-text"
              label={CheckValueLocale("type_reason", "", {}, intl)}
              value={valueInput}
              defaultValue={CheckValueLocale("no_reason", "", {}, intl)}
              helperText={
                hasError ? (
                  hasError ? (
                    <span className="err-msg">
                      {CheckValueLocale("err-long-characters", "", {}, intl)}
                    </span>
                  ) : null
                ) : hasSpecialCharacters ? (
                  <span className="err-msg">
                    {CheckValueLocale(
                      "err_no_special_characters",
                      "",
                      {},
                      intl,
                    )}
                  </span>
                ) : null
              }
            />
          ) : null}
        </Box>
      </Box>
    );
  };
  let checkDisabled =
    !completionReason ||
    hasError ||
    hasSpecialCharacters ||
    (completionReason == "other" && !valueInput);
  return (
    <div>
      <PopupModal
        title={CheckValueLocale("reason_completion", "", {}, intl)}
        body={body()}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("submit", "", {}, intl)}
        open={openPopup}
        close={() => handelClosePopup()}
        accept={updateStatus}
        disabled={checkDisabled || disabledAction}
        isRightBtnLoading={disabledAction}
        disableLeftBtn={disabledAction}
      />
    </div>
  );
}

export default EngagementComplete;
