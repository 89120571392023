import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SegmentCheckBox = ({
  checked,
  onChange,
  isAllProfile,
  profilesCount,
  isSelectAllProfiles,
  isRemoveIcon,
  selectAll,
}) => {
  const intl = useIntl();
  let checkSelectedMsg =
    isSelectAllProfiles || selectAll === false
      ? "select_all_profiles"
      : "clear_selection";

  return (
    <div
      className={
        isAllProfile
          ? "btn-select-all"
          : `segment-checkbox ${
              checked || isRemoveIcon ? "segment-active " : ""
            }`
      }
      onClick={onChange}
    >
      {isAllProfile &&
        CheckValueLocale(checkSelectedMsg, "", { number: profilesCount }, intl)}
    </div>
  );
};

export default SegmentCheckBox;
