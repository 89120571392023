import { useQuery } from "@tanstack/react-query";
import MonitorsController from "services/controllers/monitorsController.js";

export const useMonitors = ({
  page = 1,
  search,
  sortColumn,
  sortDirection,
  redirectedMonitorId,
}) => {
  const activeProducts = JSON.parse(localStorage.getItem("activeProducts"));
  const isCXMActive = activeProducts
    .map((product) => product.name)
    .includes("CXM");
  const cxmId = localStorage.getItem("cxm_id");

  return useQuery({
    queryKey: [
      "monitors",
      page,
      sortColumn,
      sortDirection,
      redirectedMonitorId,
      search,
    ],
    queryFn: async () =>
      MonitorsController.channels(
        cxmId,
        search,
        page,
        sortColumn,
        sortDirection,
        redirectedMonitorId,
      ),
    enabled: !!isCXMActive && !!cxmId,
    select: (data) => {
      const newData = data?.data?.map((monitor) => {
        if (monitor?.attributes?.account_tiktok_token_error) {
          monitor?.attributes?.account_error?.push(
            "account_tiktok_token_error",
          );
        }
        return monitor;
      });
      return { ...data, data: newData };
    },
  });
};
