import { Tooltip, Chip } from "@mui/material";

const KeywordChip = (props) => {
  const {
    labels,
    index,
    setKeywordValue,
    setShowTendingRecent,
    inputName,
    setIsClear,
    setKeywords,
    spamKeywords,
    keywords,
    setSpamKeywords,
    setFormFieldErrors,
    formFieldErrors,
    setDisableCreateExplore,
  } = props;
  const handleClick = () => {
    if (keywords?.includes(labels) || spamKeywords?.includes(labels)) {
      setFormFieldErrors({
        ...formFieldErrors,
        [inputName]: "keyword_cant_be_duplicate",
      });
    } else {
      if (inputName === "keywords") {
        setKeywords([...keywords, labels]);
      } else {
        setSpamKeywords([...spamKeywords, labels]);
      }
    }
    setDisableCreateExplore(false);
    setShowTendingRecent(false);
    setIsClear(false);
  };
  return (
    <>
      <Tooltip title={labels?.length > 36 ? labels : ""} placement="top" arrow>
        <Chip
          key={index}
          label={labels}
          onClick={handleClick}
          className="chip-keyword"
        />
      </Tooltip>
    </>
  );
};

export default KeywordChip;
