import { useState, useEffect } from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import { CheckValueLocale, getTimeZone } from "utils/helpers";
import { useIntl } from "react-intl";
import AccountsSelect from "./accountsPopupSelection";
import CloseIcon from "@mui/icons-material/Close";
import DateTimePickerPublish from "./dateTimePicker";
import dayjs from "dayjs";
import { Add } from "@mui/icons-material";
import PublicIcon from "@mui/icons-material/Public";
import moment from "moment";
import _ from "lodash";
import Reply from "./publishContentMedia";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import EngagementsController from "services/controllers/engagementsController";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LucButton from "shared/lucButton/lucButton";

const PublishPopupContent = (props) => {
  const intl = useIntl();
  const {
    popupTitle,
    handleClose,
    setOpenPublish,
    publishedTypeSelected,
    setPublishedTypeSelected,
    publishSpecifiedDate,
    contentSelected,
    accountsPublishSelected,
    contentErrormedia,
    setContentErrorMedia,
    selectedFile,
    twLimitMedia,
    fbLimitMedia,
    igLimitMedia,
    text,
    setShowAlertActionMessage,
    setShowSnackBar,
    getCalendarData,
    isPostsLoading,
    handleDateFormat,
    assignActiveFilters,
    date,
    selectedAccoutnts,
    companyTimeZone,
    setIsPostsLoading,
    publishTypeEvent,
    selectedEventDetails,
    timesEdite,
    getFormatTimeZoneDate,
    imagesDataEdit,
    setPublishTypeEvent,
    attachementsDeleted,
  } = props;
  let postsTypes = ["scheduled", "direct"];

  let timeZone = window.localStorage.companyInfo;
  const currentDate = new Date();
  //get accounts format to BE
  const getAccountsFormat = () => {
    let newData = [];
    accountsPublishSelected?.map((item) => {
      newData.push({
        reference_id: item?.reference_id,
        data_source: item?.data_source,
      });
    });
    return newData;
  };

  let cuuDateUNix =
    moment(currentDate).unix() -
    getTimeZone() * 60 * 60 +
    parseInt(localStorage.companyInfo) * 60 * 60;
  let dateNews = new Date(cuuDateUNix * 1000);
  let formattedDates = dateNews?.toISOString();
  const curentdateVal = new Date(formattedDates);
  const [datesTimePicker, setDatesTimePicker] = useState([
    { date: dayjs(publishSpecifiedDate)?.$d, is_past: "" },
  ]);

  useEffect(() => {
    if (publishSpecifiedDate && publishTypeEvent !== "edit_publish") {
      let cuuDateUNix =
        moment(currentDate).unix() -
        getTimeZone() * 60 * 60 +
        parseInt(localStorage.companyInfo) * 60 * 60;
      let dateNews = new Date(cuuDateUNix * 1000);
      let formattedDates = dateNews?.toISOString();
      const dd = new Date(formattedDates);

      setDatesTimePicker([
        {
          date: dayjs(publishSpecifiedDate).$d,
          is_past: publishSpecifiedDate?.getTime() < dd?.getTime(),
        },
      ]);
    }
  }, [publishSpecifiedDate]);
  useEffect(() => {
    if (selectedEventDetails?.id && publishTypeEvent === "edit_publish") {
      let cuuDateUNix =
        moment(currentDate).unix() -
        getTimeZone() * 60 * 60 +
        parseInt(localStorage.companyInfo) * 60 * 60;
      let dateNews = new Date(cuuDateUNix * 1000);
      let formattedDates = dateNews?.toISOString();
      const dd = new Date(formattedDates);

      let datesEdit = [];
      timesEdite?.map((el) => {
        datesEdit.push({
          date: dayjs(getFormatTimeZoneDate(el?.scheduled_time)).$d,
          is_past:
            new Date(getFormatTimeZoneDate(el?.scheduled_time)).getTime() <
            dd?.getTime(),
        });
      });
      setDatesTimePicker([...datesEdit]);
    }
  }, [timesEdite, publishTypeEvent, selectedEventDetails]);

  //get dates time to BE
  const getFormetDateTime = () => {
    let timeDateData = [];
    datesTimePicker?.map((item) => {
      let unixDateVal =
        moment(item?.date).unix() -
        parseInt(companyTimeZone) * 60 * 60 +
        getTimeZone() * 60 * 60;

      timeDateData.push(unixDateVal?.toString());
    });

    return timeDateData;
  };

  const reAssignPastTime = () => {
    let dataTimes = [...datesTimePicker];
    let newDates = [];
    dataTimes?.map((el) => {
      newDates.push({
        date: el?.date,
        is_past: el?.date?.getTime() < curentdateVal?.getTime(),
      });
    });
    return [...newDates];
  };
  const handleAddTimePicker = () => {
    let allDates = _.cloneDeep([...datesTimePicker]);

    let newDateVal = moment(datesTimePicker[datesTimePicker?.length - 1]?.date)
      .add(1, "day")
      .toDate();
    allDates.push({
      date: moment(datesTimePicker[datesTimePicker?.length - 1]?.date)
        .add(1, "day")
        .toDate(),
      is_past: newDateVal?.getTime() < curentdateVal?.getTime(),
    });
    setDatesTimePicker(allDates);
  };
  let isTwExist = accountsPublishSelected?.some(
    (item) => item?.data_source?.toLowerCase() === "twitter",
  );
  let isIgExist = accountsPublishSelected?.some(
    (item) => item?.data_source?.toLowerCase() === "instagram",
  );
  let isFbExist = accountsPublishSelected?.some(
    (item) => item?.data_source?.toLowerCase() === "facebook",
  );
  // here we check limits for media content
  const getMediaLessLimits = () => {
    let twText = twLimitMedia?.text?.constraints?.text_length;
    let twMedia = twLimitMedia?.image?.constraints.number_of_media;
    let fbText = fbLimitMedia?.text?.constraints?.text_length;
    let fbMedia = fbLimitMedia?.image?.constraints?.number_of_media;
    let igText = igLimitMedia?.text?.constraints?.text_length;
    let igMedia = igLimitMedia?.image?.constraints?.number_of_media;
    let minimumValueText = twText;
    let minimumValueNoMedia = twMedia;
    let datasourceKey = "tw";
    if (isTwExist) {
      minimumValueText = Math.min(twText, fbText, igText);
      minimumValueNoMedia = Math.min(twMedia, fbMedia, igMedia);
      datasourceKey = "tw";
    } else if (isFbExist) {
      minimumValueText = Math.min(fbText, igText);
      minimumValueNoMedia = Math.min(fbMedia, igMedia);
      datasourceKey = "fb";
    } else {
      minimumValueText = igText;
      minimumValueNoMedia = igMedia;
      datasourceKey = "ig";
    }
    return { minimumValueText, minimumValueNoMedia, datasourceKey };
  };
  //check media errors
  const handleMediaErrorMsg = () => {
    let errorMediaMsg;
    let resLimit = getMediaLessLimits();
    //handle media for datasources
    if (isIgExist && !(selectedFile?.length + imagesDataEdit?.length)) {
      errorMediaMsg = "publish_err_ig_had_one_media_limit";
    } else if (
      !isIgExist &&
      !(selectedFile?.length + imagesDataEdit?.length) &&
      !text?.length
    ) {
      errorMediaMsg = "publish_content_needed_error";
    } else if (
      selectedFile?.length + imagesDataEdit?.length >
      resLimit?.minimumValueNoMedia
    ) {
      errorMediaMsg = `publish_${resLimit?.datasourceKey}_limit_images`;
    } else if (text?.length > resLimit?.minimumValueText) {
      errorMediaMsg = `publish_content_limit_text`;
    } else {
      errorMediaMsg = "";
    }
    setContentErrorMedia(errorMediaMsg);
    return errorMediaMsg;
  };

  const checkErrorTime = () => {
    let isErrorExist = datesTimePicker?.some((item) => item?.is_past == true);
    return isErrorExist;
  };
  let checkDisabledScheduleBtn =
    !accountsPublishSelected?.length ||
    contentErrormedia ||
    (selectedEventDetails?.attributes?.status == "published" &&
    publishTypeEvent == "edit_publish"
      ? false
      : publishedTypeSelected == "scheduled"
        ? checkErrorTime()
        : false);

  const handleScheduleSubmit = () => {
    let objData = {
      scheduled_posts: getAccountsFormat(),
      scheduled_request: {
        product_id: window?.localStorage?.engagements_id,
        text: text,
        publishing_type: publishedTypeSelected,
      },
      scheduled_times:
        publishedTypeSelected == "scheduled" ? getFormetDateTime() : [],
    };

    const formDataFile = new FormData();
    selectedFile?.map((el, index) => {
      formDataFile.append(`attachments_${index}`, el);
    });
    if (selectedFile?.length) {
      formDataFile.append("attachments_size", selectedFile?.length);
    }
    formDataFile.append("scheduled", JSON.stringify(objData));

    let resMessages = handleMediaErrorMsg();
    let isErrorExistDateAfter = reAssignPastTime()?.some(
      (item) => item?.is_past == true,
    );
    let errorTimeExist =
      publishedTypeSelected == "scheduled" ? !isErrorExistDateAfter : true;
    setDatesTimePicker(reAssignPastTime());
    if (!resMessages && errorTimeExist) {
      callSubmitScheduleApi(formDataFile);
    }
  };
  ///edit two api for edit
  const handleScheduleSubmitEdit = () => {
    let objData = {
      scheduled_posts: getAccountsFormat(),
      scheduled_request: {
        product_id: window?.localStorage?.engagements_id,
        text: text,
        publishing_type: publishedTypeSelected,
      },
      scheduled_times:
        publishedTypeSelected == "scheduled" ? getFormetDateTime() : [],
    };

    const formDataFile = new FormData();
    selectedFile?.map((el, index) => {
      formDataFile.append(`attachments_${index}`, el);
    });
    if (selectedFile?.length) {
      formDataFile.append("attachments_size", selectedFile?.length);
    }

    formDataFile.append("scheduled", JSON.stringify(objData));
    if (
      imagesDataEdit?.length <
      selectedEventDetails?.attributes?.attachments?.length
    )
      formDataFile.append(
        "delete_attachments",
        JSON.stringify(attachementsDeleted),
      );
    let resMessages = handleMediaErrorMsg();
    let isErrorExistDateAfter = reAssignPastTime()?.some(
      (item) => item?.is_past == true,
    );
    let errorTimeExist =
      publishedTypeSelected == "scheduled" ? !isErrorExistDateAfter : true;
    setDatesTimePicker(reAssignPastTime());
    if (!resMessages && errorTimeExist) {
      HandleEditScheduleApi(formDataFile, selectedEventDetails?.id);
    }
  };
  const HandleEditScheduleApi = (queryData, id) => {
    //create new post publish
    setIsPostsLoading(true);
    EngagementsController.EditScheduledPost(queryData, id).then((res) => {
      if (res?.data?.status === 201 || res?.data?.status === 200) {
        setShowAlertActionMessage({
          severity: "success",
          message: "published_post_edit_success",
        });
        let { startDate, endDate } = handleDateFormat(date);
        getCalendarData(
          startDate,
          endDate,
          assignActiveFilters,
          selectedAccoutnts,
        );
      } else {
        setShowAlertActionMessage({
          severity: "error",
          message: "published_post_fail_body",
          title: "published_post_fail_title",
        });
        setIsPostsLoading(false);
      }
      setShowSnackBar(true);
    });
  };

  ////

  const callSubmitScheduleApi = (queryData) => {
    //create new post publish
    setIsPostsLoading(true);
    EngagementsController.createPostPublish(queryData).then((res) => {
      if (res?.status_code === 201 || res?.status_code === 200) {
        setShowAlertActionMessage({
          severity: "success",
          message: "published_post_added_success",
        });
        let { startDate, endDate } = handleDateFormat(date);
        getCalendarData(
          startDate,
          endDate,
          assignActiveFilters,
          selectedAccoutnts,
        );
      } else {
        setShowAlertActionMessage({
          severity: "error",
          message: "published_post_fail_body",
          title: "published_post_fail_title",
        });
        setIsPostsLoading(false);
      }
      setShowSnackBar(true);
    });
  };

  //incase api published

  const handleEditTypePublished = () => {
    let objData = {
      id: selectedEventDetails?.attributes?.post_id,
      data_source_id: selectedEventDetails?.attributes?.data_source_id,
      reference_id: selectedEventDetails?.attributes?.account_info_reference_id,
      text: text,
      product_id: window?.localStorage?.engagements_id,
    };
    const formDataFile = new FormData();
    selectedFile?.map((el, index) => {
      formDataFile.append(`attachments_${index}`, el);
    });
    if (selectedFile?.length) {
      formDataFile.append("attachments_size", selectedFile?.length);
    }

    formDataFile.append("published", JSON.stringify(objData));
    if (
      imagesDataEdit?.length <
      selectedEventDetails?.attributes?.attachments?.length
    )
      formDataFile.append(
        "delete_attachments",
        JSON.stringify(attachementsDeleted),
      );
    let resMessages = handleMediaErrorMsg();

    setDatesTimePicker(reAssignPastTime());
    if (!resMessages) {
      HandleEditPublisedApi(formDataFile, selectedEventDetails?.id);
    }
  };

  const HandleEditPublisedApi = (queryData, id) => {
    //create new post publish
    setIsPostsLoading(true);
    EngagementsController.editPostPublished(queryData, id).then((res) => {
      if (res?.status_code === 201 || res?.status_code === 200) {
        setShowAlertActionMessage({
          severity: "success",
          message: "published_post_edit_success",
        });
        let { startDate, endDate } = handleDateFormat(date);
        getCalendarData(
          startDate,
          endDate,
          assignActiveFilters,
          selectedAccoutnts,
        );
      } else {
        setShowAlertActionMessage({
          severity: "error",
          message: "published_post_fail_body",
          title: "published_post_fail_title",
        });
        setIsPostsLoading(false);
      }
      setShowSnackBar(true);
    });
  };

  let callApiSumit =
    publishTypeEvent == "create_publish"
      ? handleScheduleSubmit
      : selectedEventDetails?.attributes?.status == "published"
        ? handleEditTypePublished
        : handleScheduleSubmitEdit;
  return (
    <Box className="publish-pop-up-wrapper">
      <Box className="publish-pop-up-header">
        <Typography
          component={"p"}
          className={`publish-new-post-title ${
            publishTypeEvent == "edit_publish" && "publish-new-post-title-edit"
          }`}
        >
          {publishTypeEvent == "create_publish" ? null : (
            <ArrowForwardIcon
              className="arrow-publish-edit"
              onClick={() => setPublishTypeEvent("show_publish")}
            />
          )}
          {CheckValueLocale(popupTitle, "", {}, intl)}
        </Typography>
        <Box onClick={handleClose} className="publish-close-icon">
          <CloseIcon />
        </Box>
      </Box>
      <Divider className="publish-new-post-divider" />
      <Box className="publish-popup-content-container">
        <Box className="publish-pop-up-main-content">
          <AccountsSelect {...props} setOpenPublish={setOpenPublish} />
        </Box>
        <Box>
          <Box className="publish-content-info">
            <Typography component={"p"} className="publish-content-media-title">
              {CheckValueLocale("publish_content_title", "", {}, intl)}
            </Typography>
            <Link
              to={{
                pathname: `/publish/user_guide`,
              }}
              className="top-posts-all"
              id="publish-content-guide"
              target="_blank"
            >
              {CheckValueLocale("publish_content_guide_title", "", {}, intl)}
              <LaunchIcon sx={{ fontSize: "medium" }} className="icon-link" />
            </Link>
          </Box>
          <Box
            className={`publish-reply-content-container ${
              contentSelected && "focused"
            } ${contentErrormedia && "error"}`}
          >
            <Reply
              replyType="public"
              {...props}
              accountsSelected={accountsPublishSelected}
              setContentErrorMedia={setContentErrorMedia}
              getMediaLessLimits={getMediaLessLimits}
            />
          </Box>
          {contentErrormedia ? (
            <Box className="content-error-msg-media">
              {CheckValueLocale(
                contentErrormedia,
                "",
                { limit: getMediaLessLimits()?.minimumValueText },
                intl,
              )}
            </Box>
          ) : null}
        </Box>
        {selectedEventDetails?.attributes?.status != "published" ||
        publishTypeEvent == "create_publish" ? (
          <Box className="scheule-publish-types">
            {postsTypes?.map((element) => {
              return (
                <Box
                  className={`publish-type-content ${
                    publishedTypeSelected === element && "selected"
                  }`}
                  onClick={() => setPublishedTypeSelected(element)}
                >
                  {CheckValueLocale(`${element}_posts`, "", {}, intl)}
                </Box>
              );
            })}
          </Box>
        ) : null}
        {(selectedEventDetails?.attributes?.status != "published" &&
          publishTypeEvent == "edit_publish" &&
          publishedTypeSelected == "scheduled") ||
        (publishTypeEvent == "create_publish" &&
          publishedTypeSelected == "scheduled") ? (
          <Box className="schedule-date-publish-conntainer">
            <Typography component={"p"} className="publish-schedule-title">
              {CheckValueLocale("publish_schedule_date", "", {}, intl)}
            </Typography>
            {datesTimePicker?.map((el, index) => {
              return (
                <Box key={index}>
                  <DateTimePickerPublish
                    {...props}
                    dateValue={el?.date}
                    index={index}
                    isPast={el?.is_past}
                    datesTimePicker={datesTimePicker}
                    setDatesTimePicker={setDatesTimePicker}
                  />
                  {el?.is_past ? (
                    <Box className="publish-ivalid-date-msg">
                      {CheckValueLocale(
                        `publish_invalid_date_time`,
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  ) : null}
                </Box>
              );
            })}
            <Button
              onClick={handleAddTimePicker}
              variant="text"
              className="publish-add-time-picker"
              startIcon={<Add className="add-time-icon" />}
              id="add-publish-time-btn"
            >
              {CheckValueLocale("publish_new_time", "", {}, intl)}
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box className="publish-pop-up-footer">
        <Box className="time-zone-content">
          <PublicIcon className="public-gmt-icon" />
          <Typography component={"p"} className="publish-gmt-title">
            {`${CheckValueLocale("gmt", "", {}, intl)} 
            ${timeZone >= 0 ? "+" + timeZone : timeZone}`}
          </Typography>
        </Box>
        <Box>
          <LucButton
            variant="flat"
            type="secondary"
            size="small"
            color="inherit"
            onClick={handleClose}
            id={`${publishedTypeSelected}_publish-pop-up-cancel`}
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            onClick={callApiSumit}
            loading={isPostsLoading}
            className={`btn-create-scedule-popup`}
            id={`${publishedTypeSelected}_publish-pop-up-create`}
            disabled={checkDisabledScheduleBtn}
          >
            {CheckValueLocale(
              publishTypeEvent == "create_publish"
                ? `${publishedTypeSelected}_posts`
                : "publish_apply_changes",
              "",
              {},
              intl,
            )}
          </LucButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PublishPopupContent;
