import React, { useEffect, useState } from "react";
import { MonitorContainer } from "pages/Monitors/Components/sidebar/monitor.styles";
import PostsBody from "./postsBody";
import Header from "../../../../Monitors/Components/header";
import { isEmptyArray } from "utils/helpers";

const PostsPage = (props) => {
  const {
    response,
    resetAllFields,
    changePagination,
    pageNumber,
    widgetsName,
    dashboardDynamicData,
    singleTweetAnalysis,
    setTotalPage,
    totalPage,
    totalPageTw,
    setTotalPageTw,
    totalPageFb,
    setTotalPageFb,
    totalPageIg,
    setTotalPageIg,
    totalPageNb,
    setTotalPageNb,
    activePostsTab,
    setIsActivePostsTab,
  } = props;
  const [sortBy, setSortBy] = useState("latest_posts");
  const sortByData = ["latest_posts", "most_interactions"];
  const metricsValues = dashboardDynamicData?.metrics_values;

  const handleChange = (event) => {
    setSortBy(event.target.value);
    props.setFilterParams({
      ...props.filterParams,
      sort_by: event.target.value,
    });
    props.applyFilter(props.startDate, props.endDate, {
      ...props.filterParams,
      sort_by: event.target.value,
    });
  };

  useEffect(() => {
    if (props.filterParams["sort_by"] !== undefined) {
      setSortBy(props.filterParams["sort_by"]);
    } else {
      setSortBy("latest_posts");
    }
  }, [props.filterParams["sort_by"]]);

  const getActiveTotalValue = (datasourceType) => {
    let allDatasource = {
      TWITTER: totalPageTw,
      FACEBOOK: totalPageFb,
      INSTAGRAM: totalPageIg,
      NEWSBLOGS: totalPageNb,
    };
    return allDatasource[datasourceType] || "";
  };
  const unixDate = {
    start: props.startDate,
    end: props.endDate,
  };
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {widgetsName?.includes("top_posts_comments") && (
        <>
          {response?.eventData !== undefined && (
            <Header
              title="total_posts"
              titleValue={totalPage}
              sortByData={sortByData}
              sortBy={sortBy}
              handleChange={handleChange}
            />
          )}
          <PostsBody
            unixDate={unixDate}
            response={response}
            resetAllFields={resetAllFields}
            changePagination={changePagination}
            pageNumber={pageNumber}
            setTotalPage={setTotalPage}
            totalPage={totalPage}
            setTotalPageTw={setTotalPageTw}
            setTotalPageIg={setTotalPageIg}
            setTotalPageFb={setTotalPageFb}
            setTotalPageNb={setTotalPageNb}
            totalPageTw={totalPageTw}
            totalPageIg={totalPageIg}
            totalPageFb={totalPageFb}
            totalPageNb={totalPageNb}
            singleTweetAnalysis={singleTweetAnalysis}
            setIsActivePostsTab={setIsActivePostsTab}
            activePostsTab={activePostsTab}
            getActiveTotalValue={getActiveTotalValue}
            metricsValues={metricsValues}
            {...props}
          />
        </>
      )}
    </MonitorContainer>
  );
};

export default PostsPage;
