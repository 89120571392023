import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  startDateUnix: moment().subtract(6, "d").startOf("day").unix(),
  endDateUnix: moment().unix(),
  startDateUnixToDisplay: moment().subtract(6, "d").startOf("day").unix(),
  endDateUnixToDisplay: moment().unix(),
  startDate: null,
  endDate: null,
  filterFields: [],
  filterParams: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDownParams: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDownParamsCopy: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDown: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  filterDropDownApplyFilter: {
    gender_filter: [],
    main_dialect_filter: [],
    sub_dialect_filter: [],
    monitor_list: [],
    sentiment_filter: [],
    sub_themes_filter: [],
    themes_filter: [],
    waiting_filter: [],
    cities_filter: [],
    countries_filter: [],
    languages_filter: [],
    categories: [],
    interaction_type: [],
    dm_list: [],
    channels: [],
    locations: [],
    with_comment: [],
    manual_topics: [],
    call_status: [],
    keyword: [],
    question_status: [],
    parent_id: "",
  },
  pickerState: "last_7_days",
  showDeleteFilterBtn: false,
  dataSource: "",
  filterParamsCount: 0,
  applicableFilters: {},
  themesOptions: [],
};

const channelAnalyticsSlice = createSlice({
  name: "channelAnalytics",
  initialState,
  reducers: {
    clear: (state) => {
      state.filterParams = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDownParams = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDownParamsCopy = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDown = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.filterDropDownApplyFilter = {
        gender_filter: [],
        main_dialect_filter: [],
        sub_dialect_filter: [],
        monitor_list: [],
        sentiment_filter: [],
        sub_themes_filter: [],
        themes_filter: [],
        waiting_filter: [],
        cities_filter: [],
        countries_filter: [],
        languages_filter: [],
        categories: [],
        interaction_type: [],
        dm_list: [],
        channels: [],
        locations: [],
        with_comment: [],
        manual_topics: [],
        call_status: [],
        keyword: [],
        question_status: [],
        parent_id: "",
      };
      state.showDeleteFilterBtn = false;
      state.pickerState = "last_7_days";
      state.filterParamsCount = 0;
    },
    filters: (state, action) => {
      state.filterParams = action?.payload;
    },
    filtersDropDown: (state, action) => {
      state.filterDropDownParams = action?.payload;
    },
    filtersDropDownCopy: (state, action) => {
      state.filterDropDownParamsCopy = action?.payload;
    },
    filtersDropDownApplyFilter: (state, action) => {
      state.filterDropDownApplyFilter = action?.payload;
    },
    filtersDropDownToDisplay: (state, action) => {
      state.filterDropDown = action?.payload;
    },
    filtersCount: (state, action) => {
      state.filterParamsCount = action?.payload;
    },
    reduxApplicableFilters: (state, action) => {
      state.applicableFilters = action?.payload;
    },
    reduxDatesUnixCA: (state, action) => {
      state.startDateUnix = action?.payload?.startDate;
      state.endDateUnix = action?.payload?.endDate;
    },
    reduxDatesUnixToDisplayCA: (state, action) => {
      state.startDateUnixToDisplay = action?.payload?.startDate;
      state.endDateUnixToDisplay = action?.payload?.endDate;
    },
    reduxDatesCA: (state, action) => {
      state.startDate = action?.payload?.startCustomDate;
      state.endDate = action?.payload?.endCustomDate;
    },
    reduxDatePickerStateCA: (state, action) => {
      state.pickerState = action?.payload;
    },
    reduxDatasource: (state, action) => {
      state.dataSource = action?.payload;
      state.filterParams = {};
      state.showDeleteFilterBtn = false;
    },
    reduxThemesOptions: (state, action) => {
      state.themesOptions = action?.payload;
    },
  },
});

export default channelAnalyticsSlice.reducer;
export const {
  clear,
  filtersFields,
  filters,
  filtersDropDown,
  activeFilters,
  filtersCount,
  reduxApplicableFilters,
  reduxDatesCA,
  reduxDatePickerStateCA,
  reduxDatesUnixCA,
  reduxDatesUnixToDisplayCA,
  reduxDatasource,
  filtersDropDownToDisplay,
  filtersDropDownCopy,
  filtersDropDownApplyFilter,
  reduxThemesOptions,
} = channelAnalyticsSlice.actions;
