import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import TestYourKeyword from "../../../../../MonitorsShared/SharedComponent/testYourKeyword/testYourKeyword";
import HashtagUsedNumber from "../keywordHeader/hashtagUsedNumber";
import SharedFunctions from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctions";
import { CheckValueLocale, isEmptyArray } from "utils/helpers/index";

const KeywordListItemComponent = (props) => {
  const intl = useIntl();
  const {
    resp,
    keywords,
    twitterWordsCounter,
    wordCountFacebook,
    wordCountInstagram,
    wordCountNews,
    wordCountNewsSpamKeyword,
    language,
    countries,
    majorSource,
    nbExcludedSource,
    titleSearchKeyword,
    newsBlogCount,
    value,
    twitterKeywordCounter,
    monitorInstagramHashtagLimit,
    instagramAccounts,
  } = props;

  const { countTitleSearchKeywords } = SharedFunctions();

  return (
    <>
      {/* twitter values */}
      {value &&
      value === "twitter" &&
      twitterWordsCounter + twitterKeywordCounter > 0 ? (
        <>
          <TestYourKeyword
            keywords={
              keywords[0]?.twitter[0]?.keywords !== undefined
                ? keywords[0].twitter[0].keywords
                : ""
            }
            spamKeywords={
              keywords[0]?.twitter[1]?.spam_keywords !== undefined
                ? keywords[0]?.twitter[1]?.spam_keywords
                : ""
            }
          />
        </>
      ) : null}
      {/* instagram value */}
      {value && !isEmptyArray(instagramAccounts) && value === "instagram" && (
        <>
          <HashtagUsedNumber
            wordCountDataSource={wordCountInstagram}
            limitationOfDataSource={monitorInstagramHashtagLimit}
            instaHashtag
          />
          <TestYourKeyword
            keywords={
              keywords[1]?.instagram[0]?.hashtags !== undefined
                ? keywords[1].instagram[0].hashtags
                : ""
            }
            spamKeywords={
              keywords[1]?.instagram[1]?.spam_hashtags !== undefined
                ? keywords[1].instagram[1].spam_hashtags
                : ""
            }
          />
        </>
      )}

      {/* facebook value */}
      {value &&
        value === "facebook" &&
        keywords[2]?.facebook[0]?.keywords?.length !== 0 && (
          <>
            {keywords[2]?.facebook[0]?.keywords.length !== 0 && (
              <TestYourKeyword
                keywords={
                  keywords[2]?.facebook[0]?.keywords !== undefined
                    ? keywords[2].facebook[0].keywords
                    : ""
                }
                spamKeywords={
                  keywords[2]?.facebook[1]?.spam_keywords !== undefined
                    ? keywords[2].facebook[1].spam_keywords
                    : ""
                }
              />
            )}
          </>
        )}

      {/* news & blogs values */}
      {titleSearchKeyword.length === 0 &&
      keywords[3].newsBlogs[0].keywords.length === 0 &&
      keywords[3].newsBlogs[0].errorMsg ===
        CheckValueLocale(
          "keywords_is_required_to_monitor_data",
          "",
          {},
          intl,
        ) ? (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Box>
            {value &&
              value === "news_and_blogs" &&
              (keywords[3]?.newsBlogs[0]?.keywords?.length !== 0 ||
                keywords[3]?.newsBlogs[1]?.spam_keywords?.length !== 0 ||
                language?.length !== 0 ||
                countries?.length !== 0 ||
                majorSource?.length !== 0 ||
                nbExcludedSource?.length !== 0 ||
                titleSearchKeyword?.length !== 0) && (
                <>
                  {keywords[3].newsBlogs[0].keywords.length !== 0 && (
                    <TestYourKeyword
                      keywords={
                        keywords[3]?.newsBlogs[0]?.keywords !== undefined
                          ? keywords[3].newsBlogs[0].keywords
                          : ""
                      }
                      spamKeywords={
                        keywords[3].newsBlogs[1].spam_keywords !== undefined
                          ? keywords[3].newsBlogs[1].spam_keywords
                          : ""
                      }
                    />
                  )}
                </>
              )}
          </Box>
          <Box>
            {value &&
              value === "news_and_blogs" &&
              titleSearchKeyword.length === 0 &&
              keywords[3].newsBlogs[0].keywords.length === 0 &&
              keywords[3].newsBlogs[0].errorMsg ===
                CheckValueLocale(
                  "keywords_is_required_to_monitor_data",
                  "",
                  {},
                  intl,
                ) && (
                <Box className="err-msg">
                  {CheckValueLocale(
                    "keyword_title_empty_error_msg",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              )}
          </Box>
        </Box>
      ) : (
        value &&
        value === "news_and_blogs" &&
        (keywords[3]?.newsBlogs[0]?.keywords?.length !== 0 ||
          keywords[3]?.newsBlogs[1]?.spam_keywords?.length !== 0 ||
          language?.length !== 0 ||
          countries?.length !== 0 ||
          majorSource?.length !== 0 ||
          nbExcludedSource?.length !== 0 ||
          titleSearchKeyword?.length !== 0) && (
          <>
            {(keywords[3]?.newsBlogs[0]?.keywords?.length !== 0 ||
              keywords[3]?.newsBlogs[1]?.spam_keywords?.length !== 0 ||
              language?.length !== 0 ||
              countries?.length !== 0 ||
              majorSource?.length !== 0 ||
              nbExcludedSource?.length !== 0 ||
              titleSearchKeyword?.length !== 0) && (
              <Box
                className="keywords-counter"
                style={
                  newsBlogCount >= resp?.allowed_conditions
                    ? { color: "#F44336" }
                    : {}
                }
              >
                <Box component="span" className="keyword-num-name">
                  <Box component="span">
                    {wordCountNews +
                      wordCountNewsSpamKeyword +
                      language?.length +
                      countries?.length +
                      majorSource?.length +
                      nbExcludedSource?.length +
                      countTitleSearchKeywords(titleSearchKeyword)}
                  </Box>
                  <Box component="span">
                    &nbsp;
                    {CheckValueLocale(
                      "no_of_conditions",
                      "",
                      {
                        allowedConditions: resp?.allowed_conditions,
                      },
                      intl,
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {keywords[3].newsBlogs[0].keywords.length !== 0 && (
              <TestYourKeyword
                keywords={
                  keywords[3]?.newsBlogs[0]?.keywords !== undefined
                    ? keywords[3].newsBlogs[0].keywords
                    : ""
                }
                spamKeywords={
                  keywords[3].newsBlogs[1].spam_keywords !== undefined
                    ? keywords[3].newsBlogs[1].spam_keywords
                    : ""
                }
              />
            )}
          </>
        )
      )}
    </>
  );
};

export default KeywordListItemComponent;
