import Services from "./Services";

var Monitors = {};

Monitors.monitors = (queryData) => {
  return Services.getData("api/v4/monitors", queryData);
};

Monitors.getAllMonitors = (queryData) => {
  return Services.getData("api/v3/monitors", queryData);
};

Monitors.deleteMonitor = (pathData) => {
  return Services.deleteData("api/v3/monitors", "", pathData);
};

Monitors.resumeOrPauseMonitors = (queryData, pathData) => {
  return Services.putData("api/v3/monitors", queryData, pathData);
};

Monitors.createNewMonitor = (queryData) => {
  return Services.postData("api/v3/monitors", queryData);
};

Monitors.getCompanyList = (queryData) => {
  return Services.getData("api/v3/company/companies_list", queryData);
};
Monitors.assignUserToCompany = (queryData) => {
  return Services.postData(
    "api/v3/company/user_managements/assign_user_to_company",
    queryData,
  );
};

Monitors.getAuthorizedMonitorType = (queryData, pathData) => {
  return Services.getData(
    "api/v3/authorized_monitor_type",
    queryData,
    pathData,
  );
};
Monitors.getSocialAccounts = (queryData) => {
  return Services.getData("api/v3/company/social_accounts", queryData);
};

Monitors.getUserSocialAccounts = (queryData) => {
  return Services.getData("api/v3/user/social_accounts", queryData);
};

Monitors.getCompanySources = (queryData) => {
  return Services.getData("api/v3/company/sources", queryData);
};
Monitors.testKeywords = (queryData) => {
  return Services.getData("api/v3/monitors/keywords/matching", queryData);
};

//This API works dynamically with diff Monitor Data sources types and returns credentials for connecting with RabbitMQ
Monitors.getSocialMediaMonitorData = (
  monitorDataSource,
  monitor_id,
  queryData,
) => {
  return Services.getData(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/${monitor_id}`,
    queryData,
  );
};

Monitors.postMonitorsConfig = (queryData) => {
  return Services.postData("api/v3/monitors", queryData);
};

Monitors.getEditConfigData = (monitorId, dm_Configuration_Id) => {
  return Services.getData(
    `api/v3/monitors/${monitorId}/dm_configurations/${dm_Configuration_Id}`,
  );
};
Monitors.putEditConfigData = (monitor_Id, Id, queryData) => {
  return Services.putData(
    `api/v3/monitors/${monitor_Id}/dm_configurations/${Id}`,
    queryData,
  );
};

Monitors.getQuickInspectData = (monitorDataSource, monitor_id, queryData) => {
  return Services.getData(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/${monitor_id}/quick_inspect`,
    queryData,
  );
};

Monitors.getAuthorProfileData = (monitorDataSource, monitor_id, queryData) => {
  return Services.getData(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/${monitor_id}/engager_profile`,
    queryData,
  );
};
Monitors.getDeepInsightData = (monitorDataSource, monitor_id, queryData) => {
  return Services.getData(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/${monitor_id}/deep_insight`,
    queryData,
  );
};

Monitors.getMonitorInfo = (queryData, monitor_id) => {
  return Services.getData(`api/v3/monitors/${monitor_id}`, queryData);
};
Monitors.editMonitorInfo = (queryData, monitor_id) => {
  return Services.putData(`api/v3/monitors/edit/${monitor_id}`, queryData);
};

//This API works dynamically with diff Monitor Data sources types to change sentiment
Monitors.editSentimentInteractions = (monitorDataSource, queryData) => {
  return Services.putData(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/edit_sentiment_interactions`,
    queryData,
  );
};

Monitors.postSpamTweets = (queryData, monitor_id, product_id, tweet_id) => {
  return Services.postData(
    `api/v3/social_listening/twitter/${monitor_id}/spam_tweets?product_id=${product_id}&tweet_id=${tweet_id}`,
    queryData,
  );
};

Monitors.markSpamPost = (queryData, id) => {
  return Services.deleteData(`api/v4/monitor_spam_posts/${id}`, queryData);
};

Monitors.undoSpamPost = (queryData, id) => {
  return Services.putData(`api/v4/monitor_spam_posts/${id}`, queryData);
};

Monitors.getMoreInsights = (monitorDataSource, queryData) => {
  return Services.getData(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/post_insights`,
    queryData,
  );
};

Monitors.getSocialMediaPPT = (monitorDataSource, monitor_id, queryData) => {
  return Services.getData(
    `api/v3/social_listening/${monitorDataSource?.toLowerCase()}/${monitor_id}`,
    queryData,
  );
};

Monitors.getExportExcel = (queryData) => {
  return Services.postData(`api/v3/export/excel`, queryData);
};

Monitors.getSLExportXLSX = (queryData) => {
  return Services.postData(`api/v3/export/xlsx`, queryData);
};

Monitors.getTopicsMonitors = (queryData) => {
  return Services.getData(`api/v3/company/topics_monitors`, queryData);
};

Monitors.getTopicsForMonitor = (monitor_id, queryData) => {
  return Services.getData(`api/v3/monitors/${monitor_id}/topics`, queryData);
};

Monitors.getTopicsUsage = (queryData) => {
  return Services.getData(`api/v3/company/topics_usage`, queryData);
};

Monitors.deleteTopicsFromMonitor = (monitor_id, queryData) => {
  return Services.deleteData(`api/v3/monitors/${monitor_id}/topics`, queryData);
};

Monitors.getChannelUsage = (queryData) => {
  return Services.getData("api/v3/company/channels_usage", queryData);
};

Monitors.getAllAccounts = (queryData) => {
  return Services.getData("api/v4/engagements/authorized_accounts", queryData);
};

Monitors.getAllawedAccounts = (queryData) => {
  return Services.getData(
    "api/v4/engagements/allowed_user_accounts",
    queryData,
  );
};

Monitors.getAllawedMonitors = (queryData) => {
  return Services.getData(
    "api/v4/engagements/allowed_reply_monitors",
    queryData,
  );
};

Monitors.postReplies = (queryData) => {
  return Services.postData("api/v4/engagements", queryData);
};

Monitors.getAllSavedReplies = (queryData) => {
  return Services.getData("api/v4/engagements/saved_replies", queryData);
};

Monitors.createSavedReplies = (queryData) => {
  return Services.postData("api/v4/engagements/saved_replies", queryData);
};

Monitors.deleteSavedReplies = (id, queryData) => {
  return Services.deleteData(
    `api/v4/engagements/saved_replies/${id}`,
    queryData,
  );
};

Monitors.getExportPages = (queryData) => {
  return Services.getData("api/v3/export/multi_pages/pages", queryData);
};
Monitors.exportPPTAnalyticsPage = (queryData) => {
  return Services.postData("api/v3/export/multi_pages", queryData);
};

Monitors.getTwitterSources = (queryData) => {
  return Services.getData("api/v3/twitter_sources", queryData);
};

Monitors.getKeywordsLimit = () => {
  return Services.getData(`api/v3/company/keywords_limit`);
};

Monitors.getHashtagsLimit = (queryData) => {
  return Services.getData(
    `api/v3/social_listening/instagram/get_used_instagram_hashtags`,
    queryData,
  );
};
Monitors.exportExcelLogExport = (monitor_id) => {
  return Services.getData(
    `api/v3/social_listening/excel/log_export?monitor_id=${monitor_id}`,
  );
};

export default Monitors;
