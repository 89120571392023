import { Typography, Grid, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const CustomCard = (props) => {
  const intl = useIntl();

  const getDataHeader = (value) => {
    let allValues = {
      tweets_count: "total_comments",
      potential_impression: "potential_impression_legend",
      potential_reach: "potential_reach_legend",
      default: value,
    };
    return allValues[value] || allValues.default;
  };
  return (
    <Grid item xs={props.gridSize} className="quick-box">
      {Object.entries(props.cardData).map((i, index) => (
        <Box key={index}>
          <Typography variant="caption" className="custom-card-title">
            {i[0] ? CheckValueLocale(getDataHeader(i[0]), "", {}, intl) : ""}
          </Typography>
          <Typography
            variant="body2"
            color={
              i.includes(props.isDataType) ? props.color : props.otherColor
            }
          >
            {CheckValueLocale(i[1]?.toLocaleString(), "", {}, intl)}
          </Typography>
        </Box>
      ))}
    </Grid>
  );
};

export default CustomCard;
