import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "react-intl-tel-input/dist/main.css";
import BreadCrumb from "components/breadCrumb";
import { CheckValueLocale } from "utils/helpers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../editUser.scss";

const EditHeader = ({ links }) => {
  const intl = useIntl();

  return (
    <Box className="main-edit-header">
      <Box>
        <BreadCrumb links={links} />
        <Typography className="main-edit-header-tittle">
          {CheckValueLocale("edit_user", "", {}, intl)}
        </Typography>
      </Box>
      <Box>
        <Link to={"/settings/user_managements"} className="back-to-link">
          {CheckValueLocale("back_to_manag", "", {}, intl)}
          <ArrowForwardIcon fontSize="small" />
        </Link>
      </Box>
    </Box>
  );
};

export default EditHeader;
