import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import SectionTitle from "pages/alerts/components/sectionTitle/sectionTitle";
import QuestionCard from "pages/Monitors/Components/questionCard";
import AlertStatistics from "../../alertStatistics/alertStatistics";

const NewPostAlertFacebook = ({
  alertDetailMonitorId,
  response,
  resetAllFields,
  alertsDataWidgets,
}) => {
  const initialWidgetsState = {
    //header alert widget
    headerAlertWidget: {},
    headerAlertWidgetPreLoaderDataSources: [],

    //posts volume widget
    postsVolume: [],
    postsVolumePreLoader: [],

    //top posts widget
    topPosts: [],
    topPostsPreLoaderDataSources: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //top hastags widget
    topHashtags: [],
    topHashtagsPreLoaderDataSources: [],

    //comments setntiment analysis widget
    commentsSentimentAnalysis: [],
    commentsSentimentAnalysisPreLoaderDataSources: [],

    //dialects & subDialects widget
    dialects: [],
    subDialects: [],
    dialectsSubDialectsPreLoaderDataSources: [],
  };

  const [alertViewWidgets, setAlertViewWidgets] = useState(initialWidgetsState);

  useEffect(() => {
    switch (response?.eventName) {
      case "Facebook__NewPostAlert__header_alert_widget":
      case "Facebook__TrendingAlert__header_alert_widget":
      case "Facebook__NegativePostsAlert__header_alert_widget":
        setAlertViewWidgets({
          ...alertViewWidgets,
          headerAlertWidget: response?.eventData,
          headerAlertWidgetPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Facebook__NewPostAlert__volume_overtime":
      case "Facebook__TrendingAlert__volume_overtime":
      case "Facebook__NegativePostsAlert__volume_overtime":
        setAlertViewWidgets({
          ...alertViewWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          postsVolumePreLoader: [response?.monitor_id],
        });
        break;
      case "Facebook__NewPostAlert__sentiment_analysis":
      case "Facebook__TrendingAlert__sentiment_analysis":
      case "Facebook__NegativePostsAlert__sentiment_analysis":
        setAlertViewWidgets({
          ...alertViewWidgets,
          commentsSentimentAnalysis: response?.eventData?.sentiment_piechart,
          commentsSentimentAnalysisPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Facebook__NewPostAlert__top_keywords":
      case "Facebook__TrendingAlert__top_keywords":
      case "Facebook__NegativePostsAlert__top_keywords":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Facebook__NewPostAlert__top_hashtags":
      case "Facebook__TrendingAlert__top_hashtags":
      case "Facebook__NegativePostsAlert__top_hashtags":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topHashtags: response?.eventData?.top_hashtags,
          topHashtagsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      case "Facebook__NewPostAlert__top_posts_comments":
      case "Facebook__TrendingAlert__top_posts_comments":
      case "Facebook__NegativePostsAlert__top_posts_comments":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topPosts: response?.eventData?.top_posts,
          topPostsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      case "Facebook__NewPostAlert__dialects_and_sub_dialects":
      case "Facebook__TrendingAlert__dialects_and_sub_dialects":
      case "Facebook__NegativePostsAlert__dialects_and_sub_dialects":
        setAlertViewWidgets({
          ...alertViewWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsSubDialectsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...initialWidgetsState });
    }
  }, [resetAllFields]);

  return (
    <>
      {alertsDataWidgets?.includes("HeaderAlertWidget") ? (
        <AlertStatistics
          alertDataSource={"facebook"}
          headerAlertWidget={alertViewWidgets?.headerAlertWidget}
          headerAlertWidgetPreLoaderDataSources={
            alertViewWidgets?.headerAlertWidgetPreLoaderDataSources
          }
          preLoaderTrackerDataSources={alertDetailMonitorId}
          isSL
        />
      ) : null}
      {alertsDataWidgets?.includes("VolumeOvertimeWidget") ? (
        <Box mt={3}>
          <LineChartWidget
            title={"alerts_volume_overtime"}
            toolTipLabel={"total_interactions"}
            data={alertViewWidgets?.postsVolume}
            lineChartPreLoaderDataSources={
              alertViewWidgets?.postsVolumePreLoader
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            checkSymbolZize
          />
        </Box>
      ) : null}
      {alertsDataWidgets?.includes("TopPostsWidget") ? (
        <Box mt={3}>
          <SectionTitle sectionTitle="alert_new_posts_section" />
          <QuestionCard
            latestUnanswer={alertViewWidgets?.topPosts?.slice(0, 6)}
            icon={faFacebook}
            iconColor={"facebook_icon"}
            widget={"top_posts"}
            showHeart
            hideChartBar
            showDefaultImg
            className={"no-data-found-question"}
            latestUnanswerPreloader={
              alertViewWidgets?.topPostsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            isAlert={true}
          />
        </Box>
      ) : null}
      <SectionTitle sectionTitle="metrics" />
      <Grid container spacing={2.5} mt={3} mb={4}>
        {alertsDataWidgets?.includes("SentimentAnalysisWidget") ? (
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_sentiment_analysis"}
              data={alertViewWidgets?.commentsSentimentAnalysis}
              areaStyle={true}
              showDownloadIcon
              chartType={"sentiment"}
              pieChartPreLoaderDataSources={
                alertViewWidgets?.commentsSentimentAnalysisPreLoaderDataSources
              }
              preLoaderTrackerDataSources={alertDetailMonitorId}
              showAI
              color={["#89BB2A", "#E50035", "#F9A700"]}
            />
          </Grid>
        ) : null}
        {alertsDataWidgets?.includes("DialectsSubDialectsWidget") ? (
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              data={alertViewWidgets?.dialects}
              subData={alertViewWidgets?.subDialects}
              areaStyle={true}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={
                alertViewWidgets?.dialectsSubDialectsPreLoaderDataSources
              }
              preLoaderTrackerDataSources={alertDetailMonitorId}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={2} mb={2}>
        {alertsDataWidgets?.includes("TopKeywordsWidget") ? (
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"top_keywords"}
              leftColomnTitle={"keywords_tab_title"}
              rightColomnTitle={"number_of_posts"}
              data={alertViewWidgets?.topKeywords}
              errorMessage={"no_keywords_found"}
              showDownloadIcon
              bigImg
              keywords
              tablePagination
              count={alertViewWidgets?.topKeywords?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topKeywordsPreLoaderDataSources
              }
            />
          </Grid>
        ) : null}
        {alertsDataWidgets?.includes("TopHashTagsWidget") ? (
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"alert_top_hashtags"}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              data={alertViewWidgets?.topHashtags}
              errorMessage={"no_hashtags_found"}
              showDownloadIcon
              bigImg
              keywords
              tablePagination
              count={alertViewWidgets?.topHashtags?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topHashtagsPreLoaderDataSources
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default NewPostAlertFacebook;
