import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CheckValueLocale } from "utils/helpers";
import { sanitize } from "dompurify";

const ChartDetailsWidget = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const urlParams = useParams();
  const location = useLocation();
  const dataSourceId = urlParams.data_source_id;
  const pathName = location.pathname;

  let { statsDetailsData, redirectTo, isInteractionStatsWrapperStyle } = props;

  const viewAll = pathName.includes("/google-my-business/")
    ? "view_all_reviews"
    : "view_all_interactions";

  const mainBox = redirectTo ? "grid-of-two" : "";
  const subBox = redirectTo ? "display-in-end" : "";
  const redirectDatasource = dataSourceId !== undefined ? dataSourceId : "";

  let widgetDetailsStyle = isInteractionStatsWrapperStyle
    ? `interactions-stats-wrapper ${mainBox}`
    : `posts-stats-wrapper ${mainBox}`;

  return (
    <>
      <Box className={widgetDetailsStyle}>
        {statsDetailsData.map((data, index) => {
          return (
            <Box className={`posts_stats ${subBox}`}>
              <Box>
                <Box
                  className={`posts-stats-value ${
                    data?.showStringValue && `${data?.value}`
                  }`}
                >
                  {/* make condition to be able to pass string value here */}
                  {data?.showStringValue ? (
                    <Typography
                      className="stats-value stats-value-time"
                      dangerouslySetInnerHTML={{
                        __html: data?.value
                          ? sanitize(
                              CheckValueLocale(data?.value, "", {}, intl),
                            )
                          : sanitize(
                              data?.value === 0
                                ? data?.value?.toString()
                                : data?.value,
                            ),
                      }}
                    ></Typography>
                  ) : !isNaN(Number(data?.value)) ? (
                    Number(data?.value).toLocaleString()
                  ) : (
                    0
                  )}
                  {data?.date != "Invalid date" ? (
                    <>
                      {data?.date &&
                        (isInteractionStatsWrapperStyle ? (
                          <Box component="span">
                            <span className="chart-details-date-on-date-format">
                              {CheckValueLocale("on", "", {}, intl)}
                            </span>

                            {data?.date?.includes("-")
                              ? "(" + data?.date.replaceAll("-", "/") + ")"
                              : "(" + data?.date + ")"}
                          </Box>
                        ) : (
                          <Box component="span">
                            {CheckValueLocale("on", "", {}, intl)} {data?.date}
                          </Box>
                        ))}
                    </>
                  ) : null}
                </Box>
                <Box className="posts_stats_name">
                  {CheckValueLocale(`${data?.name}`, "", {}, intl)}
                </Box>
              </Box>
              {redirectTo && index == 0 && (
                <Box
                  className="view-all-interactions display-in-row"
                  onClick={() => history.push(redirectTo + redirectDatasource)}
                >
                  {CheckValueLocale(viewAll, "", {}, intl)}
                  <ChevronRightIcon className="view-interactions-icons" />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};
export default ChartDetailsWidget;
