import { useEffect, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import OptionsDropDown from "./optionsDropDown";
import Snackbar from "components/snackBar";
import PopupModal from "components/popupModal";
import { useHistory, Link } from "react-router-dom";
import { Add, Search, Clear } from "@mui/icons-material";
import {
  Button,
  Grid,
  TextField,
  Skeleton,
  Stack,
  InputAdornment,
  IconButton,
  Box,
  Alert,
  Typography,
  Zoom,
  Tooltip,
  Tab,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import "../../../socialListing/MonitorList/monitorsListPage.scss";
import SharedTable from "../../../../shared/table/sharedTable";
//Controllers
import CompaniesController from "../../../../services/controllers/companiesController";
import { handlelUserRoles, CheckValueLocale, maskEmail } from "utils/helpers";
import DefaultUser from "images/shared-images/user-management-default.svg";
import PageHeader from "components/pageHeader";
import { tooltipClasses } from "@mui/material/Tooltip";
import { sortedColumnTypes, useSortableTable } from "hooks/useSortableTable";
import APIIcon from "images/product-icons/api-icon.svg";
import EngagementIcon from "images/product-icons/engagement-icon.svg";
import AiAgentsIcon from "images/ai-agents/ai-agents-icon.svg";
import DCPIcon from "images/product-icons/cdp-icon.svg";
import OCIcon from "images/product-icons/oc.icon.svg";
import SettingIcon from "images/product-icons/settings-icon.svg";
import SLIcon from "images/product-icons/sl-icon.svg";
import Surveys from "images/product-icons/surveys-icon.svg";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ActivityLog from "./userActivityLog/activityLog";
import UserAvatar from "shared/unifiedUserImage/userAvatar";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const UserManagementTable = (props) => {
  const { companyUsers, setCompanyUsers, setCompanyReachedMaxUser } = props;
  const intl = useIntl();
  let history = useHistory();
  const [tableBody, setTableBody] = useState([]);
  const [searched, setSearched] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [success, setSuccess] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const skeletonArray = Array(10).fill("");
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const [tabValue, setTabValue] = useState("userList");
  const [buttonLoading, setButtonLoading] = useState(false);

  const [stats, setStats] = useState({
    user_id: null,
    user_name: "",
    anchorEl: null,
    openDeleteModal: false,
    modalTitle: "",
    modalBody: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    index: "",
    page: "",
  });
  const [pagination, setPagination] = useState(1);
  const [companyActiveProduct, setCompanyActiveProduct] = useState([]);
  const [openUpgradeSnackBar, setOpenUpgradeSnackBar] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const handleCloseSnackBar = () => {
    setSnackBarData({
      openSnackBar: false,
    });
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPage(1);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: [
      {
        body: CheckValueLocale("user_info", "", {}, intl),
        className: "first user-management-table-data",
        sx: { width: "200px", textAlign: "left" },
        name: "name",
        type: sortedColumnTypes.string,
      },
      {
        body: CheckValueLocale("permission", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", width: "36%", paddingLeft: "0px" },
      },
      {
        body: CheckValueLocale("teams", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", paddingLeft: "0px" },
      },
      {
        body: CheckValueLocale("last_seen", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", width: "150px", paddingLeft: "1px" },
        name: "last_seen",
        type: sortedColumnTypes.date,
      },
      {
        body: CheckValueLocale("status", "", {}, intl),
        className: "user-management-table-data",
        sx: { textAlign: "left", width: "120px", paddingLeft: "1px" },
        name: "status",
        type: sortedColumnTypes.string,
      },
      {
        body: null,
        permission:
          handlelUserRoles("GENERAL", "EDIT_USER") &&
          handlelUserRoles("GENERAL", "DESTROY_USER"),
        className: "user-management-table-data",
        sx: { textAlign: "center", width: "100px" },
      },
    ],
    onSortChangeCallback,
  });
  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
  };

  const handleCloseUpgradeSnackBar = () => {
    setOpenUpgradeSnackBar(false);
  };
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };
  const hanldeSearchIconClick = () => {
    setTabValue("userList");
    setSearchInput(searched);
    if (page === 1) {
      getCompanyUsers();
    } else {
      setPage(1);
    }
  };

  const showTooltipText = (text, textLength, onClick) => {
    return (
      <Box className="report-name-container" onClick={onClick}>
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Box component="span">{text?.substring(0, textLength)}...</Box>
          </BootstrapTooltip>
        ) : (
          <Typography component="p">{text}</Typography>
        )}
      </Box>
    );
  };

  // Delete Authorized Account
  const handleDeleteUserAccount = (id, index, page, name) => {
    setOpenPopup(true);
    setStats({
      user_id: id,
      user_name: name,
      openDeleteModal: true,
      modalTitle: CheckValueLocale("remove_user", "", {}, intl),
      modalBody: (
        <Box className="shared-dialog-body">
          <p>{`${CheckValueLocale("delete_user_account", "", { name }, intl)}`}</p>
          <h2 className="sub-text-desc">
            {CheckValueLocale("deleting_msg", "", {}, intl)}
          </h2>
        </Box>
      ),

      modalRightBtn: CheckValueLocale("delete_confirm", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      index: index,
      page: page,
    });
  };

  // Deactivate User
  const deactiveUser = async (id, index, page, status) => {
    CompaniesController.deactiveCompanyUser(id).then((result) => {
      if (!result.errorMsg) {
        getCompanyUsers();
        setSuccessMsg("user_deactivation_success");
        setSuccess(true);
      } else {
        setErrorMsg("user_deactivation_failed");
        setSuccess(false);
      }
    });
  };

  // Activate User
  const activeUser = async (id, index, page, status) => {
    CompaniesController.activeCompanyUser(id).then((result) => {
      if (!result.errorMsg) {
        setSuccessMsg("user_activation_success");
        setSuccess(true);
        getCompanyUsers();
      } else {
        setErrorMsg("user_activation_failed");
        setSuccess(false);
      }
    });
  };

  // Send User Invitation
  const inviteUser = async (id) => {
    CompaniesController.inviteCompanyUser(id).then((result) => {
      if (!result.errorMsg) {
        setSuccessMsg("user_invitation_success");
        setSuccess(true);
      } else {
        setErrorMsg("user_invitation_failed");
        setSuccess(false);
      }
    });
  };

  // Delete User
  const deleteUser = async () => {
    setButtonLoading(true);
    CompaniesController.deleteCompanyUser(stats.user_id).then((result) => {
      if (!result.errorMsg) {
        handleCloseDeleteModal();
        setSuccessMsg(
          intl.formatMessage(
            { id: "user_deletion_success" },
            {
              monitorname: CheckValueLocale(stats.user_name, "", {}, intl),
            },
          ),
        );
        setSuccess(true);
        getCompanyUsers();
        setSearched("");
      } else {
        setErrorMsg("user_deletion_failed");
        setSuccess(false);
      }
      setButtonLoading(false);
    });
  };

  //Change Handler
  const changeHandler = (e) => {
    setSearched(e.target.value);
  };

  //Get Company Users
  const getCompanyUsers = () => {
    if (!isSortableLoading) setLoading(true);
    CompaniesController.getCompanyUsers(
      searched,
      page,
      sortColumn,
      sortDirection,
    ).then((result) => {
      if (!result.errorMsg) {
        if (result?.data?.flags?.includes("USERS_COUNT_NOT_ENOUGH")) {
          setCompanyReachedMaxUser(true);
          setOpenUpgradeSnackBar(true);
        } else {
          setCompanyReachedMaxUser(false);
          setOpenUpgradeSnackBar(false);
        }
        setCompanyUsers(result?.data?.data);
        setPagination(result?.data?.pagination);
      } else {
        setCompanyUsers([]);
        setPagination(1);
        setErrorMsg("connection_failed");
        setSuccess(false);
      }
      setLoading(false);
      setIsSortableLoading(false);
    });
  };

  //Handle Status of User
  const getStatusLocalization = (status) => {
    const account_type = {
      active: CheckValueLocale("active", "", {}, intl),
      deactivated: CheckValueLocale("deactivated", "", {}, intl),
      invited: CheckValueLocale("invited", "", {}, intl),
    };
    return account_type[status];
  };

  const stateCell = (user) => {
    return (
      <>
        {user?.attributes?.is_locked ? (
          <Box className="status locked">
            {CheckValueLocale("locked", "", {}, intl)}
          </Box>
        ) : getStatusLocalization(user?.attributes?.status) ? (
          <Box className={"status " + user?.attributes?.status}>
            {getStatusLocalization(user?.attributes?.status)}
          </Box>
        ) : null}
      </>
    );
  };

  //Render Componenet
  useEffect(() => {
    (async () => {
      await getCompanyUsers();
      const activeProducts = JSON.parse(window.localStorage.activeProducts);
      setCompanyActiveProduct(
        activeProducts
          .filter((product) => product.active)
          .map((val) => val.name),
      );
    })();
  }, [page, sortColumn, sortDirection]);

  useEffect(() => {
    // if the search has a created_successfully or updated_successfully message status,
    // then show a snackbar with the message
    const search = history?.location?.search;
    let snackbarSuccessMessage;
    if (search && search?.includes("status=deleted_successfully")) {
      snackbarSuccessMessage = "user_deleted_success";
    }

    if (snackbarSuccessMessage) {
      // show the message and then remove it from the url
      setSnackBarData({
        message: snackbarSuccessMessage,
        severity: "success",
        openSnackBar: true,
      });
      window.history.pushState({}, "", window.location.pathname);
    }
  }, []);
  const firstBodyCellLoading = () => {
    return (
      <Stack direction="row" justifyContent="flex-start" spacing={2}>
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
        <Stack direction="column" mb={4}>
          <Skeleton
            animation="wave"
            height={10}
            width="50px"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="100px"
            style={{ marginBottom: 6 }}
          />
        </Stack>
      </Stack>
    );
  };

  const avatarBodyCell = (user) => {
    return (
      <Stack
        className="user-management-avatar-wrapper"
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <UserAvatar
          fullName={user?.attributes?.name}
          avatarSrc={user?.attributes?.avatar}
          sizes={{ ratio: "32px", fontSize: "12px" }}
        />
        <Stack direction="column" className="user-info-style">
          <Box className="user-management-head">
            {showTooltipText(
              !user?.attributes?.name?.trim()?.length
                ? "—"
                : user?.attributes?.name,
              30,
              () => history.push(`/settings/user_managements/view/${user?.id}`),
            )}
          </Box>
          <Box className="user-management-email">
            {showTooltipText(
              !user?.attributes?.email?.trim()?.length
                ? "—"
                : maskEmail(user?.attributes?.email),
              30,
            )}
          </Box>
        </Stack>
      </Stack>
    );
  };

  const bodyCellsLoading = () => {
    return <Skeleton />;
  };

  const permissionBodyCell = (user, index) => {
    const iconMapper = {
      ENGAGEMENTS: EngagementIcon,
      SM: SLIcon,
      CDP: DCPIcon,
      CXM: OCIcon,
      PUBLIC_API: APIIcon,
      GENERAL: SettingIcon,
      SURVEY: Surveys,
      AI_AGENT: AiAgentsIcon,
    };

    let roleMapper = {
      product_admin: "manager",
      viewer: "viwer",
      user: "moderator",
      team_leader_egag: "team_leader",
      agent_egag: "agent_egagment",
      no_access_egag: "no_acc",
    };
    let activeProducts = user?.attributes?.permissions
      ? user.attributes.permissions.filter(
          (val) =>
            companyActiveProduct?.includes(val?.product) ||
            val?.product === "GENERAL" ||
            (val?.product === "PUBLIC_API" &&
              companyActiveProduct?.includes("AI_API")),
        )
      : [];
    return (
      <Box className="main-permissions-products" key={index}>
        {activeProducts?.map((product, i) => (
          <Box className="permissions-product" key={i}>
            <img src={iconMapper[product?.product]} alt="product" />
            <Box className="permissions-product-name">
              {CheckValueLocale(product?.product, "", {}, intl)}
            </Box>
            <Box className="permissions-product-role">
              {`( 
              ${CheckValueLocale(
                roleMapper[
                  product?.product == "ENGAGEMENTS" ||
                  product?.product == "AI_AGENT"
                    ? `${product?.role}_egag`
                    : product?.role
                ],
                "",
                {},
                intl,
              )}
              )`}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const dateBodyCell = (user) => {
    return (
      <Box>
        {user?.attributes?.last_seen == null ? (
          CheckValueLocale("never_seen", "", {}, intl)
        ) : (
          <Box>
            <Box>
              {new Date(user?.attributes?.last_seen)
                .toLocaleDateString("en-GB")
                .replaceAll("/", "-")}
            </Box>
            <Box>
              {new Date(user?.attributes?.last_seen).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const optionsBodyCell = (user, index) => {
    return (
      <OptionsDropDown
        status={user.attributes.status}
        open={false}
        user={user}
        id={user?.attributes?.id}
        name={user?.attributes?.name}
        index={index}
        page={page}
        openDeleteModal={handleDeleteUserAccount}
        deactiveUser={deactiveUser}
        activeUser={activeUser}
        active={active}
        inviteUser={inviteUser}
        component={"userManagement"}
      />
    );
  };

  const teamsBodyCell = (user) => {
    let teams = user?.attributes?.user_teams?.length
      ? user?.attributes?.user_teams
      : [];
    return teams?.length ? (
      <Box className="users-teams">
        {teams[0]?.name?.length > 30 ? (
          <BootstrapTooltip
            title={teams[0]?.name}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            {teams[0]?.name?.substring(0, 30)}...
          </BootstrapTooltip>
        ) : (
          teams[0]?.name
        )}
        {teams?.length > 1 ? (
          <Box>
            ,
            <BootstrapTooltip
              title={teams
                ?.slice(1, teams?.length)
                ?.map((team) => team?.name)
                .join(", ")}
              arrow
              TransitionComponent={Zoom}
              placement="top"
              id="user-teams-tooltip"
            >
              +{teams?.length - 1}
            </BootstrapTooltip>
          </Box>
        ) : null}
      </Box>
    ) : (
      <Box className="empty-users-teams">
        {CheckValueLocale("not_assigned", "", {}, intl)}
      </Box>
    );
  };
  useEffect(() => {
    const tabBody = loading
      ? skeletonArray?.map((item, index) => {
          return {
            id: `skeleton-${index}`,
            data: [
              {
                body: firstBodyCellLoading(),
              },
              {
                body: bodyCellsLoading(),
              },
              {
                body: bodyCellsLoading(),
              },
              {
                body: bodyCellsLoading(),
              },
              {
                body: bodyCellsLoading(),
              },
              {
                body: bodyCellsLoading(),
              },
            ],
          };
        })
      : companyUsers?.length == 0
        ? []
        : companyUsers?.map((item, index) => {
            return {
              id: `users-${index}`,
              data: [
                {
                  body: avatarBodyCell(item),
                },
                {
                  body: permissionBodyCell(item, index),
                },
                {
                  body: teamsBodyCell(item),
                },
                {
                  body: dateBodyCell(item),
                },
                {
                  body: stateCell(item),
                },
                {
                  body: optionsBodyCell(item, index),
                  permission:
                    handlelUserRoles("GENERAL", "EDIT_USER") &&
                    handlelUserRoles("GENERAL", "DESTROY_USER"),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(companyUsers));
  }, [companyUsers, loading, page, intl.locale]);

  const emptyBody = (companyUsers) => {
    return (
      <>
        {searched !== "" && companyUsers?.length == 0 ? (
          <Box className="no-data-cdp">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="monitor_list_search_empty_submsg"
            />
          </Box>
        ) : companyUsers?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="UManagement"
              title="invite_your_team"
              description="invite_your_team_dec"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks.user_management}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <Box>
      {openUpgradeSnackBar && (
        <Alert
          onClose={handleCloseUpgradeSnackBar}
          severity={"info"}
          className="upgrade-snackbar-alert"
        >
          <Box id="settings-user-management-upgrate-request-link">
            {CheckValueLocale("reached_max_num_users", "", {}, intl)}
            <Link to="/settings/subscription">
              {CheckValueLocale("reached_max_num_subscription", "", {}, intl)}
            </Link>
          </Box>
        </Alert>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          {companyUsers?.length > 0 || searchInput ? (
            <TextField
              placeholder={CheckValueLocale(
                "search_by_username_input",
                "",
                {},
                intl,
              )}
              onInput={changeHandler}
              onPaste={changeHandler}
              name="searched"
              value={searched}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={hanldeSearchIconClick}>
                      <Search id="settings-user-management-upgrate-user-name-search-icon" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="settings-user-management-upgrate-user-name-search-input"
            />
          ) : null}
        </Grid>
      </Grid>
      <TabContext value={tabValue}>
        <Box className="tab-content-container">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={CheckValueLocale("user_list", "", {}, intl)}
              value="userList"
            />

            <Tab
              label={CheckValueLocale("activity_log", "", {}, intl)}
              value="activityLog"
            />
          </TabList>
        </Box>
        <TabPanel value="userList" className="tab-panel-container">
          {handlelUserRoles("GENERAL", "VIEW_USER") && (
            <>
              <SharedTable
                className={" "}
                headClassName={"table-head"}
                tableAriaLabel={"customized table"}
                tableHeader={tableHead}
                tableBody={tableBody}
                isSharedStyle
                emptyBody={tableEmptyBody}
                loading={isSortableLoading}
              />
            </>
          )}
        </TabPanel>
        <TabPanel className="tab-panel" value="activityLog">
          <ActivityLog />
        </TabPanel>
      </TabContext>

      {pagination?.pages > 1 &&
      !isSortableLoading &&
      tabValue !== "activityLog" ? (
        <Pagination
          onChange={handleChangePage}
          page={page}
          className="pagination-monitor-list"
          count={pagination?.pages}
          variant="outlined"
        />
      ) : null}
      {openPopup ? (
        <PopupModal
          title={stats.modalTitle}
          body={stats.modalBody}
          leftBtn={stats.modalLeftBtn}
          rightBtn={stats.modalRightBtn}
          open={stats.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={deleteUser}
          warning
          minWidth="600px"
          classeName=" actions-buttons-shared"
          isRightBtnLoading={buttonLoading}
          disabled={buttonLoading}
        />
      ) : (
        ""
      )}
      {success != null && (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              CheckValueLocale(successMsg, "", {}, intl)
            ) : (
              <div>
                <span style={{ fontWeight: "502", fontSize: "18px" }}>
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      )}
      <Snackbar
        open={snackBarData?.openSnackBar}
        severity={snackBarData?.severity}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Box>
  );
};
const Label = styled(Box)`
  padding: 7px 17px;
  border-radius: 4px;
  text-align: center;
  width: 30px;
  max-height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;
export default UserManagementTable;
