import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Checkbox,
  Chip,
  FormHelperText,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const StepFields = (props) => {
  const {
    stepNum,
    stepFields,
    requiredFields,
    selectedData,
    setSelectedData,
    stepFieldsErr,
  } = props;
  const intl = useIntl();

  const MenuProps = {
    style: { zIndex: 9999 },
    PaperProps: { style: { width: 250 } },
  };

  const [filteredFields, setFilteredFields] = useState([]);
  const stepTwoFilterd = [
    "annual_revenue",
    "years_in_operation",
    "marketing_strategies",
  ];

  useEffect(() => {
    if (stepNum && stepNum === 2) setFilteredFields(stepTwoFilterd);
  }, [stepNum]);

  const ArrowTooltips = (fieldKey, required) => {
    return (
      <Tooltip
        title={
          <Box>
            <Typography>
              {CheckValueLocale(`luci_${fieldKey}_tooltip`, "", {}, intl)}
            </Typography>
            <Box>
              {CheckValueLocale(`luci_${fieldKey}_tooltip_ex`, "", {}, intl)}
            </Box>
          </Box>
        }
        arrow
        id="tooltip-business"
      >
        <Typography component={"span"} className="tooltip-hover lable-input">
          {CheckValueLocale(`luci_${fieldKey}`, "", {}, intl)}
          {required && (
            <Box component="span" className="red-star-req">
              {` *`}
            </Box>
          )}
        </Typography>
      </Tooltip>
    );
  };

  const fieldErrorMsg = (fieldKey) => {
    if (
      fieldKey === "business_hq_required" ||
      fieldKey === "customers_geographic_required"
    )
      return CheckValueLocale("luci_field_required_error", "", {}, intl);
    else if (fieldKey === "business_hq")
      return CheckValueLocale("luci_field_validation_error", "", {}, intl);
    else if (fieldKey === "business_website")
      return CheckValueLocale("luci_url_validation_error", "", {}, intl);
    else if (
      fieldKey === "number_of_employees" ||
      fieldKey === "annual_revenue" ||
      fieldKey === "years_in_operation"
    )
      return CheckValueLocale("enter_numbers_only", "", {}, intl);
    else if (fieldKey === "customers_geographic")
      return CheckValueLocale("luci_field_validation_error", "", {}, intl);
    else return CheckValueLocale("luci_field_required_error", "", {}, intl);
  };

  const [focused, setFocused] = useState("");
  const [chipVals, setChipVals] = useState({});
  const handleAddChip = (e, fieldKey) => {
    e.preventDefault();
    if (chipVals[fieldKey]?.length !== 0) {
      selectedData[fieldKey]?.push(chipVals[fieldKey]);
      setSelectedData({ ...selectedData });
      setChipVals({ ...chipVals, [fieldKey]: "" });
    }
  };

  return (
    <Box className="main-step-inputs">
      {stepFields
        ?.filter((item) => !filteredFields?.includes(item?.key))
        ?.map((field) => {
          if (field?.type === "multiple_choices")
            return (
              <Box key={field?.key} className="item-row">
                <Box>
                  <Typography className="select-label lable-input">
                    {CheckValueLocale("luci_" + field?.key, "", {}, intl)}
                    {requiredFields?.includes(field?.key) && (
                      <Box component="span" className="red-star-req">
                        {` *`}
                      </Box>
                    )}
                  </Typography>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    className="select-input"
                    multiple
                    size="small"
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return (
                          <Box component="span" className="select-placeholder">
                            {CheckValueLocale(
                              `luci_${field?.key}_placeholder`,
                              "",
                              {},
                              intl,
                            )}
                          </Box>
                        );
                      }
                      return selected
                        ?.map((val) =>
                          CheckValueLocale(`luci_${val}`, "", {}, intl),
                        )
                        .join(", ");
                    }}
                    fullWidth
                    value={selectedData[`${field?.key}`] || []}
                    onChange={(e) => {
                      selectedData[`${field?.key}`] = e.target.value;
                      setSelectedData({ ...selectedData });
                    }}
                    error={stepFieldsErr?.includes(field?.key) && true}
                  >
                    {field?.value?.map((name) => (
                      <MenuItem
                        className="mcq-menu-item"
                        key={name}
                        value={name}
                      >
                        <Checkbox
                          className="select-checkbox"
                          checked={selectedData[`${field?.key}`]?.includes(
                            name,
                          )}
                        />
                        <ListItemText
                          primary={CheckValueLocale(
                            `luci_${name}`,
                            "",
                            {},
                            intl,
                          )}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  {stepFieldsErr?.includes(field?.key) && (
                    <FormHelperText className="field-error-helper">
                      {fieldErrorMsg(field?.key)}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            );
          else if (field?.type === "dropdown")
            return (
              <Box key={field?.key} className="item-row">
                <Box>
                  <Typography className="select-label lable-input">
                    {CheckValueLocale("luci_" + field?.key, "", {}, intl)}
                    {requiredFields?.includes(field?.key) && (
                      <Box component="span" className="red-star-req">
                        {` *`}
                      </Box>
                    )}
                  </Typography>
                  <Select
                    id="luci-dropdown-input"
                    className="select-input"
                    size="small"
                    MenuProps={MenuProps}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected || selected?.length === 0) {
                        return (
                          <Box component="span" className="select-placeholder">
                            {CheckValueLocale(
                              `luci_${field?.key}_placeholder`,
                              "",
                              {},
                              intl,
                            )}
                          </Box>
                        );
                      } else
                        return CheckValueLocale(
                          `luci_${selected}`,
                          "",
                          {},
                          intl,
                        );
                    }}
                    value={selectedData[`${field?.key}`] || ""}
                    onChange={(e) => {
                      selectedData[`${field?.key}`] = e.target.value;
                      setSelectedData({ ...selectedData });
                    }}
                    error={stepFieldsErr?.includes(field?.key) && true}
                    fullWidth
                  >
                    {field?.value?.map((name) => (
                      <MenuItem
                        className="mcq-menu-item"
                        key={name}
                        value={name}
                      >
                        {CheckValueLocale(`luci_${name}`, "", {}, intl)}
                      </MenuItem>
                    ))}
                  </Select>
                  {stepFieldsErr?.includes(field?.key) && (
                    <FormHelperText className="field-error-helper">
                      {fieldErrorMsg(field?.key)}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            );
          else if (field?.type === "free_text")
            return (
              <Box key={field?.key} className="item-row">
                <Box>
                  {field?.key === "business_hq" ||
                  field?.key === "customers_geographic" ? (
                    ArrowTooltips(field?.key, true)
                  ) : (
                    <Typography component={"span"}>
                      {CheckValueLocale(`luci_${field?.key}`, "", {}, intl)}
                    </Typography>
                  )}
                  <TextField
                    id="outlined-basic"
                    placeholder={CheckValueLocale(
                      `luci_${field?.key}_placeholder`,
                      "",
                      {},
                      intl,
                    )}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    value={selectedData[`${field?.key}`] || ""}
                    onChange={(e) => {
                      selectedData[`${field?.key}`] = e.target.value;
                      setSelectedData({ ...selectedData });
                    }}
                    error={
                      stepFieldsErr?.includes(field?.key) ||
                      stepFieldsErr?.includes(`${field?.key}_required`)
                        ? true
                        : false
                    }
                    helperText={
                      stepFieldsErr?.includes(`${field?.key}_required`)
                        ? fieldErrorMsg(`${field?.key}_required`)
                        : stepFieldsErr?.includes(field?.key)
                          ? fieldErrorMsg(field?.key)
                          : null
                    }
                  />
                </Box>
              </Box>
            );
          else if (field?.type === "text_area")
            return (
              <Box key={field?.key} className="item-row">
                <Box>
                  <Typography component={"span"} className="textarea-label">
                    {CheckValueLocale(`luci_${field?.key}`, "", {}, intl)}
                    <Box component={"span"}>
                      {selectedData[`${field?.key}`]?.length}/250
                    </Box>
                  </Typography>
                  <TextareaAutosize
                    name={field?.key}
                    className="luci-textarea"
                    placeholder={CheckValueLocale(
                      `luci_${field?.key}_placeholder`,
                      "",
                      {},
                      intl,
                    )}
                    value={selectedData[`${field?.key}`] || ""}
                    onChange={(e) => {
                      selectedData[`${field?.key}`] = e.target.value;
                      setSelectedData({ ...selectedData });
                    }}
                    minRows={"5"}
                    maxLength={250}
                  />
                </Box>
              </Box>
            );
          else if (field?.type === "input_add")
            return (
              <Box key={field?.key} className="item-row">
                <Box>
                  <Typography component={"span"}>
                    {CheckValueLocale(`luci_${field?.key}`, "", {}, intl)}
                  </Typography>
                  <OutlinedInput
                    variant="outlined"
                    size="small"
                    className="chips-input"
                    placeholder={CheckValueLocale(
                      `luci_${field?.key}_placeholder`,
                      "",
                      {},
                      intl,
                    )}
                    endAdornment={
                      focused === field?.key &&
                      chipVals[field?.key]?.length > 0 && (
                        <InputAdornment
                          onClick={(e) => handleAddChip(e, field?.key)}
                          className="chips-input-end-addorment"
                        >
                          <LucButton
                            variant="flat"
                            id={`${field?.key}_input`}
                            startIcon={
                              <FontAwesomeIcon icon={faPlus} size="small" />
                            }
                          >
                            {CheckValueLocale("luci_add", "", {}, intl)}
                          </LucButton>
                        </InputAdornment>
                      )
                    }
                    value={chipVals[field?.key]}
                    onChange={(e) =>
                      setChipVals({
                        ...chipVals,
                        [field?.key]: e.target.value,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleAddChip(e, field?.key);
                    }}
                    onFocus={() => setFocused(field?.key)}
                  />
                  {selectedData[field?.key]?.length !== 0 && (
                    <Box className="chips-container">
                      {selectedData[field?.key]?.map((item, index) => {
                        return (
                          <Chip
                            variant="outlined"
                            color="primary"
                            key={index}
                            label={item}
                            onDelete={(e) => {
                              selectedData[field?.key] = selectedData[
                                field?.key
                              ]?.filter((item, i) => i !== index);
                              setSelectedData({ ...selectedData });
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Box>
            );
        })}

      {stepNum === 2 ? (
        <Box className="stack-fileds">
          {filteredFields
            ?.filter((item) => item !== "marketing_strategies")
            ?.map((fieldKey) => {
              return (
                <Box key={fieldKey} className="item-row">
                  <Box>
                    <Typography component={"span"}>
                      {CheckValueLocale(`luci_${fieldKey}`, "", {}, intl)}
                    </Typography>
                    <OutlinedInput
                      id="outlined-basic"
                      placeholder={CheckValueLocale(
                        `luci_${fieldKey}_placeholder`,
                        "",
                        {},
                        intl,
                      )}
                      variant="outlined"
                      size="small"
                      value={selectedData[`${fieldKey}`] || ""}
                      onChange={(e) => {
                        selectedData[`${fieldKey}`] = e.target.value;
                        setSelectedData({ ...selectedData });
                      }}
                      error={stepFieldsErr?.includes(fieldKey) && true}
                      endAdornment={
                        fieldKey === "annual_revenue" && (
                          <InputAdornment position="end">USD</InputAdornment>
                        )
                      }
                    />
                    <FormHelperText className="outlined-form-helper">
                      {stepFieldsErr?.includes(fieldKey) &&
                        fieldErrorMsg(fieldKey)}
                    </FormHelperText>
                  </Box>
                </Box>
              );
            })}
        </Box>
      ) : null}
      {stepNum === 2 ? (
        <Box className="item-row">
          <Box>
            <Typography component={"span"} className="textarea-label">
              {CheckValueLocale(`luci_marketing_strategies`, "", {}, intl)}
              <Box component={"span"}>
                {selectedData[`marketing_strategies`]?.length}/250
              </Box>
            </Typography>
            <TextareaAutosize
              name="marketing_strategies"
              className="luci-textarea"
              placeholder={CheckValueLocale(
                `luci_marketing_strategies_placeholder`,
                "",
                {},
                intl,
              )}
              value={selectedData[`marketing_strategies`] || ""}
              onChange={(e) => {
                selectedData[`marketing_strategies`] = e.target.value;
                setSelectedData({ ...selectedData });
              }}
              minRows={"5"}
              maxLength={250}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default StepFields;
