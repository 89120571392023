import React, { useEffect, useState } from "react";
import { Box, Tab, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "../surveyInsights.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import IndividualSurveyTab from "./individualTab/individualSurvey";
import QuestionsTab from "./questionsTab/questionsTab";
import SummaryTab from "./summaryTab/summaryTab";
import { CheckValueLocale } from "utils/helpers";

const QuestionsStatistics = (props) => {
  const { setIndividualSurvey, page, setPage } = props;

  const intl = useIntl();
  const [value, setValue] = useState("1");
  const [selectedQuestionHeader, setSelectedQuestionHeader] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState({});

  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: themesOptions } = useThemesOptions({
    locale: intl?.locale,
  });

  const handleChangeQuestions = (value) => {
    setSelectedQuestionHeader(value);
    let questionsArray = [];
    Object.keys(props?.questions)?.map((question, i) => {
      props?.questions[question]?.questions?.map((q, index) => {
        questionsArray.push(q);
      });
    });
    questionsArray.map((q, index) => {
      if (q?.question_id === value?.question_id) {
        setSelectedQuestion(q);
        setPage(index + 1);
      }
    });
  };
  const handleChangePage = (event, page) => {
    let questionsArray = [];
    Object.keys(props?.questions)?.map((question, i) => {
      props?.questions[question]?.questions?.map((q, index) => {
        questionsArray.push(q);
      });
    });
    setPage(page);
    setSelectedQuestionHeader(questionsArray[page - 1]);
    setSelectedQuestion(questionsArray[page - 1]);
  };

  useEffect(() => {
    let questionsArray = [];
    Object.keys(props?.questions)?.map((question, i) => {
      props?.questions[question]?.questions?.map((ques, index) => {
        questionsArray.push(ques);
      });
    });
    setSelectedQuestionHeader(questionsArray[0]);
    setSelectedQuestion(questionsArray[0]);
  }, [props?.questions]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography component={"h6"} className="questions-statistics">
        {CheckValueLocale("questions_statistics", "", {}, intl).toUpperCase()}
      </Typography>
      <>
        {/*TAbs ___________________________________________________________ */}
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="questions-tabs"
            >
              <Tab
                label={CheckValueLocale("summary", "", {}, intl)}
                value="1"
                className={value === "1" && "selected"}
              />
              <Tab
                label={CheckValueLocale("questions", "", {}, intl)}
                value="2"
                className={value === "2" && "selected"}
              />
              <Tab
                label={CheckValueLocale("individual", "", {}, intl)}
                value="3"
                className={value === "3" && "selected"}
              />
            </TabList>
          </Box>
          {/*SummaryTab ___________________________________________________________ */}
          <TabPanel value="1" className="questions-panel">
            <SummaryTab
              handleChangeQuestions={handleChangeQuestions}
              handleChangePage={handleChangePage}
              selectedQuestionHeader={selectedQuestionHeader}
              page={page}
              selectedQuestion={selectedQuestion}
              questions={props.questions}
              questionsPreLoader={props.questionsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              id={props.id}
              productId={props.productId}
              sentimentsOptions={sentimentsOptions}
              categoriesOptions={categoriesOptions}
              themesOptions={themesOptions}
            />
          </TabPanel>
          {/*QuestionsTab ___________________________________________________________ */}
          <TabPanel value="2" className="questions-panel">
            <QuestionsTab
              handleChangeQuestions={handleChangeQuestions}
              handleChangePage={handleChangePage}
              selectedQuestionHeader={selectedQuestionHeader}
              page={page}
              selectedQuestion={selectedQuestion}
              questions={props.questions}
              questionsPreLoader={props.questionsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              id={props.id}
              productId={props.productId}
              sentimentsOptions={sentimentsOptions}
              categoriesOptions={categoriesOptions}
              themesOptions={themesOptions}
            />
          </TabPanel>
          {/* IndividualSurveyTab___________________________________________________________ */}
          <TabPanel value="3" className="questions-panel">
            <IndividualSurveyTab
              individualSurvey={props.individualSurvey}
              individualSurveyPreLoader={props.individualSurveyPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              id={props.id}
              productId={props.productId}
              setIndividualSurvey={setIndividualSurvey}
              page={page}
              setPage={setPage}
              sentimentsOptions={sentimentsOptions}
              categoriesOptions={categoriesOptions}
              themesOptions={themesOptions}
            />
          </TabPanel>
        </TabContext>
      </>
    </>
  );
};
export default QuestionsStatistics;
