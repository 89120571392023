import React from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const EmptySurvey = () => {
  const intl = useIntl();

  return (
    <Box className="empty-survey">
      <img
        className="empty-survey-icon"
        src="/survey_builder_icons/empty_survey_icon.svg"
        alt="logo"
      />
      <Typography component="h4" className="empty-survey-msg">
        {CheckValueLocale("empty_survey_error_msg", "", {}, intl)}
      </Typography>
    </Box>
  );
};

export default EmptySurvey;
