import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useEffect } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const SurveyPannel = (props) => {
  const intl = useIntl();

  let {
    surveyPanelList,
    selectedValues,
    setSelectedValues,
    isSurveyPanelEnabled,
    setError,
  } = props;

  const MenuProps = {
    PaperProps: {
      style: {
        width: 268,
        maxHeight: 268,
      },
    },
  };
  useEffect(() => {
    if (
      selectedValues?.Countries?.length == 0 ||
      selectedValues?.Age?.length == 0 ||
      selectedValues?.Gender?.length == 0 ||
      selectedValues?.Industries?.length == 0
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectedValues]);
  const handleChangevalue = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    if (name == "Countries") {
      setSelectedValues({
        ...selectedValues,
        Countries: typeof value === "string" ? value.split(",") : value,
      });
    } else if (name == "Age") {
      let array = [...value];
      if (value?.includes("Allـages")) {
        if (selectedValues?.Age?.length == surveyPanelList?.Age?.length) {
          array = [];
        } else {
          array = [...surveyPanelList?.Age];
        }
      }
      setSelectedValues({
        ...selectedValues,
        Age: typeof array === "string" ? array.split(",") : array,
      });
    } else if (name == "Gender") {
      setSelectedValues({
        ...selectedValues,
        Gender: typeof value === "string" ? value.split(",") : value,
      });
    } else if (name == "Industries") {
      setSelectedValues({
        ...selectedValues,
        Industries: typeof value === "string" ? value.split(",") : value,
      });
    }
  };
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel
          className="survey-input-label"
          id="demo-multiple-checkbox-countries"
        >
          {CheckValueLocale("survey_countries", "", {}, intl)}
        </InputLabel>
        <Select
          id="demo-multiple-checkbox-countries"
          className="survey-panel-select"
          multiple
          disabled={isSurveyPanelEnabled}
          name="Countries"
          value={selectedValues.Countries}
          onChange={handleChangevalue}
          input={
            <OutlinedInput
              label={CheckValueLocale("survey_countries", "", {}, intl)}
            />
          }
          renderValue={() =>
            selectedValues?.Countries?.map((selected) =>
              CheckValueLocale(
                selected?.includes(" ")
                  ? selected?.split(" ")?.join("_")
                  : selected,
                "",
                {},
                intl,
              ),
            )?.join(", ")
          }
          MenuProps={MenuProps}
        >
          {surveyPanelList?.Countries?.map((name, index) => (
            <MenuItem
              key={`countries-${name}`}
              value={name}
              id={`demo-multiple-menu-countries-${name}`}
              className="menu-item-panel"
            >
              <Checkbox
                checked={selectedValues?.Countries?.indexOf(name) > -1}
              />
              <ListItemText
                primary={CheckValueLocale(
                  name.split(" ").join("_"),
                  "",
                  {},
                  intl,
                )}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel
          className="survey-input-label"
          id="demo-multiple-checkbox-age"
        >
          {CheckValueLocale("survey_age", "", {}, intl)}
        </InputLabel>

        <Select
          labelId="demo-multiple-checkbox-age"
          className="survey-panel-select"
          multiple
          disabled={isSurveyPanelEnabled}
          name="Age"
          value={selectedValues?.Age}
          onChange={handleChangevalue}
          input={
            <OutlinedInput
              label={CheckValueLocale("survey_age", "", {}, intl)}
            />
          }
          renderValue={() =>
            selectedValues?.Age?.map((selected) =>
              CheckValueLocale(
                selected?.includes(" ")
                  ? selected?.split(" ")?.join("_")
                  : selected,
                "",
                {},
                intl,
              ),
            ).join(", ")
          }
          MenuProps={MenuProps}
        >
          {surveyPanelList?.Age ? (
            <MenuItem className="menu-item-panel" value={"Allـages"}>
              <Checkbox
                checked={
                  selectedValues.Age?.length == surveyPanelList?.Age?.length
                }
              />
              <ListItemText
                primary={CheckValueLocale("Allـages", "", {}, intl)}
              />
            </MenuItem>
          ) : null}
          {surveyPanelList?.Age?.map((name, index) => (
            <MenuItem className="menu-item-panel" key={name} value={name}>
              <Checkbox checked={selectedValues.Age?.indexOf(name) > -1} />
              <ListItemText
                primary={CheckValueLocale(
                  name.split(" ").join("_"),
                  "",
                  {},
                  intl,
                )}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel
          className="survey-input-label"
          id="demo-multiple-checkbox-industries"
        >
          {CheckValueLocale("survey_industries", "", {}, intl)}
        </InputLabel>

        <Select
          labelId="demo-multiple-checkbox-industries"
          className="survey-panel-select"
          multiple
          disabled={isSurveyPanelEnabled}
          name="Industries"
          value={selectedValues.Industries}
          onChange={handleChangevalue}
          input={
            <OutlinedInput
              label={CheckValueLocale("survey_industries", "", {}, intl)}
            />
          }
          renderValue={() =>
            selectedValues?.Industries?.map((selected) =>
              CheckValueLocale(
                selected?.includes(" ")
                  ? selected?.split(" ")?.join("_")
                  : selected,
                "",
                {},
                intl,
              ),
            ).join(", ")
          }
          MenuProps={MenuProps}
        >
          {surveyPanelList?.Industries?.map((name, index) => (
            <MenuItem className="menu-item-panel" key={name} value={name}>
              <Checkbox
                checked={selectedValues?.Industries?.indexOf(name) > -1}
              />
              <ListItemText
                primary={CheckValueLocale(
                  name.split(" ").join("_"),
                  "",
                  {},
                  intl,
                )}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel
          className="survey-input-label"
          id="demo-multiple-checkbox-gender"
        >
          {CheckValueLocale("survey_gender", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-gender"
          className="survey-panel-select"
          multiple
          disabled={isSurveyPanelEnabled}
          name="Gender"
          value={selectedValues.Gender}
          onChange={handleChangevalue}
          input={
            <OutlinedInput
              label={CheckValueLocale("survey_gender", "", {}, intl)}
            />
          }
          renderValue={() =>
            selectedValues?.Gender?.map((selected) =>
              CheckValueLocale(
                selected?.includes(" ")
                  ? selected?.split(" ")?.join("_")
                  : selected,
                "",
                {},
                intl,
              ),
            ).join(", ")
          }
          MenuProps={MenuProps}
        >
          {surveyPanelList?.Gender?.map((name, index) => (
            <MenuItem className="menu-item-panel" key={name} value={name}>
              <Checkbox checked={selectedValues?.Gender?.indexOf(name) > -1} />

              <ListItemText
                primary={CheckValueLocale(
                  name?.split(" ").join("_"),
                  "",
                  {},
                  intl,
                )}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SurveyPannel;
