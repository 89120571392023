import { Box, Divider } from "@mui/material";
import HeaderSidebar from "./components/header";
import AccountsSection from "./components/accountsSection";
import UpcomingSection from "./components/upcomingSection";
import "./sidebar.scss";

const PublishSidebar = (props) => {
  const { accountData, upcommingList } = props;
  let showReload = accountData?.length;
  return (
    <Box className="publish-sidebar-container">
      <HeaderSidebar {...props} showReload={showReload} />
      <Box className="publish-sidebar-main-content">
        <AccountsSection {...props} />
        <Divider
          className={`publish-sidebar-divider ${
            !showReload && !upcommingList?.length
              ? "publish-sidebar-divider-empty"
              : ""
          }`}
        />
        <UpcomingSection {...props} />
      </Box>
    </Box>
  );
};

export default PublishSidebar;
