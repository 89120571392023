import Themes from "services/api/themes";

var ThemesController = {};

// Get Themes list Controller
ThemesController.getThemesList = () => {
  return new Promise((resolve, reject) => {
    Themes.getThemesList()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Reorder Themes Controller
ThemesController.reorderThemes = (queryData) => {
  return new Promise((resolve, reject) => {
    Themes.reorderThemes(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get all colors for Themes (used and unused)
ThemesController.getThemesColors = () => {
  return new Promise((resolve, reject) => {
    Themes.getThemesColors()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Create New Theme Controller
ThemesController.createNewTheme = (queryData) => {
  return new Promise((resolve, reject) => {
    Themes.createNewTheme(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Edit Theme Controller
ThemesController.updateTheme = (queryData) => {
  return new Promise((resolve, reject) => {
    Themes.updateTheme(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Delete Theme Controller
ThemesController.deleteTheme = (queryData) => {
  return new Promise((resolve, reject) => {
    Themes.deleteTheme(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

export default ThemesController;
