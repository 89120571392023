import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import StepFields from "../components/stepFields";

const StepTwo = (props) => {
  const { stepFields, selectedData, setSelectedData, stepFieldsErr } = props;
  const intl = useIntl();

  return (
    <Box className="luci-steps-container">
      <Box>
        <Typography component="h3">
          {CheckValueLocale("luci_branding_and_marketing", "", {}, intl)}
        </Typography>
        <StepFields
          stepNum={2}
          stepFields={stepFields}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          stepFieldsErr={stepFieldsErr}
        />
      </Box>
    </Box>
  );
};

export default StepTwo;
