import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, Typography, Box } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import { ArrowForwardIos } from "@mui/icons-material";
import { CheckValueLocale } from "../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";

const DashboardHeader = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [dashboardUrl, setDashboardUrl] = useState("");
  const { dashboardDetails, dashboardId } = props;
  const sideBarData = dashboardDetails?.attributes?.dashboard_side_bar;
  const monitorType = dashboardDetails?.attributes?.monitor_type;
  //to get active dashboard tab
  const getActiveDashboardTab = () => {
    let activeDashboardTab = "";
    sideBarData[0]?.analytics && sideBarData[0]?.analytics[0]
      ? (activeDashboardTab = sideBarData[0]?.analytics[0]?.tabName)
      : sideBarData[0]?.list && sideBarData[0]?.list[0]
        ? (activeDashboardTab = sideBarData[0]?.list[0]?.tabName)
        : (activeDashboardTab = "");
    return activeDashboardTab;
  };

  //this function to redirect user to the correct dashboard url
  const getDashboardPageUrl = () => {
    setDashboardUrl(
      `dashboard/${monitorType}/${getActiveDashboardTab()}/${dashboardId}`,
    );
  };
  useEffect(() => {
    getDashboardPageUrl();
  }, []);

  const links = [
    {
      name: "social_listening",
      url: "social/monitor_list",
      addNameLocal: true,
    },
    {
      name: "aggregate_dashboards",
      url: "dashboard/dashboard_list",
      addNameLocal: true,
    },
    {
      name: dashboardDetails?.attributes?.name
        ? dashboardDetails?.attributes?.name
        : "",
      url: dashboardDetails?.attributes?.is_accessible ? dashboardUrl : "",
      addNameLocal: true,
      disableURL: !dashboardDetails?.attributes?.is_accessible,
    },
  ];
  return (
    <Box className="edit-dashboard-header">
      <BreadCrumb links={links} />
      <Box>
        <Typography variant="h6">
          {CheckValueLocale("edit_dashboard", "", {}, intl)}
        </Typography>
        <Button
          onClick={() =>
            history.push({
              pathname: `/${dashboardUrl}`,
            })
          }
          disabled={!dashboardDetails?.attributes?.is_accessible}
          id="dashboard-sidebar-back-to-dashboard-edit-btn"
        >
          {CheckValueLocale("back_to_dashboard", "", {}, intl)}
          <ArrowForwardIos />
        </Button>
      </Box>
      <Divider className="divider-edit-info" />
    </Box>
  );
};

export default DashboardHeader;
