import { Box, Divider, Skeleton } from "@mui/material";
import "./profileSideBar.scss";

const ProfileSideBarLoading = () => {
  const firstCounterArr = [1, 2];
  const secCounterArr = [1, 2, 3, 4];
  const thirdCounterArr = [1, 2];

  return (
    <Box className="profile-side-bar-loader">
      <Box className="profile-side-bar-heading">
        <Skeleton animation="pulse" variant="circular" width={60} height={60} />
        <Skeleton animation="pulse" height={17} width="100px" />
      </Box>
      {firstCounterArr?.map((i) => (
        <Box className="profile-side-bar-lines">
          <Skeleton animation="pulse" height={17} width="100px" />
          <Skeleton animation="pulse" height={17} width="33px" />
        </Box>
      ))}
      <Divider orientation="vertical" />
      <Box className="profile-side-bar-lines">
        <Skeleton animation="pulse" height={17} width="61px" />
      </Box>
      {secCounterArr?.map((i) => (
        <Box className="profile-side-bar-lines">
          <Skeleton animation="pulse" height={17} width="100px" />
          <Skeleton animation="pulse" height={17} width="33px" />
        </Box>
      ))}
      <Divider orientation="vertical" />
      {thirdCounterArr?.map((i) => (
        <Box className="profile-side-bar-sec-lines">
          <Skeleton animation="pulse" height={17} width="61px" />
          <Box className="lines-wrap">
            <Skeleton animation="pulse" height={38} width="100px" />
            <Skeleton animation="pulse" height={38} width="100px" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ProfileSideBarLoading;
