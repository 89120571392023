import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import WidgetHeader from "components/widgets/widgetHeader";
import { useParams } from "react-router-dom";

import MediaTabs from "../mediaTabs/mediaTabs";
import MediaTw from "../mediaComponents/twMedia";
import MediaInsta from "../mediaComponents/igMedia";
import MediaFb from "../mediaComponents/fbMedia";

const AllTopMedia = (props) => {
  const { monitorType } = useParams();

  const {
    title,
    twData,
    igData,
    fbData,
    errorMessage,
    iconAfterTitle,
    mediaTwPreLoaderDataSources,
    mediaInstaPreLoaderDataSources,
    mediaFbPreLoaderDataSources,
    showDownloadIcon,
    titleToolTip,
    insightsID,
    response,
    dates,
    allDatasources,
  } = props;
  const [value, setValue] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    allDatasources?.length && setValue(allDatasources[0]);
  }, []);
  const isManaged =
    monitorType === "MANAGED_PAGE" || monitorType === "MANAGED_ACCOUNT";
  return (
    <>
      <div className="chart_media">
        <Box>
          <WidgetHeader
            title={title}
            showDownloadIcon={showDownloadIcon}
            iconAfterTitle={iconAfterTitle}
            titleToolTip={titleToolTip}
          />
          {allDatasources?.length > 1 && (
            <MediaTabs
              handleChange={handleChange}
              value={value}
              allDatasource={allDatasources}
            />
          )}
          <Box mt={3}>
            {value === "TWITTER" && (
              <MediaTw
                data={twData}
                errorMessage={errorMessage}
                mediaTwPreLoaderDataSources={mediaTwPreLoaderDataSources}
                preLoaderTrackerDataSources={["TWITTER"]}
              />
            )}
            {value === "INSTAGRAM" && (
              <MediaInsta
                title={title}
                titleToolTip={titleToolTip}
                data={igData}
                errorMessage={errorMessage}
                hideMoreInsights={isManaged ? false : true}
                monitorInstagramMedia
                mediaInstaPreLoaderDataSources={mediaInstaPreLoaderDataSources}
                preLoaderTrackerDataSources={["INSTAGRAM"]}
                insightsID={isManaged ? insightsID : null}
                questions={igData}
                response={response}
                dates={dates}
                datasourcePostsType="INSTAGRAM"
              />
            )}
            {value === "FACEBOOK" && (
              <MediaFb
                title={title}
                data={fbData}
                errorMessage={errorMessage}
                mediaFbPreLoaderDataSources={mediaFbPreLoaderDataSources}
                preLoaderTrackerDataSources={["FACEBOOK"]}
                showDownloadIcon
                hideMoreInsights={isManaged ? false : true}
                questions={fbData}
                titleToolTip={titleToolTip}
                insightsID={isManaged ? insightsID : null}
                response={response}
                dates={dates}
                datasourcePostsType="FACEBOOK"
              />
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default AllTopMedia;
