import "../tableWidget/tableWidget.scss";
import { TableCell, TableRow } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import SentimentLabelContent from "./sentimentLabelContent";

const WordsList = (props) => {
  const intl = useIntl();
  const getFiltersItem = (data) => {
    let filteredData = [];
    data?.map((val) => {
      filteredData.push(CheckValueLocale(val, "", {}, intl));
    });
    return filteredData?.join(",");
  };

  return (
    <TableRow className="tr-bg">
      <TableCell className="text-row-content-widget">
        <span name={props?.name} className="title-name-widget">
          {CheckValueLocale(props?.name, "", {}, intl)}:
        </span>
      </TableCell>
      <TableCell>
        {Array.isArray(props?.value) &&
        props?.value?.length &&
        !props?.isSentimentContent ? (
          getFiltersItem(props?.value)
        ) : !Array.isArray(props?.value) &&
          props?.value !== undefined &&
          props?.value !== null &&
          props?.isSentimentContent ? (
          <SentimentLabelContent value={props?.value} />
        ) : props?.value ? (
          CheckValueLocale(props?.value, "", {}, intl)
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>
        {Array.isArray(props?.subValue) &&
        props?.subValue?.length &&
        !props?.isSentimentContent ? (
          getFiltersItem(props?.subValue)
        ) : !Array.isArray(props?.subValue) &&
          props?.subValue !== undefined &&
          props?.subValue !== null &&
          props?.isSentimentContent ? (
          <SentimentLabelContent value={props?.subValue} />
        ) : props?.subValue ? (
          CheckValueLocale(props?.subValue, "", {}, intl)
        ) : (
          "-"
        )}
      </TableCell>
    </TableRow>
  );
};
export default WordsList;
