import { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import AudienceController from "services/controllers/audienceController";

const ImportFunctions = () => {
  const intl = useIntl();
  const [files, setFiles] = useState([]);
  const [enableProfileAudience, setEnableProfileAudience] = useState(false);
  const [enableUncomplyingAudience, setEnableUncomplyingAudience] =
    useState(false);
  const [openImportPopUp, setOpenImportPopUp] = useState(false);
  const [excelGuideData, setExcelGuideData] = useState(false);
  let excelFileName = files && files[0]?.file?.name;
  let excelFileError = files && files[0]?.errors;
  let excelFileErrorMsg = excelFileError && files[0]?.errors[0]?.code;
  let fileName = excelFileName && excelFileName?.split(".")[0];

  const displayUploadErrorMessage = () => {
    const regex = /^[\u0621-\u064A0-9a-zA-Z0-9-_]+$/;
    const result = regex?.test(fileName);
    let errorMessage = "";
    files?.length
      ? !excelFileName?.includes("xls", "xlsx") ||
        excelFileErrorMsg === "file-invalid-type"
        ? (errorMessage = "audience_file_format_not_supported")
        : !result || fileName?.length > 20
          ? (errorMessage = "audience_wrong_file_name")
          : excelFileErrorMsg === "file-too-large"
            ? (errorMessage = "audience_file_exceed_size_limit")
            : (errorMessage = "")
      : (errorMessage = "");
    return errorMessage;
  };

  const handleImportAudience = () => {
    setOpenImportPopUp(true);
  };

  const handleResetImportPopUp = () => {
    setOpenImportPopUp(false);
    setEnableProfileAudience(false);
    setEnableUncomplyingAudience(false);
    setFiles([]);
  };

  const handleExcelGuideData = () => {
    setExcelGuideData([]);
    AudienceController.getCDPXlsxGuide().then((result) => {
      setExcelGuideData(result?.data);
    });
  };
  const handleCancelUploadFile = () => {
    setEnableProfileAudience(false);
    setEnableUncomplyingAudience(false);
    setFiles([]);
  };
  const getTitleImportExcel = () => {
    return (
      <Box>
        {CheckValueLocale(
          "audience_import_excel_success_message",
          "",
          {},
          intl,
        )}
        <Link
          className="audience-import-manage"
          to={{
            pathname: `/audience/manage-audience`,
          }}
          onClick={window.localStorage.setItem("importState", "true")}
          target="_blank"
        >
          {CheckValueLocale("audience_import_manage_message", "", {}, intl)}
        </Link>
      </Box>
    );
  };

  let isFileUploaded =
    excelFileName &&
    files?.length &&
    !excelFileErrorMsg &&
    !displayUploadErrorMessage();
  return {
    files,
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    fileName,
    displayUploadErrorMessage,
    handleImportAudience,
    handleResetImportPopUp,
    setOpenImportPopUp,
    enableProfileAudience,
    setEnableProfileAudience,
    enableUncomplyingAudience,
    setEnableUncomplyingAudience,
    openImportPopUp,
    getTitleImportExcel,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
    setExcelGuideData,
  };
};

export default ImportFunctions;
