import Services from "./Services";
var InstagramHashtags = {};

InstagramHashtags.getHashtags = (queryData) => {
  return Services.getData("api/v4/utilities/instagram_hashtags", queryData);
};

InstagramHashtags.deletHashtag = (hashtagId) => {
  return Services.deleteData(
    "api/v4/utilities/instagram_hashtags",
    {},
    hashtagId,
  );
};

InstagramHashtags.getHashtagsHeader = () => {
  return Services.getData(`api/v4/utilities/instagram_hashtags/hashtag_header`);
};

export default InstagramHashtags;
