import React from "react";
import { Box } from "@mui/material";
import DashboardSideBar from "../sidebar/dashboardSideBar";
import DashboardHeader from "./dashboardHeader";
import ActiveFilters from "shared/general-filter/activeFilters/activeFilters";

const DashboardSideBarAndHeader = (props) => {
  return (
    <>
      <DashboardHeader {...props} />
      <Box className="active-filters-box">
        <ActiveFilters {...props} />
      </Box>
      <DashboardSideBar {...props} />
    </>
  );
};

export default DashboardSideBarAndHeader;
