import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import styles from "../commonStyle.module.css";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import "../../../surveyBuilder.scss";
const DropdownQuestion = (props) => {
  const { question, questionNo, handleProceedAnswer } = props;

  const intl = useIntl();
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const { alpabetical_order, randomaize } = question?.question?.constraints;
    if (alpabetical_order) {
      let sortedItems = question?.question?.items?.sort((a, b) =>
        Object.values(a)[0].localeCompare(Object.values(b)[0]),
      );
      if (sortedItems?.length !== 0) setOptions(sortedItems);
      return;
    } else if (randomaize) {
      const shuffleArray = (array) => {
        for (let i = array?.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      };
      const randomizedItems = shuffleArray([...question?.question?.items]);
      if (randomizedItems?.length !== 0) setOptions(randomizedItems);
      return;
    } else return setOptions(question?.question?.items);
  }, [question]);

  const handleChangeSelected = (e) => {
    setSelectedValue(e.target.value);
    if (question?.is_proceed_on_answer)
      handleProceedAnswer(options[e.target.value]?.next_section);
  };

  return (
    <>
      <Card className="cardStyle">
        <CardContent>
          <Typography className={styles.questionStyle}>
            <Box
              component="span"
              className="questionNoStyle"
              style={{ fontWeight: "500" }}
            >{`${questionNo}.`}</Box>
            {question?.question?.text}
            {question?.question?.constraints?.required && (
              <Box component="span" className={styles.impQuestion}>
                *
              </Box>
            )}
          </Typography>
          <Box className={styles.subtitle} style={{ marginBottom: "15px" }}>
            {question?.question?.description}
          </Box>
          <FormControl
            className={`${styles.dropdownStyle} dropdown-question-style`}
          >
            <InputLabel id="demo-simple-select-helper-label">
              {CheckValueLocale("choose", "", {}, intl)}
            </InputLabel>
            <Select
              label={CheckValueLocale("choose", "", {}, intl)}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              className={styles.dropdownStyleInput}
              value={selectedValue}
              onChange={(e) => handleChangeSelected(e)}
            >
              {options?.map((val, i) => {
                return (
                  <MenuItem
                    id={`builder-preview-dropdown-select-${i}`}
                    value={i}
                    key={i}
                  >
                    {CheckValueLocale(Object.values(val)[0], "", {}, intl)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </CardContent>
      </Card>
    </>
  );
};

export default DropdownQuestion;
