import { useIntl } from "react-intl";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./requestFilter.scss";
import { useState } from "react";

const RequestFilter = ({
  getRequests,
  setRequestSortBy,
  requestSortBy,
  sortByData,
}) => {
  const intl = useIntl();
  const [page, setPage] = useState(1);

  const handleChange = (event) => {
    setRequestSortBy(event?.target?.value);
    getRequests(page, event?.target?.value === "done" ? "done" : "");
  };

  return (
    <Box className="luci-request-filter">
      <FormControl className="request-select-option-wrapper">
        <Select
          value={requestSortBy}
          onChange={handleChange}
          className="request-select-option"
          MenuProps={{
            style: { zIndex: 99999 },
          }}
        >
          {sortByData?.map((requestFilter) => (
            <MenuItem
              key={requestFilter}
              value={requestFilter}
              className="request-option-wrapper"
            >
              <Box className="request-option-value">
                {CheckValueLocale(
                  `luci_${requestFilter}_requests`,
                  "",
                  {},
                  intl,
                )}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RequestFilter;
