import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import CircularLoading from "components/circularProgress";
const LatestSearchList = (props) => {
  const intl = useIntl();
  const {
    isLatestSearchLoading,
    latestSearchListData,
    getPostsCount,
    handleShowSavedExplore,
  } = props;

  return (
    <Box
      className={`explore-saved-results-container ${
        isLatestSearchLoading && "explore-saved-results-loading"
      }`}
    >
      {isLatestSearchLoading ? (
        <CircularLoading />
      ) : (
        <Box className="saved-results-list">
          <Typography
            component={"p"}
            className={`saved-results-title ${
              latestSearchListData?.length && "saved-results-title-no-empty"
            }`}
          >
            {CheckValueLocale("explore_latest_search_title", "", {}, intl)}
          </Typography>
          <Box className="latest-search-content-data">
            {latestSearchListData?.map((item) => {
              return (
                <Box
                  key={item?.id}
                  className="saved-results-grid"
                  onClick={() =>
                    handleShowSavedExplore(item?.id, item?.attributes?.status)
                  }
                >
                  <Box className="saved-result-content">
                    <Box className="saved-ressult-content-wrapper">
                      <Typography
                        component={"p"}
                        className="saved-results-name"
                      >
                        {item?.attributes?.name || ""}
                      </Typography>
                      <Typography
                        component={"p"}
                        className="saved-results-count-posts"
                      >
                        {CheckValueLocale(
                          "explore_saved_res_posts_count",
                          "",
                          {
                            count: item?.attributes?.collected_count?.length
                              ? getPostsCount(item?.attributes?.collected_count)
                              : 0,
                          },
                          intl,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LatestSearchList;
