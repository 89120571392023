import { useEffect, useState } from "react";

/**
 * useShowMore hook is used to handle show more and show less functionality
 * @param {Array} data - the original source of data
 * @param {Object} pagination - the pagination object
 * @returns {Object} - the returned object contains:
 * - showMore: the state of show more button
 * - setShowMore: the function to set show more button state
 * - showedData: the data to show
 * - setShowedData: the function to set showed data
 * - isLoading: the state of loading
 * - setIsLoading: the function to set loading state
 * - isSettled: the state of settled
 * - setIsSettled: the function to set settled state
 * - onShowLess: the function to handle show less
 * - nextPage: the next page url, if doesn't exist, it will be null
 * - hasPagination: the state of pagination
 */
export const useShowMore = ({ data, pagination }) => {
  const [showMore, setShowMore] = useState(true);
  const [showedData, setShowedData] = useState(
    data?.slice(0, pagination?.items || 6),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSettled, setIsSettled] = useState(false);
  const onShowLess = () => {
    setShowedData(data?.slice(0, pagination?.items || 6));
    setShowMore(true);
  };

  return {
    showMore,
    setShowMore,
    showedData,
    setShowedData,
    isLoading,
    setIsLoading,
    isSettled,
    setIsSettled,
    onShowLess,
    nextPage: pagination?.next,
    hasPagination: !!pagination?.next || !!pagination?.prev,
  };
};
