import { Box, Grid, Typography } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import BarChartWidget from "components/widgets/barChartWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import {
  handleAggregateLegends,
  handleAggregateBarCharts,
  handleAggregatePunchCardCharts,
} from "utils/helpers/aggregateFunctions";
import { handleMinMaxValues } from "utils/helpers/index";
import _ from "lodash";
import moment from "moment";
import { CheckValueLocale } from "../../../../../../utils/helpers/index";
const OverViewSection = (props) => {
  const intl = useIntl();
  const {
    widgetsName,
    getIcons,
    metricsValues,
    response,
    isPublicAccount,
    resetAllFields,
    startDate,
    endDate,
    monitorType,
  } = props;

  //handle response
  const [pageViews, setPageViews] = useState([]);
  const [pageViewsCount, setPageViewsCount] = useState(0);
  const [pageViewsPreLoaderDataSources, setPageViewsPreLoaderDataSources] =
    useState([]);

  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [totalFollowersFollowersGrowth, setTotalFollowersGrowth] = useState(0);
  const [maxChangeFollowersGrowth, setMaxChangeFollowersGrowth] = useState(0);
  const [avgChangeFollowersGrowth, setAvgChangeFollowersGrowth] = useState(0);
  const [
    followersGrowthPreLoaderDataSources,
    setFollowersGrowthPreLoaderDataSources,
  ] = useState([]);

  const [pageReachImpressions, setPageReachImpressions] = useState([]);
  const [pageReachImpressionLegend, setPageReachImpressionLegend] = useState(
    [],
  );
  const [reachArray, setReachArray] = useState([]);
  const [impressionArray, setImpressionArray] = useState([]);
  const [
    pageReachImpressionsPreLoaderDataSources,
    setPageReachImpressionsPreLoaderDataSources,
  ] = useState([]);

  const [pageContactDetails, setPageContactDetails] = useState([]);
  const [pageContactDetailsLegends, setPageContactDetailsLegends] = useState(
    [],
  );
  const [
    pageContactDetailsPreLoaderDataSources,
    setPageContactDetailsPreLoaderDataSources,
  ] = useState([]);

  const [pageLikesUnlike, setPageLikesUnlike] = useState([]);
  const [pageLikesUnlikeLegends, setPageLikesUnlikeLegends] = useState([]);
  const [
    pageLikesUnlikePreLoaderDataSources,
    setPageLikesUnlikePreLoaderDataSources,
  ] = useState([]);

  const [pageActions, setPageActions] = useState([]);
  const [pageActionsLegends, setPageActionsLegends] = useState([]);
  const [pageActionsPreLoaderDataSources, setPageActionsPreLoaderDataSources] =
    useState([]);

  const [reachFunnel, setReachFunnel] = useState([]);
  const [reachFunnelPreLoaderDataSources, setReachFunnelPreLoaderDataSources] =
    useState([]);

  const [authorActivity, setAuthorActivity] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);
  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);

  useEffect(() => {
    switch (response?.eventName) {
      case "Facebook__AccountPage__page_views":
        setPageViews(response.eventData.page_views_overtime);
        setPageViewsCount(response.eventData.total_page_views[0].value);
        setPageViewsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__followers_growth":
      case "Instagram__AccountPage__followers_growth":
        handleAggregateLegends(
          response.eventData.followers_change_over_time,
          followersGrowth,
        );
        var new_total_followers = 0;
        if (
          Array.isArray(response.eventData.total_followers) &&
          response.eventData.total_followers.length > 0
        ) {
          new_total_followers = response.eventData.total_followers[0];
        } else if (
          Array.isArray(response.eventData.total_followers) &&
          response.eventData.total_followers.length == 0
        ) {
          new_total_followers = 0;
        } else {
          new_total_followers = response.eventData.total_followers;
        }
        setTotalFollowersGrowth(
          totalFollowersFollowersGrowth + parseInt(new_total_followers),
        );
        setFollowersGrowthPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__page_reach_impressions":
        Object.entries(
          response.eventData.page_reach_impressions_overtime,
        ).forEach(([key, value]) => {
          switch (key) {
            case "page_reach_over_time":
              value.map((i) => {
                return reachArray.push(i);
              });
              break;
            case "page_impressions_over_time":
              value.map((i) => {
                return impressionArray.push(i);
              });
              break;

            default:
              break;
          }
        });

        (reachArray?.length > 0 || impressionArray?.length > 0) &&
          setPageReachImpressions({
            reach: reachArray,
            impression: impressionArray,
          });
        setPageReachImpressionLegend(
          response.eventData.total_page_reach_impressions,
        );
        setPageReachImpressionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__page_contact_details_interactions":
      case "Instagram__AccountPage__page_contact_details_interactions":
        handleAggregateBarCharts(
          response.eventData.page_contact_details_interactions_overtime,
          pageContactDetails,
        );
        handleAggregateLegends(
          response.eventData.pie_chart_page_contact_details_interactions,
          pageContactDetailsLegends,
        );
        setPageContactDetailsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__page_likes_unlikes":
        setPageLikesUnlike(response.eventData.page_likes_unlikes_overtime);
        setPageLikesUnlikeLegends(
          response.eventData.pie_chart_page_likes_unlikes,
        );
        setPageLikesUnlikePreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__page_actions":
        setPageActions(response.eventData.page_actions_overtime);
        setPageActionsLegends(response.eventData.pie_chart_page_actions);
        setPageActionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__reach_funnel":
        setReachFunnel(response.eventData.reach_funnel);
        setReachFunnelPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__account_author_activity":
      case "Facebook__AccountPage__account_author_activity":
      case "Instagram__AccountPage__account_author_activity":
        handleAggregatePunchCardCharts(
          response.eventData.account_author_activity,
          authorActivity,
        );
        handleMinMaxValues(
          response.eventData.max_value,
          undefined,
          setMaxValueAuthorActivity,
          maxValueAuthorActivity,
          undefined,
          "max",
        );
        handleMinMaxValues(
          response.eventData.min_value,
          undefined,
          setMinValueAuthorActivity,
          minValueAuthorActivity,
          undefined,
          "min",
        );
        setAuthorActivityPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (
      metricsValues?.followers_growth &&
      _.isEqual(
        followersGrowthPreLoaderDataSources?.sort(),
        [...metricsValues?.followers_growth?.data_sources]?.sort(),
      ) === true
    ) {
      let totalVal = 0;
      followersGrowth?.map((obj) => {
        totalVal += obj?.value;
      });
      const datediff = (first, second) => {
        return Math.round((second - first) / (1000 * 60 * 60 * 24) + 1);
      };

      const parseDate = (date) => {
        let mdy = date.split("/");
        return new Date(mdy[2], mdy[0] - 1, mdy[1]);
      };

      const noOfDays = datediff(
        parseDate(moment.unix(startDate).format("MM/DD/YYYY")),
        parseDate(moment.unix(endDate).format("MM/DD/YYYY")),
      );
      setAvgChangeFollowersGrowth(totalVal / noOfDays);
      setMaxChangeFollowersGrowth(totalVal);
    }
  }, [followersGrowthPreLoaderDataSources]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setPageViews([]);
      setPageViewsCount(0);
      setPageViewsPreLoaderDataSources([]);

      setFollowersGrowth([]);
      setFollowersGrowthPreLoaderDataSources([]);
      setTotalFollowersGrowth(0);
      setMaxChangeFollowersGrowth(0);
      setAvgChangeFollowersGrowth(0);

      setPageReachImpressions([]);
      setPageReachImpressionLegend([]);
      setImpressionArray([]);
      setReachArray([]);
      setPageReachImpressionsPreLoaderDataSources([]);

      setPageContactDetails([]);
      setPageContactDetailsLegends([]);
      setPageContactDetailsPreLoaderDataSources([]);

      setPageLikesUnlike([]);
      setPageLikesUnlikeLegends([]);
      setPageLikesUnlikePreLoaderDataSources([]);

      setPageActions([]);
      setPageActionsLegends([]);
      setPageActionsPreLoaderDataSources([]);

      setReachFunnel([]);
      setReachFunnelPreLoaderDataSources([]);

      setAuthorActivity([]);
      setAuthorActivityPreLoaderDataSources([]);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
    }
  }, [resetAllFields]);

  return (
    <>
      {widgetsName?.length > 1 && (
        <Typography className="section-name">
          {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
        </Typography>
      )}
      {widgetsName?.includes("page_views") && (
        <Box mt={3}>
          <LineChartWidget
            title={"page_views"}
            totalLabel={"views"}
            totalValue={pageViewsCount}
            data={pageViews}
            showGroupBy={true}
            toolTipLabel={"views"}
            showDownloadIcon
            bigImg
            lineChartPreLoaderDataSources={pageViewsPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.page_views?.data_sources,
            ]}
            iconAfterTitle={getIcons([
              ...metricsValues?.page_views?.data_sources,
            ])}
            labelColor={"#2AAAE2"}
            showReportIcon
            titleToolTip={"page_views_fb_tooltip"}
          />
        </Box>
      )}
      {widgetsName?.includes("followers_growth") && (
        <Box mt={3}>
          <BarChartWidget
            title={"followers_growth"}
            titleToolTip={
              monitorType === "ACCOUNT_ANALYSIS"
                ? "aggregated_followers_growth_tw_tooltip"
                : "followers_growth_ig_tooltip"
            }
            data={followersGrowth}
            showGroupBy={true}
            dataColor="#2AAAE2"
            showChartDetailsWidget
            showZoomPercentage
            statsDetailsData={[
              {
                name: "total_followers",
                value: totalFollowersFollowersGrowth,
              },
              {
                name: "max_change_of_followers",
                value: maxChangeFollowersGrowth,
              },
              {
                name: "avg_change_per_day",
                value: avgChangeFollowersGrowth,
              },
            ]}
            toolTipLabel={"posts"}
            showFollowersGrowthStats
            showDownloadIcon
            bigImg
            iconAfterTitle={getIcons([
              ...metricsValues?.followers_growth?.data_sources,
            ])}
            barChartPreLoaderDataSources={followersGrowthPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.followers_growth?.data_sources,
            ]}
          />
        </Box>
      )}
      {widgetsName?.includes("page_reach_impressions") && (
        <Box mt={3}>
          <StackedLineWidget
            title={"page_reach_impressions"}
            data={pageReachImpressions}
            showGroupBy={true}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI={false}
            showDownloadIcon
            bigImg
            stackedLineWidgetPreLoaderDataSources={
              pageReachImpressionsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={[
              ...metricsValues?.page_reach_impressions?.data_sources,
            ]}
            isSwitchCase
            iconAfterTitle={getIcons([
              ...metricsValues?.page_reach_impressions?.data_sources,
            ])}
            showGroupByClientAgent={true}
            showScales
            colors={["#2AAAE2", "#80868C"]}
            labelColors={["#2AAAE2", "#80868C"]}
            showReportIcon
            titleToolTip={"page_reach_impressions_fb_tooltip"}
            hideLegend
            dataPiechart={pageReachImpressionLegend}
            isPostsReachImpression
          />
        </Box>
      )}

      {widgetsName?.includes("page_contact_details_interactions") && (
        <Box mt={3}>
          <StackedBarWidget
            title={"page_contact_details_interactions"}
            titleToolTip={"page_contact_details_interactions_ig_tooltip"}
            showDownloadIcon
            bigImg
            posts={pageContactDetails}
            colors={["#2AAAE2", "#80868C", "#334155", "#AFBCCE"]}
            labels={[
              "website_clicks",
              "phone_clicks",
              "directs_clicks",
              "profile_visits",
            ]}
            showScales
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.page_contact_details_interactions?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={
              pageContactDetailsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={[
              ...metricsValues?.page_contact_details_interactions?.data_sources,
            ]}
            showReportIcon
            hideLegend
            dataPiechart={pageContactDetailsLegends}
          />
        </Box>
      )}

      {widgetsName?.includes("page_Likes_unlikes") && (
        <Box my={3}>
          <StackedBarWidget
            title={"page_like_unlikes"}
            showDownloadIcon
            bigImg
            posts={pageLikesUnlike}
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.page_Likes_unlikes?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={
              pageLikesUnlikePreLoaderDataSources
            }
            preLoaderTrackerDataSources={[
              ...metricsValues?.page_Likes_unlikes?.data_sources,
            ]}
            showGroupByClientAgent={true}
            showScales
            colors={["#2AAAE2", "#80868C"]}
            labels={["unlike_page_over_time", "like_page_over_time"]}
            hideLegend
            dataPiechart={pageLikesUnlikeLegends}
            showReportIcon
            titleToolTip={"page_like_unlikes_fb_tooltip"}
          />
        </Box>
      )}
      {widgetsName?.includes("page_actions") && (
        <Box my={3}>
          <StackedBarWidget
            title={"page_actions"}
            showDownloadIcon
            bigImg
            posts={pageActions}
            colors={["#C51162", "#4CAF50"]}
            labels={[
              "page_negative_interactions_over_time",
              "page_positive_interactions_over_time",
            ]}
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.page_actions?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={pageActionsPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.page_actions?.data_sources,
            ]}
            showGroupByClientAgent={true}
            showScales
            showReportIcon
            titleToolTip={"page_actions_fb_tooltip"}
            hideLegend
            dataPiechart={pageActionsLegends}
          />
        </Box>
      )}

      {widgetsName?.includes("account_authors_activity") && (
        <Box my={3} className="channel-activity">
          <PunchCardWidget
            title="account_authors_activity"
            titleToolTip={
              isPublicAccount === "ACCOUNT_ANALYSIS"
                ? "account_authors_activity_tooltip_dash"
                : "account_authors_activity_fb_tooltip"
            }
            clientActivity={authorActivity}
            minValueClient={minValueAuthorActivity}
            maxValueClient={maxValueAuthorActivity}
            showDownloadIcon
            bigImg
            iconAfterTitle={getIcons([
              ...metricsValues?.account_authors_activity?.data_sources,
            ])}
            authorActivityPreLoaderDataSources={
              authorActivityPreLoaderDataSources
            }
            preLoaderTrackerDataSources={[
              ...metricsValues?.account_authors_activity?.data_sources,
            ]}
          />
        </Box>
      )}
      {widgetsName?.includes("reach_funnel") && (
        <Grid mb={3}>
          <StackedBarWidget
            title={"reach_funnel"}
            titleToolTip="reach_funnel_tw_tooltip"
            showDownloadIcon
            bigImg
            posts={reachFunnel}
            colors={["#64748B", "#2AAAE2"]}
            labels={["Retweets", "original"]}
            reachFunnel
            showScales
            iconAfterTitle={getIcons([
              ...metricsValues?.reach_funnel?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={reachFunnelPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.reach_funnel?.data_sources,
            ]}
          />
        </Grid>
      )}
    </>
  );
};

export default OverViewSection;
