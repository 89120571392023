import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import { TabContent } from "./tabContent";
import CompaniesController from "services/controllers/companiesController";
import { MultiSelectTab } from "./multiSelectTab";
import { TopicsTab } from "./topicsTab";

const tabsContent = [
  {
    tabName: "Sentiments",
    label: "select_sentiments",
    name: "sentiments",
    title: "sentiments_title",
  },
  {
    tabName: "Themes",
    label: "select_themes",
    name: "themes",
    title: "themes_title",
  },
  {
    tabName: "Segments",
    label: "select_segments",
    name: "segments",
    title: "segments_title",
    emptyListMessage: "no_segments_options",
    useLocales: false,
  },
  {
    tabName: "Topics",
    label: "Select_topics",
    name: "topics",
    title: "topics_title",
    emptyListMessage: "topics_empty_list_message",
  },
  {
    tabName: "Dialects",
    label: "select_dialects",
    name: "dialects",
    title: "dialects_title",
  },
];

export const InboxTabs = ({
  infoTabsData,
  setInfoTabsData,
  tabsErrors,
  setTabsErrors,
}) => {
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState(0);
  const prevTab = useRef(0);
  const [monitorsTopics, setsMonitorsTopic] = useState([]);
  const [isOptionsDataLoading, setIsOptionsDataLoading] = useState(false);
  const [isSegmentsLoading, setIsSegmentsLoading] = useState(false);
  const [optionsData, setOptionsData] = useState({
    segments: [],
    dialects: [],
    themes: [],
    sentiments: [],
  });
  const handleChange = (value, name) => {
    setInfoTabsData({
      ...infoTabsData,
      [name]: value,
    });
  };
  const getCompanyAiContent = () => {
    setIsOptionsDataLoading(true);
    CompaniesController?.getCompanyAiContent()?.then((res) => {
      if (!res?.errorMsg) {
        setOptionsData((prevOptions) => ({
          ...prevOptions,
          dialects: res?.data?.dialects?.map((dialect) => ({
            id: dialect?.name,
            name: dialect?.name,
          })),
          themes: res?.data?.themes?.map((theme) => ({
            id: theme?.name,
            name: theme?.name,
          })),
          sentiments: res?.data?.sentiment,
        }));
        setsMonitorsTopic(
          res?.data?.topics_monitors?.data?.map((topic) => topic?.attributes),
        );
      }
      setIsOptionsDataLoading(false);
    });
  };
  const getCompanySegments = () => {
    setIsSegmentsLoading(true);
    CompaniesController?.getCompanySegments()?.then((res) => {
      if (!res?.errorMsg) {
        setOptionsData((prevOptions) => ({
          ...prevOptions,
          segments: res?.data?.map((segment) => ({
            id: segment?.id,
            name: segment?.segment_name,
          })),
        }));
      }
      setIsSegmentsLoading(false);
    });
  };

  useEffect(() => {
    getCompanyAiContent();
    getCompanySegments();
  }, []);

  useEffect(() => {
    if (prevTab.current === currentTab) return;

    if (prevTab.current === 3) {
      const topicsPreError = tabsErrors?.topicsPreError;
      const topicsError = tabsErrors?.topics;
      if (topicsPreError && !topicsError) {
        setTabsErrors({
          ...tabsErrors,
          topics: true,
        });
      }
    }
    prevTab.current = currentTab;
  }, [currentTab]);
  return (
    <Box className="tabs-section-container">
      <Box className="tabs-section-header">
        <Typography variant="h3" className="tabs-section-title">
          {CheckValueLocale("user_inbox_engagement", "", {}, intl)}
        </Typography>
        <Typography variant="body1" className="tabs-section-description">
          {CheckValueLocale("user_advanced_info_desc", "", {}, intl)}
        </Typography>
      </Box>
      <Box className="user-tabs-container">
        <Box className="user-tabs-header">
          <Tabs value={currentTab} onChange={(_, tab) => setCurrentTab(tab)}>
            {tabsContent.map((tab) => (
              <Tab
                key={tab?.tabName}
                className={classNames(
                  "user-tabs-item",
                  tab?.name === "topics" &&
                    tabsErrors?.topics &&
                    currentTab !== 3
                    ? "user-tabs-item-error"
                    : "",
                )}
                label={CheckValueLocale(tab?.tabName, "", {}, intl)}
              />
            ))}
          </Tabs>
        </Box>
        <Box className="user-tabs-content">
          {tabsContent.map((tab, index) => {
            const { name } = tab;
            const tabValue = infoTabsData[name];
            const tabOptions = optionsData[name] || [];
            return (
              <TabContent index={index} value={currentTab}>
                {name === "topics" ? (
                  <TopicsTab
                    isLoading={isOptionsDataLoading}
                    {...tab}
                    selectedMonitorsTopics={tabValue}
                    monitorsTopics={monitorsTopics}
                    setTabsData={setInfoTabsData}
                    tabsErrors={tabsErrors}
                    setTabsErrors={setTabsErrors}
                  />
                ) : (
                  <MultiSelectTab
                    {...tab}
                    title={CheckValueLocale(tab?.title, "", {}, intl)}
                    label={CheckValueLocale(tab?.label, "", {}, intl)}
                    onChange={(event) =>
                      handleChange(event?.target?.value, name)
                    }
                    value={tabValue}
                    options={tabOptions}
                    isLoading={
                      name === "segments"
                        ? isSegmentsLoading
                        : isOptionsDataLoading
                    }
                    renderValue={(selected = []) => {
                      return (
                        <Typography className="user-tabs-content-select-msg">
                          {`${selected?.length} ${CheckValueLocale(
                            "tab_selected_options",
                            "",
                            {},
                            intl,
                          )}`}
                        </Typography>
                      );
                    }}
                  />
                )}
              </TabContent>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
