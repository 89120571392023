import "components/media/Media.scss";
import MediaFbItem from "components/media/media-fb/mediaFbItem.js";
import { isEmptyArray } from "utils/helpers";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { Box } from "@mui/material";
import NoDataFound from "components/no-Data/noDataFound";

const MediaFb = (props) => {
  const {
    data,
    questions,
    insightsID,
    response,
    dates,
    datasourcePostsType,
    mediaFbPreLoaderDataSources,
    preLoaderTrackerDataSources,
  } = props;
  return (
    <>
      {_.isEqual(
        mediaFbPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <Box>
          <FetchingData />
        </Box>
      ) : isEmptyArray(data) ? (
        <NoDataFound />
      ) : (
        <div className="topmedia-monitor-wrapper">
          {data.slice(0, 8)?.map((media, index) => (
            <MediaFbItem
              key={index}
              media={media}
              question={questions[index]}
              insightsID={insightsID}
              response={response}
              dates={dates}
              datasourcePostsType={datasourcePostsType}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default MediaFb;
