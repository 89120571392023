import { useIntl } from "react-intl";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import "./style.scss";

const NpsProceedModalBody = (props) => {
  const {
    filteredSections,
    sectionIndex,
    valuesList,
    tempProceedValues,
    setTempProceedValues,
    tempRemainingValueGoTo,
    setTempRemainingValueGoTo,
    remainingValues,
    handleChangeValue,
    handleChangeGoTo,
    handleRemoveCondition,
    showRemoveBtn,
  } = props;
  const intl = useIntl();

  const menuProps = {
    PaperProps: { style: { maxWidth: "260px" } },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: intl?.locale == "ar" ? "right" : "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: intl?.locale == "ar" ? "right" : "left",
    },
  };

  const sectionsList = [
    sectionIndex !== filteredSections?.length - 1 && {
      label: CheckValueLocale("continue_next_section", "", {}, intl),
      value: filteredSections[sectionIndex]?.order + 1,
    },
    {
      label: CheckValueLocale("submit_survey", "", {}, intl),
      value: -1,
    },
    ...(filteredSections
      ?.filter((_, i) => i !== sectionIndex && i !== sectionIndex + 1)
      ?.map((sec) => ({
        label: `${CheckValueLocale("section", "", {}, intl)} ${sec?.order} (${sec?.name})`,
        value: sec?.order,
      })) || []),
  ];

  return (
    <Box id="nps-proceed-popup-body">
      <Typography>
        {CheckValueLocale("proceed_based_on_answer_description", "", {}, intl)}
      </Typography>

      <Box className="nps-proceed-inputs-container">
        <Typography variant="h6" className="sec-title">
          {CheckValueLocale("if_answer_is", "", {}, intl)}
        </Typography>

        {tempProceedValues?.map((item, index) => (
          <Grid
            key={index}
            container
            spacing={2}
            className="nps-proceed-popup-body-grid"
          >
            <Grid item xs={6}>
              <FormControl
                className="nps-select-form-control"
                size="small"
                fullWidth
              >
                <InputLabel id={"nps-proceed-value-input-label"}>
                  {CheckValueLocale("proceed_value", "", {}, intl)}
                </InputLabel>
                <Select
                  id="nps-proceed-value-input"
                  label={CheckValueLocale("proceed_value", "", {}, intl)}
                  labelId="nps-proceed-value-input-label"
                  variant="outlined"
                  MenuProps={menuProps}
                  renderValue={(value) => (
                    <Box className={`nps-rendered-value ${value}`}>
                      {CheckValueLocale(`${value}_label`, "", {}, intl)}
                    </Box>
                  )}
                  value={item?.value}
                  onChange={(e) => handleChangeValue(e.target.value, index)}
                >
                  {valuesList
                    ?.filter(
                      (listItem) =>
                        !tempProceedValues
                          ?.map((val) => val?.value)
                          ?.includes(listItem?.value),
                    )
                    ?.map((opt) => (
                      <MenuItem
                        key={opt?.value}
                        className={`nps-select-item ${opt?.value}`}
                        value={opt?.value}
                      >
                        <Box component="span" className="text-box">
                          {CheckValueLocale(opt?.label, "", {}, intl)}
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={showRemoveBtn ? 5 : 6}>
              <FormControl
                className="nps-select-form-control"
                size="small"
                fullWidth
              >
                <InputLabel id={"nps-go-to-input-label"}>
                  {CheckValueLocale("go_to", "", {}, intl)}
                </InputLabel>
                <Select
                  id="nps-go-to-input"
                  label={CheckValueLocale("go_to", "", {}, intl)}
                  labelId="nps-go-to-input-label"
                  variant="outlined"
                  MenuProps={menuProps}
                  value={item?.next_section}
                  onChange={(e) => handleChangeGoTo(e.target.value, index)}
                >
                  {[...(sectionsList?.filter((item) => !!item) || [])]?.map(
                    (opt) => (
                      <MenuItem
                        key={opt?.value}
                        className={`nps-select-item ${opt?.value}`}
                        value={opt?.value}
                      >
                        <Box component="span" className="text-box">
                          {opt?.label}
                        </Box>
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
            {showRemoveBtn ? (
              <Grid item xs={1}>
                <LucButton
                  id="nps-proceed-on-answer-remove-condition-btn"
                  type="secondary"
                  variant="flat"
                  minWidth={40}
                  onClick={() => handleRemoveCondition(index)}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </LucButton>
              </Grid>
            ) : null}
          </Grid>
        ))}
      </Box>

      <LucButton
        id="nps-proceed-on-answer-add-condition-btn"
        variant="flat"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        disabled={
          tempProceedValues?.length > 1 ||
          (tempProceedValues?.length == 1 &&
            Object?.values(tempProceedValues[0])?.some((item) => !item))
        }
        onClick={() => {
          setTempProceedValues([
            ...tempProceedValues,
            { value: null, next_section: null },
          ]);
        }}
      >
        {CheckValueLocale("add_condition", "", {}, intl)}
      </LucButton>
      <Divider />
      <Typography variant="h6" className="sec-title">
        {CheckValueLocale(
          remainingValues?.length !== 3 ? "remaining_values" : "all_values",
          "",
          {},
          intl,
        )}
        {remainingValues?.length !== 3 ? (
          <Box component="span">
            {" ("}
            {remainingValues?.map((item, index) => (
              <Box
                component="span"
                className={`nps-remaining-value ${item}`}
                key={index}
              >
                {CheckValueLocale(item, "", {}, intl)}
                {remainingValues?.length == 2 && index == 0 ? ", " : null}
              </Box>
            ))}
            {")"}
          </Box>
        ) : null}
      </Typography>
      <Box className="nps-proceed-inputs-container">
        <FormControl className="nps-select-form-control" size="small" fullWidth>
          <InputLabel id={"nps-go-to-input-label"}>
            {CheckValueLocale("go_to", "", {}, intl)}
          </InputLabel>
          <Select
            id="nps-go-to-input"
            label={CheckValueLocale("go_to", "", {}, intl)}
            labelId="nps-go-to-input-label"
            variant="outlined"
            MenuProps={menuProps}
            value={tempRemainingValueGoTo}
            onChange={(e) => setTempRemainingValueGoTo(e.target.value)}
          >
            {[...(sectionsList?.filter((item) => !!item) || [])]?.map((opt) => (
              <MenuItem
                key={opt?.value}
                className={`nps-select-item ${opt?.value}`}
                value={opt?.value}
              >
                <Box component="span" className="text-box">
                  {opt?.label}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default NpsProceedModalBody;
