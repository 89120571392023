import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Pagination } from "@mui/material";
import CircularLoading from "components/circularProgress";
import {
  getDatasourceId,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks";
import { useSortableTable } from "hooks/useSortableTable";
import CompanySocialAccounts, {
  getCompanySocialAccountsTableHeaders,
} from "./companySocialAccounts";
import { genesysLastStepAuth } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import CompaniesController from "services/controllers/companiesController";
import { useHistory } from "react-router-dom";

const CompanyAccountsTab = () => {
  const intl = useIntl();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [companyAccounts, setCompanyAccounts] = useState([]);
  const [timeZone, setTimeZone] = useState(0);
  const [genesysDataSourceId, setGenesysDataSourceId] = useState("");
  const [tiktokDataSourceId, setTiktokDataSourceId] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [genesysCodeLoading, setGenesysCodeLoading] = useState(false);
  const [genesysStatus, setGenesysStatus] = useState("");
  const [failSnackBarData, setFailSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarAllSource, setOpenSnackBarAllSource] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isNeedsAttentionData, setIsNeedsAttentionData] = useState(false);

  let companyTabPremissions =
    handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH") &&
    (retrieveActiveProductsArray()?.includes("CXM") ||
      (retrieveActiveProductsArray()?.includes("SM") &&
        retrieveActiveProductsArray()?.includes("ENGAGEMENTS")));

  useEffect(() => {
    if (companyTabPremissions) getDataSources(setDataSources);
    setTimeZone(+localStorage?.getItem("companyInfo"));
    if (window?.location?.search?.includes("?error")) {
      setFailSnackBarData({
        message: "genesys_error_request_message",
        severity: "error",
        title: "genesys_error_request_title",
      });
      setOpenSnackBarAllSource(true);
      window?.history?.pushState({}, "", window?.location?.pathname);
    }
  }, []);

  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPageNum(1);
  };

  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getCompanySocialAccountsTableHeaders(intl),
    onSortChangeCallback,
    defaultSelectedColumn: "configuration_date",
    defaultDirection: "desc",
  });

  const getCompanyAccounts = (pagination, dataSource, createdBy) => {
    setIsLoading(true);
    setIsNeedsAttentionData(false);
    CompaniesController.getCompanyAuthorizations(
      sortColumn,
      sortDirection,
      pagination ? pagination : pageNum,
      searchText,
      dataSource,
      createdBy,
    ).then((result) => {
      if (!result.errorMsg) {
        setCompanyAccounts(result?.data?.company_social_accounts);
        setPaginationData(result?.data?.pagination);
        // Set flag for empty response in case of Needs Attentin redirection
        if (
          history?.location?.needsAttentionData &&
          createdBy &&
          !result?.data?.company_social_accounts?.length
        ) {
          setIsNeedsAttentionData(true);
        }
      } else {
        setCompanyAccounts([]);
        setPaginationData({});
        setOpenSnackBarAllSource(true);
        setFailSnackBarData({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
        });
      }
      setIsLoading(false);
      setIsSortableLoading(false);
    });
  };

  useEffect(() => {
    if (history?.location?.needsAttentionData?.isCompanyAccounts) {
      if (companyTabPremissions) {
        setSearchInput(
          history?.location?.needsAttentionData?.search_account_name,
        );
        getCompanyAccounts(
          1,
          history?.location?.needsAttentionData?.data_source,
          history?.location?.needsAttentionData?.created_by_id,
        );
      }
    } else {
      getCompanyAccounts();
    }
  }, [pageNum, sortColumn, sortDirection, history?.location]);

  useEffect(() => {
    setGenesysDataSourceId(getDatasourceId(dataSources, "GENESYS")?.id);
    setTiktokDataSourceId(getDatasourceId(dataSources, "TIKTOK")?.id);
  }, [dataSources]);

  //genesys auth
  let url = window.location?.search;
  let code = url?.slice(url?.indexOf("?code=") + 6);

  useEffect(() => {
    if (
      code !== undefined &&
      code !== "" &&
      genesysDataSourceId &&
      url?.includes("code") &&
      !url?.includes("error")
    ) {
      genesysLastStepAuth(
        genesysDataSourceId,
        code,
        setGenesysCodeLoading,
        setGenesysStatus,
        setFailSnackBarData,
        setOpenSnackBarAllSource,
        getCompanyAccounts,
      );
    }
  }, [code, genesysDataSourceId]);

  const handleChangePagination = (e, value) => setPageNum(value);

  return (
    <Box id="company-accounts-tab">
      {isLoading === true ||
      (genesysCodeLoading &&
        url?.includes("code") &&
        !url?.includes("error")) ? (
        <CircularLoading />
      ) : companyTabPremissions ? (
        <Box>
          <CompanySocialAccounts
            companySocialAccounts={companyAccounts}
            setCompanyAccounts={setCompanyAccounts}
            time_Zone={timeZone}
            setOpenSnackBarAllSource={setOpenSnackBarAllSource}
            openSnackBarAllSource={openSnackBarAllSource}
            failSnackBarData={failSnackBarData}
            setFailSnackBarData={setFailSnackBarData}
            genesysDataSourceId={genesysDataSourceId}
            tiktokDataSourceId={tiktokDataSourceId}
            tableHead={tableHead}
            isSortableLoading={isSortableLoading}
            searchText={searchText}
            setSearchText={setSearchText}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            getCompanyAccounts={getCompanyAccounts}
            page={pageNum || 1}
            setPage={setPageNum}
            isNeedsAttentionData={isNeedsAttentionData}
          />
          {companyAccounts?.length && paginationData?.pages > 1 ? (
            <Pagination
              className="pagination-monitor-list"
              variant="outlined"
              count={paginationData?.pages}
              page={pageNum || 1}
              onChange={handleChangePagination}
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default CompanyAccountsTab;
