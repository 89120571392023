//////////////////// keyword //////////////////////////////////////////
import twKeywordEngagementsVolumeOvertime from "images/live-dashboard-images/twitter/keyword/engagements/volume_overtime.png";
import twKeywordEngagementsCommentsInteractions from "images/live-dashboard-images/twitter/keyword/engagements/comments_interactions.png";
import twKeywordEngagementsSentimentAnalysis from "images/live-dashboard-images/twitter/keyword/engagements/sentiment_analysis.png";
import twKeywordEngagementsAssociatedTopics from "images/live-dashboard-images/twitter/keyword/engagements/associated_topics.png";
import twKeywordEngagementsReachFunnel from "images/live-dashboard-images/twitter/keyword/engagements/reach_funnel.png";
import twKeywordEngagementsTopPostsComments from "images/live-dashboard-images/twitter/keyword/engagements/top_posts_comments.png";
import twKeywordEngagementsTopLanguages from "images/live-dashboard-images/twitter/keyword/engagements/top_languages.png";
import twKeywordEngagementsDialectsAndSubDialects from "images/live-dashboard-images/twitter/keyword/engagements/dialects_and_sub_dialects.png";
import twKeywordEngagementsTopCountries from "images/live-dashboard-images/twitter/keyword/engagements/top_countries.png";
import twKeywordEngagementsTopKeywords from "images/live-dashboard-images/twitter/keyword/engagements/top_keywords.png";
import twKeywordEngagementsTopCities from "images/live-dashboard-images/twitter/keyword/engagements/top_cities.png";
import twKeywordEngagementsGenderDistribution from "images/live-dashboard-images/twitter/keyword/engagements/gender_distribution.png";
import twKeywordEngagementsTopEngagers from "images/live-dashboard-images/twitter/keyword/engagements/top_engagers.png";
import twKeywordEngagementsAccountTypes from "images/live-dashboard-images/twitter/keyword/engagements/account_types.png";
import twKeywordEngagementsTopVerifiedEngagers from "images/live-dashboard-images/twitter/keyword/engagements/top_verified_engagers.png";
import twKeywordEngagementsTopVideos from "images/live-dashboard-images/twitter/keyword/engagements/top_videos.png";
import twKeywordEngagementsTopHashtages from "images/live-dashboard-images/twitter/keyword/engagements/top_hashtags.png";
import twKeywordEngagementsContentStyle from "images/live-dashboard-images/twitter/keyword/engagements/content_style.png";
import twKeywordEngagementsTopImages from "images/live-dashboard-images/twitter/keyword/engagements/top_images.png";
import twKeywordEngagementsTopInfluencers from "images/live-dashboard-images/twitter/keyword/engagements/top_influencers.png";
import twKeywordEngagementsTopSources from "images/live-dashboard-images/twitter/keyword/engagements/top_sources.png";
import twKeywordPoststopPostsComments from "images/live-dashboard-images/twitter/keyword/posts/top_posts_comments.png";
import twKeywordAuthorsTopAuthors from "images/live-dashboard-images/twitter/keyword/authors/top_authors.png";

//////////////////// AccountAnalysis -- Account //////////////////////////////////////////
import twAccountAnalysisAccountFollowersGrowth from "images/live-dashboard-images/twitter/accountAnalysis/account/followers_growth.png";
import twAccountAnalysisAccountVolumeOvertime from "images/live-dashboard-images/twitter/accountAnalysis/account/volume_overtime.png";
import twAccountAnalysisAccountTopImages from "images/live-dashboard-images/twitter/accountAnalysis/account/top_images.png";
import twAccountAnalysisAccountPostsInteractions from "images/live-dashboard-images/twitter/accountAnalysis/account/posts_interactions.png";
import twAccountAnalysisAccountTopVideos from "images/live-dashboard-images/twitter/accountAnalysis/account/top_videos.png";
import twAccountAnalysisAccountContentType from "images/live-dashboard-images/twitter/accountAnalysis/account/content_type.png";
import twAccountAnalysisAccountTopPostsComments from "images/live-dashboard-images/twitter/accountAnalysis/account/top_posts_comments.png";
import twAccountAnalysisAccountReachFunnel from "images/live-dashboard-images/twitter/accountAnalysis/account/reach_funnel.png";
import twAccountAnalysisAccountAccountAuthorsActivity from "images/live-dashboard-images/twitter/accountAnalysis/account/account_authors_activity.png";
//////////////////// AccountAnalysis -- engagements //////////////////////////////////////////
import twAccountAnalysisEngagementsTopKeywords from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_keywords.png";
import twAccountAnalysisEngagementsAssociatedTopics from "images/live-dashboard-images/twitter/accountAnalysis/engagements/associated_topics.png";
import twAccountAnalysisEngagementsVolumeOvertime from "images/live-dashboard-images/twitter/accountAnalysis/engagements/volume_overtime.png";
import twAccountAnalysisEngagementsTopHashtags from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_hashtags.png";
import twAccountAnalysisEngagementsCommentsInteractions from "images/live-dashboard-images/twitter/accountAnalysis/engagements/comments_interactions.png";
import twAccountAnalysisEngagementsSentimentAnalysis from "images/live-dashboard-images/twitter/accountAnalysis/engagements/sentiment_analysis.png";
import twAccountAnalysisEngagementsContentStyle from "images/live-dashboard-images/twitter/accountAnalysis/engagements/content_style.png";
import twAccountAnalysisEngagementsTopImages from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_images.png";
import twAccountAnalysisEngagementsTopVideos from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_videos.png";
import twAccountAnalysisEngagementsTopLanguages from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_languages.png";
import twAccountAnalysisEngagementsTopCountries from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_countries.png";
import twAccountAnalysisEngagementsGenderDistribution from "images/live-dashboard-images/twitter/accountAnalysis/engagements/gender_distribution.png";
import twAccountAnalysisEngagementsDialectsAndSubDialects from "images/live-dashboard-images/twitter/accountAnalysis/engagements/dialects_and_sub_dialects.png";
import twAccountAnalysisEngagementsAccountTypes from "images/live-dashboard-images/twitter/accountAnalysis/engagements/account_types.png";
import twAccountAnalysisEngagementsTopCities from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_cities.png";
import twAccountAnalysisEngagementsTopEngagers from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_engagers.png";
import twAccountAnalysisEngagementsTopSources from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_sources.png";
import twAccountAnalysisEngagementsTopVerifiedEngagers from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_verified_engagers.png";
import twAccountAnalysisEngagementsTopInfluencers from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_influencers.png";
import twAccountAnalysisEngagementsThemesAndSubThemes from "images/live-dashboard-images/twitter/accountAnalysis/engagements/themes_and_sub_themes.png";
import twAccountAnalysisEngagementsTopPostsComments from "images/live-dashboard-images/twitter/accountAnalysis/engagements/top_posts_comments.png";
//////////////////// AccountAnalysis -- customerCare //////////////////////////////////////////
import twAccountAnalysisCustomerCareCustomerCarePerformance from "images/live-dashboard-images/twitter/accountAnalysis/customerCare/customer_care_performance.png";
import twAccountAnalysisCustomerCareLatestUnansweredQuestions from "images/live-dashboard-images/twitter/accountAnalysis/customerCare/latest_unanswered_questions.png";
//////////////////// AccountAnalysis -- commentsMentions , questions //////////////////////////////////////////
import twAccountAnalysisCommentsMentionsTopPostsComments from "images/live-dashboard-images/twitter/accountAnalysis/commentsMentions/top_posts_comments.png";
import twAccountAnalysisQuestionsLatestUnansweredQuestions from "images/live-dashboard-images/twitter/accountAnalysis/questions/latest_unanswered_questions.png";

const twKeywordEngagements = {
  volume_overtime: twKeywordEngagementsVolumeOvertime,
  comments_interactions: twKeywordEngagementsCommentsInteractions,
  sentiment_analysis: twKeywordEngagementsSentimentAnalysis,
  associated_topics: twKeywordEngagementsAssociatedTopics,
  reach_funnel: twKeywordEngagementsReachFunnel,
  top_posts_comments: twKeywordEngagementsTopPostsComments,
  top_languages: twKeywordEngagementsTopLanguages,
  dialects_and_sub_dialects: twKeywordEngagementsDialectsAndSubDialects,
  top_countries: twKeywordEngagementsTopCountries,
  top_keywords: twKeywordEngagementsTopKeywords,
  top_cities: twKeywordEngagementsTopCities,
  gender_distribution: twKeywordEngagementsGenderDistribution,
  top_engagers: twKeywordEngagementsTopEngagers,
  account_types: twKeywordEngagementsAccountTypes,
  top_verified_engagers: twKeywordEngagementsTopVerifiedEngagers,
  top_videos: twKeywordEngagementsTopVideos,
  top_hashtags: twKeywordEngagementsTopHashtages,
  content_style: twKeywordEngagementsContentStyle,
  top_images: twKeywordEngagementsTopImages,
  top_influencers: twKeywordEngagementsTopInfluencers,
  top_sources: twKeywordEngagementsTopSources,
};

const twKeyword = {
  top_posts_comments: twKeywordPoststopPostsComments,
  top_authors: twKeywordAuthorsTopAuthors,
  ...twKeywordEngagements,
};

const twAccountAnalysisAccount = {
  followers_growth: twAccountAnalysisAccountFollowersGrowth,
  volume_overtime: twAccountAnalysisAccountVolumeOvertime,
  top_images: twAccountAnalysisAccountTopImages,
  posts_interactions: twAccountAnalysisAccountPostsInteractions,
  top_videos: twAccountAnalysisAccountTopVideos,
  content_type: twAccountAnalysisAccountContentType,
  top_posts_comments: twAccountAnalysisAccountTopPostsComments,
  reach_funnel: twAccountAnalysisAccountReachFunnel,
  account_author_activity: twAccountAnalysisAccountAccountAuthorsActivity,
};

const twAccountAnalysisEngagements = {
  top_keywords: twAccountAnalysisEngagementsTopKeywords,
  associated_topics: twAccountAnalysisEngagementsAssociatedTopics,
  volume_overtime: twAccountAnalysisEngagementsVolumeOvertime,
  top_hashtags: twAccountAnalysisEngagementsTopHashtags,
  comments_interactions: twAccountAnalysisEngagementsCommentsInteractions,
  sentiment_analysis: twAccountAnalysisEngagementsSentimentAnalysis,
  content_style: twAccountAnalysisEngagementsContentStyle,
  top_images: twAccountAnalysisEngagementsTopImages,
  top_videos: twAccountAnalysisEngagementsTopVideos,
  top_languages: twAccountAnalysisEngagementsTopLanguages,
  top_countries: twAccountAnalysisEngagementsTopCountries,
  gender_distribution: twAccountAnalysisEngagementsGenderDistribution,
  dialects_and_sub_dialects: twAccountAnalysisEngagementsDialectsAndSubDialects,
  account_types: twAccountAnalysisEngagementsAccountTypes,
  top_cities: twAccountAnalysisEngagementsTopCities,
  top_engagers: twAccountAnalysisEngagementsTopEngagers,
  top_sources: twAccountAnalysisEngagementsTopSources,
  top_verified_engagers: twAccountAnalysisEngagementsTopVerifiedEngagers,
  top_influencers: twAccountAnalysisEngagementsTopInfluencers,
  themes_and_sub_themes: twAccountAnalysisEngagementsThemesAndSubThemes,
  top_posts_comments: twAccountAnalysisEngagementsTopPostsComments,
};

const twAccountAnalysisCustomerCare = {
  customer_care_performance:
    twAccountAnalysisCustomerCareCustomerCarePerformance,
  latest_unanswered_questions:
    twAccountAnalysisCustomerCareLatestUnansweredQuestions,
};

const twAccountAnalysis = {
  ...twAccountAnalysisAccount,
  ...twAccountAnalysisEngagements,
  ...twAccountAnalysisCustomerCare,
  top_posts_comments: twAccountAnalysisCommentsMentionsTopPostsComments,
  latest_unanswered_questions:
    twAccountAnalysisQuestionsLatestUnansweredQuestions,
};
const twitterImagesWidget = {
  ...twKeyword,
  ...twAccountAnalysis,
};

export default twitterImagesWidget;
