import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { Box, Skeleton, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import TitleBox from "../header/titleBox";
import "./getStarted.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import storyBehind from "images/home-page/get-started/story-behind.svg";
import trackMentions from "images/home-page/get-started/track-mentions.svg";
import insightsDelivered from "images/home-page/get-started/insights-delivered.svg";
import analyzeSideBySide from "images/home-page/get-started/analyze-side.svg";
import cutToTheChase from "images/home-page/get-started/cut-the-chase.svg";
import storyBehindModalImg from "images/home-page/get-started/modal-images/story-behind.svg";
import trackMentionsModalImg from "images/home-page/get-started/modal-images/track-mentions.svg";
import insightsDeliveredModalImg from "images/home-page/get-started/modal-images/insights-delivered.svg";
import analyzeSideBySideModalImg from "images/home-page/get-started/modal-images/analyze-side.svg";
import cutToTheChaseModalImg from "images/home-page/get-started/modal-images/cut-the-chase.svg";
import GetStartedModal from "./getStartedModal";
SwiperCore.use([Navigation]);

const GetStarted = (props) => {
  const { loading, loadingDelay } = props;
  const intl = useIntl();

  const [modalOpened, setModalOpened] = useState(false);
  const [itemIndex, setItemindex] = useState(null);

  const getStartedData = [
    {
      img: storyBehind,
      modalImg: storyBehindModalImg,
      title: "story_behind_the_post",
      description: "story_behind_the_post_description",
      desc_points: [
        "story_behind_the_post_point_1",
        "story_behind_the_post_point_2",
        "story_behind_the_post_point_3",
        "story_behind_the_post_point_4",
      ],
      note: "story_behind_the_post_note",
      learn_more_url:
        "https://knowledge.lucidya.com/تحليل-التغريدة-الواحدة-وطريقة-تصديرها-كتقرير/",
    },
    {
      img: trackMentions,
      modalImg: trackMentionsModalImg,
      title: "track_mentions_instantly",
      description: "track_mentions_instantly_description",
      desc_points: [
        "track_mentions_instantly_point_1",
        "track_mentions_instantly_point_2",
        "track_mentions_instantly_point_3",
        "track_mentions_instantly_point_4",
        "track_mentions_instantly_point_5",
        "track_mentions_instantly_point_6",
      ],
      link_1: { title: "alerts", path: "/alerts" },
      learn_more_url: "https://knowledge.lucidya.com/كيفية-إنشاء-التنبيهات/",
    },
    {
      img: insightsDelivered,
      modalImg: insightsDeliveredModalImg,
      title: "insights_delivered",
      description: "insights_delivered_description",
      desc_points: [
        "insights_delivered_point_1",
        "insights_delivered_point_2",
        "insights_delivered_point_3",
        "insights_delivered_point_4",
        "insights_delivered_point_5",
        "insights_delivered_point_6",
      ],
      link_1: { title: "reports", path: "/reports/list" },
      learn_more_url: "https://knowledge.lucidya.com/كيفية-انشاء-تقرير-مجدول/",
    },
    {
      img: analyzeSideBySide,
      modalImg: analyzeSideBySideModalImg,
      title: "analyze_side_by_side",
      description: "analyze_side_by_side_description",
      desc_points: [
        "analyze_side_by_side_point_1",
        "analyze_side_by_side_point_2",
        "analyze_side_by_side_point_3",
        "analyze_side_by_side_point_4",
        "analyze_side_by_side_point_5",
      ],
      link_1: { title: "benchmark", path: "/social/benchmark" },
      learn_more_url: "https://knowledge.lucidya.com/المقارنات/",
    },
    {
      img: cutToTheChase,
      modalImg: cutToTheChaseModalImg,
      title: "cut_to_the_chase",
      description: "cut_to_the_chase_description",
      desc_points: [
        "cut_to_the_chase_point_1",
        "cut_to_the_chase_point_2",
        "cut_to_the_chase_point_3",
      ],
      learn_more_url: "https://knowledge.lucidya.com/كيفية-انشاء-فلتر-وتطبيقه/",
    },
  ];

  const handleSkeleton = () => {
    return getStartedData?.map((el) => (
      <SwiperSlide>
        <Box className="skeleton-parant">
          <Skeleton
            className="skeleton-img"
            variant="rectangular"
            width={"100%"}
            height={118}
          />
          <Box className="skeleton-img-box">
            <Box className="skeleton-head">
              <Skeleton width="100%" height="13px"></Skeleton>
              <Skeleton width="100%" height="13px"></Skeleton>
              <Skeleton width="100%" height="13px"></Skeleton>
              <Skeleton width="100%" height="13px"></Skeleton>
              <Skeleton width="60%" height="13px"></Skeleton>
            </Box>
            <Skeleton width="100px" height="13px"></Skeleton>
          </Box>
        </Box>
      </SwiperSlide>
    ));
  };

  const handleOpenModal = (index) => {
    setItemindex(index);
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
    setItemindex(null);
  };

  return (
    <Box className="section-slide get-stared">
      <TitleBox
        title={"home_page_get_started"}
        tooltipText={"features_and_tools"}
      />
      <Box className="slider-container">
        <Swiper
          navigation
          observer={true}
          spaceBetween={15}
          slidesPerView={3.6}
          modules={[Navigation]}
          key={intl.locale}
        >
          {loading || loadingDelay
            ? handleSkeleton()
            : getStartedData?.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    onClick={() => handleOpenModal(index)}
                  >
                    <Box className="item-slide-get-started">
                      <Box className="img-box">
                        <img
                          className="get-started-img"
                          src={item?.img}
                          alt="get-started"
                        />
                      </Box>
                      <Box className="get-started-content">
                        <Typography className="get-started-type">
                          {CheckValueLocale(item?.title, "", {}, intl)}
                        </Typography>
                        <Typography className="get-started-message">
                          {CheckValueLocale(item?.description, "", {}, intl)}
                        </Typography>
                        <LucButton
                          className="get-started-btn"
                          variant="flat"
                          onClick={() => handleOpenModal(index)}
                        >
                          {CheckValueLocale("explore", "", {}, intl)}
                        </LucButton>
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </Box>
      {modalOpened ? (
        <GetStartedModal
          modalOpened={open}
          item={getStartedData[itemIndex]}
          handleCloseModal={handleCloseModal}
        />
      ) : null}
    </Box>
  );
};

export default GetStarted;
