import { useMutation, useQueryClient } from "@tanstack/react-query";
import MonitorsController from "services/controllers/monitorsController";

export const useDeleteChannel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ channelId }) => MonitorsController.deleteMonitor(channelId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["monitors"],
      });
    },
  });
};
