import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";
import OptionsDropdown from "./optionsDropdown";

export const renderThemesTableBody = ({
  data,
  setSnackBarData,
  setOpenDialog,
  setThemeDialogStats,
}) => {
  const intl = useIntl();

  return data?.map((theme, index) => {
    return {
      id: index,
      data: [
        handlelUserRoles("GENERAL", "REORDER_SUBTHEME") && {
          body: (
            <Box component="span" className="table-cell drag-icon">
              <DragIndicatorIcon />
            </Box>
          ),
          draggable: true,
        },
        {
          body: (
            <Typography
              className="table-cell color-box"
              sx={{ backgroundColor: theme?.sub_theme_color }}
            ></Typography>
          ),
        },
        {
          body: (
            <Typography className="table-cell">
              {theme?.sub_theme_en_name}
            </Typography>
          ),
        },
        {
          body: (
            <Typography className="table-cell">
              {theme?.sub_theme_ar_name}
            </Typography>
          ),
        },
        {
          body: (
            <TruncatedTooltip
              className="table-cell"
              title={theme?.sub_theme_description}
              maxChars={40}
              placement="bottom"
            />
          ),
        },
        {
          body: (
            <Typography className="table-cell">
              {!theme?.lucidya_user_id
                ? CheckValueLocale("predefined", "", {}, intl)
                : theme?.lucidya_user_id}
            </Typography>
          ),
        },
        {
          body: (
            <OptionsDropdown
              theme={theme}
              setOpenDialog={setOpenDialog}
              setThemeDialogStats={setThemeDialogStats}
              setSnackBarData={setSnackBarData}
            />
          ),
        },
      ],
    };
  });
};
