import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./freeQuestion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { useIntl } from "react-intl";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";

const FreeQuestion = (props) => {
  const { question, questionNameLocale, handleCreateFreeQuestions } = props;
  const [qsValue, setQsValue] = useState("");
  const intl = useIntl();
  const isLuciBusDet = window.localStorage.getItem("isLuciBusDet");
  const handleQusValue = (e) => {
    const val = e.target.value;
    return val.length <= 200 ? setQsValue(val) : setQsValue(qsValue);
  };
  return (
    <Box className="free-ques">
      <TextField
        className="ques-input"
        multiline
        value={qsValue}
        onChange={handleQusValue}
        placeholder={questionNameLocale(question)}
        sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <Button
                onClick={() => handleCreateFreeQuestions(question, qsValue)}
                id="send-luci-request"
                disabled={qsValue.trim().length == 0}
              >
                <FontAwesomeIcon
                  icon={faTelegramPlane}
                  flip={intl.locale == "ar" && "horizontal"}
                />
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <Box className="ques-progress">
        <Typography>{qsValue.length} / 200</Typography>
      </Box>
    </Box>
  );
};

export default FreeQuestion;
