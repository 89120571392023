import { FormControl, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const Scales = (props) => {
  const intl = useIntl();
  const [scaleBy, setScaleBy] = useState("linear");
  const scaleByData = ["linear", "logarithmic"];

  let { scaleWidgetId } = props;

  const showGroupData = (scale) => {
    setScaleBy(scale);
    props.getChartScales(scale);
  };

  return (
    <FormControl
      className="select-widget-dropdown select-scale-dropdown"
      id={scaleWidgetId ?? "select-scale-dropdown"}
    >
      <TextField
        select
        label={CheckValueLocale("scales", "", {}, intl)}
        value={scaleBy}
        onChange={(e) => showGroupData(e?.target?.value)}
        className="dropdown-field"
      >
        {scaleByData.map((option) => (
          <MenuItem
            key={option}
            value={option}
            id={`${scaleWidgetId ?? "scaleWidgetId"}-${option ?? "option"}-item`}
          >
            {CheckValueLocale(option, "", {}, intl)}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
export default Scales;
