import { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import "../../../createMonitor.scss";
import { getCompanyApps } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import SocialMediaAccountButton from "../../../Components/SocialMediaAuthButton/socialMediaAuthButton";
import InputFieldName from "../../../Components/InputFieldName/inputFieldName";

const InstagramPublicAccount = (props) => {
  const {
    monitorData,
    monitorErrors,
    setMonitorErrors,
    setMonitorData,
    handleCreateMonitorBtn,
    igSourceId,
    instagramAccounts,
    isLoading,
  } = props;
  const [companyApps, setCompanyApps] = useState({});

  useEffect(() => {
    getCompanyApps(setCompanyApps);
    props.setActiveDataSourceId(igSourceId);
  }, []);

  useEffect(() => {
    if (
      monitorErrors?.userName ||
      !monitorData?.userName ||
      monitorErrors?.monitorName ||
      monitorErrors?.monitorDescription
    ) {
      handleCreateMonitorBtn("");
    } else {
      handleCreateMonitorBtn("ig_public");
    }
  }, [
    monitorErrors?.userName,
    monitorErrors?.monitorName,
    monitorErrors?.monitorDescription,
  ]);
  return (
    <Box>
      {!instagramAccounts || isLoading ? (
        <CircularLoading />
      ) : isEmptyArray(instagramAccounts) ? (
        <>
          <SocialMediaAccountButton
            authHead={"you_need_to_connect_instagram_account"}
            companyApps={companyApps}
            authDataSourceName={"add_instagram_account_profile"}
            dataSourceName={"instagram"}
            isPublicAccount
            isCompanyAccount={false}
            redirectUrl={
              window.location.origin +
              "/settings/accounts_management/company_accounts"
            }
          />
        </>
      ) : (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="social-media-fields"
        >
          <InputFieldName
            autoFocues={false}
            name="username"
            nameInfoMsg="username_with_sign_ig"
            setMonitorErrors={setMonitorErrors}
            monitorErrors={monitorErrors}
            isPublicSpecialCharactersOrEmojis={
              isPublicSpecialCharactersOrEmojis
            }
            handleCreateMonitorBtn={handleCreateMonitorBtn}
            setMonitorData={setMonitorData}
            monitorData={monitorData}
            checkUserNameChar={true}
            isIgPublicAccount
            maxNameLength={30}
          />
        </Grid>
      )}
    </Box>
  );
};

export default InstagramPublicAccount;
