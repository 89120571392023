import Services from "./Services";

var CreateMonitor = {};

CreateMonitor.getCMData = (queryData) => {
  return Services.getData("api/v3/nb_sources", queryData);
};

CreateMonitor.getFacebookPages = (product_id, searchValue) => {
  return Services.getData(
    `api/v3/social_listening/facebook/filters/search_facebook_pages?product_id=${product_id}&q=${searchValue}`,
  );
};

export default CreateMonitor;
