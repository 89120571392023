import Services from "./Services";

let Luci = {};

Luci.getLuciStaticQuestions = (queryData) => {
  return Services.getData("api/v3/luci/questions/static_questions", queryData);
};
Luci.getRequests = (queryData) => {
  return Services.getData("api/v3/luci/questions", queryData);
};
Luci.createLuciQuestion = (queryData) => {
  return Services.postData("api/v3/luci/questions", queryData);
};
Luci.getLuciPolicy = () => {
  return Services.postData("api/v3/term_policies", {
    policy_type: "luci",
  });
};

Luci.showLuciBusinessDetails = (id) => {
  return Services.getData(`api/v3/luci/business_details/${id}`);
};

Luci.updateLuciBusinessDetails = (id, queryData) => {
  return Services.putData(`api/v3/luci/business_details/${id}`, queryData);
};

Luci.createLuciBusinessDetails = (queryData) => {
  return Services.postData(`api/v3/luci/business_details`, queryData);
};

Luci.LuciBusinessDetailsInformel = () => {
  return Services.getData(`api/v3/luci/business_details/informer`);
};
Luci.getLuciListAnswer = (id) => {
  return Services.getData(`api/v3/luci/questions/${id}`);
};

Luci.getLuciCurrentTokens = () => {
  return Services.getData("api/v3/luci/questions/current_tokens");
};

export default Luci;
