import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";

const header = (props) => {
  const intl = useIntl();
  const { companyReachedMaxUser } = props;

  return (
    <Box>
      <Box className="user-management-button">
        <Box>
          <h3 className="sub-header">
            {CheckValueLocale("user_managements", "", {}, intl)}
          </h3>
        </Box>
        {handlelUserRoles("GENERAL", "CREATE_USER") && (
          <Box>
            <LucButton
              startIcon={<Add />}
              disabled={companyReachedMaxUser}
              id="settings-user-managment-table-add-user-btn"
            >
              <Link to="/settings/user_managements/add">
                {CheckValueLocale("new_user", "", {}, intl)}
              </Link>
            </LucButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default header;
