import { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import {
  handleCopyBtn,
  handleKeywordsPopUp,
  checkValue,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import CopyIcon from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import {
  checkLabelTextEdit,
  checkKeywordPopup,
  handleInputFieldName,
  checkAllInputsField,
  handleInputChange,
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { handleTitleSearchKeywordChange } from "../../../../../../MonitorsShared/SharedFunctions/components/newBlogsSharedFunctions";

const KeywordsSection = (props) => {
  const intl = useIntl();
  const {
    keywordsInfo,
    keywords,
    indexes,
    titleSearchKeyword,
    handleDeleteWord,
    handleData,
    handleKeyDown,
    titleSearchErrorMsg,
    titleSearch,
    handleTitleskeyboardPress,
    handleDeleteTitleSearch,
    copyBtn,
    setCopyBtn,
    handleValidation,
    setKeywords,
    setSQuestion,
    setTitleSearch,
    setTitleSearchErrorMsg,
    setTitleSearchKeywordError,
    monitorErrors,
    setMonitorErrors,
  } = props;
  const [focused, setFocused] = useState(false);

  // Keep tracking for any error related to Facebook keyword
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      nb_keywords: keywords[indexes]?.newsBlogs[0]?.errorMsg,
    });
  }, [keywords[indexes]?.newsBlogs[0]?.errorMsg]);
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      nb_spam_keywords: keywords[indexes]?.newsBlogs[1]?.errorMsg,
    });
  }, [keywords[indexes]?.newsBlogs[1]?.errorMsg]);

  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      nb_title: titleSearchErrorMsg,
    });
  }, [titleSearchErrorMsg]);

  return (
    <>
      {keywordsInfo.map((keyword, index) => {
        const nbValues = Object.values(keywords[indexes].newsBlogs[index]);
        return (
          <>
            <Box className="section-box-data-keyword">
              <Tooltip
                title={CheckValueLocale(
                  "keywords_spam_keywords_tooltip",
                  "",
                  {},
                  intl,
                )}
                arrow
                placement="bottom"
              >
                <Typography className="main-titles underlined">
                  {CheckValueLocale(
                    handleInputFieldName(keyword),
                    "",
                    {},
                    intl,
                  )}
                </Typography>
              </Tooltip>

              <TextField
                label={CheckValueLocale(
                  checkLabelTextEdit(keyword),
                  "",
                  {},
                  intl,
                )}
                className={`
                 spam-text-field input-field-edit ${
                   keyword === "keywords"
                     ? "input-keyword-field"
                     : "input-field-spam"
                 }  ${checkDirectionLang(
                   keywords[indexes].newsBlogs[index].answer,
                   "news",
                 )} `}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                InputProps={{
                  endAdornment: focused && (
                    <InputAdornment onMouseDown={handleInputMouseDown}>
                      <Button
                        className="add-btn-with-plus add-btn-plus-setting"
                        onClick={(event) =>
                          handleKeyDown(event, keyword, index, "btn")
                        }
                        value={
                          Object.keys(
                            keywords[indexes]?.newsBlogs[index],
                          )[0] === keyword
                            ? keywords[indexes].newsBlogs[index].answer
                            : ""
                        }
                        id="monitor-options-nb-kw-add-exclude-keyword-btn"
                      >
                        {CheckValueLocale("add_plus", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                error={
                  (keywords[indexes].newsBlogs[index].errorMsg !== "" &&
                    titleSearchKeyword.length === 0 &&
                    keywords[indexes].newsBlogs[index].errorMsg ===
                      CheckValueLocale(
                        "keywords_is_required_to_monitor_data",
                        "",
                        {},
                        intl,
                      )) ||
                  (keywords[indexes].newsBlogs[index].errorMsg !== "" &&
                    Object.keys(keywords[indexes].newsBlogs[index])[0] ===
                      keyword &&
                    titleSearchKeyword.length === 0) ||
                  (keywords[indexes].newsBlogs[index].errorMsg !== "" &&
                    titleSearchKeyword.length === 0)
                }
                onChange={(e) => {
                  setSQuestion("newsBlogs");
                  handleInputChange(
                    e,
                    keyword,
                    "newsBlogs",
                    index,
                    indexes,
                    keywords,
                    null,
                    handleValidation,
                    setKeywords,
                  );
                }}
                value={
                  Object.keys(keywords[indexes]?.newsBlogs[index])[0] ===
                  keyword
                    ? keywords[indexes].newsBlogs[index].answer
                    : ""
                }
                onKeyDown={(event) => handleKeyDown(event, keyword, index)}
              />
            </Box>
            {/*Error Msg __________________________________________________________ */}
            {keywords[indexes]?.newsBlogs?.map((obj) => {
              if (
                Object.values(obj)[1] !== "" &&
                Object.keys(obj)[0] === keyword
              ) {
                return (
                  <Box className="error-msg-keyword">
                    <Typography className="main-titles"></Typography>
                    <Box
                      component="p"
                      className="spam-text-field err-msg-text keywords-error-msg"
                    >
                      {keywords[indexes].newsBlogs[index].errorMsg !==
                      CheckValueLocale(
                        "keywords_is_required_to_monitor_data",
                        "",
                        {},
                        intl,
                      )
                        ? CheckValueLocale(Object.values(obj)[1], "", {}, intl)
                        : null}
                    </Box>
                  </Box>
                );
              }
            })}
            {/* _______________________________________________________________________________________ */}
            {/* Error Msg is fired in case of keywords input filed is empty and save btn will not work  */}
            {keyword === "keywords" &&
              isEmptyArray(keywords[indexes]?.newsBlogs[0]?.keywords) && (
                <Box className="section-box-data">
                  <Typography className="main-titles"></Typography>
                  <Box
                    component="p"
                    className="spam-text-field err-msg-text keywords-error-msg"
                  >
                    {CheckValueLocale("this_field_required", "", {}, intl)}
                  </Box>
                </Box>
              )}
            {/* ______________________________________________________________________________________ */}

            <Box className="chip-keywords-wrapper">
              <Box></Box>
              <Box className="spam-keywords-area">
                {keywords[indexes]?.newsBlogs?.map((obj) => {
                  if (
                    Object.values(obj)[0].length > 0 &&
                    Object.keys(obj)[0] === keyword
                  ) {
                    return checkValue(Object.values(obj)[0]).map(
                      (labels, index) => (
                        <>
                          <KeywordTooltipChip
                            onDelete={(e) =>
                              handleDeleteWord(e, index, Object.keys(obj)[0])
                            }
                            dataSource={"news"}
                            indexes={indexes}
                            labels={labels}
                            keywordSetting
                          />
                        </>
                      ),
                    );
                  }
                })}

                {checkAllInputsField(keyword, keywordsInfo) && (
                  <KeywordPopupModal
                    keywords={handleKeywordsPopUp(
                      keyword,
                      keywords[3].newsBlogs[0]?.keywords,
                      keywords[3].newsBlogs[1]?.spam_keywords,
                    )}
                    handleDelete={handleDeleteWord}
                    dataSourceType={"newsBlogs"}
                    keywordType={checkKeywordPopup(keyword)}
                    dataSourceIndex={3} // dataSource index to handle delete keyword
                  />
                )}

                {nbValues[0].length !== 0 && (
                  <CopyIcon
                    checkCopyValue={nbValues[2]}
                    handleData={(e) =>
                      handleData(e, nbValues[0], indexes, index)
                    }
                  />
                )}
              </Box>
            </Box>

            {/* start of "Title keywords section" ___________________________________________*/}
            {keyword === "keywords" && (
              <>
                <Divider />
                <Box className="section-box-data-keyword">
                  <Tooltip
                    title={
                      <Box className="title-search-tooltip">
                        {intl.formatMessage(
                          {
                            id: "title_search_keyword_tooltip",
                          },
                          {
                            break: <br />,
                          },
                        )}
                      </Box>
                    }
                    arrow
                    placement="bottom"
                  >
                    <Typography className="main-titles underlined">
                      {CheckValueLocale("title_search_keyword", "", {}, intl)}
                    </Typography>
                  </Tooltip>
                  {/* _______________________________________________________________________ */}
                  <TextField
                    id="outlined-email-input"
                    className={`
                     spam-text-field input-field-edit input-field-spam  ${checkDirectionLang(
                       titleSearch,
                     )}`}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    InputProps={{
                      endAdornment: focused && (
                        <InputAdornment onMouseDown={handleInputMouseDown}>
                          <Button
                            className="add-btn-with-plus add-btn-plus-setting"
                            onClick={(event) =>
                              handleTitleskeyboardPress(event, "btn")
                            }
                            value={titleSearch}
                            id="monitor-options-nb-kw-title-search-btn"
                          >
                            {CheckValueLocale("add_plus", "", {}, intl)}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      titleSearchErrorMsg !== "" ||
                      (titleSearchKeyword.length === 0 &&
                        keywords[indexes].newsBlogs[index].errorMsg ===
                          CheckValueLocale(
                            "keywords_is_required_to_monitor_data",
                            "",
                            {},
                            intl,
                          ))
                    }
                    label={CheckValueLocale(
                      "scan_posts_have_titles",
                      "",
                      {},
                      intl,
                    )}
                    onKeyPress={handleTitleskeyboardPress}
                    onChange={(e) =>
                      handleTitleSearchKeywordChange(
                        e,
                        setTitleSearch,
                        titleSearchKeyword,
                        setTitleSearchErrorMsg,
                        setTitleSearchKeywordError,
                        intl,
                      )
                    }
                    value={titleSearch}
                  />
                </Box>
                {/*Error Msg __________________________________________________________ */}
                {titleSearchErrorMsg !== "" && (
                  <Box className="error-msg-keyword">
                    <Typography className="main-titles"></Typography>
                    <Box
                      component="p"
                      className="spam-text-field err-msg-text keywords-error-msg"
                    >
                      {CheckValueLocale(titleSearchErrorMsg, "", {}, intl)}
                    </Box>
                  </Box>
                )}
                {/* _____________________keyword chip _____________________________ */}
                <Box className="chip-keywords-wrapper">
                  <Box></Box>
                  <Box className="spam-keywords-area">
                    {checkValue(titleSearchKeyword)?.map((title, i) => {
                      return (
                        <KeywordTooltipChip
                          onDelete={() => handleDeleteTitleSearch(i)}
                          dataSource={"news"}
                          indexes={indexes}
                          index={i}
                          labels={title}
                          keywordSetting
                        />
                      );
                    })}
                    {(keyword !== "keywords" ||
                      keyword !== "spam_keywords") && (
                      <KeywordPopupModal
                        keywords={titleSearchKeyword}
                        handleDelete={handleDeleteTitleSearch}
                        dataSourceType={"newsBlogs"}
                        keywordType={"title_search_keyword"}
                        isTitleSearch
                      />
                    )}
                    {titleSearchKeyword.length !== 0 && (
                      <Tooltip title={copyBtn}>
                        <ContentCopyIcon
                          className="copy-icon cpy-icon"
                          onClick={() =>
                            handleCopyBtn(
                              setCopyBtn,
                              CheckValueLocale("copied", "", {}, intl),
                              titleSearchKeyword,
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </>
            )}
            <Divider />
            {/* end Title keywords section */}
          </>
        );
      })}
    </>
  );
};

export default KeywordsSection;
