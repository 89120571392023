import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import "./liveDashboardModel.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import liveDashboardImage from "images/live-dashboard.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import UserAccount from "services/controllers/userAccountController";
import Services from "services/api/Services.js";
import CompaniesController from "services/controllers/companiesController";
import SnackBar from "components/snackBar";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";

const LiveDashboardModel = ({ open, handleClose, handleCreate }) => {
  const intl = useIntl();

  const dashboardTextArr = [
    "interactive_interface",
    "comprehensive_data",
    "all_monitors_sources",
    "shareable_link",
  ];

  return (
    <Dialog open={open} onClose={handleClose} className="live-dashboard-dialog">
      <DialogContent className="live-dashboard-dialog-content">
        <Box
          component="span"
          onClick={handleClose}
          className="live-dashboard-close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </Box>
        <img
          src={liveDashboardImage}
          alt="live-dashboard"
          className="live-dashboard-img"
        />
        <Box className="live-dashboard-box">
          <Box component="h2" className="live-dashboard-heading">
            {CheckValueLocale("live_dashboard_heading", "", {}, intl)}
          </Box>
          <Box className="live-dashboard-description-box">
            <Box component="p" className="live-dashboard-description">
              {CheckValueLocale("live_dashboard_description", "", {}, intl)}
            </Box>
            <Box component="ul" className="live-dashboard-list">
              {dashboardTextArr.map((text) => (
                <Box component="li">
                  <FontAwesomeIcon icon={faCheck} />
                  {CheckValueLocale(text, "", {}, intl)}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className="live-dashboard-dialog-action">
        <LucButton variant="flat" onClick={handleClose} id="live-dashboard-btn">
          {CheckValueLocale("cancel_btn", "", {}, intl)}
        </LucButton>
        <LucButton onClick={handleCreate} id="live-dashboard-btn">
          {CheckValueLocale("create_live_dashboard", "", {}, intl)}
        </LucButton>
      </DialogActions>
    </Dialog>
  );
};
export default LiveDashboardModel;
