import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import moment from "moment";
import {
  Box,
  Typography,
  Button,
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  RadioGroup,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import ReportController from "services/controllers/reportController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PopupModal from "components/popupModal";
import BrandingInReports from "pages/reportsNew/components/branding/brandingComponent";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 160,
      width: 475,
    },
  },
};

const ReportTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "5px 8px",
    fontWeight: "500",
    "& span": {
      color: "rgba(97, 97, 97, 0.9) !important",
    },
  },
}));

const EditReportStepThree = ({
  setReportEmails,
  reportEmails,
  companyUsers,
  reportData,
  setReportLanguage,
  reportLanguage,
  fieldError,
  reportStateDateTime,
  handleDateValue,
  startDate,
  selectedFreq,
  setSelectedFreq,
  specDay,
  setSpecDay,
  freqId,
  setFreqId,
  isBrandingReport,
  setIsBrandingReport,
  companyhasBranding,
  lastUpdated,
}) => {
  const intl = useIntl();
  const [frequenciesList, setFrequenciesList] = useState([]);
  const [monthlyType, setMonthlyType] = useState("specific");
  const [specificSelect, setSpecificSelect] = useState(1);
  const [dayType, setDayType] = useState("first_day");
  const [selectedDay, setSelectedDay] = useState(0);
  const [isRequestError, setIsRequestError] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [step3FormFields, setStep3FormFields] = useState({
    reportStartDate: reportData?.attributes?.send_date,
  });
  const [showDate, setShowDate] = useState("");

  const weekDays = [
    { name: "Sunday", value: 0 },
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
  ];
  const monthDays = [];
  for (let i = 1; i <= 28; i++) {
    monthDays.push(i);
  }
  const getFrequenciesList = () => {
    ReportController.getReportFrequencies().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.data) setFrequenciesList(res.data);
    });
  };
  useEffect(() => {
    getFrequenciesList();
  }, []);
  const onChangeFrequeny = (e) => {
    setSelectedFreq(e.target.value);
    if (e.target.value === "daily") {
      setSpecDay("");
      frequenciesList?.map((freq) => {
        if (freq?.name === "daily") {
          freq?.report_recurrences?.map((rec) => {
            if (rec?.recurrence === 24) setFreqId(rec?.id);
          });
        }
      });
    } else if (e.target.value === "monthly") {
      setSpecDay(1);
      if (monthlyType === "specific") {
        frequenciesList?.map((freq) => {
          if (freq?.name === "monthly")
            freq?.report_recurrences?.map((rec) => {
              if (rec?.recurrence_type === "day") setFreqId(rec?.id);
            });
        });
      } else {
        frequenciesList?.map((freq) => {
          if (freq?.name === "monthly")
            freq?.report_recurrences?.map((rec) => {
              if (
                rec?.recurrence_type === dayType &&
                rec?.recurrence === selectedDay
              )
                setFreqId(rec?.id);
            });
        });
      }
    } else {
      frequenciesList?.map((freq) => {
        if (e.target.value === freq?.name)
          setFreqId(freq?.report_recurrences[0]?.id);
      });
      setSpecDay("");
    }
  };
  const onChangeMonthlyType = (e) => {
    setMonthlyType(e.target.value);
    if (e.target.value === "specific") {
      setSpecDay(1);
      frequenciesList?.map((freq) => {
        freq?.report_recurrences?.map((rec) => {
          if (rec?.recurrence_type === "day") setFreqId(rec?.id);
        });
      });
    } else {
      setSpecDay("");
      frequenciesList?.map((freq) => {
        freq?.report_recurrences?.map((rec) => {
          if (
            rec?.recurrence_type === dayType &&
            rec?.recurrence === selectedDay
          )
            setFreqId(rec?.id);
        });
      });
    }
  };

  useEffect(() => {
    frequenciesList?.map((freq) => {
      if (freq?.name === "monthly")
        freq?.report_recurrences?.map((rec) => {
          if (
            rec?.recurrence === selectedDay &&
            rec?.recurrence_type === dayType
          )
            setFreqId(rec?.id);
        });
    });
  }, [dayType, selectedDay]);

  const onChangeSpecificSelect = (e) => {
    setSpecificSelect(e.target.value);
    if (e.target.value === "custom") setSpecDay(specDay);
    else setSpecDay(e.target.value);
  };
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const dateBody = () => {
    return (
      <Box dir="ltr">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateTimePicker
            className="date-time"
            fullWidth
            renderInput={(params) => <TextField {...params} />}
            name="reportStartDate"
            value={startDate}
            onChange={handleDateValue}
          />
        </LocalizationProvider>
        <Box className="start-date-btns">
          <Box>
            <Button onClick={() => setOpenPopUp(false)}>CANCEL</Button>
          </Box>
          <Box>
            <Button onClick={handleCreateDate}>OK</Button>
          </Box>
        </Box>
      </Box>
    );
  };
  useEffect(() => {
    setStep3FormFields({
      ...step3FormFields,
      reportStartDate: reportData?.attributes?.send_date,
    });
  }, [reportData?.attributes?.send_date]);
  const handleCreateDate = () => {
    setStep3FormFields({
      ...step3FormFields,
      reportStartDate: startDate,
    });
    setShowDate(startDate);
    setOpenPopUp(false);
  };

  useEffect(() => {
    if (reportData) {
      setFreqId(reportData?.attributes?.report_recurrence?.id);
      setSelectedFreq(reportData?.attributes?.frequency?.toLowerCase());
      if (reportData?.attributes?.frequency?.toLowerCase() == "monthly") {
        setSpecDay(Number(reportData?.attributes?.send_date?.slice(8, 10)));
        if (
          reportData?.attributes?.report_recurrence?.recurrence_type == "day"
        ) {
          setMonthlyType("specific");
          if (specDay !== 1 && specDay !== 31) {
            setSpecificSelect("custom");
          } else {
            setSpecificSelect(specDay);
          }
        } else {
          setMonthlyType("every");
          setDayType(
            reportData?.attributes?.report_recurrence?.recurrence_type,
          );
          setSelectedDay(reportData?.attributes?.report_recurrence?.recurrence);
        }
      }
    }
  }, [reportData]);

  const handleChangeBranding = (e) => {
    setIsBrandingReport(JSON.parse(e.target.value));
  };

  return (
    <Grid container spacing={2.5}>
      <Grid item lg={6} xl={6}>
        <Box className="text-field">
          <InputLabel>
            {CheckValueLocale("report_report_emails", "", {}, intl)}
          </InputLabel>
          <Select
            fullWidth
            multiple
            name="reportEmails"
            value={reportEmails}
            onChange={(e) => setReportEmails([...e.target?.value])}
            input={<OutlinedInput />}
            renderValue={() => CheckValueLocale("select", "", {}, intl)}
            MenuProps={MenuProps}
            displayEmpty
            error={fieldError?.reportEmails ? true : false}
          >
            {companyUsers?.map((val) => (
              <MenuItem
                key={`companyUsers-${val?.id}`}
                value={val?.id}
                className="edit-report-multi-menuItem"
                id={`report-edit-report-company-users-${val?.id ?? "id"}`}
              >
                <Checkbox checked={reportEmails?.indexOf(val?.id) > -1} />
                <ListItemText primary={val?.email} />
              </MenuItem>
            ))}
          </Select>
          {fieldError?.reportEmails && (
            <Grid item xs={12}>
              <FormHelperText error>{fieldError?.reportEmails}</FormHelperText>
            </Grid>
          )}
        </Box>

        {reportEmails?.length > 0 && (
          <Box sx={{ mt: "8px" }}>
            {reportEmails?.map((val) => (
              <Button
                key={val}
                variant="outlined"
                className="selected-email-lable"
                endIcon={
                  <CancelIcon
                    onClick={() =>
                      setReportEmails((eval1) => [
                        ...eval1?.filter((eval2) => eval2 !== val),
                      ])
                    }
                  />
                }
                id="report-edit-report-selected-email-btn"
              >
                {companyUsers?.filter((item) => item?.id == val)[0]?.email}
              </Button>
            ))}
          </Box>
        )}
      </Grid>
      <Grid item lg={6} xl={6}>
        <Box className="text-field">
          <InputLabel>
            {CheckValueLocale("report_language", "", {}, intl)}
          </InputLabel>
          <Select
            name="reportLanguage"
            value={reportLanguage}
            fullWidth
            onChange={(e) => setReportLanguage(e?.target?.value)}
            displayEmpty
            error={fieldError?.reportLanguage ? true : false}
          >
            <MenuItem
              value={"ar-SA"}
              className="edit-report-menu-items"
              id={`report-edit-report-arabic`}
            >
              {"Arabic"}
            </MenuItem>
            <MenuItem
              value={"en-US"}
              className="edit-report-menu-items"
              id={`report-edit-report-english`}
            >
              {"English"}
            </MenuItem>
          </Select>
          {fieldError?.reportLanguage && (
            <Grid item xs={12}>
              <FormHelperText error>
                {fieldError?.reportLanguage}
              </FormHelperText>
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item lg={6} xl={6}>
        <Box className="text-field">
          <InputLabel>
            {CheckValueLocale("report_frequency", "", {}, intl)}
          </InputLabel>
          <Select
            fullWidth
            value={selectedFreq?.toLowerCase()}
            onChange={onChangeFrequeny}
          >
            <MenuItem value={""} disabled className="report-menu-items">
              {CheckValueLocale("select", "", {}, intl)}
            </MenuItem>
            {frequenciesList?.map((freq, index) => (
              <MenuItem
                key={index}
                value={freq?.name?.toLowerCase()}
                className="report-menu-items"
                id={`reports-frequency-list-${freq?.index ?? "id"}`}
              >
                {CheckValueLocale(`report_${freq?.name}`, "", {}, intl)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
      {selectedFreq?.toLowerCase() === "daily" ? (
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("daily_recurrence", "", {}, intl)}
            </InputLabel>
            <Select
              name="dailyRecurrence"
              value={freqId}
              onChange={(e) => setFreqId(e.target.value)}
              error={freqId === 0 ? true : false}
              displayEmpty
              fullWidth
            >
              {frequenciesList[0]?.report_recurrences
                ?.toReversed()
                ?.map((rec) => (
                  <MenuItem
                    key={rec?.id}
                    value={rec?.id}
                    className="report-menu-items"
                    id={`reports-daily-list-${rec?.id ?? "id"}`}
                  >
                    {`${CheckValueLocale(
                      "every",
                      "",
                      {},
                      intl,
                    )} ${CheckValueLocale(
                      `${rec?.recurrence}_hours`,
                      "",
                      {},
                      intl,
                    )} (${CheckValueLocale(
                      `${24 / rec?.recurrence}_reports_per_day`,
                      "",
                      {},
                      intl,
                    )})`}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Grid>
      ) : null}
      <Grid item lg={6} xl={6}>
        <Box className="text-field">
          <InputLabel>
            {CheckValueLocale("report_start_date", "", {}, intl)}
          </InputLabel>
          <Button id="step3-date-btn" onClick={() => setOpenPopUp(true)}>
            <Box>
              {!showDate
                ? step3FormFields?.reportStartDate
                  ? moment(step3FormFields?.reportStartDate)
                      .utcOffset(step3FormFields?.reportStartDate)
                      .format("DD/MM/YYYY hh:mmA")
                  : ""
                : moment(showDate?.$d).format("DD/MM/YYYY hh:mmA")}
            </Box>
            <Box>
              <FontAwesomeIcon icon={faCalendar} />
            </Box>
          </Button>

          {fieldError?.reportStartDate && (
            <FormHelperText error>{fieldError?.reportStartDate}</FormHelperText>
          )}
        </Box>
      </Grid>

      {selectedFreq === "monthly" ? (
        <Grid item lg={6} xl={6} sx={{ mt: 2 }}>
          <Typography variant="h6" className="create-report-body-title">
            {CheckValueLocale("sending_recurrence", "", {}, intl)}:
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={6} xl={6}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={monthlyType}
                  onChange={onChangeMonthlyType}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="specific"
                    control={<Radio />}
                    label={CheckValueLocale("on_spec_day", "", {}, intl)}
                    id="radio-button-on-spec-day"
                  />
                  <FormControlLabel
                    value="every"
                    control={<Radio />}
                    label={CheckValueLocale("every", "", {}, intl)}
                    id="radio-button-every"
                    sx={{ mt: 2 }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={6} xl={6}>
              <Grid container spacing={1}>
                <Grid
                  item
                  lg={specificSelect === "custom" ? 8 : 12}
                  xl={specificSelect === "custom" ? 8 : 12}
                >
                  <Box className="text-field">
                    <Select
                      name="specificSelect"
                      fullWidth
                      displayEmpty
                      disabled={monthlyType !== "specific" ? true : false}
                      value={specificSelect}
                      onChange={onChangeSpecificSelect}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem
                        value={1}
                        className="report-menu-items"
                        id="specific-select-first"
                      >
                        {CheckValueLocale("first_of_month", "", {}, intl)}
                      </MenuItem>
                      <MenuItem
                        value={31}
                        className="report-menu-items"
                        id="specific-select-last"
                      >
                        {CheckValueLocale("end_of_month", "", {}, intl)}
                      </MenuItem>
                      <MenuItem
                        value={"custom"}
                        className="report-menu-items"
                        id="specific-select-custom"
                      >
                        {CheckValueLocale("custom_day", "", {}, intl)}..
                      </MenuItem>
                    </Select>
                  </Box>
                </Grid>
                {specificSelect === "custom" ? (
                  <Grid item lg={4} xl={4}>
                    <Box className="text-field">
                      <Select
                        name="specificSelect"
                        fullWidth
                        displayEmpty
                        value={specDay}
                        onChange={(e) => setSpecDay(e.target.value)}
                        disabled={monthlyType !== "specific" ? true : false}
                        className="select-input"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem
                          value=""
                          className="report-menu-items"
                          id={`custom-day-select`}
                          disabled
                        >
                          {CheckValueLocale("select", "", {}, intl)}{" "}
                        </MenuItem>

                        {monthDays.map((i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={i}
                              className="report-menu-items"
                              id={`custom-day-${i}`}
                            >
                              {i}
                            </MenuItem>
                          );
                        })}
                        <MenuItem
                          value={29}
                          className="report-menu-items"
                          id="custom-day-29"
                        >
                          29 (
                          {CheckValueLocale(
                            "or_end_of_the_month",
                            "",
                            {},
                            intl,
                          )}
                          )
                        </MenuItem>
                        <MenuItem
                          value={30}
                          className="report-menu-items"
                          id="custom-day-30"
                        >
                          30 (
                          {CheckValueLocale(
                            "or_end_of_the_month",
                            "",
                            {},
                            intl,
                          )}
                          )
                        </MenuItem>
                        <MenuItem
                          value={31}
                          className="report-menu-items"
                          id="custom-day-31"
                        >
                          31 (
                          {CheckValueLocale(
                            "or_end_of_the_month",
                            "",
                            {},
                            intl,
                          )}
                          )
                        </MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                ) : null}

                <Grid item lg={12} xl={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item lg={6} xl={6}>
                      <Box className="text-field">
                        <Select
                          name="dayTypeSelect"
                          fullWidth
                          displayEmpty
                          disabled={monthlyType === "specific" ? true : false}
                          value={dayType}
                          onChange={(e) => setDayType(e.target.value)}
                        >
                          <MenuItem
                            value="first_day"
                            className="report-menu-items"
                            id="day-type-select-first"
                          >
                            {CheckValueLocale("first", "", {}, intl)}
                          </MenuItem>
                          <MenuItem
                            value="last_day"
                            className="report-menu-items"
                            id="day-type-select-last"
                          >
                            {CheckValueLocale("last", "", {}, intl)}
                          </MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item lg={6} xl={6}>
                      <Box className="text-field">
                        <Select
                          name="selectDaySelect"
                          fullWidth
                          displayEmpty
                          disabled={monthlyType === "specific" ? true : false}
                          value={selectedDay}
                          onChange={(e) => setSelectedDay(e.target.value)}
                        >
                          {weekDays.map((day) => {
                            return (
                              <MenuItem
                                key={day.value}
                                value={day.value}
                                className="report-menu-items"
                                id={`day-select-${day.value}`}
                              >
                                {CheckValueLocale(`${day.name}`, "", {}, intl)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {reportData?.attributes?.report_type_id !== 2 &&
      reportData?.attributes?.report_type?.toLowerCase() !== "newsletter" ? (
        <BrandingInReports
          isCreate={false}
          brandingValue={isBrandingReport}
          handleChangeBranding={handleChangeBranding}
          companyHasBranding={companyhasBranding}
          lastUpdated={lastUpdated}
        />
      ) : null}

      <PopupModal
        body={dateBody()}
        open={openPopUp}
        close={() => setOpenPopUp(false)}
        classeName={"date-popup-wrapper"}
        minWidth="350px"
      />
      {/* Commented Till Asiri request till NOW */}
      {/* <Grid item lg={6} xl={6}>
        <Box className="text-field radio-input-box">
          <InputLabel>{CheckValueLocale("branding", "", {}, intl)}</InputLabel>
          <FormControlLabel
            className="report-radio-ppt-label-branding"
            value="standard"
            control={<Radio checked={true} />}
            label={CheckValueLocale("standard", "", {}, intl)}
          />

          <FormControlLabel
            className="report-radio-ppt-label-branding"
            value="custom"
            control={<Radio />}
            label={
              <Box className="report-company-brand-div">
                <Typography>
                  {CheckValueLocale("reports_company_brand", "", {}, intl)}
                </Typography>

                <ReportTooltip
                  title={CheckValueLocale(
                    "check_company_branding_settings",
                    "",
                    {},
                    intl
                  )}
                  placement="top"
                  arrow
                >
                  <IconButton className="help-icon-branding">
                    <HelpIcon />
                  </IconButton>
                </ReportTooltip>
              </Box>
            }
            disabled
          />
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default EditReportStepThree;
