import { useIntl } from "react-intl";
import { Box, Button } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import PopupModal from "components/popupModal";
import "./whatsappAuthPopup.scss";
import { useEffect, useState } from "react";

const WhatsappAuthPopup = (props) => {
  const { accounts, whatsappMsgStatus } = props;
  const intl = useIntl();
  const [checkWhatsappType, setCheckWhatsappType] = useState("");
  // WhatsApp Auth Options

  useEffect(() => {
    accounts?.map((account) => {
      setCheckWhatsappType(account?.type);
    });
  }, []);

  const whatsAppAuthOptions = [
    {
      name: "twilio_auth_head",
      desc: "twilio_auth_body",
      type: "twilio_auth",
      disabled:
        whatsappMsgStatus == "pending" ||
        whatsappMsgStatus == "active" ||
        checkWhatsappType == "whatsapp_account" ||
        checkWhatsappType == "account_info",
      id: 1,
    },
    {
      name: "direct_auth_head",
      desc: "direct_auth_body",
      type: "direct_auth",
      disabled:
        whatsappMsgStatus == "pending" ||
        whatsappMsgStatus == "active" ||
        checkWhatsappType == "account_info",
      id: 2,
    },
  ];

  const handleCloseModal = () => {
    props?.setOpenWhatsAppPopup(false);
    props?.setwhatsAppDirectActive(0);
  };

  const handlewhatsAppDirectClick = (id) => {
    props?.setwhatsAppDirectActive(id);
  };

  const handleSubmit = () => {
    props?.whatsappSubmitAuth();
    props?.setOpenWhatsAppPopup(false);
  };

  const whatsappAuthModal = (
    <Box className="share-survey-container">
      <Box className="how-share-survey">
        <Box className="whatsapp-auth-step">
          {whatsAppAuthOptions?.map(({ name, desc, id, disabled }, index) => {
            return (
              <Box key={index} className="publish-step-btn">
                <Button
                  className={
                    props?.whatsAppdirectActive == id
                      ? "publish-grid-content active"
                      : "publish-grid-content"
                  }
                  onClick={() => handlewhatsAppDirectClick(id)}
                  disabled={disabled}
                >
                  <Box className="whats-auth-name">
                    {CheckValueLocale(`${name}`, "", {}, intl)}
                  </Box>

                  <Box className="whats-auth-desc">
                    {CheckValueLocale(`${desc}`, "", {}, intl)}
                  </Box>
                </Button>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <PopupModal
        title={CheckValueLocale("authenticate_whatsapp_account", "", {}, intl)}
        body={whatsappAuthModal}
        rightBtn={CheckValueLocale("send_request", "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        open={props?.openWhatsAppPopup}
        accept={handleSubmit}
        close={handleCloseModal}
        disabled={props?.whatsAppdirectActive == 0}
        classeName="whatsapp-auth-modal"
        addClasses="whatsapp-auth-buttons"
      />
    </>
  );
};

export default WhatsappAuthPopup;
