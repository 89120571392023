import { handlelUserRoles } from "utils/helpers";

export const useEngagementPermissions = () => {
  const canAccessEngagementSystem = handlelUserRoles(
    "ENGAGEMENTS",
    "ACCESS_ENGAGEMENT_SYSTEM",
  );
  const hasFullAccess = handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS");
  const hasInbox =
    handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") || hasFullAccess;
  const hasPublish =
    handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") || hasFullAccess;
  const hasAnalytics =
    handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS") ||
    hasFullAccess;

  const hasAiAgent = handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS");
  const isAdminRole = handlelUserRoles(
    "ENGAGEMENT_ACCESS",
    "ENGAGEMENT_SETTINGS",
  );

  return {
    canAccessEngagementSystem,
    // hasInboxAccess is used in routes but if you want to use it in components you can use hasInbox
    // the same goes for hasPublishAccess, hasAnalyticsAccess, hasSetupAccess, hasAiAgentAccess
    hasInboxAccess: canAccessEngagementSystem && (hasFullAccess || hasInbox),
    hasInbox,
    hasPublishAccess:
      canAccessEngagementSystem && (hasFullAccess || hasPublish),
    hasPublish,
    hasAnalyticsAccess: hasFullAccess || hasAnalytics,
    hasAnalytics,
    hasSetupAccess: isAdminRole && (hasInbox || hasPublish || hasAnalytics),
    hasAiAgent,
    hasAiAgentAccess: hasAiAgent && hasInbox,
    isAdminRole,
    hasFullAccess,
    engagementPriorityProductPath: hasInbox
      ? "/engagements/main/inbox"
      : hasAnalytics
        ? "/engagements/analytics"
        : "/engagements/main/publish",
  };
};
