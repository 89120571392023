import { Box, Container } from "@mui/material";
import { useSharedInboundRouting } from "../sharedLogic/useSharedInboundRouting";
import { EditRoutingHeader } from "./editRoutingHeader";
import "./editInboundRouting.scss";
import { EditRoutingDetails } from "./editRoutingDetails";
import { EditRoutingTeamAndSLA } from "./editRoutingTeamAndSLA";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CircularLoading from "components/circularProgress";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import {
  DeserializeConditionsValueFromBackend,
  SLATimeStringify,
  compareRoutingCondtions,
  serializeConditionsValuesForBackend,
} from "../sharedLogic/inboundRouteAdapter";
import { EditRoutingFooter } from "./editRoutingFooter";
import PopupModal from "components/popupModal";
import { sanitize } from "dompurify";

export const EditInboundRouting = () => {
  const { id } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const [isLoadingRouting, setIsLoadingRouting] = useState(true);
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [isLoadiongSavingRouting, setIsLoadiongSavingRouting] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // a ref that will holds the routing object that has came from backend when the page loads.
  const originalRouting = useRef(null);
  const {
    generalInfo,
    handleGeneralInfoChange,
    conditions,
    setConditions,
    validationErrors,
    routingError,
    setRoutingError,
    isConditionsLoading,
    conditionsOptions,
    getConditions,
    teamAndSLA,
    setTeamAndSLA,
    getTeamsAndSLAsData,
    teamAndSLAsLoading,
    getSLAs,
    getTeams,
  } = useSharedInboundRouting();
  const isEditRoutingFormValid =
    validationErrors?.isAllConditionsValid &&
    validationErrors?.isGeneralInfoValid &&
    validationErrors?.isTeamAndSLAValid;
  const getInboundRouting = () => {
    EngagementsController.getInboundRouting(id).then((res) => {
      if (res?.errorMsg) {
        setRoutingError({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      } else {
        const routing = res?.data?.data?.attributes;
        const generalInfo = {
          name: routing?.name,
          description: routing?.description,
        };
        handleGeneralInfoChange(generalInfo);
        const desrializedCondition = DeserializeConditionsValueFromBackend(
          routing?.conditions,
        );
        setConditions(desrializedCondition);
        // desrialize team, sla, and algorithm
        const team = routing?.team?.data?.attributes || null;
        const sla = routing?.sla?.data?.attributes || null;
        const algoID =
          routing?.auto_assign_algorithm?.data?.attributes?.id || null;
        const teamAndSLA = {
          selectedTeam: team,
          selectedSLA: sla
            ? {
                ...sla,
                name: `${sla?.name} ${SLATimeStringify(sla)}`,
              }
            : null,
          selectedAlgorithm: algoID,
          autoAssigned: algoID ? true : false,
        };
        setTeamAndSLA((prevData) => ({
          ...prevData,
          ...teamAndSLA,
        }));
        originalRouting.current = {
          ...generalInfo,
          conditions: desrializedCondition,
          ...teamAndSLA,
        };
      }
      setIsLoadingRouting(false);
    });
  };
  const handleSaveRoutingChanging = () => {
    setIsLoadiongSavingRouting(true);
    const updatedRoting = {
      name: generalInfo?.name,
      description: generalInfo?.description,
      team_id: teamAndSLA?.selectedTeam?.id || null,
      sla_id: teamAndSLA?.selectedSLA?.id || null,
      algorithm_type_id: teamAndSLA?.autoAssigned
        ? teamAndSLA?.selectedAlgorithm
        : null,
      conditions: serializeConditionsValuesForBackend(conditions),
    };
    EngagementsController.updateInboundRouting(id, updatedRoting).then(
      (res) => {
        if (res?.errorMsg) {
          const isNameExist =
            res?.errorMsg?.response?.data?.exception?.message ===
            "INBOUND_ROUTING_NAME_ALREADY_EXIST";
          if (isNameExist) {
            setRoutingError({
              routingNameError: "routing_name_already_exist",
            });
            window.scrollTo(0, 0);
          } else {
            setRoutingError({
              message: "routing_creation_failed_body",
              severity: "error",
              title: "routing_creation_failed_title",
              openSnackBar: true,
            });
          }
        } else {
          history.push(`/settings/inbound_routing?status=updated_successfully`);
          window.scrollTo(0, 0);
        }
        setIsLoadiongSavingRouting(false);
      },
    );
  };
  const handleCancel = () => {
    if (numberOfChanges) {
      setIsModalOpen(true);
    } else {
      history.push("/settings/inbound_routing");
      window.scrollTo(0, 0);
    }
  };
  const handleCloseSnackBar = () => {
    setRoutingError({
      openSnackBar: false,
    });
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const hanldeAcceptModal = () => {
    history.push("/settings/inbound_routing");
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    // fetch all data required for the page before we get the inbound routing object
    getInboundRouting();
    getConditions();
    getTeamsAndSLAsData();
  }, []);

  useEffect(() => {
    const routing = originalRouting?.current;
    if (!routing) return;
    // calculate the number of changes user have made every time he change something
    let counter = 0;
    // general info
    if (
      routing?.name !== generalInfo?.name?.trim() ||
      routing?.description !== generalInfo?.description?.trim()
    )
      counter++;
    // conditions
    const isConditionsDifferent = compareRoutingCondtions(
      conditions,
      routing?.conditions,
    );
    if (isConditionsDifferent) counter++;
    // team
    if (teamAndSLA?.selectedTeam?.id != routing?.selectedTeam?.id) counter++;
    // assigning algo
    // when to consider a change in the algo??!
    // case 1: when the toggle go from off to on or vice versa.
    // case 2: when the toggle is on and the algo option is changed.
    if (
      teamAndSLA?.autoAssigned !== routing?.autoAssigned ||
      (teamAndSLA?.autoAssigned &&
        teamAndSLA?.selectedAlgorithm != routing?.selectedAlgorithm)
    )
      counter++;
    // sla
    if (teamAndSLA?.selectedSLA?.id != routing?.selectedSLA?.id) counter++;
    setNumberOfChanges(counter);
  }, [generalInfo, conditions, teamAndSLA]);
  if (isConditionsLoading || isLoadingRouting) return <CircularLoading />;
  return (
    <>
      <Container maxWidth="xl" className="edit-inbound-routing-container">
        <EditRoutingHeader handleCancel={handleCancel} />
        <Box className="edit-inbound-routing-wrapper">
          <EditRoutingDetails
            generalInfo={generalInfo}
            handleGeneralInfoChange={handleGeneralInfoChange}
            routingNameError={routingError?.routingNameError}
            routingDescriptionError={routingError?.routingDescriptionError}
            conditions={conditions}
            setConditions={setConditions}
            isAllConditionsValid={validationErrors?.isAllConditionsValid}
            conditionsOptions={conditionsOptions}
          />
          <EditRoutingTeamAndSLA
            teamAndSLA={teamAndSLA}
            setTeamAndSLA={setTeamAndSLA}
            teamAndSLAsLoading={teamAndSLAsLoading}
            getSLAs={getSLAs}
            getTeams={getTeams}
          />
        </Box>
        <SnackBar
          open={routingError?.openSnackBar}
          severity={routingError?.severity}
          message={CheckValueLocale(routingError?.message, "", {}, intl)}
          title={CheckValueLocale(routingError?.title, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      </Container>
      <PopupModal
        title={CheckValueLocale("routing_discard_changes_title", "", {}, intl)}
        body={
          <Box
            className="inbound-routing-discard-modal-message"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                CheckValueLocale("routing_discard_changes_body", "", {}, intl),
              ),
            }}
          />
        }
        open={isModalOpen}
        leftBtn={CheckValueLocale("keep_editing", "", {}, intl)}
        leftBtnVariant="outline"
        leftBtnType="primary"
        rightBtn={CheckValueLocale("routing_discard_changes", "", {}, intl)}
        close={handleCloseModal}
        accept={hanldeAcceptModal}
        classeName="inbound-routing-discard-modal"
      />
      {numberOfChanges > 0 ? (
        <EditRoutingFooter
          handleCancel={handleCancel}
          numberOfChanges={numberOfChanges}
          handleSave={handleSaveRoutingChanging}
          isDisabled={!isEditRoutingFormValid}
          isLoading={isLoadiongSavingRouting}
        />
      ) : null}
    </>
  );
};
