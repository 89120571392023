import { useEffect, useState } from "react";
import InstagramPublic from "pages/alerts/components/alertTypesView/alertDataSourcesCA/instagram/instagramPublic";
import FacebookPublic from "pages/alerts/components/alertTypesView/alertDataSourcesCA/facebook/facebookPublic";
import TwiterPrivate from "pages/alerts/components/alertTypesView/alertDataSourcesCA/twiter//twiterPrivate";
import TwiterPublic from "pages/alerts/components/alertTypesView/alertDataSourcesCA/twiter//twiterPublic";
import LinkedInAlert from "../../alertDataSourcesCA/linkedInAlert";
import FacebookPrivate from "../../alertDataSourcesCA/facebook/facebookPrivate";
import GoogleMyBusinessAlert from "../../alertDataSourcesCA/googleMyBusiness";
import GenesysAlert from "../../alertDataSourcesCA/genesysAlert";
import WhatsappAlert from "../../alertDataSourcesCA/whatsapp/whatsapp";
import GmailAlert from "../../alertDataSourcesCA/gmail/gmail";
import IntercomAlert from "../../alertDataSourcesCA/intercomAlert";
import TiktokAlert from "./../../alertDataSourcesCA/tiktokAlert";

import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";

const CADatasourcesAlerts = (props) => {
  let {
    response,
    alertDetailDataSource,
    alertDetailMonitorId,
    alertDetailType,
    resetAllFields,
    page,
    setPage,
  } = props;

  const [dataSources, setDataSources] = useState([]);
  useEffect(() => {
    getDataSources(setDataSources);
  }, []);
  return alertDetailDataSource === "twitter" ? (
    <TwiterPublic
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      alertDetailDataSource={alertDetailDataSource}
      resetAllFields={resetAllFields}
      page={page}
      setPage={setPage}
    />
  ) : alertDetailDataSource === "privatetwitter" ? (
    <TwiterPrivate
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      alertDetailDataSource={alertDetailDataSource}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "instagram" ? (
    <InstagramPublic
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "facebook" ? (
    <FacebookPublic
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "privatefacebook" ? (
    <FacebookPrivate
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      alertDetailDataSource={alertDetailDataSource}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "linkedin" ? (
    <LinkedInAlert
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "genesys" ? (
    <GenesysAlert
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "googlemybusiness" ? (
    <GoogleMyBusinessAlert
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "whatsapp" ? (
    <WhatsappAlert
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "gmail" ? (
    <GmailAlert
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "intercom" ? (
    <IntercomAlert
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      resetAllFields={resetAllFields}
    />
  ) : alertDetailDataSource === "tiktok" ? (
    <TiktokAlert
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      dataSources={dataSources}
      alertDetailType={alertDetailType}
      alertDetailDataSource={alertDetailDataSource}
      resetAllFields={resetAllFields}
      page={page}
      setPage={setPage}
    />
  ) : null;
};
export default CADatasourcesAlerts;
