import Login from "../api/login.js";
import { SetUserData } from "../../shared/set-user-data/set-user-data.js";

var LoginController = {};

LoginController.userLogin = (email, password, lang) => {
  var queryData = {
    email,
    password,
    lang,
  };
  return new Promise(function (resolve) {
    Login.userLogin(queryData)
      .then((response) => {
        if (response?.status !== 200) {
          resolve({
            data: response,
          });
        } else {
          // CHECK ENV TO FIRE POPUP
          {
            process.env.REACT_APP_ENV == "production"
              ? window.localStorage.setItem("productionEnv", "true")
              : process.env.REACT_APP_ENV == "production_local"
                ? window.localStorage.setItem("productionEnv", "true")
                : window.localStorage.setItem("productionEnv", "false");
          }
          SetUserData(response);
          window.localStorage.setItem(
            "companyName",
            response?.data?.included[0]?.attributes?.name,
          );
          resolve({
            data: response.data.data,
          });
        }
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
        });
      });
  });
};

LoginController.userlogout = (monitorId) => {
  return new Promise(function (resolve) {
    Login.userlogout(monitorId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LoginController.userForgetPassword = (email) => {
  var queryData = { email };
  return new Promise(function (resolve) {
    Login.userForgetPassword(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LoginController.userResetPassword = (token, new_password) => {
  var queryData = { password_reset_token: token, new_password };
  return new Promise(function (resolve) {
    Login.userResetPassword(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LoginController.activateSignUp = (
  invitation_token,
  user_email,
  user_password,
) => {
  var queryData = { invitation_token, user_email, user_password };
  return new Promise(function (resolve) {
    Login.activateSignUp(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//login hubspot

LoginController.userLoginHubspot = (email, password, lang, redirect_url) => {
  var queryData = {
    email,
    password,
    lang,
    redirect_url,
  };
  return new Promise(function (resolve) {
    Login.userLoginHubspot(queryData)
      .then((response) => {
        resolve({
          data: response,
          status: response.status,
        });
        SetUserData(response);
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
        });
      });
  });
};

export default LoginController;
