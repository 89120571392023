import { useIntl } from "react-intl";
import ChartDetailsWidget from "../../widgets/customStatisctics/chartDetailsWidget";
import { convertDataToDhms } from "utils/helpers/index";

const InteractionListPrivateMessage = (props) => {
  const intl = useIntl();
  return (
    <div
      className={
        props?.isRemoveBox ? "" : "message-container message-container-private"
      }
    >
      <ChartDetailsWidget
        statsDetailsData={[
          {
            name: "waiting_time",
            value: convertDataToDhms(props.waitingTime, intl),
            showStringValue: true,
          },
          {
            name: "conversation_duration",
            value: convertDataToDhms(props.handlingTime, intl),
            showStringValue: true,
          },
          {
            name: "conversation_status",
            value: props?.status,
            showStringValue: true,
          },
        ]}
      />
    </div>
  );
};

export default InteractionListPrivateMessage;
