import faceBookImagesWidget from "./faceBookImagesWidget";
import twitterImagesWidget from "./twitterImagesWidget";
import instagramImagesWidget from "./instagramImagesWidget";
import newsBlogsImagesWidget from "./newsBlogsImagesWidget";

const imagesWidgetLiveDashboard = {
  ...twitterImagesWidget,
  ...faceBookImagesWidget,
  ...instagramImagesWidget,
  ...newsBlogsImagesWidget,
};

export default imagesWidgetLiveDashboard;
