import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "../manageChannels.scss";

export const StatusBodyCell = ({ channel }) => {
  const intl = useIntl();
  const statusStyle =
    channel?.attributes?.stream_status !== "unapproved"
      ? channel?.attributes?.stream_status
      : "unapproved-channel";

  const getStatusLocalization = (status) => {
    const monitor_type = {
      collecting: CheckValueLocale("active", "", {}, intl),
      paused: CheckValueLocale("paused", "", {}, intl),
      re_authenticated: CheckValueLocale("re_authenticate", "", {}, intl),
      unapproved: CheckValueLocale("under_approval", "", {}, intl),
    };
    return monitor_type[status];
  };

  return (
    <>
      {getStatusLocalization(channel?.attributes?.stream_status) ? (
        <Box className={`status ${statusStyle}`}>
          {" "}
          {getStatusLocalization(channel?.attributes?.stream_status)}
        </Box>
      ) : null}
    </>
  );
};
