import { useState } from "react";
import { useIntl } from "react-intl";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Avatar,
  Button,
  Tooltip,
} from "@mui/material";
import "react-intl-tel-input/dist/main.css";
import UserForm from "../../addNewUser/sheardComponents/UserForm";
import { CheckValueLocale } from "utils/helpers";
import "../editUser.scss";
import ImageModal from "pages/profile/components/imageEditorComponents/imageModal";
import DefaultUser from "images/shared-images/user-management-default.svg";
import UserAvatar from "shared/unifiedUserImage/userAvatar";

export const MaineEditCard = ({ userData, setUserData, isEditUser }) => {
  const intl = useIntl();
  const [openModal, setModal] = useState(false);
  const setImageUpdate = async (newImage) => {
    setUserData({ ...userData, avatar: newImage });

    handleModal();
  };
  const handleModal = () => {
    if (openModal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  return (
    <>
      {/*edit user card */}
      <Box className="main-edit-user-card">
        <Typography className="edit-sec-tit">
          {CheckValueLocale("edit_user_card_tittle", "", {}, intl)}
        </Typography>
        <Box className="edit-sec-container">
          {/*user  photo*/}
          <Box className="edit-user-img">
            <Grid item lg={3} align="center" md={5} sm={12} xs={12}>
              <Typography className="img-title">
                {CheckValueLocale("profile_image", "", {}, intl)}
              </Typography>
              <Stack direction="row" justifyContent="center">
                <UserAvatar
                  fullName={userData?.name}
                  avatarSrc={userData?.avatar}
                  sizes={{ ratio: "155px", fontSize: "42px" }}
                />
              </Stack>
              {/* {may be used later} */}
              {/* <div id="button">
                <Tooltip title={CheckValueLocale("edit_photo", "", {}, intl)}>
                  <Button
                    onClick={() => {
                      handleModal();
                    }}
                    id="profile-user-info-edit-photo-btn"
                  >
                    <CameraAltIcon className="cameraIcon" />
                  </Button>
                </Tooltip>
              </div> */}
            </Grid>
          </Box>

          {openModal ? (
            <ImageModal
              open={openModal}
              handleModal={handleModal}
              avatar={userData?.avatar}
              setImage={setImageUpdate}
              id="user-profile-image-modal"
            />
          ) : (
            ""
          )}
          <UserForm
            stepOneData={userData}
            setStepOneData={setUserData}
            isEditUser={isEditUser}
          />
        </Box>
      </Box>
    </>
  );
};
