import { Box } from "@mui/material";

import "./popupContent.scss";
const PopUpSharedContent = ({ bodyContent, handleClose }) => {
  return (
    <Box className="content-dialg-wrapper">
      <Box className="overlay" onClick={handleClose}></Box>
      {bodyContent}
    </Box>
  );
};
export default PopUpSharedContent;
