import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import SnackBar from "components/snackBar";
import AlertHeader from "pages/alerts/components/header/AlertHeader";
import AlertForm from "pages/alerts/components/alertForm/alertForm";
import AlertsFunctions from "pages/alerts/components/alertsFormFunctions/alertsFunctions";

const MonitorCreateAlert = (props) => {
  const { monitorData } = props;
  const intl = useIntl();
  const isMonitorAction = true;

  const {
    loading,
    page,
    numberOfPages,
    alertTypeNames,
    getAlerts,
    getAlertsTypes,
    selectedTypes,
    alerts,
    handleChangePage,
    openForm,
    setOpenForm,
    openFormType,
    setOpenFormType,
    handleCloseForm,
    handleOpenForm,
    alertName,
    setAlertName,
    formFieldErrors,
    setFormFieldErrors,
    handleInputChange,
    productType,
    setProductType,
    getAlertData,
    alertsInfo,
    datasourceType,
    setDatasourceType,
    alertType,
    setAlertType,
    getFilteredData,
    alertRateType,
    setAlertRateType,
    selectedMonitors,
    setSelectedMonitors,
    handleChangeMultipleSelection,
    monitorsList,
    setMonitorsList,
    getAlertsMonitors,
    recieveAlertsHourly,
    setRecieveAlertsHourly,
    handleRecieveAlertsHourly,
    alertThresholdType,
    setAlertThresholdType,
    handleChangeAlertsFields,
    thresholdManualValue,
    setThresholdManualValue,
    getComanyEmails,
    companyUsers,
    setCompanyUsers,
    selectedEmails,
    setSelectedEmails,
    loginUserData,
    emailsEnabled,
    setEmailsEnabled,
    keywordsFocused,
    setKeywordsFocused,
    alertKeywords,
    setAlertKeywords,
    selectedKeywords,
    setSelectedKeywords,
    handleKeywordChange,
    handleKeywords,
    handleDeleteKeyword,
    checkDisableCreateAlert,
    handelPostUsers,
    postUsers,
    handlePostsUsersChange,
    selectedPostUsers,
    handleDeleteInputField,
    setSelectedPostUsers,
    setPostUsers,
    handleFormSubmit,
    showSnackBar,
    setShowSnackBar,
    showAlertActionMessage,
    resetFormData,
    handleTypeSelection,
    alertTypeLocale,
    getAlertsLoading,
    isManualAlert,
    isHourlyType,
    alertThresold,
    isCreateAlert,
    alertsSources,
    alertsTypes,
    alertRates,
    isNegavtivePostsType,
    showAdavancedKeywords,
    getPostsTypeTitle,
    activeAlertType,
    handleMinimumNoFollowrs,
    minimumNoFollowers,
    isInfluncerType,
    getAlertsUpdateData,
    alertEditData,
    getEditAlertsLoading,
    setMiniumimNoFollowers,
    setPage,
    setShowAlertActionMessage,
    isInstagramDatasource,
    splitDataSourceType,
    filteredAlertsData,
    monitorsLoading,
    handleChangeAlertsMaximum,
    maxmNumberEmailsValue,
    setMaxmNumberEmailsValue,
    alertMaxEmails,
    setAlertMaxEmails,
    maxmNumValue,
    setMaxmNumValue,
    informerMaxEmails,
    setInformerMaxEmails,
    isViralTweetType,
    isInstantType,
    minimumNoInteractions,
    setMiniumimNoInteractions,
    handleMinimumNoInteractions,
    alertWebhookInfo,
    enableWebhooks,
    setEnableWebhooks,
    getApisLoading,
    lucidyaApisList,
    keyLocationList,
    apiKey,
    setApiKey,
    method,
    setMethod,
    url,
    setUrl,
    verificationKey,
    setVerificationKey,
    authVarName,
    setAuthVarName,
    handleAuthVarName,
    authLocation,
    setAuthLocation,
    handleAuthLocationChange,
    testWebhooksResult,
    setTestWebhooksResult,
    testWebhooksLoading,
    handleTestUrl,
    checkDisableTestUrl,
    deleteWebhooksLoading,
    handleDeleteWebhooks,
    handleEnableDisableWebhooks,
    enableWebhooksLoading,
    disableWebhooksLoading,
    handleVerificatinKeyChange,
    isSortableLoading,
    tableColumns,
    alertsConversationTypes,
    alertConversationType,
    setAlertConversationType,
    getAlertMonitorsEngagements,
    selectedSources,
    setSelectedSources,
    sourcesListEngagements,
    sourcesLoadingEngagements,
    handleChangeSourcesEngagements,
    handleSourcesIds,
    buttonLoading,
  } = AlertsFunctions(isMonitorAction);

  const childProps = {
    openForm,
    setOpenForm,
    openFormType,
    setOpenFormType,
    handleCloseForm,
    handleOpenForm,
    alertTypeNames,
    selectedTypes,
    handleTypeSelection,
    alertTypeLocale,
    alertName,
    setAlertName,
    formFieldErrors,
    setFormFieldErrors,
    handleInputChange,
    productType,
    setProductType,
    alertsInfo,
    datasourceType,
    setDatasourceType,
    alertType,
    setAlertType,
    getFilteredData,
    alertRateType,
    setAlertRateType,
    selectedMonitors,
    setSelectedMonitors,
    handleChangeMultipleSelection,
    monitorsList,
    setMonitorsList,
    getAlertsMonitors,
    recieveAlertsHourly,
    setRecieveAlertsHourly,
    handleRecieveAlertsHourly,
    alertThresholdType,
    setAlertThresholdType,
    thresholdManualValue,
    setThresholdManualValue,
    handleChangeAlertsFields,
    getComanyEmails,
    companyUsers,
    setCompanyUsers,
    selectedEmails,
    setSelectedEmails,
    loginUserData,
    emailsEnabled,
    setEmailsEnabled,
    keywordsFocused,
    setKeywordsFocused,
    alertKeywords,
    setAlertKeywords,
    selectedKeywords,
    setSelectedKeywords,
    handleKeywordChange,
    handleKeywords,
    handleDeleteKeyword,
    checkDisableCreateAlert,
    handelPostUsers,
    postUsers,
    handlePostsUsersChange,
    selectedPostUsers,
    handleDeleteInputField,
    setSelectedPostUsers,
    setPostUsers,
    handleFormSubmit,
    resetFormData,
    alerts,
    handleChangePage,
    getAlertData,
    getAlertsLoading,
    isManualAlert,
    isHourlyType,
    showAdavancedKeywords,
    alertThresold,
    isCreateAlert,
    alertsSources,
    alertsTypes,
    alertRates,
    isNegavtivePostsType,
    getPostsTypeTitle,
    activeAlertType,
    handleMinimumNoFollowrs,
    minimumNoFollowers,
    isInfluncerType,
    getAlertsUpdateData,
    alertEditData,
    getEditAlertsLoading,
    setMiniumimNoFollowers,
    page,
    setPage,
    setShowAlertActionMessage,
    setShowSnackBar,
    getAlerts,
    isInstagramDatasource,
    splitDataSourceType,
    filteredAlertsData,
    monitorsLoading,
    handleChangeAlertsMaximum,
    maxmNumberEmailsValue,
    setMaxmNumberEmailsValue,
    alertMaxEmails,
    setAlertMaxEmails,
    maxmNumValue,
    setMaxmNumValue,
    informerMaxEmails,
    setInformerMaxEmails,
    isViralTweetType,
    isInstantType,
    minimumNoInteractions,
    setMiniumimNoInteractions,
    handleMinimumNoInteractions,
    alertWebhookInfo,
    enableWebhooks,
    setEnableWebhooks,
    getApisLoading,
    lucidyaApisList,
    keyLocationList,
    apiKey,
    setApiKey,
    method,
    setMethod,
    url,
    setUrl,
    verificationKey,
    setVerificationKey,
    authVarName,
    setAuthVarName,
    handleAuthVarName,
    authLocation,
    setAuthLocation,
    handleAuthLocationChange,
    testWebhooksResult,
    setTestWebhooksResult,
    testWebhooksLoading,
    handleTestUrl,
    checkDisableTestUrl,
    deleteWebhooksLoading,
    handleDeleteWebhooks,
    handleEnableDisableWebhooks,
    enableWebhooksLoading,
    disableWebhooksLoading,
    handleVerificatinKeyChange,
    alertsConversationTypes,
    alertConversationType,
    setAlertConversationType,
    getAlertMonitorsEngagements,
    selectedSources,
    setSelectedSources,
    sourcesListEngagements,
    sourcesLoadingEngagements,
    handleChangeSourcesEngagements,
    handleSourcesIds,
    buttonLoading,
  };

  // Set Pre-selected monitor
  useEffect(() => {
    if (openForm && monitorData)
      setSelectedMonitors([
        {
          id: monitorData?.id,
          name: monitorData?.attributes?.name,
        },
      ]);
  }, [openForm, monitorData]);

  useEffect(() => {
    if (monitorData) {
      // set pre-selected product id
      if (alertsInfo) {
        alertsInfo?.map((product) => {
          if (product?.name == "SM") setProductType(product?.id);
        });
      }

      // set pre-selected datasource in case of single datasource
      if (
        alertsSources?.length > 0 &&
        monitorData?.attributes?.data_sources?.length === 1
      ) {
        alertsSources?.map((source) => {
          if (source?.name === monitorData?.attributes?.data_sources[0])
            setDatasourceType(
              `${source?.id}-${source?.name}-${source?.channel_type_id}`,
            );
        });
      }
    }
  }, [monitorData, alertsInfo, alertsSources]);

  return (
    <>
      <AlertHeader isMonitorAction={true} {...childProps} />
      {openForm ? (
        <AlertForm
          isMonitorAction={true}
          monitorData={monitorData}
          {...childProps}
        />
      ) : null}
      <SnackBar
        open={showSnackBar}
        severity={showAlertActionMessage?.severity}
        message={CheckValueLocale(
          showAlertActionMessage?.message,
          "",
          {},
          intl,
        )}
        title={showAlertActionMessage?.title}
        handleClose={() => {
          setShowSnackBar(false);
        }}
      />
    </>
  );
};

export default MonitorCreateAlert;
