import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { CheckValueLocale } from "utils/helpers";
import AccessTable from "./accessTables/accessTable";
import SelectUserTeams from "../sheardComponents/MultiSelectDropdown";
import "./stepThree.scss";
import { AdvancedTabs, InboxTabs } from "../tabs";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const StepThree = ({
  accTableData,
  accTableSelectedData,
  setAccTableSelectedData,
  accTableDetailsData,
  setAccTableDetailsData,
  setAccTableData,
  tableCols,
  advancedTabsData,
  setAdvancedTabsData,
  infoTabsData,
  setInfoTabsData,
  tabsErrors,
  setTabsErrors,
  teamsData,
  setTeamsData,
}) => {
  const intl = useIntl();

  return (
    <Box className="main-step-three">
      <Box className="main-step-tit">
        {CheckValueLocale("engag_perm_tit", "", {}, intl)}
        <LucButton
          variant="flat"
          endIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
        >
          {CheckValueLocale("learn_more_user", "", {}, intl)}
        </LucButton>
      </Box>
      <SelectUserTeams teamsData={teamsData} setTeamsData={setTeamsData} />
      <AdvancedTabs
        advancedTabsData={advancedTabsData}
        setAdvancedTabsData={setAdvancedTabsData}
        tabsErrors={tabsErrors}
        setTabsErrors={setTabsErrors}
      />
      <InboxTabs
        infoTabsData={infoTabsData}
        setInfoTabsData={setInfoTabsData}
        tabsErrors={tabsErrors}
        setTabsErrors={setTabsErrors}
      />
      {Object.keys(accTableData)?.map((table) => {
        return (
          <Box className={`main-acc-table-countainer ${table}`} id={table}>
            {accTableData[table]?.length ? (
              <Box className="main-table-tit">
                {CheckValueLocale(
                  table,
                  "",
                  {
                    num1: Object.keys(accTableSelectedData[table])?.length,
                    num2: accTableDetailsData[`${table}_counter`],
                  },
                  intl,
                )}
                {table == "account_access" ? (
                  <Box className="main-table-acc-prag">
                    {CheckValueLocale("account_select_prag", "", {}, intl)}
                  </Box>
                ) : null}
              </Box>
            ) : null}
            <AccessTable
              name={table}
              accTableData={accTableData}
              accTableSelectedData={accTableSelectedData}
              setAccTableSelectedData={setAccTableSelectedData}
              accTableDetailsData={accTableDetailsData}
              setAccTableDetailsData={setAccTableDetailsData}
              setAccTableData={setAccTableData}
              tableCols={tableCols}
            />
          </Box>
        );
      })}
    </Box>
  );
};
export default StepThree;
