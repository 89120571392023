import { Box, Divider, Stack } from "@mui/material";
import { useIntl } from "react-intl";
import {
  formatGMTString,
  formatNumberWithCommars,
  formateDateToLocaleString,
} from "utils/helpers";

const getCorrectDisplayDate = (date) => {
  return date?.split(",")?.reverse() || [];
};

const InteractionFooter = ({ interactionMetrics = [], createdAt }) => {
  const intl = useIntl();

  const [gmt, date] = getCorrectDisplayDate(
    `${formateDateToLocaleString(createdAt)}${formatGMTString(intl)}`,
  );
  return (
    <Box className="tweet-meta">
      <Stack className="tweet-meta-row">
        {interactionMetrics?.map((metric) =>
          metric.name === "divider" ? (
            <Divider
              className="interaction-divider"
              orientation="vertical"
              variant="middle"
              flexItem
              key={metric?.name}
            />
          ) : (
            <Box
              component="span"
              className="tweet-meta-item"
              key={metric?.name}
            >
              <Box component="span" className="tweet-meta-item-icon">
                {metric?.icon}
              </Box>
              <Box component="span" className="tweet-meta-item-value">
                {formatNumberWithCommars(metric?.value)}
              </Box>
            </Box>
          ),
        )}
        <Divider
          className="interaction-divider"
          orientation="vertical"
          variant="middle"
          flexItem
        />
        <Box className="tweet-date">
          <Box component="span">{gmt}</Box>
          <Divider
            className="interaction-divider"
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Box component="span">{date}</Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default InteractionFooter;
