import Services from "./Services";

var Alerts = {};

Alerts.getAlertsV1 = (queryData) => {
  return Services.getData("api/v3/configured_alerts", queryData);
};

Alerts.getAlerts = (queryData) => {
  return Services.getData("api/v4/utilities/alerts", queryData);
};

Alerts.getAlertMonitors = (queryData) => {
  return Services.getData(
    "api/v3/configured_alerts/alert_monitors_list",
    queryData,
  );
};

Alerts.deleteAlerts = (id) => {
  return Services.deleteData(`api/v3/configured_alerts/${id}`);
};

Alerts.editAlerts = (id, queryData) => {
  return Services.patchData(`api/v3/configured_alerts/${id}`, queryData);
};

Alerts.getAlertsTypesV1 = (id) => {
  return Services.getData("api/v3/configured_alerts/types");
};

Alerts.getAlertsTypesV2 = (id) => {
  return Services.getData("api/v3/utilities/alerts/types");
};

Alerts.createAlerts = (queryData) => {
  return Services.postData("api/v3/configured_alerts", queryData);
};

// get alert detail [ RabbitMQ ]
Alerts.getAlertDetail = (id, queryData) => {
  return Services.getData(`api/v3/utilities/alerts/${id}/stream`, queryData);
};

//new version
Alerts.getAlertsData = (queryData) => {
  return Services.getData("api/v3/utilities/alerts/informer", queryData);
};
Alerts.getAlertsMonitors = (queryData) => {
  return Services.getData("api/v3/utilities/alerts/monitors", queryData);
};
Alerts.createAlertsV2 = (queryData) => {
  return Services.postData("api/v3/utilities/alerts", queryData);
};
Alerts.updateAlertsV2 = (queryData, id) => {
  return Services.putData(`api/v3/utilities/alerts/${id}`, queryData);
};
Alerts.getUpdateAlertsDataV2 = (id) => {
  return Services.getData(`api/v3/utilities/alerts/${id}`);
};

Alerts.deleteAlertsDataV2 = (id) => {
  return Services.deleteData(`api/v3/utilities/alerts/${id}`);
};

Alerts.getTestWebhooks = (queryData) => {
  return Services.getData(
    "api/v3/public_apis/webhooks/test_callback",
    queryData,
  );
};

Alerts.deleteWebhooks = (id) => {
  return Services.deleteData(`api/v3/public_apis/webhooks/${id}`);
};

Alerts.enableWebhooks = (id) => {
  return Services.postData(`api/v3/public_apis/webhooks/enable/${id}`);
};

Alerts.disableWebhooks = (id) => {
  return Services.postData(`api/v3/public_apis/webhooks/disable/${id}`);
};

Alerts.getAlertMonitorsEngagements = (queryData) => {
  return Services.getData(
    "api/v3/utilities/alerts/engagement_monitors",
    queryData,
  );
};
export default Alerts;
