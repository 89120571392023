import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import { Box, Typography, FormHelperText } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadExceFile = (props) => {
  const intl = useIntl();
  const { files, setFiles, displayUploadErrorMessage } = props;
  let uploadErrorMessage = displayUploadErrorMessage();
  const maxSize = 10485760;

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFiles([]);
    const mapAcc = acceptedFiles?.map((file) => ({ file, errors: [] }));
    setFiles((cur) => [...cur, ...mapAcc, ...rejectedFiles]);
  }, []);
  return (
    <Box className="audience-upload-file-container">
      <Typography component="span" className="upload-file-title">
        {CheckValueLocale("audience_upload_file_title", "", {}, intl)}
      </Typography>
      <Box
        className={`upload-file-card ${
          uploadErrorMessage && "upload-error-message"
        }`}
      >
        <Dropzone onDrop={onDrop} accept=".xls,.xlsx" maxSize={maxSize}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Box className="upload-file-data">
                <CloudUploadIcon className="import-upload-icon" />
                <Box className="drop-file-content">
                  <Typography component="p" className="upload-file-header">
                    {CheckValueLocale(
                      "audience_import_file_header",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                  <Typography component="span" className="import-browse-file">
                    {CheckValueLocale("audience_import_browse", "", {}, intl)}
                  </Typography>
                </Box>
              </Box>
            </div>
          )}
        </Dropzone>
      </Box>

      {uploadErrorMessage ? (
        <FormHelperText error className="upload-file-error-msg">
          {CheckValueLocale(uploadErrorMessage, "", {}, intl)}
        </FormHelperText>
      ) : (
        <>
          <Typography
            component="span"
            className="upload-accepted-formats-title"
          >
            {CheckValueLocale("audience_accepted_formats_title", "", {}, intl)}
          </Typography>
          <Typography component="p" className="upload-accepted-formats-title">
            {CheckValueLocale(
              "audience_accepted_size_limit_title",
              "",
              {},
              intl,
            )}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default UploadExceFile;
