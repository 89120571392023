import { Box, Divider, Typography } from "@mui/material";
import "./fullTextTweetPopUp.scss";
import {
  faComments,
  faHeart,
  faRetweet,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import moment from "moment";

function FullTextTweetPopUp({
  closeFilter,
  post,
  tweet_date,
  showRetweet = false,
  showHeart = false,
  ShowComments = false,
}) {
  const intl = useIntl();
  const localizedDate =
    window.localStorage.lang === "en"
      ? moment
          .unix(parseInt(post?.created_at || post?.tweet_created_at))
          .utc()
          .format("D MMM, YYYY h:mm A")
      : moment
          .unix(post?.created_at || post?.tweet_created_at)
          .utc()
          .format("D MMM, YYYY h:mm A")
          ?.split(" ")[0] +
        " " +
        CheckValueLocale(
          moment
            .unix(post?.created_at || post?.tweet_created_at)
            .utc()
            .format("D MMM, YYYY h:mm A")
            ?.split(" ")[1],
          "",
          {},
          intl,
        ) +
        " " +
        moment
          .unix(post?.created_at || post?.tweet_created_at)
          .utc()
          .format("D MMM, YYYY h:mm A")
          ?.split(" ")[2];
  const localizedTime =
    window.localStorage.lang === "en"
      ? ""
      : moment
          ?.unix(post?.created_at || post?.tweet_created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[4] +
        " " +
        moment
          ?.unix(post?.created_at || post?.tweet_created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[5];

  return (
    <>
      <Box className="full-text-tweet-container" onClick={closeFilter}></Box>
      <Box className="full-text-tweet-popup">
        <Box className="full-text-tweet-header">
          <Box className="full-text-tweet-header-title" component={"h3"}>
            {post?.reply_to
              ? `${CheckValueLocale("reply_to", "", {}, intl)} @${post?.reply_to}`
              : CheckValueLocale("comment_mention", "", {}, intl)}
          </Box>
          <FontAwesomeIcon icon={faTimes} onClick={closeFilter} />
        </Box>
        <Box className="full-text-tweet-body">
          <Box className="full-text-tweet-contant">{post?.tweet_full_text}</Box>
          <Box className="full-text-tweet-footer">
            <Box className="full-text-tweet-statistics-icons">
              {showHeart ? (
                <Box className="tweet-statistic">
                  <FontAwesomeIcon icon={faHeart} />
                  {post?.heart_count || post?.favorite_count || 0}
                </Box>
              ) : null}
              {showRetweet ? (
                <Box className="tweet-statistic">
                  <FontAwesomeIcon icon={faRetweet} />
                  {post?.retweet_count || 0}
                </Box>
              ) : null}

              {ShowComments ? (
                <Box className="tweet-statistic">
                  <FontAwesomeIcon icon={faComments} />
                  {post?.comments_count || 0}
                </Box>
              ) : null}
            </Box>
            {localizedDate && (showHeart || showRetweet || ShowComments) ? (
              <Divider orientation="vertical" className="footer-divider" />
            ) : null}
            <Box className="footer-full-text-tweet-date">
              <Box component={"span"} className="ltr-direction">
                {tweet_date || localizedDate}
              </Box>
              <Box component={"span"} className="ltr-direction">
                {tweet_date ? "" : localizedTime}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FullTextTweetPopUp;
