import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import ProductsPermissions from "./productsPermissions/productsPermissions";

const StepTwo = ({ stepTwoData, setStepTwoData }) => {
  const intl = useIntl();

  return (
    <Box className="main-step-two">
      <Box className="main-step-tit">
        {CheckValueLocale("user_perm_tit", "", {}, intl)}
      </Box>
      <ProductsPermissions
        stepTwoData={stepTwoData}
        setStepTwoData={setStepTwoData}
      />
    </Box>
  );
};
export default StepTwo;
