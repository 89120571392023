import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { TextField, Box, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CheckValueLocale,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SavedRepliesModal = (props) => {
  const intl = useIntl();

  const {
    setText,
    setAnchorEl,
    getAllSavedReplies,
    savedReplies,
    savedRepliesloader,
    setSavedReplyIds,
    setShowDeletePopup,
    setShowCreateRepliesModal,
    setReplyToDelete,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [filteredContent, setFilteredContent] = useState([]);

  const isAdmin =
    (handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ||
      handlelUserRoles("SAVED_REPLIES", "CREATE_SAVED_REPLY")) &&
    retrieveActiveProductsArray()?.includes("ENGAGEMENTS");

  useEffect(() => {
    let ids = [];
    savedReplies?.map((repliesData) => {
      ids?.push(repliesData?.attributes?.id);
    });
    setSavedReplyIds(ids);
  }, [savedReplies]);

  useEffect(() => {
    getAllSavedReplies();
  }, []);

  let filteredData = [];

  useEffect(() => {
    const searchWords = searchText?.toLowerCase()?.split(" ");

    filteredData = savedReplies?.filter((item) => {
      const content = (
        item?.attributes?.title +
        " " +
        item?.attributes?.text
      )?.toLowerCase();
      return searchWords?.every((searchWord) => content?.includes(searchWord));
    });

    setFilteredContent(filteredData);
  }, [searchText, savedReplies]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDeleteReply = (replyToDelete) => {
    setShowDeletePopup(true);
    setReplyToDelete(replyToDelete);
  };

  const handleSavedRepliesClick = (text) => {
    setText(text);
    setAnchorEl(null);
  };

  return (
    <>
      {savedRepliesloader ? (
        <div className="loading-style-saved-replies">
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>
      ) : (
        <Box
          className={
            isAdmin
              ? "saved-replies-modal saved-replies-modal-admin"
              : "saved-replies-modal create-no-access"
          }
        >
          <Box className="saved-replies-heading">
            {CheckValueLocale("saved_replies", "", {}, intl)}
          </Box>
          <TextField
            id="outlined-size-small"
            placeholder={CheckValueLocale("search_replies", "", {}, intl)}
            size="small"
            value={searchText}
            onChange={handleSearchChange}
          />
          {filteredContent?.length > 0 ? (
            <Box className="saved-replies-scroll">
              {filteredContent?.map((content, index) => {
                const { title, text } = content?.attributes;
                return (
                  <>
                    <Box className="display-in-start-saved-replies">
                      <Box onClick={() => handleSavedRepliesClick(text)}>
                        <Box className="saved-replies-content">{title}</Box>
                        <Box className="saved-replies-description">
                          {text?.length > 40
                            ? intl.locale === "en"
                              ? text?.substring(0, 40) + "..."
                              : "..." + text?.substring(0, 40)
                            : text}
                        </Box>
                      </Box>
                      {handlelUserRoles(
                        "SAVED_REPLIES",
                        "DELETE_SAVED_REPLY",
                      ) || handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
                        <span
                          className="saved-replies-delete-span"
                          onClick={() =>
                            handleDeleteReply(filteredContent[index])
                          }
                        >
                          <DeleteIcon className="saved-replies-delete" />
                        </span>
                      ) : null}
                    </Box>
                    {index !== filteredContent?.length - 1 ? <Divider /> : null}
                  </>
                );
              })}
            </Box>
          ) : (
            <Box className="no-result-found-container">
              <Box className="create-replies-textfield-title">
                {CheckValueLocale("no_replies_found", "", {}, intl)}
              </Box>
              <Box className="no-replies-found-description">
                {CheckValueLocale("try_different_replies_again", "", {}, intl)}
              </Box>
            </Box>
          )}
          {isAdmin ? (
            <Box className="pr-18">
              <Box
                className="create-replies-btn"
                onClick={() => setShowCreateRepliesModal(true)}
              >
                {CheckValueLocale("create_replies", "", {}, intl)}
              </Box>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default SavedRepliesModal;
