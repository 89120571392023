import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { faQuestion, faPercent } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import { Box } from "@mui/system";
import BarChartWidget from "components/widgets/barChartWidget";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import DashboardFunctions from "../../../dashboardFunctions";
import { handleAggregateLegends } from "utils/helpers/aggregateFunctions";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import SnackBar from "components/snackBar";
import PopupModal from "components/popupModal";

const CustomerCareBody = (props) => {
  const intl = useIntl();
  const { getIcons } = DashboardFunctions();

  const {
    widgetsName,
    dashboardDynamicData,
    monitorType,
    dashboardID,
    isPublicAccount,
    unixDate,
  } = props;
  const metricsValues = dashboardDynamicData?.metrics_values;

  const { response, resetAllFields } = props;

  const [latestUnanswer, setLatestUnanswer] = useState([]);
  const [latestUnanswerPreloader, setLatestUnanswerPreloader] = useState([]);
  const [customerCarePreloader, setCustomercarePreloader] = useState([]);
  const [unAnsweredQuestion, setUnanswerQuestion] = useState(0);
  const [respRate, setRespRate] = useState(0);
  const [numOfAnsweredQuestion, setNumOfAnsweredQuestion] = useState(0);
  const [customerCareData, setcustomerCareData] = useState([]);

  const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState(0);
  const [avgResponseTimeInSeconds, setAvgResponseTimeInSeconds] = useState(0);
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  useEffect(() => {
    if (resetAllFields !== 1) {
      setLatestUnanswer([]);
      setCustomercarePreloader({});
      setLatestUnanswerPreloader([]);
      setCustomercarePreloader([]);
      setUnanswerQuestion(0);
      setcustomerCareData([]);

      setRespRate(0);
      setNumOfAnsweredQuestion(0);
      setTotalNumberOfQuestions(0);
      setAvgResponseTimeInSeconds(0);
    }
  }, [resetAllFields]);

  useEffect(() => {
    switch (response.eventName) {
      case "Facebook__CustomerCarePage__customer_care_performance":
      case "Instagram__CustomerCarePage__customer_care_performance":
      case "Twitter__CustomerCarePage__customer_care_performance":
        setTotalNumberOfQuestions(
          totalNumberOfQuestions +
            response.eventData?.total_number_of_questions,
        );
        setAvgResponseTimeInSeconds(
          avgResponseTimeInSeconds +
            response.eventData?.avg_response_time_in_seconds,
        );
        setNumOfAnsweredQuestion(
          numOfAnsweredQuestion +
            response.eventData.number_of_answered_questions,
        );
        setCustomercarePreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        response?.eventData?.response_time_segment &&
          Object.keys(response?.eventData?.response_time_segment)?.map(
            (val, index) => {
              handleAggregateLegends(
                [
                  {
                    name: val,
                    value: Object.values(
                      response.eventData?.response_time_segment,
                    )[index],
                  },
                ],
                customerCareData,
              );
            },
          );

        break;
      case "Twitter__CustomerCarePage__latest_unanswered_questions":
      case "Facebook__CustomerCarePage__latest_unanswered_questions":
      case "Instagram__CustomerCarePage__latest_unanswered_questions":
        response.eventData.latest_unanswered_questions?.map((post) => {
          post.datasourceTypePosts = response.data_source;
          setLatestUnanswer((oldArray) => [...oldArray, post]);
        });
        setUnanswerQuestion(
          unAnsweredQuestion +
            response.eventData.number_of_unanswered_questions,
        );
        setLatestUnanswerPreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    totalNumberOfQuestions &&
      setRespRate(numOfAnsweredQuestion / totalNumberOfQuestions);
  }, [numOfAnsweredQuestion, totalNumberOfQuestions]);
  return (
    <Box mt={3}>
      {widgetsName?.includes("customer_care_performance") && (
        <Box className="chart-body">
          <BarChartWidget
            title={"customer_performance"}
            titleToolTip={
              isPublicAccount
                ? "customer_performance_ig_tooltip"
                : "customer_performance_fb_tooltip"
            }
            data={customerCareData}
            showAI={true}
            showDownloadIcon={true}
            showChartStatsWidget={true}
            showZoomPercentage
            customerCareChart
            respTimeDistributionTooltip="respone_time_distribution_tooltip"
            gridHeight={"70%"}
            statsData={[
              {
                name: "total_ques",
                value:
                  totalNumberOfQuestions &&
                  commasAfterDigit(totalNumberOfQuestions),
                icon: faQuestion,
                titleToolTip: "number_of_ques_tooltip",
              },
              {
                name: "number_of_answered_questions",
                value: isNaN(respRate * 100)
                  ? 0 + "%"
                  : respRate.toFixed(2) * 100 + "%",
                icon: faPercent,
                titleToolTip: "response_rate_tooltip",
              },
              {
                name: "avg_response_time_in_seconds",
                value: avgResponseTimeInSeconds,
                icon: faClock,
                type: "time",
                titleToolTip: "avg_response_time_tooltip",
              },
            ]}
            dataColor="#2AAAE2"
            toolTipLabel={"posts"}
            isTwitterAccount={monitorType === "ACCOUNT_ANALYSIS" && true}
            barChartPreLoaderDataSources={customerCarePreloader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.customer_care_performance?.data_sources,
            ]}
            iconAfterTitle={getIcons([
              ...metricsValues?.customer_care_performance?.data_sources,
            ])}
          />
        </Box>
      )}
      {widgetsName?.includes("top_questions") && (
        <>
          <Box className="top-posts-head">
            <Box>
              {CheckValueLocale("latest_unanswered_ques", "", {}, intl)}
            </Box>
            <Link
              to={{
                pathname: `/dashboard/${monitorType}/questions_page/${dashboardID}`,
                state: {},
              }}
              className="top-posts-all"
              id={`check-unanswered-questions-${
                monitorType?.toLowerCase() ?? "monitor-type"
              }-questions-page-${dashboardID ?? "dashboard-id"}`}
            >
              {CheckValueLocale("check_unanswered_ques", "", {}, intl)}(
              {unAnsweredQuestion})
            </Link>
          </Box>

          <QuestionCard
            unixDate={unixDate}
            showProfile={true}
            productId={window.localStorage?.getItem("sm_id")}
            latestUnanswer={latestUnanswer}
            latestUnanswerPreloader={latestUnanswerPreloader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_questions?.data_sources,
            ]}
            showDefaultImg={true}
            className={"no-data-found-question"}
            showScreenName={true}
            showRetweet={true}
            noOfChar={15}
            updateAnalysis={updateAnalysis}
            singleTweetAnalysis={props.singleTweetAnalysis}
            isAggregateDashboard
            isCustomerCareAggreagte
            widget="comments_mentions"
          />
          {showAnalyzeTweetSnackBar && (
            <SnackBar
              open={true}
              handleClose={handleCloseSnackBar}
              title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
              message={CheckValueLocale(
                analyzeTweetSnackBar.message,
                "",
                {},
                intl,
              )}
              severity={analyzeTweetSnackBar.severity}
            />
          )}
          <PopupModal
            title={stats?.modalTitle}
            body={modalBody}
            open={stats?.openCreateModal}
            close={handleCloseCreateModal}
            warning="warning"
            classeName="create-historical-popup"
          />
        </>
      )}
    </Box>
  );
};

export default CustomerCareBody;
