import { Box, MenuItem, Typography } from "@mui/material";
import { AutoCompleteField } from "../autocompleteField/autocompleteField";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

const EmptyListLoading = () => {
  return (
    <Box className="team-and-slas-empty-list-loading">
      <FontAwesomeIcon icon={faSpinner} spin />
    </Box>
  );
};

const AddNewTeamOption = ({ label, onCustomOptionClick, icon }) => {
  const intl = useIntl();

  return (
    <MenuItem
      id="routing-select-team-and-slas-autocomplet-item"
      className="routing-select-team-and-slas-autocomplet-item add-new-team-option"
      onClick={onCustomOptionClick}
    >
      <Typography component="span" className="add-new-team-option-title">
        {CheckValueLocale(label, "", {}, intl)}
      </Typography>
      {icon ? <FontAwesomeIcon icon={icon} /> : null}
    </MenuItem>
  );
};

export const SelectSection = ({
  options,
  handleChange,
  value,
  label,
  placeholder,
  emptyListMessage,
  customOptionLabel,
  onCustomOptionClick,
  icon,
  isLoading,
  onOpenDropdown,
}) => {
  const intl = useIntl();

  return (
    <Box className="routing-select-team-and-slas">
      <Typography className="routing-select-team-and-slas-title">
        {CheckValueLocale(label, "", {}, intl)}
      </Typography>
      <AutoCompleteField
        options={options}
        onChange={handleChange}
        placeholder={CheckValueLocale(placeholder, "", {}, intl)}
        value={value}
        multiple={false}
        onOpen={onOpenDropdown}
        emptyListMessage={
          isLoading ? (
            <EmptyListLoading />
          ) : (
            <Box className="routing-select-team-and-slas-autocomplet-listbox routing-select-team-empty-box">
              <AddNewTeamOption
                label={customOptionLabel}
                onCustomOptionClick={onCustomOptionClick}
                icon={icon}
              />
              <Typography className="empty-list-message">
                {CheckValueLocale(emptyListMessage, "", {}, intl)}
              </Typography>
            </Box>
          )
        }
        className="routing-select-team-and-slas-autocomplet-item"
        groupBy={(option) => option?.typeOf}
        renderGroup={(params) => {
          return (
            <>
              <AddNewTeamOption
                label={customOptionLabel}
                onCustomOptionClick={onCustomOptionClick}
                icon={icon}
              />
              {params?.children}
            </>
          );
        }}
        listboxClassName="routing-select-team-and-slas-autocomplet-listbox"
        paperClassName="routing-select-team-and-slas-autocomplet-paper"
      />
    </Box>
  );
};
