import { useEffect, useState } from "react";
import {
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Box,
  InputLabel,
} from "@mui/material";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import {
  CheckValueLocale,
  handlelUserRoles,
  isEmptyArray,
  truncate,
} from "utils/helpers";
import { isPublicSpecialCharactersOrEmojis } from "../../MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "../../MonitorsShared/SharedFunctions/components/keywordLanguage.js";

const GeneralSection = (props) => {
  const intl = useIntl();
  const {
    monitorDetails,
    setUpdatedParams,
    updatedParams,
    monitorErrors,
    setMonitorErrors,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [monitorName, setMonitorName] = useState(
    monitorDetails?.attributes?.name,
  );
  const [description, setDescription] = useState(
    monitorDetails?.attributes?.description,
  );
  const [companyUsers, setCompanyUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(
    monitorDetails?.attributes?.monitor_creator?.id,
  );
  // _____________________________________________________________
  useEffect(() => {
    setMonitorName(monitorDetails?.attributes?.name);
    setDescription(monitorDetails?.attributes?.description);
    setSelectedUser(monitorDetails?.attributes?.monitor_creator?.id);
    if (monitorDetails?.id) {
      getCompanyUsers();
    }
  }, [monitorDetails]);
  // _____________________________________________________________
  //To get users allowed to own the monitor
  const getCompanyUsers = () => {
    CompaniesController.getUsersAllowedToCreate(
      window.localStorage.sm_id,
      monitorDetails?.id,
    ).then((data) => {
      const users = data?.data?.data?.map((i) => ({
        id: i?.attributes?.id,
        value: `${i?.attributes?.name} ( ${i?.attributes?.email} )`,
      }));
      setCompanyUsers(users);
      setIsLoading(false);
    });
  };
  // _____________________________________________________________

  const handleNameChange = (event) => {
    setMonitorName(event.target.value);
    if (event.target.value.trim() === "") {
      setMonitorErrors({
        ...monitorErrors,
        monitorName: "this_field_required",
      });
    } else if (
      isPublicSpecialCharactersOrEmojis(event.target.value, undefined, true) ||
      removeSpecialCharacters(event.target.value).length === 0
    ) {
      setMonitorErrors({
        ...monitorErrors,
        monitorName: "spam_keywords_rules",
      });
    } else {
      setMonitorErrors({
        ...monitorErrors,
        monitorName: "",
      });
      setUpdatedParams({
        ...updatedParams,
        monitor_name: event.target.value.trim(),
      });
    }
  };
  // _____________________________________________________________

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value); // this for set value of description in input
    if (
      isPublicSpecialCharactersOrEmojis(event.target.value, undefined, true)
    ) {
      setMonitorErrors({
        ...monitorErrors,
        monitorDescription: "spam_keywords_rules",
      });
    } else {
      setMonitorErrors({
        ...monitorErrors,
        monitorDescription: "",
      });
      setUpdatedParams({ ...updatedParams, description: event.target.value }); //[ setUpdatedParams ] is a key that sent to BE by spread this object first and get [Description] value from previous line and UPDATE it .
    }
  };
  // _____________________________________________________________
  //change user id from ownership dropdown
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
    setUpdatedParams({ ...updatedParams, owner_id: event.target?.value });
  };
  return (
    <>
      <Typography className="section-header">
        {CheckValueLocale("general", "", {}, intl)}
      </Typography>
      <Box className="section-box">
        {/*Monitor Name ______________________________________________________________ */}
        <Box className="section-box-data monitor-name-edit">
          <Typography className="main-titles">
            {CheckValueLocale("monitor_name", "", {}, intl)}
          </Typography>
          <TextField
            id="outlined-required"
            variant="outlined"
            label={CheckValueLocale("name", "", {}, intl)}
            value={monitorName}
            onChange={handleNameChange}
            multiline
            disabled={!handlelUserRoles("SM", "EDIT_MONITOR")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>

        {monitorErrors?.monitorName && (
          <Box className="section-box-data">
            <Typography className="main-titles"></Typography>
            <Box component="p" className="spam-text-field err-msg-text">
              {CheckValueLocale(monitorErrors?.monitorName, "", {}, intl)}
            </Box>
          </Box>
        )}
        <Divider />
        {/* Description______________________________________________________________ */}
        <Box className="section-box-data monitor-desc-edit">
          <Typography className="main-titles">
            {CheckValueLocale("description", "", {}, intl)}
          </Typography>
          <TextField
            id="outlined-required"
            label={CheckValueLocale("description", "", {}, intl)}
            value={description}
            multiline
            onChange={handleDescriptionChange}
            disabled={!handlelUserRoles("SM", "EDIT_MONITOR")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        {monitorErrors?.monitorDescription && (
          <Box className="section-box-data">
            <Typography className="main-titles"></Typography>
            <Box component="p" className="spam-text-field err-msg-text">
              {CheckValueLocale(
                monitorErrors?.monitorDescription,
                "",
                {},
                intl,
              )}
            </Box>
          </Box>
        )}
        <Divider />
        {/*Monitor Ownership ______________________________________________________________ */}
        <Box className="section-box-data">
          <Typography className="main-titles">
            {CheckValueLocale("monitor_ownership", "", {}, intl)}
          </Typography>

          {isLoading ? (
            <CircularProgress />
          ) : isEmptyArray(companyUsers) ? (
            <Tooltip
              title={CheckValueLocale("empty_owner_ship_tooltip", "", {}, intl)}
              placement="top"
              arrow
            >
              <Select className="select-owner" disabled={true}></Select>
            </Tooltip>
          ) : (
            <FormControl className="owner-input-field">
              <InputLabel id="demo-multiple-checkbox-label">
                {CheckValueLocale("select_user", "", {}, intl)}
              </InputLabel>
              <Select
                value={selectedUser}
                placeholder={CheckValueLocale("select_user", "", {}, intl)}
                onChange={handleUserChange}
                className="select-owner"
                disabled={
                  !handlelUserRoles("SM", "EDIT_MONITOR") ||
                  isEmptyArray(companyUsers)
                }
              >
                {companyUsers?.map((user, i) => {
                  return (
                    <MenuItem
                      value={user?.id}
                      key={i}
                      className="owner-name-item"
                      id={`monitor-settings-company-users-${user?.id ?? "id"}`}
                    >
                      {truncate(user?.value, 45)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GeneralSection;
