import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  monitorType: "",
  activeMonitorStep: 0,
  selectedDatasources: [0],
  keywordsData: [],
  spamKeywordsData: [],
};

const explorePageSlice = createSlice({
  name: "explorePage",
  initialState,
  reducers: {
    clear: (state) => {
      state.monitorType = "";
      state.activeMonitorStep = 0;
      state.selectedDatasources = [0];
      state.keywordsData = [];
      state.spamKeywordsData = [];
    },
    exploreMonitorsData: (state, action) => {
      state.monitorType = action?.payload?.type;
      state.activeMonitorStep = action?.payload?.activeStep;
      state.selectedDatasources = action?.payload?.datasourcesValues;
      state.keywordsData = action?.payload?.keywordsValues;
      state.spamKeywordsData = action?.payload?.spamKeywordsValues;
    },
  },
});

export default explorePageSlice.reducer;
export const { clear, exploreMonitorsData } = explorePageSlice.actions;
