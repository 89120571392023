import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { FormHelperText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

const FormHelperError = ({ value, icon = null, className = "" }) => {
  const intl = useIntl();
  return value ? (
    <FormHelperText error className={className}>
      {icon && <FontAwesomeIcon icon={faCheckCircle} />}
      {CheckValueLocale(value, "", { limit: 100 }, intl)}
    </FormHelperText>
  ) : null;
};

export default FormHelperError;
