import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useIntl } from "react-intl";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import _ from "lodash";
import "./rateReviewWidget.scss";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { CheckValueLocale } from "utils/helpers";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  width: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#F5B819",
  },
}));

const useStyles = makeStyles((theme) => ({
  customWidth: {
    padding: "13px",
  },
}));

const RateReviewWidget = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  const data = props.rateReviews;
  const rateArray = [5, 4, 3, 2, 1];

  return (
    <>
      {_.isEqual(
        props.rateReviewPreLoaderDataSources.sort(),
        props.preLoaderTrackerDataSources.sort(),
      ) !== true ? (
        <Box>
          <FetchingData />
        </Box>
      ) : Object.keys(data).length === 0 ? (
        <NoDataFound />
      ) : (
        <Box className="rating-review">
          <Box className="display-in-row">
            <Box>
              <Box className="review-value">{data?.rating}</Box>
              <Box className="review-name">
                {CheckValueLocale("out_of", "", {}, intl)}5
              </Box>
            </Box>
            <Box>
              {data?.rating_out_of_10?.map((rate, index) => {
                return (
                  <Box className="display-in-row bottom-space">
                    <Box className="linear-progress-value">
                      {rateArray[index]}
                    </Box>
                    <Tooltip
                      title={
                        <Box className="display-in-row">
                          <Box>{`${rate.toLocaleString()}`}</Box>
                          <Box className="rate-review-tooltip">
                            {data?.review && data?.review !== 0
                              ? `(${Math.round((rate / data?.review) * 100)}%)`
                              : `(0%)`}
                          </Box>
                        </Box>
                      }
                      arrow
                      placement="top"
                      classes={{ tooltip: classes.customWidth }}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          data?.review && data?.review !== 0
                            ? (rate / data?.review) * 100
                            : 0
                        }
                      />
                    </Tooltip>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="display-in-column min-space">
            <Box className="review-value">{data?.review}</Box>
            <Box className="review-name">
              {CheckValueLocale("reviews", "", {}, intl)}
            </Box>
          </Box>
          <Box />
        </Box>
      )}
    </>
  );
};

export default RateReviewWidget;
