import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import SectionTitle from "pages/alerts/components/sectionTitle/sectionTitle";
import AlertStatistics from "pages/alerts/components/alertStatistics/alertStatistics";
import InteractionsList from "components/interactions-list/InteractionsList.js";

const WhatsappAlert = ({
  alertDetailMonitorId,
  response,
  dataSources,
  alertDetailType,
  resetAllFields,
}) => {
  const initialWidgetsState = {
    //header alert widget
    headerAlertWidget: {},
    headerAlertWidgetPreLoaderDataSources: [],
    //posts volume widget
    postsVolume: [],
    postsVolumePreLoader: [],
    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],
    //top posts widget
    topPosts: [],
    topPostsPreLoaderDataSources: [],
    //comments setntiment analysis widget
    commentsSentimentAnalysis: [],
    commentsSentimentAnalysisPreLoaderDataSources: [],
    //dialects & subDialects widget
    dialects: [],
    subDialects: [],
    dialectsSubDialectsPreLoaderDataSources: [],
  };

  const [alertViewWidgets, setAlertViewWidgets] = useState(initialWidgetsState);

  useEffect(() => {
    switch (response?.eventName) {
      case "Whatsapp__NewPostAlert__header_alert_widget":
      case "Whatsapp__NegativePostsAlert__header_alert_widget":
        setAlertViewWidgets({
          ...alertViewWidgets,
          headerAlertWidget: response?.eventData,
          headerAlertWidgetPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Whatsapp__NewPostAlert__volume_overtime":
      case "Whatsapp__NegativePostsAlert__volume_overtime":
        setAlertViewWidgets({
          ...alertViewWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          postsVolumePreLoader: [response?.monitor_id],
        });
        break;
      case "Whatsapp__NewPostAlert__sentiment_analysis":
        setAlertViewWidgets({
          ...alertViewWidgets,
          commentsSentimentAnalysis: response?.eventData?.sentiment_piechart,
          commentsSentimentAnalysisPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Whatsapp__NewPostAlert__top_keywords":
      case "Whatsapp__NegativePostsAlert__top_keywords":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      case "Whatsapp__NewPostAlert__top_posts_comments":
      case "Whatsapp__NegativePostsAlert__top_posts_comments":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topPosts: response?.eventData?.top_posts,
          topPostsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      case "Whatsapp__NewPostAlert__dialects_and_sub_dialects":
      case "Whatsapp__NegativePostsAlert__dialects_and_sub_dialects":
        setAlertViewWidgets({
          ...alertViewWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsSubDialectsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...initialWidgetsState });
    }
  }, [resetAllFields]);

  return (
    <>
      <AlertStatistics
        alertDataSource={"whatsapp"}
        headerAlertWidget={alertViewWidgets?.headerAlertWidget}
        headerAlertWidgetPreLoaderDataSources={
          alertViewWidgets?.headerAlertWidgetPreLoaderDataSources
        }
        preLoaderTrackerDataSources={alertDetailMonitorId}
        isCA
        alertDetailType={alertDetailType}
        isPrivateChannel
      />
      <Box mb={3}>
        <LineChartWidget
          title={"alerts_conversation_overtime"}
          toolTipLabel={"total_interactions"}
          data={alertViewWidgets?.postsVolume}
          showDataZoom={false}
          lineChartPreLoaderDataSources={alertViewWidgets?.postsVolumePreLoader}
          preLoaderTrackerDataSources={alertDetailMonitorId}
          checkSymbolZize
        />
      </Box>
      <Box mb={5} className="new-posts-countiner">
        <SectionTitle sectionTitle="new_conversations" />
        <InteractionsList
          data={alertViewWidgets?.topPosts?.slice(0, 6)}
          dataSources={dataSources}
          latestInteractionsPreLoaderDataSources={
            alertViewWidgets?.topPostsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={alertDetailMonitorId}
        />
      </Box>
      <SectionTitle sectionTitle="metrics" />
      <Grid container spacing={2.5} mt={2} mb={4}>
        {alertDetailType !== "NegativePosts" ? (
          <Grid item md={6}>
            <PieChartWidget
              title={"alert_sentiment_analysis"}
              data={alertViewWidgets?.commentsSentimentAnalysis}
              areaStyle={true}
              showDownloadIcon
              chartType={"sentiment"}
              pieChartPreLoaderDataSources={
                alertViewWidgets?.commentsSentimentAnalysisPreLoaderDataSources
              }
              preLoaderTrackerDataSources={alertDetailMonitorId}
              showAI
              color={["#89BB2A", "#E50035", "#F9A700"]}
            />
          </Grid>
        ) : null}
        <Grid
          item
          md={6}
          className={
            alertDetailType == "NegativePosts" ? "dialects-countainer" : ""
          }
        >
          <PieChartWidget
            title={"dialects_subdialects"}
            data={alertViewWidgets?.dialects}
            subData={alertViewWidgets?.subDialects}
            areaStyle={true}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={
              alertViewWidgets?.dialectsSubDialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
          />
        </Grid>
        {alertDetailType == "NegativePosts" ? (
          <Grid item md={6}>
            <TableWidget
              wordsList
              title={"alert_top_keywords"}
              leftColomnTitle={"keywords_tab_title"}
              rightColomnTitle={"number_of_posts"}
              data={alertViewWidgets?.topKeywords}
              errorMessage={"no_keywords_found"}
              showDownloadIcon
              bigImg
              keywords
              tablePagination
              count={alertViewWidgets?.topKeywords?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topKeywordsPreLoaderDataSources
              }
            />
          </Grid>
        ) : null}
      </Grid>
      {alertDetailType !== "NegativePosts" ? (
        <Grid container spacing={2.5} mt={4} mb={4}>
          <Grid item md={6}>
            <TableWidget
              wordsList
              title={"alert_top_keywords"}
              leftColomnTitle={"keywords_tab_title"}
              rightColomnTitle={"number_of_posts"}
              data={alertViewWidgets?.topKeywords}
              errorMessage={"no_keywords_found"}
              showDownloadIcon
              bigImg
              keywords
              tablePagination
              count={alertViewWidgets?.topKeywords?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topKeywordsPreLoaderDataSources
              }
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
export default WhatsappAlert;
