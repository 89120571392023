import React from "react";
import { Box, Typography } from "@mui/material";
import WidgetHeader from "components/widgets/widgetHeader";
import "../deepInsights.scss";
import NoDataFound from "components/no-Data/noDataFound";
import { useIntl } from "react-intl";
import personalityData from "./personalityData";
import { CheckValueLocale } from "utils/helpers";

/*---------------------------------------------------------*/

const DeepInsightsPersonality = ({ personalityName }) => {
  const intl = useIntl();
  /*---------------------------------------------------------*/

  let personality;
  if (personalityName.length > 0) {
    for (var key in personalityData) {
      if (key == personalityName[0].toString()) {
        personality = personalityData[key];
      }
    }
  }
  return (
    <Box className="deep-insight-wrapper min-height" id="personality">
      <WidgetHeader
        title="personality"
        chartId="personality"
        showAI
        bigImg
        showDownloadIcon
      />
      {personalityName.length === 0 ? (
        <NoDataFound />
      ) : (
        <Box className="deep-insight-content personality">
          {window.localStorage.lang == "ar"
            ? personality.description_ar
            : personality.description}
          <Box className="deep-section-head">
            {CheckValueLocale("strengths", "", {}, intl)}
          </Box>
          <ul className="deep-content-details">
            {window.localStorage.lang == "ar"
              ? personality.strength_ar.map((item, index) => {
                  return <li>{item} </li>;
                })
              : personality.strength.map((item, index) => {
                  return <li>{item} </li>;
                })}
          </ul>
          <Box className="deep-section-head">
            {CheckValueLocale("weakness", "", {}, intl)}
          </Box>
          <ul className="deep-content-details">
            {window.localStorage.lang == "ar"
              ? personality.weakness_ar.map((item, index) => {
                  return <li>{item} </li>;
                })
              : personality.weakness.map((item, index) => {
                  return <li>{item} </li>;
                })}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default DeepInsightsPersonality;
