import Benchmark from "../api/Benchmark.js";

var BenchmarkController = {};

BenchmarkController.getBenchmarkTypes = function () {
  return new Promise(function (resolve, reject) {
    Benchmark.getBenchmarkTypes()
      .then(function (response) {
        resolve({
          data: response?.data,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

BenchmarkController.getMonitorBenchmark = function (
  start_date,
  end_date,
  monitor_list,
  monitor_type,
  benchmark_type,
  product_id,
  filters,
) {
  var queryData = {
    start_date,
    end_date,
    monitor_list,
    monitor_type,
    benchmark_type,
    product_id,
    filters,
  };

  if (monitor_list !== "" && monitor_list !== undefined) {
    queryData.monitor_list = JSON.stringify(monitor_list);
  }
  if (filters !== "" && filters !== undefined) {
    queryData.filters = JSON.stringify(filters);
  }

  return new Promise(function (resolve, reject) {
    Benchmark.getMonitorsBenchmark(queryData)
      .then(function (response) {
        resolve({
          result: response?.data?.data,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

BenchmarkController.getAllMonitors = (
  product_id,
  data_sources,
  monitor_type_name,
) => {
  var queryData = {
    product_id,
    data_sources,
    monitor_type_name,
  };
  return new Promise(function (resolve) {
    Benchmark.getAllMonitors(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

BenchmarkController.getExportBenchmark = (queryData) => {
  return new Promise(function (resolve) {
    Benchmark.getExportBenchmark(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

BenchmarkController.getBenchmarkInformal = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Benchmark.getBenchmarkInformal(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

BenchmarkController.getPolicy = (policy_type) => {
  return new Promise(function (resolve) {
    Benchmark.getPolicy(policy_type)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
BenchmarkController.getApplyPolicy = () => {
  return new Promise(function (resolve) {
    Benchmark.getApplyPolicy()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default BenchmarkController;
