import { useIntl } from "react-intl";
import { Box, Divider, Typography } from "@mui/material";
import ThemesSubThemes from "../components/themesSubThemes";
import "./userProfileWidgets.scss";
import userImage from "../../../images/userDefualt.png";
import { CheckValueLocale } from "utils/helpers";

const UserProfileWidgets = (props) => {
  const intl = useIntl();
  let { userProfileData } = props;
  const userDetails = userProfileData?.data?.basic_info;

  return (
    <Box className="interaction-page-list main-audience audience-shadow">
      <Box className="aduience-widget-header">
        <Box className="audience-widget-image">
          <img
            src={
              userProfileData?.data?.profile_image_url
                ? userProfileData?.data?.profile_image_url
                : userImage
            }
          />
        </Box>
        <Box>
          <Typography className="audience-widget-name">
            {userProfileData?.data?.name
              ? userProfileData?.data?.name
              : CheckValueLocale("no_name", "", {}, intl)}
          </Typography>
        </Box>
      </Box>
      <Box className="interaction-list__footer audience-body" component="div">
        <ThemesSubThemes userDetails={userDetails} />
        <Box>
          <Typography className="wedget-title">
            {CheckValueLocale("dialects", "", {}, intl)}:
          </Typography>
          <Typography className="wedget-subtitle">
            {CheckValueLocale(userDetails?.dialect, "none", {}, intl)}
          </Typography>
          <Typography className="widget-location wedget-subtitle">
            {userDetails?.sub_dialect &&
              "(" +
                CheckValueLocale(userDetails?.sub_dialect, "", {}, intl) +
                ")"}
          </Typography>
        </Box>
        <Divider orientation="vertical" className="vertical-line-list-header" />
        <Box>
          <Typography className="wedget-title">
            {CheckValueLocale("gender", "", {}, intl)}:
          </Typography>
          <Typography className="wedget-subtitle">
            {CheckValueLocale(userDetails?.gender, "none", {}, intl)}
          </Typography>
        </Box>
        <Divider orientation="vertical" className="vertical-line-list-header" />
        <Box>
          <Typography className="wedget-title">
            {CheckValueLocale("location", "", {}, intl)}:
          </Typography>
          <Typography className="wedget-subtitle">
            {CheckValueLocale(userDetails?.location.name, "none", {}, intl)}
          </Typography>
          <Typography className="widget-location wedget-subtitle">
            {userDetails?.location.city &&
              "(" +
                CheckValueLocale(userDetails?.location.city, "", {}, intl) +
                ")"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfileWidgets;
