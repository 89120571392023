import { Box, Divider, Grid, Typography } from "@mui/material";
import LucButton from "./lucButton";

import "./guidelinesButtons.scss";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { Add } from "@mui/icons-material";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function GuidelinesButtons() {
  const intl = useIntl();

  const RowButtons = ({
    nameRow,
    size = "large", // default value large
    type = "primary", // default value primary
    disable = false, // default value false
    loading = false, // default value false
    variant = "filled", // default value filled(containted)
  }) => {
    return (
      <Grid container spacing={1} my={2} columns={15}>
        <Grid xs={3}>{nameRow}</Grid>
        <Grid xs={3}>
          <LucButton
            onClick={() => console.log("testClick")}
            id="testId"
            disabled={disable}
            loading={loading}
            variant={variant}
            size={size}
            type={type}
          >
            {CheckValueLocale("action", "", {}, intl)}
          </LucButton>
        </Grid>
        <Grid xs={3}>
          <LucButton
            disabled={disable}
            loading={loading}
            variant={variant}
            size={size}
            type={type}
            startIcon={<Add />}
          >
            {CheckValueLocale("action", "", {}, intl)}
          </LucButton>
        </Grid>
        <Grid xs={3}>
          <LucButton
            disabled={disable}
            loading={loading}
            variant={variant}
            size={size}
            type={type}
            endIcon={<Add />}
          >
            {CheckValueLocale("action", "", {}, intl)}
          </LucButton>
        </Grid>
        <Grid xs={3}>
          <LucButton
            disabled={disable}
            loading={loading}
            variant={variant}
            size={size}
            type={type}
            startIcon={<FontAwesomeIcon icon={faFilter} />}
            endIcon={<Add />}
          >
            {CheckValueLocale("action", "", {}, intl)}
          </LucButton>
        </Grid>
      </Grid>
    );
  };

  // const cateogryButtons = (nameHeader, size = "large", type = "primary") => {
  const CateogryButtons = ({
    nameHeader,
    size = "large",
    type = "primary",
  }) => {
    return (
      <Box container width="48%">
        <Typography variant="h3" mb={4} className="header-Buttons">
          {nameHeader}
        </Typography>

        <Grid container spacing={1} columns={15} my={2}>
          <Grid xs={3}></Grid>
          <Grid xs={3}>
            <Typography variant="p">No icon</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography variant="p">Left icon</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography variant="p">Right icon</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography variant="p">Special</Typography>
          </Grid>
        </Grid>

        {type !== "secondary" && (
          <RowButtons nameRow={"Filled, default"} size={size} type={type} />
        )}
        {type !== "secondary" && (
          <RowButtons
            nameRow={"Filled, Disable"}
            size={size}
            type={type}
            disable={true}
          />
        )}
        {type !== "secondary" && (
          <RowButtons
            nameRow={"Filled, default"}
            size={size}
            type={type}
            loading={true}
          />
        )}

        {type !== "secondary" && <Divider>Outline</Divider>}
        <RowButtons
          nameRow={"Outline, default"}
          size={size}
          type={type}
          variant={"outline"}
        />
        <RowButtons
          nameRow={"Outline, Disable"}
          size={size}
          type={type}
          disable={true}
          variant={"outline"}
        />
        <RowButtons
          nameRow={"Outline, default"}
          size={size}
          type={type}
          loading={true}
          variant={"outline"}
        />

        <Divider>Flat</Divider>
        <RowButtons
          nameRow={"Flat, default"}
          size={size}
          type={type}
          variant={"flat"}
        />
        <RowButtons
          nameRow={"Flat, default"}
          size={size}
          type={type}
          disable
          variant={"flat"}
        />
      </Box>
    );
  };

  return (
    <Box className="guidelines-Buttons">
      {/*  Primary - large */}
      {/* {cateogryButtons(" Primary - large")} */}
      <CateogryButtons nameHeader={" Primary - large"} />

      {/* Primary - small */}
      {/* {cateogryButtons(" Primary - small", "small")} */}
      <CateogryButtons nameHeader={" Primary - small"} size={"small"} />

      {/* Secondary - Large */}
      {/* {cateogryButtons("Secondary - large", "large", "secondary")} */}
      <CateogryButtons nameHeader={" Secondary - large"} type={"secondary"} />

      {/* Secondary - small */}
      {/* {cateogryButtons("Secondary - small", "small", "secondary")} */}
      <CateogryButtons
        nameHeader={" Secondary - small"}
        size={"small"}
        type={"secondary"}
      />

      {/* Red - Large */}
      {/* {cateogryButtons("Red - Large", "large", "red")} */}
      <CateogryButtons nameHeader={" Red - large"} type={"red"} />

      {/* Red - small */}
      {/* {cateogryButtons("Red - small", "small", "red")} */}
      <CateogryButtons
        nameHeader={" Red - small"}
        size={"small"}
        type={"red"}
      />
    </Box>
  );
}

export default GuidelinesButtons;
