import { Box } from "@mui/material";
import profileImg from "images/engagements-images/profileImg.png";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const Anonymous = () => {
  const intl = useIntl();

  return (
    <Box className="details-side-bar-profile">
      <img src={profileImg} className="profile-img" />
      <Box className="profile-user-box">
        <p className="profile-side-bar-name">
          {" "}
          {CheckValueLocale("unknown_profile", "", {}, intl)}{" "}
        </p>
      </Box>
      <p className="profile-no-data">
        {CheckValueLocale("profile_has_no_data", "", {}, intl)}
      </p>
    </Box>
  );
};
export default Anonymous;
