import AccountBody from "./accountBody";
import { MonitorContainer } from "../../../../Monitors/Components/sidebar/monitor.styles";

const AccountPage = (props) => {
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <AccountBody {...props} />
    </MonitorContainer>
  );
};

export default AccountPage;
