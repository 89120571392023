import { CheckValueLocale } from "utils/helpers";

const createMonitorModel = (selectedAccount, monitorType) => {
  return {
    name:
      selectedAccount.attributes.name ||
      (monitorType?.attributes.data_source_id === 9
        ? selectedAccount.attributes.phone_number
        : selectedAccount.attributes.user_name),
    description: "",
    monitor_type_id:
      monitorType?.attributes.data_source_id === 9
        ? 5
        : monitorType?.attributes.monitor_type_id,
    product_id: window.localStorage.cxm_id,
  };
};
const createMonitorOptions = (
  monitorType,
  privateChannel,
  activeDataSourceName,
  selectedAccount,
  socialMediaDataSource,
  formik,
  monitorData,
) => {
  const baseOptions = {
    [monitorType.attributes.monitor_type_name]: {
      [activeDataSourceName]: {
        id: selectedAccount.attributes.id,
        spam_keywords: "",
        excluded_accounts:
          activeDataSourceName === "TWITTER" &&
          Array.isArray(monitorData?.ignoreTweets)
            ? monitorData?.ignoreTweets?.join(",")
            : undefined,
      },
    },
  };

  if (monitorType.attributes.data_source_id === 9) {
    baseOptions.MANAGED_ACCOUNT = {
      [activeDataSourceName]: {
        id: selectedAccount.attributes.id,
        spam_keywords: "",
      },
    };
  }

  if (socialMediaDataSource) {
    baseOptions.IS_PRIVATE_CHANNEL = formik.values.IS_PRIVATE_CHANNEL;
  }

  if (privateChannel && socialMediaDataSource) {
    baseOptions.DM_CONFIGURATION = {
      is_bot_enabled: formik.values.isBot,
      bot_to_human_text: formik.values.botMessageArray.join(","),
      client_conversation_end: formik.values.clientConversationEnd,
      agent_conversation_end: formik.values.agentConversationEnd,
      agent_end_msg: formik.values.agentEndMsg.join(","),
    };
  }
  return baseOptions;
};

const handleSuccessErrorCreateChannel = (
  res,
  onSuccess,
  onError,
  accountName,
  intl,
) => {
  const responseMessage = res?.errorMsg?.response?.data?.exception?.message;
  const responseStatus = res?.errorMsg?.response?.status;

  if (res?.data?.status === 204 || res?.data?.status === 200) {
    onSuccess();
    return;
  }

  if (
    responseStatus === 400 &&
    responseMessage === "WRONG_REQUEST_PARAMETERS"
  ) {
    onError(responseMessage);
  } else if (
    responseStatus === 400 &&
    responseMessage === "SOMETHINGS_WENT_WRONG_PLEASE_TRY_AGAIN_LATER"
  ) {
    onError(responseMessage);
  } else if (
    responseStatus === 400 &&
    responseMessage === "MISSING_REQUIRED_PARAMETERS"
  ) {
    onError(responseMessage);
  } else if (responseStatus === 404 && responseMessage === "RECORD_NOT_FOUND") {
    onError(responseMessage);
  } else if (
    responseStatus === 400 &&
    responseMessage === "A channel for this account already existed"
  ) {
    onError(
      `${CheckValueLocale("A channel for this account already existed", "", { accountName: accountName }, intl)}`,
    );
  }
};

const addChannelFormikInitialValues = {
  botMessageArray: [],
  isBot: 0,
  IS_PRIVATE_CHANNEL: false,
  clientConversationEnd: 0,
  agentConversationEnd: 0,
  agentEndMsg: [],
};

const addChannelSocialAccounts = [
  "TWITTER",
  "FACEBOOK",
  "INSTAGRAM",
  "LINKEDIN",
];
const addChannelInitialSteps = ["choose_category", "setup_channel"];
const addChannelSocialSteps = [
  "choose_category",
  "setup_channel",
  "bot_configuration",
  "session_configuration",
];

const selectedAccountInitialData = {
  id: "",
  type: "",
  item: {},
  attributes: {
    used_in: 0,
    name: "",
    user_name: "",
    image_url: "",
    used_in_public_channel: 0,
    used_in_private_channel: 0,
    id_public_2: "",
    id_public: 0,
    id_private: 0,
  },
};

export {
  createMonitorOptions,
  createMonitorModel,
  handleSuccessErrorCreateChannel,
  addChannelFormikInitialValues,
  addChannelSocialAccounts,
  addChannelInitialSteps,
  addChannelSocialSteps,
  selectedAccountInitialData,
};
