import React from "react";
import { useIntl } from "react-intl";
import { Box, LinearProgress, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

const SettingsHeader = (props) => {
  const { loading, isSetup, handleCloseSettings, stepNum } = props;
  const intl = useIntl();

  return (
    <Box className={`settings-header ${!isSetup && `header-border`}`}>
      <Box className="title-container">
        <Typography className="settings-title">
          {!loading
            ? CheckValueLocale(
                isSetup ? "luci_setup" : "luci_settings",
                "",
                {},
                intl,
              )
            : null}
        </Typography>
        <LucButton
          id="settings-close-btn"
          type="secondary"
          variant="flat"
          minWidth={42}
          onClick={handleCloseSettings}
        >
          <FontAwesomeIcon icon={faTimes} />
        </LucButton>
      </Box>
      {!loading && isSetup ? (
        <LinearProgress
          className="settings-progress"
          variant="determinate"
          value={(stepNum / 4) * 100}
        />
      ) : null}
    </Box>
  );
};

export default SettingsHeader;
