import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const EditReportHeader = () => {
  const intl = useIntl();

  return (
    <Box className="edit-report-header">
      <Typography variant="h6">
        {CheckValueLocale("edit_report", "", {}, intl)}
      </Typography>
      <Box>
        <Typography variant="subtitle2">
          {CheckValueLocale("edit_report_text1", "", {}, intl)}:
        </Typography>
        <Typography variant="body2">
          {CheckValueLocale("edit_report_text2", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export default EditReportHeader;
