import { useIntl } from "react-intl";
import WidgetHeader from "../widgetHeader";
import { Box } from "@mui/system";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import NoDataFound from "components/no-Data/noDataFound";
import ChartStatsWidget from "../customStatisctics/chartStatsWidget";

const StatisticsWidget = (props) => {
  const intl = useIntl();

  return (
    <div className="chart agent-performance" id={props.chartId}>
      <WidgetHeader
        title={props.title}
        showDownloadIcon
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        chartId={props.chartId}
        bigImg={props.bigImg}
      />
      {_.isEqual(
        props.statisticsPreLoaderDataSources?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <Box>
          <FetchingData />
        </Box>
      ) : (
        <>
          <ChartStatsWidget
            title={props.title}
            statsData={props.statsData}
            toolTipPosition={props?.toolTipPosition}
            tooltipID={props?.tooltipID}
            containerClassName={props?.containerClassName}
          />
        </>
      )}
    </div>
  );
};
export default StatisticsWidget;
