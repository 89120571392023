import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Checkbox, Menu, MenuItem, Tooltip } from "@mui/material";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LucButton from "shared/lucButton/lucButton";

const CategoriesMenu = (props) => {
  const {
    btnId,
    tooltipText,
    options,
    optionsLoading,
    selectedCategories,
    handleChange,
    loading,
    disabled,
  } = props;

  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (e) => setAnchorEl(e?.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const handleMenuItemClick = (category) => {
    const updatedCategories = selectedCategories?.includes(category)
      ? selectedCategories?.filter((item) => item !== category)
      : [...selectedCategories, category];

    if (!updatedCategories?.length) return;

    handleChange(updatedCategories);
  };

  return (
    <Box>
      <Tooltip
        title={CheckValueLocale(
          tooltipText && !open ? tooltipText : "",
          "",
          {},
          intl,
        )}
        placement="top"
        arrow
      >
        <Box>
          <LucButton
            id={btnId}
            className="categories-select-dropdown-btn"
            type="secondary"
            variant="flat"
            size="small"
            minWidth={60}
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleOpenMenu}
            loading={loading}
            endIcon={<FontAwesomeIcon icon={faCaretDown} fixedWidth />}
            disabled={disabled}
          >
            <Box className="themes-box">
              <Box component="span">
                {CheckValueLocale(
                  selectedCategories?.[0] || "select_category",
                  "",
                  {},
                  intl,
                )}
              </Box>
              <Box className="themes-selected-item">
                {selectedCategories?.length > 1 ? (
                  <Box className="themes-selected-count">{`+${
                    selectedCategories?.length - 1
                  }`}</Box>
                ) : null}
              </Box>
            </Box>
          </LucButton>
        </Box>
      </Tooltip>
      <Menu
        id="categories-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale !== "ar" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale !== "ar" ? "left" : "right",
        }}
      >
        {options?.map((category, index) => {
          return (
            <MenuItem
              key={index}
              id={`category-select-menu-item-${category}`}
              className={`categories-menu-item`}
              onClick={() => handleMenuItemClick(category)}
            >
              <Checkbox checked={selectedCategories?.includes(category)} />
              {CheckValueLocale(category, "", {}, intl)}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default CategoriesMenu;
