import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Container } from "@mui/material";

const SmartTagsIndex = (props) => {
  const intl = useIntl();

  return (
    <div id="smart_tags">
      <Container>
        <h1>Coming Soon</h1>
      </Container>
    </div>
  );
};
export default SmartTagsIndex;
