import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import FormTitle from "./components/formTitle";
import FormDescription from "./components/formDescription";
import FormField from "./components/formField";
import FormButton from "./components/formButton";
import { CheckValueLocale } from "utils/helpers";
import FormErrorPassword from "./components/formErrorPassword";
import { useParams } from "react-router-dom";

const FormResetPassword = ({
  stats,
  errors,
  handleInputChange,
  errorFields,
  handleUserResetPassword,
}) => {
  const intl = useIntl();
  const urlParams = useParams();
  const token = urlParams?.token;

  return (
    <Box className="form-reset-wrapper">
      <Box className="form-reset-text-box">
        <FormTitle text={CheckValueLocale("reset_password", "", {}, intl)} />
      </Box>
      <FormField
        name="password"
        label={CheckValueLocale("new_password", "", {}, intl)}
        placeholder={CheckValueLocale("type_new_password", "", {}, intl)}
        type="password"
        onChange={handleInputChange}
        error={stats?.wrongInput || errorFields?.password}
        errorField={errorFields?.password}
      />
      <FormErrorPassword
        stats={stats}
        errors={errors}
        errorField={errorFields?.errorsPassword}
      />
      <FormField
        name="confirmPassword"
        label={CheckValueLocale("confirm_new_password", "", {}, intl)}
        placeholder={CheckValueLocale("enter_new_password", "", {}, intl)}
        type="password"
        onChange={handleInputChange}
        error={stats?.wrongInput || errorFields?.confirmPassword}
        errorField={errorFields?.confirmPassword}
      />
      {stats?.wrongInput ? (
        <Box component="p" className="error-text-msg main-error">
          {" "}
          {stats?.errorText}{" "}
        </Box>
      ) : null}
      <FormButton
        text={CheckValueLocale("set_new_password", "", {}, intl)}
        variant="filled"
        onClick={() => handleUserResetPassword(token)}
        loading={stats?.resetPasswordLoading}
      />
      <Box component="p" className="account-text">
        {CheckValueLocale("already_have_account", "", {}, intl)}
        <span
          onClick={() => {
            window.location.href = "/login";
          }}
        >
          {" "}
          {CheckValueLocale("back_to_login", "", {}, intl)}{" "}
        </span>
      </Box>
    </Box>
  );
};
export default FormResetPassword;
