import { CheckValueLocale, monitorErrorsNames } from "utils/helpers";
import { RenderChannelSocialIcon } from "./RenderChannelSocialIcon";
import { StatusBodyCell } from "./StatusBodyCell";
import { AccountBodyCell } from "./AccountBodyCell";
import { useIntl } from "react-intl";
import "../manageChannels.scss";

export const renderChannelsTabBody = ({ channelsData, optionsCell }) => {
  const intl = useIntl();
  return channelsData?.data?.map((channel, index) => {
    return {
      id: index,
      className:
        channel?.attributes?.account_error?.length ||
        channel?.attributes?.account_valid_error?.length || // reauth error
        channel?.attributes?.[monitorErrorsNames.loginErr]?.length ||
        channel?.attributes?.[monitorErrorsNames.permissionErr]?.length
          ? "warning"
          : "",
      data: [
        {
          body: <AccountBodyCell channel={channel} />,
          className: "manage-account-name",
        },
        {
          body: channel?.attributes?.channel?.length ? (
            <RenderChannelSocialIcon channel={channel} />
          ) : (
            "—"
          ),
        },
        {
          body: channel?.attributes?.data_category?.length
            ? CheckValueLocale(
                channel?.attributes?.data_category?.toLowerCase(),
                "",
                {},
                intl,
              )
            : "—",
        },
        {
          body: channel?.attributes?.added_by?.trim()?.length
            ? channel?.attributes?.added_by
            : "—",
          isCreatedBy: true,
        },
        {
          body: channel?.attributes?.authenticated_by?.trim()?.length
            ? channel?.attributes?.authenticated_by
            : "—",
          isCreatedBy: true,
        },
        {
          body: channel?.attributes?.stream_status?.length ? (
            <StatusBodyCell channel={channel} />
          ) : (
            "—"
          ),
        },
        {
          ...optionsCell(channel),
        },
        // {
        //     body: optionsCell(channel),
        //     permission: canShowActionTap,
        //   },
      ],
    };
  });
};
