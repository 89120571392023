import { useIntl } from "react-intl";

// Dummy image
import dummyImage from "images/shared-images/dummy-image-for-media.svg";

// Fontawesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import { CheckValueLocale } from "utils/helpers";
import { Tooltip, Box } from "@mui/material";
import CustomizedTooltip from "components/customizedTooltip";

const MediaItem = (props) => {
  const intl = useIntl();

  const handleImageError = (event) => {
    event.target.src = dummyImage;
  };

  return (
    <div className="top-media__item mediaitem-wrapper">
      <div className="top-media__image">
        <img
          src={props.media.data}
          alt=""
          className="img-fluid"
          onError={handleImageError}
        />
      </div>
      <CustomizedTooltip
        title={
          <Box className="tooltip-custom-new">
            {CheckValueLocale("external_media_tooltip", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <a
          href={props?.media?.id}
          className="top-media__link"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </CustomizedTooltip>
      <div className="top-media__text">
        <div>
          <span>{props.media.stats_count}</span>
          {CheckValueLocale("posts", "", {}, intl)}
        </div>
        <div className="top-media__icon">
          <img src={xPlatform} alt="x-platform-logo" />
        </div>
      </div>
    </div>
  );
};

export default MediaItem;
