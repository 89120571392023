import { Box } from "@mui/material";
import DataSourceContent from "./dataSourceContent";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const InstagramDataSource = ({
  getSocialIcon,
  resetAllFields,
  response,
  preLoaderTrackerDataSources,
  unixDate,
  singleTweetAnalysis,
  instagramWidgetTypes,
  setInstagramWidgetTypes,
  sortWidgets,
  getNameKeyWidget,
  showEditBtn,
  setTotalStatisticWidgets,
  setLoadingStatisticWidgets,
  themesOptions,
}) => {
  const intl = useIntl();
  return (
    <>
      <Box className="live-dashboard-data-source">
        <Box className="data-source-heading">
          {CheckValueLocale("INSTAGRAM", "", {}, intl)}
          {getSocialIcon("INSTAGRAM")}
        </Box>
        <DataSourceContent
          resetAllFields={resetAllFields}
          response={response}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          unixDate={unixDate}
          singleTweetAnalysis={singleTweetAnalysis}
          instagramWidgetTypes={instagramWidgetTypes}
          setInstagramWidgetTypes={setInstagramWidgetTypes}
          sortWidgets={sortWidgets}
          getNameKeyWidget={getNameKeyWidget}
          showEditBtn={showEditBtn}
          setTotalStatisticWidgets={setTotalStatisticWidgets}
          setLoadingStatisticWidgets={setLoadingStatisticWidgets}
          themesOptions={themesOptions}
        />
      </Box>
    </>
  );
};
export default InstagramDataSource;
