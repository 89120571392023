import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, emailValidator } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import GmailIcon from "images/social-icons/gmail.svg";
import InstagramIcon from "images/social-icons/ig-profile-icon.svg";
import {
  faFacebook,
  faIntercom,
  faWhatsapp,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import xPlatform from "images/social-icons/x-platform-icon.svg";

const SocialMedia = ({ socialMediaData, socialMediaArray }) => {
  const intl = useIntl();

  const getSocialIcon = (type) => {
    const SocialIcons = {
      fb: (
        <Box className={"facebook-icon-profile profile-social-icon"}>
          <FontAwesomeIcon icon={faFacebook} />
        </Box>
      ),
      fb_dm: (
        <Box className={"facebook-icon-profile profile-social-icon"}>
          <FontAwesomeIcon icon={faFacebook} />
        </Box>
      ),
      tw: (
        <Box className={"twitter-icon-profile profile-social-icon"}>
          <img src={xPlatform} alt="x-platform-logo" />
        </Box>
      ),
      tw_dm: (
        <Box className={"twitter-icon-profile profile-social-icon"}>
          <img src={xPlatform} alt="x-platform-logo" />
        </Box>
      ),
      ig: (
        <Box className={"instagram-icon-profile profile-social-icon"}>
          <img src={InstagramIcon} />
        </Box>
      ),
      ig_dm: (
        <Box className={"instagram-icon-profile profile-social-icon"}>
          <img src={InstagramIcon} />
        </Box>
      ),
      intercom: (
        <Box className={"intercom-icon-profile profile-social-icon"}>
          <FontAwesomeIcon icon={faIntercom} />
        </Box>
      ),
      whatsapp: (
        <Box className={"whatsapp-icon-profile profile-social-icon"}>
          <FontAwesomeIcon icon={faWhatsapp} />
        </Box>
      ),
      gmail: (
        <Box className={"gmail-icon-profile profile-social-icon"}>
          <img src={GmailIcon} />
        </Box>
      ),
    };
    return SocialIcons[type];
  };

  const getSocialLink = (type, name) => {
    const SocialIcons = {
      fb: "https://www.facebook.com/" + name,
      fb_dm: "https://www.facebook.com/" + name,
      tw: "https://twitter.com/" + name,
      tw_dm: "https://twitter.com/" + name,
      ig: "https://www.instagram.com/" + name,
      ig_dm: "https://www.instagram.com/" + name,
      intercom: emailValidator(name) ? "mailto:" + name : "tel:" + name,
      whatsapp: "https://api.whatsapp.com/send?phone=" + name?.replace("+", ""),
      gmail: "mailto:" + name,
    };
    return SocialIcons[type];
  };

  return (
    <Box className="profile-side-bar-social">
      <h4 className="content-side-bar-heading">
        {CheckValueLocale("social_media_profile", "", {}, intl)}:
      </h4>
      {socialMediaArray?.length > 0 ? (
        socialMediaArray?.map((social, i) => (
          <Box className="profile-social-box" key={i}>
            <Box className="social-box">
              {getSocialIcon(social)}
              <p className="social-text"> {socialMediaData[social]} </p>
            </Box>
            <FontAwesomeIcon
              className="social-link"
              onClick={() =>
                window.open(
                  getSocialLink(social, socialMediaData[social]),
                  "_blank",
                )
              }
              icon={faExternalLink}
            />
          </Box>
        ))
      ) : (
        <p className="profile-side-bar-content-empty social-content-empty">
          {CheckValueLocale("no_social_media_found", "", {}, intl)}
        </p>
      )}
    </Box>
  );
};
export default SocialMedia;
