import { useIntl } from "react-intl";
import { Typography, Box, Grid } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import OverView from "components/widgets/overViewWidget";
import BarChartWidget from "components/widgets/barChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import MediaInstagram from "components/media/media-insta/mediaInsta";
import PunchCardWidget from "components/widgets/punchCardWidget";
import { CheckValueLocale } from "utils/helpers";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends.js";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import PieChartWidget from "components/widgets/pieChartWidget";

const InstagramEngagementBody = (props) => {
  const { instagramWidgets, chartStatsData, commentsInteractionsStats } = props;
  const intl = useIntl();

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale(
          "engagement_overview_title",
          "",
          {},
          intl,
        ).toUpperCase()}
      </Typography>
      <Box mt={3}>
        <LineChartWidget
          title={"comments_volume"}
          titleToolTip={"comments_volume_ig_tooltip"}
          data={instagramWidgets?.commentsVolume}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/social-media/instagram-public/"}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          iconAfterTitle={props?.instagramIcon}
          lineChartPreLoaderDataSources={
            instagramWidgets?.commentsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis"}
          titleToolTip={"comments_sentiment_analysis_tooltip"}
          data={instagramWidgets?.sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            instagramWidgets?.sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props?.instagramIcon}
          showGroupByClientAgent={true}
          showScales
          dataPiechart={instagramWidgets?.sentimentPieChart}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysisShowCharts
        />
      </Box>
      <Box mt={3}>
        <ThemeAnalysis
          title={"monitor_theme_title"}
          titleToolTip="themes_tooltip"
          data={instagramWidgets?.themeAnalysis}
          themeAnalysisPreLoaderDataSources={
            instagramWidgets?.themeAnalysisPreLoaderDataSources
          }
          iconAfterTitle={props?.instagramIcon}
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"monitor_theme_trend_title"}
          titleToolTip="themes_trend_tooltip"
          data={instagramWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          iconAfterTitle={props?.instagramIcon}
          themeTrendPreLoaderDataSources={
            instagramWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"comments_interactions"}
          titleToolTip={"comments_interactions_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={instagramWidgets?.commentsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["likes", "comments"]}
          showZoomPercentage
          iconAfterTitle={props?.instagramIcon}
          postsColumnPreLoaderDataSources={
            instagramWidgets?.commentsInteractionsPreLoader
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          hideLegend
          dataPiechart={instagramWidgets?.commentsInteractionsLegend}
          showChartDetailsWidget
          statsDetailsData={commentsInteractionsStats}
          isInteractionStatsWrapperStyle
        />
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_keywords"}
              titleToolTip={"comments_top_keywords_tooltip"}
              data={instagramWidgets?.topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={
                instagramWidgets?.topKeywordsPreLoader
              }
              preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={instagramWidgets?.topKeywords?.length}
              showDownloadIcon
              iconAfterTitle={props?.instagramIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_hashtags"}
              titleToolTip={"comments_top_hashtags_ig_tooltip"}
              data={instagramWidgets?.topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={
                instagramWidgets?.topHashtagsPreLoader
              }
              preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={instagramWidgets?.topHashtags?.length}
              showDownloadIcon
              iconAfterTitle={props?.instagramIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_associated_topics"}
              titleToolTip={"comments_associated_topics_tooltip"}
              data={instagramWidgets?.associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={
                instagramWidgets?.associatedTopicsPreLoader
              }
              preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
              iconAfterTitle={props?.instagramIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip={"dialects_subdialects_tooltip"}
              data={instagramWidgets?.dialects}
              subData={instagramWidgets?.subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={instagramWidgets?.dialectsPreLoader}
              preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
              instagramIcon={props?.instagramIcon}
              iconAfterTitle={props?.instagramIcon}
            />
          </Grid>
        </Grid>
      </Box>
      {/* <Box my={3}>
        <StackedBarWidget
          title={"gender_age_dist"}
          titleToolTip={"gender_age_dist_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={instagramWidgets?.genderAgeDistribution}
          colors={["#2AAAE2", "#FF4081"]}
          showZoomPercentage
          iconAfterTitle={props?.instagramIcon}
          postsColumnPreLoaderDataSources={
            instagramWidgets?.genderAgeDistributionPreLoader
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showScales
          dataPiechart={instagramWidgets?.genderAnalysisPieChart}
          hideLegend
          labels={["male", "female"]}
          reachFunnel
          isGenderAgeSorted
        />
      </Box> */}
      {/* <Grid container spacing={3}>
        <Grid item xs={6}>
          <PieChartWidget
            title={"top_cities"}
            titleToolTip={"top_cities_ig_tooltip"}
            data={instagramWidgets?.topCities}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={instagramWidgets?.topCitiesPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            twitterIcon={props.twitterIcon}
            iconAfterTitle={props?.instagramIcon}
          />
        </Grid>
        <Grid item xs={6}>
          <PieChartWidget
            title={"top_countries"}
            titleToolTip={"top_countries_ig_tooltip"}
            data={instagramWidgets?.BoxtopCountries}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
            pieChartPreLoaderDataSources={
              instagramWidgets?.topCountriesPreLoader
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            twitterIcon={props.twitterIcon}
            iconAfterTitle={props?.instagramIcon}
          />
        </Grid>
      </Grid> */}
      <Box my={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"top_authors"}
              titleToolTip={"top_authors_ig_tooltip"}
              data={instagramWidgets?.topAuthors}
              leftColomnTitle={"username"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={
                instagramWidgets?.topAuthorsPreLoader
              }
              preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              showDownloadIcon
              tablePagination
              showAtIcon
              count={instagramWidgets?.topAuthors?.length}
              iconAfterTitle={props?.instagramIcon}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InstagramEngagementBody;
