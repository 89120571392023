import React from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import WidgetHeader from "components/widgets/widgetHeader";
import "../deepInsights.scss";
import NoDataFound from "components/no-Data/noDataFound";
import { CheckValueLocale } from "utils/helpers";

/*---------------------------------------------------------*/

const DeepInsightsEngagement = (props) => {
  const { averageTweetPerTweet, engagementPerFollower } = props;

  const intl = useIntl();
  /*---------------------------------------------------------*/

  return (
    <Box className="deep-insight-wrapper" mb={2} id="engagment_in_the_monitor">
      <WidgetHeader
        title="engagment_in_the_monitor"
        chartId="engagment_in_the_monitor"
        showAi
        bigImg
        showDownloadIcon
      />
      {averageTweetPerTweet == "" && averageTweetPerTweet == "" ? (
        <NoDataFound />
      ) : (
        <Box className="personality-content">
          <Box className="deep-insight-stats" my={2}>
            {CheckValueLocale("average_engagement_per_tweet", "", {}, intl)}
            <Box component="span">{averageTweetPerTweet}</Box>
          </Box>
          <Box className="deep-insight-stats">
            {CheckValueLocale("engagement_per_1000_follower", "", {}, intl)}
            <Box component="span">{engagementPerFollower}</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DeepInsightsEngagement;
