import Services from "./Services";

var Explore = {};

Explore.getExploreData = () => {
  return Services.getData("api/v3/features/explores/informer");
};
Explore.createExplore = (queryData) => {
  return Services.postData("api/v3/features/explores", queryData);
};
Explore.getExploreSavedList = (queryData) => {
  return Services.getData("api/v3/features/explores", queryData);
};
Explore.deleteExploreSavedResult = (id) => {
  return Services.deleteData(`api/v3/features/explores/${id}`);
};
Explore.getExploreLatestSearch = (queryData) => {
  return Services.getData("api/v3/features/explores/latest", queryData);
};
Explore.showExplore = (id, queryData) => {
  return Services.getData(`api/v3/features/explores/${id}`, queryData);
};
Explore.saveExploreResults = (id, queryData) => {
  return Services.putData(`api/v3/features/explores/${id}`, queryData);
};
Explore.getFiltersFields = (explore_id) => {
  return Services.getData(`api/v3/features/explores/filters/${explore_id}`);
};
export default Explore;
