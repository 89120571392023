import ReactEcharts from "echarts-for-react";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";

const PieChart = (props) => {
  const intl = useIntl();

  let executedNames = ["Others", "أخرى", "other", "Other"];
  const removeItems = (array, itemToRemove) => {
    return array?.filter((v) => {
      return !itemToRemove?.includes(v);
    });
  };

  const formatToolTip = (data) => {
    let data_value = data.value;
    let format;
    props.isChannelAccounts && localStorage.lang == "ar"
      ? (format = ` ${data.marker} (${data.percent}%) ${Number(
          data_value,
        ).toLocaleString()} : ${data.name}`)
      : (format = `${data.marker} ${data.name}: ${Number(
          data_value,
        ).toLocaleString()} (${data.percent}%)`);

    let tooltipTitle =
      (props?.handleOpenQuickInspect &&
        !isEmptyArray(removeItems(data?.name?.split(), executedNames))) ??
      null;
    return tooltipTitle
      ? format +
          `<br/> <strong>${CheckValueLocale(
            "click_for_details",
            "",
            {},
            intl,
          )}</strong>`
      : format;
  };
  const onChartClick = (params) => {
    //here it is used for open quick-inspect-model
    let quickInspectModel =
      (props?.handleOpenQuickInspect &&
        !isEmptyArray(removeItems(params?.name?.split(), executedNames))) ??
      null;
    quickInspectModel &&
      props?.handleOpenQuickInspect(
        params?.name,
        props?.inspectorName,
        params?.percent,
      );
  };
  const arrLegend = props?.legend?.map((item) => {
    return { name: item };
  });

  return (
    <ReactEcharts
      option={{
        tooltip: {
          trigger: "item",
          backgroundColor: "#fff",
          textStyle: {
            fontSize: 12,
          },
          formatter: (val) => formatToolTip(val),
        },
        silent: props.haveData ? false : true,
        legend:
          props.legendPosition === "beside"
            ? {
                orient: "vertical",
                right: "right",
                top: 105,
                itemWidth: 15,
                data: arrLegend,
                color: props.color,
              }
            : {
                bottom: "auto",
                top: 280,
                left: "center",
                itemWidth: 15,
                data: arrLegend,
                color: props.color,
              },

        color: props.color,
        series: [
          {
            type: "pie",
            radius: props.solidPie ? "70%" : ["35%", "70%"],
            selectedMode: "s",
            data: props.seriesData,
            minAngle: 8,
            cursor: props.handleOpenQuickInspect ? "pointer" : "default",
            label: {
              show: props.showLabel ? true : false,
              color: props.color,
            },
            labelLine: {
              show: props.showLabel ? true : false,
              length: 30,
              length2: 0,
            },
            bottom: 40,
            itemStyle: {
              borderRadius: 2,
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
      }}
      style={{ minHeight: "330px", width: "100%" }}
      theme="custom_theme"
      onEvents={{ click: onChartClick }} //add this event for onClick work in quick-inspect
    />
  );
};

export default PieChart;
