import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LucButton from "shared/lucButton/lucButton";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import PopupModal from "components/popupModal";
import { useEffect, useState } from "react";
import LockedEngagementAssignModal from "./lockedEngagementAssignModal";
import EngagementsController from "services/controllers/engagementsController";
import { useDispatch } from "react-redux";
import { slaData } from "utils/redux/features/Engagements/EngagementsSlice";
const LockedEngagement = ({
  reduxSlaKey,
  userSignedIn,
  selectedCard,
  setEngamentAssignedSnackBar,
  trackerId,
  updateReduxAfterAssign,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [assigneeUserData, setAssigneeUserData] = useState(null);
  const [assigneeTeamData, setAssigneeTeamData] = useState(null);
  const [agentEmptyError, setAgentEmptyError] = useState(false);
  const [teamEmptyError, setTeamEmptyError] = useState(false);
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const assignedUser = reduxSlaData?.[reduxSlaKey]?.userId;
  const assignedUserData = reduxSlaData?.users?.filter((user) => {
    return user?.id === String(assignedUser);
  });
  const [teamsDataToUpdate, setTeamsDataToUpdate] = useState({
    users: [],
    teams: [],
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const assignedUserName = assignedUserData?.[0]?.attributes?.name;
  const isTeamLeader = handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS");
  const getAssigneeUserData = (data) => {
    setAssigneeUserData(data);
    if (data !== null) setAgentEmptyError(false);
  };
  const getAssigneeTeamData = (data) => {
    setAssigneeTeamData(data);
    setTeamEmptyError(false);
  };
  const getTeamsDataToUpdate = (data) => {
    setTeamsDataToUpdate(data);
    let slaDataCopy = { ...reduxSlaData };
    slaDataCopy.teams =
      data?.teams?.length > 0 ? data?.teams : [...reduxSlaData?.teams];
    dispatch(slaData(slaDataCopy));
  };

  const checkInputsValidValues = (selectedOptions) => {
    if (selectedOptions?.userId === null && selectedOptions?.teamId === null) {
      setAgentEmptyError(true);
      setTeamEmptyError(true);
    } else if (selectedOptions?.userId === null) {
      setAgentEmptyError(true);
      return;
    } else if (selectedOptions?.teamId === null) {
      setTeamEmptyError(true);
      return;
    } else {
      setAgentEmptyError(false);
      setTeamEmptyError(false);
    }
  };

  const handleAssignSubmit = (selectedOptions) => {
    checkInputsValidValues(selectedOptions);
    if (selectedOptions?.userId && selectedOptions?.teamId) {
      setIsButtonLoading(true);
      let queryData = {
        new_user_id: selectedOptions?.userId ? +selectedOptions?.userId : null,
        old_user_id: reduxSlaData[reduxSlaKey]?.userId
          ? +reduxSlaData[reduxSlaKey]?.userId
          : null,
        //selected team
        new_user_team_id: selectedOptions?.teamId
          ? +selectedOptions?.teamId
          : null,
        // previos selected team
        old_user_team_id: reduxSlaData[reduxSlaKey]?.teamId
          ? +reduxSlaData[reduxSlaKey]?.teamId
          : null,
        interaction_id: selectedCard?.tweet_id || selectedCard?.id,
        monitor_id: selectedCard?.tracker_id,
        data_source_name: selectedCard?.data_source,
        product_id: +window.localStorage.getItem("engagementsProductId"),
      };
      EngagementsController.assignTeamsAndUsers({ ...queryData }).then(
        (res) => {
          if (!res?.errorMsg) {
            // if success we update userId and teamId at redux to reflect here and at card
            let slaDataCopy = { ...reduxSlaData };
            slaDataCopy.teams =
              teamsDataToUpdate?.teams?.length > 0
                ? teamsDataToUpdate?.teams
                : [...reduxSlaData?.teams];
            let updatedSlaData = {
              ...slaDataCopy[reduxSlaKey],
              userId: +selectedOptions?.userId || null,
              teamId: +selectedOptions?.teamId || null,
            };
            let updatedReduxSlaData = {
              ...slaDataCopy,
              [reduxSlaKey]: { ...updatedSlaData },
            };
            setEngamentAssignedSnackBar({
              openSnackBar: true,
              severity: "success",
              title: "",
              message: isTeamLeader
                ? "locked_engament_assigned_successfully"
                : "locked_engament_assigned_to_you_successfully",
            });
            updateReduxAfterAssign(updatedReduxSlaData);
            // dispatch(slaData(updatedReduxSlaData));
            setOpenPopup(false);
            setAgentEmptyError(false);
            setTeamEmptyError(false);
            setAssigneeTeamData(null);
            setAssigneeUserData(null);
          } else {
            setEngamentAssignedSnackBar({
              openSnackBar: true,
              severity: "error",
              title: "locked_engament_assign_failed_title",
              message: isTeamLeader
                ? "locked_engament_assign_failed"
                : "locked_engament_assign_to_you_failed",
            });
          }
          setIsButtonLoading(false);
        },
      );
    }
  };

  const handleLockedPopupClose = () => {
    if (isButtonLoading) return;
    setOpenPopup(false);
    setAgentEmptyError(false);
    setTeamEmptyError(false);
    setAssigneeTeamData(null);
    setAssigneeUserData(null);
  };
  return (
    <Box className="locked-engagement">
      <Box className="locked-engagement-content">
        <FontAwesomeIcon icon={faLock} />
        <Box className="locked-engagement-context">
          <Typography variant="h6">
            {!assignedUserData?.length
              ? CheckValueLocale("unassigned_engagement", "", {}, intl)
              : CheckValueLocale(
                  "assigned_engagement_to_someone",
                  "",
                  { name: assignedUserName },
                  intl,
                )}
          </Typography>
          <Typography variant="p" className="locked-engagement-desc">
            {CheckValueLocale("locked_engagement_desc", "", {}, intl)}
          </Typography>
        </Box>
      </Box>

      <LucButton
        size="small"
        id="locked-engagement-assign-to-me-btn"
        onClick={() => setOpenPopup(true)}
      >
        {CheckValueLocale(
          isTeamLeader ? "assign_to_agent" : "assign_to_me",
          "",
          {},
          intl,
        )}
      </LucButton>

      <PopupModal
        classeName="locked-engagement-assign-modal"
        addClasses="actions-buttons-shared"
        minWidth="600px"
        open={openPopup}
        accept={() =>
          handleAssignSubmit({
            userId: assigneeUserData,
            teamId: assigneeTeamData,
          })
        }
        close={() => handleLockedPopupClose()}
        body={
          <LockedEngagementAssignModal
            userSignedIn={userSignedIn}
            isTeamLeader={isTeamLeader}
            reduxSlaKey={reduxSlaKey}
            getAssigneeTeamData={getAssigneeTeamData}
            getAssigneeUserData={getAssigneeUserData}
            getTeamsDataToUpdate={getTeamsDataToUpdate}
            trackerId={trackerId}
            teamEmptyError={teamEmptyError}
            agentEmptyError={agentEmptyError}
          />
        }
        title={CheckValueLocale(
          isTeamLeader
            ? "leader_engagement_assign_modal_title"
            : "agent_engagement_assign_modal_title",
          "",
          {},
          intl,
        )}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("assign_eng_modal_btn", "", {}, intl)}
        isRightBtnLoading={isButtonLoading}
      />
    </Box>
  );
};

export default LockedEngagement;
