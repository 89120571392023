import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";

import Pagination from "@mui/material/Pagination";

const GenesysInteractionBody = (props) => {
  let { response } = props;
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [latestInteractionsCount, setLatestInteractionsCount] = useState("");
  const [latestInteractionPreDataSource, setLatestInteractionPreDataSource] =
    useState([]);

  let paginationCount = Math.ceil(latestInteractionsCount / 10);

  useEffect(() => {
    if (
      response?.eventName ===
      "Genesys__CASingleDataSourceInteractionsPage__top_posts_conversations"
    ) {
      setLatestInteractionPreDataSource([response?.data_source]);
      if (props.page === 1) {
        setLatestInteractionsCount(response?.eventData?.top_posts_count);
      }
      response?.eventData?.top_posts?.map((interaction) => {
        return setLatestInteractions((oldArray) => [...oldArray, interaction]);
      });
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    if (props.resetAllFields !== 1) {
      setLatestInteractions([]);
      setLatestInteractionPreDataSource([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <ActiveFilters
        assignActiveFilters={props?.assignActiveFilters}
        startDate={props?.startDate}
        endDate={props?.endDate}
        applyFilter={props?.applyFilter}
        allActiveFilters={props?.allActiveFilters}
        filterFields={props?.filterFields}
      />
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          data={latestInteractions}
          dataSources={props?.dataSources}
          latestInteractionsPreLoaderDataSources={
            latestInteractionPreDataSource
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources[0]}
          latestInteractionsCount={latestInteractionsCount}
          loader={props?.loader}
        />
      </Box>
      {latestInteractions.length > 0 && latestInteractionsCount > 10 && (
        <Pagination
          onChange={props?.handleChangePage}
          page={props?.page}
          className="pagination-monitor-list"
          count={paginationCount}
          variant="outlined"
        />
      )}
    </>
  );
};

export default GenesysInteractionBody;
