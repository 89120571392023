import { Typography, Button } from "@mui/material";
import {
  facebookAuth,
  twitterAuth,
} from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { useIntl } from "react-intl";
import AddIcon from "@mui/icons-material/Add";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const SocialMediaAccountButton = (props) => {
  const {
    dataSourceName,
    companyApps,
    authHead,
    authDataSourceName,
    isDataSourceEmpty,
    className,
    isPublicAccount,
    isTwitter,
    isCompanyAccount,
    redirectUrl = window.location.href,
  } = props;
  const intl = useIntl();

  return (
    <>
      {isDataSourceEmpty && !isPublicAccount && (
        <Typography className="add-source-txt">
          {CheckValueLocale(authHead, "", {}, intl)}
        </Typography>
      )}
      {isPublicAccount && (
        <Typography className="social-connect-head">
          {CheckValueLocale(authHead, "", {}, intl)}
        </Typography>
      )}
      <LucButton
        variant="outline"
        startIcon={<AddIcon />}
        onClick={() =>
          isTwitter
            ? twitterAuth(
                companyApps,
                window.location.href,
                "twitter",
                true,
                isCompanyAccount,
              )
            : facebookAuth(
                companyApps,
                redirectUrl,
                dataSourceName,
                true,
                isCompanyAccount,
              )
        }
        id="monitor-options-auth-datasource-btn"
      >
        {CheckValueLocale(authDataSourceName, "", {}, intl)}
      </LucButton>
    </>
  );
};

export default SocialMediaAccountButton;
