import React, { useState } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import PopupModal from "components/popupModal";
import ThemeDialogBody from "./themeDialogBody";
import { useAddTheme } from "pages/themes/hooks/useAddTheme";
import { useUpdateTheme } from "pages/themes/hooks/useUpdateTheme";

const ThemeDialog = (props) => {
  const {
    themeDialogStats,
    openDialog,
    setOpenDialog,
    setThemeDialogStats,
    setSnackBarData,
  } = props;
  const intl = useIntl();

  const [initialValues, setInitialValue] = useState({
    sub_theme_color: "",
    sub_theme_en_name: "",
    sub_theme_ar_name: "",
    sub_theme_description: "",
    keywordInput: "",
    sub_themes_topkeywords: [],
  });

  const validationSchema = Yup.object({
    sub_theme_color: Yup.string().required("this_field_required"),
    sub_theme_en_name: Yup.string()
      .required("this_field_required")
      .max(30, "label_must_be_under_30_characters")
      .test(
        "",
        "theme_dialog_special_char_error",
        (value) =>
          !isPublicSpecialCharactersOrEmojis(value || "", undefined, true) &&
          removeSpecialCharacters(value)?.length,
      ),
    sub_theme_ar_name: Yup.string()
      .required("this_field_required")
      .max(30, "label_must_be_under_30_characters")
      .test(
        "",
        "theme_dialog_special_char_error",
        (value) =>
          !isPublicSpecialCharactersOrEmojis(value || "", undefined, true) &&
          removeSpecialCharacters(value)?.length,
      ),
    sub_theme_description: Yup.string()
      .test(
        "",
        "save_results_validation",
        (value) =>
          !isPublicSpecialCharactersOrEmojis(value || "", undefined, true) &&
          removeSpecialCharacters(value)?.length,
      )
      .max(300, "theme_desc_must_be_under_300_characters")
      .required("this_field_required"),
    keywordInput: Yup.string()
      .max(30, "keyword_must_be_under_30_characters")
      .when([], {
        is: () => Boolean(formik?.values?.keywordInput?.length),
        then: (schema) =>
          schema.test(
            "",
            "save_results_validation",
            (value) =>
              !isPublicSpecialCharactersOrEmojis(
                value || "",
                undefined,
                true,
              ) && removeSpecialCharacters(value)?.length,
          ),
      })
      .test(
        "",
        "this_keyword_already_exists",
        (value) => !formik?.values?.sub_themes_topkeywords?.includes(value),
      ),

    sub_themes_topkeywords: Yup.array()
      .max(10, "keywords_cannot_exceed_10")
      .min(1, "keywords_field_is_required"),
  });

  const handleCloseDialog = async () => {
    setOpenDialog(false);
    setTimeout(() => setThemeDialogStats({}), 500);
  };

  const { mutate: addNewTheme, isPending: isAddLoading } = useAddTheme();

  const handleCreateNew = () => {
    let queryData = { ...(formik?.values || {}) };
    delete queryData?.keywordInput; // to exclude keywordInput as not sent to BE

    addNewTheme(
      {
        data: {
          ...queryData,
          sub_themes_topkeywords: queryData?.sub_themes_topkeywords?.join(","),
        },
      },
      {
        onSuccess: () => {
          handleCloseDialog();
          setSnackBarData({
            open: true,
            severity: "success",
            title: "",
            message: "theme_added_success",
          });
        },
        onError: (data) => {
          const errorMsg = data?.errorMsg?.response?.data?.error;
          if (errorMsg == "English name already used") {
            formik.setFieldError(
              "sub_theme_en_name",
              "theme_name_must_be_unique",
            );
          } else if (errorMsg == "Arabic name already used") {
            formik.setFieldError(
              "sub_theme_ar_name",
              "theme_name_must_be_unique",
            );
          } else {
            setSnackBarData({
              open: true,
              severity: "error",
              title: "something_went_wrong",
              message: "try_again",
            });
          }
        },
      },
    );
  };

  const { mutate: editTheme, isPending: isEditLoading } = useUpdateTheme();

  const handleEditTheme = () => {
    let queryData = { ...(formik?.values || {}) };
    delete queryData?.keywordInput; // to exclude keywordInput as not sent to BE

    editTheme(
      {
        data: {
          ...queryData,
          sub_themes_topkeywords: queryData?.sub_themes_topkeywords?.join(","),
          id: themeDialogStats?.themeToEdit?.id,
        },
      },
      {
        onSuccess: () => {
          handleCloseDialog();
          setSnackBarData({
            open: true,
            severity: "success",
            title: "",
            message: "custom_dashboard_updated_successfully_msg",
          });
        },
        onError: (data) => {
          const errorMsg = data?.errorMsg?.response?.data?.error;
          if (errorMsg == "English name already used") {
            formik.setFieldError(
              "sub_theme_en_name",
              "theme_name_must_be_unique",
            );
          } else if (errorMsg == "Arabic name already used") {
            formik.setFieldError(
              "sub_theme_ar_name",
              "theme_name_must_be_unique",
            );
          } else {
            setSnackBarData({
              open: true,
              severity: "error",
              title: "something_went_wrong",
              message: "try_again",
            });
          }
        },
      },
    );
  };

  const disabledAddTheme = () => {
    let formikErrors = { ...(formik?.errors || {}) };
    if (formik?.values?.sub_themes_topkeywords?.length)
      delete formikErrors?.keywordInput;

    return !(formik?.dirty && _.isEmpty(formikErrors));
  };

  const disabledEditTheme = () => {
    const { themeToEdit } = themeDialogStats || {};
    // Values as returned from BE
    const initialData = {
      sub_theme_color: themeToEdit?.sub_theme_color,
      sub_theme_ar_name: themeToEdit?.sub_theme_ar_name,
      sub_theme_en_name: themeToEdit?.sub_theme_en_name,
      sub_theme_description: themeToEdit?.sub_theme_description,
      sub_themes_topkeywords:
        themeToEdit?.sub_themes_topkeywords?.split(",") || [],
    };
    // Live values for the form without keywordInput

    let filteredFormikValues = { ...(formik?.values || {}) };
    delete filteredFormikValues?.keywordInput; // to exclude keywordInput from Obj

    let formikErrors = { ...(formik?.errors || {}) };
    if (formik?.values?.sub_themes_topkeywords?.length)
      delete formikErrors?.keywordInput;

    return (
      _.isEqual(initialData, filteredFormikValues) ||
      !(formik?.dirty && _.isEmpty(formikErrors))
    );
  };

  const formik = useFormik({
    enableReinitialize: true, // To handle Edit case using theme id from Themes List
    initialValues: initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    handleSubmit: () => {},
  });

  const popupProps = {
    title: themeDialogStats?.isEdit ? "edit_theme" : "add_theme",
    leftBtnId: themeDialogStats?.isEdit
      ? "edit-theme-cancel-button"
      : "new-theme-cancel-button",
    rightBtn: themeDialogStats?.isEdit ? "apply" : "add",
    rightBtnId: themeDialogStats?.isEdit
      ? "edit-theme-apply-button"
      : "new-theme-add-button",
  };

  return (
    <PopupModal
      open={openDialog}
      close={handleCloseDialog}
      title={CheckValueLocale(popupProps?.title, "", {}, intl)}
      classeName="theme-dialog-popup"
      body={
        <ThemeDialogBody
          formik={formik}
          openDialog={openDialog}
          themeDialogStats={themeDialogStats}
          setInitialValue={setInitialValue}
          setSnackBarData={setSnackBarData}
        />
      }
      modalStyle="theme-dialog-content"
      leftBtnId={CheckValueLocale(popupProps?.leftBtnId, "", {}, intl)}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtnId={CheckValueLocale(popupProps?.rightBtnId, "", {}, intl)}
      rightBtn={CheckValueLocale(popupProps?.rightBtn, "", {}, intl)}
      accept={() => {
        let formikErrors = { ...(formik?.errors || {}) };
        delete formikErrors?.keywordInput; // to exclude keywordInput from Obj

        if (!Object?.keys(formikErrors)?.length) {
          if (themeDialogStats?.isEdit) handleEditTheme();
          else handleCreateNew();
        } else formik?.handleSubmit();
      }}
      isRightBtnLoading={
        themeDialogStats?.isEdit ? isEditLoading : isAddLoading
      }
      disabled={
        themeDialogStats?.isEdit ? disabledEditTheme() : disabledAddTheme()
      }
      minWidth="600px"
      addClasses="actions-buttons-shared"
    />
  );
};

export default ThemeDialog;
