import { useMutation, useQueryClient } from "@tanstack/react-query";
import MonitorsController from "services/controllers/monitorsController";

export const useEditChannel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ monitorId, dm_Configuration_Id, queryData }) =>
      MonitorsController.putEditConfigData(
        monitorId,
        dm_Configuration_Id,
        queryData,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["monitors"],
      });
    },
  });
};
