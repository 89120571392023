import { Box, Grid } from "@mui/material";
import NotFound from "images/live-dashboard-images/not-found.png";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ImagesWidgetObj from "../shard/imagesWidget";
import { useParams } from "react-router-dom";
import NameKeyWidget from "../shard/nameKeyWidget";

const WidgetBox = ({
  widgetValue,
  selectedWidgets,
  dataSource,
  handleSelectWidget,
}) => {
  const intl = useIntl();
  const urlParams = useParams();

  const widgetNameArr = widgetValue?.widget_name?.split("__");
  const widgetName = widgetNameArr?.[widgetNameArr?.length - 1];
  const widgetNameKey = NameKeyWidget(
    widgetValue?.widget_name,
    urlParams?.monitorType,
  );
  const widgetImg = ImagesWidgetObj?.[widgetName];

  return (
    <Grid item lg={3} md={4} sm={6}>
      <Box
        className={`widget-dashboard-option ${selectedWidgets?.[dataSource]?.includes(widgetValue?.id) ? "selected-widget-dashboard" : ""}`}
        onClick={() => handleSelectWidget(widgetValue?.id, dataSource)}
      >
        <img
          src={widgetImg || NotFound}
          alt={widgetValue?.widget_name}
          className="widget-option-img"
        />
        <Box component="p" className="widget-option-label">
          {CheckValueLocale(widgetNameKey || widgetName, "", {}, intl)}
        </Box>
      </Box>
    </Grid>
  );
};

export default WidgetBox;
