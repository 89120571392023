import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const Topics = ({ topicsData }) => {
  const intl = useIntl();

  return (
    <Box className="profile-side-bar-content">
      <h4 className="content-side-bar-heading no-margin">
        {CheckValueLocale("topics", "", {}, intl)}:
      </h4>
      {topicsData?.length > 0 ? (
        <Box className="content-wrap">
          {topicsData?.map((topic, i) => (
            <Box className="single-hint" key={i}>
              <span className="hint-text">
                {" "}
                {CheckValueLocale(topic, "", {}, intl)}{" "}
              </span>
            </Box>
          ))}
        </Box>
      ) : (
        <p className="profile-side-bar-content-empty">
          {" "}
          {CheckValueLocale("no_topic_found", "", {}, intl)}{" "}
        </p>
      )}
    </Box>
  );
};
export default Topics;
