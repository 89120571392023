import { Box, Typography } from "@mui/material";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SubThemes = ({ subThemesData }) => {
  const intl = useIntl();

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });

  return (
    <Box className="profile-side-bar-content">
      <h4 className="content-side-bar-heading no-margin">
        {CheckValueLocale("themes_profile", "", {}, intl)}:
      </h4>
      {subThemesData?.length > 0 ? (
        <Box className="content-wrap">
          {subThemesData?.map((subTheme, i) => {
            const selectedTheme =
              themesOptions?.find((theme) => theme?.value === subTheme) || "";

            return (
              <Box className="single-hint" key={i}>
                <Box
                  style={{
                    backgroundColor: selectedTheme?.sub_theme_color || "",
                  }}
                  className="hint-color"
                ></Box>
                <span className="hint-text">
                  {" "}
                  {selectedTheme?.sub_theme_name || ""}
                </span>
              </Box>
            );
          })}
        </Box>
      ) : (
        <p className="profile-side-bar-content-empty">
          {" "}
          {CheckValueLocale("no_themes_found", "", {}, intl)}{" "}
        </p>
      )}
    </Box>
  );
};
export default SubThemes;
