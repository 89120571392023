import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import WordCloudWidget from "components/widgets/wordCloudWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends.js";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";
import OverView from "components/widgets/overViewWidget.js";
import { handleArraySum, aggregate, output } from "../insightsCommonFunctions";
import { getNoOfDays } from "utils/helpers";
import moment from "moment";
import _ from "lodash";
import { CheckValueLocale } from "utils/helpers";

import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";

const ChatBody = (props) => {
  const intl = useIntl();
  let {
    response,
    startDate,
    endDate,
    dialectsSubDialectsData,
    setExcelSheetData,
    preLoaderTrackerDataSources,
  } = props;
  const [overviewArray, setOverviewArray] = useState([]);
  const [topKeywords, setTopkeywords] = useState([]);
  const [topKeywordsArray, setTopkeywordsArray] = useState([]);
  const [genders, setGenders] = useState([]);
  const [gendersXlsx, setGendersXlsx] = useState([]);
  const [gendersArray, setGendersArray] = useState([]);
  const [gendersArrayXlsx, setGendersArrayXlsx] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesXlsx, setCountriesXlsx] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);
  const [countriesArrayXlsx, setCountriesArrayXlsx] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesXlsx, setCitiesXlsx] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [citiesArrayXlsx, setCitiesArrayXlsx] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [dialectsXlsx, setDialectsXlsx] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [subDialectsXlsx, setSubDialectsXlsx] = useState([]);
  const [dialectsArray, setDialectsArray] = useState([]);
  const [dialectsArrayXlsx, setDialectsArrayXlsx] = useState([]);
  const [subDialectsArray, setSubDialectsArray] = useState([]);
  const [subDialectsArrayXlsx, setSubDialectsArrayXlsx] = useState([]);
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [interactionsArray, setInteractionsArray] = useState([]);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [avgInteractions, setAvgInteraction] = useState(0);
  const [interactions, setInteractions] = useState([]);
  const [themeData, setThemeData] = useState({});
  const [summedInteractions, setSummedInteractions] = useState([]);
  const [sentimentOvertime, setSentimentOvertime] = useState([]);
  const [sentimentPiechart, setSentimentPiechart] = useState([]);
  const [sentimentPieArray, setSentimentPieArray] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [overview, setOverview] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });

  // Values of DataSources totrack each widget preloader
  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [topKeywordsPreLoaderDataSources, setTopKeywordsPreLoaderDataSources] =
    useState([]);
  const [gendersPreLoaderDataSources, setGendersPreLoaderDataSources] =
    useState([]);
  const [countriesPreLoaderDataSources, setCountriesPreLoaderDataSources] =
    useState([]);
  const [citiesPreLoaderDataSources, setCitiesPreLoaderDataSources] = useState(
    [],
  );
  const [dialectsPreLoaderDataSources, setDialectsPreLoaderDataSources] =
    useState([]);
  const [
    interactionsPreLoaderDataSources,
    setInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    sentimentAnalysisPreLoaderDataSources,
    setSentimentAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [themeTrendPreLoaderDataSources, setThemeTrendPreLoaderDataSources] =
    useState([]);
  const [
    themeAnalysisPreLoaderDataSources,
    setThemeAnalysisPreLoaderDataSources,
  ] = useState([]);
  var gendersResult = [],
    gendersResultXlsx = [],
    countriesResult = [],
    countriesResultXlsx = [],
    citiesResult = [],
    citiesResultXlsx = [],
    dialectsResult = [],
    dialectsResultXlsx = [],
    subDialectsResult = [],
    subDialectsResultXlsx = [],
    sentimentPieResult = [],
    overviewResult = [],
    interactionsResult = [],
    interactionsArray2 = [],
    topKeywordsResult = [],
    interactions2 = [];

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();
  const handleResponseFunc = async () => {
    if (response?.eventName === "overview") {
      if (response?.eventData?.overview) {
        Object.entries(response?.eventData?.overview).map((i) => {
          if (i[0] === "net_sentiment") {
            i[1].map((sentiment) => {
              if (sentiment.name == "negative") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  negative: netSentiment["negative"] + sentiment.value,
                }));
              }
              if (sentiment.name == "positive") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  positive: netSentiment["positive"] + sentiment.value,
                }));
              }
              if (sentiment.name == "neutral") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  neutral: netSentiment["neutral"] + sentiment.value,
                }));
              }
            });
          } else {
            const condition = i[1] === "None" || i[1] === null || i[1] === 0;
            if (i[0] === "average_waiting_time" && condition) {
              overviewArray.push({ name: i[0], value: 0.0 });
            } else overviewArray.push({ name: i[0], value: i[1] });
          }
        });
        setOverViewPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "top_keywords") {
      response?.eventData?.top_keywords?.map((i) => {
        return topKeywordsArray?.push({
          name: i?.id,
          value: i?.stats_count,
        });
      });
      handleArraySum(topKeywordsArray, topKeywordsResult, setTopkeywords);
      setTopKeywordsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "dialects") {
      response?.eventData?.dialects?.map((i) => {
        return dialectsArray?.push(i);
      });
      response?.eventData?.dialects?.map((i) => {
        return dialectsArrayXlsx?.push(i);
      });
      response?.eventData?.sub_dialects.map((i) => {
        return subDialectsArray?.push(i);
      });
      response?.eventData?.sub_dialects?.map((i) => {
        return subDialectsArrayXlsx?.push(i);
      });
      handleArraySum(dialectsArray, dialectsResult, setDialects);
      handleArraySum(subDialectsArray, subDialectsResult, setSubDialects);
      handleArraySum(
        _.cloneDeep([...dialectsArrayXlsx]),
        _.cloneDeep([...dialectsResultXlsx]),
        setDialectsXlsx,
      );
      handleArraySum(subDialectsArray, subDialectsResult, setSubDialects);
      handleArraySum(
        _.cloneDeep([...subDialectsArrayXlsx]),
        _.cloneDeep([...subDialectsResultXlsx]),
        setSubDialectsXlsx,
      );
      setDialectsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "genders") {
      response?.eventData?.genders?.map((i) => {
        return gendersArray?.push(i);
      });
      response?.eventData?.genders?.map((i) => {
        return gendersArrayXlsx?.push(i);
      });
      handleArraySum(gendersArray, gendersResult, setGenders);
      handleArraySum(
        _.cloneDeep([...gendersArrayXlsx]),
        _.cloneDeep([...gendersResultXlsx]),
        setGendersXlsx,
      );
      setGendersPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "latest_interactions") {
      response?.eventData?.latest_interactions?.map((interaction) => {
        setLatestInteractions((oldArray) => [...oldArray, interaction]);
      });
      setLatestInteractionsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "interactions") {
      //START INTERACTIONS ARRAY
      response.eventData.interactions?.map((i) => {
        return interactionsArray?.push(i);
      });
      interactionsArray?.map((data) => [
        interactionsArray2.push({
          name: moment.unix(Number(data.name)).utc().format("YYYY-MM-DD"),
          value: data.value,
        }),
      ]);
      handleArraySum(
        interactionsArray2,
        interactionsResult,
        setInteractions,
        true,
      );
      setInteractionsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "sentiment") {
      response?.eventData?.sentiment_piechart?.map((i) => {
        sentimentPieArray.push(i);
      });
      handleArraySum(
        sentimentPieArray,
        sentimentPieResult,
        setSentimentPiechart,
      );
      Object.entries(response?.eventData?.sentiment_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentOvertime({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentAnalysisPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);

      // For demo purpose, should be deleted when Themes/Subthemes task done
    } else if (response?.eventName === "themes") {
      setThemeAnalysis((oldArray) => [
        ...oldArray,
        response?.eventData?.themes,
      ]);
      setThemeTrendPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
      setThemeAnalysisPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "countries") {
      response?.eventData?.countries?.map((i) => {
        return countriesArray.push(i);
      });
      response?.eventData?.countries?.map((i) => {
        return countriesArrayXlsx?.push(i);
      });
      handleArraySum(countriesArray, countriesResult, setCountries);
      setCountriesPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
      handleArraySum(
        _.cloneDeep([...countriesArrayXlsx]),
        _.cloneDeep([...countriesResultXlsx]),
        setCountriesXlsx,
      );
    } else if (response?.eventName === "cities") {
      response?.eventData?.cities?.map((i) => {
        citiesArray?.push(i);
      });
      response?.eventData?.cities?.map((i) => {
        citiesArrayXlsx?.push(i);
      });
      handleArraySum(citiesArray, citiesResult, setCities);
      handleArraySum(
        _.cloneDeep([...citiesArrayXlsx]),
        _.cloneDeep([...citiesResultXlsx]),
        setCitiesXlsx,
      );
      setCitiesPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment).reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverview,
      false,
      highest_sentiment,
    );
  };

  useEffect(() => {
    // Avoid Empty Array state
    let sum;
    let interactions_sum = 0;
    if (interactions.length > 0) {
      interactions.map((data) => {
        // Push data to interactions2
        interactions2.push({
          name: moment(data.name).unix(),
          value: data.value,
        });
        interactions_sum += data.value;
      });
      setSummedInteractions(interactions2);

      // Here we just need new Array of Refactor to be One Array
      setTotalInteractions(interactions_sum);
      const noOfDays = getNoOfDays(startDate, endDate);
      const avg = interactions_sum / noOfDays;
      setAvgInteraction(avg % 1 !== 0 ? avg.toFixed(2) : avg);
      //END TOTAL INTERACTIONS COUNT
    }
  }, [interactions]);

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  // Refresh all values at Each request to default values..
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setOverview([]);
      setOverviewArray([]);
      setTopkeywords([]);
      setDialects([]);
      setSubDialects([]);
      setGenders([]);
      setGendersXlsx([]);
      setGendersArray([]);
      setGendersArrayXlsx([]);
      setTopkeywordsArray([]);
      setCountries([]);
      setCountriesXlsx([]);
      setCountriesArray([]);
      setCountriesArrayXlsx([]);
      setLatestInteractions([]);
      setSentimentOvertime([]);
      setSentimentPiechart([]);
      setSentimentPieArray([]);
      setThemeAnalysis([]);
      setTotalInteractions(0);
      setAvgInteraction(0);
      setInteractionsArray([]);
      setDialectsArray([]);
      setDialectsArrayXlsx([]);
      setSubDialectsArray([]);
      setSubDialectsArrayXlsx([]);
      setCitiesArray([]);
      setCitiesArrayXlsx([]);
      setCities([]);
      setCitiesXlsx([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });

      // Values of DataSources totrack each widget preloader
      setOverViewPreLoaderDataSources([]);
      setTopKeywordsPreLoaderDataSources([]);
      setDialectsPreLoaderDataSources([]);
      setGendersPreLoaderDataSources([]);
      setCountriesPreLoaderDataSources([]);
      setCitiesPreLoaderDataSources([]);
      setInteractionsPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
      setSentimentAnalysisPreLoaderDataSources([]);
      setThemeTrendPreLoaderDataSources([]);
      setThemeAnalysisPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    if (
      _.isEqual(
        themeAnalysisPreLoaderDataSources?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) == true &&
      props.preLoaderTrackerDataSources.length &&
      themeAnalysisPreLoaderDataSources.length
    ) {
      aggregate(themeAnalysis, setThemeData);
    }
  }, [themeAnalysisPreLoaderDataSources]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: totalInteractions,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: avgInteractions,
    },
  ];

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);
  const [dialectsData, setDialectsData] = useState([]);
  const [subDialectsData, setSubDialectsData] = useState([]);
  let arrSubDialectsClone = [];
  useEffect(() => {
    if (
      _.isEqual(
        dialectsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) == true
    ) {
      dialects?.map((item) => {
        subDialects?.map((item2) => {
          if (item?.name in dialectsSubDialectsData) {
            if (
              dialectsSubDialectsData[item?.name]?.includes(
                item2?.name?.toLowerCase(),
              )
            ) {
              arrSubDialectsClone?.push(item2);
            }
          }
        });
      });
      setSubDialectsData(arrSubDialectsClone);
      setDialectsData(dialects);
    }
  }, [dialects, subDialects, dialectsPreLoaderDataSources]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [
      overViewPreLoaderDataSources,
      interactionsPreLoaderDataSources,
      themeTrendPreLoaderDataSources,
      gendersPreLoaderDataSources,
      countriesPreLoaderDataSources,
      citiesPreLoaderDataSources,
      dialectsPreLoaderDataSources,
      topKeywordsPreLoaderDataSources,
      latestInteractionsPreLoaderDataSources,
    ];
    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );
    props?.setCheckAllDataFetchingExport(result);
    let excelData = {
      preLoaderTrackerDataSources,
      overview,
      summedInteractions,
      themeData,
      dialectsXlsx,
      subDialectsXlsx,
      citiesXlsx,
      countriesXlsx,
      gendersXlsx,
      topKeywords,
    };
    setExcelSheetData([{ ...excelData }]);
  }, [
    overview,
    overViewPreLoaderDataSources,
    summedInteractions,
    interactionsPreLoaderDataSources,
    themeData,
    themeTrendPreLoaderDataSources,
    gendersXlsx,
    gendersPreLoaderDataSources,
    countriesXlsx,
    countriesPreLoaderDataSources,
    citiesXlsx,
    citiesPreLoaderDataSources,
    dialectsXlsx,
    dialectsPreLoaderDataSources,
    topKeywords,
    topKeywordsPreLoaderDataSources,
    latestInteractionsPreLoaderDataSources,
  ]);

  return (
    <>
      <OverView
        data={overview}
        isChat
        overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />

      <Typography
        variant="h6"
        mt={5}
        mb={2}
        sx={{
          color: "#475569",
          fontSize: "16px",
          fontWeight: 500,
          letterSpacing: "0.12em",
        }}
      >
        {CheckValueLocale("metrics", "", {}, intl)}
      </Typography>

      {/* Interations CHART */}
      <Box className="interations-chart">
        <LineChartWidget
          title={"interactions"}
          data={summedInteractions}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/chat"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={interactionsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      {/* Sentiment_analysis Chart */}
      <Box my={3}>
        {/* <StackedLineWidget
          title={"sentiment_analysis"}
          data={sentimentOvertime}
          dataPiechart={sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        /> */}
      </Box>

      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemeAnalysis
          title={"themes"}
          data={themeData?.theme_analysis ? themeData?.theme_analysis : []}
          showAI
          showDownloadIcon
          bigImg
          themeAnalysisPreLoaderDataSources={themeAnalysisPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          data={themeData?.theme_trend ? themeData?.theme_trend : []}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={themeTrendPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      {/* Genders & Cities & Countries CHART */}
      <Grid container spacing={2} my={3}>
        <Grid item md={4}>
          <PieChartWidget
            title={"genders_chart"}
            data={genders}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#6D737A", "#009BDF", "#F680BC"]}
            chartType={"gender"}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={gendersPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"countries"}
            data={countries}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={countriesPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"cities"}
            data={cities}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={citiesPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>

      {/* (Dialects & Sub-dialects) & Top keywords CHART */}
      <Grid container spacing={2} my={3}>
        <Grid item md={4}>
          <PieChartWidget
            title={"dialects_subdialects"}
            data={dialectsData}
            subData={subDialectsData}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={dialectsPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={8} className="topkewords_wrapper">
          <WordCloudWidget
            title={"top_keywords"}
            data={topKeywords}
            errorMessage={"no_keywords_found"}
            showDownloadIcon
            bigImg
            wordCloudPreLoaderDataSources={topKeywordsPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>

      {/* Latest Interaction CHART */}
      <Box my={3}>
        <InteractionsList
          showProfile={true}
          data={latestInteractions.slice(0, 10)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isChat
          latestInteractionsPreLoaderDataSources={
            latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showViewMoreButton={totalInteractions > 10}
        />
      </Box>
    </>
  );
};

export default ChatBody;
