import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import CustomizedTooltip from "components/customizedTooltip";
import { Box, Typography, Button, Tooltip, Zoom } from "@mui/material";
import ListOptions from "./Options";
import "./list.scss";
import { getSocialIcon } from "utils/helpers";
import SharedTable from "../../../shared/table/sharedTable";
import ErrorIcon from "@mui/icons-material/Error";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #999",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const showReportDataSourcesData = (dataSources) => {
  let dataSourcesArray = [];

  dataSources?.map((item) =>
    item?.name === "TWITTER" ||
    item?.name === "FACEBOOK" ||
    item?.name === "INSTAGRAM"
      ? dataSourcesArray?.push(`${item?.name}_${item?.type}`)
      : dataSourcesArray?.push(`${item?.name}`),
  );

  const showDatasources = dataSourcesArray.slice(0, 7);
  const showLimitDatasources = dataSourcesArray.slice(
    7,
    dataSourcesArray?.length,
  );

  const getDatasoucrsContent = (data) => {
    return data?.map((item, key) => <Box key={key}>{getSocialIcon(item)}</Box>);
  };
  return (
    <>
      <Box className="tooltip-content">
        {dataSourcesArray?.length ? getDatasoucrsContent(showDatasources) : "-"}
        {dataSourcesArray?.length >= 8 ? (
          <LightTooltip
            title={
              <Box className="tooltip-content">
                {getDatasoucrsContent(showLimitDatasources)}
              </Box>
            }
            arrow
            placement="top"
            id="tooltip-datasouces-report"
          >
            <Typography component="span" className="tooltip-content-count">
              {`(+${showLimitDatasources?.length})`}
            </Typography>
          </LightTooltip>
        ) : null}
      </Box>
    </>
  );
};

const ReportList = ({
  list,
  handleOpenDeleteModal,
  searchText,
  getReportData,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const [tableBody, setTableBody] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const showWarningMsg = (attributes) => {
    const { platform, issues, is_luci_report, has_luci_quota } =
      attributes || {};

    // Show warning icon if report has issues [is_limit_reached, are_monitors_deleted, paused_monitors, missing luci_quota]
    const reportHasIssues =
      issues?.is_limit_reached ||
      issues?.are_monitors_deleted ||
      issues?.paused_monitors?.length ||
      (is_luci_report && !has_luci_quota);

    if (reportHasIssues) {
      let tooltipTitle = "";

      // The priority is: is_limit_reached > are_monitors_deleted > paused_monitors > missing luci_quota
      if (issues?.is_limit_reached) {
        tooltipTitle = "report_list_paused_due_to_limit_reached";
      } else if (issues?.are_monitors_deleted) {
        tooltipTitle =
          platform === "CXM"
            ? "report_list_paused_due_to_channel_deleted"
            : "report_list_paused_due_to_monitor_deleted";
      } else if (issues?.paused_monitors?.length) {
        tooltipTitle =
          platform === "CXM"
            ? "report_list_paused_due_to_channel_paused"
            : "report_list_paused_due_to_monitor_paused";
      } else if (is_luci_report && !has_luci_quota) {
        tooltipTitle = "report_list_luci_issue";
      }

      return (
        <Box component="span">
          <CustomizedTooltip
            id="warning-tooltip-report"
            title={CheckValueLocale(tooltipTitle, "", {}, intl)}
            placement="bottom"
            arrow
            styles={{ height: "auto", width: "170px", padding: "10px" }}
          >
            <Box className="warning-icon-countainer">
              <ErrorIcon
                className="dashboard-warning-icon report-warning-icon"
                fontSize="small"
              />
            </Box>
          </CustomizedTooltip>
        </Box>
      );
    }

    return false; // To return false if report has no issues to not show highlighted row
  };

  const showReportText = (text, textLength, item) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Button
              variant="text"
              sx={{
                padding: 0,
                margin: 0,
                borderRadius: 0,
              }}
              id="report-create-new-show-report-text-btn"
            >
              {text?.substring(0, textLength)}...
            </Button>
          </BootstrapTooltip>
        ) : (
          <Typography variant="subtitle2">{text}</Typography>
        )}
      </Box>
    );
  };

  const handelViewReport = (reportData) => {
    history?.push({
      pathname: "/reports/view-report/" + reportData.id,
      state: { detail: reportData },
    });
  };

  const options = (item) => {
    return (
      <ListOptions
        reportData={item}
        handleOpenDeleteModal={handleOpenDeleteModal}
        history={history}
        handelViewReport={handelViewReport}
        getReportData={getReportData}
      />
    );
  };
  const stateCell = (item) => {
    return (
      <>
        <Box
          className={`status ${
            item?.attributes?.manual_status === true ? "active" : "paused"
          }`}
        >
          {CheckValueLocale(
            item?.attributes?.manual_status == true
              ? "Active"
              : "reports_paused",
            "",
            {},
            intl,
          )}
        </Box>
      </>
    );
  };
  useEffect(() => {
    setTableLoading(true);
    const tabBody =
      list?.length == 0
        ? []
        : list?.map((item) => {
            const reportHasIssues = showWarningMsg(item?.attributes);

            return {
              id: item?.id,
              className: !reportHasIssues ? "" : "warning",
              data: [
                {
                  body: (
                    <Box className="report-name-countainer">
                      {reportHasIssues ? reportHasIssues : null}
                      <Box
                        className="report-text"
                        onClick={() => handelViewReport(item)}
                      >
                        {showReportText(item?.attributes?.title, 18, item)}
                      </Box>
                    </Box>
                  ),
                  className: "report-name-td",
                },
                {
                  body: item?.attributes?.report_type?.length
                    ? CheckValueLocale(
                        `reports_${item?.attributes?.report_type}`,
                        "—",
                        {},
                        intl,
                      )
                    : "—",
                },
                {
                  body: item?.attributes?.platform?.length
                    ? item?.attributes?.platform === "SM"
                      ? CheckValueLocale("social_listening", "—", {}, intl)
                      : CheckValueLocale("channel_analytics", "—", {}, intl)
                    : "—",
                },
                {
                  body: item?.attributes?.data_sources?.length
                    ? showReportDataSourcesData(item?.attributes?.data_sources)
                    : "—",
                  className: "reports-social-icons",
                },
                {
                  body: item?.attributes?.frequency?.length
                    ? CheckValueLocale(
                        `report_${item?.attributes?.frequency?.toLowerCase()}`,
                        "—",
                        {},
                        intl,
                      )
                    : "—",
                },
                {
                  body:
                    item?.attributes?.status === "active"
                      ? item?.attributes?.next_delivery
                        ? moment(item?.attributes?.next_delivery)
                            .utcOffset(item?.attributes?.next_delivery)
                            .format("YYYY-MM-DD (h:mma)")
                        : "—"
                      : "—",
                  className: "date-direction",
                },
                {
                  body: item?.attributes?.created_by
                    ? item?.attributes?.created_by
                    : "—",
                  isCreatedBy: true,
                },
                {
                  body: stateCell(item),
                },

                {
                  body: options(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(list));
    setTableLoading(false);
  }, [list, intl?.locale]);

  const tableHead = [
    {
      body: CheckValueLocale("report_name", "", {}, intl),
      width: "12%",
    },

    {
      body: CheckValueLocale("report_type", "", {}, intl),
      width: "11.99%",
    },
    {
      body: CheckValueLocale("report_product", "", {}, intl),
      width: "10.53%",
    },
    {
      body: CheckValueLocale("report_data_source", "", {}, intl),
      width: "10.96%",
    },
    {
      body: CheckValueLocale("report_frequency", "", {}, intl),
      width: "8.56%",
    },
    {
      body: CheckValueLocale("report_next_delivery", "", {}, intl),
      width: "13.78%",
    },
    {
      body: CheckValueLocale("creator", "", {}, intl),
      width: "12.7%",
    },
    {
      body: CheckValueLocale("status", "", {}, intl),
      width: "10.36%",
    },
    {
      body: null,
      width: "5.74%",
    },
  ];
  const emptyBody = (list) => {
    return (
      <>
        {searchText !== "" && list?.length == 0 ? (
          <Box className="no-data-cdp">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="sorry_adjust_your_search"
            />
          </Box>
        ) : list?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="reports"
              title="create_your_report"
              description="create_your_report_dec"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks.reports_link}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <>
      <SharedTable
        className="report-table"
        tableHeader={tableHead}
        tableBody={tableBody}
        isSharedStyle
        emptyBody={tableEmptyBody}
      />
    </>
  );
};
export default ReportList;
