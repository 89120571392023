import { Box, Container } from "@mui/material";
// Style
import "./Footer.scss";
//Locals
import { FormattedMessage } from "react-intl";
import { MonitorContainer } from "pages/Monitors/Components/sidebar/monitor.styles";
import { useLocation } from "react-router-dom";
import saFlag from "images/saFlag.svg";
import Services from "services/api/Services";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const Footer = () => {
  const intl = useIntl();
  let location = useLocation();
  const { pathname } = location;
  let footerContainMonitor = location.pathname.includes("/monitor/"); // check if link contain [ monitor word ]
  let footerContainDashboard =
    pathname.includes("/dashboard/ACCOUNT_ANALYSIS") ||
    pathname.includes("/dashboard/MANAGED_PAGE") ||
    pathname.includes("/dashboard/MANAGED_ACCOUNT") ||
    pathname.includes("/dashboard/KEYWORD");
  let footerEngagementAnalytics = location.pathname.includes(
    "/engagements/analytics",
  );
  let checkEngagementFooter =
    pathname == "/engagements/main/inbox" ||
    pathname == "/engagements/main/publish" ||
    pathname == "/engagements/ai-agents";

  return (
    <footer className="app-footer">
      {footerContainMonitor || footerContainDashboard ? (
        <MonitorContainer
          maxWidth="xl"
          className="monitor-container montior-footer"
        >
          {Services.showThirdCompany ? (
            <Box className="login--footer--content">
              <p className="copyright-text">
                <FormattedMessage id="copy_rights" />
                {new Date().getFullYear()}
                {CheckValueLocale(
                  "lucidya_rights",
                  "",
                  { project_name_en: Services?.project_name_en.toUpperCase() },
                  intl,
                )}
                <img src={saFlag} className="sa-flag" />
              </p>
              <nav className="footer-nav">
                <a
                  href="https://lucidya.com/privacy-policy/"
                  target="_blank"
                  rel="noopener"
                >
                  <FormattedMessage id="privacy_policy" />
                </a>
                <a
                  href="https://lucidya.com/service-agreement/"
                  target="_blank"
                  rel="noopener"
                >
                  <FormattedMessage id="service_greement" />
                </a>
                <a
                  href="https://lucidya.com/author-privacy-stateme/"
                  target="_blank"
                  rel="noopener"
                >
                  <FormattedMessage id="author_privacy_statement" />
                </a>
                <a href="https://lucidya.com/" target="_blank" rel="noopener">
                  {CheckValueLocale("about_us", "", {}, intl)}
                </a>
              </nav>
            </Box>
          ) : null}
        </MonitorContainer>
      ) : !checkEngagementFooter || footerEngagementAnalytics ? (
        <Container maxWidth="xl">
          {Services.showThirdCompany ? (
            <Box className="login--footer--content">
              <p className="copyright-text">
                <FormattedMessage id="copy_rights" />
                {new Date().getFullYear()}
                {CheckValueLocale(
                  "lucidya_rights",
                  "",
                  { project_name_en: Services?.project_name_en.toUpperCase() },
                  intl,
                )}
                <img src={saFlag} className="sa-flag" />
              </p>
              <nav className="footer-nav">
                <a
                  href="https://lucidya.com/privacy-policy/"
                  target="_blank"
                  rel="noopener"
                >
                  <FormattedMessage id="privacy_policy" />
                </a>
                <a
                  href="https://lucidya.com/service-agreement/"
                  target="_blank"
                  rel="noopener"
                >
                  <FormattedMessage id="service_greement" />
                </a>
                <a
                  href="https://lucidya.com/author-privacy-stateme/"
                  rel="noopener"
                  target="_blank"
                >
                  <FormattedMessage id="author_privacy_statement" />
                </a>
                <a href="https://lucidya.com/" target="_blank" rel="noopener">
                  {CheckValueLocale("about_us", "", {}, intl)}
                </a>
              </nav>
            </Box>
          ) : null}
        </Container>
      ) : null}
    </footer>
  );
};

export default Footer;
