import TwitterKeywords from "./dataSourceComponents/twitterKeywords";
import FacebookKeywords from "./dataSourceComponents/facebookKeywords";
import InstagramKeywords from "./dataSourceComponents/instagramKeywords";
import NBKeywords from "./dataSourceComponents/newsBlogsKeywords/newsBlogsKeywords";

const KeywordsType = (props) => {
  const { monitorDetails, setNBexceedLimit } = props;
  return (
    <>
      {monitorDetails?.data_sources?.map((i, index) => {
        return (
          <>
            {i === "TWITTER" ? (
              <TwitterKeywords {...props} indexes={index} />
            ) : i === "INSTAGRAM" ? (
              <InstagramKeywords {...props} indexes={1} />
            ) : i === "FACEBOOK" ? (
              <FacebookKeywords {...props} indexes={2} />
            ) : i === "TALKWALKER" ? (
              <NBKeywords
                {...props}
                indexes={3}
                setNBexceedLimit={setNBexceedLimit}
              />
            ) : (
              ""
            )}
          </>
        );
      })}
    </>
  );
};

export default KeywordsType;
