import {
  faArrowDownRight,
  faArrowUpRight,
  faArrowRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Menu, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import { ReactComponent as ChartLine } from "images/engagements-analytics/chart-line.svg";
import { ReactComponent as ChartUp } from "images/engagements-analytics/chart-up.svg";
import { ReactComponent as ChartDown } from "images/engagements-analytics/chart-down.svg";
import { ReactComponent as ChartArrow } from "images/engagements-analytics/chart-arrow.svg";
import { useEffect, useState } from "react";
import { renderRemainingTime } from "pages/engagements/components/engagementAnalytics/sharedFunctions/datesUtils";

const statsIconsMapper = {
  line: <ChartLine />,
  up: <ChartUp />,
  down: <ChartDown />,
};

const statsClassMapper = {
  line: "constant",
  up: "ascend",
  down: "descend",
};

const statsArrowChartMapper = {
  line: faArrowRight,
  up: faArrowUpRight,
  down: faArrowDownRight,
};

const ChartAvgStatsWidget = ({ statsData, dates }) => {
  const intl = useIntl();
  const { prevDate, currDate } = dates;
  const isAr = intl?.locale === "ar";
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStats, setSelectedStats] = useState(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const open = Boolean(anchorEl);

  const handleClick = (event, idx) => {
    const card = event?.currentTarget;
    const width = card?.getBoundingClientRect?.()?.width || 390;
    setMenuWidth(width);
    setSelectedStats(statsData?.[idx]);
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedCharType =
    selectedStats?.percentage === 0
      ? "line"
      : selectedStats?.percentage > 0
        ? "up"
        : "down";
  return (
    <Grid container className="average-stats-cards-container">
      {statsData?.map?.((stats, idx) => {
        const charType =
          stats?.percentage === 0
            ? "line"
            : stats?.percentage > 0
              ? "up"
              : "down";

        return (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            className="average-stats-card"
            key={stats?.name}
          >
            <Box
              className={classNames(
                "average-stats-card-container",
                open && selectedStats?.name == stats?.name && "menu-open",
              )}
              id={`average-stats-card-${stats?.name}`}
              onClick={(event) => handleClick(event, idx)}
            >
              <Box className="average-stats-card-icon">
                {statsIconsMapper[charType]}
              </Box>
              <Box className="average-stats-card-content">
                <TooltipDown
                  title={CheckValueLocale(stats?.desc, "", {}, intl)}
                >
                  <Box className="average-stats-card-name">
                    {CheckValueLocale(stats?.name, "", {}, intl)}
                  </Box>
                </TooltipDown>

                <Box className="average-stats-card-info">
                  <Typography
                    variant="span"
                    className="average-stats-card-info-value"
                  >
                    {renderRemainingTime(stats?.currValue, intl)}
                  </Typography>
                  <Box
                    className={classNames(
                      "average-stats-card-info-avg",
                      statsClassMapper[charType],
                    )}
                  >
                    <FontAwesomeIcon icon={statsArrowChartMapper[charType]} />
                    <Typography
                      variant="span"
                      className="average-stats-card-info-avg-percentage"
                    >
                      {renderPercentage(stats?.percentage)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        );
      })}
      <Menu
        id="chart-avg-stats-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: "average-stats-menu-paper",
          root: "average-stats-menu-root",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isAr ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isAr ? "right" : "left",
        }}
        PaperProps={{
          style: {
            width: `${menuWidth}px`,
          },
        }}
      >
        <Box className="average-stats-menu-container">
          <Box className="average-stats-menu-header">
            <Typography variant="h6" className="average-stats-menu-title">
              {CheckValueLocale(selectedStats?.name, "", {}, intl)}
            </Typography>
            <Typography variant="span" className="average-stats-menu-desc">
              {CheckValueLocale(selectedStats?.desc, "", {}, intl)}
            </Typography>
          </Box>
          <Box className="average-stats-menu-divider" />
          <Box className="average-stats-menu-content">
            <Box className="average-stats-menu-content-cell">
              <TooltipDown
                title={CheckValueLocale("avg_from_date_tooltip", "", {}, intl)}
              >
                <Typography
                  variant="span"
                  className="average-stats-menu-content-cell-title"
                >
                  {prevDate}
                </Typography>
              </TooltipDown>
              <Typography
                variant="span"
                className="average-stats-menu-content-cell-value"
              >
                {renderRemainingTime(selectedStats?.prevValue, intl)}
              </Typography>
            </Box>
            <Box className="average-stats-menu-content-cell cell-icon">
              <ChartArrow />
              <ChartArrow />
            </Box>
            <Box className="average-stats-menu-content-cell">
              <TooltipDown
                title={CheckValueLocale("avg_to_date_tooltip", "", {}, intl)}
              >
                <Typography
                  variant="span"
                  className="average-stats-menu-content-cell-title"
                >
                  {CheckValueLocale(currDate, "", {}, intl)}
                </Typography>
              </TooltipDown>
              <Typography
                variant="span"
                className="average-stats-menu-content-cell-value"
              >
                {renderRemainingTime(selectedStats?.currValue, intl)}
              </Typography>
            </Box>
          </Box>
          <Box className="average-stats-menu-divider" />
          {}
          <Box className="average-stats-menu-footer">
            <Typography
              variant="span"
              className="average-stats-menu-footer-title"
            >
              {CheckValueLocale("avg_change_by_period", "", {}, intl)}
            </Typography>
            <Box
              className={classNames(
                "average-stats-menu-footer-avg",
                statsClassMapper[selectedCharType],
              )}
            >
              <FontAwesomeIcon icon={statsArrowChartMapper[selectedCharType]} />
              <Typography
                variant="span"
                className="average-stats-menu-footer-avg-percentage"
              >
                {renderPercentage(selectedStats?.percentage)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Menu>
    </Grid>
  );
};

const TooltipDown = ({ children, title }) => {
  return (
    <Tooltip
      title={title}
      placement="top"
      arrow
      classes={{ tooltip: "average-stats-card-name-tooltip" }}
    >
      {children}
    </Tooltip>
  );
};

const renderPercentage = (percentage) => {
  const fixedPercentage = percentage?.toFixed(1);

  if (percentage >= 0) {
    return `%${fixedPercentage}`;
  } else {
    return `-%${Math.abs(fixedPercentage)}`;
  }
};

export default ChartAvgStatsWidget;
