import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import {
  faFacebook,
  faInstagram,
  faIntercom,
  faWhatsapp,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as Gmail } from "images/social-icons/Gmail_icon.svg";
import Genesys from "images/shared-images/genesys.svg";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import GoogleMyBusiness from "images/shared-images/gmb.svg";
import ln from "images/social-icons/ln.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const Header = ({ setOpenDetailsSideBar, dataSourceName }) => {
  const type = dataSourceName.toLowerCase();

  const handleClose = () => {
    setOpenDetailsSideBar(false);
  };
  const getSocialIcon = (type) => {
    const SocialIcons = {
      facebook: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="facebook" icon={faFacebook} />
        </Box>
      ),
      twitter: (
        <Box className={type + "-icon user-source"}>
          <img src={xPlatform} alt="x-platform-logo" className="twitter" />
        </Box>
      ),
      instagram: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="twitter" icon={faInstagram} />
        </Box>
      ),
      tiktok: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className={type} icon={faTiktok} />
        </Box>
      ),
      genesys: (
        //cane icon x
        <Box className={type + "-icon user-source"}>
          <img src={Genesys} alt={"Genesys"} />
        </Box>
      ),
      intercom: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className={type} icon={faIntercom} />
        </Box>
      ),
      gmail: (
        <Box className={type + "-icon user-source"}>
          <Gmail />
        </Box>
      ),
      whatsapp: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className={type} icon={faWhatsapp} />
        </Box>
      ),
      google: (
        <Box className={type + "-icon user-source"}>
          <img src={GoogleMyBusiness} alt={"GoogleMyBusiness "} />
        </Box>
      ),
      linkedin: (
        //change icon
        <Box className={type + "-icon user-source"}>
          <img src={ln} alt="x-platform-logo" className={type} />
        </Box>
      ),
    };
    return SocialIcons[type];
  };
  return (
    <Box className="details-side-bar-header">
      <Box className="side-bar-name-wrapper">
        {getSocialIcon(type)}
        <span className="side-bar-name">
          {CheckValueLocale("profile", "", {}, useIntl())}
        </span>
      </Box>
      <Box className="side-bar-close-wrapper">
        <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
      </Box>
    </Box>
  );
};
export default Header;
