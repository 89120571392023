import {
  Box,
  Container,
  Typography,
  Grid,
  Stack,
  Avatar,
  Tooltip,
  Button,
} from "@mui/material";
import "../viewUser.scss";
import { CheckValueLocale, maskEmail } from "utils/helpers";
import { useIntl } from "react-intl";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import MailIcon from "@mui/icons-material/Mail";
import GroupsIcon from "@mui/icons-material/Groups";
import DefaultUser from "images/shared-images/user-management-default.svg";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
export const ViewUserMainCard = ({ userData }) => {
  const intl = useIntl();
  return (
    <Box className="main-view-user">
      <Box className="main-edit-user-card">
        <Typography className="edit-sec-tit">
          {CheckValueLocale("user_information_view", "", {}, intl)}
        </Typography>
        <Box className="edit-sec-container">
          {/*user  photo*/}
          <Box className="edit-user-img">
            <Grid item lg={3} align="center" md={5} sm={12} xs={12}>
              <Stack direction="row" justifyContent="center">
                <UserAvatar
                  fullName={userData?.name}
                  avatarSrc={userData?.avatar}
                  sizes={{ ratio: "150px", fontSize: "42px" }}
                />
              </Stack>
            </Grid>

            {/*user info box */}
            <Box className="view-info-box">
              <Typography className="info-text-header" variant="span">
                {`${userData?.first_name} 
                    ${userData?.last_name}`}
              </Typography>
              {userData?.job_title}
              {userData?.phone ? (
                <Typography className="info-text-icon" variant="span">
                  <PhoneEnabledIcon />
                  <Typography variant="span" className="view-phone">
                    {userData?.phone}
                  </Typography>
                </Typography>
              ) : null}
              {userData?.mail ? (
                <Typography className="info-text-icon" variant="span">
                  <MailIcon /> {maskEmail(userData?.mail)}
                </Typography>
              ) : null}
              {userData?.teams?.length ? (
                <Typography className="info-text-icon" variant="span">
                  <GroupsIcon />
                  {userData?.teams?.map((team) => (
                    <Typography className="acc-box"> {team?.name} </Typography>
                  ))}
                </Typography>
              ) : null}
              {userData?.status ? (
                <Box
                  className={`user-header-state  ${userData?.status?.toLowerCase()}`}
                >
                  {CheckValueLocale(
                    userData?.status?.toLowerCase(),
                    "",
                    {},
                    intl,
                  )}
                </Box>
              ) : null}
            </Box>
            {/*user lang box */}
            {userData?.hasEngagements ? (
              <Box className="view-lang-box">
                <Typography className="lang-box-header" variant="span">
                  {CheckValueLocale("languages", "", {}, intl)}
                </Typography>
                {userData?.languages?.length ? (
                  <Box className="view-lang-mini-box">
                    {userData?.languages?.map((lang) => {
                      return (
                        <Typography className="lang-box" variant="span">
                          {intl.locale === "en" ? lang?.name : lang?.ar_name}
                          {lang?.proficiency ? (
                            <Typography className="lang-box-pro" variant="span">
                              {`(${CheckValueLocale(
                                lang?.proficiency?.toLowerCase(),
                                "",
                                {},
                                intl,
                              )})`}
                            </Typography>
                          ) : null}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography className="lang-box-sub">
                    {CheckValueLocale("no_languages_assigned", "", {}, intl)}
                  </Typography>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewUserMainCard;
