import Services from "services/api/Services.js";

const sendRabbitMQRequest = (
  mqttConnectionClosed,
  clientMQTT,
  startDate,
  endDate,
  getCredentials,
  getFiltersFileds,
) => {
  const script = document.createElement("script");
  script.src = "https://unpkg.com/paho-mqtt@1.0.4/paho-mqtt.js";
  document.head.appendChild(script);
  window.scrollTo(0, 0);

  getCredentials(startDate, endDate);
  getFiltersFileds();
  return () => {
    if (clientMQTT.current && mqttConnectionClosed.current === false) {
      clientMQTT.current.disconnect();
    }
    document.head.removeChild(script);
  };
};

const connectWithRabbitMQ = (
  mqttConnectionClosed,
  clientMQTT,
  data,
  setResponse,
) => {
  const { wsbroker, wsport } = Services;
  var client = new window.Paho.MQTT.Client(
    wsbroker,
    wsport,
    "/ws",
    data?.queue_name,
  );
  // Disconnect From old MQTT
  if (clientMQTT.current) {
    clientMQTT.current.disconnect();
  }

  clientMQTT.current = client;

  client.onConnectionLost = function (responseObject) {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("CONNECTION LOST - " + responseObject.errorMessage);
    }
    if (responseObject.reconnect) {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log("Automatic reconnect is currently active.");
      }
    } else {
      //alert('Lost connection to host.')
      mqttConnectionClosed.current = true;
    }
  };

  client.onMessageArrived = (message) => {
    // Checking that the message Destination name equals to our current client id
    // This will be used to ensure that we are connected to the right client
    // In case of message arrived before Disconnect function of old client, we can ignore it easily
    // Here we ensure always that we will depend on that each clientId contains (frontend_) at the beginning
    // We slice after clientId and it must always start with (frontend_)
    if (clientMQTT.current.clientId.slice(9) == message.destinationName) {
      var data = JSON.parse(message.payloadString);
      // Temporary Solution to ignore Email at Insights to avoid Errors
      if (window.location.host !== "cxm.lucidya.com") {
        console.log(data); // [Commented as it still needed by team members]
      }
      setResponse(data);
    }
  };

  var options = {
    timeout: 120,
    keepAliveInterval: 10,
    reconnect: false, // Enable automatic reconnect
    useSSL: true,
    onSuccess: () => {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log("CONNECTION SUCCESS");
      }
      client.subscribe(data.routing_key, { qos: 1 });
    },
    onFailure: (message) => {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log("CLIENT CONNECTION FAILURE - " + message.errorMessage);
      }
    },
    userName: data.username,
    password: data.password,
  };
  client.connect(options);
};

export { sendRabbitMQRequest, connectWithRabbitMQ };
