import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import styles from "../commonStyle.module.css";

const YesNoQuestion = (props) => {
  const { question, questionNo, handleProceedAnswer } = props;
  const intl = useIntl();

  const [selectedValue, setSelectedValue] = useState(null);
  const optionNo = [
    CheckValueLocale("a", "", {}, intl),
    CheckValueLocale("b", "", {}, intl),
  ];

  useEffect(() => {
    if (question) setSelectedValue(null);
  }, [question]);

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {question?.question?.text}
          {question?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{question?.question?.description}</Box>
        {question?.question?.items?.map((val, index) => {
          return (
            <Box
              className={`${styles.mcqOptions} ${
                selectedValue === Object.keys(val)[0]
                  ? styles.selected
                  : styles.unSelected
              }`}
              onClick={(e) => {
                setSelectedValue(Object.keys(val)[0]);
                if (question?.is_proceed_on_answer)
                  handleProceedAnswer(val?.next_section);
              }}
            >
              <Box>{`${optionNo[index]}-`}</Box>
              <Box className={styles.optionValue}>
                {Object.values(val)[0]
                  ? CheckValueLocale(Object.values(val)[0], "", {}, intl)
                  : ""}
              </Box>
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default YesNoQuestion;
