import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import InsightsPageHeader from "pages/channelAnalytics/InsightsPage/insightsPageHeader.js";
import { connectWithRabbitMQ } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions.js";
import FiltersController from "services/controllers/filtersController";
import moment from "moment";
import { isEmptyObj } from "utils/helpers";
import InteractionsBody from "./linkedInInteractionBody";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";
import InteractionsController from "services/controllers/interactionsController.js";
import styles from "pages/channelAnalytics/InsightsPage/insightsPage.module.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActiveParams } from "shared/channel-analytics-filter/activeFilters/activeFilters";

const LinkedInInteractionPage = () => {
  const urlParams = useParams();
  const dataSourceId = urlParams?.data_source_id;
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");

  //breadcrumb Links
  const links = [
    {
      name: "analytics",
      url: "insights/main",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true" ? `/linkedin-channel/${dataSourceId}` : ""
      }`,
    },
    {
      name: "linkedin",
      url: `insights/social-media/linkedin-channel/${dataSourceId}`,
    },
  ];
  /* Start Redux Declarations */
  const reduxStartDateUx = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );

  const reduxEndDateUx = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );

  const reduxFilterParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );
  /* End Redux Declarations */

  const [response, setResponse] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  let startDate = reduxStartDateUx;
  let endDate = reduxEndDateUx;
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [resetAllFields, setResetAllFields] = useState(0);
  const [page, setPage] = useState(1);
  const [filterParamsMainPage, setFilterParamsMainPage] = useState();
  const [loader, setLoader] = useState();
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  const handleChangePage = (event, page) => {
    setLoader(true);
    setPage(page);
    getCredentials(startDate, endDate, filterParamsMainPage, "", page);
  };

  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);

  const getCredentials = (startDate, endDate, filterParams, custom, page) => {
    let pageParam = "";
    if (page === undefined || page === "") {
      pageParam = 1;
    } else {
      pageParam = page;
    }
    setResponse([]);
    setResetAllFields((prev) => prev + 1);
    InteractionsController.getInteractionsLinkedInCredentials(
      startDate,
      endDate,
      filterParams,
      custom,
      window?.localStorage?.cxm_id,
      dataSourceId,
      pageParam,
    ).then((data) => {
      if (!isEmptyObj(data?.data)) {
        setPreLoaderTrackerDataSources(data?.data?.data_sources);
        connectWithRabbitMQ(
          mqttConnectionClosed,
          clientMQTT,
          data?.data,
          setResponse,
        );
      } else {
        mqttConnectionClosed.current = true;
      }
      setLoader(false);
    });
  };

  const applyFilter = (startDate, endDate, filterParams) => {
    setResetAllFields((prev) => prev + 1);
    setPage(1);
    setAssignActiveFilters(reduxFilterParams);

    getCredentials(startDate, endDate, reduxFilterParams, true, 1);
  };

  const getFiltersFileds = (productId) => {
    FiltersController.getLinkedInInteractionsFiltersFields(
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      setFilterFields(data.data);
    });
  };

  let activeFiltersArr = getActiveParams(assignActiveFilters);
  useEffect(() => {
    getDataSources(setDataSources);
    getFiltersFileds();
  }, []);

  return (
    <Container
      maxWidth="xl"
      className={`${styles.insightsStyle} bread-crumb-page-style`}
    >
      <InsightsPageHeader
        title="linkedin_interaction"
        showNewChannelBtn={false}
        showDatePicker={true}
        showFiltersBtn={true}
        filterFields={filterFields}
        applyFilter={applyFilter}
        startDate={startDate}
        endDate={endDate}
        showBreadCrumb={false}
        breadCrumbLinks={links}
        setFilterParamsMainPage={setFilterParamsMainPage}
        assignActiveFilters={assignActiveFilters}
        allActiveFilters={activeFiltersArr}
        sectionName="linkedin_public"
        luciFeature="Analytics"
        dataSourceId={dataSourceId}
        isInteractionsPage
        dataLoaded={checkAllDataExist}
        dataEmpty={checkDataIsEmpty}
      />
      <InteractionsBody
        response={response}
        dataSources={dataSources}
        resetAllFields={resetAllFields}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        handleChangePage={handleChangePage}
        page={page}
        loader={loader}
        assignActiveFilters={assignActiveFilters}
        setAssignActiveFilters={setAssignActiveFilters}
        startDate={startDate}
        endDate={endDate}
        applyFilter={applyFilter}
        allActiveFilters={activeFiltersArr}
        filterFields={filterFields}
        setCheckAllDataExist={setCheckAllDataExist}
        setCheckDataIsEmpty={setCheckDataIsEmpty}
      />
    </Container>
  );
};

export default LinkedInInteractionPage;
