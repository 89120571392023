import NewPostAlertTwitter from "pages/alerts/components/alertTypesView/alertDataSources/alertViewTwitter.js";
import NewPostAlertNewsBlogs from "pages/alerts/components/alertTypesView/alertDataSources/alertViewNewsBlogs.js";
import NewPostAlertInstagram from "pages/alerts/components/alertTypesView/alertDataSources/alertViewInstagram.js";
import NewPostAlertFacebook from "pages/alerts/components/alertTypesView/alertDataSources/alertViewFacebook.js";

const SLDatasourcesAlerts = (props) => {
  let {
    response,
    alertDetailDataSource,
    alertDetailMonitorId,
    resetAllFields,
    alertMonitorType,
    page,
    setPage,
    alertsDataWidgets,
  } = props;
  return alertDetailDataSource === "twitter" ? (
    <NewPostAlertTwitter
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      resetAllFields={resetAllFields}
      page={page}
      setPage={setPage}
      alertsDataWidgets={alertsDataWidgets}
    />
  ) : alertDetailDataSource === "newsblogs" ? (
    <NewPostAlertNewsBlogs
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      resetAllFields={resetAllFields}
      alertsDataWidgets={alertsDataWidgets}
    />
  ) : alertDetailDataSource === "instagram" ? (
    <NewPostAlertInstagram
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      resetAllFields={resetAllFields}
      alertMonitorType={alertMonitorType}
      alertDetailDataSource={alertDetailDataSource}
      alertsDataWidgets={alertsDataWidgets}
    />
  ) : alertDetailDataSource === "facebook" ? (
    <NewPostAlertFacebook
      response={response}
      alertDetailMonitorId={alertDetailMonitorId}
      resetAllFields={resetAllFields}
      alertsDataWidgets={alertsDataWidgets}
    />
  ) : null;
};

export default SLDatasourcesAlerts;
