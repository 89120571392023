import { FormControl, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./styles/groupByClientAgent.scss";

const GroupBy = (props) => {
  const intl = useIntl();
  const [groupDuration, setGroupDuration] = useState("day");
  const groupByData = ["day", "weekly", "monthly", "yearly"];

  let { groupByWidgetId } = props;

  const showGroupData = (duration) => {
    setGroupDuration(duration);
    if (duration === "day") {
      props.getChartMeta(props.data, duration);
    } else {
      let groupedData = getGroupedData(props.data, duration);
      props.getChartMeta(groupedData, duration);
    }
  };

  const getGroupedData = (data, duration) => {
    let groupedData = [];
    let period;
    let preData = data;
    let objWithKeys = {};
    let totalDataObj = {};
    let dataObject = {};

    if (props.nestedLoop === true) {
      // eslint-disable-next-line no-loop-func
      for (const [key, x] of Object.entries(preData)) {
        let secDataObject = {};
        // eslint-disable-next-line no-loop-func
        x.map((itemSec) => {
          period = moment.unix(Number(itemSec.name)).utc().format("YYYY-MM-DD");
          if (duration === "yearly") {
            period = moment(period).year();
          } else if (duration === "weekly") {
            const beginningOfWeek = moment(period)
              .week(Number(period))
              .startOf("week")
              .format("YYYY-MM-DD");
            const endOfWeek = moment(period)
              .week(Number(period))
              .startOf("week")
              .add(6, "days")
              .format("YYYY-MM-DD");

            period = beginningOfWeek + "\n" + endOfWeek;
          } else if (duration === "monthly") {
            period =
              moment(period).year() +
              "-" +
              moment(period).month(period).format("MMM");
          }

          if (period in totalDataObj) {
            totalDataObj[period] += itemSec?.native_value
              ? itemSec?.native_value
              : null;
          } else {
            totalDataObj[period] = itemSec?.native_value
              ? itemSec?.native_value
              : null;
          }

          if (period in secDataObject) {
            secDataObject[period] += itemSec?.native_value
              ? itemSec?.native_value
              : itemSec?.value;
          } else {
            secDataObject[period] = itemSec?.native_value
              ? itemSec?.native_value
              : itemSec?.value;
          }
        });
        objWithKeys[key] = secDataObject;
      }
      var nestedObj = {};
      for (const [key, value] of Object.entries(objWithKeys)) {
        var nestedArr = [];
        for (const [k, v] of Object.entries(value)) {
          if (
            props.title === "messages_statues" ||
            (props.hidePercentage !== undefined && props.hidePercentage)
          ) {
            nestedArr.push({
              name: k,
              value: totalDataObj[k] !== 0 ? v / totalDataObj[k] : v,
              native_value: v,
            });
          } else {
            if (duration === "weekly") {
              nestedArr.push({
                name: k,
                value: totalDataObj[k] !== 0 ? v / totalDataObj[k] : v,
                native_value: v,
              });
            } else if (duration === "monthly") {
              nestedArr.push({
                name: k,
                value: totalDataObj[k] !== 0 ? v / totalDataObj[k] : v,
                native_value: v,
              });
            } else if (duration === "yearly") {
              nestedArr.push({
                name: k,
                value: totalDataObj[k] !== 0 ? v / totalDataObj[k] : v,
                native_value: v,
              });
            } else {
              nestedArr.push({
                name: k,
                value: totalDataObj[k] !== 0 ? v / totalDataObj[k] : v,
                native_value: v,
              });
            }
          }
        }

        nestedObj[key] = nestedArr;
      }
      groupedData = nestedObj;
    } else {
      data.map((item) => {
        period = moment.unix(Number(item.name)).utc().format("YYYY-MM-DD");
        if (duration === "yearly") {
          period = moment(period).year();
        } else if (duration === "weekly") {
          const beginningOfWeek = moment(period)
            .week(Number(period))
            .startOf("week")
            .format("YYYY-MM-DD");
          const endOfWeek = moment(period)
            .week(Number(period))
            .startOf("week")
            .add(6, "days")
            .format("YYYY-MM-DD");

          period = beginningOfWeek + "\n" + endOfWeek;
        } else if (duration === "monthly") {
          period =
            moment(period).year() +
            "-" +
            moment(period).month(period).format("MMM");
        }

        if (period in dataObject) {
          dataObject[period] += item.value;
        } else {
          dataObject[period] = item.value;
        }
      });
      for (const property in dataObject) {
        groupedData.push({ name: property, value: dataObject[property] });
      }
    }
    return groupedData;
  };

  return (
    <FormControl
      className="select-widget-dropdown select-groupby-dropdown"
      id={groupByWidgetId ?? "select-groupby-dropdown"}
    >
      <TextField
        select
        label={CheckValueLocale("group_by", "", {}, intl)}
        value={groupDuration}
        onChange={(e) => showGroupData(e.target.value)}
        className="dropdown-field"
      >
        {groupByData.map((option) => (
          <MenuItem
            key={option}
            value={option}
            id={`${groupByWidgetId ?? "groupByWidgetId"}-${
              option ?? "option"
            }-item`}
          >
            {CheckValueLocale(option, "", {}, intl)}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
export default GroupBy;
