import { useIntl } from "react-intl";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CheckValueLocale } from "utils/helpers";
import "../../listQuestions.scss";

const SuccessAnswer = ({ setSnackbarShow, setShowLuciAnswers }) => {
  const intl = useIntl();

  return (
    <Box
      className="luci-question-incoming-answer"
      onClick={() => {
        setSnackbarShow(false);
        setShowLuciAnswers(true);
      }}
    >
      <Typography component={"p"} className="question-item-data">
        <FontAwesomeIcon icon={faCheckCircle} className="luci-incoming-icon" />
        {CheckValueLocale("luci_incoming_success", "", {}, intl)}
      </Typography>
      <KeyboardArrowRightIcon className="question-arrow-icon" />
    </Box>
  );
};

export default SuccessAnswer;
