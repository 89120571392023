import { faPercent, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import BarChartWidget from "components/widgets/barChartWidget";
import { useIntl } from "react-intl";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CustomerCareSection = (props) => {
  let { TwitterIcon, twitterWidgets, preLoaderTrackerDataSources } = props;
  const intl = useIntl();
  const [isHiddenTw, setIsHiddenTw] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("customer_care")) {
      setIsHiddenTw(false);
    } else {
      setIsHiddenTw(true);
    }
  }, []);

  const handleClick = () => {
    localStorage.setItem("customer_care", false);
    setIsHiddenTw(false);
  };
  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("customer_care_page", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        {isHiddenTw ? (
          <Box className="customer-care-alert margin-bottom">
            <Box className="start-box">
              <InfoOutlinedIcon />
              <Typography>
                {CheckValueLocale("customer_care_alerts_channel", "", {}, intl)}
              </Typography>
            </Box>
            <Box className="end-box">
              <Button onClick={() => history.push("/manage-channels/list/")}>
                {CheckValueLocale("manage_channels", "", {}, intl)}
              </Button>
              <FontAwesomeIcon onClick={handleClick} icon={faTimes} />
            </Box>
          </Box>
        ) : null}
        <div className="chart-body">
          <BarChartWidget
            title={"customer_performance"}
            titleToolTip={"customer_performance_ig_tooltip"}
            data={twitterWidgets?.customerCareData}
            showAI={true}
            showDownloadIcon={true}
            showChartStatsWidget={true}
            showZoomPercentage
            customerCareChart
            gridHeight={"70%"}
            respTimeDistributionTooltip="respone_time_distribution_tooltip"
            statsData={[
              {
                name: "total_ques",
                value:
                  twitterWidgets?.customerCarePerformance
                    ?.total_number_of_questions &&
                  commasAfterDigit(
                    twitterWidgets?.customerCarePerformance
                      ?.total_number_of_questions,
                  ),
                icon: faQuestion,
                titleToolTip: "number_of_ques_tooltip",
              },
              {
                name: "number_of_answered_questions",
                value: isNaN(twitterWidgets?.respRate * 100)
                  ? 0 + "%"
                  : twitterWidgets?.respRate.toFixed(2) * 100 + "%",
                icon: faPercent,
                titleToolTip: "response_rate_tooltip",
              },
              {
                name: "avg_response_time_in_seconds",
                value:
                  twitterWidgets?.customerCarePerformance
                    ?.avg_response_time_in_seconds,
                icon: faClock,
                type: "time",
                titleToolTip: "avg_response_time_tooltip",
              },
            ]}
            dataColor="#2AAAE2"
            toolTipLabel={"posts"}
            isTwitterAccount
            iconAfterTitle={TwitterIcon}
            barChartPreLoaderDataSources={twitterWidgets?.customerCarePreloader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </div>
      </Box>
    </>
  );
};

export default CustomerCareSection;
