import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import { faShare, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import pdfIcon from "images/files-icons/pdf.svg";
import txtIcon from "images/files-icons/txt-icon.svg";
import pptIcon from "images/files-icons/ppt-icon.svg";
import docsIcon from "images/files-icons/docs-icon.svg";
import xlsxIcon from "images/files-icons/xlsx-icon.svg";

import contactIcon from "images/contact.svg";
import markerIcon from "images/marker.svg";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import * as L from "leaflet";
import { CheckValueLocale } from "utils/helpers";

const InteractionListMessageWhatsapp = (props) => {
  const intl = useIntl();

  const LeafIcon = L.Icon.extend({
    options: {},
  });
  const icon = new LeafIcon({
    iconUrl: markerIcon,
  });

  const getFileIcon = (type) => {
    const fileIcons = {
      "text/plain": <img src={txtIcon} />,
      "application/pdf": <img src={pdfIcon} />,
      "application/vnd.ms-powerpoint": <img src={pptIcon} />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <img src={pptIcon} />,
      "application/msword": <img src={docsIcon} />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <img src={docsIcon} />,
      "application/vnd.ms-excel": <img src={xlsxIcon} />,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
        <img src={xlsxIcon} />
      ),
    };
    return fileIcons[type];
  };

  const checkFileType = (type) => {
    const fileType = {
      "text/plain": "text/plain",
      "application/pdf": "application/pdf",
      "application/vnd.ms-powerpoint": "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword": "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel": "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    return fileType[type];
  };

  return (
    <>
      {/* Handle Whatsapp Text to be appeared only in case of whatsapp */}
      {props.forwardedMessageFlag === true && (
        <div className="forwarded-message">
          <FontAwesomeIcon icon={faShare} />
          {CheckValueLocale("forwarded", "", {}, intl)}
        </div>
      )}

      {props?.attachementType === "text" ? (
        <div className="whats-text">
          <p>{props?.text}</p>
        </div>
      ) : null}

      {/* img integration For whatsapp*/}
      {props?.attachementType !== null &&
        props?.attachementType !== undefined &&
        props?.attachementType == "image/jpeg" && (
          <div className="interaction-img-div">
            {<img className="interaction-img" src={props?.attachementLink} />}
            {props?.text}
            <Link
              to={`${props.link}/${props?.id}/${props?.tracker_id}/${props?.channel_id}`}
            ></Link>
          </div>
        )}

      {props?.attachementType !== null &&
        props?.attachementType !== undefined &&
        props?.attachementType == "image/webp" && (
          <div className="interaction-img-div">
            {
              <picture>
                <source srcset={props?.attachementLink} type="image/webp" />
                <img src={props?.attachementLink} />
              </picture>
            }
            {props?.text}
          </div>
        )}

      {/* video integration For whatsapp */}
      {props?.attachementType !== null &&
        props?.attachementType !== undefined &&
        props?.attachementType == "video/mp4" && (
          <div className="whats-video">
            <video
              width="250"
              height="200"
              controls
              className="interaction_vid"
            >
              <source src={props?.attachementLink} type="video/mp4" />
            </video>
            {props?.text}
            <Link
              to={`${props.link}/${props?.id}/${props?.tracker_id}/${props?.channel_id}`}
            ></Link>
          </div>
        )}
      {/* audio integration For whatsapp */}
      {props?.attachementType !== null &&
        props?.attachementType !== undefined &&
        props?.attachementType == "audio/ogg" && (
          <div className="whats-audio">
            <audio controls className="interaction_audio">
              <source src={props?.attachementLink} type="audio/ogg" />
            </audio>
            {props?.text}
            <Link
              to={`${props.link}/${props?.id}/${props?.tracker_id}/${props?.channel_id}`}
            ></Link>
          </div>
        )}

      {/* PDF File */}
      {props?.attachementType !== undefined &&
        props?.attachementType === checkFileType(props?.attachementType) && (
          <div className="whats-pdf">
            <a
              href={props?.obj?.message?.attachement?.link}
              className="pdfDiv"
              download
              target="_blank"
              rel="noreferrer"
            >
              <iframe
                frameborder="0"
                src={
                  checkFileType(props?.attachementType) !==
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ? props?.obj.message?.attachement?.link
                    : null
                }
                width="330px"
                height="200px"
              />
              {props?.obj.message.text !== null && (
                <p className="pdf-title">
                  {getFileIcon(props?.attachementType)}
                  {props?.obj?.message?.text}
                </p>
              )}
            </a>
          </div>
        )}

      {/* Location integration */}
      {props?.attachementType !== null &&
        props?.attachementType !== undefined &&
        props?.attachementType === "location" && (
          <div className="map-container">
            <MapContainer
              center={[
                props?.obj?.message?.attachement?.latitude[0]
                  ? props?.obj?.message?.attachement?.latitude[0]
                  : null,
                props?.obj?.message?.attachement?.longitude[0]
                  ? props?.obj?.message?.attachement?.longitude[0]
                  : null,
              ]}
              zoom={13}
            >
              <TileLayer
                attribution=" "
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
              <Marker
                position={[
                  props?.obj?.message?.attachement?.latitude[0]
                    ? props?.obj?.message?.attachement?.latitude[0]
                    : null,
                  props?.obj?.message?.attachement?.longitude[0]
                    ? props?.obj?.message?.attachement?.longitude[0]
                    : null,
                ]}
                icon={icon}
              >
                <Popup>
                  <span>{props?.obj?.message?.attachement?.label[0]}</span>
                </Popup>
              </Marker>
            </MapContainer>
            <div className="loction-info">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>{props?.obj?.message?.attachement?.label[0]}</span>
            </div>
          </div>
        )}
      {props?.attachementType !== null &&
        props?.attachementType !== undefined &&
        props?.attachementType === "contact" && (
          <div className="contact-div">
            <a
              href={
                props.obj.message.attachement.link !== undefined &&
                props.obj.message.attachement.link !== null
                  ? props.obj.message.attachement.link
                  : ""
              }
              className="contact-download"
              download
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faArrowToBottom} />
            </a>
            {props?.obj.message.attachement.customer_phone_number !== null && (
              <div className="contact-details">
                <img src={contactIcon} />
                <div className="contact-info">
                  <h3>{CheckValueLocale("Contact", "", {}, intl)}</h3>
                  <span>{props?.obj.message.customer_phone_number}</span>
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default InteractionListMessageWhatsapp;
