import { TeamsTable } from "./teamsTable";
import { Box, Typography } from "@mui/material";
import TeamsNotFound from "images/shared-images/no-teams-found.svg";
import CreateTeam from "images/shared-images/create-team.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { sanitize } from "dompurify";

export const TeamsPreview = (props) => {
  const { teams, search, tableEmptyBody } = props;
  if (teams?.length === 0) {
    return <TeamsTable {...props} tableEmptyBody={tableEmptyBody} />;
  }

  return <TeamsTable {...props} tableEmptyBody={tableEmptyBody} />;
};
