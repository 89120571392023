import React from "react";
import { MonitorContainer } from "pages/Monitors/Components/sidebar/monitor.styles";
import CustomerCareBody from "./customerCareBody";

const CustomerCarePage = (props) => {
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <CustomerCareBody {...props} />
    </MonitorContainer>
  );
};

export default CustomerCarePage;
