import { Avatar, Box, Divider, Typography } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import AIAgentPublishedIcon from "images/ai-agents/ai-agent-published.svg";
import AIAgentPublishedFilledIcon from "images/ai-agents/ai-agent-published-filled.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import moment from "moment";
import { useContext } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import UserAvatar from "shared/unifiedUserImage/userAvatar";

const AiAgentPublishedVersionItem = ({
  changedByImage,
  changedBy,
  date,
  isLastItem,
  id,
  index,
  itemRef,
  closeDrawer,
  edited,
  publishedVersionResources,
}) => {
  const intl = useIntl();
  const isCurrentVersion = index === 0;
  const {
    selectedAiAgentPublishedVersion,
    setSelectedAiAgentPublishedVersion,
    setPublishedVersionResources,
  } = useContext(AiAgentsContext);
  const currentVersionIcon = isCurrentVersion
    ? AIAgentPublishedFilledIcon
    : AIAgentPublishedIcon;

  const formattedDate = moment(date)
    .tz("utc", true)
    .format("D MMMM YYYY , hh:mm A");

  const handleChangeAiAgentSelected = () => {
    closeDrawer();
    if (selectedAiAgentPublishedVersion === id) return;
    setSelectedAiAgentPublishedVersion(id);
    setPublishedVersionResources(publishedVersionResources);
  };

  return (
    <>
      <Box ref={itemRef} className="ai-agent-published-version-item-container">
        <Box className="ai-agent-published-version-icon-info-container">
          <img src={currentVersionIcon} alt="published" />
          <Box className="ai-agent-version-info-container">
            {isCurrentVersion && (
              <Typography className="ai-agent-version-typography-info">
                {CheckValueLocale("current_version", "", {}, intl)}
              </Typography>
            )}
            <Typography
              dir="ltr"
              className="ai-agent-version-date-typography-info"
            >
              {formattedDate}
            </Typography>
            <Box className="ai-agent-version-changed-by-avatar-container">
              <UserAvatar
                avatarSrc={changedByImage}
                fullName={changedBy}
                sizes={{ ratio: "16px", fontSize: "7px" }}
              />
              <Typography className="ai-agent-version-changed-by-typography-info">
                {changedBy}
              </Typography>
            </Box>
          </Box>
        </Box>
        <LucButton
          disabled={!edited && index === 0}
          onClick={handleChangeAiAgentSelected}
          variant="outline"
          size="small"
          id="ai-agent-button-view-published-version"
        >
          {CheckValueLocale("view_published_version", "", {}, intl)}
        </LucButton>
      </Box>
      {!isLastItem && (
        <Divider
          orientation="horizontal"
          className="ai-agent-published-divider"
        />
      )}
    </>
  );
};

export default AiAgentPublishedVersionItem;
