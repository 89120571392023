import { faPercent, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import BarChartWidget from "components/widgets/barChartWidget";
import { useIntl } from "react-intl";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
//import "./customerCare.scss";

const CustomerCareSection = (props) => {
  let { tikTokIcon, tikTokWidgets, preLoaderTrackerDataSources } = props;
  const intl = useIntl();

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("customer_care_page", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        <div className="chart-body">
          <BarChartWidget
            title={"customer_performance"}
            titleToolTip={"cust_tolt"}
            data={tikTokWidgets?.customerCareData}
            showAI={true}
            showDownloadIcon={true}
            showChartStatsWidget={true}
            showZoomPercentage
            customerCareChart
            gridHeight={"70%"}
            respTimeDistributionTooltip="respone_time_distribution_tooltip"
            statsData={[
              {
                name: "total_ques",
                value:
                  tikTokWidgets?.customerCarePerformance
                    ?.total_number_of_questions &&
                  commasAfterDigit(
                    tikTokWidgets?.customerCarePerformance
                      ?.total_number_of_questions,
                  ),
                icon: faQuestion,
                titleToolTip: "number_of_ques_tooltip",
              },
              {
                name: "number_of_answered_questions",
                value: isNaN(tikTokWidgets?.respRate * 100)
                  ? 0 + "%"
                  : tikTokWidgets?.respRate.toFixed(2) * 100 + "%",
                icon: faPercent,
                titleToolTip: "response_rate_tooltip",
              },
              {
                name: "avg_response_time_in_seconds",
                value:
                  tikTokWidgets?.customerCarePerformance
                    ?.avg_response_time_in_seconds,
                icon: faClock,
                type: "time",
                titleToolTip: "avg_response_time_tooltip",
              },
            ]}
            dataColor="#2AAAE2"
            toolTipLabel={"posts"}
            isTwitterAccount
            iconAfterTitle={tikTokIcon}
            barChartPreLoaderDataSources={tikTokWidgets?.customerCarePreloader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </div>
      </Box>
    </>
  );
};

export default CustomerCareSection;
