import profileImg from "images/engagements-images/profileImg.png";
import "./profileUser.scss";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const AnonymousUser = () => {
  const intl = useIntl();

  return (
    <>
      <div className="profile-dropdown-heading">
        <div className="profile-dropdown-img">
          <img src={profileImg} />
        </div>
        <div className="profile-dropdown-text">
          <p className="profile-name">
            {CheckValueLocale("unknown_profile", "", {}, intl)}
          </p>
        </div>
      </div>
      <p className="profile-no-data">
        {CheckValueLocale("profile_has_no_data", "", {}, intl)}
      </p>
    </>
  );
};

export default AnonymousUser;
