import { Box, TextField, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { editMessageValidationSchema } from "../utils/farewellGreetingUtils";

const EditGreetingFarewellMessageModal = ({
  open,
  close,
  message,
  handleChangeMessageText,
}) => {
  const intl = useIntl();
  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      message: message?.message,
    },
    validationSchema: editMessageValidationSchema,
    onSubmit: (values) => {
      handleChangeMessageText({
        messageItem: message,
        newText: values.message,
      });
    },
  });

  const messageLength = formik.values?.message?.length;
  const isSpacesAll = !formik.values?.message?.trim();
  const isMaxCharactersReached = messageLength > 280;
  const isDisabled = isSpacesAll || isMaxCharactersReached;

  const handleEditMessage = () => {
    if (isDisabled) return;
    formik.handleSubmit();
  };

  const modalBody = (
    <Box>
      <Box className="edit-greeting-farewell-message-input-label-container">
        <Typography className="edit-greeting-farewell-message-input-label">
          {CheckValueLocale(`${message?.message_type}_message`, "", {}, intl)}
        </Typography>
        <Typography>{messageLength} / 280</Typography>
      </Box>
      <TextField
        multiline
        rows={3}
        variant="outlined"
        size="small"
        fullWidth
        value={formik.values.message}
        name="message"
        onChange={formik.handleChange}
        error={!!formik.errors.message}
        helperText={CheckValueLocale(formik.errors.message, "", {}, intl)}
        className="edit-greeting-farewell-message-input"
        FormHelperTextProps={{
          component: "div",
        }}
      />
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("edit_message", "", {}, intl)}
      body={modalBody}
      open={open}
      close={close}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("save_changes", "", {}, intl)}
      classeName="edit-greeting-farewell-message-modal"
      accept={handleEditMessage}
      disabled={isDisabled}
      addClasses="edit-greeting-farewell-message-modal-buttons"
    />
  );
};

export default EditGreetingFarewellMessageModal;
