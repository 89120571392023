import { Box, FormControlLabel, Switch } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SwitchElement = ({
  checked,
  onChange,
  name,
  label,
  descriptionLabel,
}) => {
  const intl = useIntl();
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} name={name} />}
      label={
        <Box className="keyword-switch-box-label">
          <Box className="keyword-switch-label">
            {CheckValueLocale(label, "", {}, intl)}
          </Box>
          {descriptionLabel ? (
            <Box className="keyword-switch-description-label">
              {CheckValueLocale(descriptionLabel, "", {}, intl)}
            </Box>
          ) : null}
        </Box>
      }
    />
  );
};

export default SwitchElement;
