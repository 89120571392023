import TopHashtags from "../api/topHashtags.js";

var TopHashtagsController = {};

TopHashtagsController.getOverall = (
  woe_ids,
  hashtags,
  start_date,
  end_date,
  per_page,
  page,
  sort_by,
  order_by,
) => {
  const queryData = {
    product_id: 1,
    user_type: true,
    woe_ids,
    function: "max",
    hashtags,
    start_date,
    end_date,
    page,
    per_page,
    sort_by,
    order_by,
  };

  return new Promise(function (resolve) {
    TopHashtags.getOverall(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

TopHashtagsController.getHourlyDaily = (woe_ids, hashtags, timeFrame) => {
  const queryData = {
    product_id: 1,
    user_type: true,
    woe_ids,
    function: "max",
    hashtags,
    timeFrame,
  };

  return new Promise(function (resolve) {
    TopHashtags.getHourlyDaily(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

TopHashtagsController.getLocation = () => {
  const queryData = {
    product_id: 1,
    user_type: true,
  };

  return new Promise(function (resolve) {
    TopHashtags.getLocation(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

TopHashtagsController.getAnalyzeData = (a_keyword) => {
  const queryData = {
    product_id: 1,
    user_type: true,
    a_keyword,
  };

  return new Promise(function (resolve) {
    TopHashtags.getAnalyzeData(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default TopHashtagsController;
