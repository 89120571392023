import { useEffect, useState } from "react";
import ExploreLoading from "images/explore-page/explore-loading.svg";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./exploreLoadingPage.scss";

const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text?.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return <>{currentText}</>;
};

const ExploreLoadingPage = () => {
  const intl = useIntl();

  useEffect(() => {
    let footer = document.getElementsByClassName("app-footer");
    if (footer?.length) {
      footer[0].style.display = "none";
    }
  }, []);

  return (
    <Box className="explore-loading-container">
      <img src={ExploreLoading} />
      <Box className="explore-matching-wrapper">
        {CheckValueLocale("explore_matching_result", "", {}, intl)}
        <Box className="explore-wait-container">
          {CheckValueLocale("explore_please_wait", "", {}, intl)}
          <Typewriter text={"..."} delay={500} />
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreLoadingPage;
