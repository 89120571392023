import React, { useEffect, useState } from "react";
import { Box, Switch } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../surveyBuilder.scss";

const Name = (props) => {
  const {
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
    }
  }, [question]);

  const handleSwitch = (e, constraint) => {
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints[constraint] = e.target.checked;
    setSurveySections([...surveySections]);
  };

  return (
    <>
      {Object.entries(question?.constraints)?.map((constraint, index) => {
        return (
          <Box
            className={`constraints-text ${
              editable === false && `disabled-color`
            }`}
            key={index}
          >
            <Box>{CheckValueLocale(constraint[0], "", {}, intl)}</Box>
            <Switch
              data-testid="required_switch"
              onChange={(e) => handleSwitch(e, constraint[0])}
              checked={Object.values(constraint)[1]}
              disabled={
                editable === true && constraint[0] !== "required" ? true : false
              }
            />
          </Box>
        );
      })}
    </>
  );
};

export default Name;
