import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Checkbox, FormControlLabel, Tooltip } from "@mui/material";

const RanksComponent = (props) => {
  const {
    isCreateMonitor,
    nbRanks,
    setNbRanks,
    setNbDuplicated,
    selectedSource,
    updatedParams,
    setUpdatedParams,
  } = props;
  const intl = useIntl();

  const ranksData = [
    { id: 1, tooltip: "all_sources_rank_1_tooltip" },
    { id: 2, tooltip: "all_sources_rank_2_tooltip" },
    { id: 3, tooltip: "all_sources_rank_3_tooltip" },
    { id: 4, tooltip: "all_sources_rank_4_tooltip" },
    { id: 5, tooltip: "all_sources_rank_5_tooltip" },
  ];

  const handleChangeRanks = async (e) => {
    let rankValue = +e.target.value;
    let rankChecked = e.target.checked;
    let newRanks = [...nbRanks];

    if (rankChecked) newRanks?.push(rankValue);
    else newRanks = newRanks?.filter((rank) => rank !== rankValue);
    if (newRanks?.length > 0) {
      setNbRanks(newRanks);
      if (!isCreateMonitor)
        setUpdatedParams({
          ...updatedParams,
          nb_ranks:
            newRanks?.length === 0
              ? ""
              : newRanks?.toString()?.split(",")?.join(","),
        });
    }
  };

  useEffect(() => {
    if (isCreateMonitor) {
      if (selectedSource === "major_sources") setNbRanks([]);
      else setNbRanks([1, 2, 3, 4, 5]);
    }
  }, [isCreateMonitor, selectedSource]);

  return (
    <Box className="ranks-form-group">
      {ranksData?.map((rank) => {
        return (
          <FormControlLabel
            id={`all-sources-rank-${rank?.id}`}
            key={rank?.id}
            label={
              <Tooltip
                id="rank-tooltip"
                placement="top"
                arrow
                title={CheckValueLocale(rank?.tooltip, "", {}, intl)}
              >
                <Box className="media-input-label">
                  {CheckValueLocale(
                    `all_sources_rank`,
                    "",
                    { num: rank?.id },
                    intl,
                  )}
                </Box>
              </Tooltip>
            }
            value={rank?.id}
            control={
              <Checkbox
                id={`all-sources-rank-${rank?.id}-checkbox`}
                checked={
                  nbRanks?.length && nbRanks?.includes(rank?.id) ? true : false
                }
                onChange={handleChangeRanks}
              />
            }
          />
        );
      })}
    </Box>
  );
};

export default RanksComponent;
