import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import PunchCardWidget from "components/widgets/punchCardWidget";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import { CheckValueLocale } from "utils/helpers";

const AccountOverViewSection = ({
  faceBookWidgets,
  preLoaderTrackerDataSources,
  FacebookIcon,
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Typography variant="h6" className="ca-head">
        {CheckValueLocale("account_overview", "", {}, intl).toUpperCase()}
      </Typography>

      {/* // NOTE: we commented this code until BE fixed these widgets. */}
      {/* <Box mt={3}>
        <LineChartWidget
          title={"page_views"}
          totalLabel={"views"}
          totalValue={faceBookWidgets?.totalPageViewsCount}
          data={faceBookWidgets?.pageViews}
          showGroupBy={true}
          toolTipLabel={"views"}
          showDownloadIcon
          bigImg
          iconAfterTitle={FacebookIcon}
          labelColor={"#2AAAE2"}
          titleToolTip={"page_views_fb_tooltip"}
          lineChartPreLoaderDataSources={
            faceBookWidgets?.pageViewsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Box my={3}>
        <StackedBarWidget
          title={"page_like_unlikes"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.pageLikeUnlike}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          colors={["#2AAAE2", "#80868C"]}
          labels={["unlike_page_over_time", "like_page_over_time"]}
          hideLegend
          dataPiechart={faceBookWidgets?.pageLikeUnlikeLegend}
          titleToolTip={"page_like_unlikes_fb_tooltip"}
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.pageContactDetailsInteractionsPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Box mt={3}>
        <StackedLineWidget
          title={"page_reach_impressions"}
          data={faceBookWidgets?.pageReachImpression}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          showDownloadIcon
          bigImg
          isSwitchCase
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          colors={["#2AAAE2", "#80868C"]}
          labelColors={["#2AAAE2", "#80868C"]}
          titleToolTip={"page_reach_impressions_fb_tooltip"}
          hideLegend
          dataPiechart={faceBookWidgets?.pageReachImpressionLegend}
          isPostsReachImpression
          stackedLineWidgetPreLoaderDataSources={
            faceBookWidgets?.pageReachImpressionPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Box my={3}>
        <StackedBarWidget
          title={"page_contact_details_interactions"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.pageContactDetailsInteractions}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={[
            "page_website_clicks_over_time",
            "page_call_phone_clicks_over_time",
            "page_directions_clicks_over_time",
          ]}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          titleToolTip={"page_contact_details_interactions_fb_tooltip"}
          hideLegend
          dataPiechart={faceBookWidgets?.pageContactDetailsLegend}
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.pageContactDetailsInteractionsPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Box my={3}>
        <StackedBarWidget
          title={"page_actions"}
          showDownloadIcon
          bigImg
          posts={faceBookWidgets?.pageActions}
          colors={["#C51162", "#4CAF50"]}
          labels={[
            "page_negative_interactions_over_time",
            "page_positive_interactions_over_time",
          ]}
          showZoomPercentage
          iconAfterTitle={FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          titleToolTip={"page_actions_fb_tooltip"}
          hideLegend
          dataPiechart={faceBookWidgets?.pageActionsLegend}
          postsColumnPreLoaderDataSources={
            faceBookWidgets?.pageActionsPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box> */}

      <Box my={3}>
        <PunchCardWidget
          title="account_authors_activity"
          clientActivity={faceBookWidgets?.accountAuthorActivity}
          minValueClient={faceBookWidgets?.minValueAuthorActivity}
          maxValueClient={faceBookWidgets?.maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={FacebookIcon}
          titleToolTip={"account_authors_activity_fb_tooltip"}
          showNotAppliedFilter
          authorActivityPreLoaderDataSources={
            faceBookWidgets?.accountAuthorActivityPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
    </Box>
  );
};

export default AccountOverViewSection;
