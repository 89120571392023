import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  checkProgressExceeded,
  getPercentage,
  handlelUserRoles,
} from "utils/helpers";
import Snackbar from "components/snackBar";
import { useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./subscription.scss";
import LucButton from "shared/lucButton/lucButton";
import IconTooltip from "./components/iconTooltip";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ChannelAnalytics = (props) => {
  const {
    success,
    channelAnalyticsInfo,
    setSuccess,
    sendPackageUpgrade,
    isLoadingUpgrade,
    cxmRef,
  } = props;

  const intl = useIntl();
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [channelAnalyticsRequest, setChannelAnalyticsRequest] = useState(null);
  const [channelAnalyticsSuccess, setChannelAnalyticsSuccess] = useState(null);
  const [upgradePackageRequest, setUpgradePackageRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const requestChannelAnalDemo = () => {
    let queryData = {
      service_name: "request_channel_analytics_demo",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setChannelAnalyticsRequest(true);
        setSuccessMsg("request_demo");
        setChannelAnalyticsSuccess(true);
      } else {
        setChannelAnalyticsRequest(false);
        setErrorMsg("request_demo_failed");
        setChannelAnalyticsSuccess(false);
      }
      setIsLoading(false);
    });
  };

  return (
    <div id="channel-analytics" ref={cxmRef}>
      <h3 className="sub__head">
        {CheckValueLocale("channel_analytics", "", {}, intl)}
      </h3>
      <Card className="body">
        {props.cxmActive ? (
          <>
            <Card justifyContent="center">
              {channelAnalyticsInfo.packageInfo ? (
                <div>
                  <Typography>
                    {CheckValueLocale("company_info", "", {}, intl)}
                  </Typography>
                  <Card className="percentage">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={10}
                      p={2}
                    >
                      <div>
                        <Typography>
                          {CheckValueLocale("package_name", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {channelAnalyticsInfo.packageInfo.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_start", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              channelAnalyticsInfo.packageInfo.subscribe_start,
                            ).split(" ")[0]
                          }
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_end", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              channelAnalyticsInfo.packageInfo.subscribe_end,
                            ).split(" ")[0]
                          }
                        </Typography>
                      </div>
                    </Stack>
                  </Card>
                  <Card justifyContent="center" m={5}>
                    <Card justifyContent="center">
                      <Typography>
                        {CheckValueLocale("package_stats", "", {}, intl)}
                      </Typography>
                      <Card className="percentage">
                        <CardContent>
                          <Grid container lg={12} md={12} sm={12} spacing={5}>
                            <Grid item lg={12} md={12} sm={12}>
                              <Typography>
                                {CheckValueLocale("channels", "", {}, intl)}
                              </Typography>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                mb={1}
                              >
                                <div>
                                  {`${commasAfterDigit(
                                    channelAnalyticsInfo.packageInfo
                                      .package_used_monitors,
                                  )} ${CheckValueLocale("of", "", {}, intl)}
                      ${commasAfterDigit(
                        channelAnalyticsInfo.packageInfo.max_channels,
                      )}`}
                                </div>
                                <div className="percentage">
                                  {`${getPercentage(
                                    channelAnalyticsInfo.packageInfo
                                      .package_used_monitors,
                                    channelAnalyticsInfo.packageInfo
                                      .max_channels,
                                  )}%`}
                                </div>
                              </Stack>
                              <BorderLinearProgress
                                variant="determinate"
                                className={
                                  channelAnalyticsInfo?.packageInfo
                                    ?.package_used_monitors >=
                                  channelAnalyticsInfo?.packageInfo
                                    ?.max_channels
                                    ? "red-color"
                                    : ""
                                }
                                value={
                                  checkProgressExceeded(
                                    channelAnalyticsInfo?.packageInfo
                                      ?.package_used_monitors,
                                    channelAnalyticsInfo?.packageInfo
                                      ?.max_channels,
                                  ) ??
                                  getPercentage(
                                    channelAnalyticsInfo?.packageInfo
                                      ?.package_used_monitors,
                                    channelAnalyticsInfo?.packageInfo
                                      ?.max_channels,
                                  )
                                }
                                id="linear-progress-color"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Card>
                  </Card>
                  <Card justifyContent="center">
                    <Typography>
                      {CheckValueLocale("data_usage", "", {}, intl)}
                      {channelAnalyticsInfo?.packageInfo?.package_type ===
                      "Monthly"
                        ? ` (${CheckValueLocale("monthly", "", {}, intl)})`
                        : ""}
                      <IconTooltip
                        titleID="channel_analytics_tooltip"
                        id="channel-analytics-tooltip"
                        placement="top"
                      />
                    </Typography>
                    <Card className="percentage">
                      <CardContent>
                        <Grid container lg={12} md={12} sm={12} spacing={5}>
                          <Grid item lg={12} md={12} sm={12}>
                            <Typography className="head-social-list">
                              {CheckValueLocale(
                                "interactions_user",
                                "",
                                {},
                                intl,
                              )}
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              mb={1}
                            >
                              <div>
                                {`${commasAfterDigit(
                                  channelAnalyticsInfo.packageInfo
                                    .interactions_usage,
                                )} ${CheckValueLocale("of", "", {}, intl)}
                      ${commasAfterDigit(
                        channelAnalyticsInfo.packageInfo.max_interactions,
                      )}`}
                              </div>
                              <div className="percentage">
                                {`${getPercentage(
                                  channelAnalyticsInfo.packageInfo
                                    .interactions_usage,
                                  channelAnalyticsInfo.packageInfo
                                    .max_interactions,
                                )}%`}
                              </div>
                            </Stack>
                            <BorderLinearProgress
                              variant="determinate"
                              className={
                                channelAnalyticsInfo.packageInfo
                                  .interactions_usage >=
                                channelAnalyticsInfo.packageInfo
                                  .max_interactions
                                  ? "red-color"
                                  : ""
                              }
                              value={
                                checkProgressExceeded(
                                  channelAnalyticsInfo?.packageInfo
                                    ?.interactions_usage,
                                  channelAnalyticsInfo?.packageInfo
                                    ?.max_interactions,
                                ) ??
                                getPercentage(
                                  channelAnalyticsInfo.packageInfo
                                    .interactions_usage,
                                  channelAnalyticsInfo.packageInfo
                                    .max_interactions,
                                )
                              }
                              id="linear-progress-color"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Card>
                </div>
              ) : (
                <Typography>
                  {CheckValueLocale("channel_analytics_demo", "", {}, intl)}
                </Typography>
              )}
            </Card>
            {handlelUserRoles("GENERAL", "CREATE_REQUEST") && (
              <Stack direction="row" justifyContent="flex-end">
                <LucButton
                  onClick={() => {
                    sendPackageUpgrade(channelAnalyticsInfo.id);
                    setUpgradePackageRequest(true);
                  }}
                  id="settings-subscription-channel-analytics-package-btn"
                  loading={isLoadingUpgrade}
                >
                  {CheckValueLocale("request_upgrade", "", {}, intl)}
                </LucButton>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography>
              {CheckValueLocale("channel_analytics_msg", "", {}, intl)}
            </Typography>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  requestChannelAnalDemo();
                }}
                id="settings-subscription-channel-analytics-package-btn"
              >
                {isLoading ? (
                  <span className="sending-request-btn-text">
                    <CircularProgress className="circular-progress-subscription circular-progress" />
                    {CheckValueLocale("sending_request", "", {}, intl)}
                  </span>
                ) : (
                  CheckValueLocale("request_demo", "", {}, intl)
                )}
              </Button>
            </Stack>
          </>
        )}
      </Card>

      {success != null ? (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span>
                  <br />
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_success", "", {}, intl)
                    : CheckValueLocale(successMsg, "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_failed", "", {}, intl)
                    : CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
      {channelAnalyticsSuccess != null ? (
        <Snackbar
          open={channelAnalyticsSuccess != null ? true : false}
          handleClose={() => {
            setChannelAnalyticsSuccess(null);
          }}
          severity={channelAnalyticsSuccess ? "success" : "error"}
          message={
            channelAnalyticsSuccess ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("request_demo_success", "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default ChannelAnalytics;
