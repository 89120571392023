import { useEffect, useState } from "react";
import { Paper, Grid, Typography, Button } from "@mui/material";

//Locals
import { useIntl } from "react-intl";
import "./addChannel.scss";

//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import {
  CompanyProduct,
  CompanyProductInjectedData,
  HandleChooseCategory,
} from "utils/types";
import { styled } from "@mui/material/styles";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

type Props = {
  readonly companyProducts: readonly CompanyProduct[];
  readonly handleChooseCategory: HandleChooseCategory;
  readonly activeProductName: string;
};
const ChooseCategoryStep: React.FC<Props> = ({
  companyProducts,
  handleChooseCategory,
  activeProductName,
}) => {
  const intl = useIntl();

  const [currentCompanyProducts, setCurrentCompanyProducts] = useState<
    readonly CompanyProduct[]
  >([]);

  const updateCateogry =
    (category: CompanyProduct) => (newData: CompanyProductInjectedData) => {
      setCurrentCompanyProducts((prevState) => [
        ...prevState,
        {
          ...category,
          ...newData,
        },
      ]);
    };

  useEffect(() => {
    setCurrentCompanyProducts([]);
    companyProducts.forEach((category) => {
      const injectCategoryData = updateCateogry(category);
      if (!category.launched) {
        injectCategoryData({
          badgeStyle: "comming_soon",
          icon: <FontAwesomeIcon icon={faStopwatch} />,
          itemStyle: "category-item-commingSoon",
        });
      } else if (!category.active) {
        handlelUserRoles("GENERAL", "CREATE_REQUEST") &&
          injectCategoryData({
            badgeStyle: "upgrade",
            icon: <FontAwesomeIcon icon={faCrown} />,
            itemStyle: "category-item-upgrade",
          });
      } else {
        injectCategoryData({
          itemStyle: "category-item",
        });
      }
    });
  }, [companyProducts]);

  return (
    <Paper elevation={3} className="panel-body">
      <Typography
        style={{
          fontSize: "13px",
          marginBottom: "20px",
          color: "#232B34",
          fontWeight: 600,
        }}
      >
        1. {CheckValueLocale("choose_category", "", {}, intl)}
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {currentCompanyProducts.map((category, i) => {
          return category?.name !== "Product" ? (
            <Grid item xs={3} key={i}>
              <CategoryButton
                onClick={() =>
                  handleChooseCategory(category.name, category.active)
                }
                className={`category-item ${category.itemStyle} ${
                  activeProductName === category.name ? "is-active" : ""
                }`}
                disabled={category.launched === false ? true : false}
                id={`channel-analytics-category-${category?.name ?? "name"}`}
              >
                {category.badgeStyle !== undefined && (
                  <span className={category.badgeStyle}>
                    {category.icon}
                    {CheckValueLocale(category.badgeStyle, "", {}, intl)}
                  </span>
                )}
                {CheckValueLocale(
                  category?.name?.toLowerCase() === "calls"
                    ? "calls_center"
                    : category?.name?.toLowerCase(),
                  "",
                  {},
                  intl,
                )}
              </CategoryButton>
            </Grid>
          ) : null;
        })}
      </Grid>
    </Paper>
  );
};
export default ChooseCategoryStep;

const CategoryButton = styled(Button)`
  &.is-active {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    background-color: #d5f0fc;
    border: 2px solid #456eb2;
    color: #456eb2;
  }
`;
