import MonitorInfoSections from "pages/socialListing/MonitorsOptions/createMonitor/Components/MonitorInfoSections/monitorInfoSections";
import KeywordsMonitorDataSources from "./keywordsMonitorDataSources-SM";
import { Box, Container } from "@mui/material";
import AddAutoSummaryWidget from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/AddAutoSummaryWidget/addAutoSummaryWidget";
import CommonFuntions from "../../createCommonFunctions/createMonitorCommonFunctions";

const KeywordMonitor = (props) => {
  const { calcSummaryTokens } = CommonFuntions();
  return (
    <Box className="monitor-keyword-type">
      <Container>
        <MonitorInfoSections {...props} />
        <KeywordsMonitorDataSources {...props} />
        {props?.autoSummaryWidgetPackage ? (
          <AddAutoSummaryWidget
            setWidgetData={props?.setMonitorData}
            widgetData={props?.monitorData}
            summaryTokens={calcSummaryTokens(
              props?.summarySmTokensKeywords,
              props?.checked,
            )}
          />
        ) : null}
      </Container>
    </Box>
  );
};

export default KeywordMonitor;
