import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const TextButton = styled(Button)`
  padding: 0;
  &:hover {
    background: none;
    text-decoration: underline;
  }
`;
