import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Container, styled, Divider, Box } from "@mui/material";
import {
  isEmptyValue,
  CheckValueLocale,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useEditSentiment } from "pages/channelAnalytics/InsightsPage/hooks/useEditSentiment";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRetweet,
  faHeart,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import Luci from "pages/luci";
import SnackBar from "components/snackBar.js";
import ReplyModal from "../../../../../replyModal/replyModal";
import InteractionFooter from "../../../common/InteractionFooter";
import InteractionBrand from "../../../common/InteractionBrand";
import xPlatformLogo from "images/interactions-details/x-platform-logo.svg";
import InteractionContentRenderer from "../../../common/InteractionContentRenderer";
import "../../../common/interactionCommon.scss";
import MonitorsController from "services/controllers/monitorsController";

const SingleTweetInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams?.id;
  const trackerId = urlParams?.tracker_id;
  const dataSourceId = urlParams?.data_source_id;
  const [allawedTrackers, setAllawedTrackers] = useState([]);
  const [text, setText] = useState("");
  const [replyModalData, setReplyModalData] = useState([
    {
      selectedValue: "",
      replyTime: "",
    },
  ]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);

  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  let apiVars = "social_media/twitter/public";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true"
          ? `/x-platform-channel/${dataSourceId}`
          : ""
      }`,
    },
  ];
  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
    ).then((result) => {
      if (result !== undefined && !isEmptyValue(result?.data)) {
        if (
          handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
          retrieveActiveProductsArray()?.includes("ENGAGEMENTS")
        ) {
          MonitorsController.getAllawedMonitors(
            window?.localStorage?.cxm_id,
          ).then((AllawedData) => {
            if (AllawedData?.data?.data?.monitors_list?.length) {
              setAllawedTrackers([...AllawedData?.data?.data?.monitors_list]);
            }
          });
        }
        setConversation(result?.data);
      }
    });
  }, []);

  const handleCloseRepliedSnack = () => {
    setShowErrorSnackBar(false);
  };

  const interactionMetrics = [
    {
      name: "retweets",
      value: conversation?.retweet_count || 0,
      icon: <FontAwesomeIcon icon={faRetweet} />,
    },
    {
      name: "likes",
      value: conversation?.favorite_count || 0,
      icon: <FontAwesomeIcon icon={faHeart} />,
    },
    {
      name: "comments",
      value: conversation?.reply_count || 0,
      icon: <FontAwesomeIcon icon={faComment} />,
    },
  ];

  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: themesOptions } = useThemesOptions({
    locale: intl?.locale,
  });

  const { mutate: editSentiment, isPending: isSentimentPending } =
    useEditSentiment();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();
  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();

  const preQueryData = {
    id: conversation?.tweet_id,
    created_at: conversation?.created_at,
    data_source_id: conversation?.channel_id,
    product_id: window.localStorage.cxm_id,
    tracker_id: conversation?.tracker_id,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: conversation?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, themes: updatedCategories })),
        onError: () => setShowErrorSnackBar(true),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: conversation?.sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sub_themes: updatedThemes })),
        onError: () => setShowErrorSnackBar(true),
      },
    );
  };

  const handleEditSentiment = (updatedSentiment) => {
    editSentiment(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedSentiment,
          old_label: conversation?.sentiment,
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sentiment: updatedSentiment })),
        onError: () => setShowErrorSnackBar(true),
      },
    );
  };

  return (
    <>
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyValue(conversation) ? (
          <CircularLoading />
        ) : (
          <Box className="single-interaction-container">
            <SingleInteractionHeader links={links} dataSource="twitter" />
            <Box className="single-intr">
              <Box className="single-intr-wrapper">
                <InteractionBrand
                  interactionType={conversation?.interaction_type}
                  title={CheckValueLocale("twitter", "", {}, intl)}
                  brand={<img src={xPlatformLogo} alt="x-platform-logo" />}
                />
                <Box className="interactions-themes-wrapper">
                  <SentimentCategoriesThemes
                    themesData={{
                      btnId: "xplatform-single-interaction-themes-btn",
                      options: themesOptions,
                      selected: conversation?.sub_themes,
                      handleChange: handleEditTheme,
                      loading: isThemesPending,
                    }}
                    categoriesData={{
                      btnId: "xplatform-single-interaction-categories-btn",
                      options: categoriesOptions,
                      selected: conversation?.themes,
                      handleChange: handleEditCategories,
                      loading: isCategoriesPending,
                    }}
                    sentimentData={{
                      btnId: "xplatform-single-interaction-sentiment-btn",
                      options: sentimentsOptions,
                      selected: conversation?.sentiment,
                      handleChange: handleEditSentiment,
                      loading: isSentimentPending,
                    }}
                    dividers={[1, 2]}
                  />
                </Box>
              </Box>
              <Box className="single-intr__body">
                <InteractionContentRenderer
                  trackerId={trackerId}
                  content={conversation?.text}
                  username={conversation?.name}
                  interactionURL={conversation?.interaction_url}
                  userHandle={conversation?.created_by}
                  imgURL={conversation?.profile_image_url}
                  createdAt={conversation?.created_at}
                  dataSourceName={"TWITTER"}
                  conversation={conversation}
                />
                <InteractionFooter
                  interactionMetrics={interactionMetrics}
                  createdAt={conversation?.created_at}
                />
              </Box>
              {handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
              retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
              allawedTrackers?.includes(+trackerId) ? (
                <ReplyModal
                  replyData={conversation}
                  setText={setText}
                  text={text}
                  replyModalData={replyModalData}
                  setReplyModalData={setReplyModalData}
                  setShowSuccessModal={setShowSuccess}
                  showSuccessModal={showSuccess}
                  setShowErrorSnackBar={setShowErrorSnackBar}
                />
              ) : null}
            </Box>
          </Box>
        )}
        {showErrorSnackBar ? (
          <SnackBar
            open={showErrorSnackBar}
            autoHideDuration={5000}
            severity="error"
            message={CheckValueLocale("please_try_again", "", {}, intl)}
            title={CheckValueLocale("wrong_request_parameter", "", {}, intl)}
            handleClose={handleCloseRepliedSnack}
          />
        ) : null}
      </Container>
    </>
  );
};

export default SingleTweetInteraction;

const DividerVer = styled(Divider)`
  color: #cbd5e1;
  width: 1px;
  height: 20px;
  margin: 0px 10px;
`;
