import { Box, MenuItem, Select, Typography } from "@mui/material";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import SurveyRequestImgEn from "images/engagements-images/farewell-greeting-survey-image-en.svg";
import SurveyRequestImgAr from "images/engagements-images/farewell-greeting-survey-image-ar.svg";
import LucidyaSurveyIcon from "images/ai-agents/lucidya-survey-icon.svg";
import { CheckValueLocale } from "utils/helpers";
import { useSurveysPublishedList } from "../../aiAgents/aiAgentsHooks/useSurveysPublishedList";
import { useEffect, useState } from "react";
import AiAgentsFields from "../../aiAgents/component/aiAgentsContent/aiAgentsFormContent/component/aiAgentsFields";

const FarewellMessageSurvey = ({
  messagesData,
  setMessagesData,
  selectedTapSurveyIdKey,
}) => {
  const intl = useIntl();

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const surveyActive = activeProducts?.find(
    (product) => product?.name == "SURVEY",
  );

  const [isSurveysLoading, setIsSurveysLoading] = useState(false);

  const {
    data: surveysList,
    isLoading: surveysListLoading,
    refetch: refetchSurveysList,
    isRefetching: isRefetchSurveysList,
  } = useSurveysPublishedList(!!surveyActive);

  const onOpenSurveysDropdown = () => {
    setIsSurveysLoading(true);
    refetchSurveysList();
  };

  useEffect(() => {
    if (surveysList && !surveysListLoading && !isRefetchSurveysList) {
      setIsSurveysLoading(false);
    }
  }, [surveysList, surveysListLoading, isRefetchSurveysList]);

  const surveyOptionsList =
    surveysList?.map((item) => {
      return item?.id;
    }) || [];

  const surveyOptionsListObj =
    surveysList?.map((item) => {
      return {
        name: item?.name,
        id: item?.id,
      };
    }) || [];

  const onClickAddNewSurvey = () => {
    window.open("/survey/list", "_blank");
  };

  const isEn = intl?.locale === "en";
  const SurveyRequestImg = isEn ? SurveyRequestImgEn : SurveyRequestImgAr;

  const handleSurveyChange = (value) => {
    setMessagesData({
      ...messagesData,
      farewell_greeting_enabled: {
        ...messagesData?.farewell_greeting_enabled,
        [selectedTapSurveyIdKey]: value,
      },
    });
  };

  const selectedSurveyId =
    messagesData?.farewell_greeting_enabled?.[selectedTapSurveyIdKey];

  return (
    <Box className="ai-agents-survey-request-wrapper">
      <Box className="farewell-survey-container">
        <Box className="farewell-survey-content-wrapper">
          <Box>
            <img src={LucidyaSurveyIcon} alt="lucidya-survey-icon" />
          </Box>
          <Typography className="farewell-survey-title">
            {CheckValueLocale("boost_feedback", "", {}, intl)}
          </Typography>
          <Typography className="farewell-survey-description">
            {CheckValueLocale(
              "farewell_survey_select_description",
              "",
              {},
              intl,
            )}
          </Typography>
          <Box className="farewell-survey-select">
            <AiAgentsFields
              type="autoComplete"
              id="ai-agent-survey-select"
              name="survey_id"
              value={selectedSurveyId}
              optionsList={surveyOptionsList}
              optionsListObj={surveyOptionsListObj}
              handleChange={(e, value) => handleSurveyChange(value)}
              placeholder={CheckValueLocale("select", "", {}, intl)}
              emptyListMessage={CheckValueLocale(
                "no_surveys_show",
                "",
                {},
                intl,
              )}
              customOptionLabel={CheckValueLocale("new_survey", "", {}, intl)}
              onCustomOptionClick={onClickAddNewSurvey}
              icon={faExternalLink}
              isLoading={isSurveysLoading}
              onOpen={onOpenSurveysDropdown}
            />
          </Box>
        </Box>
      </Box>
      <Box className="survey-request-image-wrapper">
        <img src={SurveyRequestImg} alt="survey-request-image" />
      </Box>
    </Box>
  );
};

export default FarewellMessageSurvey;
