import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Breadcrumbs,
  Box,
  Button,
  Popover,
  TextField,
  Divider,
} from "@mui/material";
import { faGrinAlt, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const EditReply = (props) => {
  const intl = useIntl();
  const {
    editReplyDisable,
    maxChar,
    btnLoading,
    handleEditReply,
    postData,
    setActiveReplyEditEngagement,
    slEdit,
    disableActiveEditMode,
    question,
    setEditText,
    editText,
    onOpen,
  } = props;

  const [anchorElEmoji, setAnchorElEmoji] = useState(null);

  const replyBtnStyle =
    editText === "" || editText?.length > maxChar || btnLoading;

  // emoji Dropdown HANDLER
  const handleClickEmoji = (event) => {
    setAnchorElEmoji(event.currentTarget);
  };
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };
  const openEmoji = Boolean(anchorElEmoji);

  const handleEditTextChange = (event) => {
    setEditText(event.target.value);
  };

  useEffect(() => {
    onOpen?.();
  }, []);

  const breadCrumbsStyle = editText?.length > maxChar ? "word-reached" : "";

  const emojiSelect = (e) => {
    setEditText(editText + e);
    handleCloseEmoji();
  };

  const handleClostActiveEdit = () => {
    if (slEdit) {
      disableActiveEditMode();
    } else {
      setActiveReplyEditEngagement(false);
    }
  };

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  return (
    <Box className="reply-post-edit">
      <TextField
        value={editText}
        onChange={handleEditTextChange}
        multiline
        maxRows={3}
        variant="outlined"
        className="reply-textarea"
        error={editText?.length > maxChar}
        disabled={editReplyDisable}
        sx={{
          "& fieldset": { border: "none" },
        }}
      />
      <Box className="emoji-buttons-container">
        <Box className="emoji-text-wrapper">
          <Breadcrumbs
            aria-label="breadcrumb"
            className={`reply-word-count ${breadCrumbsStyle}`}
          >
            <Box component="span">
              {editText?.trim()?.length === 0 || editText == null
                ? 0
                : editText?.trimStart()?.length}
            </Box>
            <Box component="span">{maxChar}</Box>
          </Breadcrumbs>
          <Divider orientation="vertical" className="edit-reply-divider" />
          <Box
            className="reply-footer-icon emoji-icon"
            onClick={handleClickEmoji}
          >
            <FontAwesomeIcon icon={faGrinAlt} />
          </Box>
          <Popover
            anchorOrigin={anchorPosition}
            transformOrigin={transformPosition}
            onClose={handleCloseEmoji}
            open={openEmoji}
            anchorEl={anchorElEmoji}
          >
            <Picker data={data} onEmojiSelect={(e) => emojiSelect(e?.native)} />
          </Popover>
        </Box>
        <Box className="edit-reply-buttons">
          <Button
            onClick={handleClostActiveEdit}
            variant="contained"
            className="engagements-edit-cancel"
            disabled={editReplyDisable}
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </Button>
          <Button
            disabled={replyBtnStyle}
            onClick={() =>
              handleEditReply(
                slEdit
                  ? question?.tweet_id
                  : postData?.system_replies?.replier_info?.parent_id,
              )
            }
            variant="contained"
            className="engagements-send-edit"
          >
            {btnLoading ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              CheckValueLocale("save", "", {}, intl)
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditReply;
