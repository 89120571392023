import { useMutation, useQueryClient } from "@tanstack/react-query";
import MonitorsController from "services/controllers/monitorsController";

export const usePauseOrResumeChannel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ channelId }) =>
      MonitorsController.resumeOrPauseMonitors(channelId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["monitors"],
      });
    },
  });
};
