import { sortedColumnTypes, useSortableTable } from "hooks/useSortableTable";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const useTopHashtagsSortingTable = () => {
  const intl = useIntl();
  const [topHashtagsSnackBar, setTopHashtagsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const handleCloseSnackBar = () => {
    setTopHashtagsSnackBar({
      openSnackBar: false,
    });
  };
  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: [
      {
        body: CheckValueLocale("rank", "", {}, intl),
        className: "rank-header",
      },
      {
        body: CheckValueLocale("trend_name", "", {}, intl),
        name: "hashtag",
        type: sortedColumnTypes.string,
      },
      {
        body: CheckValueLocale("trending_hrs", "", {}, intl),
        name: "hour_count",
        type: sortedColumnTypes.number,
      },
      {
        body: CheckValueLocale("hashtags_tweets", "", {}, intl),
        name: "tweet_volume",
        type: sortedColumnTypes.number,
      },
      {
        body: CheckValueLocale("start_place", "", {}, intl),
        name: "start_place",
        type: sortedColumnTypes.string,
      },
    ],
    onSortChangeCallback,
  });

  const setTopHashtagsSnackbarError = () => {
    setTopHashtagsSnackBar({
      message: "try_again_error_message",
      severity: "error",
      title: "failed_error_message",
      openSnackBar: true,
    });
  };
  return {
    topHashtagsSnackBar,
    handleCloseSnackBar,
    setTopHashtagsSnackbarError,
    tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  };
};
