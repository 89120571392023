import "./style.scss";
import Request from "./components/request/request";
import Content from "./components/content/content";

const LucidyaAPIsIndex = () => {
  var activeProducts = JSON.parse(window.localStorage.activeProducts || null);
  let lucidyaApisActive = activeProducts?.find(
    (product) => product?.name == "PUBLIC_API",
  );
  let aiApisActive = activeProducts?.find(
    (product) => product?.name == "AI_API",
  );
  const lucidyaApisActiveCheck =
    lucidyaApisActive || aiApisActive ? true : false;

  return lucidyaApisActiveCheck ? (
    <Content id={lucidyaApisActive?.id} />
  ) : (
    <Request />
  );
};
export default LucidyaAPIsIndex;
