import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { InputWithShrinkLabel } from "./inputWithShrinkLabel/inputWithShrinkLabel";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./teamsSharedStyle.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const TeamInformation = ({
  teamName,
  setTeamName,
  selectedProduct,
  setSelectedProduct,
  options = [],
  teamNameError,
  isProductsDisabled,
}) => {
  const intl = useIntl();
  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };
  return (
    <Box className="team-information-container">
      <Typography className="team-information-label">
        {CheckValueLocale("team_information", "", {}, intl)}
      </Typography>
      <Box className="team-information-form">
        <InputWithShrinkLabel
          label={CheckValueLocale("team_name", "", {}, intl)}
          value={teamName}
          onChange={(e) => setTeamName(e?.target?.value)}
          error={teamNameError}
          helperText={
            teamNameError ? CheckValueLocale(teamNameError, "", {}, intl) : null
          }
        />
        <FormControl className="product-select" size="small">
          <InputLabel id="select-small-label">
            {CheckValueLocale("select_product", "", {}, intl)}
          </InputLabel>
          <Select
            id="select-small-label"
            className="select-with-shrink-label"
            value={selectedProduct}
            onChange={handleChange}
            input={
              <OutlinedInput
                label={CheckValueLocale("select_product", "", {}, intl)}
                className="input-with-shrink-label"
                size="small"
              />
            }
            MenuProps={MenuProps}
            disabled={isProductsDisabled}
          >
            {options?.map((option) => {
              return (
                <MenuItem value={option?.id} key={option?.id}>
                  {CheckValueLocale(option?.name, "", {}, intl)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
