import React, { useEffect, useState } from "react";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "../../../surveyBuilder.scss";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import arrowIcon from "images/survey-builder-icons/arrow-from-top-to-right.svg";
import PopupModal from "components/popupModal";
import NpsProceedFunctions from "./npsProceedFunctions";
import NpsProceedModalBody from "./npsProceedModalBody";

const Nps = (props) => {
  const {
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
    rootQuestion,
    hasProceedSec,
    hasProceedQues,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);

  const {
    handleChangeProceedSwitch,
    openModal,
    setOpenModal,
    proceedValues,
    tempProceedValues,
    handleDisabledApply,
    handleApply,
    valuesList,
    setTempProceedValues,
    tempRemainingValueGoTo,
    setTempRemainingValueGoTo,
    remainingValues,
    handleChangeValue,
    handleChangeGoTo,
    handleRemoveCondition,
    showRemoveBtn,
  } = NpsProceedFunctions(
    surveySections,
    setSurveySections,
    filteredSections,
    sectionIndex,
    question,
    questionIndex,
  );

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
    }
  }, [question]);

  const handleSwitch = (e, constraint) => {
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints[constraint] = e.target.checked;
    setSurveySections([...surveySections]);
  };

  return (
    <>
      {Object.entries(question?.constraints)?.map((constraint, index) => {
        return (
          <Box
            className={`constraints-text ${
              editable === false && `disabled-color`
            }`}
            key={index}
          >
            <Box>{CheckValueLocale(constraint?.[0], "", {}, intl)}</Box>
            <Switch
              data-testid="required_switch"
              onChange={(e) => handleSwitch(e, constraint?.[0])}
              checked={Object.values(constraint)?.[1]}
              disabled={
                editable === false && constraint?.[0] !== "required"
                  ? true
                  : false
              }
            />
          </Box>
        );
      })}

      <Box
        className={`constraints-text ${
          (filteredSections?.length < 2 ||
            rootQuestion?.is_proceed_editable === false) &&
          `disabled-color`
        } ${
          hasProceedSec === sectionIndex &&
          hasProceedQues !== questionIndex &&
          `disabled-color`
        }`}
      >
        <Box>{CheckValueLocale("is_proceed_on_answer", "", {}, intl)}</Box>
        {hasProceedSec === sectionIndex && hasProceedQues !== questionIndex ? (
          <Tooltip
            title={CheckValueLocale("proceed_disabled_msg", "", {}, intl)}
            placement="left"
            arrow
            componentsProps={{
              tooltip: {
                sx: { width: "160px", padding: "13px", lineHeight: "16px" },
              },
            }}
          >
            <Box className="disabled-proceed-switch">
              <Switch
                data-testid="proceed_on_answer_switch"
                onChange={(e) => handleSwitch(e, "is_proceed_on_answer")}
                checked={false}
                disabled={true}
              />
            </Box>
          </Tooltip>
        ) : (
          <>
            <Switch
              data-testid="proceed_on_answer_switch"
              onChange={(e) => handleChangeProceedSwitch(e?.target?.checked)}
              checked={rootQuestion?.is_proceed_on_answer}
              disabled={
                filteredSections?.length < 2 ||
                rootQuestion?.is_proceed_editable === false
              }
            />
          </>
        )}
      </Box>

      {rootQuestion?.is_proceed_on_answer ? (
        <Box className="show-conditions-btn-container">
          <img src={arrowIcon} alt="" />
          <Box className="show-conditions-btn-wrapper">
            <Typography variant="h6" onClick={() => setOpenModal(true)}>
              {`${
                proceedValues?.filter(
                  (item) => !!item?.value && !!item?.next_section,
                )?.length + 1
              } ${CheckValueLocale("proceed_conditions", "", {}, intl)}`}
            </Typography>
            {rootQuestion?.is_proceed_editable ? (
              <Tooltip
                title={CheckValueLocale("clear", "", {}, intl)}
                placement="bottom"
                arrow
              >
                <Box
                  component="span"
                  id="clear-conditions-btn"
                  onClick={() => handleChangeProceedSwitch(false)}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
      ) : null}

      <PopupModal
        open={openModal}
        title={CheckValueLocale("proceed_based_on_answer", "", {}, intl)}
        body={
          <NpsProceedModalBody
            filteredSections={filteredSections}
            sectionIndex={sectionIndex}
            valuesList={valuesList}
            tempProceedValues={tempProceedValues}
            setTempProceedValues={setTempProceedValues}
            tempRemainingValueGoTo={tempRemainingValueGoTo}
            setTempRemainingValueGoTo={setTempRemainingValueGoTo}
            remainingValues={remainingValues}
            handleChangeValue={handleChangeValue}
            handleChangeGoTo={handleChangeGoTo}
            handleRemoveCondition={handleRemoveCondition}
            showRemoveBtn={showRemoveBtn}
          />
        }
        leftBtnId="nps-proceed-on-answer-cancel-btn"
        rightBtnId="nps-proceed-on-answer-apply-btn"
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("proceed_apply", "", {}, intl)}
        close={() => setOpenModal(false)}
        accept={handleApply}
        disabled={handleDisabledApply()}
        classeName="actions-buttons-shared"
        addClasses="nps-proceed-modal-btns-container"
        modalStyle="nps-proceed-modal-content"
        minWidth="600px"
      />
    </>
  );
};

export default Nps;
