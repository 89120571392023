import React from "react";
import { useIntl } from "react-intl";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import SurveyPannel from "../../SurveyPanel";

const StepTwo = (props) => {
  const {
    stepTwo,
    isSurveyPanelEnabled,
    handleChange,
    checkSwitch,
    surveyPanelList,
    selectedValues,
    setSelectedValues,
    surveyPanel,
    SurveyspanelById,
    setError,
    isSurveyEnabled,
  } = props;
  const intl = useIntl();

  return (
    <>
      <Box className="share-survey-container share-survey-parant">
        {stepTwo && !isSurveyPanelEnabled ? (
          <Box className="close-share-btn">
            <Box className="publish-step">2 / 3</Box>
          </Box>
        ) : null}
        <FormGroup>
          {!isSurveyPanelEnabled ? (
            <FormControlLabel
              control={
                <Switch
                  onChange={handleChange}
                  className="switch-panel"
                  checked={isSurveyPanelEnabled ? true : checkSwitch}
                />
              }
            />
          ) : null}
          <Typography component={"span"} className="survey-Panel-switch">
            {CheckValueLocale("survey_Panel", "", {}, intl)}
          </Typography>
          <Typography className="paid" component={"span"}>
            {CheckValueLocale("paid", "", {}, intl)}
          </Typography>
        </FormGroup>
        <Typography>
          {CheckValueLocale("request_access_survey_panel", "", {}, intl)}
        </Typography>
        {checkSwitch || isSurveyPanelEnabled ? (
          <Box className="survey-panel-select-group">
            <SurveyPannel
              surveyPanelList={surveyPanelList}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              isSurveyPanelEnabled={isSurveyPanelEnabled}
              surveyPanel={surveyPanel}
              SurveyspanelById={SurveyspanelById}
              setError={setError}
            />
          </Box>
        ) : null}
        {isSurveyEnabled && isSurveyPanelEnabled ? (
          <Box className="manage-domain-btn successfully-msg">
            <FontAwesomeIcon icon={faCheck} />
            {CheckValueLocale("survey_successfully", "", {}, intl)}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default StepTwo;
