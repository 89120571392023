import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleAuthorActivity, handleXlsData } from "../excelCommonFn";

const account_ig_MP = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var followersGrowth = [],
      postsContactDetails = [],
      authorActivity = [],
      postsVolume = [],
      reachImpression = [],
      postsInteractions = [],
      averageEngagementPerPost = [],
      postsContentType = [],
      topPosts = [],
      topVideos = [],
      topImages = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "followersGrowth":
            value?.map((i) => {
              followersGrowth.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([followersGrowth, lang.followers_growth]);
            break;
          // _______________________________________________________________________________

          case "postsContactDetails":
            value?.map((i) => {
              postsContactDetails.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.website_clicks]: i?.value[0],
                [lang.phone_clicks]: i?.value[1],
                [lang.directs_clicks]: i?.value[2],
                [lang.profile_visits]: i?.value[3],
                [lang.total]:
                  i?.value[0] + i?.value[1] + i?.value[2] + i?.value[3],
              });
            });
            allSheetData.push([
              postsContactDetails,
              lang.page_contact_details_interactions_xlsx_sheetname,
            ]);
            break;
          //_______________________________________________________________________________
          case "reachImpression":
            value?.impression?.map((i, index) => {
              if (i.name == value.reach[index].name) {
                reachImpression.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.impression]: i?.value,
                  [lang.reach]: value.reach[index].value,
                });
              }
            });
            allSheetData.push([reachImpression, lang.posts_reach_impressions]);
            break;
          // _______________________________________________________________________________

          case "averageEngagementPerPost":
            value?.map((i) => {
              averageEngagementPerPost.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([
              averageEngagementPerPost,
              lang.average_engagements_per_post,
            ]);
            break;

          // _______________________________________________________________________________
          case "postsVolume":
            value?.map((i) => {
              postsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([postsVolume, lang.posts_volume]);
            break;
          // _______________________________________________________________________________

          case "authorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                authorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData.push([authorActivity, lang.account_authors_activity]);
            break;

          //_______________________________________________________________________________

          case "postsInteractions":
            value?.map((i) => {
              postsInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value[0],
                [lang.likes]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData.push([postsInteractions, lang.posts_interactions]);
            break;
          //_______________________________________________________________________________

          case "postsContentType":
            value?.map((i) => {
              postsContentType.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.video]: i?.value[0],
                [lang.photo]: i?.value[1],
                [lang.catalog]: i?.value[2],
                [lang.total]: i?.value[0] + i?.value[1] + i?.value[2],
              });
            });
            allSheetData.push([postsContentType, lang.posts_content_type]);
            break;

          //_______________________________________________________________________________;

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.permalink });
            });
            allSheetData.push([topImages, lang.top_images]);

            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos.push({ [lang.video]: i?.permalink });
            });
            allSheetData.push([topVideos, lang.top_videos]);
            break;
          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData.push([topPosts, lang.top_posts]);

            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { account_ig_MP };
