import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";

function IconTooltip({ titleID, placement, id }) {
  const intl = useIntl();
  return (
    <Tooltip
      title={
        <Typography component={"p"} className="icon-sl-ca-tooltip">
          {CheckValueLocale(titleID, "", {}, intl)}
        </Typography>
      }
      placement={placement}
      arrow
      id={id}
    >
      <Typography component={"span"} className="icon-info-circle">
        <FontAwesomeIcon icon={faInfoCircle} />
      </Typography>
    </Tooltip>
  );
}

export default IconTooltip;
