import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Button, CircularProgress } from "@mui/material";
import { Done } from "@mui/icons-material";
import "./noPermission.scss";
import CompaniesController from "services/controllers/companiesController";
import { CheckValueLocale } from "utils/helpers";
import noPermissionImage from "images/benchmark-images/no-permission.svg";
import SnackBar from "components/snackBar";

const NoPermission = ({ type }) => {
  const intl = useIntl();
  const [request, setRequest] = useState(false);
  const [success, setSuccess] = useState(null);
  const [snackBarHeading, setSnackBarHeading] = useState(null);
  const [snackBarDescription, setSnackBarDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const requestPermission = () => {
    let queryData = {
      service_name:
        type === "sm"
          ? "request_social_listening_demo"
          : "request_channel_analytics_demo",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSuccess(true);
        setRequest(true);
        setSnackBarHeading("demo_request_successfully_heading");
        setSnackBarDescription("demo_request_successfully_description");
      } else {
        setSuccess(false);
        setRequest(false);
        setSnackBarHeading("demo_request_error_heading");
        setSnackBarDescription("demo_request_error_description");
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      <Box className="permission-wrapper-container">
        <Box className="permission-wrapper">
          <img src={noPermissionImage} alt="requestImage" />
          <p className="permission-description">
            {CheckValueLocale(
              type + "_no_permission_description",
              "",
              {},
              intl,
            )}
          </p>
          {request ? (
            <Button
              variant="outlined"
              disabled
              startIcon={<Done className="add-icon" />}
              className="permission-button"
              id="permission-sent-btn"
            >
              {CheckValueLocale("request_sent_benchmark", "", {}, intl)}
            </Button>
          ) : (
            <Button
              onClick={() => {
                requestPermission();
              }}
              variant="contained"
              className="permission-button"
              id="permission-access-btn"
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="sending-request-btn-text">
                  <CircularProgress className="circular-progress-subscription circular-progress" />
                  {CheckValueLocale("sending_request", "", {}, intl)}
                </span>
              ) : (
                CheckValueLocale("request_demo_benchmark", "", {}, intl)
              )}
            </Button>
          )}
        </Box>
      </Box>
      {success != null ? (
        <SnackBar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            <div className="lucidya-apis-snackbar-wrapper">
              <span className="lucidya-apis-snackbar-heading">
                {CheckValueLocale(snackBarHeading, "", {}, intl)}
              </span>
              <span className="lucidya-apis-snackbar-description">
                {CheckValueLocale(snackBarDescription, "", {}, intl)}
              </span>
            </div>
          }
        />
      ) : (
        ""
      )}
    </>
  );
};
export default NoPermission;
