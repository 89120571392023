import { Card, CardContent, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";

const MultipleChoiceQuestion = (props) => {
  const intl = useIntl();
  const { question, questionNo, questionAnswer } = props;

  return (
    <Card className="cardStyle noError audience">
      <CardContent>
        <Typography className="questionStyle audience">
          <span>{`${questionNo}.`}</span>
          {question?.question}
        </Typography>

        <Box>
          {!isEmptyArray(questionAnswer) &&
          typeof questionAnswer == "object" ? (
            questionAnswer?.map((item) => {
              return item !== "" ? (
                <Input
                  readOnly
                  id="standard-basic"
                  placeholder={CheckValueLocale(
                    "you_didnt_answer",
                    "",
                    {},
                    intl,
                  )}
                  variant="standard"
                  className="textFieldStyle audience"
                  value={item}
                />
              ) : null;
            })
          ) : (
            <Input
              readOnly
              id="standard-basic"
              placeholder={CheckValueLocale("you_didnt_answer", "", {}, intl)}
              variant="standard"
              className="textFieldStyle audience"
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MultipleChoiceQuestion;
