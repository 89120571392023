import { styled } from "@mui/material/styles";
import { MenuItem, ListSubheader, FormControl, Select } from "@mui/material";

export const CustomFormControl = styled(FormControl)`
  width: 100%;
  position: relative;
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-size: 14px !important;
  }
  .MuiInputLabel-root {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    line-height: 1;
  }
  .Mui-focused {
    color: #456eb2 !important;
  }
`;
export const CustomSelect = styled(Select)`
  .MuiInputBase-input {
    padding: 10px;
  }
  .MuiSelect-select {
    font-weight: 400;
    font-size: 13px;
    color: #8d8d8d;
  }
`;
export const CustomMenuItem = styled(MenuItem)`
  &.MuiButtonBase-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    padding: 7px 0px 7px 40px;
  }
`;

export const CustomCheckboxMenuItem = styled(MenuItem)`
  &.MuiButtonBase-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
  }
  .MuiCheckbox-root {
    padding: 0 !important;
    margin: 0 !important;
    margin-right: 18px !important;
  }
`;

export const CustomListSubheader = styled(ListSubheader)`
  &.MuiListSubheader-root {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 16px;
  }
  svg {
    color: #1da1f2;
    margin: 0px 8px;
    font-size: 18px;
  }
`;
