import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
// import "pages/Monitors/monitors.scss";
import PunchCardWidget from "components/widgets/punchCardWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import ThemeSection from "./../../../Components/themeSection";
import TopicsSection from "../../../Components/topicsSection";
import { CheckValueLocale } from "utils/helpers";

/*---------------------------------------------------------*/
const PublicFBEngagementBody = (props) => {
  const intl = useIntl();
  const { monitorType, monitorDataSource, monitorId } = useParams();
  let { response } = props;

  const monitorDetails = props.monitorData;

  //handle response
  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [postsVolumePreLoaderDataSources, setPostsVolumePreLoaderDataSources] =
    useState([]);
  //______________________________________________
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  //____________________________________________
  const [authorActivity, setAuthorActivity] = useState([]);
  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);

  // ___________________________________________

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);

  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  //___________________________________________
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);
  //______________________________________________
  const [topComments, setTopComments] = useState([]);
  const [topCommentsCount, setTopCommentsCount] = useState(0);
  const [topCommentsPreLoaderDataSources, setTopCommentsPreLoaderDataSources] =
    useState([]);
  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__EngagementsPage__volume_overtime":
        setPostsVolume(response.eventData.posts_over_time);
        setTotalPostsVolumeCount(response.eventData.total_posts_count);
        setPostsVolumePreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__sentiment_analysis":
        Object.entries(response.eventData.sentiment_overtime).forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setSentimentAnalysis({
          negative: negativeArray,
          positive: positiveArray,
          neutral: neutralArray,
        });
        setSentimentAnalysisPieChart(response.eventData.sentiment_piechart);

        setSentimentAnalysisPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__dialects_and_sub_dialects":
        setDialects(response.eventData.dialects);
        setSubDialects(response.eventData.sub_dialects);
        setDialectsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;

      case "Facebook__EngagementsPage__account_author_activity":
        setMinValueAuthorActivity(response.eventData.min_value);
        setMaxValueAuthorActivity(response.eventData.max_value);
        setAuthorActivity(response.eventData.account_author_activity);
        setAuthorActivityPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;

      case "Facebook__EngagementsPage__top_keywords":
        setTopKeywords(response.eventData.top_keywords);
        setTopKeywordsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__top_hashtags":
        setTopHashtags(response.eventData.top_hashtags);
        setTopHashtagsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__top_posts_comments":
        setTopComments(response.eventData.top_posts);
        setTopCommentsCount(response.eventData.top_posts_count);
        setTopCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  //_____________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([
      { postsVolume: postsVolume },
      { sentimentAnalysis: sentimentAnalysis },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      {
        authorActivity: [
          authorActivity,
          minValueAuthorActivity,
          maxValueAuthorActivity,
        ],
      },
      { dialects: dialects },
      { subDialects: subDialects },
      { topComments: topComments },
    ]);
  }, [
    postsVolume,
    sentimentAnalysis,
    topKeywords,
    topHashtags,
    authorActivity,
    dialects,
    subDialects,
    topComments,
  ]);
  //_____________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setSentimentAnalysisPieChart([]);

      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);

      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setPostsVolumePreLoaderDataSources([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);

      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setAuthorActivity([]);
      setAuthorActivityPreLoaderDataSources([]);

      setTopComments([]);
      setTopCommentsCount(0);
      setTopCommentsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      postsVolumePreLoaderDataSources,
      sentimentAnalysisPreLoader,
      dialectsPreLoader,
      authorActivityPreLoaderDataSources,
      topKeywordsPreLoader,
      topHashtagsPreLoader,
      topCommentsPreLoaderDataSources,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      postsVolume,
      sentimentAnalysis,
      topKeywords,
      topHashtags,
      authorActivity,
      dialects,
      subDialects,
      topComments,
    );
  }, [
    postsVolume,
    sentimentAnalysis,
    topKeywords,
    topHashtags,
    authorActivity,
    dialects,
    subDialects,
    topComments,
    postsVolumePreLoaderDataSources,
    sentimentAnalysisPreLoader,
    dialectsPreLoader,
    authorActivityPreLoaderDataSources,
    topKeywordsPreLoader,
    topHashtagsPreLoader,
    topCommentsPreLoaderDataSources,
  ]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      topCommentsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([topComments]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    topComments,
    topCommentsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"comments_volume"}
          titleToolTip="comments_volume_ig_tooltip"
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          labelColor={"#2AAAE2"}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.FacebookIcon}
          lineChartPreLoaderDataSources={postsVolumePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis"}
          titleToolTip="comments_sentiment_analysis_tooltip"
          data={sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          dataPiechart={sentimentPieChart}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
        />
      </Box>
      <ThemeSection
        iconAfterTitle={props.FacebookIcon}
        {...props}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        eventName="Facebook__EngagementsPage__themes_and_sub_themes"
        themeTrend={themeTrend}
        setThemeTrend={setThemeTrend}
        themeAnalysis={themeAnalysis}
        setThemeAnalysis={setThemeAnalysis}
        themeTrendPreLoader={themeTrendPreLoader}
        setThemeTrendPreLoader={setThemeTrendPreLoader}
        themeAnalysisPreLoader={themeAnalysisPreLoader}
        setThemeAnalysisPreLoader={setThemeAnalysisPreLoader}
        isSocialListening
      />

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_keywords"}
              titleToolTip="comments_top_keywords_tooltip"
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              iconAfterTitle={props.FacebookIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_hashtags"}
              titleToolTip="comments_top_hashtags_tooltip"
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              iconAfterTitle={props.FacebookIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Typography className="section-name-secondary">
          {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
        </Typography>
      </Box>
      <Box my={3} className="channel-activity">
        <PunchCardWidget
          title="engagers_activity"
          titleToolTip="engagers_activity__account_fb_tooltip"
          clientActivity={authorActivity}
          minValueClient={minValueAuthorActivity}
          maxValueClient={maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.FacebookIcon}
          authorActivityPreLoaderDataSources={
            authorActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip="dialects_subdialects_tooltip"
              data={dialects}
              subData={subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              iconAfterTitle={props.FacebookIcon}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={dialectsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="top-posts-head">
        <Box>{CheckValueLocale("top_comments_mentions", "", {}, intl)}</Box>
        <Link
          to={{
            pathname: `/monitor/${monitorType}/${monitorDataSource}/comments_mentions/${monitorId}`,
            state: monitorDetails,
          }}
          className="top-posts-all"
          id={`check-mentions-comments-${
            monitorType?.toLowerCase() ?? "monitor-type"
          }-${
            monitorDataSource?.toLowerCase() ?? "monitor-data-source"
          }-comments-mentions-${monitorId ?? "monitor-id"}`}
        >
          {CheckValueLocale("check_all_comments_mentions", "", {}, intl)} (
          {topCommentsCount})
        </Link>
      </Box>
      <Box>
        <QuestionCard
          unixDate={props.unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={topComments}
          latestUnanswerPreloader={topCommentsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faFacebook}
          iconColor={"facebook_icon"}
          showHeart={true}
          showDefaultImg={true}
          className={"no-data-found-question"}
          hideChartBar
          hideChangeSentiment
        />
      </Box>
      <TopicsSection iconToDisplay={props.FacebookIcon} isFacebook {...props} />
    </>
  );
};

export default PublicFBEngagementBody;
