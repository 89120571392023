import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Button, Box, Tooltip, Divider } from "@mui/material";
import { Add, FilterAlt, CalendarToday } from "@mui/icons-material";
import { handlelUserRoles } from "utils/helpers/index.js";
import { CheckValueLocale } from "utils/helpers/index";
import PageHeader from "components/pageHeader.js";
import Luci from "pages/luci";

const EmptyHeader = (props) => {
  const intl = useIntl();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <Luci isInactive={true} />
      <Box className="export-monitor-box-container">
        <Box className="export-monitor-box analytics-header">
          {props?.showFiltersBtn ? (
            <Button
              startIcon={<FilterAlt />}
              className="disabled-btn-empty filters-btn"
              disabled={true}
              filterButtonID="channel-analytics-insights-filter-btn"
            >
              {CheckValueLocale("filter", "", {}, intl)}
            </Button>
          ) : null}
          <Divider orientation="vertical" />
          {props?.showDatePicker ? (
            <Button
              startIcon={<CalendarToday />}
              className="disabled-btn-empty calender-btn"
              disabled={true}
              id="channel-analytics-insights-date-picker-btn"
            >
              {CheckValueLocale("date", "", {}, intl)}
            </Button>
          ) : null}
        </Box>
      </Box>

      <Box className="channel-analytics-title divider-margin">
        <div className="create-new-channel create-new-channel-empty">
          <div>
            <PageHeader title={props?.title} boxXS={4} />
          </div>
          {handlelUserRoles("CXM", "CREATE_CHANNEL") &&
          props?.showNewChannelBtn ? (
            <span
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className="new-channel-span"
            >
              <Tooltip
                title={CheckValueLocale(
                  "new_channel_btn_tooltip",
                  "",
                  {},
                  intl,
                )}
                placement="bottom"
                arrow
                id="manage-channel-btn-tooltip"
                open={!props?.companyCanCreateChannel && isHovered}
              >
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    window.location.href = "/manage-channels/add-channel";
                  }}
                  className={`new__channel__filter ${
                    !props?.companyCanCreateChannel && isHovered
                      ? "new-channel-filter-disabled-hover"
                      : ""
                  }`}
                  disabled={
                    !handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH") ||
                    !handlelUserRoles("GENERAL", "VIEW_USER_AUTH") ||
                    !props?.companyCanCreateChannel
                  }
                  id="channel-analytics-insights-add-new-channel-btn"
                >
                  {CheckValueLocale("new_channel_insights", "", {}, intl)}
                </Button>
              </Tooltip>
            </span>
          ) : null}
        </div>
      </Box>
    </div>
  );
};
export default EmptyHeader;
