import React, { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import ContentAddBtn from "../contentAddBtn";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./contentTab.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerPlus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import SectionsContainer from "./SectionsContainer";
import DragableQuestions from "./DragableQuestions";

const ContentTab = (props) => {
  const {
    surveySections,
    setSurveySections,
    filteredSections,
    handleNewSection,
    handleDuplicateSection,
    handleDeleteSection,
    emptySection,
    setEmptySection,
    handleDeleteQuestion,
    setSQIndex,
    sqIndex,
    setSSIndex,
    ssIndex,
    setOperationPerformed,
    scrollToIdFunc,
    proceedError,
    afterSecError,
    secTitleErr,
    handleSectionDir,
  } = props;

  const intl = useIntl();

  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [newIndex, setNewIndex] = useState(0);
  const open = Boolean(anchorEl);

  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
  };

  const handleDelete = (index, sIndex, qIndex) => {
    setSQIndex(index);
    setAnchorEl(null);
    handleDeleteQuestion(sIndex, qIndex);
    setStats({ openDeleteModal: false });
  };

  return (
    <Box className="content-tab-container">
      <Box className="content-tab-header">
        <Box className="title" data-testid="content">
          {CheckValueLocale("questions", "", {}, intl).toUpperCase()}
        </Box>
        {filteredSections?.length < 2 && (
          <ContentAddBtn
            surveySections={surveySections}
            setSurveySections={setSurveySections}
            filteredSections={filteredSections}
            setSQIndex={setSQIndex}
            sqIndex={sqIndex}
            setSSIndex={setSSIndex}
            ssIndex={ssIndex}
            setOperationPerformed={setOperationPerformed}
            emptySection={emptySection}
            setEmptySection={setEmptySection}
            scrollToIdFunc={scrollToIdFunc}
          />
        )}
      </Box>
      {filteredSections?.length < 2 ? (
        <DragableQuestions
          sectionIndex={0}
          sectionQuestions={filteredSections[0]?.questions}
          filteredSections={filteredSections}
          surveySections={surveySections}
          setSurveySections={setSurveySections}
          setSQIndex={setSQIndex}
          sqIndex={sqIndex}
          setSSIndex={setSSIndex}
          ssIndex={ssIndex}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          open={open}
          setOperationPerformed={setOperationPerformed}
          setNewIndex={setNewIndex}
          setStats={setStats}
          scrollToIdFunc={scrollToIdFunc}
          proceedError={proceedError}
        />
      ) : (
        <SectionsContainer
          surveySections={surveySections}
          setSurveySections={setSurveySections}
          filteredSections={filteredSections}
          emptySection={emptySection}
          setEmptySection={setEmptySection}
          sqIndex={sqIndex}
          setSQIndex={setSQIndex}
          ssIndex={ssIndex}
          setSSIndex={setSSIndex}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          open={open}
          setOperationPerformed={setOperationPerformed}
          setNewIndex={setNewIndex}
          setStats={setStats}
          handleDuplicateSection={handleDuplicateSection}
          handleDeleteSection={handleDeleteSection}
          scrollToIdFunc={scrollToIdFunc}
          proceedError={proceedError}
          afterSecError={afterSecError}
          secTitleErr={secTitleErr}
          handleSectionDir={handleSectionDir}
        />
      )}

      <Divider mt={2} />
      <Box m={2}>
        <LucButton
          variant="flat"
          startIcon={<FontAwesomeIcon icon={faLayerPlus} />}
          onClick={() => handleNewSection()}
          disabled={
            filteredSections?.length < 2 &&
            filteredSections[0]?.questions?.filter(
              (question) => question?.is_deleted !== true,
            )?.length < 1 &&
            true
          }
        >
          {CheckValueLocale("new_section", "", {}, intl)}
        </LucButton>
      </Box>
      {stats.openDeleteModal && (
        <PopupModal
          title={stats.title}
          body={stats.body}
          leftBtn={stats.modalLeftBtn}
          rightBtn={stats.modalRightBtn}
          open={stats.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={() => handleDelete(newIndex, stats?.sIndex, stats?.qIndex)}
          warning={stats.warning}
          classeName="delete-ques-modal"
        />
      )}
    </Box>
  );
};

export default ContentTab;
