import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import Pagination from "@mui/material/Pagination";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";

const LinkedInInteractionBody = (props) => {
  let { response } = props;
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [latestInteractionsCount, setLatestInteractionsCount] = useState("");
  const [latestInteractionPreDataSource, setLatestInteractionPreDataSource] =
    useState([]);

  let paginationCount = Math.ceil(latestInteractionsCount / 10);

  useEffect(() => {
    if (
      response?.eventName ===
      "Linkedin__CASingleDataSourceInteractionsPage__top_posts_comments"
    ) {
      setLatestInteractionPreDataSource([response?.data_source]);
      if (props.page === 1) {
        setLatestInteractionsCount(response?.eventData?.interactions_count);
      }
      response?.eventData?.top_posts?.map((interaction) => {
        return setLatestInteractions((oldArray) => [...oldArray, interaction]);
      });
    }
  }, [response]);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  // Refresh all values at Each request to default values
  useEffect(() => {
    if (props.resetAllFields !== 1) {
      setLatestInteractions([]);
      setLatestInteractionPreDataSource([]);
    }
  }, [props.resetAllFields]);
  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionPreDataSource,
      props?.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionPreDataSource,
    props?.preLoaderTrackerDataSources,
  ]);
  return (
    <>
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          data={latestInteractions}
          dataSources={props?.dataSources}
          latestInteractionsPreLoaderDataSources={
            latestInteractionPreDataSource
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          latestInteractionsCount={latestInteractionsCount}
          loader={props?.loader}
        />
      </Box>
      {latestInteractions.length > 0 && latestInteractionsCount > 10 && (
        <Pagination
          onChange={props?.handleChangePage}
          page={props?.page}
          className="pagination-monitor-list"
          count={paginationCount}
          variant="outlined"
        />
      )}
    </>
  );
};

export default LinkedInInteractionBody;
