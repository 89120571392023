import { Box, Pagination } from "@mui/material";
import Header from "pages/Monitors/Components/header";
import QuestionCard from "pages/Monitors/Components/questionCard";
import "./exlporeTopPosts.scss";

const ExlporeTopPosts = (props) => {
  const {
    topPosts,
    topPostsCount,
    setPageNumber,
    pageNumber,
    exploreId,
    preLoaderTrackerDataSources,
    getShowExploreData,
    topPostsPreloader,
    sortBy,
    setSortBy,
    sortByData,
    filterParams,
  } = props;
  const handleChange = (event) => {
    setSortBy(event.target.value);
    exploreId &&
      getShowExploreData(
        exploreId,
        pageNumber,
        event.target.value,
        filterParams,
      );
  };

  const handleChangePage = (event, pageNumber) => {
    if (Boolean(pageNumber) === false) return;
    setPageNumber(pageNumber);
    exploreId &&
      getShowExploreData(exploreId, pageNumber, sortBy, filterParams);
  };

  return (
    <Box className="explore-top-posts">
      <Header
        title="posts"
        sortByData={sortByData}
        sortBy={sortBy}
        handleChange={handleChange}
        isQuickSearch
      />

      <QuestionCard
        latestUnanswer={topPosts}
        latestUnanswerPreloader={topPostsPreloader}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        showRetweet={true}
        showBanIcon={false}
        showScreenName={true}
        noOfChar={15}
        hideChartBar
        showDefaultImg={true}
        isAggregateDashboard
        newsBlogImg={true}
        showFlash
        showEye
        showEarth
        isQuickSearch
      />

      {topPosts?.length > 0 && Math.ceil(topPostsCount / 10) > 1 && (
        <Pagination
          onChange={handleChangePage}
          page={pageNumber}
          className="pagination-monitor-list"
          count={Math.ceil(topPostsCount / 10)}
          variant="outlined"
        />
      )}
    </Box>
  );
};

export default ExlporeTopPosts;
