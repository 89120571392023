import { Box, Grid, Typography } from "@mui/material";
import MediaTw from "components/media/media-tw/mediaTw";
import LineChartWidget from "components/widgets/lineChartWidget";
import { useIntl } from "react-intl";
import BarChartWidget from "components/widgets/barChartWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import { CheckValueLocale } from "utils/helpers";

const AccountSection = (props) => {
  let { tikTokIcon, tikTokWidgets, preLoaderTrackerDataSources } = props;

  const intl = useIntl();

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("account_overview_tik", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"video_volume"}
          titleToolTip="vid_vol_tolt"
          totalLabel={"video_content_type"}
          totalValue={tikTokWidgets?.totalPostsVolumeCount}
          data={tikTokWidgets?.postsVolume}
          showGroupBy={true}
          toolTipLabel={"video_content_type"}
          labelColor={"#2AAAE2"}
          showDownloadIcon
          bigImg
          showNotAppliedFilter
          iconAfterTitle={tikTokIcon}
          lineChartPreLoaderDataSources={
            tikTokWidgets?.postsVolumePreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <BarChartWidget
          title={"followers_growth"}
          titleToolTip="foll_gro_tolt"
          data={tikTokWidgets?.followersGrowth}
          showGroupBy={true}
          dataColor="#2AAAE2"
          showChartDetailsWidget
          showNotAppliedFilter
          showZoomPercentage
          statsDetailsData={[
            {
              name: "total_followers",
              value: tikTokWidgets?.totalFollowersFollowersGrowth,
            },
            {
              name: "change_of_followers",
              value: tikTokWidgets?.maxChangeFollowersGrowth,
            },
            {
              name: "avg_change",
              value: tikTokWidgets?.avgChangeFollowersGrowth,
            },
          ]}
          toolTipLabel={"posts"}
          showFollowersGrowthStats
          showDownloadIcon
          bigImg
          iconAfterTitle={tikTokIcon}
          barChartPreLoaderDataSources={
            tikTokWidgets?.followersGrowthPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={3} className="channel-activity">
        <PunchCardWidget
          title="account_authors_activity"
          titleToolTip="autor_tolt"
          clientActivity={tikTokWidgets?.authorActivity}
          minValueClient={tikTokWidgets?.minValueAuthorActivity}
          maxValueClient={tikTokWidgets?.maxValueAuthorActivity}
          showDownloadIcon
          showNotAppliedFilter
          bigImg
          iconAfterTitle={tikTokIcon}
          authorActivityPreLoaderDataSources={
            tikTokWidgets?.authorActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"videos_interactions"}
          titleToolTip="vid_inter_tolt"
          showDownloadIcon
          bigImg
          posts={tikTokWidgets?.postsInteractions}
          showChartDetailsWidget
          showScales
          showNotAppliedFilter
          showGroupBy={false}
          statsDetailsData={[
            {
              name: "max_interaction",
              value: tikTokWidgets?.maxValuePostsInteractions,
              date: tikTokWidgets?.maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: tikTokWidgets?.minValuePostsInteractions,
              date: tikTokWidgets?.minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: tikTokWidgets?.avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#334155", "#80868C", "#CBD5E1"]}
          labels={["like_count", "comment_count", "view_count", "share_count"]}
          showPostsInteractionStats
          showZoomPercentage
          iconAfterTitle={tikTokIcon}
          postsColumnPreLoaderDataSources={
            tikTokWidgets?.postsInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={tikTokWidgets?.postInteractionsLegend}
        />
      </Box>
      <Box my={4}>
        <MediaTw
          title={CheckValueLocale("top_videos", "", {}, intl)}
          titleToolTip="top_vid_tolt"
          data={tikTokWidgets?.topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={tikTokIcon}
          showNotAppliedFilter
          mediaTwPreLoaderDataSources={
            tikTokWidgets?.topVideosPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isTiktok
        />
      </Box>
    </>
  );
};

export default AccountSection;
