import { useIntl } from "react-intl";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import "./style.scss";

const ScaleProceedModalBody = (props) => {
  const {
    filteredSections,
    question,
    sectionIndex,
    tempRemainingValueGoTo,
    setTempRemainingValueGoTo,
    remainingValues,
    tempProceedValues,
    setTempProceedValues,
    showRemoveBtn,
    handleChangeProceedValue,
    handleChangeProceedGoTo,
    handleRemoveCondition,
    handleItemsList,
  } = props;

  const intl = useIntl();

  const menuProps = {
    PaperProps: { style: { maxWidth: "260px" } },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: intl?.locale == "ar" ? "right" : "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: intl?.locale == "ar" ? "right" : "left",
    },
  };

  const sectionsList = [
    sectionIndex !== filteredSections?.length - 1 && {
      label: CheckValueLocale("continue_next_section", "", {}, intl),
      value: filteredSections[sectionIndex]?.order + 1,
    },
    {
      label: CheckValueLocale("submit_survey", "", {}, intl),
      value: -1,
    },
    ...(filteredSections
      ?.filter((_, i) => i !== sectionIndex && i !== sectionIndex + 1)
      ?.map((sec) => ({
        label: `${CheckValueLocale("section", "", {}, intl)} ${sec?.order} (${sec?.name})`,
        value: sec?.order,
      })) || []),
  ];

  return (
    <Box id="scale-proceed-popup-body">
      <Typography>
        {CheckValueLocale("proceed_based_on_answer_description", "", {}, intl)}
      </Typography>

      <Box className="scale-proceed-inputs-container">
        <Typography variant="h6" className="sec-title">
          {CheckValueLocale("if_answer_is", "", {}, intl)}
        </Typography>

        {tempProceedValues?.map((proceedItem, index) => (
          <Grid
            key={index}
            container
            spacing={2}
            className="scale-proceed-popup-body-grid"
          >
            <Grid item xs={6}>
              <FormControl
                className="scale-select-form-control"
                size="small"
                fullWidth
              >
                <InputLabel id="scale-proceed-input-label">
                  {CheckValueLocale("proceed_value", "", {}, intl)}
                </InputLabel>
                <Select
                  id="scale-proceed-input"
                  labelId="scale-proceed-input-label"
                  label={CheckValueLocale("proceed_value", "", {}, intl)}
                  variant="outlined"
                  multiple
                  renderValue={(selected) => selected?.join(", ")}
                  value={proceedItem?.value || []}
                  onChange={(e) =>
                    handleChangeProceedValue(e.target.value, index)
                  }
                >
                  {handleItemsList(index)?.map((item) => {
                    const checked = !proceedItem?.value?.includes(item)
                      ? false
                      : true;

                    return (
                      <MenuItem
                        id="scale-items-list-menu-item"
                        key={item}
                        value={item}
                        disabled={!checked && remainingValues?.length < 2}
                      >
                        <Checkbox checked={checked} />
                        <ListItemText primary={item} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={showRemoveBtn ? 5 : 6}>
              <FormControl
                className="scale-select-form-control"
                size="small"
                fullWidth
              >
                <InputLabel id="scale-go-to-input-label">
                  {CheckValueLocale("go_to", "", {}, intl)}
                </InputLabel>
                <Select
                  id="scale-go-to-input"
                  label={CheckValueLocale("go_to", "", {}, intl)}
                  labelId="scale-go-to-input-label"
                  variant="outlined"
                  MenuProps={menuProps}
                  value={proceedItem?.next_section}
                  onChange={(e) =>
                    handleChangeProceedGoTo(e.target.value, index)
                  }
                >
                  {[...(sectionsList?.filter((item) => !!item) || [])]?.map(
                    (opt) => (
                      <MenuItem
                        key={opt?.value}
                        className={`scale-select-item ${opt?.value}`}
                        value={opt?.value}
                      >
                        <Box component="span" className="text-box">
                          {opt?.label}
                        </Box>
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
            {showRemoveBtn ? (
              <Grid item xs={1}>
                <LucButton
                  id="scale-proceed-on-answer-remove-condition-btn"
                  type="secondary"
                  variant="flat"
                  minWidth={40}
                  onClick={() => handleRemoveCondition(index)}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </LucButton>
              </Grid>
            ) : null}
          </Grid>
        ))}
      </Box>

      <LucButton
        id="scale-proceed-on-answer-add-condition-btn"
        variant="flat"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        disabled={
          remainingValues?.length < 2 ||
          tempProceedValues?.some(
            (item) => !item.value?.length || !item?.next_section,
          )
        }
        onClick={() => {
          setTempProceedValues([
            ...tempProceedValues,
            { value: null, next_section: null },
          ]);
        }}
      >
        {CheckValueLocale("add_condition", "", {}, intl)}
      </LucButton>
      <Divider />
      <Typography variant="h6" className="sec-title">
        {CheckValueLocale(
          remainingValues?.length === question?.items?.length
            ? "all_values"
            : "remaining_values",
          "",
          {},
          intl,
        )}
        {remainingValues?.length === question?.items?.length ? null : (
          <Box component="span"> ({remainingValues?.join(", ")})</Box>
        )}
      </Typography>
      <Box className="scale-proceed-inputs-container">
        <FormControl
          className="scale-select-form-control"
          size="small"
          fullWidth
        >
          <InputLabel id="scale-go-to-input-label">
            {CheckValueLocale("go_to", "", {}, intl)}
          </InputLabel>
          <Select
            id="scale-go-to-input"
            label={CheckValueLocale("go_to", "", {}, intl)}
            labelId="scale-go-to-input-label"
            variant="outlined"
            MenuProps={menuProps}
            value={tempRemainingValueGoTo}
            onChange={(e) => setTempRemainingValueGoTo(e.target.value)}
          >
            {[...(sectionsList?.filter((item) => !!item) || [])]?.map((opt) => (
              <MenuItem
                key={opt?.value}
                className={`scale-select-item ${opt?.value}`}
                value={opt?.value}
              >
                <Box component="span" className="text-box">
                  {opt?.label}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ScaleProceedModalBody;
