import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
// ___________________________________________________________
import DeepInsightsProfileDetails from "./components/profileDetails";
import DeepInsightsPersonality from "./components/personality";
import DeepInsightsInterests from "./components/interests";
import DeepInsightsEngagement from "./components/engagmentInTheMonitor";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import MonitorsController from "services/controllers/monitorsController";
import CircularLoading from "components/circularProgress";
import { MonitorContainer } from "../../../../Components/sidebar/monitor.styles";
import { useParams } from "react-router-dom";
import { convertXPlateformToTwitter } from "utils/helpers";

// ___________________________________________________________

const DeepInsightsPage = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorDataSource = convertXPlateformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorId = urlParams.monitorId;
  const userId = urlParams.userId;
  const accountName = urlParams.userName;
  // ___________________________________________________________
  const [userName, setUserName] = useState("");
  const [screenName, setScreenName] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [userBio, setUserBio] = useState("");
  const [isLoading, setLoading] = useState(false);
  // ___________________________________________________________
  const [activityTimeMaxValue, setActivityTimeMaxValue] = useState(0);
  const [activityTimeMinValue, setActivityTimeMinValue] = useState(0);
  const [activityTime, setActivityTime] = useState([]);
  // ___________________________________________________________
  const [topKeywords, setTopKeywords] = useState([]);
  const [interests, setInterests] = useState({});
  // ___________________________________________________________
  const [averageTweetPerTweet, setAverageTweetPerTweet] = useState("");
  const [engagementPerFollower, setEngagementPerFollower] = useState("");
  // ___________________________________________________________
  const [personalityName, setPersonalityName] = useState("");

  /*---------------------------------------------------------*/

  const getDeepInsightData = () => {
    setLoading(true);
    MonitorsController.getDeepInsightData(
      monitorDataSource,
      monitorId,
      window.localStorage.sm_id,
      accountName,
      userId,
    ).then(({ data }) => {
      setUserBio(data.data.user_desc);
      setUserName(data.data.user_name);
      setScreenName(data.data.user_screen_name);
      setProfileImg(data.data.user_profile_url);
      /*---------------------------------------------------------*/
      setActivityTimeMaxValue(data.data.activity_time_max_value);
      setActivityTimeMinValue(data.data.activity_time_min_value);
      setActivityTime(data.data.activity_time);
      /*---------------------------------------------------------*/
      setTopKeywords(data.data.top_keywords);
      setInterests(data.data.interests);
      /*---------------------------------------------------------*/
      setAverageTweetPerTweet(data.data.average_engagement_per_tweet);
      setEngagementPerFollower(data.data.engagement_per_1000_follower);
      /*---------------------------------------------------------*/
      setPersonalityName(data.data.personality);
      /*---------------------------------------------------------*/
      setLoading(false);
    });
  };

  //to call getDeepInsightData in first page load
  useEffect(() => {
    getDeepInsightData();
  }, []);

  /*---------------------------------------------------------*/

  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          {/* /*-------------------DeepInsightsProfileDetails--------------------------------------*/}
          <DeepInsightsProfileDetails
            userName={userName}
            screenName={screenName}
            profileImg={profileImg}
            userBio={userBio}
          />
          {/* /*---------------------DeepInsightsPersonality & DeepInsightsInterests------------------------------------*/}
          <Grid container spacing={3} mb={3}>
            <Grid item xs={6}>
              <DeepInsightsPersonality personalityName={personalityName} />
            </Grid>
            <Grid item xs={6}>
              <DeepInsightsInterests interests={interests} />
            </Grid>
          </Grid>

          {/* /*--------------------Activity Time-------------------------------------*/}
          <Box my={4}>
            <PunchCardWidget
              title="activity_time"
              titleToolTip="activity_time"
              clientActivity={activityTime}
              minValueClient={activityTimeMinValue}
              maxValueClient={activityTimeMaxValue}
              showDownloadIcon
              bigImg
              authorActivityPreLoaderDataSources={[1]}
              preLoaderTrackerDataSources={[1]}
            />
          </Box>
          {/* /*---------------------------------------------------------*/}

          {/* /*---------------------DeepInsightsEngagement && DeepInsightsExtraInformation && DeepInsightsOtherSocial------------------------------------*/}
          <Grid container spacing={3} className="deep-insight-body">
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"top_keywords"}
                data={topKeywords}
                leftColomnTitle={"keywords"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={[1]}
                preLoaderTrackerDataSources={[1]}
                keywords
                tablePagination
                count={topKeywords.length}
                iconAfterTitle={props.twitterIcon}
              />
            </Grid>

            <Grid item xs={6}>
              <DeepInsightsEngagement
                averageTweetPerTweet={averageTweetPerTweet}
                engagementPerFollower={engagementPerFollower}
              />
            </Grid>
          </Grid>
        </>
      )}
    </MonitorContainer>
  );
};

export default DeepInsightsPage;
