import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const QuickInspectPopupModal = (props) => {
  const { open, close, title, body } = props;
  return (
    <Dialog
      open={open}
      onClose={close}
      id="quick-inspect-dialog"
      className={props.addClasses ? props.addClasses : ""} //custom style for the modal
    >
      <div className="inspect-header">
        <DialogTitle className="inspect-title">{title}</DialogTitle>
        <CloseIcon className="close-btn" onClick={close} />
      </div>
      <DialogContent className={props.modalStyle ? props.modalStyle : ""}>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default QuickInspectPopupModal;
