import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import "../../App.scss";

export default function CircularDottedLoading() {
  const intl = useIntl();

  return (
    <Box className="circular-dotted-loading">
      <FontAwesomeIcon icon={faSpinner} spin />
      <Typography component={"span"}>
        {CheckValueLocale("loading_dotted", "", {}, intl)}
      </Typography>
    </Box>
  );
}
