import "./noDataFound.scss";
import { useIntl } from "react-intl";
import NoDataFound from "./noDataFound";

const TopicsNoDataFound = (props) => {
  const intl = useIntl();

  return (
    <div className="topics-no-data">
      <div className="no-data-date">
        <NoDataFound />
      </div>
    </div>
  );
};

export default TopicsNoDataFound;
