import moment from "moment";
import { isEmptyArray, convertToDhmsExcelSheet } from "utils/helpers";
import {
  handleXlsData,
  handleSortedCase,
  handleAuthorActivity,
} from "../excelCommonFn";

const googlemybusiness = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var overView = [],
      interactions = [],
      sentimentAnalysis = [],
      whereFindBusiness = [],
      excelTopCountries = [],
      excelTopCities = [],
      excelGenderDistribution = [],
      excelDialects = [],
      excelSubDialects = [],
      topKeywords = [],
      rateReviews = [],
      allSheetData = [];

    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "overView":
            value?.map((i) => {
              overView.push({
                [lang.xlsx_name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.xlsx_value]:
                  i?.name == "net_sentiment"
                    ? lang[i?.value[0]?.name]
                    : i?.value,
              });
            });
            allSheetData.push([overView, lang.xlxs_overview]);
            break;

          case "interactions":
            value?.map((i) => {
              interactions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.reviews]: i?.value,
              });
            });
            allSheetData.push([interactions, lang.reviews]);
            break;

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i?.name == value?.neutral[index]?.name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100)?.toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? (
                          (value?.positive[index]?.value / total) *
                          100
                        )?.toFixed(2) + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? (
                          (value?.negative[index]?.value / total) *
                          100
                        )?.toFixed(2) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([sentimentAnalysis, lang.sentiment_analysis]);
            break;

          case "whereFindBusiness":
            value?.map((i) => {
              whereFindBusiness.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.listing_on_search]: i?.value[0],
                [lang.listing_on_maps]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData.push([
              whereFindBusiness,
              lang.where_find_your_business_xlxs,
            ]);
            break;

          case "excelTopCountries":
            value?.map((i) => {
              excelTopCountries.push({
                [lang.countries]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelTopCountries, lang.top_countries]);
            break;
          //_______________________________________________________________________________

          case "excelTopCities":
            value?.map((i) => {
              excelTopCities.push({
                [lang.cities]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelTopCities, lang.top_cities]);
            break;

          case "excelGenderDistribution":
            value?.map((i) => {
              excelGenderDistribution.push({
                [lang.gender]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([
              excelGenderDistribution,
              lang.genders_distribution,
            ]);
            break;

          case "excelDialects":
            handleSortedCase(
              value,
              excelDialects,
              lang.dialect,
              lang.main_dialect_filter,
              lang,
            );
            allSheetData.push([excelDialects, lang.main_dialect_filter]);
            break;
          // //_______________________________________________________________________________

          case "excelSubDialects":
            handleSortedCase(
              value,
              excelSubDialects,
              lang.sub_dialects,
              lang.number,
              lang,
            );
            allSheetData.push([excelSubDialects, lang.sub_dialects]);
            break;
          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([topKeywords, lang.top_keywords_xlsx_sheetname]);
            break;
          case "rateReviews":
            value?.rating_out_of_10?.length &&
              value?.rating_out_of_10?.map((item, i) => {
                rateReviews.push({
                  [lang.total_reviews]: i == 0 ? value?.review : "",
                  [lang.average_rating]:
                    i == 0 ? value?.avg_positive_reviews : "",
                  [lang.out_of + " 5"]: value?.rating_out_of_10?.length - i,
                  [lang.count]: item,
                });
              });

            allSheetData.push([rateReviews, lang.rate_reviews]);
            break;
          default:
            break;
        }
      });
    });
    return handleXlsData(allSheetData);
  }
};
export { googlemybusiness };
