import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LuciApplyingOn from "../ApplyingOn/luciApplyingOn";
import { useState } from "react";

const LuciQuestionItem = (props) => {
  const {
    question,
    questionNameLocale,
    handleCreateFreeQuestions,
    isSurvey,
    individualSurvey,
    setIndividualSurvey,
    page,
    setPage,
    showConfirmationPopup,
    showSurvey,
    setShowSurvey,
  } = props;

  return (
    <>
      <Box
        className="luci-question-item"
        onClick={() => {
          if (isSurvey) {
            setShowSurvey({ show: true, question: question });
          } else {
            handleCreateFreeQuestions(question, questionNameLocale(question));
          }
        }}
      >
        <Typography component={"p"} className="question-item-data">
          {questionNameLocale(question)}
        </Typography>
        <KeyboardArrowRightIcon className="question-arrow-icon" />
      </Box>
    </>
  );
};

export default LuciQuestionItem;
