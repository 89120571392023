import React from "react";
import { useIntl } from "react-intl";
import QRCode from "react-qr-code";
import { Box, InputAdornment, Tab, TextField } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  faExclamationCircle,
  faLink,
} from "@fortawesome/pro-regular-svg-icons";
import StepTwo from "./stepTwo";
import LucButton from "shared/lucButton/lucButton";
import CustomizedTooltip from "components/customizedTooltip";

const StepThree = (props) => {
  const {
    stepTwo,
    checkIsSurveyPage,
    isSurveyPanelEnabled,
    handleChange,
    checkSwitch,
    surveyPanelList,
    selectedValues,
    setSelectedValues,
    surveyPanel,
    SurveyspanelById,
    setError,
    value,
    handleChangeTabs,
    isSurveyEnabled,
    checkIfLucidyaCustomDomain,
    surveyData,
    copied,
    handleCopy,
    downloadImg,
  } = props;
  const intl = useIntl();

  return (
    <>
      <Box className="share-survey-container">
        {!stepTwo && isSurveyPanelEnabled ? (
          <Box className="close-share-btn">
            {!checkIsSurveyPage && <Box className="publish-step">3 / 3</Box>}
          </Box>
        ) : null}
        <Box className="tabcontext-parant">
          <TabContext value={value}>
            {!checkIsSurveyPage ? (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChangeTabs}>
                  <Tab
                    label={CheckValueLocale("survey_share", "", {}, intl)}
                    value="1"
                    className="tab-survey-item"
                  />
                  <Tab
                    label={CheckValueLocale("survey_Panel", "", {}, intl)}
                    value="2"
                    className="tab-survey-item"
                  />
                </TabList>
              </Box>
            ) : null}
            <TabPanel className="tap-content" value="1">
              {isSurveyEnabled ? (
                <Box className="alert-box">
                  <FontAwesomeIcon
                    className="exclamation-circle"
                    icon={faExclamationCircle}
                  />
                  {CheckValueLocale("survey_already_published", "", {}, intl)}
                </Box>
              ) : null}
              <Box className={value == 1 ? "share-left-side-stp" : ""}>
                <Box className="share-left-side">
                  <Box
                    component="span"
                    className="share-type"
                    id="survey-share-check-type-text"
                  >
                    {CheckValueLocale("with_link", "", {}, intl)}
                  </Box>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={`${checkIfLucidyaCustomDomain}${surveyData?.url_slug}`}
                    className="publish-input-filed"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <LucButton
                            onClick={handleCopy}
                            variant={copied ? "outline" : "filled"}
                            size="small"
                            id="survey-share-btn-item"
                            className={copied && "share-btn-copied"}
                          >
                            {CheckValueLocale(
                              copied ? "survey_copied" : "copy",
                              "",
                              {},
                              intl,
                            )}
                          </LucButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box className="share-icons">
                    <CustomizedTooltip
                      title={
                        <Box className="item-title-tooltip">
                          {CheckValueLocale("facebook", "", {}, intl)}
                        </Box>
                      }
                      placement="bottom"
                      arrow
                    >
                      <Box component="span" className="social-share-icon">
                        <FacebookShareButton
                          url={`${checkIfLucidyaCustomDomain}${surveyData?.url_slug}`}
                          size={32}
                          id="survey-share-facebook-btn"
                        >
                          <img className="main-icon" src={publicFacebook} />
                        </FacebookShareButton>
                      </Box>
                    </CustomizedTooltip>
                    <CustomizedTooltip
                      title={
                        <Box className="item-title-tooltip">
                          {CheckValueLocale("twitter", "", {}, intl)}
                        </Box>
                      }
                      placement="bottom"
                      arrow
                    >
                      <Box component="span" className="social-share-icon">
                        <TwitterShareButton
                          url={`${checkIfLucidyaCustomDomain}${surveyData?.url_slug}`}
                          id="survey-share-twitter-btn"
                        >
                          <img
                            className="main-icon"
                            src={xPlatform}
                            alt="x-platform-logo"
                          />
                        </TwitterShareButton>
                      </Box>
                    </CustomizedTooltip>
                    <CustomizedTooltip
                      title={
                        <Box className="item-title-tooltip">
                          {CheckValueLocale("whatsApp", "", {}, intl)}
                        </Box>
                      }
                      placement="bottom"
                      arrow
                    >
                      <Box component="span" className="social-share-icon">
                        <WhatsappShareButton
                          url={`${checkIfLucidyaCustomDomain}${surveyData?.url_slug}`}
                          id="survey-share-whatsapp-btn"
                        >
                          <FontAwesomeIcon icon={faWhatsapp} />
                        </WhatsappShareButton>
                      </Box>
                    </CustomizedTooltip>
                    <CustomizedTooltip
                      title={
                        <Box className="item-title-tooltip">
                          {CheckValueLocale("LinkedIn", "", {}, intl)}
                        </Box>
                      }
                      placement="bottom"
                      arrow
                    >
                      <Box component="span" className="social-share-icon">
                        <LinkedinShareButton
                          url={`${checkIfLucidyaCustomDomain}${surveyData?.url_slug}`}
                          id="survey-share-linkedin-btn"
                        >
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </LinkedinShareButton>
                      </Box>
                    </CustomizedTooltip>
                  </Box>
                </Box>
                <Box component="span" className="dashed-line"></Box>
                <Box
                  className="qr-share"
                  id={surveyData.id + "-" + surveyData.title}
                >
                  <Box component="span" className="share-type">
                    {CheckValueLocale("with_qr_code", "", {}, intl)}
                  </Box>
                  <QRCode
                    size={80}
                    value={`${checkIfLucidyaCustomDomain}${surveyData?.url_slug}`}
                    id={surveyData.id + "-" + surveyData.title}
                  />
                  <a
                    onClick={() => {
                      downloadImg(surveyData?.id + "-" + surveyData?.title);
                    }}
                    className="down-qr"
                    id="survey-share-download-btn"
                  >
                    {CheckValueLocale("download", "", {}, intl)}
                  </a>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <StepTwo
                isSurveyEnabled={isSurveyEnabled}
                stepTwo={stepTwo}
                isSurveyPanelEnabled={isSurveyPanelEnabled}
                handleChange={handleChange}
                checkSwitch={checkSwitch}
                surveyPanelList={surveyPanelList}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                surveyPanel={surveyPanel}
                SurveyspanelById={SurveyspanelById}
                setError={setError}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default StepThree;
