import FirebaseFCM from "../api/firebaseFCM.js";

var FirebaseFCMController = {};

FirebaseFCMController.userTokenFCM = (fcmToken) => {
  return new Promise(function (resolve) {
    FirebaseFCM.userTokenFCM(fcmToken)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default FirebaseFCMController;
