import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import InstagramIcon from "@mui/icons-material/Instagram";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";
import { getNoOfDays } from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import { handleUnixFormat } from "utils/helpers";
import OverView from "components/widgets/overViewWidget";

import InstagramAccountBody from "./components/instagramAccountBody";
import InstagramEngagementBody from "./components/instagramEngagementBody";
import InstagramCustomerCareBody from "./components/instagramCustomerCareBody";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import _ from "lodash";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";

const InstagramBody = (props) => {
  const intl = useIntl();
  let { response, startDate, endDate, setCheckAllDataFetchingExport } = props;

  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoaderDataSources: [],
    //follower growth
    // NOTE: we commented this code until BE fixed these widgets.
    // followersGrowth: [],
    // totalFollowersFollowersGrowth: 0,
    // maxChangeFollowersGrowth: 0,
    // avgChangeFollowersGrowth: 0,
    // followersGrowthPreLoaderDataSources: [],
    //posts Contact Details
    // postsContactDetails: [],
    // postsContactDetailsLegend: [],
    // postsContactDetailsPreLoaderDataSources: [],
    //author activity
    authorActivity: [],
    minValueAuthorActivity: 0,
    maxValueAuthorActivity: 0,
    authorActivityPreLoaderDataSources: [],
    //posts volume
    postsVolume: [],
    totalPostsVolumeCount: 0,
    postsVolumePreLoaderDataSources: [],
    // reach impression
    // reachImpression: [],
    // reachImpressionLegend: [],
    // reachImpressionPreLoaderDataSources: [],
    // reach: [],
    // impression: [],
    //post interactions
    postsInteractions: [],
    maxValuePostsInteractions: 0,
    minValuePostsInteractions: 0,
    avgValuePostsInteractions: 0,
    maxValueInteractionsDate: 0,
    minValueInteractionsDate: 0,
    postInteractionsLegend: [],
    postsInteractionsPreLoaderDataSources: [],

    // average engagement per posts
    averageEngagementPerPost: [],
    maxValueAverageEngagementPerPost: 0,
    minValueAverageEngagementPerPost: 0,
    avgValueAverageEngagementPerPost: 0,
    maxValueAverageEngagementPerPostDate: 0,
    setMaxValueAverageEngagementPerPostDate: 0,
    minValueAverageEngagementPerPostDate: 0,
    averageEngagementPerPostPreLoaderDataSources: [],
    //posts content type
    postsContentType: [],
    postContentPieChart: [],
    postsContentTypePreLoaderDataSources: [],
    //top Images
    topImages: [],
    topImagesPreLoaderDataSources: [],
    //top videos
    topVideos: [],
    topVideosPreLoaderDataSources: [],
    //comments volume
    commentsVolume: [],
    totalComments: 0,
    avgComments: 0,
    commentsPreLoaderDataSources: [],
    //sentiment widget
    sentimentAnalysis: [],
    sentimentPieChart: [],
    sentimentAnalysisPreLoaderDataSources: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],
    //themes widget
    themeXlxs: [],
    themeAnalysis: [],
    themeAnalysisPreLoaderDataSources: [],
    themeTrend: [],
    themeTrendPreLoaderDataSources: [],
    //commenst interactions
    commentsInteractions: [],
    commentsInteractionsLegend: [],
    totalValueCommentsInteractions: 0,
    maxValueCommentsInteractions: 0,
    maxValueCommentsInteractionsDate: 0,
    avgValueCommentsInteractions: 0,
    commentsInteractionsPreLoader: [],
    //top keywords
    topKeywords: [],
    topKeywordsPreLoader: [],
    //top Hashtags
    topHashtags: [],
    topHashtagsPreLoader: [],
    //associated Topics
    associatedTopics: [],
    excelAssociatedTopics: [],
    associatedTopicsContent: [],
    associatedTopicsPreLoader: [],
    //dialects
    dialects: [],
    excelDialects: [],
    subDialects: [],
    dialectsPreLoader: [],
    //gender age distribution
    // genderAgeDistribution: [],
    // genderAgeDistributionPreLoader: [],
    // genderAnalysisPieChart: [],
    //top cities
    // topCities: [],
    // topCitiesPreLoader: [],
    //top countries
    // topCountries: [],
    // topCountriesPreLoader: [],
    //top authors
    topAuthors: [],
    topAuthorsPreLoader: [],
    //customer care
    customerCarePerformance: [],
    unAnsweredQuestion: 0,
    respRate: 0,
    customerCareData: [],
    totalQuestion: 0,
    customerCarePreloader: [],
    //latestInteractions widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],
  };

  const [instagramWidgets, setInstagramWidgets] = useState(initialWidgetsState);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Instagram__CASingleDataSourceInsightsPage__overview":
        if (response?.eventData?.overview) {
          let overViewArray = Object?.entries(
            response?.eventData?.overview,
          )?.map((key) => ({
            name: key[0],
            value: key[1],
          }));
          setInstagramWidgets({
            ...instagramWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      // case "Instagram__AccountPage__followers_growth":
      //   setInstagramWidgets({
      //     ...instagramWidgets,
      //     followersGrowth: response?.eventData?.followers_change_over_time,
      //     totalFollowersFollowersGrowth: response?.eventData?.total_followers,
      //     maxChangeFollowersGrowth:
      //       response?.eventData?.max_change_of_followers,
      //     avgChangeFollowersGrowth: response?.eventData?.avg_chage_of_day,
      //     followersGrowthPreLoaderDataSources: [response?.data_source],
      //   });
      //   break;

      // case "Instagram__AccountPage__page_contact_details_interactions":
      //   setInstagramWidgets({
      //     ...instagramWidgets,
      //     postsContactDetails:
      //       response.eventData.page_contact_details_interactions_overtime,
      //     postsContactDetailsLegend:
      //       response.eventData.pie_chart_page_contact_details_interactions,

      //     postsContactDetailsPreLoaderDataSources: [response?.data_source],
      //   });

      //   break;

      case "Instagram__AccountPage__account_author_activity":
        setInstagramWidgets({
          ...instagramWidgets,
          authorActivity: response?.eventData?.account_author_activity,
          maxValueAuthorActivity: response?.eventData?.max_value,
          minValueAuthorActivity: response?.eventData?.min_value,
          authorActivityPreLoaderDataSources: [response?.data_source],
        });

        break;

      case "Instagram__AccountPage__volume_overtime":
        setInstagramWidgets({
          ...instagramWidgets,
          postsVolume: response?.eventData?.posts_over_time,
          totalPostsVolumeCount: response?.eventData?.total_posts_count,
          postsVolumePreLoaderDataSources: [response?.data_source],
        });

        break;

      // case "Instagram__AccountPage__reach_funnel":
      //   Object.entries(response.eventData.reach_funnel_overtime).forEach(
      //     ([key, value]) => {
      //       switch (key) {
      //         case "reach":
      //           value.map((i) => {
      //             return instagramWidgets?.reach.push(i);
      //           });
      //           break;
      //         case "impression":
      //           value.map((i) => {
      //             return instagramWidgets?.impression.push(i);
      //           });
      //           break;
      //         default:
      //           break;
      //       }
      //     }
      //   );

      //   setInstagramWidgets({
      //     ...instagramWidgets,
      //     reachImpression: {
      //       reach: instagramWidgets?.reach,
      //       impression: instagramWidgets?.impression,
      //     },
      //     reachImpressionLegend: response.eventData.reach_funnel_legends,
      //     postsVolumePreLoaderDataSources: [response?.data_source],
      //   });
      //   break;

      case "Instagram__AccountPage__posts_interactions":
        let dateMaxInteractions = handleUnixFormat(
          response?.eventData?.posts_interactions?.max_interaction_date,
        );
        let dateMinInteractions = handleUnixFormat(
          response?.eventData?.posts_interactions?.min_interaction_date,
        );

        setInstagramWidgets({
          ...instagramWidgets,
          postsInteractions:
            response.eventData?.posts_interactions
              ?.posts_interactions_over_time,
          maxValuePostsInteractions:
            response.eventData?.posts_interactions?.max_interactions,
          minValuePostsInteractions:
            response.eventData?.posts_interactions?.min_interactions,
          avgValuePostsInteractions:
            response.eventData?.posts_interactions?.average_interactions,
          maxValueInteractionsDate: dateMaxInteractions,
          minValueInteractionsDate: dateMinInteractions,
          postInteractionsLegend:
            response.eventData?.posts_interactions?.posts_interactions_legends,
          postsInteractionsPreLoaderDataSources: [response?.data_source],
        });

        break;

      case "Instagram__AccountPage__average_engagement_per_post":
        let dateMaxEngagement = handleUnixFormat(
          response.eventData?.posts_interactions?.max_engagement_date,
        );
        let dateMinEngagement = handleUnixFormat(
          response.eventData?.posts_interactions?.min_engagement_date,
        );
        setInstagramWidgets({
          ...instagramWidgets,
          averageEngagementPerPost:
            response.eventData?.posts_interactions?.posts_engagements_over_time,
          maxValueAverageEngagementPerPost:
            response.eventData?.posts_interactions?.max_engagements,
          minValueAverageEngagementPerPost:
            response.eventData?.posts_interactions?.min_engagements,
          avgValueAverageEngagementPerPost:
            response.eventData?.posts_interactions?.average_interactions,
          maxValueAverageEngagementPerPostDate: dateMaxEngagement,
          minValueAverageEngagementPerPostDate: dateMinEngagement,
          averageEngagementPerPostPreLoaderDataSources: [response?.data_source],
        });
        break;

      case "Instagram__AccountPage__content_type":
        setInstagramWidgets({
          ...instagramWidgets,
          postsContentType: response.eventData?.posts_content_type,
          postContentPieChart: response.eventData?.posts_content_types_legends,
          postsContentTypePreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Instagram__AccountPage__top_images":
        setInstagramWidgets({
          ...instagramWidgets,
          topImages: response.eventData?.top_images,
          topImagesPreLoaderDataSources: [response?.data_source],
        });
        break;

      case "Instagram__AccountPage__top_videos":
        setInstagramWidgets({
          ...instagramWidgets,
          topVideos: response.eventData?.top_videos,
          topVideosPreLoaderDataSources: [response?.data_source],
        });

        break;
      case "Instagram__EngagementsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_posts_count / noOfDays;
        setInstagramWidgets({
          ...instagramWidgets,
          commentsVolume: response?.eventData?.posts_over_time,
          avgComments: avg % 1 !== 0 ? avg?.toFixed(2) : avg,
          totalComments: response?.eventData?.total_posts_count,
          commentsPreLoaderDataSources: [response?.data_source],
        });
        break;

      case "Instagram__EngagementsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return instagramWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return instagramWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return instagramWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setInstagramWidgets({
          ...instagramWidgets,
          sentimentAnalysis: {
            negative: instagramWidgets?.negativeArray,
            positive: instagramWidgets?.positiveArray,
            neutral: instagramWidgets?.neutralArray,
          },
          sentimentPieChart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoaderDataSources: [response?.data_source],
        });

        break;
      case "Instagram__EngagementsPage__themes_and_sub_themes":
        setInstagramWidgets({
          ...instagramWidgets,
          themeXlxs: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisPreLoaderDataSources: [response?.data_source],
          themeTrendPreLoaderDataSources: [response?.data_source],
        });
        break;

      case "Instagram__EngagementsPage__comments_interactions":
        setInstagramWidgets({
          ...instagramWidgets,
          commentsInteractions: response?.eventData?.comments_interactions,
          commentsInteractionsLegend:
            response?.eventData?.comments_interactions_legends,
          totalValueCommentsInteractions:
            response?.eventData?.total_interactions,
          maxValueCommentsInteractions: response?.eventData?.max_interactions,
          maxValueCommentsInteractionsDate: handleUnixFormat(
            response?.eventData?.max_interaction_date,
          ),
          avgValueCommentsInteractions:
            response?.eventData?.average_interactions,
          commentsInteractionsPreLoader: [response?.data_source],
        });
        break;

      case "Instagram__EngagementsPage__top_keywords":
        setInstagramWidgets({
          ...instagramWidgets,
          topKeywords: response.eventData?.top_keywords,
          topKeywordsPreLoader: [response?.data_source],
        });
        break;
      case "Instagram__EngagementsPage__top_hashtags":
        setInstagramWidgets({
          ...instagramWidgets,
          topHashtags: response.eventData?.top_hashtags,
          topHashtagsPreLoader: [response?.data_source],
        });

        break;
      case "Instagram__EngagementsPage__associated_topics":
        setInstagramWidgets({
          ...instagramWidgets,
          associatedTopics: response.eventData?.associated_topics,
          excelAssociatedTopics: _.cloneDeep([
            ...response.eventData?.associated_topics,
          ]),
          associatedTopicsPreLoader: [response?.data_source],
        });
        break;

      case "Instagram__EngagementsPage__dialects_and_sub_dialects":
        setInstagramWidgets({
          ...instagramWidgets,
          dialects: response.eventData?.dialects,
          excelDialects: _.cloneDeep([...response.eventData?.dialects]),
          subDialects: response.eventData?.sub_dialects,
          excelsubDialects: _.cloneDeep([...response.eventData?.sub_dialects]),
          dialectsPreLoader: [response?.data_source],
        });

        break;

      // case "Instagram__EngagementsPage__gender_distribution":
      //   if (response.eventData.gender_distribution) {
      //     let arrayAgeDistribution = [];
      //     response.eventData.gender_distribution.map((gd) => {
      //       Object.keys(gd)?.map((val, index) => {
      //         arrayAgeDistribution.push({
      //           name: val,
      //           value: Object.values(gd)[index],
      //         });
      //       });
      //     });
      //     setInstagramWidgets({
      //       ...instagramWidgets,
      //       genderAgeDistribution: arrayAgeDistribution,
      //       genderAgeDistributionPreLoader: [response?.data_source],
      //       genderAnalysisPieChart: response.eventData.gender_piechart,
      //     });
      //   }
      //   break;

      // case "Instagram__EngagementsPage__top_cities":
      //   setInstagramWidgets({
      //     ...instagramWidgets,
      //     topCities: response.eventData.top_cities,
      //     topCitiesPreLoader: [response?.data_source],
      //   });
      //   break;
      // ___________________________________________________________

      // case "Instagram__EngagementsPage__top_countries":
      //   setInstagramWidgets({
      //     ...instagramWidgets,
      //     topCountries: response.eventData.top_countries,
      //     topCountriesPreLoader: [response?.data_source],
      //   });
      //   break;

      case "Instagram__EngagementsPage__top_engagers":
        setInstagramWidgets({
          ...instagramWidgets,
          topAuthors: response.eventData?.top_engagers,
          topAuthorsPreLoader: [response?.data_source],
        });
        break;

      case "Instagram__CASingleDataSourceInsightsPage__customer_care_performance":
        let arrCustomerCare = [];
        if (response?.eventData?.response_time_segment) {
          Object.keys(response.eventData?.response_time_segment)?.map(
            (val, index) => {
              arrCustomerCare.push({
                name: val,
                value: Object.values(response.eventData?.response_time_segment)[
                  index
                ],
              });
            },
          );
        }
        setInstagramWidgets({
          ...instagramWidgets,
          customerCarePerformance: response?.eventData,
          totalQuestion: response.eventData?.total_number_of_questions,
          customerCareData: arrCustomerCare,
          respRate:
            response.eventData?.number_of_answered_questions /
            response.eventData?.total_number_of_questions,
          customerCarePreloader: [response?.data_source],
        });
        break;
      case "Instagram__EngagementsPage__top_posts_comments":
        setInstagramWidgets({
          ...instagramWidgets,
          latestInteractions: response?.eventData?.top_posts,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.top_posts_count,
        });

        break;
      default:
        break;
    }
  }, [response]);

  const chartStatsData = [
    {
      name: "total_comments",
      value: instagramWidgets?.totalComments,
    },
    {
      name: "insights_avg_comments_per_day",
      value: instagramWidgets?.avgComments,
    },
  ];
  let commentsInteractionsStats = [
    {
      name: "total_interactions",
      value: instagramWidgets?.totalValueCommentsInteractions,
    },
    {
      name: "max_interaction",
      value: instagramWidgets?.maxValueCommentsInteractions,
      date: instagramWidgets?.maxValueCommentsInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: instagramWidgets?.avgValueCommentsInteractions,
    },
  ];
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setInstagramWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      instagramWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([instagramWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    instagramWidgets?.latestInteractions,
    instagramWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  // check fetching for export excel
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      instagramWidgets?.overViewPreLoaderDataSources,
      instagramWidgets?.authorActivityPreLoaderDataSources,
      instagramWidgets?.postsVolumePreLoaderDataSources,
      instagramWidgets?.postsInteractionsPreLoaderDataSources,
      instagramWidgets?.averageEngagementPerPostPreLoaderDataSources,
      instagramWidgets?.postsContentTypePreLoaderDataSources,
      instagramWidgets?.topImagesPreLoaderDataSources,
      instagramWidgets?.topVideosPreLoaderDataSources,
      instagramWidgets?.commentsPreLoaderDataSources,
      instagramWidgets?.sentimentAnalysisPreLoaderDataSources,
      instagramWidgets?.themeAnalysisPreLoaderDataSources,
      instagramWidgets?.themeTrendPreLoaderDataSources,
      instagramWidgets?.commentsInteractionsPreLoader,
      instagramWidgets?.topKeywordsPreLoader,
      instagramWidgets?.topHashtagsPreLoader,
      instagramWidgets?.associatedTopicsPreLoader,
      instagramWidgets?.dialectsPreLoader,
      instagramWidgets?.topAuthorsPreLoader,
      instagramWidgets?.customerCarePreloader,
      instagramWidgets?.latestInteractionsPreLoaderDataSources,
    ];

    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      props?.preLoaderTrackerDataSources,
    );
    setCheckAllDataFetchingExport(result);
  }, [
    instagramWidgets?.overViewPreLoaderDataSources,
    instagramWidgets?.authorActivityPreLoaderDataSources,
    instagramWidgets?.postsVolumePreLoaderDataSources,
    instagramWidgets?.postsInteractionsPreLoaderDataSources,
    instagramWidgets?.averageEngagementPerPostPreLoaderDataSources,
    instagramWidgets?.postsContentTypePreLoaderDataSources,
    instagramWidgets?.topImagesPreLoaderDataSources,
    instagramWidgets?.topVideosPreLoaderDataSources,
    instagramWidgets?.commentsPreLoaderDataSources,
    instagramWidgets?.sentimentAnalysisPreLoaderDataSources,
    instagramWidgets?.themeAnalysisPreLoaderDataSources,
    instagramWidgets?.themeTrendPreLoaderDataSources,
    instagramWidgets?.commentsInteractionsPreLoader,
    instagramWidgets?.topKeywordsPreLoader,
    instagramWidgets?.topHashtagsPreLoader,
    instagramWidgets?.associatedTopicsPreLoader,
    instagramWidgets?.dialectsPreLoader,
    instagramWidgets?.topAuthorsPreLoader,
    instagramWidgets?.customerCarePreloader,
    instagramWidgets?.latestInteractionsPreLoaderDataSources,
  ]);

  //getting Data to export xls sheet
  useEffect(() => {
    let authorAcc = [
      instagramWidgets?.authorActivity,
      instagramWidgets?.minValueAuthorActivity,
      instagramWidgets?.maxValueAuthorActivity,
    ];

    props.setExcelSheetData([
      { ...instagramWidgets, authorActivity: authorAcc },
    ]);
  }, [instagramWidgets]);

  const instagramIcon = (
    <>
      <img src={publicInstagram} className="icon-after-title instagram" />
    </>
  );
  return (
    <>
      <OverView
        data={instagramWidgets?.overview}
        directMessage={false}
        overViewPreLoaderDataSources={
          instagramWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />
      <InstagramAccountBody
        instagramWidgets={instagramWidgets}
        chartStatsData={chartStatsData}
        commentsInteractionsStats={commentsInteractionsStats}
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        instagramIcon={instagramIcon}
      />
      <InstagramEngagementBody
        instagramWidgets={instagramWidgets}
        chartStatsData={chartStatsData}
        commentsInteractionsStats={commentsInteractionsStats}
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        instagramIcon={instagramIcon}
      />
      <InstagramCustomerCareBody
        unixDate={props.unixDate}
        instagramWidgets={instagramWidgets}
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        instagramIcon={instagramIcon}
        dataSources={props?.dataSources}
        latestInteractionsCount={instagramWidgets?.latestInteractionsCount}
      />
    </>
  );
};

export default InstagramBody;
