import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { BarChart, DeleteOutline } from "@mui/icons-material";
import { CheckValueLocale } from "utils/helpers";
import { handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faArrowToBottom, faClone } from "@fortawesome/pro-solid-svg-icons";

const DropDownMenuOptions = (props) => {
  const {
    stats,
    handleClose,
    open,
    survey,
    surveyLimitReach,
    setOpenPublishSurveyModal,
    setOpenShareSurveyModal,
    openPublishSurveyModal,
    setOpenDeleteSurveyModal,
    setIsHovered,
    isHovered,
    handleOpenDuplicateSurvey,
    handleApplyExport,
  } = props;
  const intl = useIntl();
  let history = useHistory();

  return (
    <Menu
      id="survey-cards-options-dropdown"
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={stats.anchorEl}
      open={open}
      onClose={handleClose}
      className="manage-menu menu-serveys"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: intl?.locale == "en" ? "right" : "left",
      }}
    >
      {survey?.status === "active" ? (
        <>
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenPublishSurveyModal(!openPublishSurveyModal);
              setOpenShareSurveyModal(true);
            }}
            disableRipple
            className="drop-down-btn-servey"
            disabled={false}
            id="survey-cards-dropdown-share"
          >
            <FontAwesomeIcon
              className={"header-icons onhover_class"}
              icon={faShareAlt}
            />
            {CheckValueLocale("share", "", {}, intl)}
          </MenuItem>

          {handlelUserRoles("SURVEY", "CREATE_SURVEY") && (
            <Typography
              component={"span"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Tooltip
                title={CheckValueLocale("max_survey_count", "", {}, intl)}
                placement="bottom"
                arrow
                id="survey-max-btn-tooltip"
                open={surveyLimitReach && isHovered}
              >
                <MenuItem
                  className="drop-down-btn-servey"
                  onClick={() => {
                    handleClose();
                    handleOpenDuplicateSurvey("open");
                  }}
                  id={`surveys-cards-dropdown-edit-${
                    survey?.id ?? "survey-id"
                  }`}
                  disabled={surveyLimitReach}
                >
                  <FontAwesomeIcon
                    className={"header-icons onhover_class"}
                    icon={faClone}
                  />
                  {CheckValueLocale("survey_duplicate", "", {}, intl)}
                </MenuItem>
              </Tooltip>
            </Typography>
          )}
          {handlelUserRoles("SURVEY", "EXPORT_SURVEY") && (
            <MenuItem
              className="drop-down-btn-servey"
              onClick={handleApplyExport}
              id={`survey-cards-dropdown-export-as-excel-${
                survey?.id ?? "survey-id"
              }`}
            >
              <FontAwesomeIcon
                className={"header-icons onhover-class-delete"}
                icon={faArrowToBottom}
              />
              {CheckValueLocale("export_as_excel", "", {}, intl)}
            </MenuItem>
          )}
          {handlelUserRoles("SURVEY", "DESTROY_SURVEY") && (
            <MenuItem
              className="drop-down-btn-servey"
              onClick={() => {
                handleClose();
                setOpenDeleteSurveyModal(true);
              }}
              id={`survey-cards-dropdown-delete-${survey?.id ?? "survey-id"}`}
            >
              <FontAwesomeIcon
                className={"header-icons onhover-class-delete"}
                icon={faArchive}
              />
              {CheckValueLocale("archive", "", {}, intl)}
            </MenuItem>
          )}
        </>
      ) : survey.status === "draft" ? (
        <>
          {handlelUserRoles("SURVEY", "CREATE_SURVEY") && (
            <Typography
              component={"span"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Tooltip
                title={CheckValueLocale("max_survey_count", "", {}, intl)}
                placement="bottom"
                arrow
                id="survey-max-btn-tooltip"
                open={surveyLimitReach && isHovered}
              >
                <MenuItem
                  className="drop-down-btn-servey"
                  onClick={() => {
                    handleOpenDuplicateSurvey("open");
                  }}
                  id={`surveys-cards-dropdown-edit-${
                    survey?.id ?? "survey-id"
                  }`}
                  disabled={surveyLimitReach}
                >
                  <FontAwesomeIcon
                    className={"header-icons onhover_class"}
                    icon={faClone}
                  />
                  {CheckValueLocale("survey_duplicate", "", {}, intl)}
                </MenuItem>
              </Tooltip>
            </Typography>
          )}
        </>
      ) : (
        <>
          {handlelUserRoles("SURVEY", "CREATE_SURVEY") && (
            <Typography
              component={"span"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Tooltip
                title={CheckValueLocale("max_survey_count", "", {}, intl)}
                placement="bottom"
                arrow
                id="survey-max-btn-tooltip"
                open={surveyLimitReach && isHovered}
              >
                <MenuItem
                  className="drop-down-btn-servey"
                  onClick={() => {
                    handleOpenDuplicateSurvey("open");
                  }}
                  id={`surveys-cards-dropdown-edit-${
                    survey?.id ?? "survey-id"
                  }`}
                  disabled={surveyLimitReach}
                >
                  <FontAwesomeIcon
                    className={"header-icons onhover_class"}
                    icon={faClone}
                  />
                  {CheckValueLocale("survey_duplicate", "", {}, intl)}
                </MenuItem>
              </Tooltip>
            </Typography>
          )}
          {handlelUserRoles("SURVEY", "EXPORT_SURVEY") && (
            <MenuItem
              className="drop-down-btn-servey"
              onClick={handleApplyExport}
              id={`survey-cards-dropdown-export-as-excel-${
                survey?.id ?? "survey-id"
              }`}
            >
              <FontAwesomeIcon
                className={"header-icons onhover-class-delete"}
                icon={faArrowToBottom}
              />
              {CheckValueLocale("export_as_excel", "", {}, intl)}
            </MenuItem>
          )}
        </>
      )}
    </Menu>
  );
};

export default DropDownMenuOptions;
