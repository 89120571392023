import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale, classNames } from "utils/helpers";
import CreateAgentCapacityPopup from "../../createAgentCapacityPopup/createAgentCapacityPopup";
import { useState } from "react";

const SelectedAlgorithmCard = ({ title, desc, selected, onSelect }) => {
  const intl = useIntl();
  return (
    <Box
      className={classNames(
        "routing-auto-assign-member-cards-item",
        selected && "selected",
      )}
      onClick={onSelect}
    >
      <Typography className="routing-auto-assign-member-cards-item-title">
        {CheckValueLocale(title, "", {}, intl)}
      </Typography>
      <Typography className="routing-auto-assign-member-cards-item-desc">
        {CheckValueLocale(desc, "", {}, intl)}
      </Typography>
    </Box>
  );
};

export const AutoAssignMember = ({ teamAndSLA, setTeamAndSLA }) => {
  const intl = useIntl();
  const [isAgentCapacityPopupOpen, setIsAgentCapacityPopupOpen] =
    useState(false);

  return (
    <Box className="routing-auto-assign-member">
      <Box className="routing-auto-assign-member-header">
        <Box className="routing-auto-assign-member-group">
          <FormGroup>
            <FormControlLabel
              disabled={!teamAndSLA?.selectedTeam}
              control={
                <Switch
                  size="small"
                  onChange={(e) =>
                    setTeamAndSLA((prev) => ({
                      ...prev,
                      autoAssigned: e?.target?.checked,
                    }))
                  }
                  checked={teamAndSLA?.autoAssigned}
                />
              }
              className={classNames(
                "routing-auto-assign-member-checkbox",
                !teamAndSLA?.selectedTeam && "disabled-label",
              )}
              label={CheckValueLocale(
                "auto_assign_to_team_member",
                "",
                {},
                intl,
              )}
            />
          </FormGroup>
          <Typography className="routing-auto-assign-member-desc">
            {CheckValueLocale("auto_assign_to_team_member_desc", "", {}, intl)}
          </Typography>
        </Box>
        {teamAndSLA?.autoAssigned ? (
          <LucButton
            variant="outlined"
            onChange={() => {}}
            startIcon={<FontAwesomeIcon icon={faUsers} />}
            onClick={() => setIsAgentCapacityPopupOpen(true)}
            id="auto-assign-member-btn"
          >
            {CheckValueLocale("routing_agent_capacity", "", {}, intl)}
          </LucButton>
        ) : null}
      </Box>
      {teamAndSLA?.autoAssigned ? (
        <Box className="routing-auto-assign-member-cards">
          {teamAndSLA?.algorithms?.map((algorithm) => (
            <SelectedAlgorithmCard
              key={algorithm?.id}
              title={algorithm?.name}
              desc={algorithm?.desc}
              selected={teamAndSLA?.selectedAlgorithm === algorithm?.id}
              onSelect={() =>
                setTeamAndSLA((prev) => ({
                  ...prev,
                  selectedAlgorithm: algorithm?.id,
                }))
              }
            />
          ))}
        </Box>
      ) : null}
      <Box className="routing-auto-assign-member-divider" />
      <CreateAgentCapacityPopup
        openCreateAgentCapacityPopup={isAgentCapacityPopupOpen}
        setOpenCreateAgentCapacityPopup={setIsAgentCapacityPopupOpen}
      />
    </Box>
  );
};
