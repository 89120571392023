import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import {
  checkKeyboardLanguage,
  CheckValueLocale,
  validateUrlInjection,
} from "utils/helpers";
import { useIntl } from "react-intl";
import CustomizedTooltip from "components/customizedTooltip";
import UploadLogo from "./uploadLogo";
import "../../surveyBuilder.scss";

const ConfigsTab = (props) => {
  const {
    surveyData,
    setSurveyData,
    setLogoData,
    logoData,
    handleSaveChanges,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
  } = props;

  const intl = useIntl();

  const [redirectionUrl, setRedirectionUrl] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [urlError, setUrlError] = useState("");

  useEffect(() => {
    if (surveyData?.redirection_url?.trim()?.length) {
      const cleanedUrl = surveyData?.redirection_url?.replace(
        /^(https?:\/\/)/,
        "",
      );
      setRedirectionUrl(cleanedUrl);
      surveyData.redirection_url = cleanedUrl;
    } else setRedirectionUrl("");
  }, [surveyData?.redirection_url]);

  useEffect(() => {
    if (redirectionUrl?.trim()?.length > 0) {
      const urlValidation = validateUrlInjection(
        redirectionUrl?.replace(/^(https?:\/\/)/, ""),
        992,
      );

      if (urlValidation?.error) setUrlError(urlValidation?.message);
      else setUrlError("");
    } else setUrlError("");
  }, [redirectionUrl]);

  const handleChangeText = (e, type) => {
    if (type === "title")
      return setSurveyData({ ...surveyData, title: e.target.value });
    if (type === "subtitle")
      return setSurveyData({ ...surveyData, subtitle: e.target.value });
  };

  return (
    <>
      <Box className="sec-title">
        <Typography component="h1">
          {CheckValueLocale("survey_customize", "", {}, intl)}
        </Typography>
      </Box>
      <Box className="info-header">
        <UploadLogo
          setLogoData={setLogoData}
          logoData={logoData}
          handleSaveChanges={handleSaveChanges}
          surveyData={surveyData}
          setSaveChangesFlag={setSaveChangesFlag}
        />
      </Box>
      <Box className="information-box" data-testid="title_field">
        <TextField
          name="title"
          variant="outlined"
          className="info-field"
          value={surveyData?.title}
          onChange={(e) => handleChangeText(e, "title")}
          placeholder={CheckValueLocale("Untitled Survey", "", {}, intl)}
          style={{
            direction: checkKeyboardLanguage(surveyData?.title) ? "rtl" : "ltr",
          }}
        />
        <Box className={"info-text"}>
          {CheckValueLocale("this_title_on_top_of_survey", "", {}, intl)}
        </Box>
      </Box>

      <Box className="information-box" data-testid="subtitle_field">
        <TextField
          name="subtitle"
          variant="outlined"
          className="info-field"
          value={surveyData?.subtitle}
          onChange={(e) => handleChangeText(e, "subtitle")}
          placeholder={CheckValueLocale(
            "Survey Description (Optional)",
            "",
            {},
            intl,
          )}
          style={{
            direction: checkKeyboardLanguage(surveyData?.subtitle)
              ? "rtl"
              : "ltr",
          }}
        />
        <Box className={"info-text"}>
          {CheckValueLocale("this_subtitle_below_title", "", {}, intl)}
        </Box>
      </Box>
      <Box mx={2}>
        <Divider />
      </Box>
      <Box>
        <Box className="sec-title">
          <Typography component="h1">
            {CheckValueLocale("survey_access", "", {}, intl)}
          </Typography>
        </Box>
        <Box className="multiple-responses-container">
          <FormGroup>
            <FormControlLabel
              className="multiple-responses"
              control={<Switch checked={multiRes} onChange={handleMultiRes} />}
              label={CheckValueLocale(
                "multiple_responses_per_device",
                "",
                {},
                intl,
              )}
            />
          </FormGroup>
        </Box>
      </Box>

      {/* This field will be shown only if the survey has a redirection url. from System Admin */}
      {surveyData?.is_redirection_url_enabled ? (
        <Box className="redirection-url-wrapper">
          <Divider />
          <Typography component="h1">
            {CheckValueLocale("redirect_on_completion", "", {}, intl)}
            <CustomizedTooltip
              title={CheckValueLocale(
                "redirect_on_completion_info_tooltip",
                "",
                {},
                intl,
              )}
              placement="top"
              arrow
              styles={{ width: "200px", padding: "10px" }}
            >
              <FontAwesomeIcon className="info-icon" icon={faCircleInfo} />
            </CustomizedTooltip>
          </Typography>
          <TextField
            id="redirection-on-completion-input"
            className={`redirect-url-input ${!isFocused && !redirectionUrl?.length && intl?.locale === "ar" ? "rtl" : ""}`}
            placeholder={
              !isFocused ? CheckValueLocale("redirect_url", "", {}, intl) : ""
            }
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment:
                isFocused || redirectionUrl?.length ? (
                  <InputAdornment position="start" dir="ltr">
                    https://
                  </InputAdornment>
                ) : null,
            }}
            value={redirectionUrl?.replace(/^(https?:\/\/)/, "")}
            onChange={(e) => {
              setRedirectionUrl(e?.target?.value);
              setSaveChangesFlag(true);
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => {
              setIsFocused(false);
              if (e?.target?.value !== surveyData?.redirection_url)
                setSurveyData({
                  ...surveyData,
                  redirection_url: redirectionUrl,
                });
            }}
            error={urlError?.length}
            helperText={CheckValueLocale(
              urlError?.length ? urlError : "redirection_url_helper_text",
              "",
              {},
              intl,
            )}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ConfigsTab;
