import { useIntl } from "react-intl";
import { useState } from "react";
import { TextField, Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers/index";
import ExploreController from "services/controllers/exploreController";
import PopupModal from "components/popupModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import "./saveResultsPopup.scss";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";

const SaveResultsPopup = (props) => {
  const intl = useIntl();
  const { create, open, close, exploreId } = props;
  const [disable, setDisable] = useState(true);
  const [saveResultName, setSaveResultName] = useState("");
  const [savedLoading, setSavedLoading] = useState(false);
  const [err, setErr] = useState("");

  const updateSaveResultsName = (id, saveResultName) => {
    ExploreController.saveExploreResults(id, saveResultName).then((result) => {
      if (create) {
        if (result?.status == 200) {
          create("success", "save_result_success_alert");
          setSavedLoading(true);
        } else {
          create("error", "try_again_error_message", "failed_error_message");
        }
      }
    });
  };

  //// Save Result Validation
  const handleValditon = (e) => {
    setErr("");
    setDisable(true);

    const txt = e.target.value;
    setSaveResultName(txt);

    if (
      isPublicSpecialCharactersOrEmojis(txt, undefined, true) ||
      removeSpecialCharacters(txt)?.length === 0
    ) {
      setErr("save_results_validation");
    } else if (txt?.length > 0) {
      setDisable(false);
    }
  };

  const handleCreate = () => {
    updateSaveResultsName(exploreId, saveResultName);
    close();
  };

  let body = (
    <Box className="save-results">
      <Box className="save-results-title">
        <Typography className="title">
          {CheckValueLocale("save_results_body", "", {}, intl)}
        </Typography>
      </Box>
      <Box>
        <TextField
          className="save-results-input"
          onChange={handleValditon}
          fullWidth
          error={err?.length > 0}
          id="outlined-error-helper-text"
          label={CheckValueLocale("save_results_label", "", {}, intl)}
          defaultValue={saveResultName}
          helperText={CheckValueLocale(err, "", {}, intl)}
        />
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("save_results", "", {}, intl)}
      body={body}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={
        savedLoading ? (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            className="spinner-icon-explore"
          />
        ) : (
          CheckValueLocale("save_results_save_btn", "", {}, intl)
        )
      }
      disabled={disable}
      open={open}
      close={close}
      monitor_id={saveResultName}
      accept={handleCreate}
      addClasses={"save-results-btns"}
      classeName={"save-result-popup-wrapper"}
      minWidth="600px"
    />
  );
};

export default SaveResultsPopup;
