import { useIntl } from "react-intl";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import InteractionListMessageWhatsapp from "./InteractionListMessageWhatsapp";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { Box } from "@mui/material";
import { CheckValueLocale, checkIfWeNeedShowMoreTweet } from "utils/helpers";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";
import { useState } from "react";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#faaf00",
  },
});

const InteractionsListMessage = (props) => {
  const intl = useIntl();
  const [showFullTextTweet, setShowFullTextTweet] = useState(false);

  return (
    <div
      className={`
        ${
          props?.attachementType === "text" ||
          props?.attachementType === "audio/ogg"
            ? "message-container message-container-text"
            : "message-container"
        }
          ${
            props.component === "google_my_business"
              ? "display-start-between"
              : ""
          }`}
    >
      <div
        className={
          props.component === "google_my_business" ? "width-style" : ""
        }
      >
        {/* Handle Whatsapp Text to be appeared only in case of Any data source rather than whatsapp */}
        {props?.channelName !== "whatsapp" && props?.text?.length > 235 ? (
          `${props?.text.slice(0, 500)} .... `
        ) : // `${props?.text} .... `
        props?.channelName !== "whatsapp" &&
          props.component === "google_my_business" &&
          props.text === "" ? (
          <StyledRating
            name="customized-color"
            value={parseInt(props.rating)}
            precision={0.5}
            readOnly
            icon={<StarRoundedIcon fontSize="large" />}
            emptyIcon={<StarOutlineRoundedIcon fontSize="large" />}
          />
        ) : props?.channelName !== "whatsapp" ? (
          `${props?.text}`
        ) : null}
        {props?.channelName === "twitter" &&
        checkIfWeNeedShowMoreTweet(props?.text, props?.obj?.tweet_full_text) ? (
          <Box
            component="span"
            className="tweet-full-text-read-all-btn"
            onClick={() => setShowFullTextTweet(true)}
          >
            {CheckValueLocale("read_all", "", {}, intl)}
          </Box>
        ) : null}
      </div>

      {/* Handle Whatsapp Text to be appeared only in case of whatsapp */}
      {props?.channelName === "whatsapp" ? (
        <InteractionListMessageWhatsapp
          id={props.obj.id}
          tracker_id={props.obj.tracker_id}
          channel_id={props.obj.channel_id}
          channelName={props.channelName}
          link={props.link}
          text={props.text}
          attachementType={props.attachementType}
          attachementLink={props.attachementLink}
          isInteractionPage={props.isInteractionPage}
          obj={props.obj}
          forwardedMessageFlag={props.forwardedMessageFlag}
        />
      ) : null}

      {showFullTextTweet ? (
        <FullTextTweetPopUp
          closeFilter={() => setShowFullTextTweet(false)}
          post={props?.obj}
        />
      ) : null}
    </div>
  );
};

export default InteractionsListMessage;
