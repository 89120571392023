import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as TeamGroupIcon } from "images/shared-images/team-group.svg";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";

export const TeamMembersEmptyCase = ({ id }) => {
  const intl = useIntl();
  return (
    <Box className="view-team-details-content-empty">
      <TeamGroupIcon className="team-group-icon" />
      <Typography className="view-team-details-content-empty-text">
        {CheckValueLocale("no_teams_member_to_show_1", "", {}, intl)}
        &nbsp;
        <Link
          to={{
            pathname: `/settings/teams/edit/${id}`,
            state: { id: id, from: "teamDetails" },
          }}
          className="view-team-details-content-empty-link"
        >
          {CheckValueLocale("edit", "", {}, intl)}
        </Link>
        &nbsp;
        {CheckValueLocale("no_teams_member_to_show_2", "", {}, intl)}
      </Typography>
    </Box>
  );
};
