import React, { useEffect, useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { CheckValueLocale, getTimeZone } from "utils/helpers";
import { Box, styled, Divider, Typography } from "@mui/material";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import InteractionsController from "services/controllers/interactionsController";

const InteractionsListFooter = (props) => {
  let {
    obj,
    hideEditSentimentThemes,
    categoriesOptions,
    themesOptions,
    sentimentsOptions,
  } = props;
  const intl = useIntl();
  let date = obj?.CA_date
    ? obj?.CA_date
    : obj.channel_id === 2
      ? obj.created_at
      : obj.channel_id === 3
        ? obj.created_at
        : obj.messages === undefined
          ? obj.created_at
          : obj.messages[0].message_created_at;

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedSentiment, setSelectedSentiment] = useState([]);

  const [themesLoading, setThemesLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [sentimentLoading, setSentimentLoading] = useState(false);

  useEffect(() => {
    if (typeof obj?.sub_themes === "string")
      setSelectedThemes(obj?.sub_themes?.split(","));
    else setSelectedThemes(obj?.sub_themes);

    if (typeof obj?.themes === "string")
      setSelectedCategories(obj?.themes?.split(","));
    else setSelectedCategories(obj?.themes);

    if (obj?.isPublicChannel) {
      if (sentimentsOptions?.[obj?.CA_sentiment] != undefined)
        setSelectedSentiment(sentimentsOptions?.[obj?.CA_sentiment]);
      else setSelectedSentiment(obj?.CA_sentiment);
    } else {
      if (sentimentsOptions?.[obj?.sentiment] != undefined)
        setSelectedSentiment(sentimentsOptions?.[obj?.sentiment]);
      else setSelectedSentiment(obj?.sentiment);
    }
  }, [obj, sentimentsOptions]);

  const handleSelectTheme = (updatedThemes) => {
    setThemesLoading(true);

    InteractionsController.updateSingleInteractionSubThemes(
      props?.channelName == "gmail"
        ? obj?.email_id
        : obj?.isPublicChannel
          ? obj?.tweet_id
          : obj?.id,
      obj?.tracker_id,
      typeof obj?.sub_themes === "string"
        ? selectedThemes?.split(",")
        : selectedThemes,
      typeof obj?.sub_themes === "string"
        ? updatedThemes?.split(",")
        : updatedThemes,
      window.localStorage.cxm_id,
      obj?.isPublicChannel ? obj?.CA_channel_id : obj?.channel_id,
      date,
      obj?.isPublicChannel ? obj?.tweet_id : obj?.id,
      obj?.parent?.media_id,
      obj?.isPublicChannel ? obj?.tracker_id : obj?.account_id,
      obj?.location_id,
      obj?.audio_id,
      obj?.lucidya_audio_id,
      props?.apiVars,
    ).then((result) => {
      setThemesLoading(false);
      if (result !== undefined && result.data.status === 200)
        setSelectedThemes(updatedThemes);
    });
  };

  const handleSelectCategory = (updatedCategories) => {
    setCategoriesLoading(true);
    InteractionsController.updateSingleInteractionThemes(
      props?.channelName == "gmail"
        ? obj?.email_id
        : obj?.isPublicChannel
          ? obj?.tweet_id
          : obj?.id,
      obj?.tracker_id,
      typeof obj?.themes === "string"
        ? selectedCategories?.split(",")
        : selectedCategories,
      typeof obj?.themes === "string"
        ? updatedCategories?.split(",")
        : updatedCategories,
      window.localStorage.cxm_id,
      obj?.isPublicChannel ? obj?.CA_channel_id : obj?.channel_id,
      date,
      obj?.isPublicChannel ? obj?.tweet_id : obj?.id,
      obj?.parent?.media_id,
      obj?.isPublicChannel ? obj?.tracker_id : obj?.account_id,
      obj?.location_id,
      obj?.audio_id,
      obj?.lucidya_audio_id,
      props?.apiVars,
    ).then((result) => {
      setCategoriesLoading(false);
      if (result !== undefined && result.data.status === 200)
        setSelectedCategories(updatedCategories);
    });
  };

  const handleEditSentiment = (updatedSentiment) => {
    setSentimentLoading(true);
    InteractionsController.updateSingleInteractionSentiment(
      props?.channelName == "gmail"
        ? obj?.email_id
        : obj?.isPublicChannel
          ? obj?.tweet_id
          : obj?.id,
      obj?.tracker_id,
      selectedSentiment,
      updatedSentiment,
      window.localStorage.cxm_id,
      obj?.isPublicChannel ? obj?.CA_channel_id : obj?.channel_id,
      date,
      obj?.isPublicChannel ? obj?.tweet_id : obj?.id,
      obj?.parent?.media_id,
      obj?.isPublicChannel ? obj?.tracker_id : obj?.account_id,
      obj?.location_id,
      obj?.audio_id,
      obj?.lucidya_audio_id,
      obj?.previousVarApis,
    ).then((result) => {
      setSentimentLoading(false);
      if (result !== undefined && result?.data?.status === 200)
        setSelectedSentiment(updatedSentiment);
    });
  };

  return (
    <Box className={`interactions-list-card-footer`}>
      <Box className="date-time-wrapper">
        <Box className="date-format">
          {moment.unix(date)?.format("YYYY/MM/DD ")}
          <Typography component="span">at</Typography>
          {moment.unix(date)?.utc().format(" LT")}
          &nbsp;
        </Box>
        <Box className="time-zone-format">
          {getTimeZone() > 0
            ? `(${CheckValueLocale("gmt", "", {}, intl)} +${getTimeZone()})`
            : `(${CheckValueLocale("gmt", "", {}, intl)} -${getTimeZone()})`}
        </Box>
      </Box>
      {props?.component === "google_my_business" &&
      props?.text === "" ? null : (
        <SentimentCategoriesThemes
          themesData={{
            btnId: "-interactions-list-item-themes-btn",
            options: themesOptions,
            selected: selectedThemes,
            handleChange: handleSelectTheme,
            loading: themesLoading,
          }}
          categoriesData={{
            btnId: "-interactions-list-item-categories-btn",
            options: categoriesOptions,
            selected: selectedCategories,
            handleChange: handleSelectCategory,
            loading: categoriesLoading,
          }}
          sentimentData={{
            btnId: "-interactions-list-item-sentiment-btn",
            options: sentimentsOptions,
            selected: selectedSentiment,
            handleChange: handleEditSentiment,
            loading: sentimentLoading,
            disabled: hideEditSentimentThemes,
          }}
          dividers={[0, 1, 2]}
        />
      )}
    </Box>
  );
};

export default InteractionsListFooter;
const BoxWrapper = styled(Box)`
  background-color: #eef2f6;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 10px;
`;
const TypoText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
`;
const TypoTextHead = styled(Typography)`
  color: #333b48;
  font-weight: 500;
  font-size: 12px;
  padding: 0;
  margin: 0;
`;
const DividerVer = styled(Divider)`
  color: #cbd5e1;
  width: 1px;
  height: 20px;
  margin: 0px 10px;
`;
