import { Box } from "@mui/material";
import Turnstile from "react-turnstile";

const FormCloudFlareLiveDashboard = ({
  handleVerifySuccess,
  handleVerifyExpire,
}) => {
  return (
    <Box className="cloudflare-wrapper">
      <Turnstile
        className="cloudflare-box"
        theme="light"
        sitekey="0x4AAAAAAAPYnlA5vbwV-WV2"
        onVerify={handleVerifySuccess}
        onExpire={handleVerifyExpire}
        onTimeout={handleVerifyExpire}
      />
    </Box>
  );
};
export default FormCloudFlareLiveDashboard;
