import { useState } from "react";
import { useIntl } from "react-intl";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./alert-filter.scss";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 304,
      width: 300,
    },
  },
};

const AlertFilter = ({
  alertTypeNames,
  selectedTypes,
  handleTypeSelection,
  alertTypeLocale,
  setFillterSearch,
}) => {
  const intl = useIntl();

  const handleValues = (values) => {
    const selected = alertTypeNames?.filter((item) => {
      if (values?.includes(item?.id)) {
        return item;
      }
    });
    const typesLocales = selected?.map((item) => alertTypeLocale(item?.value));
    return typesLocales?.join(", ");
  };

  return (
    <FormControl className="alert-filter-formControl">
      <InputLabel
        id="demo-multiple-checkbox-label"
        className={selectedTypes?.length > 0 ? "filter-selected-label" : ""}
      >
        {CheckValueLocale("alert_select_type", "", {}, intl)}
      </InputLabel>
      <Select
        labelId="alert-type-multiple-checkbox-label"
        id="alert-type-multiple-checkbox"
        multiple
        value={selectedTypes}
        onChange={(e) => {
          handleTypeSelection(e);
          setFillterSearch(selectedTypes);
        }}
        input={
          <OutlinedInput
            label={CheckValueLocale("alert_select_type", "", {}, intl)}
          />
        }
        renderValue={(selected) => handleValues(selected)}
        MenuProps={MenuProps}
      >
        {alertTypeNames?.map((val) => {
          return (
            <MenuItem
              className="alert-filter-menuItem"
              key={val?.id}
              value={val?.id}
              id={`alert-header-filter-menu-item-${
                val?.value ?? "alert-type-name"
              }`}
            >
              <Checkbox checked={selectedTypes?.includes(val?.id)} />
              <ListItemText primary={alertTypeLocale(val?.value)} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default AlertFilter;
