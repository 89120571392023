import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./monitorDropdowns.scss";
import InfoIcon from "@mui/icons-material/Info";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const getDefaultOptions = (options, selectedOptions) => {
  return options?.filter((option) => selectedOptions?.includes(option?.id));
};
const AutocompleteDropdown = ({
  options,
  defaultValue = [],
  placeholder,
  selectAll,
  selectWrapperClassName,
  applySelectionConditionFn,
  errorText,
  ...props
}) => {
  const [showError, setShowError] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    defaultValue?.length ? getDefaultOptions(options, defaultValue) : [],
  );
  const intl = useIntl();
  const nameField =
    intl?.locale === "ar"
      ? options?.[0]?.ar_name?.length
        ? "ar_name"
        : options?.[0]?.arabic_name?.length
          ? "arabic_name"
          : "name"
      : "name";
  const [open, setOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, newSelectedOptions) => {
    if (applySelectionConditionFn) {
      const moveOn = applySelectionConditionFn(
        newSelectedOptions?.length - selectedOptions?.length,
      );
      if (!moveOn) {
        setShowError(true);
        return;
      }
    }
    setSelectedOptions(newSelectedOptions);
    props?.onChange &&
      props?.onChange?.(newSelectedOptions?.map((option) => option?.id));
  };
  const optionItemRender = (props, option, { selected }) => {
    return (
      <li
        {...props}
        className={`${props.className} monitor-select-option-item`}
        key={option?.id}
      >
        <Checkbox checked={selected} />
        <Box className="monitor-select-option-title">
          <Typography>{option?.[nameField]}</Typography>
        </Box>
      </li>
    );
  };

  const inputRender = (params) => (
    <TextField
      {...params}
      onChange={(e) => setInputValue(e.target.value)}
      label={
        open || isInputFocused
          ? null
          : selectedOptions?.length === 0
            ? placeholder
            : `${selectedOptions?.join(",").split(",")?.length} ${CheckValueLocale(
                "selected_num",
                "",
                {},
                intl,
              )}`
      }
      className={`monitor-autocomplete-input ${
        open || selectedOptions?.length === 0 ? "" : "selected"
      }`}
      // disable label animation when focused
      InputLabelProps={{
        shrink: false,
      }}
      onFocus={() => setIsInputFocused(true)}
      onBlur={() => {
        setIsInputFocused(false);
        setShowError(false);
      }}
      error={showError && errorText}
      helperText={
        showError &&
        CheckValueLocale(errorText, "", { limit: props?.limit }, intl)
      }
    />
  );

  const getOptionSelected = (option, anotherOption) => {
    return option?.id === anotherOption?.id;
  };

  useEffect(() => {
    // options may came after the component is mounted, so we need to update the selected options
    if (!selectedOptions?.length) {
      setSelectedOptions(
        defaultValue?.length ? getDefaultOptions(options, defaultValue) : [],
      );
    }
  }, [options]);
  return (
    <FormControl
      className={`monitor-autocomplete-wrapper ${
        selectWrapperClassName ? selectWrapperClassName : "default-wrapper"
      }`}
    >
      <Autocomplete
        disableClearable
        disableCloseOnSelect={true}
        autoComplete
        autoHighlight
        className="monitor-autocomplete-select"
        options={options}
        inputValue={inputValue}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedOptions}
        getOptionLabel={(option) => option?.[nameField]}
        onChange={handleChange}
        renderOption={optionItemRender}
        isOptionEqualToValue={getOptionSelected}
        multiple
        renderInput={inputRender}
        noOptionsText={CheckValueLocale("empty_options_list", "", {}, intl)}
        renderTags={() => null} // Disable rendering of tags
        classes={{
          paper: "monitor-autocomplete-paper-menu",
        }}
        size="small"
      />
    </FormControl>
  );
};

export const DropdownField = ({ tooltip, label, tooltipPosition }) => {
  return (
    <>
      <Typography variant="caption" className="dropdown-label">
        <CustomizedTooltip
          title={tooltip || ""}
          arrow
          placement={tooltipPosition}
          styles={{ ...customizedStyledTooltip, maxWidth: "200px" }}
        >
          <Box className="media-input-label">{label}</Box>
        </CustomizedTooltip>
      </Typography>
    </>
  );
};

export const DropdownSection = ({
  tooltip,
  label,
  containerClassName = "",
  tooltipPosition = "top",
  ...props
}) => {
  return (
    <Box className={`${containerClassName} dropdown-section`}>
      <DropdownField
        tooltip={tooltip}
        label={label}
        tooltipPosition={tooltipPosition}
      />
      <AutocompleteDropdown {...props} />
    </Box>
  );
};
