import {
  faArrowLeft,
  faArrowRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

const LiveDashboardDeleted = () => {
  const intl = useIntl();
  const history = useHistory();
  return (
    <Box className="live-dashboard-deleted-page">
      <FontAwesomeIcon icon={faTrash} />
      <Box className="live-dashboard-deleted-title">
        {CheckValueLocale("dashboard_is_deleted", "", {}, intl)}
      </Box>
      <LucButton
        type="secondary"
        variant="outline"
        className="btn-back-to-monitors-list"
        startIcon={
          <FontAwesomeIcon
            icon={intl?.locale == "ar" ? faArrowRight : faArrowLeft}
          />
        }
        onClick={() => {
          history.push({
            pathname: `/social/monitor_list`,
          });
        }}
      >
        {CheckValueLocale("back_to_monitors_list", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default LiveDashboardDeleted;
