import { useContext } from "react";
import { useIntl } from "react-intl";
import { Box, Button, Tooltip, CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";
import "./header.scss";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { LucidyaApisContext } from "../lucidyaApisContext";
import LucButton from "shared/lucButton/lucButton";
import { Done } from "@mui/icons-material";
import Services from "services/api/Services.js";

const Header = ({
  requestHeader = false,
  tokenLimitReached = false,
  request,
  requestAccessLucidyaApis,
  isLoading,
  lucidyaApisActiveCheck,
}) => {
  const intl = useIntl();
  const { handleCreateApiDialog, setApiDialogType } =
    useContext(LucidyaApisContext);

  const handleGenerateApiKey = () => {
    setApiDialogType("createApiKey");
    handleCreateApiDialog();
  };

  const tooltipTitle = tokenLimitReached ? (
    <p className="title-limit-reached">
      {CheckValueLocale("token_limit_reached", "", {}, intl)}{" "}
    </p>
  ) : null;

  return (
    <Box className="header-wrapper actions-buttons-shared">
      <Box className="header-details">
        <h2 className="request-heading">
          {CheckValueLocale("lucidya_api", "", {}, intl)}
        </h2>
        {!requestHeader ? (
          <p className="request-description">
            {CheckValueLocale(
              Services.showThirdCompany
                ? "lucidya_api_description"
                : "lucidya_api_description_no_read",
              "",
              {},
              intl,
            )}
            {Services.showThirdCompany ? (
              <a
                href="https://docs.lucidya.com/"
                target="_blank"
                rel="noreferrer"
                className="api-documentation-link"
              >
                {CheckValueLocale("api_documentation", "", {}, intl)}
              </a>
            ) : null}
          </p>
        ) : null}
      </Box>
      {!requestHeader && handlelUserRoles("PUBLIC_API", "GENERATE_TOKEN") ? (
        <Tooltip title={tooltipTitle} placement="left-start" arrow>
          <Box>
            <LucButton
              onClick={handleGenerateApiKey}
              id="lucidya-apis-generate-key"
              startIcon={<Add />}
              disabled={tokenLimitReached}
            >
              {CheckValueLocale("generate_api_key", "", {}, intl)}
            </LucButton>
          </Box>
        </Tooltip>
      ) : null}
      {request ? (
        <Button
          variant="outlined"
          disabled
          startIcon={<Done className="add-icon" />}
          className="lucidya-apis-button"
          id="lucidya-apis-request-sent-btn"
        >
          {CheckValueLocale("request_sent", "", {}, intl)}
        </Button>
      ) : lucidyaApisActiveCheck ? (
        <LucButton
          onClick={() => {
            requestAccessLucidyaApis();
          }}
          variant="filled"
          size="small"
          className="luc-Button"
          id="lucidya-apis-request-access-btn"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="sending-request-btn-text">
              <CircularProgress className="circular-progress-subscription circular-progress" />
              {CheckValueLocale("sending_request", "", {}, intl)}
            </span>
          ) : (
            CheckValueLocale("request_access", "", {}, intl)
          )}
        </LucButton>
      ) : null}
    </Box>
  );
};
export default Header;
