import { useState } from "react";
import "./main.scss";
import PublicLiveDashboardAuth from "./components/publicLiveDashboardAuth";
import { useParams } from "react-router-dom";
import LiveDashboard from "./../index";

const LiveDashboardPublic = () => {
  const [challengeKeyStorge, setChallengeKeyStorge] = useState(
    localStorage.getItem("challengeKey"),
  );

  const urlParams = useParams();

  return (
    <>
      {!challengeKeyStorge ? (
        <PublicLiveDashboardAuth
          urlParams={urlParams}
          setChallengeKeyStorge={setChallengeKeyStorge}
        />
      ) : (
        <LiveDashboard />
      )}
    </>
  );
};

export default LiveDashboardPublic;
