import { Card, CardContent, FormControl, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import IntlTelInput from "react-intl-tel-input";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";

const PhoneNoQuestion = (props) => {
  const intl = useIntl();
  const { question, questionNo, questionAnswer } = props;
  return (
    <Card className="cardStyle noError audience">
      <CardContent>
        <Typography className="questionStyle audience">
          <span>{`${questionNo}.`}</span>
          {question?.question}
        </Typography>
        <Box className="phone-number-question phone-preview">
          <FormControl className="textFieldStyle audience">
            {!isEmptyArray(questionAnswer) &&
            typeof questionAnswer == "object" ? (
              questionAnswer?.map((item) => {
                return (
                  <IntlTelInput
                    disabled
                    containerClassName="intl-tel-input"
                    inputClassName="form-control react-tel-input phoneInput audience"
                    separateDialCode
                    defaultCountry={
                      item.country_code
                        ? item?.country_code?.toLowerCase()
                        : "sa"
                    }
                    excludeCountries={["il"]}
                    fieldName="phone_number"
                    placeholder={CheckValueLocale(
                      "you_didnt_answer",
                      "",
                      {},
                      intl,
                    )}
                    allowDropdown={false}
                    value={item?.phone_number}
                  />
                );
              })
            ) : (
              <IntlTelInput
                disabled
                containerClassName="intl-tel-input"
                inputClassName="form-control react-tel-input phoneInput audience"
                separateDialCode
                defaultCountry={"sa"}
                fieldName="phone_number"
                placeholder={CheckValueLocale("you_didnt_answer", "", {}, intl)}
                allowDropdown={false}
              />
            )}
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PhoneNoQuestion;
