//check [keyword - spam keywords] length of twitter and split any special char if included
import { checkKeywordLength } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordsLength";

export const removeCrossedKeywords = (data) => {
  let keywordData = data?.monitor_options?.KEYWORD;
  let newKeywordsTw = [],
    newSpamKwordsTw = [],
    newKeywordsFb = [],
    newSpamKwordsFb = [],
    newKeywordsIg = [],
    newSpamKwordsIg = [],
    newKeywordsNb = [],
    newSpamKwordsNb = [];

  if (keywordData) {
    //check twitter keywords
    if (keywordData?.TWITTER?.keywords) {
      keywordData?.TWITTER?.keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newKeywordsTw.push(kw);
        }
      });
      keywordData["TWITTER"].keywords = newKeywordsTw.toString();
    }
    //check twitter spam keywords
    if (keywordData?.TWITTER?.spam_keywords) {
      keywordData?.TWITTER?.spam_keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newSpamKwordsTw.push(kw);
        }
      });
      keywordData["TWITTER"].spam_keywords = newSpamKwordsTw.toString();
    }

    //________________________________________________
    //check facebook keywords
    if (keywordData?.FACEBOOK?.keywords) {
      keywordData?.FACEBOOK?.keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newKeywordsFb.push(kw);
        }
      });
      keywordData["FACEBOOK"].keywords = newKeywordsFb.toString();
    }
    //check facebook spam keywords
    if (keywordData?.FACEBOOK?.spam_keywords) {
      keywordData?.FACEBOOK?.spam_keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newSpamKwordsFb.push(kw);
        }
      });
      keywordData["FACEBOOK"].spam_keywords = newSpamKwordsFb.toString();
    }
    //____________________________________________________

    //check INSTAGRAM keywords
    if (keywordData?.INSTAGRAM?.keywords) {
      keywordData?.INSTAGRAM?.keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newKeywordsIg.push(kw);
        }
      });
      keywordData["INSTAGRAM"].keywords = newKeywordsIg.toString();
    }
    //check facebook spam keywords
    if (keywordData?.INSTAGRAM?.spam_keywords) {
      keywordData?.INSTAGRAM?.spam_keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newSpamKwordsIg.push(kw);
        }
      });
      keywordData["INSTAGRAM"].spam_keywords = newSpamKwordsIg.toString();
    }
    //________________________________________________________

    //check TALKWALKER keywords
    if (keywordData?.TALKWALKER?.keywords) {
      keywordData?.TALKWALKER?.keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newKeywordsNb.push(kw);
        }
      });
      keywordData["TALKWALKER"].keywords = newKeywordsNb.toString();
    }
    //check TALKWALKER spam keywords
    if (keywordData?.TALKWALKER?.spam_keywords) {
      keywordData?.TALKWALKER?.spam_keywords.split(",").map((kw) => {
        if (!checkKeywordLength(kw)) {
          newSpamKwordsNb.push(kw);
        }
      });
      keywordData["TALKWALKER"].spam_keywords = newSpamKwordsNb.toString();
    }
  }

  return data;
};

//check spam crossed or not.
export const checkKewordsCrossed = (spamKeywords) => {
  let newSpamKeyword = [];
  if (spamKeywords) {
    spamKeywords
      ?.toString()
      .split(",")
      .map((spam) => {
        if (!checkKeywordLength(spam)) {
          newSpamKeyword.push(spam);
        }
      });
    newSpamKeyword = newSpamKeyword.toString().split(",")?.join(", ");
  }
  return newSpamKeyword;
};

//to check keyword crossed or not.
export const checkCrossedValid = (data) => {
  return data.some((el) => checkKeywordLength(el) === true);
};
