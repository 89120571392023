import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import "../../../monitors.scss";
import ThemeSection from "./../../../Components/themeSection";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";

const OverViewSection = (props) => {
  const intl = useIntl();
  let {
    response,
    commentsVolumePreLoader,
    setCommentsVolumePreLoader,
    commentsVolume,
    setCommentsVolume,
    totalCommentsVolumeCount,
    setTotalCommentsVolumeCount,
    reactions,
    setReactions,
    reactionsPreLoader,
    setReactionsPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    negativeArray,
    setNegativeArray,
    positiveArray,
    setPositiveArray,
    neutralArray,
    setNeutralArray,
    commentsInteractions,
    setCommentsInteractions,
    commentsInteractionsPreLoader,
    setCommentsInteractionsPreLoader,
    commentsInteractionsLegend,
    setCommentsInteractionsLegend,
    topKeywords,
    setTopKeywords,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topHashtags,
    setTopHashtags,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    associatedTopics,
    setAssociatedTopics,
    associatedTopicsPreLoader,
    setAssociatedTopicsPreLoader,
  } = props;
  //handle response
  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);
  const [pageReactionPieChartValue, setPageReactionPieChartValue] = useState(
    [],
  );

  // ___________________________________________________________
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];
  // ___________________________________________________________

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__EngagementsPage__volume_overtime":
        setCommentsVolume(response.eventData.posts_over_time);
        setTotalCommentsVolumeCount(response.eventData.total_posts_count);
        setCommentsVolumePreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__reactions":
        setReactions(
          response.eventData.posts_reactions.posts_reactions_over_time,
        );
        setReactionsPreLoader((oldArray) => [...oldArray, response.monitor_id]);

        setPageReactionPieChartValue(
          response.eventData.posts_reactions.posts_reactions_piechart,
        );
        break;
      case "Facebook__EngagementsPage__sentiment_analysis":
        Object.entries(response.eventData.sentiment_overtime).forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );
        setSentimentAnalysisPieChart(response.eventData.sentiment_piechart);
        setSentimentAnalysis({
          negative: negativeArray,
          positive: positiveArray,
          neutral: neutralArray,
        });
        setSentimentAnalysisPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__posts_interactions":
        setCommentsInteractions(
          response?.eventData?.posts_interactions?.posts_interactions_over_time,
        );
        setCommentsInteractionsLegend(
          response?.eventData?.posts_interactions?.posts_interactions_legends,
        );

        setTotalValuePostsInteractions(
          response?.eventData?.posts_interactions?.total_interactions,
        );
        setMaxValuePostsInteractions(
          response?.eventData?.posts_interactions?.max_interactions,
        );
        let date = handleUnixFormat(
          response?.eventData?.posts_interactions?.max_interaction_date,
        );
        setMaxValueInteractionsDate(date);
        setAvgValuePostsInteractions(
          response?.eventData?.posts_interactions?.average_interactions,
        );

        setCommentsInteractionsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);

        break;
      case "Facebook__EngagementsPage__top_keywords":
        setTopKeywords(response.eventData.top_keywords);
        setTopKeywordsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__top_hashtags":
        setTopHashtags(response.eventData.top_hashtags);
        setTopHashtagsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__associated_topics":
        setAssociatedTopics(response.eventData.associated_topics);
        setAssociatedTopicsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  useEffect(() => {
    props.setOverViewData([
      { commentsVolume: commentsVolume },
      { reactions: reactions },
      { sentimentAnalysis: sentimentAnalysis },
      { commentsInteractions: commentsInteractions },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      { associatedTopics: associatedTopics },
    ]);
  }, [
    commentsVolume,
    reactions,
    sentimentAnalysis,
    commentsInteractions,
    topKeywords,
    topHashtags,
    associatedTopics,
  ]);
  // ________________________________________________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setCommentsVolume([]);
      setTotalCommentsVolumeCount([]);
      setCommentsVolumePreLoader([]);

      setReactions([]);
      setReactionsPreLoader([]);
      setPageReactionPieChartValue([]);

      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setSentimentAnalysisPieChart([]);

      setCommentsInteractions([]);
      setCommentsInteractionsLegend([]);
      setCommentsInteractionsPreLoader([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);

      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setAvgValuePostsInteractions(0);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"comments_volume"}
          totalLabel={"comments"}
          totalValue={totalCommentsVolumeCount}
          data={commentsVolume}
          showGroupBy={true}
          toolTipLabel={"comments"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={commentsVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.FacebookIcon}
          labelColor={"#2AAAE2"}
          titleToolTip={"comments_volume_fb_tooltip"}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"comments_reactions"}
          titleToolTip={"comments_reactions_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={reactions}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={reactionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          labels={[
            "like_reaction",
            "love_reaction",
            "care_reaction",
            "haha_reaction",
            "wow_reaction",
            "angry_reaction",
            "sad_reaction",
          ]}
          hideLegend
          dataPiechart={pageReactionPieChartValue}
          colors={[
            "#1892F2",
            "#FB5C77",
            "#F7B94D",
            "#FEDC5F",
            "#00D7B5",
            "#EC8A92",
            "#1C404E",
          ]}
        />
      </Box>
      <ThemeSection
        iconAfterTitle={props.FacebookIcon}
        {...props}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        eventName="Facebook__EngagementsPage__themes_and_sub_themes"
        isSocialListening
      />
      <Box mt={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis"}
          titleToolTip={"comments_sentiment_analysis_tooltip"}
          data={sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          dataPiechart={sentimentPieChart}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"comments_interactions"}
          titleToolTip={"comments_interactions_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={commentsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "likes"]}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={commentsInteractionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          hideLegend
          dataPiechart={commentsInteractionsLegend}
          showChartDetailsWidget
          statsDetailsData={interactionsStats}
          isInteractionStatsWrapperStyle
        />
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_keywords"}
              titleToolTip={"comments_top_keywords_tooltip"}
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              iconAfterTitle={props.FacebookIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_hashtags"}
              titleToolTip={"comments_top_hashtags_tooltip"}
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              iconAfterTitle={props.FacebookIcon}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_associated_topics"}
              titleToolTip={"comments_associated_topics_tooltip"}
              data={associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={associatedTopicsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.FacebookIcon}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OverViewSection;
