import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const BackgroundHeader = () => {
  const intl = useIntl();

  const handleSwitchLang = () => {
    if (window?.localStorage?.getItem("lang") === "ar") {
      window?.localStorage?.setItem("lang", "en");
    } else {
      window?.localStorage?.setItem("lang", "ar");
    }
    window?.location?.reload();
  };

  return (
    <Box className="background-header-wrapper">
      <Box component="p" onClick={handleSwitchLang}>
        {" "}
        {CheckValueLocale("change_lang_login", "", {}, intl)}{" "}
      </Box>
    </Box>
  );
};
export default BackgroundHeader;
