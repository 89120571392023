import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faMessageCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const Hint = ({ showTotalEngagementsHandler, selectedCard }) => {
  const intl = useIntl();

  return (
    <Box className="profile-side-bar-content">
      <Box className="content-box">
        <span className="title-box">
          <FontAwesomeIcon icon={faUserPlus} />
          {CheckValueLocale("followers_engagements_profile", "", {}, intl)}:
        </span>
        <span className="value-box">
          {selectedCard?.followers_count ? (
            <>
              {+selectedCard?.followers_count >= 1000
                ? `${
                    (+selectedCard?.followers_count / 1000).toFixed() > 1000
                      ? (
                          (+selectedCard?.followers_count / 1000).toFixed() /
                          1000
                        ).toFixed() + "m "
                      : (+selectedCard?.followers_count / 1000).toFixed() + "k "
                  }`
                : `${selectedCard?.followers_count} `}
            </>
          ) : (
            "-"
          )}
        </span>
      </Box>
      <Box className="content-box">
        <Tooltip
          title={
            <span className="total-tooltip">
              {CheckValueLocale("total_engagements_profile_hint", "", {}, intl)}
            </span>
          }
          placement={
            window?.localStorage?.lang === "en" ? "left-start" : "right-start"
          }
          arrow
        >
          <span className="title-box">
            <FontAwesomeIcon icon={faMessageCheck} />
            {CheckValueLocale("total_engagements_profile", "", {}, intl)}:
          </span>
        </Tooltip>
        <span
          className="value-box show-total"
          onClick={showTotalEngagementsHandler}
        >
          {CheckValueLocale("show", "", {}, intl)}
        </span>
      </Box>
    </Box>
  );
};
export default Hint;
