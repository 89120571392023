import moment from "moment";
import {
  isEmptyArray,
  convertToDhmsExcelSheet,
  secondsToHms,
  HmsToSeconds,
} from "utils/helpers";
import { handleXlsData, handleSortedCase } from "../excelCommonFn";
const gmail = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var overView = [],
      interactions = [],
      sentimentAnalysis = [],
      theme = [],
      subTheme = [],
      themesTrend = [],
      subThemesTrend = [],
      excelDialects = [],
      excelSubDialects = [],
      topKeywords = [],
      allSheetData = [];
    const getLocaleItem = (name) => {
      const nameLocales = {
        average_interactions_per_day: "avg_email_per_day",
        total_people: "total_contacts",
        default: name,
      };
      return nameLocales[name] || nameLocales.default;
    };
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "overview":
            value?.map((i) => {
              overView.push({
                [lang.xlsx_name]: lang[i?.name]
                  ? lang[getLocaleItem(i?.name)]
                  : getLocaleItem(i?.name),

                [lang.xlsx_value]:
                  i?.name == "net_sentiment"
                    ? lang[i?.value[0]?.name]
                    : i?.name == "response_rate"
                      ? `${(i?.value).toFixed(2)} %`
                      : i?.name == "average_interactions_per_day"
                        ? `${(i?.value).toFixed(2)}`
                        : i?.name == "total_interactions"
                          ? `${i?.value}`
                          : i?.name == "average_waiting_time"
                            ? HmsToSeconds(0, i?.value)
                              ? `${secondsToHms(HmsToSeconds(0, i?.value))[0]} ${
                                  lang["hour"]
                                }  ${secondsToHms(HmsToSeconds(0, i?.value))[1]} ${
                                  lang["minutes"]
                                }  ${secondsToHms(HmsToSeconds(0, i?.value))[2]} ${
                                  lang["seconds"]
                                }`
                              : `0 ${lang["hour"]} 0 ${lang["minutes"]} 0 ${lang["seconds"]}`
                            : i?.value,
              });
            });
            allSheetData.push([overView, lang.xlxs_overview]);
            break;
          // // _______________________________________________________________________________
          case "interaction":
            value?.map((i) => {
              interactions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([interactions, lang.emails]);
            break;
          // //_______________________________________________________________________________
          case "sentimentOvertime":
            value?.negative?.map((i, index) => {
              if (i?.name == value?.neutral[index]?.name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100)?.toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? (
                          (value?.positive[index]?.value / total) *
                          100
                        )?.toFixed(2) + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? (
                          (value?.negative[index]?.value / total) *
                          100
                        )?.toFixed(2) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([sentimentAnalysis, lang.sentiment_analysis]);
            break;
          // //_______________________________________________________________________________
          case "themeXlxs":
            //themes
            let mainThemes = value?.theme_analysis?.main_themes_view;
            mainThemes &&
              Object.entries(mainThemes)?.forEach(([key1, value1]) => {
                let subThemes = value1?.sub_themes;
                subThemes &&
                  Object.entries(subThemes)?.forEach(([key2, value2]) => {
                    theme?.push({
                      [lang?.excel_categories]: `${lang?.[key1]} (${lang?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([theme, lang?.excel_categories]);
            //sub-themes
            let mainSubThemes = value?.theme_analysis?.sub_themes_view;
            mainSubThemes &&
              Object.entries(mainSubThemes)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes;
                mainThemes &&
                  Object.entries(mainThemes)?.forEach(([key2, value2]) => {
                    subTheme?.push({
                      [lang?.excel_themes]: `${lang?.[key1]} (${lang?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([subTheme, lang?.excel_themes]);
            //themes-trend
            let mainthemesTrend = value?.theme_trend;
            mainthemesTrend &&
              Object.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes_view;
                let rawObj = {};
                mainThemes &&
                  Object.entries(mainThemes)?.forEach(([key2, value2]) => {
                    let subThemes = value2?.sub_themes;
                    subThemes &&
                      Object.entries(subThemes)?.forEach(([key3, value3]) => {
                        rawObj = {
                          ...rawObj,
                          [`${lang?.[key2]} (${lang?.[key3]})`]: value3,
                        };
                      });
                  });
                themesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([themesTrend, lang?.excel_categories_trend]);
            //sub-themes-trend
            mainthemesTrend &&
              Object.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let subThemesTrendObj = value1?.sub_themes_view;
                let rawObj = {};
                subThemesTrendObj &&
                  Object.entries(subThemesTrendObj)?.forEach(
                    ([key2, value2]) => {
                      let mainThemes = value2?.main_themes;
                      mainThemes &&
                        Object.entries(mainThemes)?.forEach(
                          ([key3, value3]) => {
                            rawObj = {
                              ...rawObj,
                              [`${lang?.[key2]} (${lang?.[key3]})`]: value3,
                            };
                          },
                        );
                    },
                  );
                subThemesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([subThemesTrend, lang?.excel_themes_trend]);
            break;

          case "excelDialects":
            handleSortedCase(
              value,
              excelDialects,
              lang.dialect,
              lang.main_dialect_filter,
              lang,
            );
            allSheetData.push([excelDialects, lang.main_dialect_filter]);
            break;
          // //_______________________________________________________________________________
          case "excelSubDialects":
            handleSortedCase(
              value,
              excelSubDialects,
              lang.sub_dialects,
              lang.number,
              lang,
            );
            allSheetData.push([excelSubDialects, lang.sub_dialects]);
            break;
          // //_______________________________________________________________________________
          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords]: i?.name,
                [lang.xlsx_number_of_posts]: i?.value,
              });
            });
            allSheetData.push([topKeywords, lang.top_keywords_xlsx_sheetname]);
            break;

          default:
            break;
        }
      });
    });
    return handleXlsData(allSheetData);
  }
};
export { gmail };
