import { useIntl } from "react-intl";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import CancelIcon from "@mui/icons-material/Cancel";

const EditReportStepTwo = ({ reportData }) => {
  const intl = useIntl();

  const monitorsTypeKeys = {
    MANAGED_ACCOUNT: "managed_account_title",
    MANAGED_PAGE: "managed_account_title",
    ACCOUNT_ANALYSIS: "public_account_title",
    KEYWORD: "keywords_title",
    REGION: "geographical_title",
  };

  return (
    <Grid container spacing={2.5}>
      {reportData?.attributes?.monitor_type &&
      reportData?.attributes?.platform === "SM" ? (
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel disabled={true}>
              {CheckValueLocale("monitor_type", "", {}, intl)}
            </InputLabel>
            <Select fullWidth defaultValue={""} displayEmpty disabled={true}>
              <MenuItem
                value=""
                disabled
                className="report-menu-items"
                id="report-edit-report-monitor-type"
              >
                {reportData?.attributes?.monitor_type?.name &&
                  (monitorsTypeKeys[reportData?.attributes?.monitor_type?.name]
                    ? CheckValueLocale(
                        monitorsTypeKeys[
                          reportData?.attributes?.monitor_type?.name
                        ],
                        "",
                        {},
                        intl,
                      )
                    : reportData?.attributes?.monitor_type?.name)}
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      ) : null}

      {reportData?.attributes?.channel_type &&
      reportData?.attributes?.platform === "CXM" ? (
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel disabled={true}>
              {CheckValueLocale("reports_edit_channel_type", "", {}, intl)}
            </InputLabel>
            <Select fullWidth defaultValue={""} displayEmpty disabled={true}>
              <MenuItem
                value=""
                disabled
                className="report-menu-items"
                id="report-edit-report-monitor-type"
              >
                {reportData?.attributes?.channel_type &&
                  CheckValueLocale(
                    reportData?.attributes?.channel_type,
                    "",
                    {},
                    intl,
                  )}
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      ) : null}

      {reportData?.attributes?.report_type ? (
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel disabled={true}>
              {CheckValueLocale("reports_edit_type", "", {}, intl)}
            </InputLabel>
            <Select fullWidth defaultValue={""} displayEmpty disabled={true}>
              <MenuItem
                value=""
                disabled
                className="report-menu-items"
                id="report-edit-report-type"
              >
                {CheckValueLocale(
                  `${reportData?.attributes?.report_type}_report_type`,
                  "",
                  {},
                  intl,
                )}
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      ) : null}

      {reportData?.attributes?.monitors ? (
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel disabled={true}>
              {CheckValueLocale(
                reportData?.attributes?.platform === "CXM"
                  ? "reports_channel_account"
                  : "monitor",
                "",
                {},
                intl,
              )}
            </InputLabel>
            <Select
              renderValue={() =>
                CheckValueLocale("reports_choose_account", "", {}, intl)
              }
              input={<OutlinedInput />}
              fullWidth
              defaultValue={""}
              displayEmpty
              disabled={true}
            >
              <MenuItem
                value=""
                disabled
                className="report-menu-items"
                id="report-edit-report-monitors"
              ></MenuItem>
            </Select>
          </Box>
          {reportData?.attributes?.monitors?.length > 0 && (
            <Box sx={{ mt: "8px" }}>
              {reportData?.attributes?.monitors?.map((val) => (
                <Button
                  key={val}
                  variant="outlined"
                  className="report-selected-channel"
                  endIcon={<CancelIcon />}
                  id="report-edit-report-selected-email-btn"
                  disabled
                >
                  {val?.name}
                </Button>
              ))}
            </Box>
          )}
        </Grid>
      ) : null}

      {reportData?.attributes?.data_sources ? (
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel disabled={true}>
              {CheckValueLocale(
                reportData?.attributes?.platform === "CXM"
                  ? "reports_select_data_included"
                  : "data_source",
                "",
                {},
                intl,
              )}
            </InputLabel>
            <Select fullWidth defaultValue={""} displayEmpty disabled={true}>
              <MenuItem
                value=""
                disabled
                className="report-menu-items"
                id="report-edit-report-data-sources"
              >
                {reportData?.attributes?.data_sources &&
                  reportData?.attributes?.data_sources
                    ?.map((val) =>
                      CheckValueLocale(
                        val?.type?.toLowerCase() === "private"
                          ? `${val?.name?.toLowerCase()}_dm`
                          : val?.name?.toLowerCase(),
                        "",
                        {},
                        intl,
                      ),
                    )
                    ?.join(", ")}
              </MenuItem>
            </Select>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default EditReportStepTwo;
