import React, { useState } from "react";
import {
  Card,
  Divider,
  Stack,
  Button,
  Grid,
  CardContent,
  CardActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import CompaniesController from "services/controllers/companiesController";
import Snackbar from "components/snackBar";
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";

const Addons = (props) => {
  const intl = useIntl();
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [socialListeningRequest, setSocialListeningRequest] = useState(null);
  const [socialListeningSuccess, setSocialListeningSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedCardId, setIsSelectedCardId] = useState(0);

  const sendThirdPartyDemo = async (text, id) => {
    let queryData = {
      service_name: text,
    };
    setIsSelectedCardId(id);
    setIsLoading(true);
    await CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSocialListeningRequest(true);
        setSuccessMsg("addons_success");
        setServiceName(text);
        setSocialListeningSuccess(true);
      } else {
        setSocialListeningRequest(true);
        setErrorMsg("addons_failed");
        setSocialListeningSuccess(false);
      }
      setIsLoading(false);
    });
  };
  return (
    <div id="addons">
      <h3 className="addons-head sub__head">
        {CheckValueLocale("addons", "", {}, intl)}
      </h3>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 3, md: 3 }}
        className="addons-list"
      >
        {[
          "news_&_blogs_monitoring",
          "professional_reports",
          "tv_&_radio_monitoring",
          "social_media_management",
          "branded_live_dashboard",
          "social_media_migration",
          "lucidya_api",
        ].map((text, index) => (
          <div key={index} className="addons-item">
            <Grid item>
              <Card
                justifyContent="center"
                style={{ border: "1px solid #E5E5E5", padding: 4 }}
              >
                <CardContent>
                  <Stack direction="column" mb={2}>
                    <Stack direction="row" justifyContent="center">
                      <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                        {CheckValueLocale(text, "", {}, intl)}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
                <Stack
                  className={text + "_stack cards_addons"}
                  direction="row"
                  justifyContent="center"
                  mt={6}
                  sx={{
                    marginTop: "5px",
                    minHeight: "70px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                >
                  <span className="addons__desc">
                    {CheckValueLocale(`${text}_definition`, "", {}, intl)}
                  </span>
                </Stack>

                <div className={text}>
                  <Divider />
                  <Stack direction="row" justifyContent="flex-end">
                    <CardActions>
                      <Stack direction="row" justifyContent="flex-end" mt={2}>
                        <LucButton
                          variant="outline"
                          type="secondary"
                          size="small"
                          onClick={() => {
                            sendThirdPartyDemo(text, index);
                          }}
                          disabled={
                            !handlelUserRoles("GENERAL", "CREATE_REQUEST")
                          }
                          id="setting-historical-send-request-upgrade-btn"
                          loading={isLoading && isSelectedCardId === index}
                        >
                          {CheckValueLocale("request", "", {}, intl)}
                        </LucButton>
                      </Stack>
                    </CardActions>
                  </Stack>
                </div>
              </Card>
            </Grid>
          </div>
        ))}
      </Grid>
      {socialListeningSuccess != null ? (
        <Snackbar
          open={socialListeningSuccess != null ? true : false}
          handleClose={() => {
            setSocialListeningSuccess(null);
          }}
          severity={socialListeningSuccess ? "success" : "error"}
          message={
            socialListeningSuccess ? (
              <div>
                <span id="subscription-addson-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span id="subscription-addson-snackbar-body">
                  <br />
                  {CheckValueLocale("add_addson_success_msg", "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span id="subscription-addson-snackbar-msg">
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span id="subscription-addson-snackbar-body">
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Addons;
