import { useState } from "react";
import { InputAdornment, Button, OutlinedInput } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useIntl } from "react-intl";

import { handleNbExcludedSourcesChange } from "../../../../../../../MonitorsShared/SharedFunctions/components/newBlogsSharedFunctions";
import {
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";

const ExcludedInputFieldCreate = (props) => {
  const intl = useIntl();
  const {
    nbExcludedSource,

    excludedSourcesErrorMsg,
    excludedSource,
    handleSourceskeyboardPress,
    setExcludedSource,
    setExcludedSourcesErrorMsg,
    setNbExcludedSourceError,
  } = props;
  // input Foucsed Case
  const [focused, setFocused] = useState(false);

  return (
    <OutlinedInput
      id="outlined-email-input"
      placeholder={CheckValueLocale("exlude_sources_titles", "", {}, intl)}
      className={`keywords-field keyword-input-field  ${checkDirectionLang(
        excludedSource,
      )}`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      endAdornment={
        focused && (
          <InputAdornment onMouseDown={handleInputMouseDown}>
            <Button
              className="add-btn-with-plus "
              onClick={(event) => handleSourceskeyboardPress(event, "btn")}
              value={excludedSource}
              id="monitor-options-nb-add-exclude-keyword-btn"
            >
              {CheckValueLocale("add_plus", "", {}, intl)}
            </Button>
          </InputAdornment>
        )
      }
      error={excludedSourcesErrorMsg !== "" ? excludedSourcesErrorMsg : ""}
      onKeyPress={handleSourceskeyboardPress}
      onChange={(e) =>
        handleNbExcludedSourcesChange(
          e,
          setExcludedSource,
          nbExcludedSource,
          setExcludedSourcesErrorMsg,
          setNbExcludedSourceError,
          intl,
        )
      }
      value={excludedSource}
    />
  );
};

export default ExcludedInputFieldCreate;
