import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import StepFields from "../components/stepFields";

const StepFour = (props) => {
  const {
    stepFields,
    requiredFields,
    selectedData,
    setSelectedData,
    stepFieldsErr,
  } = props;
  const intl = useIntl();

  return (
    <Box className="luci-steps-container">
      <Box>
        <Typography component="h3">
          {CheckValueLocale("luci_customers", "", {}, intl)}
        </Typography>
        <StepFields
          stepFields={stepFields}
          requiredFields={requiredFields}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          stepFieldsErr={stepFieldsErr}
        />
      </Box>
    </Box>
  );
};

export default StepFour;
