import React from "react";
import { useIntl } from "react-intl";
import { Box, CircularProgress, Switch, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import luciIcon from "images/luci-images/logoLuci.svg";
import tokensIcon from "images/luci-images/tokens-icon-blue.svg";
import "./luciStyle.scss";

const LuciInReports = (props) => {
  const intl = useIntl();
  const {
    step3FormFields,
    setStep3FormFields,
    isEdit,
    isLuciReport,
    setIsLuciReport,
    luciLoading,
    luciPerReport,
  } = props;

  const handleLuciSwitch = (e) => {
    if (isEdit === true) {
      setIsLuciReport(e.target.checked);
    } else {
      step3FormFields.isLuciReport = e.target.checked;
      setStep3FormFields({ ...step3FormFields });
    }
  };

  return (
    <Box className="luci-in-reports">
      <Box className="content-container">
        <Box className="luci-icon">
          <img src={luciIcon} alt="" />
        </Box>
        <Box className="content-text">
          <Typography className="title">
            {CheckValueLocale("luci_in_reports", "", {}, intl)}
            <Box component={"span"}>
              <Switch
                size="small"
                checked={isEdit ? isLuciReport : step3FormFields?.isLuciReport}
                onChange={handleLuciSwitch}
              />
            </Box>
          </Typography>
          <Typography className="info">
            {CheckValueLocale("luci_in_reports_info_1", "", {}, intl)}
            <Box component={"span"}>🚀✨</Box>
            <br />
            {CheckValueLocale("luci_in_reports_info_2", "", {}, intl)}
          </Typography>
          <Typography className="tokens-container">
            <Box component="span" className="tokens-num">
              <Box component="span">
                {luciLoading ? <CircularProgress /> : luciPerReport || 0}
              </Box>
              <img src={tokensIcon} alt="" />
            </Box>
            <Box component="span" className="tokens-per-report">
              {CheckValueLocale("per_report", "", {}, intl)}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LuciInReports;
