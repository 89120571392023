import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import { getTimeZone, isEmptyObj } from "utils/helpers";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useEditSentiment } from "pages/channelAnalytics/InsightsPage/hooks/useEditSentiment";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import GoogleMyBusiness from "images/shared-images/gmb.svg";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import user from "images/monitor_revamp/user.svg";
import { CheckValueLocale } from "utils/helpers/index";
import Luci from "pages/luci";
import { sanitize } from "dompurify";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#faaf00",
  },
});

const SingleGmbInteractions = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  let apiVars = "ratings/gmb";

  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "rating",
      url: `insights/ratings${
        hasDatasourceOnly === "true"
          ? `/google-my-business/${dataSourceId}`
          : ""
      }`,
    },
  ];
  const intl = useIntl();
  const [interactionData, setInteractionData] = useState({});
  const [iconToDisplay, setIconToDisplay] = useState(user);

  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result.data)) {
        setInteractionData(result.data);
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      const image = new Image();
      image.src = interactionData.profilePhotoUrl;
      image.onload = () => {
        setIconToDisplay(interactionData.profilePhotoUrl);
      };
    })();
  }, [interactionData]);

  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: themesOptions } = useThemesOptions({
    locale: intl?.locale,
  });

  const { mutate: editSentiment, isPending: isSentimentPending } =
    useEditSentiment();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();
  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();

  const preQueryData = {
    id: interactionId,
    tracker_id: interactionData?.tracker_id,
    product_id: window.localStorage.cxm_id,
    data_source_id: interactionData?.channel_id,
    created_at: interactionData?.created_at,
    account_id: interactionData?.account_id,
    location_id: interactionData?.location_id,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: interactionData?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setInteractionData((prev) => ({
            ...prev,
            themes: updatedCategories,
          })),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: interactionData?.sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setInteractionData((prev) => ({
            ...prev,
            sub_themes: updatedThemes,
          })),
      },
    );
  };

  const handleEditSentiment = (updatedSentiment) => {
    editSentiment(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedSentiment,
          old_label: interactionData?.sentiment,
        },
      },
      {
        onSuccess: () =>
          setInteractionData((prev) => ({
            ...prev,
            sentiment: updatedSentiment,
          })),
      },
    );
  };

  return (
    <>
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyObj(interactionData) ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader
              links={links}
              dataSource="google_my_business"
            />
            <div className="gmb-single-intr">
              <Box className="single-intr-wrapper">
                <span className="gmb-single-intr-icon">
                  <img
                    src={GoogleMyBusiness}
                    alt="icon-google-my-business"
                    width={"20px"}
                  />
                </span>
                <span className="gmb-single-intr-title">
                  {interactionData.sender_email_address}
                </span>

                <Box>
                  <SentimentCategoriesThemes
                    themesData={{
                      btnId: "gmb-single-interaction-themes-btn",
                      options: themesOptions,
                      selected: interactionData?.sub_themes,
                      handleChange: handleEditTheme,
                      loading: isThemesPending,
                    }}
                    categoriesData={{
                      btnId: "gmb-single-interaction-categories-btn",
                      options: categoriesOptions,
                      selected: interactionData?.themes,
                      handleChange: handleEditCategories,
                      loading: isCategoriesPending,
                    }}
                    sentimentData={{
                      btnId: "gmb-single-interaction-sentiment-btn",
                      options: sentimentsOptions,
                      selected: interactionData?.sentiment,
                      handleChange: handleEditSentiment,
                      loading: isSentimentPending,
                    }}
                    dividers={[1, 2]}
                  />
                </Box>
              </Box>
              <div className="gmb-single-intr-body">
                <Box className="gmb-interaction-header" component="div">
                  <div className="display-in-row">
                    <img
                      src={iconToDisplay}
                      alt="profile"
                      className="gmb-review-img"
                    />

                    <div className="rating-header">
                      <StyledRating
                        name="customized-color"
                        value={parseInt(interactionData.rating)}
                        precision={0.5}
                        readOnly
                        icon={<StarRoundedIcon fontSize="small" />}
                        emptyIcon={<StarOutlineRoundedIcon fontSize="small" />}
                      />
                      <div className="header-user-name">
                        {interactionData?.sender_email_address}
                      </div>
                    </div>
                  </div>
                </Box>
                <p
                  style={{ whiteSpace: "pre-line" }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      interactionData.message
                        ?.replace(/(\r\n)+/g, "\r\n")
                        .replace(/(\n\r)+/g, "\r\n")
                        .replace(/\n+/g, "\n")
                        .replace(/\r+/g, "\r")
                        .trim(),
                    ),
                  }}
                />
                <div className="gmb-single-intr-footer row">
                  <div>
                    {moment
                      .unix(interactionData.created_at)
                      .utc()
                      .format("YYYY/MM/DD ")}
                    <Typography component="span">at</Typography>
                    {moment
                      .unix(interactionData.created_at)
                      .utc()
                      .format(" LT ")}
                    {getTimeZone() > 0
                      ? `(${CheckValueLocale(
                          "gmt",
                          "",
                          {},
                          intl,
                        )} +${getTimeZone()})`
                      : `(${CheckValueLocale(
                          "gmt",
                          "",
                          {},
                          intl,
                        )} -${getTimeZone()})`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleGmbInteractions;
