import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import BodyPopUpMonitorLink from "./bodyPopUpMonitorLink";

function MonitorsCell({ monitors, intl }) {
  const [openPopupShowAllMonitors, setOpenPopupShowAllMonitors] =
    useState(null);
  const fristThreeMonitors = monitors?.slice(0, 3);

  const openPopupShowAll = () => {
    setOpenPopupShowAllMonitors(true);
  };

  const getLinkMonitor = (monitor) => {
    return {
      pathname: `/monitor/KEYWORD/INSTAGRAM/engagements/${monitor?.id}`,
    };
  };

  return (
    <>
      {fristThreeMonitors?.map((monitor) => (
        <>
          <Link to={getLinkMonitor(monitor)} className="monitor-link">
            {monitor?.name}
          </Link>
          {monitor?.id !== monitors[monitors?.length - 1]?.id ? (
            <Box component="span">, </Box>
          ) : null}
        </>
      ))}

      {monitors?.length > 3 ? (
        <Box
          component="span"
          onClick={openPopupShowAll}
          className="show-all-monitors"
        >
          {CheckValueLocale("show_all", "", {}, intl)}
        </Box>
      ) : null}

      {openPopupShowAllMonitors && (
        <PopupModal
          title={CheckValueLocale("monitors", "", {}, intl)}
          body={
            <BodyPopUpMonitorLink
              Monitors={monitors}
              getLinkMonitor={getLinkMonitor}
            />
          }
          childrenModal={
            <Box className="number-of-monitors" component="span">
              {monitors?.length}{" "}
              {CheckValueLocale("num_monitors_hashtag", "", {}, intl)}
            </Box>
          }
          rightBtn={CheckValueLocale("done", "", {}, intl)}
          classeName="popup-show-all"
          open={openPopupShowAllMonitors || false}
          close={() => setOpenPopupShowAllMonitors(null)}
          accept={() => setOpenPopupShowAllMonitors(null)}
        />
      )}
    </>
  );
}

export default MonitorsCell;
