import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";

export const useCategoriesOptions = ({ locale, disableFetch }) => {
  const urlParams = useParams();
  return useQuery({
    queryKey: ["categoriesOptions", locale],
    queryFn: () => {
      if (window?.location?.href?.includes("/live-dashboard/public/")) {
        return InteractionsController.getThemesPublic({
          monitor_id: urlParams?.monitorId,
          dashboard_user_id: +urlParams?.userDashboardId,
        });
      } else {
        return InteractionsController.getThemes();
      }
    },
    enabled: !disableFetch,
    retry: false,
    select: (data) => {
      return data?.data?.data?.data;
    },
  });
};
