// Common file used in whole app to show Alert Success/Danger Msg at top side (left/right based on app language) of the screen .
// Don't repeat/update or remove this file!
// This File Supports English and Arabic pages .
// props to be send :message,title,severity,handleClose,open,alertStyle.

import { Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";

const SnackBar = (props) => {
  var vertical = "top";
  var horizontal = "right";

  if (window.localStorage.lang === "ar") {
    horizontal = "left";
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props.open} //open should be boolean not string
      autoHideDuration={7000} //snackbar auto hides after 7000
      onClose={() => {
        if (typeof props.handleClose === "function") {
          props?.handleClose();
        }
      }}
      className="common-snackbar"
      id={props.snackBarId}
    >
      <Alert
        onClose={props?.handleClose}
        severity={props.severity} //severity is success(green color)/error(red color)
        sx={props.alertStyle ? props.alertStyle : { width: "100%" }} //change the dimensions of the alert
      >
        {props.title && (
          <div>
            <strong>
              {props.title}
              {/* send as intl.formatMessage({id:""}) */}
            </strong>
          </div>
        )}
        {props.message}
        {/* send as intl.formatMessage({id:""}) */}
      </Alert>
    </Snackbar>
  );
};

SnackBar.propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  alertStyle: PropTypes.object,
  snackBarId: PropTypes.string,
};
export default SnackBar;
