import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import OverView from "components/widgets/overViewWidget";
import BarChartWidget from "components/widgets/barChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import MediaInstagram from "components/media/media-insta/mediaInsta";
import PunchCardWidget from "components/widgets/punchCardWidget";
import { CheckValueLocale } from "utils/helpers";

const InstagramAccountBody = (props) => {
  const intl = useIntl();
  let { instagramWidgets } = props;
  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("account_overview", "", {}, intl).toUpperCase()}
      </Typography>
      {/* <Box mt={3}>
        <BarChartWidget
          title={"followers_growth"}
          titleToolTip={"followers_growth_ig_tooltip"}
          data={instagramWidgets?.followersGrowth}
          showGroupBy={true}
          dataColor="#2AAAE2"
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "total_followers",
              value: instagramWidgets?.totalFollowersFollowersGrowth,
            },
            {
              name: "max_change_of_followers",
              value: instagramWidgets?.maxChangeFollowersGrowth,
            },
            {
              name: "avg_change_per_day",
              value: instagramWidgets?.avgChangeFollowersGrowth,
            },
          ]}
          toolTipLabel={"posts"}
          showFollowersGrowthStats
          showDownloadIcon
          showZoomPercentage
          bigImg
          iconAfterTitle={props.instagramIcon}
          barChartPreLoaderDataSources={
            instagramWidgets?.followersGrowthPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box> */}
      {/* <Box mt={3}>
        <StackedBarWidget
          title={"page_contact_details_interactions"}
          titleToolTip={"page_contact_details_interactions_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={instagramWidgets?.postsContactDetails}
          colors={["#2AAAE2", "#80868C", "#334155", "#AFBCCE"]}
          labels={[
            "website_clicks",
            "phone_clicks",
            "directions",
            "profile_visits",
          ]}
          showScales
          showZoomPercentage
          iconAfterTitle={props.instagramIcon}
          postsColumnPreLoaderDataSources={
            instagramWidgets?.postsContactDetailsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={instagramWidgets?.postsContactDetailsLegend}
        />
      </Box> */}
      <Box my={3} className="channel-activity">
        <PunchCardWidget
          title="account_authors_activity"
          titleToolTip={"account_authors_activity_ig_tooltip"}
          clientActivity={instagramWidgets?.authorActivity}
          minValueClient={instagramWidgets?.minValueAuthorActivity}
          maxValueClient={instagramWidgets?.maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={props?.instagramIcon}
          showNotAppliedFilter
          authorActivityPreLoaderDataSources={
            instagramWidgets?.authorActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        />
      </Box>
      <Typography className="section-name">
        {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"posts_volume"}
          titleToolTip={"posts_volume_ig_tooltip"}
          totalLabel={"posts"}
          totalValue={instagramWidgets?.totalPostsVolumeCount}
          data={instagramWidgets?.postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          showNotAppliedFilter
          iconAfterTitle={props?.instagramIcon}
          labelColor={"#2AAAE2"}
          lineChartPreLoaderDataSources={
            instagramWidgets?.postsVolumePreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        />
      </Box>
      {/* <Box className="sentiment-chart" mt={3}>
        <StackedLineWidget
          title={"posts_reach_impressions"}
          titleToolTip={"posts_reach_impressions_ig_tooltip"}
          data={instagramWidgets?.reachImpression}
          areaStyle={true}
          isSwitchCase={true}
          toolTipLabel={"toolTipLabel"}
          colors={["#2AAAE2", "#80868C"]}
          showDownloadIcon
          stackedLineWidgetPreLoaderDataSources={
            instagramWidgets?.reachImpressionPreLoaderDataSources
          }
          isPostsReachImpression
          bigImg
          iconAfterTitle={props.instagramIcon}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupBy={true}
          showScales
          hideLegend
          dataPiechart={instagramWidgets?.reachImpressionLegend}
          labelColors={["#2AAAE2", "#80868C"]}
        />
      </Box> */}

      <Box mt={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          titleToolTip={"posts_interactions_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={instagramWidgets?.postsInteractions}
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "max_interaction",
              value: instagramWidgets?.maxValuePostsInteractions,
              date: instagramWidgets?.maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: instagramWidgets?.minValuePostsInteractions,
              date: instagramWidgets?.minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: instagramWidgets?.avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "likes"]}
          showPostsInteractionStats
          showScales
          showZoomPercentage
          showNotAppliedFilter
          iconAfterTitle={props?.instagramIcon}
          postsColumnPreLoaderDataSources={
            instagramWidgets?.postsInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={instagramWidgets?.postInteractionsLegend}
        />
      </Box>

      <Box mt={3} className="average-engagements-per-post">
        <BarChartWidget
          title={"average_engagements_per_post"}
          titleToolTip={"average_engagements_per_post_ig_tooltip"}
          data={instagramWidgets?.averageEngagementPerPost}
          showGroupBy
          dataColor="#2AAAE2"
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "max_interactions_per_post",
              value: instagramWidgets?.maxValueAverageEngagementPerPost,
              date: instagramWidgets?.maxValueAverageEngagementPerPostDate,
            },
            {
              name: "min_interactions_per_post",
              value: instagramWidgets?.minValueAverageEngagementPerPost,
              date: instagramWidgets?.minValueAverageEngagementPerPostDate,
            },
            {
              name: "avg_interaction_per_post",
              value: instagramWidgets?.avgValueAverageEngagementPerPost,
            },
          ]}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          showNotAppliedFilter
          iconAfterTitle={props?.instagramIcon}
          showZoomPercentage
          barChartPreLoaderDataSources={
            instagramWidgets?.averageEngagementPerPostPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"posts_content_type"}
          titleToolTip={"posts_content_type_ig_tooltip"}
          posts={instagramWidgets?.postsContentType}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={["video", "photo", "catalog"]}
          hideLegend
          dataPiechart={instagramWidgets?.postContentPieChart}
          showDownloadIcon
          bigImg
          showScales
          showZoomPercentage
          showNotAppliedFilter
          iconAfterTitle={props?.instagramIcon}
          postsColumnPreLoaderDataSources={
            instagramWidgets?.postsContentTypePreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={6}>
        <MediaInstagram
          title={CheckValueLocale("top_images", "", {}, intl)}
          titleToolTip={"posts_top_images_tooltip"}
          data={instagramWidgets?.topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={props?.instagramIcon}
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={
            instagramWidgets?.topImagesPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          questions={instagramWidgets?.topImages}
          showNotAppliedFilter
          datasourcePostsType="INSTAGRAM"
          hideMoreInsights
        />
      </Box>
      <Box my={4}>
        <MediaInstagram
          title={CheckValueLocale("top_reels", "", {}, intl)}
          titleToolTip={"posts_top_reels_tooltip"}
          data={instagramWidgets?.topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={props?.instagramIcon}
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={
            instagramWidgets?.topVideosPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          questions={instagramWidgets?.topVideos}
          showNotAppliedFilter
          datasourcePostsType="INSTAGRAM"
          hideMoreInsights
        />
      </Box>
    </>
  );
};

export default InstagramAccountBody;
