import React, { useState } from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";

import NeedsAttentionSection from "./needsAttention/needsAttentionSection";
import Notifications from "./notifications/notifications";
import GetStarted from "./getStarted/getStarted";
import LucidyaSuite from "./lucidyaSuite/lucidyaSuite";
import SnackBar from "components/snackBar";
import {
  CheckValueLocale,
  handleActiveFeature,
  handlelUserRoles,
} from "utils/helpers";
import Dashboards from "./dashboard/dashboards";

const SlideSection = (props) => {
  const {
    loading,
    luciSuite,
    needsAttentionData,
    notifications,
    loadingDelay,
    dashboard,
  } = props;
  const intl = useIntl();
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  return (
    <Box className="slide-continer">
      <NeedsAttentionSection
        loading={loading}
        needsAttentionData={needsAttentionData}
        loadingDelay={loadingDelay}
        setSnackBar={setSnackBar}
        snackBar={snackBar}
      />
      {(handlelUserRoles("SM", "VIEW_DASHBOARD_LIST") &&
        handleActiveFeature("SM", "is_dashboards_enabled")) ||
      handleActiveFeature("SM", "is_sl_custom_dashboards_enabled") ||
      handleActiveFeature("CXM", "is_cxm_custom_dashboards_enabled") ? (
        <Dashboards
          loading={loading}
          loadingDelay={loadingDelay}
          dashboard={dashboard}
        />
      ) : null}
      <Notifications
        loading={loading}
        loadingDelay={loadingDelay}
        notifications={notifications}
      />
      <GetStarted loadingDelay={loadingDelay} loading={loading} />
      <LucidyaSuite
        loading={loading}
        loadingDelay={loadingDelay}
        luciSuite={luciSuite}
        setSnackBar={setSnackBar}
        snackBar={snackBar}
      />
      <SnackBar
        className="home-page-snackbar"
        open={snackBar?.open}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
      />
    </Box>
  );
};

export default SlideSection;
