const timeList = [
  { value: 0, name: "12:00 AM" },
  { value: 0.5, name: "12:30 AM" },
  { value: 1, name: "01:00 AM" },
  { value: 1.5, name: "01:30 AM" },
  { value: 2, name: "02:00 AM" },
  { value: 2.5, name: "02:30 AM" },
  { value: 3, name: "03:00 AM" },
  { value: 3.5, name: "03:30 AM" },
  { value: 4, name: "04:00 AM" },
  { value: 4.5, name: "04:30 AM" },
  { value: 5, name: "05:00 AM" },
  { value: 5.5, name: "05:30 AM" },
  { value: 6, name: "06:00 AM" },
  { value: 6.5, name: "06:30 AM" },
  { value: 7, name: "07:00 AM" },
  { value: 7.5, name: "07:30 AM" },
  { value: 8, name: "08:00 AM" },
  { value: 8.5, name: "08:30 AM" },
  { value: 9, name: "09:00 AM" },
  { value: 9.5, name: "09:30 AM" },
  { value: 10, name: "10:00 AM" },
  { value: 10.5, name: "10:30 AM" },
  { value: 11, name: "11:00 AM" },
  { value: 11.5, name: "11:30 AM" },
  { value: 12, name: "12:00 PM" },
  { value: 12.5, name: "12:30 PM" },
  { value: 13, name: "01:00 PM" },
  { value: 13.5, name: "01:30 PM" },
  { value: 14, name: "02:00 PM" },
  { value: 14.5, name: "02:30 PM" },
  { value: 15, name: "03:00 PM" },
  { value: 15.5, name: "03:30 PM" },
  { value: 16, name: "04:00 PM" },
  { value: 16.5, name: "04:30 PM" },
  { value: 17, name: "05:00 PM" },
  { value: 17.5, name: "05:30 PM" },
  { value: 18, name: "06:00 PM" },
  { value: 18.5, name: "06:30 PM" },
  { value: 19, name: "07:00 PM" },
  { value: 19.5, name: "07:30 PM" },
  { value: 20, name: "08:00 PM" },
  { value: 20.5, name: "08:30 PM" },
  { value: 21, name: "09:00 PM" },
  { value: 21.5, name: "09:30 PM" },
  { value: 22, name: "10:00 PM" },
  { value: 22.5, name: "10:30 PM" },
  { value: 23, name: "11:00 PM" },
  { value: 23.5, name: "11:30 PM" },
];

export default timeList;
