import { useMutation } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useEditAnswerSentiment = () => {
  return useMutation({
    mutationFn: async ({ queryData }) => {
      await SurveyController.editSentiment(queryData);
    },
  });
};
