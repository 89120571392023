import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WidgetBox from "./widgetBox";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const SingleDataSource = ({
  getSourceName,
  dataSource,
  dataSourceTabs,
  handleChangeCollapse,
  expanded,
  selectedWidgets,
  handleSelectWidget,
  getSelectedWidgetsLength,
  getDataSourceTabsSorted,
  getSocialIcon,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(0 + "" + dataSource);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const widgetIgnored = [
    "Twitter__AccountPage__top_posts_comments",
    "Talkwalker__EngagementsPage__posts_interactions",
    "Talkwalker__EngagementsPage__overview",
  ];

  const isTwitterDataSource = getSourceName(dataSource) === "TWITTER";

  const dataSourceTabsSorted = getDataSourceTabsSorted(dataSourceTabs);

  const selectedWidgetsDataSourceLength = selectedWidgets?.[dataSource]?.length;

  return (
    <Accordion
      expanded={expanded === dataSource}
      onChange={handleChangeCollapse(dataSource)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className="summary-data-source-wrapper">
          <Box component="h4" className="summary-content-heading">
            {CheckValueLocale(getSourceName(dataSource), "", {}, intl)}
            {getSocialIcon(getSourceName(dataSource))}
          </Box>
          {selectedWidgetsDataSourceLength ? (
            <Box component="p" className="summary-content-hint">
              {CheckValueLocale(
                "widgets_selected",
                "",
                {
                  num: selectedWidgetsDataSourceLength,
                },
                intl,
              )}
            </Box>
          ) : null}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TabContext value={value}>
          <TabList
            className="create-live-dashboard-tabs-btns"
            onChange={handleChangeTabs}
            aria-label="create live dashboard tabs"
          >
            {dataSourceTabsSorted?.map((tabName, i) => {
              const selectedWidgetsLength = getSelectedWidgetsLength(
                dataSourceTabs?.[tabName],
                selectedWidgets?.[dataSource],
              );
              return !(tabName == "authors" && isTwitterDataSource) ? (
                <Tab
                  label={
                    <>
                      {CheckValueLocale(tabName, "", {}, intl)}
                      {selectedWidgetsLength ? (
                        <span className="selected-widgets-length">
                          {selectedWidgetsLength}
                        </span>
                      ) : null}
                    </>
                  }
                  value={i + "" + dataSource}
                />
              ) : null;
            })}
          </TabList>
          {dataSourceTabsSorted?.map((tabName, i) => {
            return !(tabName == "authors" && isTwitterDataSource) ? (
              <TabPanel value={i + "" + dataSource} sx={{ padding: "0" }}>
                <Grid container spacing={3}>
                  {dataSourceTabs?.[tabName]?.map((widgetValue) => {
                    return !widgetIgnored?.includes(
                      widgetValue?.widget_name,
                    ) ? (
                      <WidgetBox
                        widgetValue={widgetValue}
                        selectedWidgets={selectedWidgets}
                        dataSource={dataSource}
                        handleSelectWidget={handleSelectWidget}
                      />
                    ) : null;
                  })}
                </Grid>
              </TabPanel>
            ) : null;
          })}
        </TabContext>
      </AccordionDetails>
    </Accordion>
  );
};

export default SingleDataSource;
