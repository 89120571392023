import { faList } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import profileImg from "images/engagements-images/profileImg.png";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";

const distanceFromTop = 270; // distance from the top of the window
const menuMaxWidth = 293; // max width for the menu list

export const UsersListMenu = ({
  selectedUsers,
  users,
  handleUserDelete,
  handleUserAdd,
  menuRef,
  handleTextInputFocus,
  showMenu = true,
}) => {
  const intl = useIntl();
  const postOwner = users?.find((user) => user?.postOwner);
  const otherReplyToUsers = users?.filter?.((user) => !user?.postOwner);
  const [anchorPosition, setAnchorPosition] = useState(null); // Save the position
  let open = Boolean(anchorPosition);
  const menuMaxHeight = window?.innerHeight - distanceFromTop; // max height for the menu list

  const handleOpenMenu = (event) => {
    event.stopPropagation(); // Stop propagation to prevent ClickAwayListener from closing the menu

    // before opening the menu we need to calculate the position of the menu
    // we need to place the menu at a fixed position that will not change when the the anchor element is moving or resizing
    // we need to place the menu at the top of the anchor element

    const anchorRect = event?.currentTarget?.getBoundingClientRect?.();
    const calcMenuTopPosition = () => {
      const itemHeight = 59; // height of each item in the menu list
      const titleItemHeight = 41; // height of the title item in the menu list
      const dividerHeight = 8 + 17; // height of the divider in the menu list
      // total height of the titles in the menu list (reply to twitter + reply to others in conversation)
      const titlesHeight =
        users?.length > 1
          ? titleItemHeight * 2 + dividerHeight
          : titleItemHeight;
      // total height of the users in the menu list (each user will have a height of 59px) + the titles height
      let totalHeight = titlesHeight + users?.length * itemHeight;
      // if the total height is greater than the max height then we will return 80px as the top position for the menu from the window top
      if (totalHeight >= menuMaxHeight) {
        return 90;
      }
      return anchorRect?.top - totalHeight - 10; // 10px is the gap between the menu and the anchor element
    };

    const leftMenuCenteredPosition =
      anchorRect?.left - menuMaxWidth / 2 + anchorRect?.width / 2;
    setAnchorPosition({
      top: calcMenuTopPosition(),
      left: leftMenuCenteredPosition,
    });
  };
  const handleCloseMenu = () => {
    setAnchorPosition(null);
    setTimeout(() => {
      handleTextInputFocus?.();
    }, 0);
  };
  const handleToggleUser = (user, isSelected) => {
    if (isSelected) {
      handleUserDelete(user?.id);
    } else {
      handleUserAdd(user);
    }
  };

  return (
    <>
      <Tooltip
        title={CheckValueLocale("reply_to_users_show_more", "", {}, intl)}
        placement="bottom"
        arrow
      >
        <Box
          className="twitter-reply-to-menu"
          ref={menuRef}
          id="twitter-reply-to-menu-icon"
          onClick={handleOpenMenu}
        >
          <FontAwesomeIcon icon={faList} />
        </Box>
      </Tooltip>

      {showMenu ? (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu
            id="twitter-reply-to-menu-list"
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            onClose={handleCloseMenu}
            open={open}
            classes={{ paper: "twitter-reply-to-menu-list-paper" }}
            slotProps={{
              paper: {
                style: {
                  width: `${menuMaxWidth}px`,
                  maxHeight: `${menuMaxHeight}px`,
                },
              },
            }}
          >
            <UsersMenuListItems
              users={[postOwner]}
              selectedUsers={selectedUsers}
              title="reply_to_twitter"
            />
            {otherReplyToUsers?.length > 0 ? (
              <Box>
                <Divider className="twitter-reply-to-menu-divider" />
                <UsersMenuListItems
                  users={otherReplyToUsers}
                  handleToggleUser={handleToggleUser}
                  selectedUsers={selectedUsers}
                  title="reply_to_others_in_conversation"
                />
              </Box>
            ) : null}
          </Menu>
        </ClickAwayListener>
      ) : null}
    </>
  );
};

const UsersMenuListItems = ({
  users,
  handleToggleUser,
  title,
  selectedUsers,
}) => {
  const intl = useIntl();
  const handleImageError = (e) => {
    e.target.src = profileImg;
  };

  return (
    <>
      <Typography variant="h6" className="twitter-reply-to-menu-title">
        {CheckValueLocale(title, "", {}, intl)}
      </Typography>
      {users?.map?.((user, idx) => {
        const isSelected = selectedUsers?.some?.(
          (selectedUser) => selectedUser?.id === user?.id,
        );
        const username =
          intl?.locale === "ar" ? `${user?.username}@` : `@${user?.username}`;
        const isPostOwner = user?.postOwner;

        return (
          <MenuItem
            key={user?.id}
            onClick={() =>
              isPostOwner ? null : handleToggleUser?.(user, isSelected)
            }
            className={classNames(
              "twitter-reply-to-menu-item",
              isPostOwner ? "item-reply-disabled" : "",
            )}
            disableRipple={true}
            disableGutters={true}
            value={user?.id}
            id={`twitter-reply-to-menu-item-${isPostOwner ? "disabled" : ""}-${idx}`}
          >
            <Checkbox
              checked={isSelected}
              className="twitter-reply-to-menu-item-checkbox"
              disabled={isPostOwner}
            />
            <img
              src={user?.profile_image_url || profileImg}
              onError={handleImageError}
              className="twitter-reply-to-menu-item-profile-img"
            />
            <Box className="twitter-reply-to-menu-item-profile">
              <TruncatedTooltip
                title={user?.name}
                placement="bottom"
                className="twitter-reply-to-menu-item-profile-name"
                enablePropperProps={true}
              />
              <TruncatedTooltip
                title={username}
                placement="bottom"
                className="twitter-reply-to-menu-item-profile-username"
                enablePropperProps={true}
              />
            </Box>
          </MenuItem>
        );
      })}
    </>
  );
};
