import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { arrayMoveImmutable } from "array-move";
import { Alert, Box, Container } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useGetThemes } from "./hooks/useGetThemes";
import { useReorderThemes } from "./hooks/useReorderThemes";
import { renderThemesTableBody } from "./components/renderThemesTableBody";
import SnackBar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import ThemesPageHeader from "./components/themesPageHeader";
import OrderedTable from "pages/settings/components/InboundRouting/components/orderedTable/orderedTable";
import ThemeDialog from "./components/ThemeDialog/themeDialog";
import "./style.scss";

const ThemesPage = () => {
  const intl = useIntl();

  const [snackBarData, setSnackBarData] = useState({
    open: false,
    severity: "",
    title: "",
    message: "",
  });

  // New Theme or Edit Theme Dialog Stats depending on (isEdit: true) inCase of EDIT
  const [openDialog, setOpenDialog] = useState(false);
  const [themeDialogStats, setThemeDialogStats] = useState({ isEdit: false });

  // Get Themes List
  const {
    data: themesData,
    isError: isThemesError,
    isPending: isThemesLoading,
  } = useGetThemes();

  // ReOrder themes list by Draging
  const {
    mutate: reorderThemes,
    isPending: isReorderLoading,
    isError: isReorderError,
  } = useReorderThemes();

  const handleDragAndDrop = (result) => {
    const oldIndex = result?.source?.index;
    const newIndex = result?.destination?.index;
    if (oldIndex == newIndex) return; // To avoid requesting to BE withoout updates in order

    const reOrderedThemes = arrayMoveImmutable(
      themesData,
      oldIndex,
      newIndex,
    )?.map((theme, index) => ({ ...theme, sub_themes_order: index }));

    reorderThemes({ data: reOrderedThemes });
  };

  useEffect(() => {
    if (isThemesError || isReorderError) {
      setSnackBarData({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    }
  }, [isThemesError, isReorderError]);

  const tableHeader = [
    handlelUserRoles("GENERAL", "REORDER_SUBTHEME") && { width: "5.75%" },
    { body: CheckValueLocale("color", "", {}, intl), wdith: "6.86%" },
    { body: CheckValueLocale("english_label", "", {}, intl), width: "15.44%" },
    { body: CheckValueLocale("arabic_label", "", {}, intl), width: "15.44%" },
    { body: CheckValueLocale("description", "", {}, intl), width: "37.3%" },
    {
      body: CheckValueLocale("custom_theme_created_by", "", {}, intl),
      width: "13.72%",
    },
    { width: "5.5%" },
  ];

  return (
    <Container maxWidth="xl">
      {isThemesLoading ? (
        <CircularLoading />
      ) : (
        <Box id="custom-themes-page-wrapper">
          <ThemesPageHeader
            disableNewTheme={themesData?.length >= 10}
            setOpenDialog={setOpenDialog}
            setThemeDialogStats={setThemeDialogStats}
          />
          {themesData?.length >= 10 ? (
            <Alert id="themes-page-reached-limit-alert" severity="warning">
              {CheckValueLocale("reached_limit_for_themes_alert", "", {}, intl)}
            </Alert>
          ) : null}
          <Box id="themes-page-table-wrapper">
            <OrderedTable
              isSharedStyle
              isHashTable // to remove margin as classified in shared component
              tableHeader={tableHeader}
              tableBody={
                !themesData?.length
                  ? []
                  : renderThemesTableBody({
                      data: themesData,
                      setOpenDialog,
                      setThemeDialogStats,
                      setSnackBarData,
                    })
              }
              handleDragEnd={handleDragAndDrop}
              emptyBody={""} // No case for empty as we can't delete Undefined Theme (Requirement)
              loading={isReorderLoading}
            />
          </Box>
        </Box>
      )}
      {/* This dialog used for [New Theme, Edit Theme] */}
      <ThemeDialog
        themeDialogStats={themeDialogStats}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setThemeDialogStats={setThemeDialogStats}
        setSnackBarData={setSnackBarData}
      />
      <SnackBar
        open={snackBarData?.open}
        severity={snackBarData?.severity}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
        handleClose={() =>
          setSnackBarData({
            open: false,
            severity: "",
            title: "",
            message: "",
          })
        }
      />
    </Container>
  );
};

export default ThemesPage;
