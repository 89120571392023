import { Box, Button, FormControlLabel, Typography } from "@mui/material";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./luciPolicy.scss";
import Checkbox from "@mui/material/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import policyLogo from "../../../../images/luci-images/policy.svg";
import LuciController from "services/controllers/luciController";
import { Link } from "react-router-dom";

const LuciPolicy = (props) => {
  const intl = useIntl();
  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const getLuciPolicy = () => {
    LuciController.getLuciPolicy().then((result) => {
      if (!result.errorMsg) {
        window.localStorage.setItem("is_policy_active", "true");
        props?.setIsLuciPolicy(true);
        props?.handleOpenSettings();
      }
    });
  };
  const handleSendReqPolicy = () => {
    getLuciPolicy();
  };
  const checkLang = () => {
    return (
      <a
        href="https://openai.com/policies/api-data-usage-policies"
        target="_blank"
        rel="noreferrer"
      >
        {CheckValueLocale("following_link", "", {}, intl)}
      </a>
    );
  };
  const link = checkLang();
  return (
    <Box className="luci-policy-parant">
      <Box className="side-bar-head">
        <Button
          className="btn-close-side-bar"
          onClick={() => props?.closeSideBar()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Box className="luci-terms">
          <Typography component={"span"} className="logo-luci-answer">
            <img src={policyLogo} alt="" loading="lazy" />
          </Typography>
          <Typography component={"div"} className="luci-terms-content">
            <Typography component={"h3"}>
              {CheckValueLocale("luci_terms_policy", "", {}, intl)}
            </Typography>
            <Typography component={"span"}>
              {CheckValueLocale("luci_Last_updated", "", {}, intl)}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box className="luci-policy">
        <Typography component={"h3"} className="luci-policy-title">
          {CheckValueLocale("luci_policy_title", "", {}, intl)}
        </Typography>

        <ol className="list-policy">
          <li>{CheckValueLocale("luci_policy_content", "", {}, intl)}</li>
          <li>
            {CheckValueLocale("luci_policy_recommendations", "", {}, intl)}
          </li>
          <li>{CheckValueLocale("luci_policy_acknowledge", "", {}, intl)}</li>
          <li>{CheckValueLocale("luci_agree_use_service", "", {}, intl)}</li>
          <li>
            {/* {CheckValueLocale("luci_service_provided", "", { link }, intl)} */}
            {intl.formatMessage(
              { id: "luci_service_provided" },
              { link: link },
            )}
          </li>
        </ol>
        <Typography component={"h3"} className="luci-policy-title"></Typography>
        <Typography className="luci-paragraph-policy">
          {CheckValueLocale("luci_accept", "", {}, intl)}
        </Typography>
        <FormControlLabel
          className="luci-form-control-lable"
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label={CheckValueLocale("luci_agree_policy", "", {}, intl)}
        />
        <Button
          className={
            checked === true
              ? "btn-accept-policy"
              : "btn-accept-policy accept-policy-disabled"
          }
          disabled={checked === false}
          onClick={handleSendReqPolicy}
        >
          {CheckValueLocale("luci_accept_policy", "", {}, intl)}
        </Button>
      </Box>
    </Box>
  );
};

export default LuciPolicy;
