import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getPercentage, handlelUserRoles } from "utils/helpers";
import Snackbar from "components/snackBar";
import { useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./subscription.scss";
import LucButton from "shared/lucButton/lucButton";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const AudiencePackage = (props) => {
  const { success, cdpInfo, setSuccess, sendPackageUpgrade, isLoadingUpgrade } =
    props;

  const intl = useIntl();
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [cdpRequest, setCDPRequest] = useState(null);
  const [cdpSuccess, setCdpSuccess] = useState(null);
  const [upgradePackageRequest, setUpgradePackageRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const requestChannelAnalDemo = () => {
    let queryData = {
      service_name: "request_audience_demo",
    };

    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result?.errorMsg) {
        setCDPRequest(true);
        setSuccessMsg("request_demo");
        setCdpSuccess(true);
      } else {
        setCDPRequest(false);
        setErrorMsg("request_demo_failed");
        setCdpSuccess(false);
      }
      setIsLoading(false);
    });
  };

  return (
    <Box id="channel-analytics">
      <h3 className="sub__head">
        {CheckValueLocale("audience", "", {}, intl)}
      </h3>
      <Card className="body">
        {props.cdpActive ? (
          <>
            <Card justifyContent="center">
              {cdpInfo.packageInfo ? (
                <Box>
                  <Typography>
                    {CheckValueLocale("company_info", "", {}, intl)}
                  </Typography>
                  <Card className="percentage">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={10}
                      p={2}
                    >
                      <Box>
                        <Typography>
                          {CheckValueLocale("package_name", "", {}, intl)}
                        </Typography>
                        <Typography>{cdpInfo.packageInfo.name}</Typography>
                      </Box>
                      <Box>
                        <Typography>
                          {CheckValueLocale("subscribe_start", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(cdpInfo.packageInfo.subscribe_start).split(
                              " ",
                            )[0]
                          }
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          {CheckValueLocale("subscribe_end", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(cdpInfo.packageInfo.subscribe_end).split(
                              " ",
                            )[0]
                          }
                        </Typography>
                      </Box>
                    </Stack>
                  </Card>
                </Box>
              ) : (
                <Typography>
                  {CheckValueLocale("channel_analytics_demo", "", {}, intl)}
                </Typography>
              )}
            </Card>

            {handlelUserRoles("GENERAL", "CREATE_REQUEST") && (
              <Stack direction="row" justifyContent="flex-end">
                <LucButton
                  onClick={() => {
                    sendPackageUpgrade(cdpInfo.id);
                    setUpgradePackageRequest(true);
                  }}
                  id="settings-subscription-audience-package-btn"
                  loading={isLoadingUpgrade}
                >
                  {CheckValueLocale("request_upgrade", "", {}, intl)}
                </LucButton>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography>{CheckValueLocale("cdp_msg", "", {}, intl)}</Typography>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  requestChannelAnalDemo();
                }}
                id="settings-subscription-audience-package-btn"
              >
                {isLoading ? (
                  <span className="sending-request-btn-text">
                    <CircularProgress className="circular-progress-subscription circular-progress" />
                    {CheckValueLocale("sending_request", "", {}, intl)}
                  </span>
                ) : (
                  CheckValueLocale("request_demo", "", {}, intl)
                )}
              </Button>
            </Stack>
          </>
        )}
      </Card>

      {/* SnackBar Msgs */}

      {success != null ? (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span>
                  <br />
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_success", "", {}, intl)
                    : CheckValueLocale(successMsg, "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_failed", "", {}, intl)
                    : CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
      {cdpSuccess != null ? (
        <Snackbar
          open={cdpSuccess != null ? true : false}
          handleClose={() => {
            setCdpSuccess(null);
          }}
          severity={cdpSuccess ? "success" : "error"}
          message={
            cdpSuccess ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("request_demo_success", "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default AudiencePackage;
