import { useMutation } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useEditInteraction = () => {
  return useMutation({
    mutationFn: async ({ queryData }) => {
      await EngagementsController.editInteractions(queryData);
    },
  });
};
