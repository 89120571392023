import { useEffect, useState } from "react";
import Header from "../../../../Monitors/Components/header";
import { MonitorContainer } from "pages/Monitors/Components/sidebar/monitor.styles";
import CommentsMentionsBody from "./commentsMentionsBody";

/*---------------------------------------------------------*/

const CommentsMentionsPage = (props) => {
  const {
    widgetsName,
    dashboardDynamicData,
    monitorType,
    filterParams,
    setFilterParams,
    startDate,
    endDate,
    applyFilter,
    pageNumber,
    totalPage,
    setTotalPage,
    response,
  } = props;
  const metricsValues = dashboardDynamicData?.metrics_values;
  const [sortBy, setSortBy] = useState("newest");
  const sortByData = ["newest", "most_interactions"];

  useEffect(() => {
    if (filterParams["sort_by"] !== undefined) {
      setSortBy(filterParams["sort_by"]);
    } else {
      setSortBy("newest");
    }
  }, [filterParams["sort_by"]]);

  const handleChange = (event) => {
    setSortBy(event.target.value);
    setFilterParams({
      ...filterParams,
      sort_by: event.target.value,
    });
    applyFilter(
      startDate,
      endDate,
      {
        ...filterParams,
        sort_by: event.target.value,
      },
      pageNumber,
    );
  };

  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {widgetsName?.includes("top_posts_comments") && (
        <>
          {response?.eventData !== undefined && (
            <Header
              title="total_comments_mentions"
              titleValue={totalPage}
              sortByData={sortByData}
              sortBy={sortBy}
              handleChange={handleChange}
            />
          )}

          <CommentsMentionsBody
            {...props}
            setTotalPage={setTotalPage}
            totalPage={totalPage}
            metricsValues={metricsValues}
            monitorType={monitorType}
          />
        </>
      )}
    </MonitorContainer>
  );
};

export default CommentsMentionsPage;
