import React, { useEffect, useState } from "react";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import OverViewSection from "./overviewSection";
import AuthorAnalyticsSection from "./authorAnalyticsSection";
import { isEmptyArray } from "utils/helpers";
import TopicsSection from "../../../Components/topicsSection";

const EngagementsBody = (props) => {
  const [postsAnalyticsData, setPostsAnalyticsData] = useState([]);
  const [overViewData, setOverViewData] = useState([]);

  const [commentsVolume, setCommentsVolume] = useState([]);
  const [totalCommentsVolumeCount, setTotalCommentsVolumeCount] = useState([]);
  // _____________________________________________
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );
  // _____________________________________________

  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );

  //__________________

  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  // _____________________________________________

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);

  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  // _____________________________________________
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );

  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);

  // ___________________________________________________________
  const [topCities, setTopCities] = useState([]);
  const [topCitiesPreLoader, setTopCitiesPreLoader] = useState([]);
  // ___________________________________________________________
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);

  // ___________________________________________________________
  const [genderAgeDistribution, setGenderAgeDistribution] = useState([]);
  const [genderAgeDistributionPreLoader, setGenderAgeDistributionPreLoader] =
    useState([]);

  // ___________________________________________________________
  const [topAuthors, setTopAuthors] = useState([]);
  const [topAuthorsPreLoader, setTopAuthorsPreLoader] = useState([]);
  // ___________________________________________________________
  const [commentsMentions, setCommentsMentions] = useState([]);
  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );
  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  const childProps = {
    associatedTopicsPreLoader,
    setAssociatedTopicsPreLoader,
    associatedTopics,
    setAssociatedTopics,
    commentsVolumePreLoader,
    setCommentsVolumePreLoader,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    topHashtags,
    setTopHashtags,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topKeywords,
    setTopKeywords,
    neutralArray,
    setNeutralArray,
    positiveArray,
    setPositiveArray,
    negativeArray,
    setNegativeArray,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    commentsInteractionsLegend,
    setCommentsInteractionsLegend,
    commentsInteractionsPreLoader,
    setCommentsInteractionsPreLoader,
    commentsInteractions,
    setCommentsInteractions,
    totalCommentsVolumeCount,
    setTotalCommentsVolumeCount,
    commentsVolume,
    setCommentsVolume,
    commentsMentionsPreloader,
    setCommentsMentionsPreloader,
    commentsMentions,
    setCommentsMentions,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    topCountries,
    setTopCountries,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCities,
    setTopCities,
    dialectsPreLoader,
    setDialectsPreLoader,
    subDialects,
    setSubDialects,
    dialects,
    setDialects,
    topAuthorsPreLoader,
    setTopAuthorsPreLoader,
    topAuthors,
    setTopAuthors,
    genderAgeDistributionPreLoader,
    setGenderAgeDistributionPreLoader,
    genderAgeDistribution,
    setGenderAgeDistribution,
    themeTrend,
    setThemeTrend,
    themeAnalysis,
    setThemeAnalysis,
    themeTrendPreLoader,
    setThemeTrendPreLoader,
    themeAnalysisPreLoader,
    setThemeAnalysisPreLoader,
  };

  useEffect(() => {
    if (!isEmptyArray(postsAnalyticsData) && !isEmptyArray(overViewData)) {
      props.setExcelSheetData(overViewData.concat(postsAnalyticsData));
    }
  }, [overViewData, postsAnalyticsData]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      commentsMentionsPreloader,
      topCountriesPreLoader,
      topCitiesPreLoader,
      dialectsPreLoader,
      associatedTopicsPreLoader,
      commentsVolumePreLoader,
      topHashtagsPreLoader,
      topKeywordsPreLoader,
      sentimentAnalysisPreLoader,
      commentsInteractionsPreLoader,
      topAuthorsPreLoader,
      genderAgeDistributionPreLoader,
      sentimentAnalysisPreLoader,
      commentsInteractionsPreLoader,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      topAuthors,
      commentsMentions,
      topCountries,
      topCities,
      subDialects,
      dialects,
      associatedTopics,
      topHashtags,
      topKeywords,
      neutralArray,
      positiveArray,
      negativeArray,
      sentimentAnalysis,
      commentsInteractionsLegend,
      commentsInteractions,
      totalCommentsVolumeCount,
      commentsVolume,
      genderAgeDistribution,
    );
  }, [
    topAuthors,
    commentsMentions,
    topCountries,
    topCities,
    subDialects,
    dialects,
    associatedTopics,
    topHashtags,
    topKeywords,
    neutralArray,
    positiveArray,
    negativeArray,
    sentimentAnalysis,
    commentsInteractionsLegend,
    commentsInteractions,
    totalCommentsVolumeCount,
    commentsVolume,
    genderAgeDistribution,
    commentsMentionsPreloader,
    topCountriesPreLoader,
    topCitiesPreLoader,
    dialectsPreLoader,
    associatedTopicsPreLoader,
    commentsVolumePreLoader,
    topHashtagsPreLoader,
    topKeywordsPreLoader,
    sentimentAnalysisPreLoader,
    commentsInteractionsPreLoader,
    topAuthorsPreLoader,
    genderAgeDistributionPreLoader,
  ]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      commentsMentionsPreloader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([commentsMentions]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    commentsMentions,
    commentsMentionsPreloader,
    props.preLoaderTrackerDataSources,
  ]);

  const instagramIcon = (
    <span className="topics-twitter-icon">
      <img src={publicInstagram} className="icon-after-title instagram" />
    </span>
  );
  return (
    <>
      <OverViewSection
        instagramIcon={instagramIcon}
        setOverViewData={setOverViewData}
        {...props}
        {...childProps}
      />
      <AuthorAnalyticsSection
        instagramIcon={instagramIcon}
        setPostsAnalyticsData={setPostsAnalyticsData}
        {...props}
        {...childProps}
      />
      <TopicsSection iconToDisplay={instagramIcon} isInstagram {...props} />
    </>
  );
};

export default EngagementsBody;
