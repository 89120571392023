import { Parser, grammar } from "shared/parser/parser";

// Converted Input Rule - Parser [Advanced]
export const convertedInputRule = (input_rule) => {
  let converted_input_rule = input_rule
    .replace(/ AND /g, "&&")
    .replace(/ and /g, "&&")
    .replace(/ OR /g, "||")
    .replace(/ or /g, "||")
    .replace(/ NOT /g, "~")
    .replace(/ not /g, "~")
    .replace(/"/g, '"')
    .replace(/'/g, '"')
    .replace(/'/g, " ")
    .replace(/\u201D/g, '"')
    .replace(/\u201C/g, '"')
    .replace(/\u2018/g, '"')
    .replace(/\u2019/g, '"')
    .replace(/[:+±§!$%*=\[\]{}،<>؟?]/g, " ")
    .replace(/ +/g, " ")
    .replace(/NOT /g, "~")
    .trim();

  return converted_input_rule;
};

// Output from - Parser [Advanced]
export const advancedParser = (converted_input_rule) => {
  let parser = new Parser(grammar),
    pre_output = parser.parse(converted_input_rule);

  let output = [];
  for (let i = 0; i < pre_output.length; i++) {
    let processed_output = pre_output[i].replace(/\(/g, "");
    if (processed_output !== "") {
      output.push(processed_output);
    }
  }

  return output;
};
