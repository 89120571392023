import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";

const FormErrorPassword = ({ stats, errors, errorField }) => {
  const intl = useIntl();
  const errorsList = [
    "chars_num_err",
    "uppercase_err",
    "lowercase_err",
    "number_err",
  ];
  return (
    <Box className="password-errors-box">
      <Box component="p" className="password-errors-heading">
        {" "}
        {CheckValueLocale("password_must_contain", "", {}, intl)}{" "}
      </Box>
      {errorsList?.map((err) => (
        <Box component="span" className="password-error">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={`
            ${
              errorField && errors?.includes(err)
                ? "error-fail"
                : errors?.includes(err) || stats?.password?.length === 0
                  ? ""
                  : "error-check"
            }`}
          />
          {CheckValueLocale(err, "", {}, intl)}
        </Box>
      ))}
      {errorField ? (
        <Box component="p" className="error-text-msg main-error">
          {" "}
          {CheckValueLocale(errorField, "", {}, intl)}{" "}
        </Box>
      ) : null}
    </Box>
  );
};
export default FormErrorPassword;
