import { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";

const CreateSurveyModal = (props) => {
  const intl = useIntl();
  const [surveyName, setSurveyName] = useState("");
  const [showError, setShowError] = useState(false);
  const [nameLengthFlag, setNameLengthFlag] = useState(false);

  const [open, setOpen] = useState(props.openCreateSurveyModal);

  const handleClose = () => {
    props.handleOpenCreateSurvey(false);
  };

  const handleSurveyName = (e) => {
    setSurveyName(e.target.value);
    props?.isDuplicateSurvey && props?.setDuplicateSurveyName(e?.target?.value);
    if (e.target.value.length > 50) {
      setNameLengthFlag(true);
    } else {
      setNameLengthFlag(false);
    }
  };

  const modalBody = (
    <Box className="survey-dialog">
      <Box className="survey-dialog__field">
        <Typography className="body-input-label show-red-icon">
          {CheckValueLocale("survey_name", "", {}, intl)}
        </Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={
            props?.isDuplicateSurvey ? props?.duplicateSurveyName : surveyName
          }
          placeholder={CheckValueLocale(
            "please_enter_your_survey_name",
            "",
            {},
            intl,
          )}
          onChange={handleSurveyName}
          className={showError && "modal-field_style"}
        />
        {showError && (
          <Box className="err-msg">
            {CheckValueLocale("required_field", "", {}, intl)}
          </Box>
        )}
        {nameLengthFlag && (
          <Box className="err-msg">
            {CheckValueLocale("sruvey_title_limit", "", {}, intl)}
          </Box>
        )}
      </Box>
    </Box>
  );
  const handleCreateSurvey = () => {
    if (
      (surveyName === "" && !props?.isDuplicateSurvey) ||
      props?.duplicateSurveyName === ""
    ) {
      setShowError(true);
      return;
    }
    props.handleCreateSurvey(surveyName, false);
  };
  return (
    <PopupModal
      title={CheckValueLocale(
        props?.isDuplicateSurvey ? "duplicate_survey" : "create_survey",
        "",
        {},
        intl,
      )}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale(
        props?.isDuplicateSurvey ? "survey_duplicated" : "create",
        "",
        {},
        intl,
      )}
      open={open}
      close={handleClose}
      accept={handleCreateSurvey}
      addClasses="model-buttons create-survey-content"
      disabled={nameLengthFlag || props?.buttonLoading}
      isRightBtnLoading={props?.buttonLoading}
      id="survey-create-btn"
      minWidth="600px"
    />
  );
};
export default CreateSurveyModal;
