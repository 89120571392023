// *******COMMON FILE,Any change in this file will have effect on many pages
// Please Don't Change, delete or update in this file without perrmission
// data sent in form of array or object with name and value

import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Box } from "@mui/material";
import FetchingData from "components/fetchingData/fetchingData";
// Pie
import PieChart from "../echarts/pieChart.js";
import NestedPieChart from "../echarts/NestedPieChart .js";
// preloader
import WidgetHeader from "./widgetHeader.js";
import { Checkbox } from "@mui/material";
import NoDataFound from "components/no-Data/noDataFound";
import { CheckValueLocale, pieChartSort } from "utils/helpers/index.js";

// const checkValue = (data) => {
//   // checking sum of value should be more then 0
//   return data.reduce((total, current) => (total += current.value), 0) > 0;
// };

const defaultColor = [
  "#003D59",
  "#009BDF",
  "#45B9E9",
  "#97D7F3",
  "#95C5EB",
  "#B1D3F0",
];

const sentimentColors = {
  Positive: "#57C242",
  Neutral: "#F9C92E",
  Negative: "#FD0F47",
  محايد: "#F9C92E",
  سلبي: "#FD0F47",
  إيجابي: "#57C242",
};
//

const npsColors = {
  passive: "#F5B819",
  detractors: "#ED1846",
  promoters: "#9FC653",
  Passive: "#F5B819",
  Detractors: "#ED1846",
  Promoters: "#9FC653",
  محايد: "#F5B819",
  المنتقدين: "#ED1846",
  المروجين: "#9FC653",
};

const shareOfVoiceTopicsColors = [
  "#1892F2",
  "#FB5C77",
  "#F7B94D",
  "#FEDC5F",
  "#AF8AEC",
  "#00D7B5",
  "#1C404E",
  "#EC8A92",
];

const categoriesColors = {
  "مواقع التواصل الاجتماعي": "#003D59",
  "Social Media": "#003D59",
  "social media": "#003D59",
  "social Media": "#003D59",
  "Social media": "#003D59",
  "وسائل التواصل الاجتماعي": "#003D59",
  Chats: "#B7E8FE",
  chats: "#B7E8FE",
  المحادثات: "#B7E8FE",
  Emails: "#45B9E9",
  emails: "#45B9E9",
  "البريد الإلكترونى": "#45B9E9",
  Ratings: "#C3DFF5",
  التقييم: "#C3DFF5",
  Calls: "#CFF0FF",
  calls: "#CFF0FF",
  الاتصالات: "#CFF0FF",
};

const channelsColors = {
  Twitter: "#003D59",
  twitter: "#003D59",
  تويتر: "#003D59",
  "x platform": "#003D59",
  "X Platform": "#003D59",
  "منصة إكس": "#003D59",
  Intercom: "#B7E8FE",
  intercom: "#B7E8FE",
  إنتركوم: "#B7E8FE",
  Gmail: "#97D7F3",
  gmail: "#97D7F3",
  جيميل: "#97D7F3",
  Facebook: "#009BDF",
  facebook: "#009BDF",
  فيسبوك: "#009BDF",
  Instagram: "#45B9E9",
  instagram: "#45B9E9",
  إنستقرام: "#45B9E9",
  Whatsapp: "#38bd6a",
  whatsapp: "#38bd6a",
  "واتس آب": "#38bd6a",
  googlemybusiness: "#C3DFF5",
  "Google My Business": "#C3DFF5",
  "جوجل التجاري": "#C3DFF5",
  linkedin: "#CFF0FF",
  Linkedin: "#CFF0FF",
  "لينكد ان": "#CFF0FF",
  "فيس بوك": "#009BDF",
  انستقرام: "#45B9E9",
  Genesys: "#CFF0FF",
  genesys: "#CFF0FF",
  جينيسيز: "#CFF0FF",
  tiktok: "#C3E1F8",
  TikTok: "#C3E1F8",
  Tiktok: "#C3E1F8",
  تيكتوك: "#C3E1F8",
  "تيك توك": "#C3E1F8",
};

const gendersColors = {
  Male: "#009BDF",
  male: "#009BDF",
  ذكور: "#009BDF",
  Female: "#F680BC",
  female: "#F680BC",
  اناث: "#F680BC",
  Unknown: "#6D737A",
  unknown: "#6D737A",
  Undefined: "#6D737A",
  undefined: "#6D737A",
  "غير معرف": "#6D737A",
  "غير معروف": "#6D737A",
};
const dialectsColors = {
  Saudi: "#189C53",
  saudi: "#189C53",
  سعودي: "#189C53",
  white: "#003D59",
  "common/un-classified": "#003D59",
  "Common/un-classified": "#003D59",
  "بيضاء/ غير معرف": "#003D59",
  "Modern Arabic": "#717D7E",
  "modern arabic": "#717D7E",
  "Modern arabic": "#717D7E",
  "modern Arabic": "#717D7E",
  "modern ar": "#717D7E",
  "لهجة عامة": "#717D7E",
  فصيحة: "#717D7E",
  Egyptian: "#B8961F",
  egyptian: "#B8961F",
  مصرية: "#B8961F",
  Iraqi: "#A03500",
  iraqi: "#A03500",
  عراقية: "#A03500",
  Shami: "#894BA2",
  shami: "#894BA2",
  شامية: "#894BA2",
  khaleji: "#2785C3",
  Khaleji: "#2785C3",
  خليجية: "#2785C3",
  Maghrebi: "#ED452E",
  maghrebi: "#ED452E",
  مغربية: "#ED452E",
};

const groupedSaudiSubDialectsColors = {
  "najdi-SA": "#52D78B",
  "najdi-sa": "#52D78B",
  "hijaze-SA": "#52D78B",
  "hijaze-sa": "#52D78B",
  "other-SA": "#52D78B",
  "other-sa": "#52D78B",
};
const groupedEgyptianSubDialectsColors = {
  egyptian: "#F5D12F",
  Egyptian: "#F5D12F",
};
const groupedIraqiSubDialectsColors = {
  iraqi: "#DE7627",
  Iraqi: "#DE7627",
};
const groupedShamiSubDialectsColors = {
  lebanese: "#C49AD4",
  syrian: "#C49AD4",
  palestinian: "#C49AD4",
};
const groupedKhalejiSubDialectsColors = {
  kuwaiti: "#83C0EB",
  bahrani: "#83C0EB",
  emirati: "#83C0EB",
};
const groupedMaghrebiSubDialectsColors = {
  moroccan: "#F39488",
  algerian: "#F39488",
  libyan: "#F39488",
};
const groupedWhiteSubDialectsColors = {
  white: "#71BECC",
};
const groupedModernArabicSubDialectsColors = {
  "modern ar": "#AAB7B8",
};

const subDialectsColors = {
  ...groupedSaudiSubDialectsColors,
  ...groupedEgyptianSubDialectsColors,
  ...groupedIraqiSubDialectsColors,
  ...groupedShamiSubDialectsColors,
  ...groupedKhalejiSubDialectsColors,
  ...groupedMaghrebiSubDialectsColors,
  ...groupedWhiteSubDialectsColors,
  ...groupedModernArabicSubDialectsColors,
};

const dialectsNameMapper = {
  Saudi: "saudi",
  saudi: "saudi",
  سعودي: "saudi",
  white: "white",
  "common/un-classified": "white",
  "Common/un-classified": "white",
  "بيضاء/ غير معرف": "white",
  "Modern Arabic": "ma",
  "modern arabic": "ma",
  "Modern arabic": "ma",
  "modern Arabic": "ma",
  "modern ar": "ma",
  "لهجة عامة": "ma",
  فصيحة: "ma",
  Egyptian: "egyptian",
  egyptian: "egyptian",
  مصرية: "egyptian",
  Iraqi: "iraqi",
  iraqi: "iraqi",
  عراقية: "iraqi",
  Shami: "shami",
  shami: "shami",
  شامية: "shami",
  khaleji: "khaleji",
  خليجية: "khaleji",
  Maghrebi: "maghrebi",
  maghrebi: "maghrebi",
};

const dialectsMapper = {
  saudi: groupedSaudiSubDialectsColors,
  white: groupedWhiteSubDialectsColors,
  ma: groupedModernArabicSubDialectsColors,
  egyptian: groupedEgyptianSubDialectsColors,
  iraqi: groupedIraqiSubDialectsColors,
  shami: groupedShamiSubDialectsColors,
  khaleji: groupedKhalejiSubDialectsColors,
  maghrebi: groupedMaghrebiSubDialectsColors,
};

/**
 * sortSubDialectsBasedOnDialects takes two arrays of dialects and subDialects and sort the subDialects based on the dialects array
 * @param {Array} dialects [{name:name1,value:..., name:name2,value:...}]
 * @param {Array} subDialects [{name:name2,value:..., name:name1,value:...}]
 * @returns {Array} sortedSubDialects [{name:name1,value:..., name:name2,value:...}]
 */
const sortSubDialectsBasedOnDialects = (dialects, subDialects) => {
  try {
    const sortedSubDialects = [];
    const visitedSubDialects = new Set();
    // loop over the dialects and sort get all the subDialects that are in the same group of the dialect
    dialects?.forEach((dialect) => {
      const dialectName = dialect?.name;
      const dialectNameMapper = dialectsNameMapper[dialectName];
      const subDialectGroup = dialectsMapper[dialectNameMapper];
      if (subDialectGroup) {
        // select the subDialects that are in the subDialectGroup
        const subDialectsInGroup =
          subDialects?.filter((subDialect) => {
            if (Object.keys(subDialectGroup).includes(subDialect?.name)) {
              visitedSubDialects.add(subDialect?.name);
              return true;
            }
            return false;
          }) || [];
        sortedSubDialects.push(...subDialectsInGroup);
      }
    });
    // add the subDialects that are not in any group
    const subDialectsNotInGroup = subDialects?.filter(
      (subDialect) => !visitedSubDialects.has(subDialect?.name),
    );
    sortedSubDialects.push(...subDialectsNotInGroup, subDialectsNotInGroup);
    // return a unique array of subDialects names
    return sortedSubDialects.filter(
      (subDialect, index, self) =>
        self?.findIndex((s) => s?.name === subDialect?.name) === index,
    );
  } catch (err) {
    return subDialects;
  }
};

const countriesColors = {
  "Saudi Arabia": "#009BDF",
  "saudi arabia": "#009BDF",
  "Saudi arabia": "#009BDF",
  "saudi Arabia": "#009BDF",
  Egypt: "#003D59",
  egypt: "#003D59",
  "United States": "#717D7E",
  "united states": "#717D7E",
  "United states": "#717D7E",
  "united States": "#717D7E",
  Oman: "#B8961F",
  oman: "#B8961F",
  Canada: "#A03500",
  canada: "#A03500",
  India: "#894BA2",
  india: "#894BA2",
  Bermuda: "#2785C3",
  bermuda: "#2785C3",
  "United Kingdom": "#ED452E",
  "united eingdom": "#ED452E",
  "United kingdom": "#ED452E",
  "united Kingdom": "#ED452E",
  Afghanistan: "#ED452E",
  afghanistan: "#ED452E",
  Finland: "#ED452E",
  finland: "#ED452E",
};

const PieChartWidget = (props) => {
  const intl = useIntl();

  const getTop5Labels = (data) => {
    let newData = [];
    let otherValue = 0;
    [...data].forEach((item) => {
      if (
        item?.name === "Others" ||
        item?.name === "others" ||
        item?.name === "أخرى"
      ) {
        otherValue += +item?.value;
      } else {
        newData?.push(item);
      }
    });
    newData?.sort((a, b) => b.value - a.value);
    let firstFive = newData?.slice(0, 5);
    newData?.slice(5)?.every((item) => (otherValue += +item.value));
    firstFive?.push({
      name: CheckValueLocale("other", "", {}, intl),
      value: otherValue,
    });

    return firstFive;
  };

  let categoriesColorsList = [];
  let subDialectsData = props?.subData;
  if (props?.title === "dialects_subdialects" || props?.isDialectsSubDialects) {
    subDialectsData = sortSubDialectsBasedOnDialects(
      props?.data,
      props?.subData,
    );
  }
  if (props.title === "categories") {
    props?.data?.map((item) => {
      let name = item?.name;
      categoriesColorsList?.push(categoriesColors[name]);
    });
  }
  let npsList = [];
  if (props.title === "nps_tradmark") {
    props?.data?.map((item) => {
      let name = item?.name?.toLowerCase();
      npsList.push(npsColors[name]);
    });
  }

  let channelsColorsList = [];
  if (props.title === "channels") {
    props.data.map((item) => {
      let name = item.name;
      channelsColorsList.push(channelsColors[name]);
    });
  }

  let gendersColorsList = [];
  if (
    props.title === "genders" ||
    props.title === "gender_distribution" ||
    props.title === "genders_distribution"
  ) {
    pieChartSort(props.data).map((item) => {
      let name = item.name;
      gendersColorsList.push(gendersColors[name]);
    });
  }
  let dialectsColorsList = [];
  let subDialectsColorsList = [];
  if (props?.title === "dialects_subdialects" || props?.isDialectsSubDialects) {
    props?.data?.map((item, index) => {
      let name = item?.name;
      dialectsColorsList?.push(dialectsColors[name]);
    });
    subDialectsData?.map((item, index) => {
      let name = item?.name;
      subDialectsColorsList.push(subDialectsColors[name]);
    });
  }
  let countriesColorsList = [];
  if (props.title === "countries") {
    props?.data?.map((item) => {
      let name = item?.name;
      countriesColorsList?.push(countriesColors[name]);
    });
  }
  let legend = [],
    seriesData = props.data,
    color =
      props.title === "categories"
        ? categoriesColorsList
        : props.title === "channels"
          ? channelsColorsList
          : props.title === "genders" ||
              props.title === "gender_distribution" ||
              props.title === "genders_distribution"
            ? gendersColorsList
            : props?.title === "dialects_subdialects" ||
                props?.isDialectsSubDialects
              ? dialectsColorsList
              : props.title === "share_of_voice"
                ? shareOfVoiceTopicsColors
                : props.title === "nps_tradmark"
                  ? npsList
                  : props.color || defaultColor,
    subColor =
      props?.title === "dialects_subdialects" || props?.isDialectsSubDialects
        ? subDialectsColorsList
        : props?.color || defaultColor,
    haveData = true;
  let subSeriesData,
    subLegend = [];
  if (props?.title === "dialects_subdialects" || props?.isDialectsSubDialects) {
    subSeriesData = subDialectsData;
  }

  if (props?.data?.length > 0 && !props?.loading) {
    props.data?.map((obj) => {
      if (
        !(
          obj?.name === null ||
          props?.flagTitle === "surveyTitle" ||
          props?.title === "top_countries" ||
          props?.title === "countries" ||
          props?.title === "top_cities" ||
          props?.title === "cities" ||
          props?.title === "top_languages"
        )
      ) {
        obj.name = props?.disableLocales
          ? obj?.name
          : CheckValueLocale(obj?.name?.toLowerCase(), "", {}, intl);
      }
    });
    legend = props?.data?.map((obj) =>
      obj.name === null || obj.value == 0
        ? null
        : props?.disableLocales
          ? obj?.name
          : CheckValueLocale(obj?.name?.toLowerCase(), "", {}, intl),
    );
    // sort the data for showing
    if (
      props.title !== "dialects_subdialects" &&
      !props?.isDialectsSubDialects &&
      props?.title !== "nps_tradmark"
    ) {
      seriesData?.sort((left, right) => right.value - left.value);
    }

    if (
      props.chartType === "gender" ||
      props.title === "gender_distribution" ||
      props.title === "genders_distribution"
    ) {
      seriesData.map((obj) => {
        return (obj.name =
          obj.name === null ? null : CheckValueLocale(obj.name, "", {}, intl));
      });

      seriesData = pieChartSort(seriesData);
    } else {
      legend = props.data.map((obj) =>
        obj.name === null || obj.value == 0
          ? null
          : props?.disableLocales
            ? obj?.name
            : CheckValueLocale(obj.name, "", {}, intl),
      );

      if (
        props?.data?.length > 5 &&
        props?.title !== "dialects_subdialects" &&
        !props?.isDialectsSubDialects &&
        props?.title !== "channels" &&
        props?.title !== "share_of_voice"
      ) {
        seriesData = getTop5Labels(props?.data);
      }
    }
    legend = seriesData.map((obj) =>
      obj.name === null || obj.value == 0
        ? null
        : CheckValueLocale(obj.name, "", {}, intl),
    );

    if (
      props?.title === "dialects_subdialects" ||
      props?.isDialectsSubDialects
    ) {
      subLegend = subSeriesData?.map((obj) =>
        obj.name === null || obj.value == 0
          ? null
          : CheckValueLocale(obj.name, "", {}, intl),
      );
    }

    // re-arrange items for sentiment
    if (
      props.chartType === "sentiment" ||
      props.title === "sentiment_analysis"
    ) {
      color = props.data.map((item) => sentimentColors[item.name]);
    }
  }

  let valueExist = seriesData?.every((val) => val.value === 0);
  let nameExist = seriesData?.every((val) => val.name === null);

  const [subDialectsChecked, setSubDialectsChecked] = useState(false);
  const handleCheckBoxChange = (event) => {
    setSubDialectsChecked(event.target.checked);
  };

  //remove data that have zero value
  let filtredData = seriesData?.filter((el) => el.value != 0);
  filtredData = filtredData.map((obj) => ({
    ...obj,
    name: props?.disableLocales
      ? obj?.name
      : CheckValueLocale(obj?.name, "", {}, intl),
  }));
  return (
    <div
      className={props.customStyle ? props.customStyle : "chart"}
      id={
        props?.isCustomDashboard
          ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
          : props?.title
      }
    >
      <WidgetHeader
        iconAfterTitle={props.iconAfterTitle}
        title={props.title}
        showAI={props.showAI}
        showDownloadIcon={props.showDownloadIcon}
        chartId={
          props?.isCustomDashboard
            ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
            : props?.title
        }
        totalLabel={
          _.isEqual(
            props.pieChartPreLoaderDataSources?.sort(),
            props.preLoaderTrackerDataSources?.sort(),
          ) !== true
            ? undefined
            : props.totalLabel
        }
        totalValue={
          _.isEqual(
            props.pieChartPreLoaderDataSources?.sort(),
            props.preLoaderTrackerDataSources?.sort(),
          ) !== true
            ? undefined
            : props.totalValue
        }
        bigImg={props.bigImg}
        titleToolTip={props.titleToolTip}
        showReportIcon={props.showReportIcon}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
      />
      {(props.title === "dialects_subdialects" ||
        props?.isDialectsSubDialects) &&
      !(
        _.isEqual(
          props.pieChartPreLoaderDataSources.sort(),
          props.preLoaderTrackerDataSources.sort(),
        ) !== true
      ) &&
      subSeriesData?.length > 0 ? (
        //note we will hide sub-dialects in CA untill BE fix it
        <div
          className={`sub-dialect-checkbox ${props?.isCustomDashboard ? "custom-dashboard" : ""}`}
        >
          <Checkbox
            checked={subDialectsChecked}
            onChange={handleCheckBoxChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <span>{CheckValueLocale("show_sub_dialects", "", {}, intl)}</span>
        </div>
      ) : null}
      {_.isEqual(
        props.pieChartPreLoaderDataSources?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : seriesData?.length === 0 ||
        seriesData === [] ||
        seriesData === undefined ||
        props.data === undefined ||
        valueExist ||
        nameExist ? (
        <NoDataFound />
      ) : props?.title !== "dialects_subdialects" &&
        !props?.isDialectsSubDialects ? (
        <PieChart
          legend={legend}
          legendPosition={props.legendPosition}
          seriesData={filtredData} //[{name:...,value:...},{name:...,value:...}]
          color={color}
          haveData={true}
          type={props.subTitle}
          channelId={props.channelId}
          solidPie={props.solidPie}
          showLabel={props.showLabel}
          inspectorName={props.inspectorName}
          handleOpenQuickInspect={props.handleOpenQuickInspect}
          isChannelAccounts={props.isChannelAccounts}
        />
      ) : (
        <NestedPieChart
          legend={legend}
          subLegend={subLegend}
          legendPosition={props.legendPosition}
          seriesData={seriesData}
          subSeriesData={subSeriesData} //[{name:...,value:...},{name:...,value:...}]
          color={color}
          subColor={subColor}
          haveData={true}
          type={props.subTitle}
          channelId={props.channelId}
          showSubDialects={subDialectsChecked}
        />
      )}
    </div>
  );
};

export default PieChartWidget;
