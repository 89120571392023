import moment from "moment";
import { CheckValueLocale, isEmptyValue } from "utils/helpers";
import {
  handleAuthorActivity,
  handleXlsData,
  handleSortedCase,
} from "../excelCommonFn";
import { displayRemainingTime } from "pages/engagements/components/engagementAnalytics/sharedFunctions/datesUtils";

export const engagementAnalyticsInbox = (response, lang) => {
  if (isEmptyValue(response)) {
    return [];
  } else {
    let routings = [],
      dataSources = [],
      completionReasons = [],
      completedByTeams = [],
      avgSla = [],
      engagOverView = [],
      engagVolume = [],
      engagCompletedVolume = [],
      engagersActivity = [],
      agentsPerformance = [],
      allSheetData = [];
    Object.entries(response)?.forEach(([key, value]) => {
      switch (key) {
        case "avgSla":
          const { prevDate, currDate, data } = value;
          const cDate = lang?.[currDate]
            ? lang?.[currDate]
            : currDate?.replace?.(" ", "");
          const currValText = lang?.avg_slas_curr_val?.replace?.(
            "{time}",
            cDate,
          );
          const prevValText = lang?.avg_slas_prev_val?.replace?.(
            "{time}",
            prevDate?.replace?.(" ", ""),
          );
          data?.map((i) => {
            avgSla.push({
              [lang.avg_slas_metrics]: lang[i?.name] ? lang[i?.name] : i?.name,
              [currValText]: handleShowRemainingTime(i?.currValue, lang),
              [prevValText]: handleShowRemainingTime(i?.prevValue, lang),
              [lang.avg_slas_change]: i?.percentage?.toFixed?.(2) + "%",
            });
          });
          allSheetData.push([avgSla, lang.avg_slas]);
          break;
        case "routings":
          value?.map((i) => {
            routings.push({
              [lang.routings_distribution_name]: i?.name,
              [lang.routings_distribution_val]: i?.value,
            });
          });
          allSheetData.push([routings, lang.engag_routings_distribution_xlx]);
          break;
        case "dataSources":
          value?.map((i) => {
            dataSources.push({
              [lang.engag_data_sources_name]: lang[i?.name]
                ? lang[i?.name]
                : i?.name,
              [lang.engag_data_sources_val]: i?.value,
            });
          });
          allSheetData.push([dataSources, lang.engag_data_sources]);
          break;
        case "completionReasons":
          value?.map((i) => {
            completionReasons.push({
              [lang.engag_completion_reasons_name]: lang[i?.name]
                ? lang[i?.name]
                : i?.name,
              [lang.engag_completion_reasons_val]: i?.value,
            });
          });
          allSheetData.push([completionReasons, lang.engag_completion_reasons]);
          break;
        case "completedByTeams":
          value?.map((i) => {
            completedByTeams.push({
              [lang.engag_completed_by_teams_name]: i?.name,
              [lang.engag_completed_by_teams_val]: i?.value,
            });
          });
          allSheetData.push([completedByTeams, lang.engag_completed_by_teams]);
          break;
        case "engagOverView":
          Object.keys(value)?.map((i) => {
            let overviewItemLang =
              i == "total_posts" ? lang["total_posts_engag"] : lang[i];
            let num = value[i];
            if (i === "avg_engag") {
              num = handleShowRemainingTime(
                displayRemainingTime(+(value?.[i] || 0)),
                lang,
              );
            }
            engagOverView.push({
              [lang.engag_metric]: overviewItemLang,
              [lang.xlsx_value]: num ? num : 0,
            });
          });
          allSheetData.push([engagOverView, lang.engag_overview]);
          break;
        case "engagVolume":
          value?.comments_mentions?.map((item, i) => {
            engagVolume.push({
              [lang.date_quick]: moment
                .unix(item?.name)
                .utc()
                .format("YYYY-MM-DD"),
              [lang.xlsx_engag_dm]: value?.direct_message?.[i]?.value,
              [lang.xlsx_engag_public]: item?.value,
            });
          });
          allSheetData.push([engagVolume, lang.engag_volume]);
          break;
        case "engagCompletedVolume":
          value?.comments_mentions?.map((item, i) => {
            engagCompletedVolume.push({
              [lang.date_quick]: moment
                .unix(item?.name)
                .utc()
                .format("YYYY-MM-DD"),
              [lang.xlsx_complete_engag_dm]: value?.direct_message?.[i]?.value,
              [lang.xlsx_complete_engag_public]: item?.value,
            });
          });
          allSheetData.push([
            engagCompletedVolume,
            lang.engag_completed_volume,
          ]);
          break;
        case "engagersActivity":
          handleAuthorActivity(value[0], value[1], value[2], lang)?.map((i) => {
            engagersActivity?.push({
              [lang.day]: i[0],
              [lang.hour_group]: i[1],
              [lang.no_of_activity]: i[2],
            });
          });
          allSheetData.push([engagersActivity, lang.engag_activity]);
          break;
        case "agentsPerformance":
          value?.map((i) => {
            agentsPerformance.push({
              [lang.engag_agents]: i?.name,
              [lang.engag_assigned]: i?.assignedValue,
              [lang.engag_completed]: i?.completedValue,
            });
          });
          allSheetData.push([agentsPerformance, lang.agents_performance]);
          break;
        default:
          break;
      }
    });
    return handleXlsData(allSheetData);
  }
};

const handleShowRemainingTime = (times = [], lang) => {
  const timeValue = times
    ?.map((time) => {
      const locale = "engag_time_" + time?.type;
      const timeLocale = lang?.[locale]?.replace("{time}", time?.value);
      return timeLocale;
    })
    ?.join(", ");
  return timeValue;
};
