import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeh, faFrown, faSmile } from "@fortawesome/free-solid-svg-icons";
import "./themesSubThemes.scss";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";

const ThemesSubThemes = (props) => {
  const intl = useIntl();
  let { userDetails } = props;
  const [sentimentIcon, setSentimentIcon] = useState("");
  const [sentimentLabel, setSentimentLabel] = useState("");
  const [sentimentColor, setSentimentColor] = useState("");

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });

  useEffect(() => {
    handleSentiment(userDetails?.sentiment);
  }, []);

  const handleSentiment = (type) => {
    let themeIcon, themeLabel;
    const icon = {
      negative: "negative",
      positive: "positive",
      neutral: "neutral",
      default: "none",
    };

    themeIcon = icon[type] || icon["default"];
    setSentimentIcon(themeIcon);

    const label = {
      negative: faFrown,
      positive: faSmile,
      neutral: faMeh,
      default: faMeh,
    };

    themeLabel = label[type] || label["default"];
    setSentimentLabel(themeLabel);

    const color = {
      negative: "#E50C35",
      positive: "#89BB2A",
      neutral: "#F9A700",
      default: "#80868c",
    };

    themeLabel = color[type] || color["default"];
    setSentimentColor(themeLabel);
  };

  return (
    <>
      <Box className="main-themes">
        <Box>
          <Box className="theme wedget-title">
            {CheckValueLocale("widget_category", "", {}, intl)}:
          </Box>
          <Box className="subtheme">
            {!isEmptyArray(userDetails?.main_themes) &&
            userDetails?.main_themes ? (
              userDetails?.main_themes
                ?.map((theme, i) => {
                  return CheckValueLocale(theme, "", {}, intl);
                })
                ?.join(", ")
            ) : (
              <Box className="subtheme">
                {CheckValueLocale("none", "", {}, intl)}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Divider orientation="vertical" className="vertical-line-list-header" />

      <Box className="main-themes" ml={1}>
        <Box>
          <Box className="theme wedget-title">
            {CheckValueLocale("themes", "", {}, intl)}:
          </Box>
          {!isEmptyArray(userDetails?.sub_themes) && userDetails?.sub_themes ? (
            userDetails?.sub_themes?.map((theme, i) => {
              const selectedTheme = themesOptions?.find(
                (item) => item?.value === theme,
              );

              return (
                <Box className="wedget-subtitle themes" key={i}>
                  <Box
                    className="box-color font"
                    style={{
                      backgroundColor: selectedTheme?.sub_theme_color,
                    }}
                  ></Box>
                  <Box className="subtheme" mx={1} key={i}>
                    {selectedTheme?.sub_theme_name}
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box className="subtheme">
              {CheckValueLocale("none", "", {}, intl)}
            </Box>
          )}
        </Box>
      </Box>
      <Divider orientation="vertical" className="vertical-line-list-header" />

      <Box className="main-themes white">
        <Box
          className="wedget-subtitle font white"
          style={{ color: sentimentColor }}
        >
          <FontAwesomeIcon icon={sentimentLabel} className="space" />
          {CheckValueLocale(sentimentIcon, "", {}, intl)}
        </Box>
      </Box>
      <Divider orientation="vertical" className="vertical-line-list-header" />
    </>
  );
};

export default ThemesSubThemes;
