import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import "./audienceAboutPage.scss";
import {
  emailIsValid,
  isOnlyNumbers,
  charForNumbers,
  checkSpecialCharExist,
  whiteSpaces,
  isValidPhone,
  isEmojis,
  specialCharSearch,
  CheckValueLocale,
} from "utils/helpers";
import SharedField from "./component/sharedField.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const AudienceAboutPage = (props) => {
  const intl = useIntl();
  let { userProfileData, updatedSavedValues, setUpdatedSavedValues } = props;
  let selectedValue = userProfileData?.data?.details;

  const [selectedWord, setSelectedWord] = useState({
    name: selectedValue?.name ? selectedValue?.name : [],
    phone_number: selectedValue?.phone_number
      ? selectedValue?.phone_number
      : [],
    email: selectedValue?.email ? selectedValue?.email : [],
    twitter: selectedValue?.twitter ? selectedValue?.twitter : [],
    facebook: selectedValue?.facebook ? selectedValue?.facebook : [],
    instagram: selectedValue?.instagram ? selectedValue?.instagram : [],
    intercom: selectedValue?.intercom ? selectedValue?.intercom : [],
    gmail: selectedValue?.gmail ? selectedValue?.gmail : [],
    whatsapp: selectedValue?.whatsapp ? selectedValue?.whatsapp : [],
    google_my_business: selectedValue?.google_my_business
      ? selectedValue?.google_my_business
      : [],
    twitter_dm: selectedValue?.twitter_dm ? selectedValue?.twitter_dm : [],
    facebook_dm: selectedValue?.facebook_dm ? selectedValue?.facebook_dm : [],
    instagram_dm: selectedValue?.instagram_dm
      ? selectedValue?.instagram_dm
      : [],
    tiktok: selectedValue?.tiktok ? selectedValue?.tiktok : [],
  });

  let removeTwitterDuplicate = selectedWord.twitter.concat(
    selectedWord.twitter_dm,
  );
  selectedWord.twitter = [
    ...new Map(removeTwitterDuplicate.map((v) => [v.value, v])).values(),
  ];

  let removeFacebbokDuplicate = selectedWord.facebook.concat(
    selectedWord.facebook_dm,
  );
  selectedWord.facebook = [
    ...new Map(removeFacebbokDuplicate.map((v) => [v.value, v])).values(),
  ];

  let removeInstgramDuplicate = selectedWord.instagram.concat(
    selectedWord.instagram_dm,
  );
  selectedWord.instagram = [
    ...new Map(removeInstgramDuplicate.map((v) => [v.value, v])).values(),
  ];

  const [userValues, setUserValues] = useState({
    name: "",
    phone_number: "",
    email: "",
    twitter: "",
    facebook: "",
    instagram: "",
    intercom: "",
    gmail: "",
    whatsapp: "",
    google_my_business: "",
    tiktok: "",
  });

  const [updatedError, setUpdatedError] = useState({
    name: "",
    phone_number: "",
    email: "",
    twitter: "",
    facebook: "",
    instagram: "",
    intercom: "",
    gmail: "",
    whatsapp: "",
    google_my_business: "",
    tiktok: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserValues({
      ...userValues,
      [name]: value,
    });
  };

  const deleteWords = (index, field, id) => {
    let currentValues = selectedWord[field];
    let updatedValues = {};
    let keptValue = currentValues[index];
    updatedValues[field] = currentValues.filter((item, key) => key !== index);
    setSelectedWord((filedsValues) => ({
      ...selectedWord,
      ...updatedValues,
    }));

    let repeated = -1;
    updatedSavedValues.data.forEach((item, index) => {
      if (
        item["field_name"] === field &&
        item["field_value"] === keptValue.value &&
        item["action"] === "add"
      ) {
        repeated = index;
      }
    });

    let values = updatedSavedValues.data;
    if (repeated > -1) {
      values = values.filter((item, i) => i != repeated);
    } else {
      values.push({
        field_value: keptValue?.value,
        field_name: field,
        action: "delete",
        id: id,
      });
    }
    setUpdatedSavedValues({ data: values });
  };

  const updateWordValues = (e, field, clickType) => {
    let value = e.target.value;
    if (
      (e.keyCode === 13 || (clickType === "btn" && value)) &&
      validateField(value, field)
    ) {
      let currentValues = selectedWord[field];
      let updatedValues = [];
      updatedValues = currentValues.push({ value: value.trim() });
      setSelectedWord({
        ...selectedWord,
        ...updatedValues,
      });

      let values = updatedSavedValues.data;
      values.push({
        field_value: value,
        field_name: field,
        action: "add",
      });

      setUpdatedSavedValues({ data: values });
      let resetValue = {};
      resetValue[field] = "";
      setUserValues({ ...userValues, ...resetValue });
    }
  };

  const validateField = (value, name) => {
    let errorMessage = {};
    let valueLength = value.length;
    // check if value is empty we return
    if (value.trim() === "") {
      errorMessage[name] = "";
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if value is duplicate
    let selectedValue = selectedWord[name].map((item) => {
      return item?.value;
    });
    if (selectedValue.includes(userValues[name].trim())) {
      errorMessage[name] = CheckValueLocale(name + "_duplicate", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if value is emoji
    if (isEmojis(value)) {
      errorMessage[name] = CheckValueLocale("emoji", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if value length for name > 40
    if (valueLength > 40 && name === "name") {
      errorMessage[name] = CheckValueLocale("name_char", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if value for name not numbers
    if (name === "name" && !isOnlyNumbers(value)) {
      errorMessage[name] = CheckValueLocale("name_format", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if value length > 30
    if (
      valueLength > 65 &&
      ["email", "facebook", "instagram", "gmail", "intercom"].includes(name)
    ) {
      errorMessage[name] = CheckValueLocale("_char", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    if (valueLength > 15 && name === "twitter") {
      errorMessage[name] = CheckValueLocale("twitter_char", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    if (
      (value.includes("@") && name === "twitter") ||
      (value.includes("@") && name === "instagram")
    ) {
      errorMessage[name] = CheckValueLocale(
        "wrong_char_insta_twitter",
        "",
        {},
        intl,
      );
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    if (
      (valueLength > 17 || valueLength < 7) &&
      (name === "phone_number" || name === "whatsapp")
    ) {
      errorMessage[name] = CheckValueLocale("wrong_phone", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if there's white spaces
    if (
      (name === "facebook" || name === "twitter" || name === "instagram") &&
      whiteSpaces(value)
    ) {
      errorMessage[name] = CheckValueLocale("white_spaces", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if there's special char
    if (!checkSpecialCharExist(value) && name === "facebook") {
      errorMessage[name] = CheckValueLocale("wrong_format", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    if (
      !specialCharSearch(value) &&
      (name === "twitter" || name === "instagram")
    ) {
      errorMessage[name] = CheckValueLocale("wrong_format", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check for only numbers without special char
    if (
      (name === "phone_number" || name === "whatsapp") &&
      charForNumbers(value)
    ) {
      errorMessage[name] = CheckValueLocale("wrong_lang", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if this valid phone number
    if (
      (name === "phone_number" || name === "whatsapp") &&
      isValidPhone(value)
    ) {
      errorMessage[name] = CheckValueLocale("wrong_lang", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    // check if this valid email
    if (
      (name === "email" || name === "gmail" || name === "intercom") &&
      emailIsValid(value)
    ) {
      errorMessage[name] = CheckValueLocale("wrong_email", "", {}, intl);
      setUpdatedError({ ...updatedError, ...errorMessage });
      return false;
    }
    errorMessage[name] = "";
    setUpdatedError({ ...updatedError, ...errorMessage });
    return true;
  };

  return (
    <Box className="interaction-page-list">
      <Box calssName="audience-warning" mb={4}>
        <Typography className="audience-main-title" varinat="h6">
          {CheckValueLocale("about", "", {}, intl)}
        </Typography>
      </Box>

      <Grid container spacing={4} mb={9}>
        <SharedField
          title="name_about"
          placHolder="add_name"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.name}
          updatedError={updatedError.name}
          userValues={userValues.name}
          onChange={handleChange}
          name="name"
        />

        <SharedField
          title="phone"
          placHolder="add_phone_number"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.phone_number}
          updatedError={updatedError.phone_number}
          userValues={userValues.phone_number}
          onChange={handleChange}
          name="phone_number"
        />

        <SharedField
          title="email"
          placHolder="add_email"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.email}
          updatedError={updatedError.email}
          userValues={userValues.email}
          onChange={handleChange}
          name="email"
        />
      </Grid>
      <Box calssName="audience-warning" mb={4}>
        <Typography className="audience-main-title" varinat="h6">
          {CheckValueLocale("social_media", "", {}, intl)}
        </Typography>
      </Box>
      <Grid container spacing={4} mb={9}>
        <SharedField
          title="twitter"
          placHolder="add_username"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.twitter}
          updatedError={updatedError.twitter}
          userValues={userValues.twitter}
          onChange={handleChange}
          name="twitter"
        />

        <SharedField
          title="facebook"
          placHolder="add_page"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.facebook}
          updatedError={updatedError.facebook}
          userValues={userValues.facebook}
          onChange={handleChange}
          name="facebook"
        />

        <SharedField
          title="instagram"
          placHolder="add_username"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.instagram}
          updatedError={updatedError.instagram}
          userValues={userValues.instagram}
          onChange={handleChange}
          name="instagram"
        />

        <SharedField
          title="intercom"
          placHolder="add_email"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.intercom}
          updatedError={updatedError.intercom}
          userValues={userValues.intercom}
          onChange={handleChange}
          name="intercom"
        />

        <SharedField
          title="gmail"
          placHolder="add_gmail"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.gmail}
          updatedError={updatedError.gmail}
          userValues={userValues.gmail}
          onChange={handleChange}
          name="gmail"
        />

        <SharedField
          title="whatsapp"
          placHolder="add_whatsapp"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.whatsapp}
          updatedError={updatedError.whatsapp}
          userValues={userValues.whatsapp}
          onChange={handleChange}
          name="whatsapp"
        />
        <SharedField
          title="tiktok"
          placHolder="add_tiktok"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord?.tiktok}
          updatedError={updatedError?.tiktok}
          userValues={userValues?.tiktok}
          onChange={handleChange}
          name="tiktok"
        />

        {/* <SharedField
          title="googlemybusiness"
          placHolder="add_gmail"
          deleteWords={deleteWords}
          updateWordValues={updateWordValues}
          selectedWord={selectedWord.google_my_business}
          updatedError={updatedError.google_my_business}
          userValues={userValues.google_my_business}
          onChange={handleChange}
          name="google_my_business"
        /> */}
      </Grid>
    </Box>
  );
};

export default AudienceAboutPage;
