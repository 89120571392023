import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBar from "components/snackBar";
import PuplishPopup from "./components/puplishPopup";
import SurveyModalFunctions from "./surveyModalFunctions";
import StepsButtons from "./components/stepsButtons";
import StepOne from "./components/surveyModalSteps/stepOne";
import StepTwo from "./components/surveyModalSteps/stepTwo";
import StepThree from "./components/surveyModalSteps/stepThree";

const PublishSurveyModal = (props) => {
  const {
    intl,
    open,
    copied,
    domainActive,
    surveyPanelList,
    surveyPanel,
    checkSwitch,
    value,
    stepThree,
    setStepThree,
    isSurveyPanelEnabled,
    error,
    setError,
    loading,
    selectedValues,
    setSelectedValues,
    snackBar,
    checkEmptyValue,
    stepTwo,
    handleCopy,
    handleSubmit,
    handleDomainClick,
    handleCloseModal,
    shareSurvey,
    handleChangeTabs,
    handleChange,
    handelSendRequest,
    downloadImg,
    getSurveyspanelList,
    SurveyspanelById,
    checkIsSurveyPage,
    checkIfLucidyaCustomDomain,
    submitSurveysSkip,
    styles,
    setDomainActive,
  } = SurveyModalFunctions(props);

  const modalBodySteps = (
    <>
      {!checkIsSurveyPage ? (
        props?.customDomainStepLoading ? (
          <Box className="share-survey-loading">
            <CircularProgress />
          </Box>
        ) : !stepTwo && !props?.isSurveyEnabled ? (
          <StepOne
            customDomain={props?.customDomain}
            customDomainStatus={props?.customDomainStatus}
            shareSurvey={shareSurvey}
            domainActive={domainActive}
            handleDomainClick={handleDomainClick}
            setIsLucidyaDomain={props?.setIsLucidyaDomain}
            handlelUserRoles={handlelUserRoles}
            styles={styles}
            setDomainActive={setDomainActive}
          />
        ) : !props?.isSkeipped &&
          stepTwo &&
          !isSurveyPanelEnabled &&
          !stepThree ? (
          <StepTwo
            isSurveyEnabled={props?.isSurveyEnabled}
            stepTwo={stepTwo}
            isSurveyPanelEnabled={isSurveyPanelEnabled}
            handleChange={handleChange}
            checkSwitch={checkSwitch}
            surveyPanelList={surveyPanelList}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            surveyPanel={surveyPanel}
            SurveyspanelById={SurveyspanelById}
            setError={setError}
          />
        ) : (
          <StepThree
            stepTwo={stepTwo}
            checkIsSurveyPage={checkIsSurveyPage}
            isSurveyPanelEnabled={isSurveyPanelEnabled}
            handleChange={handleChange}
            checkSwitch={checkSwitch}
            surveyPanelList={surveyPanelList}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            surveyPanel={surveyPanel}
            SurveyspanelById={SurveyspanelById}
            setError={setError}
            value={value}
            handleChangeTabs={handleChangeTabs}
            isSurveyEnabled={props?.isSurveyEnabled}
            checkIfLucidyaCustomDomain={checkIfLucidyaCustomDomain}
            surveyData={props?.surveyData}
            copied={copied}
            handleCopy={handleCopy}
            downloadImg={downloadImg}
          />
        )
      ) : (
        <StepThree
          stepTwo={stepTwo}
          checkIsSurveyPage={checkIsSurveyPage}
          isSurveyPanelEnabled={isSurveyPanelEnabled}
          handleChange={handleChange}
          checkSwitch={checkSwitch}
          surveyPanelList={surveyPanelList}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          surveyPanel={surveyPanel}
          SurveyspanelById={SurveyspanelById}
          setError={setError}
          value={value}
          handleChangeTabs={handleChangeTabs}
          isSurveyEnabled={props?.isSurveyEnabled}
          checkIfLucidyaCustomDomain={checkIfLucidyaCustomDomain}
          surveyData={props?.surveyData}
          copied={copied}
          handleCopy={handleCopy}
          downloadImg={downloadImg}
        />
      )}
      {!checkIsSurveyPage ? (
        <StepsButtons
          checkIsSurveyPage={checkIsSurveyPage}
          isSurveyEnabled={props?.isSurveyEnabled}
          customDomainStep={props?.customDomainStep}
          surveyData={props?.surveyData}
          isSurveyPanelEnabled={isSurveyPanelEnabled}
          checkSwitch={checkSwitch}
          setStepThree={setStepThree}
          checkEmptyValue={checkEmptyValue}
          error={error}
          stepThree={stepThree}
          loading={loading}
          handleCloseModal={handleCloseModal}
          open={open}
          acceptSendRequest={handelSendRequest}
          accept={handleSubmit}
          close={handleCloseModal}
          submitSurveysSkip={submitSurveysSkip}
          isSkeipped={props?.isSkeipped}
        />
      ) : null}
    </>
  );

  useEffect(() => {
    getSurveyspanelList();
    if (props?.isSurveyEnabled) {
      SurveyspanelById();
    }
  }, []);

  return (
    <>
      <PuplishPopup
        body={modalBodySteps}
        checkIsSurveyPage={checkIsSurveyPage}
        customDomainStep={props?.customDomainStep}
        surveyData={props?.surveyData}
        isSurveyPanelEnabled={isSurveyPanelEnabled}
        checkSwitch={checkSwitch}
        setStepThree={setStepThree}
        checkEmptyValue={checkEmptyValue}
        error={error}
        loading={loading}
        handleCloseModal={handleCloseModal}
        open={open}
        acceptSendRequest={handelSendRequest}
        accept={handleSubmit}
        close={handleCloseModal}
        disabledLeftBtn={!checkSwitch || checkEmptyValue ? true : null}
        disabled={
          domainActive == "" ||
          domainActive == undefined ||
          (props?.customDomain == "" && domainActive != 1)
        }
        classeName="publish-survey-modal"
        addClasses="survey-buttons"
      />
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
      />
    </>
  );
};
export default PublishSurveyModal;
