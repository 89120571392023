import { Typography, TableCell, TableHead, TableRow } from "@mui/material";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";

const BenchmarkTableHead = ({
  benchmarkHeadData,
  monitorPublicType,
  allMonitorData,
  intl,
}) => {
  const tableHeadCellData =
    benchmarkHeadData?.monitorData?.length > 0
      ? benchmarkHeadData?.monitorData?.map((x) => {
          return {
            monitor_name: x?.monitor_name,
            data_source_name: x?.data_source_name,
          };
        })
      : [];

  return (
    <TableHead>
      <TableRow className="body-tr-head">
        <TableCell
          className={
            monitorPublicType === "Monitor_vs_Monitors"
              ? "sticky width-title-header metrics-title-vs-monitor"
              : ""
          }
        >
          <Typography component="span">
            {CheckValueLocale("metrics", "", {}, intl)}
          </Typography>
        </TableCell>
        {tableHeadCellData?.map((val, index) => (
          <TableCell
            key={`metrics-head-${index}`}
            align={"center"}
            className={
              monitorPublicType === "Monitor_vs_Monitors"
                ? "width-title-header"
                : ""
            }
          >
            <Typography component="span">{val?.monitor_name}</Typography>
          </TableCell>
        ))}
      </TableRow>
      <TableRow className="body-tr-head">
        <TableCell
          className={
            monitorPublicType === "Monitor_vs_Monitors"
              ? "sticky width-title-header metrics-title-vs-monitor"
              : ""
          }
        >
          <Typography component="span">
            {CheckValueLocale("data_source", "", {}, intl)}
          </Typography>
        </TableCell>
        {tableHeadCellData?.map((val, index) => (
          <TableCell
            key={`metrics-head-${index}`}
            align={"center"}
            className={
              monitorPublicType === "Monitor_vs_Monitors"
                ? "width-title-header"
                : ""
            }
          >
            <Typography component="span">
              {" "}
              {getSocialIcon(val?.data_source_name)}{" "}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default BenchmarkTableHead;
