import { useQuery } from "@tanstack/react-query";
import LiveDashboardController from "services/controllers/liveDashboardController";
export const useMonitorWidgets = (queryData) => {
  return useQuery({
    queryKey: ["monitorWidgets", queryData?.monitor_id],
    queryFn: () => LiveDashboardController.getMonitorWidgets(queryData),
    enabled: !!queryData?.monitor_id,
    select: (data) => data?.data,
    retry: false,
  });
};
