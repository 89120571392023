import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Card, CardContent, Input, Typography } from "@mui/material";
import styles from "../commonStyle.module.css";

const NameQuestion = (props) => {
  const { question, questionNo } = props;
  const intl = useIntl();
  const data = question;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleAnswers = (e) => setFirstName(e.target.value);

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {data?.question?.text}
          {data?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{data?.question?.description}</Box>
        <Box>
          <Input
            id="standard-basic"
            placeholder={CheckValueLocale("first_name", "", {}, intl)}
            variant="standard"
            className={styles.nameTextFieldStyle}
            value={firstName}
            onChange={handleAnswers}
          />
          <Input
            id="standard-basic"
            placeholder={CheckValueLocale("last_name", "", {}, intl)}
            variant="standard"
            className={styles.nameTextFieldStyle}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NameQuestion;
