import { Box } from "@mui/material";
import Dropdownlabel from "./DropdownLabel";
import DropdownSelect from "./DropdownSelect";
import "./monitorDropdownsSearch.scss";

const MonitorDropdownsSearch = ({
  tooltip,
  label,
  containerClassName = "",
  tooltipPosition = "top",
  ...props
}) => {
  return (
    <Box className={`${containerClassName} dropdown-section`}>
      <Dropdownlabel
        tooltip={tooltip}
        label={label}
        tooltipPosition={tooltipPosition}
      />
      <DropdownSelect {...props} />
    </Box>
  );
};

export default MonitorDropdownsSearch;
