import { Box, Grid, ListItem, List, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import { SelectableCard } from "./selectableCard";
import { Link } from "react-router-dom";
import "./teamsSharedStyle.scss";
import { ShowMoreButton } from "./showMoreButton";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
// commented will be used later

const prepareAccountName = (account_name, data_sources) => {
  return !account_name || !account_name?.trim?.()
    ? "-"
    : data_sources?.includes("TWITTER")
      ? `@${account_name}`
      : account_name;
};

const MonitorCard = ({
  monitor,
  isSelected,
  handleSelectMonitor,
  selectable,
  isChannels = false, // is Managed Channel
}) => {
  const { name, data_sources, monitor_type_name, account_name, is_dm } =
    monitor?.attributes || {};
  const intl = useIntl();
  const previewedName = isChannels
    ? prepareAccountName(account_name, data_sources)
    : name;
  return (
    <SelectableCard
      selectable={selectable}
      isSelected={isSelected}
      onSelect={() => {
        handleSelectMonitor(monitor?.id);
      }}
    >
      <Box className="monitor-card-info">
        <Typography variant="h6" className="monitor-card-name">
          {previewedName}
        </Typography>
        {/* bullet list wiht mui List component */}
        <List className="monitor-card-list">
          <ListItem className="monitor-card-list-item">
            <Typography variant="body2" className="monitor-card-type">
              {CheckValueLocale("type", "", {}, intl)}:{" "}
              <Typography component="span">
                {CheckValueLocale(
                  isChannels && is_dm ? "team_dm" : monitor_type_name,
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </Typography>
          </ListItem>
          <ListItem className="monitor-card-list-item">
            <Typography variant="body2" className="monitor-card-type">
              {CheckValueLocale("alert_datasource_type_title", "", {}, intl)}:{" "}
            </Typography>
            <Box className="monitor-card-datasources">
              {data_sources?.map((dataSource) => {
                return <Box key={dataSource}>{getSocialIcon(dataSource)}</Box>;
              })}
            </Box>
          </ListItem>
        </List>
      </Box>
    </SelectableCard>
  );
};

const cardMessages = {
  monitors: {
    title: "monitors",
    description: "select_monitors_for_team",
    notFoundMessage: "add_new_monitor_for_team",
    addNewLink: "/social/monitors/create_monitor",
    addNewBtnLabel: "new_monitor",
  },
  manage_channels: {
    title: "channels",
    description: "select_channels_for_team",
    notFoundMessage: "add_new_channel_for_team",
    addNewLink: "/manage-channels/add-channel",
    addNewBtnLabel: "new_channel",
  },
};

// this component is used for both channels and monitors in team page.
export const TeamMonitors = ({
  monitors,
  selectedMonitors,
  handleSelectMonitor,
  isChannels = false, // is Managed Channel
  selectable = true,
}) => {
  const intl = useIntl();
  const selectedCounter =
    intl?.locale === "ar"
      ? `(${monitors?.length || 0}/${selectedMonitors?.length})`
      : `(${selectedMonitors?.length}/${monitors?.length || 0})`;
  const messages = isChannels
    ? cardMessages?.manage_channels
    : cardMessages?.monitors;
  return (
    <Box className="team-information-container">
      <Box className="team-information-header">
        <Typography className="team-information-label">
          {CheckValueLocale(messages?.title, "", {}, intl)} {selectedCounter}
        </Typography>
        {monitors?.length ? (
          <Typography className="team-information-label">
            {CheckValueLocale(messages?.description, "", {}, intl)}
          </Typography>
        ) : null}
      </Box>
      {monitors?.length > 0 ? (
        <Box className="team-members-section-block">
          <Grid
            className="team-members-container"
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {monitors?.map((monitor) => {
              const isSelected =
                selectable && selectedMonitors?.includes(monitor?.id);
              return (
                <Grid item xs={12} sm={6} lg={4} key={monitor?.id}>
                  <MonitorCard
                    monitor={monitor}
                    handleSelectMonitor={handleSelectMonitor}
                    isSelected={isSelected}
                    selectable={selectable}
                    isChannels={isChannels}
                  />
                </Grid>
              );
            })}
          </Grid>
          <ShowMoreButton
            show={monitors?.length > 6}
            loading={false}
            hasMore={monitors?.length > 6}
            onShowLess={() => {}}
            onShowMore={() => {}}
          />
        </Box>
      ) : (
        <Box>
          <Typography className="empty-members-label">
            {CheckValueLocale(messages?.notFoundMessage, "", {}, intl)}
          </Typography>
          <Link to={messages?.addNewLink} className="add-user-link">
            <LucButton
              variant="outline"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              id="no-monitors-add-monitor-btn"
            >
              {CheckValueLocale(messages?.addNewBtnLabel, "", {}, intl)}
            </LucButton>
          </Link>
        </Box>
      )}
    </Box>
  );
};
