import React from "react";
import { Box, linearProgressClasses, Grid } from "@mui/material";
import WidgetHeader from "components/widgets/widgetHeader";
import "../deepInsights.scss";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import NoDataFound from "components/no-Data/noDataFound";
import { CheckValueLocale } from "utils/helpers";

/*---------------------------------------------------------*/

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#2AAAE2",
  },
}));

/*---------------------------------------------------------*/

const DeepInsightsInterests = ({ interests }) => {
  const intl = useIntl();
  return (
    <Box className="deep-insight-wrapper min-height" id="interests">
      <WidgetHeader
        title="interests"
        chartId="interests"
        showAI
        bigImg
        showDownloadIcon
      />

      {interests == undefined || interests == "" || interests == null ? (
        <NoDataFound />
      ) : (
        Object.entries(interests).map(([key, value]) => (
          <Grid container className="deep-progress-container" mt={2}>
            <Grid item xs={2}>
              <Box className="deep-progress-name">
                <Typography>
                  {key !== "" && CheckValueLocale(`${key}`, "", {}, intl)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box className="deep-progress-line">
                <BorderLinearProgress
                  variant="determinate"
                  value={value.toFixed(2)}
                />
              </Box>
            </Grid>
            <Grid item xs={2} className="deep-progress-value-wrapper">
              <Box className="deep-progress-value">{value.toFixed(2)}%</Box>
            </Grid>
          </Grid>
        ))
      )}
    </Box>
  );
};

export default DeepInsightsInterests;
