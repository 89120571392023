// getBeforeNot - Include Function
export const getBeforeNot = (output, monitor) => {
  let includedKeywords = output
    ?.filter((key) => {
      if (key.charAt(0) !== '"') return key.charAt(0) !== "~";
      else if (key.charAt(0) === '"') return key.charAt(1) !== "~";
    })
    ?.map((key) => {
      if (monitor === "instagram") {
        return "#" + key.replace(/~/g, "");
      } else {
        return key.replace(/~/g, "");
      }
    });

  return includedKeywords;
};

// getAfterNot - Exclude Function
export const getAfterNot = (output, monitor) => {
  let spamKeywords = output
    ?.filter((key) => {
      if (key.charAt(0) !== '"') return key.charAt(0) === "~";
      else if (key.charAt(0) === '"') return key.charAt(1) === "~";
    })
    ?.map((key) => {
      if (monitor === "instagram") {
        return "#" + key.replace(/~/g, "");
      } else {
        return key.replace(/~/g, "");
      }
    });
  return spamKeywords;
};

// check Matched Advanced Keywords
export const checkMatchedAdvancedKeywords = (new_word, advanced) => {
  if (advanced) {
    if (new_word[0] === "^") {
      if (
        (new_word[0] === "^" && new_word[1] === '"') ||
        (new_word[0] === "^" && new_word[1] === "(" && new_word[2] === '"')
      ) {
        if (!new_word[2].match(/[a-z]/i)) {
          new_word = new_word.replace("^", "") + "^";
        } else {
          new_word = "^" + new_word.replace("^", "");
        }
      }
    } else if (new_word[0] === "#") {
      if (
        (new_word[0] === "#" && new_word[1] === '"') ||
        (new_word[0] === "^" && new_word[1] === "(" && new_word[2] === '"')
      ) {
        if (!new_word[2].match(/[a-z]/i)) {
          new_word = new_word.replace("#", "") + "#";
        } else {
          new_word = "#" + new_word.replace("#", "");
        }
      }
    } else if (
      new_word[0] === '"' &&
      new_word[1] === "^" &&
      !new_word[2].match(/[a-z]/i)
    ) {
      new_word = new_word.replace(/\"/g, "");

      if (new_word[0] === "^" && !new_word[1].match(/[a-z]/i)) {
        new_word = new_word.replace("^", "");
        new_word = `"${new_word}^"`;
      }
    } else if (
      new_word[0] === '"' &&
      new_word[1] === "#" &&
      !new_word[2].match(/[a-z]/i)
    ) {
      new_word = new_word.replace(/\"/g, "");

      if (new_word[0] === "#" && !new_word[1].match(/[a-z]/i)) {
        new_word = new_word.replace("#", "");
        new_word = `"${new_word}#"`;
      }
    } else {
      new_word = new_word;
    }
  } else {
    if (
      new_word[0] === '"' &&
      new_word[new_word.length - 1] === '"' &&
      new_word.includes("and")
    ) {
      new_word = new_word
        .replace(/[§±!\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.,؛؟،\"\'\']/g, " ")
        .trim();
      new_word = `"${new_word}"`;
    }
  }

  new_word = new_word.replace(
    /[§±!\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.,؛؟،]/g,
    "",
  );
  return new_word;
};

// check Repeated Advanced Keywords - especially Done for Not Special Seperated cases
export const NonRepeatedKeywords = (keyword) => {
  var keywordsAsString = keyword.toString(),
    words = keywordsAsString.split(/[,]+/),
    nonRepeatedWords = Array.from(new Set(words)).toString();

  return nonRepeatedWords;
};

// check Invalid Keyword Cases with special character that includes
export const checkInvalidCases = (state_value, dataSourceValue) => {
  let Invaild = false;
  Invaild =
    /* 1st case:
    - check if first character is hashtag and second character is space
    - in case twitter only will accept spaces between more than value in case # included
    #reem re accepted but # r  not accepted
    */
    (dataSourceValue == "twitter" &&
      state_value[0] === "#" &&
      state_value[1] === " ") ||
    (dataSourceValue !== "twitter" &&
      state_value.slice(0).includes("#") &&
      state_value.slice(1).includes(" ")) ||
    // 2nd case: if the first character is hashtag and the second character is not English word
    (state_value.replace(/[\"]/g, "").slice(0) === "#" &&
      !state_value.slice(1).match(/[a-zA-Z0-9\u0660-\u0669\u0621-\u064A]/i)) ||
    // 3rd case: if there is hashtag in keywords and it is not the first character
    // (state_value.includes("#") && state_value[0] !== "#") ||
    // 4th case: check if underscore is used and (#,@,^) doesn't exist
    (state_value.includes("_") && !state_value.match(/[\@\#\^]/i)) ||
    !checkNotAcceptedHashtagsCases(state_value);
  return Invaild;
};

// check if keyword of [ newsBlogs , instagram , facebook ] contain quotaionMark and search in whole keyword to replace it
export const checkQuotationMark = (word, monitorType) => {
  let old_word = word,
    word_length = old_word.length - 1,
    word_without_special_characters,
    word_without_quotation;

  if (window.localStorage.lang !== "en") {
    var arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;
  }

  if (
    monitorType === "twitter" ||
    monitorType === "facebook" ||
    monitorType === "newsBlogs"
  ) {
    if (
      (old_word[0] === "^" &&
        old_word[1] === ('"' || "'" || "‟") &&
        old_word[word_length] === ('"' || "'" || "”")) ||
      (old_word[1] === "(" &&
        old_word[2] === ('"' || "'" || "‟") &&
        old_word[word_length - 1] === ('"' || "'" || "”") &&
        old_word[word_length] === ")") ||
      (old_word[0] === "(" &&
        old_word[1] === ('"' || "'" || "‟") &&
        old_word[word_length - 1] === ('"' || "'" || "”") &&
        old_word[word_length] === ")")
    ) {
      word_without_quotation = word.slice(2, word_length);

      word_without_special_characters = word_without_quotation.replace(
        /[`§±!\$%&\*\\\+=\[\]{};:\\\|~<>\/\?\.,؛؟،'"‟”“]/g,
        " ",
      );

      if (word_without_special_characters.length === 0) {
        word_without_special_characters = "";
      } else if (
        old_word.includes("^(") &&
        old_word.includes(")") &&
        old_word.includes('^"')
      ) {
        let startIndex = old_word.indexOf("^("),
          endIndex = old_word.indexOf(")"),
          length = endIndex - startIndex + 1;
        let new_word = old_word.slice(startIndex, endIndex + 1);

        let words_inside_carrot = [],
          words_inside_carrot_length = 0,
          final_words_inside_carrot = [],
          word = "";

        new_word = new_word.replace(
          /[§±!\^$%&\*\(\)\÷\×\+=\[\]{};:\\\|~<>\/\?\.,؛؟،\'\"\‟\”]/g,
          "",
        );
        words_inside_carrot = new_word.split(" ");
        words_inside_carrot_length = words_inside_carrot.length;
        let count = 0;
        words_inside_carrot.map((word) => {
          count !== words_inside_carrot_length
            ? final_words_inside_carrot.push("^" + word + " ")
            : final_words_inside_carrot.push("" + word);
          count++;
        });

        new_word = "";
        final_words_inside_carrot.map((t) => {
          new_word += t;
        });
        final_words_inside_carrot = [];
        word += new_word;

        word =
          old_word.substr(0, startIndex) +
          word +
          old_word.substr(startIndex + length);

        word =
          "" +
          word.replace(
            /[`§±!\$%&\*\\\+=\[\]{};:\\\|~<>\/\?\.,؛؟،'"‟”“]/g,
            " ",
          ) +
          "";

        word_without_special_characters = word;
      } else if (
        old_word[0] === "^" &&
        (!old_word.includes("^(") || old_word.includes('^("'))
      ) {
        if (arabicAlphabetDigits?.test(old_word[2])) {
          word_without_special_characters = `"${word_without_special_characters.trim()}"^`;
        } else if (
          arabicAlphabetDigits?.test(old_word[3]) &&
          old_word[1] === "("
        ) {
          word_without_special_characters = `"${word_without_special_characters.trim()}"^`;
        } else if (arabicAlphabetDigits?.test(old_word[3])) {
          word_without_special_characters = `"${word_without_special_characters.trim()}"^`;
        } else {
          word_without_special_characters = `^"${word_without_special_characters.trim()}"`;
        }
      } else {
        word_without_special_characters = `"${word_without_special_characters.trim()}"`;
      }
    } else if (
      old_word[0] === ('"' || "'" || "‟") &&
      old_word[word_length] === ('"' || "'" || "”") &&
      old_word.includes("^" && "(" && ")")
    ) {
      let new_word = old_word.split("^"),
        words_inside_carrot = [],
        words_inside_carrot_length = 0,
        final_words_inside_carrot = [],
        word = "";

      for (let i = 0; i < new_word.length; i++) {
        if (new_word[i].includes("(") && new_word[i].includes(")")) {
          let betweenBracket = new_word[i].split(")");
          new_word[i] = betweenBracket[0].replace(
            /[§±!\$%&\*\(\)\÷\×\+=\[\]{};:\\\|~<>\/\?\.,؛؟،\'\"\”\‟\“]/g,
            "",
          );
          words_inside_carrot = betweenBracket[0].split(" ");
          words_inside_carrot_length = words_inside_carrot.length;

          let count = 0;
          words_inside_carrot.map((word) => {
            count !== words_inside_carrot_length
              ? final_words_inside_carrot.push("^" + word + " ")
              : final_words_inside_carrot.push("" + word);
            count++;
          });

          new_word[i] = "";
          final_words_inside_carrot.map((t) => {
            new_word[i] += t;
          });
          final_words_inside_carrot = [];
          word = `${word}${new_word[i]}`;
          word = `${word.trim()}${betweenBracket[1]}`;
        } else {
          word =
            new_word[i][0] === '"' || new_word[i][0] === " "
              ? `${word}${new_word[i]}`
              : arabicAlphabetDigits?.test(new_word[i][1])
                ? `${word}(^${new_word[i]})`
                : `${word}^${new_word[i]})`;
        }
      }

      word =
        "" +
        word.replace(/[§±!\$%&\*\(\)\÷\×\+=\[\]{};:\\\|~<>\/\?\.,؛؟،]/g, "") +
        "";
      word_without_special_characters = word;
    } else if (
      (old_word[0] === '"' && old_word[word_length] === '"') ||
      (old_word[0] === "'" && old_word[word_length] === "'") ||
      (old_word[0] === "‟" && old_word[word_length] === "”") ||
      (old_word[0] === '"' && old_word[word_length] === "”") ||
      (old_word[0] === "‟" && old_word[word_length] === '"') ||
      (old_word[0] === "”" && old_word[word_length] === "“") ||
      (old_word[0] === "”" && old_word[word_length] === "“") ||
      (old_word[0] === "“" && old_word[word_length] === "”")
    ) {
      word_without_quotation = word.slice(1, word_length);

      word_without_special_characters = word_without_quotation.replace(
        /[`§±!\$%&\*\\\+=\[\]{};:\\\|~<>\/\?\.,؛؟،'"‟”“]/g,
        " ",
      );

      if (word_without_special_characters.length == 0) {
        word_without_special_characters = "";
      } else if (old_word[1] === "^") {
        if (arabicAlphabetDigits?.test(old_word[2])) {
          word_without_special_characters =
            word_without_special_characters.replace("^", "");
          word_without_special_characters =
            '"' + word_without_special_characters + '^"';
        } else {
          word_without_special_characters =
            '"' + word_without_special_characters + '"';
        }
      } else if (old_word[1] === "#") {
        if (arabicAlphabetDigits?.test(old_word[2])) {
          word_without_special_characters =
            word_without_special_characters.replace("#", "");
          word_without_special_characters =
            '"' + word_without_special_characters + '#"';
        } else {
          word_without_special_characters =
            '"' + word_without_special_characters + '"';
        }
      } else {
        word_without_special_characters =
          '"' + word_without_special_characters + '"';
      }
    } else if (
      old_word[0] === "^" &&
      (old_word[1] === "(" || (old_word[1] === "" && old_word[2] === "(")) &&
      old_word[word_length] === ")"
    ) {
      let new_word = old_word.slice(2, -1),
        words_inside_carrot = [],
        words_inside_carrot_length = 0,
        final_words_inside_carrot = [],
        word = "";

      new_word = new_word.replace(
        /[§±!\$%&\*\(\)\÷\×\+=\[\]{};:\\\|~<>\/\?\.,؛؟،\'\"\”\‟\“]/g,
        "",
      );
      words_inside_carrot = new_word.split(" ");
      words_inside_carrot_length = words_inside_carrot.length;

      let count = 0;
      words_inside_carrot.map((word) => {
        count !== words_inside_carrot_length
          ? final_words_inside_carrot.push("^" + word + " ")
          : final_words_inside_carrot.push("" + word);
        count++;
      });

      new_word = "";
      final_words_inside_carrot.map((t) => {
        new_word += t;
      });
      final_words_inside_carrot = [];
      word += new_word;

      word =
        "" +
        word.replace(/[§±!\$%&\*\(\)\÷\×\+=\[\]{};:\\\|~<>\/\?\.,؛؟،]/g, " ") +
        "";

      word_without_special_characters = word;
    } else {
      if (
        (old_word[0] === ('"' || "'" || "‟") &&
          old_word[2] === ('"' || "'" || "”") &&
          old_word[word_length] === "^") ||
        (old_word[0] === ('"' || "'" || "‟") &&
          old_word[word_length - 1] === ('"' || "'" || "”") &&
          old_word[word_length] === "^") ||
        (old_word[2] === ('"' || "'" || "‟") &&
          old_word[word_length] === ('"' || "'" || "”") &&
          old_word[0] === "^")
      ) {
        word_without_special_characters = old_word.replace(
          /[`§±!\$%&\*\\\+=\[\]{};:\\\|~<>\/\?\.,؛؟،'"‟”“]/g,
          " ",
        );
      } else {
        word_without_special_characters = old_word.replace(
          /[`§±!\$%&\*\\\+=\[\]{};:\\\|~<>\/\?\.,؛؟،'"‟”“]/g,
          " ",
        );
      }
    }
  } else {
    word_without_special_characters = old_word.replace(
      /[§±!^\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.,؛؟،'"‟”“ ]/g,
      " ",
    );
  }
  //remove arabic text diactric
  if (window.localStorage.lang !== "en") {
    word_without_special_characters = word_without_special_characters.replace(
      /[\u0600-\u061B\u063B-\u063F\u064B-\u0660\u066A-\u066D\u06D2-\u06ED\u2018\u2019]/g,
      "",
    );
  }
  return word_without_special_characters;
};

// check accepted hashtag cases and pass [ word ] to from [ checkInvalidCases ] function
export const checkNotAcceptedHashtagsCases = (word) => {
  let str = word,
    hashtagsIndices = [],
    accepeted = true;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "#") hashtagsIndices.push(i);
  }
  if (hashtagsIndices.length > 1) {
    hashtagsIndices.map((el) => {
      if (el !== 0) {
        if (str[el - 1] !== " ") {
          accepeted = false;
        }
      }
    });
  }
  return accepeted;
};

// Check if we have space after hashtag or not
export const checkSpaceAfterHashtag = (word) => {
  let reWhiteSpace = new RegExp("\\s+"),
    space = 0;

  let hashtagIndexes = word
    .split("")
    .map((e, i) => {
      if (e === "#") return i;
    })
    .filter((n) => typeof n === "number");

  for (let i = 0; i < word.length; i++) {
    for (let hash = 0; hash < hashtagIndexes.length; hash++) {
      if (i === hashtagIndexes[hash]) {
        if (reWhiteSpace.test(word[i + 1])) {
          space++;
        }
      }
    }
  }
  if (space > 0) {
    return true;
  }
  return false;
};
/* here we show error msg in case:
    - single " or ' or ( or ) 
    - () or "" or '' without any letter
  */

export const checkQuotesNoChar = (str) => {
  let regexOne = /["'()]/;
  let pattern = /["'()]/g;
  let patternDoubleQuotes = /(["])/g;
  let patternSingleQuotes = /(['])/g;
  let patternSingleCaretOpen = /([(])/g;
  let patternSingleCaretClose = /([)])/g;
  let oddDoubleQuotes = str.match(patternDoubleQuotes) || [];
  let oddSingleQuotes = str.match(patternSingleQuotes) || [];

  let caretOpenMatch = str.match(patternSingleCaretOpen) || [];
  let caretCloseMatch = str.match(patternSingleCaretClose) || [];

  let res =
    str?.replace(pattern, "")?.trim().length == 0 ||
    ((patternSingleQuotes.test(str) || patternDoubleQuotes.test(str)) &&
      (oddDoubleQuotes.length % 2 !== 0 || oddSingleQuotes.length % 2 !== 0)) ||
    ((patternSingleCaretOpen.test(str) || patternSingleCaretClose.test(str)) &&
      caretOpenMatch.length != caretCloseMatch.length);

  if (regexOne.test(str)) {
    return res;
  }
  return false;
};

export const checkCharNumCount = (str, exp) => {
  let checkCountNum = str?.match(exp) || [];
  return checkCountNum?.length == 2 ? true : false;
};
