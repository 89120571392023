import { useEffect, useState } from "react";
import Header from "../header";
import { Box } from "@mui/material";

const initialTabWidget = {};
const SLAsTab = ({ selectedTab, preLoaderTrackerDataSources }) => {
  const [tabWidgets, setTabWidgets] = useState({ ...initialTabWidget });

  useEffect(() => {
    setTabWidgets({
      ...tabWidgets,
    });
  }, []);

  return (
    <>
      <Header selectedTab={selectedTab} />
      <Box className={"engag-dashboard-widgets-container"}></Box>
    </>
  );
};

export default SLAsTab;
