import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";

import { ReactComponent as ForwardIcon } from "images/interactions-details/arrow-up-right-from-box.svg";
import { SelectableCard } from "./selectableCard";
import "./teamsSharedStyle.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ShowMoreButton } from "./showMoreButton";
import DefaultUSer from "images/shared-images/user-management-default.svg";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
// commented will be used later
const dataSourceEmptyStateMessages = {
  facebook: {
    title: "add_new_facebook_account_to_team",
    btn: "new_account_to_team",
    dataSrouceName: "report_FACEBOOK",
  },
  instagram: {
    title: "add_new_instagram_account_to_team",
    btn: "new_account_to_team",
    dataSrouceName: "instagram",
  },
  twitter: {
    title: "add_new_twitter_account_to_team",
    btn: "new_account_to_team",
    dataSrouceName: "twitter",
  },
};

const SocialMediaAccountCard = ({ account, isSelected, selectable }) => {
  const { image_url, name, handle } = account;
  const [selected, setSelected] = useState(!!isSelected);
  return (
    <SelectableCard
      isSelected={selected}
      onSelect={() => setSelected(!selected)}
      selectable={selectable}
    >
      <Box className="account-card-info">
        <Box className="account-card-info-img">
          <img
            src={image_url}
            alt="account"
            className="account-card-img"
            onError={(e) => {
              e.target.src = DefaultUSer;
            }}
          />
        </Box>
        <Box className="account-card-info-text">
          <Box>
            <Typography variant="h6" className="account-card-name">
              {name}
            </Typography>
            <Typography variant="body2" className="account-card-handle">
              {handle}
            </Typography>
          </Box>
        </Box>
      </Box>
    </SelectableCard>
  );
};

export const SocialMediaAccounsSection = ({ socialAccount }) => {
  const { icon, accounts, name } = socialAccount;
  const intl = useIntl();
  const dataSourceMessages =
    dataSourceEmptyStateMessages[name?.toLowerCase()] ?? {};
  return (
    <Box className="team-accounts-container">
      <Box className="team-accounts-header">
        <Box className="team-account-brand">
          {getSocialIcon(icon)}
          <Typography variant="h6" className="team-accounts-title">
            {CheckValueLocale(dataSourceMessages?.dataSrouceName, "", {}, intl)}
          </Typography>
        </Box>
        <Box className="team-accounts-header-connector">
          <Typography variant="span" />
        </Box>
        {accounts?.length ? (
          <Link to="/settings" className="add-user-link">
            <ForwardIcon />
            <Typography variant="body1">
              {CheckValueLocale("authenticate_new", "", {}, intl)}
            </Typography>
          </Link>
        ) : null}
      </Box>
      {accounts?.length ? (
        <Grid
          className="team-members-container"
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {accounts?.map((account) => {
            const isSelected = false;
            //selectedMembers?.includes(account?.id);
            return (
              <Grid item xs={12} sm={6} lg={4} key={account?.name}>
                <SocialMediaAccountCard
                  account={account}
                  // handleSelectMember={handleSelectMember}
                  isSelected={isSelected}
                  selectable={true}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box>
          <Typography className="empty-members-label">
            {CheckValueLocale(dataSourceMessages?.title, "", {}, intl)}
          </Typography>
          <Link to="/settings" className="add-user-link">
            <LucButton
              variant="outline"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              {CheckValueLocale(
                dataSourceMessages?.btn,
                "",
                {
                  dataSource: CheckValueLocale(
                    dataSourceMessages?.dataSrouceName,
                    "",
                    {},
                    intl,
                  ),
                },
                intl,
              )}
            </LucButton>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export const TeamSocialAccount = ({ socialAccounts }) => {
  const intl = useIntl();
  const selectedCounter = intl?.locale === "ar" ? `(${0}/${0})` : `(${0}/${0})`;
  return (
    <Box className="team-social-media-container">
      <Box className="team-social-meida-header">
        <Typography variant="h6" className="team-social-media-title">
          {CheckValueLocale("social_accounts", "", {}, intl)} {selectedCounter}
        </Typography>
        <Typography variant="body2" className="team-social-media-description">
          {CheckValueLocale("select_accounts_for_team", "", {}, intl)}
        </Typography>
      </Box>
      {socialAccounts?.map((socialAccount) => {
        return (
          <Box className="team-social-media-block" key={socialAccount?.name}>
            <SocialMediaAccounsSection socialAccount={socialAccount} />
            <ShowMoreButton
              show={socialAccount?.accounts?.length > 6}
              loading={false}
              hasMore={socialAccount?.accounts?.length > 6}
              onShowLess={() => {}}
              onShowMore={() => {}}
            />
          </Box>
        );
      })}
    </Box>
  );
};
