import { useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Box, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import UserInfo from "./components/userInfo";
import PageHeader from "components/pageHeader.js";
import "./profile.scss";
import { UserTeams } from "./components/userTeams";

const ProfilePage = (props) => {
  const intl = useIntl();
  const [showAlert, setAlertShow] = useState(true);

  return (
    <Container className="profile-container" maxWidth="xl">
      <PageHeader title="profile_settings" />
      <div id="profile">
        {showAlert ? (
          <Alert severity="info" id="profile-page-accounts-management-alert">
            <Box className="alert-content">
              {CheckValueLocale(
                "profile_page_accounts_management_alert_msg",
                "",
                {},
                intl,
              )}
              <Box className="btns-wrapper">
                <Link
                  to={"/settings/accounts_management/users_accounts"}
                  id="accounts-management-alert-view-btn"
                  className="view-btn"
                >
                  {CheckValueLocale(
                    "accounts_managemnt_view_btn",
                    "",
                    {},
                    intl,
                  )}
                </Link>
                <Box
                  component={"span"}
                  id="profile-page-accounts-management-alert-close-btn"
                  className="close-btn"
                  onClick={() => setAlertShow(false)}
                >
                  <FontAwesomeIcon icon={faClose} size="lg" />
                </Box>
              </Box>
            </Box>
          </Alert>
        ) : null}
        <UserInfo />
        <UserTeams />
      </div>
    </Container>
  );
};
export default ProfilePage;
