import { faGripDotsVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";

const DragHandleIcon = () => {
  return (
    <Box component={"span"} className="drag-widget-icon">
      <FontAwesomeIcon icon={faGripDotsVertical} />
    </Box>
  );
};
export default DragHandleIcon;
