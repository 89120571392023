import React from "react";
import { useIntl } from "react-intl";
import { Typography, Box, Button } from "@mui/material";
import "./topicsHeader.scss";
import { useHistory, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const TopicsHeader = (props) => {
  const intl = useIntl();
  let history = useHistory();
  let { monitorId } = useParams();
  const { title, subtitle, topicsCount, btnIcon, btnText, dynamicId } = props;
  return (
    <Box className="single-intr-wrapper">
      <Box>
        <Typography className={"topics-title"}>
          {CheckValueLocale(title, "", {}, intl).toUpperCase()}
        </Typography>
        <Typography className={"topics-collected"}>
          {CheckValueLocale(subtitle, "", {}, intl)}
          {topicsCount.toLocaleString()}
        </Typography>
      </Box>
      <LucButton
        variant="outline"
        type="secondary"
        size="small"
        startIcon={btnIcon}
        disabled={props.disabled}
        onClick={() => history.push(`/monitor/monitor-settings/${monitorId}`)}
        id={`monitor-topics-settings-${dynamicId ?? "dynamicId"}`}
      >
        {CheckValueLocale(btnText, "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default TopicsHeader;
