import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { TeamAndSLAs } from "../components/teamAndSLAs/teamAndSLAs";

export const TeamAndSLAsStep = ({
  teamAndSLA,
  setTeamAndSLA,
  teamAndSLAsLoading,
  getSLAs,
  getTeams,
}) => {
  const intl = useIntl();
  return (
    <Box className="team-and-slas-details">
      <Box className="team-and-slas-details-header">
        <Typography variant="h6" className="team-and-slas-details-title">
          {CheckValueLocale("team_and_slas_details_title", "", {}, intl)}
        </Typography>
        <Typography variant="p" className="team-and-slas-details-desc">
          {CheckValueLocale("team_and_slas_details_desc", "", {}, intl)}
        </Typography>
      </Box>
      <TeamAndSLAs
        teamAndSLA={teamAndSLA}
        setTeamAndSLA={setTeamAndSLA}
        teamAndSLAsLoading={teamAndSLAsLoading}
        getSLAs={getSLAs}
        getTeams={getTeams}
      />
    </Box>
  );
};
