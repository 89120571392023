import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { useDeleteTheme } from "../hooks/useDeleteTheme";
import PopupModal from "components/popupModal";
import LucButton from "shared/lucButton/lucButton";
import CustomizedTooltip from "components/customizedTooltip";
import "../style.scss";

const OptionsDropdown = ({
  theme,
  setOpenDialog,
  setThemeDialogStats,
  setSnackBarData,
}) => {
  const intl = useIntl();

  const [menuStats, setMenuStats] = useState({ anchorEl: null });
  const menuOpened = Boolean(menuStats?.anchorEl);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  const handleOpenMenuDropDown = (event) =>
    setMenuStats({ ...menuStats, anchorEl: event?.currentTarget });

  const handleCloseMenuDropdown = () =>
    setMenuStats({ ...menuStats, anchorEl: null });

  const handleOpenDeleteModal = () => {
    handleCloseMenuDropdown();
    setDeleteModalOpened(true);
  };

  const {
    mutate: deleteTheme,
    isPending: isDeleteLoading,
    isError: isDeleteError,
  } = useDeleteTheme();

  const handleDeleteTheme = () =>
    deleteTheme(
      { label_id: theme?.id },
      {
        onSuccess: () => {
          setDeleteModalOpened(false);
          setSnackBarData({
            open: true,
            severity: "success",
            title: "",
            message: "theme_deleted_successfully",
          });
        },
        onError: () =>
          setSnackBarData({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          }),
      },
    );

  const handleOpenEditThemeModal = async () => {
    handleCloseMenuDropdown();
    await setThemeDialogStats({ isEdit: true, themeToEdit: theme });
    setOpenDialog(true);
  };

  // User can not Edit or Delete >>> "Undefined" or "غير معرف" <<< theme
  const disabledForUndefined =
    ["undefined", "غير معرف"]?.includes(
      theme?.sub_theme_en_name?.toLowerCase(),
    ) ||
    ["undefined", "غير معرف"]?.includes(
      theme?.sub_theme_ar_name?.toLowerCase(),
    );

  const enabledEdit =
    !disabledForUndefined &&
    (handlelUserRoles("GENERAL", "REORDER_SUBTHEME") || // MANAGER
      (handlelUserRoles("GENERAL", "EDIT_SUBTHEME") &&
        theme?.is_subtheme_owner)); // MODERATOR && OWNER

  const enabledDelete =
    !disabledForUndefined &&
    (handlelUserRoles("GENERAL", "REORDER_SUBTHEME") || // MANAGER
      (handlelUserRoles("GENERAL", "DESTROY_SUBTHEME") &&
        theme?.is_subtheme_owner)); // MODERATOR && OWNER

  return (
    <Box>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        placement="top"
        arrow
      >
        <Box>
          <LucButton
            id="custom-themes-more-settings-btn"
            className={`menu-items menu-item-container ${menuOpened ? "menu-item-active" : ""}`}
            type="secondary"
            variant="flat"
            minWidth={42}
            aria-expanded={menuOpened ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenuDropDown}
            onClose={handleCloseMenuDropdown}
          >
            <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
          </LucButton>
        </Box>
      </CustomizedTooltip>
      <Menu
        id="themes-table-options-menu"
        anchorEl={menuStats?.anchorEl}
        open={menuOpened}
        onClose={handleCloseMenuDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale == "ar" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale == "ar" ? "left" : "right",
        }}
      >
        <MenuItem
          id="themes-table-more-options-edit-btn"
          disableRipple
          disabled={!enabledEdit}
          onClick={handleOpenEditThemeModal}
        >
          <FontAwesomeIcon icon={faPencil} fixedWidth />
          {CheckValueLocale("edit", "", {}, intl)}
        </MenuItem>
        <MenuItem
          id="themes-table-more-options-delete-btn"
          disableRipple
          disabled={!enabledDelete}
          onClick={handleOpenDeleteModal}
        >
          <FontAwesomeIcon icon={faTrash} fixedWidth />
          {CheckValueLocale("delete", "", {}, intl)}
        </MenuItem>
      </Menu>
      {/* ********* Delete Theme Modal *********  */}
      <PopupModal
        open={deleteModalOpened}
        close={() => setDeleteModalOpened(false)}
        title={CheckValueLocale("delete_theme", "", {}, intl)}
        body={
          <Box id="delete-theme-popup-body">
            <Typography variant="p" className="text-line-one">
              {CheckValueLocale(
                "delete_theme_popup_body_one",
                "",
                {
                  theme_name:
                    intl?.locale == "ar"
                      ? theme?.sub_theme_ar_name
                      : theme?.sub_theme_en_name,
                },
                intl,
              )}
            </Typography>
            <br />
            <Typography variant="p" className="text-line-two">
              {CheckValueLocale("delete_theme_popup_body_two", "", {}, intl)}
            </Typography>
          </Box>
        }
        classeName="create-historical-popup"
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("confirm_delete", "", {}, intl)}
        accept={handleDeleteTheme}
        rightColor="error"
        warning
        leftBtnId="delete-theme-cancel-delete-button"
        rightBtnId="delete-theme-confirm-delete-button"
        isRightBtnLoading={isDeleteLoading}
        minWidth="600px"
        addClasses="actions-buttons-shared"
      />
    </Box>
  );
};

export default OptionsDropdown;
