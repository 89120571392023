// Common file used in whole app to show Circular Loading GIF before each step!
// Don't repeat/update or remove this file!
// in case you want to change its style , include <CircularLoading/> inside a <div/> and add styling to the <div/>

import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "../App.scss";
import { CheckValueLocale } from "utils/helpers";

export default function CircularLoading() {
  const intl = useIntl();

  return (
    <Box className="circular-loading">
      <CircularProgress id="circular-progress-component" />
      <Typography>
        {CheckValueLocale("loading_please_wait", "", {}, intl)}
      </Typography>
    </Box>
  );
}
