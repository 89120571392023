import { Typography } from "@mui/material";
import { CheckValueLocale, handleSentiment } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";

const SentimentLabelContent = (props) => {
  const intl = useIntl();

  return (
    <>
      <FontAwesomeIcon
        style={{
          color: handleSentiment(props?.value)?.color,
        }}
        icon={handleSentiment(props?.value)?.label}
      />
      <Typography
        component="span"
        className="sentiment-widget-value"
        style={{
          color: handleSentiment(props?.value)?.color,
        }}
      >
        {CheckValueLocale(handleSentiment(props?.value)?.icon, "", {}, intl)}
      </Typography>
    </>
  );
};

export default SentimentLabelContent;
