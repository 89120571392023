import Alerts from "../api/alert.js";

var AlertController = {};

AlertController.createAlerts = (queryData) => {
  return new Promise(function (resolve) {
    Alerts.createAlerts(queryData)
      .then(function (response) {
        resolve({
          data: response.data || [],
          status_code: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.editAlert = (id, queryData) => {
  return new Promise(function (resolve) {
    Alerts.editAlerts(id, queryData)
      .then(function (response) {
        resolve({
          data: response.data || [],
          status_code: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getAlertsV1 = (user_id, page_num, per_page, alert_types) => {
  const queryData = {
    user_id,
    page_num,
    per_page,
    alert_types,
  };

  return new Promise(function (resolve) {
    Alerts.getAlertsV1(queryData)
      .then((response) => {
        resolve({
          data: response.data.configured_alerts.data,
          total_count: response.data.total_count,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getAlerts = (
  page,
  alert_types,
  webhook_filter,
  sort_by,
  order_by,
) => {
  const queryData = {
    page,
    alert_types,
    webhook_filter,
    sort_by,
    order_by,
  };

  return new Promise(function (resolve) {
    Alerts.getAlerts(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          pagination: response?.data?.pagination,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getAlertMonitors = (alert_type) => {
  const queryData = {
    alert_type,
  };

  return new Promise(function (resolve) {
    Alerts.getAlertMonitors(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getAlertsTypesV1 = () => {
  return new Promise(function (resolve) {
    Alerts.getAlertsTypesV1()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getAlertsTypesV2 = () => {
  return new Promise(function (resolve) {
    Alerts.getAlertsTypesV2()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.deleteAlerts = (id) => {
  return new Promise(function (resolve) {
    Alerts.deleteAlerts(id)
      .then((response) => {
        return response;
      })
      .then(function (json) {
        resolve({
          data: json,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// get alert detail [ RabbitMQ ]
AlertController.getAlertDetail = (id, page_number) => {
  let queryData = {
    page_number,
  };
  return new Promise(function (resolve) {
    Alerts.getAlertDetail(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//new version
AlertController.getAlertsData = (queryData) => {
  return new Promise(function (resolve) {
    Alerts.getAlertsData(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getAlertsMonitors = (
  product_id,
  data_source_id,
  alert_type_id,
  channel_type_id,
) => {
  const queryData = {
    product_id,
    data_source_id,
    alert_type_id,
    channel_type_id,
  };

  return new Promise(function (resolve) {
    Alerts.getAlertsMonitors(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.createAlertsV2 = (queryData) => {
  return new Promise(function (resolve) {
    Alerts.createAlertsV2(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AlertController.updateAlertsV2 = (queryData, id) => {
  return new Promise(function (resolve) {
    Alerts.updateAlertsV2(queryData, id)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AlertController.getUpdateAlertsDataV2 = (id) => {
  return new Promise(function (resolve) {
    Alerts.getUpdateAlertsDataV2(id)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.deleteAlertsDataV2 = (id) => {
  return new Promise(function (resolve) {
    Alerts.deleteAlertsDataV2(id)
      .then((response) => {
        return response;
      })
      .then(function (json) {
        resolve({
          data: json,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getTestWebhooks = (queryData) => {
  return new Promise(function (resolve) {
    Alerts.getTestWebhooks(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.deleteWebhooks = (id) => {
  return new Promise(function (resolve) {
    Alerts.deleteWebhooks(id)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.enableWebhooks = (id) => {
  return new Promise(function (resolve) {
    Alerts.enableWebhooks(id)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.disableWebhooks = (id) => {
  return new Promise(function (resolve) {
    Alerts.disableWebhooks(id)
      .then((response) => {
        resolve({
          data: response.data,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AlertController.getAlertMonitorsEngagements = (queryData) => {
  return new Promise(function (resolve) {
    Alerts.getAlertMonitorsEngagements(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default AlertController;
