import { useState } from "react";
import { useIntl } from "react-intl";
import { Button, Card, Stack, Typography } from "@mui/material";
import { Phone } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import CompaniesController from "services/controllers/companiesController";
import Snackbar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";

const CustomerManagerDetails = (props) => {
  const intl = useIntl();
  const [requested, setRequested] = useState(false);
  const [success, setSuccess] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const sendRequestCall = () => {
    let queryData = {
      service_name: "request_a_callback",
    };
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSuccessMsg("request_call_sent_success");
        setSuccess(true);
        setRequested(true);
      } else {
        setErrorMsg("request_call_sent_failed");
        setSuccess(false);
        setRequested(false);
      }
    });
  };

  return (
    <div id="customer_success">
      {props.customerSuccessManager && (
        <>
          <Typography className="add-social">
            {CheckValueLocale("customer_success", "", {}, intl)}
          </Typography>
          <Card>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div name="name">
                <label className="label__name">
                  {CheckValueLocale("name", "", {}, intl)}:&emsp;
                </label>
                <span className="customer_name">
                  {props.customerSuccessManager
                    ? props.customerSuccessManager.name
                    : ""}
                </span>
              </div>
              <div name="phone_no" className="phone_wrapper">
                <label className="label__name">
                  {CheckValueLocale("phone_number", "", {}, intl)}:&emsp;
                </label>
                <a href={`tel:${props.customerSuccessManager.phone_number}`}>
                  <span>
                    {props.customerSuccessManager
                      ? props.customerSuccessManager.phone_number
                      : ""}
                  </span>
                </a>
              </div>
              <div name="email">
                <label className="label__name">
                  {CheckValueLocale("email", "", {}, intl)}:&emsp;
                </label>
                <a href={`mailto:${props.customerSuccessManager.email}`}>
                  <span>
                    {props.customerSuccessManager
                      ? props.customerSuccessManager.email
                      : ""}
                  </span>
                </a>
              </div>
              <Button
                className="customer_success__btn"
                disabled={requested ? true : false}
                startIcon={
                  requested ? (
                    <FontAwesomeIcon icon={faPaperPlane} />
                  ) : (
                    <Phone />
                  )
                }
                style={{
                  textTransform: "none",
                  padding: "5px 15px",
                }}
                variant="contained"
                onClick={() => {
                  sendRequestCall();
                }}
                id={`setting-company-info-${requested ? `request` : `request-call`}-btn`}
              >
                {CheckValueLocale(
                  requested ? "requested" : "request_call",
                  "",
                  {},
                  intl,
                )}
              </Button>
            </Stack>
          </Card>
        </>
      )}
      {success != null ? (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#000",
                }}
              >
                {CheckValueLocale(successMsg, "", {}, intl)}
              </span>
            ) : (
              <div>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default CustomerManagerDetails;
