import { Box } from "@mui/material";
import SnackBar from "components/snackBar";
import BarChartWidget from "components/widgets/barChartWidget";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PersonalInformation from "./components/personalInformation";
import { CheckValueLocale } from "utils/helpers";
import PopupModal from "components/popupModal";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

// ___________________________________________________________

const AuthorsBody = (props) => {
  const {
    response,
    preLoaderTrackerDataSources,
    analyzeProfile,
    monitorData,
    postsUsedLimitisProfileAnalyzed,
    isProfileAnalyzed,
    postsUsedLimit,
    getCompanyDataQuota,
  } = props;
  const intl = useIntl();
  // ___________________________________________________________
  const [latestPosts, setLatestPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [latestPostsPreLoaderDataSources, setLatestPostsPreLoaderDataSources] =
    useState([]);

  //Monitor Engagement
  const [totalEngagements, setTotalEngagements] = useState(0);
  const [userEngagementsMonitor, setUserEngagementsMonitor] = useState([]);
  const [engagementsRate, setEngagementsRate] = useState("");
  const [
    monitorEngagementsPreLoaderDataSources,
    setMonitorEngagementsPreLoaderDataSources,
  ] = useState([]);

  // Monitor Post Engagement ___________________________________________________________

  const [userPostEngagementsMonitor, setUserPostEngagementsMonitor] = useState(
    [],
  );
  const [userPostEngagementsRate, setUserPostEngagementsRate] = useState("");
  const [userPostTotalEngagements, setUserPostTotalEngagements] = useState(0);
  const [
    monitorPostEngagementsPreLoaderDataSources,
    setMonitorPostEngagementsPreLoaderDataSources,
  ] = useState([]);

  //____________________________________

  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Twitter__EngagerProfilePage__top_posts_comments":
        setLatestPosts(response.eventData.top_posts);
        setTopPostsCount(response.eventData.top_posts_count);
        setLatestPostsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Twitter__EngagerProfilePage__monitor_engagement":
        setUserEngagementsMonitor(
          response.eventData.user_monitor_engagement.chart_data,
        );
        setEngagementsRate(
          CheckValueLocale(
            response.eventData.user_monitor_engagement.engagement_rate.toLowerCase(),
            "",
            {},
            intl,
          ),
        );
        setTotalEngagements(
          response.eventData.user_monitor_engagement.total_engagement,
        );
        setMonitorEngagementsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);

        break;
      case "Twitter__EngagerProfilePage__engagement_on_user_posts":
        setUserPostEngagementsMonitor(
          response.eventData.engager_profile_engagement_on_user_posts
            .chart_data,
        );
        setUserPostEngagementsRate(
          response.eventData.engager_profile_engagement_on_user_posts
            .engagement_rate,
        );
        setUserPostTotalEngagements(
          response.eventData.engager_profile_engagement_on_user_posts
            .total_engagement,
        );

        setMonitorPostEngagementsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  /*---------------------------------------------------------*/
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setLatestPosts([]);
      setTopPostsCount(0);
      setTotalEngagements(0);
      setEngagementsRate("");
      setUserEngagementsMonitor([]);
      //_______
      setUserPostTotalEngagements(0);
      setUserPostEngagementsRate("");
      setUserPostEngagementsMonitor([]);
      // Values of DataSources toTrack each widget preloader
      setLatestPostsPreLoaderDataSources([]);
      setMonitorEngagementsPreLoaderDataSources([]);
      setMonitorPostEngagementsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  /*---------------------------------------------------------*/
  const contentStylesColors = {
    [CheckValueLocale("posts", "", {}, intl)]: "#2AAAE2",
    [CheckValueLocale("retweets", "", {}, intl)]: "#334155",
    [CheckValueLocale("replies", "", {}, intl)]: "#64748B",
  };
  const contentPostStylesColors = {
    [CheckValueLocale("retweets", "", {}, intl)]: "#2AAAE2",
    [CheckValueLocale("replies", "", {}, intl)]: "#334155",
  };

  /*---------------------------------------------------------*/
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  return (
    <>
      <Box className="engager-widget-head" mt={4}>
        <Box>{CheckValueLocale("personal_information", "", {}, intl)}</Box>
      </Box>
      <PersonalInformation
        response={response}
        analyzeProfile={analyzeProfile}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        monitorData={monitorData}
        postsUsedLimit={postsUsedLimit}
        isProfileAnalyzed={isProfileAnalyzed}
        getCompanyDataQuota={getCompanyDataQuota}
        deepInsightsLoading={props?.deepInsightsLoading}
        setDeepInsightsLoading={props?.setDeepInsightsLoading}
      />
      {/* /*---------------------------------------------------------*/}

      <Box className="engager-widget-head" mb={1.5}>
        <Box>{CheckValueLocale("monitor_engagement", "", {}, intl)}</Box>
      </Box>
      <Box mb={5}>
        <BarChartWidget
          title="user_engagment_in_the_monitor"
          titleToolTip={"user_engagment_in_the_monitor_tooltip"}
          showDownloadIcon
          dataColor="#2AAAE2"
          customBarColor={contentStylesColors}
          data={userEngagementsMonitor}
          barChartPreLoaderDataSources={monitorEngagementsPreLoaderDataSources}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          zoomPercentage
          totalEngagements={totalEngagements}
          engagementsRate={engagementsRate}
          showAuthorProfile
          showZoomPercentage
          xAxisFontColor={"#323232"}
          xAxisFontWeight={"bold"}
          yAxisGridLeft={"7%"}
          showReportIcon
          engagmentToolTip="engagement_rate_tooltip"
        />
      </Box>
      <Box mb={5}>
        <BarChartWidget
          title="engagment_on_user_posts_in_the_monitor"
          titleToolTip={"engagment_on_user_posts_in_the_monitor_tooltip"}
          showDownloadIcon
          dataColor="#2AAAE2"
          customBarColor={contentPostStylesColors}
          data={userPostEngagementsMonitor}
          barChartPreLoaderDataSources={
            monitorPostEngagementsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          showZoomPercentage
          showAuthorProfile
          totalEngagements={userPostTotalEngagements}
          averageEngagementsRate={userPostEngagementsRate}
          xAxisFontColor={"#323232"}
          xAxisFontWeight={"bold"}
          yAxisGridLeft={"7%"}
          showReportIcon
          engagmentToolTip="avg_engagements_per_author_post_tooltip"
        />
      </Box>
      {/* /*---------------------------------------------------------*/}

      <Box className="engager-widget-head" mb={1.5}>
        <Box>{CheckValueLocale("latest_posts", "", {}, intl)}</Box>
      </Box>
      <QuestionCard
        icon={xPlatform}
        latestUnanswer={latestPosts.slice(0, 6)}
        showRetweet
        showScreenName
        showBanIcon
        latestUnanswerPreloader={latestPostsPreLoaderDataSources}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        updateAnalysis={updateAnalysis}
        noOfChar={15}
        singleTweetAnalysis={props.singleTweetAnalysis}
        showDefaultImg
      />
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={stats?.modalTitle}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
      />
    </>
  );
};

export default AuthorsBody;
