import { useMutation } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useEditAnswerCategories = () => {
  return useMutation({
    mutationFn: async ({ queryData }) => {
      await SurveyController.editCategories(queryData);
    },
  });
};
