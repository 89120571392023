import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Container, Divider, Box, Typography, Grid } from "@mui/material";
import { isEmptyObj, CheckValueLocale } from "utils/helpers";
import { useParams } from "react-router-dom";

import InteractionsController from "services/controllers/interactionsController";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useEditSentiment } from "pages/channelAnalytics/InsightsPage/hooks/useEditSentiment";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import NoDataFound from "components/no-Data/noDataFound";
import SnackBar from "components/snackBar";
import Luci from "pages/luci";
import Genesys from "images/shared-images/genesys-icon.svg";
import InteractionsListMessageGenesys from "components/interactions-list/components/InteractionsListMessageGenesys";
import TableWidgetAnalysis from "components/widgets/tableWidgetAnalysis/tableWidgetAnaysis";
import WordCloudWidget from "components/widgets/wordCloudWidget";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";

const SingleGenesysInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;

  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [genesysSingleData, setGenesysSingleData] = useState({});
  const [speakersAnalysisAgent, setSpeakersAnalysisAgent] = useState({});
  const [speakersAnalysisCustomer, setSpeakersAnalysisCustomer] = useState({});
  const [genesysData, setGenesysData] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [openProfile, setOpenProfile] = useState(false);
  let apiVars = "calls/genesys";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");

  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "calls",
      url: `insights/calls${
        hasDatasourceOnly === "true" ? `/genesys/${dataSourceId}` : ""
      }`,
    },
  ];

  const getSingleInteractions = () => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result?.data)) {
        if (result?.data) {
          let kwArray = [];
          setSuccess(true);
          setGenesysData(result?.data);
          setGenesysSingleData(result?.data?.single_interactions);
          result?.data?.speakers_analysis_agent?.length &&
            setSpeakersAnalysisAgent(result?.data?.speakers_analysis_agent[0]);
          result?.data?.speakers_analysis_customer?.length &&
            setSpeakersAnalysisCustomer(
              result?.data?.speakers_analysis_customer[0],
            );
          result?.data?.top_keywords?.map((item) => {
            kwArray.push({ name: item?.id, value: item?.stats_count });
          });
          setKeywords(kwArray);
        }
      }
      setSuccess(true);
      setLoading(false);
    });
  };
  useEffect(() => {
    getSingleInteractions();
  }, []);
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: themesOptions } = useThemesOptions({
    locale: intl?.locale,
  });

  useEditSentiment();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();
  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();

  const preQueryData = {
    id: interactionId,
    lucidya_audio_id: genesysSingleData?.lucidya_audio_id,
    audio_id: genesysSingleData?.audio_id,
    tracker_id: genesysSingleData?.tracker_id,
    product_id: window.localStorage.cxm_id,
    data_source_id: genesysSingleData?.channel_id,
    created_at: genesysSingleData?.created_at,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: genesysSingleData?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setGenesysSingleData((prev) => ({
            ...prev,
            themes: updatedCategories,
          })),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: genesysSingleData?.sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setGenesysSingleData((prev) => ({
            ...prev,
            sub_themes: updatedThemes,
          })),
      },
    );
  };

  return (
    <>
      <UserProfileSideBar
        openProfile={openProfile}
        setOpenProfile={setOpenProfile}
        selectedCard={{}}
        dataSourceName={"GENESYS"}
        monitorId={trackerId}
        productId={window.localStorage?.getItem("cxm_id")}
      />
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {loading ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="genesys" />
            {isEmptyObj(genesysData) ? (
              <>
                <SnackBar
                  open={success != null ? true : false}
                  severity={"error"}
                  handleClose={() => {
                    setSuccess(null);
                  }}
                  message={CheckValueLocale(
                    "please_wait_and_try_again_later",
                    "",
                    {},
                    intl,
                  )}
                  title={CheckValueLocale("failed_error", "", {}, intl)}
                />
                <Box
                  component="div"
                  className="interaction-list single-interaction-empty-data"
                >
                  <NoDataFound showApplyFilterMsg={false} />
                </Box>
              </>
            ) : (
              <Box
                className="interaction-list interaction-list-main"
                component="div"
              >
                <div className="header-interaction interaction-head">
                  <div className="interaction-list-header interaction-header-align">
                    <Box
                      className={`user-single-header hover-show  `}
                      onClick={handleSideBar}
                    >
                      <span className="interaction-list__icon interaction-list__icon--genesys">
                        <img
                          src={Genesys}
                          alt={"Genesys"}
                          className="genesys-interactions-icon"
                        />
                      </span>
                      <span className="interaction-list-user-data genesys-user-data">
                        <p className="label label-name">{"genesys"}</p>
                        <p className="label label-username">
                          {genesysSingleData?.created_by}
                        </p>
                      </span>
                    </Box>
                    <Divider
                      orientation="vertical"
                      className="vertical-line-list-header"
                    />
                    <Typography className="interaction-list-type">
                      {CheckValueLocale("type_call", "", {}, intl)}
                    </Typography>
                  </div>
                  {!genesysSingleData?.missed_call ? (
                    <SentimentCategoriesThemes
                      themesData={{
                        btnId: "genesys-single-interaction-themes-btn",
                        options: themesOptions,
                        selected: genesysSingleData?.sub_themes,
                        handleChange: handleEditTheme,
                        loading: isThemesPending,
                      }}
                      categoriesData={{
                        btnId: "genesys-single-interaction-categories-btn",
                        options: categoriesOptions,
                        selected: genesysSingleData?.themes,
                        handleChange: handleEditCategories,
                        loading: isCategoriesPending,
                      }}
                      sentimentData={{
                        btnId: "genesys-single-interaction-sentiment-btn",
                        selected: genesysSingleData?.sentiment,
                        disabled: true,
                      }}
                      dividers={[1, 2]}
                    />
                  ) : null}
                </div>
                <InteractionsListMessageGenesys
                  obj={genesysSingleData}
                  audio={genesysSingleData?.audio}
                  missedCalls={genesysSingleData?.missed_call}
                />
              </Box>
            )}
            <Grid
              container
              spacing={2}
              my={3}
              className="single-genesys-widgets-wrapper"
            >
              <Grid item md={6}>
                <TableWidgetAnalysis
                  wordsList
                  firstColumnTitle={"genesys_metrics"}
                  secondColumnTitle={"genesys_agent"}
                  thirdColumnTitle={"genesys_Customer"}
                  title={"genesys_speaker_analysis"}
                  secondColumnTitleColor={"#F4663F"}
                  thirdColumnTitleColor={"#2AAAE2"}
                  showBorderColorGenesys
                  firstColumnData={speakersAnalysisAgent}
                  secondColumnData={speakersAnalysisCustomer}
                />
              </Grid>
              <Grid item md={6} className="genesys-top-keywords">
                <WordCloudWidget
                  title={"genesys_top_keywords"}
                  titleToolTip="genesys_intercations_top_keywords_tooltip"
                  data={keywords}
                  errorMessage={"no_keywords_found"}
                  wordCloudPreLoaderDataSources={[]}
                  preLoaderTrackerDataSources={[]}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleGenesysInteraction;
