import { Box, Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import "./userProfileHeader.scss";
import { CheckValueLocale } from "utils/helpers";

const UserProfilerHeader = () => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Box className="user-profile-header">
      <Box className="user-profile">
        <Box className="user-profile-info audience-profile">
          <Typography variant="h6">
            {CheckValueLocale("user_profile", "", {}, intl)}
          </Typography>
        </Box>
        <Button
          className="user-profile-info back-audience"
          onClick={() => {
            history.push(`/audience/list`);
          }}
          id="audience-user-profile-back-to-audience-btn"
        >
          {CheckValueLocale("back_to_audience", "", {}, intl)}
          <ArrowForwardIos className="profile-icon" />
        </Button>
      </Box>
    </Box>
  );
};

export default UserProfilerHeader;
