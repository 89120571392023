import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./summaryWidget.scss";
import SummaryHeader from "./component/summaryHeader";
import SummaryContent from "./component/summaryContent";
import SummaryMore from "./component/summaryMore";
import SummaryFooter from "./component/summaryFooter";
import SummaryFunction from "./component/summaryFunction";
import SummaryLoading from "./component/summaryLoading";
import SummaryEmpty from "./component/summaryEmpty";
import SummaryNotFound from "./component/summaryNotFound";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const SummaryWidget = ({
  pageParams,
  setOpenLuci,
  getSummaryStaticQuestions,
  summaryStaticQuestions,
  summaryStaticQuestionsLoading,
  mainQuestionNotification,
}) => {
  const intl = useIntl();
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  const {
    openAppliedFiltersModal,
    handleAppliedFiltersModal,
    applySummaryStaticQuestions,
    createLuciQuestion,
    getLastQuestionAnswer,
    lastQuestionAnswerLoading,
    summaryListAnswerLoading,
    postQuestionStatus,
    summaryLastQuestion,
    summaryLastAnswer,
    getQuestionStatus,
    getRemainingEnergy,
    getNextUpdateTime,
    calcDays,
    isUserThinking,
    snackBarStatus,
    setSnackBarStatus,
    getSummaryListAnswer,
    refreshCount,
    staticQuestions,
    currentToken,
    localizedDateTime,
  } = SummaryFunction();

  useEffect(() => {
    applySummaryStaticQuestions(summaryStaticQuestions);
  }, [summaryStaticQuestions]);

  useEffect(() => {
    if (pageParams?.dataSourceId) {
      getLastQuestionAnswer(pageParams);
    }
  }, [pageParams?.dataSourceId]);

  let isSummaryNotification =
    mainQuestionNotification?.summaryWidget === "true" &&
    mainQuestionNotification?.sectionName === pageParams?.sectionName &&
    mainQuestionNotification?.monitorId === pageParams?.monitorId;

  useEffect(() => {
    if (isSummaryNotification) {
      getSummaryListAnswer(
        mainQuestionNotification?.questionId,
        pageParams,
        getSummaryStaticQuestions,
      );
    }
  }, [mainQuestionNotification]);

  const isRemainingEnergy = getRemainingEnergy();
  const isDataAnalyze = !pageParams?.dataLoaded;
  const isNoData = pageParams?.dataEmpty;
  const isQuestionInProgress =
    summaryLastQuestion?.attributes?.status === "in_progress";
  const isQuestionInFailed =
    summaryLastQuestion?.attributes?.status === "failed";

  const summaryWidgetDisabled =
    isQuestionInProgress || isDataAnalyze || isNoData;
  const summaryActionDisabled =
    isQuestionInProgress ||
    !summaryLastAnswer?.length ||
    !isRemainingEnergy ||
    isQuestionInFailed;

  const isLastQuestionEmpty = !Object.keys(summaryLastQuestion || {})?.length;

  const isMonitorluciSummaryTokens =
    pageParams?.monitorluciSummaryTokens > currentToken && isLastQuestionEmpty;
  return (
    <>
      <Box className="summary-widget-wrapper">
        {lastQuestionAnswerLoading ||
        summaryListAnswerLoading ||
        summaryStaticQuestionsLoading ? (
          <SummaryLoading />
        ) : !summaryLastQuestion ? (
          <SummaryNotFound noFoundType="notFound" />
        ) : isMonitorluciSummaryTokens ? (
          <SummaryNotFound noFoundType="noLuciSummaryTokens" />
        ) : isLastQuestionEmpty ? (
          <SummaryNotFound noFoundType="notReturned" />
        ) : (
          <>
            <SummaryHeader
              summaryLastQuestion={summaryLastQuestion}
              getQuestionStatus={getQuestionStatus}
              isRemainingEnergy={isRemainingEnergy}
              getNextUpdateTime={getNextUpdateTime}
              calcDays={calcDays}
              isUserThinking={isUserThinking}
              pageParams={pageParams}
              createLuciQuestion={createLuciQuestion}
              openAppliedFiltersModal={openAppliedFiltersModal}
              handleAppliedFiltersModal={handleAppliedFiltersModal}
              refreshCount={refreshCount}
              getSummaryStaticQuestions={getSummaryStaticQuestions}
              staticQuestions={staticQuestions}
              isQuestionInProgress={isQuestionInProgress}
              isDataAnalyze={isDataAnalyze}
              isNoData={isNoData}
              summaryWidgetDisabled={summaryWidgetDisabled}
              localizedDateTime={localizedDateTime}
            />
            {isQuestionInProgress ? (
              <SummaryEmpty emptyType="inProgress" />
            ) : isQuestionInFailed ? (
              <SummaryEmpty emptyType="failed" />
            ) : summaryLastAnswer?.length ? (
              <>
                <SummaryContent
                  summaryExpanded={summaryExpanded}
                  summaryLastAnswer={summaryLastAnswer}
                />
                {summaryLastAnswer?.length > 4 ? (
                  <SummaryMore
                    summaryExpanded={summaryExpanded}
                    setSummaryExpanded={setSummaryExpanded}
                  />
                ) : null}
              </>
            ) : !isRemainingEnergy ? (
              <SummaryEmpty emptyType="noEnergy" />
            ) : (
              <SummaryEmpty emptyType="noData" />
            )}
            <SummaryFooter
              summaryLastQuestion={summaryLastQuestion}
              isRemainingEnergy={isRemainingEnergy}
              pageParams={pageParams}
              postQuestionStatus={postQuestionStatus}
              setOpenLuci={setOpenLuci}
              summaryWidgetDisabled={summaryWidgetDisabled}
              summaryActionDisabled={summaryActionDisabled}
            />
          </>
        )}
      </Box>
      {snackBarStatus?.success != null ? (
        <SnackBar
          open={snackBarStatus?.success != null ? true : false}
          handleClose={() => {
            setSnackBarStatus({
              success: null,
              snackBarHeading: "",
            });
          }}
          severity={snackBarStatus?.success ? "success" : "error"}
          message={CheckValueLocale(
            snackBarStatus?.snackBarHeading,
            "",
            {},
            intl,
          )}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default SummaryWidget;
