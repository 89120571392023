import React from "react";
import {
  Box,
  TextField,
  InputLabel,
  Typography,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const CustomDomainStepOne = (props) => {
  const intl = useIntl();
  const {
    formFieldErrors,
    customDomainError,
    customDomainName,
    handleInputChange,
  } = props;

  return (
    <Box className="custom-domain-container">
      <Box className="custom-domain-input-wrapper">
        <InputLabel>
          {CheckValueLocale("domain_name", "", {}, intl)}
          <Typography component="span" className="required">
            *
          </Typography>
        </InputLabel>
        <TextField
          placeholder={CheckValueLocale("custom_domain_example", "", {}, intl)}
          error={formFieldErrors?.name || customDomainError ? true : false}
          value={customDomainName}
          onChange={(e) => handleInputChange(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography component={"span"}>survey.</Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {formFieldErrors?.name || customDomainError ? (
        <FormHelperText error id="custom-domain-validation">
          {CheckValueLocale(
            customDomainError ? "custom_domain_validat" : formFieldErrors?.name,
            "",
            {},
            intl,
          )}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default CustomDomainStepOne;
