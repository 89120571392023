import { Breadcrumbs, Link, Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import Snackbar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import "./createHeader.scss";

const CreateHeader = (props) => {
  const intl = useIntl();
  let {
    mainHeaderTitle,
    mainHeaderLink,
    subHeaderTitle,
    subHeaderLink,
    cardHeaderHeading,
    errorMsg,
    setErrorMsg,
    isCreateScreen,
  } = props;

  let mainHeaderClassName = isCreateScreen
    ? "main-header-create-screen"
    : "main-header";
  let cardHeaderClassName = isCreateScreen
    ? "card-header-heading-create-screen"
    : "card-header-heading";

  return (
    <Box className={mainHeaderClassName}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          href={mainHeaderLink}
          className="textStyle"
          data-testid="innerFolder"
        >
          {CheckValueLocale(mainHeaderTitle, "", {}, intl)}
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href={subHeaderLink}
          aria-current="page"
          className="textStyle"
          data-testid="outerFolder"
        >
          {CheckValueLocale(subHeaderTitle, "", {}, intl)}
        </Link>
      </Breadcrumbs>
      <Box>
        <Typography
          variant="h6"
          component="div"
          className={cardHeaderClassName}
        >
          {CheckValueLocale(cardHeaderHeading, "", {}, intl)}
        </Typography>
      </Box>
      {errorMsg !== "" && errorMsg ? (
        <Snackbar
          open={errorMsg !== "" ? true : false}
          handleClose={() => {
            setErrorMsg("");
          }}
          severity={"error"}
          message={
            <Box>
              <Box component="span" className="card-header-error-msg">
                {CheckValueLocale(errorMsg, "", {}, intl)}
              </Box>
            </Box>
          }
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default CreateHeader;
