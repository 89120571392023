import { Box, Typography, Tab, Tabs } from "@mui/material";
import "../viewUser.scss";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Tooltip } from "@mui/material";
import { typeFormatMonitor } from "../../sharedLogic/inviteUserAdapter";

export const ViewUserTabs = ({ userData }) => {
  const intl = useIntl();
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function generateTabs(tabsInfo, error) {
    return tabsInfo?.map((tab, index) => (
      <Tab label={CheckValueLocale(tab?.name, "", {}, intl)} />
    ));
  }
  const topics = userData?.topics?.map((elem) => {
    return {
      name: elem?.monitor_model_name,
      data_sources: elem?.data_sources,
      proficiency: elem?.topics?.map((elem) => elem?.name),
      monitor_type_name: elem?.monitor_type_name,
    };
  });

  function transformWorkingTime(working_time) {
    let result = [];
    for (let day in working_time) {
      let times = working_time[day].map((timeSlot) => {
        let from = convertTo12HourFormat(timeSlot?.from);
        let to = convertTo12HourFormat(timeSlot?.to);
        return `${from} - ${to}`;
      });
      result.push({ name: day, times: times });
    }
    return result;
  }

  function convertTo12HourFormat(time) {
    let [hour, minute] = time?.split(":");
    let period = +hour < 12 ? "am" : "pm";
    if (+hour > 12) hour = hour - 12;
    if (+hour === 0) hour = 12;
    return `${hour}:${minute} ${period}`;
  }
  const getDataStringDatasources = (arrDatasoures) => {
    let res = [];
    res = arrDatasoures
      ?.map((item) => CheckValueLocale(item, "", {}, intl))
      ?.join(", ");
    return res;
  };

  function generateTabsContent(tabsInfo) {
    return tabsInfo.map((tab) => {
      return (
        <>
          {tabValue === tab?.index ? (
            <Box>
              {tab?.data.length > 0 ? (
                <Box className="view-lang-mini-box user-tab-lang-box">
                  {tab?.data?.map((item) => {
                    if (tab?.name === "topics") {
                      return (
                        <>
                          <Tooltip
                            id="setting-topic-user-view-tooltip"
                            title={
                              <>
                                {getDataStringDatasources(item?.data_sources)}

                                <Typography
                                  variant="span"
                                  className="monitor-type-name-topic"
                                >
                                  (
                                  {CheckValueLocale(
                                    typeFormatMonitor(item?.monitor_type_name),
                                    "",
                                    {},
                                    intl,
                                  )}
                                  )
                                </Typography>
                              </>
                            }
                            arrow
                            placement="top"
                          >
                            <Typography
                              className={
                                tab?.name === "topics"
                                  ? "lang-box en-box"
                                  : "lang-box"
                              }
                              variant="span"
                            >
                              {item?.name}
                              <Typography
                                className="lang-box-pro"
                                variant="span"
                              >
                                {`(${item?.proficiency?.toString()})`}
                              </Typography>
                            </Typography>
                          </Tooltip>
                        </>
                      );
                    }
                    return (
                      <Typography
                        className={
                          tab?.name === "topics"
                            ? "lang-box en-box"
                            : "lang-box"
                        }
                        variant="span"
                      >
                        {item?.title ? (
                          <>
                            {item?.title}
                            {item?.skill_level ? (
                              <Typography
                                className="lang-box-pro"
                                variant="span"
                              >
                                {` (${CheckValueLocale(
                                  item?.skill_level?.toLowerCase(),
                                  "",
                                  {},
                                  intl,
                                )})`}
                              </Typography>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {item?.ar_name && intl?.locale === "ar" ? (
                              item?.ar_name
                            ) : (
                              <>
                                {tab.name === "topics" ||
                                tab.name === "segments_tab"
                                  ? item?.name
                                  : CheckValueLocale(item?.name, "", {}, intl)}
                              </>
                            )}
                            {item?.proficiency ? (
                              <>
                                <Typography
                                  className="lang-box-pro"
                                  variant="span"
                                >
                                  {item?.ar_name
                                    ? ` (${CheckValueLocale(
                                        item?.proficiency?.toLowerCase(),
                                        "",
                                        {},
                                        intl,
                                      )})`
                                    : `(${item?.proficiency?.toString()})`}
                                </Typography>
                              </>
                            ) : (
                              <>
                                {item?.times
                                  ? item?.times?.map((time) => {
                                      return (
                                        <>
                                          <Typography
                                            className="lang-box-pro"
                                            variant="span"
                                          >
                                            {` (${CheckValueLocale(
                                              time?.toString().toLowerCase(),
                                              "",
                                              {},
                                              intl,
                                            )})`}
                                          </Typography>
                                        </>
                                      );
                                    })
                                  : null}
                              </>
                            )}
                          </>
                        )}
                      </Typography>
                    );
                  })}
                </Box>
              ) : (
                <Typography>
                  {CheckValueLocale(`no_${tab?.name}_assigned`, "", {}, intl)}
                </Typography>
              )}
            </Box>
          ) : null}
        </>
      );
    });
  }

  const tabsInfo = [
    {
      index: 0,
      name: "skills_tab",
      data: userData?.skills,
    },
    {
      index: 1,
      name: "sentiments_tab",
      data: userData?.sentiments,
    },
    {
      index: 2,
      name: "themes",
      data: userData?.themes,
    },
    {
      index: 3,
      name: "segments_tab",
      data: userData?.segments,
    },

    {
      index: 4,
      name: "topics",
      data: topics,
    },
    {
      index: 5,
      name: "dialects_tab",
      data: userData?.dialects,
    },
    {
      index: 6,
      name: "working_time",
      data: transformWorkingTime(
        userData?.working_time?.length
          ? userData?.working_time[0]?.working_time
          : [],
      ),
    },
  ];

  return (
    <>
      {/* user engagement info box  */}
      {userData?.hasEngagements ? (
        <Box className="main-view-user">
          <Box className="main-edit-user-card">
            <Typography className="edit-sec-tit">
              {CheckValueLocale("user_adv_information", "", {}, intl)}
            </Typography>
            <Box className="view-user-tabs">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="view tabs"
              >
                {generateTabs(tabsInfo)}
              </Tabs>
            </Box>
            <Box className="edit-sec-container">
              <Box className="edit-user-img">
                {generateTabsContent(tabsInfo)}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
