import { Grid, Typography, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import OverView from "components/widgets/overViewWidget";
import { useIntl } from "react-intl";
import "./whatsAppBody.scss";
import { useEffect, useState } from "react";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import PieChartWidget from "components/widgets/pieChartWidget";
import WordCloudWidget from "components/widgets/wordCloudWidget";
import SwitchPunchCardWidget from "components/widgets/switchPunchCardWidget";
import InteractionsList from "components/interactions-list/InteractionsList";
import ClientEnangement from "components/widgets/clientEngagement";
import { getNoOfDays } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import _ from "lodash";

const WhatsAppBody = (props) => {
  const intl = useIntl();
  let { response, startDate, endDate, setExcelSheetData } = props;
  //handle response of whatsApp
  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoaderDataSources: [],

    //interaction widget
    interactions: [],
    totalInteractions: [],
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentOvertime: [],
    sentimentPiechart: [],
    sentimentAnalysisPreLoaderDataSources: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],

    //clientEngagement widget
    clientEngagement: [],
    clientEngagementXLSX: [],
    clientEngagementAvgTime: {},
    clientEngagementResponseTime: {},
    clientEngagementPreLoaderDataSources: [],

    //contentType widget
    contentType: [],
    excelContentType: [],
    contentTypePreLoaderDataSources: [],

    //channel activity widget
    clientChannelActivity: [],
    agentChannelActivity: [],
    minValueAgent: 0,
    maxValueAgent: 0,
    minValueClient: 0,
    maxValueClient: 0,
    channelActivityPreLoaderDataSources: [],

    //messagesStatues widget
    messagesStatues: [],
    messagesStatuesPreLoaderDataSources: [],

    //theme widget
    themeXlxs: [],
    themeAnalysis: [],
    themeTrend: [],
    themeAnalysisPreLoaderDataSources: [],
    themeTrendPreLoaderDataSources: [],

    //dialects sub dialects widget
    dialects: [],
    subDialects: [],
    excelSubDialects: [],
    excelDialects: [],
    dialectsPreLoaderDataSources: [],

    //countries widget
    countries: [],
    excelTopCountries: [],
    countriesPreLoaderDataSources: [],
    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //latest interaction widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],
  };

  const [whatsappWidgets, setWhatsappWidgets] = useState(initialWidgetsState);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  //handle response with useEffect (when load page & any update)
  useEffect(() => {
    switch (response?.eventName) {
      case "Whatsapp__CASingleDataSourceInsightsPage__overview":
        if (response?.eventData?.overview) {
          let overViewArray = Object?.entries(
            response?.eventData?.overview,
          )?.map((key) => ({
            name: key[0],
            value: key[1],
          }));
          setWhatsappWidgets({
            ...whatsappWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__top_keywords":
        setWhatsappWidgets({
          ...whatsappWidgets,
          topKeywords: response?.eventData?.top_keywords?.map((item) => {
            return { name: item?.id, value: item?.stats_count };
          }),
          topKeywordsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__dialects_and_sub_dialects":
        setWhatsappWidgets({
          ...whatsappWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          excelDialects: _.cloneDeep([...response?.eventData?.dialects]),
          excelSubDialects: _.cloneDeep([...response?.eventData?.sub_dialects]),
          dialectsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__content_type":
        setWhatsappWidgets({
          ...whatsappWidgets,
          contentType: response?.eventData?.content_type_piechart,
          excelContentType: _.cloneDeep([
            ...response?.eventData?.content_type_piechart,
          ]),
          contentTypePreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__client_engagements":
        setWhatsappWidgets({
          ...whatsappWidgets,
          clientEngagement: response?.eventData,
          clientEngagementResponseTime: response?.eventData?.response_time,
          clientEngagementAvgTime: response?.eventData?.avg_time_until_read,
          clientEngagementPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__messages_status":
        setWhatsappWidgets({
          ...whatsappWidgets,
          messagesStatues: response?.eventData,
          messagesStatuesPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__top_countries":
        setWhatsappWidgets({
          ...whatsappWidgets,
          countries: response?.eventData?.top_countries,
          excelTopCountries: _.cloneDeep([...response.eventData.top_countries]),
          countriesPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setWhatsappWidgets({
          ...whatsappWidgets,
          interactions: response?.eventData?.interactions,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteractions: response?.eventData?.total_interactions,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return whatsappWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return whatsappWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return whatsappWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setWhatsappWidgets({
          ...whatsappWidgets,
          sentimentOvertime: {
            negative: whatsappWidgets?.negativeArray,
            positive: whatsappWidgets?.positiveArray,
            neutral: whatsappWidgets?.neutralArray,
          },
          sentimentPiechart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoaderDataSources: [response?.data_source],
        });

        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__themes_and_sub_themes":
        setWhatsappWidgets({
          ...whatsappWidgets,
          themeXlxs: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisPreLoaderDataSources: [response?.data_source],
          themeTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__contacts_activity":
        setWhatsappWidgets({
          ...whatsappWidgets,
          minValueAgent: response?.eventData?.min_value_agent,
          maxValueAgent: response?.eventData?.max_value_agent,
          maxValueClient: response?.eventData?.max_value_client,
          minValueClient: response?.eventData?.min_value_client,
          clientChannelActivity: response?.eventData?.client,
          agentChannelActivity: response?.eventData?.agent,
          channelActivityPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Whatsapp__CASingleDataSourceInsightsPage__top_posts_comments":
        setWhatsappWidgets({
          ...whatsappWidgets,
          latestInteractions: response?.eventData?.top_posts,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.interactions_count,
        });
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setWhatsappWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: whatsappWidgets?.totalInteractions,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: whatsappWidgets?.avgInteractions,
    },
  ];

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      whatsappWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([whatsappWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    whatsappWidgets?.latestInteractions,
    whatsappWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [
      whatsappWidgets?.overViewPreLoaderDataSources,
      whatsappWidgets?.topKeywordsPreLoaderDataSources,
      whatsappWidgets?.interactionsPreLoaderDataSources,
      whatsappWidgets?.dialectsPreLoaderDataSources,
      whatsappWidgets?.countriesPreLoaderDataSources,
      whatsappWidgets?.latestInteractionsPreLoaderDataSources,
      whatsappWidgets?.sentimentAnalysisPreLoaderDataSources,
      whatsappWidgets?.themeAnalysisPreLoaderDataSources,
      whatsappWidgets?.themeTrendPreLoaderDataSources,
      whatsappWidgets?.messagesStatuesPreLoaderDataSources,
      whatsappWidgets?.contentTypePreLoaderDataSources,
      whatsappWidgets?.clientEngagementPreLoaderDataSources,
      whatsappWidgets?.channelActivityPreLoaderDataSources,
    ];
    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      props?.preLoaderTrackerDataSources,
    );
    props?.setCheckAllDataFetchingExport(result);
    let excelData = { ...whatsappWidgets };
    props?.setExcelSheetData([{ ...excelData }]);
  }, [whatsappWidgets]);

  useEffect(() => {
    let excelData = { ...whatsappWidgets };
    let authorAcc = [
      whatsappWidgets?.clientChannelActivity,
      whatsappWidgets?.minValueClient,
      whatsappWidgets?.maxValueClient,
    ];
    let authorAgent = [
      whatsappWidgets?.agentChannelActivity,
      whatsappWidgets?.minValueAgent,
      whatsappWidgets?.maxValueAgent,
    ];
    let clientEngagementXLSX = [
      {
        name: "total_sent",
        value: `${whatsappWidgets?.clientEngagement?.total_sent}`,
      },
      {
        name: "instant_response",
        value: `${whatsappWidgets?.clientEngagement?.instant_response}`,
      },
      {
        name: "read_messages_rate",
        value: `${whatsappWidgets?.clientEngagement?.read_messages_rate}%`,
      },
      {
        name: "response_rate",
        value: `${whatsappWidgets?.clientEngagement?.response_rate}%`,
      },
      {
        name: "avg_time_until_read",
        value: whatsappWidgets?.clientEngagementAvgTime,
      },
      {
        name: "response_time",
        value: whatsappWidgets?.clientEngagementResponseTime,
      },
    ];
    excelData["clientEngagement"] = [...clientEngagementXLSX];
    excelData["clientChannelActivity"] = [...authorAcc];
    excelData["agentChannelActivity"] = [...authorAgent];
    setExcelSheetData([{ ...excelData }]);
  }, [whatsappWidgets]);

  return (
    <>
      <Box className="overview-chart">
        <OverView
          data={whatsappWidgets?.overview}
          isWhatsApp
          overViewPreLoaderDataSources={
            whatsappWidgets?.overViewPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Typography
        variant="h6"
        mt={5}
        mb={2}
        sx={{
          color: "#475569",
          fontSize: "16px",
          fontWeight: 500,
          letterSpacing: "0.12em",
        }}
      >
        {CheckValueLocale("metrics", "", {}, intl).toUpperCase()}
      </Typography>

      {/* Interations CHART */}
      <Box className="interations-chart">
        <LineChartWidget
          title={"interactions"}
          data={whatsappWidgets?.interactions}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/chat/whatsapp/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            whatsappWidgets?.interactionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      {/* Sentiment_analysis Chart */}
      <Box mt={3} className="sentiment-chart">
        <StackedLineWidget
          title={"sentiment_analysis"}
          data={whatsappWidgets?.sentimentOvertime}
          dataPiechart={whatsappWidgets?.sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            whatsappWidgets?.sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      {/* Client Engagement && Content Type CHARTS */}
      <Grid container spacing={2} my={3} className="pie-chart">
        <Grid item md={6}>
          <ClientEnangement
            title={"client_engagement"}
            clientEngagement={whatsappWidgets?.clientEngagement}
            showDownloadIcon
            clientEngagementPreLoaderDataSources={
              whatsappWidgets?.clientEngagementPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"content_type"}
            data={whatsappWidgets?.contentType}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={
              whatsappWidgets?.contentTypePreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>

      {/* Channel Activity CHART */}
      <Box my={3} className="channel-activity">
        <SwitchPunchCardWidget
          title="channel_activity"
          clientActivity={whatsappWidgets?.clientChannelActivity}
          agentActivity={whatsappWidgets?.agentChannelActivity}
          minValueAgent={whatsappWidgets?.minValueAgent}
          maxValueAgent={whatsappWidgets?.maxValueAgent}
          minValueClient={whatsappWidgets?.minValueClient}
          maxValueClient={whatsappWidgets?.maxValueClient}
          showDownloadIcon
          bigImg
          showGroupByClientAgent
          channelActivityPreLoaderDataSources={
            whatsappWidgets?.channelActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      {/* Messages Statues CHART */}
      <Box className="sentiment-chart">
        <StackedLineWidget
          title={"messages_statues"}
          data={whatsappWidgets?.messagesStatues}
          dataPiechart={whatsappWidgets?.sentimentPiechart}
          areaStyle={true}
          isSwitchCase={true}
          toolTipLabel={"toolTipLabel"}
          showDownloadIcon
          stackedLineWidgetPreLoaderDataSources={
            whatsappWidgets?.messagesStatuesPreLoaderDataSources
          }
          bigImg
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          colors={["#4CAF50", "#2AAAE2", "#80868C"]}
          showGroupBy={true}
          isPostsReachImpression
        />
      </Box>

      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          title={"themes"}
          data={whatsappWidgets?.themeAnalysis}
          themeAnalysisPreLoaderDataSources={
            whatsappWidgets?.themeAnalysisPreLoaderDataSources
          }
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          data={whatsappWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={
            whatsappWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      {/* (Dialects & Sub-dialects) & Top Countries CHART */}
      <Grid container spacing={2} mt={3} className="pie-chart">
        <Grid item md={6}>
          <PieChartWidget
            title={"dialects_subdialects"}
            data={whatsappWidgets?.dialects}
            subData={whatsappWidgets?.subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={
              whatsappWidgets?.dialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"countries"}
            data={whatsappWidgets?.countries}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={
              whatsappWidgets?.countriesPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>

      {/* Top Keywords CHART */}
      <Box my={3} className="topkewords_wrapper">
        <WordCloudWidget
          title={"top_keywords"}
          data={whatsappWidgets?.topKeywords}
          showDownloadIcon
          errorMessage={"no_keywords_found"}
          wordCloudPreLoaderDataSources={
            whatsappWidgets?.topKeywordsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      {/* Latest Interaction CHART */}
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          data={whatsappWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          latestInteractionsPreLoaderDataSources={
            whatsappWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase={true}
          showViewMoreButton={whatsappWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};
export default WhatsAppBody;
