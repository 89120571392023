import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";
import NewSegmentPopup from "../components/newSegment/newSegmentPopup";
import OptionsDropDown from "./optionsDropDown";
import { useIntl } from "react-intl";
import LinearProgress from "@mui/material/LinearProgress";
import userImage from "../../../images/userDefualt.png";
import "./audienceList.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getSocialIcon, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import {
  faFolderMinus,
  faFolderPlus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import SegmentCheckBox from "../components/SegmentCheckBox";
import RemoveIcon from "@mui/icons-material/Remove";
import { CheckValueLocale } from "../../../utils/helpers/index.js";
import AddSegment from "./segmentOption/addSegmentPopUp";
import ExcludeSegmentPopUp from "./segmentOption/excludeSegmentPopUp";
import SharedTable from "../../../shared/table/sharedTable";
import LucButton from "shared/lucButton/lucButton";
import NoDataFoundProducts from "components/no-Data";

const AudienceList = (props) => {
  let { startDate, endDate, identifier, filters, audience, page } = props;
  const intl = useIntl();
  const history = useHistory();
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const [audienceData, setAudienceData] = useState(audience?.data?.data);
  const [selectAll, setSelectAll] = useState(false);
  const [select, setSelect] = useState(false);
  const [allProfile, setAllProfile] = useState(false);
  const [isSelectAllProfiles, setIsSelectAllProfiles] = useState(true);
  const [count, setCount] = useState();
  const [isCheckedData, setIsCheckedData] = useState(false);
  const [addSegmentFlag, setAddSegmentFlag] = useState(false);
  const [sellectAllFlag, setSellectAllFlag] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleCreate = (severity, msg, tit, number, isAddOrExclude) => {
    props?.snackbar?.(severity, msg, tit, number, isAddOrExclude);
  };
  const [excludeSegmentFlag, setExcludeSegmentFlag] = useState(false);

  const toolTipAppear = (name, count, style) =>
    name?.length > count ? (
      <Tooltip
        title={<Box className="audience-tooltip">{name}</Box>}
        arrow
        placement="top"
      >
        <Typography className={style}>
          {name?.substring(0, count) + "..."}
        </Typography>
      </Tooltip>
    ) : (
      name
    );
  const handleAddSegment = () => {
    setAddSegmentFlag(true);
  };

  const newDataSourceArray = (data) => {
    let socialArray = [];
    data?.map((social) => {
      if (!data?.includes(social + "_DM")) {
        socialArray.push(social);
      }
    });
    return socialArray;
  };

  const handleCheckClick = (id) => {
    let checkedIds = [...audienceData];
    let checkToUpdate = checkedIds?.find((ids) => ids.id === id);
    checkToUpdate.active = !checkToUpdate?.active;
    if (!checkToUpdate?.active && selectAll) {
      handleSelectAll();
    }
    setAudienceData(checkedIds);
    let flag = true;
    for (let i = 0; i < audienceData?.length; i++) {
      flag = flag && audienceData[i].active;
    }
    if (flag && !selectAll) {
      handleSelectAll();
    }
    setSelect(false);
    setAllProfile(true);
    setIsCheckedData(true);
  };
  const handleExcludeSegmentFlag = () => {
    setExcludeSegmentFlag(true);
  };
  const handleSelectAll = () => {
    setSellectAllFlag(false);
    setSelectAll((prevState) => !prevState);
    setAudienceData(
      audienceData?.map((select) => ({ ...select, active: !selectAll })),
    );
    setSelect(true);
    setAllProfile(false);
    setIsCheckedData(true);
    setIsSelectAllProfiles(true);
  };

  const handleSelectAllProfiles = () => {
    setIsCheckedData(false);
    if (isSelectAllProfiles) {
      setCount(props?.audience?.data?.profiles_count);
      setSelectAll(true);
      setSellectAllFlag(true);
      setAudienceData(
        audienceData?.map((select) => ({ ...select, active: true })),
      );
    } else {
      setCount(0);
      setSellectAllFlag(false);
      setSelectAll(false);
      setAudienceData(
        audienceData?.map((select) => ({ ...select, active: false })),
      );
    }
  };
  const handleSelectAllProfile = () => {
    setIsSelectAllProfiles((prevState) => !prevState);
    handleSelectAllProfiles();
  };

  let usersId = [];
  audienceData?.map((item) => {
    if (item?.active === true) {
      usersId.push(item.id);
    }
  });

  let checkSelectedMsg =
    isSelectAllProfiles || isCheckedData
      ? "profiles_selected"
      : "all_profiles_selected";
  let countSelectedProfile;
  isCheckedData == false
    ? (countSelectedProfile = count)
    : (countSelectedProfile = usersId?.length);

  let filteredAudienceData = audienceData?.filter((item) => {
    return item.active === true;
  });

  useEffect(() => {
    setAudienceData(audience?.data?.data);
  }, [audience?.data?.data]);

  const checkHeaderCell = () => {
    return (
      <>
        <Box className="check-user">
          {audienceData?.length > 0 && (
            <Box className="item select-all">
              {handlelUserRoles("CDP", "CREATE_SEGMENT") === true ? (
                <SegmentCheckBox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  select={select}
                  isRemoveIcon={selectAll === false && usersId?.length}
                />
              ) : (
                <Tooltip
                  title={
                    <Box className="segment-tooltip">
                      <Box component="p">
                        {CheckValueLocale(
                          "check_box_segment_tooltip",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    </Box>
                  }
                  arrow
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        padding: "8",
                        width: 136,
                      },
                    },
                  }}
                >
                  <span className="check-viewer"></span>
                </Tooltip>
              )}

              {selectAll === true ? (
                <CheckIcon className="check-icon" />
              ) : selectAll === false && usersId?.length ? (
                <RemoveIcon className="check-icon-remove" />
              ) : (
                ""
              )}
            </Box>
          )}
          {CheckValueLocale("user_id", "", {}, intl)}
        </Box>
      </>
    );
  };

  const completRateCell = () => {
    return (
      <Tooltip
        title={
          <Box className="completeness-tooltip">
            <Box component="p">
              {CheckValueLocale("completeness_tooltip", "", {}, intl)}
            </Box>
          </Box>
        }
        placement="bottom"
        arrow
      >
        <Box className="completeness-rate-tooltip">
          {CheckValueLocale("completeness_rate", "", {}, intl)}
        </Box>
      </Tooltip>
    );
  };

  const mainTableHeader = () => {
    return (
      <>
        {audienceData?.length > 0 ? (
          <Box className="audience-user audience-header-btn">
            <Typography variant="h6">
              {CheckValueLocale("all_users", "", {}, intl)}(
              {props?.audience?.data?.profiles_count})
            </Typography>
            <Box className="audience-btns">
              {usersId?.length > 0 && (
                <Box className="segment-exclude-add-btns">
                  <LucButton
                    variant="flat"
                    onClick={handleExcludeSegmentFlag}
                    id="audience-list-exclude-segment-btn"
                    startIcon={<FontAwesomeIcon icon={faFolderMinus} />}
                  >
                    {CheckValueLocale("exclude_segment", "", {}, intl)}
                  </LucButton>
                  <LucButton
                    variant="flat"
                    onClick={handleAddSegment}
                    id="audience-list-add-segment-btn"
                    startIcon={<FontAwesomeIcon icon={faFolderPlus} />}
                  >
                    {CheckValueLocale("add_segment", "", {}, intl)}
                  </LucButton>
                  {handlelUserRoles("CDP", "CREATE_SEGMENT") && (
                    <Divider orientation="vertical" variant="middle" />
                  )}
                </Box>
              )}
              {handlelUserRoles("CDP", "CREATE_SEGMENT") && (
                <LucButton
                  variant="flat"
                  onClick={() => setShowPopup(true)}
                  id="audience-list-create-new-segment-btn"
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                >
                  {CheckValueLocale("new_segment", "", {}, intl)}
                </LucButton>
              )}
            </Box>
          </Box>
        ) : null}
        {handlelUserRoles("CDP", "CREATE_SEGMENT") && (
          <Box>
            {audienceData?.length > 0 && (
              <Box className="selected-user-parant">
                <Typography>
                  {CheckValueLocale(
                    checkSelectedMsg,
                    "",
                    { num: countSelectedProfile ? countSelectedProfile : 0 },
                    intl,
                  )}
                </Typography>

                <SegmentCheckBox
                  onChange={handleSelectAllProfile}
                  isAllProfile
                  isSelectAllProfiles={isSelectAllProfiles}
                  selectAll={selectAll}
                  allProfile={allProfile}
                  profilesCount={props?.audience?.data?.profiles_count}
                />
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  const tableHead = [
    {
      body: checkHeaderCell(),
      width: 80,
    },
    {
      body: CheckValueLocale("aduience_name", "", {}, intl),
      width: 230,
    },
    {
      body: CheckValueLocale("data_sources", "", {}, intl),
      width: 197.25,
    },
    {
      body: CheckValueLocale("create_date", "", {}, intl),
      width: 197.25,
    },
    {
      body: CheckValueLocale("segment", "", {}, intl),
      width: 197.25,
    },
    {
      body: completRateCell(),
      width: 197.25,
      permision: true,
    },
    {
      body: <></>,
      width: 67,
    },
  ];

  const checkBodyCell = (user) => {
    return (
      <Box className="check-user">
        <Box className="item" key={user.id}>
          {handlelUserRoles("CDP", "CREATE_SEGMENT") === true ? (
            <>
              <SegmentCheckBox
                checked={user?.active}
                onChange={() => handleCheckClick(user.id)}
              />
              <CheckIcon className="check-icon" />
            </>
          ) : (
            <Tooltip
              title={
                <Box className="segment-tooltip">
                  <Box component="p">
                    {CheckValueLocale(
                      "check_box_segment_tooltip",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                </Box>
              }
              arrow
              PopperProps={{
                className: "segment-box-tooltip",
              }}
            >
              <span className="check-viewer"></span>
            </Tooltip>
          )}
        </Box>
        {user.id}
      </Box>
    );
  };

  const nameCell = (user) => {
    return (
      <Box
        className="audience-image"
        onClick={() => {
          handlelUserRoles("CDP", "VIEW_PROFILE") &&
            history.push(`/audience/user-profile/${user?.id}`);
        }}
      >
        <Box className="audience-user-image">
          <img
            src={
              user.information.profile_image_url
                ? user.information.profile_image_url
                : userImage
            }
          />
        </Box>
        {user.information.name || user.information.identifier ? (
          <Box className="audience-information">
            <Typography className="audience-table-title audience-subtitle">
              {user?.information?.name ? (
                toolTipAppear(
                  user?.information?.name,
                  30,
                  "audience-table-title",
                )
              ) : (
                <Typography className="audience-no-name">
                  {CheckValueLocale("no_name", "", {}, intl)}
                </Typography>
              )}
            </Typography>
            <Typography className="audience-identifier audience-subtitle">
              <Box className="audience-social audience-social-name">
                <Box className="box-identifier">
                  {getSocialIcon(
                    user?.information?.identifier_source == "TIKTOK"
                      ? "TIKTOK_FW"
                      : user?.information?.identifier_source,
                    "box-icon",
                    "icon",
                  )}
                </Box>
                {user?.information?.identifier
                  ? toolTipAppear(
                      user?.information?.identifier_source == "TIKTOK"
                        ? intl.locale == "en"
                          ? `@${user?.information?.identifier}`
                          : `${user?.information?.identifier}@`
                        : user?.information?.identifier,
                      30,
                      "audience-identifier",
                    )
                  : ""}
              </Box>
            </Typography>
          </Box>
        ) : (
          <Typography className="audience-user-name">
            {CheckValueLocale("user_name", "", {}, intl)}
          </Typography>
        )}
      </Box>
    );
  };

  const dataSourcesCell = (user) => {
    return (
      <Box className="social">
        {user?.sources?.length
          ? newDataSourceArray(user?.sources)?.map((social, i) => {
              return (
                <Box className="social-icon icon" key={i}>
                  {getSocialIcon(social)}
                </Box>
              );
            })
          : "—"}
      </Box>
    );
  };

  const segmentsBodyCell = (user) => {
    const segmentsName = () => {
      return user?.segments?.length > 1
        ? `${user?.segments[0]?.name}${",..."}(+${user?.segments?.length - 1})`
        : user?.segments?.length == 1
          ? user?.segments[0]?.name
          : "—";
    };
    return (
      <Box>
        <Typography className="audience-main-name">{segmentsName()}</Typography>
      </Box>
    );
  };

  const completRateBodyCell = (user) => {
    return (
      <Box className="audience-rate">
        <Typography className="progress-value audience-main-name">{`${user.completeness_rate}%`}</Typography>
        <Typography className="progress">
          <LinearProgress
            variant="determinate"
            value={user.completeness_rate}
          />
        </Typography>
      </Box>
    );
  };

  const optionsCell = (user) => {
    return (
      <OptionsDropDown
        id={user.id}
        username={user.information.name}
        getAudience={props.getAudience}
        startDate={props.startDate}
        endDate={props.endDate}
        filters={filters}
      />
    );
  };
  const emptyBody = (audienceData) => {
    return (
      <>
        {(audienceData?.length == 0 && !props?.searchActivated) ||
        (audienceData?.length == 0 &&
          props?.searchActivated &&
          props?.searchText == "") ? (
          <Box className="no-data-cdp">
            <NoDataFoundProducts
              image="cdp"
              title="manage_your_customer_data"
              description="your_active_audience"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : audienceData?.length == 0 &&
          props?.searchActivated &&
          props?.searchText !== "" ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="no_data_matching"
            />
          </Box>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    const tabBody =
      audienceData?.length == 0
        ? []
        : audienceData?.map((item) => {
            return {
              id: item?.id,
              data: [
                {
                  body: checkBodyCell(item),
                  className: "audience-main-name",
                },
                {
                  body: nameCell(item),
                },
                {
                  body: dataSourcesCell(item),
                },
                {
                  body: item?.created_at
                    ? moment.unix(item?.created_at).format("YYYY/MM/DD ")
                    : "—",
                  className: "audience-main-name",
                },
                {
                  body: segmentsBodyCell(item),
                  className: "segments-main-name",
                },
                {
                  body: completRateBodyCell(item),
                  className: "audience-rate",
                },
                {
                  body: optionsCell(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(audienceData));
  }, [audienceData, intl.locale]);

  return (
    <>
      <SharedTable
        className="monitor-table"
        tableClassName="aduience-table"
        isSharedStyle
        tableHeader={tableHead}
        mainBox={mainTableHeader()}
        tableBody={tableBody}
        emptyBody={tableEmptyBody}
      />
      {addSegmentFlag && (
        <AddSegment
          segmentList={props?.segmentList}
          open={() => setAddSegmentFlag(true)}
          close={() => setAddSegmentFlag(false)}
          setAddSegmentFlag={setAddSegmentFlag}
          selectedUsersId={usersId}
          create={handleCreate}
          isSelectAllProfiles={sellectAllFlag}
          startDate={startDate}
          endDate={endDate}
          identifier={identifier}
          filters={filters}
          count={count}
          page={page}
        />
      )}

      {showPopup && (
        <NewSegmentPopup
          open={() => setShowPopup(true)}
          close={() => setShowPopup(false)}
          create={handleCreate}
          usersIds={usersId}
          allSegments={sellectAllFlag}
          segmentList={props?.segmentList ? props.segmentList : []}
          filteredAudienceData={filteredAudienceData}
          startDate={startDate}
          endDate={endDate}
          identifier={identifier}
          filters={filters}
          count={count}
          page={page}
        />
      )}
      {excludeSegmentFlag && (
        <ExcludeSegmentPopUp
          open={() => setExcludeSegmentFlag(true)}
          close={() => setExcludeSegmentFlag(false)}
          segmentList={props.segmentList}
          selectedUsersId={usersId}
          create={props.handleAlert}
          getAudience={props.getAudience}
          isSelectAllProfiles={sellectAllFlag}
          filteredAudienceData={filteredAudienceData}
          startDate={startDate}
          endDate={endDate}
          identifier={identifier}
          filters={filters}
          count={count}
          page={page}
        />
      )}
    </>
  );
};

export default AudienceList;
