import { Alert, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const GoogleEmailPolicyAlert = ({
  activeStep,
  activeDatasourcesName,
  stats,
}) => {
  const intl = useIntl();
  return activeStep === 1 && activeDatasourcesName ? (
    <Box
      className={`${
        stats?.activeDataSourceName === "GENESYS" &&
        "add-channel-msg-auth-genesys"
      } add-channel-msg-auth`}
    >
      <Alert severity="info">
        {stats?.activeDataSourceName === "GOOGLEMYBUSINESS" ? (
          <>
            <span>
              <b>{CheckValueLocale("note", "", {}, intl)}</b>
            </span>
            <ul className="gmb-info-messages">
              <li>{CheckValueLocale("add_channel_auth_info", "", {}, intl)}</li>
              <li>
                {CheckValueLocale(
                  "add_channel_gmb_account_note_info",
                  "",
                  {},
                  intl,
                )}
              </li>
            </ul>
          </>
        ) : stats?.activeDataSourceName === "GENESYS" ? (
          <>
            <span>
              <b>{CheckValueLocale("note_genesys", "", {}, intl)}</b>
            </span>
            <ul className="gmb-info-messages">
              <li>
                {CheckValueLocale(
                  "add_channel_auth_info_genesys",
                  "",
                  {},
                  intl,
                )}
              </li>
            </ul>
          </>
        ) : (
          <>
            {CheckValueLocale("note", "", {}, intl)}
            {CheckValueLocale("add_channel_auth_info", "", {}, intl)}
          </>
        )}
      </Alert>
    </Box>
  ) : null;
};

export default GoogleEmailPolicyAlert;
