import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const listOfTimesSla = ["FRT", "NRT", "TTC", "TUT"];
const listOfTypeTimesSla = ["minutes", "hours", "days"];
const maxNumOfCharName = 50;

function BodyCreatePopup({
  slaName,
  setSlaName,
  slaValues,
  setSlaValues,
  errorMsgName,
  setErrorMsgName,
  disabled,
}) {
  const intl = useIntl();
  const handleSLANameChange = (e) => {
    const value = e?.target?.value;
    if (value?.length > maxNumOfCharName) {
      setErrorMsgName("max_number_char_sla_name_error");
      return;
    } else if (value === "") {
      setErrorMsgName("sla_name_required");
    } else if (
      isPublicSpecialCharactersOrEmojis(value, undefined, true) ||
      removeSpecialCharacters(value)?.length === 0
    ) {
      setErrorMsgName("spam_keywords_rules");
    } else {
      setErrorMsgName("");
    }
    setSlaName(value);
  };
  const handleChangeSLAValues = (slaKey, slaValue, isValue) => {
    if (isValue) {
      let val = slaValue?.trim();
      let selectedValue = "";
      if (!val?.length) {
        selectedValue = "";
      } else {
        if (val?.length == 1 && val[0] == "0") {
          selectedValue = "0";
        } else {
          if (val[0] == "0") {
            const valueNum = val?.slice(1);
            if (/^\d+$/.test(valueNum)) {
              selectedValue = valueNum;
            } else {
              selectedValue = "0";
            }
          } else {
            val?.split("")?.map((dig) => {
              if (/^\d+$/.test(dig)) {
                selectedValue += dig;
              }
            });
          }
        }
      }
      setSlaValues((prev) => ({
        ...prev,
        [slaKey]: {
          ...prev[slaKey],
          value: selectedValue,
        },
      }));
    } else {
      setSlaValues((prev) => ({
        ...prev,
        [slaKey]: {
          ...prev[slaKey],
          unit: slaValue,
        },
      }));
    }
  };
  return (
    <Box className="body-new-sla-popup">
      <Box className="description-new-sla-popup">
        {CheckValueLocale("description_new_sla_popup", "", {}, intl)}
      </Box>
      <Box className="input-form-control">
        <InputLabel className="label-sla-name">
          {CheckValueLocale("sla_name", "", {}, intl)}
          <Typography component="span" className="required">
            *
          </Typography>
        </InputLabel>
        <TextField
          fullWidth
          autoFocus
          id="sla-name-input-form"
          name="slaName"
          placeholder={CheckValueLocale("sla_name", "", {}, intl)}
          error={errorMsgName ? true : false}
          helperText={CheckValueLocale(
            errorMsgName,
            "",
            { maxNumChar: maxNumOfCharName },
            intl,
          )}
          value={slaName}
          onChange={(e) => handleSLANameChange(e)}
          disabled={disabled}
        />
      </Box>
      <Box className="sla-box-divider" />
      <Box className="sla-value-container">
        <Box className="select-time-case-title">
          {CheckValueLocale("select_time_case_title", "", {}, intl)}
        </Box>
        <Box className="sla-value-form">
          {Object.keys(slaValues)?.map?.((slaKey, idx) => {
            const timeName = listOfTimesSla[idx];
            const { value, unit } = slaValues[slaKey];
            return (
              <Box className="input-time-form" key={slaKey}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel>
                      {CheckValueLocale(`sla_time_${timeName}`, "", {}, intl)}
                      <Typography component="span" className="required">
                        *
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id={`sla-time-input-form-${timeName}`}
                      name={`slaTime${timeName}`}
                      placeholder={0}
                      value={value}
                      error={value < 0 || value === ""}
                      onChange={(e) =>
                        handleChangeSLAValues(slaKey, e?.target?.value, true)
                      }
                      disabled={disabled}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      name={`slaTypeTime${timeName}`}
                      value={unit}
                      onChange={(e) => {
                        handleChangeSLAValues(slaKey, e?.target?.value);
                      }}
                      fullWidth
                      className="type-time-text-field-item"
                      id={`type-time-sla-select-${timeName}`}
                      disabled={disabled}
                    >
                      {listOfTypeTimesSla?.length &&
                        listOfTypeTimesSla?.map((val) => (
                          <MenuItem
                            className="type-time-form-field"
                            key={`type-time-${val}-${timeName}`}
                            value={val}
                            id={`type-time-${val}-${timeName}`}
                          >
                            {CheckValueLocale(
                              `type_time_sla_${val}`,
                              "",
                              {},
                              intl,
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default BodyCreatePopup;
