import {
  TextField,
  Typography,
  Select,
  MenuItem,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SharedBusinessForm = (props) => {
  const {
    formData,
    selectedBusinessFormData,
    setSelectedBusinessFormData,
    pageNum,
    isUpdateForm,
  } = props;
  const intl = useIntl();
  const handleChange = (event, key) => {
    const {
      target: { value },
    } = event;
    selectedBusinessFormData[key] = value;
    setSelectedBusinessFormData({ ...selectedBusinessFormData });
  };
  const ArrowTooltips = () => {
    return (
      <Tooltip
        title={
          <Box>
            <Typography>
              {CheckValueLocale("luci_business_hq_tooltip", "", {}, intl)}
            </Typography>
            <Box>
              {CheckValueLocale("luci_business_hq_tooltip_ex", "", {}, intl)}
            </Box>
          </Box>
        }
        arrow
        id="tooltip-business"
      >
        <Typography component={"span"} className="tooltip-hover lable-input">
          {CheckValueLocale("luci_business_hq", "", {}, intl)}
        </Typography>
      </Tooltip>
    );
  };
  const ArrowTooltipsgeographic = () => {
    return (
      <Tooltip
        title={
          <Box>
            <Typography>
              {CheckValueLocale(
                "luci_customers_geographic_tooltip",
                "",
                {},
                intl,
              )}
            </Typography>
            <Box>
              {CheckValueLocale(
                "luci_customers_geographic_tooltip_ex",
                "",
                {},
                intl,
              )}
            </Box>
          </Box>
        }
        arrow
        id="tooltip-business"
      >
        <Typography component={"span"} className="tooltip-hover lable-input">
          {CheckValueLocale("luci_customers_geographic", "", {}, intl)}
        </Typography>
      </Tooltip>
    );
  };
  return (
    <>
      {isUpdateForm ? (
        <Box>
          <Typography className="business-title">
            {CheckValueLocale("business_details_title", "", {}, intl)}
          </Typography>
          <Typography className="business-sub-title">
            {CheckValueLocale("business_details_sub_title", "", {}, intl)}
          </Typography>
        </Box>
      ) : null}
      <Box>
        {Object.keys(formData)?.map((step) => {
          const data =
            pageNum == 1 && step == "step1"
              ? formData?.step1
              : pageNum == 2 && step == "step2"
                ? formData?.step2
                : [];
          return (
            <>
              {isUpdateForm && data.length > 0 ? (
                <Box key={step}>
                  <Typography className="page-title">
                    {pageNum}
                    {". "}
                    {CheckValueLocale(
                      pageNum == 1
                        ? "luci_your_besiness"
                        : "luci_your_customers",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Box>
              ) : null}
              {data.map((item) => {
                return item?.type == "multiple_choices" ? (
                  <Box key={item?.key} className="item-row">
                    <Box
                      className={
                        selectedBusinessFormData[item?.key]?.length == 0
                          ? "validation-err"
                          : ""
                      }
                    >
                      <Typography className="select-label lable-input">
                        {CheckValueLocale("luci_" + item?.key, "", {}, intl)}
                      </Typography>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        className="select-input"
                        multiple={
                          item?.key == "customer_demographics_gender"
                            ? false
                            : true
                        }
                        MenuProps={{ style: { zIndex: 9999 } }}
                        displayEmpty
                        value={
                          selectedBusinessFormData[item?.key]
                            ? selectedBusinessFormData[item?.key]
                            : []
                        }
                        onChange={(e) => handleChange(e, item?.key)}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <span className="select-placeholder">
                                {CheckValueLocale(
                                  "luci_besiness_select",
                                  "",
                                  {},
                                  intl,
                                )}
                              </span>
                            );
                          }
                          return item?.key == "customer_demographics_gender"
                            ? CheckValueLocale("luci_" + selected, "", {}, intl)
                            : selected
                                ?.map((val) =>
                                  CheckValueLocale("luci_" + val, "", {}, intl),
                                )
                                .join(", ");
                        }}
                      >
                        {item?.value?.map((name) => (
                          <MenuItem
                            id={isUpdateForm ? "luci-menu-update" : "luci-menu"}
                            key={name}
                            value={name}
                          >
                            {!isUpdateForm ? null : (
                              <Checkbox
                                checked={
                                  selectedBusinessFormData[item?.key]?.indexOf(
                                    name,
                                  ) > -1
                                }
                              />
                            )}
                            <ListItemText
                              primary={CheckValueLocale(
                                "luci_" + name,
                                "",
                                {},
                                intl,
                              )}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                ) : item?.type == "free_text" ? (
                  <Box key={item?.key} className="item-row">
                    <Box
                      className={
                        selectedBusinessFormData[item?.key]?.length == 0
                          ? "validation-err"
                          : ""
                      }
                    >
                      {item?.key == "business_hq"
                        ? ArrowTooltips()
                        : item?.key == "customers_geographic"
                          ? ArrowTooltipsgeographic()
                          : null}
                      <TextField
                        id="outlined-basic"
                        placeholder={CheckValueLocale(
                          "luci_besiness_text",
                          "",
                          {},
                          intl,
                        )}
                        value={
                          selectedBusinessFormData[item?.key]
                            ? selectedBusinessFormData[item?.key]
                            : item.value
                        }
                        onChange={(e) => handleChange(e, item?.key)}
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                ) : null;
              })}
            </>
          );
        })}
      </Box>
    </>
  );
};

export default SharedBusinessForm;
