import { Card, CardContent } from "@mui/material";
import { classNames } from "utils/helpers";
import "./teamsSharedStyle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

export const SelectableCard = ({
  selectable,
  isSelected,
  children,
  onSelect,
}) => {
  return (
    <Card
      className={classNames(
        "team-selectable-card",
        selectable && "selectable",
        isSelected && "checked",
      )}
      onClick={selectable ? onSelect : undefined}
    >
      <CardContent className="team-selectable-card-content">
        {children}
        <FontAwesomeIcon
          className="team-selectable-card-check-icon"
          icon={faCheckCircle}
        />
      </CardContent>
    </Card>
  );
};
