import { Typography } from "@mui/material";
import DashboardMonitors from "../../createDashboard/createAggDashboard/aggDashboardMonitors";
import { CheckValueLocale } from "../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";

const MonitorsDatasourcesDashboard = (props) => {
  const intl = useIntl();
  const {
    dashboardData,
    handleMonitorValueChange,
    selectedMonitors,
    handleSelectMonitor,
    exceedLimit,
    getMonitorsListForDashboard,
    monitorsList,
    showLessMonitors,
    handleShowAllMonitors,
    handleShowLessMonitors,
    displayMonitorNameAndUsername,
    dashboardMonitorsLoading,
    countMonitorsSelected,
    setdashboardData,
    setSelectedMonitors,
    showAllMonitors,
    displayMonitorNameTooltip,
    isEditDashboard,
    setUpdatedValuesParams,
    updatedValuesParams,
    dashboardDetails,
    countMonitorsSelectedEdit,
  } = props;
  return (
    <>
      <Typography className="section-header">
        {CheckValueLocale("monitors_datasources", "", {}, intl)}
      </Typography>
      <DashboardMonitors
        dashboardData={dashboardData}
        setdashboardData={setdashboardData}
        handleMonitorValueChange={handleMonitorValueChange}
        selectedMonitors={selectedMonitors}
        setSelectedMonitors={setSelectedMonitors}
        handleSelectMonitor={handleSelectMonitor}
        exceedLimit={exceedLimit}
        getMonitorsListForDashboard={getMonitorsListForDashboard}
        monitorsList={monitorsList}
        showAllMonitors={showAllMonitors}
        showLessMonitors={showLessMonitors}
        handleShowAllMonitors={handleShowAllMonitors}
        handleShowLessMonitors={handleShowLessMonitors}
        displayMonitorNameAndUsername={displayMonitorNameAndUsername}
        displayMonitorNameTooltip={displayMonitorNameTooltip}
        dashboardMonitorsLoading={dashboardMonitorsLoading}
        countMonitorsSelected={countMonitorsSelected}
        isEditDashboard={isEditDashboard}
        setUpdatedValuesParams={setUpdatedValuesParams}
        updatedValuesParams={updatedValuesParams}
        monitorTypeVal={dashboardDetails?.attributes?.monitor_type}
        countMonitorsSelectedEdit={countMonitorsSelectedEdit}
      />
    </>
  );
};

export default MonitorsDatasourcesDashboard;
