import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleAuthorActivity, handleXlsData } from "../excelCommonFn";

const account_fb_AA = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var authorActivity = [],
      postsVolume = [],
      postsInteractions = [],
      reactions = [],
      topKeywords = [],
      topHashtags = [],
      topPosts = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "authorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                authorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData.push([authorActivity, lang.account_authors_activity]);
            break;
          //_______________________________________________________________________________

          case "postsVolume":
            value?.map((i) => {
              postsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([postsVolume, lang.posts_volume]);
            break;

          //_______________________________________________________________________________

          case "postsInteractions":
            value?.map((i) => {
              postsInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value[0],
                [lang.reactions]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData.push([postsInteractions, lang.posts_interactions]);
            break;
          //_______________________________________________________________________________

          case "reactions":
            value?.map((i) => {
              reactions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.like_reaction]: i?.value[0],
                [lang.love_reaction]: i?.value[1],
                [lang.care_reaction]: i?.value[2],
                [lang.haha_reaction]: i?.value[3],
                [lang.wow_reaction]: i?.value[4],
                [lang.angry_reaction]: i?.value[5],
                [lang.total]:
                  i?.value[0] +
                  i?.value[1] +
                  i?.value[2] +
                  i?.value[3] +
                  i?.value[4] +
                  i?.value[5],
              });
            });
            allSheetData.push([reactions, lang.posts_reactions]);
            break;

          // _______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([
              topKeywords,
              lang.posts_top_keywords_xlsx_sheetname,
            ]);
            break;
          // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([topHashtags, lang.posts_top_hashtags]);
            break;

          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData.push([topPosts, lang.top_posts]);
            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { account_fb_AA };
