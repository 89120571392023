import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../../createMonitor/createMonitor.scss";
import { isPublicSpecialCharactersOrEmojis } from "./specialCharOrEmojis";
import InfoIcon from "@mui/icons-material/Info";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { checkDirectionLang } from "./kewordsSharedFunctions";

const CustomerCareInput = ({
  coustomerCareVal,
  setCoustomerCareVal,
  dataSourceName,
  isSpecificFormat,
  monitorData,
  setMonitorErrors,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const intl = useIntl();

  const handleInputChange = (event) => {
    const val = event.target.value;
    setCoustomerCareVal([val]);
    let errorMessage = "";
    if (val.length > 15 && isSpecificFormat) {
      errorMessage = "username_max_char";
    } else if (
      isPublicSpecialCharactersOrEmojis(
        val,
        undefined,
        undefined,
        false,
        isSpecificFormat,
      )
    ) {
      errorMessage = "usernames_collect_ignore_tweets_error_name";
    } else if (monitorData?.ignoreTweets?.includes(val)) {
      errorMessage =
        "usernames_collect_ignore_tweets_error_exists_other_fields";
    }
    setErrorMsg(errorMessage);
    setMonitorErrors((prev) => ({
      ...prev,
      cstCareAccount: errorMessage,
    }));
  };
  return (
    <Box className="shared-customer-care-container">
      <CustomizedTooltip
        title={CheckValueLocale("customer_care_info", "", {}, intl)}
        placement="bottom"
        arrow
        id="radio-buttons-box"
        styles={{ ...customizedStyledTooltip, maxWidth: "223px" }}
      >
        <Typography component={"h3"}>
          {CheckValueLocale("customer_care_account", "", {}, intl)}
        </Typography>
      </CustomizedTooltip>
      <TextField
        id="outlined-error-helper-text"
        className={`${coustomerCareVal?.[0] ? checkDirectionLang(coustomerCareVal?.[0]) : ""}
          ${
            dataSourceName == "facebook"
              ? "customer-care-input-facebook customer-care-input"
              : "customer-care-input"
          }`}
        error={errorMsg}
        helperText={
          <span className={errorMsg ? "error-msg helper-msg" : "helper-msg"}>
            {CheckValueLocale(errorMsg ? errorMsg : "", "", {}, intl)}
          </span>
        }
        value={coustomerCareVal}
        onChange={(e) => handleInputChange(e)}
        onFocus={(e) => {
          if (e?.target?.value) handleInputChange(e);
        }}
        placeholder={CheckValueLocale(
          "insert_username_then_enter_without_atsign",
          "",
          {},
          intl,
        )}
      />
    </Box>
  );
};

export default CustomerCareInput;
