import Services from "./Services";

var Insights = {};

Insights.getInsightsCredentials = (queryData) => {
  return Services.getData("api/v3/channel_analytics/insights", queryData);
};

//email & gmail
Insights.getEmailCredentials = (queryData) => {
  return Services.getData("api/v3/channel_analytics/email/insights", queryData);
};
Insights.getGmailCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/email/gmail/insights",
    queryData,
  );
};
// Chat & Intercom & Whats
Insights.getMainChatCredentials = (queryData) => {
  return Services.getData("api/v3/channel_analytics/chat/insights", queryData);
};
Insights.getIntercomCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/intercom/insights",
    queryData,
  );
};
Insights.getWhatsappCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/whatsapp/insights",
    queryData,
  );
};
// Social & TW & IG & FB
Insights.getSocialMediaCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/insights",
    queryData,
  );
};
//public & private datasource
Insights.getPublicTwitterCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/twitter/public/insights",
    queryData,
  );
};

Insights.getPrivateTwitterCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/twitter/private/insights",
    queryData,
  );
};

Insights.getPublicFacebookCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/facebook/public/insights",
    queryData,
  );
};
Insights.getPrivateFacebookCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/facebook/private/insights",
    queryData,
  );
};
Insights.getPublicInstagramCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/instagram/public/insights",
    queryData,
  );
};
Insights.getPrivateInstagramCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/instagram/private/insights",
    queryData,
  );
};
//get linkedin credentials
Insights.getLinkedInCredentials = (queryData) => {
  return Services.getData(
    "/api/v3/channel_analytics/social_media/linkedin/public/insights",
    queryData,
  );
};
//get active channels
Insights.getActiveChannels = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/active_channels",
    queryData,
  );
};
//get google my business credentials
Insights.getGMBCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/ratings/gmb/insights",
    queryData,
  );
};

// get genesys credentials
Insights.getGenesysCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/calls/genesys/insights",
    queryData,
  );
};

Insights.updateGenesysAudio = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/calls/genesys/refresh_expired_link",
    queryData,
  );
};

Insights.getPublicExportPPT = (queryData, datasourcePath) => {
  return Services.getData(
    `api/v3/channel_analytics/${datasourcePath}`,
    queryData,
  );
};
Insights.getDialectsSubDialects = () => {
  return Services.getData("api/v3/dialects_sub_dialects");
};

Insights.getPublicTiktokCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/tiktok/public/insights",
    queryData,
  );
};
Insights.getPolicy = (policy_type) => {
  return Services.postData("api/v4/term_policies", {
    policy_type: policy_type,
  });
};
Insights.getApplyPolicy = () => {
  return Services.getData("api/v4/term_policies");
};

export default Insights;
