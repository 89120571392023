import { Avatar, Box, Typography } from "@mui/material";
import "./sideBarTabs.scss";
import {
  CheckValueLocale,
  isArabic,
  getTimeZone,
  differenceBetweenDates,
  extractDisplayInfoFromDataSource,
  timeCounter,
  classNames,
  handlelUserRoles,
  convertToSeconds,
  postTimeWithFormatAgo,
} from "utils/helpers";
import {
  faSmile,
  faMeh,
  faAngry,
  faUsers,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faUserTimes,
  faSpinner,
  faGearComplex,
} from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import verviedIcon from "images/engagements-images/vervied.svg";
import profileImg from "images/engagements-images/profileImg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import publicInstagram from "images/engagements-images/ig-icon-eng-round.svg";
import publicFacebook from "images/engagements-images/fb-icon-eng-round.svg";
import publicXPlatform from "images/engagements-images/xp-icon-eng-round.svg";
import Tooltip from "@mui/material/Tooltip";
import DefaultUser from "images/engagements-images/Avatar.svg";
import DefaultUserAvtar from "images/shared-images/user-management-default.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar.js";
import LucButton from "shared/lucButton/lucButton";
import { useHistory } from "react-router-dom";
import UserImage from "./componenets/userImage";
import aiAgentImageDefault from "images/ai-agents/ai-agents-icon.svg";
import UserAvatar from "shared/unifiedUserImage/userAvatar";

const socialAndSentimentIcons = (type) => {
  const types = {
    facebook: <img src={publicFacebook} />,
    twitter: <img src={publicXPlatform} alt="x-platform-logo" />,
    instagram: <img src={publicInstagram} />,
    neutral: <FontAwesomeIcon icon={faMeh} />,
    negative: <FontAwesomeIcon icon={faAngry} />,
    positive: <FontAwesomeIcon icon={faSmile} />,
    none: (
      <HelpOutlineIcon className="smiliesStyle" style={{ color: "#64748B" }} />
    ),
  };
  return types[type];
};

const CardItem = ({
  setCardState,
  cardState,
  item,
  setSelectedCard,
  selectedCard,
  allData,
  zIndex,
  frtSec,
  ttcSec,
  nrtSec,
  tutSec,
  userId,
  teamId,
  teams,
  users,
  slasList,
  slaId,
  aiAgentList,
  nrtDate,
  index,
  lastMsgDirecton,
  morThanOneItem,
  reload,
  setOpenSidebarToAssignTeam,
  cardText,
  id,
  openUserList,
  setOpenUserList,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [displayDate, setDisplayDate] = useState("");
  const [displayCounterFrt, setDisplayCounterFrt] = useState([
    0,
    "S",
    100,
    true,
  ]);
  const [displayCounterTtc, setDisplayCounterTtc] = useState([
    0,
    "S",
    100,
    true,
  ]);
  const [displayCounterTut, setDisplayCounterTut] = useState([
    0,
    "S",
    100,
    true,
  ]);
  const [displayCounterNrt, setDisplayCounterNrt] = useState([
    0,
    "S",
    100,
    true,
  ]);
  const [selectedUser, setSelectedUser] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [userAssignList, setUserAssignList] = useState([]);
  const [slaTimes, setSlaTimes] = useState({});
  const [usersLoading, setUsersLoading] = useState(false);
  const [selctUserLoading, setSelctUserLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [openTeamList, setOpenTeamList] = useState(false);
  const [selctTeamLoading, setSelctTeamLoading] = useState(false);
  const [ttcExpectedDate, setTtcExpectedDate] = useState(0);
  const [tutExpectedDate, setTutExpectedDate] = useState(0);
  const [nrtExpectedDate, setNrtExpectedDate] = useState(0);
  const [statusItemCard, setStatusItemCard] = useState("");
  const [displaiedCounter, setDisplaiedCounter] = useState("");
  const [aiAgentDetails, setAiAgentDetails] = useState({});
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    userName: "",
  });
  const [usersYouCanChoose, setUsersYouCanChoose] = useState([]);
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const userSignedIn = localStorage?.getItem("user_id");
  // to get saved sla data from redux we use slaKey
  const slaKey = `${item?.tweet_id ? item?.tweet_id : item?.id}-${
    item?.tracker_id
  }`;

  let intervalFrt;
  let intervalTtc;
  let intervalNrt;
  let intervalTut;
  const handleChangeSelectedCard = () => {
    setCardState({
      ...cardState,
      cardIndex: index,
      cardInitialState: statusItemCard,
      cardData: { ...item },
      changeStateDone: false,
    });
    setSelectedCard({ ...item });
  };

  const cardUserEmail = item?.user_email;
  const cardAiAgentId = item?.ai_agent?.ai_agent_id;
  const cardTeamId = item?.assigning_info?.team_id;
  const aiAgentId =
    cardAiAgentId && cardTeamId === -5
      ? cardAiAgentId
      : !item?.ai_agent &&
          cardUserEmail?.includes("Ai_Agent") &&
          cardTeamId === -5
        ? cardUserEmail?.split("_")?.[2]
        : null;

  //here we filter aiAgentList to get data of aiAgent for this card
  useEffect(() => {
    if (aiAgentId && aiAgentList?.length) {
      setAiAgentDetails(
        aiAgentList?.find((item) => +item?.id == +aiAgentId) || {},
      );
    }
  }, [aiAgentList, aiAgentId]);

  //here we filter slasList to get data of sla for this card
  useEffect(() => {
    if (slaId && slasList?.length) {
      let data = slasList?.filter((item) => +item?.id == +slaId);
      setSlaTimes(data?.length == 1 ? data[0] : {});
    }
  }, [slasList, slaId]);

  // here we handle whitch counter will be display with ttc counter
  useEffect(() => {
    if (!frtSec) {
      setDisplaiedCounter("frt");
    } else if (
      !nrtSec &&
      lastMsgDirecton == "inbound" &&
      item?.type == "dm" &&
      nrtDate
    ) {
      setDisplaiedCounter("nrt");
      //!tutSec || !userId
    } else if (!tutSec) {
      setDisplaiedCounter("tut");
    } else if (
      nrtSec &&
      lastMsgDirecton !== "inbound" &&
      item?.type == "dm" &&
      nrtDate
    ) {
      setDisplaiedCounter("nrt");
    } else {
      setDisplaiedCounter("frt");
    }
  }, [frtSec, tutSec, nrtSec, nrtDate, lastMsgDirecton]);

  // we have one useEffect for every counter to handle if it is run live or not
  useEffect(() => {
    // here we check if it well be live or not
    if (!frtSec && slaId && slaTimes?.id && displaiedCounter == "frt") {
      startIntervalFrt(1000);
    } else {
      clearInterval(intervalFrt);
      if (slaId && slaTimes?.id) {
        let frtExpectedDate =
          item?.created_at +
          convertToSeconds({ ...slaTimes?.first_response_time });
        const currentUnixDate = item?.created_at + frtSec;
        let { value, timer, percentage } = timeCounter(
          frtExpectedDate,
          currentUnixDate,
          convertToSeconds({ ...slaTimes?.first_response_time }),
        );
        setDisplayCounterFrt([...value, percentage, false]);
      }
    }

    return () => {
      clearInterval(intervalFrt);
    };
  }, [frtSec, slaId, slaTimes, displaiedCounter, reload]);

  useEffect(() => {
    setNrtExpectedDate(
      nrtDate + convertToSeconds({ ...slaTimes?.next_response_time }),
    );
    if (
      !nrtSec &&
      nrtDate &&
      item?.type == "dm" &&
      slaId &&
      slaTimes?.id &&
      displaiedCounter == "nrt"
    ) {
      startIntervalNrt(
        1000,
        nrtDate + convertToSeconds({ ...slaTimes?.next_response_time }),
      );
    } else {
      clearInterval(intervalNrt);
      if (slaId && slaTimes?.id) {
        let nrtExpectedDate =
          nrtDate -
          nrtSec +
          convertToSeconds({ ...slaTimes?.next_response_time });
        const currentUnixDate = nrtDate;
        let { value, timer, percentage } = timeCounter(
          nrtExpectedDate,
          currentUnixDate,
          convertToSeconds({ ...slaTimes?.next_response_time }),
        );
        setDisplayCounterNrt([...value, percentage, false]);
      }
    }

    return () => {
      clearInterval(intervalNrt);
    };
  }, [nrtSec, nrtDate, slaId, slaTimes, displaiedCounter, reload]);

  // handle change of selected user
  useEffect(() => {
    if (users?.length) {
      if (userId) {
        let user = users?.filter((item) => +item?.id === +userId)?.[0] || {};
        if (userAssignList?.length) {
          let list = userAssignList?.filter((item) => +item?.id !== +userId);
          setUsersList([...list]);
        }
        setSelectedUser({ ...user });
      } else {
        setSelectedUser({});
      }
    } else {
      setSelectedUser({});
      setUserAssignList([]);
      setUsersList([]);
    }
  }, [userId, users, userAssignList]);

  // handle change of selected team
  useEffect(() => {
    if (teams?.length) {
      if (teamId) {
        let team = teams?.filter((item) => +item?.id === +teamId)?.[0] || {};
        setSelectedTeam({ ...team });
      } else {
        setSelectedTeam({});
      }
    } else {
      setSelectedTeam({});
    }
  }, [teamId, teams]);

  useEffect(() => {
    if ((!ttcSec || statusItemCard !== "complete") && slaId && slaTimes?.id) {
      let ttcDate =
        item?.created_at + convertToSeconds({ ...slaTimes?.time_to_complete });
      setTtcExpectedDate(ttcDate);
      if (ttcSec) {
        let countedPeriod =
          convertToSeconds({ ...slaTimes?.time_to_complete }) - ttcSec;
        ttcDate =
          moment().unix() +
          parseInt(localStorage.companyInfo) * 60 * 60 +
          countedPeriod;
      }
      setTtcExpectedDate(ttcDate);
      startIntervalTtc(1000, ttcDate);
    } else {
      clearInterval(intervalTtc);
      if (slaId && slaTimes?.id) {
        let ttcExDate =
          item?.created_at +
          convertToSeconds({ ...slaTimes?.time_to_complete });
        const currentUnixDate = item?.created_at + ttcSec;
        let { value, timer, percentage } = timeCounter(
          ttcExDate,
          currentUnixDate,
          convertToSeconds({ ...slaTimes?.time_to_complete }),
        );

        setDisplayCounterTtc([...value, percentage, false]);
      }
    }

    return () => {
      clearInterval(intervalTtc);
    };
  }, [ttcSec, slaId, slaTimes, statusItemCard, reload]);

  useEffect(() => {
    //(!tutSec || !userId)
    if (!tutSec && slaId && slaTimes?.id && displaiedCounter == "tut") {
      let tutDate =
        item?.created_at +
        convertToSeconds({ ...slaTimes?.total_unassigned_time });
      if (tutSec) {
        let countedPeriod =
          convertToSeconds({ ...slaTimes?.total_unassigned_time }) - tutSec;
        tutDate =
          moment().unix() +
          parseInt(localStorage.companyInfo) * 60 * 60 +
          countedPeriod;
      }
      setTutExpectedDate(tutDate);
      startIntervalTut(1000, tutDate);
    } else {
      clearInterval(intervalTut);
      if (slaId && slaTimes?.id) {
        let tutExDate =
          item?.created_at +
          convertToSeconds({ ...slaTimes?.total_unassigned_time });
        const currentUnixDate = item?.created_at + tutSec;
        let { value, timer, percentage } = timeCounter(
          tutExDate,
          currentUnixDate,
          convertToSeconds({ ...slaTimes?.total_unassigned_time }),
        );

        setDisplayCounterTut([...value, percentage, false]);
      }
    }

    return () => {
      clearInterval(intervalTut);
    };
  }, [tutSec, slaId, slaTimes, displaiedCounter, reload]);

  //here we handle card created at
  useEffect(() => {
    setStatusItemCard(
      item?.system_replies ? item?.system_replies?.status : item?.status,
    );
    setDisplayDate(postTimeWithFormatAgo(item?.created_at, intl));
  }, [intl.locale, item]);

  const handleCounterFrt = () => {
    let frtExpectedDate =
      item?.created_at + convertToSeconds({ ...slaTimes?.first_response_time });
    const currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    let { value, timer, percentage } = timeCounter(
      frtExpectedDate,
      currentUnixDate,
      // here we retern values with seconds
      convertToSeconds({ ...slaTimes?.first_response_time }),
    );
    setDisplayCounterFrt([...value, percentage, false]);

    startIntervalFrt(statusItemCard !== "complete" ? timer : 0);
  };

  const startIntervalFrt = (timer = 0) => {
    // if timer = 0 we stop live counter
    clearInterval(intervalFrt);

    if (!timer) {
      // console.log(` card num : ${index + 1} Clearing interval Frt...`);
      return;
    }
    // console.log(`card num : ${index + 1} Frt every ${timer / 1000} S`);
    intervalFrt = setInterval(() => {
      handleCounterFrt();
    }, timer);
  };

  const handleCounterTtc = (date) => {
    const currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    let { value, timer, percentage } = timeCounter(
      date,
      currentUnixDate,
      convertToSeconds({ ...slaTimes?.time_to_complete }),
    );

    setDisplayCounterTtc([...value, percentage, false]);
    startIntervalTtc(timer, date);
  };

  const startIntervalTtc = (timer = 0, date = ttcExpectedDate) => {
    clearInterval(intervalTtc);

    if (!timer) {
      // console.log(` card num : ${index + 1} Clearing interval Ttc...`);
      return;
    }
    // console.log(`card num : ${index + 1} Ttc every ${timer / 1000} S`);
    intervalTtc = setInterval(() => {
      handleCounterTtc(date);
    }, timer);
  };

  const handleCounterTut = (date) => {
    const currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    let { value, timer, percentage } = timeCounter(
      date,
      currentUnixDate,
      convertToSeconds({ ...slaTimes?.total_unassigned_time }),
    );
    setDisplayCounterTut([...value, percentage, false]);
    startIntervalTut(statusItemCard !== "complete" ? timer : 0, date);
  };

  const startIntervalTut = (timer = 0, date = tutExpectedDate) => {
    clearInterval(intervalTut);

    if (!timer) {
      // console.log(` card num : ${index + 1} Clearing interval Tut...`);
      return;
    }
    // console.log(`card num : ${index + 1} Tut every ${timer / 1000} S`);
    intervalTut = setInterval(() => {
      handleCounterTut(date);
    }, timer);
  };

  const handleCounterNrt = (date) => {
    const currentUnixDate =
      moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    let { value, timer, percentage } = timeCounter(
      date,
      currentUnixDate,
      convertToSeconds({ ...slaTimes?.next_response_time }),
    );

    setDisplayCounterNrt([...value, percentage, false]);
    startIntervalNrt(statusItemCard !== "complete" ? timer : 0, date);
  };

  const startIntervalNrt = (timer = 0, date = nrtExpectedDate) => {
    clearInterval(intervalNrt);

    if (!timer) {
      // console.log(` card num : ${index + 1} Clearing interval Nrt...`);
      return;
    }
    // console.log(`card num : ${index + 1} Nrt every ${timer / 1000} S`);
    intervalNrt = setInterval(() => {
      handleCounterNrt(date);
    }, timer);
  };
  const isTwitter = item?.data_source?.toLowerCase?.() === "twitter";
  const isInstagram = item?.data_source?.toLowerCase?.() === "instagram";
  const isDM = item?.isDm;
  const { displayName, profilePicture } = extractDisplayInfoFromDataSource(
    item,
    {
      displayName: item?.user_name
        ? (isTwitter || isInstagram) && isDM && item?.name
          ? item?.name
          : item?.user_name
        : "user",
      profilePicture: item?.user_profile_image_url,
    },
  );

  const handleChangeUser = (userObject) => {
    setSelctUserLoading(true);
    let snackObj = {
      open: true,
      severity: "",
      userName: userObject?.attributes?.name,
    };
    let queryData = {
      new_user_id: +userObject?.id,
      old_user_id: +userId,
      new_user_team_id: +teamId,
      old_user_team_id: +teamId,
      interaction_id: item?.tweet_id || item?.id,
      monitor_id: item?.tracker_id,
      data_source_name: item?.data_source,
      product_id: +window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.assignTeamsAndUsers({ ...queryData }).then((res) => {
      snackObj.severity = "error";
      if (!res?.errorMsg) {
        snackObj.severity = "success";
        let tutValues = [displayCounterTut[0], displayCounterTut[1]];
        if (displaiedCounter != "tut") {
          let tutDate =
            item?.created_at +
            convertToSeconds({ ...slaTimes?.total_unassigned_time });
          if (tutSec) {
            let countedPeriod =
              convertToSeconds({ ...slaTimes?.total_unassigned_time }) - tutSec;
            tutDate =
              moment().unix() +
              parseInt(localStorage.companyInfo) * 60 * 60 +
              countedPeriod;
          }
          const currentUnixDate =
            moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
          let { value, timer, percentage } = timeCounter(
            tutDate,
            currentUnixDate,
            convertToSeconds({ ...slaTimes?.total_unassigned_time }),
          );
          tutValues = [...value];
        }
        // here update redux userId
        let slaDataCopy = { ...reduxSlaData };
        let updatedSlaData = {
          ...slaDataCopy[slaKey],
          userId: +userObject?.id || null,
          // tutSec:
          //   convertToSeconds({ ...slaTimes?.total_unassigned_time }) -
          //   convertToSeconds({
          //     value: tutValues[0],
          //     unit: tutValues[1],
          //   }),
        };

        let updatedReduxSlaData = {
          ...slaDataCopy,
          [slaKey]: updatedSlaData,
        };

        dispatch(slaData(updatedReduxSlaData));
        setOpenUserList("");
      } else {
        const isAgentPermissionError =
          res?.errorMsg?.response?.data?.exception?.message ===
          "User does not have access to this monitor";
        if (isAgentPermissionError) {
          snackObj.severity = "error";
          snackObj.isAgentPermissionError = true;
        }
      }
      setSelctUserLoading(false);
      setSnackBar({ ...snackObj });
      setTimeout(() => {
        setSnackBar({ open: false });
      }, 5000);
    });
  };

  // when open users list we call getTeamUsers to get users for selected team
  const handleOpenUsersList = (e) => {
    e?.stopPropagation?.();
    if (openUserList !== id) {
      setOpenUserList(id);
      if (teamId) {
        setUsersLoading(true);
        EngagementsController.getTeamUsers(
          teamId,
          window.localStorage.getItem("engagementsProductId"),
        ).then((res) => {
          setUserAssignList([]);
          setUsersList([]);
          if (
            !res?.errorMsg &&
            res?.data?.data?.attributes?.users?.data?.length
          ) {
            setUserAssignList([...res?.data?.data?.attributes?.users?.data]);
            let list = res?.data?.data?.attributes?.users?.data?.filter(
              (item) => +item?.id !== +userId,
            );
            const usersHasMonitorAcces = list
              ?.filter(
                (item) =>
                  item?.attributes?.accessible_monitors_ids?.includes(
                    +selectedCard?.tracker_id,
                  ) &&
                  (isUserAdmin || +item?.id === +userSignedIn),
              )
              ?.map((user) => +user?.id);
            setUsersYouCanChoose([...usersHasMonitorAcces]);
            let userObj =
              res?.data?.data?.attributes?.users?.data?.filter(
                (item) => +item?.id === +userId,
              )?.[0] || null;
            if (
              res?.data?.data?.attributes?.users?.data?.length &&
              !userObj &&
              userId
            ) {
              let slaDataCopy = { ...reduxSlaData };
              let updatedSlaData = {
                ...slaDataCopy[slaKey],
                userId: null,
              };
              let updatedReduxSlaData = {
                ...slaDataCopy,
                [slaKey]: updatedSlaData,
              };
              dispatch(slaData(updatedReduxSlaData));
            }
            setUsersList([...list]);
          }
          setUsersLoading(false);
        });
      }
    } else {
      setOpenUserList("");
    }
  };

  const handelAssignTeam = () => {
    setSelectedCard({ ...item });
    setOpenSidebarToAssignTeam(true);
  };
  const popperProps = useMemo(
    () => ({
      disablePortal: true,
      popperOptions: {
        positionFixed: true,
        modifiers: {
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
        },
      },
    }),
    [],
  );
  // disabling the selects of assigning user and team if the user is not admin
  const isUserAdmin = handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS");
  return (
    <Box
      key={item?.tweet_id ? item?.tweet_id : item?.id}
      className="card-engag-container"
      id={id}
    >
      <Box
        className={
          item?.tweet_id
            ? selectedCard?.tweet_id === item?.tweet_id &&
              item?.tracker_id === selectedCard?.tracker_id
              ? "main-engag-cards-item selected"
              : "main-engag-cards-item"
            : item?.id === selectedCard?.id &&
                item?.tracker_id === selectedCard?.tracker_id
              ? "main-engag-cards-item selected"
              : "main-engag-cards-item"
        }
        onClick={handleChangeSelectedCard}
        id={"engagement-card-" + index}
      >
        <Box className="card-item-header">
          <Box className="main-img-acc-name">
            <Box className="main-img">
              <img
                src={profilePicture}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = profileImg;
                }}
                id={`engagement-card-profile-img-${index}`}
              />
              <Box
                className={"main-icon " + item?.data_source}
                id={`engagement-card-data-source-icon-${index}`}
              >
                {socialAndSentimentIcons(item?.data_source)}
              </Box>
            </Box>
            <Box>
              <Box
                className="acc-name"
                id={`engagement-card-acc-name-${index}`}
              >
                {displayName?.length > 12 ? (
                  <Tooltip
                    title={displayName}
                    arrow
                    placement="top"
                    PopperProps={popperProps}
                  >
                    {`${displayName?.substring(0, 12)}...`}
                  </Tooltip>
                ) : (
                  displayName
                )}

                {item?.verified == "true" ? <img src={verviedIcon} /> : null}
              </Box>
              <Box className="acc-followers">
                <Box>{displayDate}</Box>
                <Box>{" | "}</Box>
                <Box id={"engagement-card-data-source-type-" + index}>
                  {CheckValueLocale(
                    item?.type == "dm"
                      ? "engagment_direct_message"
                      : item?.type,
                    "",
                    {},
                    intl,
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className={
              statusItemCard == "complete"
                ? "card-datasource complete"
                : "card-datasource"
            }
          >
            {allData?.filters?.engagement_status == "all" ? (
              <Box
                id={`card-engag-state-${index}`}
                className={"card-engag-state"}
              >
                {CheckValueLocale(
                  statusItemCard
                    ? statusItemCard == "reopen"
                      ? "active_item"
                      : statusItemCard + "_item"
                    : "new_item",
                  "",
                  {},
                  intl,
                )}
              </Box>
            ) : null}
            <Box
              className={`item-sentiment ${
                item?.sentiment ? item?.sentiment : "none"
              }`}
              id={`engagement-card-item-sentiment-${index}`}
            >
              {socialAndSentimentIcons(
                item?.sentiment ? item?.sentiment : "none",
              )}
            </Box>
          </Box>
        </Box>
        <Box
          className={
            cardText && isArabic(cardText)
              ? "card-item-contant ar"
              : "card-item-contant en"
          }
          id={`engagement-card-item-contant-${index}`}
        >
          {cardText}
        </Box>
        {aiAgentId ? (
          <Box className="card-item-footer-ai-agent">
            <Avatar
              src={aiAgentDetails?.ai_agent_image || aiAgentImageDefault}
              alt={aiAgentDetails?.name}
            />
            <Typography className="card-footer-ai-name">
              {aiAgentDetails?.name}
            </Typography>
          </Box>
        ) : (
          <Box className="card-item-footer">
            {!aiAgentId ? (
              <Box
                className="card-users-teams"
                sx={{
                  zIndex:
                    zIndex == 1 &&
                    (openUserList == id || openTeamList) &&
                    morThanOneItem
                      ? 4
                      : zIndex,
                }}
              >
                {/* users assigin */}
                <Tooltip
                  title={
                    openUserList == id
                      ? undefined
                      : selectedUser?.attributes?.name
                  }
                  arrow
                  placement="bottom"
                  PopperProps={popperProps}
                >
                  <Box className="card-user-img">
                    <Box
                      className={`card-user-img-styles-holder ${openUserList == id && "list-opened"} ${!isUserAdmin && "disabled-agent"}`}
                      onClick={(e) => {
                        handleOpenUsersList(e);
                      }}
                      id={`engagement-card-user-assign-${index}`}
                    >
                      <UserAvatar
                        fullName={selectedUser?.attributes?.name}
                        avatarSrc={
                          selectedUser?.id
                            ? selectedUser?.attributes?.avatar
                            : DefaultUser
                        }
                        sizes={{ ratio: "22px", fontSize: "9px" }}
                      />
                    </Box>
                  </Box>
                </Tooltip>
                {openUserList == id ? (
                  <ClickAwayListener onClickAway={() => setOpenUserList("")}>
                    <Box
                      className={
                        zIndex == 1 && openUserList == id && morThanOneItem
                          ? "card-users-container last-one"
                          : "card-users-container"
                      }
                      onClick={(e) => e?.stopPropagation?.()}
                    >
                      {usersLoading ? (
                        <Box className="users-list-loading">
                          <FontAwesomeIcon icon={faSpinner} spin />
                        </Box>
                      ) : (
                        <>
                          {userAssignList?.length && usersList?.length ? (
                            <Box className="main-card-users-list">
                              <Box>
                                {CheckValueLocale("assign_to", "", {}, intl)}
                              </Box>
                              {usersList?.map((item, x) => (
                                <Box
                                  key={x}
                                  className={`assign-item-countainer ${
                                    item?.id === selectedUser?.id &&
                                    "selected-user"
                                  } ${selctUserLoading && "item-loading"} ${!usersYouCanChoose?.includes(+item?.id) && "item-not-allowed-disabled"}`}
                                  onClick={() =>
                                    !selctUserLoading &&
                                    usersYouCanChoose?.includes(+item?.id) &&
                                    handleChangeUser(item)
                                  }
                                  id={`engagement-card-assign-item-countainer-${index}-${x}`}
                                >
                                  <Box className="assign-item-profile">
                                    <UserAvatar
                                      fullName={item?.attributes?.name}
                                      avatarSrc={item?.attributes?.avatar}
                                      sizes={{
                                        ratio: "34px",
                                        fontSize: "13px",
                                      }}
                                      showStatusIndicator={true}
                                      statusIndicatorValue={
                                        item?.attributes
                                          ?.availability_status !==
                                        "unavailable"
                                          ? "available"
                                          : "unavailable"
                                      }
                                      isBgDark={false}
                                    />
                                  </Box>
                                  <Box className="assign-item-details">
                                    <Box>{item?.attributes?.name}</Box>
                                    <Box className="assign-item-state">
                                      {CheckValueLocale(
                                        item?.attributes
                                          ?.availability_status !==
                                          "unavailable"
                                          ? "available"
                                          : "unavailable",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <Box className="main-card-users-empty">
                              {CheckValueLocale(
                                `no_agents_assign${!teamId ? "_no_team" : ""}`,
                                "",
                                {},
                                intl,
                              )}
                              {isUserAdmin ? (
                                <Box component={"p"}>
                                  {CheckValueLocale(
                                    `no_agents_assign_content${!teamId ? "_no_team" : ""}`,
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Box>
                              ) : null}
                              {isUserAdmin ? (
                                <LucButton
                                  className="link-user-management"
                                  size="small"
                                  variant="flat"
                                  id={`engagement-card-link-user-management-${index}`}
                                  startIcon={
                                    <FontAwesomeIcon icon={faGearComplex} />
                                  }
                                  onClick={() =>
                                    !teamId
                                      ? handelAssignTeam()
                                      : history.push(
                                          `/settings/teams/edit/${teamId}`,
                                        )
                                  }
                                >
                                  {CheckValueLocale(
                                    `${!teamId ? "engagement_details" : "team_name_settings"}`,
                                    "",
                                    { team: selectedTeam?.name },
                                    intl,
                                  )}
                                </LucButton>
                              ) : null}
                            </Box>
                          )}
                          {selectedUser?.id ? (
                            <Box
                              className={`card-user-unassign ${
                                selctUserLoading && "item-loading"
                              }`}
                              onClick={() =>
                                !selctTeamLoading &&
                                !selctUserLoading &&
                                handleChangeUser({})
                              }
                              id={`engagement-card-user-unassign-${index}`}
                            >
                              <FontAwesomeIcon icon={faUserTimes} />
                              {CheckValueLocale("unassign", "", {}, intl)}
                            </Box>
                          ) : null}
                        </>
                      )}
                    </Box>
                  </ClickAwayListener>
                ) : null}
                {/* team assigin */}
                <Tooltip
                  title={
                    openTeamList
                      ? undefined
                      : selectedTeam?.id
                        ? selectedTeam?.name
                        : CheckValueLocale("no_team_tooltip", "", {}, intl)
                  }
                  arrow
                  placement="bottom"
                  PopperProps={popperProps}
                >
                  <Box
                    className="card-user-img team-name with-sla"
                    id={`engagement-card-user-team-name-${index}`}
                  >
                    <Box className="team-name-driver"></Box>
                    {selectedTeam?.id ? (
                      <Box
                        className="team-name-exist"
                        id={`engagement-card-team-name-exist-${index}`}
                      >
                        <FontAwesomeIcon icon={faUsers} />
                        <span>{selectedTeam?.name}</span>
                      </Box>
                    ) : (
                      <span>{CheckValueLocale("no_team", "", {}, intl)}</span>
                    )}
                  </Box>
                </Tooltip>
              </Box>
            ) : null}
            <Box className="card-item-state">
              {!slaTimes?.id || !slaId ? (
                CheckValueLocale("no_slas", "", {}, intl)
              ) : (
                <>
                  <Box>
                    <Tooltip
                      title={CheckValueLocale("ttc", "", {}, intl)}
                      arrow
                      placement="bottom"
                      PopperProps={popperProps}
                    >
                      <Box
                        className="sla-countainer"
                        id={`engagement-card-sla-ttc-${index}`}
                      >
                        <Box className="sla-item">TTC:</Box>
                        {!displayCounterTtc[3] ? (
                          <Box
                            className={`sla-counter ${
                              displayCounterTtc[2] >= 0 &&
                              displayCounterTtc[2] <= 90
                                ? "green"
                                : displayCounterTtc[2] <= 95
                                  ? "orange"
                                  : "red"
                            }`}
                          >
                            <Box dir={"ltr"}>{`${displayCounterTtc[0]}`}</Box>
                            <Box>{`${CheckValueLocale(
                              displayCounterTtc[1],
                              "",
                              {},
                              intl,
                            )}`}</Box>
                          </Box>
                        ) : (
                          <FontAwesomeIcon icon={faSpinnerThird} spin />
                        )}
                      </Box>
                    </Tooltip>
                  </Box>
                  {displaiedCounter == "tut" ? (
                    <Box>
                      <Tooltip
                        title={CheckValueLocale("tut", "", {}, intl)}
                        arrow
                        placement="bottom"
                        PopperProps={popperProps}
                      >
                        <Box
                          className="sla-countainer"
                          id={`engagement-card-sla-tut-${index}`}
                        >
                          <Box className="sla-item">TUT:</Box>
                          {!displayCounterTut[3] ? (
                            <Box
                              className={`sla-counter ${
                                displayCounterTut[2] >= 0 &&
                                displayCounterTut[2] <= 90
                                  ? "green"
                                  : displayCounterTut[2] <= 95
                                    ? "orange"
                                    : "red"
                              }`}
                            >
                              <Box dir={"ltr"}>{`${displayCounterTut[0]}`}</Box>
                              <Box>{`${CheckValueLocale(
                                displayCounterTut[1],
                                "",
                                {},
                                intl,
                              )}`}</Box>
                            </Box>
                          ) : (
                            <FontAwesomeIcon icon={faSpinnerThird} spin />
                          )}
                        </Box>
                      </Tooltip>
                    </Box>
                  ) : displaiedCounter == "nrt" ? (
                    <Box>
                      <Tooltip
                        title={CheckValueLocale("nrt", "", {}, intl)}
                        arrow
                        placement="bottom"
                        PopperProps={popperProps}
                      >
                        <Box
                          className="sla-countainer"
                          id={`engagement-card-sla-nrt-${index}`}
                        >
                          <Box className="sla-item">NRT:</Box>
                          {!displayCounterNrt[3] ? (
                            <Box
                              className={`sla-counter ${
                                displayCounterNrt[2] >= 0 &&
                                displayCounterNrt[2] <= 90
                                  ? "green"
                                  : displayCounterNrt[2] <= 95
                                    ? "orange"
                                    : "red"
                              }`}
                            >
                              <Box dir={"ltr"}>{`${displayCounterNrt[0]}`}</Box>
                              <Box>{`${CheckValueLocale(
                                displayCounterNrt[1],
                                "",
                                {},
                                intl,
                              )}`}</Box>
                            </Box>
                          ) : (
                            <FontAwesomeIcon icon={faSpinnerThird} spin />
                          )}
                        </Box>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Box>
                      <Tooltip
                        title={CheckValueLocale("frt", "", {}, intl)}
                        arrow
                        placement="bottom"
                        PopperProps={popperProps}
                      >
                        <Box
                          className="sla-countainer"
                          id={`engagement-card-sla-frt-${index}`}
                        >
                          <Box className="sla-item">FRT:</Box>
                          {!displayCounterFrt[3] ? (
                            <Box
                              className={`sla-counter ${
                                displayCounterFrt[2] >= 0 &&
                                displayCounterFrt[2] <= 90
                                  ? "green"
                                  : displayCounterFrt[2] <= 95
                                    ? "orange"
                                    : "red"
                              }`}
                            >
                              <Box dir={"ltr"}>{`${displayCounterFrt[0]}`}</Box>
                              <Box>{`${CheckValueLocale(
                                displayCounterFrt[1],
                                "",
                                {},
                                intl,
                              )}`}</Box>
                            </Box>
                          ) : (
                            <FontAwesomeIcon icon={faSpinnerThird} spin />
                          )}
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        title={
          snackBar?.severity == "error" && !snackBar?.isAgentPermissionError
            ? CheckValueLocale("something_wrong", "", {}, intl)
            : null
        }
        message={CheckValueLocale(
          snackBar?.severity == "error"
            ? snackBar?.isAgentPermissionError
              ? "sla_agents_dont_have_permission"
              : "snack_assigned_user_error"
            : snackBar?.userName
              ? "snack_assigned_user"
              : "snack_unassigned_user",
          "",
          { name: snackBar?.userName },
          intl,
        )}
      />
    </Box>
  );
};

export default CardItem;
