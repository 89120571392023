import { FormControl } from "@mui/base";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import Facebook_verified from "images/social-icons/Facebook_verified.svg";

const getDefaultOptions = (options, selectedOptions) => {
  return options?.filter((option) => selectedOptions?.includes(option?.id));
};
const DropdownSelect = ({
  options,
  resetSearchList,
  defaultValue = [],
  placeholder,
  selectWrapperClassName,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    defaultValue?.length ? getDefaultOptions(options, defaultValue) : [],
  );
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
    props?.onChange &&
      props?.onChange?.(newSelectedOptions?.map((option) => option?.id));
  };
  const optionItemRender = (props, option, { selected }) => {
    return (
      <li
        {...props}
        className={`${props.className} monitor-select-option-item`}
        key={option?.id}
      >
        <Checkbox checked={selected} />
        {option?.profile_picture?.length > 0 ? (
          <Box className="monitor-select-option-image">
            <img src={option?.profile_picture} alt="" />
          </Box>
        ) : null}
        <Box className="monitor-select-option-title">
          <Typography>{option?.name}</Typography>
          {option?.verification_status === "blue_verified" ? (
            <img src={Facebook_verified} alt="fb_verified" />
          ) : null}
        </Box>
      </li>
    );
  };

  const searchItems = (e) => {
    if (e.key === "Enter" && props?.searchApi && inputValue?.length) {
      props?.getApiSearch(inputValue);
      setInputValue("");
    }
  };

  const blurInput = () => {
    setIsInputFocused(false);
    resetSearchList([]);
  };

  const inputRender = (params) => (
    <TextField
      {...params}
      onChange={(e) => setInputValue(e.target.value)}
      label={
        selectedOptions?.length
          ? `${
              selectedOptions?.join(",").split(",")?.length
            } ${CheckValueLocale("selected_num", "", {}, intl)}`
          : open || isInputFocused
            ? CheckValueLocale("Search_here", "", {}, intl)
            : placeholder
      }
      className={`monitor-autocomplete-input ${
        open || selectedOptions?.length === 0 ? "" : "selected"
      }`}
      onFocus={() => setIsInputFocused(true)}
      onBlur={blurInput}
      onKeyUp={(e) => searchItems(e)}
      error={props?.limitError || false}
    />
  );

  const getOptionSelected = (option, anotherOption) => {
    return option?.id == anotherOption?.id; // == sometimes data types are different and values are equal
  };

  useEffect(() => {
    // options may came after the component is mounted, so we need to update the selected options
    if (!selectedOptions?.length) {
      setSelectedOptions(
        defaultValue?.length ? getDefaultOptions(options, defaultValue) : [],
      );
    }
  }, [options]);
  return (
    <FormControl
      className={`monitor-autocomplete-wrapper ${
        selectWrapperClassName ? selectWrapperClassName : "default-wrapper"
      }`}
    >
      <Autocomplete
        disableClearable
        disableCloseOnSelect={true}
        autoComplete
        autoHighlight
        className="monitor-autocomplete-select"
        options={!props?.isLoadingSelectList ? options : []}
        inputValue={inputValue}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedOptions}
        getOptionLabel={(option) => ""}
        onChange={handleChange}
        renderOption={optionItemRender}
        isOptionEqualToValue={getOptionSelected}
        multiple
        renderInput={inputRender}
        noOptionsText={
          props?.isLoadingSelectList
            ? CheckValueLocale("loading_dotted", "", {}, intl)
            : CheckValueLocale("type_to_search_list", "", {}, intl)
        }
        renderTags={() => null} // Disable rendering of tags
        classes={{
          paper: "monitor-autocomplete-paper-menu",
        }}
        filterOptions={(x) => x}
        size="small"
        onKeyDown={(event) => {
          if (
            (event.key === "Enter" && inputValue?.length) ||
            event.key === "Backspace"
          ) {
            // Prevent's default 'Enter' , 'Backspace' behavior.
            event.defaultMuiPrevented = true;
          }
        }}
      />
    </FormControl>
  );
};

export default DropdownSelect;
