import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box, Divider, Typography } from "@mui/material";
import { CheckValueLocale, getTimeZone, isEmptyObj } from "utils/helpers";
import InteractionsController from "services/controllers/interactionsController";
import CircularLoading from "components/circularProgress";
import { useParams } from "react-router-dom";
import { useEditSentiment } from "pages/channelAnalytics/InsightsPage/hooks/useEditSentiment";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import SnackBar from "components/snackBar";
import Luci from "pages/luci";

const SingleLinkedInInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;
  const [openProfile, setOpenProfile] = useState(false);
  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);

  let apiVars = "social_media/linkedin/public";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  let timeZone =
    getTimeZone() > 0
      ? `(${CheckValueLocale("gmt", "", {}, intl)} +${getTimeZone()})`
      : `(${CheckValueLocale("gmt", "", {}, intl)} -${getTimeZone()})`;
  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true" ? `/linkedin-channel/${dataSourceId}` : ""
      }`,
    },
    {
      name: "LinkedIn",
      url: "insights/social-media/linkedin-channel/" + dataSourceId,
    },
  ];

  useEffect(() => {
    setPageNumber(pageNumber + 1);
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
      pageNumber,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result?.data)) {
        if (result?.data) {
          setConversation(result.data);
          setSuccess(true);
        }
        setLoading(false);
        setSuccess(false);
      }
    });
  }, []);
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: themesOptions } = useThemesOptions({
    locale: intl?.locale,
  });

  const { mutate: editSentiment, isPending: isSentimentPending } =
    useEditSentiment();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();
  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();

  const preQueryData = {
    id: interactionId,
    comment_id: interactionId,
    data_source_id: conversation?.channel_id,
    tracker_id: conversation?.tracker_id,
    product_id: window.localStorage.cxm_id,
    created_at: conversation?.created_at,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: conversation?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, themes: updatedCategories })),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: conversation?.sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sub_themes: updatedThemes })),
      },
    );
  };

  const handleEditSentiment = (updatedSentiment) => {
    editSentiment(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedSentiment,
          old_label: conversation?.sentiment,
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sentiment: updatedSentiment })),
      },
    );
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: conversation?.created_by,
            user_name: conversation?.created_by,
          }}
          dataSourceName={"LINKEDIN"}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {loading ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="linkedin" />
            {isEmptyObj(conversation) ? (
              <>
                <SnackBar
                  open={success != null ? true : false}
                  severity={"error"}
                  handleClose={() => {
                    setSuccess(null);
                  }}
                  message={CheckValueLocale(
                    "please_wait_and_try_again_later",
                    "",
                    {},
                    intl,
                  )}
                  title={CheckValueLocale("failed_error", "", {}, intl)}
                />
              </>
            ) : (
              <div className="single-intr">
                <Box className="single-intr-wrapper">
                  <div className="linkedin-wrapper">
                    <Box
                      onClick={handleSideBar}
                      className={`user-single-header hover-show`}
                    >
                      <span className="single-intr__icon single-intr__icon--linkedin">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </span>
                      <span className="linkedin-created-by">
                        {conversation?.created_by}
                      </span>
                    </Box>
                    <Divider
                      orientation="vertical"
                      className="vertical-line-list-header"
                    />
                    <Typography className="interaction-list-type">
                      {CheckValueLocale("type_comment", "", {}, intl)}
                    </Typography>
                  </div>
                  <Box>
                    <SentimentCategoriesThemes
                      themesData={{
                        btnId: "facebook-dm-single-interaction-themes-btn",
                        options: themesOptions,
                        selected: conversation?.sub_themes,
                        handleChange: handleEditTheme,
                        loading: isThemesPending,
                      }}
                      categoriesData={{
                        btnId: "facebook-dm-single-interaction-categories-btn",
                        options: categoriesOptions,
                        selected: conversation?.themes,
                        handleChange: handleEditCategories,
                        loading: isCategoriesPending,
                      }}
                      sentimentData={{
                        btnId: "facebook-dm-single-interaction-sentiment-btn",
                        options: sentimentsOptions,
                        selected: conversation?.sentiment,
                        handleChange: handleEditSentiment,
                        loading: isSentimentPending,
                      }}
                      dividers={[1, 2]}
                    />
                  </Box>
                </Box>
                <div className="single-intr__body linkedin-body">
                  <div className="single-intr-wrapper">
                    <p className="linkedin-text">{conversation?.text}</p>
                    <a
                      href={conversation?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faExternalLink}
                        className="linkedin-external-link"
                      />
                    </a>
                  </div>
                  <div className="tweet-meta box-image">
                    <div className="linkedin-reactions">
                      <p>
                        {moment
                          .unix(conversation?.created_at)
                          .utc()
                          .format("MM/DD/YYYY, LT ")}
                        {timeZone}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleLinkedInInteraction;
