import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "../../../monitors.scss";
import _ from "lodash";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { useParams, Link } from "react-router-dom";
import {
  convertXPlateformToTwitter,
  translateLocalsFromRabbitMQ,
} from "../../../../../utils/helpers/index";
import { CheckValueLocale } from "utils/helpers";

const AuthorAnalyticsSection = (props) => {
  const intl = useIntl();
  let {
    response,
    setDataToSend,
    dataToSend,
    topLanguages,
    setTopLanguages,
    topLanguagesPreLoader,
    setTopLanguagesPreLoader,
    dialects,
    setDialects,
    subDialects,
    setSubDialects,
    dialectsPreLoader,
    setDialectsPreLoader,
    topCities,
    setTopCities,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCountries,
    setTopCountries,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    genderDistribution,
    setGenderDistribution,
    genderDistributionPreLoader,
    setGenderDistributionPreLoader,
    accountTypes,
    setAccountTypes,
    accountTypesPreLoader,
    setAccountTypesPreLoader,
    topSources,
    setTopSources,
    topSourcesPreLoader,
    setTopSourcesPreLoader,
    topVerifiedEngagers,
    setTopVerifiedEngagers,
    topVerifiedEngagersPreLoader,
    setTopVerifiedEngagersPreLoader,
    topEngagers,
    setTopEngagers,
    topEngagersPreLoader,
    setTopEngagersPreLoader,
    topInfleuncers,
    setTopInfleuncers,
    topInfleuncersPreLoader,
    setTopInfleuncersPreLoader,
    commentsMentions,
    setCommentsMentions,
    commentsMentionsPreloader,
    setCommentsMentionsPreloader,
    unixDate,
  } = props;
  const urlParams = useParams();
  const monitorType = urlParams.monitorType;
  const monitorDataSource = convertXPlateformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorId = urlParams.monitorId;

  const monitorDetails = props.monitorData;
  //handle response
  const [totalPage, setTotalPage] = useState(0);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (response.eventName === "Twitter__EngagementsPage__top_languages") {
      setTopLanguages(
        translateLocalsFromRabbitMQ(
          "Twitter__EngagementsPage__top_languages",
          response.eventData.top_languages,
        ),
      );

      setDataToSend({
        ...dataToSend,
        languages: _.cloneDeep(response.eventData.top_languages),
      });

      setTopLanguagesPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response.eventName ===
      "Twitter__EngagementsPage__dialects_and_sub_dialects"
    ) {
      setDialects(response.eventData.dialects);
      setSubDialects(response.eventData.sub_dialects);
      setDialectsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      // ___________________________________________________________
    } else if (response.eventName === "Twitter__EngagementsPage__top_cities") {
      setTopCities(response.eventData.top_cities);
      setDataToSend({
        ...dataToSend,
        cities: _.cloneDeep(response.eventData.top_cities),
      });
      setTopCitiesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Twitter__EngagementsPage__top_countries"
    ) {
      setTopCountries(response.eventData.top_countries);
      setDataToSend({
        ...dataToSend,
        countries: _.cloneDeep(response.eventData.top_countries),
      });
      setTopCountriesPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Twitter__EngagementsPage__gender_distribution"
    ) {
      setGenderDistribution(response.eventData.top_genders);
      setGenderDistributionPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Twitter__EngagementsPage__account_types"
    ) {
      setAccountTypes(response.eventData.account_types);
      setAccountTypesPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);

      // ___________________________________________________________
    } else if (response.eventName === "Twitter__EngagementsPage__top_sources") {
      setTopSources(response.eventData.top_sources);
      setDataToSend({
        ...dataToSend,
        sources: _.cloneDeep(response.eventData.top_sources),
      });
      setTopSourcesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      return null;

      // ___________________________________________________________
    } else if (
      response.eventName === "Twitter__EngagementsPage__top_verified_engagers"
    ) {
      setTopVerifiedEngagers(response.eventData.top_verified_engagers);
      setTopVerifiedEngagersPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]); // ___________________________________________________________
    } else if (
      response.eventName === "Twitter__EngagementsPage__top_engagers"
    ) {
      setTopEngagers(response.eventData.top_engagers);
      setTopEngagersPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Twitter__EngagementsPage__top_influencers"
    ) {
      setTopInfleuncers(response.eventData.top_influencers);
      setTopInfleuncersPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Twitter__EngagementsPage__top_posts_comments"
    ) {
      setCommentsMentions(response.eventData.top_posts);
      setTotalPage(response.eventData.top_posts_count);
      setCommentsMentionsPreloader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setPostsAnalyticsData([
      { topLanguages: topLanguages },
      { dialects: dialects },
      { subDialects: subDialects },
      { topCountries: topCountries },
      { topCities: topCities },
      { genderDistribution: genderDistribution },
      { accountTypes: accountTypes },
      { topEngagers: topEngagers },
      { topSources: topSources },
      { topVerifiedEngagers: topVerifiedEngagers },
      { topInfleuncers: topInfleuncers },
      { commentsMentions: commentsMentions },
    ]);
  }, [
    topLanguages,
    dialects,
    subDialects,
    topCountries,
    topCities,
    genderDistribution,
    accountTypes,
    topEngagers,
    topSources,
    topVerifiedEngagers,
    topInfleuncers,
    commentsMentions,
  ]);

  // ________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTopLanguages([]);
      setTopLanguagesPreLoader([]);
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);
      setTopCities([]);
      setTopCitiesPreLoader([]);
      setTopCountries([]);
      setTopCountriesPreLoader([]);
      setGenderDistribution([]);
      setGenderDistributionPreLoader([]);
      setAccountTypes([]);
      setAccountTypesPreLoader([]);
      setTopSources([]);
      setTopSourcesPreLoader([]);
      setTopVerifiedEngagers([]);
      setTopVerifiedEngagersPreLoader([]);
      setTopEngagers([]);
      setTopEngagersPreLoader([]);
      setTopInfleuncers([]);
      setTopInfleuncersPreLoader([]);
      setCommentsMentions([]);
      setTotalPage(0);
      setCommentsMentionsPreloader([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name-secondary">
        {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_languages"}
              titleToolTip={"top_languages_fb_tooltip"}
              data={topLanguages}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={topLanguagesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
              inspectorName={"languages"}
              handleOpenQuickInspect={props.handleOpenQuickInspect}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip={"dialects_subdialects_tooltip"}
              data={dialects}
              subData={subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={dialectsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_countries"}
              titleToolTip={"top_countries_tw_tooltip"}
              data={topCountries}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
              pieChartPreLoaderDataSources={topCountriesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
              inspectorName={"countries"}
              handleOpenQuickInspect={props.handleOpenQuickInspect}
              showAI
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_cities"}
              titleToolTip={"top_cities_tw_tooltip"}
              data={topCities}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={topCitiesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
              inspectorName={"cities"}
              handleOpenQuickInspect={props.handleOpenQuickInspect}
              showAI
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"gender_distribution"}
              titleToolTip={"gender_distribution_tw_tooltip"}
              data={genderDistribution}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#2AAAE2", "#FF4081"]}
              pieChartPreLoaderDataSources={genderDistributionPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
              showAI
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"account_types"}
              titleToolTip={"account_types_tw_tooltip"}
              data={accountTypes}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={accountTypesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
              showAI
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              unixDate={unixDate}
              showProfile={true}
              productId={window.localStorage?.getItem("sm_id")}
              monitorId={monitorId}
              circularPP={true}
              itemsList
              title={"top_engagers"}
              titleToolTip={"top_engagers_tw_tooltip_twitter"}
              secondaryTitle={"engagement_overview_title"}
              isXPlatform
              data={topEngagers}
              leftColomnTitle={"users"}
              rightColomnTitle={"followers"}
              tableChartPreLoaderDataSources={topEngagersPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              tablePagination
              count={topEngagers.length}
              iconAfterTitle={props.twitterIcon}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <PieChartWidget
              title={"top_sources"}
              titleToolTip={"top_sources_tw_tooltip"}
              data={topSources}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
              pieChartPreLoaderDataSources={topSourcesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              iconAfterTitle={props.twitterIcon}
              inspectorName={"sources"}
              handleOpenQuickInspect={props.handleOpenQuickInspect}
            />
           </Grid> */}
          <Grid item xs={6}>
            <TableWidget
              unixDate={unixDate}
              showProfile={true}
              productId={window.localStorage?.getItem("sm_id")}
              monitorId={monitorId}
              circularPP={true}
              itemsList
              title={"top_verified_engagers"}
              titleToolTip={"top_verified_engagers_tw_tooltip_twitter"}
              secondaryTitle={"engagement_overview_title"}
              isXPlatform
              data={topVerifiedEngagers}
              leftColomnTitle={"users"}
              rightColomnTitle={"followers"}
              tableChartPreLoaderDataSources={topVerifiedEngagersPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              tablePagination
              count={topVerifiedEngagers.length}
              iconAfterTitle={props.twitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}

        <Grid container spacing={3} mb={10}>
          <Grid item xs={6}>
            <TableWidget
              unixDate={unixDate}
              showProfile={true}
              productId={window.localStorage?.getItem("sm_id")}
              monitorId={monitorId}
              circularPP={true}
              itemsList
              title={"top_infleuncers"}
              titleToolTip={"top_infleuncers_tw_tooltip_twitter"}
              secondaryTitle={"engagement_overview_title"}
              isXPlatform
              data={topInfleuncers}
              leftColomnTitle={"user"}
              rightColomnTitle={"followers"}
              tableChartPreLoaderDataSources={topInfleuncersPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              twitterIcon={props.twitterIcon}
              tablePagination
              count={topInfleuncers.length}
              iconAfterTitle={props.twitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      {/* _____________________________________________________ */}

      <Box mt={3}>
        <Box className="top-posts-head">
          <Box>{CheckValueLocale("top_comments_mentions", "", {}, intl)}</Box>
          <Link
            to={{
              pathname: `/monitor/${monitorType}/${urlParams.monitorDataSource}/comments_mentions/${monitorId}`,
              state: monitorDetails,
            }}
            className="top-posts-all"
            id={`comments-mentions-${
              monitorType?.toLowerCase() ?? "monitor-type"
            }-${
              monitorDataSource?.toLowerCase() ?? "monitor-data-source"
            }-comments-mentions-${monitorId ?? "monitor-id"}`}
          >
            {CheckValueLocale(
              "comments_mentions_all_comments_mentions",
              "",
              {},
              intl,
            )}
            ( {totalPage} )
          </Link>
        </Box>
        <QuestionCard
          unixDate={unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={commentsMentions}
          unAnsweredQuestion={totalPage}
          icon={xPlatform}
          iconColor={"twitter_icon"}
          showRetweet={true}
          showBanIcon={true}
          showScreenName={true}
          latestUnanswerPreloader={commentsMentionsPreloader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          updateAnalysis={props?.updateAnalysis}
          singleTweetAnalysis={props.singleTweetAnalysis}
          hideWhenRetweeted
          showHeart
          showDefaultImg
          categoriesOptions={props?.categoriesOptions}
          themesOptions={props?.themesOptions}
          sentimentsOptions={props?.sentimentsOptions}
        />
      </Box>
    </>
  );
};

export default AuthorAnalyticsSection;
