import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.js";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
//  Hot Module Replacement (HMR), it allows all kinds of modules to be updated at runtime without the need for a full refresh.
if (module.hot) {
  module.hot.accept();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
