import {
  faGlobe,
  faLock,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import SingleInputDateRangePicker from "./singleInputDateRangePicker";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const iconToDashboardAccess = {
  private_option: faLock,
  team_option: faUserGroup,
  public_option: faGlobe,
};

const LiveDashBoardAccess = ({
  dashboardAccessList,
  dashboardAccessTypeId,
  setDashboardAccessTypeId,
  customDataRange,
  setCustomDataRange,
  disabledPrivateType,
  setErrorMaxDateRange,
  errorMaxDateRange,
}) => {
  const intl = useIntl();
  return (
    <Box className="dashboard-access-container">
      <Box component="p" className="create-live-dashboard-label">
        {CheckValueLocale("live_dashboard_access_title", "", {}, intl)}
        <Box component="span" className="dashboard-required">
          *
        </Box>
      </Box>

      <FormControl>
        <Box className="access-type-wrapper">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={+dashboardAccessTypeId}
            onChange={(event) => {
              setDashboardAccessTypeId(+event?.target?.value);
            }}
            className="form-control-live-dashboard-access"
          >
            {dashboardAccessList?.map((dashboardAccess, index) => {
              const disabledType =
                disabledPrivateType &&
                dashboardAccess?.sharing_option === "private_option";

              return (
                <FormControlLabel
                  key={index}
                  className={`dashboard-form-control-label ${disabledType ? "disabled" : ""}`}
                  value={+dashboardAccess?.id}
                  control={<Radio disabled={disabledType} />}
                  label={
                    <CustomizedTooltip
                      title={CheckValueLocale(
                        disabledType
                          ? "live_dashboard_access_private_tooltip"
                          : "",
                        "",
                        {},
                        intl,
                      )}
                      arrow
                      placement={"bottom"}
                      styles={{ ...customizedStyledTooltip, maxWidth: "340px" }}
                    >
                      <Box className="card-container">
                        <Box className="card-live-dashboard-heading">
                          <FontAwesomeIcon
                            icon={
                              iconToDashboardAccess?.[
                                dashboardAccess?.sharing_option
                              ]
                            }
                          />
                          {CheckValueLocale(
                            `live_dashboard_access_${dashboardAccess?.sharing_option}`,
                            "",
                            {},
                            intl,
                          )}
                        </Box>
                        <Box className="card-live-dashboard-description">
                          {CheckValueLocale(
                            `live_dashboard_access_description_${dashboardAccess?.sharing_option}`,
                            "",
                            {},
                            intl,
                          )}
                        </Box>
                      </Box>
                    </CustomizedTooltip>
                  }
                />
              );
            })}
          </RadioGroup>
        </Box>
      </FormControl>
      {dashboardAccessTypeId ===
      dashboardAccessList?.find(
        (accessType) => accessType?.sharing_option == "public_option",
      )?.id ? (
        <Box>
          <SingleInputDateRangePicker
            customDataRange={customDataRange}
            setCustomDataRange={setCustomDataRange}
            maxDays={30} // Maximum number of days allowed to public live dashboard
            setErrorMaxDateRange={setErrorMaxDateRange}
            errorMaxDateRange={errorMaxDateRange}
          />
          <Alert severity="warning" className="alert-live-dashboard-date-range">
            {CheckValueLocale("alert_live_dashboard_date_range", "", {}, intl)}
          </Alert>
        </Box>
      ) : null}
    </Box>
  );
};

export default LiveDashBoardAccess;
