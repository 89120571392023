import { useIntl } from "react-intl";

import { CheckValueLocale } from "utils/helpers";
import exploreIcon from "images/explore-icon.svg";
import { Box, Typography } from "@mui/material";

const ExploreHeader = () => {
  const intl = useIntl();

  return (
    <Box className="explore-header-container">
      <Typography component="span" className="explore-title-head">
        {CheckValueLocale("explore_header_title", "", {}, intl)}
      </Typography>
      <img src={exploreIcon} alt="explore-icon" id="explore-icon" />
    </Box>
  );
};

export default ExploreHeader;
