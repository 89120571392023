import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Tooltip } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BreadCrumb from "components/breadCrumb";
import LucButton from "shared/lucButton/lucButton";
import "../surveyBuilder.scss";

const Header = (props) => {
  const intl = useIntl();
  const {
    surveyData,
    saveChangesFlag,
    handleSaveChanges,
    handlePublishBtn,
    setPreview,
    id,
    surveyLimitReach,
    publishFlag,
    saveLoading,
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const links = [
    { name: "my_surveys", url: "survey/list" },
    {
      name: <Box className="survey-builder-header">{surveyData?.name}</Box>,
      url: `survey/survey-builder/${id}`,
      addNameLocal: false,
    },
  ];

  return (
    <Box className="header">
      <Box>
        <BreadCrumb links={links} />
      </Box>
      <Box className="header-btns">
        <LucButton
          id="survey-builder-preview-btn"
          data-testid="save"
          type="secondary"
          variant="flat"
          size="small"
          minWidth={36}
          onClick={() => setPreview(true)}
        >
          <RemoveRedEyeIcon />
        </LucButton>
        <LucButton
          id="survey-builder-save-btn"
          data-testid="save"
          variant="outline"
          size="small"
          disabled={saveChangesFlag ? false : true}
          loading={saveLoading}
          onClick={handleSaveChanges}
        >
          {CheckValueLocale("save", "", {}, intl)}
        </LucButton>
        {handlelUserRoles("GENERAL", "CREATE_UTILITIES") && (
          <Box
            component={"span"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Tooltip
              title={CheckValueLocale("max_survey_count", "", {}, intl)}
              placement="bottom"
              arrow
              id="survey-max-btn-tooltip"
              open={
                surveyLimitReach && isHovered && surveyData?.status !== "draft"
              }
            >
              <Box data-testid="publish">
                <LucButton
                  id="survey-builder-publish-btn"
                  size="small"
                  onClick={handlePublishBtn}
                  disabled={
                    !publishFlag ||
                    (surveyLimitReach && surveyData?.status !== "draft")
                  }
                >
                  {CheckValueLocale("publish", "", {}, intl)}
                </LucButton>
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
