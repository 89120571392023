import { Tooltip } from "@mui/material";
import { handleDateLangFormat } from "utils/helpers";

export const tooltipCell = (text, textLength) => {
  return text?.length > textLength ? (
    <Tooltip title={text} placement="top" arrow>
      <span>{text?.substring(0, textLength)}...</span>
    </Tooltip>
  ) : (
    text
  );
};
export const ipSubnetCell = (arr) => {
  let ipSubnetText = "";
  arr?.map((ip, i) => {
    ipSubnetText =
      i == arr?.length - 1
        ? ipSubnetText + ip?.replaceAll('"', "")
        : ipSubnetText + ip?.replaceAll('"', "") + ", ";
  });
  return tooltipCell(ipSubnetText, 20);
};
export const dateCell = (date, appLang) => {
  return (
    <>
      {handleDateLangFormat(date, "MMMM DD, YYYY", appLang)}
      <span className="time-span">
        {handleDateLangFormat(date, "h:mm a", appLang)}
      </span>
    </>
  );
};

export const statusCell = (status) => (status ? "active" : "deActiveStatus");

export const lucidyaApisTypeLocale = (locale) => locale + "_API";
