import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from "@mui/material";
import { CheckValueLocale, classNames } from "utils/helpers";
import InputNumberField from "./componernts/inputNumberField";
import { useIntl } from "react-intl";
import CircularLoading from "components/circularProgress";

function BodyCreateAgentCapacity({
  agentEngagementsCount,
  setAgentEngagementsCount,
  listAgent,
  setListAgent,
  loading,
  setDispalyDistribute,
  dispalyDistribute,
  dispalyCustomizePerAgentSection,
  setDispalyCustomizePerAgentSection,
  disabled,
}) {
  const intl = useIntl();

  const handleInputChange = (e) => {
    let valueCount = e?.target?.value;
    let selectedValue = "";
    if (valueCount?.length == 1 && valueCount[0] == "0") {
      selectedValue = "0";
    } else {
      if (valueCount[0] == "0") {
        const valueNum = valueCount?.slice(1);
        if (/^\d+$/.test(valueNum)) {
          selectedValue = valueNum;
        } else {
          selectedValue = "0";
        }
      } else {
        valueCount?.split("")?.map((dig) => {
          if (/^\d+$/.test(dig)) {
            selectedValue += dig;
          }
        });
      }
    }

    setAgentEngagementsCount(selectedValue);

    const copyListAgent = listAgent?.map((agent) => {
      if (!agent?.customFlag) {
        return {
          ...agent,
          customValue: selectedValue,
        };
      } else {
        return agent;
      }
    });
    setListAgent(copyListAgent);
  };
  const handleInputCustomChange = (e) => {
    let valueCount = e?.target?.value;
    let selectedValue = "";
    if (valueCount?.length == 1 && valueCount[0] == "0") {
      selectedValue = "0";
    } else {
      if (valueCount[0] == "0") {
        selectedValue = valueCount?.slice(1);
      } else {
        valueCount?.split("")?.map((dig) => {
          if (/^\d+$/.test(dig)) {
            selectedValue += dig;
          }
        });
      }
    }

    const copyListAgent = listAgent?.map((agent) => {
      if (agent?.id == e?.target?.name) {
        return {
          ...agent,
          customFlag: true,
          customValue: selectedValue,
        };
      } else {
        return agent;
      }
    });
    setListAgent(copyListAgent);
  };
  const handleSwitchChage = (value) => {
    setDispalyDistribute(value);
    if (!value) {
      setDispalyCustomizePerAgentSection(value);
    }
  };

  return (
    <Box className={"body-create-agent-capacity-popup"}>
      {loading ? (
        <Box className="loading-agent-capacity-popup">
          <CircularLoading />
        </Box>
      ) : null}
      <Box className={"description-agent-capacity-popup"}>
        <Box className="define-agent-capacity" component={"h4"}>
          {CheckValueLocale("define_agent_capacity", "", {}, intl)}
        </Box>
        <Box className="setting-agent-capacity" component={"span"}>
          {CheckValueLocale("setting_agent_capacity", "", {}, intl)}
        </Box>
        <FormGroup className="switch-distribute-according-capacity">
          <FormControlLabel
            disabled={disabled}
            className={classNames(disabled && "disabled-field")}
            control={
              <Switch
                size="small"
                onChange={(e) => handleSwitchChage(e?.target?.checked)}
                inputProps={{ "aria-label": "controlled" }}
                checked={dispalyDistribute}
              />
            }
            label={CheckValueLocale(
              "distribute_according_capacity",
              "",
              {},
              intl,
            )}
          />
        </FormGroup>
      </Box>

      {dispalyDistribute ? (
        <Box className="agent-engagements-count-section">
          <Box className="input-form-control agent-capacity-main-count">
            <InputNumberField
              ToolTipTitle="agent_engagements_count_tooltip"
              labelInput="agent_engagements_count"
              name="agentEngagementsCount"
              value={agentEngagementsCount}
              onChange={handleInputChange}
              autoFocus
              disabled={disabled}
            />
          </Box>
          <FormGroup className="check-customize-per-agent">
            <FormControlLabel
              disabled={disabled}
              className={classNames(disabled && "disabled-field")}
              control={
                <Checkbox
                  checked={dispalyCustomizePerAgentSection}
                  onChange={() =>
                    setDispalyCustomizePerAgentSection((prev) => !prev)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={CheckValueLocale(
                "customize_per_agent_label",
                "",
                {},
                intl,
              )}
            />
          </FormGroup>
        </Box>
      ) : null}

      {dispalyCustomizePerAgentSection && dispalyDistribute ? (
        <Grid container spacing={2}>
          {listAgent?.map((agent) => (
            <Grid
              item
              xs={6}
              className="input-form-control agent-capacity-count-users"
            >
              <InputNumberField
                labelInput={agent?.agentName}
                name={agent?.id}
                value={agent?.customValue}
                onChange={handleInputCustomChange}
                disabled={disabled}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Box>
  );
}

export default BodyCreateAgentCapacity;
