import { useEffect, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import _ from "lodash";
import moment from "moment";
import SurveyResponseHeader from "./components/surveyResponseHeader";
import SurveyQuestion from "pages/audience/userActivites/survey/surveyQuestion.js";
import FetchingData from "components/fetchingData/fetchingData";
import { isEmptyArray, CheckValueLocale } from "utils/helpers";
import NoDataFound from "components/no-Data/noDataFound";

const SurveyResponseAlert = (props) => {
  const intl = useIntl();
  const {
    response,
    resetAllFields,
    surveyName,
    surveyPreloaderDatasource,
    alertCreatedAt,
  } = props;
  const initialWidgetsState = {
    //survey question widget
    questionsSurveys: {},
    questionsSurveysPreLoaderDataSources: [],
  };

  const [alertViewWidgets, setAlertViewWidgets] = useState(initialWidgetsState);
  const [surveyData, setSurveyData] = useState([]);

  useEffect(() => {
    let questionsSurveys = [];
    Object.values(alertViewWidgets?.questionsSurveys)?.map((val) => {
      questionsSurveys?.push(val);
    });
    setSurveyData(questionsSurveys);
  }, [alertViewWidgets?.questionsSurveys]);

  useEffect(() => {
    switch (response?.eventName) {
      case "NewPostAlert__individual_answer":
        setAlertViewWidgets({
          ...alertViewWidgets,
          questionsSurveys: response?.eventData?.individual_answer?.answer_1,
          questionsSurveysPreLoaderDataSources: [response?.data_source],
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...initialWidgetsState });
    }
  }, [resetAllFields]);
  return (
    <Container maxWidth="xl">
      <SurveyResponseHeader name={surveyName} />
      <Box className="survey-date-title">
        <Typography variant="span">
          {CheckValueLocale("submitted_at", "", {}, intl)}
          <Box className="date">
            {moment.unix(alertCreatedAt).utc().format("HH:mm A - DD/MM/YYYY")}
          </Box>
        </Typography>
      </Box>
      {_.isEqual(
        surveyPreloaderDatasource?.sort(),
        alertViewWidgets?.questionsSurveysPreLoaderDataSources?.sort(),
      ) !== true ? (
        <Box sx={{ position: "relative", top: "45%", right: 0 }}>
          <FetchingData />
        </Box>
      ) : isEmptyArray(alertViewWidgets?.questionsSurveys) ? (
        <NoDataFound />
      ) : (
        <SurveyQuestion questions={surveyData} isAlert={true} isCDP={false} />
      )}
    </Container>
  );
};

export default SurveyResponseAlert;
