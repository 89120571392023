import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import FormTitle from "./components/formTitle";
import FormDescription from "./components/formDescription";
import FormField from "./components/formField";
import FormButton from "./components/formButton";
import FormCloudFlare from "./components/formCloudFlare";
import { CheckValueLocale } from "utils/helpers";

const FormForgetPassword = ({
  stats,
  handleInputChange,
  errorFields,
  handleUserForgetPassword,
  handleBkToSignIn,
  handleVerifySuccess,
  handleVerifyExpire,
}) => {
  const intl = useIntl();
  return (
    <Box className="form-forget-wrapper">
      <Box className="form-forget-text-box">
        <FormTitle text={CheckValueLocale("forgot_password", "", {}, intl)} />
        <FormDescription
          text={CheckValueLocale("forgot_password_description", "", {}, intl)}
        />
      </Box>
      <FormField
        name="email"
        label={CheckValueLocale("email", "", {}, intl)}
        placeholder={CheckValueLocale("enter_your_email", "", {}, intl)}
        type="text"
        onChange={handleInputChange}
        error={stats?.wrongInput || errorFields?.email}
        errorField={errorFields?.email}
      />
      {stats?.wrongInput ? (
        <Box component="p" className="error-text-msg main-error">
          {" "}
          {stats?.errorText}{" "}
        </Box>
      ) : null}
      <FormCloudFlare
        handleVerifySuccess={handleVerifySuccess}
        handleVerifyExpire={handleVerifyExpire}
        errorField={errorFields?.verify}
      />
      <FormButton
        text={CheckValueLocale("reset_password", "", {}, intl)}
        variant="filled"
        onClick={handleUserForgetPassword}
        loading={stats?.forgetPasswordLoading}
      />
      <Box component="p" className="account-text">
        {CheckValueLocale("already_have_account", "", {}, intl)}
        <Box component="span" onClick={handleBkToSignIn}>
          {" "}
          {CheckValueLocale("back_to_login", "", {}, intl)}{" "}
        </Box>
      </Box>
    </Box>
  );
};
export default FormForgetPassword;
