import Services from "./Services";

var HomePage = {};

HomePage.getHomePageMonitors = (queryData) => {
  return Services.getData("api/v4/home_page/products", queryData);
};
HomePage.getHomePageNeedsAttention = (queryData) => {
  return Services.getData("api/v4/home_page/monitors", queryData);
};
HomePage.getHomePageReport = (queryData) => {
  return Services.getData("api/v4/home_page/report", queryData);
};
HomePage.getNotifications = (queryData) => {
  return Services.getData("api/v3/notifications", queryData);
};
HomePage.getCsmInfo = (queryData) => {
  return Services.getData("api/v4/home_page/csm_info", queryData);
};
HomePage.getDashboards = (queryData) => {
  return Services.getData("api/v4/homepage_dashboards", queryData);
};
export default HomePage;
