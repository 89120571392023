import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

export const CreateRouteFooter = ({
  handleCancel,
  disableNextStep,
  activeStep,
  handleChangeStep,
  isLoading,
  btnText,
}) => {
  const intl = useIntl();
  return (
    <Box className="steppers-buttons">
      <Box className="steppers-btn-wrapper">
        <Box>
          {activeStep > 0 ? (
            <LucButton
              type="secondary"
              variant="outline"
              id="back-btn-create"
              onClick={() => handleChangeStep(0)}
            >
              {CheckValueLocale("back", "", {}, intl)}
            </LucButton>
          ) : (
            <Box />
          )}
        </Box>
        <Box class="stepper-end">
          <LucButton
            onClick={handleCancel}
            variant="flat"
            disabled={isLoading}
            type="secondary"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            onClick={() => handleChangeStep(activeStep + 1)}
            loading={isLoading}
            disabled={disableNextStep || isLoading}
          >
            {CheckValueLocale(btnText, "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
    </Box>
  );
};
