import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { checkProgressExceeded, getPercentage } from "utils/helpers";
import Snackbar from "components/snackBar";
import { useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import {
  commasAfterDigit,
  CheckValueLocale,
  handlelUserRoles,
} from "utils/helpers";
import { useIntl } from "react-intl";
import "./subscription.scss";
import LucButton from "shared/lucButton/lucButton";
import moment from "moment";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme?.palette?.grey[theme?.palette?.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme?.palette?.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const EngagementPackage = (props) => {
  const {
    success,
    engagementInfo,
    setSuccess,
    sendPackageUpgrade,
    isLoadingUpgrade,
  } = props;

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [engagementRequest, setEngagementRequest] = useState(null);
  const [engagementSuccess, setEngagementSuccess] = useState(null);
  const [upgradePackageRequest, setUpgradePackageRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const requestChannelAnalDemo = () => {
    let queryData = {
      service_name: "request_engagement_demo",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result?.errorMsg) {
        setEngagementRequest(true);
        setSuccessMsg("request_demo");
        setEngagementSuccess(true);
      } else {
        setEngagementRequest(false);
        setErrorMsg("request_demo_failed");
        setEngagementSuccess(false);
      }
      setIsLoading(false);
    });
  };

  return (
    <div id="channel-analytics">
      <h3 className="sub__head">
        {CheckValueLocale("engagement", "", {}, intl)}
      </h3>
      <Card className="body">
        {props.engagementActive ? (
          <>
            <Card justifyContent="center">
              {engagementInfo?.packageInfo ? (
                <div>
                  <Typography>
                    {CheckValueLocale("company_info", "", {}, intl)}
                  </Typography>
                  <Card className="percentage">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={10}
                      p={2}
                    >
                      <div>
                        <Typography>
                          {CheckValueLocale("package_name", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {engagementInfo?.packageInfo?.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_start", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              engagementInfo?.packageInfo?.subscribe_start,
                            )?.split(" ")[0]
                          }
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_end", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              engagementInfo?.packageInfo?.subscribe_end,
                            )?.split(" ")[0]
                          }
                        </Typography>
                      </div>
                    </Stack>
                  </Card>
                  <Card justifyContent="center">
                    <Typography>
                      {CheckValueLocale("package_stats", "", {}, intl)}
                    </Typography>
                    <Card className="percentage">
                      <CardContent>
                        <Grid container lg={12} md={12} sm={12} spacing={5}>
                          <Grid item lg={12} md={12} sm={12}>
                            <Typography className="head-social-list">
                              {CheckValueLocale(
                                "managers_engagement",
                                "",
                                {},
                                intl,
                              )}
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              mb={1}
                            >
                              <div>
                                {commasAfterDigit(
                                  engagementInfo?.packageInfo
                                    ?.package_used_admins,
                                )}{" "}
                                {CheckValueLocale("of", "", {}, intl)}{" "}
                                {commasAfterDigit(
                                  engagementInfo?.packageInfo?.max_admins,
                                )}
                              </div>
                              <div className="percentage">
                                {`${getPercentage(
                                  engagementInfo?.packageInfo
                                    ?.package_used_admins,
                                  engagementInfo?.packageInfo?.max_admins,
                                )}%`}
                              </div>
                            </Stack>
                            <BorderLinearProgress
                              variant="determinate"
                              className={
                                engagementInfo?.packageInfo
                                  ?.package_used_admins >=
                                engagementInfo?.packageInfo?.max_admins
                                  ? "red-color"
                                  : ""
                              }
                              value={
                                checkProgressExceeded(
                                  engagementInfo?.packageInfo
                                    ?.package_used_admins,
                                  engagementInfo?.packageInfo?.max_admins,
                                ) ??
                                getPercentage(
                                  engagementInfo?.packageInfo
                                    ?.package_used_admins,
                                  engagementInfo?.packageInfo?.max_admins,
                                )
                              }
                              id="linear-progress-color"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Card>

                  <Card justifyContent="center">
                    <Typography>
                      {CheckValueLocale(
                        "package_max_x_dm_replies",
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                    <Card className="percentage">
                      <CardContent>
                        <Grid container lg={12} md={12} sm={12} spacing={5}>
                          <Grid item lg={6} md={6} sm={12}>
                            <Typography className="head-social-list">
                              {CheckValueLocale(
                                "package_24_hrs_limit",
                                "",
                                {},
                                intl,
                              )}
                              {getRenewDate(
                                engagementInfo?.packageInfo
                                  ?.rate_limit_24h_reset,
                                intl,
                              )}
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              mb={1}
                            >
                              <div>
                                {commasAfterDigit(
                                  engagementInfo?.packageInfo
                                    ?.agent_replies_day_count,
                                )}{" "}
                                {CheckValueLocale("of", "", {}, intl)}{" "}
                                {commasAfterDigit(
                                  engagementInfo?.packageInfo
                                    ?.max_replies_per_day,
                                )}
                              </div>
                              <div className="percentage">
                                {engagementInfo?.packageInfo
                                  ?.max_replies_per_day === 0
                                  ? "100.0%"
                                  : `${getPercentage(
                                      engagementInfo?.packageInfo
                                        ?.agent_replies_day_count,
                                      engagementInfo?.packageInfo
                                        ?.max_replies_per_day,
                                    )}%`}
                              </div>
                            </Stack>
                            <BorderLinearProgress
                              variant="determinate"
                              className={
                                engagementInfo?.packageInfo
                                  ?.agent_replies_day_count >=
                                engagementInfo?.packageInfo?.max_replies_per_day
                                  ? "red-color"
                                  : ""
                              }
                              value={
                                checkProgressExceeded(
                                  engagementInfo?.packageInfo
                                    ?.agent_replies_day_count,
                                  engagementInfo?.packageInfo
                                    ?.max_replies_per_day,
                                ) ??
                                getPercentage(
                                  engagementInfo?.packageInfo
                                    ?.agent_replies_day_count,
                                  engagementInfo?.packageInfo
                                    ?.max_replies_per_day,
                                )
                              }
                              id="linear-progress-color"
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={12}>
                            <Typography className="head-social-list">
                              {CheckValueLocale(
                                "package_15_mins_limit",
                                "",
                                {},
                                intl,
                              )}
                              {getRenewDate(
                                engagementInfo?.packageInfo
                                  ?.rate_limit_15m_reset,
                                intl,
                              )}
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              mb={1}
                            >
                              <div>
                                {commasAfterDigit(
                                  engagementInfo?.packageInfo
                                    ?.agent_replies_15_mins_count,
                                )}{" "}
                                {CheckValueLocale("of", "", {}, intl)}{" "}
                                {commasAfterDigit(
                                  engagementInfo?.packageInfo
                                    ?.max_replies_per_15_minutes,
                                )}
                              </div>
                              <div className="percentage">
                                {engagementInfo?.packageInfo
                                  ?.max_replies_per_15_minutes === 0
                                  ? "100.0%"
                                  : `${getPercentage(
                                      engagementInfo?.packageInfo
                                        ?.agent_replies_15_mins_count,
                                      engagementInfo?.packageInfo
                                        ?.max_replies_per_15_minutes,
                                    )}%`}
                              </div>
                            </Stack>
                            <BorderLinearProgress
                              variant="determinate"
                              className={
                                engagementInfo?.packageInfo
                                  ?.agent_replies_15_mins_count >=
                                engagementInfo?.packageInfo
                                  ?.max_replies_per_15_minutes
                                  ? "red-color"
                                  : ""
                              }
                              value={
                                checkProgressExceeded(
                                  engagementInfo?.packageInfo
                                    ?.agent_replies_15_mins_count,
                                  engagementInfo?.packageInfo
                                    ?.max_replies_per_15_minutes,
                                ) ??
                                getPercentage(
                                  engagementInfo?.packageInfo
                                    ?.agent_replies_15_mins_count,
                                  engagementInfo?.packageInfo
                                    ?.max_replies_per_15_minutes,
                                )
                              }
                              id="linear-progress-color"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Card>
                </div>
              ) : (
                <Typography>
                  {CheckValueLocale("channel_analytics_demo", "", {}, intl)}
                </Typography>
              )}
            </Card>
            {handlelUserRoles("GENERAL", "CREATE_REQUEST") && (
              <Stack direction="row" justifyContent="flex-end">
                <LucButton
                  onClick={() => {
                    sendPackageUpgrade(engagementInfo?.id);
                    setUpgradePackageRequest(true);
                  }}
                  id="settings-subscription-engagement-package-btn"
                  loading={isLoadingUpgrade}
                >
                  {CheckValueLocale("request_upgrade", "", {}, intl)}
                </LucButton>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography>
              {CheckValueLocale("engagement_msg", "", {}, intl)}
            </Typography>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  requestChannelAnalDemo();
                }}
                id="settings-subscription-engagement-package-btn"
              >
                {isLoading ? (
                  <span className="sending-request-btn-text">
                    <CircularProgress className="circular-progress-subscription circular-progress" />
                    {CheckValueLocale("sending_request", "", {}, intl)}
                  </span>
                ) : (
                  CheckValueLocale("request_demo", "", {}, intl)
                )}
              </Button>
            </Stack>
          </>
        )}
      </Card>

      {success != null ? (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span>
                  <br />
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_success", "", {}, intl)
                    : CheckValueLocale(successMsg, "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_failed", "", {}, intl)
                    : CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
      {engagementSuccess != null ? (
        <Snackbar
          open={engagementSuccess != null ? true : false}
          handleClose={() => {
            setEngagementSuccess(null);
          }}
          severity={engagementSuccess ? "success" : "error"}
          message={
            engagementSuccess ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("request_demo_success", "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default EngagementPackage;

const getRenewDate = (date, intl) => {
  if (date) {
    if (intl?.locale === "ar") {
      const isAm = moment.unix(date).utc().format("A") === "AM";
      return CheckValueLocale(
        "renewed_at",
        "",
        {
          date: moment.unix(date).utc().format("M/D/YYYY"),
          time: moment.unix(date).utc().format("h:mm"),
          period: isAm ? "صباحا" : "مساءً",
        },
        intl,
      );
    } else {
      return CheckValueLocale(
        "renewed_at",
        "",
        { date: moment.unix(date).utc().format("M/D/YYYY h:mm A") },
        intl,
      );
    }
  } else {
    return "";
  }
};
