import { Box } from "@mui/material";

const SummaryContent = ({ summaryExpanded, summaryLastAnswer }) => {
  const summaryAnswerLength = summaryExpanded ? summaryLastAnswer?.length : 4;
  return (
    <Box className="summary-content-wrapper">
      <Box className="summary-main-content">
        {summaryLastAnswer?.map((answer, i) => {
          return i < summaryAnswerLength ? (
            <Box
              className="summary-content-box"
              component="p"
              dangerouslySetInnerHTML={{
                __html: answer,
              }}
            ></Box>
          ) : null;
        })}
      </Box>
    </Box>
  );
};

export default SummaryContent;
