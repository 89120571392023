import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import { useIntl } from "react-intl";
import "../../../monitors.scss";
import { CheckValueLocale } from "utils/helpers";

const OverViewSection = (props) => {
  const intl = useIntl();
  let {
    response,
    pageViews,
    setPageViews,
    pageLikeUnlike,
    setPageLikeUnlike,
    pageLikeUnlikePreLoader,
    setPageLikeUnlikePreLoader,
    pageLikeUnlikeLegend,
    setPageLikeUnlikeLegend,
    pageReachImpression,
    setPageReachImpression,
    pageReachImpressionPreLoader,
    setPageReachImpressionPreLoader,
    setPageReachImpressionLegend,
    reachArray,
    setReachArray,
    impressionArray,
    setImpressionArray,
    pageContactDetailsInteractions,
    setPageContactDetailsInteractions,
    pageContactDetailsInteractionsPreLoader,
    setPageContactDetailsInteractionsPreLoader,
    pageContactDetailsLegend,
    setPageContactDetailsLegend,
    pageActions,
    setPageActions,
    pageActionsPreLoader,
    setPageActionsPreLoader,
    pageActionsLegend,
    setPageActionsLegend,
    pageReachImpressionLegend,
    accountAuthorActivity,
    setAccountAuthorActivity,
    accountAuthorActivityPreLoader,
    setAccountAuthorActivityPreLoader,
    pageViewsPreLoader,
    setPageViewsPreLoader,
  } = props;
  //handle response
  const [totalPageViewsCount, setTotalPageViewsCount] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__AccountPage__page_views":
        setPageViews(response.eventData.page_views_overtime);
        setTotalPageViewsCount(response.eventData.total_page_views[0].value);
        setPageViewsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Facebook__AccountPage__page_likes_unlikes":
        setPageLikeUnlike(response.eventData.page_likes_unlikes_overtime);
        setPageLikeUnlikeLegend(
          response.eventData.pie_chart_page_likes_unlikes,
        );
        setPageLikeUnlikePreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__page_reach_impressions":
        Object.entries(
          response.eventData.page_reach_impressions_overtime,
        ).forEach(([key, value]) => {
          switch (key) {
            case "page_reach_over_time":
              value.map((i) => {
                return reachArray.push(i);
              });
              break;
            case "page_impressions_over_time":
              value.map((i) => {
                return impressionArray.push(i);
              });
              break;

            default:
              break;
          }
        });

        (reachArray.length > 0 || impressionArray.length > 0) &&
          setPageReachImpression({
            reach: reachArray,
            impression: impressionArray,
          });
        setPageReachImpressionLegend(
          response.eventData.total_page_reach_impressions,
        );
        setPageReachImpressionPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__page_contact_details_interactions":
        setPageContactDetailsInteractions(
          response.eventData.page_contact_details_interactions_overtime,
        );
        setPageContactDetailsLegend(
          response.eventData.pie_chart_page_contact_details_interactions,
        );
        setPageContactDetailsInteractionsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;

      case "Facebook__AccountPage__page_actions":
        setPageActions(response.eventData.page_actions_overtime);
        setPageActionsLegend(response.eventData.pie_chart_page_actions);
        setPageActionsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__AccountPage__account_author_activity":
        setMinValueAuthorActivity(response.eventData.min_value);
        setMaxValueAuthorActivity(response.eventData.max_value);
        setAccountAuthorActivity(response.eventData.account_author_activity);
        setAccountAuthorActivityPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  useEffect(() => {
    props.setOverViewData([
      { pageViews: pageViews },
      { pageLikeUnlike: pageLikeUnlike },
      { pageReachImpression: pageReachImpression },
      { pageContactDetailsInteractions: pageContactDetailsInteractions },
      { pageActions: pageActions },
      {
        accountAuthorActivity: [
          accountAuthorActivity,
          minValueAuthorActivity,
          maxValueAuthorActivity,
        ],
      },
    ]);
  }, [
    pageViews,
    pageLikeUnlike,
    pageReachImpression,
    pageContactDetailsInteractions,
    pageActions,
    accountAuthorActivity,
    minValueAuthorActivity,
    maxValueAuthorActivity,
  ]);

  // ________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPageViews([]);
      setTotalPageViewsCount([]);

      setPageLikeUnlike([]);
      setPageLikeUnlikeLegend([]);
      setPageLikeUnlikePreLoader([]);
      setPageReachImpression([]);
      setPageReachImpressionLegend([]);
      setPageReachImpressionPreLoader([]);
      setImpressionArray([]);
      setReachArray([]);

      setPageContactDetailsInteractions([]);
      setPageContactDetailsLegend([]);
      setPageContactDetailsInteractionsPreLoader([]);

      setPageActions([]);
      setPageActionsLegend([]);
      setPageActionsPreLoader([]);
      setAccountAuthorActivity([]);
      setAccountAuthorActivityPreLoader([]);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setPageViewsPreLoader([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"page_views"}
          totalLabel={"views"}
          totalValue={totalPageViewsCount}
          data={pageViews}
          showGroupBy={true}
          toolTipLabel={"views"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={pageViewsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.FacebookIcon}
          labelColor={"#2AAAE2"}
          showReportIcon
          titleToolTip={"page_views_fb_tooltip"}
        />
      </Box>

      <Box my={3}>
        <StackedBarWidget
          title={"page_like_unlikes"}
          showDownloadIcon
          bigImg
          posts={pageLikeUnlike}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={pageLikeUnlikePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          colors={["#2AAAE2", "#80868C"]}
          labels={["unlike_page_over_time", "like_page_over_time"]}
          hideLegend
          dataPiechart={pageLikeUnlikeLegend}
          showReportIcon
          titleToolTip={"page_like_unlikes_fb_tooltip"}
        />
      </Box>

      <Box mt={3}>
        <StackedLineWidget
          title={"page_reach_impressions"}
          data={pageReachImpression}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={pageReachImpressionPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          colors={["#2AAAE2", "#80868C"]}
          labelColors={["#2AAAE2", "#80868C"]}
          showReportIcon
          titleToolTip={"page_reach_impressions_fb_tooltip"}
          hideLegend
          dataPiechart={pageReachImpressionLegend}
          isPostsReachImpression
        />
      </Box>

      <Box my={3}>
        <StackedBarWidget
          title={"page_contact_details_interactions"}
          showDownloadIcon
          bigImg
          posts={pageContactDetailsInteractions}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={[
            "page_website_clicks_over_time",
            "page_call_phone_clicks_over_time",
            "page_directions_clicks_over_time",
          ]}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={
            pageContactDetailsInteractionsPreLoader
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          showReportIcon
          titleToolTip={"page_contact_details_interactions_fb_tooltip"}
          hideLegend
          dataPiechart={pageContactDetailsLegend}
        />
      </Box>

      <Box my={3}>
        <StackedBarWidget
          title={"page_actions"}
          showDownloadIcon
          bigImg
          posts={pageActions}
          colors={["#C51162", "#4CAF50"]}
          labels={[
            "page_negative_interactions_over_time",
            "page_positive_interactions_over_time",
          ]}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={pageActionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          showReportIcon
          titleToolTip={"page_actions_fb_tooltip"}
          hideLegend
          dataPiechart={pageActionsLegend}
        />
      </Box>

      <Box my={3}>
        <PunchCardWidget
          title="account_authors_activity"
          clientActivity={accountAuthorActivity}
          minValueClient={minValueAuthorActivity}
          maxValueClient={maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.FacebookIcon}
          authorActivityPreLoaderDataSources={accountAuthorActivityPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          titleToolTip={"account_authors_activity_fb_tooltip"}
        />
      </Box>
    </>
  );
};

export default OverViewSection;
