import { useEffect, useState } from "react";
import PopupModal from "components/popupModal";
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "../../../../utils/helpers/index.js";
import { Box } from "@mui/system";
import AudienceController from "services/controllers/audienceController";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";

const ExcludeSegmentPopUp = (props) => {
  const {
    open,
    close,
    segmentList,
    selectedUsersId,
    create,
    getAudience,
    startDate,
    endDate,
    filteredAudienceData,
    identifier,
    filters,
    isSelectAllProfiles,
    count,
    page,
  } = props;
  const [selectedSegmant, setSelectedSegmant] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [checkedExclude, setCheckedExclude] = useState(false);
  const [selectedSegmantIds, setSelectedSegmantIds] = useState([]);
  const intl = useIntl();
  const { changeFilterParamsToString } = CommonFunctions();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleCreate = () => {
    excludeSegmentApi();
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 220,
        width: 300,
      },
    },
  };

  const excludeSegmentApi = () => {
    setIsButtonLoading(true);
    AudienceController.excludeSegmantProfile(
      selectedSegmantIds,
      selectedUsersId,
      isSelectAllProfiles,
      startDate,
      endDate,
      identifier,
      filters && changeFilterParamsToString(filters, true),
    ).then((result) => {
      if (result?.data?.status === 200) {
        create("success", "excluded_msg_alert", "");
      } else {
        create("error", "try_again_error_message", "failed_error_message", "");
      }
      setIsButtonLoading(false);
      close();
      getAudience(startDate, endDate, filters, page);
    });
  };
  const handleFormMultipleSelection = (event) => {
    let selectedValue = event.target.value;
    let selectedValueIds = [];
    selectedValueIds.push(selectedValue?.id);
    setSelectedSegmant(selectedValue);
    setSelectedSegmantIds(selectedValueIds);
  };
  const handleChange = (event) => {
    setCheckedExclude(event.target.checked);
  };
  const getSegemntsIds = (data) => {
    let segementsIds = [];
    data?.map((item) => {
      segementsIds.push(item?.id);
    });
    return segementsIds;
  };

  let segmentsCountSelected = filteredAudienceData?.filter((item) => {
    return getSegemntsIds(item.segments)?.includes(selectedSegmant.id);
  });

  useEffect(() => {
    if (selectedSegmant?.length !== 0 && checkedExclude !== false) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedSegmant, checkedExclude]);
  let body = (
    <div className="add-segment-popup">
      <Typography>
        {intl.formatMessage(
          { id: "exclude_segment_un_assign" },
          {
            num: (
              <Box component="span" className="segment-num-deleted-users">
                {isSelectAllProfiles ? count : selectedUsersId?.length}
              </Box>
            ),
          },
        )}
      </Typography>
      <FormControl className="segment-formControl">
        <Select
          id="monitors-select"
          fullWidth
          name="alertMonitors"
          onChange={handleFormMultipleSelection}
          renderValue={(selected) => {
            return selected?.id
              ? selected?.name
              : CheckValueLocale("select_segment", "", {}, intl);
          }}
          value={selectedSegmant}
          className="add-segment-select"
          displayEmpty
          MenuProps={MenuProps}
        >
          {segmentList?.map((val) => (
            <MenuItem
              className="segment-menu-item"
              key={`Segmant-${val.id}`}
              id={val.id}
              value={val}
            >
              <ListItemText
                className="selected-name-segment"
                primary={val.name}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedSegmant?.length !== 0 && (
        <Box className="checkbox-exclude">
          <FormControlLabel
            className="understand-exclude-segment"
            label={CheckValueLocale(
              "i_understand_segment",
              "",
              {
                num: segmentsCountSelected?.length,
              },
              intl,
            )}
            control={<Checkbox onChange={handleChange} />}
          />
        </Box>
      )}
    </div>
  );
  return (
    <PopupModal
      title={CheckValueLocale("exclude_segments_title", "", {}, intl)}
      body={body}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("exclude_segment_btn", "", {}, intl)}
      disabled={disabled || isButtonLoading}
      open={open}
      close={close}
      minWidth="600px"
      accept={handleCreate}
      isRightBtnLoading={isButtonLoading}
    />
  );
};

export default ExcludeSegmentPopUp;
