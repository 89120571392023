//this shared component it contain any function that related to Language .

import { checkCharNumCount, checkQuotationMark } from "./advancedKeyword";

// function that called in checkKeywordLanguage to push new word to Array
const checkDifferentLanguages = (word, lang) => {
  let wholeWord = "",
    newWordArray = word.split("_");

  if (lang === "ar-SA") {
    for (var i = newWordArray.length - 1; i >= 0; i--) {
      if (i === 0) {
        wholeWord += newWordArray[i];
      } else {
        wholeWord += newWordArray[i] + "_";
      }
    }
  } else {
    for (var i = 0; i < newWordArray.length; i++) {
      if (i === 0) {
        wholeWord += newWordArray[i];
      } else {
        wholeWord += "_" + newWordArray[i];
      }
    }
  }
  return wholeWord;
};

//check keyword language if it is Arabic OR English if arabic it make some tests to avoid special characters
export const checkKeywordLanguage = (word, monitorType) => {
  let arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g,
    appLanguage = window.localStorage.language;

  let hashtagIndexes = word
    .split("")
    .map(function (e, i) {
      if (e === "#") return i;
    })
    .filter((n) => typeof n === "number");

  if (
    word[0] !== undefined &&
    word[0].includes("#") &&
    !hashtagIndexes.length > 1
  ) {
    word = word.replace(/#/g, "");

    if (appLanguage === "en-US") {
      if (arabicAlphabetDigits.test(word[0])) {
        word = checkDifferentLanguages(word, "ar-SA");
        word = checkQuotationMark(word.trim(), monitorType) + "#";
        if (word.match(/[a-z]/i) && !word.includes("_")) {
          word = word.replace(/#/g, "");
          word = "#" + checkQuotationMark(word.trim(), monitorType);
        }
      } else {
        word = "#" + checkQuotationMark(word.trim(), monitorType);
      }
    } else {
      if (!arabicAlphabetDigits.test(word[0])) {
        word = checkDifferentLanguages(word, "en-US");
        word = "#" + checkQuotationMark(word.trim(), monitorType);
      } else {
        word = "#" + checkQuotationMark(word.trim(), monitorType);
      }
    }
  } else if (monitorType === "instagram") {
    word = word.replace(
      /[§±!\@$%\^&\*\(\)\÷\×\+=\[\]{};:\\\|~<>\/\?\.,؛؟،\'\"\‟\”]/g,
      "",
    );
    // colse if for Hashtags
  } else {
    word = checkQuotationMark(word.trim(), monitorType);
  }

  return word;
};

// function to test keyword when start with english character in [ twitter , facebook , news ] --> make test on it to sure it not have special character and it is between [a-z]
export const startWithEnglishCharacter = (keyword, monitorType) => {
  let arabicAlphabetDigits =
    /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;

  if (keyword !== undefined) {
    let isStartWithEnglish =
      monitorType === ("twitter" || "facebook" || "news")
        ? (arabicAlphabetDigits.test(keyword[2]) &&
            keyword[0] === '"' &&
            keyword[1] === "^") ||
          (!arabicAlphabetDigits.test(keyword[1]) && keyword[0] === "^") ||
          (keyword[1] !== "" &&
            keyword[1] !== undefined &&
            !keyword[1].match(/[a-z]/i) &&
            keyword[0] === "#") ||
          (keyword[0] !== "(" &&
            keyword[1] !== undefined &&
            !keyword[1].match(/[a-z]/i))
          ? false
          : true
        : arabicAlphabetDigits.test(keyword[1])
          ? false
          : true;

    return isStartWithEnglish;
  }
};

// push new keyword to array and [trim] any space from it
export const checkAdvancedKeywordLanguage = (allKeywords, monitorType) => {
  let newKeywords = [];
  for (let key = 0; key < allKeywords.length; key++) {
    newKeywords.push(
      checkKeywordLanguage(allKeywords[key].trim(), monitorType),
    );
  }
  return newKeywords;
};

// check Arabic local that entred to english local and replace direction of it then split any special character that enterd in first of input
export const checkArabicInEnglishLocal = (keyword) => {
  let style = {},
    arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;
  let appLanguage = window.localStorage.lang;

  if (appLanguage === "ar-SA") {
    if (arabicAlphabetDigits.test(keyword)) {
      if (keyword[keyword.length - 1] === "#") {
        style = { maxWidth: 350, direction: "ltr" };
      } else if (
        (keyword[0] === "#" &&
          ((keyword[1] !== "" && !keyword[1].match(/[a-z]/i)) ||
            (keyword[1] === '"' && !keyword[2].match(/[a-z]/i)))) ||
        (keyword[0] === "^" &&
          keyword[1] !== "" &&
          !keyword[1].match(/[a-z]/i)) ||
        (keyword[1] === '"' && !keyword[2].match(/[a-z]/i))
      ) {
        style = { maxWidth: 350, direction: "rtl" };
      } else if (
        (keyword[0] === '"' &&
          keyword[keyword.length - 1] === '"' &&
          (keyword[keyword.length - 2] === "^" ||
            keyword[keyword.length - 2] === "#") &&
          !keyword[1].match(/[a-z]/i)) ||
        keyword[keyword.length - 1] === "^"
      ) {
        style = { maxWidth: 350, direction: "ltr" };
      } else if (
        (keyword[0] === '"' && keyword[1] === "@") ||
        (keyword[0] === "@" &&
          !keyword[1].match(/[a-z]/i) &&
          keyword[1] === '"') ||
        (keyword[0] === "#" && !keyword[1].match(/[a-z]/i))
      ) {
        style = { maxWidth: 350, direction: "rtl" };
      } else if (keyword[0] === "^" && keyword[1].match(/[a-z]/i)) {
        style = { maxWidth: 350, direction: "ltr" };
      } else if (keyword[0] === '"' && keyword[1] === '"') {
        style = { maxWidth: 350, direction: "rtl" };
      }
    } else {
      if (
        keyword[0] === "#" ||
        keyword[0] === "^" ||
        (keyword[0] === '"' &&
          (keyword[1] === "@" || keyword[1] === "^" || keyword[1] === "#"))
      ) {
        style = { maxWidth: 350, direction: "ltr" };
      } else {
        style = { maxWidth: 350, direction: "ltr" };
      }
    }
  } else {
    if (arabicAlphabetDigits?.test(keyword)) {
      if (
        (keyword[0] === "#" &&
          ((keyword[1] !== "" && !keyword[1].match(/[a-z]/i)) ||
            (keyword[1] === '"' && !keyword[2].match(/[a-z]/i)))) ||
        (keyword[0] === "^" &&
          keyword[1] !== "" &&
          !keyword[1].match(/[a-z]/i)) ||
        (keyword[1] === '"' && !keyword[2].match(/[a-z]/i))
      ) {
        style = { maxWidth: 350, direction: "rtl" };
      } else if (
        (keyword[0] === '"' &&
          keyword[1] === '"' &&
          (keyword[2] === "^" || keyword[2] === "#") &&
          !keyword[1].match(/[a-z]/i)) ||
        keyword[1] === "^"
      ) {
        style = { maxWidth: 350, direction: "ltr" };
      } else if (
        (keyword[0] === '"' && keyword[1] === "@") ||
        (keyword[0] === "@" && !keyword[1].match(/[a-z]/i))
      ) {
        style = { maxWidth: 350, direction: "rtl" };
      } else if (keyword[0] === "@" && keyword[0] === '"') {
        style = { maxWidth: 350, direction: "ltr" };
      }
    } else {
      style = { maxWidth: 350, direction: "ltr" };
    }
  }

  return style;
};

// function to remove any special character from word and validate it before Push to array
export const removeSpecialCharacters = (words, monitorType) => {
  let new_words_array = [];
  let newVal;
  for (let i = 0; i < words.length; i++) {
    let new_word =
      monitorType === "twitter" ||
      monitorType === "facebook" ||
      monitorType === "newsBlogs"
        ? checkQuotationMark(words[i], monitorType).trim()
        : checkQuotationMark(words[i]).trim();
    if (new_word.length > 0 && /\S/.test(new_word)) {
      if (!words[i]?.includes("#") && monitorType != undefined) {
        if (words[i]?.includes('"')) {
          if (checkCharNumCount(new_word, /(["])/g)) {
            newVal = new_word;
          } else {
            newVal = `"${new_word}"`;
          }
        } else if (words[i]?.includes("'")) {
          if (checkCharNumCount(new_word, /(['])/g)) {
            newVal = new_word;
          } else {
            newVal = `'${new_word}'`;
          }
        } else {
          newVal = new_word;
        }
        new_words_array.push(newVal);
      } else {
        new_words_array.push(new_word);
      }
    }
  }
  return new_words_array;
};
