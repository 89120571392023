import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, Typography, Box } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import { useIntl } from "react-intl";
import { data } from "pages/Monitors/Components/sidebar/dataSourceTabs";
import "../monitorSettings.scss";
import { ArrowForwardIos } from "@mui/icons-material";
import PageHeader from "components/pageHeader";
import { CheckValueLocale } from "utils/helpers";

const MonitorSettingsHeader = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [monitorUrl, setMonitorUrl] = useState("");
  const { monitorDetails } = props;
  const monitorDataSources = monitorDetails?.attributes?.data_sources[0];
  const monitorType = monitorDetails?.attributes?.monitor_type_name;
  const monitorId = monitorDetails?.attributes?.id;
  // _________________________________________________________
  //this function to redirect user to the correct monitor url from monitorList page
  useEffect(() => {
    getMonitorUrl();
  }, [monitorDetails]);
  const getMonitorUrl = () => {
    const monitorDataSources = monitorDetails?.attributes?.data_sources[0];
    const monitorType = monitorDetails?.attributes?.monitor_type_name;

    data?.forEach((i) => {
      //'data' here is static array of object for all avaliable tabs for each datasource as per the its monitorType

      if (Object.keys(i)[0] == `${monitorDataSources}_${monitorType}`) {
        let monitorDS = Object.keys(i)[0].split("_")[0];
        monitorDS === "TALKWALKER"
          ? setMonitorUrl(
              `monitor/${monitorType}/NEWSBLOGS/${
                Object.values(i)[0][0].analytics[0].tabName
              }/${monitorId}`,
            )
          : setMonitorUrl(
              `monitor/${monitorType}/${monitorDataSources}/${
                Object.values(i)[0][0].analytics[0].tabName
              }/${monitorId}`,
            );
        return;
      }
    });
  };

  // _________________________________________________________

  const links = [
    {
      name: "monitors_list",
      url: "social/monitor_list",
      addNameLocal: true,
    },
    {
      name: `${monitorDetails?.id} - ${monitorDetails?.attributes?.name}`,
      url: monitorUrl,
      state: monitorDetails,
      addNameLocal: false,
    },
  ];

  return (
    <Box className="monitor-setting-header">
      <BreadCrumb id="breadcrumb-style-monitors" links={links} />
      <Box>
        <PageHeader title="monitor_settings" withBreadCrumb={true} />
        <Button
          onClick={() =>
            history.push({
              pathname: `/${monitorUrl}`,
              state: monitorDetails,
              tabs: `${monitorDataSources}_${monitorType}`,
            })
          }
          id="monitor-options-back-to-monitor-btn"
        >
          {CheckValueLocale("back_to_monitor", "", {}, intl)}
          <ArrowForwardIos />
        </Button>
      </Box>
      <Divider />
    </Box>
  );
};

export default MonitorSettingsHeader;
