import { Box, Divider, Typography } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import PageHeader from "components/pageHeader";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export const EditTeamHeader = ({ team, id }) => {
  const location = useLocation();
  const intl = useIntl();
  const isComingFromTeamDetails = location?.state?.from === "teamDetails";

  const links = [
    {
      name: "settings",
      url: "settings",
      addNameLocal: true,
    },
    {
      name: "teams",
      url: "settings/teams",
      addNameLocal: true,
    },
    {
      name: team?.team_name,
      url: `settings/teams/view/${id}`,
      addNameLocal: false,
    },
  ];

  return (
    <>
      <Box className="edit-team-header">
        <Box className="edit-team-header-breadcrumb">
          <BreadCrumb id="breadcrumb-style-monitors" links={links} />
          <PageHeader title="edit_team" withBreadCrumb={true} />
        </Box>
        <Link
          to={
            isComingFromTeamDetails
              ? `/settings/teams/view/${id}`
              : "/settings/teams"
          }
          className="edit-team-header-btn"
          id="edit-team-header-btn"
        >
          <Typography as="span" className="edit-team-header-btn-title">
            {CheckValueLocale(
              isComingFromTeamDetails
                ? "back_to_team_details"
                : "back_to_teams",
              "",
              {},
              intl,
            )}
          </Typography>
          <ArrowForwardIcon />
        </Link>
      </Box>
      <Divider />
    </>
  );
};
