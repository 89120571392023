import React from "react";
import { useIntl } from "react-intl";
import { Box, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useWidgetName } from "../../../hooks/useWidgetName";

const ContentWidgetCard = (props) => {
  const {
    index,
    dashboardInfo,
    widget,
    handleDeleteWidget,
    DragHandle,
    handleWidgetImage,
  } = props;
  const intl = useIntl();

  const halfWidgets = [
    "dialects_subdialects",
    "dialects",
    "associated_topics",
    "gender",
    "gender_distribution",
    "top_cities",
    "top_countries",
    "top_languages",
    "top_sources",
    "account_types",
    "content_style",
    "topics",
    "top_keywords",
    "top_hashtags",
    "top_influencers",
    "top_engagers",
    "top_verified_engagers",
    "top_authors",
    "top_sites",
    "top_engaged_sites",
    "top_influencers",
    "top_verified_engagers",
    "top_engagers",
    "posts_top_keywords",
    "posts_top_hash_tags",
    // -------------- Omnichannel Pie ----------------
    "comments_content_style",
    "comments_associated_topics",
    "comments_top_countries",
    "comments_top_cities",
    "comments_gender_distribution",
    "comments_top_languages",
    "comments_dialects_sub_dialects",
    "comments_account_types",
    "comments_top_sources",
    "dialects_sub_dialects",
    "channel_accounts",
    // -------------- Omnichannel Table ----------------
    "comments_top_keywords",
    "comments_top_hash_tags",
    "comments_top_engagers",
    "comments_top_verified_engagers",
    "comments_top_influencers",
    "top_hash_tags",
  ];

  let widgetName = useWidgetName(
    dashboardInfo?.product,
    widget?.widget_name,
    widget?.monitor_type?.name,
    widget?.dashboard_page_name,
    widget?.data_source,
  );

  const handleCheckContentTypeWidget = () => {
    if (
      (dashboardInfo?.product === "sm" &&
        widget?.data_source === "INSTAGRAM" &&
        widget?.monitor_type?.name === "KEYWORD") ||
      (dashboardInfo?.product === "cxm" &&
        [
          "FACEBOOK_PRIVATE",
          "INSTAGRAM_PRIVATE",
          "LINKEDIN_PUBLIC",
          "TWITTER_PRIVATE",
        ]?.includes(widget?.dashboard_page_name))
    ) {
      return "piechart";
    } else return "stackedbar";
  };

  const widgetImg = handleWidgetImage(
    dashboardInfo?.product == "sm" ? "SocialListening" : "Omnichannel",
    dashboardInfo?.product === "cxm" && widget?.is_dm
      ? `${widget?.data_source}_DM`
      : widget?.data_source,
    widget?.monitor_type?.name == "MANAGED_PAGE"
      ? "MANAGED_ACCOUNT"
      : widget?.monitor_type?.name,
    halfWidgets?.includes(widget?.widget_name) ||
      (widget?.widget_name === "content_type" &&
        handleCheckContentTypeWidget() === "piechart")
      ? `6-col/${widgetName}`
      : widgetName,
    intl?.locale?.toLowerCase(),
  );

  return (
    <Grid
      item
      xs={
        halfWidgets?.includes(widget?.widget_name) ||
        (widget?.widget_name === "content_type" &&
          handleCheckContentTypeWidget() === "piechart")
          ? 6
          : 12
      }
      key={widget?.order}
    >
      <Box className="content-widget-container-box">
        <DragHandle />
        <Box component={"span"} className="content-image-wrapper">
          <img src={widgetImg} alt="" width={"100%"} height={"auto"} />
          <Box
            component={"span"}
            id="custom-dashboard-editor-remove-widget-btn"
            className="remove-widget-btn"
            onClick={() => handleDeleteWidget(index)}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContentWidgetCard;
