import { Box } from "@mui/material";

const FormTitle = ({ text }) => {
  return (
    <Box component="h2" className="form-title">
      {" "}
      {text}{" "}
    </Box>
  );
};
export default FormTitle;
