import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";

const InteractionsPage = (props) => {
  const intl = useIntl();
  let { response } = props;
  const [latestInteractions, setLatestInteractions] = useState([]);

  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);

  const [latestInteractionsCount, setLatestInteractionsCount] = useState(0);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response.eventName) {
      case "latest_interactions":
        if (props.preLoaderTrackerDataSources.includes(response.data_source)) {
          response.eventData.latest_interactions?.map((interaction) => {
            setLatestInteractions((oldArray) => [...oldArray, interaction]);
          });
          setLatestInteractionsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response.data_source,
          ]);
          if (props.page === 1) {
            setLatestInteractionsCount(
              (oldCount) => oldCount + response.eventData.interactions_count,
            );
          }
        }
        break;
      default:
        break;
    }
  }, [response]);

  const handleChangePage = () => {
    if (latestInteractions.length < latestInteractionsCount) {
      props.setPage(props.page + 1);
    }
  };

  let arr = latestInteractionsPreLoaderDataSources;
  const NewPreLoader = arr.filter((item, index) => arr.indexOf(item) === index);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      NewPreLoader,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [latestInteractions, NewPreLoader, props.preLoaderTrackerDataSources]);

  return (
    <InteractionsList
      data={latestInteractions}
      dataSources={props.dataSources}
      isAudiencePage
      latestInteractionsPreLoaderDataSources={NewPreLoader}
      preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      handleChangePage={handleChangePage}
      latestInteractionsCount={latestInteractionsCount}
      page={props.page}
    />
  );
};

export default InteractionsPage;
