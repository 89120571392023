import { Grid, Box } from "@mui/material";
import { useIntl } from "react-intl";
import CardHeader from "./cardHeader";
import CardData from "./cardData";

const SurveyCards = (props) => {
  const intl = useIntl();

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {props?.surveys?.map((survey, i) => (
        <Grid item xs={6} key={i} className="survey-card-body">
          <Box className="survey-card">
            <CardHeader
              survey={survey}
              getSurveys={props?.getSurveys}
              surveyLimitReach={props?.surveyLimitReach}
            />
            <CardData survey={survey} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default SurveyCards;
