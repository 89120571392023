import { faCalendar, faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faBadgeCheck, faComments } from "@fortawesome/pro-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import SnackBar from "components/snackBar";
import penSquare from "images/penSquare.svg";
import _ from "lodash";
import moment from "moment";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  isEmptyArray,
  CheckValueLocale,
  checkIfWeNeedShowMoreTweet,
} from "utils/helpers";
import "./authors.scss";
import ListItemIcon from "@mui/material/ListItemIcon";
import InsightsIcon from "@mui/icons-material/Insights";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import IconFunctions from "pages/Monitors/Components/headerIconFunctions";
import PopupModal from "components/popupModal";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import CustomizedTooltip from "components/customizedTooltip";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";

const AuthorsBody = (props) => {
  const { snackBarData, openSnackBar, handleCloseSnack, postSpamTweets } =
    IconFunctions();
  const {
    resetAllFields,
    changePagination,
    preLoaderTrackerDataSources,
    totalPage,
    pageNumber,
    setPageNumber,
    setTotalPage,
    singleTweetAnalysis,
    response,
  } = props;

  const intl = useIntl();
  const [authorPreloader, setAuthorPreloader] = useState([]);
  const [authorsData, setAuthorData] = useState([]);
  const [openMoreVertIcon, setOpenMoreVertIcon] = useState(false);
  const [clickedCard, setClickedCard] = useState();
  const [showFullTextTweet, setShowFullTextTweet] = useState([]);

  useEffect(() => {
    if (response.eventName === "Twitter__AuthorsPage__top_authors") {
      setAuthorData(response?.eventData?.top_authors);
      const listShowFullText = response?.eventData?.top_authors?.map(
        () => false,
      );
      setShowFullTextTweet(listShowFullText);
      if (response.eventData.top_authors_count !== undefined) {
        setTotalPage(response.eventData.top_authors_count);
        setPageNumber(1);
      }
      setAuthorPreloader([...authorPreloader, response.data_source]);
    }
  }, [response]);
  useEffect(() => {
    if (resetAllFields !== 1) {
      setAuthorPreloader([]);
      setAuthorData([]);
    }
  }, [resetAllFields]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPageNumber(page);
    changePagination(page);
  };

  const handleClick = (index) => {
    setOpenMoreVertIcon(!openMoreVertIcon);
    setClickedCard(index);
  };

  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  const updateTweets = (tweetID, tweetTrackerID, screenName, tweetTime) => {
    updateAnalysis(tweetID, screenName, tweetTrackerID, tweetTime);
  };

  return (
    <>
      {_.isEqual(
        preLoaderTrackerDataSources?.sort(),
        authorPreloader?.sort(),
      ) !== true ? (
        <FetchingData />
      ) : isEmptyArray(authorsData) ? (
        <NoDataFound />
      ) : (
        authorsData?.map((cardData, index) => {
          const comments = [
            {
              name: "comments_mentions_monitor",
              value: cardData?.comments_mentions_count,
              icon: penSquare,
            },
            {
              name: "total_interactions",
              tooltip: "total_interactions_tooltip_title",
              value: cardData?.total_interactions,
              icon: faComments,
            },
          ];
          return (
            <Box className={"post-item authors-item"}>
              <Box className="post-head">
                <Box className="post-title-date">
                  <img src={cardData?.user_profile_image_url} alt="" />

                  <Box className="post-title">
                    <Box className="flex-Stylee">
                      <img
                        src={xPlatform}
                        alt="x-platform-logo"
                        className="twitter-icon-authors"
                      />
                      <Box>{cardData?.user_name}</Box>
                      <Box className="post-user-name user-name-author-dash">
                        @{cardData?.screen_name}
                      </Box>
                      {cardData?.verified !== "false" && (
                        <FontAwesomeIcon icon={faBadgeCheck} color="#1D9BF0" />
                      )}
                    </Box>

                    <Box className="post-date">
                      {cardData?.followers_count?.toLocaleString()}
                      {CheckValueLocale("author_follower", "", {}, intl)} |
                      {cardData?.country}
                    </Box>
                  </Box>
                </Box>

                <Box className="post-top-icons more-vert-icon">
                  <CustomizedTooltip
                    id={
                      openMoreVertIcon && clickedCard === index
                        ? "author-item-list-tooltip"
                        : ""
                    }
                    title={
                      <Box className="item-title-tooltip">
                        {CheckValueLocale("items_ellipsis", "", {}, intl)}
                      </Box>
                    }
                    arrow
                    placement="bottom"
                  >
                    <IconButton
                      aria-label="more"
                      aria-haspopup="true"
                      onClick={() => handleClick(index)}
                      className={`authors-header-style ${
                        openMoreVertIcon &&
                        clickedCard === index &&
                        "author-post-open"
                      }`}
                      id="author-post-choices-icon-btn"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className={
                          openMoreVertIcon && clickedCard === index
                            ? "menu-style menu-style-open"
                            : "menu-style"
                        }
                      />
                    </IconButton>
                  </CustomizedTooltip>
                  {openMoreVertIcon && clickedCard === index && (
                    <div
                      className="custom-dropdown"
                      onMouseLeave={() => setOpenMoreVertIcon(false)}
                    >
                      <Tooltip
                        title={
                          singleTweetAnalysis
                            ? CheckValueLocale("analyze_tweet", "", {}, intl)
                            : CheckValueLocale(
                                "analyze_tweet_upgrade",
                                "",
                                {},
                                intl,
                              )
                        }
                      >
                        <div
                          className="interaction-header-align custom-dropdown-items"
                          onClick={() =>
                            !singleTweetAnalysis
                              ? false
                              : updateTweets(
                                  cardData?.tweet_id,
                                  cardData?.tracker_id,
                                  cardData?.screen_name,
                                  cardData?.tweet_created_at,
                                )
                          }
                        >
                          <ListItemIcon>
                            <InsightsIcon fontSize="small" />
                          </ListItemIcon>

                          {singleTweetAnalysis
                            ? CheckValueLocale("analyze_tweet", "", {}, intl)
                            : CheckValueLocale(
                                "analyze_tweet_upgrade",
                                "",
                                {},
                                intl,
                              )}
                        </div>
                      </Tooltip>

                      <div
                        className="interaction-header-align custom-dropdown-items"
                        onClick={() => {
                          postSpamTweets(cardData, response?.data_source);
                          handleClick(index);
                        }}
                      >
                        <ListItemIcon>
                          <NotInterestedIcon fontSize="small" />
                        </ListItemIcon>
                        {CheckValueLocale("spam_tweeet", "", {}, intl)}
                      </div>
                    </div>
                  )}
                </Box>
              </Box>
              <Box className={"flex-style-box authors-margin"}>
                <Box className="author-comments-container">
                  {comments?.map((i) => {
                    return (
                      <Box className="total-interactions-style">
                        <Box className="author-icons">
                          {i?.name !== "comments_mentions_monitor" ? (
                            <FontAwesomeIcon
                              icon={i?.icon}
                              className="author-font-icons"
                            />
                          ) : (
                            <img src={i?.icon} alt={i?.name} />
                          )}
                        </Box>

                        <Box className="author-details">
                          <Typography className="author-body-title">
                            {i?.value?.toLocaleString()}
                          </Typography>
                          {i?.tooltip ? (
                            <Tooltip
                              title={CheckValueLocale(i.tooltip, "", {}, intl)}
                              arrow
                              placement="top"
                            >
                              <Typography className="author-body-subtitle-tooltip">
                                {CheckValueLocale(i.name, "", {}, intl)}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography className="author-body-subtitle">
                              {CheckValueLocale(i.name, "", {}, intl)}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>

                <Box className={"question-content authors-card"}>
                  <Box className="widget-box">
                    <Box className="authorsCard">
                      <Box>
                        {CheckValueLocale(
                          "top_comments_mentions",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    </Box>
                    {cardData?.tweet_text}
                    {checkIfWeNeedShowMoreTweet(
                      cardData?.tweet_text,
                      cardData?.tweet_full_text,
                    ) ? (
                      <Box
                        component="span"
                        className="tweet-full-text-read-all-btn"
                        onClick={() =>
                          setShowFullTextTweet((prev) => {
                            let newPrev = [...prev];
                            newPrev[index] = true;
                            return newPrev;
                          })
                        }
                      >
                        {CheckValueLocale("read_all", "", {}, intl)}
                      </Box>
                    ) : null}
                    <Box className={"statistics-icons authors-margin"}>
                      <Box>
                        <FontAwesomeIcon icon={faRetweet} />{" "}
                        {cardData?.retweet_count?.toLocaleString()}
                      </Box>
                      <Box>
                        <FontAwesomeIcon icon={faCalendar} />{" "}
                        {cardData?.tweet_created_at &&
                          moment
                            .unix(cardData.tweet_created_at)
                            .utc()
                            .format("DD-MM-YYYY HH:mm:ss")}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {showFullTextTweet[index] ? (
                <FullTextTweetPopUp
                  closeFilter={() =>
                    setShowFullTextTweet((prev) => {
                      let newPrev = [...prev];
                      newPrev[index] = false;
                      return newPrev;
                    })
                  }
                  post={cardData}
                  showRetweet={true}
                />
              ) : null}
            </Box>
          );
        })
      )}

      {/* pagination & openSnackBar */}
      {Math.ceil(totalPage / 10) > 1 && authorsData?.length > 0 ? (
        <Pagination
          onChange={handleChangePage}
          page={pageNumber}
          className="pagination-monitor-list"
          count={Math.ceil(totalPage / 10)}
          variant="outlined"
        />
      ) : (
        <div className="pagination-one-page"></div>
      )}
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={stats?.modalTitle}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
      />
      {openSnackBar && (
        <SnackBar
          open={openSnackBar}
          severity={snackBarData.severity}
          message={CheckValueLocale(
            snackBarData.message,
            "failed_error",
            {},
            intl,
          )}
          handleClose={handleCloseSnack}
        />
      )}
    </>
  );
};

export default AuthorsBody;
