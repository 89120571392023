import { Divider, Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import DropDownComponent from "pages/socialListing/MonitorsOptions/createMonitor/MonitorTypes/KeywordsMonitor/dataSourcesComponents/newBlogsKeyword/components/dropDownNBComponent/dropDownNBComponent";
import { CheckValueLocale } from "utils/helpers";

const DropDownSection = (props) => {
  const intl = useIntl();
  const { dropdown } = props;

  return (
    <>
      {dropdown.map((dropdownVal) => (
        <>
          <Box className="section-box-data dropdown-list-nb-edit">
            <Typography className="main-titles">
              {CheckValueLocale(dropdownVal, "", {}, intl)}
            </Typography>

            {/* shared dropDown component with Create Monitor Keyword */}
            <DropDownComponent
              {...props}
              dropdownVal={dropdownVal}
              monitorSetting
            />
          </Box>
          <Divider />
        </>
      ))}
    </>
  );
};

export default DropDownSection;
