import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Select, MenuItem, Box } from "@mui/material";
import _ from "lodash";
import "./styles/groupByClientAgent.scss";
import { CheckValueLocale } from "utils/helpers";

const GroupByClientAgent = (props) => {
  const intl = useIntl();
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    event.preventDefault();
    setValue(event.target.value);
    props.toggleSelectedValue(event.target.value);
  };

  let { groupByWidgetId } = props;

  useEffect(() => {
    // Set Initial state to be Client
    setValue("clients");
  }, []);

  useEffect(() => {
    setValue("clients");
    props.toggleSelectedValue("clients");
  }, [props.channelActivityUpdated]);

  return (
    <Box
      component="div"
      className="groupby_clientagent"
      id={groupByWidgetId ?? "select-group-by-dropdown"}
    >
      <Box className="groupby_title">
        {CheckValueLocale("group_by", "", {}, intl)}:
      </Box>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        className={"select"}
        disabled={
          !_.isEqual(
            props.channelActivityPreLoaderDataSources.sort(),
            props.preLoaderTrackerDataSources.sort(),
          )
        }
      >
        <MenuItem
          value="clients"
          id={`${groupByWidgetId ?? "groupByWidgetId"}-clients`}
        >
          {CheckValueLocale("clients", "", {}, intl)}
        </MenuItem>
        <MenuItem
          value="agent"
          id={`${groupByWidgetId ?? "groupByWidgetId"}-agent`}
        >
          {CheckValueLocale("agent", "", {}, intl)}
        </MenuItem>
      </Select>
    </Box>
  );
};
export default GroupByClientAgent;
