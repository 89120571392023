import { Box, CircularProgress } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import "./stepsButtons.scss";

const StepsButtons = (props) => {
  const intl = useIntl();
  const stepTwo =
    props?.customDomainStep ||
    props?.checkIsSurveyPage ||
    props?.surveyData?.published;

  const {
    acceptSendRequest,
    error,
    checkSwitch,
    stepThree,
    isSurveyEnabled,
    isSurveyPanelEnabled,
    setStepThree,
    submitSurveysSkip,
  } = props;

  const handleSkip = () => {
    submitSurveysSkip();
    setStepThree(true);
  };
  return (
    <Box className="steps-btn-parant">
      {checkSwitch && !isSurveyPanelEnabled ? (
        <LucButton
          onClick={acceptSendRequest}
          disabled={error}
          variant="outlined"
          className="popup-send-reques puplish-btn"
        >
          {CheckValueLocale("send_request", "", {}, intl)}
          {props?.loading ? (
            <Box className="circular-box">
              <CircularProgress />
            </Box>
          ) : null}
        </LucButton>
      ) : null}
      {stepTwo && !isSurveyPanelEnabled && !stepThree && !props?.isSkeipped ? (
        <LucButton
          onClick={() => handleSkip()}
          disabled={props?.checkSwitch}
          variant="outlined"
          className="popup__cancelbtn skip-btn"
          id="survey-btn-done"
        >
          {CheckValueLocale("skip", "", {}, intl)}
        </LucButton>
      ) : null}
      {/* close step three */}
      {stepTwo && isSurveyPanelEnabled && isSurveyEnabled ? (
        <LucButton
          onClick={props.close}
          variant="outlined"
          className="popup__cancelbtn close-btn"
          id="survey-btn-done"
        >
          {CheckValueLocale("close", "", {}, intl)}
        </LucButton>
      ) : null}
      {(!isSurveyEnabled && props?.isSkeipped) || stepThree ? (
        <LucButton
          onClick={props.close}
          variant="outline"
          id="survey-btn-done-publish"
        >
          {CheckValueLocale("done", "", {}, intl)}
        </LucButton>
      ) : null}
    </Box>
  );
};

export default StepsButtons;
