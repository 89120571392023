import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import "../../../listQuestions.scss";

const FailedAnswerPopup = () => {
  const intl = useIntl();
  const userName = localStorage.getItem("name");

  return (
    <Box className="luci-recived-popup failed">
      <FontAwesomeIcon icon={faExclamationCircle} />
      {CheckValueLocale(
        "luci_failed_answer_pupup",
        "",
        {
          username: userName,
        },
        intl,
      )}
    </Box>
  );
};

export default FailedAnswerPopup;
