import { useQuery } from "@tanstack/react-query";
import MonitorsController from "services/controllers/monitorsController.js";

export const useAuthorizedMonitorType = () => {
  const cxmId = localStorage.cxm_id;
  return useQuery({
    queryKey: ["authorizedMonitorType"],
    queryFn: () => MonitorsController.getAuthorizedMonitorType(cxmId),
    enabled: !!cxmId,
  });
};
