import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

//shared function
import { removeSpecialCharacters } from "../../MonitorsShared/SharedFunctions/components/keywordLanguage";
import {
  NonRepeatedKeywords,
  checkInvalidCases,
  getAfterNot,
  getBeforeNot,
  checkQuotesNoChar,
} from "../../MonitorsShared/SharedFunctions/components/advancedKeyword";
import { CheckValueLocale } from "utils/helpers";

import {
  checkKeywordLanguage,
  checkAdvancedKeywordLanguage,
} from "../../MonitorsShared/SharedFunctions/components/keywordLanguage";
import WordCount from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/wordCount.js";
import {
  getKeywordsNewsLength,
  getKeywordsLength,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordsLength";
import {
  convertedInputRule,
  advancedParser,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/parserFunctions";
import {
  isEmojis,
  isPublicSpecialCharactersOrEmojis,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import _ from "lodash";
import { useSelector } from "react-redux";
import SharedFunctionsKeywords from "./sharedFunctionsKeywords";

const SharedFunctions = (config = {}) => {
  const {
    checkKeywordLimitNews,
    checkKeywordLimit,
    checkKeywordExist,
    checkKeywordExistInstagram,
    checkKeywordLimitInstagram,
    checkKeywordLimitTwitter,
    getTotalTwitterWordsCount,
  } = WordCount();
  //redux
  const { keywordsData, spamKeywordsData, selectedDatasources } = useSelector(
    (state) => state?.explorePageSlice,
  );
  const intl = useIntl();
  // Start Keywords Monitor_____________________________________________
  const [sQuestion, setSQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [spamAnswer, setSpamAnswer] = useState("");
  const [newsBlogCount, setnewsBlogCount] = useState(0);
  const [collectTweets, setCollecttweets] = useState("");
  const [ignoreTweets, setIgnoretweets] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [isVarifiedAccounts, setIsVarifiedAccounts] = useState(false);
  const [ignoretweetsFlag, setIgnoreTweetsFlag] = useState(false);
  //array of objects, each object includes datasource
  const [keywords, setKeywords] = useState([
    {
      twitter: [
        {
          keywords:
            keywordsData?.length && selectedDatasources?.includes("twitter")
              ? [...keywordsData]
              : [],
          errorMsg: "",
          copy: "",
          answer: "",
        },
        {
          spam_keywords:
            spamKeywordsData?.length && selectedDatasources?.includes("twitter")
              ? [...spamKeywordsData]
              : [],
          errorMsg: "",
          copy: "",
          answer: "",
        },
        { collect_tweets: [], errorMsg: "", copy: "", answer: "" },
        { ignore_tweets: [], errorMsg: "", copy: "", answer: "" },
      ],
    },
    {
      instagram: [
        { hashtags: [], errorMsg: "", copy: "", answer: "" },
        { spam_hashtags: [], errorMsg: "", copy: "", answer: "" },
      ],
    },
    {
      facebook: [
        { keywords: [], errorMsg: "", copy: "", answer: "" },
        { spam_keywords: [], errorMsg: "", copy: "", answer: "" },
      ],
    },

    {
      newsBlogs: [
        {
          keywords:
            keywordsData?.length &&
            selectedDatasources?.includes("news_and_blogs")
              ? [...keywordsData]
              : [],
          errorMsg: "",
          copy: false,
          answer: "",
        },
        {
          spam_keywords:
            spamKeywordsData?.length &&
            selectedDatasources?.includes("news_and_blogs")
              ? [...spamKeywordsData]
              : [],
          errorMsg: "",
          copy: false,
          answer: "",
        },
      ],
    },
  ]);
  const [wordCountNewsSpamKeyword, setWordCountNewsSpamKeyword] = useState(0);
  const [copyValue, setCopyValue] = useState("");
  const [wordCountFacebook, setWordCountFacebook] = useState(0);
  const [wordCountInstagram, setWordCountInstagram] = useState(0);
  const [monitorInstagramOldHashtags, setMonitorInstagramOldHashtags] =
    useState([]);
  const [monitorInstagramSettingHashtags, setMonitorInstagramSettingHashtags] =
    useState([]);
  const [monitorInstagramUsedHashtags, setMonitorInstagramUsedHashtags] =
    useState([]);
  const [wordCountNews, setWordCountNews] = useState(
    keywordsData?.length ? keywordsData?.length : 0,
  );
  const [data, setdata] = useState(true);
  const [language, setLanguage] = useState([]);
  const [countries, setCountries] = useState([]);
  const [majorSource, setMajorSource] = useState([]);
  const [nbRanks, setNbRanks] = useState([]);
  const [nbDuplicated, setNbDuplicated] = useState(false);
  const [nbExcludedSource, setNbExcludedSource] = useState([]);
  const [nbExcludedSourceError, setNbExcludedSourceError] = useState("");
  const [titleSearchKeyword, setTitleSearchKeyword] = useState([]);
  const [titleSearchKeywordError, setTitleSearchKeywordError] = useState("");
  const [twitterWordsCounter, setTwitterWordsCounter] = useState(0);
  const [twitterKeywordCounter, setTwitterKeywordCounter] = useState(0);
  const [twitterLanguages, setTiwtterLanguages] = useState([]);
  const [twitterCountries, setTwitterCountries] = useState([]);
  const [selectedFacebookPages, setSelectedFacebookPages] = useState([]);
  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  // count title of keyword that appear in every header of dataSource
  const countTitleSearchKeywords = (titleSearchKeyword) => {
    return getKeywordsLength(titleSearchKeyword);
  };

  useEffect(() => {
    const isTwitterPublicOrKeywords =
      config?.selectedMonitorType === "KEYWORD" ||
      config?.selectedMonitorType === "ACCOUNT_ANALYSIS";
    if (isTwitterPublicOrKeywords && config?.isTwitterOpen) {
      const { keywordsLength, otherLength } = getTotalTwitterWordsCount(
        keywords[0]?.twitter,
      );
      const totalKeywords =
        otherLength + twitterCountries?.length + twitterLanguages?.length;
      setTwitterWordsCounter(totalKeywords);
      setTwitterKeywordCounter(keywordsLength);
    }
  }, [
    config?.isTwitterOpen,
    config?.selectedMonitorType,
    getTotalTwitterWordsCount,
    keywords,
    twitterCountries?.length,
    twitterLanguages?.length,
  ]);

  useEffect(() => {
    setnewsBlogCount(
      wordCountNews +
        wordCountNewsSpamKeyword +
        language?.length +
        countries?.length +
        majorSource?.length +
        nbExcludedSource?.length +
        countTitleSearchKeywords(titleSearchKeyword),
    );
  }, [
    language,
    countries,
    majorSource,
    nbExcludedSource,
    titleSearchKeyword,
    wordCountNewsSpamKeyword,
    wordCountNews,
  ]);

  useEffect(() => {
    setMonitorInstagramUsedHashtags((currentHashtags) => [
      ...monitorInstagramOldHashtags,
      ...monitorInstagramSettingHashtags,
    ]);
  }, [monitorInstagramOldHashtags, monitorInstagramSettingHashtags]);

  //function to handle ICON COPY
  const handleData = (
    e,
    data,
    indexes,
    index,
    data_source_name = undefined,
  ) => {
    const keywordsCopy = [...keywords];
    Object.values(keywordsCopy[indexes]).map((val) => {
      val[index]["copy"] = true;
    });
    // In case of data source equals to IG: we need to remove hashtag from beginning of each word to be able to paste it
    if (data_source_name === "instagram") {
      data = data.map((val) => {
        if (val[0] === "#") {
          val = val.substring(1);
        }
        return val;
      });
    }
    navigator.clipboard.writeText(data);
    setKeywords(keywordsCopy);
  };

  //HANDLE KEYWORDS IN ALL DATA SOUECES //
  const handleKeywords = (
    event,
    keywordType,
    source,
    i,
    clickType,
    limit, //nbcondions limit in case of newsblogs
  ) => {
    const { value } = event.target;
    const selectedMonitorType = config?.selectedMonitorType;
    const isTwitterKeywords =
      source === "twitter" &&
      (selectedMonitorType === "KEYWORD" ||
        selectedMonitorType === "ACCOUNT_ANALYSIS");
    if (
      (event.keyCode === 13 || (clickType === "btn" && value)) &&
      data &&
      value?.trim() !== ""
    ) {
      const keywordsCopy = [...keywords];
      keywordsCopy.map((val, index) => {
        Object.values(val)[0]?.map((obj) => {
          if (Object.keys(obj)[0] === keywordType && source === sQuestion) {
            if (
              Object.values(obj)[0]?.length >= 0 &&
              Object.keys(val)[0] === sQuestion
            ) {
              // handle keywords & spam for each type & datasource to sent data to shared function
              let keywordsDataM = [],
                spamKeywordsD = [];
              if (
                keywordType === "hashtags" ||
                keywordType === "spam_hashtags"
              ) {
                keywordsDataM = Object.values(val)[0][0].hashtags;
                spamKeywordsD = Object.values(val)[0][1].spam_hashtags;
              } else if (
                keywordType === "keywords" ||
                keywordType === "spam_keywords"
              ) {
                keywordsDataM = Object.values(val)[0][0].keywords;
                spamKeywordsD = Object.values(val)[0][1].spam_keywords;
              } else {
                keywordsDataM = Object.values(val)[0][2].collect_tweets;
                spamKeywordsD = Object.values(val)[0][3].ignore_tweets;
              }

              let isNewsBlogsCreateValidateCount =
                language.length +
                countries.length +
                majorSource.length +
                nbExcludedSource.length +
                countTitleSearchKeywords(titleSearchKeyword);

              let twitterExccedKeywordsLimit = false;
              let usedkeywords =
                keywordType === "hashtags" && source === "instagram"
                  ? monitorInstagramUsedHashtags
                  : [];

              //using keywords shared function
              let objData = handlekeywordsShared(
                value,
                keywordType,
                keywordsDataM,
                spamKeywordsD,
                source,
                Object.values(val)[0], //keywords such as [{keywords:[]},{spamKeywords:[]}]
                limit,
                newsBlogCount, //in case newsblogs,
                isNewsBlogsCreateValidateCount,
                usedkeywords,
              );
              if (isTwitterKeywords) {
                const { keywordsLength, otherLength } =
                  checkKeywordLimitTwitter(
                    objData?.state_value,
                    Object.values(val)[0],
                    keywordType,
                  );
                const lengthArr =
                  keywordType === "keywords" ? keywordsLength : otherLength;
                const newCount = lengthArr?.reduce((a, b) => a + b, 0);
                const twitterNewCount = isTwitterKeywords
                  ? keywordType !== "keywords"
                    ? newCount +
                      twitterCountries?.length +
                      twitterLanguages?.length
                    : newCount
                  : 0;
                if (twitterNewCount > limit) {
                  twitterExccedKeywordsLimit = true;
                  obj.errorMsg =
                    selectedMonitorType === "ACCOUNT_ANALYSIS"
                      ? "public_monitor_limit_excceds"
                      : "other_fields_keywords_error_limit";
                }
              }
              if (!(twitterExccedKeywordsLimit && keywordType !== "keywords")) {
                obj.errorMsg = objData?.errorMsg;
              }
              if (!objData?.errorMsg && !twitterExccedKeywordsLimit) {
                if (
                  keywordType === "keywords" ||
                  keywordType === "spam_keywords"
                ) {
                  Object.values(val)[0][0].keywords = [...objData?.keywords];
                  Object.values(val)[0][1].spam_keywords = [
                    ...objData?.spamKeywords,
                  ];
                } else if (
                  keywordType === "hashtags" ||
                  keywordType === "spam_hashtags"
                ) {
                  Object.values(val)[0][0].hashtags = objData?.keywords;
                  Object.values(val)[0][1].spam_hashtags =
                    objData?.spamKeywords;
                } else {
                  Object.values(val)[0][2].collect_tweets = [
                    ...objData?.keywords,
                  ];
                  Object.values(val)[0][3].ignore_tweets = [
                    ...objData?.spamKeywords,
                  ];
                }
                if (keywordType === "hashtags" && objData?.count != undefined) {
                  let countHashtags = 0;
                  objData?.keywords?.map((keyword) => {
                    if (
                      !monitorInstagramUsedHashtags?.includes(
                        keyword?.replace("#", ""),
                      )
                    ) {
                      countHashtags++;
                    }
                  });
                  setWordCountInstagram(
                    source === "instagram" ? countHashtags : objData?.count,
                  );
                }
                if (source !== "newsBlogs" && !objData.exccedKeywordsLimit)
                  obj.answer = "";
              }

              //setcount of each datasource
              if (
                (keywordType !== "hashtags" ||
                  keywordType !== "spam_hashtags") &&
                !objData?.errorMsg
              ) {
                // if twitter, we already calculating the count automatically in the useEffect above.
                if (
                  source === "facebook" &&
                  (keywordType === "keywords" ||
                    objData?.obj_answer_string_tracker?.match(
                      /[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+\s(not|NOT)\s[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+/,
                    )) &&
                  !objData.exccedKeywordsLimit &&
                  objData?.count != undefined
                ) {
                  setWordCountFacebook(objData?.count);
                }
                if (
                  source === "newsBlogs" &&
                  objData?.countNews !== undefined &&
                  objData?.countNews[0] +
                    objData?.countNews[1] +
                    language.length +
                    countries.length +
                    majorSource.length +
                    countTitleSearchKeywords(titleSearchKeyword) +
                    nbExcludedSource.length <=
                    limit
                ) {
                  // Count of news here will be the sum of both Keywords & spam keywords
                  setWordCountNews(objData?.countNews[0]);
                  setWordCountNewsSpamKeyword(objData?.countNews[1]);
                  obj.answer = "";
                } else {
                  if (source === "newsBlogs") obj.answer = value;
                }
              }
            }
          }
        });
        setAnswer("");
        setSpamAnswer("");
        setCollecttweets("");
        setIgnoretweets("");
        setCopyValue("");
        setKeywords(keywordsCopy);
      });
    }
  };
  // handle validation for keyword type depend on DataSource
  const handleValidation = (event, keywordType, source, indexes) => {
    const { value } = event.target;
    keywords?.map((data) => {
      Object.values(data)?.map((val) => {
        const valObj = Object.values(val);
        valObj?.map((values, index) => {
          if (indexes === index && source === Object.keys(data)[0]) {
            let keywordsTypeContent = Object.values(valObj[0])[0];
            let spamKeywordsTypeContent = Object.values(valObj[1])[0];
            if (
              keywordType === "collect_tweets" ||
              keywordType === "ignore_tweets"
            ) {
              keywordsTypeContent = Object.values(valObj[2])[0];
              spamKeywordsTypeContent = Object.values(valObj[3])[0];
            }
            let errorMessage = handleValidationKeywords(
              value,
              keywordsTypeContent,
              spamKeywordsTypeContent,
              keywordType,
              setdata,
              source,
            );
            values.errorMsg = errorMessage;
          }
        });
      });
    });
  };

  // End Functions for handle Delete of any keyword in all dataSources
  const handleDelete = (e, index, source, indexes, keywordType) => {
    const keywordCopy = [...keywords];

    keywordCopy[indexes]?.[keywordType]?.map((obj) => {
      const values = Object.values(obj);

      if (Object.keys(obj)[0] === source) {
        let keywords = [...Object.values(obj)[0]],
          numberOfKeywords;

        if (keywordType === "instagram" && source === "hashtags") {
          keywords = keywords?.filter(
            (keyword) =>
              keyword !== keywords[index] &&
              !monitorInstagramUsedHashtags?.includes(
                keyword?.replace("#", ""),
              ),
          );
        } else {
          keywords = keywords?.filter((keyword) => keyword !== keywords[index]);
        }
        numberOfKeywords = getKeywordsLength(keywords);

        // we already calculate the count of twitter keywords in the useEffect above.

        if (keywordType === "facebook" && source === "keywords") {
          setWordCountFacebook(numberOfKeywords);
        }

        if (keywordType === "instagram" && source === "hashtags") {
          setWordCountInstagram(numberOfKeywords);
        }
        if (keywordType === "newsBlogs") {
          let keyData = [
            ...Object.values(keywordCopy[indexes]?.[keywordType][0])[0],
          ];
          let spamKeyData = [
            ...Object.values(keywordCopy[indexes]?.[keywordType][1])[0],
          ];
          if (source === "keywords") {
            keyData = keyData.filter((keyword) => keyword !== keyData[index]);
          }

          if (source === "spam_keywords") {
            spamKeyData = spamKeyData.filter(
              (keyword) => keyword !== spamKeyData[index],
            );
          }
          setWordCountNews(getKeywordsLength(keyData));
          setWordCountNewsSpamKeyword(getKeywordsLength(spamKeyData));
        }
        values[0]?.splice(index, 1);
      }
    });

    setKeywords(keywordCopy);
  };

  // End Keywords Monitor_____________________________________________
  return {
    keywords,
    handleKeywords,
    setKeywords,
    answer,
    setAnswer,
    collectTweets,
    setCollecttweets,
    setSpamAnswer,
    spamAnswer,
    ignoreTweets,
    setIgnoretweets,
    isVarifiedAccounts,
    setIsVarifiedAccounts,
    ignoretweetsFlag,
    setIgnoreTweetsFlag,
    sQuestion,
    setSQuestion,
    handleDelete,
    copyValue,
    setCopyValue,
    handleValidation,
    handleData,
    newsBlogCount,
    wordCountNews,
    wordCountFacebook,
    wordCountInstagram,
    wordCountNewsSpamKeyword,
    language,
    setLanguage,
    countries,
    setCountries,
    majorSource,
    setMajorSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    nbExcludedSource,
    setNbExcludedSource,
    setSelectedSource,
    selectedSource,
    titleSearchKeyword,
    setTitleSearchKeyword,
    countTitleSearchKeywords,
    titleSearchKeywordError,
    setTitleSearchKeywordError,
    nbExcludedSourceError,
    setNbExcludedSourceError,
    setWordCountFacebook,
    setWordCountInstagram,
    setnewsBlogCount,
    setWordCountNews,
    setWordCountNewsSpamKeyword,
    twitterCountries,
    setTwitterCountries,
    twitterLanguages,
    setTiwtterLanguages,
    twitterWordsCounter,
    setTwitterWordsCounter,
    selectedFacebookPages,
    setSelectedFacebookPages,
    twitterKeywordCounter,
    setTwitterKeywordCounter,
    monitorInstagramOldHashtags,
    setMonitorInstagramOldHashtags,
    monitorInstagramSettingHashtags,
    setMonitorInstagramSettingHashtags,
  };
};

export default SharedFunctions;
