import { Box, Typography, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { CheckValueLocale } from "utils/helpers";
import EmptyCalenderImage from "images/engagements-images/empty-calender.svg";

const UpcomingSection = ({
  upcommingList,
  handleDatasourcesIcon,
  handleTruncateName,
  upcomingLoader, // will replace to upcomming loader
}) => {
  const intl = useIntl();
  return (
    <Box
      className={`${!upcomingLoader && "accounts-sections-container-loading"}`}
    >
      {upcommingList?.length || !upcomingLoader ? (
        <Box className="accounts-sections-container">
          <Typography component={"p"} className="accounts-publish-title">
            {CheckValueLocale("publish_upcoming", "", {}, intl)}
          </Typography>
          {!upcomingLoader ? null : (
            <Box className="upcoming-publish-container">
              {upcommingList?.map((item) => {
                return (
                  <Box className="upcoming-publish-content">
                    <Typography
                      component={"p"}
                      className="upcoming-publish-title"
                    >
                      {CheckValueLocale(item?.title, "", {}, intl)}
                    </Typography>
                    {item?.accountList?.map((listItem) => {
                      return (
                        <Box>
                          <Tooltip
                            id="upcoming-tooltip-section"
                            title={
                              <Box className="tooltip-account-content">
                                <Typography
                                  component={"p"}
                                  className="tooltip-account-content-title"
                                >
                                  {CheckValueLocale(
                                    "publish_accounts_content",
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Typography>
                                <Box className="content-account-list">
                                  {listItem?.content}
                                </Box>
                              </Box>
                            }
                            placement="right"
                            arrow
                          >
                            <Box className="upcomming-accounts-content">
                              <Box className="upcoming-datasource-accounts">
                                <img
                                  src={handleDatasourcesIcon(
                                    listItem?.datasource?.toLowerCase(),
                                  )}
                                  className="accounts-datasource-icon"
                                />

                                {listItem?.name?.length > 10 ? (
                                  <Tooltip
                                    title={listItem?.name}
                                    arrow
                                    placement="top"
                                  >
                                    <Typography
                                      component={"span"}
                                      className="upcoming-accounts-title"
                                    >
                                      {handleTruncateName(listItem?.name, 10)}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography
                                    component={"span"}
                                    className="upcoming-accounts-title"
                                  >
                                    {handleTruncateName(listItem?.name, 10)}
                                  </Typography>
                                )}
                              </Box>
                              <Box className="upcoming-schedule-content">
                                <Typography
                                  component={"span"}
                                  className="upcoming-accounts-time"
                                >
                                  {listItem?.time}
                                </Typography>
                                <HourglassTopIcon className="upcoming-schedule-icon" />
                              </Box>
                            </Box>
                          </Tooltip>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      ) : (
        <Box className="empty-upcoming-container">
          <Box>
            <img src={EmptyCalenderImage} className="empty-calender-icon" />
          </Box>
          <Typography component={"p"} className="upcoming-publish-empty-title">
            {CheckValueLocale("publish_empty_upcoming_title", "", {}, intl)}
          </Typography>
          <Typography component={"p"} className="upcoming-publish-empty-desc">
            {CheckValueLocale("publish_empty_upcoming_message", "", {}, intl)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UpcomingSection;
