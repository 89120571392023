import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import InsightsPageHeader from "../insightsPageHeader.js";
import SocialMediaBody from "./SocialBody.js";
import InsightsController from "services/controllers/insightsController.js";
import { connectWithRabbitMQ } from "../insightsCommonFunctions.js";
import FiltersController from "services/controllers/filtersController";
import moment from "moment";
import {
  isEmptyObj,
  handleCAFiltersIds,
  handleCAFiltersVals,
  handleApplicableFiltersCA,
  checkEmptyValuesinObjectOfArrays,
  getIdsFromFilter,
  handleFiltersParamsIds,
} from "utils/helpers";
import {
  getDataSourceCategories,
  getDataSources,
  getChannelsUsage,
} from "../../channelAnalytics.hooks.js";
import { useParams } from "react-router-dom";
import styles from "../insightsPage.module.css";
import EmptyPage from "../emptyPage.js";
import EmptyHeader from "../emptyHeader.js";
import CircularLoading from "components/circularProgress.js";
import AlertMessage from "shared/alert/alertMessage.js";
import { getActiveParams } from "shared/channel-analytics-filter/activeFilters/activeFilters.js";
import { useSelector, useDispatch } from "react-redux";
import {
  filtersDropDownToDisplay,
  reduxApplicableFilters,
} from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice.js";
import { useIntl } from "react-intl";
import NoDataFoundProducts from "components/no-Data/index.js";
import NoDataLinks from "components/no-Data/noDataLinks.js";

const SocialMediaPage = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  /* Start Redux Declarations */
  const reduxStartDateUx = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );

  const reduxEndDateUx = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );

  const reduxFilterParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );

  const reduxApplicableFiltersCA = useSelector(
    (state) => state?.channelAnalytics?.applicableFilters,
  );
  /* End Redux Declarations */

  const [response, setResponse] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  const [dataSources, setDataSources] = useState([]);
  let startDate = reduxStartDateUx;
  let endDate = reduxEndDateUx;
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const urlParams = useParams();
  const dataSourceId = urlParams.data_source_id;
  const [resetAllFields, setResetAllFields] = useState(0);
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  const [companyCanCreateChannel, setCompanyCanCreateChannel] = useState(false);
  const [reachInteractionsLimit, setReachInteractionsLimit] = useState(false);
  const [reachInteractionsLimitAlert, setReachInteractionsLimitAlert] =
    useState(true);
  const [dialectsSubDialectsData, setDialectsSubDialectsData] = useState([]);

  const [isApplicableFilterCalculated, setIsApplicableFilterCalculated] =
    useState(false);

  const [checkAllDataFetchingExport, setCheckAllDataFetchingExport] =
    useState(false);
  const [excelSheetData, setExcelSheetData] = useState([]);

  useEffect(() => {
    getCategories();
    getChannelsUsage(setCompanyCanCreateChannel);
    getDialectSubDialects();
  }, []);
  const getCategories = () => {
    getDataSourceCategories("Social Media", setIsActive, setIsLoading);
  };

  useEffect(() => {
    if (isActive) {
      getDataSources(setDataSources);
      getFiltersFileds();
    }
  }, [isActive]);

  const getCredentials = (startDate, endDate, filterParams, custom) => {
    setResponse([]);
    InsightsController.getSocialMediaCredentials(
      startDate,
      endDate,
      filterParams,
      custom,
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      if (!isEmptyObj(data.data)) {
        setReachInteractionsLimit(data?.data?.interactions_limit_reach);
        setPreLoaderTrackerDataSources(data?.data?.data_sources);

        connectWithRabbitMQ(
          mqttConnectionClosed,
          clientMQTT,
          data.data,
          setResponse,
        );
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };

  const applyFilter = (
    startDate,
    endDate,
    applicableFilters,
    applicableFilterCalculated,
  ) => {
    if (isApplicableFilterCalculated || applicableFilterCalculated) {
      let selectedFilterParams = applicableFilters
        ? applicableFilters
        : !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
          ? reduxApplicableFiltersCA
          : assignActiveFilters;

      if (
        applicableFilters === undefined &&
        !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
      ) {
        const commonObject = {};
        const obj = {
          ...filterFields?.by_interactions,
          ...filterFields?.by_user,
        };

        const finalArr = getIdsFromFilter(obj);
        Object.keys(reduxApplicableFiltersCA).forEach((key) => {
          if (
            Array.isArray(reduxApplicableFiltersCA[key]) &&
            Array.isArray(finalArr[key])
          ) {
            const commonValues = reduxApplicableFiltersCA[key].filter(
              (value) => {
                return (
                  finalArr[key].findIndex(
                    (item) =>
                      item?.toString()?.toLowerCase() ===
                      value?.toString()?.toLowerCase(),
                  ) !== -1
                );
              },
            );
            commonObject[key] = commonValues;
          } else {
            commonObject[key] = [];
          }
        });
        selectedFilterParams = { ...commonObject };
        selectedFilterParams = handleFiltersParamsIds(
          selectedFilterParams,
          filterFields,
        );
      }

      const resp = handleCAFiltersIds(selectedFilterParams, filterFields);
      const {
        dmMonitorListFullName,
        waitingFilterFullName,
        monitorListFullName,
      } = resp;
      selectedFilterParams = resp?.selectedFilterParams;
      setResetAllFields((prev) => prev + 1);

      selectedFilterParams = handleCAFiltersVals(
        dmMonitorListFullName,
        monitorListFullName,
        waitingFilterFullName,
        selectedFilterParams,
      );
      setAssignActiveFilters(selectedFilterParams);
      getCredentials(startDate, endDate, selectedFilterParams, true);
    }
  };

  const getFiltersFileds = () => {
    FiltersController.getSocialMediaFiltersFileds(
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      setFilterFields(data?.data);
    });
  };

  useEffect(() => {
    if (!isEmptyObj(filterFields)) {
      let applicableFilters = handleApplicableFiltersCA(
        filterFields,
        reduxFilterParams,
      );
      let commonObject = {};
      const obj = {
        ...filterFields?.by_interactions,
        ...filterFields?.by_user,
      };

      const finalArr = getIdsFromFilter(obj);

      Object.keys(applicableFilters).forEach((key) => {
        if (
          Array.isArray(applicableFilters[key]) &&
          Array.isArray(finalArr[key])
        ) {
          const commonValues = applicableFilters[key].filter((value) => {
            return (
              finalArr[key].findIndex((item) =>
                key === "sub_themes_filter"
                  ? item?.value?.toString() === value?.toString()
                  : item?.toString()?.toLowerCase() ===
                    value?.toString()?.toLowerCase(),
              ) !== -1
            );
          });
          commonObject[key] = commonValues;
        } else {
          commonObject[key] = [];
        }
      });

      setIsApplicableFilterCalculated(true);
      applyFilter(
        startDate,
        endDate,
        handleFiltersParamsIds(commonObject, filterFields),
        true,
      );
      dispatch(reduxApplicableFilters(applicableFilters));
      dispatch(filtersDropDownToDisplay(commonObject));
    }
  }, [filterFields]);

  const getDataLowerCase = (data) => {
    let arrDialects = [];
    data?.map((el) => {
      arrDialects.push(el?.toLowerCase());
    });
    return arrDialects;
  };
  const getDialectSubDialects = () => {
    InsightsController.getDialectsSubDialects().then((data) => {
      let obj = {};
      data?.data?.dialects &&
        Object.entries(data?.data?.dialects)?.map(([key, val]) => {
          obj[key] = getDataLowerCase(val);
        });
      setDialectsSubDialectsData(obj);
    });
  };
  let activeFiltersArr = getActiveParams(assignActiveFilters);

  return (
    <Container maxWidth="xl" className={styles.insightsStyle}>
      {isLoading === true ? (
        <CircularLoading />
      ) : (
        <>
          {isActive === true ? (
            <>
              <InsightsPageHeader
                title="Social Media"
                showNewChannelBtn={true}
                showDatePicker={true}
                showFiltersBtn={true}
                filterFields={filterFields}
                applyFilter={applyFilter}
                startDate={startDate}
                endDate={endDate}
                showBreadCrumb={false}
                assignActiveFilters={assignActiveFilters}
                allActiveFilters={activeFiltersArr}
                isActive={isActive}
                companyCanCreateChannel={companyCanCreateChannel}
                sectionName="Social Media"
                luciFeature="Analytics"
                dataLoaded={checkAllDataExist}
                dataEmpty={checkDataIsEmpty}
                showExportBtn
                isExportLoaded={checkAllDataFetchingExport}
                excelSheetData={excelSheetData}
                monitorDataSource={"Social_media"}
                datasourceName={"Social_media"}
                datasourcePath={"social_media/insights"}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                isAggregatePageCA
              />

              <AlertMessage
                mainFlag={reachInteractionsLimit}
                alertMainFlag={reachInteractionsLimitAlert}
                setAlertMainFlag={setReachInteractionsLimitAlert}
                alertMessageId={"interactions_limit_alert"}
              />

              <SocialMediaBody
                response={response}
                dataSources={dataSources}
                resetAllFields={resetAllFields}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                assignActiveFilters={assignActiveFilters}
                setAssignActiveFilters={setAssignActiveFilters}
                startDate={startDate}
                endDate={endDate}
                applyFilter={applyFilter}
                allActiveFilters={activeFiltersArr}
                filterFields={filterFields}
                setCheckAllDataExist={setCheckAllDataExist}
                setCheckDataIsEmpty={setCheckDataIsEmpty}
                dialectsSubDialectsData={dialectsSubDialectsData}
                setCheckAllDataFetchingExport={setCheckAllDataFetchingExport}
                setExcelSheetData={setExcelSheetData}
              />
            </>
          ) : (
            <div className="empty-box">
              <EmptyHeader
                title="Social Media"
                showNewChannelBtn={true}
                showDatePicker={true}
                showFiltersBtn={true}
                companyCanCreateChannel={companyCanCreateChannel}
              />
              <Box className="empty-box-insights-page">
                <NoDataFoundProducts
                  image="socialMedia"
                  title="analyze_your_social"
                  description="click_new_channel"
                  linkOne="how_to_use"
                  linkOneHref={NoDataLinks.insights_link}
                  orCase="or_visit_the"
                  linkTwo="knowledge_base"
                />
              </Box>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default SocialMediaPage;
