import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import "./twitterReplyTo.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";
import { useTwitterReplyTo } from "./useTwitterReplyTo";
import { UsersListMenu } from "./usersListMenu";

export const TwitterReplyTo = ({
  selectedUsers,
  setSelectedUsers,
  users,
  handleTextInputFocus,
}) => {
  const {
    visibleUsersForHiddenReplyRow,
    visibleUsersForShowedReplyRow,
    hiddenUsersCount,
    maxWidthForTrucatedLastVisibleUser,
    truncatedUser,
    handleUserDelete,
    handleUserAdd,
    containerRef,
    textRef,
    menuRef,
    userListRef,
  } = useTwitterReplyTo({
    setSelectedUsers,
    selectedUsers,
  });

  const handleDeleteUserFromList = (id) => {
    handleUserDelete(id);
    handleTextInputFocus?.();
  };

  return (
    <Box className="twitter-reply-to-root">
      {/*
      here we are maintaining the two rows for the reply to users
      first row will be hidden and will be use for the calculation of the visible users, since we don't want to show the shiffting of the users in the visible row
      and when the hidden row is filled with the right amount of users then we will update the visible row with the users from the hidden row
      and like that we have avioded the shifting of the users in the visible row
      */}
      <ReplyToUsersRow // hidden row
        isHidden={true}
        usersList={visibleUsersForHiddenReplyRow}
        hiddenUsersCount={hiddenUsersCount}
        containerRef={containerRef}
        textRef={textRef}
        menuRef={menuRef}
        userListRef={userListRef}
      />
      <ReplyToUsersRow // visible row
        usersList={visibleUsersForShowedReplyRow}
        hiddenUsersCount={hiddenUsersCount}
        handleUserDelete={handleDeleteUserFromList}
        handleUserAdd={handleUserAdd}
        maxWidthForTrucatedLastVisibleUser={maxWidthForTrucatedLastVisibleUser}
        truncatedUser={truncatedUser}
        users={users}
        selectedUsers={selectedUsers}
        handleTextInputFocus={handleTextInputFocus}
      />
    </Box>
  );
};

const ReplyToUsersRow = ({
  isHidden,
  usersList,
  handleUserDelete,
  handleUserAdd,
  containerRef,
  textRef,
  menuRef,
  userListRef,
  hiddenUsersCount,
  maxWidthForTrucatedLastVisibleUser,
  truncatedUser,
  users = [],
  selectedUsers = [],
  handleTextInputFocus,
}) => {
  const intl = useIntl();
  return (
    <Box
      className={classNames(
        "twitter-reply-to-container",
        isHidden ? "hidden-replay-to" : "",
      )}
      ref={containerRef}
    >
      <Typography className="twitter-reply-to-text" ref={textRef}>
        {CheckValueLocale("reply_to_twitter", "", {}, intl)}
      </Typography>
      <UserList
        usersList={usersList}
        handleUserDelete={handleUserDelete}
        userListRef={userListRef}
        hiddenUsersCount={hiddenUsersCount}
        maxWidthForTrucatedLastVisibleUser={maxWidthForTrucatedLastVisibleUser}
        truncatedUser={truncatedUser}
      />
      <UsersListMenu
        selectedUsers={selectedUsers}
        users={users}
        handleUserDelete={handleUserDelete}
        handleUserAdd={handleUserAdd}
        menuRef={menuRef}
        handleTextInputFocus={handleTextInputFocus}
        showMenu={!isHidden}
      />
    </Box>
  );
};

const UserList = ({
  usersList,
  handleUserDelete,
  userListRef,
  hiddenUsersCount,
  maxWidthForTrucatedLastVisibleUser,
  truncatedUser,
}) => {
  return (
    <Box className="twitter-reply-to-users-list" ref={userListRef}>
      {usersList?.map((user, idx) => (
        <UserChip
          key={user.id}
          onDelete={handleUserDelete}
          user={user}
          isDeletable={user?.postOwner === false}
          index={idx}
        />
      ))}
      {maxWidthForTrucatedLastVisibleUser > 0 && truncatedUser ? (
        <Box
          sx={{
            maxWidth: maxWidthForTrucatedLastVisibleUser,
          }}
          className="twitter-reply-to-truncated-user"
        >
          <UserChip
            user={truncatedUser}
            onDelete={handleUserDelete}
            isTruncated={true}
            isDeletable={truncatedUser?.postOwner === false}
            index={usersList?.length}
          />
        </Box>
      ) : null}
      {hiddenUsersCount > 0 ? (
        <Box className="twitter-reply-to-hidden-users">+{hiddenUsersCount}</Box>
      ) : null}
    </Box>
  );
};

const UserChip = ({
  user,
  onDelete,
  isTruncated,
  isDeletable = true,
  index = 0,
}) => {
  const intl = useIntl();
  const usernameEn = `@${user?.username}`;
  const usernameAr = `${user?.username}@`;
  const username = intl?.locale === "en" ? usernameEn : usernameAr;

  return (
    <Box className="twitter-reply-to-user-chip">
      {isTruncated ? (
        <TruncatedTooltip
          className="user-chip-text"
          title={usernameEn}
          placement="bottom"
        />
      ) : (
        <Typography className="user-chip-text">{username}</Typography>
      )}
      {isDeletable ? (
        <FontAwesomeIcon
          onClick={() => onDelete?.(user?.id)}
          icon={faCircleXmark}
          id={`twitter-reply-to-delete-user-${index}`}
        />
      ) : null}
    </Box>
  );
};
