import { Box, Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { CheckValueLocale, commasAfterDigit } from "utils/helpers";
import BarChartWidget from "components/widgets/barChartWidget";
import { faQuestion, faPercent } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
const CustomerCareSection = ({
  faceBookWidgets,
  preLoaderTrackerDataSources,
  FacebookIcon,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [isHiddenFb, setIsHiddenFb] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("customer_care")) {
      setIsHiddenFb(false);
    } else {
      setIsHiddenFb(true);
    }
  }, []);

  const handleClick = () => {
    localStorage.setItem("customer_care", false);
    setIsHiddenFb(false);
  };

  return (
    <Box>
      <Typography variant="h6" className="ca-head">
        {CheckValueLocale("customer_care", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        {isHiddenFb ? (
          <Box className="customer-care-alert margin-bottom">
            <Box className="start-box">
              <InfoOutlinedIcon />
              <Typography>
                {CheckValueLocale("customer_care_alerts_channel", "", {}, intl)}
              </Typography>
            </Box>
            <Box className="end-box">
              <Button onClick={() => history.push("/manage-channels/list/")}>
                {CheckValueLocale("manage_channels", "", {}, intl)}
              </Button>
              <FontAwesomeIcon onClick={handleClick} icon={faTimes} />
            </Box>
          </Box>
        ) : null}
        <Box className="chart-body">
          <BarChartWidget
            title={"customer_performance"}
            titleToolTip={"customer_performance_fb_tooltip"}
            data={faceBookWidgets?.customerCareData}
            showAI={true}
            showDownloadIcon={true}
            showChartStatsWidget={true}
            showZoomPercentage
            customerCareChart
            respTimeDistributionTooltip="respone_time_distribution_tooltip"
            gridHeight={"70%"}
            statsData={[
              {
                name: "total_ques",
                value:
                  faceBookWidgets?.totalQuestion &&
                  commasAfterDigit(faceBookWidgets?.totalQuestion),
                icon: faQuestion,
                titleToolTip: "number_of_ques_tooltip",
              },
              {
                name: "number_of_answered_questions",
                value: isNaN(faceBookWidgets?.respRate * 100)
                  ? 0 + "%"
                  : faceBookWidgets?.respRate?.toFixed(2) * 100 + "%",
                icon: faPercent,
                titleToolTip: "response_rate_tooltip",
              },
              {
                name: "avg_response_time_in_seconds",
                value:
                  faceBookWidgets?.customerCarePerformance
                    ?.avg_response_time_in_seconds,
                icon: faClock,
                type: "time",
                titleToolTip: "avg_response_time_tooltip",
              },
            ]}
            dataColor="#2AAAE2"
            toolTipLabel={"posts"}
            isTwitterAccount
            barChartPreLoaderDataSources={
              faceBookWidgets?.customerCarePreloader
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            iconAfterTitle={FacebookIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerCareSection;
