import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
const ShowMoreBtn = ({
  name,
  accTableData,
  accTableDetailsData,
  setAccTableDetailsData,
  setAccTableData,
}) => {
  const intl = useIntl();
  const handleShowMore = () => {
    if (
      accTableDetailsData[`${name}_page`] < accTableDetailsData[`${name}_pages`]
    ) {
      setAccTableDetailsData({
        ...accTableDetailsData,
        [`${name}_page`]: accTableDetailsData[`${name}_page`] + 1,
      });
    } else {
      if (accTableDetailsData[name]?.length == accTableData[name]?.length) {
        setAccTableData({
          ...accTableData,
          [name]: [...accTableDetailsData[name]?.slice(0, 6)],
        });
        document.getElementById(name).scrollIntoView();
      } else {
        setAccTableData({
          ...accTableData,
          [name]: [...accTableDetailsData[name]],
        });
      }
    }
  };
  return (
    <Box className="show-more-container">
      <LucButton
        variant={
          accTableDetailsData[`${name}_counter`] == accTableData[name]?.length
            ? "outline"
            : "filled"
        }
        loading={accTableDetailsData[`${name}_loading`]}
        onClick={!accTableDetailsData[`${name}_loading`] && handleShowMore}
      >
        {CheckValueLocale(
          +accTableDetailsData[`${name}_counter`] == accTableData[name]?.length
            ? "show_less"
            : name == "account_access"
              ? "show_more_page"
              : "show_more_btn_num",
          "",
          {
            num:
              +accTableDetailsData[`${name}_counter`] -
              accTableData[name]?.length,
          },
          intl,
        )}
      </LucButton>
    </Box>
  );
};
export default ShowMoreBtn;
