import React from "react";
import "./fetchingData.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import fetchingImage from "images/noDataFound/fetchingImage.gif";

const FetchingData = () => {
  const intl = useIntl();

  return (
    <Box className="empty-data-search empty-data-search-Box">
      <img
        src={fetchingImage}
        alt="No Data Available Image"
        className="fetching-image"
      />
      <Box className="no-data-date">
        <Typography component={"h3"} className="no-data-found">
          {CheckValueLocale("collecting_data", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export default FetchingData;
