import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import PieChartWidget from "components/widgets/pieChartWidget";
import BarChartWidget from "components/widgets/barChartWidget";
import OverView from "components/widgets/overViewWidget.js";
import QuestionsStatistics from "./questionsStatistics";
import { handleArraySum } from "../../../channelAnalytics/InsightsPage/insightsCommonFunctions";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import _ from "lodash";
import { CheckValueLocale, pieChartSort } from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";

const SurveyWidgets = (props) => {
  const intl = useIntl();
  const { response, individualSurvey, setIndividualSurvey, page, setPage } =
    props;
  const [countries, setCountries] = useState([]);
  const [countriesPreLoader, setCountriesPreLoader] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesPreLoader, setCitiesPreLoader] = useState([]);
  const [browser, setBrowser] = useState([]);
  const [browserPreLoader, setBrowserPreLoader] = useState([]);
  const [questionsOvertime, setQuestionsOvertime] = useState([]);
  const [questionsOvertimePreLoader, setQuestionsOvertimePreLoader] = useState(
    [],
  );
  const [questionsStatistics, setQuestionsStatistics] = useState([]);
  const [questionsStatisticsPreLoader, setQuestionsStatisticsPreLoader] =
    useState([]);
  const [overview, setOverView] = useState([]);

  const [individualSurveyPreLoader, setIndividualSurveyPreLoader] = useState(
    [],
  );
  const [nps, setNps] = useState([]);
  const [npsPreLoader, setNpsPreLoader] = useState([]);
  const [npsScore, setNpsScore] = useState("");

  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsArray, setDialectsArray] = useState([]);
  const [subDialectsArray, setSubDialectsArray] = useState([]);

  const [dialectsPreLoaderDataSources, setDialectsPreLoaderDataSources] =
    useState([]);
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );

  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);

  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoaderDataSources, setThemeTrendPreLoaderDataSources] =
    useState([]);
  const [
    themeAnalysisPreLoaderDataSources,
    setThemeAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });
  const [overviewArray, setOverviewArray] = useState([]);
  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);

  const { checkLuciAllDataFounded } = LuciFunctions();
  // __________________________________________________

  let dialectsResult = [],
    subDialectsResult = [],
    countriesResult = [],
    citiesResult = [],
    overviewResult = [];

  let valueExist = nps?.every((val) => val?.value === 0);
  let nameExist = nps?.every((val) => val?.name === null);

  useEffect(() => {
    switch (response?.eventName) {
      case "SurveyPage__overview":
        if (response?.eventData) {
          Object.entries(response?.eventData?.overview)?.map((i) => {
            if (i[0] === "net_sentiment") {
              i[1]?.map((sentiment) => {
                if (sentiment?.name == "negative") {
                  setNetSentiment((netSentiment) => ({
                    ...netSentiment,
                    negative: netSentiment["negative"] + sentiment?.value,
                  }));
                }
                if (sentiment?.name == "positive") {
                  setNetSentiment((netSentiment) => ({
                    ...netSentiment,
                    positive: netSentiment["positive"] + sentiment?.value,
                  }));
                }
                if (sentiment?.name == "neutral") {
                  setNetSentiment((netSentiment) => ({
                    ...netSentiment,
                    neutral: netSentiment["neutral"] + sentiment?.value,
                  }));
                }
              });
            } else {
              overviewArray.push({ name: i[0], value: i[1] });
            }
          });
          setOverViewPreLoaderDataSources([response?.data_source]);
        }
        break;
      case "SurveyPage__questions_overtime":
        setQuestionsOvertime(response?.eventData?.questions_overtime);
        setQuestionsOvertimePreLoader([response?.data_source]);
        break;
      case "SurveyPage__top_cities":
        response?.eventData?.cities?.map((i) => {
          citiesArray?.push(i);
        });
        handleArraySum(citiesArray, citiesResult, setCities);
        setCitiesPreLoader([response?.data_source]);
        break;
      case "SurveyPage__associated_topics":
        setAssociatedTopics(response?.eventData?.associated_topics);
        setAssociatedTopicsPreLoader([response?.data_source]);
        break;
      case "SurveyPage__sentiment_analysis":
        setSentimentAnalysis(response?.eventData?.sentiment_overtime);
        setSentimentAnalysisPieChart(response?.eventData?.sentiment_piechart);
        setSentimentAnalysisPreLoader([response?.data_source]);
        break;
      case "SurveyPage__top_countries":
        response?.eventData?.countries?.map((i) => {
          return countriesArray?.push(i);
        });
        handleArraySum(countriesArray, countriesResult, setCountries);
        setCountriesPreLoader([response?.data_source]);
        break;
      case "SurveyPage__browser":
        setBrowser(response?.eventData?.browser);
        setBrowserPreLoader([response?.data_source]);
        break;
      case "SurveyPage__questions_statistics":
        setQuestionsStatistics(response?.eventData?.questions_statistics);
        setQuestionsStatisticsPreLoader([response?.data_source]);
        break;
      case "SurveyPage__individual_answer":
        setIndividualSurvey(response?.eventData?.individual_answer);
        setIndividualSurveyPreLoader([response?.data_source]);
        break;
      case "SurveyPage__nps":
        setNps(response?.eventData.nps);
        setNpsPreLoader([response?.data_source]);
        setNpsScore(response?.eventData?.nps_score.toString());
        break;
      case "SurveyPage__themes":
        setThemeAnalysis(response?.eventData?.themes?.theme_analysis);
        setThemeTrend(response?.eventData?.themes?.theme_trend);
        setThemeTrendPreLoaderDataSources([response?.data_source]);
        setThemeAnalysisPreLoaderDataSources([response?.data_source]);
        break;
      case "SurveyPage__dialects_and_sub_dialects":
        response?.eventData?.dialects?.map((dialect) => {
          return dialectsArray?.push(dialect);
        });
        response?.eventData?.sub_dialects?.map((subDialect) => {
          return subDialectsArray?.push(subDialect);
        });
        handleArraySum(dialectsArray, dialectsResult, setDialects);
        handleArraySum(subDialectsArray, subDialectsResult, setSubDialects);
        setDialectsPreLoaderDataSources([response?.data_source]);
        break;
    }
  }, [response]);

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment)?.reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverView,
      false,
      highest_sentiment,
    );
  };
  // __________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setCountries([]);
      setCountriesPreLoader([]);
      setCities([]);
      setCitiesPreLoader([]);
      setBrowser([]);
      setBrowserPreLoader([]);
      setQuestionsOvertime([]);
      setQuestionsOvertimePreLoader([]);
      setQuestionsStatistics([]);
      setQuestionsStatisticsPreLoader([]);
      setOverView([]);
      setIndividualSurvey([]);
      setIndividualSurveyPreLoader([]);
      setNps([]);
      setNpsPreLoader([]);
      setCitiesArray([]);
      setCountriesArray([]);
      setDialectsArray([]);
      setSubDialectsArray([]);
      setDialects([]);
      setSubDialects([]);

      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });
      setOverviewArray([]);

      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setSentimentAnalysisPieChart([]);
    }
  }, [props.resetAllFields]);
  // __________________________________________________

  const handleCheckDataIsEmpty = (data) => {
    let isEmpty = true;
    if (data?.length > 0 && Object?.values(data[0])?.length > 0) {
      Object.values(data[0])?.forEach((item) =>
        item?.questions?.map((question) => {
          if (question?.answer_list?.length > 0) return (isEmpty = false);
        }),
      );
    }
    return isEmpty;
  };

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      questionsStatisticsPreLoader,
      props.preLoaderTrackerDataSources,
    );
    let y = handleCheckDataIsEmpty([questionsStatistics]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    questionsStatistics,
    questionsStatisticsPreLoader,
    props.preLoaderTrackerDataSources,
  ]);

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });

  return (
    <>
      <Typography variant="h6" className="survey-title">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>
      <OverView
        data={overview}
        overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        page={"surveyInsights"}
      />
      <Typography variant="h6" className="survey-title">
        {CheckValueLocale("metrics", "", {}, intl).toUpperCase()}
      </Typography>
      <BarChartWidget
        title="completion_per_questions"
        titleToolTip="completion_per_questions_survey_tooltip"
        showDownloadIcon={false}
        dataColor="#2AAAE2"
        data={questionsOvertime}
        barChartPreLoaderDataSources={questionsOvertimePreLoader}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        applyAxisFormatter=" %{value}"
        isSurveyInsights
      />
      <Typography variant="h6" className="survey-title">
        {CheckValueLocale("responses_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <StackedLineWidget
            title={"sentiment_overtime_legend"}
            titleToolTip="responses_sentiment_analysis_tooltip"
            data={sentimentAnalysis}
            showGroupBy={true}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            hideLegend
            showDownloadIcon
            bigImg
            showAI
            stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            isSwitchCase
            iconAfterTitle={props.FacebookIcon}
            showGroupByClientAgent={true}
            showScales
            dataPiechart={sentimentPieChart}
            labelColors={["#89BB2A", "#E50035", "#F9A700"]}
            colors={["#F9A700", "#89BB2A", "#E50035"]} // yellow, green, red
            isSentimentAnalysisShowCharts
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            titleToolTip="dialects_per_subdialects_tooltip"
            title={"dialects_subdialects"}
            data={dialects}
            subData={subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            bigImg
            showDownloadIcon
            pieChartPreLoaderDataSources={dialectsPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"associated_topics"}
            titleToolTip={"responses_associated_topics_tooltip"}
            data={associatedTopics}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            bigImg
            showDownloadIcon
            pieChartPreLoaderDataSources={associatedTopicsPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={12}>
          <ThemesAnalysis
            title={"themes"}
            titleToolTip="themes_tooltip"
            data={themeAnalysis}
            themeAnalysisPreLoaderDataSources={
              themeAnalysisPreLoaderDataSources
            }
            showAI
            showDownloadIcon
            bigImg
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            themesList={themesOptions}
            isSurvey
          />
        </Grid>
        <Grid item md={12}>
          <ThemesTrends
            title={"themes_trend"}
            titleToolTip="themes_trend_tooltip"
            data={themeTrend}
            showGroupBy={false}
            showAI
            showDownloadIcon
            bigImg
            themeTrendPreLoaderDataSources={themeTrendPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            themesList={themesOptions}
            isSurvey
          />
        </Grid>
        <Grid item md={6}>
          <div className="nps-item-parant">
            {_.isEqual(
              npsPreLoader?.sort(),
              props.preLoaderTrackerDataSources?.sort(),
            ) &&
              !valueExist &&
              !nameExist &&
              nps !== undefined && (
                <div className="nps-item">
                  <p>{CheckValueLocale("nps_widget", "", {}, intl)}</p>
                  <h4>
                    {npsScore !== "0.00" && npsScore !== "0"
                      ? npsScore?.startsWith("-")
                        ? npsScore
                        : "+" + npsScore
                      : 0}
                  </h4>
                </div>
              )}
            <PieChartWidget
              title={"nps_mark"}
              titleToolTip="nps_tooltip"
              data={pieChartSort(nps)}
              toolTipLabel={"toolTipLabel"}
              areaStyle={true}
              showDownloadIcon
              pieChartPreLoaderDataSources={npsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            />
          </div>
        </Grid>
      </Grid>
      <Typography variant="h6" className="survey-title">
        {CheckValueLocale("responces_details", "", {}, intl).toUpperCase()}
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={4}>
          <PieChartWidget
            title={"cities"}
            titleToolTip="cities_survey_tooltip"
            data={cities}
            toolTipLabel={"toolTipLabel"}
            chartType={"cities"}
            pieChartPreLoaderDataSources={citiesPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"countries"}
            titleToolTip="countries_survey_tooltip"
            data={countries}
            toolTipLabel={"toolTipLabel"}
            chartType={"countries"}
            pieChartPreLoaderDataSources={countriesPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"browsers_and_os"}
            titleToolTip="browsers_and_os_survey_tooltip"
            data={browser}
            toolTipLabel={"toolTipLabel"}
            chartType="device"
            color={["#FF4C82", "#B233E2", "#4DC1F4"]}
            pieChartPreLoaderDataSources={browserPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>

      <QuestionsStatistics
        questions={questionsStatistics}
        questionsPreLoader={questionsStatisticsPreLoader}
        individualSurveyPreLoader={individualSurveyPreLoader}
        individualSurvey={individualSurvey}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        id={props.id}
        productId={props.productId}
        setIndividualSurvey={setIndividualSurvey}
        page={page}
        setPage={setPage}
      />
    </>
  );
};
export default SurveyWidgets;
