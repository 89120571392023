import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import "./createApi.scss";
import { Box, Dialog, DialogTitle } from "@mui/material";
import { CheckValueLocale } from "utils/helpers/index";
import CreateForm from "./createForm";
import GeneratedApi from "./generatedApi";
import { LucidyaApisContext } from "../lucidyaApisContext";

const CreateApi = ({ open, apisListData }) => {
  const intl = useIntl();
  const { handleCreateApiDialog, apiDialogType } =
    useContext(LucidyaApisContext);
  const [apikeyResponse, setApikeyResponse] = useState({
    tokenName: "",
    token: "",
  });
  const [generated, setGenerateApi] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={handleCreateApiDialog}>
        <Box className="create-api-wrapper actions-buttons-shared">
          <DialogTitle className="create-api-title">
            {CheckValueLocale(
              apiDialogType === "editApiKey"
                ? "edit_key_information"
                : "generate_api_key",
              "",
              {},
              intl,
            )}
            {apiDialogType !== "editApiKey" ? (
              <span className="create-api-page">
                {CheckValueLocale(
                  "create_api_key_step",
                  "",
                  {
                    step: !generated ? 1 : 2,
                  },

                  intl,
                )}
              </span>
            ) : null}
          </DialogTitle>
          {!generated ? (
            <CreateForm
              setGenerateApi={setGenerateApi}
              setApikeyResponse={setApikeyResponse}
              apisListData={apisListData}
            />
          ) : (
            <GeneratedApi apikeyResponse={apikeyResponse} />
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default CreateApi;
