import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { RoutingGeneralInfo } from "../components/routingGeneralInfo";
import { RoutingConditions } from "../components/routingConditions/routingConditions";

export const EditRoutingDetails = ({
  handleGeneralInfoChange,
  generalInfo,
  routingNameError,
  routingDescriptionError,
  conditions,
  setConditions,
  isAllConditionsValid,
  conditionsOptions,
}) => {
  const intl = useIntl();
  return (
    <Box className="edit-inbound-routing-section">
      <Typography className="edit-inbound-routing-section-title">
        {CheckValueLocale("inbound_routing_details", "", {}, intl)}
      </Typography>
      <Box className="edit-inbound-routing-section-body">
        <RoutingGeneralInfo
          generalInfo={generalInfo}
          handleGeneralInfoChange={handleGeneralInfoChange}
          routingNameError={routingNameError}
          routingDescriptionError={routingDescriptionError}
        />
        <RoutingConditions
          conditions={conditions}
          setConditions={setConditions}
          isAllConditionsValid={isAllConditionsValid}
          conditionsOptions={conditionsOptions}
        />
      </Box>
    </Box>
  );
};
