import AccountBody from "./accountBody";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
import xPlatform from "images/social-icons/x-platform-icon.svg";

/*---------------------------------------------------------*/

const AccountPage = (props) => {
  const twitterIcon = (
    <span className="topics-twitter-icon">
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className={"icon-after-title twitter-icon"}
      />
    </span>
  );
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <AccountBody {...props} TwitterIcon={twitterIcon} />
    </MonitorContainer>
  );
};

export default AccountPage;
