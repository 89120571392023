import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";
import { handlelUserRoles } from "utils/helpers";

export const useCompanyApps = () => {
  const enabled =
    handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH") ||
    handlelUserRoles("GENERAL", "VIEW_USER_AUTH");
  return useQuery({
    queryKey: ["companyApps"],
    queryFn: () => CompaniesController.getCompanyApps(),
    enabled,
  });
};
