import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  InputAdornment,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faPlus,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";

const StepThree = (props) => {
  const { selectedData, setSelectedData } = props;
  const intl = useIntl();
  const [btnStatus, setbtnStatus] = useState(true);
  const [expanded, setExpanded] = useState([0]);

  useEffect(() => {
    if (selectedData?.competitors !== undefined) {
      const emptyName = selectedData?.competitors?.some(
        (competitor) => competitor?.name === "",
      );
      const emptyTwoFields = selectedData?.competitors?.some((competitor) => {
        return (
          competitor?.website_url === "" &&
          competitor?.social_media_handler?.length < 1
        );
      });

      if (emptyName || emptyTwoFields) setbtnStatus(false);
      else setbtnStatus(true);
    }
  }, [selectedData]);

  const handleChangeCompetitor = (value, index, key) => {
    selectedData.competitors[index][key] = value;
    setSelectedData({ ...selectedData });
  };

  const handleAddCompetitor = () => {
    setExpanded([selectedData?.competitors?.length]);
    selectedData?.competitors?.push({
      name: "",
      website_url: "",
      social_media_handler: [],
    });
    setSelectedData({ ...selectedData });
  };

  const handleDeleteCompetitor = (e, index) => {
    e.stopPropagation();
    if (expanded?.includes(index)) {
      let filteredExpanded = expanded?.filter((item) => item !== index);
      setExpanded(filteredExpanded);
    }
    let filteredCompetitors = selectedData?.competitors?.filter(
      (competitor, i) => index !== i,
    );
    selectedData.competitors = filteredCompetitors;
    setSelectedData({ ...selectedData });
  };

  const [focused, setFocused] = useState(null);
  const [chipVals, setChipVals] = useState({});
  const handleAddChip = (e, index) => {
    e.preventDefault();
    if (chipVals[index]?.length !== 0) {
      selectedData?.competitors[index]?.social_media_handler?.push(
        chipVals[index],
      );
      setSelectedData({ ...selectedData });
      setChipVals({ ...chipVals, [index]: "" });
    }
  };

  const handleDeleteChip = (index, chipIndex) => {
    selectedData.competitors[index].social_media_handler =
      selectedData?.competitors[index]?.social_media_handler?.filter(
        (item, i) => i !== chipIndex,
      );
    setSelectedData({ ...selectedData });
  };

  const handleChangeAccordion = (index) => {
    if (expanded?.includes(index)) {
      let filteredExpanded = expanded?.filter((item) => item !== index);
      setExpanded(filteredExpanded);
    } else setExpanded([...expanded, index]);
  };

  return (
    <Box className="luci-steps-container">
      <Box>
        <Typography component="h3">
          {CheckValueLocale("luci_competitors", "", {}, intl)}
        </Typography>
        <Box className="main-step-inputs step3">
          {selectedData?.competitors?.length > 0
            ? selectedData?.competitors?.map((competitor, index) => {
                return (
                  <Accordion
                    className="competitor-accordion"
                    expanded={expanded?.includes(index) ? true : false}
                    onChange={() => handleChangeAccordion(index)}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="competitor-accordion-summary"
                    >
                      <Typography className="competitor-name">
                        {competitor?.name === ""
                          ? CheckValueLocale(
                              "luci_competitor_name_title",
                              "",
                              {},
                              intl,
                            )
                          : competitor?.name}
                      </Typography>
                      <Tooltip
                        arrow
                        placement="bottom"
                        PopperProps={{ style: { zIndex: 9999 } }}
                        title={CheckValueLocale("delete", "", {}, intl)}
                      >
                        <Box component={"span"}>
                          <LucButton
                            type="secondary"
                            variant="flat"
                            minWidth={42}
                            onClick={(e) => handleDeleteCompetitor(e, index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </LucButton>
                        </Box>
                      </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails className="competitor-accordion-details">
                      <Box className="competitor-input-field">
                        <Typography className="input-title">
                          {CheckValueLocale(
                            `luci_competitor_name`,
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                        <TextField
                          id="competitor-name-field"
                          variant="outlined"
                          placeholder={CheckValueLocale(
                            `luci_competitor_name_placeholder`,
                            "",
                            {},
                            intl,
                          )}
                          size="small"
                          inputProps={{ maxLength: 100 }}
                          value={competitor?.name}
                          onChange={(e) =>
                            handleChangeCompetitor(
                              e.target.value,
                              index,
                              "name",
                            )
                          }
                          fullWidth
                        />
                      </Box>
                      <Box className="competitor-input-field">
                        <Typography className="input-title">
                          {CheckValueLocale(`luci_website_url`, "", {}, intl)}
                        </Typography>
                        <TextField
                          id="website-url-field"
                          variant="outlined"
                          placeholder={CheckValueLocale(
                            `luci_website_url_placeholder`,
                            "",
                            {},
                            intl,
                          )}
                          size="small"
                          inputProps={{ maxLength: 100 }}
                          value={competitor?.website_url}
                          onChange={(e) =>
                            handleChangeCompetitor(
                              e.target.value,
                              index,
                              "website_url",
                            )
                          }
                          fullWidth
                        />
                      </Box>
                      <Box className="competitor-input-field">
                        <Typography className="input-title">
                          {CheckValueLocale(
                            `luci_social_media_handler`,
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                        <OutlinedInput
                          variant="outlined"
                          size="small"
                          className="chips-input"
                          placeholder={CheckValueLocale(
                            `luci_social_media_handler_placeholder`,
                            "",
                            {},
                            intl,
                          )}
                          endAdornment={
                            chipVals[index]?.length > 0 &&
                            focused === index && (
                              <InputAdornment
                                onClick={(e) => handleAddChip(e, index)}
                                className="chips-input-end-addorment"
                              >
                                <LucButton
                                  variant="flat"
                                  id={`social_media_handler_input`}
                                  startIcon={
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      size="small"
                                    />
                                  }
                                >
                                  {CheckValueLocale("luci_add", "", {}, intl)}
                                </LucButton>
                              </InputAdornment>
                            )
                          }
                          value={chipVals[index]}
                          onChange={(e) =>
                            setChipVals({
                              ...chipVals,
                              [index]: e.target.value,
                            })
                          }
                          onFocus={() => setFocused(index)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleAddChip(e, index);
                          }}
                          fullWidth
                        />
                        {selectedData?.competitors[index]?.social_media_handler
                          ?.length !== 0 && (
                          <Box className="competitors-chips-container">
                            {selectedData?.competitors[
                              index
                            ]?.social_media_handler?.map((item, itemIndex) => {
                              return (
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  key={itemIndex}
                                  label={item}
                                  onDelete={(e) =>
                                    handleDeleteChip(index, itemIndex)
                                  }
                                />
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : null}
          <Box>
            <LucButton
              variant="flat"
              startIcon={<FontAwesomeIcon icon={faPlus} size="small" />}
              onClick={handleAddCompetitor}
              disabled={!btnStatus}
              fullWidth={selectedData?.competitors?.length >= 1 ? false : true}
            >
              {CheckValueLocale("luci_add_competitor", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StepThree;
