import Notifications from "../api/notifications.js";

var NotificationsController = {};

NotificationsController.getNotifications = (
  notification_type,
  start_date,
  end_date,
  per_page,
  page,
) => {
  const queryData = {
    notification_type,
    start_date,
    end_date,
    per_page,
    page,
  };
  return new Promise(function (resolve) {
    Notifications.getNotifications(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
          totalCount: response.data.pagination.count || 0,
          totalPages: response.data.pagination.pages || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

NotificationsController.getNotificationsCount = () => {
  return new Promise(function (resolve) {
    Notifications.getNotificationsCount()
      .then((response) => {
        resolve({
          count: response.data.data.count || 0,
          oldest_date: response.data.data.oldest_date || null,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
NotificationsController.getNotificationsDownloadLink = (id) => {
  return new Promise(function (resolve) {
    Notifications.getNotificationsDownloadLink(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
NotificationsController.getNotificationsXlsxDownload = (id) => {
  return new Promise(function (resolve) {
    Notifications.getNotificationsXlsxDownload(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default NotificationsController;
