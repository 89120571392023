import { useState, useEffect } from "react";
import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  Typography,
  LinearProgress,
  linearProgressClasses,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { checkProgressExceeded, getPercentage } from "utils/helpers";
import Snackbar from "components/snackBar";
import CompaniesController from "services/controllers/companiesController";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./subscription.scss";
import LucButton from "shared/lucButton/lucButton";
import IconTooltip from "./components/iconTooltip";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const SocialListenting = (props) => {
  const {
    success,
    socialListeningInfo,
    setSuccess,
    sendPackageUpgrade,
    isLoadingUpgrade,
    newsAndBlogsInfo,
    smRef,
  } = props;

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [socialListeningRequest, setSocialListeningRequest] = useState(null);
  const [socialListeningSuccess, setSocialListeningSuccess] = useState(null);
  const [upgradePackageRequest, setUpgradePackageRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const requestSocialListDemo = () => {
    let queryData = {
      service_name: "request_social_listening_demo",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSocialListeningRequest(true);
        setSuccessMsg("request_demo");
        setSocialListeningSuccess(true);
      } else {
        setSocialListeningRequest(false);
        setErrorMsg("request_demo_failed");
        setSocialListeningSuccess(false);
      }
      setIsLoading(false);
    });
  };

  return (
    <div id="social-listening" ref={smRef}>
      <h3 className="sub__head">
        {CheckValueLocale("social_listening", "", {}, intl)}
      </h3>
      <Card className="body">
        {props.smActive ? (
          <>
            <Card justifyContent="center">
              <Typography>
                {CheckValueLocale("company_info", "", {}, intl)}
              </Typography>
              <Card className="percentage">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={10}
                  p={2}
                >
                  <div>
                    <Typography className="head-social-list">
                      {CheckValueLocale("package_name", "", {}, intl)}
                    </Typography>
                    <Typography>
                      {socialListeningInfo.packageInfo.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="head-social-list">
                      {CheckValueLocale("subscribe_start", "", {}, intl)}
                    </Typography>
                    <Typography>
                      {
                        String(
                          socialListeningInfo.packageInfo.subscribe_start,
                        ).split(" ")[0]
                      }
                    </Typography>
                  </div>
                  <div>
                    <Typography className="head-social-list">
                      {CheckValueLocale("subscribe_end", "", {}, intl)}
                    </Typography>
                    <Typography>
                      {
                        String(
                          socialListeningInfo.packageInfo.subscribe_end,
                        ).split(" ")[0]
                      }
                    </Typography>
                  </div>
                </Stack>
              </Card>
            </Card>
            <Card justifyContent="center">
              <Typography>
                {CheckValueLocale("package_stats", "", {}, intl)}
              </Typography>
              <Card className="percentage">
                <CardContent>
                  <Grid container lg={12} md={12} sm={12} spacing={5}>
                    <Grid item lg={12} md={12} sm={12}>
                      <Typography className="head-social-list">
                        {CheckValueLocale("monitors_used", "", {}, intl)}
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <div>
                          {`${commasAfterDigit(
                            socialListeningInfo?.packageInfo
                              ?.package_used_monitors,
                          )}${" "}${CheckValueLocale("of", "", {}, intl)} ${" "}
                      ${commasAfterDigit(
                        socialListeningInfo?.packageInfo?.max_monitors,
                      )}`}
                        </div>
                        <div>
                          {`${getPercentage(
                            socialListeningInfo?.packageInfo
                              ?.package_used_monitors,
                            socialListeningInfo?.packageInfo?.max_monitors,
                          )}%`}
                        </div>
                      </Stack>
                      <BorderLinearProgress
                        variant="determinate"
                        className={
                          socialListeningInfo?.packageInfo
                            ?.package_used_monitors >=
                          socialListeningInfo?.packageInfo?.max_monitors
                            ? "red-color"
                            : ""
                        }
                        value={
                          checkProgressExceeded(
                            socialListeningInfo?.packageInfo
                              ?.package_used_monitors,
                            socialListeningInfo?.packageInfo?.max_monitors,
                          ) ??
                          getPercentage(
                            socialListeningInfo?.packageInfo
                              ?.package_used_monitors,
                            socialListeningInfo?.packageInfo?.max_monitors,
                          )
                        }
                        id="linear-progress-color"
                      />
                    </Grid>
                    {socialListeningInfo?.packageInfo
                      ?.package_used_dashboards !== undefined && (
                      <Grid item lg={6} md={6} sm={6}>
                        <Typography className="head-social-list">
                          {CheckValueLocale(
                            "aggregate_dashboards",
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <div>
                            {`${commasAfterDigit(
                              socialListeningInfo?.packageInfo
                                ?.package_used_dashboards,
                            )} ${CheckValueLocale("of", "", {}, intl)}
                      ${commasAfterDigit(
                        socialListeningInfo?.packageInfo?.dashboards_limit,
                      )}`}
                          </div>
                          <div>
                            {`${getPercentage(
                              socialListeningInfo?.packageInfo
                                ?.package_used_dashboards,
                              socialListeningInfo?.packageInfo
                                ?.dashboards_limit,
                            )}%`}
                          </div>
                        </Stack>
                        <BorderLinearProgress
                          variant="determinate"
                          className={
                            socialListeningInfo?.packageInfo
                              ?.package_used_dashboards >=
                            socialListeningInfo?.packageInfo?.dashboards_limit
                              ? "red-color"
                              : ""
                          }
                          value={
                            checkProgressExceeded(
                              socialListeningInfo?.packageInfo
                                ?.package_used_dashboards,
                              socialListeningInfo?.packageInfo
                                ?.dashboards_limit,
                            ) ??
                            getPercentage(
                              socialListeningInfo?.packageInfo
                                ?.package_used_dashboards,
                              socialListeningInfo?.packageInfo
                                ?.dashboards_limit,
                            )
                          }
                          id="linear-progress-color"
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Card>
            {socialListeningInfo.packageInfo.manual_topics_enabled && (
              <Card justifyContent="center">
                <Card className="percentage">
                  <CardContent>
                    <Grid container lg={12} md={12} sm={12} spacing={5}>
                      <Grid item lg={12} md={12} sm={12}>
                        <Typography className="head-social-list">
                          {CheckValueLocale("topics_used", "", {}, intl)}
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <div>
                            {commasAfterDigit(
                              socialListeningInfo?.packageInfo
                                ?.package_used_topics,
                            )}{" "}
                            {CheckValueLocale("of", "", {}, intl)}{" "}
                            {commasAfterDigit(
                              socialListeningInfo?.packageInfo?.topics_limit,
                            )}
                          </div>
                          <div>{`${getPercentage(
                            socialListeningInfo?.packageInfo
                              ?.package_used_topics,
                            socialListeningInfo?.packageInfo?.topics_limit,
                          )}%`}</div>
                        </Stack>
                        <BorderLinearProgress
                          variant="determinate"
                          className={
                            socialListeningInfo?.packageInfo
                              ?.package_used_topics >=
                            socialListeningInfo?.packageInfo?.topics_limit
                              ? "red-color"
                              : ""
                          }
                          value={
                            checkProgressExceeded(
                              socialListeningInfo?.packageInfo
                                ?.package_used_topics,
                              socialListeningInfo?.packageInfo?.topics_limit,
                            ) ??
                            getPercentage(
                              socialListeningInfo?.packageInfo
                                ?.package_used_topics,
                              socialListeningInfo?.packageInfo?.topics_limit,
                            )
                          }
                          id="linear-progress-color"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Card>
            )}
            <Card justifyContent="center">
              <Typography>
                {CheckValueLocale("data_usage", "", {}, intl)}
                {socialListeningInfo?.packageInfo?.package_type === "Monthly"
                  ? ` (${CheckValueLocale("monthly", "", {}, intl)})`
                  : ""}
                <IconTooltip
                  titleID="social_listen_tooltip"
                  id="social-listen-tooltip"
                  placement="top"
                />
              </Typography>
              <Card className="percentage">
                <CardContent>
                  <Grid container lg={12} md={12} sm={12} spacing={5}>
                    <Grid item lg={6} md={6} sm={6}>
                      <Typography className="head-social-list">
                        {CheckValueLocale(
                          "social_media_posts_used",
                          "",
                          {},
                          intl,
                        )}
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <div>
                          {commasAfterDigit(
                            socialListeningInfo?.packageInfo?.social_usage,
                          )}{" "}
                          {CheckValueLocale("of", "", {}, intl)}{" "}
                          {commasAfterDigit(
                            socialListeningInfo?.packageInfo?.social_limit,
                          )}
                        </div>
                        <div>{`${getPercentage(
                          socialListeningInfo?.packageInfo?.social_usage,
                          socialListeningInfo?.packageInfo?.social_limit,
                        )}%`}</div>
                      </Stack>
                      <BorderLinearProgress
                        variant="determinate"
                        className={
                          socialListeningInfo?.packageInfo?.social_usage >=
                          socialListeningInfo?.packageInfo?.social_limit
                            ? "red-color"
                            : ""
                        }
                        value={
                          checkProgressExceeded(
                            socialListeningInfo?.packageInfo?.social_usage,
                            socialListeningInfo?.packageInfo?.social_limit,
                          ) ??
                          getPercentage(
                            socialListeningInfo?.packageInfo?.social_usage,
                            socialListeningInfo?.packageInfo?.social_limit,
                          )
                        }
                        id="linear-progress-color"
                      />
                    </Grid>
                    {newsAndBlogsInfo !== undefined &&
                      newsAndBlogsInfo.id !== undefined && (
                        <Grid item lg={6} md={6} sm={6}>
                          <Typography className="head-social-list">
                            {CheckValueLocale(
                              "news_and_blogs_posts_used",
                              "",
                              {},
                              intl,
                            )}
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <div>
                              {commasAfterDigit(
                                newsAndBlogsInfo?.packageInfo?.used_quota,
                              )}{" "}
                              {CheckValueLocale("of", "", {}, intl)}{" "}
                              {commasAfterDigit(
                                newsAndBlogsInfo?.packageInfo?.package_qouta,
                              )}
                            </div>
                            <div>
                              {newsAndBlogsInfo?.packageInfo?.used_quota ===
                                0 &&
                              newsAndBlogsInfo?.packageInfo?.package_qouta === 0
                                ? "100%"
                                : `${getPercentage(
                                    newsAndBlogsInfo?.packageInfo?.used_quota,
                                    newsAndBlogsInfo?.packageInfo
                                      ?.package_qouta,
                                  )}%`}
                            </div>
                          </Stack>
                          <BorderLinearProgress
                            variant="determinate"
                            className={
                              newsAndBlogsInfo?.packageInfo?.used_quota >=
                              newsAndBlogsInfo?.packageInfo?.package_qouta
                                ? "red-color"
                                : ""
                            }
                            value={
                              checkProgressExceeded(
                                newsAndBlogsInfo?.packageInfo?.used_quota,
                                newsAndBlogsInfo?.packageInfo?.package_qouta,
                              ) ??
                              getPercentage(
                                newsAndBlogsInfo?.packageInfo?.used_quota,
                                newsAndBlogsInfo?.packageInfo?.package_qouta,
                              )
                            }
                            id="linear-progress-color"
                          />
                        </Grid>
                      )}
                    {/* <Grid item lg={6} md={6} sm={6}>
                      <Typography className="head-social-list">
                        {CheckValueLocale("twitter_historical_posts","", {},intl)}
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <div>
                          {
                            socialListeningInfo.subscriptionInfo
                              .twitter_gnip_count
                          }
                          {CheckValueLocale("of","", {},intl)} 100
                        </div>
                        <div>
                          {`${getPercentage(
                            socialListeningInfo.subscriptionInfo
                              .twitter_gnip_count,
                            100
                          )}%`}
                        </div>
                      </Stack>
                      <BorderLinearProgress variant="determinate" value={50} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6}>
                      <Typography className="head-social-list">
                        {CheckValueLocale("search_credits","", {},intl)}
                      </Typography>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <div>50 {CheckValueLocale("of","", {},intl)} 100</div>
                        <div>50.0%</div>
                      </Stack>
                      <BorderLinearProgress variant="determinate" value={50} />
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
            </Card>
            <Stack direction="row" justifyContent="flex-end">
              {
                <LucButton
                  onClick={() => {
                    sendPackageUpgrade();
                    setUpgradePackageRequest(true);
                  }}
                  id="settings-subscription-social-listening-package-btn"
                  loading={isLoadingUpgrade}
                >
                  {CheckValueLocale("request_upgrade", "", {}, intl)}
                </LucButton>
              }
            </Stack>
          </>
        ) : (
          <>
            <Typography>
              {CheckValueLocale("social_listening_msg", "", {}, intl)}
            </Typography>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  requestSocialListDemo();
                }}
                id="settings-subscription-social-listening-package-btn"
              >
                {isLoading ? (
                  <span className="sending-request-btn-text">
                    <CircularProgress className="circular-progress-subscription circular-progress" />
                    {CheckValueLocale("sending_request", "", {}, intl)}
                  </span>
                ) : (
                  CheckValueLocale("request_demo", "", {}, intl)
                )}
              </Button>
            </Stack>
          </>
        )}
      </Card>

      {success != null ? (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span>
                  <br />
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_success", "", {}, intl)
                    : CheckValueLocale(successMsg, "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_failed", "", {}, intl)
                    : CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}

      {socialListeningSuccess != null ? (
        <Snackbar
          open={socialListeningSuccess != null ? true : false}
          handleClose={() => {
            setSocialListeningSuccess(null);
          }}
          severity={socialListeningSuccess ? "success" : "error"}
          message={
            socialListeningSuccess ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale(successMsg, "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default SocialListenting;
