import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  classNames,
  sanitizeEngagementChatMessage,
} from "utils/helpers";
import "./conversation.scss";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MediaPreviewer } from "pages/engagements/components/mediaPreviewer/mediaPreviewer";

const repliedTo = "interactions_replied_to";
const mentionedYou = "interactions_mentioned_you";

const StoryReplayPreview = ({ data, isEngagement }) => {
  const intl = useIntl();
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [media, setMedia] = useState([]);
  // We don't know whether the story is an image or a video, it is just a link that returned from Instagram API.
  // but we for sure know that the story can be an image or a video, so we will first try to render the image.
  // if the image is not available, we will try to render the video
  // if the video is not available, we will show a placeholder.
  // 0: story is available
  // -1: story is not an image but maybe a video
  // 1: story is not an image nor a video
  const [storyCode, setStoryCode] = useState(0);
  const [story, setStory] = useState();
  // will hold the img link to either reply_to.link or mention.link
  const [storyImageOrVideoLink, setStoryImageOrVideoLink] = useState();
  const [currentStoryMessage, setCurrentStoryMessage] = useState();
  const handleImageError = () => {
    setStoryCode(-1);
  };
  const handleVideoError = () => {
    setStoryCode(1);
  };
  const handleMediaClick = () => {
    if (!isEngagement) return;
    if (storyImageOrVideoLink && storyCode <= 0) {
      setShowMediaPreviewer(true);
      setMedia([
        {
          type: storyCode == -1 ? "video" : "image",
          url: storyImageOrVideoLink,
        },
      ]);
    }
  };
  const renderProperStory = () => {
    switch (storyCode) {
      case 0:
        return (
          <img
            src={storyImageOrVideoLink}
            alt=""
            className={classNames(
              "story-preview-image",
              isEngagement ? "engagement-chat-meida-image" : "",
            )}
            onError={handleImageError}
            onClick={() => handleMediaClick()}
          />
        );
      case -1:
        const VideoComp = (
          <video
            src={storyImageOrVideoLink}
            className={classNames(
              "story-preview-video",
              isEngagement ? "engagement-chat-meida-video" : "",
            )}
            controls={isEngagement ? false : true}
            onError={handleVideoError}
          />
        );
        return isEngagement ? (
          <Box
            className="engagement-chat-message-media-video-container"
            onClick={() => handleMediaClick()}
          >
            {VideoComp}
            <Box className="play-button-container">
              <Box className="play-button">
                <FontAwesomeIcon icon={faPlay} />
              </Box>
            </Box>
          </Box>
        ) : (
          VideoComp
        );
      case 1:
        return (
          <Box className="story-preview-placeholder">
            {CheckValueLocale("interaction_not_available", "", {}, intl)}
          </Box>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    try {
      let properStoryMessage = "";
      const storyObject = JSON.parse(data?.content?.story || "{}");
      let link;
      if (storyObject?.reply_to?.link?.length > 0) {
        link = storyObject?.reply_to?.link;
      } else if (storyObject?.mention?.link?.length > 0) {
        link = storyObject?.mention?.link;
      }
      // setting the proper story message
      if (
        storyObject?.reply_to !== undefined &&
        storyObject?.reply_to !== null
      ) {
        properStoryMessage = repliedTo;
      } else if (
        storyObject?.mention !== undefined &&
        storyObject?.mention !== null
      ) {
        properStoryMessage = mentionedYou;
      }
      setStory(storyObject);
      setStoryImageOrVideoLink(link);
      setCurrentStoryMessage(properStoryMessage);
    } catch (e) {
      // error when parsing the story object
    }
  }, [data?.content?.story]);

  return (
    <>
      {showMediaPreviewer && isEngagement ? (
        <MediaPreviewer
          showMediaPreviewer={showMediaPreviewer}
          setShowMediaPreviewer={setShowMediaPreviewer}
          media={media}
        />
      ) : null}
      <Box
        component="p"
        className={`data-content-text story-wrapper ${
          data?.direction === "inbound" ? "story-inbound" : "story-outbound"
        }`}
      >
        <Box className="replied-text">
          <Box className="replied-to">
            {currentStoryMessage
              ? CheckValueLocale(
                  currentStoryMessage,
                  "",
                  {
                    company_name: data?.username,
                  },
                  intl,
                )
              : null}
          </Box>
          {storyImageOrVideoLink ? (
            <Box className="story-preview">{renderProperStory()}</Box>
          ) : null}
        </Box>
        {story?.reply_to?.link === "" || story?.mention?.link === "" ? (
          <Box className="link-not-available">
            {CheckValueLocale("interaction_not_available", "", {}, intl)}
          </Box>
        ) : null}
        <Box
          className="dm-text-style"
          dangerouslySetInnerHTML={{
            __html: sanitizeEngagementChatMessage(data?.content?.text),
          }}
        />
      </Box>
    </>
  );
};

export default StoryReplayPreview;
