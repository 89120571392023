import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularLoading from "components/circularProgress";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import ShortTextQuestion from "../components/Questions/ShortTextQuestion";
import MultipleChoiceQuestion from "../components/Questions/MCQ";
import YesNoQuestion from "../components/Questions/YesNoQuestion";
import NumberQuestion from "../components/Questions/NumberQuestion";
import PhoneNoQuestion from "../components/Questions/PNQuestion";
import EmailQuestion from "../components/Questions/EmailQuestion";
import NameQuestion from "../components/Questions/NameQuestion";
import DropdownQuestion from "../components/Questions/DropdownQuestion";
import SocialMediaQuestion from "../components/Questions/SocialMediaQuestion";
import NpsQuestion from "../components/Questions/NpsQuestion";
import LinearScaleQuestion from "../components/Questions/LinearScaleQuestion";
import styles from "../components/commonStyle.module.css";

const PreviewPage = (props) => {
  const { surveyData, filteredSections, setPreview, logoData, showPoweredBy } =
    props;
  const intl = useIntl();

  const [sectionsStack, setSectionsStack] = useState([0]);
  const [sectionIndex, setSectionIndex] = useState(null);
  const [afterSection, setAfterSection] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [btnVal, setBtnVal] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const handleBackToBuilder = () => setPreview(false);

  useEffect(() => setSectionIndex(0), []);

  useEffect(() => {
    if (filteredSections?.length < 2) {
      setNextSection(null);
      setAfterSection(-1);
      setBtnVal(-1);
      return;
    } else {
      setNextSection(null);
      setAfterSection(filteredSections[sectionIndex]?.next_section);
      setBtnVal(filteredSections[sectionIndex]?.next_section);
    }
  }, [sectionIndex]);

  useEffect(() => {
    if (nextSection !== null) return setBtnVal(nextSection);
    else if (afterSection !== null) return setBtnVal(afterSection);
    else setBtnVal(-1);
  }, [afterSection, nextSection]);

  useEffect(() => {
    if (btnVal === -1 || btnVal === null) setProgressValue(100);
    else {
      let val = (sectionIndex / (filteredSections?.length - 1)) * 100;
      setProgressValue(val);
    }
  }, [btnVal]);

  const handleProceedAnswer = (nextSection) => {
    if (nextSection === null) return setNextSection(null);
    else setNextSection(Number(nextSection));
  };

  const handleSubmit = (e) => setSubmitted(true);
  const handleClickNext = () => {
    if (btnVal !== null) {
      setSectionIndex(btnVal - 1);
      let stackArr = [...sectionsStack];
      stackArr?.push(btnVal - 1);
      setSectionsStack(stackArr);
    }
  };
  const handleClickBack = (e) => {
    setSectionIndex(sectionsStack?.slice(-2)[0]);
    let newStack = [...sectionsStack];
    newStack?.pop();
    setSectionsStack(newStack);
  };

  let directionSection = filteredSections?.[sectionIndex]?.text_direction;
  let surveyDirection = surveyData?.text_direction;

  let sectionSurveyDirection =
    filteredSections?.length <= 1
      ? surveyDirection
      : directionSection
        ? directionSection
        : surveyDirection
          ? surveyDirection
          : intl?.locale == "en"
            ? "ltr"
            : "rtl";

  return (
    <Box>
      <Box className={styles.preview}>
        <Box component="span" className={styles.arrowIcon}>
          <LucButton
            variant="flat"
            type="secondary"
            minWidth={36}
            onClick={handleBackToBuilder}
          >
            <ArrowBackIcon />
          </LucButton>
        </Box>
        <Box component="span">
          {CheckValueLocale("live_preview", "", {}, intl)}
        </Box>
      </Box>
      <Box className={styles.mainStyle}>
        {!filteredSections ? (
          <CircularLoading />
        ) : (
          <Box
            className={`all-section-direction-${
              surveyDirection
                ? surveyDirection
                : intl?.locale == "en"
                  ? "ltr"
                  : "rtl"
            }`}
          >
            <Header
              surveyData={surveyData}
              filteredSections={filteredSections}
              sectionName={filteredSections[sectionIndex]?.name}
              submitted={submitted}
              logoData={logoData}
              sectionsDirection={filteredSections[sectionIndex]?.text_direction}
            />
            {submitted ? (
              <Box className={styles.submitBox}>
                <Typography>
                  {CheckValueLocale("submission_complete", "", {}, intl)}
                </Typography>
                <LucButton
                  className={"back-to-survey-btn"}
                  variant="flat"
                  minWidth={126}
                  onClick={handleBackToBuilder}
                >
                  {CheckValueLocale("back_to_survey", "", {}, intl)}
                </LucButton>
              </Box>
            ) : (
              <Box>
                <Box
                  className={`section-direction-${sectionSurveyDirection} ${styles.sectionPreview}`}
                >
                  {filteredSections[sectionIndex]?.questions
                    ?.filter((ques) => ques?.is_deleted !== true)
                    ?.map((question, index) => {
                      if (question?.question?.type === "short_text")
                        return (
                          <ShortTextQuestion
                            question={question}
                            questionNo={index + 1}
                            key={index}
                          />
                        );
                      else if (question?.question?.type === "multiple_choice")
                        return (
                          <MultipleChoiceQuestion
                            question={question}
                            questionNo={index + 1}
                            handleProceedAnswer={handleProceedAnswer}
                          />
                        );
                      else if (question?.question?.type === "yes_no")
                        return (
                          <YesNoQuestion
                            question={question}
                            questionNo={index + 1}
                            handleProceedAnswer={handleProceedAnswer}
                          />
                        );
                      else if (question?.question?.type === "number")
                        return (
                          <NumberQuestion
                            question={question}
                            questionNo={index + 1}
                          />
                        );
                      else if (question?.question?.type === "phone_number")
                        return (
                          <PhoneNoQuestion
                            question={question}
                            questionNo={index + 1}
                          />
                        );
                      else if (question?.question?.type === "email")
                        return (
                          <EmailQuestion
                            question={question}
                            questionNo={index + 1}
                          />
                        );
                      else if (question?.question?.type === "name")
                        return (
                          <NameQuestion
                            question={question}
                            questionNo={index + 1}
                          />
                        );
                      else if (question?.question?.type === "dropdown")
                        return (
                          <DropdownQuestion
                            question={question}
                            questionNo={index + 1}
                            handleProceedAnswer={handleProceedAnswer}
                          />
                        );
                      else if (question?.question?.type === "nps")
                        return (
                          <NpsQuestion
                            question={question}
                            questionNo={index + 1}
                          />
                        );
                      else if (question?.question?.type === "scale")
                        return (
                          <LinearScaleQuestion
                            question={question}
                            questionNo={index + 1}
                          />
                        );
                      else if (
                        question?.question?.constraints?.platforms?.length !==
                          0 &&
                        question?.question?.constraints?.platforms !== undefined
                      )
                        return (
                          <SocialMediaQuestion
                            question={question}
                            questionNo={index + 1}
                          />
                        );
                    })}
                </Box>
              </Box>
            )}
            {!submitted ? (
              <Footer
                filteredSections={filteredSections}
                sectionsStack={sectionsStack}
                submitted={submitted}
                progressValue={progressValue}
                btnVal={btnVal}
                handleClickNext={handleClickNext}
                handleClickBack={handleClickBack}
                handleSubmit={handleSubmit}
                showPoweredBy={showPoweredBy}
                directionFooter={surveyData?.text_direction}
              />
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PreviewPage;
