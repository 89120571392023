import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, styled, Divider, Box } from "@mui/material";
import { getTimeZone, isEmptyObj, CheckValueLocale } from "utils/helpers";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useEditSentiment } from "pages/channelAnalytics/InsightsPage/hooks/useEditSentiment";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faEye,
  faComments,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import Luci from "pages/luci";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import avater from "images/shared-images/tiktokAvatar.svg";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";

const SingleTiktokInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const type = urlParams.type;

  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;

  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  const [openProfile, setOpenProfile] = useState(false);
  let apiVars = "social_media/tiktok/public";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true" ? `/tikTok-channel/${dataSourceId}` : ""
      }`,
    },
  ];
  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
      null,
      type,
    ).then((result) => {
      if (
        result !== undefined &&
        !isEmptyObj(result.data) &&
        !result.errorMsg
      ) {
        var regex = /id=(\d+)/;

        // Use the exec method to extract the video ID
        var match = regex.exec(result?.data?.video_url_embed);

        // Check if there is a match and extract the video ID
        if (match && match[1]) {
          var videoId = match[1];
          result.data.videoId = videoId;
        }
        setConversation(result?.data);
      }
    });
  }, []);

  const commentItem = (name, text, date, likes, isMain, isReplied, isfirst) => {
    return (
      <Box
        className={
          isMain
            ? "main-tiktok-tree blue"
            : isReplied
              ? "main-tiktok-tree reply"
              : ""
        }
      >
        <Box className={isfirst ? "border-top" : "border-start"}></Box>
        <Box className="tiktok-avater">
          <img src={avater} />
        </Box>
        <Box className="tiktok-comment-dets">
          <Box>
            <Box className="tiktok-comment-name">{name}</Box>
            <Box className="tiktok-comment-date">
              {moment.unix(date).utc().format(" DD MMM YYYY ")}
              {" | "}
              {moment.unix(date).utc().format("h:mm a ")}
              {getTimeZone() > 0
                ? `(${CheckValueLocale("gmt", "", {}, intl)} +${getTimeZone()})`
                : `(${CheckValueLocale(
                    "gmt",
                    "",
                    {},
                    intl,
                  )} -${getTimeZone()})`}
            </Box>
          </Box>
          <Box>{text}</Box>
          <Box className="tiktok-comment-likes">
            <FontAwesomeIcon icon={faHeart} />
            {likes > 1000
              ? `${Number.parseFloat(likes / 1000).toFixed(1)}K`
              : likes}
          </Box>
        </Box>
      </Box>
    );
  };
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: themesOptions } = useThemesOptions({
    locale: intl?.locale,
  });

  const { mutate: editSentiment, isPending: isSentimentPending } =
    useEditSentiment();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();
  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();

  const preQueryData = {
    id: interactionId,
    tracker_id: conversation?.tracker_id,
    product_id: window.localStorage.cxm_id,
    data_source_id: conversation?.channel_id,
    created_at: conversation?.created_at,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: conversation?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, themes: updatedCategories })),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: conversation?.sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sub_themes: updatedThemes })),
      },
    );
  };

  const handleEditSentiment = (updatedSentiment) => {
    editSentiment(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedSentiment,
          old_label: conversation?.sentiment,
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sentiment: updatedSentiment })),
      },
    );
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: conversation?.created_by,
            user_name: conversation?.created_by,
            profile_image: "",
          }}
          dataSourceName={"TIKTOK"}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyObj(conversation) ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="tik_tok" />
            <div className="single-intr">
              <Box className="single-intr-wrapper">
                <Box display="flex">
                  <span className="single-intr__icon interaction-list__icon--tiktok">
                    <FontAwesomeIcon icon={faTiktok} />
                  </span>
                  <Box className="single-intr__title tiktok-title">
                    {CheckValueLocale("tik_tok", "", {}, intl)}
                    <Box className="single-intr-type">
                      <Box>
                        {CheckValueLocale(
                          conversation?.type == "comment"
                            ? "type_comment"
                            : "type_reply",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <SentimentCategoriesThemes
                    themesData={{
                      btnId: "tiktok-single-interaction-themes-btn",
                      options: themesOptions,
                      selected: conversation?.sub_themes,
                      handleChange: handleEditTheme,
                      loading: isThemesPending,
                    }}
                    categoriesData={{
                      btnId: "tiktok-single-interaction-categories-btn",
                      options: categoriesOptions,
                      selected: conversation?.themes,
                      handleChange: handleEditCategories,
                      loading: isCategoriesPending,
                    }}
                    sentimentData={{
                      btnId: "tiktok-single-interaction-sentiment-btn",
                      options: sentimentsOptions,
                      selected: conversation?.sentiment,
                      handleChange: handleEditSentiment,
                      loading: isSentimentPending,
                    }}
                    dividers={[1, 2]}
                  />
                </Box>
              </Box>
              <Box className="tiktok-single-inter-body">
                <Box
                  className="tiktok-inter-name interaction-user-profile hover-show"
                  onClick={handleSideBar}
                >
                  <img src={avater} />
                  <Box>{conversation?.created_by}</Box>
                </Box>
                <Box className="tiktok-single-video-body">
                  <iframe
                    id={`tiktok-iframe-${conversation?.id}`}
                    height="750px"
                    width="325px"
                    frameBorder="0"
                    src={`https://www.tiktok.com/embed/v2/${conversation?.videoId}`}
                    sandbox
                  ></iframe>
                  <Box className="tiktok-video-describtion">
                    <Box>
                      <Box className="tiktok-video-craetor">
                        {conversation?.video_creator}
                        <Box className="tiktok-video-link">
                          <a
                            href={conversation?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faExternalLink} />
                          </a>
                        </Box>
                      </Box>
                      {conversation?.video_description ? (
                        <Box className="tiktok-main-desc">
                          {CheckValueLocale("desc", "", {}, intl)}
                          <Box>{conversation?.video_description}</Box>
                        </Box>
                      ) : null}
                    </Box>
                    <Box className="tiktok-video-reactions">
                      <Box className="tiktok-reactions-det">
                        <Box>
                          <FontAwesomeIcon icon={faHeart} />
                          {conversation?.video_likes > 1000
                            ? `${Number.parseFloat(
                                conversation?.video_likes / 1000,
                              ).toFixed(1)}K`
                            : conversation?.video_likes}
                        </Box>
                        <Box>
                          <FontAwesomeIcon icon={faComments} />
                          {conversation?.video_comments > 1000
                            ? `${Number.parseFloat(
                                conversation?.video_comments / 1000,
                              ).toFixed(1)}K`
                            : conversation?.video_comments}
                        </Box>
                        <Box>
                          <FontAwesomeIcon icon={faEye} />
                          {conversation?.video_views > 1000
                            ? `${Number.parseFloat(
                                conversation?.video_views / 1000,
                              ).toFixed(1)}K`
                            : conversation?.video_views}
                        </Box>
                        <Box>
                          <FontAwesomeIcon icon={faShare} />
                          {conversation?.video_shares > 1000
                            ? `${Number.parseFloat(
                                conversation?.video_shares / 1000,
                              ).toFixed(1)}K`
                            : conversation?.video_shares}
                        </Box>
                      </Box>
                      <Box dir="ltr">
                        {moment
                          .unix(conversation?.video_created_at)
                          .utc()
                          .format("h:mm a ")}
                        {getTimeZone() > 0
                          ? `(${CheckValueLocale(
                              "gmt",
                              "",
                              {},
                              intl,
                            )} +${getTimeZone()}) `
                          : `(${CheckValueLocale(
                              "gmt",
                              "",
                              {},
                              intl,
                            )} -${getTimeZone()}) `}
                        {moment
                          .unix(conversation?.video_created_at)
                          .utc()
                          .format(" YYYY/MM/DD ")}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {type == "reply" || type == "comment" ? (
                  <Box className="tiktok-single-comments">
                    {type == "reply" ? (
                      <Box className="main-tree">
                        {commentItem(
                          conversation?.parent_created_by,
                          conversation?.parent_text,
                          conversation?.parent_created_at,
                          conversation?.parent_like_count,
                          false,
                          true,
                          true,
                        )}
                        <Box className="tiktok-main-reply">
                          {commentItem(
                            conversation?.created_by,
                            conversation?.text,
                            conversation?.created_at,
                            conversation?.like_count,
                            true,
                            false,
                            true,
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Box className="main-tree-one-comment">
                        {commentItem(
                          conversation?.created_by,
                          conversation?.text,
                          conversation?.created_at,
                          conversation?.like_count,
                          true,
                          false,
                          true,
                        )}
                      </Box>
                    )}
                  </Box>
                ) : null}
              </Box>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleTiktokInteraction;
const DividerVer = styled(Divider)`
  color: #cbd5e1;
  width: 1px;
  height: 20px;
  margin: 0px 10px;
`;
