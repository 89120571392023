import { useIntl } from "react-intl";

import { Typography, Box, Divider } from "@mui/material";
import KeywordChip from "../keywordChip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
const TrendingRecentContent = ({
  trendingData,
  setKeywordValue,
  setShowTendingRecent,
  focused,
  recentTitle,
  isKeywordsFields,
  recentData,
  keywordValue,
  inputName,
  isLoading,
  setIsClear,
  setKeywords,
  spamKeywords,
  keywords,
  setSpamKeywords,
  formFieldErrors,
  setFormFieldErrors,
  setDisableCreateExplore,
}) => {
  const intl = useIntl();
  return (
    <ClickAwayListener
      onClickAway={() => !focused && setShowTendingRecent(false)}
    >
      <Box className="keywords-content-recent">
        {isLoading ? (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            className="spinner-icon-explore"
          />
        ) : (
          <>
            {isKeywordsFields && trendingData?.length ? (
              <Box className="trending-topics-container">
                <Typography component={"p"} className="trending-topics-title">
                  {CheckValueLocale("explore_trending_topics", "", {}, intl)}
                </Typography>
                {trendingData?.map((keyword, i) => {
                  return (
                    <KeywordChip
                      index={i}
                      labels={keyword}
                      setKeywordValue={setKeywordValue}
                      setShowTendingRecent={setShowTendingRecent}
                      keywordValue={keywordValue}
                      inputName={inputName}
                      setIsClear={setIsClear}
                      setKeywords={setKeywords}
                      spamKeywords={spamKeywords}
                      keywords={keywords}
                      setSpamKeywords={setSpamKeywords}
                      formFieldErrors={formFieldErrors}
                      setFormFieldErrors={setFormFieldErrors}
                      setDisableCreateExplore={setDisableCreateExplore}
                    />
                  );
                })}
              </Box>
            ) : null}
            {recentData?.length && trendingData?.length ? (
              <Divider
                orientation="horizontal"
                className="horizontal-keywords-divider"
              />
            ) : null}
            {recentData?.length ? (
              <Box className="recent-search-container">
                <Typography component={"p"} className="trending-topics-title">
                  {CheckValueLocale(recentTitle, "", {}, intl)}
                </Typography>
                {recentData?.map((keyword, i) => {
                  return (
                    <KeywordChip
                      index={i}
                      labels={keyword}
                      setKeywordValue={setKeywordValue}
                      setShowTendingRecent={setShowTendingRecent}
                      keywordValue={keywordValue}
                      inputName={inputName}
                      setIsClear={setIsClear}
                      setKeywords={setKeywords}
                      spamKeywords={spamKeywords}
                      keywords={keywords}
                      setSpamKeywords={setSpamKeywords}
                      formFieldErrors={formFieldErrors}
                      setFormFieldErrors={setFormFieldErrors}
                      setDisableCreateExplore={setDisableCreateExplore}
                    />
                  );
                })}
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default TrendingRecentContent;
