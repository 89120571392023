import { styled } from "@mui/material/styles";
import {
  Button,
  ListItem,
  Drawer,
  AccordionSummary,
  ListItemText,
  Accordion,
} from "@mui/material";

export const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    max-width: 50px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    position: relative;
  }
  position: fixed;
  top: 60px;
  overflow: hidden;
  height: 100%;
  width: 100px;
  left: 0 !important;
  right: 0;
  .MuiDivider-root {
    margin-left: 25px;
    margin-right: 25px;
  }
`;
export const ListItemTextBtn = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: 10px;
    color: #64748b;
    font-weight: 500;
    font-style: normal;
    line-height: 10px;
    margin-top: 5px;
    text-align: center;
  }
`;
export const ButtonLink = styled(Button)`
  &:hover {
    background-color: transparent;
  }
`;
export const AccordionSummaryCon = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &:hover {
    background-color: transparent;
  }
  height: 55px;
  svg {
    font-size: 16px;
    color: #64748b;
    line-height: 20px;
  }
`;
export const ListItemWrapper = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
  }
  &:hover {
    background-color: transparent !important;
  }
`;
export const AccordionWrapper = styled(Accordion)`
  .Mui-expanded {
    color: #456eb2;
    .MuiTypography-root {
      color: #456eb2;
    }
  }
  .MuiSvgIcon-root {
    font-size: 18px;
  }
  .MuiAccordionDetails-root {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  color: #64748nb;
`;
