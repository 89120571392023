import { InputAdornment, OutlinedInput } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { handleInputMouseDown } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { useState } from "react";

const LinkWithCopyButton = ({ typeLink, dashboardUrl }) => {
  const [copied, setCopied] = useState(false);
  const copyLinkDashboard = () => {
    navigator.clipboard.writeText(dashboardUrl);
    setCopied(true);
  };
  const intl = useIntl();
  return (
    <OutlinedInput
      variant="outlined"
      className="copy-share-link-field-live-dashboard"
      endAdornment={
        <CustomizedTooltip
          title={CheckValueLocale(
            `${copied ? "link_copied" : "copy_link"}`,
            "",
            {},
            intl,
          )}
          placement={"bottom"}
          className={`copy-share-link-tooltip ${copied ? "link-copied" : ""}`}
          styles={customizedStyledTooltip}
        >
          <InputAdornment onMouseDown={handleInputMouseDown}>
            <LucButton
              className="copy-btn-live-dashboard"
              onClick={copyLinkDashboard}
              variant="outline"
              id={`copy-btn-live-dashboard-${typeLink}`}
              startIcon={<FontAwesomeIcon icon={faLink} />}
            >
              {CheckValueLocale("copy", "", {}, intl)}
            </LucButton>
          </InputAdornment>
        </CustomizedTooltip>
      }
      value={dashboardUrl}
    />
  );
};

export default LinkWithCopyButton;
