import {
  Box,
  Autocomplete,
  Typography,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

const AutoCompleteFields = ({
  id,
  name,
  value,
  handleChange,
  optionsList,
  optionsListObj,
  emptyListMessage,
  customOptionLabel,
  onCustomOptionClick,
  icon,
  isLoading,
  renderOptionContent,
  placeholder,
  helperText,
  error,
  disabled,
  onOpen,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    onOpen && onOpen();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const EmptyListLoading = () => {
    return (
      <Box className="team-and-slas-empty-list-loading">
        <FontAwesomeIcon icon={faSpinner} spin />
      </Box>
    );
  };

  const AddNewListOption = ({ label, onCustomOptionClick, icon }) => {
    return (
      <MenuItem
        id="routing-select-ai-agents-autocomplete-item"
        className="routing-select-ai-agents-autocomplete-item"
        onClick={onCustomOptionClick}
      >
        {icon ? <FontAwesomeIcon icon={icon} /> : null}
        <Typography component="span">{label}</Typography>
      </MenuItem>
    );
  };

  const getOptionsListObj = (id) =>
    optionsListObj?.find((item) => item?.id == id);

  return (
    <>
      <FormControl className="ai-agents-auto-complete">
        <Autocomplete
          id={id}
          name={name}
          className="company-dropdown"
          labelId="controlled-company-select-label"
          disableClearable
          autoComplete
          autoHighlight
          autoSelect={false}
          open={open}
          options={optionsList}
          disabled={disabled}
          onClose={handleClose}
          onOpen={handleOpen}
          getOptionLabel={(option) => getOptionsListObj(option)?.name || ""}
          renderOption={(props, option) => {
            const { key, className, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                className={`${className} ai-agents-auto-complete-list`}
                {...optionProps}
              >
                {renderOptionContent
                  ? renderOptionContent(option)
                  : getOptionsListObj(option)?.name}
              </Box>
            );
          }}
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              helperText={helperText}
              error={error}
            />
          )}
          noOptionsText={
            isLoading ? (
              <EmptyListLoading />
            ) : (
              <Box className="ai-agents-empty-list-wrapper">
                <AddNewListOption
                  label={customOptionLabel}
                  onCustomOptionClick={onCustomOptionClick}
                  icon={icon}
                />
                <Typography className="ai-agents-empty-list-message">
                  {emptyListMessage}
                </Typography>
              </Box>
            )
          }
          groupBy={(option) => option?.typeOf}
          renderGroup={(params) => {
            return isLoading ? (
              <EmptyListLoading />
            ) : (
              <>
                <AddNewListOption
                  label={customOptionLabel}
                  onCustomOptionClick={onCustomOptionClick}
                  icon={icon}
                />
                {params?.children}
              </>
            );
          }}
        />
      </FormControl>
    </>
  );
};

export default AutoCompleteFields;
