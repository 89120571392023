import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import Divider from "@mui/material/Divider";
import moment from "moment";

const MediaTik = (props) => {
  const intl = useIntl();

  return (
    <div className="mediaitem-wrapper tiktok">
      <iframe
        id={`tiktok-iframe-${props?.media?.id}`}
        height="500px"
        width="325px"
        frameBorder="0"
        src={`https://www.tiktok.com/embed/v2/${props?.media?.id}`}
        sandbox
      ></iframe>
      <div className="details-tiktok">
        <div className="details-item">
          {props?.media?.view_count}
          <div className="details-label">
            {CheckValueLocale("view_count", "", {}, intl)}
          </div>
        </div>
        <Divider orientation="vertical" variant="middle" flexItem />
        <div className="details-item">
          {moment.unix(props?.media?.created_at).format("DD-MM-YYYY")}
          <div className="details-label">
            {CheckValueLocale("tiktok_created", "", {}, intl)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaTik;
