import React from "react";
import { useIntl } from "react-intl";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import "./style.scss";
import { CheckValueLocale } from "utils/helpers";

// This componant Created as a shared component for displaying Alerts for different pages in the applyFilter
// Component : Alert Message [Default Case: Error Alert]
// props:
// mainFlag, alertMainFlag , setAlertMainFlag, alertSeverity,boxStyle, alertStyle, alertMessageId, alertMessageIdWithLink, alertMessageIdWithLinkText

const AlertMessage = (props) => {
  const intl = useIntl();
  let {
    mainFlag,
    alertMainFlag,
    setAlertMainFlag,
    alertSeverity,
    boxStyle,
    alertStyle,
    alertMessageId,
    alertMessageIdWithLink,
    alertMessageIdWithLinkText,
  } = props;

  const OnCloseReachLimitAlert = () => {
    setAlertMainFlag(false);
  };

  return (
    <div
      style={
        mainFlag && alertMainFlag
          ? boxStyle
            ? boxStyle
            : { marginBottom: "10px" }
          : null
      }
    >
      {mainFlag && alertMainFlag && (
        <Alert
          open={mainFlag}
          severity={alertSeverity ? alertSeverity : "error"}
          onClose={OnCloseReachLimitAlert}
          style={alertStyle ? { alertStyle } : { color: "#763531" }}
        >
          {alertMessageIdWithLink !== "" &&
          alertMessageIdWithLink !== undefined ? (
            <span>
              <span> {CheckValueLocale(alertMessageId, "", {}, intl)} </span>
              {alertMessageIdWithLinkText !== undefined && (
                <span className="alert-message-Id-with-link">
                  <Link to={alertMessageIdWithLink}>
                    {CheckValueLocale(alertMessageIdWithLinkText, "", {}, intl)}
                    .
                  </Link>{" "}
                </span>
              )}
            </span>
          ) : (
            CheckValueLocale(alertMessageId, "", {}, intl)
          )}
        </Alert>
      )}
    </div>
  );
};

export default AlertMessage;
