import { Box } from "@mui/system";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends.js";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./../monitors.scss";
import {
  aggregate,
  output,
} from "../../channelAnalytics/InsightsPage/insightsCommonFunctions";
import _ from "lodash";
import { Grid } from "@mui/material";

const ThemeSection = (props) => {
  const intl = useIntl();
  let {
    response,
    themeTrendPreLoader,
    setThemeTrendPreLoader,
    themeAnalysisPreLoader,
    setThemeAnalysisPreLoader,
    preLoaderTrackerDataSources,
    eventName,
    themeAnalysisTitle,
    themeTrendTitle,
  } = props;

  const [themes, setThemes] = useState([]);

  //handle response
  useEffect(() => {
    if (response?.eventName === eventName) {
      setThemes((oldArray) => [...oldArray, response?.eventData?.themes]);
      setThemeTrendPreLoader([parseInt(response?.monitor_id)]);
      setThemeAnalysisPreLoader([parseInt(response?.monitor_id)]);
    }
  }, [response, eventName]);

  useEffect(() => {
    if (
      _.isEqual(
        themeAnalysisPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) === true
    ) {
      aggregate(themes);
    }
  }, [themeAnalysisPreLoader]);
  // _____________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setThemeTrendPreLoader([]);
      setThemeAnalysisPreLoader([]);
      setThemes([]);
    }
  }, [props.resetAllFields]);

  return (
    <Grid
      container
      spacing={1}
      className={`${props.liveDashboardWidget ? "live-dashboard-widget-container" : ""}`}
    >
      <Grid
        item
        lg={props.liveDashboardWidget ? 6 : 12}
        xs={12}
        className={`${props.liveDashboardWidget ? "dashboard-widget-wrapper-column" : ""}`}
      >
        <Box mt={`${props.liveDashboardWidget ? 0 : "24px"}`}>
          <ThemeAnalysis
            title={
              themeAnalysisTitle ? themeAnalysisTitle : "monitor_theme_title"
            }
            titleToolTip={"themes_tooltip"}
            data={output?.theme_analysis ? output?.theme_analysis : []}
            themeAnalysisPreLoaderDataSources={themeAnalysisPreLoader}
            showAI
            showDownloadIcon={!props?.isLiveDashboardPublic}
            bigImg
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            iconAfterTitle={props.iconAfterTitle}
            liveDashboardWidget={props.liveDashboardWidget}
            widgetSection={props?.widgetSection}
            themesList={props?.themesOptions}
            isSocialListening={props?.isSocialListening}
            isSurvey={props?.isSurvey}
            isLiveDashboard={props?.isLiveDashboard}
          />
        </Box>
      </Grid>
      <Grid
        item
        lg={props.liveDashboardWidget ? 6 : 12}
        xs={12}
        className={`${props.liveDashboardWidget ? "dashboard-widget-wrapper-column" : ""}`}
      >
        <Box mt={`${props.liveDashboardWidget ? 0 : "24px"}`}>
          <ThemesTrends
            title={
              themeTrendTitle ? themeTrendTitle : "monitor_theme_trend_title"
            }
            titleToolTip={"themes_trend_tooltip"}
            data={output?.theme_trend ? output?.theme_trend : []}
            showGroupBy={false}
            showAI
            showDownloadIcon={!props?.isLiveDashboardPublic}
            bigImg
            themeTrendPreLoaderDataSources={themeTrendPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            iconAfterTitle={props.iconAfterTitle}
            liveDashboardWidget={props.liveDashboardWidget}
            widgetSection={props?.widgetSection}
            themesList={props?.themesOptions}
            isSocialListening={props?.isSocialListening}
            isSurvey={props?.isSurvey}
            isLiveDashboard={props?.isLiveDashboard}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ThemeSection;
