import { useEffect, useState } from "react";
import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import PopupModal from "components/popupModal.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./componentes/optionsDropDown.scss";
import SnackBar from "components/snackBar";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import AudienceController from "services/controllers/audienceController";

import { faArrowToBottom, faEye } from "@fortawesome/pro-solid-svg-icons";
import "./componentes/optionsDropDown.scss";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDownImport = (props) => {
  const intl = useIntl();
  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });

  const [success, setSuccess] = useState(null);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [numErors, setNumErors] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const open = Boolean(stats.anchorEl);

  const handleClick = (event) => {
    setStats({
      ...stats,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };
  const num = props?.importItem?.updated_profiles;
  const handleOpenDeleteModal = () => {
    setStats({
      openDeleteModal: true,
      addClasses: "model-buttons",
      title: `${CheckValueLocale(
        "audience_remove_from_audience_list",
        "",
        {},
        intl,
      )}`,
      body: (
        <Box className="shared-dialog-body">
          <p>
            {CheckValueLocale(
              "audience_sure_remove_from_audience",
              "",
              {
                number: num,
              },
              intl,
            )}
          </p>

          <h2 className="sub-text-desc">
            {CheckValueLocale("audience_are_you_sure", "", {}, intl)}
          </h2>
        </Box>
      ),

      modalRightBtn: CheckValueLocale("confirm_remove", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      warning: true,
    });
  };
  const handleOpenViewErrors = () => {
    props?.importCDpViewNum(props?.id, props?.importItem?.file_name);
    handleClose(true);
  };


  const handleDeleteImport = () => {
    setButtonLoading(true);
    AudienceController.importCDPDelete(props?.id).then((data) => {
      if (data?.data?.status === 204 || data?.data?.status === 200) {
        setSuccess(true);
        setSnackBarMsg(
          CheckValueLocale("imports_deleted_success", "", {}, intl),
        );
        setSuccessStatus("success");
        showSnackBar("delete", "success");
        setTimeout(() => {
          props?.getListOfCdpImports();
        }, 3000);
        handleCloseDeleteModal();
      } else {
        setSuccess(true);
        setSuccessStatus("error");
        setSnackBarMsg(CheckValueLocale("wrong_msg", "", {}, intl));
        setSnackBarTitle(CheckValueLocale("failed_error", "", {}, intl));
        showSnackBar("delete", "warning");
        handleCloseDeleteModal();
      }
      setButtonLoading(false);
    });
  };
  const handleDownloadFile = () => {
    props?.importCDPDwonload(props?.id);
  };
  const showSnackBar = (action, severity) => {
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      openDeleteModal: false,
      openPauseResumeModal: false,
      snackBarMsg: "",
    });
    window.scrollTo(0, 0);
    if (action === "delete" && severity === "success") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: intl.formatMessage(
          { id: "remove_pofileuser" },
          {
            name: CheckValueLocale(props?.username, "no_name", {}, intl),
          },
        ),
      });
    } else if (action === "delete" && severity === "warning") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: CheckValueLocale("wrong_msg", "", {}, intl),
        snackBarTitle: CheckValueLocale("fail_req", "", {}, intl),
      });
    }
  };
  let checkStatus = props?.importItem?.status;
  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
    setNumErors({ openDeleteModal: false });
  };
  return (
    <div className="menu-list">
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          onClose={handleClose}
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="cdp-import-options-ddl"
        MenuListProps={{
          "aria-labelledby": "monitor-menu-button",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: intl.locale == "ar" ? "left" : "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl.locale == "ar" ? "left" : "right",
        }}
      >
        {handlelUserRoles("GENERAL", "DESTROY_UTILITIES") ? (
          <MenuItem onClick={handleOpenDeleteModal} disableRipple>
            <FontAwesomeIcon
              icon={faTrash}
              className="icon-drop-import trash-import"
            />
            {CheckValueLocale(
              "audience_remove_from_audience_list",
              "",
              {},
              intl,
            )}
          </MenuItem>
        ) : null}
        {handlelUserRoles("GENERAL", "VIEW_UTILITIES") ? (
          <MenuItem
            disableRipple
            onClick={() => handleDownloadFile(props?.id)}
            disabled={checkStatus == "in_progress" || checkStatus == "failed"}
          >
            <FontAwesomeIcon
              icon={faArrowToBottom}
              className="icon-drop-import arrow-import"
              disabled={checkStatus == "Success"}
            />
            {CheckValueLocale("audience_download_imported_file", "", {}, intl)}
          </MenuItem>
        ) : null}
        {handlelUserRoles("GENERAL", "VIEW_UTILITIES") ? (
          <MenuItem
            onClick={handleOpenViewErrors}
            disabled={checkStatus == "failed" || checkStatus == "in_progress"}
            disableRipple
          >
            <FontAwesomeIcon
              icon={faEye}
              className="icon-drop-import eye-import"
            />
            {CheckValueLocale("audience_view_num_errors", "", {}, intl)}
          </MenuItem>
        ) : null}
      </Menu>
      {stats.openDeleteModal && (
        <PopupModal
          title={stats?.title}
          body={stats?.body}
          leftBtn={stats?.modalLeftBtn}
          rightBtn={stats?.modalRightBtn}
          accept={handleDeleteImport}
          open={stats?.openDeleteModal}
          close={handleCloseDeleteModal}
          warning={stats?.warning}
          classeName="pop-up-delete-errors actions-buttons-shared "
          minWidth="600px"
          isRightBtnLoading={buttonLoading}
          disabled={buttonLoading}
        />
      )}
      <SnackBar
        open={success != null ? true : false}
        title={snackBarTitle}
        severity={successStatus}
        message={snackBarMsg}
        showCloseBtn={true}
        handleClose={onclose}
      />
    </div>
  );
};
export default OptionsDropDownImport;
