import { Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { classNames } from "utils/helpers";

/**
 * TruncatedTooltip is a normal Typography component, the only difference is that by default it truncates the text if it
 * exceeded the available line width, and it will show a tooltip with the full text when the text is truncated, ow it will not show the tooltip.
 * aslo when defining a maxChars, it will truncate the text to the defined number of characters and will show the ellipsis(...) at the end of the text.
 * @param {string} title - The text to be displayed
 * @param {number} maxChars - (optional) The maximum number of characters to be displayed
 * @param {string} className - (optional) The class name of the Typography component
 * @param {string} tooltipClassName - (optional) The class name of the Tooltip component
 * @param {string} placement - (optional) The placement of the tooltip, default is "top", other options are "bottom", "left", "right"
 * @param {boolean} arrow - (optional) Whether to show the arrow of the tooltip, default is true
 * @param {string} component - (optional) The component of the Typography, default is "p"
 * @param {object} props - (optional) The other props of the Typography component
 */
const TruncatedTooltip = ({
  title,
  maxChars,
  className,
  tooltipClassName,
  placement = "top",
  arrow = true,
  component = "p",
  enablePropperProps,
  ...props
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const titleRef = useRef(null);
  const propperProps = useMemo(
    () =>
      enablePropperProps
        ? {
            disablePortal: true,
            popperOptions: {
              positionFixed: true,
              modifiers: {
                preventOverflow: {
                  enabled: true,
                  boundariesElement: "window", // where "window" is the boundary
                },
              },
            },
          }
        : {},
    [enablePropperProps],
  );
  useEffect(() => {
    // check if the text is truncated or not
    if (titleRef?.current) {
      // scrollWidth is the actual width of the text
      // clientWidth is the width of the container
      setIsTruncated(
        titleRef?.current?.scrollWidth > titleRef?.current?.clientWidth,
      );
    }
  }, [title]);
  return (
    <Tooltip
      title={isTruncated ? title : null}
      classes={{
        tooltip: tooltipClassName,
      }}
      arrow={arrow}
      placement={placement}
      PopperProps={propperProps}
    >
      <Typography
        ref={titleRef}
        className={classNames("truncated-title-wrapper", className)}
        component={component}
        sx={{
          maxWidth: maxChars ? `${maxChars}ch` : "auto",
        }}
        {...props}
      >
        {title}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTooltip;
