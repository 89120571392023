import { sortedColumnTypes } from "hooks/useSortableTable";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const manageChannelsInitialColumns = (canShowActionTap) => {
  const intl = useIntl();
  return [
    {
      body: CheckValueLocale("account_identifier", "", {}, intl),
      width: "260px",
      className: "text-transform-letter  align-cell",
      name: "account_identifier",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("channel", "", {}, intl),
      width: "180px",
      className: "text-transform-letter  align-cell",
      name: "channel",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("data_category", "", {}, intl),
      width: "170px",
      className: "text-transform-letter  align-cell",
      name: "data_category",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("added_by", "", {}, intl),
      width: "170px",
      className: "text-transform-letter  align-cell",
      name: "created_by",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("authenticated_by", "", {}, intl),
      width: "170px",
      className: "text-transform-letter  align-cell",
      name: "authenticated_by",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("status", "", {}, intl),
      width: "100px",
      className: "text-transform-letter align-cell",
      name: "status",
      type: sortedColumnTypes.string,
    },
    {
      body: null,
      width: "50px",
      className: "text-transform-letter  align-cell",
      permission: canShowActionTap,
    },
  ];
};
