import {
  ListItem,
  Grid,
  ListItemAvatar,
  ListItemText,
  List,
  Avatar,
  Box,
} from "@mui/material";
import { useState } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ManagedAccountAuthUsers = (props) => {
  const {
    handleCreateMonitorBtn,
    monitorData,
    setMonitorData,
    SourceId,
    activeDataSourceId,
    socialAccounts,
    isClikedAvtiveDatasource,
    setIsClikedAvtiveDatasource,
    setIsClikedDatasource,
  } = props;
  const [selectedAcount, setSelectedAcount] = useState("");

  const handleSelectedAccount = (account) => {
    setIsClikedDatasource(false);
    setSelectedAcount(account);
    //save selected account in monitor Data array
    setMonitorData({ ...monitorData, selectedAccount: account });
    //enable create monitor btn as user selected an account
    handleCreateMonitorBtn(account);
    setIsClikedAvtiveDatasource(true);
  };

  if (
    monitorData?.selectedAccount &&
    activeDataSourceId == SourceId &&
    isClikedAvtiveDatasource
  ) {
    handleCreateMonitorBtn(props.monitorData?.selectedAccount?.id);
  } else {
    handleCreateMonitorBtn("");
  }

  //check account selected or not
  const showSelected = (accountId) => {
    return monitorData?.selectedAccount?.id == accountId?.id;
  };
  const checkSelectAccount = (account) => {
    return (
      (account?.id === selectedAcount?.id || showSelected(account)) &&
      `selected`
    );
  };
  return socialAccounts?.map((account, i) => (
    <Grid item xs={4} key={i} marginTop={1.5}>
      <List
        className={`account-card ${checkSelectAccount(account)}`}
        onClick={() => handleSelectedAccount(account)}
      >
        <ListItem className="account-avatar-managed">
          <ListItemAvatar>
            <Avatar src={account.attributes.image_url} />
          </ListItemAvatar>
          <ListItemText primary={"@" + account.attributes.name} />
          {checkSelectAccount(account) ? (
            <Box className="selectedAcount">
              <FontAwesomeIcon icon={faCheck} />
            </Box>
          ) : null}
        </ListItem>
      </List>
    </Grid>
  ));
};

export default ManagedAccountAuthUsers;
