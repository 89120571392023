import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./activeFilters.scss";
import CloseIcon from "@mui/icons-material/Close";
import { CheckValueLocale } from "utils/helpers";

// ____________________________________________________________________
export const getActiveParams = (assignActiveFilters, filterFields) => {
  let allActiveFilters = [];
  if (assignActiveFilters) {
    Object.entries(assignActiveFilters)?.forEach(([key, val]) => {
      if ((typeof val === "string" && val !== "") || typeof val === "boolean") {
        allActiveFilters?.push({ name: key, value: val });
      } else {
        if (val?.length) {
          if (Array.isArray(val)) {
            val?.map((v) => {
              allActiveFilters?.push({ name: key, value: v });
            });
          } else {
            val.split(",").map((v) => {
              allActiveFilters?.push({ name: key, value: v });
            });
          }
        }
      }
    });
  }

  return allActiveFilters;
};
// ____________________________________________________________________
const ActiveFilters = (props) => {
  const intl = useIntl();
  const {
    assignActiveFilters,
    startDate,
    endDate,
    applyFilter,
    allActiveFilters,
    isAudiencePage,
    showFilterBtn,
    setAssignActiveFilters,
  } = props;

  const [showLess, setShowLess] = useState(false);
  // ____________________________________________________________________
  //show more and less filters
  const seeMoreFilters = () => {
    var element = document.getElementById("show-filter");
    element.classList.toggle("show-active-filters");
    setShowLess(!showLess);
  };
  // ____________________________________________________________________
  //remove single active filters param
  const removeActiveFilter = (name, value) => {
    let assignActiveFiltersClone = { ...assignActiveFilters };
    Object.entries(assignActiveFilters).forEach(([key, val]) => {
      if (name === key) {
        if (
          name === "keywords" ||
          name === "exclude_keywords" ||
          name === "exclude_user" ||
          name === "users"
        ) {
          let updatedVal = val.split(",");
          val = updatedVal?.filter((item) => {
            return item !== value;
          });
          val = val.toString();
        } else if (typeof val === "boolean") {
          val = [val]?.filter((item) => {
            return item !== value;
          });
        } else if (typeof val === "string") {
          val = [val]?.filter((item) => {
            return item !== value;
          });
        } else {
          val = val?.filter((item) => {
            return item !== value;
          });
        }
        assignActiveFiltersClone[key] = val;
        setAssignActiveFilters(assignActiveFiltersClone);
      }
    });
    //After removing the filter param we re apply filter with new filters
    applyFilter(startDate, endDate, assignActiveFiltersClone, undefined, true);
  };
  // ____________________________________________________________________
  //In this part we remove the values that shoud not be appearing to user in active filters like :
  //sortBy/hideSpam||hideRetweet =="false" param from the list of active filters shown to the user
  var arr = [];
  allActiveFilters &&
    allActiveFilters?.map((i) => {
      typeof i.value === "string" &&
        i.value?.split(",").map((ii) => {
          arr.push({ name: i.name, value: ii });
        });
    });
  var activeFiltersAfterHidingSomeParams = arr;
  var withoutHiddenParamsLength = activeFiltersAfterHidingSomeParams;

  // _________________________________
  withoutHiddenParamsLength.map((i) => {
    if (i.name === "sort_by") {
      var sortByIndex = withoutHiddenParamsLength.findIndex((object) => {
        return object.name === "sort_by";
      });
      withoutHiddenParamsLength.splice(sortByIndex, 1);
    }
    // _________________________________

    if (i.name === "hide_spam" && i.value === "False") {
      var hideSpamIndex = withoutHiddenParamsLength.findIndex((object) => {
        return object.name === "hide_spam";
      });
      withoutHiddenParamsLength.splice(hideSpamIndex, 1);
    }
    // _________________________________

    if (i.name === "hide_retweet" && i.value === "False") {
      var hideRetweetIndex = withoutHiddenParamsLength.findIndex((object) => {
        return object.name === "hide_retweet";
      });
      withoutHiddenParamsLength.splice(hideRetweetIndex, 1);
    }
  });
  const showItems = showLess
    ? activeFiltersAfterHidingSomeParams
    : activeFiltersAfterHidingSomeParams.slice(0, 5);
  // ____________________________________________________________________
  return (
    <>
      <>
        {withoutHiddenParamsLength.length > 0 && showFilterBtn && (
          <div className="active-filters-container">
            <div
              className="all-active-filters show-active-filters"
              id="show-filter"
            >
              {showItems.map(({ name, value }) => {
                return (
                  <>
                    {name !== "sort_by" && (
                      <Chip
                        className="active-filter-params"
                        label={` ${CheckValueLocale(name, "", {}, intl)} : ${
                          name === "segment"
                            ? value
                            : CheckValueLocale(
                                name === "location" && isAudiencePage
                                  ? `${value?.toLowerCase()}_location`
                                  : value,
                                "",
                                {},
                                intl,
                              )
                        }`}
                        //
                        onDelete={() => removeActiveFilter(name, value)}
                        deleteIcon={<CloseIcon />}
                        variant="outlined"
                      />
                    )}
                  </>
                );
              })}
            </div>

            {showLess ? (
              <p onClick={seeMoreFilters} className="show-less-filters">
                {CheckValueLocale("show_less", "", {}, intl)}
              </p>
            ) : (
              activeFiltersAfterHidingSomeParams.length > 5 && (
                <Chip
                  label={`${CheckValueLocale("show_all", "", {}, intl)} (+${
                    activeFiltersAfterHidingSomeParams.length - 5
                  })`}
                  variant="outlined"
                  onClick={seeMoreFilters}
                  className="show-hidden-filters"
                />
              )
            )}
          </div>
        )}
      </>
    </>
  );
};
export default ActiveFilters;
