import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useIntl } from "react-intl";
import FacebookIcon from "images/social-icons/facebook-public-icon.png";
import InstagramIcon from "images/social-icons/instagram-public-icon.svg";
import xPlatform from "images/survey-builder-icons/x-platform.png";
import TiktokIcon from "images/survey-builder-icons/tiktok.svg";
import SnapchatIcon from "images/survey-builder-icons/snapchat.svg";
import { CheckValueLocale } from "utils/helpers";
import { Box } from "@mui/material";

const SocialAccountQuestion = (props) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [selectedDataSources, setSelectedDataSources] = useState([]);

  const handleChange = (panel, item) => {
    //create empty array that includes selected datasources to be expanded by user
    //if data source is expanded we check its index remove it from the array and de-expand it
    if (selectedDataSources?.includes(item)) {
      const index = selectedDataSources?.indexOf(item);
      selectedDataSources?.splice(index, 1);
    } else {
      //if data source is de-expanded we push it to the array and expand it
      selectedDataSources?.push(item);
    }
    setExpanded(!expanded ? panel : false);
  };

  useEffect(() => handleQuestions(), [props?.data]);

  const handleQuestions = () => {
    let dataSources = [];
    props?.data?.map((dataSource) => {
      let item;
      switch (Object.keys(dataSource)[0]) {
        case "facebook":
          item = {
            icon: <img src={FacebookIcon} alt="fb" />,
            name: "facebook",
            account: Object.values(dataSource)[0],
          };
          break;
        case "instagram":
          item = {
            icon: <img src={InstagramIcon} alt="ig" />,
            name: "instagram",
            account: Object.values(dataSource)[0],
          };
          break;
        case "twitter":
          item = {
            icon: <img src={xPlatform} alt="x-platform-logo" />,
            name: "twitter",
            account: Object.values(dataSource)[0],
          };
          break;
        case "tiktok":
          item = {
            icon: <img src={TiktokIcon} alt="tiktok" />,
            name: "tiktok",
            account: Object.values(dataSource)[0],
          };
          break;
        case "snapchat":
          item = {
            icon: <img src={SnapchatIcon} alt="snapchat" />,
            name: "snapchat",
            account: Object.values(dataSource)[0],
          };
          break;
        default:
          break;
      }
      dataSources.push(item);
      setDataSources(dataSources);
    });
  };

  return (
    <Box className="question-accordion">
      {dataSources.map((account, i) => {
        return (
          <Accordion
            key={i}
            expanded={
              selectedDataSources.includes(account?.name) ? true : false
            }
            onChange={() => handleChange(`panel${i}`, account?.name)}
            className="question-source"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="account-datasource">
                {account?.icon}
                {CheckValueLocale(account?.name, "", {}, intl)}
              </Typography>
              <Typography className="accounts-number">
                [{account?.account?.length}{" "}
                {CheckValueLocale("accounts", "", {}, intl)}]
              </Typography>
            </AccordionSummary>
            {account?.account?.map((item, i) => {
              return item !== "" ? (
                <AccordionDetails key={i} className="accordion-details-body">
                  <Typography>{item}</Typography>
                </AccordionDetails>
              ) : null;
            })}
          </Accordion>
        );
      })}
    </Box>
  );
};
export default SocialAccountQuestion;
