import ReactEcharts from "echarts-for-react";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import _ from "lodash";
import { CheckValueLocale } from "utils/helpers";

const StackedHorizontalBar = (props) => {
  const [seriesData, setSeriesData] = useState([]);
  const [topicsTotalRef, setTopicsTotalRef] = useState({});
  const [largestValue, setLargestValue] = useState({});
  const intl = useIntl();

  const define_colors = (name, index) => {
    if (props.isSentimentAnalysis) {
      return props?.all_sentiment_values[name];
    } else {
      return props.all_topics_colors[index];
    }
  };

  const formatToolTip = (data) => {
    // Reverse the equation of displaying data
    let real_data;
    if (data.value === 0) {
      real_data = 0;
    } else {
      real_data =
        data.value -
        (largestValue.total_value - topicsTotalRef[data.name]["total_value"]) /
          topicsTotalRef[data.name]["total_non_zeros"];
    }

    let format =
      props.legendPosition !== "right"
        ? data.data === 0
          ? data.marker +
            `<span style=\"font-weight: bold;\">${data.seriesName}</span> (${data.name}): 0% (${Math.round(real_data)})`
          : data.marker +
            `<span style=\"font-weight: bold;\">${data.seriesName}</span> (${
              data.name
            }): ${(
              (real_data / topicsTotalRef[data.name]["total_value"]) *
              100
            ).toFixed(2)}% (${Math.round(real_data)})`
        : data.data === 0
          ? `(${Math.round(real_data)}) 0% :(${data.name}) <span style=\"font-weight: bold;\">${data.seriesName}</span>` +
            data.marker
          : `(${Math.round(real_data)}) ${(
              (real_data / topicsTotalRef[data.name]["total_value"]) *
              100
            ).toFixed(2)}% :(${data.name}) <span style=\"font-weight: bold;\">${
              data.seriesName
            }</span>` + data.marker;
    return format;
  };

  useEffect(() => {
    if (
      props?.data?.series_data &&
      typeof props?.data?.series_data !== undefined &&
      props?.data?.series_data?.length > 0
    ) {
      // create a reference object for all values with their total values & Non-zero values
      props?.data.y_axis_data.map((elm) => {
        topicsTotalRef[elm] = {
          total_value: 0,
          total_non_zeros: 0,
        };
      });

      // Prepare data
      props?.data?.series_data.map((element, index) => {
        // increase the data of Actual object to have a reference value
        element["data"].map((val, idx) => {
          let x = val;
          topicsTotalRef[props?.data.y_axis_data[idx]]["total_value"] += x;
          // Insert non zeros values
          if (val !== 0) {
            topicsTotalRef[props?.data.y_axis_data[idx]]["total_non_zeros"] +=
              1;
          }
        });
      });

      // Define which one is Larger
      // Loop over all data to modify its values according to larger
      var largest_value = Object.keys(topicsTotalRef).reduce(
        (acc, curr) =>
          acc.total_value
            ? topicsTotalRef[curr].total_value > acc.total_value
              ? topicsTotalRef[curr]
              : acc
            : topicsTotalRef[curr],
        {},
      );
      setLargestValue(largest_value);
      // Modify data of series
      var new_data_array = _.cloneDeep(props?.data?.series_data);
      new_data_array.map((element, index) => {
        element["data"].forEach((val, idx, array) => {
          if (
            topicsTotalRef[props?.data.y_axis_data[idx]]["total_non_zeros"] !==
              0 &&
            val !== 0
          ) {
            array[idx] =
              val +
              (largest_value.total_value -
                topicsTotalRef[props?.data.y_axis_data[idx]]["total_value"]) /
                topicsTotalRef[props?.data.y_axis_data[idx]]["total_non_zeros"];
          } else {
            array[idx] = val + 0;
          }
        });
      });

      let series_objects = [];
      new_data_array.map((element, index) => {
        series_objects.push({
          name: props.isSentimentAnalysis
            ? CheckValueLocale(element["name"]?.toLowerCase(), "", {}, intl)
            : CheckValueLocale(element["name"], "", {}, intl),
          type: "bar",
          stack: "total",
          label: {
            show: false,
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            borderColor: "#ffffff",
            borderWidth: "1",
            borderRadius:
              // we don't need any bars to have radius value
              [0, 0, 0, 0],
          },
          color: define_colors(element["name"], index),
          data: element["data"], // Array of all data related to this Topic among all values
          barWidth: "85%",
        });
      });
      setSeriesData(series_objects);
      // Set Total value
    } else if (props?.data?.series_data?.length === 0) {
      setSeriesData([]);
    }
  }, [props]);

  let option = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      backgroundColor: "#fff",
      textStyle: {
        fontSize: 12,
      },
      padding: [6, 9],
      formatter: (val) => formatToolTip(val),
    },
    legend: {
      top: 2,
      bottom: 3,
      right: props.legendPosition === "right" ? -5 : "auto",
      itemWidth: 15,
      left: props.legendPosition === "left" ? -5 : "auto",
      align: props.legendPosition === "right" ? "right" : "left",
      icon: "roundRect",
      show: props.hideLegend ? false : true,
    },
    grid: {
      y: 50,
      containLabel: true,
      left: "2%",
      right: "2%",
      bottom: "5%",
      top:
        props?.data?.series_data?.length > 7 && !props.hideLegend
          ? "15%"
          : props.hideLegend
            ? props.isSentimentAnalysis
              ? "-2%"
              : "1%"
            : "10%",
    },
    xAxis: {
      show: false,
      inverse: props.legendPosition === "right" ? true : false,
    },
    yAxis: {
      type: "category",
      data: props?.data?.y_axis_data,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      position: props.legendPosition === "right" ? "right" : "left",
    },
    series: seriesData,
  };
  return (
    <ReactEcharts
      notMerge={true}
      option={option}
      theme="custom_theme"
      style={
        props.increaseHeight && !props.isSentimentAnalysis
          ? { height: "460px", width: "100%" }
          : { height: "400px", width: "100%" }
      }
    />
  );
};
export default StackedHorizontalBar;
