import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Grid, Box, Divider } from "@mui/material";
import SharedFunctions from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctions";
import { checkArabicInEnglishLocal } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import "../../keywords.scss";

// newsBlogs component
import DropDownInputs from "./components/dropDownNBComponent/dropDownInputs";
import SourcesInput from "./components/sourcesInputComponent/sourcesInput";
import KeywordsInputs from "./components/keywordsInputs";
import {
  exceedNewsBlogsCountLimits,
  handleValidationSourcesNb,
  handleValidationTitleSearchNb,
  handleDeleteTitle,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/newBlogsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";

const NewsBlogKeyword = (props) => {
  const intl = useIntl();
  const {
    resp,
    handleDelete,
    indexes,
    setKeywords,
    handleKeywords,
    keywords,
    handleValidation,
    handleData,
    setLanguage,
    language,
    setCountries,
    countries,
    setMajorSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    majorSource,
    wordCountNews,
    wordCountNewsSpamKeyword,
    setSelectedSource,
    selectedSource,
    nbExcludedSource,
    setNbExcludedSource,
    titleSearchKeyword,
    setTitleSearchKeyword,
    setTitleSearchKeywordError,
    setNbExcludedSourceError,
  } = props;

  let keywordsInfo = ["keywords", "spam_keywords"];

  const { countTitleSearchKeywords } = SharedFunctions();
  const [excludedSource, setExcludedSource] = useState("");
  const [excludedSourcesErrorMsg, setExcludedSourcesErrorMsg] = useState("");
  const [titleSearch, setTitleSearch] = useState("");
  const [titleSearchErrorMsg, setTitleSearchErrorMsg] = useState("");
  const [copyBtn, setCopyBtn] = useState(
    CheckValueLocale("click_to_copy", "", {}, intl),
  );

  let dropdown = ["countries", "languages_filter"];

  //___________________________get News&blogs limits____________
  let exceedNewsBlogsCountLimitValue = exceedNewsBlogsCountLimits(
    resp?.allowed_conditions,
    wordCountNews +
      wordCountNewsSpamKeyword +
      language.length +
      countries.length +
      majorSource.length +
      nbExcludedSource.length +
      countTitleSearchKeywords(titleSearchKeyword),
  );
  const handleChangeDropdown = (event, type, resp) => {
    const { value } = event.target;
    let enableLanguages =
      type === "languages_filter" &&
      wordCountNews +
        value.length +
        countries.length +
        majorSource.length +
        nbExcludedSource.length +
        countTitleSearchKeywords(titleSearchKeyword) <=
        resp?.allowed_conditions;

    let enableCountries =
      type === "countries" &&
      wordCountNews +
        value.length +
        language.length +
        majorSource.length +
        nbExcludedSource.length +
        countTitleSearchKeywords(titleSearchKeyword) <=
        resp?.allowed_conditions;

    let enableSources =
      type === "major_sources" &&
      wordCountNews +
        value.length +
        countries.length +
        language.length +
        countTitleSearchKeywords(titleSearchKeyword) <=
        resp?.allowed_conditions;
    let languagesList;
    let countriesList;
    let sourcesList;
    if (value.includes("all")) {
      if (type === "languages_filter") {
        languagesList = [...value];
        if (languagesList?.length - 1 == resp?.languages?.length) {
          languagesList = [];
        } else {
          languagesList = [];
          resp?.languages?.map((item) => {
            languagesList.push(item?.id);
          });
        }
        setLanguage(languagesList);

        return;
      }
      if (type === "countries") {
        countriesList = [...value];
        if (countriesList?.length - 1 == resp?.countries?.length) {
          countriesList = [];
        } else {
          countriesList = [];
          resp?.countries?.map((item) => {
            countriesList.push(item?.id);
          });
        }
        setCountries(countriesList);

        return;
      }

      if (type === "major_sources") {
        sourcesList = [...value];
        if (sourcesList?.length - 1 == resp?.sources?.length) {
          sourcesList = [];
        } else {
          sourcesList = [];
          resp?.sources?.map((item) => {
            sourcesList.push(item?.id);
          });
        }

        setMajorSource(sourcesList);

        return;
      }
      return;
    }

    if (type === "languages_filter") {
      setLanguage([...value]);
    }

    if (type === "major_sources") {
      setMajorSource([...value]);
    }
    if (type === "countries") {
      setCountries([...value]);
    }
  };

  const handleSourceskeyboardPress = (event, clickType) => {
    let source = event.target.value;
    if (
      (event.key === "Enter" || (clickType === "btn" && source)) &&
      !exceedNewsBlogsCountLimitValue &&
      source.trim()
    ) {
      handleValidationSourcesNb(
        event,
        source,
        setExcludedSourcesErrorMsg,
        setNbExcludedSourceError,
        excludedSourcesErrorMsg,
        nbExcludedSource,
        setExcludedSource,
        setCopyBtn,
        setNbExcludedSource,
        intl,
        clickType,
      );
    }
  };

  const handleTitleskeyboardPress = (event, clickType) => {
    let title = event.target.value;
    if (
      (event.key === "Enter" || (clickType === "btn" && title)) &&
      !exceedNewsBlogsCountLimitValue &&
      title.trim()
    ) {
      handleValidationTitleSearchNb(
        event,
        title,
        titleSearchErrorMsg,
        titleSearchKeyword,
        setTitleSearch,
        setCopyBtn,
        setTitleSearchKeyword,
        intl,
        clickType,
      );
    }
  };

  const handleDeleteExcludedSource = (index) => {
    handleDeleteTitle(
      index,
      nbExcludedSource,
      setExcludedSourcesErrorMsg,
      setNbExcludedSource,
    );
  };

  const handleDeleteTitleSearch = (index) => {
    handleDeleteTitle(
      index,
      titleSearchKeyword,
      setTitleSearchErrorMsg,
      setTitleSearchKeyword,
    );
  };

  return (
    <Box className="data-source-keyword">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* Keywords Section__________________________________________________________ */}
        <KeywordsInputs
          keywordsInfo={keywordsInfo}
          keywords={keywords}
          indexes={indexes}
          resp={resp}
          titleSearchKeyword={titleSearchKeyword}
          handleData={handleData}
          titleSearchErrorMsg={titleSearchErrorMsg}
          titleSearch={titleSearch}
          checkArabicInEnglishLocal={checkArabicInEnglishLocal}
          handleTitleskeyboardPress={handleTitleskeyboardPress}
          handleDeleteTitleSearch={handleDeleteTitleSearch}
          handleKeywords={handleKeywords}
          handleDelete={handleDelete}
          copyBtn={copyBtn}
          handleValidation={handleValidation}
          setKeywords={setKeywords}
          setCopyBtn={setCopyBtn}
          setTitleSearch={setTitleSearch}
          setTitleSearchErrorMsg={setTitleSearchErrorMsg}
          setTitleSearchKeywordError={setTitleSearchKeywordError}
        />
      </Grid>
      {/* dropdown Section__________________________________________________________ */}
      <Divider sx={{ paddingTop: 4 }} />
      <DropDownInputs
        dropdown={dropdown}
        language={language}
        countries={countries}
        handleChangeDropdown={handleChangeDropdown}
        resp={resp}
      />
      <Divider sx={{ paddingBottom: 4 }} />
      {/* sources Radio btn__________________________________________________ */}
      <SourcesInput
        selectedSource={selectedSource}
        setSelectedSource={setSelectedSource}
        setMajorSource={setMajorSource}
        nbRanks={nbRanks}
        setNbRanks={setNbRanks}
        nbDuplicated={nbDuplicated}
        setNbDuplicated={setNbDuplicated}
        setNbExcludedSource={setNbExcludedSource}
        nbExcludedSource={nbExcludedSource}
        majorSource={majorSource}
        handleChangeDropdown={handleChangeDropdown}
        resp={resp}
        excludedSourcesErrorMsg={excludedSourcesErrorMsg}
        excludedSource={excludedSource}
        handleSourceskeyboardPress={handleSourceskeyboardPress}
        handleDeleteExcludedSource={handleDeleteExcludedSource}
        copyBtn={copyBtn}
        setCopyBtn={setCopyBtn}
        setExcludedSource={setExcludedSource}
        setExcludedSourcesErrorMsg={setExcludedSourcesErrorMsg}
        setNbExcludedSourceError={setNbExcludedSourceError}
      />
    </Box>
  );
};

export default NewsBlogKeyword;
