import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import FormTitle from "./components/formTitle";
import FormDescription from "./components/formDescription";
import FormField from "./components/formField";
import FormButton from "./components/formButton";
import { CheckValueLocale } from "utils/helpers";
import FormErrorPassword from "./components/formErrorPassword";
import { useParams } from "react-router-dom";
import FormControl from "./components/formControl";
import Services from "services/api/Services";

const FormNewUser = ({
  stats,
  errors,
  handleInputChange,
  errorFields,
  handleActivateSignUp,
}) => {
  const intl = useIntl();
  const urlParams = useParams();
  const invitationToken = urlParams?.invitation_token;
  const userEmail = urlParams?.user_email;

  return (
    <Box className="form-new-user-wrapper">
      <Box className="form-new-user-text-box">
        <FormTitle
          text={CheckValueLocale("let_setup_your_account", "", {}, intl)}
        />
      </Box>
      <FormField
        name="email"
        label={CheckValueLocale("email", "", {}, intl)}
        placeholder={CheckValueLocale("enter_your_email", "", {}, intl)}
        type="text"
        onChange={handleInputChange}
        error={stats?.wrongInput || errorFields?.email}
        errorField={errorFields?.email}
        disabled
        defaultValue={userEmail}
      />
      <FormField
        name="password"
        label={CheckValueLocale("password", "", {}, intl)}
        placeholder={CheckValueLocale("enter_your_password", "", {}, intl)}
        type="password"
        onChange={handleInputChange}
        error={stats?.wrongInput || errorFields?.password}
        errorField={errorFields?.password}
        star={true}
      />
      <FormErrorPassword
        stats={stats}
        errors={errors}
        errorField={errorFields?.errorsPassword}
      />
      <FormField
        name="confirmPassword"
        label={CheckValueLocale("confirm_password", "", {}, intl)}
        placeholder={CheckValueLocale(
          "enter_your_password_again",
          "",
          {},
          intl,
        )}
        type="password"
        onChange={handleInputChange}
        error={stats?.wrongInput || errorFields?.confirmPassword}
        errorField={errorFields?.confirmPassword}
        star={true}
      />
      {Services.showThirdCompany ? (
        <FormControl
          name="checked"
          checked={stats?.checked}
          onChange={handleInputChange}
          label={CheckValueLocale("agree_to_privacy_policy", "", {}, intl)}
          errorField={errorFields?.checked}
        />
      ) : null}
      {stats?.wrongInput ? (
        <Box component="p" className="error-text-msg main-error">
          {" "}
          {stats?.errorText}{" "}
        </Box>
      ) : null}
      <FormButton
        text={CheckValueLocale("get_started_now", "", {}, intl)}
        variant="filled"
        onClick={() => handleActivateSignUp(invitationToken, userEmail)}
        loading={stats?.newUserLoading}
      />
    </Box>
  );
};
export default FormNewUser;
