import PostsBody from "./postsBody";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
/*---------------------------------------------------------*/

const PostsPage = (props) => {
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <PostsBody {...props} />
    </MonitorContainer>
  );
};

export default PostsPage;
