import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import Services from "services/api/Services.js";

const FormHeader = () => {
  const intl = useIntl();

  return (
    <Box className="form-header-wrapper">
      <Box>
        <img src={Services.loginLogo} alt="" />
      </Box>
    </Box>
  );
};
export default FormHeader;
