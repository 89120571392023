import moment from "moment";
import {
  Typography,
  TableCell,
  TableRow,
  Tooltip,
  Box,
  TableBody,
} from "@mui/material";
import TotalPostsIcon from "images/benchmark-images/total-posts.svg";
import AvgPostsIcon from "images/benchmark-images/avg-posts.svg";
import TotalEngPerFollowerIcon from "images/benchmark-images/total-engagment-pet-follower.svg";
import TotalEngIcon from "images/benchmark-images/total-engagment.svg";
import ShareOfVoiceIcon from "images/benchmark-images/share-of-voice.svg";
import ShareOfVoiceTooltipIcon from "images/benchmark-images/sov.svg";
import FetchingData from "../../../../../components/fetchingData/fetchingData";
import NoDataFound from "../../../../../components/no-Data/noDataFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngry, faSmile } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import {
  dateTotext,
  CheckValueLocale,
  dateTotextMinusLastDay,
} from "utils/helpers";

const EngagementOverview = ({
  monitorPublicType,
  benchmarkHeadData,
  rmqResponse,
  intl,
  resetTablesData,
  preloaderDatasource,
  setAllDataExist,
  engagementData,
  setEngagementData,
}) => {
  const [engagmentData, setEngagmentData] = useState([]);
  const [engagmentMonitorsID, setEngagmentMonitorsID] = useState([]);
  const [existData, setExistData] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const [shareOfVoiceData, setShareOfVoiceData] = useState([]);
  const [maxShareOfVoiceDataCount, setMaxShareOfVoiceDataCount] = useState(0);
  const [totalPostsData, setTotalPostsData] = useState([]);
  const [maxTotalPostsDataCount, setMaxTotalPostsDataCount] = useState(0);
  const [avgPostsPerDay, setAvgPostsPerDay] = useState([]);
  const [maxAvgPostsPerDayCount, setMaxAvgPostsPerDayCount] = useState(0);
  const [totalEngagments, setTotalEngagments] = useState([]);
  const [maxTotalEngagmentsCount, setMaxTotalEngagmentsCount] = useState(0);
  const [avgEngagmentPerTweetData, setAvgEngagmentPerTweetData] = useState([]);
  const [maxAvgEngagmentPerTweetCount, setMaxAvgEngagmentPerTweetCount] =
    useState(0);
  const [totalEngagmentFollowertData, setTotalEngagmentFollowertData] =
    useState([]);
  const [
    maxTotalEngagmentFollowertDataCount,
    setMaxTotalEngagmentFollowertDataCount,
  ] = useState(0);
  const [engagementtableHeadCellData, setEngagementtableHeadCellData] =
    useState([]);

  const monitorsID = benchmarkHeadData?.monitorData?.map(
    (x) =>
      `${x?.monitor_id}_${x?.data_source_name?.charAt(0)?.toUpperCase() + x?.data_source_name?.slice(1)?.toLowerCase()}`,
  );
  const lastPeriodDates = preloaderDatasource;
  useEffect(() => {
    setNoDataFound(false);
    setExistData(true);
    setAllDataExist(false);
    setEngagmentData([]);
    setEngagmentMonitorsID([]);
    setShareOfVoiceData([]);
    setTotalPostsData([]);
    setAvgPostsPerDay([]);
    setTotalEngagments([]);
    setAvgEngagmentPerTweetData([]);
    setTotalEngagmentFollowertData([]);
    setEngagementtableHeadCellData([]);
    setEngagementData({
      shareOfVoiceData: [],
      totalPostsData: [],
      avgPostsPerDay: [],
      totalEngagments: [],
      avgEngagmentPerTweetData: [],
      totalEngagmentFollowertData: [],
      engagementtableHeadCellData: [],
      maxShareOfVoiceDataCount: 0,
      maxTotalPostsDataCount: 0,
      maxAvgPostsPerDayCount: 0,
      maxTotalEngagmentsCount: 0,
      maxAvgEngagmentPerTweetCount: 0,
      maxTotalEngagmentFollowertDataCount: 0,
    });
  }, [resetTablesData]);

  const engagmentDataTypes = (type, value) => {
    switch (type) {
      case "sov":
        shareOfVoiceData?.push(value);
        break;
      case "total_posts":
        totalPostsData?.push(value);
        break;
      case "avg_posts":
        avgPostsPerDay?.push(value);
        break;
      case "total_eng":
        totalEngagments?.push(value);
        break;
      case "avg_eng":
        avgEngagmentPerTweetData?.push(value);
        break;
      case "total_k_eng":
        totalEngagmentFollowertData?.push(value);
        break;
    }
  };

  const engagmentDataPostiveTypes = (type, value) => {
    switch (type) {
      case "sov":
        shareOfVoiceData?.unshift(value);
        break;
      case "total_posts":
        totalPostsData?.unshift(value);
        break;
      case "avg_posts":
        avgPostsPerDay?.unshift(value);
        break;
      case "total_eng":
        totalEngagments?.unshift(value);
        break;
      case "avg_eng":
        avgEngagmentPerTweetData?.unshift(value);
        break;
      case "total_k_eng":
        totalEngagmentFollowertData?.unshift(value);
        break;
    }
  };

  const setAllData = () => {
    setEngagementData({
      shareOfVoiceData: shareOfVoiceData,
      totalPostsData: totalPostsData,
      avgPostsPerDay: avgPostsPerDay,
      totalEngagments: totalEngagments,
      avgEngagmentPerTweetData: avgEngagmentPerTweetData,
      totalEngagmentFollowertData: totalEngagmentFollowertData,
      engagementtableHeadCellData: [],
      maxShareOfVoiceDataCount: Math.max(...shareOfVoiceData),
      maxTotalPostsDataCount: Math.max(...totalPostsData),
      maxAvgPostsPerDayCount: Math.max(...avgPostsPerDay),
      maxTotalEngagmentsCount: Math.max(...totalEngagments),
      maxAvgEngagmentPerTweetCount: Math.max(...avgEngagmentPerTweetData),
      maxTotalEngagmentFollowertDataCount: Math.max(
        ...totalEngagmentFollowertData,
      ),
    });
    setShareOfVoiceData(shareOfVoiceData);
    setMaxShareOfVoiceDataCount(Math.max(...shareOfVoiceData));

    setAvgEngagmentPerTweetData(avgEngagmentPerTweetData);
    setMaxAvgEngagmentPerTweetCount(Math.max(...avgEngagmentPerTweetData));

    setTotalEngagmentFollowertData(totalEngagmentFollowertData);
    setMaxTotalEngagmentFollowertDataCount(
      Math.max(...totalEngagmentFollowertData),
    );

    setTotalPostsData(totalPostsData);
    setMaxTotalPostsDataCount(Math.max(...totalPostsData));

    setAvgPostsPerDay(avgPostsPerDay);
    setMaxAvgPostsPerDayCount(Math.max(...avgPostsPerDay));

    setTotalEngagments(totalEngagments);
    setMaxTotalEngagmentsCount(Math.max(...totalEngagments));

    const totalPostsDataSummation = totalPostsData?.reduce(
      (total, num) => total + num,
      0,
    );
    const shareOfVoiceDataArr = totalPostsData?.map((val) =>
      totalPostsDataSummation != 0
        ? ((val / totalPostsDataSummation) * 100)?.toFixed(2)
        : 0,
    );
    setShareOfVoiceData(shareOfVoiceDataArr);
  };

  const fetchingDone = () => {
    setNoDataFound(
      shareOfVoiceData?.length == 0 &&
        totalEngagmentFollowertData?.length == 0 &&
        avgEngagmentPerTweetData?.length == 0 &&
        totalPostsData?.length == 0 &&
        avgPostsPerDay?.length == 0 &&
        totalEngagments?.length == 0,
    );

    setExistData(false);
    setAllDataExist(true);
  };

  useEffect(() => {
    switch (rmqResponse?.eventName) {
      case "Twitter__LastPeriodBenchmarkV4Page__engagements_overview":
      case "Facebook__LastPeriodBenchmarkV4Page__engagements_overview":
      case "Instagram__LastPeriodBenchmarkV4Page__engagements_overview":
      case "Talkwalker__LastPeriodBenchmarkV4Page__engagements_overview":
        setNoDataFound(false);
        setExistData(true);
        setAllDataExist(false);
        if (
          monitorsID[0] ==
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source}`
        ) {
          switch (rmqResponse?.period) {
            case lastPeriodDates[0]:
              engagementtableHeadCellData?.unshift(
                monitorPublicType === "Time_Period_vs_Time_Period"
                  ? dateTotextMinusLastDay(rmqResponse?.period)
                  : dateTotext(rmqResponse?.period),
              );
              setEngagementtableHeadCellData(engagementtableHeadCellData);
              rmqResponse?.eventData?.engagements_overview?.map((x) => {
                engagmentDataPostiveTypes(x?.name, x?.value);
              });
              setAllData();
              break;
            case lastPeriodDates[1]:
              engagementtableHeadCellData?.push(
                monitorPublicType === "Time_Period_vs_Time_Period"
                  ? dateTotextMinusLastDay(rmqResponse?.period)
                  : dateTotext(rmqResponse?.period),
              );
              setEngagementtableHeadCellData(engagementtableHeadCellData);
              rmqResponse?.eventData?.engagements_overview?.map((x) => {
                engagmentDataTypes(x?.name, x?.value);
              });
              setAllData();
              break;
          }
          if (engagementtableHeadCellData?.length == 2) {
            fetchingDone();
          }
        }
        break;

      case "Twitter__PositiveNegativeBenchmarkV4Page__engagements_overview":
      case "Facebook__PositiveNegativeBenchmarkV4Page__engagements_overview":
      case "Instagram__PositiveNegativeBenchmarkV4Page__engagements_overview":
      case "Talkwalker__PositiveNegativeBenchmarkV4Page__engagements_overview":
        setNoDataFound(false);
        setExistData(true);
        setAllDataExist(false);
        if (
          monitorsID[0] ==
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source}`
        ) {
          switch (rmqResponse?.sentiment) {
            case "Positive":
              engagementtableHeadCellData?.unshift("Positive");
              setEngagementtableHeadCellData(engagementtableHeadCellData);
              rmqResponse?.eventData?.engagements_overview?.map((x) => {
                engagmentDataPostiveTypes(x?.name, x?.value);
              });
              setAllData();
              break;
            case "Negative":
              engagementtableHeadCellData?.push("Negative");
              setEngagementtableHeadCellData(engagementtableHeadCellData);
              rmqResponse?.eventData?.engagements_overview?.map((x) => {
                engagmentDataTypes(x?.name, x?.value);
              });
              setAllData();
              break;
          }
          if (engagementtableHeadCellData?.length == 2) {
            fetchingDone();
          }
        }
        break;

      case "Twitter__VsAnotherBenchmarkV4Page__engagements_overview":
      case "Facebook__VsAnotherBenchmarkV4Page__engagements_overview":
      case "Instagram__VsAnotherBenchmarkV4Page__engagements_overview":
      case "Talkwalker__VsAnotherBenchmarkV4Page__engagements_overview":
        setNoDataFound(false);
        setExistData(true);
        setAllDataExist(false);
        engagmentMonitorsID?.push(
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source}`,
        );
        setEngagmentMonitorsID(engagmentMonitorsID);
        engagmentData?.push(rmqResponse?.eventData?.engagements_overview);
        setEngagmentData(engagmentData);
        if (engagmentMonitorsID?.length == monitorsID?.length) {
          let checkPreLoader = [];
          monitorsID?.map((x) => {
            engagmentMonitorsID?.map((y) =>
              y == x ? checkPreLoader?.push(y) : null,
            );
          });
          if (checkPreLoader?.length == monitorsID?.length) {
            monitorsID?.map((id1) => {
              engagmentMonitorsID?.map((id2, x) => {
                if (id1 == id2) {
                  let data = engagmentData[x];
                  data?.map((i) => engagmentDataTypes(i?.name, i?.value));
                }
              });
            });
            setAllData();
            fetchingDone();
          } else {
            setNoDataFound(true);
            setExistData(false);
            setAllDataExist(true);
          }
        }
        break;
      default:
        break;
    }
  }, [rmqResponse]);

  return (
    <>
      {monitorPublicType !== "Monitor_vs_Monitors" && (
        <TableRow className="body-tr-title" sx={{ height: "0px !important" }}>
          <TableCell
            sx={{ background: "#f8fafc", borderBottom: "none !important" }}
          ></TableCell>
        </TableRow>
      )}
      <TableRow className="body-tr-title">
        <TableCell
          className={`${
            monitorPublicType === "Monitor_vs_Monitors" && "sticky"
          } widget-title-benchmark`}
          colSpan={8}
        >
          {CheckValueLocale("engagement_overview", "", {}, intl)}
        </TableCell>
      </TableRow>
      {existData ? (
        <TableRow className="body-tr-head fetching-tr">
          <TableCell className="feching-td">
            <FetchingData />
          </TableCell>
        </TableRow>
      ) : noDataFound ? (
        <TableRow className="body-tr-head fetching-tr">
          <TableCell className="feching-td">
            <NoDataFound />
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {monitorPublicType !== "Monitor_vs_Monitors" && (
            <TableRow className="body-tr-head">
              <TableCell component="th">
                <Typography component="span">
                  {CheckValueLocale("metrics", "", {}, intl)}
                </Typography>
              </TableCell>
              {engagementtableHeadCellData?.map((val, index) => (
                <TableCell
                  component="th"
                  key={`engagement-th-${index}`}
                  align={"center"}
                >
                  {monitorPublicType === "Positive_vs_Negative" && (
                    <FontAwesomeIcon
                      icon={val === "Positive" ? faSmile : faAngry}
                      className={`${val?.toLowerCase()}_icon`}
                    />
                  )}
                  <Typography
                    component="span"
                    dir={
                      monitorPublicType === "Time_Period_vs_Time_Period" &&
                      "ltr"
                    }
                  >
                    {CheckValueLocale(val, "", {}, intl)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )}
          {monitorPublicType === "Monitor_vs_Monitors" ? (
            <TableRow className="body-tr-data">
              <TableCell component="th" scope="row">
                <img src={ShareOfVoiceIcon} />
                <Typography component="span">
                  {CheckValueLocale("share_of_voice", "", {}, intl)}
                </Typography>
                <Box component="span" className="share-voice-icon-tooltip">
                  <Tooltip
                    title={
                      <Typography fontSize={11}>
                        {intl.formatMessage(
                          { id: "sov_tooltip" },
                          {
                            break: <br />,
                          },
                        )}
                      </Typography>
                    }
                    sx={{ cursor: "default" }}
                  >
                    <img src={ShareOfVoiceTooltipIcon} />
                  </Tooltip>
                </Box>
              </TableCell>
              {shareOfVoiceData?.map((val, index) => {
                return (
                  <TableCell
                    key={`share-of-voice-${index}`}
                    align="center"
                    className={
                      val > 0 && val === maxShareOfVoiceDataCount
                        ? "highlight-td"
                        : ""
                    }
                  >
                    {val != null ? <>{val || 0}%</> : "—"}
                  </TableCell>
                );
              })}
            </TableRow>
          ) : null}
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={TotalPostsIcon} />
              <Typography component="span">
                {CheckValueLocale("total_posts", "", {}, intl)}
              </Typography>
            </TableCell>
            {totalPostsData?.map((val, index) => {
              return (
                <TableCell
                  key={`total-posts-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxTotalPostsDataCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null ? intl.formatNumber(val) : "—"}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={AvgPostsIcon} />
              <Typography component="span" className="avg-posts-title">
                {CheckValueLocale("avg_posts_per_day", "", {}, intl)}
              </Typography>
            </TableCell>
            {avgPostsPerDay?.map((val, index) => {
              return (
                <TableCell
                  key={`avg-posts-per-day-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxAvgPostsPerDayCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null ? intl.formatNumber(val) : "—"}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={TotalEngIcon} />
              <Typography component="span">
                {CheckValueLocale("total_engagments", "", {}, intl)}
              </Typography>
            </TableCell>
            {totalEngagments?.map((val, index) => {
              return (
                <TableCell
                  key={`total-engagments-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxTotalEngagmentsCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null ? intl.formatNumber(val) : "—"}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={TotalEngIcon} />
              <Typography component="span">
                {CheckValueLocale("avg_engagment_per_tweet", "", {}, intl)}
              </Typography>
            </TableCell>
            {avgEngagmentPerTweetData?.map((val, index) => {
              return (
                <TableCell
                  key={`avg-engagment-per-tweet-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxAvgEngagmentPerTweetCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null ? intl.formatNumber(val) : "—"}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={TotalEngPerFollowerIcon} />
              <Typography component="span">
                {CheckValueLocale("total_engagment_per_follower", "", {}, intl)}
              </Typography>
            </TableCell>
            {totalEngagmentFollowertData?.map((val, index) => {
              return (
                <TableCell
                  key={`total-engagment-per-follower-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxTotalEngagmentFollowertDataCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null ? intl.formatNumber(val) : "—"}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

export default EngagementOverview;
