import { useMutation, useQueryClient } from "@tanstack/react-query";
import MonitorsController from "services/controllers/monitorsController.js";

export const useCreateMonitor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      monitor_model,
      monitor_options,
      data_sources,
      activeDataSourceNameOrCustomerCareVal,
    }) =>
      MonitorsController.createNewMonitor(
        monitor_model,
        monitor_options,
        data_sources,
        activeDataSourceNameOrCustomerCareVal,
      ),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["monitors"],
      });
    },
  });
};
