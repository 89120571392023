import { CheckValueLocale } from "utils/helpers";

//check active datasource tab
export const checkActiveDatasource = (source, value) => {
  let dataSources = {
    TWITTER: `twitter_tab ${value === 1 && `selected`}`,
    FACEBOOK: `facebook_tab ${value === 2 && `selected`}`,
    INSTAGRAM: `instagram_tab ${value === 3 && `selected`}`,
  };
  return dataSources[source] || "";
};

export const checkDatasourceExist = (categorySource, isPublicAccount) => {
  return (
    categorySource &&
    ((categorySource.includes("TWITTER") && isPublicAccount) ||
      categorySource.includes("FACEBOOK") ||
      categorySource.includes("INSTAGRAM"))
  );
};

//function that used to copy Keywords
export const handleCopyBtn = (setCopyBtn, copied, data) => {
  setCopyBtn(copied);
  navigator.clipboard.writeText(data);
};

export const handleName = (
  e,
  setMonitorErrors,
  monitorErrors,
  intl,
  isPublicSpecialCharactersOrEmojis,
  handleCreateMonitorBtn,
  setMonitorData,
  monitorData,
  checkUserNameChar,
  maxNameLength = 15,
  isSpecificFormat = false,
) => {
  setMonitorErrors({ ...monitorErrors, userName: "" });
  if (checkUserNameChar && e.target.value.length > maxNameLength) {
    // IG max length is 30, TW max length is 15
    if (maxNameLength === 30) {
      setMonitorErrors({
        ...monitorErrors,
        userName: "ig_max_char",
      });
    } else {
      setMonitorErrors({
        ...monitorErrors,
        userName: "username_max_char",
      });
    }
  } else if (
    isPublicSpecialCharactersOrEmojis(
      e?.target?.value,
      undefined,
      undefined,
      false,
      isSpecificFormat,
    )
  ) {
    setMonitorErrors({
      ...monitorErrors,
      userName: "usernames_collect_ignore_tweets_error_name",
    });
  } else if (monitorData?.ignoreTweets?.includes(e.target.value)) {
    setMonitorErrors({
      ...monitorErrors,
      userName: "usernames_collect_ignore_tweets_error_exists_other_fields",
    });
  } else {
    handleCreateMonitorBtn(e.target.value);
  }
  setMonitorData({ ...monitorData, userName: e.target.value });
};

//check value
export const checkValue = (spamKeywords) => {
  if (spamKeywords.length > 5) {
    return spamKeywords.slice(0, 6);
  } else {
    return spamKeywords;
  }
};

//check keywords validation
export const checkKeywordsValidation = (
  val,
  monitorErrors,
  checkQuotationMark,
  monitorData,
  setKeyword,
  setCopyBtn,
  data,
) => {
  val
    .trim()
    .toString()
    .split(/[,،]+/)
    .map((word) => {
      if (
        (monitorErrors.spamKeywords === "" ||
          monitorErrors.spamKeywords === null) &&
        val.trim()
      ) {
        let new_word = checkQuotationMark(word, "twitter");
        monitorData.spamKeywords.push(new_word);
      }
    });
  setKeyword("");
  setCopyBtn(data);
};
//check is btn to be enabled or not
export const checkEnabledCreateBtn = (data, errorMsg) => {
  return data && errorMsg === "";
};

//check keywordsPopup all keywords type
export const handleKeywordsPopUp = (
  keyword,
  kewordsVal,
  spamVal,
  collectval,
  ignoreVal,
) => {
  let keywordsTypes = {
    keywords: kewordsVal,
    hashtags: kewordsVal,
    spam_keywords: spamVal,
    spam_hashtags: spamVal,
    collect_tweets: collectval,
    ignore_tweets: ignoreVal,
  };
  return keywordsTypes[keyword] || [];
};

//get datasourceFieldName
export const getDatasourceEditInputFieldName = (type) => {
  let datsources = {
    TWITTER: "twitter_account",
    INSTAGRAM: "instagram_public_account",
    FACEBOOK: "facebook_public_page",
  };
  return datsources[type] || "";
};

// get datasources Id
export const getDatasourcesId = (companyAllowedSources) => {
  let dataSourcesId = {};
  companyAllowedSources?.map((src) => {
    let company_source = src?.attributes?.source;
    switch (company_source) {
      case "TWITTER":
        dataSourcesId.twSourceId = parseInt(src?.id);
        break;
      case "FACEBOOK":
        dataSourcesId.fbSourceId = parseInt(src?.id);
        break;
      case "INSTAGRAM":
        dataSourcesId.igSourceId = parseInt(src?.id);
        break;
      default:
        break;
    }
  });
  return dataSourcesId;
};

//get monitorTypes name
export const getMonitorTypesName = (companyMonitorTypes) => {
  let monitorTypes = [];
  companyMonitorTypes.map((monitorType) => {
    monitorTypes.push(monitorType.attributes.name[0]);
  });
  return monitorTypes;
};

export const isInThePast = (date) => {
  const today = new Date();
  // This line sets the hour of the current date to midnight
  // so the comparison only returns `true` if the passed in date
  // is at least yesterday
  today.setHours(0, 0, 0, 0);
  return date < today;
};
