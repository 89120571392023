import PopupModal from "components/popupModal";
import { useUpgradeCategory } from "../hooks/useUpgradeCategory";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const AddChannelUpgradeIntegrationPopup = ({
  open,
  close,
  rightBtn,
  title,
  body,
  activeCategory,
  onSuccess,
  onError,
}) => {
  const intl = useIntl();
  const { mutate: upgradeCategory } = useUpgradeCategory();

  const handleUpgradeModal = async () => {
    const categoriesKeys = {
      CRM: "add_channel_crm",
    };
    const queryData = {
      service_name: categoriesKeys[activeCategory],
    };
    upgradeCategory(
      { queryData },
      {
        onSuccess: () => {
          onSuccess();
          close();
        },
        onError: () => {
          onError();
          close();
        },
      },
    );
  };

  return (
    <PopupModal
      title={title}
      body={body}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={rightBtn}
      open={open}
      close={close}
      accept={() => handleUpgradeModal()}
    />
  );
};

export default AddChannelUpgradeIntegrationPopup;
