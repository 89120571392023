import React, { useEffect, useState } from "react";
import { Button, Box, Grid, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SwipeableDrawerWrapper } from "../channel-analytics/filter.styles.js";
import FilterDropDownMenu from "./components/filterDropDownMenu";
import {
  isEmptyArray,
  isEmptyObj,
  CheckValueLocale,
} from "utils/helpers/index.js";
import { useDispatch, useSelector } from "react-redux";
import {
  clear,
  reduxSavedFilterId,
  filtersCount,
  clearForSavedFilter,
} from "utils/redux/features/SocialListening/socialListeningSlice.js";
import HelpIcon from "@mui/icons-material/Help";
import LucButton from "shared/lucButton/lucButton.js";

const Filter = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    isAudiencePage,
    filterFields,
    startDate,
    endDate,
    filterParams,
    showFilterBtn,
    monitorActiveTabFilterID,
  } = props;
  const [state, setState] = useState({
    right: false,
    left: false,
  });
  const [errorMessageKeywords, setErrorMessageKeywords] = useState("");

  //  start redux Declarations
  const reduxShowDeleteBtn = useSelector(
    (state) => state?.socialListening?.showDeleteFilterBtn,
  );

  const reduxFilterParamsCount = useSelector(
    (state) => state?.socialListening?.filterParamsCount,
  );

  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );

  const [showDeleteFilterBtn, setShowDeleteFilterBtn] =
    useState(reduxShowDeleteBtn);

  const reduxSavedFilter = useSelector(
    (state) => state?.socialListening?.savedFilter,
  );
  //  end redux Declarations

  var filterDirection = window.localStorage.lang !== "ar" ? "right" : "left";

  const calculateFiltersCount = (filterParamsObject) => {
    let filtersParams = filterParamsObject;
    let checkBoxesFalsyValues = 0;

    // checking False Value For checkboxes [hide spam & hid retweet]
    if (filtersParams["hide_spam"] === "False") checkBoxesFalsyValues++;
    if (filtersParams["hide_retweet"] === "False") checkBoxesFalsyValues++;

    let count =
      Object.keys(filtersParams).filter((key) => filtersParams[key]?.length)
        .length - checkBoxesFalsyValues;

    return count;
  };

  // ______________________________________________________________________________________
  //1st call when filter is rendered that get all filter fields

  useEffect(() => {
    if (!props.isDashboardFilter || props.isDashboardFilter === undefined) {
      props.getFiltersFileds();
    }
  }, []);
  const isActiveFilter = props?.allActiveFilters?.length > 0;

  // _______________________________________________________________________________________
  //this function has 2 actions ,
  // 1st:closes the filter SideBar
  // 2nd:send the applied filters to handleApplyFilter func.
  // or
  // 3rd: send saveAndApply prop to go throught save filter cycle
  const toggleDrawer =
    (anchor, open, applyFilter, saveAndApply, deleteFilter) => (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
      const applicableFilters = filterFields?.filter((allFilters) => {
        return Object.keys(filterParams).some((activeFiltersName) => {
          return allFilters.name === activeFiltersName;
        });
      });
      let applicableFiltersName = applicableFilters?.map((el) => {
        return el.name;
      });
      let finalObj = {};
      finalObj = Object.fromEntries(
        Object.keys(filterParams)
          ?.filter((key) => applicableFiltersName.includes(key))
          ?.map((key) => [key, filterParams[key]]),
      );

      if (applyFilter) {
        props.setAssignActiveFilters(filterParams);
        if (!isEmptyObj(finalObj))
          props.applyFilter(
            reduxFilterStartDateUX,
            reduxFilterEndDateUX,
            finalObj,
            undefined,
            true,
          );
        else
          props.applyFilter(
            reduxFilterStartDateUX,
            reduxFilterEndDateUX,
            filterParams,
            undefined,
            true,
          ); //function created inside 'src\pages\Monitors\mainMonitorPage.js'
        dispatch(filtersCount(calculateFiltersCount(props?.filterParams)));
      }
      if (saveAndApply) {
        if (!isEmptyObj(finalObj)) props.handleShowSaveAndApplyModal(finalObj);
        else props.handleShowSaveAndApplyModal(filterParams);
        setShowDeleteFilterBtn(true);
        dispatch(filtersCount(calculateFiltersCount(props?.filterParams)));
      }
      if (deleteFilter) {
        props.setShowDeleteFilterModal(true);
        dispatch(clear());
      }
      if (open) {
        if (!isActiveFilter) {
          props.handleClearFilterParams();
        }
      }
    };
  // _______________________________________________________________________________________
  // this Function is fired when user choose a saved filter from drop down menu
  const handleSavedFilter = (e) => {
    props.getSavedFilterData(e?.target?.value);
    props.setSavedFilterId(e?.target?.value);
    dispatch(reduxSavedFilterId(e?.target?.value));
    setShowDeleteFilterBtn(true);
  };
  // _______________________________________________________________________________________
  // this function clear selected params and remove delete btn
  const handleClearFilter = () => {
    dispatch(clearForSavedFilter());
    props.handleClearFilterParams();
    !isAudiencePage && props.setSavedFilterId("");
    !isAudiencePage && setShowDeleteFilterBtn(false);
    setErrorMessageKeywords("");
  };

  // _______________________________________________________________________________________
  // Handle ID method for ChurnZero elements
  let handleFilterMonitorButtonId = (val) => {
    let filterId = val
      ? `monitor-${monitorActiveTabFilterID}-${val}`
      : `monitor-${monitorActiveTabFilterID}`;
    return filterId;
  };

  // _______________________________________________________________________________________
  const filterBtn = () => {
    return (
      <>
        <LucButton
          onClick={showFilterBtn ? toggleDrawer(filterDirection, true) : ""}
          startIcon={
            <FontAwesomeIcon
              icon={faFilter}
              className="monitor-header-filter-icon"
            />
          }
          variant="outline"
          type="secondary"
          loading={isEmptyArray(props?.filterFields)}
          id={handleFilterMonitorButtonId("active-Tab-filter-id")}
        >
          {CheckValueLocale("filters", "", {}, intl)}
          <span className={"monitor-filter-count"}>
            {reduxFilterParamsCount ? `(${reduxFilterParamsCount})` : ""}
          </span>
        </LucButton>
      </>
    );
  };

  let inApplicableFilterContent = !isEmptyArray(props?.inApplicableFiltersArray)
    ? props?.inApplicableFiltersArray?.map((item, index) => {
        return (
          <div className="inapplicable-names" key={index}>
            {`${CheckValueLocale(item?.name, "", {}, intl)}: ${
              Array.isArray(item?.value)
                ? item?.name === "sub_themes_filter" ||
                  item?.name === "sub_themes"
                  ? props?.themesOptions
                      ?.filter((theme) =>
                        item?.value?.includes(theme?.sub_theme_id),
                      )
                      ?.map((theme) => theme?.sub_theme_name)
                      ?.join(" - ")
                  : `${CheckValueLocale(item?.value?.join(" - "), "", {}, intl)}`
                : `${item?.value?.replace(",", " - ")}` ?? null
            }`}
          </div>
        );
      })
    : null;

  return (
    <>
      {!showFilterBtn ? (
        <Tooltip
          title={
            <div className="redux-tooltip-text">
              {CheckValueLocale("redux_filter_tooltip", "", {}, intl)}
            </div>
          }
          arrow
          placement="bottom"
        >
          {filterBtn()}
        </Tooltip>
      ) : (
        filterBtn()
      )}

      <SwipeableDrawerWrapper
        anchor={filterDirection}
        open={state[filterDirection]}
        onClose={toggleDrawer(filterDirection, false)}
        onOpen={toggleDrawer(filterDirection, true)}
      >
        <Box
          sx={{
            width: "300px",
            padding: "20px",
            backgroundColor: "#FCFCFC",
            paddingTop: "30px",
          }}
        >
          {/* FILTER HEADER */}
          <Grid
            container
            className="filter-header-grid"
            columnSpacing={{ xs: 1 }}
            sx={{ marginBottom: "20px", backgroundColor: "#fff" }}
          >
            <Grid item xs={6}>
              <Box className="monitor_filter__head">
                <FontAwesomeIcon
                  icon={faFilter}
                  className="monitor-header-filter-menu-icon"
                />
                {CheckValueLocale("filters", "", {}, intl).toUpperCase()}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <LucButton
                onClick={toggleDrawer(filterDirection, false)}
                variant="outline"
                type="secondary"
                size="small"
                className="monitor_hide__btn"
              >
                {CheckValueLocale("hide", "", {}, intl)}
              </LucButton>
            </Grid>
          </Grid>
          <Box className="filter_line"></Box>

          {/* FILTER BODY */}
          <Box className="filterbody__wrapper">
            {!isEmptyArray(props?.filterFields) &&
            props?.isSocialGlobalFilters &&
            !isEmptyArray(props?.inApplicableFiltersArray) ? (
              <Box className="inapplicable-filters-sidemenu">
                <Box className="monitor-inapplicable-filters filter-sidemenu">
                  <Typography variant={"h7"}>
                    <HelpIcon />
                    {CheckValueLocale(
                      "inapplicable_filters",
                      "",
                      { num: props?.inApplicableFiltersArray?.length },
                      intl,
                    )}
                  </Typography>
                </Box>
                <div className="inapplicable-items">
                  {inApplicableFilterContent}
                </div>
              </Box>
            ) : null}
            <FilterDropDownMenu
              {...props}
              handleSavedFilter={handleSavedFilter}
              handleDeleteBtn={toggleDrawer(
                filterDirection,
                false,
                false,
                false,
                "deleteFilter",
              )}
              applyFilterRequested
              showDeleteFilterBtn={showDeleteFilterBtn}
              reduxSavedFilter={reduxSavedFilter}
              id="filter-dropdown"
              errorMessageKeywords={errorMessageKeywords}
              setErrorMessageKeywords={setErrorMessageKeywords}
            />
          </Box>
          {/* FILTER FOOTER */}
          <Box
            className="footer__wrapper"
            style={{
              filter: "drop-shadow(0px -5px 16px rgba(0, 0, 0, 0.08))",
              zIndex: "1",
            }}
          >
            <LucButton
              variant="filled"
              size="small"
              onClick={toggleDrawer(filterDirection, false, true)}
              id={handleFilterMonitorButtonId("filter-apply-btn-id")}
            >
              {CheckValueLocale("apply", "", {}, intl)}
            </LucButton>
            {!isAudiencePage && (
              <LucButton
                variant="outline"
                type="secondary"
                size="small"
                onClick={toggleDrawer(filterDirection, false, false, true)}
                disabled={isEmptyObj(filterParams) && true}
                id={handleFilterMonitorButtonId("filter-save-and-apply-btn-id")}
              >
                {CheckValueLocale("save_and_apply", "", {}, intl)}
              </LucButton>
            )}
            <LucButton
              variant="flat"
              type="secondary"
              size="small"
              onClick={handleClearFilter}
              id={handleFilterMonitorButtonId("filter-clear-btn-id")}
            >
              {CheckValueLocale("clear", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </SwipeableDrawerWrapper>
    </>
  );
};
export default Filter;
