import { Tabs, Tab, Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const MediaTabs = (props) => {
  const intl = useIntl();
  const { handleChange, value, allDatasource } = props;

  return (
    <Box>
      <Tabs
        onChange={handleChange}
        className="social-media-tabs media-tabs-dashboard"
        value={value}
      >
        {allDatasource?.map((datasource, i) => (
          <Tab
            key={i}
            value={datasource}
            className="datasource-media-tab-dash"
            label={
              <Box component="span" className="tap-text-media-dash">
                {CheckValueLocale(datasource?.toLowerCase(), "", {}, intl)}
              </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default MediaTabs;
