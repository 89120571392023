import CreateMonitor from "../api/createMonitor";

var CreateMonitorController = {};

// Get Create Monitor Info
CreateMonitorController.getCreateMonitorData = (queryData) => {
  return new Promise((resolve, reject) => {
    CreateMonitor.getCMData(queryData)
      .then((response) => {
        resolve({
          data: response.data,
          errorMsg: response.msg,
        });
      })
      .catch((err) => {
        resolve({
          data: {},
          errorMsg: err,
          status: err.status,
        });
      });
  });
};

CreateMonitorController.getFacebookPages = (product_id, searchValue) => {
  return new Promise(function (resolve) {
    CreateMonitor.getFacebookPages(product_id, searchValue)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
          status: error?.status,
        });
      });
  });
};
export default CreateMonitorController;
