import { Box } from "@mui/material";

const DefaultAccountImg = ({ selectedValue }) => {
  const firstTwoCharacters = selectedValue
    ?.replace(/[@_]/g, "")
    ?.substring(0, 2);
  return (
    <Box component="span" className="default-img-content">
      {firstTwoCharacters?.toUpperCase()}
    </Box>
  );
};

export default DefaultAccountImg;
