import { useIntl } from "react-intl";
import { Box, Typography, Link, Button } from "@mui/material";
import PageHeader from "components/pageHeader.js";
import { CheckValueLocale } from "utils/helpers";

const FaqsListHeader = ({ faqsExpanded, handleCollapseAll }) => {
  const intl = useIntl();

  return (
    <Box
      mt={5}
      mb={5}
      sx={{
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
      }}
    >
      <div>
        <PageHeader title="freq_asked_ques" />
        <Typography variant="body1" color={"#80868C"} mt={1}>
          {CheckValueLocale("cant_find_what_you_are_looking_for", "", {}, intl)}{" "}
          <Link href="mailto:support@lucidya.com">
            {CheckValueLocale("contact_us", "", {}, intl)}
          </Link>
        </Typography>
      </div>
      <div>
        <Button
          variant="outlined"
          sx={{
            color: "#80868C",
            borderColor: "#80868C",
            padding: "8px 22px",
            fontSize: "15px",
            lineHeight: "26px",
            borderRadius: "4px",
            marginBottom: "-20px",
          }}
          onClick={handleCollapseAll}
          id="faqs-list-collapse-expand-btn"
        >
          {CheckValueLocale(
            faqsExpanded === "all" ? "collapse_all" : "expand_all",
            "",
            {},
            intl,
          )}
        </Button>
      </div>
    </Box>
  );
};

export default FaqsListHeader;
