import Services from "./Services";

var Channels = {};

Channels.getChannels = (queryData) => {
  return Services.getData("api/v3/channel_analytics/channel_types", queryData);
};

Channels.EditCustomerCare = (monitorId, queryData) => {
  return Services.putData(`api/v3/monitors/edit/${monitorId}`, queryData);
};
export default Channels;
