import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { Search } from "@mui/icons-material";
import LucButton from "shared/lucButton/lucButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TeamsHeader = ({ teams, search, handleSearchIconClick }) => {
  const intl = useIntl();
  const [searchInput, setSearchInput] = useState(search);
  const handleSearchInputChange = (e) => {
    setSearchInput(e?.target?.value);
  };
  const handleSearchRun = () => {
    handleSearchIconClick(searchInput);
  };
  return (
    <>
      <Box className="teams-header">
        <Box>
          <Typography variant="h3" className="teams-header-title">
            {CheckValueLocale("teams", "", {}, intl)}
          </Typography>
        </Box>
        <Box>
          <Link to="/settings/teams/add">
            <LucButton startIcon={<FontAwesomeIcon icon={faPlus} />}>
              {CheckValueLocale("new_team", "", {}, intl)}
            </LucButton>
          </Link>
        </Box>
      </Box>
      {teams?.length > 0 || search ? (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <TextField
              placeholder={CheckValueLocale(
                "search_by_team_name",
                "",
                {},
                intl,
              )}
              onInput={handleSearchInputChange}
              onPaste={handleSearchInputChange}
              name="searched"
              value={searchInput}
              className="teams-search-input"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchRun();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearchRun}>
                      <Search id="teams-search-icon" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
