import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import SavedResultItem from "./savedResultItem";

const SavedResults = (props) => {
  const intl = useIntl();
  const {
    getExploreSavedListData,
    isSavedExploreLoading,
    page,
    setPage,
    statsExploreResults,
    setStatsExploreResults,
    handleDeleteSavedResult,
    openSnackBarDelete,
    setOpenSnackBarDelete,
    snackBarData,
    selectedSavedItem,
  } = props;
  const getModelDeleteSavedBody = () => {
    return (
      <Box>
        <Typography component={"p"}>
          {CheckValueLocale("delete_Saved_result_desc_title", "", {}, intl)}
        </Typography>
        <Typography component={"p"}>
          {CheckValueLocale(
            "delete_Saved_result_desc_body",
            "",
            { name: selectedSavedItem?.name },
            intl,
          )}
        </Typography>
        <Typography component={"p"} className="delete-saved-result-head">
          {CheckValueLocale("delete_Saved_result_desc_header", "", {}, intl)}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    getExploreSavedListData(page);
  }, [page]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };
  const handleCloseDeleteModal = () => {
    setStatsExploreResults({ ...statsExploreResults, openDeleteModal: false });
  };

  const handleOpenDeleteModal = (event) => {
    event.stopPropagation();
    setStatsExploreResults({
      ...statsExploreResults,
      openDeleteModal: true,
    });
  };
  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
  };
  return (
    <Box
      className={`explore-saved-results-container ${
        isSavedExploreLoading && "explore-saved-results-loading"
      }`}
    >
      {isSavedExploreLoading ? (
        <CircularLoading />
      ) : (
        <SavedResultItem
          {...props}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleChangePage={handleChangePage}
        />
      )}

      <PopupModal
        title={CheckValueLocale("delete_saved_result_title", "", {}, intl)}
        body={getModelDeleteSavedBody()}
        leftBtn={CheckValueLocale("dashboard_cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("dashboard_delete", "", {}, intl)}
        open={statsExploreResults?.openDeleteModal}
        close={handleCloseDeleteModal}
        accept={() => handleDeleteSavedResult(selectedSavedItem?.id)}
        rightColor="error"
        warning
        minWidth="600px"
      />

      <SnackBar
        open={openSnackBarDelete}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        severity={snackBarData?.severity}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
      />
    </Box>
  );
};

export default SavedResults;
