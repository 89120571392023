import {
  convertedInputRule,
  advancedParser,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/parserFunctions";
import {
  isEmojis,
  isPublicSpecialCharactersOrEmojis,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import {
  checkInvalidCases,
  getAfterNot,
  getBeforeNot,
  checkQuotesNoChar,
  NonRepeatedKeywords,
} from "./components/advancedKeyword";
import {
  checkAdvancedKeywordLanguage,
  checkKeywordLanguage,
  removeSpecialCharacters,
} from "./components/keywordLanguage";
import WordCount from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/wordCount.js";
import _ from "lodash";

//check duplicate in array includes case sensitive
function checkDuplicateArray(dataKeywords) {
  let resCaseSensitive = Array.from(new Set(dataKeywords?.map((e) => e)));
  let resNOtCaseSensitive = Array.from(
    new Set(dataKeywords?.map((e) => e?.toLowerCase())),
  );
  if (dataKeywords?.length !== resCaseSensitive?.length) {
    return false;
  }
  return dataKeywords?.length !== resNOtCaseSensitive?.length;
}

const SharedFunctionsKeywords = () => {
  const {
    checkKeywordLimitNews,
    checkKeywordLimit,
    checkKeywordExist,
    checkKeywordLimitInstagram,
  } = WordCount();
  //handle validattioon for all types
  /* NOTE: when using this function should pass these:
 value:e.target.value
 keywordsData: keywords array
 spamKeywordsData: spam keywords array,
 keywordType: type of field like keywords or spamKeywords,
 setdata: state to show we had error message or not
 */
  const handleValidationKeywords = (
    value,
    keywordsData,
    spamKeywordsData,
    keywordType,
    setdata,
    dataSourceVal,
  ) => {
    let errorMessageResult,
      errorMsgExist = "";

    if (keywordType === "keywords" || keywordType === "spam_keywords") {
      // ************************************************* Keywords / Spam keywords (Twitter/FB/IG/NB) ***********************************************
      // This Validation will work with just Twitter / Facebook / NB (Keyword & Spam Keyword)
      // Rules according to PT specifications for spam: https://lucidya.atlassian.net/wiki/spaces/Q3R/pages/2003959827/Create+Monitor
      // Spam Keywords & Keywords
      // 1- the query can be 60 characters long, the character count includes the entire query string including spaces and operators, for example, the following query is 59 characters long: (happy OR happiness) place_country:GB -birthday -is:retweet
      // 2- a user can match based on emojis
      // 3- "exact phrase matching" using ““
      // 4- Allowed character: [a-z][A-Z][0-9][۰-۹]["'@#^, ] [space but not as single character]
      let keyword_duplicate = false,
        keyword_duplicate_other = false,
        spam_keywords_rules = false,
        invalid_keyword = false,
        max_length = false,
        invalid_parser_syntax = false;
      // If we have Advanced keyword written
      if (
        value?.match(/\w\s(not|NOT|or|OR|AND|and)\s\w/) ||
        value?.match(
          /[\u0600-\u06FF\u0900-\u097F\u0C80-\u0CFF\u0D80-\u0DFF\u0980-\u09FF\u0A80-\u0AFF\u0E00-\u0E7F\u0F00-\u0FFF\u0590-\u05FF\u1E00-\u1EFF\u0041-\u005A\u0061-\u007A\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]\s(not|NOT|or|OR|AND|and)\s[\u0600-\u06FF\u0900-\u097F\u0C80-\u0CFF\u0D80-\u0DFF\u0980-\u09FF\u0A80-\u0AFF\u0E00-\u0E7F\u0F00-\u0FFF\u0590-\u05FF\u1E00-\u1EFF\u0041-\u005A\u0061-\u007A\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]/,
        )
      ) {
        let converted_input_rule = convertedInputRule(value?.trim());
        // here we use try & catch in case of advancedParser cause error show invalid syntx error
        try {
          var output = advancedParser(converted_input_rule);
          let include_keywords = getBeforeNot(output);
          let spam_keywords = getAfterNot(output);
          // Keyword duplicate will already be validated in handleKeywords function
          // So we will just here check for rules, invalid and max length
          include_keywords?.concat(spam_keywords)?.map((keyword) => {
            if (isPublicSpecialCharactersOrEmojis(keyword, true)) {
              spam_keywords_rules = true;
            } else if (
              (checkInvalidCases(keyword, dataSourceVal) ||
                checkQuotesNoChar(value?.trim())) &&
              keyword?.length !== 0
            ) {
              invalid_keyword = true;
            } else if (keyword?.length > 60) {
              max_length = true;
            }
          });
        } catch (err) {
          invalid_parser_syntax = true;
          errorMsgExist = false;
        }
        if (spam_keywords_rules) {
          errorMessageResult = "keywords_spam_keywords_error_name";
          errorMsgExist = false;
        } else if (invalid_keyword) {
          errorMessageResult = "invalid_keyword";

          errorMsgExist = false;
        } else if (max_length) {
          errorMessageResult =
            "reached_maximum_characters_numbers_in_keywords_tooltip";

          errorMsgExist = false;
        } else if (invalid_parser_syntax) {
          errorMessageResult = "invalid_syntax_error";

          errorMsgExist = false;
        } else {
          errorMessageResult = "";
          errorMsgExist = true;
        }
        setdata(errorMsgExist);
        // IF we don't have advanced keywords
      } else {
        let checkKwExixt, checkSpamKwExist;
        value?.split(/[,]+/)?.map((keyword) => {
          //here we check if we had any duplicate for keywords & spamKeywords
          checkKwExixt = keywordsData?.some(
            (item) => keyword?.toLowerCase() === item?.toLowerCase(),
          );

          checkSpamKwExist = spamKeywordsData?.some(
            (item) => keyword?.toLowerCase() === item?.toLowerCase(),
          );

          if (
            keywordsData?.includes(keyword) ||
            spamKeywordsData?.includes(keyword) ||
            checkKwExixt ||
            checkSpamKwExist ||
            checkDuplicateArray([...value?.split(",")])
          ) {
            if (keywordType === "keywords") {
              if (
                keywordsData?.includes(keyword) ||
                checkKwExixt ||
                checkDuplicateArray([...value?.split(",")])
              )
                keyword_duplicate = true;
              else if (spamKeywordsData?.includes(keyword) || checkSpamKwExist)
                keyword_duplicate_other = true;
            } else if (keywordType === "spam_keywords") {
              if (
                spamKeywordsData?.includes(keyword) ||
                checkSpamKwExist ||
                checkDuplicateArray([...value?.split(",")])
              )
                keyword_duplicate = true;
              else if (keywordsData?.includes(keyword) || checkKwExixt)
                keyword_duplicate_other = true;
            }
            keyword_duplicate = true;
          } else if (isPublicSpecialCharactersOrEmojis(keyword, true)) {
            spam_keywords_rules = true;
          } else if (
            keyword?.length !== 0 &&
            (checkInvalidCases(keyword, dataSourceVal) ||
              checkQuotesNoChar(value?.trim()))
          ) {
            invalid_keyword = true;
          } else if (keyword?.length > 60) {
            max_length = true;
          }
        });
        if (keyword_duplicate_other) {
          errorMessageResult = "keywords_error_exists_other_fields";
          errorMsgExist = false;
        } else if (keyword_duplicate) {
          errorMessageResult = "keywords_error_exists";
          errorMsgExist = false;
        } else if (spam_keywords_rules) {
          errorMessageResult = "keywords_spam_keywords_error_name";

          errorMsgExist = false;
        } else if (invalid_keyword) {
          errorMessageResult = "invalid_keyword";

          errorMsgExist = false;
        } else if (max_length) {
          errorMessageResult =
            "reached_maximum_characters_numbers_in_keywords_tooltip";

          errorMsgExist = false;
        } else if (max_length) {
          errorMessageResult =
            "reached_maximum_characters_numbers_in_keywords_tooltip";

          errorMsgExist = false;
        } else {
          errorMessageResult = "";
          errorMsgExist = true;
        }
        setdata(errorMsgExist);
      }
    }
    // **************** hashtags / spam_hashtags (IG) *******************************
    else if (keywordType === "hashtags" || keywordType === "spam_hashtags") {
      var reWhiteSpace = new RegExp("\\s+"); // Avoid \s case !!
      var hashtag_duplicate = false;
      var invalid_hashtag = false;
      var check_emojis = false;
      var one_hash = false;
      var hashtag_max_length = false;
      let checkHastagDuplicate, checkSpamHashtagDuplicate;
      value?.split(/\,|\s#/)?.map((keyword) => {
        let hasQuotes =
          keyword[0] === ('"' || "'" || "‟") &&
          keyword[keyword?.length - 1] === ('"' || "'" || "”");
        let newValue = keyword.replace(/[\"\'\‟\”]/g, "");
        checkHastagDuplicate = keywordsData?.some(
          (item) =>
            `#${keyword}`?.toLowerCase() === item?.toLowerCase() ||
            keyword?.toLowerCase() === item?.toLowerCase(),
        );
        checkSpamHashtagDuplicate = spamKeywordsData?.some(
          (item) =>
            `#${keyword}`?.toLowerCase() === item?.toLowerCase() ||
            keyword?.toLowerCase() === item?.toLowerCase(),
        );
        let noSpecialChar =
          keyword.search(
            /[§±!^\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.؛؟،` ]/g,
          ) === -1 && !isEmojis(keyword);
        if (
          keywordsData?.includes(`#${keyword}`) ||
          spamKeywordsData?.includes(`#${keyword}`) ||
          (hasQuotes && keywordsData?.includes(`#${newValue}`)) ||
          (hasQuotes && spamKeywordsData?.includes(`#${newValue}`)) ||
          checkHastagDuplicate ||
          checkSpamHashtagDuplicate ||
          checkDuplicateArray([...value?.split(",")])
        ) {
          hashtag_duplicate = true;
        } else if (
          (keyword[0] === "#"
            ? checkInvalidCases(keyword, dataSourceVal)
            : checkInvalidCases(`#${keyword}`, dataSourceVal)) &&
          keyword?.length !== 0
        ) {
          invalid_hashtag = true;
        } else if (!noSpecialChar) {
          check_emojis = true;
        } else if (
          reWhiteSpace.test(keyword) ||
          (keyword.match(/#/g) || [])?.length > 1
        ) {
          one_hash = true;
        } else if (keyword?.length > 60) {
          //Hashtag max value equals to keyword max value
          hashtag_max_length = true;
        }
      });
      // if keyword is duplicated, we will not proceed
      if (hashtag_duplicate) {
        errorMessageResult = "keyword_cant_be_duplicate";
        errorMsgExist = false;
      } else if (invalid_hashtag) {
        errorMessageResult = "invalid_keyword";
        errorMsgExist = false;
      } else if (check_emojis) {
        errorMessageResult = "invalid_input_char_emojis";
        errorMsgExist = false;
      } else if (one_hash) {
        errorMessageResult = "one_hash_a_time";
        errorMsgExist = false;
      } else if (hashtag_max_length) {
        //Hashtag max value equals to keyword max value
        errorMessageResult =
          "reached_maximum_characters_numbers_in_keywords_tooltip";
        errorMsgExist = false;
      } else {
        errorMessageResult = "";
        errorMsgExist = true;
      }
      setdata(errorMsgExist);
    } else {
      /******************************************* */
      if (keywordType === "collect_tweets" || keywordType === "ignore_tweets") {
        let username_duplicate = false;
        let username_duplicate_other = false;
        let username_rules = false;
        let username_max_char = false;
        value?.split(/[,]+/).map((keyword) => {
          if (keyword?.length > 15) {
            username_max_char = true;
          } else if (
            keywordsData?.includes(keyword) ||
            spamKeywordsData?.includes(keyword) ||
            checkDuplicateArray([...value?.split(",")])
          ) {
            if (keywordType === "collect_tweets") {
              if (
                keywordsData?.includes(keyword) ||
                checkDuplicateArray([...value?.split(",")])
              )
                username_duplicate = true;
              else username_duplicate_other = true;
            } else if (keywordType === "ignore_tweets") {
              if (
                spamKeywordsData?.includes(keyword) ||
                checkDuplicateArray([...value?.split(",")])
              )
                username_duplicate = true;
              else username_duplicate_other = true;
            }
          } else if (
            isPublicSpecialCharactersOrEmojis(
              keyword,
              undefined,
              undefined,
              false,
              true,
            )
          ) {
            username_rules = true;
          }
        });
        if (username_max_char) {
          errorMessageResult = "username_max_char";

          errorMsgExist = false;
        } else if (username_duplicate) {
          errorMessageResult = "userNames_collect_ignore_error_exists";

          errorMsgExist = false;
        } else if (username_duplicate_other) {
          errorMessageResult =
            "usernames_collect_ignore_tweets_error_exists_other_fields";

          errorMsgExist = false;
        } else if (username_rules) {
          errorMessageResult = "usernames_collect_ignore_tweets_error_name";

          errorMsgExist = false;
        } else {
          errorMessageResult = "";
          errorMsgExist = true;
        }
        setdata(errorMsgExist);
      }
    }
    return errorMessageResult;
  };

  // handle keywords function shared
  /* NOTE: when using this function should pass these:
 value:e.target.value
 keywordType: type of field like keywords or spamKeywords,
 keywordsData: keywords array
 spamKeywordsData: spam keywords array,
 source: datasource name,
 allkeywordsData : array of objects , each object had keywords and spamkeyords [{keywords:[]},{spamKeywords:[]}]
 limit:  limit for each keywords,
 newsBlogCount: in case of newsblogs create montior
 isNewsBlogsCreateValidateCount: in case of newsblogs create montior
 */
  const handlekeywordsShared = (
    value,
    keywordType,
    keywordsData,
    spamKeywordsData,
    source,
    allkeywordsData, //keywords such as [{keywords:[]},{spamKeywords:[]}]
    limit,
    newsBlogCount, //in case of newsblogs
    isNewsBlogsCreateValidateCount, //in case of newsblogs
    usedkeywords = [],
  ) => {
    let objKw = {
      keywords: keywordsData?.length ? [...keywordsData] : [],
      spamKeywords: spamKeywordsData?.length ? [...spamKeywordsData] : [],
      errorMsg: "",
    };
    let count = 0;
    let countNews = 0;
    let exccedKeywordsLimit = false;
    //reset error messge
    objKw.errorMsg = "";

    let checkKeywordsTypeData = ["hashtags", "keywords", "collect_tweets"];
    if (keywordType === "hashtags" || keywordType === "spam_hashtags") {
      var reWhiteSpace = new RegExp("\\s+");
      var words = value?.split(/\,|\s#/);
      words = removeSpecialCharacters(words);
      var nonRepeatedWords = Array.from(new Set(words))
        ?.map((key) => {
          return key;
        })
        ?.toString();
      var state_value = nonRepeatedWords;
      if (keywordType === "hashtags") {
        var keywordsfiltered = [];
        if (source === "instagram") {
          keywordsfiltered = [...keywordsData]?.filter(
            (keyword) => !usedkeywords?.includes(keyword?.replace("#", "")),
          );
        } else {
          keywordsfiltered = [...keywordsData];
        }
        count = checkKeywordLimitInstagram(state_value, keywordsfiltered); //keywords or spam data
      }
      var keyword_duplicate = false;
      var invalid_hashtag = false;
      var check_emojis = false;
      var one_hash = false;
      var max_length = false;
      let checkHashtagExist, checkSpamHashtagExist;

      value?.split(/\,|\s#/)?.map((keyword) => {
        checkHashtagExist = keywordsData?.some(
          (item) => `#${keyword}`?.toLowerCase() === item?.toLowerCase(),
        );
        checkSpamHashtagExist = spamKeywordsData?.some(
          (item) => `#${keyword}`?.toLowerCase() === item?.toLowerCase(),
        );
        let hasQuotes =
          keyword?.length &&
          keyword[0] === ('"' || "'" || "‟") &&
          keyword[keyword?.length - 1] === ('"' || "'" || "”");
        let newValue = keyword?.replace(/[\"\'\‟\”]/g, "");
        let noSpecialChar =
          keyword?.search(
            /[§±!^\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.؛؟،` ]/g,
          ) === -1 && !isEmojis(keyword);
        if (
          keywordsData?.includes(`#${keyword}`) ||
          spamKeywordsData?.includes(`#${keyword}`) ||
          (hasQuotes && keywordsData?.includes(`#${newValue}`)) ||
          (hasQuotes && spamKeywordsData?.includes(`#${newValue}`)) ||
          checkSpamHashtagExist ||
          checkHashtagExist ||
          (value?.length && checkDuplicateArray([...value?.split(",")]))
        ) {
          keyword_duplicate = true;
        } else if (
          (keyword?.length && keyword[0] === "#"
            ? checkInvalidCases(keyword, source)
            : checkInvalidCases(`#${keyword}`, source)) &&
          keyword.length !== 0
        ) {
          invalid_hashtag = true;
        } else if (!noSpecialChar) {
          check_emojis = true;
        } else if (
          reWhiteSpace?.test(keyword) ||
          (keyword?.match(/#/g) || []).length > 1
        ) {
          one_hash = true;
        } else if (keyword?.length > 60) {
          //Hashtag max value equals to keyword max value
          max_length = true;
        }
      });
      // if keyword is duplicated, we will not proceed
      if (keyword_duplicate) {
        objKw.errorMsg = "keyword_cant_be_duplicate";
      } else if (invalid_hashtag) {
        objKw.errorMsg = "invalid_keyword";
      } else if (check_emojis) {
        objKw.errorMsg = "invalid_input_char_emojis";
      } else if (one_hash) {
        objKw.errorMsg = "one_hash_a_time";
      } else if (max_length) {
        //Hashtag max value equals to keyword max value
        objKw.errorMsg =
          "reached_maximum_characters_numbers_in_keywords_tooltip";
      } else if (count > limit) {
        objKw.errorMsg = "keywords_limit_excceds";
      } else {
        objKw.errorMsg = "";
      }
      // Add hashtag to list of hashtags
      if (!objKw.errorMsg) {
        state_value?.split(/[,،]+/)?.map((val) => {
          if (val?.trim() !== "") {
            objKw.errorMsg = "";
            // Append hashtag in Front of keyword value
            if (!val?.slice(0)?.includes("#")) {
              val = "#" + val;
            }
            keywordType === "hashtags"
              ? objKw["keywords"].push(checkKeywordLanguage(val?.trim()))
              : objKw["spamKeywords"].push(checkKeywordLanguage(val?.trim()));
          }
        });
      }
    }
    // in case keywords & spamKeywords
    else {
      let converted_input_rule = convertedInputRule(value?.trim());

      try {
        var output = advancedParser(converted_input_rule);

        let oldKeywords = objKw.keywords;
        let oldSpamKeywords = objKw.spamKeywords;
        let include_keywords = getBeforeNot(output);
        let spam_keywords = getAfterNot(output);
        let checkValKeywordExist;
        let checkValSpamExist;

        var spam_keywordsAsString = NonRepeatedKeywords(spam_keywords);
        let include_keywordsAsString = NonRepeatedKeywords(include_keywords);

        let words = include_keywordsAsString?.split(/[,]+/); //ifnot match we pass here c=value
        words = removeSpecialCharacters(words, source);
        checkValKeywordExist = oldKeywords?.some(
          (item) =>
            include_keywordsAsString?.toLowerCase() === item?.toLowerCase() ||
            spam_keywordsAsString?.toLowerCase() === item?.toLowerCase(),
        );
        checkValSpamExist = oldSpamKeywords?.some(
          (item) =>
            include_keywordsAsString?.toLowerCase() === item?.toLowerCase() ||
            spam_keywordsAsString?.toLowerCase() === item?.toLowerCase(),
        );

        let nonRepeatedWords = Array.from(new Set(words))
          ?.map((key) => {
            return key;
          })
          ?.toString();
        let state_value = nonRepeatedWords;
        objKw.state_value = state_value;
        let countKw = checkKeywordLimit(state_value, objKw["keywords"]);
        let countSpam = checkKeywordLimit(state_value, objKw["spamKeywords"]);
        // here to check keywords or spamkeywords exceed limit or not when using not advanced keyword
        exccedKeywordsLimit =
          source !== "newsBlogs" &&
          value?.match(
            /[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+\s(not|NOT)\s[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+/,
          ) &&
          (countKw > limit || countSpam > limit);
        count = checkKeywordLimit(
          state_value,
          objKw[
            checkKeywordsTypeData?.includes(keywordType)
              ? "keywords"
              : "spamKeywords"
          ],
        );

        if (
          checkKeywordExist(
            include_keywordsAsString,
            allkeywordsData, //keywords such as [{keywords:[]},{spamKeywords:[]}]
            true,
            keywordType,
          ) ||
          // Ensure that we don't have any duplication between Spam & non-spam keywords
          spam_keywordsAsString
            ?.split(",")
            ?.filter((element) =>
              include_keywordsAsString?.split(",")?.includes(element),
            )?.length > 0 ||
          checkValKeywordExist ||
          checkValSpamExist ||
          checkDuplicateArray([...include_keywordsAsString?.split(",")]) ||
          checkDuplicateArray([...spam_keywordsAsString?.split(",")]) ||
          include_keywordsAsString?.toLowerCase() ==
            spam_keywordsAsString?.toLowerCase()
        ) {
          objKw["errorMsg"] = "keyword_cant_be_duplicate";
        }
        let isDatasourceNews = source === "newsBlogs" ? newsBlogCount : count;
        if (isDatasourceNews > limit) {
          if (source !== "newsBlogs") {
            // this case only in tw fb
            objKw["errorMsg"] = "keywords_limit_excceds";
          }
        } else {
          if (!objKw["errorMsg"]) {
            // We have here 2 cases:
            // 1- we are using fields (keywords, collect_tweets, ignore_tweets) || not in the query
            //    in that case we will use the default behavior where data will be assigned to its fields
            // 2- we are using field (spam keywords) and (not/NOT in the query)
            //    so here the new keywords will be spam and excluded keywords will be assigned as keywords
            let allKeywords, excludedKeywords;
            if (
              keywordType === "keywords" ||
              keywordType === "collect_tweets" ||
              keywordType === "ignore_tweets" ||
              !value?.match(
                /[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+\s(not|NOT)\s[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+/,
              )
            ) {
              allKeywords = [
                ...oldKeywords,
                ...include_keywordsAsString?.split(","),
              ];

              if (spam_keywordsAsString !== "") {
                excludedKeywords = [
                  ...oldSpamKeywords,
                  ...spam_keywordsAsString?.split(","),
                ];
              } else {
                excludedKeywords = [...oldSpamKeywords];
              }
            } else {
              allKeywords = [
                ...oldKeywords,
                ...spam_keywordsAsString?.split(","),
              ];

              if (spam_keywordsAsString !== "") {
                excludedKeywords = [
                  ...oldSpamKeywords,
                  ...include_keywordsAsString?.split(","),
                ];
              } else {
                excludedKeywords = [...oldSpamKeywords];
              }
            }

            allKeywords = checkAdvancedKeywordLanguage(allKeywords, source);
            excludedKeywords = checkAdvancedKeywordLanguage(
              excludedKeywords,
              source,
            );
            if (source === "newsBlogs" && newsBlogCount <= limit) {
              countNews = checkKeywordLimitNews(
                state_value,
                allkeywordsData, //all data array of all types
                keywordType,
                spam_keywords.join(","),
              );
            }
            if (
              source === "newsBlogs" &&
              countNews !== undefined &&
              countNews[0] + //count of keywords
                countNews[1] + //count of spamkeywords
                isNewsBlogsCreateValidateCount <=
                limit
            ) {
              // in case not advanced added to spam keywords
              if (excludedKeywords?.length > 0) {
                objKw.spamKeywords = excludedKeywords;
              }

              if (
                keywordType === "keywords" ||
                !value?.match(
                  /[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+\s(not|NOT)\s[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+/,
                )
              ) {
                objKw[
                  checkKeywordsTypeData?.includes(keywordType)
                    ? "keywords"
                    : "spamKeywords"
                ] = objKw[
                  checkKeywordsTypeData?.includes(keywordType)
                    ? "keywords"
                    : "spamKeywords"
                ]?.concat(include_keywordsAsString?.split(","));
              } else {
                objKw["keywords"] = // keywords data
                  objKw["keywords"]?.concat(spam_keywordsAsString.split(","));
              }

              objKw.answer = "";
            } //in case tw , fb
            else {
              if (
                source !== "newsBlogs" &&
                count <= limit &&
                !exccedKeywordsLimit
              ) {
                // in case not advanced
                if (excludedKeywords.length > 0) {
                  objKw["spamKeywords"] = excludedKeywords;
                }
                if (
                  keywordType === "keywords" ||
                  keywordType === "collect_tweets" ||
                  keywordType === "ignore_tweets" ||
                  !value?.match(
                    /[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+\s(not|NOT)\s[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+/,
                  )
                ) {
                  objKw[
                    checkKeywordsTypeData?.includes(keywordType)
                      ? "keywords"
                      : "spamKeywords"
                  ] = objKw[
                    checkKeywordsTypeData?.includes(keywordType)
                      ? "keywords"
                      : "spamKeywords"
                  ]?.concat(include_keywordsAsString.split(","));
                } else {
                  objKw.keywords = objKw.keywords?.concat(
                    spam_keywordsAsString?.split(","),
                  );
                  // Change the length of Count due to change of keywords length depending on spam
                  count = objKw?.keywords?.length;
                }

                objKw.obj_answer_string_tracker = value;
                objKw.answer = "";
              }
            }
          }
        }
      } catch (err) {
        let words = value?.split(/[,]+/);
        words = removeSpecialCharacters(words, source);
        let nonRepeatedWords = Array.from(new Set(words))
          ?.map((key) => {
            return key;
          })
          ?.toString();

        let state_value = nonRepeatedWords;
        objKw.state_value = state_value;
        let checkDupilcateKeywords, checkDuplicateSpamKw;
        checkDupilcateKeywords = objKw.keywords?.some(
          (item) => state_value?.toLowerCase() === item?.toLowerCase(),
        );

        checkDuplicateSpamKw = objKw.spamKeywords?.some(
          (item) => state_value?.toLowerCase() === item?.toLowerCase(),
        );
        let countKw = checkKeywordLimit(state_value, objKw["keywords"]);
        let countSpam = checkKeywordLimit(state_value, objKw["spamKeywords"]);
        // here to check keywords or spamkeywords exceed limit or not when using not advanced keyword

        exccedKeywordsLimit =
          source !== "newsBlogs" &&
          value?.match(
            /[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+\s(not|NOT)\s[\w\u0600-\u06ff\u0750-\u077f\ufb50-\ufc3f\ufe70-\ufefc]+/,
          ) &&
          (countKw > limit || countSpam > limit);
        count = checkKeywordLimit(
          state_value,
          objKw[
            checkKeywordsTypeData?.includes(keywordType)
              ? "keywords"
              : "spamKeywords"
          ],
        );
        if (
          checkKeywordExist(state_value, allkeywordsData, true, keywordType) ||
          checkDupilcateKeywords ||
          checkDuplicateSpamKw ||
          checkDuplicateArray([...state_value])
        ) {
          objKw["errorMsg"] = "keyword_cant_be_duplicate";
        }
        let isDatasourceNews = source === "newsBlogs" ? newsBlogCount : count;

        if (isDatasourceNews > limit) {
          objKw["errorMsg"] = "keywords_limit_excceds";
        } else {
          if (!objKw["errorMsg"]) {
            if (source === "newsBlogs" && newsBlogCount <= limit) {
              countNews = checkKeywordLimitNews(
                state_value,
                allkeywordsData,
                keywordType,
              );
            }
            if (
              countNews !== undefined &&
              countNews[0] + countNews[1] + isNewsBlogsCreateValidateCount <=
                limit &&
              source === "newsBlogs"
            ) {
              let values = state_value?.split(/[,،]+/);
              values?.map((val) => {
                if (val?.trim() !== "") {
                  objKw[
                    checkKeywordsTypeData?.includes(keywordType)
                      ? "keywords"
                      : "spamKeywords"
                  ].push(val?.trim());
                }
              });
              objKw.answer = "";
              objKw.errorMsg = "";
            }
            if (
              source !== "newsBlogs" &&
              count <= limit &&
              !exccedKeywordsLimit
            ) {
              var values = state_value?.split(/[,،]+/);
              values?.map((val) => {
                if (val?.trim() !== "") {
                  objKw[
                    checkKeywordsTypeData?.includes(keywordType)
                      ? "keywords"
                      : "spamKeywords"
                  ].push(val?.trim());
                }
              });
              objKw.answer = "";
            }
          }
        }
      }

      objKw.countNews = countNews;
    }
    objKw.count = count;
    objKw.exccedKeywordsLimit = exccedKeywordsLimit;

    return objKw;
  };
  return {
    handleValidationKeywords,
    handlekeywordsShared,
  };
};
export default SharedFunctionsKeywords;
