import { Box } from "@mui/material";
import "./detailsSideBar.scss";
import HeaderSideBar from "./headerSideBar/headerSideBar";
import ProfileSideBar from "./profileSideBar/profileSideBar";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";

const DetailsSideBar = ({
  selectedCard,
  openDetailsSideBar,
  setOpenDetailsSideBar,
  fetchSideBar,
  profileSideBarData,
  showTotalEngagementsHandler,
}) => {
  let typeSideBarHandler = () => {
    switch (openDetailsSideBar?.type) {
      case "profile":
        return (
          <ProfileSideBar
            selectedCard={selectedCard}
            fetchSideBar={fetchSideBar}
            profileSideBarData={profileSideBarData}
            showTotalEngagementsHandler={showTotalEngagementsHandler}
          />
        );
      default:
        return "";
    }
  };

  return (
    <Box className="details-side-bar-wrapper">
      <HeaderSideBar
        selectedCard={selectedCard}
        openDetailsSideBar={openDetailsSideBar}
        setOpenDetailsSideBar={setOpenDetailsSideBar}
      />
      {typeSideBarHandler()}
    </Box>
  );
};
export default DetailsSideBar;
