import { Box, Skeleton } from "@mui/material";
import "./profileUser.scss";

const ProfileLoading = () => {
  const counterArr = [1, 2, 3];

  return (
    <Box className="profile-card-loader">
      <Box className="profile-card-heading">
        <Skeleton animation="pulse" variant="circular" width={60} height={60} />
        <Box className="card-heading-lines">
          <Skeleton animation="pulse" height={17} width="100px" />
          <Skeleton animation="pulse" height={17} width="71px" />
        </Box>
      </Box>
      {counterArr?.map((i) => (
        <Box className="card-lines">
          <Skeleton animation="pulse" height={17} width="100px" />
          <Skeleton animation="pulse" height={17} width="33px" />
        </Box>
      ))}
    </Box>
  );
};

export default ProfileLoading;
