import {
  Typography,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  InputLabel,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  FormHelperText,
  MenuItem,
  Button,
  Chip,
  TextField,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useIntl } from "react-intl";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CustomMenuItem,
  CustomSelect,
  CustomFormControl,
} from "shared/channel-analytics-filter/filter.styles";
import {
  isEmojis,
  isEmptyArray,
  CheckValueLocale,
  getSocialIcon,
  classNames,
} from "utils/helpers";
import { simpleKeywordFieldCheck } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/simpleKeywordFieldCheck";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis.js";
import { useDispatch } from "react-redux";
import { filters } from "utils/redux/features/SocialListening/socialListeningSlice";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import { checkDirectionLang } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import TruncatedTooltip from "shared/truncatedTooltip/truncatedTooltip";
const filtersLabelsAsItIs = [
  "engagement_analytics_monitors",
  "engagement_routing",
  "sub_themes",
];

const FilterDropDownMenu = (props) => {
  const intl = useIntl();
  const {
    isAudiencePage,
    isQuickSearch,
    checkDataSourceSelected,
    isPublishPage,
    setErrorMessageKeywords,
    errorMessageKeywords,
    filterParams,
    isEngagementAnalytics,
    isBenchmarkPage,
    isUserActivity,
  } = props;
  const isTwitterExploreSelected =
    checkDataSourceSelected &&
    checkDataSourceSelected(props?.filterParams)?.includes("twitter");
  const [dataExist, setDataExist] = useState(true);

  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  /* ______________________________________________________________________________ */
  //handleChange function for all filters except input type:keyword,user,excludeuser,excludekeyword
  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { checked },
    } = event;
    if (name === "min_followers" && value < 0) {
      return false;
    } else if (name === "min_followers" && value >= 0) {
      var obj = {
        ...props.filterParams,
        [name]: value,
      };
    } else {
      obj = {
        ...props.filterParams,
        //case of cheek box
        [name]:
          (isQuickSearch && name !== "data_source") ||
          (isPublishPage && name == "post_id")
            ? value
            : checked === true
              ? "True"
              : checked === false
                ? "False"
                : typeof value === "string" //case of multiple inputs
                  ? value.split(",")
                  : value, //case of single input
      };
    }
    props?.setFilterParams(obj);
  };
  /* ______________________________________________________________________________ */
  //handle change in case of input fields
  const [inputValue, setInputValue] = useState([]);
  const handleChangeInput = (event, inputName) => {
    var value = event.target.value;
    if (
      inputName === "exclude_user" ||
      inputName === "users" ||
      inputName === "usernames"
    ) {
      // users / exclude users: should accept only underscore + characters + numbers
      if (
        value.search(/[§±!^\#\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.,؛؟، ]/g) ===
          -1 &&
        !isEmojis(value)
      ) {
        setInputValue({ name: inputName, value: value.trim() });
      }
    } else {
      setInputValue({ name: inputName, value: value });
    }
    if (inputName === "keywords" || inputName === "exclude_keywords") {
      setInputValue({
        name: inputName,
        value: value,
      });

      //using shared
      let errorMessage = handleValidationKeywords(
        value,
        filterParams["keywords"] ? filterParams["keywords"]?.split(",") : [],
        filterParams["exclude_keywords"]
          ? filterParams["exclude_keywords"]?.split(",")
          : [],
        inputName === "keywords" ? "keywords" : "spam_keywords",
        setDataExist,
        "twitter",
      );
      setErrorMessageKeywords(errorMessage);
    }
  };

  const handleKeyFunc = (inputName, inputName1, inputName2) => {
    if (props.filterParams[inputName1] && props.filterParams[inputName2]) {
      let removeDuplicateUser = props.filterParams[inputName1]?.split(",");
      let removeDuplicateExUser = props.filterParams[inputName2]?.split(",");
      if (
        removeDuplicateUser.includes(inputValue.value.trim()) ||
        removeDuplicateExUser.includes(inputValue.value.trim())
      ) {
        setInputValue({ name: inputName, value: "" });
        return false;
      }
    } else if (props.filterParams[inputName1]) {
      let removeDuplicateUser = props.filterParams[inputName1]
        ?.toString()
        ?.split(",");
      if (removeDuplicateUser.includes(inputValue.value.trim())) {
        setInputValue({ name: inputName, value: "" });
        return false;
      }
    } else if (props.filterParams[inputName2]) {
      let removeDuplicateUser = props.filterParams[inputName2]
        ?.toString()
        ?.split(",");
      if (removeDuplicateUser.includes(inputValue.value.trim())) {
        setInputValue({ name: inputName, value: "" });
        return false;
      }
    }
  };
  /* ______________________________________________________________________________ */
  //hande inputfileds after user click "enter" btn
  const handleKeyDown = (event, inputName) => {
    var value = event.target.value;
    //inputValue.value.trim().length !== 0 is added to refuse empty input or contating spaces only
    if (event.keyCode === 13 && inputValue.value.trim().length !== 0) {
      //Prevent user to enter same value twice
      if (inputName === "exclude_user" || inputName === "users") {
        const val = handleKeyFunc(inputName, "users", "exclude_user");
        if (val !== undefined) {
          return val;
        }
        setInputValue({ name: inputName, value: "" });
      }
      if (inputName === "keywords" || inputName === "exclude_keywords") {
        if (event.keyCode === 13 && dataExist && value?.trim().length !== 0) {
          let objData = handlekeywordsShared(
            value,
            inputName === "keywords" ? "keywords" : "spam_keywords",
            filterParams["keywords"]
              ? filterParams["keywords"]?.split(",")
              : [],
            filterParams["exclude_keywords"]
              ? filterParams["exclude_keywords"]?.split(",")
              : [],
            "twitter",
            [
              {
                keywords: filterParams["keywords"]
                  ? filterParams["keywords"]?.split(",")
                  : [],
              },
              {
                spam_keywords: filterParams["exclude_keywords"]
                  ? filterParams["exclude_keywords"]?.split(",")
                  : [],
              },
            ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
            100,
            undefined, //in case newsblogs,
            undefined,
          );
          if (!objData?.errorMsg) {
            var obj = {
              ...filterParams,
              keywords:
                filterParams["keywords"] &&
                !isEmptyArray(filterParams["keywords"]) &&
                !!filterParams["keywords"]
                  ? [...objData?.keywords].join()
                  : [...objData?.keywords].join(),
              exclude_keywords:
                filterParams["exclude_keywords"] &&
                !isEmptyArray(filterParams["exclude_keywords"]) &&
                !!filterParams["exclude_keywords"]
                  ? [...objData?.spamKeywords].join()
                  : [...objData?.spamKeywords].join(),
            };
            if (!checkExludeExist) {
              delete obj.exclude_keywords;
            }
            props?.setFilterParams(obj);
            if (!objData.exccedKeywordsLimit) {
              setInputValue({
                name: inputName,
                value: "",
              });
            }
          } else {
            setInputValue({
              name: inputName,
              value: value,
            });
          }
        } else {
          if (!errorMessageKeywords)
            setInputValue({
              name: "",
              value: "",
            });
        }
      } else {
        [inputValue]?.map((i) => {
          if (i.name === inputName && i.value !== "") {
            var obj = {
              ...props.filterParams,
              [inputName]:
                props.filterParams[inputName] &&
                !isEmptyArray(props.filterParams[inputName]) &&
                !!props.filterParams[inputName]
                  ? [
                      ...props.filterParams[inputName]?.toString()?.split(","),
                      i.value.trim(),
                    ].join()
                  : [i.value.trim()].join(),
            };
            props?.setFilterParams(obj);
          }
        });
        setInputValue({ name: inputName, value: "" }); //empty filed after user press enter
      }
    }
  };

  /* ______________________________________________________________________________ */
  //handle delete chip
  const handleDeleteWord = (e, index, inputName) => {
    let words = props?.filterParams[inputName]
      ? props?.filterParams[inputName]?.toString()?.split(",")
      : [props?.filterParams[inputName]];
    if (Array.isArray(words) && words?.length > 0) {
      words?.splice(index, 1);
    }
    let obj = {
      ...props?.filterParams,
      [inputName]:
        Array.isArray(words) && words?.length > 0 ? words?.join() : "",
    };
    props?.setFilterParams(obj);
  };
  const handleTruncateName = (input, count) => {
    return input?.length > count
      ? intl.locale === "en"
        ? `${input?.substring(0, count)}...`
        : `...${input?.substring(0, count)}`
      : input;
  };

  const checkLabelFilter = (label, filterName) => {
    return isAudiencePage && filterName === "location"
      ? `${label}_location`
      : isPublishPage && filterName === "status"
        ? `publish_${label}`
        : isPublishPage && filterName === "users"
          ? handleTruncateName(label, 33)
          : label;
  };
  let checkExludeExist = props?.filterFields
    ?.map((item) => item?.name)
    ?.includes("exclude_keywords");
  return (
    <>
      {!isAudiencePage &&
      !isEngagementAnalytics &&
      !isBenchmarkPage &&
      !isUserActivity ? (
        <Box className="filter_sort">
          <CustomFormControl
            sx={{ width: 300, marginBottom: "15px" }}
            className=" monitor-filter-item"
          >
            <Typography className="monitor_filter__label" mb={1}>
              <InputLabel id="demo-multiple-checkbox-label">
                {CheckValueLocale("select_saved_filter", "", {}, intl)}
              </InputLabel>
            </Typography>
            <CustomSelect
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Select Saved Filters"
              onChange={props.handleSavedFilter}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                  },
                },
              }}
              className="filter_dropdown"
              renderValue={(selected) => props.filterName}
              value={props.savedFilterId}
              disabled={isEmpty(props.savedFilters)}
            >
              {!isEmpty(props.savedFilters) &&
                props.savedFilters?.map((val, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={val?.attributes.id}
                      id={`saved-filters-${
                        val?.attributes?.name_filter ?? "filter-name"
                      }`}
                    >
                      {val?.attributes?.name_filter}
                    </MenuItem>
                  );
                })}
            </CustomSelect>
          </CustomFormControl>
          {!isEmpty(props?.savedFilters) &&
          props?.showDeleteFilterBtn &&
          props?.savedFilterId ? (
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              color="error"
              onClick={props?.handleDeleteBtn}
              id="monitors-delete-filter-btn"
            >
              {CheckValueLocale("delete_filter", "", {}, intl)}
            </Button>
          ) : null}
        </Box>
      ) : null}
      {/*case of DropDowns _____________________________________________________________ */}
      {isQuickSearch ? (
        <Box className="explore-engagement">
          {CheckValueLocale("exlpore_engagement", "", {}, intl).toUpperCase()}
        </Box>
      ) : null}
      {props?.filterFields?.length > 0 &&
      !isEngagementAnalytics &&
      !isAudiencePage &&
      !isBenchmarkPage &&
      !isUserActivity ? (
        <Box className="filter_sort"></Box>
      ) : null}
      {props?.filterFields
        ?.sort((a, b) => a.priority - b.priority)
        .map((filter, i) => {
          const isInboxMonitors =
            isEngagementAnalytics &&
            filter?.name === "engagement_analytics_monitors";
          if (
            filter.selection_attribute !== "checkbox-inline" &&
            filter.selection_attribute !== "input-add" &&
            filter.selection_attribute !== "null" &&
            filter.name !== undefined &&
            filter.name !== "seperator" && //hide seperator option
            filter.name !== "export" //hide export option
          ) {
            return (
              <CustomFormControl
                sx={{
                  width: 300,
                  marginBottom: "15px",
                  display:
                    isEmpty(filter.options) &&
                    filter.selection_attribute !== "input-number" &&
                    "none", //in case the dropdown options is empty from BE it won't be shown to user
                }}
                key={i}
                className={classNames(
                  "monitor-filter-item",
                  isInboxMonitors
                    ? "monitor-filter-item-analytics-container"
                    : "",
                )}
              >
                {!isQuickSearch && !isPublishPage ? (
                  <Typography className="monitor_filter__label" mb={1}>
                    {/* User Translation method for all types*/}
                    <InputLabel id="demo-multiple-checkbox-label">
                      {CheckValueLocale(
                        isUserActivity
                          ? `${filter.name}_user_activity`
                          : filter?.name === "sub_themes"
                            ? "filter_themes"
                            : filter?.name === "themes"
                              ? "filter_category"
                              : filter.name,
                        "",
                        {},
                        intl,
                      )}
                    </InputLabel>
                  </Typography>
                ) : null}
                {isPublishPage && filter.name != "post_id" ? (
                  <Typography className="monitor_filter__label" mb={1}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      {CheckValueLocale(filter.name, "", {}, intl)}
                    </InputLabel>
                  </Typography>
                ) : null}
                {isQuickSearch &&
                filter.selection_attribute == "input-number" &&
                filter.name == "retweet_count" ? (
                  <Box className="retweet-count-title">
                    {CheckValueLocale(
                      "explore_twitter_interaction",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                ) : null}
                {/*  Custom input-number appear in QuickSearch*/}
                {filter?.selection_attribute == "input-number" &&
                isQuickSearch ? (
                  <Box
                    className={`explore-text-filed-container ${filter?.name}`}
                  >
                    <TextField
                      type="number"
                      id="outlined-adornment-amount"
                      className="explore-text-field-input"
                      variant="outlined"
                      value={
                        props.filterParams[filter?.name]
                          ? props.filterParams[filter?.name]
                          : ""
                      }
                      name={filter?.name}
                      inputProps={{ min: 1 }}
                      onChange={handleChange}
                      disabled={!isTwitterExploreSelected}
                    />
                    <Typography
                      component="span"
                      className={`${
                        !isTwitterExploreSelected && "explore-input-label"
                      }`}
                    >
                      {CheckValueLocale(filter?.name, "", {}, intl)}
                    </Typography>
                  </Box>
                ) : null}

                {/* // in case of publish page  */}
                {/*  Custom input-number appear in QuickSearch*/}
                {filter?.selection_attribute == "input-number" &&
                isPublishPage ? (
                  <Box
                    className={`publish-text-filed-container ${filter?.name}`}
                  >
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label={CheckValueLocale(filter?.name, "", {}, intl)}
                      variant="outlined"
                      className="publish-text-field-input"
                      value={
                        props.filterParams[filter?.name]
                          ? props.filterParams[filter?.name]
                          : ""
                      }
                      name={filter?.name}
                      inputProps={{ min: 1 }}
                      onChange={handleChange}
                    />
                  </Box>
                ) : null}

                {filter.selection_attribute !== "input-number" ? (
                  <>
                    {isQuickSearch ? (
                      <Typography className="monitor_filter__label" mb={1}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          {CheckValueLocale(filter?.name, "", {}, intl)}
                        </InputLabel>
                      </Typography>
                    ) : null}
                    <CustomSelect
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple={filter?.name === "verified_only" ? false : true}
                      value={
                        props?.filterParams[filter?.name] !== undefined
                          ? !Array.isArray(props?.filterParams[filter?.name])
                            ? props?.filterParams[filter?.name]?.split(",")
                            : props?.filterParams[filter?.name]
                          : []
                      }
                      label={CheckValueLocale(
                        isUserActivity
                          ? `${filter.name}_user_activity`
                          : filter.name,
                        "",
                        {},
                        intl,
                      )}
                      name={filter?.name}
                      onChange={handleChange}
                      className="filter_dropdown monitor-filter-item"
                      renderValue={(selected) =>
                        Array.isArray(selected)
                          ? selected.map((i, index) => {
                              let labels = filter?.options?.filter(
                                (key) => key?.value === i,
                              );
                              if (labels[0]?.label) {
                                i =
                                  filter?.name === "segment"
                                    ? labels[0]?.label
                                    : CheckValueLocale(
                                        checkLabelFilter(
                                          labels[0]?.label?.toLowerCase(),
                                          filter?.name,
                                        ),
                                        "",
                                        {},
                                        intl,
                                      );
                              }

                              if (
                                selected?.length > 1 &&
                                index !== selected?.length - 1
                              ) {
                                return [
                                  filter?.name === "segment"
                                    ? i + ", "
                                    : ((isBenchmarkPage || isUserActivity) &&
                                          filter?.name === "monitor_list") ||
                                        (isUserActivity &&
                                          filter?.name === "user")
                                      ? filter?.options?.map((opt) => {
                                          if (opt?.id == i)
                                            return opt?.value + ", ";
                                        })
                                      : CheckValueLocale(
                                          i?.toLowerCase(),
                                          "",
                                          {},
                                          intl,
                                        ) + ", ",
                                ];
                              } else {
                                return [
                                  filter?.name === "segment"
                                    ? i
                                    : ((isBenchmarkPage || isUserActivity) &&
                                          filter?.name === "monitor_list") ||
                                        (isUserActivity &&
                                          filter?.name === "user")
                                      ? filter?.options?.map((opt) => {
                                          if (opt?.id == i)
                                            return `${opt?.value}, `;
                                        })
                                      : CheckValueLocale(
                                          i?.toLowerCase(),
                                          "",
                                          {},
                                          intl,
                                        ),
                                ];
                              }
                            })
                          : selected.split(",").map((i, index) => {
                              let labels = filter?.options?.filter(
                                (key) => key?.value === i,
                              );
                              if (labels[0]?.label) {
                                i =
                                  filter?.name === "segment"
                                    ? labels[0]?.label
                                    : CheckValueLocale(
                                        labels[0]?.label.toLowerCase(),
                                        "",
                                        {},
                                        intl,
                                      );
                              }

                              if (
                                selected?.length > 1 &&
                                index !== selected?.length - 1
                              ) {
                                return [
                                  filter?.name === "segment"
                                    ? i
                                    : CheckValueLocale(
                                        checkLabelFilter(
                                          i.toLowerCase(),
                                          filter?.name,
                                        ),
                                        "",
                                        {},
                                        intl,
                                      ) + ", ",
                                ];
                              } else {
                                return [
                                  filter?.name === "segment"
                                    ? i
                                    : CheckValueLocale(
                                        i.toLowerCase(),
                                        "",
                                        {},
                                        intl,
                                      ),
                                ];
                              }
                            })
                      }
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 300,
                            width: 250,
                          },
                        },
                      }}
                      disabled={isEmpty(filter?.options)}
                    >
                      {!isEmpty(filter.options) &&
                        filter.options?.map((val, i) => {
                          return (filter?.name === "LanguagesDistribution" &&
                            (val?.label === "qme" ||
                              val?.label === "zxx" ||
                              val?.label === "qam")) ||
                            (filter?.name === "location" &&
                              val?.label === "IL") ? null : (
                            <CustomMenuItem
                              key={i}
                              value={
                                isEngagementAnalytics
                                  ? "engagement_data_sources" === filter?.name
                                    ? val?.label
                                    : val?.value
                                  : ((isBenchmarkPage || isUserActivity) &&
                                        filter?.name === "monitor_list") ||
                                      (isUserActivity &&
                                        filter?.name === "user")
                                    ? val?.id
                                    : filter?.name === "sub_themes" ||
                                        (isQuickSearch &&
                                          filter?.name == "data_source") ||
                                        (isPublishPage &&
                                          (filter?.name == "users" ||
                                            filter?.name == "data_source" ||
                                            filter?.name == "status"))
                                      ? val?.value
                                      : val?.label
                                        ? val?.label
                                        : val?.value
                              } //some filters doesn't have label so we use value instead for those cases
                              className="monitor-filter-item"
                            >
                              <Checkbox
                                sx={{ margin: "5px" }}
                                checked={
                                  props.filterParams[filter?.name]?.indexOf(
                                    isEngagementAnalytics
                                      ? "engagement_data_sources" ===
                                        filter?.name
                                        ? val?.label
                                        : val?.value
                                      : ((isBenchmarkPage || isUserActivity) &&
                                            filter?.name == "monitor_list") ||
                                          filter?.name == "user"
                                        ? val?.id
                                        : filter?.name === "sub_themes" ||
                                            (isQuickSearch &&
                                              filter?.name == "data_source") ||
                                            (isPublishPage &&
                                              (filter?.name == "users" ||
                                                filter?.name == "data_source" ||
                                                filter?.name == "status"))
                                          ? val?.value
                                          : val?.label
                                            ? val?.label
                                            : val?.value, //some filters doesn't have label so we use value instead for those cases
                                  ) > -1
                                }
                              />
                              <Box
                                className={classNames(
                                  "monitor-filter-item-text-container",
                                  isInboxMonitors
                                    ? "engagement-analytics-monitors-item-container"
                                    : "",
                                )}
                              >
                                <TruncatedTooltip
                                  className="monitor-filter-item-text"
                                  tooltipClassName="general-filters-monitors-tooltip"
                                  enablePropperProps={true}
                                  title={
                                    filtersLabelsAsItIs?.includes(filter?.name)
                                      ? val?.label
                                      : filter?.name ===
                                            "LanguagesDistribution" &&
                                          val?.label === "no"
                                        ? CheckValueLocale(
                                            "norwegian",
                                            "",
                                            {},
                                            intl,
                                          )
                                        : filter?.name !==
                                              "SourceDistribution" && val?.label
                                          ? filter?.name === "segment"
                                            ? val?.label
                                            : CheckValueLocale(
                                                checkLabelFilter(
                                                  val?.label?.toLowerCase(),
                                                  filter?.name,
                                                ),
                                                "",
                                                {},
                                                intl,
                                              )
                                          : val?.value
                                            ? CheckValueLocale(
                                                val?.value.toLowerCase(),
                                                "",
                                                {},
                                                intl,
                                              )
                                            : ""
                                  }
                                />

                                {isInboxMonitors ? (
                                  <EngagementAnalyticsMonitorOption
                                    monitor={val}
                                  />
                                ) : null}
                              </Box>
                            </CustomMenuItem>
                          );
                        })}
                    </CustomSelect>
                  </>
                ) : (
                  <>
                    {/* //case of Number Input filed */}
                    {!isQuickSearch && !isPublishPage ? (
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        type="number"
                        label={CheckValueLocale(filter?.name, "", {}, intl)}
                        name={filter?.name}
                        onChange={handleChange}
                        value={
                          props.filterParams[filter?.name]
                            ? props.filterParams[filter?.name]
                            : ""
                        }
                        className="filter_dropdown monitor-filter-item"
                      />
                    ) : null}
                  </>
                )}
              </CustomFormControl>
            );
          }
        })}
      {/* ______________________________________________________________________________ */}
      {isQuickSearch ? (
        <Box className="explore-tw-authors">
          {CheckValueLocale("exlpore_twitter_authors", "", {}, intl)}
        </Box>
      ) : null}
      {props?.filterFields?.length > 0 &&
        !isPublishPage &&
        !isEngagementAnalytics && <Box className="filter_sort"></Box>}
      {props?.filterFields !== undefined &&
        props?.filterFields
          .sort((a, b) => a.priority - b.priority)
          .map((filter, key) => {
            if (
              filter.selection_attribute === "checkbox-inline" ||
              filter.selection_attribute === "input-add"
            ) {
              return (
                <>
                  {filter.selection_attribute !== "checkbox-inline" ? (
                    //case of Inputs
                    <>
                      <CustomFormControl
                        sx={{ width: 300, marginBottom: "15px" }}
                        className="textfield-label monitor-filter-item"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          {CheckValueLocale(filter?.name, "", {}, intl)}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          startAdornment={
                            (filter.name === "users" ||
                              filter.name === "exclude_user" ||
                              filter.name === "usernames") && (
                              <InputAdornment position="start">
                                @
                              </InputAdornment>
                            )
                          }
                          label={CheckValueLocale(filter?.name, "", {}, intl)}
                          className={`filter_dropdown ${checkDirectionLang(
                            inputValue?.value,
                          )}`}
                          onKeyDown={(e) => {
                            handleKeyDown(e, filter?.name);
                          }}
                          onChange={(e) => handleChangeInput(e, filter?.name)}
                          value={
                            inputValue.name === filter?.name
                              ? inputValue.value
                              : ""
                          }
                          name={filter?.name}
                          disabled={isQuickSearch && !isTwitterExploreSelected}
                          error={
                            inputValue.name === filter?.name &&
                            errorMessageKeywords !== ""
                          }
                        />
                        {errorMessageKeywords &&
                        inputValue.name === filter?.name ? (
                          <Box className="err-msg">
                            {CheckValueLocale(
                              errorMessageKeywords,
                              "",
                              { limit: 100 },
                              intl,
                            )}
                          </Box>
                        ) : (
                          <FormHelperText
                            id="filled-weight-helper-text"
                            style={{ marginBottom: "5px" }}
                          >
                            {CheckValueLocale(
                              "click_enter_to_add",
                              "",
                              {},
                              intl,
                            )}
                          </FormHelperText>
                        )}
                      </CustomFormControl>

                      {/* Chip Section__________________________________________________ */}
                      {!isEmptyArray(props.filterParams[filter?.name]) &&
                        props.filterParams[filter?.name] !== undefined &&
                        props.filterParams[filter?.name] !== "" && (
                          <div className="section-box-data box-margin">
                            {/* this part has two cases if user add keywords for 1st time it will be added in type of string 
                            BUT if user choose old saved filter that contain keywords it will be in type of Array
                            so we do split in first case to avoid map crash  */}
                            {typeof props.filterParams[filter?.name] ===
                            "string"
                              ? props.filterParams[filter?.name]
                                  ?.split(",")
                                  .map((word, index) => {
                                    return (
                                      <KeywordTooltipChip
                                        onDelete={(e) =>
                                          handleDeleteWord(
                                            e,
                                            index,
                                            filter?.name,
                                          )
                                        }
                                        dataSource={"twitter"}
                                        index={index}
                                        labels={word}
                                      />
                                    );
                                  })
                              : props.filterParams[filter?.name].map(
                                  (word, index) => {
                                    return (
                                      <Chip
                                        label={word}
                                        className={"chip-style"}
                                        onDelete={(e) =>
                                          handleDeleteWord(
                                            e,
                                            index,
                                            filter?.name,
                                          )
                                        }
                                      />
                                    );
                                  },
                                )}
                          </div>
                        )}
                    </>
                  ) : (
                    //case of checkBoxes
                    <FormGroup className="check_box">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              props.filterParams[filter?.name] == "True"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label={CheckValueLocale(filter?.name, "", {}, intl)}
                        name={filter?.name}
                      />
                    </FormGroup>
                  )}
                </>
              );
            }
          })}
    </>
  );
};

// A component to handle the monitor/channels option in the filter dropdown inside the engagement analytics page
const EngagementAnalyticsMonitorOption = ({ monitor }) => {
  const intl = useIntl();
  // is it monitor or channel analytics
  const isSocialListening = monitor?.product_name === "SM";
  const isDM = monitor?.is_dm;
  const isPaused = monitor?.paused;
  const dataSources = monitor?.data_source || [];
  const monitorName =
    monitor?.monitor_type_name === "MANAGED_PAGE"
      ? "MANAGED_ACCOUNT"
      : monitor?.monitor_type_name;
  return (
    <Box className="engagement-analytics-monitors-info">
      <Box
        className={`status-circle ${isPaused ? "not-available" : "available"}`}
      />
      <Typography className="engagement-analytics-monitors-type">
        {CheckValueLocale(
          isSocialListening ? monitorName : "engag_channel_analytics",
          "",
          {},
          intl,
        )}
      </Typography>
      <Box className="engagement-analytics-monitors-icons">
        {dataSources?.map?.((source) => {
          let dataSource = source?.toUpperCase();
          if (isDM) {
            dataSource = dataSource + "_private";
          }
          return <Box key={dataSource}>{getSocialIcon(dataSource)}</Box>;
        })}
      </Box>
    </Box>
  );
};

export default FilterDropDownMenu;
