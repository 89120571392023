import { Box } from "@mui/material";
import "./backgroundSide.scss";
import BackgroundHeader from "./backgroundHeader";
import BackgroundFooter from "./backgroundFooter";

const BackgroundSide = () => {
  return (
    <Box className="background-wrapper">
      <BackgroundHeader />
      <BackgroundFooter />
    </Box>
  );
};
export default BackgroundSide;
