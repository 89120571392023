import "./noDataFound.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";

import Monitor from "images/noDataFound/Monitor.svg";

const NoDataFound = (props) => {
  const { showApplyFilterMsg } = props;
  const intl = useIntl();

  return (
    <Box className="chart-no-data charts-no-data">
      <img src={Monitor} alt="No Data Available Image" />
      <Typography component={"h3"} className="no-data-found">
        {CheckValueLocale("no_data_found", "", {}, intl)}
      </Typography>
      <Typography component={"span"} className="try-adjust-date">
        {CheckValueLocale("no_data_found_adjust", "", {}, intl)}
      </Typography>
    </Box>
  );
};

export default NoDataFound;
