import { TextField } from "@mui/material";
import React from "react";
import { classNames } from "utils/helpers";
import "./inputField.scss";

export const InputField = (props) => {
  return (
    <TextField
      variant="outlined"
      margin="none"
      fullWidth
      size="small"
      {...props}
      className={classNames(props.className, "input-field-with-shrink-label")}
    />
  );
};
