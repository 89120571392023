import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Tooltip,
  TextField,
  Divider,
} from "@mui/material";
import { useIntl } from "react-intl";
import CachedIcon from "@mui/icons-material/Cached";
import {
  facebookAuth,
  getCompanyApps,
} from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { CheckValueLocale } from "utils/helpers";
import { isPublicSpecialCharactersOrEmojis } from "../../MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import InfoIcon from "@mui/icons-material/Info";

const ManagedType = (props) => {
  const intl = useIntl();
  const { monitorDetails, updatedParams, setUpdatedParams } = props;
  const [companyApps, setCompanyApps] = useState([]);
  let monitorCreator = monitorDetails?.monitor_creator?.name;
  const [errorMsg, setErrorMsg] = useState(false);

  // Get Company Apps from Common Auth File to use them in auth________________________
  useEffect(() => {
    getCompanyApps(setCompanyApps);
  }, []);

  // Handle Auth from Common Auth File_________________________________________________
  const handleReauth = (dataSourceName) => {
    const redirectUrl =
      window?.location?.origin + "/settings/accounts_management/users_accounts";
    switch (dataSourceName) {
      case "FACEBOOK":
        facebookAuth(companyApps, redirectUrl, dataSourceName);
        break;
      case "INSTAGRAM":
        facebookAuth(companyApps, redirectUrl, dataSourceName);
        break;
      default:
        break;
    }
  };
  const accountTitle =
    monitorDetails?.data_sources[0] === "INSTAGRAM"
      ? "instagram_business_account"
      : "facebook_page";

  const handleInputChange = (event) => {
    let val = event.target.value;
    if (isPublicSpecialCharactersOrEmojis(val)) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
    setUpdatedParams({
      ...updatedParams,
      customer_care_accounts: [val],
    });
  };

  return (
    <>
      <Typography className="section-header">
        {CheckValueLocale(
          monitorDetails?.data_sources[0]?.toLowerCase(),
          "",
          {},
          intl,
        )}
      </Typography>
      <Typography className="posts-used">
        {CheckValueLocale("posts_used", "", {}, intl)}:{" "}
        {monitorDetails?.posts_count_stats[monitorDetails?.data_sources[0]]}
      </Typography>
      {/* ______________________________________________________________ */}
      <Box className="section-box">
        <Box className="section-box-data">
          <Typography className="main-titles">
            {CheckValueLocale(accountTitle, "", {}, intl)}
          </Typography>

          <Box className="reauth-name deactive-reauth">
            <Typography id={monitorDetails?.account_name}>
              {"@" + monitorDetails?.account_name}
            </Typography>
            {monitorCreator.includes("Deactivated") ||
            monitorCreator.includes("Deleted") ? (
              <>
                <Tooltip
                  title={
                    <Box className="audience-tooltip">
                      {CheckValueLocale("reauthenticate_tooltip", "", {}, intl)}
                    </Box>
                  }
                  arrow
                  placement="top"
                >
                  <Button
                    className="btn-deactive"
                    startIcon={<CachedIcon />}
                    variant="text"
                    id="monitor-options-re-authenticate-managed-type-btn"
                  >
                    {CheckValueLocale("reauthenticate", "", {}, intl)}
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Button
                startIcon={<CachedIcon />}
                variant="text"
                onClick={() => {
                  handleReauth(monitorDetails?.data_sources[0]);
                }}
                id="monitor-options-re-authenticate-btn"
              >
                {CheckValueLocale("reauthenticate", "", {}, intl)}
              </Button>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className="section-box-data section-box-data-customer">
          <Typography component={"h3"}>
            {CheckValueLocale("customer_care_account", "", {}, intl)}
            <Tooltip
              title={CheckValueLocale("customer_care_info", "", {}, intl)}
              placement={intl?.locale === "en" ? "right" : "left"}
              arrow
              id="radio-buttons-box"
              componentsProps={{
                tooltip: {
                  sx: {
                    width: "234px",
                  },
                },
              }}
            >
              <Typography component={"span"} className="tooltip-btn">
                <InfoIcon id="customer-care-info-icon" />
              </Typography>
            </Tooltip>
          </Typography>
          <Box className="shared-customer-care-container">
            <TextField
              error={errorMsg}
              label={
                <span className={errorMsg ? "username-label" : ""}>
                  {CheckValueLocale("username", "", {}, intl)}
                </span>
              }
              placeholder={
                !monitorDetails?.customer_care_accounts?.length > 0
                  ? CheckValueLocale("insert_username_then_enter", "", {}, intl)
                  : null
              }
              helperText={
                <span
                  className={errorMsg ? "error-msg helper-msg" : "helper-msg"}
                >
                  {CheckValueLocale(
                    errorMsg
                      ? "username_special_characters_error"
                      : "customer_care_username",
                    "",
                    {},
                    intl,
                  )}
                </span>
              }
              id="outlined-read-only-input"
              className="customer-care-input"
              value={
                updatedParams?.customer_care_accounts?.length
                  ? updatedParams?.customer_care_accounts[0]
                  : monitorDetails?.customer_care_accounts
              }
              onChange={(e) => handleInputChange(e)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ManagedType;
