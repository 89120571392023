import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Container } from "@mui/material";
// import useStyles from "./useStyles";
import CompanyInfoIndex from "./components/companyInfo/index";
import Sidebar from "./components/sidebar";
import SmartTagsIndex from "./components/smartTags/index";
import ThirdPartyIntegrationIndex from "./components/thirdPartyIntegration/index";
import SubscriptionIndex from "./components/subscription/index";
import UserManagementIndex from "./components/userManagement/index";
import HistoricalRequest from "./components/historicalRequest/index";
import { Route, Switch } from "react-router-dom";
import "./settings.scss";
import NotFoundPage from "pages/not-found/NotFoundPage.js";
import LucidyaApi from "./components/lucidyaAPIs/index";
import Teams from "./components/teams";
import InstagramHashtags from "./components/InstagramHashtags/index";
import InboundedRouting from "./components/InboundRouting/index";
import AccountsManagement from "./components/accountsManagement";
import { handlelUserRoles } from "utils/helpers";

const SettingsPage = (props) => {
  const intl = useIntl();

  return (
    <Grid container spacing={1} className="container-settings">
      <Grid item lg={2} alignContent="center">
        <Sidebar />
      </Grid>
      <Grid item lg={9} alignContent="center">
        <Container
          maxWidth="xl"
          sx={{
            marginTop: "20px",
            minHeight: " 70px",
            lineHeight: "15.4px",
            paddingLeft: "10px !important",
            paddingRight: "10px !important",
            marginBottom: "25px",
          }}
        >
          <Switch>
            <Route exact path="/settings/" component={CompanyInfoIndex} />
            <Route path="/settings/smart_tags" component={SmartTagsIndex} />
            <Route
              exact
              path="/settings/subscription"
              component={SubscriptionIndex}
            />
            <Route
              path="/settings/user_managements"
              render={({ match: { url } }) => (
                <>
                  <Switch>
                    <Route
                      path={`${url}/`}
                      component={UserManagementIndex}
                      exact
                    />
                    <Route path="*" exact component={NotFoundPage} />
                  </Switch>
                </>
              )}
            />
            <Route path="/settings/teams" component={Teams} />
            <Route
              path="/settings/accounts_management"
              component={AccountsManagement}
            />
            <Route
              path="/settings/historical_request"
              component={HistoricalRequest}
            />
            <Route
              path="/settings/3rd_party_integrations"
              component={ThirdPartyIntegrationIndex}
            />
            <Route path="/settings/lucidya_api" component={LucidyaApi} />
            <Route
              path="/settings/instagram_hashtags"
              component={InstagramHashtags}
            />
            <Route
              path="/settings/inbound_routing"
              component={
                handlelUserRoles("ENGAGEMENTS", "ACCESS_ENGAGEMENT_SYSTEM") &&
                (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                  handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX"))
                  ? InboundedRouting
                  : NotFoundPage
              }
              exact
            />
            <Route path="*" exact component={NotFoundPage} />
          </Switch>
        </Container>
      </Grid>
    </Grid>
  );
};
export default SettingsPage;
