import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index.js";
import DateRangePicker from "../../../shared/dateRangePicker/dateRangePicker.js";
import "./userActivitesHeader.scss";

const UserActivitesHeader = (props) => {
  const intl = useIntl();
  return (
    <Box container className="activites-header">
      <Box>
        <Typography className="activites-title" variant="h6">
          {CheckValueLocale(props.title, "", {}, intl)}
        </Typography>
      </Box>

      <Box className="audience-date">
        <DateRangePicker
          applyFilter={props.applyFilter}
          startDate={props.startDate}
          endDate={props.endDate}
          openCustomDateLeft={true}
          datePickerPosition="monitorheader-datepicker-position"
          id="date-range-picker-audience-activities"
          monitorActiveTabId="audience-activities"
          allTimeEnabled
          allTimeStartDate={props?.allTimeStartDate}
        />
      </Box>
    </Box>
  );
};

export default UserActivitesHeader;
