export const AUTH_FAILED = "auth_failed";
export const TRY_AGAIN = "try_again";
export const EDIT_SUCCESS = "edit_success";
export const UNCOMPLETED_DATA = "data_not_completed";
export const UNVALID_PASSWORD = "password_not_valid";
export const CONNECTION_FAILED = "connection_failed";
export const UN_AUTH_SUCCESS = "un_auth_success";
export const RE_AUTH_SUCCESS = "reauthenticate_success_msg";
export const RE_AUTH_FAILED = "reauthenticate_error_msg";
export const ACCOUNT_AUTH_SUCCESS = "authenticate_success_msg";
export const ACCOUNT_AUTH_FAILED = "authenticate_error_msg";
export const ACCOUNT_AUTH_PAGE_MISSING = "authenticate_error_page_missing_msg";
