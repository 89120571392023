import React, { useState, useEffect } from "react";
import IntlTelInput from "react-intl-tel-input";
import { Box, FormControl, Card, CardContent, Typography } from "@mui/material";
import styles from "../commonStyle.module.css";
import "../../../surveyBuilder.scss";

const PhoneNoQuestion = (props) => {
  const { question, questionNo } = props;

  const data = question;
  const [phoneNo, setPhoneNo] = useState("");
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (question) {
      setEditable(question?.question?.editable);
    }
  }, [question]);

  const changeHandler = (e) => setPhoneNo(e.target.value);

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {data?.question?.text}
          {data?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{data?.question?.description}</Box>
        <Box className="phone-number-question phone-preview">
          <FormControl className={styles.textFieldStyle}>
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName="form-control react-tel-input phoneInput"
              separateDialCode
              defaultCountry={
                data?.question?.constraints?.country_code &&
                data?.question?.constraints?.country_code?.toLowerCase()
              }
              excludeCountries={["il"]}
              fieldName="phone_number"
              placeholder=""
              onChange={(e) => changeHandler(e)}
            />
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PhoneNoQuestion;
