import Services from "./Services";

var LiveDashboard = {};

LiveDashboard.getMonitorWidgets = (queryData) => {
  return Services.getData("api/v4/live_dashboard/monitor_widgets", queryData);
};

LiveDashboard.getMonitorLiveDashboardDetails = (queryData) => {
  return Services.getData("api/v4/live_dashboard/show", queryData);
};

LiveDashboard.getMonitorLiveDashboardPublicDetails = (queryData) => {
  return Services.getData("api/public/live_dashboard/show", queryData);
};
LiveDashboard.getMonitorLiveDashboardList = (queryData) => {
  return Services.getData("api/v4/live_dashboard/index", queryData);
};

LiveDashboard.createLiveDashboard = (queryData) => {
  return Services.postData("api/v4/live_dashboard/create", queryData);
};

LiveDashboard.editLiveDashboard = (queryData) => {
  return Services.putData(`api/v4/live_dashboard/update`, queryData);
};
LiveDashboard.deleteLiveDashboard = (queryData) => {
  return Services.deleteData("api/v4/live_dashboard/delete ", queryData);
};

LiveDashboard.getAccessTypes = (queryData) => {
  return Services.getData("api/v4/live_dashboard/access", queryData);
};

LiveDashboard.authenticationPublicLiveDashboard = (queryData) => {
  return Services.getData(
    "api/public/live_dashboard/authentication",
    queryData,
  );
};
LiveDashboard.getDatasources = () => {
  return Services.getData("api/public/live_dashboard/data_sources");
};

export default LiveDashboard;
