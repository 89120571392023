import Services from "./Services";

var Auth = {};

Auth.twitterAuth = (queryData) => {
  let is_company_account =
    queryData.is_company_account != undefined ||
    queryData.is_company_account != null
      ? `&is_company_account=${queryData.is_company_account}`
      : "";
  let url = `${queryData.oauth2_flow_init_url}?consumer_key=${
    queryData.consumer_key
  }&user_id=${window.localStorage.getItem(
    "user_id",
  )}${is_company_account}&product_id=${queryData.product_id}
  &redirection_url=${window.location.href}`;
  window.location.href = url;
};

Auth.facebookAuth = (queryData) => {
  let is_company_account =
    queryData.is_company_account != undefined ||
    queryData.is_company_account != null
      ? `&is_company_account=${queryData.is_company_account}`
      : "";
  let data_source_name =
    queryData.data_source_name != undefined ||
    queryData.data_source_name != null
      ? `&data_source_name=${queryData.data_source_name}`
      : "";
  let redirect_url = queryData?.redirect_url?.length
    ? queryData?.redirect_url
    : window.location.href;
  let url = `${queryData.oauth2_flow_init_url}?consumer_key=${
    queryData.consumer_key
  }&user_id=${window.localStorage.getItem(
    "user_id",
  )}${is_company_account}&product_id=${queryData.product_id}
  &redirection_url=${redirect_url}${data_source_name}`;
  window.location.href = url;
};
Auth.gmailAuth = (queryData) => {
  let is_company_account = queryData.is_company_account
    ? `&is_company_account=${queryData.is_company_account}`
    : "";
  let url = ` ${queryData.oauth2_flow_init_url}?consumer_key=${
    queryData.consumer_key
  }&user_id=${window.localStorage.getItem(
    "user_id",
  )}${is_company_account}&product_id=${queryData.product_id}
  &redirection_url=${window.location.href}`;
  window.location.href = url;
};

//GMB
Auth.gmbAuth = (queryData) => {
  let is_company_account = queryData.is_company_account
    ? `&is_company_account=${queryData.is_company_account}`
    : "";
  let url = ` ${queryData.oauth2_flow_init_url}?consumer_key=${
    queryData.consumer_key
  }&user_id=${window.localStorage.getItem(
    "user_id",
  )}${is_company_account}&data_source_name=googlemybusiness&product_id=${
    queryData.product_id
  }
  &redirection_url=${window.location.href}`;
  window.location.href = url;
};

Auth.interComAuth = (queryData) => {
  return Services.postData(
    "api/v3/auth/third_party_integrations/intercom",
    queryData,
  );
};

//whatspp auth with [ Twilio ]
Auth.whatsappAuth = (queryData) => {
  return Services.postData(
    "api/v3/channel_analytics/chat/whatsapp/email",
    queryData,
  );
};

Auth.linkedInAuth = (queryData) => {
  let companyAccount =
    queryData.companyAccount != undefined || queryData.companyAccount != null
      ? `&is_company_account=${queryData.companyAccount}`
      : "";

  let url = `${queryData.oauth2_flow_init_url}?consumer_key=${
    queryData.consumer_key
  }&user_id=${window.localStorage.getItem(
    "user_id",
  )}${companyAccount}&data_source_name=${queryData.data_source}&product_id=${
    queryData.product_id
  }&redirect_uri=${queryData.redirectTo}&redirection_url=${
    window.location.href
  }`;

  window.location.href = url;
};

Auth.unAuthAccount = (data_source, id, queryData) => {
  return Services.deleteData(`auth/${data_source}/${id}`, queryData);
};

Auth.getGenesysRegions = (queryData) => {
  return Services.getData("api/v3/auth/calls/genesys/regions", queryData);
};
Auth.getGenesysInformer = (queryData) => {
  return Services.getData("api/v3/auth/calls/genesys/informer", queryData);
};
Auth.genesysIntegrations = (queryData) => {
  return Services.postData(
    "api/v3/auth/third_party_integrations/integrations",
    queryData,
  );
};
Auth.genesysLastStepAuth = (queryData) => {
  return Services.postData("api/v3/auth/calls/genesys", queryData);
};

Auth.tiktokAuth = (queryData) => {
  return Services.getData("api/v3/auth/social_media/tiktok", queryData);
};

export default Auth;
