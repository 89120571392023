import { useIntl } from "react-intl";
import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const EditReportStepOne = ({
  setReportName,
  reportName,
  reportData,
  fieldError,
}) => {
  const intl = useIntl();
  return (
    <Grid container spacing={2.5}>
      <Grid item lg={6} xl={6}>
        <Box className="text-field">
          <InputLabel>
            {CheckValueLocale("edit_report_name", "", {}, intl)}
          </InputLabel>
          <TextField
            fullWidth
            name="reportName"
            value={reportName}
            onChange={(e) => setReportName(e?.target?.value)}
            error={fieldError?.reportName ? true : false}
          />
          {fieldError?.reportName && (
            <Grid item xs={12}>
              <FormHelperText error>{fieldError?.reportName}</FormHelperText>
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item lg={6} xl={6}>
        <Box className="text-field">
          <InputLabel disabled={true}>
            {CheckValueLocale("edit_report_platform", "", {}, intl)}
          </InputLabel>
          <Select fullWidth defaultValue={""} displayEmpty disabled={true}>
            <MenuItem
              value=""
              disabled
              className="report-menu-items"
              id="report-edit-sm"
            >
              {CheckValueLocale(
                `reports_${reportData?.attributes?.platform}`,
                "",
                {},
                intl,
              )}
            </MenuItem>
          </Select>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditReportStepOne;
