import { useState, useEffect } from "react";
import MonitorsController from "services/controllers/monitorsController";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CheckValueLocale, convertXPlateformToTwitter } from "utils/helpers";
import { useIntl } from "react-intl";

function IconFunctions() {
  const urlParams = useParams();
  const monitorDataSource = convertXPlateformToTwitter(
    urlParams?.monitorDataSource,
  );
  const intl = useIntl();
  const [snackBarData, setSnackBarData] = useState({
    message: "",
    severity: "",
    title: "",
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleCloseSnack = () => {
    setOpenSnackBar(false);
  };

  const postSpamTweets = (question, data_source) => {
    MonitorsController.markSpamPost(
      urlParams?.monitorId
        ? parseInt(urlParams?.monitorId)
        : parseInt(question?.tracker_id),
      monitorDataSource
        ? monitorDataSource === "NEWSBLOGS"
          ? "talkwalker"
          : monitorDataSource?.toLowerCase()
        : data_source && data_source === "NEWSBLOGS"
          ? "talkwalker"
          : data_source?.toLowerCase(),
      parseInt(window.localStorage.sm_id),
      monitorDataSource === "TWITTER" || data_source === "TWITTER"
        ? question?.tweet_id
        : question?.id,
    ).then((response) => {
      if (response?.data && response?.data?.status === 200) {
        setSnackBarData({
          message: "post_marked_as_spam",
          severity: "success",
          title: "",
        });
        setOpenSnackBar(true);
      } else if (response?.errorMsg?.response?.status >= 400) {
        setSnackBarData({
          title: CheckValueLocale("unable_to_mark_as_spam", "", {}, intl),
          message: CheckValueLocale("api_mark_as_spam_error", "", {}, intl),
          severity: "error",
        });
        setOpenSnackBar(true);
      }
    });
  };

  const handleSpamPost = (
    question,
    setSpamLoading,
    setIsSpam,
    datasourcePostsType,
  ) => {
    setSpamLoading(true);
    MonitorsController.markSpamPost(
      urlParams?.monitorId
        ? parseInt(urlParams?.monitorId)
        : parseInt(question?.tracker_id),
      monitorDataSource
        ? monitorDataSource === "NEWSBLOGS"
          ? "talkwalker"
          : monitorDataSource?.toLowerCase()
        : datasourcePostsType && datasourcePostsType === "NEWSBLOGS"
          ? "talkwalker"
          : datasourcePostsType?.toLowerCase(),
      parseInt(window.localStorage.sm_id),
      monitorDataSource === "TWITTER" || datasourcePostsType === "TWITTER"
        ? question?.tweet_id
        : question?.id,
    ).then((response) => {
      if (response?.data && response?.data?.status === 200) {
        setSpamLoading(false);
        setIsSpam(true);
      } else if (response?.errorMsg?.response?.status >= 400) {
        setSpamLoading(false);
        setSnackBarData({
          title: CheckValueLocale("unable_to_mark_as_spam", "", {}, intl),
          message: CheckValueLocale("api_mark_as_spam_error", "", {}, intl),
          severity: "error",
        });
        setOpenSnackBar(true);
      }
    });
  };

  const handleUndoSpam = (question, setIsSpam, datasourcePostsType) => {
    MonitorsController.undoSpamPost(
      urlParams?.monitorId
        ? parseInt(urlParams?.monitorId)
        : parseInt(question?.tracker_id),
      monitorDataSource
        ? monitorDataSource === "NEWSBLOGS"
          ? "talkwalker"
          : monitorDataSource?.toLowerCase()
        : datasourcePostsType && datasourcePostsType === "NEWSBLOGS"
          ? "talkwalker"
          : datasourcePostsType?.toLowerCase(),
      parseInt(window.localStorage.sm_id),
      monitorDataSource === "TWITTER" || datasourcePostsType === "TWITTER"
        ? question?.tweet_id
        : question?.id,
    ).then((response) => {
      if (response?.data && response?.data?.status === 200) {
        setIsSpam(false);
      } else if (response?.errorMsg?.response?.status >= 400) {
        setSnackBarData({
          message: CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          ),
          severity: "error",
          title: "",
        });
        setOpenSnackBar(true);
      }
    });
  };

  const getMoreInsights = (
    post,
    insightsResponse,
    insightsIDs,
    insightsDates,
    isUpdate,
    isEmbedCode,
    dashboardDatasource,
  ) => {
    let duplicateResp = insightsResponse;
    let duplicateIds = insightsIDs;
    let duplicateDates = insightsDates;
    const id = !isEmbedCode
      ? post.tweet_id
      : monitorDataSource === "FACEBOOK" || dashboardDatasource === "FACEBOOK"
        ? post.post_id
        : post.id;
    setLoader(true);
    MonitorsController.getMoreInsights(
      monitorDataSource ? monitorDataSource : dashboardDatasource,
      urlParams.monitorId ? urlParams.monitorId : post?.tracker_id,
      id,
      window.localStorage.sm_id,
    ).then((resp) => {
      if (resp.data.status === 200) {
        if (!isUpdate) {
          if (!insightsIDs?.includes(post.tweet_id) && !isEmbedCode) {
            duplicateIds.push(post.tweet_id);
          } else {
            if (
              monitorDataSource === "FACEBOOK" ||
              dashboardDatasource === "FACEBOOK"
            ) {
              if (!insightsIDs?.includes(post.post_id)) {
                duplicateIds.push(post.post_id);
              }
            } else {
              if (!insightsIDs.includes(post.id)) {
                duplicateIds.push(post.id);
              }
            }
          }
        } else {
          let index;
          if (!isEmbedCode) {
            index = duplicateIds?.indexOf(post.tweet_id);
            duplicateIds.splice(index, 1);
            duplicateIds.push(post.tweet_id);
            duplicateResp.splice(index, 1);
            duplicateDates.splice(index, 1);
          } else {
            if (
              monitorDataSource === "FACEBOOK" ||
              dashboardDatasource === "FACEBOOK"
            ) {
              index = duplicateIds?.indexOf(post.post_id);
              duplicateIds.splice(index, 1);
              duplicateIds.push(post.post_id);
              duplicateResp.splice(index, 1);
              duplicateDates.splice(index, 1);
            } else {
              index = duplicateIds?.indexOf(post.id);
              duplicateIds.splice(index, 1);
              duplicateIds.push(post.id);
              duplicateResp.splice(index, 1);
              duplicateDates.splice(index, 1);
            }
          }
        }
        duplicateResp.push(resp.data.data);
        duplicateDates.push(moment().format("DD/MM/YYYY"));
        localStorage.setItem("moreInsightsResp", JSON.stringify(duplicateResp));
        localStorage.setItem("moreInsightsIds", JSON.stringify(duplicateIds));
        localStorage.setItem(
          "moreInsightsDates",
          JSON.stringify(duplicateDates),
        );
        setSnackBarData({
          message: "request_sent_successfully",
          severity: "success",
          title: "",
        });
        setOpenSnackBar(true);
      } else if (
        resp?.errorMsg?.response?.status > 399 &&
        resp?.errorMsg?.response?.status < 430
      ) {
        setSnackBarData({
          title: resp.errorMsg.response?.data.error?.detail?.toLowerCase(),
          message: "try_again",
          severity: "error",
        });
        setOpenSnackBar(true);
      }
      setLoader(false);
    });
  };
  return {
    snackBarData,
    openSnackBar,
    handleCloseSnack,
    postSpamTweets,
    handleSpamPost,
    handleUndoSpam,
    getMoreInsights,
    loader,
  };
}

export default IconFunctions;
