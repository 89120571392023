import { Box, Tooltip, Button } from "@mui/material";
import { useIntl } from "react-intl";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../navigationSidebar/navigationContent.scss";
import NavigationSidebar from "../navigationSidebar/navigationContent";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentMedical, faFilter } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import LucButton from "shared/lucButton/lucButton";
import { useState, useEffect } from "react";

const HeadingSidebar = ({
  handleClickReload,
  handleSettings,
  setOpenNavigationMenu,
  title,
  subTitle,
  openNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  showReload,
  isDoneSetup,
  setOpenNewConversation,
  startDate,
  endDate,
  handleDatePicker,
  setOpenFilters,
  openFilters,
  filtersChangesCount,
}) => {
  const intl = useIntl();
  const [haveMoreThanOne, setHaveMoreThanOne] = useState(false);

  useEffect(() => {
    setHaveMoreThanOne(false);
    if (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS")) {
      setHaveMoreThanOne(true);
    } else {
      let checkArr = [];

      handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") &&
        checkArr.push("PUBLISH");

      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") && checkArr.push("INBOX");

      handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS") &&
        checkArr.push("ENGAGEMENT_ANALYTICS");

      var activeProducts = JSON.parse(
        window.localStorage.activeProducts || null,
      );
      let aiAgentActive = activeProducts?.find(
        (product) => product?.name == "AI_AGENT",
      );

      handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS") &&
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
        aiAgentActive &&
        checkArr.push("AI_AGENT");
      if (checkArr?.length > 1) {
        setHaveMoreThanOne(true);
      }
    }
  }, []);

  return (
    <>
      <Box
        id="engagements-inbox-btn"
        className="heading-refrsh"
        onClick={() => haveMoreThanOne && setOpenNavigationMenu(true)}
      >
        <NavigationSidebar
          title={title}
          subTitle={subTitle}
          openNavigationMenu={openNavigationMenu}
          navigationMenuStatus={navigationMenuStatus}
          setNavigationMenuStatus={setNavigationMenuStatus}
          notOpened={!haveMoreThanOne}
        />
      </Box>
      <Box className="refresh-setting-container">
        {navigationMenuStatus == "inbox" &&
        (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
          handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")) ? (
          <Tooltip
            title={CheckValueLocale("start_new_conversation", "", {}, intl)}
            arrow
            placement="bottom"
          >
            <Button
              onClick={() => setOpenNewConversation(true)}
              variant="contained"
              className="add-new-conv-btn"
            >
              <FontAwesomeIcon icon={faCommentMedical} />
            </Button>
          </Tooltip>
        ) : null}
        {navigationMenuStatus !== "inbox" ? (
          <Tooltip
            title={CheckValueLocale("inbox_refresh", "", {}, intl)}
            arrow
            placement="bottom"
          >
            <RefreshIcon
              id="engagements-refresh-btn"
              className="refrsh-icon publish-menu"
              onClick={() => handleClickReload()}
            />
          </Tooltip>
        ) : null}
        {handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") ? (
          isDoneSetup ? (
            <Tooltip
              title={CheckValueLocale("engagm_setting", "", {}, intl)}
              arrow
              placement={"bottom"}
            >
              <SettingsIcon
                className={
                  navigationMenuStatus === "inbox"
                    ? "engagement-settings-icon"
                    : "engagement-settings-icon publish-menu"
                }
                onClick={handleSettings}
              />
            </Tooltip>
          ) : (
            <SettingsIcon
              className={
                navigationMenuStatus === "inbox"
                  ? "engagement-settings-icon"
                  : "engagement-settings-icon publish-menu"
              }
              onClick={handleSettings}
            />
          )
        ) : null}
        {navigationMenuStatus === "inbox" ? (
          <>
            <Box className="header-divider"></Box>
            <LucButton
              id="engagements-filter-btn"
              variant="outline"
              onClick={() => setOpenFilters(!openFilters)}
              size="small"
              type="secondary"
              startIcon={<FontAwesomeIcon icon={faFilter} />}
            >
              {CheckValueLocale("filters", "", {}, intl)}
              <span className="engagement-filter-count">
                {filtersChangesCount ? `(${filtersChangesCount})` : ""}
              </span>
            </LucButton>
            <Box id="engagements-data-picker">
              <DateRangePicker
                applyFilter={(startDate, endDate) =>
                  handleDatePicker(startDate, endDate)
                }
                startDate={startDate}
                endDate={endDate}
                openCustomDateLeft={true}
                smallBoxCustom="engagement-custom-date-picker "
              />
            </Box>
          </>
        ) : null}
      </Box>
    </>
  );
};

export default HeadingSidebar;
