import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "../.././surveyInsights.scss";
import PieChartWidget from "components/widgets/pieChartWidget";
import SocialAccountQuestion from "./socialAccountQuestion";
import _ from "lodash";
import BarChartWidget from "components/widgets/barChartWidget";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { CheckValueLocale, pieChartSort } from "utils/helpers";
import { useEditAnswerThemes } from "pages/survey/hooks/useEditAnswerThemes";
import { useEditAnswerCategories } from "pages/survey/hooks/useEditAnswerCategories";
import { useEditAnswerSentiment } from "pages/survey/hooks/useEditAnswerSentiment";

const OneQuestionsData = (props) => {
  const intl = useIntl();
  const [data, setData] = useState();
  const [answersSentiments, setAnswersSentiments] = useState([]);
  const [answersCategories, setAnswersCategories] = useState([]);
  const [answersThemes, setAnswersThemes] = useState([]);

  useEffect(() => {
    if (props?.selectedQuestion?.type === "short_text") {
      // set sentiments
      const sentiments = props?.selectedQuestion?.answer_list?.map(
        (answer) => answer?.sentiment,
      );
      setAnswersSentiments(sentiments);

      // set categories
      const categories = props?.selectedQuestion?.answer_list?.map(
        (answer) => answer?.themes,
      );
      setAnswersCategories(categories);

      // set themes
      const themes = props?.selectedQuestion?.answer_list?.map(
        (answer) => answer?.sub_themes,
      );
      setAnswersThemes(themes);
    }
  }, [props?.selectedQuestion]);

  const getSurveyName = (name) => {
    let result = "";
    if (name === "7" || name === "8") {
      result = "passive";
    } else if (name === "9" || name === "10") {
      result = "promoters";
    } else {
      result = "detractors";
    }
    return result;
  };

  const colorsSurvey = {
    "": "#E85954",
    0: "#E85954",
    1: "#E85954",
    2: "#E85954",
    3: "#E85954",
    4: "#E85954",
    5: "#E85954",
    6: "#E85954",
    7: "#F5B819",
    8: "#F5B819",
    9: "#9FC653",
    10: "#9FC653",
  };

  const answers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  useEffect(() => {
    handleQuestions();
  }, [
    props?.selectedQuestion,
    answersSentiments,
    answersCategories,
    answersThemes,
  ]);

  let valueExist = props?.selectedQuestion?.answer_list?.nps_values?.nps?.every(
    (val) => val.value === 0,
  );
  let nameExist = props?.selectedQuestion?.answer_list?.nps_values?.nps?.every(
    (val) => val.name === null,
  );

  const { mutate: editSentiment, isPending: editSentimentPending } =
    useEditAnswerSentiment();

  const handleEditSentiment = (sentimentId, index, answerId) => {
    editSentiment(
      {
        queryData: {
          id: answerId,
          survey_id: props?.id,
          old_label: answersSentiments?.[index],
          new_label: sentimentId,
          product_id: props?.productId,
        },
      },
      {
        onSuccess: () => {
          let newSentiments = [...answersSentiments];
          newSentiments[index] = sentimentId;
          setAnswersSentiments(newSentiments);
        },
      },
    );
  };

  const { mutate: editCategories, isPending: editCategoriesPending } =
    useEditAnswerCategories();

  const handleEditCategories = (updatedCategories, index, answerId) => {
    editCategories(
      {
        queryData: {
          id: answerId,
          survey_id: props?.id,
          old_label: answersCategories?.[index] || [],
          new_label: updatedCategories,
          product_id: props?.productId,
        },
      },
      {
        onSuccess: () => {
          let newCategories = [...answersCategories];
          newCategories[index] = updatedCategories;
          setAnswersCategories(newCategories);
        },
      },
    );
  };

  const { mutate: editThemes, isPending: editThemesPending } =
    useEditAnswerThemes();

  const handleEditThemes = (updatedThemes, index, answerId) => {
    editThemes(
      {
        queryData: {
          id: answerId,
          survey_id: props?.id,
          old_label: answersThemes?.[index] || [],
          new_label: updatedThemes,
          product_id: props?.productId,
        },
      },
      {
        onSuccess: () => {
          let newThemes = [...answersThemes];
          newThemes[index] = updatedThemes;
          setAnswersThemes(newThemes);
        },
      },
    );
  };

  const handleQuestions = () => {
    let item;
    switch (props?.selectedQuestion?.type) {
      case "short_text":
        item = props?.selectedQuestion?.answer_list?.map((answer, i) => {
          return (
            answer?.value !== "" && (
              <Box className="single-text-answers-parant" key={i}>
                <Box className="single-text-answers">{answer?.value}</Box>
                <Box className="survey-sentiment-categories-themes-container">
                  <SentimentCategoriesThemes
                    themesData={{
                      btnId: "survey-answer-themes-btn",
                      options: props?.themesOptions,
                      selected: answersThemes?.[i],
                      handleChange: (updatedThemes) =>
                        handleEditThemes(updatedThemes, i, answer?.answer_id),
                      loading: editThemesPending,
                    }}
                    categoriesData={{
                      btnId: "survey-answer-categories-btn",
                      options: props?.categoriesOptions,
                      selected: answersCategories?.[i],
                      handleChange: (updatedCategories) =>
                        handleEditCategories(
                          updatedCategories,
                          i,
                          answer?.answer_id,
                        ),
                      loading: editCategoriesPending,
                    }}
                    sentimentData={{
                      btnId: "survey-answer-sentiment-btn",
                      options: props?.sentimentsOptions,
                      selected: answersSentiments?.[i],
                      handleChange: (sentimentId) =>
                        handleEditSentiment(sentimentId, i, answer?.answer_id),
                      loading: editSentimentPending,
                    }}
                    dividers={[1, 2]}
                  />
                </Box>
              </Box>
            )
          );
        });
        break;
      case "email":
      case "number":
      case "phone_number":
        item = props?.selectedQuestion?.answer_list?.map((answer, i) => {
          return (
            answer !== "" && (
              <Box className="single-text-answer" key={i}>
                {answer}
              </Box>
            )
          );
        });
        break;
      case "name":
        item = props?.selectedQuestion?.answer_list?.map((answer, i) => {
          return (
            answer !== "" && (
              <Box className="single-text-answer" key={i}>
                {answer?.first_name} {answer?.last_name}
              </Box>
            )
          );
        });
        break;
      case "dropdown":
      case "multiple_choice":
        item = (
          <Box className="pie-chart-question">
            <PieChartWidget
              data={props?.selectedQuestion?.answer_list}
              pieChartPreLoaderDataSources={["questions"]}
              preLoaderTrackerDataSources={["questions"]}
              legendPosition={"beside"}
              flagTitle={"surveyTitle"}
              showLabel
              customStyle
            />
          </Box>
        );
        break;
      case "yes_no":
        item = (
          <Box className="pie-chart-question">
            <PieChartWidget
              data={props?.selectedQuestion?.answer_list}
              pieChartPreLoaderDataSources={["questions"]}
              preLoaderTrackerDataSources={["questions"]}
              legendPosition={"beside"}
              showLabel
              solidPie
              customStyle
            />
          </Box>
        );
        break;
      case "social_media":
        item = (
          <SocialAccountQuestion data={props?.selectedQuestion?.answer_list} />
        );
        break;
      case "nps":
        item = (
          <>
            {props?.isSummary ? (
              <Box className="nps-question">
                <Box className="item-nps">
                  <div className="nps-item-parant">
                    {_.isEqual(
                      props?.questionsPreLoader?.["questions"],
                      props?.preLoaderTrackerDataSources?.["questions"],
                    ) &&
                      props?.selectedQuestion?.answer_list?.nps_values?.nps && (
                        <>
                          {!valueExist &&
                            !nameExist &&
                            props?.selectedQuestion?.answer_list?.nps_values
                              ?.nps !== undefined && (
                              <Box className="nps-item">
                                <Typography>
                                  {CheckValueLocale("nps_widget", "", {}, intl)}
                                </Typography>
                                <Typography component={"h4"}>
                                  {props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString() !==
                                    "0" &&
                                  props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString() !==
                                    "0.00"
                                    ? props?.selectedQuestion?.answer_list?.nps_values?.nps_score
                                        ?.toString()
                                        ?.startsWith("-")
                                      ? props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString()
                                      : "+" +
                                        props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString()
                                    : 0}
                                </Typography>
                              </Box>
                            )}

                          <PieChartWidget
                            title={"nps_tradmark"}
                            titleToolTip="nps_tooltip"
                            data={pieChartSort(
                              props?.selectedQuestion?.answer_list?.nps_values
                                ?.nps,
                            )}
                            toolTipLabel={"toolTipLabel"}
                            areaStyle={true}
                            pieChartPreLoaderDataSources={["questions"]}
                            preLoaderTrackerDataSources={["questions"]}
                          />
                        </>
                      )}
                  </div>
                </Box>

                <Box className="item-nps-chart">
                  <BarChartWidget
                    title={"response_by_Score"}
                    showDownloadIcon={false}
                    getSurveyName={getSurveyName}
                    colorsSurvey={colorsSurvey}
                    data={
                      props?.selectedQuestion?.answer_list?.nps_score
                        ?.response_by_score
                    }
                    barChartPreLoaderDataSources={["questions"]}
                    preLoaderTrackerDataSources={["questions"]}
                    applyAxisFormatter=" %{value}"
                    isSurveyInsights
                    isSurvey
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                {props?.selectedQuestion?.answer_list?.nps_score?.response_by_score?.map(
                  (key, index) => {
                    return (
                      <Box key={index}>
                        {key?.value !== "" && (
                          <Box className="answers-box">
                            <Box>{props?.selectedQuestion?.question}</Box>
                            <Box className="radio-item-individual">
                              <Box className="radio-item ">
                                {answers?.map((item, index) => {
                                  return (
                                    <label className="radio-item" key={index}>
                                      <input
                                        disabled
                                        type="radio"
                                        name={
                                          "colors" +
                                          index +
                                          props?.selectedQuestion?.question_id
                                        }
                                        checked={index === +key.name}
                                      />
                                      <Typography
                                        component={"span"}
                                        className={
                                          props?.selectedQuestion?.answer_list
                                            ?.coloured
                                            ? index === 7 || index === 8
                                              ? "choose yellow"
                                              : index === 9 || index === 10
                                                ? "choose green"
                                                : "choose red"
                                            : "choose"
                                        }
                                      >
                                        <Box>{item}</Box>
                                      </Typography>
                                    </label>
                                  );
                                })}
                              </Box>
                              <Box className="most-like">
                                <Typography>
                                  {CheckValueLocale("unlikely", "", {}, intl)}
                                </Typography>
                                <Typography>
                                  {CheckValueLocale(
                                    "very_likely",
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Box className="answers-header">
                                {key?.value}{" "}
                                {CheckValueLocale("response", "", {}, intl)}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    );
                  },
                )}
              </Box>
            )}
          </>
        );
        break;
      case "scale":
        item = (
          <Box className="nps-question">
            <Box className="item-scale-chart">
              {props?.selectedQuestion?.answer_list !== undefined
                ? props?.selectedQuestion?.answer_list[0]?.scale?.length >
                    0 && (
                    <BarChartWidget
                      title={""}
                      showDownloadIcon={false}
                      getSurveyName={getSurveyName}
                      dataColor="#2AAAE2"
                      colorsSurvey={colorsSurvey}
                      data={props?.selectedQuestion?.answer_list[0]?.scale}
                      barChartPreLoaderDataSources={["questions"]}
                      preLoaderTrackerDataSources={["questions"]}
                      applyAxisFormatter=" %{value}"
                      isSurveyInsights
                      isScaleQues={true}
                    />
                  )
                : null}
              <Box className="values-text" mx={4}>
                <Typography component="p">
                  {props?.selectedQuestion?.answer_list[0]?.low_value}
                </Typography>
                <Typography component="p">
                  {props?.selectedQuestion?.answer_list[0]?.high_value}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
        break;

      default:
        break;
    }
    setData(item);
  };
  return <Box>{data}</Box>;
};
export default OneQuestionsData;
