import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Switch,
} from "@mui/material";
import { useIntl } from "react-intl";
import KeywordsInputComponent from "pages/socialListing/MonitorsOptions/createMonitor/MonitorTypes/KeywordsMonitor/dataSourcesComponents/newBlogsKeyword/components/sourcesInputComponent/keywordsInputComponent";
import { CheckValueLocale } from "utils/helpers";

const SourcesSection = (props) => {
  const intl = useIntl();
  const {
    selectedSource,
    setSelectedSource,
    setMajorSource,
    setNbExcludedSource,
    nbExcludedSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    updatedParams,
    setUpdatedParams,
  } = props;

  const handleChangeDuplicated = (e) => {
    let value = e.target.checked;
    setNbDuplicated(value);
    setUpdatedParams({ ...updatedParams, nb_duplicated: value });
  };

  return (
    <>
      <Box className="section-box-data-setting">
        <Typography className="main-titles">
          {CheckValueLocale("sources", "", {}, intl)}
        </Typography>
        <RadioGroup
          value={selectedSource === "major_sources" ? "major" : "all"}
          name="radio-buttons-group"
          className="sources-radio-btn"
        >
          <FormControlLabel
            value="all"
            control={<Radio />}
            label={CheckValueLocale("all_sources", "", {}, intl)}
            onClick={() => {
              setSelectedSource("all_sources");
              setMajorSource([]);
              setNbExcludedSource(nbExcludedSource);
              setNbRanks([1, 2, 3, 4, 5]);
              setUpdatedParams({
                ...updatedParams,
                nb_sources: "*",
                nb_ranks: "1,2,3,4,5",
              });
            }}
          />
          <FormControlLabel
            value="major"
            control={<Radio />}
            label={CheckValueLocale("custom_sources", "", {}, intl)}
            onClick={() => {
              setSelectedSource("major_sources");
              setNbExcludedSource([]);
              setNbRanks([]);
              setUpdatedParams({
                ...updatedParams,
                nb_ranks: "",
              });
            }}
          />
        </RadioGroup>
      </Box>

      {/* shared Component with Create Monitor File */}
      <KeywordsInputComponent {...props} monitorSetting />

      {/* Duplicated Articles Switch */}
      <Divider />
      <Box className="section-box-data">
        <Typography className="main-titles">
          {CheckValueLocale("dublicates", "", {}, intl)}
        </Typography>
        <Box className="include-articles-container">
          <Typography className="title">
            {CheckValueLocale("include_duplicated_articles", "", {}, intl)}
            <Switch
              id="monitor-settings-duplicated-articles"
              size="small"
              checked={nbDuplicated}
              onChange={(e) => handleChangeDuplicated(e)}
            />
          </Typography>
          <Typography className="description">
            {CheckValueLocale(
              "include_duplicated_articles_description",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SourcesSection;
