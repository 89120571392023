import { useQuery } from "@tanstack/react-query";
import InteractionsController from "services/controllers/interactionsController";
import LiveDashboardController from "services/controllers/liveDashboardController";
export const useGetDataSources = () => {
  return useQuery({
    queryKey: ["dataSourcesList"],
    queryFn: () => {
      if (window?.location?.href?.includes("/live-dashboard/public/")) {
        return LiveDashboardController.getDatasources();
      } else {
        return InteractionsController.getDatasources();
      }
    },
    select: (data) => data?.data?.data,
    retry: false,
  });
};
