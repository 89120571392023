import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MonitorsController from "services/controllers/monitorsController";
import CompaniesController from "services/controllers/companiesController";
import UserAccount from "services/controllers/userAccountController";
import AuthController from "services/controllers/authController";
import { useIntl } from "react-intl";
import { isEmptyArray } from "utils/helpers";
import {
  removeCrossedKeywords,
  checkKewordsCrossed,
} from "../../MonitorsShared/SharedFunctions/components/crossedKeywords";
import {
  getPublicAccountDatasourcesId,
  getMonitorTypeSelected,
  getActiveDatasourceName,
  getDataPublicAccountParams,
  getTopicsData,
} from "./getDataCreateMonitor";
import { useSelector } from "react-redux";
import CreateMonitorController from "services/controllers/createMonitorController";

const CreateMonitorCommonFuntions = () => {
  const intl = useIntl();
  const history = useHistory();
  //redux: handle data from explore page
  const { monitorType, activeMonitorStep } = useSelector(
    (state) => state?.explorePageSlice,
  );
  const steps = ["monitor_type", "setup_monitor"];
  const [activeStep, setActiveStep] = useState(
    activeMonitorStep ? activeMonitorStep : 0,
  );
  const [completed, setCompleted] = useState({});
  const [selectedMonitorType, setSelectedMonitorType] = useState(
    monitorType ? monitorType : "MANAGED_PAGE",
  );
  const [activeDataSourceId, setActiveDataSourceId] = useState("");
  const [errorMsgMonitor, setErrorMsgMonitor] = useState("");
  const [isClikedFb, setIsClikedFb] = useState(false);
  const [isClikedIg, setIsClikedIg] = useState(false);
  // __________________________________________________________
  //These functions are for handleing back/next steps
  const handleNext = () => {
    handleComplete();
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };
  //These functions are for handleing complete steps of create new monitor
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  //topics data
  const [monitorTopics, setMonitorTopics] = useState([
    {
      name: "",
      keywords: [],
      exclude_keywords: [],
    },
  ]);
  const [errorKeywordsMsg, setErrorKewyordsMsg] = useState({
    i: "",
    errorMsg: "",
  });
  const [errorExKeywordsMsg, setExErrorKewyordsMsg] = useState({
    i: "",
    errorMsg: "",
  });
  const [displayNameMsgError, setDisplayNameMsgError] = useState({
    i: "",
    errorMsg: "",
  });

  // __________________________________________________________

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSelectList, setIsLoadingSelectList] = useState(false);
  const [companyAllowedSources, setCompanyAllowedSources] = useState([]);
  const [twitterLanguagesAndCountries, setTwitterLanguagesAndCountries] =
    useState(null);
  const [monitorData, setMonitorData] = useState({
    monitorName: "",
    monitorDescription: "",
    userName: "",
    pageName: "",
    cstCareAccount: "",
    spamKeywords: [],
    ignoreTweets: [],
    languages: [],
    twitterLanguages: [],
    twitterCountries: [],
    auto_summary_widget: false,
  });
  const [monitorErrors, setMonitorErrors] = useState({
    monitorName: "",
    userName: "",
    pageName: "",
    cstCareAccount: "",
    spamKeywords: "",
    monitorDescription: "",
  });
  const [createMonitorBtn, setCreateMonitorBtn] = useState(false);
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [allSocialAccounts, setAllSocialAccounts] = useState([]);
  const [companyMonitorTypes, setCompanyMonitorTypes] = useState([]);
  const [reverseMonitorTypes, setReverseCompanyMonitorTypes] = useState([]);
  const [authorizedAccounts, setAuthorizedAccounts] = useState({
    twitterAccounts: [],
    facebookAccounts: [],
    instagramAccounts: [],
  });
  const [facebookPages, setFacebookPages] = useState([]);
  //__________________________________________________________
  // This Function will used in create New Monitors pages
  const getCompanySources = () => {
    setIsLoading(true);
    MonitorsController.getCompanySources().then((data) => {
      if (data) {
        setCompanyAllowedSources(data?.data?.company_allowed_sources?.data);
      }
      setIsLoading(false);
    });
  };
  //__________________________________________________________
  // This Function will used in create New Monitors pages
  const getCompanyMonitorTypes = () => {
    setIsLoading(true);
    CompaniesController.getCompanyMonitorTypes().then((data) => {
      if (data) {
        setCompanyMonitorTypes(data?.data);
        setReverseCompanyMonitorTypes(data?.data.reverse());
      }
      setIsLoading(false);
    });
  };
  //______________________________________
  //Make the first monitor type selected by default
  // change selectedMonitorType according to first monitor type
  useEffect(() => {
    if (reverseMonitorTypes?.length > 0) {
      setSelectedMonitorType(
        monitorType ? monitorType : reverseMonitorTypes[0].attributes.name[0],
      );
    }
  }, [reverseMonitorTypes?.length]);

  //__________________________________________________________
  //get all social accounts [ twitter , instgram , facebook ]
  var accounts = [];
  var allAccounts = [];
  const getSocialAccounts = (id) => {
    setSocialAccounts([]);
    setIsLoading(true);
    MonitorsController.getSocialAccounts(id).then((data) => {
      data?.included?.map((account) => {
        if (isEmptyArray(account?.attributes?.used_in_which_monitor)) {
          accounts.push(account);
          setSocialAccounts(accounts);
        }
      });
      data?.data?.map((account) => {
        allAccounts.push(account);
        setAllSocialAccounts(allAccounts);
      });
      setIsLoading(false);
    });
  };

  const getUserSocialAccounts = (id) => {
    setSocialAccounts([]);
    setIsLoading(true);
    MonitorsController.getUserSocialAccounts(id).then((data) => {
      data?.included?.map((account) => {
        accounts.push(account);
        setSocialAccounts(accounts);
      });
      data?.data?.map((account) => {
        allAccounts.push(account);
        setAllSocialAccounts(allAccounts);
      });
      setIsLoading(false);
    });
  };

  //_____________________________________________________________
  // Get Authorized Accounts for
  const getAuthorizedAccount = () => {
    setIsLoading(true);
    UserAccount.getAuthorizedAccounts().then((result) => {
      if (!result.errorMsg) {
        setAuthorizedAccounts({
          twitterAccounts: result.data.data.twitter.data,
          facebookAccounts: result.data.data.facebook.data,
          instagramAccounts: result.data.data.instagram.data,
        });
      }
      setIsLoading(false);
    });
  };
  //________________________function submitting all monitor Data (last step)______________________
  const submitCreateMonitor = (data, accounts, isValid) => {
    let newData = removeCrossedKeywords(data);
    //if monitor name is empty submit won't work and error msg will show up

    if (monitorData.monitorName === "") {
      setMonitorErrors({
        ...monitorErrors,
        monitorName: "this_field_required",
      });
      window.scrollTo(0, 0);
    } else if (!isValid) {
      handleCreateMonitor(newData, accounts);
    }
  };
  //___________________________handle Create Monitor Btn _______________________________
  //This function control the disable/enable of create monitor btn ,
  //Btn should be enabled if user entered account user name or selected and account or keywords
  const handleCreateMonitorBtn = (param) => {
    //enable create monitor btn as user selected an account
    if (
      param === "" ||
      !Object.values(monitorErrors).every((str) => str === "")
    ) {
      setCreateMonitorBtn(false);
    } else {
      setCreateMonitorBtn(true);
    }
  };
  // getTopicsData(monitorTopics);
  //________________________________handle create monitor______________________________

  const handleCreateMonitor = (data) => {
    let topicsData = getTopicsData(monitorTopics);
    const {
      monitorName,
      monitorDescription,
      userName,
      pageName,
      selectedAccount,
      spamKeywords,
      auto_summary_widget,
    } = monitorData;

    let { twAccountId, fbAccountId, igAccountId } =
      getPublicAccountDatasourcesId(authorizedAccounts);
    let newSpamKeyword = checkKewordsCrossed(spamKeywords);
    let { monitorTypeId, monitorTypeName } = getMonitorTypeSelected(
      companyMonitorTypes,
      selectedMonitorType,
    );
    let activeDataSourceName = getActiveDatasourceName(
      companyAllowedSources,
      activeDataSourceId,
    );

    //check is managed account or managed page
    if (monitorTypeId == 5 && activeDataSourceId == 2) {
      monitorTypeId = 6;
      monitorTypeName = "MANAGED_PAGE";
    } else if (monitorTypeId == 6 && activeDataSourceId == 3) {
      monitorTypeId = 5;
      monitorTypeName = "MANAGED_ACCOUNT";
    }
    //params send to api create monitor
    let params = {};
    const monitor_model = {
      name: monitorName,
      description: monitorDescription,
      monitor_type_id: monitorTypeId,
      product_id: window.localStorage.sm_id,
      auto_summary_widget,
    };
    let monitor_options = {
      [monitorTypeName]: {},
    };
    let data_sources = [];
    //Here we get params data
    getDataPublicAccountParams(
      selectedMonitorType,
      activeDataSourceId,
      params,
      twAccountId,
      fbAccountId,
      igAccountId,
      userName,
      pageName,
      newSpamKeyword,
      selectedAccount,
    );
    if (
      selectedMonitorType === "ACCOUNT_ANALYSIS" &&
      activeDataSourceId === 1
    ) {
      if (monitorData?.twitterLanguages?.length) {
        if (!params?.sources) params.sources = {};
        params.sources["tw_languages"] =
          monitorData?.twitterLanguages?.join(",");
      }
      if (monitorData?.twitterCountries?.length) {
        if (!params?.sources) params.sources = {};
        params.sources["tw_countries"] =
          monitorData?.twitterCountries?.join(",");
      }
      if (monitorData?.ignoreTweets?.length) {
        params.excluded_accounts = monitorData?.ignoreTweets?.join(",");
      }
    }
    if (
      selectedMonitorType == "MANAGED_ACCOUNT" ||
      selectedMonitorType == "MANAGED_PAGE" ||
      selectedMonitorType == "ACCOUNT_ANALYSIS"
    ) {
      monitor_options[monitorTypeName][activeDataSourceName] = params;
      data_sources.push(activeDataSourceId);
    } else if (selectedMonitorType == "KEYWORD") {
      data_sources = data.data_sources;
      monitor_options = data.monitor_options;
    }

    let keyword_option =
        data.monitor_options["KEYWORD"]["TALKWALKER"] !== undefined &&
        data.monitor_options["KEYWORD"]["TALKWALKER"]["keywords"],
      title_option =
        data.monitor_options["KEYWORD"]["TALKWALKER"] !== undefined &&
        data.monitor_options["KEYWORD"]["TALKWALKER"]["sources"] !== undefined
          ? data.monitor_options["KEYWORD"]["TALKWALKER"]["sources"][
              "nb_title_search_keywords"
            ]
          : "";
    //set topics to monitor options
    monitor_options["topics"] = topicsData;

    let prevent_create_news_blogs_monitor =
      data.data_sources[0] === 4 &&
      title_option === "" &&
      (keyword_option === "" || keyword_option === undefined);
    if (!prevent_create_news_blogs_monitor) {
      //call api

      createNewMonitor(
        monitor_model,
        monitor_options,
        data_sources,
        monitorData?.coustomerCareVal,
      );
    }
  };
  //___________________________  //Here we call create monitor api.__________________________

  const createNewMonitor = (
    monitor_model,
    monitor_options,
    data_sources,
    customer_care_accounts,
  ) => {
    MonitorsController.createNewMonitor(
      monitor_model,
      monitor_options,
      data_sources,
      customer_care_accounts,
    ).then((res) => {
      if (res.data.status === 204) {
        history.push({
          pathname: "/social/monitor_list",
          state: { monitorName: monitorData.monitorName },
        });
      } else {
        let errorMsg = res.errorMsg.response.data.exception.message;
        let stateFaild = "added_monitor_faild";
        if (
          res.errorMsg.response.status == 400 &&
          (errorMsg == "MISSING_REQUIRED_PARAMETERS" ||
            errorMsg == "WRONG_REQUEST_PARAMETERS")
        ) {
          setErrorMsgMonitor("error_request_param");
          window.scrollTo(0, 0);
        } else if (
          ((res.errorMsg.response.status == 400 ||
            res.errorMsg.response.status == 500) &&
            errorMsg == "SOMETHINGS_WENT_WRONG_PLEASE_TRY_AGAIN_LATER") ||
          (res.errorMsg.response.status == 404 &&
            errorMsg == "RECORD_NOT_FOUND")
        ) {
          history.push({
            pathname: "/social/monitor_list",
            state: { fail: stateFaild },
          });
        } else if (
          res.errorMsg.response.status === 400 &&
          errorMsg === "COMPANY_TOPICS_LIMIT_REACHED"
        ) {
          setErrorMsgMonitor("max_numbers_of_Topics_company");
          window.scrollTo(0, 0);
        }
      }
    });
  };

  // This Function will used in retrieve the languages and countries for api.
  const getTwitterLanguagesAndCountries = () => {
    // if already fetched, don't fetch again
    if (twitterLanguagesAndCountries) return;
    MonitorsController.getTwitterSources({
      data_source: "twitter",
    }).then((response) => {
      if (response?.data?.data?.data?.languages?.length) {
        setTwitterLanguagesAndCountries(response?.data?.data?.data);
      }
    });
  };

  // This Function will used in retrieve facebook pages list
  const getFacebookPages = (searchValue) => {
    const product_id = parseInt(window.localStorage.sm_id);
    setIsLoadingSelectList(true);
    CreateMonitorController.getFacebookPages(product_id, searchValue).then(
      (data) => {
        if (data?.data?.data?.result) {
          setFacebookPages(data?.data?.data?.result);
        }
        setIsLoadingSelectList(false);
      },
    );
  };

  const getDataSourceName = (name) => {
    let datasources = {
      FACEBOOK: "facebook",
      INSTAGRAM: "instagram",
      TWITTER: "twitter",
      TALKWALKER: "news_and_blogs",
      1: "TWITTER",
      2: "FACEBOOK",
      3: "INSTAGRAM",
      default: name,
    };
    return datasources[name] || datasources.default;
  };

  // This Function will used to calc Summary Tokens
  const calcSummaryTokens = (summaryTokens, dataSourcesList) => {
    let summaryTokensResult = 0;
    Object.keys(summaryTokens)?.map((dataSource) => {
      if (dataSourcesList?.includes(getDataSourceName(dataSource))) {
        summaryTokensResult += summaryTokens?.[dataSource];
      }
    });
    return summaryTokensResult;
  };

  return {
    steps,
    selectedMonitorType,
    setSelectedMonitorType,
    handleNext,
    completed,
    activeStep,
    setActiveStep,
    getSocialAccounts,
    companyAllowedSources,
    submitCreateMonitor,
    setMonitorData,
    monitorErrors,
    monitorData,
    setMonitorErrors,
    createMonitorBtn,
    handleCreateMonitorBtn,
    setCreateMonitorBtn,
    socialAccounts,
    isLoading,
    setIsLoading,
    getCompanySources,
    getCompanyMonitorTypes,
    companyMonitorTypes,
    allSocialAccounts,
    handleCreateMonitor,
    setActiveDataSourceId,
    reverseMonitorTypes,
    errorMsgMonitor,
    setErrorMsgMonitor,
    setAllSocialAccounts,
    activeDataSourceId,
    isClikedFb,
    setIsClikedFb,
    isClikedIg,
    setIsClikedIg,
    getAuthorizedAccount,
    authorizedAccounts,
    setAuthorizedAccounts,
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    getUserSocialAccounts,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    getFacebookPages,
    facebookPages,
    setFacebookPages,
    isLoadingSelectList,
    setIsLoadingSelectList,
    calcSummaryTokens,
    getDataSourceName,
  };
};
export default CreateMonitorCommonFuntions;
