import { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import SingleTweetController from "services/controllers/SingleTweetController";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import _ from "lodash";
import CompaniesController from "services/controllers/companiesController";
import {
  Box,
  Tooltip,
  TextField,
  Button,
  FormHelperText,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import "../publicAccount/twitterAccountAnalysis/authors/authorProfile/authorProfile.scss";
import { LoadingButton } from "@mui/lab";
import SummaryWidgetController from "services/controllers/summaryWidgetController";
import LucButton from "shared/lucButton/lucButton";

const CommonFunctions = (props) => {
  const intl = useIntl();

  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [tweetCreateAt, setTweetCreatedAt] = useState(0);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  const { monitorId, monitorType, activeTab } = useParams();
  const [showAnalyzeTweetSnackBar, setShowAnalyzeTweetSnackBar] =
    useState(false);
  const [analyzeTweetSnackBar, setAnalyzeTweetSnackBar] = useState({
    title: "",
    message: "",
    severity: "",
  });
  /* 
   - limit --> social_remaining_quota
   - used --> quota_consumer
   - type --> 1 [Single Analytics] , 2 [Data insights] 
   */
  const [postsUsedLimit, setPostsUsedLimit] = useState({
    used: 0,
    limit: 0,
    type: 0,
  });

  //deep insight popup
  const [quotaCount, setQuotaCount] = useState("");
  const [analyzeProfileError, setAnalyzeProfileError] = useState(false);
  const [formFieldErrors, setFormFieldErrors] = useState({
    quotaLimit: "",
  });
  const [tweetInfo, setTweetInfo] = useState({
    tweetId: "",
    screenName: "",
    trackerId: "",
  });

  const [singleTweetLoading, setSingleTweetLoading] = useState(false);
  const [enterFirstTime, setEnterFirstTime] = useState(false);
  const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [postsSortBy, setPostsSortBy] = useState();
  const [summaryStaticQuestions, setSummaryStaticQuestions] = useState(null);
  const [summaryStaticQuestionsLoading, setSummaryStaticQuestionsLoading] =
    useState(false);
  const [openSnack, setOpenSnack] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  let singleTweetCreateTime = moment.unix(tweetCreateAt).format("DD MMM, YY");
  const handleHideSnackBar = () => {
    setOpenSnack(false);
  };
  // get Company Quota
  const getCompanyDataInsightsQuota = () => {
    CompaniesController.getCompanyDataInsightsQuota().then((result) => {
      var smQuota = result?.data;
      for (let i = 0; i < smQuota?.length; i++) {
        if (smQuota[i]?.name === "SM") {
          setPostsUsedLimit({
            limit:
              smQuota[i]?.quota?.posts_limit?.limit -
              smQuota[i]?.quota?.posts_limit?.used,
            used: smQuota[i]?.quota?.posts_limit?.used,
            type: 2,
          });
        }
      }
    });
  };

  // get Company Quota - Single Tweets Insights
  const getCompanySingleTweetsQuota = (
    tweetID,
    monitorID,
    productID,
    tweetTime,
    screenName,
    startDate,
    endDate,
  ) => {
    if (!enterFirstTime) setSingleTweetLoading(true);
    CompaniesController.getCompanySingleTweetsQuota(
      tweetID,
      monitorID,
      productID,
      tweetTime,
      screenName,
      startDate,
      endDate,
    ).then((result) => {
      var smQuota = result?.data;

      setPostsUsedLimit({
        limit: smQuota?.data?.social_remaining_quota,
        used: smQuota?.data?.quota_consumer,
        type: 1,
      });
      setEnterFirstTime(false);
      setSingleTweetLoading(false);
    });
  };

  /* Single Tweet Analysis Btn____________________________________________________________________________________ */
  const handleCloseSnackBar = () => {
    setShowAnalyzeTweetSnackBar(false);
  };
  const [stats, setStats] = useState({
    anchorEl: null,
    openCreateModal: false,
  });

  const updateAnalysis = (
    tweetId,
    screenName,
    trackerId,
    tweetTime,
    enteredFirstTime = true,
  ) => {
    setTweetCreatedAt(tweetTime);
    setEnterFirstTime(enteredFirstTime);
    setTweetInfo({
      tweetId,
      screenName,
      trackerId,
    });

    handleOpenCreateModal();
  };

  const updateSingleTweetAnalysis = (
    tweetId,
    screenName,
    trackerId,
    quotaCount,
    startDate,
    endDate,
  ) => {
    setIsButtonLoading(true);
    SingleTweetController.createAnalysisSingleTweet(
      monitorId ? monitorId : trackerId,
      tweetId,
      screenName,
      true,
      parseInt(quotaCount),
      startDate,
      endDate,
    ).then((response) => {
      if (response?.status === 200) {
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        if (
          monitorType === "ACCOUNT_ANALYSIS" &&
          (activeTab === "posts" || activeTab === "account")
        ) {
          //success msg text is different in case of AccountAnalysis PostsPage
          setAnalyzeTweetSnackBar({
            title: "request_sent_successfully",
            message: "analyze_tweet_msg",
            severity: "success",
          });
        } else {
          setStats({ ...stats, openCreateModal: false });
          setQuotaCount("");
          setAnalyzeTweetSnackBar({
            title: "request_sent_successfully",
            message: "analyze_tweet_24_hr_msg",
            severity: "success",
          });
        }
      } else {
        setStats({ ...stats, openCreateModal: false });
        setQuotaCount("");
        setAnalyzeTweetSnackBar({
          title:
            response?.errorMsg?.toLowerCase() ??
            CheckValueLocale("request_failed_deep_insights", "", {}, intl), //msg that shows to user will be based on backend errormsg
          message: CheckValueLocale("update_not_deep_insights", "", {}, intl),
          severity: "error",
        });
      }
      setIsButtonLoading(false);
      setShowAnalyzeTweetSnackBar(true);
    });
  };

  const inputValidator = (inputValue) => {
    const value = /^\d*\d?((10)|(0))\.?((0)|(00))?$/;
    return value.test(inputValue);
  };

  const handleOpenCreateModal = () => {
    setStats({
      ...stats,
      openCreateModal: true,
      modalTitle: `${CheckValueLocale("single_tweet_analysis", "", {}, intl)}`,
      anchorEl: null,
    });
  };
  const handleCloseCreateModal = () => {
    setStats({ ...stats, openCreateModal: false });
    setQuotaCount("");
    setFormFieldErrors({
      quotaLimit: "",
    });
  };

  const handlequotaCount = (e) => {
    let value = e?.target?.value;
    setQuotaCount(value);
    let analyzeProfileError = "";

    setAnalyzeProfileError(false);

    if (Number(value) === 0) {
      analyzeProfileError = "deep_insight_ten_multiple";
    } else if (postsUsedLimit?.limit < value && postsUsedLimit?.type === 2) {
      analyzeProfileError = "quota_out";
    } else if (!inputValidator(value)) {
      analyzeProfileError = "deep_insight_ten_multiple";
    } else if (postsUsedLimit?.limit === 0 && postsUsedLimit?.type === 2) {
      analyzeProfileError = "deep_insight_limit_zero";
    } else if (value > 200 && postsUsedLimit?.type === 2) {
      analyzeProfileError = "deep_insight_over_200";
    }

    setFormFieldErrors({
      ...formFieldErrors,
      quotaLimit: analyzeProfileError,
    });
  };

  const applyFilter = (startDate, endDate) => {
    let productID = window?.localStorage?.sm_id;
    setStartDate(startDate);
    setEndDate(endDate);
    getCompanySingleTweetsQuota(
      tweetInfo.tweetId,
      tweetInfo.trackerId,
      productID,
      tweetCreateAt,
      tweetInfo.screenName,
      startDate,
      endDate,
    );
  };
  const modalBody = (
    <Box className="deep-insights-modal deep-insights-content-modal">
      <Box className="shared-dialog-body">
        <p>{CheckValueLocale("single_tweet_popup_desc", "", {}, intl)}</p>
      </Box>
      <Box className="deep-posts-remainig-content">
        <Box component="span">
          {CheckValueLocale(
            "deep_insights_remainig_post",
            "",
            {
              remaining: (
                <Box
                  component="span"
                  className={
                    singleTweetLoading ? "deep-number-loader" : "deep-number"
                  }
                >
                  {postsUsedLimit?.limit?.toLocaleString() ?? 0}
                </Box>
              ),
            },
            intl,
          )}
          <Tooltip
            title={
              <Box className="deep-insight-tooltip">
                {CheckValueLocale("deep_tweet_tooltip_quota", "", {}, intl)}
              </Box>
            }
            arrow
            placement={window?.localStorage?.lang !== "en" ? "left" : "right"}
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: "10px",
                  width: 250,
                  lineHeight: 1.5,
                },
              },
            }}
          >
            <Box component="span">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <TextField
        variant="outlined"
        placeholder={CheckValueLocale("EX:30", "", {}, intl)}
        onChange={handlequotaCount}
        className="single-tweet-input"
        value={quotaCount}
        type="number"
        disabled={singleTweetLoading}
        error={
          formFieldErrors?.quotaLimit || analyzeProfileError ? true : false
        }
      />
      {formFieldErrors?.quotaLimit || analyzeProfileError ? (
        <FormHelperText error className="deep-insight-validation">
          {CheckValueLocale(
            analyzeProfileError
              ? "custom_domain_validation"
              : formFieldErrors?.quotaLimit,
            "",
            {},
            intl,
          )}
        </FormHelperText>
      ) : (
        <FormHelperText className="deep-insight-validation" />
      )}
      <Box className="text-field create-historical-calendar">
        <Box className="label-container-calendar">
          <InputLabel className="body-input-label show-red-icon">
            {CheckValueLocale("dates_range", "", {}, intl)}
          </InputLabel>
          <Box className="post-publish-date">
            {CheckValueLocale("post_publish_date", "", {}, intl)}
            <div className="single-date">{singleTweetCreateTime}</div>
          </Box>
        </Box>
        <DateRangePicker
          disablePast
          startDate={startDate}
          endDate={endDate}
          oldestDate={moment.unix(1142899200).format("YYYY/MM/DD")} // this number required to not allow user to get data less than 2006
          openCustomDateLeft={true}
          applyFilter={applyFilter}
          isActive={!singleTweetLoading}
          datePickerPosition="create-historical-calendar-datepicker-position"
          className="create-historical-select reate-historical-select"
        />
      </Box>
      {singleTweetLoading ? (
        <Box className="single-tweet-quota">
          <CircularProgress />
        </Box>
      ) : (
        <Box className="single-tweet-note single-tweet-qouta-content">
          <FormattedMessage
            id="single_tweet_qouta"
            values={{
              tweetNum: (
                <strong>{postsUsedLimit?.used?.toLocaleString() ?? 0}</strong>
              ),
            }}
          />
        </Box>
      )}

      <Box className="step-footer deep-insight">
        <LucButton
          variant="flat"
          type="secondary"
          id="cancel-btn-deep-insight"
          onClick={() => handleCloseCreateModal()}
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
        <LucButton
          id="request-btn-deep-insight"
          onClick={() =>
            updateSingleTweetAnalysis(
              tweetInfo?.tweetId,
              tweetInfo?.screenName,
              tweetInfo?.trackerId,
              quotaCount,
              startDate,
              endDate,
            )
          }
          disabled={
            quotaCount <= 0 ||
            (postsUsedLimit.type === 2 &&
              (quotaCount === "" || quotaCount < 10)) ||
            formFieldErrors?.quotaLimit !== "" ||
            postsUsedLimit?.limit <= 0 ||
            singleTweetLoading ||
            isButtonLoading
          }
          loading={singleTweetLoading || isButtonLoading}
        >
          {CheckValueLocale("request", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );

  // ____________________________________________________________________________________________________________________
  //format FilterParams to be string instead of array and remove empty array params
  const changeFilterParamsToString = (filterParams, isFormattedString) => {
    for (let [key, value] of Object.entries(filterParams)) {
      if (key != "manual_topics") {
        if (Array.isArray(value) && !isEmptyArray(value)) {
          //if value is array change it to string
          var filterParamsToString = {
            ...filterParamsToString,
            [key]: isFormattedString ? value : value.join(),
          };
        } else if (
          (typeof value === "string" && value !== "") ||
          typeof value === "boolean"
        ) {
          //if value is not array keep it string as it is
          //in case if hide spam/retweet
          filterParamsToString = {
            ...filterParamsToString,
            [key]: value,
          };
        }
      } else {
        filterParamsToString = {
          ...filterParamsToString,
          [key]: value,
        };
      }
    }
    return filterParamsToString;
  };
  /*______________________________________________________________*/
  // this fn used for check all data between preloader and tracker datasource is loaded
  const checkAllDataFounded = (totalPreLoaderDataSources, preLoaderTracker) => {
    if (preLoaderTracker?.length == 0) return false;
    else {
      let result = totalPreLoaderDataSources?.every(
        (item) => _.isEqual(item, preLoaderTracker) == true,
      );
      setCheckAllDataExist(result);
      return result;
    }
  };
  /*______________________________________________________________*/
  // this fn to check if any data founded or not
  const checkDataIsFounded = (totalData) => {
    let data = totalData.some((item) => !isEmptyArray(item));
    setCheckDataIsEmpty(data);
    return data;
  };

  //handle Auto summary widget in monitor
  const autoSummaryActive = {
    ACCOUNT_ANALYSIS: [
      "engagements",
      "customer_care",
      "comments_mentions",
      "questions",
    ],
    MANAGED_ACCOUNT: [
      "engagements",
      "customer_care",
      "comments_mentions",
      "questions",
    ],
    MANAGED_PAGE: [
      "engagements",
      "customer_care",
      "comments_mentions",
      "questions",
    ],
    KEYWORD: ["engagements", "posts", "comments_mentions"],
  };

  const getLuciFeature = (monitorType) => {
    let monitorTypes = {
      ACCOUNT_ANALYSIS: "Account Analysis",
      MANAGED_ACCOUNT: "Managed Account",
      MANAGED_PAGE: "Managed Account",
      KEYWORD: "Keywords",
      default: "Account Analysis",
    };
    return monitorTypes[monitorType] || monitorTypes.default;
  };
  const getSummaryStaticQuestions = (dataSourceId) => {
    const queryData = {
      section_name: activeTab,
      product_id: window.localStorage.sm_id,
      luci_feature: getLuciFeature(monitorType),
      data_source_id: dataSourceId,
    };
    setSummaryStaticQuestionsLoading(true);
    SummaryWidgetController.getSummaryStaticQuestions(queryData).then(
      (result) => {
        if (result?.data) {
          setSummaryStaticQuestions(result?.data);
        } else {
          setSummaryStaticQuestions(null);
        }
        setSummaryStaticQuestionsLoading(false);
      },
    );
  };

  return {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    changeFilterParamsToString,
    checkAllDataFounded,
    checkDataIsFounded,
    checkAllDataExist,
    checkDataIsEmpty,
    setCheckDataIsEmpty,
    postsUsedLimit,
    handleCloseCreateModal,
    modalBody,
    stats,
    getCompanySingleTweetsQuota,
    getCompanyDataInsightsQuota,
    totalPostsCount,
    setTotalPostsCount,
    postsSortBy,
    setPostsSortBy,
    autoSummaryActive,
    getSummaryStaticQuestions,
    summaryStaticQuestions,
    summaryStaticQuestionsLoading,
    openSnack,
    handleHideSnackBar,
  };
};
export default CommonFunctions;
