import { Box } from "@mui/material";
import "./userAvatar.scss";
import { useEffect, useState } from "react";
import DefaultUser from "images/shared-images/user-management-default.svg";

const getUserImage = (userName) => {
  // Replace multiple spaces with a single space
  const cleanedName = userName?.replace(/\s+/g, " ")?.trim() || "";
  const firstName =
    cleanedName?.split(" ")?.[0]?.charAt(0)?.toLocaleUpperCase() || "";
  const lastName =
    cleanedName?.split(" ")?.[1]?.charAt(0)?.toLocaleUpperCase() || "";
  return firstName + lastName;
};

const UserAvatar = ({
  fullName,
  avatarSrc,
  sizes,
  statusIndicatorValue,
  showStatusIndicator,
  isBgDark,
}) => {
  const lettersImage = getUserImage(fullName);
  const [srcIsValid, setSrcIsValid] = useState(true);
  // Ensure size is a number
  const avatarSize = sizes?.ratio ? +sizes?.ratio?.replace?.("px", "") || 0 : 0;

  // Calculate font size based on avatar size
  const fontSize = avatarSize * 0.4; // Adjust the multiplier as needed
  // This is to keep tracking of the avatarSrc validity in the case of an error
  useEffect(() => {
    if (avatarSrc) {
      setSrcIsValid(true);
    } else {
      setSrcIsValid(false);
    }
  }, [avatarSrc]);

  return (
    <Box
      component="div"
      className="user-avatar-main-wrapper"
      sx={{ width: sizes?.ratio, height: sizes?.ratio }}
    >
      <>
        {avatarSrc && srcIsValid ? (
          <Box
            component={"img"}
            className="user-avatar-main-img"
            src={avatarSrc}
            alt="user avatar"
            onError={() => {
              setSrcIsValid(false);
            }}
          />
        ) : lettersImage ? (
          <Box
            component={"div"}
            sx={{ fontSize: fontSize }}
            className="user-avatar-default-img"
          >
            {lettersImage}
          </Box>
        ) : (
          <Box
            component={"img"}
            className="user-avatar-default-img"
            src={DefaultUser}
            alt="user avatar"
          />
        )}
      </>
      {showStatusIndicator ? (
        <Box
          className={`user-avatar-status-indicator ${statusIndicatorValue}`}
          sx={{ border: `3px solid ${isBgDark ? "#000" : "#fff"}` }}
        />
      ) : null}
    </Box>
  );
};

export default UserAvatar;
