import { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import SharedFunctions from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctions";
import TestYourKeyword from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/testYourKeyword/testYourKeyword";
import { checkArabicInEnglishLocal } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import KeywordsInputs from "./inputs/keywordsInputs";
import DropDownInputs from "./inputs/dropDownInputs";
import SourcesInput from "./inputs/sourcesInput";
import HashtagUsedNumber from "pages/socialListing/MonitorsOptions/createMonitor/MonitorTypes/KeywordsMonitor/components/keywordHeader/hashtagUsedNumber";
import {
  exceedNewsBlogsCountLimits,
  handleValidationSourcesNb,
  handleValidationTitleSearchNb,
  getDataNbMonitorData,
  handleDeleteTitle,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/newBlogsSharedFunctions";

const NBKeywords = (props) => {
  const intl = useIntl();
  const {
    handleDelete,
    handleKeywords,
    keywords,
    handleValidation,
    handleData,
    setSQuestion,
    setUpdatedParams,
    updatedParams,
    monitorDetails,
    indexes,
    setKeywords,
    setLanguage,
    language,
    setCountries,
    countries,
    setMajorSource,
    majorSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    wordCountNews,
    wordCountNewsSpamKeyword,
    setSelectedSource,
    selectedSource,
    nbExcludedSource,
    setNbExcludedSource,
    titleSearchKeyword,
    setTitleSearchKeyword,
    setTitleSearchKeywordError,
    setNbExcludedSourceError,
    monitorErrors,
    setMonitorErrors,
    setNBexceedLimit,
  } = props;

  let keywordsInfo = ["keywords", "spam_keywords"];
  const { countTitleSearchKeywords } = SharedFunctions();

  const [excludedSource, setExcludedSource] = useState("");
  const [excludedSourcesErrorMsg, setExcludedSourcesErrorMsg] = useState("");
  const [titleSearch, setTitleSearch] = useState("");
  const [titleSearchErrorMsg, setTitleSearchErrorMsg] = useState("");

  const [copyBtn, setCopyBtn] = useState(
    CheckValueLocale("click_to_copy", "", {}, intl),
  );

  /* End of Excluded Source & title Search Keywords */
  let dropdown = ["languages_filter", "countries"];
  const [resp, setResp] = useState();
  //__________NB Limit_______________
  let exceedNewsBlogsCountLimitValue = exceedNewsBlogsCountLimits(
    resp?.allowed_conditions,
    wordCountNews +
      wordCountNewsSpamKeyword +
      language.length +
      countries.length +
      majorSource.length +
      nbExcludedSource.length +
      countTitleSearchKeywords(titleSearchKeyword),
  );

  useEffect(() => {
    getDataNbMonitorData(setResp);
  }, []);

  useEffect(() => {
    if (
      wordCountNews +
        wordCountNewsSpamKeyword +
        language.length +
        countries.length +
        majorSource.length +
        nbExcludedSource.length +
        countTitleSearchKeywords(titleSearchKeyword) >
      resp?.allowed_conditions
    )
      setNBexceedLimit(true);
    else {
      setNBexceedLimit(false);
    }
  }, [
    wordCountNews,
    wordCountNewsSpamKeyword,
    language.length,
    countries.length,
    majorSource.length,
    nbExcludedSource.length,
    countTitleSearchKeywords(titleSearchKeyword),
  ]);
  //__________________Handle dropdown change_______________________
  const handleChangeDropdown = (event, type, resp) => {
    const { value } = event.target;
    let enableLanguages =
      type === "languages_filter" &&
      wordCountNews +
        value?.length +
        countries?.length +
        majorSource?.length +
        nbExcludedSource?.length +
        countTitleSearchKeywords(titleSearchKeyword) <=
        resp?.allowed_conditions;
    let enableSources =
      wordCountNews +
        value?.length +
        countries?.length +
        language?.length +
        countTitleSearchKeywords(titleSearchKeyword) <=
      resp?.allowed_conditions;
    let enableCountries =
      wordCountNews +
        value?.length +
        language?.length +
        majorSource?.length +
        nbExcludedSource?.length +
        countTitleSearchKeywords(titleSearchKeyword) <=
      resp?.allowed_conditions;
    let languagesList;
    let countriesList;
    let sourcesList;
    if (value.includes("all")) {
      if (type === "languages_filter") {
        languagesList = [...value];
        if (languagesList?.length - 1 == resp?.languages?.length) {
          languagesList = [];
        } else {
          languagesList = [];
          resp?.languages?.map((item) => {
            languagesList.push(item?.id);
          });
        }
        setLanguage(languagesList);
        setUpdatedParams({
          ...updatedParams,
          nb_languages: languagesList?.join(),
        });
        return;
      }
      if (type === "countries") {
        countriesList = [...value];
        if (countriesList?.length - 1 == resp?.countries?.length) {
          countriesList = [];
        } else {
          countriesList = [];
          resp?.countries?.map((item) => {
            countriesList.push(item?.id);
          });
        }
        setCountries(countriesList);
        setUpdatedParams({
          ...updatedParams,
          nb_countries: countriesList?.join(),
        });
        return;
      }

      if (type === "major_sources") {
        sourcesList = [...value];
        if (sourcesList?.length - 1 == resp?.sources?.length) {
          sourcesList = [];
        } else {
          sourcesList = [];
          resp?.sources?.map((item) => {
            sourcesList.push(item?.id);
          });
        }

        setMajorSource(sourcesList);
        setUpdatedParams({
          ...updatedParams,
          nb_sources: sourcesList?.join(),
        });
        return;
      }
      return;
    }

    if (type === "languages_filter") {
      setLanguage([...value]);
      setUpdatedParams({
        ...updatedParams,
        nb_languages: value?.join(),
      });
    }

    if (type === "major_sources") {
      setMajorSource([...value]);
      setUpdatedParams({
        ...updatedParams,
        nb_sources: value?.join(),
      });
    }
    if (type === "countries") {
      setCountries([...value]);
      setUpdatedParams({
        ...updatedParams,
        nb_countries: value?.join(),
      });
    }
  };
  /* __________________________________________________________ */
  const handleKeyDown = (event, keywordType, index, clickType) => {
    handleKeywords(
      event,
      keywordType,
      "newsBlogs",
      index,
      clickType,
      resp?.allowed_conditions,
    );
    handleUpdateParams(keywordType);
  };
  const handleDeleteWord = (e, index, keywordType) => {
    handleDelete(e, index, keywordType, indexes, "newsBlogs");
    handleUpdateParams(keywordType);
  };

  /* This Function updates the parameters on add or delete______ */
  const handleUpdateParams = (type) => {
    if (type === "keywords") {
      setUpdatedParams({
        ...updatedParams,
        keywords: {
          ...updatedParams.keywords,
          TALKWALKER: Object.values(keywords[3])[0][0]?.keywords?.join(),
        },
      });
    }

    if (type === "spam_keywords") {
      setUpdatedParams({
        ...updatedParams,
        spam_keywords: {
          ...updatedParams.spam_keywords,
          TALKWALKER: Object.values(keywords[3])[0][1].spam_keywords?.join(),
        },
      });
    }
    if (type === "nb_title_Search") {
      setUpdatedParams({
        ...updatedParams,
        nb_title_search_keywords: titleSearchKeyword.join(),
      });
    }
    if (type === "nb_excluded_sources") {
      setUpdatedParams({
        ...updatedParams,
        nb_excluded_sources: nbExcludedSource.join(),
      });
    }
  };

  //Start of nb_excluded_sources _____________________________________________________________
  const handleSourceskeyboardPress = (event, clickType) => {
    let source = event.target.value;
    if (
      (event.key === "Enter" || (clickType === "btn" && source)) &&
      !exceedNewsBlogsCountLimitValue &&
      source.trim()
    ) {
      handleValidationSourcesNb(
        event,
        source,
        setExcludedSourcesErrorMsg,
        setNbExcludedSourceError,
        excludedSourcesErrorMsg,
        nbExcludedSource,
        setExcludedSource,
        setCopyBtn,
        setNbExcludedSource,
        intl,
        clickType,
      );
      handleUpdateParams("nb_excluded_sources");
    }
  };

  const handleDeleteExcludedSource = (index) => {
    handleDeleteTitle(
      index,
      nbExcludedSource,
      setExcludedSourcesErrorMsg,
      setNbExcludedSource,
    );
    handleUpdateParams("nb_excluded_sources");
  };

  // ________________________handle title search_________________________
  const handleTitleskeyboardPress = (event, clickType) => {
    let title = event.target.value;
    if (
      (event.key === "Enter" || (clickType === "btn" && title)) &&
      !exceedNewsBlogsCountLimitValue &&
      title.trim()
    ) {
      handleValidationTitleSearchNb(
        event,
        title,
        titleSearchErrorMsg,
        titleSearchKeyword,
        setTitleSearch,
        setCopyBtn,
        setTitleSearchKeyword,
        intl,
        clickType,
      );
      handleUpdateParams("nb_title_Search");
    }
  };

  const handleDeleteTitleSearch = (index) => {
    handleDeleteTitle(
      index,
      titleSearchKeyword,
      setTitleSearchErrorMsg,
      setTitleSearchKeyword,
    );
    handleUpdateParams("nb_title_Search");
  };

  return (
    <>
      <Box className="inline-align">
        <Box>
          <Typography className="section-header">
            {CheckValueLocale("NEWSBLOGS", "", {}, intl)}
          </Typography>
          <Typography className="posts-used">
            {CheckValueLocale("posts_used", "", {}, intl)}:{" "}
            {monitorDetails?.posts_count_stats?.TALKWALKER
              ? monitorDetails?.posts_count_stats?.TALKWALKER
              : monitorDetails?.posts_count_stats?.NEWSBLOGS !== undefined
                ? monitorDetails?.posts_count_stats?.NEWSBLOGS
                : 0}
          </Typography>
        </Box>
        {/* __________________________________________________________ */}

        <Box className="inline-flex data-source-setting">
          <HashtagUsedNumber
            wordCountDataSource={props.newsBlogCount}
            limitationOfDataSource={resp?.allowed_conditions || 0}
            isNewBlogs
          />

          <TestYourKeyword
            keywords={monitorDetails.keywords.TALKWALKER}
            spamKeywords={monitorDetails.spam_keywords.TALKWALKER}
          />
        </Box>
      </Box>
      {/* Keywords Section__________________________________________________________ */}
      <Box className="section-box">
        <KeywordsInputs
          keywordsInfo={keywordsInfo}
          keywords={keywords}
          indexes={indexes}
          titleSearchKeyword={titleSearchKeyword}
          handleDeleteWord={handleDeleteWord}
          handleData={handleData}
          handleKeyDown={handleKeyDown}
          titleSearchErrorMsg={titleSearchErrorMsg}
          titleSearch={titleSearch}
          checkArabicInEnglishLocal={checkArabicInEnglishLocal}
          handleTitleskeyboardPress={handleTitleskeyboardPress}
          handleDeleteTitleSearch={handleDeleteTitleSearch}
          copyBtn={copyBtn}
          setCopyBtn={setCopyBtn}
          handleValidation={handleValidation}
          setKeywords={setKeywords}
          handleUpdateParams={handleUpdateParams}
          handleKeywords={handleKeywords}
          setSQuestion={setSQuestion}
          setTitleSearch={setTitleSearch}
          setTitleSearchErrorMsg={setTitleSearchErrorMsg}
          setTitleSearchKeywordError={setTitleSearchKeywordError}
          monitorErrors={monitorErrors}
          setMonitorErrors={setMonitorErrors}
        />
        {/* dropdown Section__________________________________________________________ */}
        <DropDownInputs
          dropdown={dropdown}
          language={language}
          countries={countries}
          handleChangeDropdown={handleChangeDropdown}
          resp={resp}
        />
        {/* sources Radio btn__________________________________________________ */}
        <SourcesInput
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
          setMajorSource={setMajorSource}
          setNbExcludedSource={setNbExcludedSource}
          nbExcludedSource={nbExcludedSource}
          majorSource={majorSource}
          nbRanks={nbRanks}
          setNbRanks={setNbRanks}
          nbDuplicated={nbDuplicated}
          setNbDuplicated={setNbDuplicated}
          handleChangeDropdown={handleChangeDropdown}
          resp={resp}
          excludedSourcesErrorMsg={excludedSourcesErrorMsg}
          excludedSource={excludedSource}
          handleSourceskeyboardPress={handleSourceskeyboardPress}
          handleDeleteExcludedSource={handleDeleteExcludedSource}
          copyBtn={copyBtn}
          setUpdatedParams={setUpdatedParams}
          updatedParams={updatedParams}
          setCopyBtn={setCopyBtn}
          setExcludedSource={setExcludedSource}
          setExcludedSourcesErrorMsg={setExcludedSourcesErrorMsg}
          setNbExcludedSourceError={setNbExcludedSourceError}
          monitorErrors={monitorErrors}
          setMonitorErrors={setMonitorErrors}
        />
      </Box>
    </>
  );
};

export default NBKeywords;
