import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { TeamMembers } from "../../components/teamMembers";
import { useShowMore } from "hooks/useShowMore";
import { ShowMoreButton } from "../../components/showMoreButton";
import CompaniesController from "services/controllers/companiesController";

export const EditTeamMembers = ({
  members,
  setMembers,
  teamId,
  handleSelectMember,
  selectedMembers,
}) => {
  const intl = useIntl();
  const {
    isSettled,
    setIsSettled,
    setShowedData: setShowedMembers,
    showedData: showedMembers,
    showMore,
    setShowMore,
    isLoading,
    setIsLoading,
    onShowLess,
    nextPage,
    hasPagination,
  } = useShowMore({
    data: members?.data,
    pagination: members?.pagination,
  });

  const onShowMore = () => {
    if (isSettled) {
      setShowedMembers(members?.data);
      setShowMore(false);
      return;
    }
    setIsLoading(true);
    CompaniesController.getTeamMembersSelected(teamId, nextPage).then((res) => {
      if (!res?.errorMsg) {
        const newMembers = res?.data;
        const newMembersData = [...members?.data, ...newMembers?.data];
        const nextPage = newMembers?.pagination?.next;
        setMembers({
          data: newMembersData,
          pagination: newMembers?.pagination,
        });
        setShowedMembers(newMembersData);
        if (!nextPage) {
          setIsSettled(true);
          setShowMore(false);
        }
      }
      setIsLoading(false);
    });
  };
  return (
    <Box className="edit-team-details-section">
      <TeamMembers
        members={showedMembers}
        selectable={true}
        title="team_members"
        selectedMembers={selectedMembers}
        handleSelectMember={handleSelectMember}
        overallCount={members?.pagination?.count}
      />
      <ShowMoreButton
        show={hasPagination}
        loading={isLoading}
        hasMore={showMore}
        onShowLess={onShowLess}
        onShowMore={onShowMore}
      />
    </Box>
  );
};
