import { faPlus, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import CreateAgentCapacityPopup from "../createAgentCapacityPopup/createAgentCapacityPopup";

function InboundedRoutingHeader() {
  const intl = useIntl();
  const [openCreateAgentCapacityPopup, setOpenCreateAgentCapacityPopup] =
    useState(null);
  return (
    <Box className="inbound-routing-header">
      <Box>
        <Box component="h2" className="inbound-routing-title">
          {CheckValueLocale("inbound_routing_title", "", {}, intl)}
        </Box>
        <Box component="span" className="inbound-routing-description">
          {CheckValueLocale("inbound_routing_description", "", {}, intl)}
        </Box>
      </Box>
      <Box>
        <LucButton
          variant="outline"
          className="agent-capacity-button"
          onClick={() => setOpenCreateAgentCapacityPopup(true)}
          startIcon={<FontAwesomeIcon icon={faUsers} />}
          id="inbound-routing-agent-capacity-button"
        >
          {CheckValueLocale("agent_capacity_button", "", {}, intl)}
        </LucButton>
        <Link to="/settings/inbound_routing/create/step_1">
          <LucButton
            id="new-inbound-routing-btn"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            {CheckValueLocale("new_routing_button", "", {}, intl)}
          </LucButton>
        </Link>
      </Box>

      <CreateAgentCapacityPopup
        openCreateAgentCapacityPopup={openCreateAgentCapacityPopup}
        setOpenCreateAgentCapacityPopup={setOpenCreateAgentCapacityPopup}
      />
    </Box>
  );
}

export default InboundedRoutingHeader;
