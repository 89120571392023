import { useMutation } from "@tanstack/react-query";
import InteractionsController from "services/controllers/interactionsController";

export const useEditThemes = () => {
  return useMutation({
    mutationFn: async ({ apiVars, queryData }) => {
      await InteractionsController.editThemes(apiVars, queryData);
    },
  });
};
