import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import SnackBar from "components/snackBar";
import CustomizedTooltip from "components/customizedTooltip";

const MonitorCreateReport = (props) => {
  const { monitorData } = props;
  const intl = useIntl();
  const history = useHistory();

  const [snackBarStatus, setSnackBarStatus] = useState(true);

  useEffect(() => {
    if (history?.location?.showSnackBar)
      setTimeout(() => {
        setSnackBarStatus(false);
      }, 5000);
  }, [history?.location?.showSnackBar]);

  const handleCreateReport = () => {
    history.push({
      pathname: "/reports/add-report",
      isMonitorAction: true,
      monitorPath: history?.location?.pathname,
      preSelected: {
        productName: "SM",
        monitorId: monitorData?.id,
        monitorName: monitorData?.attributes?.name,
        monitorType: monitorData?.attributes?.monitor_type_name,
        dataSources: monitorData?.attributes?.data_sources,
      },
    });
  };

  return (
    <>
      <CustomizedTooltip
        arrow
        placement="bottom"
        title={
          <Box className="tooltip-custom-new">
            {CheckValueLocale("report_create_report", "", {}, intl)}
          </Box>
        }
      >
        <Box>
          <LucButton
            id="create-report-monitor-header-btn"
            className="monitor-header-action-btn"
            type="secondary"
            variant="flat"
            minWidth={42}
            onClick={handleCreateReport}
          >
            <FontAwesomeIcon icon={faFilePlus} size="lg" />
          </LucButton>
        </Box>
      </CustomizedTooltip>
      {history?.location?.showSnackBar ? (
        <SnackBar
          lang={window?.localStorage?.lang}
          open={snackBarStatus}
          severity={history?.location?.snackBarStatus}
          message={history?.location?.snackBarMsg}
        />
      ) : null}
    </>
  );
};

export default MonitorCreateReport;
