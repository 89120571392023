import AccountBody from "./accountBody";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
/*---------------------------------------------------------*/

const ManagedFBAccountPage = (props) => {
  const FacebookIcon = (
    <span className="topics-twitter-icon">
      <img src={publicFacebook} className="icon-after-title facebook" />
    </span>
  );
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <AccountBody {...props} FacebookIcon={FacebookIcon} />
    </MonitorContainer>
  );
};

export default ManagedFBAccountPage;
