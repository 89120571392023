import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";

const GMBInteractionBody = (props) => {
  let { response } = props;
  const [listOfReviews, setListOfReviews] = useState([]);
  const [listOfReviewsCount, setListOfReviewsCount] = useState(0);
  const [listOfReviewsPreLoader, setListOfReviewsPreLoader] = useState([]);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response.eventName) {
      case "Googlemybusiness__CASingleDataSourceInteractionsPage__top_posts_comments":
        setListOfReviews(response.eventData.top_posts);
        if (props.page === 1) {
          setListOfReviewsCount(response.eventData.interactions_count);
        }
        setListOfReviewsPreLoader([response.data_source]);
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (props.resetAllFields !== 1) {
      setListOfReviews([]);
      setListOfReviewsPreLoader([]);
    }
  }, [props.resetAllFields]);
  useEffect(() => {
    let x = checkLuciAllDataFounded(
      listOfReviewsPreLoader,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([listOfReviews]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    listOfReviews,
    listOfReviewsPreLoader,
    props.preLoaderTrackerDataSources,
  ]);
  return (
    <>
      <Box my={2}>
        <InteractionsList
          showProfile={true}
          data={listOfReviews}
          dataSources={props.dataSources}
          component={"google_my_business"}
          latestInteractionsPreLoaderDataSources={listOfReviewsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          latestInteractionsCount={listOfReviewsCount}
          loader={props.loader}
        />
      </Box>
      {listOfReviews.length > 0 && listOfReviewsCount > 10 && (
        <Pagination
          onChange={props.handleChangePage}
          page={props.page}
          className="pagination-monitor-list"
          count={Math.ceil(listOfReviewsCount / 10)}
          variant="outlined"
        />
      )}
    </>
  );
};

export default GMBInteractionBody;
