import Services from "./Services";

var User = {};

// Get User Info
User.getUserInfo = (queryData) => {
  return Services.getData("api/v3/user/info", queryData);
};
// Get User Info
User.getUserInfoV4 = (queryData) => {
  return Services.getData("api/v4/user/info", queryData);
};

// Get User Teams
User.getUserTeams = (queryData) => {
  return Services.getData("api/v4/user/teams", queryData);
};

// Edit User Info
User.editUserInfo = (queryData) => {
  return Services.putData("api/v3/user/info", queryData);
};

// Get User Authorized Accounts
User.getAuthorizedAccounts = (queryData) => {
  return Services.getData("api/v3/user/authorized_accounts");
};

// Get New User Data
User.getNewUserData = (queryData) => {
  return Services.getData("api/v3/auth/user/me");
};

User.getSmData = (queryData) => {
  return Services.getSmData(queryData);
};
//set user language
User.setUserLang = function (queryData) {
  return Services.putData("api/v3/user/language", queryData);
};
// create hubspot user
User.createUserHutspot = (queryData) => {
  return Services.postData(
    "api/v3/auth/user/hubspot/create_hubspot_user_session",
    queryData,
  );
};

export default User;
