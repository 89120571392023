import { Box, Button, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import "pages/audience/userProfile/userProfileHeader.scss";
import { CheckValueLocale } from "utils/helpers";

const SurveyResponseHeader = (props) => {
  const intl = useIntl();
  const history = useHistory();
  let { name } = props;

  return (
    <Box className="user-profile-header">
      <Box className="user-profile">
        <Box className="user-profile-info audience-profile">
          <Typography variant="h6">
            {name ? name : CheckValueLocale("no_name", {}, intl)}
          </Typography>
        </Box>
        <Button
          className="user-profile-info back-audience"
          onClick={() => {
            history.push(`/notification`);
          }}
          id="manage-audience-back-to-user-profile-btn"
        >
          {CheckValueLocale("back_to_notifications", "", {}, intl)}
          <ArrowForwardIos className="profile-icon" />
        </Button>
      </Box>
    </Box>
  );
};

export default SurveyResponseHeader;
