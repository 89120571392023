import Insights from "../api/insights";

var InsightsController = {};

InsightsController.getInsightsCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
  };
  return new Promise(function (resolve) {
    Insights.getInsightsCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getEmailCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getEmailCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getGmailCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getGmailCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
// public&private
InsightsController.getPublicTwitterCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getPublicTwitterCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//private
InsightsController.getPrivateTwitterCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getPrivateTwitterCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getSocialMediaCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getSocialMediaCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getPublicFacebookCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getPublicFacebookCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//private
InsightsController.getPrivateFacebookCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getPrivateFacebookCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getPublicInstagramCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getPublicInstagramCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getLinkedInCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getLinkedInCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//private
InsightsController.getPrivateInstagramCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getPrivateInstagramCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getWhatsappCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getWhatsappCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getIntercomCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getIntercomCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getMainChatCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getMainChatCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//get active channels
InsightsController.getActiveChannels = () => {
  var queryData = {
    product_id: window.localStorage.cxm_id,
  };
  return new Promise(function (resolve) {
    Insights.getActiveChannels(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getGMBCredentials = (
  start_date,
  end_date,
  filterParams,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getGMBCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getGenesysCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getGenesysCredentials(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.updateGenesysAudio = (
  product_id,
  data_source_id,
  monitor_id,
  job_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
    monitor_id,
    job_id,
  };
  return new Promise(function (resolve) {
    Insights.updateGenesysAudio(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

///Export Public PPT
InsightsController.getPublicExportPPT = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  language,
  exportPPT,
  datasourcePath,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    language,
    export: exportPPT,
  };
  return new Promise(function (resolve) {
    Insights.getPublicExportPPT(queryData, datasourcePath)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getDialectsSubDialects = () => {
  return new Promise(function (resolve) {
    Insights.getDialectsSubDialects()
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InsightsController.getPublicTiktokCredentials = (
  start_date,
  end_date,
  filterParams,
  product_id,
  data_source_id,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Insights.getPublicTiktokCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getPolicy = (policy_type) => {
  return new Promise(function (resolve) {
    Insights.getPolicy(policy_type)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InsightsController.getApplyPolicy = () => {
  return new Promise(function (resolve) {
    Insights.getApplyPolicy()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default InsightsController;
