import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import "../manageChannels.scss";

export const RenderChannelSocialIcon = ({ channel }) => {
  const intl = useIntl();
  return (
    <Box className="manage-channel-socials">
      <Box className="social">
        {getSocialIcon(channel?.attributes?.channel)}
      </Box>
      <Box className="icon">
        {channel?.attributes?.dm_channel === 1 ? (
          <span>
            {CheckValueLocale(channel?.attributes?.channel, "", {}, intl)}(
            {CheckValueLocale("DM", "", {}, intl)})
          </span>
        ) : (
          <span>
            {CheckValueLocale(channel?.attributes?.channel, "", {}, intl)}
          </span>
        )}
      </Box>
    </Box>
  );
};
