import React from "react";
import { Box } from "@mui/material";
import "../deepInsights.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBadgeCheck, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

/*---------------------------------------------------------*/
const DeepInsightsProfileDetails = (props) => {
  const { userBio, profileImg, screenName, userName, verified } = props;
  const intl = useIntl();

  /*---------------------------------------------------------*/
  return (
    <Box className="user-info-box personality" my={5}>
      <Box className="personal-info-stats">
        <Box className="personal-info-img">
          <Box className="author-twitter-icon">
            <img src={xPlatform} alt="x-platform-logo" />
          </Box>
          <img src={profileImg} />
        </Box>

        <Box className="account-data">
          <Box className="personal-info-name-wrapper">
            <Box className="personal-info-name">
              <Box className="screen-name">{userName}</Box>
              <Box component="span" marginX={0.5}>
                @{screenName}
              </Box>
              {verified && (
                <FontAwesomeIcon
                  icon={faBadgeCheck}
                  color="#1D9BF0"
                  className="verified-icon"
                />
              )}
            </Box>
            <Box className="user-bio deep-insights">{userBio}</Box>
          </Box>
        </Box>
        {/*---------------------------------------------------------*/}
      </Box>
      {/* <Link className="back-to-btn">
        {intl.formatMessage({ id: "back_to" })} Jeff Bezos Profile
        <FontAwesomeIcon icon={faArrowRight} solid />
      </Link> */}
    </Box>
  );
};

export default DeepInsightsProfileDetails;
