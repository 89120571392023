import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  faComments,
  faCommentsQuestionCheck,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

const TotalStatisticWidget = ({
  titleWidget,
  countWidget,
  iconName,
  loadingStatisticWidgets,
}) => {
  const intl = useIntl();
  return (
    <Grid item md={4} xs={12}>
      <Box className="total-statistic-widget">
        <Box>
          <Box component={"h4"} className="title-statistic-widget">
            {CheckValueLocale(titleWidget, "", {}, intl)}
          </Box>
          {loadingStatisticWidgets ? (
            <LoadingSpinner />
          ) : (
            <Box component={"span"} className="count-statistic-widget">
              {countWidget}
            </Box>
          )}
        </Box>
        <FontAwesomeIcon icon={iconName} className="icon-statistic-widget" />
      </Box>
    </Grid>
  );
};

const StatisticWidgets = ({
  totalStatisticWidgets,
  loadingStatisticWidgets,
}) => {
  return (
    <Grid container spacing={2} className="statistic-widgets">
      <TotalStatisticWidget
        titleWidget={"total_posts_dashboard"}
        countWidget={totalStatisticWidgets?.totalPosts}
        iconName={faComments}
        loadingStatisticWidgets={loadingStatisticWidgets}
      />
      <TotalStatisticWidget
        titleWidget={"total_authors_dashboard"}
        countWidget={totalStatisticWidgets?.totalAuthors}
        iconName={faUsers}
        loadingStatisticWidgets={loadingStatisticWidgets}
      />
      <TotalStatisticWidget
        titleWidget={"total_interactions_dashboard"}
        countWidget={totalStatisticWidgets?.totalInteractions}
        iconName={faCommentsQuestionCheck}
        loadingStatisticWidgets={loadingStatisticWidgets}
      />
    </Grid>
  );
};

export default StatisticWidgets;
