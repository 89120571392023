import { useHistory, useParams } from "react-router-dom";
import "./monitorSideBar.scss";
import { Box, Button } from "@mui/material";
import { DrawerContainer } from "./monitor.styles.js";
import { useIntl } from "react-intl";
import SideMenuTabs from "./sideBarTabs";
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const MonitorSideBar = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const urlParams = useParams();
  const monitorDetails = props.monitorData;
  const formatMonitorType = (type) => {
    if (type === "MANAGED_PAGE") return "MANAGED_ACCOUNT";
    else if (type === "KEYWORD") return "keyword";
    else {
      return type;
    }
  };
  const email = window.localStorage.getItem("email");
  return (
    <>
      {/* Monitors SIDE MENU */}
      <DrawerContainer
        anchor={window.localStorage.lang !== "ar" ? "left" : "right"}
        open={true}
        variant="persistent"
        className="monitor-sidebar"
      >
        {/* Side Menu Header_______________________________________________________ */}
        <Box className="monitor_details">
          <Box className="monitor_name">
            <Box component="span">{monitorDetails?.attributes?.name}</Box>
            {(handlelUserRoles("SM", "EDIT_OTHERS_MONITOR") ||
              (handlelUserRoles("SM", "EDIT_MONITOR") &&
                monitorDetails?.attributes?.monitor_creator?.email?.toLowerCase() ===
                  email?.toLowerCase())) && (
              <Box className="setting_btn">
                <CustomizedTooltip
                  title={
                    <Box className="tooltip-custom-new">
                      {CheckValueLocale(
                        "monitor_settings_tooltip",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  }
                  arrow
                  placement="bottom"
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      history.push(
                        `/monitor/monitor-settings/${urlParams.monitorId}`,
                      );
                    }}
                    id="monitors-settings-btn"
                  >
                    <FontAwesomeIcon icon={faGear} />
                  </Button>
                </CustomizedTooltip>
              </Box>
            )}
          </Box>
          <Box className="account_type">
            {CheckValueLocale(
              formatMonitorType(monitorDetails?.attributes?.monitor_type_name),
              "",
              {},
              intl,
            )}
          </Box>
        </Box>
        {/* _______________________________________________________ */}

        <SideMenuTabs monitorData={props.monitorData} />
      </DrawerContainer>
    </>
  );
};
export default MonitorSideBar;
