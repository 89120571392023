import { useIntl } from "react-intl";
import { useState } from "react";
import {
  faTimes,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "../../listQuestions.scss";

const FailedAnswer = ({ setSnackbarShow }) => {
  const intl = useIntl();
  const [closeFailed, setCloseFailed] = useState(true);

  return (
    <>
      {closeFailed && (
        <Box
          className="luci-question-incoming-answer failed"
          onClick={() => {
            setSnackbarShow(false);
          }}
        >
          <Typography component={"p"} className="question-item-data failed">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="luci-failed-icon"
            />
            <Box className="luci-failed-msgs">
              <Box className="luci-msg-header">
                {CheckValueLocale("luci_incoming_faild_header", "", {}, intl)}
              </Box>
              <Box className="luci-msg-body">
                {CheckValueLocale("luci_incoming_faild_body", "", {}, intl)}
              </Box>
            </Box>
          </Typography>
          <Button
            className="luci-failed-close-icon"
            onClick={() => {
              setCloseFailed(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Box>
      )}
    </>
  );
};

export default FailedAnswer;
