import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "../../main.scss";
import "./applyingOn.scss";
import LuciHeader from "../luciHeader/luciHeader";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";

const LuciApplyingOn = (props) => {
  const intl = useIntl();

  const {
    setShowLuciAnswers,
    setShowConfirmationPopup,
    setShowRequests,
    individualSurvey,
    page,
    handleCreateFreeQuestions,
    showSurvey,
    setShowSurvey,
  } = props;

  const [expanded, setExpanded] = useState(0);

  const handleIsNoQuestion = (individualSurvey) => {
    let hasShortText = false;
    if (individualSurvey && individualSurvey?.answer_1)
      Object.values(individualSurvey?.answer_1)?.map((section) =>
        section?.questions?.map((question) => {
          if (question?.type === "short_text") return (hasShortText = true);
        }),
      );
    return hasShortText;
  };

  useEffect(() => {
    if (individualSurvey && !handleIsNoQuestion(individualSurvey))
      setShowSurvey({ ...showSurvey, show: false, isNoQusetioin: true });
  }, [individualSurvey]);

  const handleQuestionClick = (item) => {
    item.token = showSurvey?.question?.token;
    item.id = showSurvey?.question?.id;
    let question =
      intl.locale == "en"
        ? showSurvey?.question?.en_text
        : showSurvey?.question?.ar_text;
    setShowSurvey({
      ...showSurvey,
      question: { ...showSurvey?.question, surveyQuestion: item?.question },
    });
    handleCreateFreeQuestions(item, question);
  };

  const handleFreeTextQuestions = (data) => {
    if (data?.length === 1) {
      if (data[0]?.questions?.some((ques) => ques?.type === "short_text"))
        return data[0]?.questions
          ?.filter((ques) => ques?.type === "short_text")
          ?.map((item, index) => {
            return (
              <Box>
                <Button
                  className="apply-items"
                  onClick={() => handleQuestionClick(item)}
                  key={index}
                >
                  <Box component={"span"}>
                    {`${CheckValueLocale("Question", "", {}, intl)} ${
                      index + 1
                    }`}
                  </Box>
                  <Typography>{item?.question}</Typography>
                </Button>
              </Box>
            );
          });
    } else {
      return data?.map((sec, index) => {
        if (sec?.questions?.some((ques) => ques?.type === "short_text")) {
          return (
            <Accordion
              className="insights-accordion"
              onChange={() => {
                expanded == index ? setExpanded(null) : setExpanded(index);
              }}
              expanded={expanded == index}
              key={index}
            >
              <AccordionSummary
                id={sec?.section_id}
                className="insights-accordion-summary"
                aria-controls={sec?.section_id}
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              >
                {`${CheckValueLocale("section", "", {}, intl)} ${index + 1} (${
                  sec?.section_name
                })`}
              </AccordionSummary>
              <AccordionDetails className="insights-accordion-details">
                {sec?.questions
                  ?.filter((ques) => ques?.type === "short_text")
                  ?.map((item, index) => {
                    return (
                      <Box>
                        <Button
                          key={index}
                          className={`apply-items ${
                            index == 0 && `first-question-btn`
                          }`}
                          onClick={() => handleQuestionClick(item)}
                        >
                          <Box component={"span"}>
                            {`${CheckValueLocale("Question", "", {}, intl)} ${
                              index + 1
                            }`}
                          </Box>
                          <Typography>{item?.question}</Typography>
                        </Button>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          );
        }
      });
    }
  };

  return (
    <Box>
      <LuciHeader
        handleCloseSideBar={props?.handleCloseSideBar}
        sectionName="luci_applying_on"
        setShowPage={setShowLuciAnswers}
        setShowConfirmationPopup={setShowConfirmationPopup}
        setShowRequests={setShowRequests}
        showSurvey={showSurvey}
        setShowSurvey={setShowSurvey}
      />
      <Box className="apply-parant">
        <Typography component={"h3"} className="head-title-apply">
          {CheckValueLocale("apply_your_request", "", {}, intl)}
        </Typography>
        <Box>
          {Object.values(individualSurvey)[page - 1] &&
          Object.values(Object.values(individualSurvey)[page - 1])
            ? handleFreeTextQuestions(
                Object.values(Object.values(individualSurvey)[page - 1]),
              )
            : null}
        </Box>
      </Box>
    </Box>
  );
};

export default LuciApplyingOn;
