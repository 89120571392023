import { Box, Tooltip, Button } from "@mui/material";
import "./mainEdit.scss";
import React, { useState, useEffect } from "react";
import PopupModal from "components/popupModal";
import {
  CheckValueLocale,
  isEmptyObj,
  handlelUserRoles,
} from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import sparkIcon from "images/engagements-images/spark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAltLines,
  faTrash,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import Pagination from "@mui/material/Pagination";
import NewReplayPopup from "../setup/newReplayPopup";
import MonitorsController from "services/controllers/monitorsController";
import SnackBar from "components/snackBar.js";
import EngagementsController from "services/controllers/engagementsController";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import Services from "services/api/Services";

const ReplySection = ({ selectedData, setSelectedData }) => {
  const intl = useIntl();
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState({});

  const [repsAiLoading, setRepsAiLoading] = useState(false);
  const [aiPage, setAiPage] = useState(1);
  const [repsPage, setRepsPage] = useState(1);
  const [repsCount, setRepsCount] = useState(0);
  const [repsPages, setRepsPages] = useState(1);
  const [repsList, setRepsList] = useState([]);
  const [repsLoading, setRepsLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [paganationAiList, setPaganationAiList] = useState([]);
  const [newRepPopup, setNewRepPopup] = useState({
    open: false,
    id: null,
    isDuplicate: false,
    title: "",
    contant: "",
    ai: false,
    loading: false,
    done: false,
  });
  const [delPopup, setDelPopup] = useState({
    open: false,
    item: {},
    loading: false,
    done: false,
  });

  const [aiList, setAiList] = useState([]);

  const getAllSavedReplies = (page) => {
    setRepsLoading(true);
    MonitorsController.getAllSavedReplies(
      window?.localStorage?.engagements_id,
      page ? page : repsPage,
    )
      .then((data) => {
        if (data?.data?.pagination) {
          setRepsCount(data?.data?.pagination?.count);
          setRepsPages(data?.data?.pagination?.pages);
        }
        setRepsList([...data?.data?.data]);
        setRepsLoading(false);
        setDelPopup({
          item: {},
          open: false,
          loading: false,
        });
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          ai: false,
          loading: false,
          done: false,
        });
      })
      .catch((err) => {
        setRepsPage(1);
        setRepsCount(0);
        setRepsPages(1);
        setRepsList([]);
        setRepsLoading(false);
        setDelPopup({
          item: {},
          open: false,
          loading: false,
        });
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          ai: false,
          loading: false,
          done: false,
        });
      });
  };

  const handleDeleteSavedReplies = () => {
    setDelPopup({
      ...delPopup,
      loading: true,
    });
    MonitorsController.deleteSavedReplies(
      delPopup?.item?.id,
      window?.localStorage?.engagements_id,
    )
      .then((data) => {
        setRepsPage(1);
        getAllSavedReplies(1);
        setSnackBar({
          open: true,
          title: "",
          message: "rep_deleted",
          severity: "success",
        });
        setDelPopup({
          item: {},
          open: false,
          loading: false,
        });
      })
      .catch((error) => {
        setRepsPage(1);
        getAllSavedReplies(1);
        setSnackBar({
          open: true,
          title: "wrong_request_parameter",
          message: "please_try_again",
          severity: "error",
        });
        setDelPopup({
          item: {},
          open: false,
          loading: false,
        });
      });
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const handleCreateReplies = () => {
    MonitorsController.createSavedReplies(
      window?.localStorage?.engagements_id,
      newRepPopup?.title?.trim(),
      newRepPopup?.contant?.trim(),
    ).then((data) => {
      if (data?.errorMsg) {
        if (
          data?.errorMsg?.response?.data?.exception?.message ==
          "DUPLICATE_REPLY_FOUND"
        ) {
          setNewRepPopup({
            ...newRepPopup,
            isDuplicate: true,
            loading: false,
            done: false,
          });
        } else {
          setNewRepPopup({
            open: false,
            id: null,
            isDuplicate: false,
            title: "",
            contant: "",
            ai: false,
            loading: false,
            done: false,
          });
          setSnackBar({
            open: true,
            title: "wrong_request_parameter",
            message: "please_try_again",
            severity: "error",
          });
        }
      } else {
        if (newRepPopup?.ai) {
          handleAddAirep(newRepPopup?.id);
        }
        setRepsPage(1);
        getAllSavedReplies(1);
        setSnackBar({
          open: true,
          title: "",
          message: "new_rep_added",
          severity: "success",
        });
      }
    });
    setTimeout(() => {
      setSnackBar({
        open: false,
        title: "",
        message: "",
        severity: "",
      });
    }, 7000);
  };

  const getAiReplais = () => {
    setRepsAiLoading(true);
    EngagementsController.getAiReplaies(
      selectedData?.stepTwo?.luci_industry,
      selectedData?.stepTwo?.luci_business_type,
      selectedData?.stepTwo?.luci_business_hq,
      selectedData?.stepTwo?.brand_tone,
      selectedData?.stepTwo?.aboutYour,
      selectedData?.stepTwo?.knowledge_base_url,
      window?.localStorage?.engagements_id,
    ).then((data) => {
      if (data?.data?.data?.password && !isEmptyObj(data?.data?.data)) {
        setResponse({});
        // connectWithRabbitMQ(
        //   mqttConnectionClosed,
        //   clientMQTT,
        //   data?.data?.data,
        //   setResponse
        // );
        mqttRabbitMQResponce(clientMQTT, data?.data?.data, setResponse);
      } else {
        setRepsAiLoading(false);
        mqttConnectionClosed.current = true;
      }
    });
  };

  useEffect(() => {
    if (response?.responses?.length) {
      let arr = [];
      response?.responses?.map((rep, i) => arr.push({ id: i, text: rep }));
      setAiPage(1);
      setAiList([...arr]);
    }
  }, [response]);

  useEffect(() => {
    getAllSavedReplies();
  }, []);

  useEffect(() => {
    if (newRepPopup?.done) {
      handleCreateReplies();
    }
  }, [newRepPopup]);

  useEffect(() => {
    let newArr = [];
    let inatialArr = [...aiList];
    while (inatialArr?.length) newArr.push(inatialArr?.splice(0, 8));
    setPaganationAiList([...newArr]);
    setRepsAiLoading(false);
  }, [aiList]);

  const handleAddAirep = (id) => {
    if (paganationAiList[aiPage - 1]?.length == 1) {
      setAiPage(aiPage == 1 ? aiPage : aiPage - 1);
    }
    setAiList([...aiList?.filter((i) => i?.id !== id)]);
  };

  const delBody = () => {
    return (
      <Box className="shared-dialog-body">
        <p>
          {`${CheckValueLocale("delete_saved_replies_body_engag", "", { name: delPopup?.item?.title }, intl)}`}
        </p>
        <h2 className="sub-text-desc">
          {CheckValueLocale("deleting_msg", "", {}, intl)}
        </h2>
      </Box>
    );
  };

  const repCard = (item, isAi) => {
    return (
      <Box key={item?.id} className="rep-card-countainer">
        {!isAi ? (
          <Box className="rep-card-header">
            <Box>{item?.title}</Box>
            <Box>
              {handlelUserRoles("SAVED_REPLIES", "DELETE_SAVED_REPLY") ||
              handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
                <Tooltip
                  title={CheckValueLocale("dashboard_delete", "", {}, intl)}
                  placement="top"
                  arrow
                >
                  <Box>
                    <FontAwesomeIcon
                      id="engagement-settings-delete-saved-reply-btn"
                      icon={faTrash}
                      onClick={() =>
                        setDelPopup({
                          open: true,
                          item: { ...item },
                        })
                      }
                    />
                  </Box>
                </Tooltip>
              ) : null}
            </Box>
          </Box>
        ) : null}
        <Box className="rep-card-contant">{item?.text}</Box>
        {isAi &&
        (handlelUserRoles("SAVED_REPLIES", "CREATE_SAVED_REPLY") ||
          handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS")) ? (
          <Box className="rep-card-footer">
            <Button
              onClick={() =>
                setNewRepPopup({
                  open: true,
                  id: item?.id,
                  isDuplicate: false,
                  title: "",
                  contant: item?.text,
                  ai: true,
                  loading: false,
                  done: false,
                })
              }
            >
              <Box>
                <FontAwesomeIcon icon={faCommentAltLines} />
              </Box>
              <Box>{CheckValueLocale("add_rep", "", {}, intl)}</Box>
            </Button>
          </Box>
        ) : null}
      </Box>
    );
  };

  const handleChangeAiPage = (event, page) => {
    if (Boolean(page) === false) return;
    if (paganationAiList[page - 1]?.length) {
      setAiPage(page);
    } else {
      setAiPage(page - 1);
    }
  };

  const handleChangeRepsPage = (event, page) => {
    if (Boolean(page) === false) return;
    setRepsPage(page);
    getAllSavedReplies(page);
  };
  return (
    <Box className="main-step-three">
      <Box className="reps-list">
        <Box className="reps-list-header">
          <Box>
            {CheckValueLocale("saved_reps", "", { num: repsCount }, intl)}
          </Box>
          {handlelUserRoles("SAVED_REPLIES", "CREATE_SAVED_REPLY") ||
          handlelUserRoles("SAVED_REPLIES", "FULL_ACCESS") ? (
            <Button
              id="engagement-settings-new-reply-btn"
              className="new-rep-btn"
              onClick={() =>
                setNewRepPopup({
                  open: true,
                  id: null,
                  isDuplicate: false,
                  title: "",
                  contant: "",
                  ai: false,
                  loading: false,
                  done: false,
                })
              }
            >
              <span>+</span>
              {CheckValueLocale("new_rep", "", {}, intl)}
            </Button>
          ) : null}
        </Box>
        <Box className="reps-list-contant">
          {!repsList?.length && repsLoading ? (
            <Box className="fetching-loader">
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </Box>
          ) : (
            <>
              {!repsList?.length ? (
                <Box>{CheckValueLocale("create_first", "", {}, intl)}</Box>
              ) : (
                <>
                  <Box className="list-container">
                    {repsList?.map((item) => repCard(item?.attributes))}
                  </Box>
                  <Box className="reps-list-pagonation new_reps">
                    <Pagination
                      onChange={handleChangeRepsPage}
                      page={repsPage}
                      count={repsPages}
                      variant="outlined"
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box className="ai-list">
        <Box
          className={
            paganationAiList?.length
              ? "ai-list-header not-empty"
              : "ai-list-header"
          }
        >
          <Box className="ai-header-title">
            <Box className="header-title-main">
              {CheckValueLocale("ai_res", "", {}, intl)}
            </Box>
            {Services.showThirdCompany ? (
              <Box className="header-title-sub">
                {CheckValueLocale("ai_prag", "", {}, intl)}
              </Box>
            ) : null}
          </Box>
          <Button
            disabled={
              repsAiLoading ||
              !selectedData?.stepTwo?.luci_industry?.length ||
              !selectedData?.stepTwo?.luci_business_type?.length ||
              !selectedData?.stepTwo?.luci_business_hq?.length ||
              !selectedData?.stepTwo?.brand_tone?.length ||
              !selectedData?.stepTwo?.aboutYour?.length ||
              !selectedData?.stepTwo?.knowledge_base_url?.length
            }
            id="engagement-settings-suggest-responses-btn"
            className="ai-sug-btn"
            onClick={() => getAiReplais()}
          >
            {repsAiLoading ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              <img src={sparkIcon} />
            )}

            <Box>
              {CheckValueLocale(
                !paganationAiList?.length
                  ? repsAiLoading
                    ? "genrate"
                    : "ai_sug"
                  : repsAiLoading
                    ? "genrate"
                    : "re_gen",
                "",
                {},
                intl,
              )}
            </Box>
          </Button>
        </Box>
        {paganationAiList?.length ? (
          <Box className="ai-list-contant">
            <Box>
              {CheckValueLocale("ai_reps", "", { num: aiList?.length }, intl)}
            </Box>
            <Box className="list-container">
              {paganationAiList[aiPage - 1]?.map((item) => repCard(item, true))}
            </Box>
            <Box className="reps-list-pagonation">
              <Pagination
                onChange={handleChangeAiPage}
                page={aiPage}
                count={paganationAiList?.length}
                variant="outlined"
              />
            </Box>
          </Box>
        ) : null}
      </Box>
      {newRepPopup?.open ? (
        <NewReplayPopup
          newRepPopup={newRepPopup}
          setNewRepPopup={setNewRepPopup}
        />
      ) : null}
      {delPopup?.open ? (
        <PopupModal
          title={CheckValueLocale("delete_saved_replies_engag", "", {}, intl)}
          body={delBody()}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
          open={delPopup?.open}
          close={() =>
            !delPopup?.loading &&
            setDelPopup({
              open: false,
              item: {},
              loading: false,
            })
          }
          disableLeftBtn={delPopup?.loading}
          disabled={delPopup?.loading}
          acceptOtherOption={() =>
            setDelPopup({
              item: {},
              open: false,
              loading: false,
            })
          }
          accept={() => {
            setDelPopup({
              ...delPopup,
              loading: true,
            });
            handleDeleteSavedReplies();
          }}
          rightBtnId="delete-saved-reply-engag"
          leftBtnId="cancel-saved-reply-engag"
          warning
          minWidth="600px"
          classeName="actions-buttons-shared"
          isRightBtnLoading={delPopup?.loading}
        />
      ) : null}
      <SnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={CheckValueLocale(snackBar.message, "", {}, intl)}
        title={CheckValueLocale(snackBar.title, "", {}, intl)}
      />
    </Box>
  );
};

export default ReplySection;
