import { useEffect, useState } from "react";
import moment from "moment";

import { Container, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";

import ListHeader from "./list/Header";
import NotificationList from "./list/List";
import NotificationsController from "services/controllers/notificationsController";
import "./list/list.scss";
import Services from "services/api/Services.js";
import UserAccount from "services/controllers/userAccountController";
import CircularLoading from "components/circularProgress";

const NotificationsNew = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [oldDate, setOldDate] = useState(null);
  const [filterData, setFilterData] = useState({
    notificationType: [],
    startDate: moment().subtract(7, "d").startOf("day").unix(),
    endDate: moment().unix(),
  });
  const [notificationType, setNotificationType] = useState(
    filterData.notificationType,
  );

  useEffect(() => {
    NotificationsController.getNotificationsCount().then((res) => {
      setOldDate(res.oldest_date);
    });
  }, []);

  const getNotifications = (pageNum, filters) => {
    !loading && setLoading(true);
    let { notificationType, startDate, endDate } = filters;
    notificationType = notificationType.join(",");
    NotificationsController.getNotifications(
      notificationType,
      startDate,
      endDate,
      12,
      pageNum,
    ).then((res) => {
      setNotifications([...res.data]);
      setTotalPage(res.totalPages || 0);
      setLoading(false);
    });
  };

  useEffect(() => {
    !loading && getNotifications(page, filterData);
  }, [page]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const handelFilters = (filterData) => {
    getNotifications(1, filterData);
    setFilterData({ ...filterData });
    setPage(1);
  };

  const HandelRedirection = async (monitor) => {
    setLoading(true);
    let redirectionUrl = Services.switchUrl;

    let dataSourcesLinksView = {
      KEYWORD: {
        TWITTER: "metrics",
        FACEBOOK: "fb",
        INSTAGRAM: "instagram",
        TALKWALKER: "news",
      },
      MANAGED_PAGE: {
        FACEBOOK: "managed_page/facebook/account-analysis",
      },
      MANAGED_ACCOUNT: {
        INSTAGRAM: "managed_account/instagram/account-analysis",
      },
      ACCOUNT_ANALYSIS: {
        TWITTER: "account_analysis",
        INSTAGRAM: "account_analysis/instagram",
        FACEBOOK: "public_pages/facebook",
      },
      REGION: {
        TWITTER: "metrics",
      },
    };

    let monitor_link =
      "#/" +
      dataSourcesLinksView[monitor.monitor_type_name][
        monitor.monitor_active_sources[0]
      ];
    let redirectLink = `${monitor_link}/${monitor.monitor_id}/${monitor.created_at}/`;

    await UserAccount.getSmData().then((result) => {
      if (result.data !== undefined) {
        localStorage.setItem("user_token", result.data.data.jwt);
        window.open(
          redirectionUrl +
            redirectLink +
            "?lang=" +
            localStorage.lang +
            "&sm_token=" +
            result.data.data.jwt,
          "_self",
        );
      }
    });
  };

  return (
    <Container maxWidth="xl">
      <Box mt={5} mb={5} className="notifications-main-box">
        <ListHeader
          handelFilters={handelFilters}
          filterData={filterData}
          oldDate={oldDate}
          notificationType={notificationType}
          setNotificationType={setNotificationType}
        />
        {loading ? (
          <CircularLoading />
        ) : (
          <>
            <NotificationList
              list={notifications}
              HandelRedirection={HandelRedirection}
              selectNotificationType={notificationType}
            />
            {totalPage > 1 && (
              <Pagination
                onChange={handleChangePage}
                page={page}
                className="pagination-monitor-list pagination-notification-list"
                count={totalPage}
                variant="outlined"
              />
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default NotificationsNew;
