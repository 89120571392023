import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { TeamMonitors } from "../components/teamMonitors";
import { TeamSocialAccount } from "../components/teamSocialAccount";
// commented will be used later

const twitterX = {
  icon: "TWITTER",
  name: "twitter",
  accounts: [
    {
      name: "user1",
      handle: "@user1",
      image_url: "https://i.pravatar.cc/150?img=1",
    },
    {
      name: "user2",
      handle: "@user2",
      image_url: "https://i.pravatar.cc/150?img=2",
    },
    {
      name: "user3",
      handle: "@user3",
      image_url: "https://i.pravatar.cc/150?img=3",
    },
    {
      name: "user4",
      handle: "@user4",
      image_url: "https://i.pravatar.cc/150?img=4",
    },
    {
      name: "user5",
      handle: "@user5",
      image_url: "https://i.pravatar.cc/150?img=5",
    },
    {
      name: "user6",
      handle: "@user6",
      image_url: "https://i.pravatar.cc/150?img=6",
    },
    {
      name: "user7",
      handle: "@user7",
      image_url: "https://i.pravatar.cc/150?img=7",
    },
    {
      name: "user8",
      handle: "@user8",
      image_url: "https://i.pravatar.cc/150?img=8",
    },
    {
      name: "user9",
      handle: "@user9",
      image_url: "https://i.pravatar.cc/150?img=9",
    },
  ],
};
const facebook = {
  icon: "FACEBOOK",
  name: "facebook",
  accounts: [
    {
      name: "user1",
      handle: "@user1",
      image_url: "https://i.pravatar.cc/150?img=1",
    },
    {
      name: "user2",
      handle: "@user2",
      image_url: "https://i.pravatar.cc/150?img=2",
    },
  ],
};

const instagram = {
  icon: "INSTAGRAM",
  name: "instagram",
  accounts: [],
};

const socialAccounts = [twitterX, facebook, instagram];
export const AccoutnsAndMonitors = (props) => {
  const intl = useIntl();
  return (
    <Box className="add-team-section add-team-details">
      <Typography variant="h6" className="add-team-title">
        2. {CheckValueLocale("select_account_and_monitors", "", {}, intl)}
      </Typography>
      <TeamSocialAccount socialAccounts={socialAccounts} />
      <TeamMonitors {...props} monitors={defaultMonitors} selectable={true} />
      <TeamMonitors
        {...props}
        selectedMonitors={props?.selectedChannels}
        handleSelectMonitor={props?.handleSelectChannel}
        monitors={defaultChannels}
        isChannels={true}
        selectable={true}
      />
    </Box>
  );
};

const defaultMonitors = [
  {
    id: "45277",
    type: "monitor_model",
    attributes: {
      id: 45277,
      status: "",
      name: "Webhook Test",
      monitor_type_name: "MANAGED_ACCOUNT",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-09",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["INSTAGRAM"],
      added_by: " Mohamed Tabakh ",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "Webhook Test",
      total_count: 22,
      third_party_unique_id: "alrajhibank",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 6543,
        email: "meltabakh@lucidya.com",
        name: " Mohamed Tabakh ",
      },
      created_at_in_unix: 1704798445,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "45261",
    type: "monitor_model",
    attributes: {
      id: 45261,
      status: "",
      name: "Test",
      monitor_type_name: "KEYWORD",
      paused: 1,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-08",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["TWITTER"],
      added_by: "Ghadeer Qalas",
      data_category: "Social Media",
      channel: ["TWITTER"],
      account_name: "Test",
      total_count: 3698,
      third_party_unique_id: null,
      percentage: -0.9832877516746521,
      stream_status: "paused",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: {
        twitter_keywords_count: 1,
      },
      monitor_creator: {
        id: 5791,
        email: "gqalas@lucidya.com",
        name: "Ghadeer Qalas",
      },
      created_at_in_unix: 1704730085,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "paused",
      customer_care_accounts: [],
    },
  },
  {
    id: "45229",
    type: "monitor_model",
    attributes: {
      id: 45229,
      status: "",
      name: "Test 1",
      monitor_type_name: "MANAGED_PAGE",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-08",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["FACEBOOK"],
      added_by: " Mohamed Tabakh ",
      data_category: "Social Media",
      channel: ["FACEBOOK"],
      account_name: "Test 1",
      total_count: 0,
      third_party_unique_id: "103223686186785",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 6543,
        email: "meltabakh@lucidya.com",
        name: " Mohamed Tabakh ",
      },
      created_at_in_unix: 1704712279,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "45165",
    type: "monitor_model",
    attributes: {
      id: 45165,
      status: "",
      name: "khayyatz",
      monitor_type_name: "ACCOUNT_ANALYSIS",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-07",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["TWITTER"],
      added_by: "Zuhair Khayyat",
      data_category: "Social Media",
      channel: ["TWITTER"],
      account_name: "khayyatz",
      total_count: 16,
      third_party_unique_id: "khayyatz",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 681,
        email: "zuhair@lucidya.com",
        name: "Zuhair Khayyat",
      },
      created_at_in_unix: 1704635702,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "45045",
    type: "monitor_model",
    attributes: {
      id: 45045,
      status: "",
      name: "IslamNewOne",
      monitor_type_name: "MANAGED_ACCOUNT",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-04",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["INSTAGRAM"],
      added_by: "Islam Shaaban",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "IslamNewOne",
      total_count: 34,
      third_party_unique_id: "islam_direct31",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 6067,
        email: "ishaban@lucidya.com",
        name: "Islam Shaaban",
      },
      created_at_in_unix: 1704397924,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "45041",
    type: "monitor_model",
    attributes: {
      id: 45041,
      status: "",
      name: "IslamNewOne",
      monitor_type_name: "MANAGED_ACCOUNT",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-04",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["INSTAGRAM"],
      added_by: "Islam Shaaban",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "IslamNewOne",
      total_count: 34,
      third_party_unique_id: "islam_direct31",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 6067,
        email: "ishaban@lucidya.com",
        name: "Islam Shaaban",
      },
      created_at_in_unix: 1704397720,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "45009",
    type: "monitor_model",
    attributes: {
      id: 45009,
      status: "",
      name: "IslamDirect2",
      monitor_type_name: "MANAGED_ACCOUNT",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-04",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["INSTAGRAM"],
      added_by: "Islam Shaaban",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "IslamDirect2",
      total_count: 34,
      third_party_unique_id: "islam_direct31",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 6067,
        email: "ishaban@lucidya.com",
        name: "Islam Shaaban",
      },
      created_at_in_unix: 1704394702,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "45005",
    type: "monitor_model",
    attributes: {
      id: 45005,
      status: "",
      name: "IslamDirect",
      monitor_type_name: "MANAGED_ACCOUNT",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-04",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["INSTAGRAM"],
      added_by: "Islam Shaaban",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "IslamDirect",
      total_count: 34,
      third_party_unique_id: "islam_direct31",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 6067,
        email: "ishaban@lucidya.com",
        name: "Islam Shaaban",
      },
      created_at_in_unix: 1704394182,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "45001",
    type: "monitor_model",
    attributes: {
      id: 45001,
      status: "",
      name: "IslamKeyword",
      monitor_type_name: "KEYWORD",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-04",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["INSTAGRAM"],
      added_by: "Islam Shaaban",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "IslamKeyword",
      total_count: 1450,
      third_party_unique_id: null,
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: {
        instagram_hashtags_count: 1,
      },
      monitor_creator: {
        id: 6067,
        email: "ishaban@lucidya.com",
        name: "Islam Shaaban",
      },
      created_at_in_unix: 1704394151,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
  {
    id: "44997",
    type: "monitor_model",
    attributes: {
      id: 44997,
      status: "",
      name: "LucidyaIslam",
      monitor_type_name: "ACCOUNT_ANALYSIS",
      paused: 0,
      description: "",
      is_deleted: 0,
      created_at: "2024-01-04",
      is_dm: false,
      is_engagement_monitor: false,
      data_sources: ["FACEBOOK"],
      added_by: "Islam Shaaban",
      data_category: "Social Media",
      channel: ["FACEBOOK"],
      account_name: "LucidyaIslam",
      total_count: 61,
      third_party_unique_id: "lucidyaislam",
      percentage: 0,
      stream_status: "collecting",
      limit_error: [],
      monitor_owner: false,
      keywords_stats: null,
      monitor_creator: {
        id: 6067,
        email: "ishaban@lucidya.com",
        name: "Islam Shaaban",
      },
      created_at_in_unix: 1704391349,
      dm_channel: 0,
      dm_configuration_id: 0,
      topics: [],
      account_error: [],
      account_valid_error: [],
      monitor_account_name: "",
      auth_error_login: [],
      auth_error_permission: [],
      monitor_warning: "",
      customer_care_accounts: [],
    },
  },
];

const defaultChannels = [
  {
    id: "45301",
    type: "channel",
    attributes: {
      id: 45301,
      status: "",
      name: "Test-luc-1",
      monitor_type_name: "MANAGED_ACCOUNT",
      paused: 1,
      created_at: "2024-01-09",
      data_sources: ["LINKEDIN"],
      is_engagement_monitor: false,
      added_by: "Khaled Alasmari",
      authenticated_by: "Khaled Alasmari",
      data_category: "Social Media",
      channel: ["LINKEDIN"],
      account_name: "Test-luc-1",
      total_count: 0,
      third_party_unique_id: "Test-luc-1",
      percentage: 0,
      stream_status: "paused",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 0,
      dm_configuration_id: 0,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: false,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "45257",
    type: "channel",
    attributes: {
      id: 45257,
      status: "",
      name: "Shoroog Lucidya2",
      monitor_type_name: "Monitor_dm_configuration",
      paused: 0,
      created_at: "2024-01-08",
      data_sources: ["INSTAGRAM"],
      is_engagement_monitor: false,
      added_by: "Shoroog Alghamdi",
      authenticated_by: "Mohameddd Saad Farid",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "51406742021",
      total_count: 0,
      third_party_unique_id: null,
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 1,
      dm_configuration_id: 1181,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: true,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44369",
    type: "channel",
    attributes: {
      id: 44369,
      status: "",
      name: "Mostafa Salama",
      monitor_type_name: "Monitor_dm_configuration",
      paused: 0,
      created_at: "2024-01-01",
      data_sources: ["INSTAGRAM"],
      is_engagement_monitor: true,
      added_by: "Motaman Ali",
      authenticated_by: "Mostafa Salama",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "54559985204",
      total_count: 0,
      third_party_unique_id: null,
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 1,
      dm_configuration_id: 1153,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: true,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44245",
    type: "channel",
    attributes: {
      id: 44245,
      status: "",
      name: "Testing_public_pages",
      monitor_type_name: "Monitor_dm_configuration",
      paused: 0,
      created_at: "2023-12-31",
      data_sources: ["FACEBOOK"],
      is_engagement_monitor: true,
      added_by: "lobna abdelhamed",
      authenticated_by: "lobna abdelhamed",
      data_category: "Social Media",
      channel: ["FACEBOOK"],
      account_name: "101169692979423",
      total_count: 0,
      third_party_unique_id: null,
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 1,
      dm_configuration_id: 1145,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: true,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44241",
    type: "channel",
    attributes: {
      id: 44241,
      status: "",
      name: "Testing classic page",
      monitor_type_name: "Monitor_dm_configuration",
      paused: 0,
      created_at: "2023-12-31",
      data_sources: ["FACEBOOK"],
      is_engagement_monitor: true,
      added_by: "lobna abdelhamed",
      authenticated_by: "lobna abdelhamed",
      data_category: "Social Media",
      channel: ["FACEBOOK"],
      account_name: "104024022650877",
      total_count: 0,
      third_party_unique_id: null,
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 1,
      dm_configuration_id: 1141,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: true,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44237",
    type: "channel",
    attributes: {
      id: 44237,
      status: "",
      name: "Lobna Abdelhamed",
      monitor_type_name: "Monitor_dm_configuration",
      paused: 0,
      created_at: "2023-12-31",
      data_sources: ["TWITTER"],
      is_engagement_monitor: true,
      added_by: "lobna abdelhamed",
      authenticated_by: "lobna abdelhamed",
      data_category: "Social Media",
      channel: ["TWITTER"],
      account_name: "lobna_lucidya",
      total_count: 0,
      third_party_unique_id: null,
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 1,
      dm_configuration_id: 1137,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: true,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44157",
    type: "channel",
    attributes: {
      id: 44157,
      status: "",
      name: "Abdulrahman Hassan",
      monitor_type_name: "ACCOUNT_ANALYSIS",
      paused: 0,
      created_at: "2023-12-31",
      data_sources: ["GMAIL"],
      is_engagement_monitor: false,
      added_by: "yazeed ",
      authenticated_by: "Abdelrahman Hassan ( Deleted )",
      data_category: "emails",
      channel: ["GMAIL"],
      account_name: "ahassan@lucidya.com",
      total_count: 0,
      third_party_unique_id: "ahassan@lucidya.com",
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 0,
      dm_configuration_id: 0,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: false,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44101",
    type: "channel",
    attributes: {
      id: 44101,
      status: "",
      name: "islam direct",
      monitor_type_name: "Monitor_dm_configuration",
      paused: 0,
      created_at: "2023-12-30",
      data_sources: ["INSTAGRAM"],
      is_engagement_monitor: true,
      added_by: "Islam Shaaban",
      authenticated_by: "Islam Shaaban",
      data_category: "Social Media",
      channel: ["INSTAGRAM"],
      account_name: "59194784968",
      total_count: 0,
      third_party_unique_id: null,
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 1,
      dm_configuration_id: 1101,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: true,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44093",
    type: "channel",
    attributes: {
      id: 44093,
      status: "",
      name: "Mahmoud Abu Hasna",
      monitor_type_name: "ACCOUNT_ANALYSIS",
      paused: 0,
      created_at: "2023-12-30",
      data_sources: ["GMAIL"],
      is_engagement_monitor: false,
      added_by: "Alaa Mansour",
      authenticated_by: "Mahmoud  Abu Hasna",
      data_category: "emails",
      channel: ["GMAIL"],
      account_name: "mabuhasna@lucidya.com",
      total_count: 0,
      third_party_unique_id: "mabuhasna@lucidya.com",
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 0,
      dm_configuration_id: 0,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: false,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
  {
    id: "44017",
    type: "channel",
    attributes: {
      id: 44017,
      status: "",
      name: "Mostafa",
      monitor_type_name: "MANAGED_PAGE",
      paused: 0,
      created_at: "2023-12-29",
      data_sources: ["FACEBOOK"],
      is_engagement_monitor: false,
      added_by: "Mostafa Salama",
      authenticated_by: "Mostafa Salama",
      data_category: "Social Media",
      channel: ["FACEBOOK"],
      account_name: "Mostafa",
      total_count: 10,
      third_party_unique_id: "101404152670824",
      percentage: 0,
      stream_status: "collecting",
      monitor_owner: false,
      disable_reauth: false,
      dm_channel: 0,
      dm_configuration_id: 0,
      limit_error: [],
      account_error: [],
      account_tiktok_token_error: false,
      account_valid_error: [],
      is_dm: false,
      auth_error_login: [],
      auth_error_permission: [],
      customer_care_accounts: [],
    },
  },
];
