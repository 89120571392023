import React from "react";
import ReactEcharts from "echarts-for-react";

//

// import wordcloud lib
require("echarts-wordcloud");

const formatToolTip = (data) => `${data.marker} ${data.name}: ${data.value}`;

const WordCloud = (props) => {
  let ActiveHashtags = [];
  let hashtags = props.data;
  // Working on Color Values
  let totalData = 0,
    elementRatio = 0,
    elements = [],
    savedColor = [];
  hashtags.map((d) => {
    totalData += d.value;
  });
  hashtags.map((e) => {
    elementRatio = e.value / totalData;
    elements.push(elementRatio);
  });
  elements = elements.sort();
  elements = elements.reverse();
  // Generate Colors :
  // lightness is : 0% is black, and 100% is white
  let max = 100,
    min = 20,
    length = props.data.length > 9 ? props.data.length : 10,
    arrayOfColors = [],
    averageValue = (max - min) / length;
  for (let i = min; i < max; i += averageValue) {
    arrayOfColors.push(i);
  }
  hashtags.map(function (activeHashtag) {
    ActiveHashtags.push({
      name: activeHashtag.name,
      value: activeHashtag.value,
      itemStyle: {
        normal: {},
        emphasis: {},
      },
    });
    return activeHashtag;
  });

  let option = {
    series: [
      {
        type: "wordCloud",
        shape: "circle",
        left: "center",
        top: "center",
        width: "90%",
        height: "80%",
        right: null,
        bottom: null,
        sizeRange: [14, 50],
        rotationRange: [0, 0],
        rotationStep: 0,
        gridSize: 22,
        cursor: props.handleOpenQuickInspect ? "pointer" : "default",
        // Global text style
        textStyle: {
          fontWeight: "bold",
          // Color can be a callback function or a color string
          color: function (e) {
            let color = "";
            if (e.dataIndex !== 0) {
              if (elements[e.dataIndex] === elements[e.dataIndex - 1]) {
                color = savedColor[e.dataIndex - 1];
              } else {
                color =
                  "hsl(" +
                  [198, "76%", arrayOfColors[e.dataIndex] + "%"].join(",") +
                  ")";
              }
            } else {
              color =
                "hsl(" +
                [198, "76%", arrayOfColors[e.dataIndex] + "%"].join(",") +
                ")";
            }
            savedColor.push(color);
            return color;
          },
        },

        // Data is an array. Each array item must have name and value property.
        data: ActiveHashtags,
      },
    ],
    tooltip: {
      trigger: "item",
      backgroundColor: "#fff",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
      },
      formatter: (val) => formatToolTip(val),
    },
  };
  return (
    <ReactEcharts
      option={option}
      style={{ height: "350px", width: "100%" }}
      theme="custom_theme"
    />
  );
};

export default WordCloud;
