import { Box, Grid, Typography } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import BarChartWidget from "components/widgets/barChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import AllTopMedia from "../../components/allTopMeda/allTopMedia";
import AllPosts from "../../components/allPosts/allPosts";
import {
  handleAggregateLineChartOneValue,
  handleAggregateLegends,
  handleAggregateBarCharts,
  handleAggregatePieChart,
  handlePostsInteractionsLegends,
  handleContentTypeInteractionsLegends,
} from "utils/helpers/aggregateFunctions";
import {
  handleDates,
  getNoOfDays,
  CheckValueLocale,
} from "utils/helpers/index";
import _ from "lodash";

const PostAnalytics = (props) => {
  const intl = useIntl();
  const {
    widgetsName,
    getIcons,
    metricsValues,
    monitorType,
    dashboardID,
    response,
    insightsID,
    insightsResponse,
    dates,
    updateAnalysis,
    isPublicAccount,
    resetAllFields,
    startDate,
    endDate,
  } = props;

  //handle response
  const [volumeOvertime, setVolumeOvertime] = useState([]);
  const [volumeOvertimeCount, setVolumeOvertimeCount] = useState(0);
  const [
    volumeOvertimePreLoaderDataSources,
    setVolumeOvertimePreLoaderDataSources,
  ] = useState([]);

  const [postReachImpressions, setPostReachImpressions] = useState([]);
  const [postReachImpressionsLegends, setPostReachImpressionsLegends] =
    useState([]);
  const [reachArray, setReachArray] = useState([]);
  const [impressionArray, setImpressionArray] = useState([]);
  const [
    postReachImpressionsPreLoaderDataSources,
    setPostReachImpressionsPreLoaderDataSources,
  ] = useState([]);

  const [averageEngagements, setAverageEngagements] = useState([]);
  const [maxAverageEngagements, setMaxAverageEngagements] = useState(0);
  const [minAverageEngagements, setMinAverageEngagements] = useState(0);
  const [maxAverageEngagementsDate, setMaxAverageEngagementsDate] =
    useState("");
  const [minAverageEngagementsDate, setMinAverageEngagementsDate] =
    useState("");
  const [avgAverageEngagements, setAvgAverageEngagements] = useState(0);
  const [
    averageEngagementsPreLoaderDataSources,
    setAverageEngagementsPreLoaderDataSources,
  ] = useState([]);

  const [interactions, setInteractions] = useState([]);
  const [interactionsLegends, setInteractionsLegends] = useState([]);
  const [maxInteractions, setMaxInteractions] = useState(0);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [maxInteractionsDate, setMaxInteractionsDate] = useState("");
  const [avgInteractions, setAvgInteractions] = useState(0);
  const [
    interactionsPreLoaderDataSources,
    setInteractionsPreLoaderDataSources,
  ] = useState([]);

  const [contentType, setContentType] = useState([]);
  const [contentTypeLegends, setContentTypeLegends] = useState([]);
  const [contentTypePreLoaderDataSources, setContentTypePreLoaderDataSources] =
    useState([]);

  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsCount, setVideoViewsCount] = useState(0);
  const [videoViewsPreLoaderDataSources, setVideoViewsPreLoaderDataSources] =
    useState([]);

  const [reactions, setReactions] = useState([]);
  const [reactionsLegends, setReactionsLegends] = useState([]);
  const [reactionsPreLoaderDataSources, setReactionsPreLoaderDataSources] =
    useState([]);

  const [twitterTopVideos, setTwitterTopVideos] = useState([]);
  const [
    twitterTopVideosPreLoaderDataSources,
    setTwitterTopVideosPreLoaderDataSources,
  ] = useState([]);

  const [instagramTopVideos, setInstagramTopVideos] = useState([]);
  const [
    instagramTopVideosPreLoaderDataSources,
    setInstagramTopVideosPreLoaderDataSources,
  ] = useState([]);

  const [fbTopVideos, setFbTopVideos] = useState([]);
  const [fbTopVideosPreLoaderDataSources, setFbTopVideosPreLoaderDataSources] =
    useState([]);

  const [twitterTopImages, setTwitterTopImages] = useState([]);
  const [
    twitterTopImagesPreLoaderDataSources,
    setTwitterTopImagesPreLoaderDataSources,
  ] = useState([]);

  const [instagramTopImages, setInstagramTopImages] = useState([]);
  const [
    instagramTopImagesPreLoaderDataSources,
    setInstagramTopImagesPreLoaderDataSources,
  ] = useState([]);

  const [fbTopImages, setFbTopImages] = useState([]);
  const [fbTopImagesPreLoaderDataSources, setFbTopImagesPreLoaderDataSources] =
    useState([]);

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoaderDataSources, setTopKeywordsPreLoaderDataSources] =
    useState([]);

  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoaderDataSources, setTopHashtagsPreLoaderDataSources] =
    useState([]);

  const [twTopPostComments, setTwTopPostComments] = useState([]);
  const [topPostComments, setTopPostCommentsCount] = useState(0);
  const [
    twTopPostCommentsPreLoaderDataSources,
    setTwTopPostCommentsPreLoaderDataSources,
  ] = useState([]);

  const [igTopPostComments, setIgTopPostComments] = useState([]);
  const [
    igTopPostCommentsPreLoaderDataSources,
    setIgTopPostCommentsPreLoaderDataSources,
  ] = useState([]);

  const [fbTopPostComments, setFbTopPostComments] = useState([]);
  const [
    fbTopPostCommentsPreLoaderDataSources,
    setFbTopPostCommentsPreLoaderDataSources,
  ] = useState([]);

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitter__AccountPage__volume_overtime":
      case "Facebook__AccountPage__volume_overtime":
      case "Instagram__AccountPage__volume_overtime":
        handleAggregateLineChartOneValue(
          response.eventData.posts_over_time,
          volumeOvertime,
        );
        setVolumeOvertimeCount(
          volumeOvertimeCount + response.eventData.total_posts_count,
        );
        setVolumeOvertimePreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);

        break;
      case "Instagram__AccountPage__reach_funnel":
      case "Facebook__AccountPage__reach_funnel":
        handleAggregateLineChartOneValue(
          response.eventData.reach_funnel_overtime.impression,
          impressionArray,
        );
        handleAggregateLineChartOneValue(
          response.eventData.reach_funnel_overtime.reach,
          reachArray,
        );
        (reachArray?.length > 0 || impressionArray?.length > 0) &&
          setPostReachImpressions({
            reach: reachArray,
            impression: impressionArray,
          });
        handleAggregateLegends(
          response.eventData.reach_funnel_legends,
          postReachImpressionsLegends,
        );
        setPostReachImpressionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__AccountPage__average_engagement_per_post":
        setAverageEngagements(
          response.eventData.posts_interactions.posts_engagements_over_time,
        );
        setMaxAverageEngagements(
          response.eventData.posts_interactions.max_engagements,
        );
        setMinAverageEngagements(
          response.eventData.posts_interactions.min_engagements,
        );
        handleDates(
          response.eventData.posts_interactions.max_engagement_date,
          setMaxAverageEngagementsDate,
        );
        handleDates(
          response.eventData.posts_interactions.min_engagement_date,
          setMinAverageEngagementsDate,
        );
        setAvgAverageEngagements(
          response.eventData.posts_interactions.average_interactions,
        );
        setAverageEngagementsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__posts_interactions":
      case "Facebook__AccountPage__posts_interactions":
      case "Instagram__AccountPage__posts_interactions":
        handleAggregateBarCharts(
          response.eventData.posts_interactions.posts_interactions_over_time,
          interactions,
        );
        handlePostsInteractionsLegends(
          response.eventData.posts_interactions.posts_interactions_legends,
          interactionsLegends,
          metricsValues?.interactions &&
            _.cloneDeep([...metricsValues?.interactions?.data_sources]),
        );
        setTotalInteractions(
          totalInteractions +
            response?.eventData?.posts_interactions?.total_interactions,
        );
        if (
          maxInteractions <
          response?.eventData?.posts_interactions?.max_interactions
        ) {
          setMaxInteractions(
            response?.eventData?.posts_interactions?.max_interactions,
          );
          handleDates(
            response?.eventData?.posts_interactions?.max_interaction_date,
            setMaxInteractionsDate,
          );
        }
        setInteractionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__content_type":
      case "Instagram__AccountPage__content_type":
      case "Facebook__AccountPage__content_type":
        handleAggregateBarCharts(
          response.eventData.posts_content_type,
          contentType,
        );
        handleContentTypeInteractionsLegends(
          response.eventData.posts_content_types_legends,
          contentTypeLegends,
          metricsValues?.interactions &&
            _.cloneDeep([...metricsValues?.content_type?.data_sources]),
        );
        setContentTypePreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__page_videos_views":
        setVideoViews(response.eventData.page_videos_views_overtime);
        setVideoViewsCount(response.eventData.total_page_videos_views[0].value);
        setVideoViewsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__reactions":
        setReactions(
          response.eventData.posts_reactions.posts_reactions_over_time,
        );
        setReactionsLegends(
          response.eventData.posts_reactions.posts_reactions_piechart,
        );
        setReactionsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__top_videos":
        setTwitterTopVideos(response.eventData.top_videos);
        setTwitterTopVideosPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__AccountPage__top_videos":
        setInstagramTopVideos(response.eventData.top_videos);
        setInstagramTopVideosPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__top_videos":
        setFbTopVideos(response.eventData.top_videos);
        setFbTopVideosPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__top_images":
        setTwitterTopImages(response.eventData.top_images);
        setTwitterTopImagesPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__AccountPage__top_images":
        setInstagramTopImages(response.eventData.top_images);
        setInstagramTopImagesPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__top_images":
        setFbTopImages(response.eventData.top_images);
        setFbTopImagesPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__top_keywords":
      case "Facebook__AccountPage__top_keywords":
      case "Instagram__AccountPage__top_keywords":
        handleAggregatePieChart(
          response.eventData.top_keywords,
          topKeywords,
          "id",
          "stats_count",
          true,
        );
        setTopKeywordsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__top_hashtags":
      case "Facebook__AccountPage__top_hashtags":
      case "Instagram__AccountPage__top_hashtags":
        handleAggregatePieChart(
          response.eventData.top_hashtags,
          topHashtags,
          "id",
          "stats_count",
          true,
        );
        setTopHashtagsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__AccountPage__top_posts_comments":
        setTwTopPostComments(response.eventData.top_posts);
        setTopPostCommentsCount(
          topPostComments + response.eventData.top_posts_count,
        );
        setTwTopPostCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__AccountPage__top_posts_comments":
        setFbTopPostComments(response.eventData.top_posts);
        setTopPostCommentsCount(
          topPostComments + response.eventData.top_posts_count,
        );
        setFbTopPostCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__AccountPage__top_posts_comments":
        setIgTopPostComments(response.eventData.top_posts);
        setTopPostCommentsCount(
          topPostComments + response.eventData.top_posts_count,
        );
        setIgTopPostCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (
      metricsValues?.interactions &&
      _.isEqual(
        interactionsPreLoaderDataSources?.sort(),
        [...metricsValues?.interactions?.data_sources]?.sort(),
      ) === true
    ) {
      let tempArr = [];
      interactions?.map((interaction) => {
        tempArr?.push({
          date: interaction?.name,
          totalValue: eval(interaction?.value?.join("+")),
        });
      });
      let maxValueObject =
        tempArr?.length !== 0 &&
        tempArr?.reduce((max, arr) =>
          max.totalValue > arr?.totalValue ? max : arr,
        );
      setMaxInteractions(maxValueObject?.totalValue);
      handleDates(maxValueObject?.date, setMaxInteractionsDate);
      const noOfDays = getNoOfDays(startDate, endDate);
      const avg = totalInteractions / noOfDays;
      setAvgInteractions(avg % 1 !== 0 ? avg.toFixed(2) : avg);
    }
  }, [interactionsPreLoaderDataSources]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setVolumeOvertime([]);
      setVolumeOvertimeCount(0);
      setVolumeOvertimePreLoaderDataSources([]);

      setPostReachImpressions([]);
      setPostReachImpressionsLegends([]);
      setReachArray([]);
      setImpressionArray([]);
      setPostReachImpressionsPreLoaderDataSources([]);

      setAverageEngagements([]);
      setMaxAverageEngagements(0);
      setMinAverageEngagements(0);
      setMaxAverageEngagementsDate("");
      setMinAverageEngagementsDate("");
      setAvgAverageEngagements(0);
      setAverageEngagementsPreLoaderDataSources([]);

      setInteractions([]);
      setInteractionsLegends([]);
      setMaxInteractions(0);
      setTotalInteractions(0);
      setMaxInteractionsDate("");
      setAvgInteractions(0);
      setInteractionsPreLoaderDataSources([]);

      setContentType([]);
      setContentTypeLegends([]);
      setContentTypePreLoaderDataSources([]);

      setVideoViews([]);
      setVideoViewsCount(0);
      setVideoViewsPreLoaderDataSources([]);

      setReactions([]);
      setReactionsLegends([]);
      setReactionsPreLoaderDataSources([]);

      setTwitterTopVideos([]);
      setTwitterTopVideosPreLoaderDataSources([]);

      setInstagramTopVideos([]);
      setInstagramTopVideosPreLoaderDataSources([]);

      setFbTopVideos([]);
      setFbTopVideosPreLoaderDataSources([]);

      setTwitterTopImages([]);
      setTwitterTopImagesPreLoaderDataSources([]);

      setInstagramTopImages([]);
      setInstagramTopImagesPreLoaderDataSources([]);

      setFbTopImages([]);
      setFbTopImagesPreLoaderDataSources([]);

      setTopKeywords([]);
      setTopKeywordsPreLoaderDataSources([]);

      setTopHashtags([]);
      setTopHashtagsPreLoaderDataSources([]);

      setTwTopPostComments([]);
      setTopPostCommentsCount(0);
      setTwTopPostCommentsPreLoaderDataSources([]);

      setFbTopPostComments([]);
      setFbTopPostCommentsPreLoaderDataSources([]);

      setIgTopPostComments([]);
      setIgTopPostCommentsPreLoaderDataSources([]);
    }
  }, [resetAllFields]);

  let interactionsLabels = interactionsLegends?.map((item) => item.name);
  let contentTypeLabels = contentTypeLegends?.map((item) => item.name);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalInteractions,
    },
    {
      name: "max_interaction",
      value: maxInteractions,
      date: maxInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgInteractions,
    },
  ];

  return (
    <>
      <Box mt={3}>
        {widgetsName?.length > 1 && (
          <Typography className="section-title">
            {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
          </Typography>
        )}
      </Box>
      {widgetsName?.includes("volume_overtime") && (
        <Box mt={3} className="posts-volume-chart">
          <LineChartWidget
            title={"posts_volume"}
            titleToolTip={
              isPublicAccount
                ? "posts_volume_public_ig_tooltip"
                : "posts_volume_fb_tooltip"
            }
            totalLabel={"posts"}
            totalValue={volumeOvertimeCount}
            data={volumeOvertime}
            topPostsCount={volumeOvertimeCount}
            showGroupBy={true}
            toolTipLabel={"posts"}
            labelColor={"#2AAAE2"}
            showDownloadIcon
            bigImg
            iconAfterTitle={getIcons([
              ...metricsValues?.volume_overtime?.data_sources,
            ])}
            lineChartPreLoaderDataSources={volumeOvertimePreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.volume_overtime?.data_sources,
            ]}
          />
        </Box>
      )}
      {widgetsName?.includes("posts_reach_impressions") && (
        <Box mt={3}>
          <StackedLineWidget
            title={"posts_reach_impressions"}
            data={postReachImpressions}
            showGroupBy={true}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI={false}
            showDownloadIcon
            bigImg
            isPostsReachImpression
            stackedLineWidgetPreLoaderDataSources={
              postReachImpressionsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={[
              ...metricsValues?.posts_reach_impressions?.data_sources,
            ]}
            isSwitchCase
            iconAfterTitle={getIcons([
              ...metricsValues?.posts_reach_impressions?.data_sources,
            ])}
            showGroupByClientAgent={true}
            showScales
            labels={["reach", "impressions"]}
            labelColors={["#2AAAE2", "#80868C"]}
            colors={["#2AAAE2", "#80868C"]}
            titleToolTip={"posts_reach_impressions_ig_tooltip"}
            hideLegend
            dataPiechart={postReachImpressionsLegends}
          />
        </Box>
      )}

      {widgetsName?.includes("average_engagements_per_post") && (
        <Box mt={3} className="average-engagements-per-post">
          <BarChartWidget
            title={"average_engagements_per_post"}
            titleToolTip={"average_engagements_per_post_ig_tooltip"}
            data={averageEngagements}
            showGroupBy
            dataColor="#2AAAE2"
            showChartDetailsWidget
            statsDetailsData={[
              {
                name: "max_interactions_per_post",
                value: maxAverageEngagements,
                date: maxAverageEngagementsDate,
              },
              {
                name: "min_interactions_per_post",
                value: minAverageEngagements,
                date: minAverageEngagementsDate,
              },
              {
                name: "avg_interaction_per_post",
                value: avgAverageEngagements,
              },
            ]}
            toolTipLabel={"posts"}
            showDownloadIcon
            bigImg
            iconAfterTitle={getIcons([
              ...metricsValues?.average_engagements_per_post?.data_sources,
            ])}
            showZoomPercentage
            barChartPreLoaderDataSources={
              averageEngagementsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={[
              ...metricsValues?.average_engagements_per_post?.data_sources,
            ]}
          />
        </Box>
      )}

      {widgetsName?.includes("interactions") && (
        <Box mt={3}>
          <StackedBarWidget
            title={"posts_interactions"}
            titleToolTip={"posts_interactions_tooltip_dash_account"}
            showDownloadIcon
            bigImg
            posts={interactions}
            showChartDetailsWidget
            showScales
            showGroupBy={false}
            isInteractionStatsWrapperStyle
            statsDetailsData={interactionsStats}
            colors={["#2AAAE2", "#80868C", "#334155"]}
            labels={interactionsLabels}
            showPostsInteractionStats
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.interactions?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={interactionsPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.interactions?.data_sources,
            ]}
            hideLegend
            dataPiechart={interactionsLegends}
          />
        </Box>
      )}

      {widgetsName?.includes("content_type") && (
        <Box mt={3}>
          <StackedBarWidget
            title={"posts_content_type"}
            titleToolTip="posts_content_type_tw_tooltip"
            posts={contentType}
            colors={["#2AAAE2", "#80868C", "#334155", "#AFBCCE"]}
            labels={contentTypeLabels}
            showDownloadIcon
            showScales
            bigImg
            showGroupBy={false}
            showZoomPercentage
            iconAfterTitle={getIcons(
              ...[metricsValues?.content_type?.data_sources],
            )}
            postsColumnPreLoaderDataSources={contentTypePreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.content_type?.data_sources,
            ]}
            hideLegend
            dataPiechart={contentTypeLegends}
          />
        </Box>
      )}
      {widgetsName?.includes("videos_views") && (
        <Box mt={3}>
          <LineChartWidget
            title={"videos_views"}
            totalLabel={"video_content_type"}
            totalValue={videoViewsCount}
            data={videoViews}
            showGroupBy={true}
            toolTipLabel={"video_content_type"}
            showDownloadIcon
            bigImg
            lineChartPreLoaderDataSources={videoViewsPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.videos_views?.data_sources,
            ]}
            iconAfterTitle={getIcons([
              ...metricsValues?.videos_views?.data_sources,
            ])}
            labelColor={"#2AAAE2"}
            showReportIcon
            titleToolTip={"videos_views_fb_tooltip"}
          />
        </Box>
      )}
      {widgetsName?.includes("reactions") && (
        <Box my={3}>
          <StackedBarWidget
            title={"dashboard_posts_reactions"}
            titleToolTip={
              isPublicAccount
                ? "reactions_fb_tooltip_dash"
                : "reactions_fb_tooltip"
            }
            showDownloadIcon
            isPublicAccount
            bigImg
            posts={reactions}
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.reactions?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={reactionsPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.reactions?.data_sources,
            ]}
            showGroupByClientAgent={true}
            showScales
            labels={[
              "like_reaction",
              "love_reaction",
              "care_reaction",
              "haha_reaction",
              "wow_reaction",
              "angry_reaction",
              "sad_reaction",
            ]}
            hideLegend
            dataPiechart={reactionsLegends}
            colors={[
              "#1892F2",
              "#FB5C77",
              "#F7B94D",
              "#FEDC5F",
              "#00D7B5",
              "#EC8A92",
              "#1C404E",
            ]}
          />
        </Box>
      )}

      {widgetsName?.includes("top_videos") && (
        <Box my={4}>
          <AllTopMedia
            title={CheckValueLocale("dashboard_top_videos", "", {}, intl)}
            titleToolTip={
              isPublicAccount
                ? "top_videos_tooltip_public_dash"
                : "top_videos_tooltip_managed_dash"
            }
            twData={twitterTopVideos}
            igData={instagramTopVideos}
            fbData={fbTopVideos}
            errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
            iconAfterTitle={getIcons([
              ...metricsValues?.top_videos?.data_sources,
            ])}
            showDownloadIcon
            hideMoreInsights
            mediaTwPreLoaderDataSources={twitterTopVideosPreLoaderDataSources}
            mediaInstaPreLoaderDataSources={
              instagramTopVideosPreLoaderDataSources
            }
            mediaFbPreLoaderDataSources={fbTopVideosPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_videos?.data_sources,
            ]}
            allDatasources={[...metricsValues?.top_videos?.data_sources]}
            insightsID={insightsID}
            response={insightsResponse}
            dates={dates}
          />
        </Box>
      )}
      {widgetsName?.includes("top_images") && (
        <Box my={4}>
          <AllTopMedia
            title={CheckValueLocale("dashboard_top_images", "", {}, intl)}
            titleToolTip={
              isPublicAccount
                ? "top_images_tooltip_public_dash"
                : "top_images_tooltip_managed_dash"
            }
            twData={twitterTopImages}
            igData={instagramTopImages}
            fbData={fbTopImages}
            errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
            iconAfterTitle={getIcons([
              ...metricsValues?.top_images?.data_sources,
            ])}
            showDownloadIcon
            hideMoreInsights
            mediaTwPreLoaderDataSources={twitterTopImagesPreLoaderDataSources}
            mediaInstaPreLoaderDataSources={
              instagramTopImagesPreLoaderDataSources
            }
            mediaFbPreLoaderDataSources={fbTopImagesPreLoaderDataSources}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_images?.data_sources,
            ]}
            allDatasources={[...metricsValues?.top_images?.data_sources]}
            insightsID={insightsID}
            response={insightsResponse}
            dates={dates}
          />
        </Box>
      )}

      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("top_keywords") && (
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"posts_top_keywords"}
                titleToolTip={
                  isPublicAccount
                    ? "posts_top_keywords_fb_tooltip"
                    : "posts_top_keywords_fb_tooltip"
                }
                data={topKeywords}
                leftColomnTitle={"keywords"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={topKeywordsPreLoaderDataSources}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_keywords?.data_sources,
                ]}
                keywords
                tablePagination
                count={topKeywords?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_keywords?.data_sources,
                ])}
              />
            </Grid>
          )}
          {widgetsName?.includes("top_hashtags") && (
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"posts_top_hashtags"}
                titleToolTip={
                  isPublicAccount
                    ? "posts_top_hashtags_tooltip_dash"
                    : "posts_top_hashtags_fb_tooltip"
                }
                data={topHashtags}
                leftColomnTitle={"hashtags"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={topHashtagsPreLoaderDataSources}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_hashtags?.data_sources,
                ]}
                hashtags
                tablePagination
                count={topHashtags?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_hashtags?.data_sources,
                ])}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {widgetsName?.includes("top_posts_comments") && (
        <Box>
          <Box className="top-posts-head">
            <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
            <Link
              to={{
                pathname: `/dashboard/${monitorType}/posts_page/${dashboardID}`,
                state: { dashboardLinkFlag: true },
              }}
              className="top-posts-all"
              id={`check-all-posts-dashboard-${monitorType?.toLowerCase()}-posts-page-${dashboardID}`}
            >
              {CheckValueLocale("check_all_posts", "", {}, intl)} (
              {topPostComments})
            </Link>
          </Box>
          <AllPosts
            igTopPosts={igTopPostComments}
            twTopPosts={twTopPostComments}
            fbTopPosts={fbTopPostComments}
            insightsID={insightsID}
            response={insightsResponse}
            dates={dates}
            updateAnalysis={updateAnalysis}
            igTopPostsPreLoaderDataSources={
              igTopPostCommentsPreLoaderDataSources
            }
            twTopPostsPreLoaderDataSources={
              twTopPostCommentsPreLoaderDataSources
            }
            fbTopPostsPreLoaderDataSources={
              fbTopPostCommentsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_posts_comments?.data_sources,
            ]}
            allDatasources={[
              ...metricsValues?.top_posts_comments?.data_sources,
            ]}
            isAggregateAccountPage
            hideChangeSentiment
          />
        </Box>
      )}
    </>
  );
};

export default PostAnalytics;
