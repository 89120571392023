export const timeOffsets = [
  {
    name: "UTC/GMT -1 hours",
    value: "-1",
  },
  {
    name: "UTC/GMT 0 hours",
    value: "0",
  },
  {
    name: "UTC/GMT 1 hours",
    value: "1",
  },
  {
    name: "UTC/GMT 2 hours",
    value: "2",
  },
  {
    name: "UTC/GMT 3 hours",
    value: "3",
  },
  {
    name: "UTC/GMT 4 hours",
    value: "4",
  },
  {
    name: "UTC/GMT 5 hours",
    value: "5",
  },
  {
    name: "UTC/GMT 6 hours",
    value: "6",
  },
  {
    name: "UTC/GMT 7 hours",
    value: "7",
  },
  {
    name: "UTC/GMT 8 hours",
    value: "8",
  },
  {
    name: "UTC/GMT 9 hours",
    value: "9",
  },
  {
    name: "UTC/GMT 10 hours",
    value: "10",
  },
  {
    name: "UTC/GMT 11 hours",
    value: "11",
  },
  {
    name: "UTC/GMT 12 hours",
    value: "12",
  },
];
