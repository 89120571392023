import ReactEcharts from "echarts-for-react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const NestedPieChart = (props) => {
  const intl = useIntl();
  const formatToolTip = (data) => {
    let data_value = data.value;
    let format = `${data.marker} ${data.name}: ${Number(
      data_value,
    ).toLocaleString()} (${data.percent}%)`;
    return props.handleOpenQuickInspect
      ? format +
          `<br/> <strong>${CheckValueLocale(
            "click_for_details",
            "",
            {},
            intl,
          )}</strong>`
      : format;
  };

  return (
    <ReactEcharts
      option={{
        tooltip: {
          trigger: "item",
          backgroundColor: "#fff",
          textStyle: {
            fontSize: 12,
          },
          formatter: (val) => formatToolTip(val),
        },
        silent: props.haveData ? false : true,

        legend: {
          bottom: "auto",
          top: 280,
          left: "center",
          itemWidth: 15,
          data: props.legend,
          color: props.color,
          selectedMode: false,
        },

        series: [
          {
            type: "pie",
            cursor: props.handleOpenQuickInspect ? "pointer" : "default",
            radius:
              props.showSubDialects === true ? ["25%", "50%"] : ["30%", "75%"],
            color: props.color,
            selectedMode: "s",
            data: props.seriesData,
            label: {
              show: false,
            },
            bottom: 50,
          },
          {
            type: "pie",
            cursor: props.handleOpenQuickInspect ? "pointer" : "default",
            radius:
              props.showSubDialects === true ? ["53%", "75%"] : ["0%", "0%"],
            color: props.subColor,
            selectedMode: "s",
            data: props.subSeriesData,
            label: {
              show: false,
            },
            bottom: 50,
          },
        ],
      }}
      style={{ minHeight: "330px", width: "100%" }}
      theme="custom_theme"
      //onEvents={{ click: onChartClick }}
    />
  );
};

export default NestedPieChart;
