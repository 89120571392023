import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import moment from "moment";
import { CheckValueLocale, getSocialIcon, getTimeZone } from "utils/helpers";
import "./alertTitleDate.scss";

const AlertTitleDate = (props) => {
  const { alertDetailProductType } = props;
  const intl = useIntl();
  const timeZone = getTimeZone();
  const alertEndHour = moment
    .unix(props?.endhour)
    .utc()
    .format("MM/DD/YYYY HH:mm:ss");
  const getAlertType = () => {
    let alertTypeLocale = props?.alertDetailType?.toLowerCase();
    let alertsLocales = {
      privatefacebook: `${alertTypeLocale}_alert_dm`,
      privatetwitter: `${alertTypeLocale}_alert_dm`,
      privateinstagram: `${alertTypeLocale}_alert_dm`,
      genesys: `${alertTypeLocale}_alert_genesys`,
      googlemybusiness: `${alertTypeLocale}_alert_gmb`,
      intercom: `${alertTypeLocale}_alert_dm`,
      whatsapp: `${alertTypeLocale}_alert_dm`,
      gmail: `${alertTypeLocale}_alert_gmail`,
      tiktok: `${alertTypeLocale}_alert_tiktok`,
      default: `${alertTypeLocale}_alerts`,
    };
    const val =
      alertDetailProductType === "cxm"
        ? alertsLocales[props?.alertDetailDataSource?.toLowerCase()] ||
          alertsLocales.default
        : `${alertTypeLocale}_alerts`;
    return val;
  };
  return (
    <>
      <Box component="div" className="alert-title-wrapper" my={5}>
        <Box>
          <Box className="alert-view-title">
            {getSocialIcon(props?.alertDetailDataSource?.toUpperCase())}
            <Box component="span" px={1.5}>
              {props?.alertName} {CheckValueLocale("for", "", {}, intl)}{" "}
              <Box component="span" className="alert-name">
                <Box className="title-type" component="span">
                  {props?.alertDetailMonitorName}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="alert-type-header">
            {CheckValueLocale(`${getAlertType()}`, "", {}, intl)}
          </Box>
        </Box>

        <Box component="div" className="alert-date">
          <Box component="span">
            {intl.formatMessage(
              { id: "last_update_time_alert" },
              {
                fromnow: moment(alertEndHour).fromNow(true),
                datetime: moment(alertEndHour).format("DD/MM/YYYY, HH:mm A"),
                timeZone: timeZone > 0 ? "+" + timeZone : timeZone,
              },
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AlertTitleDate;
