import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CompaniesController from "services/controllers/companiesController";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { Box } from "@mui/material";

const DeleteCustomDomainModal = (props) => {
  const {
    setOpenDeleteSurveyModal,
    openDeleteSurveyModal,
    setCustomDomainName,
    setCustomDomain,
    setSnackBarDetails,
  } = props;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const modalBody = (
    <Box className="shared-dialog-body">
      <p>{CheckValueLocale("remove_custom_domain_body", "", {}, intl)}</p>
      <h2 className="sub-text-desc">
        {CheckValueLocale("deleting_msg", "", {}, intl)}
      </h2>
    </Box>
  );

  const handleDeleteCustomDomain = () => {
    setButtonLoading(true);
    setLoading(true);
    CompaniesController.deleteDomain({ custom_domain: "" }).then((res) => {
      if (res?.data?.status === 200) {
        setSnackBarDetails({
          openSnackBar: true,
          severity: "success",
          snackBarMsg: CheckValueLocale("custom_remove_success", "", {}, intl),
        });
        setTimeout(() => {
          setCustomDomainName("");
          setCustomDomain("");
        }, 2000);
        setOpenDeleteSurveyModal(false);
      } else {
        setSnackBarDetails({
          openSnackBar: true,
          severity: "error",
          snackBarMsg: CheckValueLocale("try_again", "", {}, intl),
          title: CheckValueLocale("something_went_wrong", "", {}, intl),
        });
        setOpenDeleteSurveyModal(false);
      }
      setLoading(false);
      setButtonLoading(false);
      setTimeout(() => {
        setSnackBarDetails({ openSnackBar: false });
      }, 3000);
    });
  };

  return (
    <>
      <PopupModal
        title={CheckValueLocale("custom_domain_remove", "", {}, intl)}
        body={modalBody}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
        open={openDeleteSurveyModal}
        close={() => {
          setOpenDeleteSurveyModal(false);
        }}
        isRightBtnLoading={loading || buttonLoading}
        disabled={loading || buttonLoading}
        accept={handleDeleteCustomDomain}
        warning={true}
        minWidth="600px"
        classeName="custom-domain-modal actions-buttons-shared"
      />
    </>
  );
};
export default DeleteCustomDomainModal;
