import { Box, TextField, Tooltip, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import InfoIcon from "@mui/icons-material/Info";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const handleValidation = (val, listToCheckDuplication) => {
  if (isPublicSpecialCharactersOrEmojis(val)) {
    return "usernames_collect_ignore_tweets_error_name";
  } else if (listToCheckDuplication?.includes(val)) {
    return "usernames_collect_ignore_tweets_error_exists_other_fields";
  }
  return "";
};

const CustomerCareChannel = ({
  monitorData,
  setMonitorData,
  setMonitorErrors,
  monitorErrors,
}) => {
  const intl = useIntl();

  const handleInputChange = (event) => {
    const val = event.target.value;
    setMonitorData((prev) => ({ ...prev, customerCareVal: val }));

    const errorMessage = handleValidation(val, monitorData?.ignoreTweets ?? []);
    if (errorMessage) {
      setMonitorErrors((prev) => ({ ...prev, customerCareVal: errorMessage }));
    } else {
      setMonitorErrors((prev) => ({ ...prev, customerCareVal: "" }));
    }
  };
  return (
    <Box className="shared-customer-care-container-channels">
      <CustomizedTooltip
        title={CheckValueLocale("customer_care_info", "", {}, intl)}
        placement="bottom"
        arrow
        id="radio-buttons-box"
        styles={{ ...customizedStyledTooltip, maxWidth: "223px" }}
      >
        <Typography component={"h3"}>
          {CheckValueLocale("customer_care_account", "", {}, intl)}
        </Typography>
      </CustomizedTooltip>
      <TextField
        id="outlined-error-helper-text"
        type="email"
        className="customer-care-input"
        error={monitorErrors?.customerCareVal}
        helperText={
          <span
            className={
              monitorErrors?.customerCareVal
                ? "error-msg helper-msg"
                : "helper-msg"
            }
          >
            {CheckValueLocale(
              monitorErrors?.customerCareVal
                ? monitorErrors?.customerCareVal
                : "",
              "",
              {},
              intl,
            )}
          </span>
        }
        value={monitorData?.customerCareVal || ""}
        onChange={(e) => handleInputChange(e)}
        onFocus={(e) => handleInputChange(e)}
        placeholder={CheckValueLocale(
          "insert_username_then_enter_without_atsign",
          "",
          {},
          intl,
        )}
      />
    </Box>
  );
};

export default CustomerCareChannel;
