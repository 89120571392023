import { useMutation } from "@tanstack/react-query";
import SurveyController from "services/controllers/surveyController";

export const useEditAnswerThemes = () => {
  return useMutation({
    mutationFn: async ({ queryData }) => {
      await SurveyController.editThemes(queryData);
    },
  });
};
