import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const FormControl = ({ name, label, checked, onChange, errorField }) => {
  const intl = useIntl();

  return (
    <FormGroup className="group-field">
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={checked}
            onChange={onChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        className="control-label-field"
        label={label}
      />
      {errorField ? (
        <Box component="p" className="error-text-msg">
          {" "}
          {CheckValueLocale(errorField, "", {}, intl)}{" "}
        </Box>
      ) : null}
    </FormGroup>
  );
};
export default FormControl;
