import { Box } from "@mui/material";
import UserManagementTable from "./userManagementTable";
import { useIntl } from "react-intl";
import { useState } from "react";
import Header from "./header";

const UserManagementIndex = (props) => {
  const intl = useIntl();
  const [companyReachedMaxUser, setCompanyReachedMaxUser] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);

  return (
    <Box id="user_management">
      <Header
        companyReachedMaxUser={companyReachedMaxUser}
        companyUsers={companyUsers}
        setCompanyUsers={setCompanyUsers}
      />
      <UserManagementTable
        setCompanyReachedMaxUser={setCompanyReachedMaxUser}
        companyUsers={companyUsers}
        setCompanyUsers={setCompanyUsers}
      />
    </Box>
  );
};
export default UserManagementIndex;
