import { Box, TextField, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "../manageChannels.scss";

export const CustomerCareBody = ({
  handleInputChange,
  customerCareVal,
  errorCustomerCare,
}) => {
  const intl = useIntl();

  return (
    <Box className="customer-Care-Body">
      <Typography>
        {CheckValueLocale("add_customer_care_account", "", {}, intl)}
      </Typography>
      <TextField
        id="outlined-error-helper-text"
        type="email"
        value={customerCareVal || ""}
        className="customer-care-input"
        onChange={handleInputChange}
        helperText={
          <span
            className={
              errorCustomerCare ? "error-msg helper-msg" : "helper-msg"
            }
          >
            {errorCustomerCare
              ? CheckValueLocale(
                  "username_special_characters_error",
                  "",
                  {},
                  intl,
                )
              : CheckValueLocale("customer_care_username", "", {}, intl)}
          </span>
        }
      />
    </Box>
  );
};
