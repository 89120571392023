import AccountBody from "./accountBody";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";

/*---------------------------------------------------------*/

const AccountPage = (props) => {
  const instagramIcon = (
    <span className="topics-twitter-icon">
      <img src={publicInstagram} className="icon-after-title instagram" />
    </span>
  );
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <AccountBody {...props} InstagramIcon={instagramIcon} />
    </MonitorContainer>
  );
};

export default AccountPage;
