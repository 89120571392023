import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

const MonitorCard = (props) => {
  const { id, name, type, dataSources, handleClick, selected, unselected } =
    props;
  const intl = useIntl();

  // Any edits in this card will affect Custom Dashboard ----- Editor Page (Manage Dialog) -----
  return (
    <Box
      id="custom-dashboard-monitor-card"
      className={`${unselected ? "unselected" : selected ? "selected" : ""}`}
      onClick={() => handleClick(id)}
    >
      <Box className="monitor-info">
        <Typography className="monitor-name">{name}</Typography>
        <Box component={"ul"} className="info-list">
          <Box component={"li"}>
            <Box className="monitor-data-item">
              {CheckValueLocale("type", "", {}, intl)}:
              <Box component={"span"}>
                {CheckValueLocale(type, "", {}, intl)}
              </Box>
            </Box>
          </Box>
          <Box component={"li"}>
            <Box className="monitor-data-item">
              {CheckValueLocale("data_source", "", {}, intl)}:{" "}
              <Box component={"span"}>
                {dataSources?.map((dataSource) => {
                  return getSocialIcon(dataSource);
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="selected-check">
        <FontAwesomeIcon icon={faCheck} size="small" />
      </Box>
    </Box>
  );
};

export default MonitorCard;
