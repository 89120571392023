import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  DialogContentText,
  Divider,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
} from "@mui/material";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { CheckValueLocale } from "utils/helpers";
import "./style.scss";
import ExcelIcon from "images/audience-images/execl-icon.svg";
import AudienceController from "services/controllers/audienceController";
import UploadExceFile from "./components/uploadFileExcel";
import DownloadFileExcel from "./components/downloadFileExcel";
import "./style.scss";
import LucButton from "shared/lucButton/lucButton";

const AudienceImportExcelPopUp = (props) => {
  const intl = useIntl();
  const {
    files,
    setFiles,
    excelFileName,
    isFileUploaded,
    fileName,
    displayUploadErrorMessage,
    handleResetImportPopUp,
    setExportSnackBar,
    setOpenImportPopUp,
    enableProfileAudience,
    setEnableProfileAudience,
    enableUncomplyingAudience,
    setEnableUncomplyingAudience,
    getTitleImportExcel,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
    isManageAudiencePage,
    getListOfCdpImports,
  } = props;

  const [buttonLoading, setButtonLoading] = useState(false);

  const isDisableImport =
    !isFileUploaded || !enableProfileAudience || !enableUncomplyingAudience;

  const handleUploadData = () => {
    setButtonLoading(true);
    const formDataFile = new FormData();
    formDataFile.append("file", files[0]?.file);
    AudienceController.importCDPExcelFile(formDataFile).then((result) => {
      if (result?.data?.status === 204 || result?.data?.status === 200) {
        setExportSnackBar({
          title: CheckValueLocale(
            "audience_import_excel_success_title",
            "",
            {},
            intl,
          ),
          message: isManageAudiencePage
            ? CheckValueLocale(
                "manage_audience_import_excel_success_message",
                "",
                {},
                intl,
              )
            : getTitleImportExcel(),
          severity: "success",
          openSnackBar: true,
        });
        isManageAudiencePage && getListOfCdpImports();
      } else {
        setExportSnackBar({
          title: CheckValueLocale(
            "audience_import_excel_fail_title",
            "",
            {},
            intl,
          ),
          severity: "error",
          message: CheckValueLocale(
            "audience_import_excel_fail_message",
            "",
            {},
            intl,
          ),
          openSnackBar: true,
        });
      }
      setButtonLoading(false);
      handleResetImportPopUp();
    });
  };

  useEffect(() => {
    handleExcelGuideData();
  }, []);

  return (
    <Dialog
      open={true}
      onClose={handleResetImportPopUp}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
      className="audience-import-pop-up-container"
    >
      <Box className="audience-import-pop-up actions-buttons-shared">
        <DialogTitle id="audience-import-dialog-title">
          {CheckValueLocale("audience_import_title", "", {}, intl)}
        </DialogTitle>
        <DialogContent
          id="dialog-content-import-popup"
          className={`${!isFileUploaded ? "dialog-content-import-uploaded" : ""}`}
        >
          {!isFileUploaded ? (
            <Box>
              <DialogContentText className="audience-import-desc">
                {CheckValueLocale("audience_import_description", "", {}, intl)}
              </DialogContentText>
              <DownloadFileExcel excelGuideData={excelGuideData} />
              <UploadExceFile
                files={files}
                setFiles={setFiles}
                displayUploadErrorMessage={displayUploadErrorMessage}
                fileName={fileName}
                excelFileName={excelFileName}
              />
            </Box>
          ) : (
            <Box className="excel-file-container">
              <Box className="excel-file-content">
                <img
                  src={ExcelIcon}
                  className="excel-file-icon"
                  alt="excel icon"
                />
                <Box className="uploaded-file-content">
                  <Typography
                    component="span"
                    className="excel-file-name-title"
                  >
                    {excelFileName}
                  </Typography>
                  <Typography className="upload-file-progress">
                    {CheckValueLocale(
                      "audience_upload_sussessfully",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Tooltip
                  title={CheckValueLocale(
                    "import_cancel_upload_file_tooltip",
                    "",
                    {},
                    intl,
                  )}
                  arrow
                >
                  <Box
                    className="close-upload-file"
                    onClick={handleCancelUploadFile}
                  >
                    <CloseIcon />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          )}

          <Divider className="import-divider" />
          <Grid item className="enable-upload-box">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableProfileAudience}
                    onChange={() => setEnableProfileAudience((val) => !val)}
                  />
                }
                label={CheckValueLocale(
                  "audience_import_enable_pre_existing_profiles",
                  "",
                  {},
                  intl,
                )}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableUncomplyingAudience}
                    onChange={() => setEnableUncomplyingAudience((val) => !val)}
                  />
                }
                label={CheckValueLocale(
                  "audience_import_enable_un_complying",
                  "",
                  {},
                  intl,
                )}
              />
            </FormGroup>
          </Grid>
        </DialogContent>
        <DialogActions
          className={`import-action-container ${
            isManageAudiencePage && "manage-import-action-container"
          }`}
        >
          {!isManageAudiencePage ? (
            <Link
              to={{
                pathname: `/audience/manage-audience`,
              }}
              onClick={window.localStorage.setItem("importState", "true")}
              target="_blank"
            >
              <Typography component="p" className="manage-import-file-title">
                {CheckValueLocale("audience_manage_import_file", "", {}, intl)}
              </Typography>
            </Link>
          ) : null}
          <Box>
            <LucButton
              onClick={handleResetImportPopUp}
              variant="flat"
              type="secondary"
              className="cancel-popup-btn"
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </LucButton>
            <LucButton
              disabled={isDisableImport || buttonLoading}
              onClick={handleUploadData}
              loading={buttonLoading}
            >
              {CheckValueLocale("audience_import", "", {}, intl)}
            </LucButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default AudienceImportExcelPopUp;
