import translations from "utils/locale";
import { secondsToHms } from "utils/helpers";
import { monitor_vs_monitors } from "./types/monitor-vs-monitors";
import { time_vs_time } from "./types/timePeriod-vs-timePeriod";
import { positive_vs_negative } from "./types/positive-vs-negative";
import handleExcelData from "./handleExcelData";

const posNegNeuPercentage = (inputArr) => {
  const totalValues = inputArr?.reduce(
    (acc, item) => item?.value?.map((val, index) => (acc[index] || 0) + val),
    [],
  );
  const resultArray = inputArr?.map((item) => {
    const percentageValues = item?.value
      ?.map((val, index) => (val / totalValues[index]) * 100)
      ?.map((val) => Math.round(val * 100) / 100);
    return { name: item?.name, value: percentageValues };
  });

  return resultArray;
};

const excelGeneration = (props, language) => {
  let data = handleExcelData(props);
  var xlsLanguage = translations[language];

  const convertSeconds = (time) => {
    let formattedTime,
      timeData = [];
    if (time?.toString()?.includes(":"))
      timeData = time ? time?.split(":") : [];
    else {
      formattedTime = secondsToHms(time);
      timeData = formattedTime;
    }
    let timetext = "";
    if (timeData[0] && timeData[0] > 0)
      timetext += `${Number(timeData[0])} ${xlsLanguage["hrs"]} `;
    if (timeData[1] && timeData[1] > 0)
      timetext += `${Number(timeData[1])} ${xlsLanguage["min"]} `;
    if (timeData[2] && timeData[2] > 0)
      timetext += `${Number(timeData[2])} ${xlsLanguage["sec"]}`;
    return timetext;
  };

  if (props?.monitorPublicType === "Monitor_vs_Monitors") {
    return monitor_vs_monitors(
      data,
      xlsLanguage,
      convertSeconds,
      posNegNeuPercentage,
    );
  } else if (props?.monitorPublicType === "Time_Period_vs_Time_Period") {
    return time_vs_time(data, xlsLanguage, convertSeconds, posNegNeuPercentage);
  } else if (props?.monitorPublicType === "Positive_vs_Negative") {
    return positive_vs_negative(
      data,
      xlsLanguage,
      convertSeconds,
      posNegNeuPercentage,
    );
  }
};

export { excelGeneration };
