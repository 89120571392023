import React, { useState } from "react";
import { Box, Card, CardContent, Input, Typography } from "@mui/material";
import styles from "../commonStyle.module.css";

const EmailQuestion = (props) => {
  const { question, questionNo } = props;
  const data = question;
  const [answer, setAnswer] = useState("");

  const handleAnswers = (e) => setAnswer(e.target.value);

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {data?.question?.text}
          {data?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{data?.question?.description}</Box>
        <Box>
          <Input
            id="standard-basic"
            placeholder="name@example.com"
            variant="standard"
            className={styles.textFieldStyle}
            value={answer}
            onChange={handleAnswers}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default EmailQuestion;
