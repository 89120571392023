import Services from "./Services";
var SingleTweet = {};

SingleTweet.getAnalysis = (id, monitor_id, queryData) => {
  return Services.getData(
    `api/v3/social_media/twitter/single_tweets/${id}/?monitor_id=${monitor_id}`,
    queryData,
  );
};

SingleTweet.createAnalysisSingleTweet = (queryData) => {
  return Services.postData(
    "api/v3/social_media/twitter/single_tweets",
    queryData,
  );
};
SingleTweet.getExportPPT = (
  id,
  monitor_id,
  product_id,
  exportPpt,
  langValue,
) => {
  return Services.getData(
    `api/v3/social_media/twitter/single_tweets/${id}/?monitor_id=${monitor_id}&product_id=${product_id}&export=${exportPpt}&language=${langValue}`,
  );
};

export default SingleTweet;
