import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Input } from "@mui/material";
import { Box } from "@mui/system";
import "../surveyQuestion.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const ShortTextQuestion = (props) => {
  const intl = useIntl();
  const { question, questionNo, questionAnswer } = props;

  return (
    <Card className="cardStyle noError audience">
      <CardContent>
        <Typography className="questionStyle audience">
          <span>{`${questionNo}.`}</span>
          {question?.question}
        </Typography>
        <Box>
          <Input
            readOnly
            id="standard-basic"
            placeholder={CheckValueLocale("you_didnt_answer", "", {}, intl)}
            variant="standard"
            className="textFieldStyle audience"
            value={questionAnswer}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ShortTextQuestion;
