import React from "react";
import { Box } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const SettingsFooter = (props) => {
  const {
    isSetup,
    stepNum,
    handleSettingsNext,
    handleSettingsBack,
    saveLoading,
  } = props;
  const intl = useIntl();

  return (
    <Box className="settings-footer">
      <Box className="footer-btns-container">
        {isSetup && stepNum === 1 ? null : (
          <LucButton
            type="secondary"
            variant="outline"
            fullWidth
            onClick={handleSettingsBack}
          >
            {CheckValueLocale("luci_settings_back", "", {}, intl)}
          </LucButton>
        )}
        <LucButton fullWidth onClick={handleSettingsNext} loading={saveLoading}>
          {stepNum === 4
            ? CheckValueLocale("luci_save", "", {}, intl)
            : CheckValueLocale("luci_next", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );
};

export default SettingsFooter;
