import { displayRemainingTime } from "./datesUtils";

const sortingPriority = {
  time_to_complete: 0,
  first_response_time: 1,
  next_response_time: 2,
  unassigned_response_time: 3,
  hits_count: 4,
  misses_count: 5,
};
/**
 * handleAvgSLAs - This function is used to calculate the average SLAs for the given data
 * @param {*} data an object that contains to object  current_period and previous_period in which each object contains the calc for the sla
 * @param {*} inconmingData an object that contains the current_period and previous_period
 * @returns an array of objects that contains the avg sla for each sla
 */
export const handleAvgSLAs = (avgSLasData, inconmingData = {}) => {
  // newAvgSLAsData is used to store the new data after the calculation and aggregation
  const newAvgSLAsData = [];
  const { current_period, previous_period } = inconmingData;
  const currentPeriod = Object.keys(current_period ? current_period : {});
  currentPeriod.forEach((key) => {
    // newEmptyObj is used to store the current sla data
    // it will be inserted in the newAvgSLAsData array if it's the frist response for this sla
    const newEmptyObj = {
      id: key,
      name: "avgs_" + key,
      desc: "avgs_" + key + "_desc",
      currSum: 0, // accumulate the sum of the current sla
      currCount: 0, // accumulate the count of the current sla
      currValue: 0, // will hold the average value of the current sla .e.g 2h, 10m
      prevSum: 0,
      prevCount: 0,
      prevValue: 0, // will hold the average value of the previous sla .e.g 2h, 10m
      percentage: 0, // will hold the percentage of the current sla compared to the previous sla
    };
    const newSum = +current_period?.[key]?.sum;
    const newCount = +current_period?.[key]?.count;
    const prevSum = +previous_period?.[key]?.sum;
    const prevCount = +previous_period?.[key]?.count;
    let existedObj = avgSLasData?.find?.((item) => item?.id === key);
    // if the sla is not existed in the avgSLasData array, make it equal to the newEmptyObj
    if (!existedObj) {
      existedObj = newEmptyObj;
    }

    existedObj.currSum += newSum;
    existedObj.currCount += newCount;
    existedObj.prevSum += prevSum;
    existedObj.prevCount += prevCount;
    const newCurrValue = existedObj?.currCount
      ? Math.round(existedObj?.currSum / existedObj?.currCount)
      : 0;
    const newPrevValue = existedObj?.prevCount
      ? Math.round(existedObj?.prevSum / existedObj?.prevCount)
      : 0;

    existedObj.currValue = displayRemainingTime(newCurrValue);
    existedObj.prevValue = displayRemainingTime(newPrevValue);
    existedObj.percentage =
      newPrevValue > 0
        ? ((newCurrValue - newPrevValue) / newPrevValue) * 100
        : newCurrValue
          ? 100
          : 0;
    newAvgSLAsData.push(existedObj);
  });

  return newAvgSLAsData?.sort((a, b) => {
    const priorityA = sortingPriority[a?.id];
    const priorityB = sortingPriority[b?.id];
    return priorityA - priorityB;
  });
};

export const orderObjectByArray = (obj, orderArr) => {
  const result = {};
  orderArr?.forEach((item) => {
    const name = item;
    if (name in obj) {
      result[name] = obj[name];
    }
  });

  return result;
};

/*************handle sorting array******** */
// to handle sorting of array [{name:"", value:""}]  accoding to sorted array above
export const getHandleSortedArray = (customData, orginalData) => {
  const sortedData = orginalData?.sort((a, b) => {
    return customData?.indexOf(a?.name) - customData.indexOf(b?.name);
  });
  return sortedData;
};
