import { useIntl } from "react-intl";
import { Box, Typography, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale, handleSentiment } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const TotalLabelsSentimentAnalysis = (props) => {
  const intl = useIntl();
  const { inspectorName, isQuickExplore } = props;

  return (
    <>
      <Box className="sentiment-labels">
        <Box>
          <Typography
            component="span"
            style={{ color: handleSentiment(props?.totalLabel).color }}
          >
            {!isQuickExplore ? (
              <FontAwesomeIcon
                icon={handleSentiment(props?.totalLabel).label}
              />
            ) : null}
          </Typography>

          <Typography component="span" className="sentiment-label-percentage">
            {props?.percentage}{" "}
            <Typography component="span" className="sentiment-label-value">
              {"(" + props?.totalValue?.toLocaleString() + ")" || "(" + 0 + ")"}
            </Typography>
          </Typography>
          {inspectorName && (
            <Box className="btn-inspect-sentiment" component="span">
              {" "}
              <LucButton
                onClick={(e) => {
                  // sentiment_legend
                  props.handleOpenQuickInspect &&
                    props.handleOpenQuickInspect(
                      props?.totalLabel,
                      "sentiment_legend",
                      props?.percentage,
                    );
                }}
                variant="flat"
                id="total-label-sentiment-analysis-inspect-btn"
              >
                {CheckValueLocale("inspect", "", {}, intl)}
              </LucButton>
            </Box>
          )}
        </Box>

        <Box component="p" className="sentiment-label-name">
          {props?.totalLabel
            ? CheckValueLocale(props?.totalLabel, "", {}, intl)
            : ""}
        </Box>
      </Box>
    </>
  );
};
export default TotalLabelsSentimentAnalysis;
