import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import userImage from "../../../../images/userDefualt.png";
import "./surveyDetails.scss";

const SurveyDetails = (props) => {
  const intl = useIntl();
  let { profile, detail } = props;

  return (
    <Box className="interaction-page-list main-audience main-survey audience-shadow">
      <Box className="aduience-widget-header header-survey">
        <Box className="audience-widget-image">
          <img
            src={
              profile?.data?.profile_image_url
                ? profile?.data?.profile_image_url
                : userImage
            }
          />
        </Box>
        <Box>
          <Typography className="audience-widget-name">
            {profile?.data?.name}
          </Typography>
        </Box>
      </Box>
      <Box className="survey-date">
        <Typography variant="span">
          {CheckValueLocale("submitted_at", "", {}, intl)}
          <Box className="date">
            {moment
              .unix(detail?.submitted_date)
              .utc()
              .format("HH:mm A - DD/MM/YYYY")}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default SurveyDetails;
