import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { sanitize } from "dompurify";

const TestKeywordResultTesting = (props) => {
  const { spam, keyword } = props;
  const intl = useIntl();

  return (
    <>
      {/* SUCCESS CASE */}
      {keyword === true && spam === false && (
        <Box className="test-keyword-msg-wrapper">
          <Box component="span" className="test-result-icon success">
            <FontAwesomeIcon icon={faCheck} />
          </Box>
          <Box className="test-result-body">
            <Box>
              <Box component="span">
                {CheckValueLocale("result", "", {}, intl)}
              </Box>
              {CheckValueLocale("keywords_found_successfully", "", {}, intl)}
            </Box>
            <Box className="test-result-explain">
              {CheckValueLocale(
                "can_use_keyword_while_monitoring",
                "",
                {},
                intl,
              )}
            </Box>
          </Box>
        </Box>
      )}

      {/* FAILED CASE */}
      {((keyword === true && spam === true) ||
        (keyword === false && spam === true) ||
        (keyword === false && spam === false)) && (
        <Box className="test-keyword-msg-wrapper">
          <Box component="span" className="test-result-icon failed">
            <FontAwesomeIcon icon={faExclamation} />
          </Box>
          <Box className="test-result-body">
            <Box>
              <Box component="span">
                {CheckValueLocale("result", "", {}, intl)}
              </Box>
              {CheckValueLocale("found_spam_keywrod_post", "", {}, intl)}
            </Box>
            <Box
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  CheckValueLocale("spelling_matched_post", "", {}, intl),
                ),
              }}
              className="test-result-explain"
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default TestKeywordResultTesting;
