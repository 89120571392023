import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import OverView from "components/widgets/overViewWidget";
import { handleArraySum } from "../InsightsPage/insightsCommonFunctions";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";

const InteractionsBody = (props) => {
  const intl = useIntl();
  let { response } = props;
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [overviewArray, setOverviewArray] = useState([]);
  const [overview, setOverview] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });

  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();
  const [latestInteractionsCount, setLatestInteractionsCount] = useState(0);

  var overviewResult = [];
  useEffect(() => {
    switch (response.eventName) {
      case "overview":
        if (response.eventData.overview) {
          if (
            props.preLoaderTrackerDataSources.includes(response.data_source)
          ) {
            Object.entries(response.eventData.overview).map((i) => {
              if (i[0] === "net_sentiment") {
                i[1].map((sentiment) => {
                  if (sentiment.name == "negative") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      negative: netSentiment["negative"] + sentiment.value,
                    }));
                  }
                  if (sentiment.name == "positive") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      positive: netSentiment["positive"] + sentiment.value,
                    }));
                  }
                  if (sentiment.name == "neutral") {
                    setNetSentiment((netSentiment) => ({
                      ...netSentiment,
                      neutral: netSentiment["neutral"] + sentiment.value,
                    }));
                  }
                });
              } else {
                overviewArray.push({ name: i[0], value: i[1] });
              }
            });
            setOverViewPreLoaderDataSources((oldArray) => [
              ...oldArray,
              response.data_source,
            ]);
          }
        }
        break;
      case "latest_interactions":
        if (props.preLoaderTrackerDataSources.includes(response.data_source)) {
          response.eventData.latest_interactions?.map((interaction) => {
            setLatestInteractions((oldArray) => [...oldArray, interaction]);
          });
          if (response.data_source)
            if (props.page === 1 && response.eventData?.interactions_count) {
              if (
                response.eventData.interactions_count > latestInteractionsCount
              ) {
                setLatestInteractionsCount(
                  response.eventData?.interactions_count,
                );
              }
            }
          setLatestInteractionsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response.data_source,
          ]);
        }
        break;
      default:
        break;
    }
  }, [response]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment).reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverview,
      false,
      highest_sentiment,
    );
  };

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setOverview([]);
      setLatestInteractions([]);
      setOverviewArray([]);
      setNetSentiment({
        negative: 0,
        positive: 0,
        neutral: 0,
      });
      if (props.page === 1) setLatestInteractionsCount(0);
      // Values of DataSources totrack each widget preloader
      setOverViewPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);
  const unixDate = { start: props.startDate, end: props.endDate };
  return (
    <>
      <OverView
        data={overview}
        isInteractionsPage={true}
        overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />
      <Box my={4} className="all-interactions-list">
        <InteractionsList
          showProfile={true}
          unixDate={unixDate}
          data={latestInteractions}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isInteractionPage={true}
          pagenation={true}
          latestInteractionsCount={latestInteractionsCount}
          handleChangePage={props.handleChangePage}
          latestInteractionsPreLoaderDataSources={
            latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          page={props.page}
          loader={props.loader}
        />
      </Box>
    </>
  );
};

export default InteractionsBody;
