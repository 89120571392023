import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { Box } from "@mui/material";
import warningIcon from "images/warning.svg";
import LucButton from "shared/lucButton/lucButton";
import "../monitorSettings.scss";

const HasInactiveHashtags = () => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Box className="setting-inactive-hashtags-wrapper">
      <Box className="hashtags-limit-wrapper">
        <img src={warningIcon} className="limit-warning" />
        <Box component="p" className="limit-text-wrapper">
          <Box component="span" className="limit-text">
            {CheckValueLocale("has_inactive_hashtags_text", "", {}, intl)}
          </Box>
        </Box>
        <LucButton
          type="primary"
          variant="outline"
          size="small"
          id="ig-business-account"
          onClick={() => history.push(`/settings/instagram_hashtags`)}
          className="limit-btn"
        >
          {CheckValueLocale("ig_hashtags_manager", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );
};

export default HasInactiveHashtags;
