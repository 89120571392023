import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { Link } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmptyAccess = ({ name }) => {
  const intl = useIntl();
  let urls = {
    account_access: "/settings",
    monitors_access: "/social/monitors/create_monitor",
    channels_access: "/manage-channels/list",
  };
  return (
    <Box
      className={`empty-access-container ${
        name !== "channels_access" ? "with-border" : ""
      }`}
    >
      <Box>{CheckValueLocale(`${name}_tit`, "", {}, intl)}</Box>
      <Box className="empty-access-parag">
        {CheckValueLocale(`${name}_empty_prag`, "", {}, intl)}
      </Box>
      <Link target="_blank" to={urls[name]} className="">
        <LucButton
          variant="outline"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          {CheckValueLocale(`${name}_new`, "", {}, intl)}
        </LucButton>
      </Link>
    </Box>
  );
};
export default EmptyAccess;
