import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Container, Box, Tab } from "@mui/material";
import moment from "moment";
import BenchmarkController from "services/controllers/benchmarkController";
import "./benchmark.scss";
import PageHeader from "components/pageHeader";
import { CheckValueLocale } from "utils/helpers";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext, TabList } from "@mui/lab";
import NoPermission from "./components/noPermission/noPermission";
import SmTabContent from "./components/smTabContent";
import CxmTabContent from "./components/cxmTabContent";
import Luci from "pages/luci";
import { useHistory } from "react-router-dom";

const BenchMark = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [tabValue, setTabValue] = useState("sm");
  const [comparisonTypes, setComparisonTypes] = useState([]);
  const [informalList, setInformalList] = useState([]);
  const [luciDataLoading, setLuciDataLoading] = useState(false);
  const [benchmarkData, setBenchmarkData] = useState({});
  const [luciFilters, setLuciFilters] = useState({});
  const [comparisonType, setComparisonType] = useState("");
  const [luciComparisonType, setLuciComparisonType] = useState("");
  const [dataSourceName, setDataSourceName] = useState("");
  const [luciMonitorType, setLuciMonitorType] = useState("");
  const [monitorsObj, setMonitorsObj] = useState({});
  const [checkDataArr, setCheckDataArr] = useState([]);
  const [checkNoDataFound, setCheckNoDataFound] = useState(true);
  const [endDate, setEndDate] = useState(moment().unix());
  const [startDate, setStartDate] = useState(
    moment().subtract(10, "d").startOf("day").unix(),
  );
  const [vsTypeData, setVsTypeData] = useState([]);
  const [anotherTypeData, setAnotherTypeData] = useState("");
  const [monitorType, setMonitorType] = useState("");
  const [filterBenchmark, setFilterBenchmark] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [filterFlag, setFilterFlag] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  var activeProducts = JSON.parse(window.localStorage.activeProducts || null);
  let activeSm = activeProducts?.find((product) => product?.name == "SM");
  let activeCxm = activeProducts?.find((product) => product?.name == "CXM");

  const user_roles = JSON.parse(window.localStorage.getItem("user_roles"));
  let smRoles = activeSm && user_roles && user_roles?.SM;
  let cxmRoles = activeCxm && user_roles && user_roles?.CXM;

  useEffect(() => {
    if (smRoles) {
      setTabValue("sm");
    } else if (cxmRoles) {
      setTabValue("cxm");
    } else {
      setTabValue("sm");
    }
    BenchmarkController.getBenchmarkTypes().then((data) => {
      setComparisonTypes(data?.data);
    });
  }, []);

  useEffect(() => {
    const tabValueId =
      tabValue === "sm"
        ? window.localStorage.sm_id
        : window.localStorage.cxm_id;
    BenchmarkController.getBenchmarkInformal(tabValueId).then((data) => {
      setInformalList(data?.data);
    });
    setLuciDataLoading(false);
    setComparisonType("");
    setVsTypeData([]);
    setAnotherTypeData("");
    setMonitorType("");
    setFilterBenchmark({});
    setFilterParams({});
  }, [tabValue]);

  const handleMonitorsAndFilters = () => {
    const monitorIds = {};
    const filtersObj = {
      comparison_type: comparisonType,
      monitor_type: luciMonitorType,
      data_source: [],
      monitors: [],
    };
    const dataSources = {};

    benchmarkData?.monitorData?.forEach((monitor) => {
      const { data_source_name, monitor_id, monitor_name } = monitor;
      const dataSource = data_source_name?.toLowerCase();
      if (dataSource in monitorIds) monitorIds[dataSource]?.push(monitor_id);
      else monitorIds[dataSource] = [monitor_id];

      dataSources[dataSource] = true;
      filtersObj.monitors.push(monitor_name);
    });

    filtersObj.data_source = Object.keys(dataSources);

    setMonitorsObj(monitorIds);
    setLuciFilters(filtersObj);
  };

  useEffect(() => {
    if (benchmarkData) handleMonitorsAndFilters();
  }, [benchmarkData]);

  useEffect(() => {
    setFilterParams({});
    setFilterBenchmark({});
  }, [vsTypeData, anotherTypeData, monitorType, tabValue]);
  useEffect(() => {
    if (window.location.href?.endsWith("/social-listening"))
      window.location.href?.replace("/social-listening", "/omnichannel");
    else if (
      window.location.href?.endsWith("/social/benchmark") ||
      window.location.href?.endsWith("/social/benchmark/")
    )
      window.history?.pushState(
        {},
        "",
        window.location.href?.replace(
          "social/benchmark",
          "social/benchmark/omnichannel",
        ),
      );
  }, []);

  useEffect(() => {
    if (history?.location?.pathname == "/social/benchmark") {
      setTabValue("sm");
      history.push({
        pathname: `/social/benchmark/social-listening`,
      });
    } else if (history?.location?.pathname == "/social/benchmark/omnichannel") {
      setTabValue("cxm");
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    setFilterParams({});
    setFilterBenchmark({});
    if (tabValue == "sm") {
      history.push({
        pathname: `/social/benchmark/social-listening`,
      });
    } else if (tabValue == "cxm") {
      history.push({
        pathname: `/social/benchmark/omnichannel`,
      });
    }
  }, [vsTypeData, anotherTypeData, monitorType, tabValue]);

  return (
    <Container maxWidth="xl">
      <Box className="benchmark-title">
        <PageHeader title="benchmark" />
      </Box>
      <Box className="benchmark-tab">
        <TabContext value={tabValue}>
          <TabList onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label={CheckValueLocale("SM", "", {}, intl)} value="sm" />
            <Tab label={CheckValueLocale("CXM", "", {}, intl)} value="cxm" />
          </TabList>
          <TabPanel value="sm">
            {smRoles ? (
              <SmTabContent
                comparisonTypes={comparisonTypes}
                informalList={informalList}
                setLuciDataLoading={setLuciDataLoading}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setComparisonType={setComparisonType}
                setLuciMonitorType={setLuciMonitorType}
                setCheckDataArr={setCheckDataArr}
                setCheckNoDataFound={setCheckNoDataFound}
                setBenchmarkData={setBenchmarkData}
                setLuciComparisonType={setLuciComparisonType}
                setDataSourceName={setDataSourceName}
                vsTypeData={vsTypeData}
                setVsTypeData={setVsTypeData}
                anotherTypeData={anotherTypeData}
                setAnotherTypeData={setAnotherTypeData}
                monitorType={monitorType}
                setMonitorType={setMonitorType}
                comparisonType={comparisonType}
                filterBenchmark={filterBenchmark}
                setFilterBenchmark={setFilterBenchmark}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterFlag={filterFlag}
                setFilterFlag={setFilterFlag}
              />
            ) : (
              <NoPermission type="sm" />
            )}
          </TabPanel>
          <TabPanel value="cxm">
            {cxmRoles ? (
              <CxmTabContent
                comparisonTypes={comparisonTypes}
                informalList={informalList}
                setLuciDataLoading={setLuciDataLoading}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setComparisonType={setComparisonType}
                setLuciMonitorType={setLuciMonitorType}
                setCheckDataArr={setCheckDataArr}
                checkNoDataFound={checkNoDataFound}
                setCheckNoDataFound={setCheckNoDataFound}
                setBenchmarkData={setBenchmarkData}
                setLuciComparisonType={setLuciComparisonType}
                setDataSourceName={setDataSourceName}
                vsTypeData={vsTypeData}
                setVsTypeData={setVsTypeData}
                anotherTypeData={anotherTypeData}
                setAnotherTypeData={setAnotherTypeData}
                monitorType={monitorType}
                setMonitorType={setMonitorType}
                comparisonType={comparisonType}
                filterBenchmark={filterBenchmark}
                setFilterBenchmark={setFilterBenchmark}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                filterFlag={filterFlag}
                setFilterFlag={setFilterFlag}
              />
            ) : (
              <NoPermission type="cxm" />
            )}
          </TabPanel>
        </TabContext>
      </Box>
      <Luci
        isInactive={
          tabValue === "sm" &&
          luciComparisonType === "Time_Period_vs_Time_Period" &&
          luciMonitorType === "ACCOUNT_ANALYSIS" &&
          dataSourceName?.toLowerCase() === "instagram"
        }
        isBenchmark={true}
        pageParams={{
          luciFeature: "Benchmark",
          sectionName: tabValue === "sm" ? "social_listening" : "omnichannel",
          monitorName: tabValue === "sm" ? "social_listening" : "omnichannel",
          productId:
            tabValue === "sm"
              ? window.localStorage.sm_id
              : window.localStorage.cxm_id,
          startDate: startDate,
          endDate: endDate,
          requestedData: checkDataArr?.length > 0 ? true : false,
          dataLoaded: !luciDataLoading,
          dataEmpty: checkNoDataFound,
          filterParams: luciFilters,
          benchmarkData: {
            benchmark: {
              product_id:
                tabValue === "sm"
                  ? +window.localStorage.sm_id
                  : +window.localStorage.cxm_id,
              benchmark_type: comparisonType,
              monitor_list: monitorsObj,
            },
          },
        }}
      />
    </Container>
  );
};

export default BenchMark;
