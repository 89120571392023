import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Box,
  Stack,
} from "@mui/material";
import { useIntl } from "react-intl";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getCompanyApps } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import { checkQuotationMark } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/advancedKeyword";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../../createMonitor.scss";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import KeywordTooltipChip from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import {
  handleCopyBtn,
  checkValue,
  checkKeywordsValidation,
  checkEnabledCreateBtn,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";

import SocialMediaAccountButton from "../../../Components/SocialMediaAuthButton/socialMediaAuthButton";
import InputFieldName from "../../../Components/InputFieldName/inputFieldName";
import {
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import { DropdownSection } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/monitorDropdowns/monitorDropdowns";
import HashtagUsedNumber from "../../KeywordsMonitor/components/keywordHeader/hashtagUsedNumber";
import { getKeywordsLength } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordsLength";
import CustomerCareInput from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/customerCareInput";
import InputWithAddButton from "components/inputWithAddButton/InputWithAddButton";
const TwitterPublicAccount = (props) => {
  const intl = useIntl();
  const {
    monitorData,
    setMonitorData,
    monitorErrors,
    setMonitorErrors,
    handleCreateMonitorBtn,
    twSourceId,
    twitterAccounts,
    isLoading,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    twitterWordsCounter,
    setTwitterWordsCounter,
  } = props;

  const [companyApps, setCompanyApps] = useState([]);
  const [spamKeyword, setSpamKeyword] = useState("");
  const [ignoreTweets, setIgnoreTweets] = useState("");
  const [coustomerCareVal, setCoustomerCareVal] = useState([]);
  const limitCountKeywords = 100;

  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  const [dataExist, setDataExist] = useState(true);

  // handle keyPress
  const handlekeyboardPress = (val) => {
    if (val && dataExist && val?.trim() !== "") {
      let keywordsCopy = [...monitorData.spamKeywords];

      let objData = handlekeywordsShared(
        val,
        "spam_keywords",
        [],
        keywordsCopy?.length ? keywordsCopy : [],
        "twitter",
        [
          {
            keywords: [],
          },
          {
            spam_keywords: keywordsCopy?.length ? keywordsCopy : [],
          },
        ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
        limitCountKeywords -
          (twitterWordsCounter - monitorData?.spamKeywords?.length),
        undefined, //in case newsblogs,
        undefined,
      );
      if (!objData?.errorMsg) {
        setMonitorData({
          ...monitorData,
          spamKeywords: [...objData?.spamKeywords],
        });
        setSpamKeyword("");
        setMonitorErrors({
          ...monitorErrors,
          spamKeywords: "",
        });
      } else {
        setSpamKeyword(val);
        setMonitorErrors({
          ...monitorErrors,
          spamKeywords:
            objData?.errorMsg === "keywords_limit_excceds"
              ? "public_monitor_limit_excceds"
              : objData?.errorMsg,
        });
      }
    }
  };

  const handleChangeInput = (
    event,
    setString,
    keyFeild,
    listToCheckDuplication,
  ) => {
    const { value, name } = event.target;
    setString(value);
    if (value) {
      const keywordsCopy = [...monitorData?.[keyFeild]];
      const errorMessage = handleValidationKeywords(
        value,
        listToCheckDuplication ?? [],
        keywordsCopy?.length ? keywordsCopy : [],
        name,
        setDataExist,
        "twitter",
      );

      setMonitorErrors({
        ...monitorErrors,
        [keyFeild]: errorMessage,
      });
    } else {
      setMonitorErrors({
        ...monitorErrors,
        [keyFeild]: "",
      });
    }
  };

  const handelValidationLimit = (
    eventName,
    value,
    keyFeild,
    listToCheckDuplication,
  ) => {
    const listKeywords = Array.from(new Set(value?.split(",")));
    const keywordsCopy = [...monitorData?.[keyFeild]];
    const errorMessage = handleValidationKeywords(
      value,
      listToCheckDuplication ?? [],
      keywordsCopy?.length ? keywordsCopy : [],
      eventName,
      setDataExist,
      "twitter",
    );
    if (errorMessage) {
      return { errorMsg: errorMessage };
    }
    if (listKeywords?.length + twitterWordsCounter > limitCountKeywords) {
      return { errorMsg: "public_monitor_limit_excceds" };
    }
    return { [keyFeild]: [...listKeywords] };
  };

  const handleAddKeywordsTolist = (
    eventName,
    eventValue,
    setString,
    keyFeild,
    listToCheckDuplication,
  ) => {
    if (eventValue && dataExist && eventValue?.trim() !== "") {
      const objData = handelValidationLimit(
        eventName,
        eventValue,
        keyFeild,
        listToCheckDuplication,
      );
      if (!objData?.errorMsg) {
        setMonitorData({
          ...monitorData,
          [keyFeild]: [...monitorData?.[keyFeild], ...objData?.[keyFeild]],
        });
        setString("");
        setMonitorErrors({
          ...monitorErrors,
          [keyFeild]: "",
        });
      } else {
        setString(eventValue);
        setMonitorErrors({
          ...monitorErrors,
          [keyFeild]: objData?.errorMsg,
        });
      }
    }
  };

  useEffect(() => {
    getCompanyApps(setCompanyApps);
    props.setActiveDataSourceId(twSourceId);
  }, []);

  useEffect(() => {
    if (
      monitorErrors?.userName ||
      !monitorData?.userName ||
      monitorErrors?.spamKeywords ||
      monitorErrors?.ignoreTweets ||
      monitorErrors?.monitorName ||
      monitorErrors?.monitorDescription ||
      monitorErrors?.cstCareAccount
    ) {
      handleCreateMonitorBtn("");
    } else {
      handleCreateMonitorBtn("tw_pub");
    }
  }, [
    monitorErrors?.userName,
    monitorErrors?.spamKeywords,
    monitorErrors?.ignoreTweets,
    monitorErrors?.monitorName,
    monitorErrors?.monitorDescription,
    monitorErrors?.cstCareAccount,
  ]);

  const applySelectionConditionFn = (optionsToBeAdded) => {
    return optionsToBeAdded + twitterWordsCounter <= limitCountKeywords;
  };

  const onCoutriesChange = (newCoutries = []) => {
    setMonitorData({ ...monitorData, twitterCountries: newCoutries });
  };
  const onLanguagesChange = (newLanguages = []) => {
    setMonitorData({ ...monitorData, twitterLanguages: newLanguages });
  };

  useEffect(() => {
    let spamKeywordsCount = 0,
      ignoreTweetsCount = 0;
    if (monitorData?.spamKeywords?.length) {
      spamKeywordsCount = getKeywordsLength(monitorData?.spamKeywords);
    }
    if (monitorData?.ignoreTweets?.length) {
      ignoreTweetsCount = getKeywordsLength(monitorData?.ignoreTweets);
    }
    setTwitterWordsCounter(
      spamKeywordsCount +
        ignoreTweetsCount +
        monitorData?.twitterLanguages?.length +
        monitorData?.twitterCountries?.length,
    );
  }, [
    monitorData?.ignoreTweets?.length,
    monitorData?.spamKeywords?.length,
    monitorData?.twitterLanguages,
    monitorData?.twitterCountries,
  ]);

  useEffect(() => {
    // to handle validation with remove duplicate
    if (
      !monitorData?.ignoreTweets?.includes(monitorData?.userName) &&
      monitorErrors?.userName ===
        "usernames_collect_ignore_tweets_error_exists_other_fields"
    ) {
      setMonitorErrors((prev) => ({
        ...prev,
        userName: "",
      }));
    }
  }, [monitorData?.ignoreTweets?.length]);

  useEffect(() => {
    getTwitterLanguagesAndCountries();
    return () => {
      setTwitterWordsCounter(0);
    };
  }, []);
  useEffect(() => {
    setMonitorData({ ...monitorData, coustomerCareVal });
  }, [coustomerCareVal]);
  return (
    <Box>
      {!twitterAccounts || isLoading ? (
        <CircularLoading />
      ) : (
        // : isEmptyArray(twitterAccounts) ? (
        //   <>
        //     <SocialMediaAccountButton
        //       authHead={"you_need_to_connect_twitter_account"}
        //       companyApps={companyApps}
        //       authDataSourceName={"add_twitter_account"}
        //       isTwitter
        //       isPublicAccount
        //       isCompanyAccount={false}
        //     />
        //   </>
        // )
        <>
          <Stack direction="row-reverse">
            {twitterWordsCounter > 0 ? (
              <HashtagUsedNumber
                wordCountDataSource={twitterWordsCounter}
                limitationOfDataSource={limitCountKeywords}
                isNewBlogs
                toolTipText={"twitter_words_counter_tooltip"}
                tooltipPlacement={"bottom"}
              />
            ) : null}
          </Stack>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="social-media-fields"
          >
            <InputFieldName
              autoFocues={true}
              name="username"
              nameInfoMsg="insert_username_then_enter_without_atsign"
              setMonitorErrors={setMonitorErrors}
              monitorErrors={monitorErrors}
              isPublicSpecialCharactersOrEmojis={
                isPublicSpecialCharactersOrEmojis
              }
              handleCreateMonitorBtn={handleCreateMonitorBtn}
              setMonitorData={setMonitorData}
              monitorData={monitorData}
              checkUserNameChar={true}
              maxNameLength={15}
              isSpecificFormat={true}
              isRequired
            />
            <Grid
              item
              xs={6}
              className="social-media-field-grid keywords-inputs-fields"
            >
              <InputWithAddButton
                tooltipTitle={"you_can_use_connectiong_methods"}
                labelName={"spam_keywords"}
                setMonitorErrors={setMonitorErrors}
                monitorErrors={monitorErrors}
                setMonitorData={setMonitorData}
                monitorData={monitorData}
                keyFeild={"spamKeywords"}
                placeholder={"exclude_posts"}
                handleChange={(e) =>
                  handleChangeInput(e, setSpamKeyword, "spamKeywords")
                }
                handleAddKeywordsTolist={() => handlekeyboardPress(spamKeyword)}
                value={spamKeyword}
                limitCount={limitCountKeywords}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className="social-media-field-grid keywords-inputs-fields"
            >
              <CustomerCareInput
                coustomerCareVal={coustomerCareVal}
                setCoustomerCareVal={setCoustomerCareVal}
                isSpecificFormat={true}
                monitorData={monitorData}
                setMonitorErrors={setMonitorErrors}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className="social-media-field-grid keywords-inputs-fields"
            >
              <InputWithAddButton
                labelName={"ignore_tweets"}
                setMonitorErrors={setMonitorErrors}
                monitorErrors={monitorErrors}
                setMonitorData={setMonitorData}
                monitorData={monitorData}
                keyFeild={"ignoreTweets"}
                placeholder={"insert_username_then_enter_with_add_button"}
                handleChange={(e) =>
                  handleChangeInput(e, setIgnoreTweets, "ignoreTweets", [
                    monitorData.userName,
                    ...coustomerCareVal,
                  ])
                }
                handleAddKeywordsTolist={() =>
                  handleAddKeywordsTolist(
                    "ignore_tweets",
                    ignoreTweets,
                    setIgnoreTweets,
                    "ignoreTweets",
                    [monitorData.userName, ...coustomerCareVal],
                  )
                }
                value={ignoreTweets}
                limitCount={limitCountKeywords}
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownSection
                options={
                  twitterLanguagesAndCountries?.countries?.length
                    ? twitterLanguagesAndCountries?.countries
                    : []
                }
                placeholder={CheckValueLocale("select_countries", "", {}, intl)}
                label={CheckValueLocale("countries", "", {}, intl)}
                tooltip={CheckValueLocale(
                  "targeted_countries_tooltip",
                  "",
                  {},
                  intl,
                )}
                onChange={onCoutriesChange}
                applySelectionConditionFn={applySelectionConditionFn}
                tooltipPosition="bottom"
                errorText="public_monitor_limit_excceds"
                limit={limitCountKeywords}
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownSection
                options={
                  twitterLanguagesAndCountries?.languages?.length
                    ? twitterLanguagesAndCountries?.languages
                    : []
                }
                placeholder={CheckValueLocale("select_languages", "", {}, intl)}
                label={CheckValueLocale("languages", "", {}, intl)}
                tooltip={CheckValueLocale(
                  "targeted_languages_tooltip",
                  "",
                  {},
                  intl,
                )}
                onChange={onLanguagesChange}
                applySelectionConditionFn={applySelectionConditionFn}
                tooltipPosition="bottom"
                errorText="public_monitor_limit_excceds"
                limit={limitCountKeywords}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default TwitterPublicAccount;
