import { useIntl } from "react-intl";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import moment from "moment";

const AppliedFiltersModal = ({
  appliedFilters,
  open,
  handleAppliedFiltersModal,
  calcDays,
}) => {
  const intl = useIntl();
  return (
    <>
      <Dialog
        open={open}
        className="summary-filters-modal"
        onClose={handleAppliedFiltersModal}
      >
        <DialogTitle className="summary-filters-title">
          <Box component="span">
            {CheckValueLocale("applied_filters", "", {}, intl)}
          </Box>
          <FontAwesomeIcon
            icon={faTimes}
            className="summary-filters-close"
            onClick={handleAppliedFiltersModal}
          />
        </DialogTitle>
        <DialogContent className="summary-filters-content">
          <Box className="summary-filters-wrapper">
            <Box component="p" className="summary-filters-box">
              <Box component="span" className="summary-filters-box-title">
                {CheckValueLocale("date_range", "", {}, intl)} :
              </Box>
              <Box component="span" className="summary-filters-box-info">
                {moment.unix(appliedFilters?.startDate)?.format("YYYY/MM/DD")} -{" "}
                {moment(calcDays("minus", appliedFilters?.endDate, 1))?.format(
                  "YYYY/MM/DD",
                )}
              </Box>
            </Box>
            {Object.keys(appliedFilters?.filterParams || {}).map((filter) => {
              const appliedFiltersValue =
                appliedFilters?.filterParams?.[filter];
              return (
                <Box component="p" className="summary-filters-box">
                  <Box component="span" className="summary-filters-box-title">
                    {CheckValueLocale(filter, "", {}, intl)} :
                  </Box>
                  <Box component="span" className="summary-filters-box-info">
                    {typeof appliedFiltersValue === "string"
                      ? CheckValueLocale(appliedFiltersValue, "", {}, intl)
                      : appliedFiltersValue?.map((filterValue, i) => {
                          return i !== 0
                            ? "," + CheckValueLocale(filterValue, "", {}, intl)
                            : CheckValueLocale(filterValue, "", {}, intl);
                        })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions className="summary-filters-actions">
          <LucButton
            variant="filled"
            size="large"
            type="primary"
            id="summary-filters-done"
            onClick={handleAppliedFiltersModal}
          >
            {CheckValueLocale("done", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppliedFiltersModal;
