import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import TitleBox from "../header/titleBox";
import { useIntl } from "react-intl";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faObjectGroup,
  faStarChristmas,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";

const Dashboards = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const { loading, loadingDelay, dashboard } = props;
  const dataSkeleton = [
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "4" },
  ];

  const swiperSkeletonfun = () => {
    return (
      <Swiper
        navigation
        observer={true}
        spaceBetween={15}
        slidesPerView={3.6}
        modules={[Navigation]}
        key={intl.locale}
      >
        {dataSkeleton?.map((el, index) => {
          return (
            <SwiperSlide key={index}>
              <Box className="skeleton-parant">
                <Box className="skeleton-head">
                  <Skeleton variant="rectangular" width={40} height={40} />
                  <Skeleton variant="rectangular" width={160} height={20} />
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  return (
    <Box className="section-slide home-page-dashboard">
      <TitleBox
        title={"dashboards"}
        tooltipText={"most_viewed_dashboards"}
        isDashBoard
      />
      <Box className="slider-container">
        {loading || loadingDelay ? (
          swiperSkeletonfun()
        ) : (
          <Swiper
            navigation
            observer={true}
            spaceBetween={15}
            slidesPerView={3.6}
            modules={[Navigation]}
            key={intl.locale}
          >
            {dashboard?.dashboards?.length ? (
              dashboard?.dashboards?.map((item, index) => {
                const dashbardType = item?.attributes?.dashboard_type;
                const monitorType = item?.attributes?.dashboard_monitor_type;
                const dashboardActiveTab =
                  item?.attributes?.dashboard_side_bar?.[0]?.analytics?.[0]
                    ?.tabName;

                const dashboardLink =
                  dashbardType == "CUSTOM_DASHBOARD"
                    ? `/dashboard/custom/view/${item?.id}`
                    : `/dashboard/${monitorType}/${dashboardActiveTab}/${item?.id}`;

                const disabledLink =
                  dashbardType === "AGGREGATE_DASHBOARD" &&
                  !item?.attributes?.is_accessible;

                return (
                  <SwiperSlide key={index}>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        disabledLink
                          ? CheckValueLocale(
                              "homepage_dashboard_warning_msg",
                              "",
                              {},
                              intl,
                            )
                          : ""
                      }
                    >
                      <Link
                        className={`item-slide-notifications slide-dashbard ${
                          disabledLink ? "disabled" : ""
                        }`}
                        to={disabledLink ? {} : dashboardLink}
                      >
                        <Box className="dashbard-type-icon">
                          <FontAwesomeIcon
                            icon={
                              dashbardType === "CUSTOM_DASHBOARD"
                                ? faStarChristmas
                                : faObjectGroup
                            }
                          />
                        </Box>
                        <Box className="dashbard-name">
                          {item?.attributes?.name}
                        </Box>
                      </Link>
                    </Tooltip>
                  </SwiperSlide>
                );
              })
            ) : (
              <Box className="no-data-home-page-dashboard">
                {CheckValueLocale(
                  "home_page_empty_dashboard",
                  "",
                  {
                    firstDashboard: (
                      <Typography
                        className="empty-link"
                        onClick={() => window.open("/dashboard/dashboard_list")}
                      >
                        {CheckValueLocale("first_dashboard", "", {}, intl)}
                      </Typography>
                    ),
                  },
                  intl,
                )}
              </Box>
            )}
          </Swiper>
        )}
      </Box>
    </Box>
  );
};
export default Dashboards;
