import { isEmptyArray, removeTrailingZeros } from "utils/helpers";
import { handleXlsData } from "pages/Monitors/Components/exportButton/exportExcel/ExportExcelSheet/excelCommonFn";

const monitor_vs_monitors = (
  response,
  lang,
  convertSeconds,
  posNegNeuPercentage,
) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var allSheetData = [],
      monitorVsMonitors = [];

    response?.map((i) => {
      Object?.entries(i)?.forEach(([key, value]) => {
        switch (key) {
          case "monitorVsMonitors":
            let headArr = [];
            let tempObj = {};
            let posNegNeu = [];
            value?.map((i) => {
              if (i?.name === "monitors_name") headArr = i?.value;
              else if (
                i?.name === "Positive" ||
                i?.name === "neutral" ||
                i?.name === "Negative"
              )
                posNegNeu?.push(i);
            });

            value?.map((i) => {
              if (i?.name === "pdf_data_sources") {
                headArr?.map(
                  (item, index) => (tempObj[`${item}`] = lang[i?.value[index]]),
                );
              } else if (i?.name === "pdf_share_of_voice") {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[index]) || i?.value[index] === null
                      ? `—`
                      : `${removeTrailingZeros(
                          parseFloat(i?.value[index])?.toFixed(2),
                        )}%`;
                });
              } else if (i?.name === "pdf_total_engagement_per_follower") {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[index]) || i?.value[index] === null
                      ? `—`
                      : i?.value[index] % 1 === 0
                        ? i?.value[index]
                        : i?.value[index]?.toFixed(3);
                });
              } else if (i?.name === "pdf_response_rate") {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[index]) || i?.value[index] === null
                      ? `—`
                      : `${removeTrailingZeros(
                          (i?.value[index] * 100)?.toFixed(2),
                        )}%`;
                });
              } else if (i?.name === "pdf_resp_time") {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    i?.value[index] === 0
                      ? 0
                      : isNaN(i?.value[index]) || i?.value[index] === null
                        ? `—`
                        : `${convertSeconds(parseFloat(i?.value[index]))}`;
                });
              } else if (
                i?.name === "Positive" ||
                i?.name === "neutral" ||
                i?.name === "Negative"
              ) {
                let valObj = {};
                posNegNeuPercentage(posNegNeu)?.map((item) => {
                  if (item?.name === i?.name) valObj = item;
                });

                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    i?.value[index] === 0
                      ? `0%`
                      : isNaN(i?.value[index]) || i?.value[index] === null
                        ? `—`
                        : `${valObj?.value[index]}%`;
                });
              } else {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[index]) || i?.value[index] === null
                      ? `—`
                      : removeTrailingZeros(i?.value[index]?.toFixed(2));
                });
              }

              if (i?.name !== "monitors_name")
                monitorVsMonitors?.push({
                  [lang.metrics]: lang[i?.name],
                  ...tempObj,
                });
            });
            allSheetData?.push([monitorVsMonitors, "Sheet1"]);
            break;

          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { monitor_vs_monitors };
