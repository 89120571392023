import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import { AutoCompleteDropdown } from "../sheardComponents/components/autoCompleteDropdown/autoCompleteDropdown";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { typeFormatMonitor } from "../../sharedLogic/inviteUserAdapter";

const MonitorRenderItem = (option, selected, nameKey, multiple, intl) => {
  return (
    <Box className="user-tabs-selectable-tab-content-render-item">
      <Typography className="render-item-title">
        {option?.name}
        {option?.monitor_type_name ? (
          <Typography component="span" className="render-item-sub-title">
            (
            {CheckValueLocale(
              typeFormatMonitor(option?.monitor_type_name),
              "",
              {},
              intl,
            )}
            )
          </Typography>
        ) : null}
      </Typography>
      <Box className="render-item-icons">
        {option?.data_sources?.map?.((dataSopurce) => {
          return getSocialIcon(dataSopurce);
        })}
      </Box>
    </Box>
  );
};

export const TopicsTab = ({
  monitorsTopics = [],
  selectedMonitorsTopics = [],
  setTabsData,
  title,
  isLoading,
  tabsErrors,
  setTabsErrors,
}) => {
  const intl = useIntl();
  const filteredMonitors = monitorsTopics?.filter(
    (monitor) =>
      !selectedMonitorsTopics?.find(
        (selectedTopic) => selectedTopic?.id === monitor?.id,
      ),
  );
  const [lastRowValue, setLastRowValue] = useState({});

  const handleChangeMonitors = (newValue, index) => {
    const newMonitorsTopics = [...selectedMonitorsTopics];
    const monitor = {
      id: newValue?.id,
      name: newValue?.name,
      topics: [],
    };
    newMonitorsTopics[index] = monitor;
    setTabsData((tabsData) => ({
      ...tabsData,
      topics: newMonitorsTopics,
    }));
  };

  const handleRemoveRow = (index) => {
    const rowData = selectedMonitorsTopics?.[index];
    // if only one row and has data, clear it
    if (
      selectedMonitorsTopics?.length === 1 &&
      (rowData?.id || rowData?.topics?.length)
    ) {
      setTabsData((tabsData) => ({
        ...tabsData,
        topics: [
          {
            id: "",
            name: "",
            topics: [],
          },
        ],
      }));
    } else if (selectedMonitorsTopics?.length > 1) {
      // if more than one row and has data, remove it
      const newMonitorsTopics = selectedMonitorsTopics?.filter(
        (row, i) => i !== index,
      );
      setTabsData((tabsData) => ({
        ...tabsData,
        topics: newMonitorsTopics,
      }));
    }
  };

  const handleAddRow = () => {
    const newTabData = [
      ...selectedMonitorsTopics,
      { id: "", name: "", topics: [] },
    ];
    setTabsData((tabsData) => ({
      ...tabsData,
      topics: newTabData,
    }));
  };
  const handleChangeTopics = (newValue, index) => {
    const newMonitorsTopics = [...selectedMonitorsTopics];
    const selectedMonitor = newMonitorsTopics[index];
    selectedMonitor.topics = newValue;
    setTabsData((tabsData) => ({
      ...tabsData,
      topics: newMonitorsTopics,
    }));
  };
  useEffect(() => {
    if (selectedMonitorsTopics?.length) {
      setLastRowValue(
        selectedMonitorsTopics[selectedMonitorsTopics?.length - 1],
      );
    }
  }, [selectedMonitorsTopics]);

  useEffect(() => {
    const topicsError = tabsErrors?.topics;
    const topicsPreError = tabsErrors?.topicsPreError;
    // error should happen if any row has monitor and no topics are empty
    const hasError = selectedMonitorsTopics?.some(
      (monitor) => monitor?.id && !monitor?.topics?.length,
    );
    if (!hasError && (topicsError || topicsPreError)) {
      setTabsErrors((prevTabsErrors) => ({
        ...prevTabsErrors,
        topics: false,
        topicsPreError: false,
      }));
    } else if (hasError || hasError !== topicsPreError) {
      setTabsErrors((prevTabsErrors) => ({
        ...prevTabsErrors,
        topicsPreError: true,
      }));
    }
  }, [selectedMonitorsTopics]);
  return (
    <Box className="selectable-tab-content-container">
      <Typography variant="body2" className="selectable-tab-content-title">
        {CheckValueLocale(title, "", {}, intl)}
      </Typography>
      {selectedMonitorsTopics?.map((monitor, index) => {
        const hasError =
          monitor?.id && !monitor?.topics?.length && tabsErrors?.topics;
        const selectedMonitor = monitorsTopics?.find(
          (globalMonitor) => globalMonitor?.id === monitor?.id,
        );
        const isTopicsDisabled = !monitor?.id;
        return (
          <Box
            className="selectable-tab-content-row"
            key={monitor?.id || index}
          >
            <AutoCompleteDropdown
              label={CheckValueLocale("Select_monitors", "", {}, intl)}
              options={filteredMonitors}
              value={monitor?.id ? monitor : null}
              onChange={(newValue) => handleChangeMonitors(newValue, index)}
              isLoading={isLoading}
              loadingMessage={CheckValueLocale(
                "dropdown_loading",
                "",
                {},
                intl,
              )}
              emptyListMessage={CheckValueLocale(
                "no_monitors_to_show",
                "",
                {},
                intl,
              )}
              multiple={false}
              renderItem={MonitorRenderItem}
            />

            <AutoCompleteDropdown
              label={CheckValueLocale("Select_topics", "", {}, intl)}
              options={selectedMonitor?.topics || []}
              value={monitor?.id ? monitor?.topics : []}
              onChange={(newValue) => handleChangeTopics(newValue, index)}
              emptyListMessage={CheckValueLocale(
                "no_topics_to_show",
                "",
                {},
                intl,
              )}
              error={hasError}
              helperText={
                hasError
                  ? CheckValueLocale("topics_field_required", "", {}, intl)
                  : null
              }
              multiple={true}
              listboxClassName="user-tabs-select-dropdown-list"
              disabled={isTopicsDisabled}
              renderTags={(selected = [], _getTagProps, isOpen) => {
                if (isOpen) return null;
                return (
                  <Typography className="user-tabs-content-select-msg auto-comp-empty">
                    {`${selected?.length} ${CheckValueLocale(
                      "tab_selected_options",
                      "",
                      {},
                      intl,
                    )}`}
                  </Typography>
                );
              }}
            />
            <LucButton
              variant="outline"
              type="red"
              minWidth={40}
              className="icon-tab-button"
              onClick={() => handleRemoveRow(index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </LucButton>
          </Box>
        );
      })}
      <Box>
        <LucButton
          minWidth={40}
          className="icon-tab-button"
          disabled={
            !lastRowValue?.id ||
            !lastRowValue?.topics?.length ||
            !filteredMonitors?.length
          }
          onClick={handleAddRow}
        >
          <FontAwesomeIcon icon={faPlus} />
        </LucButton>
      </Box>
    </Box>
  );
};
