import { useIntl } from "react-intl";
import Pagination from "@mui/material/Pagination";
import { Typography, Box, Tooltip } from "@mui/material";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SavedResultItem = (props) => {
  const intl = useIntl();
  const {
    handleOpenDeleteModal,
    exploreSavedListData,
    getPostsCount,
    setSetSelectedItem,
    pagination,
    page,
    handleChangePage,
    showTooltipText,
    handleShowSavedExplore,
  } = props;

  return (
    <Box className="saved-results-list">
      <Typography
        component={"p"}
        className={`saved-results-title ${
          exploreSavedListData?.length && "saved-results-title-no-empty"
        }`}
      >
        {CheckValueLocale("explore_saved_results_title", "", {}, intl)}
      </Typography>

      {isEmptyArray(exploreSavedListData) ? (
        <Typography component={"p"} className="empty-saved-results">
          {CheckValueLocale("explore_saved_empty_results", "", {}, intl)}
        </Typography>
      ) : (
        <Box className="saved-results-content-data">
          {exploreSavedListData?.map((item) => {
            return (
              <Box
                key={item?.id}
                className="saved-results-grid"
                onClick={() =>
                  handleShowSavedExplore(item?.id, item?.attributes?.status)
                }
              >
                <Box className="saved-result-content">
                  <Box className="saved-ressult-content-wrapper">
                    <Typography component={"p"} className="saved-results-name">
                      {/* {showTooltipText("item?.attributes?.name ", 30)} */}
                      {item?.attributes?.name || ""}
                    </Typography>
                    <Typography
                      component={"p"}
                      className="saved-results-count-posts"
                    >
                      {CheckValueLocale(
                        "explore_saved_res_posts_count",
                        "",
                        {
                          count: item?.attributes?.collected_count?.length
                            ? getPostsCount(item?.attributes?.collected_count)
                            : 0,
                        },
                        intl,
                      )}
                    </Typography>
                  </Box>
                  <Tooltip
                    title={CheckValueLocale("delete", "", {}, intl)}
                    arrow
                    id="delete-tooltip-icon-explore"
                  >
                    <Box
                      onClick={(event) => {
                        setSetSelectedItem(item?.attributes);
                        handleOpenDeleteModal(event);
                      }}
                      className="delete-explore-saved-container"
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="saved-result-delete-icon"
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      {pagination?.pages > 1 && exploreSavedListData?.length ? (
        <Pagination
          onChange={handleChangePage}
          page={page}
          className="pagination-monitor-list"
          count={pagination?.pages}
          variant="outlined"
          rowsPerPage={pagination?.items}
        />
      ) : null}
    </Box>
  );
};

export default SavedResultItem;
