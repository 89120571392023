import { useQuery } from "@tanstack/react-query";
import LiveDashboardController from "services/controllers/liveDashboardController";
export const useMonitorLiveDashboardList = (queryData) => {
  return useQuery({
    queryKey: ["liveDashboardList", queryData?.monitor_id],
    queryFn: () =>
      LiveDashboardController.getMonitorLiveDashboardList(queryData),
    enabled: !!queryData?.monitor_id,
    select: (data) => data?.data?.monitor_user_live_dashboard,
    retry: false,
  });
};
