import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Box,
  MenuItem,
  Select,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import "./publishPopup.scss";
const PublishPopUp = ({
  setOpen,
  setPublishTypeEvent,
  popupBody,
  handleClose,
  setOpenPublish,
}) => {
  return <Box className="publish-pop-up-events">{popupBody}</Box>;
};

export default PublishPopUp;
