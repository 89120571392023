import { Box } from "@mui/material";
import Footer from "shared/footer/Footer";
import FormCloudFlareLiveDashboard from "./formCloudFlareLiveDashboard";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useEffect, useState } from "react";
import { useAuthPublicLiveDashboard } from "pages/createLiveDashboard/liveDashboardHooks/useAuthPublicLiveDashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faUserClock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Services from "services/api/Services.js";

const PublicLiveDashboardAuth = ({ urlParams, setChallengeKeyStorge }) => {
  const intl = useIntl();
  const [verify, setVerify] = useState(null);
  const [challengeKey, setChallengeKey] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    data: authPublicDashboard,
    isError,
    error,
  } = useAuthPublicLiveDashboard({
    challenge_string: challengeKey,
    monitor_id: urlParams?.monitorId,
    dashboard_user_id: urlParams?.userDashboardId,
  });

  useEffect(() => {
    if (authPublicDashboard?.data?.success) {
      //save challenge key in local storge
      //call show(public) and open socket
      localStorage.setItem("challengeKey", challengeKey);
      setChallengeKeyStorge(challengeKey);
      setErrorMessage("");
    } else if (isError) {
      setErrorMessage(error?.errorMsg);
      localStorage.removeItem("challengeKey");
    }
  }, [authPublicDashboard, error]);

  const handleVerifySuccess = (newKey) => {
    setChallengeKey(newKey);
    setVerify(true);
  };
  const handleVerifyExpire = () => {
    setVerify(false);
  };

  return (
    <Box className="formcloud-live-dashboard-public">
      <Box className="formcloud-live-dashboard-public-header">
        <img src={Services.companyFaviconPathPNG} alt="Lucidya Logo" />
      </Box>
      <Box className="formcloud-live-dashboard-body">
        <Box className="formcloud-live-dashboard-box">
          {isError ? (
            <>
              <Box className="icon-formcloud-live-dashboard-error">
                <FontAwesomeIcon
                  icon={errorMessage == "Limit Reached" ? faUserClock : faBan}
                />
              </Box>
              <Box className="verifying-live-dashboard-public">
                {CheckValueLocale(
                  errorMessage == "Limit Reached"
                    ? "visitors_limit_reached_live_dashboard"
                    : "visitors_not_available_live_dashboard",
                  "",
                  {},
                  intl,
                )}
              </Box>
              <Box className="visitors-limit-reached-description">
                {CheckValueLocale(
                  errorMessage == "Limit Reached"
                    ? "visitors_limit_reached_description_one"
                    : "visitors_not_available_description_one",
                  "",
                  {},
                  intl,
                )}
                <Link to="/login">
                  {CheckValueLocale("login", "", {}, intl)}
                </Link>
                {CheckValueLocale(
                  errorMessage == "Limit Reached"
                    ? "visitors_limit_reached_description_two"
                    : "visitors_not_available_description_two",
                  "",
                  {},
                  intl,
                )}
              </Box>
            </>
          ) : (
            <>
              <Box>
                <img src={Services.companyFaviconPathPNG} alt="Lucidya Logo" />
              </Box>
              {!verify ? (
                <Box className="verifying-live-dashboard-public">
                  {CheckValueLocale(
                    "verifying_live_dashboard_public",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              ) : null}

              <FormCloudFlareLiveDashboard
                handleVerifySuccess={handleVerifySuccess}
                handleVerifyExpire={handleVerifyExpire}
              />
            </>
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default PublicLiveDashboardAuth;
