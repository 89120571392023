import "../../../monitors.scss";
import OverViewSection from "./overviewSection";
import AuthorAnalyticsSection from "./authorAnalyticsSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { isEmptyArray } from "utils/helpers";
import TopicsSection from "../../../Components/topicsSection";

const EngagementsBody = (props) => {
  const [postsAnalyticsData, setPostsAnalyticsData] = useState([]);
  const [overViewData, setOverViewData] = useState([]);

  const [overView, setOverView] = useState([]);
  const [overViewPreLoader, setOverViewPreLoader] = useState([]);
  const [postsVolume, setPostsVolume] = useState([]);
  const [postsVolumePreLoader, setPostsVolumePreLoader] = useState([]);
  const [postsInteractions, setPostsInteractions] = useState([]);
  const [postsInteractionsPreLoader, setPostsInteractionsPreLoader] = useState(
    [],
  );
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [topEngaged, setTopEngaged] = useState([]);
  const [topEngagedPreLoader, setTopEngagedPreLoader] = useState([]);
  const [topSites, setTopSites] = useState([]);
  const [topSitesPreLoader, setTopSitesPreLoader] = useState([]);
  const [reachFunnelLegacy, setReachFunnelLegacy] = useState([]);
  const [
    reachFunnelLegacyPreLoaderDataSources,
    setReachFunnelLegacyPreLoaderDataSources,
  ] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);

  const [topLanguages, setTopLanguages] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);

  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);

  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  const [themeXlxs, setThemeXlxs] = useState([]);

  let childProps = {
    setOverViewData,
    overView,
    setOverView,
    overViewPreLoader,
    setOverViewPreLoader,
    postsVolume,
    setPostsVolume,
    postsVolumePreLoader,
    setPostsVolumePreLoader,
    reachFunnelLegacyPreLoaderDataSources,
    setReachFunnelLegacyPreLoaderDataSources,
    postsInteractions,
    setPostsInteractions,
    postsInteractionsPreLoader,
    setPostsInteractionsPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    topEngaged,
    setTopEngaged,
    topEngagedPreLoader,
    topSites,
    setTopSites,
    topSitesPreLoader,
    setTopSitesPreLoader,
    reachFunnelLegacy,
    setReachFunnelLegacy,
    setTopEngagedPreLoader,
    topPosts,
    setTopPosts,
    topTopPostsPreLoaderDataSources,
    setTopPostsPreLoaderDataSources,
    setPostsAnalyticsData,
    topLanguages,
    setTopLanguages,
    topLanguagesPreLoader,
    topCountries,
    setTopCountries,
    setTopLanguagesPreLoader,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    themeTrend,
    setThemeTrend,
    themeAnalysis,
    setThemeAnalysis,
    themeTrendPreLoader,
    setThemeTrendPreLoader,
    themeAnalysisPreLoader,
    setThemeAnalysisPreLoader,
    themeXlxs,
    setThemeXlxs,
  };

  useEffect(() => {
    if (!isEmptyArray(postsAnalyticsData) && !isEmptyArray(overViewData)) {
      props.setExcelSheetData(overViewData.concat(postsAnalyticsData));
    }
  }, [overViewData, postsAnalyticsData]);

  useEffect(() => {
    props.checkDataIsFounded(
      postsAnalyticsData,
      overViewData,
      overView,
      postsVolume,
      postsInteractions,
      sentimentAnalysis,
      topEngaged,
      topSites,
      reachFunnelLegacy,
      topPosts,
      topLanguages,
      topCountries,
    );

    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      overViewPreLoader,
      postsVolumePreLoader,
      postsInteractionsPreLoader,
      sentimentAnalysisPreLoader,
      topEngagedPreLoader,
      topSitesPreLoader,
      reachFunnelLegacyPreLoaderDataSources,
      topTopPostsPreLoaderDataSources,
      topLanguagesPreLoader,
      topCountriesPreLoader,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );
  }, [
    overViewPreLoader,
    postsVolumePreLoader,
    postsInteractionsPreLoader,
    sentimentAnalysisPreLoader,
    topEngagedPreLoader,
    topSitesPreLoader,
    reachFunnelLegacyPreLoaderDataSources,
    topTopPostsPreLoaderDataSources,
    topLanguagesPreLoader,
    topCountriesPreLoader,
    postsAnalyticsData,
    overViewData,
    overView,
    postsVolume,
    postsInteractions,
    sentimentAnalysis,
    topEngaged,
    topSites,
    reachFunnelLegacy,
    topPosts,
    topLanguages,
    topCountries,
  ]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      topTopPostsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([topPosts]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    topPosts,
    topTopPostsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  const newsBlogIcon = (
    <span className="topics-twitter-icon">
      <FontAwesomeIcon
        ml={2}
        className={"icon-after-title-news news-blogs-icon"}
        icon={faNewspaper}
      />
    </span>
  );
  return (
    <>
      <OverViewSection newsBlogIcon={newsBlogIcon} {...props} {...childProps} />
      <AuthorAnalyticsSection
        newsBlogIcon={newsBlogIcon}
        {...props}
        {...childProps}
      />
      <TopicsSection iconToDisplay={newsBlogIcon} {...props} isNB />
    </>
  );
};

export default EngagementsBody;
