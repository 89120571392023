import React, { useEffect, useState } from "react";
import OverViewSection from "./overviewSection";
import AuthorsAnalyticsSection from "./authorAnalytics";
import { isEmptyArray } from "utils/helpers";
import TopicsSection from "../../../Components/topicsSection";

const EngagementsBody = (props) => {
  const [postsAnalyticsData, setPostsAnalyticsData] = useState([]);
  const [overViewData, setOverViewData] = useState([]);

  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  const [commentsVolume, setCommentsVolume] = useState([]);
  const [totalCommentsVolumeCount, setTotalCommentsVolumeCount] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [reactionsPreLoader, setReactionsPreLoader] = useState([]);
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );

  const [engagersVolume, setEngagersVolume] = useState([]);
  const [totalEngagersVolumeCount, setTotalEngagersVolumeCount] = useState([]);
  const [engagersVolumePreLoader, setEngagersVolumePreLoader] = useState([]);
  const [genderDistribution, setGenderDistribution] = useState([]);
  const [genderDistributionPreLoader, setGenderDistributionPreLoader] =
    useState([]);
  const [topLanguages, setTopLanguages] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [topCitiesPreLoader, setTopCitiesPreLoader] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);
  const [accountAuthorActivity, setAccountAuthorActivity] = useState([]);
  const [accountAuthorActivityPreLoader, setAccountAuthorActivityPreLoader] =
    useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState([]);
  const [topPostsPreLoader, setTopPostsPreLoader] = useState([]);
  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  const childProps = {
    associatedTopicsPreLoader,
    setAssociatedTopicsPreLoader,
    associatedTopics,
    setAssociatedTopics,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    topHashtags,
    setTopHashtags,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topKeywords,
    setTopKeywords,
    commentsInteractionsLegend,
    setCommentsInteractionsLegend,
    commentsInteractionsPreLoader,
    setCommentsInteractionsPreLoader,
    commentsInteractions,
    setCommentsInteractions,
    neutralArray,
    setNeutralArray,
    positiveArray,
    setPositiveArray,
    negativeArray,
    setNegativeArray,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    reactionsPreLoader,
    setReactionsPreLoader,
    reactions,
    setReactions,
    totalCommentsVolumeCount,
    setTotalCommentsVolumeCount,
    commentsVolume,
    setCommentsVolume,
    commentsVolumePreLoader,
    setCommentsVolumePreLoader,
    topPostsPreLoader,
    setTopPostsPreLoader,
    topPostsCount,
    setTopPostsCount,
    topPosts,
    setTopPosts,
    accountAuthorActivityPreLoader,
    setAccountAuthorActivityPreLoader,
    accountAuthorActivity,
    setAccountAuthorActivity,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    topCountries,
    setTopCountries,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCities,
    setTopCities,
    dialectsPreLoader,
    setDialectsPreLoader,
    subDialects,
    setSubDialects,
    dialects,
    setDialects,
    topLanguagesPreLoader,
    setTopLanguagesPreLoader,
    topLanguages,
    setTopLanguages,
    genderDistributionPreLoader,
    setGenderDistributionPreLoader,
    genderDistribution,
    setGenderDistribution,
    engagersVolumePreLoader,
    setEngagersVolumePreLoader,
    totalEngagersVolumeCount,
    setTotalEngagersVolumeCount,
    engagersVolume,
    setEngagersVolume,
    themeTrend,
    setThemeTrend,
    themeAnalysis,
    setThemeAnalysis,
    themeTrendPreLoader,
    setThemeTrendPreLoader,
    themeAnalysisPreLoader,
    setThemeAnalysisPreLoader,
  };

  useEffect(() => {
    if (!isEmptyArray(postsAnalyticsData) && !isEmptyArray(overViewData)) {
      props.setExcelSheetData(overViewData.concat(postsAnalyticsData));
    }
  }, [overViewData, postsAnalyticsData]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      topPostsPreLoader,
      accountAuthorActivityPreLoader,
      topCountriesPreLoader,
      topCitiesPreLoader,
      dialectsPreLoader,
      topLanguagesPreLoader,
      genderDistributionPreLoader,
      engagersVolumePreLoader,
      associatedTopicsPreLoader,
      topHashtagsPreLoader,
      topKeywordsPreLoader,
      commentsInteractionsPreLoader,
      sentimentAnalysisPreLoader,
      reactionsPreLoader,
      commentsVolumePreLoader,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      topPosts,
      accountAuthorActivity,
      topCities,
      subDialects,
      dialects,
      topLanguages,
      genderDistribution,
      totalEngagersVolumeCount,
      engagersVolume,
      associatedTopics,
      topHashtags,
      topKeywords,
      commentsInteractionsLegend,
      commentsInteractions,
      neutralArray,
      positiveArray,
      negativeArray,
      sentimentAnalysis,
      reactions,
      totalCommentsVolumeCount,
      commentsVolume,
    );
  }, [
    topPostsPreLoader,
    accountAuthorActivityPreLoader,
    topCountriesPreLoader,
    topCitiesPreLoader,
    dialectsPreLoader,
    topLanguagesPreLoader,
    genderDistributionPreLoader,
    engagersVolumePreLoader,
    associatedTopicsPreLoader,
    topHashtagsPreLoader,
    topKeywordsPreLoader,
    commentsInteractionsPreLoader,
    sentimentAnalysisPreLoader,
    reactionsPreLoader,
    commentsVolumePreLoader,
    topPosts,
    accountAuthorActivity,
    topCities,
    subDialects,
    dialects,
    topLanguages,
    genderDistribution,
    totalEngagersVolumeCount,
    engagersVolume,
    associatedTopics,
    topHashtags,
    topKeywords,
    commentsInteractionsLegend,
    commentsInteractions,
    neutralArray,
    positiveArray,
    negativeArray,
    sentimentAnalysis,
    reactions,
    totalCommentsVolumeCount,
    commentsVolume,
  ]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      topPostsPreLoader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([topPosts]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [topPosts, topPostsPreLoader, props.preLoaderTrackerDataSources]);

  return (
    <>
      <OverViewSection
        setOverViewData={setOverViewData}
        {...props}
        {...childProps}
      />
      <AuthorsAnalyticsSection
        setPostsAnalyticsData={setPostsAnalyticsData}
        {...props}
        {...childProps}
      />
      <TopicsSection iconToDisplay={props.FacebookIcon} isFacebook {...props} />
    </>
  );
};

export default EngagementsBody;
