import { useState, useEffect } from "react";
import moment from "moment";
import { Typography, TableCell, TableRow, TableBody } from "@mui/material";
import _ from "lodash";
import FetchingData from "components/fetchingData/fetchingData";
import { dateTotextMinusLastDay, secondsToHms } from "utils/helpers/index";

// Fontawesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngry, faSmile } from "@fortawesome/free-regular-svg-icons";
import TotalAskedIcon from "images/benchmark-images/total-asked-question.svg";
import ResponseRateIcon from "images/benchmark-images/response-rate.svg";
import ResponseTimeIcon from "images/benchmark-images/response-time-to-question.svg";
import { dateTotext, CheckValueLocale } from "utils/helpers";
/*------------------------------------------------*/

const CustomerCareOverview = (props) => {
  const {
    monitorPublicType,
    intl,
    rmqResponse,
    preloaderDatasource,
    benchmarkHeadData,
    resetAllFields,
    setAllDataExist,
    customerData,
    setCustomerData,
  } = props;

  const [maxTotalAskedQuestionsCount, setMaxTotalAskedQuestionsCount] =
    useState(0);
  const [maxResponseRateCount, setMaxResponseRateCount] = useState(0);
  const [maxResponseTimeQuestionsCount, setMaxResponseTimeQuestionsCount] =
    useState(0);
  const [customerCareTableHeadCellData, setCustomerCareTableHeadCellData] =
    useState([]);

  const lastPeriodDates = preloaderDatasource;
  const monitorsID = benchmarkHeadData?.monitorData?.map(
    (x) =>
      `${x?.monitor_id}_${x?.data_source_name?.charAt(0)?.toUpperCase() + x?.data_source_name?.slice(1)?.toLowerCase()}`,
  );

  const [totalAskedQuestionsData, setTotalAskedQuestionsData] = useState([]);
  const [responseRateData, setResponseRateData] = useState([]);
  const [responseTimeQuestionsData, setResponseTimeQuestionsData] = useState(
    [],
  );
  const [customerCarePreloader, setCustomercarePreloader] = useState([]);
  const [customerCareData, setCustomerCareData] = useState([]);
  const [customerCareId, setCustomerCareId] = useState([]);

  const handleCustomerOverviewData = (response, order, tableHeadData) => {
    if (order !== undefined) {
      switch (order) {
        case lastPeriodDates[0]:
          customerCareTableHeadCellData?.unshift(tableHeadData);
          setCustomerCareTableHeadCellData(customerCareTableHeadCellData);
          totalAskedQuestionsData?.unshift(
            response?.eventData?.total_number_of_questions,
          );
          setTotalAskedQuestionsData(totalAskedQuestionsData);
          responseTimeQuestionsData?.unshift(
            response?.eventData?.avg_response_time_in_seconds,
          );
          setResponseTimeQuestionsData(responseTimeQuestionsData);
          if (response?.eventData?.total_number_of_questions != 0)
            responseRateData?.unshift(
              response?.eventData?.number_of_answered_questions /
                response?.eventData?.total_number_of_questions,
            );
          else responseRateData?.unshift(0);
          setResponseRateData(responseRateData);
          break;
        case lastPeriodDates[1]:
          customerCareTableHeadCellData?.push(tableHeadData);
          setCustomerCareTableHeadCellData(customerCareTableHeadCellData);
          totalAskedQuestionsData?.push(
            response?.eventData?.total_number_of_questions,
          );
          setTotalAskedQuestionsData(totalAskedQuestionsData);
          responseTimeQuestionsData?.push(
            response?.eventData?.avg_response_time_in_seconds,
          );
          setResponseTimeQuestionsData(responseTimeQuestionsData);
          if (response?.eventData?.total_number_of_questions != 0)
            responseRateData?.push(
              response?.eventData?.number_of_answered_questions /
                response?.eventData?.total_number_of_questions,
            );
          else responseRateData?.push(0);
          setResponseRateData(responseRateData);
          break;
        default:
          break;
      }
    } else {
      const totalQuesArr = totalAskedQuestionsData;
      customerData?.totalAskedQuestionsData?.push(
        response?.eventData?.total_number_of_questions,
      );
      setCustomerData(customerData);
      totalQuesArr?.push(response?.eventData?.total_number_of_questions);
      setTotalAskedQuestionsData(totalQuesArr);
      const avgRespTimeArr = responseTimeQuestionsData;
      customerData?.responseTimeQuestionsData?.push(
        response?.eventData?.avg_response_time_in_seconds,
      );
      setCustomerData(customerData);
      avgRespTimeArr?.push(response?.eventData?.avg_response_time_in_seconds);
      setResponseTimeQuestionsData(avgRespTimeArr);
      const respRateArr = responseRateData;
      if (response?.eventData?.total_number_of_questions != 0) {
        customerData?.responseRateData?.push(
          response?.eventData?.number_of_answered_questions /
            response?.eventData?.total_number_of_questions,
        );
        respRateArr?.push(
          response?.eventData?.number_of_answered_questions /
            response?.eventData?.total_number_of_questions,
        );
      } else {
        customerData?.responseRateData?.push(0);
        respRateArr?.push(0);
      }
      setCustomerData(customerData);
      setResponseRateData(respRateArr);
    }
  };

  useEffect(() => {
    if (
      monitorPublicType === "Time_Period_vs_Time_Period" ||
      monitorPublicType === "Positive_vs_Negative"
    ) {
      setCustomerData({
        ...customerData,
        totalAskedQuestionsData,
        responseRateData,
        responseTimeQuestionsData,
      });
    }
  }, [totalAskedQuestionsData, responseTimeQuestionsData, responseRateData]);

  useEffect(() => {
    switch (rmqResponse?.eventName) {
      case "Twitter__VsAnotherBenchmarkV4Page__customer_care_performance":
      case "Facebook__VsAnotherBenchmarkV4Page__customer_care_performance":
      case "Instagram__VsAnotherBenchmarkV4Page__customer_care_performance":
      case "Talkwalker__VsAnotherBenchmarkV4Page__customer_care_performance":
        setAllDataExist(false);
        customerCareId?.push(
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source}`,
        );
        setCustomerCareId(customerCareId);
        customerCareData?.push(rmqResponse);
        setCustomerCareData(customerCareData);
        if (customerCareId?.length == monitorsID?.length) {
          let checkPreLoader = [];
          monitorsID?.map((x) => {
            customerCareId?.map((y) =>
              y == x ? checkPreLoader?.push(y) : null,
            );
          });
          if (checkPreLoader?.length == monitorsID?.length) {
            monitorsID?.map((id1) => {
              customerCareId?.map((id2, x) => {
                if (id1 == id2) {
                  let data = customerCareData[x];
                  handleCustomerOverviewData(data);
                }
              });
            });
          }
        }
        setCustomercarePreloader([
          ...customerCarePreloader,
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source?.toLowerCase()}`,
        ]);
        break;
      case "Twitter__LastPeriodBenchmarkV4Page__customer_care_performance":
      case "Facebook__LastPeriodBenchmarkV4Page__customer_care_performance":
      case "Instagram__LastPeriodBenchmarkV4Page__customer_care_performance":
      case "Talkwalker__LastPeriodBenchmarkV4Page__customer_care_performance":
        setAllDataExist(false);
        const tableHeadData = "Time_Period_vs_Time_Period"
          ? dateTotextMinusLastDay(rmqResponse?.period)
          : dateTotext(rmqResponse?.period);
        handleCustomerOverviewData(
          rmqResponse,
          rmqResponse?.period,
          tableHeadData,
        );
        setCustomercarePreloader([
          ...customerCarePreloader,
          rmqResponse?.period,
        ]);
        break;
      case "Twitter__PositiveNegativeBenchmarkV4Page__customer_care_performance":
      case "Facebook__PositiveNegativeBenchmarkV4Page__customer_care_performance":
      case "Instagram__PositiveNegativeBenchmarkV4Page__customer_care_performance":
      case "Talkwalker__PositiveNegativeBenchmarkV4Page__customer_care_performance":
        const sentimentTableHeadData = rmqResponse?.sentiment?.toLowerCase();
        handleCustomerOverviewData(
          rmqResponse,
          rmqResponse?.sentiment,
          sentimentTableHeadData,
        );
        setCustomercarePreloader([
          ...customerCarePreloader,
          rmqResponse?.sentiment,
        ]);
        break;
      default:
        break;
    }
  }, [rmqResponse]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setTotalAskedQuestionsData([]);
      setResponseRateData([]);
      setResponseTimeQuestionsData([]);
      setCustomercarePreloader([]);
      setCustomerCareTableHeadCellData([]);
      setAllDataExist(false);
      setCustomerData({
        totalAskedQuestionsData: [],
        responseRateData: [],
        responseTimeQuestionsData: [],
        customerCareTableHeadCellData: [],
        maxTotalAskedQuestionsCount: 0,
        maxResponseRateCount: 0,
        maxResponseTimeQuestionsCount: 0,
      });
    }
  }, [resetAllFields]);

  useEffect(() => {
    setAllDataExist(false);
    if (_.isEqual(customerCarePreloader?.sort(), preloaderDatasource?.sort())) {
      setAllDataExist(true);
      customerData.maxTotalAskedQuestionsCount = Math.max(
        ...totalAskedQuestionsData,
      );
      customerData.maxResponseRateCount = Math.max(...responseRateData);
      customerData.maxResponseTimeQuestionsCount = Math.max(
        ...responseTimeQuestionsData,
      );
      setCustomerData(customerData);
      setMaxTotalAskedQuestionsCount(Math.max(...totalAskedQuestionsData));
      setMaxResponseRateCount(Math.max(...responseRateData));
      setMaxResponseTimeQuestionsCount(Math.max(...responseTimeQuestionsData));
    }
  }, [customerCarePreloader]);

  const showTimeFormate = (time) => {
    let formattedTime,
      timeData = [];
    if (time?.toString()?.includes(":")) {
      timeData = time ? time?.split(":") : [];
    } else {
      formattedTime = secondsToHms(time);
      timeData = formattedTime;
    }
    let timetext = "";
    if (timeData[0] && timeData[0] > 0) {
      timetext +=
        `${Number(timeData[0])} ${CheckValueLocale("hrs", "", {}, intl)}` + " ";
    }
    if (timeData[1] && timeData[1] > 0) {
      timetext +=
        `${Number(timeData[1])} ${CheckValueLocale("min", "", {}, intl)}` + " ";
    }
    if (timeData[2] && timeData[2] > 0) {
      timetext += `${Number(timeData[2])} ${CheckValueLocale(
        "sec",
        "",
        {},
        intl,
      )}`;
    }
    return timetext;
  };

  return (
    <>
      {monitorPublicType !== "Monitor_vs_Monitors" && (
        <TableRow className="body-tr-title" sx={{ height: "0px !important" }}>
          <TableCell
            sx={{
              background: "#f8fafc",
              borderBottom: "none !important",
              border: "none !important",
            }}
          ></TableCell>
        </TableRow>
      )}
      <TableRow className="body-tr-title">
        <TableCell
          className={`${
            monitorPublicType === "Monitor_vs_Monitors" && "sticky"
          } widget-title-benchmark`}
          colSpan={8}
        >
          {CheckValueLocale("customer_care_overview", "", {}, intl)}
        </TableCell>
      </TableRow>
      {_.isEqual(customerCarePreloader?.sort(), preloaderDatasource?.sort()) !==
      true ? (
        <TableRow className="body-tr-head fetching-tr">
          <TableCell className="feching-td">
            <FetchingData />
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {monitorPublicType !== "Monitor_vs_Monitors" && (
            <TableRow className="body-tr-head">
              <TableCell component="th">
                <Typography component="span">
                  {CheckValueLocale("metrics", "", {}, intl)}
                </Typography>
              </TableCell>
              {customerCareTableHeadCellData?.map((val, index) => (
                <TableCell
                  component="th"
                  key={`customer-care-metrics-th-${index}`}
                  align={"center"}
                >
                  {monitorPublicType === "Positive_vs_Negative" && (
                    <FontAwesomeIcon
                      icon={val === "positive" ? faSmile : faAngry}
                      className={`${val}_icon`}
                    />
                  )}
                  <Typography
                    component="span"
                    dir={
                      monitorPublicType === "Time_Period_vs_Time_Period" &&
                      "ltr"
                    }
                  >
                    {CheckValueLocale(val, "", {}, intl)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )}

          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={TotalAskedIcon} className="customer-care-icon" />
              <Typography component="span">
                {CheckValueLocale("total_asked_questions", "", {}, intl)}
              </Typography>
            </TableCell>
            {totalAskedQuestionsData?.map((val, index) => {
              return (
                <TableCell
                  key={`total-asked-questions-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxTotalAskedQuestionsCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null ? intl.formatNumber(val || 0) : "—"}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={ResponseRateIcon} className="customer-care-icon" />
              <Typography component="span">
                {CheckValueLocale("response_rate", "", {}, intl)}
              </Typography>
            </TableCell>
            {responseRateData?.map((val, index) => {
              return (
                <TableCell
                  key={`response-rate-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxResponseRateCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null && !isNaN(val) ? (
                    <>
                      {(val * 100) % 1 === 0
                        ? val * 100
                        : (val * 100)?.toFixed(2) || 0}
                      %
                    </>
                  ) : (
                    "—"
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            <TableCell component="th" scope="row">
              <img src={ResponseTimeIcon} className="customer-care-icon" />
              <Typography component="span">
                {CheckValueLocale("response_time_to_questions", "", {}, intl)}
              </Typography>
            </TableCell>
            {responseTimeQuestionsData?.map((val, index) => {
              return (
                <TableCell
                  key={`response-time-to-questions-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxResponseTimeQuestionsCount
                      ? "highlight-td"
                      : ""
                  }
                >
                  {val != null ? showTimeFormate(val) || 0 : "—"}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

export default CustomerCareOverview;
