import { useEffect, useState } from "react";
import StatisticsList from "shared/statistics-list/statistics.js";
import {
  faCommentAlt,
  faComments,
  faHourglassStart,
  faUsers,
  faUserClock,
  faFrown,
  faSmile,
  faMeh,
  faClock,
  faQuestion,
  faQuestionCircle,
  faHourglass,
  faReply,
  faGlobeAsia,
  faGlobe,
  faGlobeAfrica,
  faSearch,
  faStar,
  faChartLine,
  faPhone,
  faUser,
  faUserTimes,
  faAt,
} from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import {
  secondsToHms,
  HmsToSeconds,
  numberFormatter,
  secondsToDays,
} from "utils/helpers";
import { useLocation } from "react-router-dom";
import {
  faEye,
  faHandPointer,
  faInboxIn,
  faInboxOut,
  faAddressBook,
  faBoltLightning,
  faCommentsAlt,
  faCommentDots,
  faCheckDouble,
} from "@fortawesome/pro-solid-svg-icons";

import { faHandPointUp } from "@fortawesome/pro-light-svg-icons";
import { faFileCheck } from "@fortawesome/pro-regular-svg-icons";

const OverView = (props) => {
  const { data, isAggregateDashboard, isQuickExplore, isInboxAnalytics } =
    props;
  const intl = useIntl();
  const location = useLocation();
  const [statistics, setStatistics] = useState([]);
  const insightPageOrder = {
    total_interactions: 0,
    net_sentiment: 1,
    total_people: 2,
    total_requests: 3,
    response_rate: 4,
    average_waiting_time: 5,
  };

  const interactionsPageOrder = {
    total_interactions: 0,
    net_sentiment: 1,
    avg_interactions_per_day: 2,
    total_requests: 3,
    response_rate: 4,
    total_people: 5,
  };

  const socialMediaOrder = {
    total_interactions: 0,
    net_sentiment: 1,
    total_people: 2,
    response_rate: 3,
    average_waiting_time: 4,
  };
  const genesysOrder = {
    total_interactions: 0,
    net_sentiment: 1,
    total_people: 2,
    dropout_rate: 3,
    avg_handling_time: 4,
    avg_waiting_time: 5,
  };
  const socialMediaPrivateOrder = {
    total_interactions: 0,
    total_messages: 1,
    inbound_messages: 2,
    outbound_messages: 3,
    net_sentiment: 4,
    total_people: 5,
  };
  const EmailGmailPage = {
    total_interactions: 0,
    total_people: 1,
    net_sentiment: 2,
    average_interactions_per_day: 3,
    response_rate: 4,
    average_waiting_time: 5,
  };

  const ChatIntercomOrder = {
    total_interactions: 0,
    net_sentiment: 1,
    avg_conversation_per_hour: 2,
    response_rate: 3,
    average_waiting_time: 4,
  };
  const Whatsapp = {
    total_interactions: 0,
    net_sentiment: 1,
    total_people: 2,
    response_rate: 3,
    average_waiting_time: 4,
  };
  const NewsBlogsEngagement = {
    potential_reach: 3,
    total_articles: 0,
    total_engagement: 2,
    total_sites: 1,
  };
  const GoogleMyBusinessOrder = {
    total_searches: 0,
    total_reviews: 1,
    total_activity: 2,
    average_rating: 3,
    net_sentiment: 4,
    response_rate: 5,
  };
  const LinkedInOrder = {
    total_interactions: 0,
    net_sentiment: 1,
    total_people: 2,
    total_requests: 3,
    response_rate: 4,
    average_waiting_time: 5,
    total_searches: 6,
    average_rating: 7,
    total_reviews: 8,
    total_activity: 9,
  };
  const inboxAnalyticsOrder = {
    total_interactions: 0,
    total_messages: 1,
    inbound_messages: 2,
    outbound_messages: 3,
    net_sentiment: 4,
    total_people: 5,
  };

  const orderOverviewWidget = (icon_name) => {
    if (/interactions/.test(location.pathname))
      return interactionsPageOrder[icon_name];
    if (location.pathname.includes("/insights/main"))
      return insightPageOrder[icon_name];
    if (
      /insights$/.test(location.pathname) ||
      /social-media/.test(location.pathname)
    )
      return insightPageOrder[icon_name];
    //private pages
    if (
      /x-platform-private-channel/.test(location.pathname) ||
      /facebook-private-channel/.test(location.pathname) ||
      /instagram-private-channel/.test(location.pathname)
    )
      return socialMediaPrivateOrder[icon_name];
    if (
      /x-platform-channel/.test(location.pathname) ||
      /facebook-channel/.test(location.pathname) ||
      /instagram-channel/.test(location.pathname)
    )
      return socialMediaOrder[icon_name];
    if (/intercom/.test(location.pathname) || /chat\/$/.test(location.pathname))
      return ChatIntercomOrder[icon_name];
    if (/whatsapp/.test(location.pathname)) return Whatsapp[icon_name];
    if (/email\/$/.test(location.pathname) || /gmail/.test(location.pathname))
      return EmailGmailPage[icon_name];
    if (/newsblogs/.test(location.pathname))
      return NewsBlogsEngagement[icon_name];
    if (/rating/.test(location.pathname))
      return GoogleMyBusinessOrder[icon_name];
    if (/linkedin-channel/.test(location.pathname))
      return LinkedInOrder[icon_name];
    if (/genesys/.test(location.pathname)) return genesysOrder[icon_name];
    if (/inbox-analytics/.test(location.pathname))
      return inboxAnalyticsOrder[icon_name];
  };

  useEffect(() => {
    if (
      data?.average_waiting_time ||
      data?.avg_waiting_time ||
      data?.avg_handling_time
    ) {
      secondsToHms(data?.average_waiting_time);
    }
    handleStatictics(data);
  }, [data]);

  const handleAvgTime = (time) => {
    let timeInSeconds = time;
    if (typeof time === "string" && time?.includes(":")) {
      if (time?.includes("day") || time?.includes("days")) {
        const dayAndTime = time?.split(",");
        const days = dayAndTime[0]?.split(" ");
        timeInSeconds = HmsToSeconds(days[0], dayAndTime[1]);
      } else timeInSeconds = HmsToSeconds(0, time);
    }
    let convertedTime = secondsToDays(timeInSeconds);
    let avgTimeToDisplay = `0<span>H</span> 0<span>M</span> 0<span>S</span> `;
    if (
      convertedTime[0] != 0 ||
      convertedTime[1] != 0 ||
      convertedTime[2] != 0 ||
      convertedTime[3] != 0
    ) {
      if (convertedTime[0] != 0) {
        avgTimeToDisplay = `${`${convertedTime[0]}<span>D</span>`} ${`${convertedTime[1]}<span>H</span>`} ${`${convertedTime[2]}<span>M</span>`}`;
      } else {
        avgTimeToDisplay = `${`${convertedTime[1]}<span>H</span>`} ${`${convertedTime[2]}<span>M</span>`} ${`${convertedTime[3]}<span>S</span>`}`;
      }
    }
    return avgTimeToDisplay;
  };
  if (location.pathname.includes("/survey-insights")) {
    const customSort = (a, b) => {
      const order = [
        "total_response",
        "total_views",
        "net_sentiment",
        "completion_rate",
        "avg_time_to_complete",
      ];
      return order?.indexOf(a?.name) - order?.indexOf(b?.name);
    };
    data?.sort(customSort);
  }

  const handleStatictics = (data) => {
    // Temp solution for Demo
    let totalRequestsPercentageDemo = 0;
    data?.map((i) => {
      if (i.name == "total_interactions") {
        totalRequestsPercentageDemo = i.value !== undefined ? i.value : 0;
      }
    });
    let statistics = [];
    data?.map((i) => {
      let item;
      switch (i.name) {
        case "total_interactions":
          item = {
            title: isInboxAnalytics
              ? "total_inbox_engagements"
              : props?.directMessage
                ? "total_interactions_dm"
                : "total_interactions",
            value: i.value !== undefined ? i.value.toLocaleString() : 0,
            icon: isInboxAnalytics
              ? faCommentsAlt
              : /genesys/.test(location.pathname)
                ? faPhone
                : faHandPointer,
            color: "orange",
            index: orderOverviewWidget("total_interactions"),
            socialMediaTooltip: isInboxAnalytics
              ? "total_engagements_tooltip"
              : props?.isLinkedInPage
                ? "total_interactions_tooltip_linkedin"
                : props?.isGenesysPage
                  ? "total_interactions_tooltip_genesys"
                  : props?.directMessage
                    ? "total_interactions_dm_tooltip"
                    : props?.directMessage == false
                      ? "total_interactions_public_tooltip"
                      : props?.isInsightsPage || props?.isInteractionsPage
                        ? "total_interactions_tooltip"
                        : props?.isSocialMedia
                          ? "total_interactions_tooltip_sm"
                          : props?.isChat
                            ? "total_interactions_tooltip_chats"
                            : props?.isWhatsApp
                              ? "total_interactions_whatsApp_tooltip"
                              : props?.isGmail
                                ? "total_interactions_gmail_tooltip"
                                : "",
            realValue: i.value !== undefined ? i.value : 0,
          };
          break;
        case "total_requests":
          item = {
            title: "total_requests",
            value: i.value !== undefined ? i.value.toLocaleString() : 0,
            // Temporary Fix for Demo
            socialMediaTooltip: props?.isLinkedInPage
              ? "total_requests_tooltip_linkedin"
              : "total_requests_tooltip",
            // value:
            //   totalRequestsPercentageDemo > 0
            //     ? parseInt(
            //         (totalRequestsPercentageDemo * 20) / 100
            //       ).toLocaleString()
            //     : 0,
            icon: faQuestionCircle,
            color: "darkBlue",
            index: orderOverviewWidget("total_requests"),
            realValue: i.value !== undefined ? i.value : 0,
          };
          break;
        case "net_sentiment":
          if (i.value) {
            item = {
              title: "net_sentiment",
              value:
                !i?.value[0]?.name || i?.value[0]?.name === "none"
                  ? "none"
                  : i?.value[0]?.name,
              icon:
                i?.value[0]?.name === "negative"
                  ? faFrown
                  : i?.value[0]?.name === "positive"
                    ? faSmile
                    : i?.value[0]?.name === "neutral"
                      ? faMeh
                      : faQuestion,
              color:
                i?.value[0]?.name === "negative"
                  ? "red"
                  : i?.value[0]?.name === "positive"
                    ? "green"
                    : i?.value[0]?.name === "neutral"
                      ? "yellow"
                      : "grey",
              netSentiment: true,
              index: orderOverviewWidget("net_sentiment"),
              socialMediaTooltip: isInboxAnalytics
                ? "total_engagements_net_sentiment_tooltip"
                : props?.isLinkedInPage
                  ? "net_sentiment_tooltip_linkedin"
                  : props?.page === "surveyInsights"
                    ? "free_text_sentiment"
                    : props?.isGmbPage
                      ? "net_sentiment_tooltip_gmb"
                      : props?.directMessage
                        ? "net_sentiment_dm_tooltip"
                        : props?.isInsightsPage ||
                            !props?.directMessage ||
                            props?.isGenesysPage
                          ? "net_sentiment_tooltip"
                          : "",
              realValue:
                !i?.value[0]?.name || i?.value[0]?.name === "none"
                  ? "none"
                  : i?.value[0]?.name,
            };
          } else {
            item = {
              title: "net_sentiment",
              value: "none",
              icon: faQuestion,
              color: "grey",
              netSentiment: true,
              index: orderOverviewWidget("net_sentiment"),
              realValue: "none",
            };
          }
          break;
        case "avg_conversation_per_hour":
          item = {
            title: "avg_conversation_per_hour",
            value: i?.value !== undefined ? i?.value.toLocaleString() : 0,
            socialMediaTooltip: props?.isChat
              ? "avg_conversation_per_hour_tooltip"
              : "",
            icon: faHourglass,
            color: "green",
            index: orderOverviewWidget("avg_conversation_per_hour"),
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        case "average_interactions_per_day":
          item = {
            title: "avg_email_per_day",
            value: i?.value !== undefined ? i?.value.toFixed(2) : 0,
            icon: faHourglassStart,
            color: "green",
            index: orderOverviewWidget("average_interactions_per_day"),
            socialMediaTooltip: props?.isGmail
              ? "average_interactions_per_day_gmail_tooltip"
              : "",
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        case "response_rate":
          item = {
            title:
              props?.isInsightsPage || props?.isInteractionsPage
                ? "avg_response_rate"
                : "response_rate",
            // Temporary Fix for Demo
            // value:
            //   totalRequestsPercentageDemo > 0
            //     ? (Math.random() * (35 - 30 + 1) + 30).toFixed(2) + "%"
            //     : 0 + "%",
            value:
              i?.value === undefined || i?.value === 0 || i?.value === null
                ? 0
                : (
                    i?.value / props?.overViewPreLoaderDataSources?.length
                  ).toFixed(2) + "%",
            icon: faClock,
            color: "black",
            index: orderOverviewWidget("response_rate"),
            socialMediaTooltip: props?.isLinkedInPage
              ? "response_rate_tooltip_linkedin"
              : props?.directMessage == false
                ? "response_rate_public_tooltip"
                : props?.isInsightsPage ||
                    props?.isInteractionsPage ||
                    props?.isSocialMedia
                  ? "response_rate_tooltip_insights"
                  : props?.isGmbPage
                    ? "response_rate_tooltip_gmb"
                    : props?.isChat
                      ? "response_conversations_tooltip"
                      : props?.isWhatsApp
                        ? "response_rate_whatsApp_tooltip"
                        : props?.isGmail
                          ? "response_rate_gmail_tooltip"
                          : "",
            realValue: i.value !== undefined ? i.value : 0,
          };

          break;
        case "average_waiting_time":
        case "avg_waiting_time":
          item = {
            title: "average_waiting_time",
            // Temporary Fix for Demo
            // value: `${
            //   secondsToHms(
            //     totalRequestsPercentageDemo > 0
            //       ? totalRequestsPercentageDemo + 1000
            //       : 0
            //   )[1]
            // }<span>M</span> ${
            //   secondsToHms(
            //     totalRequestsPercentageDemo > 0
            //       ? totalRequestsPercentageDemo + 10000
            //       : 0
            //   )[2]
            // }<span>S</span>`,
            // Correct solution
            value: handleAvgTime(i.value),
            icon: faUserClock,
            color: "pink",
            index: orderOverviewWidget("average_waiting_time"),
            socialMediaTooltip: props?.isLinkedInPage
              ? "average_waiting_tooltip_linkedin"
              : props?.isGenesysPage
                ? "average_waiting_tooltip_genesys"
                : "average_waiting_tooltip",
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        case "avg_interactions_per_day":
          item = {
            title: "avg_interactions_per_day",
            value: i?.value ? i?.value.toLocaleString() : 0,
            icon: faComments,
            color: "black",
            index: orderOverviewWidget("avg_interactions_per_day"),
            socialMediaTooltip: "average_interactions_per_day_tooltip",
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        case "responses_number":
        case "total_response":
          item = {
            title: "responses_number",
            value: i?.value ? i?.value.toLocaleString() : 0,
            icon: faReply,
            color: "blue",
            index: orderOverviewWidget("responses_number"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip:
              props.page === "surveyInsights" && "responses_number_tooltip",
          };
          break;
        case "views_number":
          item = {
            title: "views_number",
            value: i?.value ? i?.value.toLocaleString() : 0,
            icon: faEye,
            color: "blue",
            index: orderOverviewWidget("views_number"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip: props?.page === "surveyInsights" && "views_number_tooltip",
          };
          break;
        case "total_views":
          item = {
            title: "views_number",
            value: i?.value ? i?.value?.toLocaleString() : 0,
            icon: faEye,
            color: "darkBlue",
            index: orderOverviewWidget("views_number"),
            realValue: i.value !== undefined ? i?.value : 0,
            tooltip: props?.page === "surveyInsights" && "views_number_tooltip",
          };
          break;
        case "link_clicks_number":
          item = {
            title: "link_clicks_number",
            value: i?.value ? i?.value?.toLocaleString() : 0,
            icon: faHandPointUp,
            color: "darkBlue",
            index: orderOverviewWidget("link_clicks_number"),
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        case "completion_rate": //survey insights page
          item = {
            title: "completion_rate",
            value: i?.value + "%",
            icon: faCheckDouble,
            color: "black",
            index: orderOverviewWidget("completion_rate"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip:
              props.page === "surveyInsights" && "completetion_rate_tooltip",
          };
          break;
        case "avg_time_to_complete": //survey insights page
          item = {
            title: "avg_time_to_complete",
            value: `${
              secondsToHms(i.value > 0 ? i.value : 0)[1]
            }<span>M</span> ${
              secondsToHms(i.value > 0 ? i.value : 0)[2]
            }<span>S</span>`,
            icon: faClock,
            color: "red",
            index: orderOverviewWidget("avg_time_to_complete"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip: props.page === "surveyInsights" && "avgtime_tooltip",
          };
          break;
        //used in private pages
        case "total_messages":
          item = {
            title: isInboxAnalytics
              ? "total_engagements_messages"
              : "total_messages",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: isInboxAnalytics ? faCommentDots : faCommentAlt,
            color: "black",
            index: orderOverviewWidget("total_messages"),
            socialMediaTooltip: isInboxAnalytics
              ? "total_engagements_messages_tooltip"
              : "total_messages_tooltip",
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;

        //
        case "inbound_messages":
          item = {
            title: "inbound_messages",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faInboxIn,
            color: "darkgreen",
            index: orderOverviewWidget("inbound_messages"),
            socialMediaTooltip: isInboxAnalytics
              ? "total_engagements_inbounded_tooltip"
              : "in_bound_tooltip",
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        case "outbound_messages":
          item = {
            title: "outbound_messages",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faInboxOut,
            color: "darkblue",
            index: orderOverviewWidget("outbound_messages"),
            socialMediaTooltip: isInboxAnalytics
              ? "total_engagements_outbounded_tooltip"
              : "out_bound_tooltip",
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        case "total_people":
        case "total_contacts":
          item = {
            title: isQuickExplore
              ? "total_people_quick_explore"
              : "total_contacts",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: isQuickExplore ? faUsers : faAddressBook,
            color: isQuickExplore ? "blackQuick" : "blue",
            index: orderOverviewWidget("total_people"),
            socialMediaTooltip: props?.isLinkedInPage
              ? "total_contacts_tooltip_linkedin"
              : props?.directMessage
                ? "total_contacts_dm_tooltip"
                : props?.directMessage == false
                  ? "total_contacts_public_tooltip"
                  : props?.isInsightsPage || props?.isInteractionsPage
                    ? "total_contacts_tooltip"
                    : props?.isWhatsApp
                      ? "total_contacts_whatsapp_tooltip"
                      : props?.isGmail
                        ? "total_contacts_gmail_tooltip"
                        : props?.isGenesysPage
                          ? "total_contacts_tooltip_genesys"
                          : isInboxAnalytics
                            ? "total_engagements_contacts_tooltip"
                            : "",
            realValue: i.value !== undefined ? i.value : 0,
            directMessagesTooltip: "total_people_dm_tooltip",
          };
          break;
        case "total_mentions":
          item = {
            title: "total_mentions",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faAt,
            color: isQuickExplore ? "blueQuick" : "blue",
            index: orderOverviewWidget("total_mentions"),
            socialMediaTooltip: props?.isLinkedInPage
              ? "total_contacts_tooltip_linkedin"
              : props?.directMessage
                ? "total_contacts_dm_tooltip"
                : props?.directMessage == false
                  ? "total_contacts_public_tooltip"
                  : props?.isInsightsPage || props?.isInteractionsPage
                    ? "total_contacts_tooltip"
                    : props?.isWhatsApp
                      ? "total_contacts_whatsapp_tooltip"
                      : props?.isGmail
                        ? "total_contacts_gmail_tooltip"
                        : props?.isGenesysPage
                          ? "total_contacts_tooltip_genesys"
                          : "",
            realValue: i.value !== undefined ? i.value : 0,
            directMessagesTooltip: "total_people_dm_tooltip",
          };
          break;
        case "total_articles":
          item = {
            title: "total_articles",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faNewspaper,
            color: "yellow",
            index: orderOverviewWidget("total_articles"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip: isAggregateDashboard && "total_articles_tooltip_dash",
          };
          break;
        case "total_sites":
          item = {
            title: "total_sites",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faGlobe,
            color: "darkblue",
            index: orderOverviewWidget("total_sites"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip: isAggregateDashboard && "total_sites_tooltip_dash",
          };
          break;
        case "total_engagement":
          item = {
            title: "total_engagement",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faBoltLightning,
            color: "blue",
            index: orderOverviewWidget("total_engagement"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip: isAggregateDashboard && "total_engagement_tooltip_dash",
          };
          break;
        case "potential_reach":
          item = {
            title: "potential_reach",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faGlobeAfrica,
            color: "green",
            index: orderOverviewWidget("potential_reach"),
            realValue: i?.value !== undefined ? i?.value : 0,
            tooltip: isAggregateDashboard && "potential_reach_tooltip_dash",
          };
          break;
        case "total_reviews":
          item = {
            title: "total_reviews",
            value: i?.value !== undefined ? numberFormatter(i?.value) : 0,
            icon: faCommentAlt,
            color: "blue",
            index: orderOverviewWidget("total_reviews"),
            realValue: i?.value !== undefined ? i?.value : 0,
            socialMediaTooltip: "total_reviews_tooltip",
          };
          break;
        case "total_activity":
          item = {
            title: "total_activity",
            value: i?.value !== undefined ? numberFormatter(i?.value) : 0,
            icon: faChartLine,
            color: "darkgreen",
            index: orderOverviewWidget("total_activity"),
            realValue: i?.value !== undefined ? i?.value : 0,
            socialMediaTooltip: "total_activity_tooltip",
          };
          break;
        case "average_rating":
          item = {
            title: "average_rating",
            value: i?.value !== undefined ? i?.value?.toLocaleString() : 0,
            icon: faStar,
            color: "darkBlue",
            index: orderOverviewWidget("average_rating"),
            realValue: i?.value !== undefined ? i?.value : 0,
            socialMediaTooltip: "avg_rating_tooltip",
          };
          break;
        case "total_searches":
          item = {
            title: "total_searches",
            value: i?.value !== undefined ? numberFormatter(i?.value) : 0,
            icon: faSearch,
            color: "orange",
            index: orderOverviewWidget("total_searches"),
            realValue: i?.value !== undefined ? i?.value : 0,
            socialMediaTooltip: "total_searches_tooltip",
          };
          break;
        case "dropout_rate":
          item = {
            title: "dropout_rate",
            value: i?.value !== undefined ? i?.value : 0,
            icon: faUserTimes,
            color: "darkBlue",
            index: orderOverviewWidget("dropout_rate"),
            realValue: i?.value !== undefined ? i?.value : 0,
            socialMediaTooltip: props?.isGenesysPage
              ? "dropout_rate_tooltip"
              : "",
          };
          break;
        case "avg_handling_time":
          item = {
            title: "average_handle_time",
            value: handleAvgTime(i.value),
            icon: faClock,
            color: "black",
            index: orderOverviewWidget("average_handle_time"),
            socialMediaTooltip: props?.isGenesysPage
              ? "average_handle_tooltip"
              : "",
            realValue: i?.value !== undefined ? i?.value : 0,
          };
          break;
        default:
          break;
      }
      statistics.push(item);
      setStatistics(statistics);
    });
  };
  return (
    <StatisticsList
      title={props?.title}
      data={statistics}
      overViewPreLoaderDataSources={props?.overViewPreLoaderDataSources}
      preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
      directMessage={props?.directMessage}
      isGenesysPage={props?.isGenesysPage}
      isInboxAnalytics={isInboxAnalytics}
    />
  );
};

export default OverView;
