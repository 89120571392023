import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { handelViewLink } from "../sharedFunctions/viewDownloadFunctions";

const ViewDownloadComponent = (item, history, intl) => {
  return (
    <Box className="action-td" onClick={() => handelViewLink(item, history)}>
      {item?.notification_type === "excel_export" ? (
        <Typography className="btn-action btn-action-download">
          {CheckValueLocale("download", "", {}, intl)}
        </Typography>
      ) : (
        <>
          <Typography className="btn-action btn-action-view">
            {CheckValueLocale("view_notification", "", {}, intl)}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ViewDownloadComponent;
