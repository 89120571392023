import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const SummaryNotFound = ({ noFoundType }) => {
  const intl = useIntl();
  let textSummaryNotFound = null;

  switch (noFoundType) {
    case "notFound":
      textSummaryNotFound = {
        title: "summary_error_title",
        hint: "summary_error_text",
      };
      break;
    case "noLuciSummaryTokens":
      textSummaryNotFound = {
        title: "summary_no_luci_summary_tokens_title",
        hint: "summary_no_luci_summary_tokens_text",
      };
      break;
    case "notReturned":
      textSummaryNotFound = {
        title: "summary_no_question_text",
        hint: "summary_no_question_hint",
      };
      break;
    default:
      break;
  }
  const { title, hint } = textSummaryNotFound;

  return (
    <Box className="summary-Loading-wrapper">
      <Box component="p" className="summary-empty-title">
        {CheckValueLocale(title, "", {}, intl)}
      </Box>
      <Box component="p" className="summary-empty-hint">
        {CheckValueLocale(hint, "", {}, intl)}
      </Box>
    </Box>
  );
};

export default SummaryNotFound;
