import { Grid, IconButton, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useIntl } from "react-intl";
import "./keywordHeader.scss";
import { CheckValueLocale } from "utils/helpers";

const NeedHelpChooseSourceHeader = () => {
  const intl = useIntl();

  return (
    <>
      <Grid item xs={6} mb={1.5}>
        <Typography variant="caption" className="select-monitor-style" mb={0}>
          {CheckValueLocale(
            "choose_social_media_source_add_keyword",
            "",
            {},
            intl,
          )}
          :
        </Typography>
      </Grid>

      <Grid item xs={6} className="need-help-faq">
        <Typography variant="caption" className="monitor-card-help">
          {CheckValueLocale("need_help", "", {}, intl)}
          <a href="/faqs" target="_blank" className="faq-link">
            {CheckValueLocale("keywords_guide", "", {}, intl)}

            <IconButton>
              <LaunchIcon sx={{ fontSize: "medium" }} className="icon-link" />
            </IconButton>
          </a>
        </Typography>
      </Grid>
    </>
  );
};

export default NeedHelpChooseSourceHeader;
