import { useEffect } from "react";
import CircularLoading from "components/circularProgress";
//pages
import AccountPage from "./account/accountPage";
import EngagementsPage from "./engagements/engagementsPage";
import CustomerCarePage from "./customerCare/customerCarePage";
import PostsPage from "./posts/postsPage";
import CommentsMentionsPage from "./commentsMentions/commentsMentionsPage";
import QuestionPage from "./questions/questionsPage";
import AuthorsPage from "./authors/authorsPage";
import "./style.scss";

const DashboardPages = (props) => {
  const {
    dashboardDynamicData,
    dynamicDataLoader,
    getDashboardWidgetsName,
    dashboardActiveTab,
  } = props;

  useEffect(() => {
    getDashboardWidgetsName(dashboardDynamicData);
  }, [dashboardDynamicData]);

  return (
    <>
      {dynamicDataLoader ? (
        <CircularLoading />
      ) : dashboardActiveTab === "account_page" ? (
        <AccountPage {...props} />
      ) : dashboardActiveTab === "engagements_page" ? (
        <EngagementsPage {...props} />
      ) : dashboardActiveTab === "customer_care_page" ? (
        <CustomerCarePage {...props} />
      ) : dashboardActiveTab === "posts_page" ? (
        <PostsPage {...props} />
      ) : dashboardActiveTab === "comments_mentions_page" ? (
        <CommentsMentionsPage {...props} />
      ) : dashboardActiveTab === "questions_page" ? (
        <QuestionPage {...props} />
      ) : dashboardActiveTab === "authors_page" ? (
        <AuthorsPage {...props} />
      ) : null}
    </>
  );
};

export default DashboardPages;
