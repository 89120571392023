import React, { useEffect, useState } from "react";
import { Box, Switch } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import ReactFlagsSelect from "react-flags-select";
import "../../surveyBuilder.scss";

const PhoneNo = (props) => {
  const {
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
    selectedCountryCode,
    setSelectedCountryCode,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);
  const [switchReq, setSwitchReq] = useState(false);

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setSwitchReq(question?.constraints?.required);
    }
    if (
      selectedCountryCode !==
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex]?.question?.constraints?.country_code
    )
      setSelectedCountryCode(
        filteredSections[sectionIndex].questions.filter(
          (question) => question?.is_deleted !== true,
        )[questionIndex]?.question?.constraints?.country_code,
      );
  }, [question, surveySections]);

  const handleSwitch = (e, constraint) => {
    if (constraint === "required") setSwitchReq(e.target.checked);
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints[constraint] = e.target.checked;
    setSurveySections([...surveySections]);
  };

  const handleCountryCode = (code) => {
    setSelectedCountryCode(code);
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints.country_code = code.toLowerCase();
    setSurveySections([...surveySections]);
  };
  return (
    <>
      <Box
        className={`constraints-text ${editable === false && `disabled-color`}`}
      >
        <Box>{CheckValueLocale("required", "", {}, intl)}</Box>
        <Switch
          data-testid="required_switch"
          onChange={(e) => handleSwitch(e, "required")}
          checked={switchReq}
        />
      </Box>
      <Box className="country-dropdown">
        <ReactFlagsSelect
          className="menu-flags"
          selected={selectedCountryCode?.toUpperCase()}
          disabled={editable === false ? true : false}
          onSelect={(code) => handleCountryCode(code)}
          searchable
          countries={["IL"]}
          blacklistCountries
        />
      </Box>
    </>
  );
};

export default PhoneNo;
