import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Paper, Typography, Select, MenuItem, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularLoading from "components/circularProgress";
import "./addChannel.scss";
import { CheckValueLocale } from "utils/helpers/index";

export default function SessionConfiguration(props) {
  const formik = props?.formik;
  const [customerReply, setCustomerReply] = useState(1);
  const [agentRespond, setAgentRespond] = useState(1);
  const intl = useIntl();

  const [newCustomerReplyTime, setNewCustomerReplyTime] = useState(
    formik.values?.clientConversationEnd,
  );
  const [newAgentRespondTime, setNewAgentRespondTime] = useState(
    formik.values?.agentConversationEnd,
  );

  useEffect(() => {
    if (newCustomerReplyTime >= 60) {
      setCustomerReply(2);
      setNewCustomerReplyTime(newCustomerReplyTime / 60);
    } else {
      setCustomerReply(1);
      setNewCustomerReplyTime(newCustomerReplyTime);
    }
    if (newAgentRespondTime >= 60) {
      setAgentRespond(2);
      setNewAgentRespondTime(newAgentRespondTime / 60);
    } else {
      setAgentRespond(1);
      setNewAgentRespondTime(newAgentRespondTime);
    }
  }, []);

  const handleAgentSelectChange = (e) => {
    if (e.target.value === 2) {
      setAgentRespond(2);
      formik.setFieldValue(
        "agentConversationEnd",
        formik.values.agentConversationEnd * 60,
      );
    } else if (e.target.value === 1) {
      setAgentRespond(1);
      if (newAgentRespondTime >= 60) {
        formik.setFieldValue(
          "agentConversationEnd",
          formik.values.agentConversationEnd / 60,
        );
      } else {
        formik.setFieldValue(
          "agentConversationEnd",
          Number(newAgentRespondTime),
        );
      }
    }
  };

  const handleCustomerSelectChange = (e) => {
    if (e.target.value === 2) {
      setCustomerReply(2);
      formik.setFieldValue(
        "clientConversationEnd",
        formik.values.clientConversationEnd * 60,
      );
    } else if (e.target.value === 1) {
      setCustomerReply(1);
      if (newCustomerReplyTime >= 60) {
        formik.setFieldValue(
          "clientConversationEnd",
          formik.values.clientConversationEnd / 60,
        );
      } else {
        formik.setFieldValue(
          "clientConversationEnd",
          Number(newCustomerReplyTime),
        );
      }
    }
  };

  return (
    <>
      <Paper
        className={
          props.editSession
            ? "edit-session-configuration session-body"
            : "session-body"
        }
      >
        {!props?.loading ? (
          <>
            <Typography
              className={
                props.editSession
                  ? "bot-heading-edit session-heading"
                  : "session-heading"
              }
            >
              {props.editSession ? "2. " : "4. "}
              {CheckValueLocale("session_configuration", "", {}, intl)}

              {props.editSession ? (
                ""
              ) : (
                <Tooltip
                  className="tool-tip-icon"
                  title={
                    <div className="tooltip-custom">
                      {CheckValueLocale(
                        "session_configuration_tooltip",
                        "",
                        {},
                        intl,
                      )}
                    </div>
                  }
                  arrow={true}
                  sx={{ cursor: "default" }}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            {props?.editTitle === true ? (
              <div className="edit-title">
                {CheckValueLocale("edit_heading", "", {}, intl)}
              </div>
            ) : null}

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className={
                props.editSession
                  ? "edit-main-box main-select-box"
                  : "main-select-box"
              }
            >
              <Grid item xs={12} md={6} className="col-6-full">
                <Grid item xs={12}>
                  <Typography className="select-textfield-heading">
                    {CheckValueLocale(
                      "customer_does_not_reply_to_the_agent_in",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={
                    localStorage.lang === "ar"
                      ? "ar-select-box select-box"
                      : "select-box"
                  }
                >
                  <Grid item xs={8} className="outlinedbox-select">
                    <TextField
                      className="remove-arrow"
                      type="number"
                      id="outlined-basic"
                      placeholder="0"
                      name="clientConversationEnd"
                      value={newCustomerReplyTime}
                      error={
                        !!formik.errors?.clientConversationEnd &&
                        formik.touched.clientConversationEnd
                      }
                      variant="outlined"
                      onKeyDown={(evt) =>
                        ["e", "E", ".", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        if (customerReply === 2) {
                          formik.setFieldValue(
                            "clientConversationEnd",
                            Number(e.target.value) * 60,
                          );
                          setNewCustomerReplyTime(e.target.value);
                          return;
                        }
                        setNewCustomerReplyTime(e.target.value);
                        formik.setFieldValue(
                          "clientConversationEnd",
                          Number(e.target.value),
                        );
                      }}
                      FormHelperTextProps={{
                        className:
                          localStorage.lang === "ar"
                            ? "session-ar-err-msg err-msg"
                            : "err-msg",
                      }}
                      helperText={
                        formik.touched.clientConversationEnd &&
                        formik.errors.clientConversationEnd
                      }
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customerReply}
                        onChange={handleCustomerSelectChange}
                      >
                        <MenuItem
                          value={1}
                          id={`channel-analytics-session-minute`}
                        >
                          {CheckValueLocale("minute", "", {}, intl)}
                        </MenuItem>
                        <MenuItem
                          value={2}
                          id={`channel-analytics-session-hours`}
                        >
                          {CheckValueLocale("hours", "", {}, intl)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className="col-6-full">
                <Grid item xs={12}>
                  <Typography className="select-textfield-heading">
                    {CheckValueLocale(
                      "agent_does_not_respond_to_the_customer_in",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={
                    localStorage.lang === "ar"
                      ? "ar-select-box select-box"
                      : "select-box"
                  }
                >
                  <Grid item md={8} className="outlinedbox-select">
                    <TextField
                      className="remove-arrow"
                      type="number"
                      id="outlined-basic"
                      placeholder="0"
                      variant="outlined"
                      name="agentConversationEnd"
                      value={newAgentRespondTime}
                      error={
                        !!formik.errors?.agentConversationEnd &&
                        formik.touched.agentConversationEnd
                      }
                      onKeyDown={(evt) =>
                        ["e", "E", ".", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => {
                        if (agentRespond === 2) {
                          formik.setFieldValue(
                            "agentConversationEnd",
                            Number(e.target.value) * 60,
                          );
                          setNewAgentRespondTime(e.target.value);
                          return;
                        }
                        setNewAgentRespondTime(e.target.value);
                        formik.setFieldValue(
                          "agentConversationEnd",
                          Number(e.target.value),
                        );
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.agentConversationEnd &&
                        formik.errors.agentConversationEnd
                      }
                      FormHelperTextProps={{
                        className:
                          localStorage.lang === "ar"
                            ? "session-ar-err-msg err-msg"
                            : "err-msg",
                      }}
                    />
                  </Grid>
                  <Grid item xs md={4}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={agentRespond}
                        onChange={handleAgentSelectChange}
                      >
                        <MenuItem
                          value={1}
                          id={`channel-analytics-session-minute`}
                        >
                          {CheckValueLocale("minute", "", {}, intl)}
                        </MenuItem>
                        <MenuItem
                          value={2}
                          id={`channel-analytics-session-hours`}
                        >
                          {CheckValueLocale("hours", "", {}, intl)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <div className="loader-box">
            <CircularLoading />
          </div>
        )}
      </Paper>
    </>
  );
}
