import moment from "moment";
import { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, Pagination } from "@mui/material";
import CircularLoading from "components/circularProgress";
import EmptySegments from "images/audience-images/empty-segments.svg";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import OptionsDropDown from "./componentes/optionsDropDown";
import "./manageAudience.scss";
import { useHistory } from "react-router-dom";
import UserContext from "context/audienceContext";
import SharedTable from "../../../shared/table/sharedTable";
import { sortedColumnTypes } from "hooks/useSortableTable";

export const getSegmentsTableHeader = (intl) => {
  return [
    {
      body: CheckValueLocale("segments_name", "", {}, intl),
      name: "name",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("segements_create_date", "", {}, intl),
      name: "created_at",
      type: sortedColumnTypes.date,
    },
    {
      body: CheckValueLocale("segments_num_of_profiles", "", {}, intl),
      name: "profiles_count",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("segements_uploaded_by", "", {}, intl),
      type: sortedColumnTypes.string,
    },
    {
      body: null,
      width: "10%",
    },
  ];
};

const MainSegmentsTable = (props) => {
  const intl = useIntl();
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const {
    getListOfSegments,
    segmentsAudience,
    handleChangePage,
    pagination,
    page,
    loading,
    setSegment,
    tableHead,
    isTableLoading,
  } = props;
  const ctx = useContext(UserContext);
  const history = useHistory();
  const redirectToAudienceList = (name) => {
    const queryData = {
      segment: [`${name}`],
    };
    ctx.audienceFilters = queryData;
    history.push(`/audience/list`);
  };

  const segmentNameCell = (segment) => {
    return (
      <Box
        className="audience-name-text"
        onClick={() => redirectToAudienceList(segment?.name)}
      >
        {segment?.name ? segment?.name : "—"}
      </Box>
    );
  };
  const profilesCountCell = (segment) => {
    return (
      <Box>
        {segment?.profiles_count}
        {CheckValueLocale("segments_of_profiles", "", {}, intl)}
      </Box>
    );
  };
  const optionsCell = (segment) => {
    return (
      <OptionsDropDown
        getListOfSegments={getListOfSegments}
        segmentId={segment?.id}
        segment={segment}
        segmentNumOfProfiles={segment?.profiles_count}
        setSegment={setSegment}
      />
    );
  };

  const emptyBody = (segmentsAudience) => {
    return (
      <>
        {!segmentsAudience?.length && (
          <Box className="segment-empty-item">
            <img src={EmptySegments} />
            <Typography variant={"h7"}>
              {CheckValueLocale("no_data_found", "", {}, intl)}
            </Typography>
            <Box component="p">
              {CheckValueLocale("there_are_no_segments_yet", "", {}, intl)}
            </Box>
          </Box>
        )}
      </>
    );
  };
  useEffect(() => {
    const tabBody =
      segmentsAudience?.length == 0
        ? []
        : segmentsAudience?.map((item) => {
            return {
              id: item?.id,
              data: [
                {
                  body: item?.name ? segmentNameCell(item) : "—",
                  className: "audience-main-name audience-name-btn",
                },
                {
                  body: item?.created_at
                    ? moment.unix(item?.created_at).format("DD/MM/YYYY")
                    : "—",
                },
                {
                  body: profilesCountCell(item),
                  className: "audience-main-name",
                },
                {
                  body: item?.creator_user ? item?.creator_user : "—",
                  isCreatedBy: true,
                },
                {
                  body: optionsCell(item),
                  className: "audience-actions-menu",
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(segmentsAudience));
  }, [segmentsAudience, intl.locale]);

  return (
    <Box>
      {loading ? (
        <Box sx={{ p: 1 }}>
          <CircularLoading />
        </Box>
      ) : (
        <>
          <SharedTable
            className="monitor-table"
            tableClassName="aduience-table"
            headerRowClassName={"audience-table-head"}
            isSharedStyle
            tableHeader={tableHead}
            tableBody={tableBody}
            emptyBody={tableEmptyBody}
            loading={isTableLoading}
          />

          {pagination > 1 && segmentsAudience.length > 0 && !isTableLoading ? (
            <Pagination
              onChange={handleChangePage}
              className="pagination-monitor-list"
              count={pagination > 1 ? pagination : 1}
              page={page}
              variant="outlined"
            />
          ) : null}
        </>
      )}
    </Box>
  );
};

export default MainSegmentsTable;
