import { useState } from "react";
import { useIntl } from "react-intl";

import { Button, TextField, Box, InputAdornment, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { handleInputMouseDown } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import { checkValue } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import { handleDeleteTitle } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/newBlogsSharedFunctions";
import TrendingRecentContent from "./trendingRecentContent";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import { checkDirectionLang } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
const KeywordsInput = (props) => {
  const intl = useIntl();
  const [dataExist, setDataExist] = useState(true);

  const {
    setFocused,
    focused,
    keywordsErrorMsg,
    setkeywordsErrorMsg,
    keywordsTitle,
    recentTitle,
    isKeywordsFields,
    trendingData,
    recentData,
    formFieldErrors,
    setFormFieldErrors,
    inputName,
    keywords,
    spamKeywords,
    setSelectedKeyword,
    setSelectedSpamKeyword,
    setKeywords,
    setSpamKeywords,
    showContentRecentTrending,
    isLoading,
    setIsClear,
    isClear,
    setDisableCreateExplore,
    selectedKeyword,
    selectedSpamKeyword,
  } = props;
  let keywordsLimit = 100;
  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  const handleDelete = (i) => {
    setDisableCreateExplore(false);
    handleDeleteTitle(i, keywordsContent, setkeywordsErrorMsg, setKeywordValue);
    setFormFieldErrors({
      keywords: "",
      exclude_keywords: "",
    });
  };
  const [showTendingRecent, setShowTendingRecent] = useState(false);
  const [keywordsCount, setKeywordsCount] = useState(0);
  const [spamKeywordsCount, setSpamKeywordsCount] = useState(0);

  let setKeywordValue =
    inputName === "keywords" ? setSelectedKeyword : setSelectedSpamKeyword;
  let keywordValue =
    inputName === "keywords" ? selectedKeyword : selectedSpamKeyword;

  let KeywordValueCountSetter =
    inputName === "keywords" ? setKeywordsCount : setSpamKeywordsCount;
  let keywordValueCount =
    inputName === "keywords" ? keywordsCount : spamKeywordsCount;
  let keywordsContent = inputName === "keywords" ? keywords : spamKeywords;
  const handleKeywords = (event, clickType) => {
    setDisableCreateExplore(false);
    const { value } = event.target;
    if (
      (event.keyCode === 13 || (clickType === "btn" && value)) &&
      value?.trim() !== "" &&
      dataExist
    ) {
      let objData = handlekeywordsShared(
        value,
        inputName === "keywords" ? "keywords" : "spam_keywords",
        keywords?.length ? keywords : [],
        spamKeywords?.length ? spamKeywords : [],
        "twitter",
        [
          {
            keywords: keywords?.length ? keywords : [],
          },
          {
            spam_keywords: spamKeywords?.length ? spamKeywords : [],
          },
        ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
        keywordsLimit,
        undefined, //in case newsblogs,
        undefined,
      );

      if (!objData?.errorMsg) {
        setKeywords([...objData?.keywords]);
        setSpamKeywords([...objData?.spamKeywords]);
        setFormFieldErrors({
          ...formFieldErrors,
          [inputName]: "",
        });
        if (!objData.exccedKeywordsLimit) {
          setKeywordValue({ name: inputName, value: "" });
        }
        KeywordValueCountSetter(objData?.count);
      } else {
        setFormFieldErrors({
          ...formFieldErrors,
          [inputName]: objData?.errorMsg,
        });
        setKeywordValue({ name: inputName, value: value });
      }
    }
  };
  const handleChange = (event) => {
    const { value } = event?.target;
    setKeywordValue({ name: inputName, value: value });
    //using shared
    let errorMessage = handleValidationKeywords(
      value,
      keywords?.length ? keywords : [],
      spamKeywords?.length ? spamKeywords : [],
      inputName === "keywords" ? "keywords" : "spam_keywords",
      setDataExist,
      "twitter",
    );
    setFormFieldErrors({
      ...formFieldErrors,
      [inputName]: errorMessage,
    });
  };
  return (
    <>
      <div className="expore-keywords-content">
        <TextField
          id="outlined-email-input"
          label={CheckValueLocale(keywordsTitle, "", {}, intl)}
          variant="outlined"
          className={`topics-keywords-field social-media-field explore-keywords-field  ${checkDirectionLang(
            keywordValue?.value,
          )}`}
          onFocus={() => {
            setFocused(true);
            setShowTendingRecent(true);
            setIsClear(true);
            setFormFieldErrors({
              [inputName]: "",
            });
          }}
          onBlur={() => {
            setFocused(false);
            setFormFieldErrors({
              [inputName]: "",
            });
            setKeywordValue({ name: inputName, value: "" });
          }}
          InputProps={{
            endAdornment: focused && (
              <InputAdornment onMouseDown={handleInputMouseDown}>
                {keywordValueCount >= keywordsLimit ? (
                  <Tooltip
                    title={
                      <Box sx={{ width: 140 }}>
                        {CheckValueLocale(
                          "explore_keywords_limit_title",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    }
                    arrow
                  >
                    <Box className="tooltip-explore-limit">
                      <Button
                        className="add-btn-with-plus add-btn-edit-topic add-explore-plus-btn"
                        onClick={(event) => {
                          handleKeywords(event, "btn");
                        }}
                        value={keywordValue?.value}
                        id="explore-keywords-field-keyword-btn"
                        disabled={keywordValueCount >= keywordsLimit}
                      >
                        {CheckValueLocale("add_plus", "", {}, intl)}
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Button
                    className="add-btn-with-plus add-btn-edit-topic add-explore-plus-btn"
                    onClick={(event) => {
                      handleKeywords(event, "btn");
                    }}
                    value={keywordValue?.value}
                    id="explore-keywords-field-keyword-btn"
                  >
                    {CheckValueLocale("add_plus", "", {}, intl)}
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
          onKeyDown={(event) => handleKeywords(event)}
          onChange={handleChange}
          value={keywordValue?.value}
          error={formFieldErrors[inputName]?.length}
        />
        {showTendingRecent &&
        !formFieldErrors[inputName]?.length &&
        showContentRecentTrending &&
        isClear ? (
          <TrendingRecentContent
            trendingData={trendingData}
            {...props}
            setShowTendingRecent={setShowTendingRecent}
            focused={focused}
            recentTitle={recentTitle}
            isKeywordsFields={isKeywordsFields}
            recentData={recentData}
            inputName={inputName}
            isLoading={isLoading}
            setIsClear={setIsClear}
            setKeywords={setKeywords}
            spamKeywords={spamKeywords}
            keywords={keywords}
            setSpamKeywords={setSpamKeywords}
            formFieldErrors={formFieldErrors}
            setFormFieldErrors={setFormFieldErrors}
            setDisableCreateExplore={setDisableCreateExplore}
          />
        ) : null}
        {keywordsErrorMsg ? (
          <Box className="topics-err-msg explore-err-msg">
            {CheckValueLocale(
              keywordsErrorMsg,
              "",
              { limit: keywordsLimit },
              intl,
            )}
          </Box>
        ) : null}
        <Box className="keyword-spam-wrapper">
          {checkValue(keywordsContent)?.map((keyword, i) => {
            return (
              <KeywordTooltipChip
                onDelete={(e) => handleDelete(i)}
                dataSource={"twitter"}
                index={i}
                labels={keyword}
              />
            );
          })}
          <KeywordPopupModal
            keywords={keywordsContent}
            handleDelete={handleDelete}
            dataSourceType={"twitter"}
            keywordType={"keywords"}
            isTitleSearch
            dataSourceIndex={0}
          />
        </Box>
      </div>
    </>
  );
};

export default KeywordsInput;
