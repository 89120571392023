import Audience from "../api/audience.js";
import { isEmptyObj } from "utils/helpers";

let AudienceController = {};

AudienceController.getAudience = (
  identifier,
  start_date,
  end_date,
  page,
  filters,
) => {
  let queryData = {
    identifier,
    start_date,
    end_date,
    filters: JSON.stringify(filters),
    page,
  };

  return new Promise(function (resolve) {
    Audience.getAudience(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.getUserProfile = (id) => {
  return new Promise(function (resolve) {
    Audience.getUserProfile(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.deleteProfile = (profileID) => {
  return new Promise(function (resolve) {
    Audience.deleteProfile(profileID)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.editProfileData = (profileID, updatedSavedValues) => {
  return new Promise(function (resolve) {
    Audience.editProfileData(profileID, updatedSavedValues)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.getAudienceSurvey = (profileID, start_date, end_date) => {
  let queryData = {
    start_date,
    end_date,
  };

  return new Promise(function (resolve) {
    Audience.getAudienceSurvey(profileID, queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.getAudienceInteractions = (
  profileID,
  start_date,
  end_date,
  page_number,
) => {
  let queryData = {
    start_date,
    end_date,
    page_number,
  };
  return new Promise(function (resolve) {
    Audience.getAudienceInteractions(profileID, queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AudienceController.getSegmentList = () => {
  return new Promise(function (resolve) {
    Audience.getSegmentList()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.createSegment = (
  name,
  profiles_ids,
  select_all,
  start_date,
  end_date,
  identifier,
  filters,
) => {
  let queryData;
  if (select_all === true) {
    queryData = {
      name,
      select_all,
      start_date,
      end_date,
      identifier,
      filters: filters ? JSON.stringify(filters) : {},
    };
  } else {
    queryData = {
      name,
      profiles_ids,
      select_all: false,
    };
  }
  return new Promise(function (resolve) {
    Audience.createSegment(queryData)
      .then((response) => {
        resolve({
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        resolve({
          status: error.response.status,
        });
      });
  });
};

AudienceController.getListOfSegments = (page, sort_by, order_by) => {
  let queryData = {
    page,
    sort_by,
    order_by,
  };

  return new Promise(function (resolve) {
    Audience.getListOfSegments(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.deleteSegment = (segmentId) => {
  return new Promise(function (resolve) {
    Audience.deleteSegment(segmentId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AudienceController.exportCDPExcelFile = (queryData) => {
  return new Promise(function (resolve) {
    Audience.exportCDPExcelFile({
      ...queryData,
      filters: JSON.stringify(queryData?.filters),
    })
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AudienceController.updateSegmentName = (segmentId, segment_name) => {
  let queryData = {
    segmentId,
    segment_name,
  };
  return new Promise(function (resolve) {
    Audience.updateSegmentName(segmentId, queryData)
      .then((response) => {
        resolve({
          data: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        resolve({
          status: error.response.status,
        });
      });
  });
};
AudienceController.addSegmantProfile = (
  segment_ids,
  profiles_ids,
  select_all,
  start_date,
  end_date,
  identifier,
  filters,
) => {
  let queryData = {
    segment_ids,
    profiles_ids,
    select_all,
    start_date,
    end_date,
    identifier,
    filters: filters ? JSON.stringify(filters) : {},
  };
  return new Promise(function (resolve) {
    Audience.addSegmantProfile(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AudienceController.excludeSegmantProfile = (
  segment_ids,
  profiles_ids,
  select_all,
  start_date,
  end_date,
  identifier,
  filters,
) => {
  let queryData = {
    segment_ids,
    profiles_ids,
    select_all,
    start_date,
    end_date,
    identifier,
    filters: filters ? JSON.stringify(queryData?.filters) : {},
  };
  return new Promise(function (resolve) {
    Audience.excludeSegmantProfile(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.importCDPImports = (page, sort_by, order_by) => {
  let queryData = {
    page,
    sort_by,
    order_by,
  };
  return new Promise(function (resolve) {
    Audience.importCDPImports(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.importCDPDwonload = (imports_id) => {
  return new Promise(function (resolve) {
    Audience.importCDPDwonload(imports_id)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AudienceController.importCDPDelete = (imports_id) => {
  return new Promise(function (resolve) {
    Audience.importCDPDelete(imports_id)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.importCDPExcelFile = (queryData) => {
  return new Promise(function (resolve) {
    Audience.importCDPExcelFile(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AudienceController.getCDPXlsxGuide = () => {
  return new Promise(function (resolve) {
    Audience.getCDPXlsxGuide()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AudienceController.importCDpViewNum = (imports_id) => {
  return new Promise(function (resolve) {
    Audience.importCDpViewNum(imports_id)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default AudienceController;
