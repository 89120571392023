import { Container } from "@mui/material";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";
import SurveyDetails from "./surveyDetails";
import SurveyHeader from "./surveyHeader";
import SurveyQuestion from "./surveyQuestion";

const SurveyBody = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const id = urlParams?.id;
  const location = useLocation();

  return (
    <Container maxWidth="xl">
      <SurveyHeader
        id={id}
        detail={location?.state?.detail}
        profile={location?.state?.profile}
      />
      <SurveyDetails
        profile={location?.state?.profile}
        detail={location?.state?.detail}
      />
      <SurveyQuestion
        data={location?.state?.detail}
        questions={location?.state?.detail?.survey}
        isCDP={true}
        isAlert={false}
      />
    </Container>
  );
};

export default SurveyBody;
