import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import SectionTitle from "pages/alerts/components/sectionTitle/sectionTitle";
import AlertStatistics from "pages/alerts/components/alertStatistics/alertStatistics";
import InteractionsList from "components/interactions-list/InteractionsList.js";

const GmailAlert = ({
  alertDetailMonitorId,
  response,
  dataSources,
  alertDetailType,
  resetAllFields,
}) => {
  const initialWidgetsState = {
    //header alert widget
    headerAlertWidget: {},
    headerAlertWidgetPreLoaderDataSources: [],
    //posts volume widget
    postsVolume: [],
    postsVolumePreLoader: [],
    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],
    //top posts widget
    topPosts: [],
    topPostsPreLoaderDataSources: [],
    //comments setntiment analysis widget
    commentsSentimentAnalysis: [],
    commentsSentimentAnalysisPreLoaderDataSources: [],
  };

  const [alertViewWidgets, setAlertViewWidgets] = useState(initialWidgetsState);

  useEffect(() => {
    switch (response?.eventName) {
      case "Gmail__NewPostAlert__header_alert_widget":
      case "Gmail__NegativePostsAlert__header_alert_widget":
        setAlertViewWidgets({
          ...alertViewWidgets,
          headerAlertWidget: response?.eventData,
          headerAlertWidgetPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Gmail__NewPostAlert__volume_overtime":
      case "Gmail__NegativePostsAlert__volume_overtime":
        setAlertViewWidgets({
          ...alertViewWidgets,
          postsVolume: response?.eventData?.interactions,
          postsVolumePreLoader: [response?.monitor_id],
        });
        break;
      case "Gmail__NewPostAlert__sentiment_analysis":
        setAlertViewWidgets({
          ...alertViewWidgets,
          commentsSentimentAnalysis: response?.eventData?.sentiment_piechart,
          commentsSentimentAnalysisPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Gmail__NewPostAlert__top_keywords":
      case "Gmail__NegativePostsAlert__top_keywords":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      case "Gmail__NewPostAlert__top_posts_emails":
      case "Gmail__NegativePostsAlert__top_posts_emails":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topPosts: response?.eventData?.latest_interactions,
          topPostsPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...initialWidgetsState });
    }
  }, [resetAllFields]);

  return (
    <>
      <AlertStatistics
        alertDataSource={"gmail"}
        headerAlertWidget={alertViewWidgets?.headerAlertWidget}
        headerAlertWidgetPreLoaderDataSources={
          alertViewWidgets?.headerAlertWidgetPreLoaderDataSources
        }
        preLoaderTrackerDataSources={alertDetailMonitorId}
        isCA
        alertDetailType={alertDetailType}
      />
      <Box mb={3}>
        <LineChartWidget
          title={"gmail_emails_overtime"}
          toolTipLabel={"total_interactions"}
          data={alertViewWidgets?.postsVolume}
          showDataZoom={false}
          lineChartPreLoaderDataSources={alertViewWidgets?.postsVolumePreLoader}
          preLoaderTrackerDataSources={alertDetailMonitorId}
          checkSymbolZize
        />
      </Box>
      <Box mb={5} className="new-posts-countiner">
        <SectionTitle sectionTitle="gmail_new_emails" />
        <InteractionsList
          data={alertViewWidgets?.topPosts?.slice(0, 6)}
          dataSources={dataSources}
          latestInteractionsPreLoaderDataSources={
            alertViewWidgets?.topPostsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={alertDetailMonitorId}
        />
      </Box>
      <SectionTitle sectionTitle="metrics" />
      {alertDetailType !== "NegativePosts" ? (
        <Grid container spacing={2.5} mb={4} className="gmail-alert-view">
          <Grid item md={6}>
            <PieChartWidget
              title={"alert_sentiment_analysis"}
              data={alertViewWidgets?.commentsSentimentAnalysis}
              areaStyle={true}
              showDownloadIcon
              chartType={"sentiment"}
              pieChartPreLoaderDataSources={
                alertViewWidgets?.commentsSentimentAnalysisPreLoaderDataSources
              }
              preLoaderTrackerDataSources={alertDetailMonitorId}
              showAI
              color={["#89BB2A", "#E50035", "#F9A700"]}
            />
          </Grid>
          <Grid item md={6}>
            <TableWidget
              wordsList
              title={"alert_top_keywords"}
              leftColomnTitle={"keywords_tab_title"}
              rightColomnTitle={"number_of_posts"}
              data={alertViewWidgets?.topKeywords}
              errorMessage={"no_keywords_found"}
              showDownloadIcon
              bigImg
              keywords
              tablePagination
              count={alertViewWidgets?.topKeywords?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topKeywordsPreLoaderDataSources
              }
            />
          </Grid>
        </Grid>
      ) : null}
      {alertDetailType == "NegativePosts" ? (
        <Grid container spacing={2.5} mb={4}>
          <Grid item md={6}>
            <TableWidget
              wordsList
              title={"alert_top_keywords"}
              leftColomnTitle={"keywords_tab_title"}
              rightColomnTitle={"number_of_posts"}
              data={alertViewWidgets?.topKeywords}
              errorMessage={"no_keywords_found"}
              showDownloadIcon
              bigImg
              keywords
              tablePagination
              count={alertViewWidgets?.topKeywords?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topKeywordsPreLoaderDataSources
              }
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
export default GmailAlert;
