import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CheckValueLocale } from "utils/helpers";

const exportPDF = async (props, fileName, intl, setSnackBar, setPdfLoading) => {
  setPdfLoading(true);
  try {
    const input = document.querySelectorAll("#pdf-content");
    let doc = new jsPDF("p", "mm", "a4", true);

    for (let i = 0; i < input?.length; i++) {
      await window?.scrollTo(0, 0);
      const canvas = await html2canvas(input[i]);
      const imgWidth = 208;
      let imgHeight;
      let height =
        intl?.locale === "ar" ? (imgHeight = 135) : (imgHeight = 200);
      if (props?.pdfData?.monitors?.length < 2)
        imgHeight = (canvas?.height * height) / canvas?.width;
      else imgHeight = (canvas?.height * imgWidth) / canvas?.width;
      const position = 10;
      const imgData = canvas?.toDataURL("image/png", 1);
      doc?.addImage(
        imgData,
        "PNG",
        5,
        position,
        imgWidth,
        imgHeight,
        "",
        "SLOW",
      );
      if (i + 1 === input?.length)
        await doc?.save(fileName ? fileName : "benchmark");
      else doc?.addPage();
    }
    setSnackBar({
      open: true,
      title: CheckValueLocale("benchmark_export_success_title", "", {}, intl),
      msg: "",
      severity: "success",
    });
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    setPdfLoading(false);
  }
};

export default exportPDF;
