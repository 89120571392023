import { Box, Button, Divider } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import "./customDomainInfo.scss";
import DeleteCustomDomainModal from "../deleteCustomDomain/deleteCustomDomainModal";
import CustomizedTooltip from "components/customizedTooltip";
import SnackBar from "components/snackBar";

const CustomDomainInfo = ({
  customDomain,
  setCustomDomain,
  setCustomDomainName,
  setCustomDomainError,
  handleView,
  customDomainDetails,
}) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const [openDeleteSurveyModal, setOpenDeleteSurveyModal] = useState(false);
  const [snackBarDetails, setSnackBarDetails] = useState({
    openSnackBar: false,
    title: "",
    snackBarMsg: "",
    severity: "",
  });
  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(customDomain);
  };

  const handleDeleteCustomDomain = () => {
    setOpenDeleteSurveyModal(true);
  };
  let status = customDomainDetails?.frontend_status;
  let checkStatus =
    status === "pending"
      ? "custom-pending"
      : status === "connected"
        ? "custom-verified"
        : "custom-failed";

  const handleCloseSnackBar = () => {
    setSnackBarDetails({
      openSnackBar: false,
      snackBarMsg: "",
      severity: "",
    });
  };
  return (
    <>
      <Box className="custom-domain-info">
        <Box className="custom-copy-name">
          <FontAwesomeIcon icon={faGlobe} className="custom-globle-icon" />
          {customDomain}
          {status ? (
            <Box className={`custom-status ${checkStatus}`}>
              {CheckValueLocale(
                status === "pending"
                  ? "custom_domain_pending"
                  : status === "connected"
                    ? "custom_domain_verified"
                    : "custom_domain_failed",
                "",
                {},
                intl,
              )}
            </Box>
          ) : null}
        </Box>
        <Box className="custom-connected-delete">
          <CustomizedTooltip
            id={copied ? "custom-domain-copied-tooltip" : ""}
            title={
              <Box className="item-title-tooltip">
                {CheckValueLocale(
                  copied ? "linked_copy" : "copy",
                  "",
                  {},
                  intl,
                )}
              </Box>
            }
            placement="bottom"
            arrow
          >
            <Box
              component="span"
              onClick={handleCopy}
              className="custom-domain-copy custom-domain-copy-container"
            >
              <FontAwesomeIcon
                className={copied && "domain-copied-icon"}
                icon={faCopy}
              />
            </Box>
          </CustomizedTooltip>
          <Divider className="custom-divider" orientation="vertical" />
          <CustomizedTooltip
            title={
              <Box className="item-title-tooltip">
                {CheckValueLocale("view", "", {}, intl)}
              </Box>
            }
            placement="bottom"
            arrow
          >
            <Box
              component="span"
              onClick={handleView}
              className="custom-domain-copy custom-domain-copy-container"
            >
              <FontAwesomeIcon icon={faEye} />
            </Box>
          </CustomizedTooltip>
          <Divider className="custom-divider" orientation="vertical" />

          <CustomizedTooltip
            title={
              <Box className="item-title-tooltip">
                {CheckValueLocale("delete", "", {}, intl)}
              </Box>
            }
            arrow
            placement="bottom"
          >
            <Button
              id="delete-custom-domain"
              onClick={handleDeleteCustomDomain}
            >
              <FontAwesomeIcon
                className={`delete-survey-icon ${openDeleteSurveyModal && "delete-survey-icon-active"}`}
                icon={faTrashCan}
              />
            </Button>
          </CustomizedTooltip>
        </Box>
      </Box>
      <DeleteCustomDomainModal
        openDeleteSurveyModal={openDeleteSurveyModal}
        setOpenDeleteSurveyModal={setOpenDeleteSurveyModal}
        setCustomDomain={setCustomDomain}
        setCustomDomainName={setCustomDomainName}
        setCustomDomainError={setCustomDomainError}
        setSnackBarDetails={setSnackBarDetails}
        snackBarDetails={snackBarDetails}
      />
      {snackBarDetails?.openSnackBar ? (
        <SnackBar
          open={snackBarDetails?.openSnackBar}
          severity={snackBarDetails?.severity}
          title={snackBarDetails?.title}
          message={snackBarDetails?.snackBarMsg}
          snackBarClassName={"delete-survey-snackbar"}
          showCloseBtn={snackBarDetails?.severity === "success" ? true : false}
          textColor={
            snackBarDetails?.severity === "success" ? "green" : "brown"
          }
          handleClose={handleCloseSnackBar}
        />
      ) : null}
    </>
  );
};

export default CustomDomainInfo;
