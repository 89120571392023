import { Box, InputLabel, TextField, Tooltip, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

function InputNumberField({
  ToolTipTitle = "",
  labelInput = "",
  onChange,
  name,
  error,
  value,
  autoFocus,
  placeholder = "",
  disabled,
}) {
  const intl = useIntl();
  return (
    <>
      <Tooltip
        title={
          ToolTipTitle ? (
            <Box component={"span"} className="method-tooltip">
              {CheckValueLocale(ToolTipTitle, "", {}, intl)}
            </Box>
          ) : null
        }
        placement="bottom"
        arrow
      >
        <InputLabel>
          {CheckValueLocale(labelInput, "", {}, intl)}
          <Typography component="span" className="required">
            *
          </Typography>
        </InputLabel>
      </Tooltip>
      <TextField
        fullWidth
        id={`${name}-input-form`}
        onChange={(e) => onChange(e)}
        placeholder={CheckValueLocale(placeholder, "", {}, intl)}
        autoFocus={autoFocus}
        name={name}
        value={value}
        disabled={disabled}
        error={value < 0 || value === ""}
      />
    </>
  );
}

export default InputNumberField;
