import React, { useEffect, useState } from "react";
import { MonitorContainer } from "pages/Monitors/Components/sidebar/monitor.styles";
import AuthorsBody from "./authorsBody";
import Header from "../../../../Monitors/Components/header";

const AuthorsPage = (props) => {
  const [sortBy, setSortBy] = useState("latest_posts");
  const sortByData = ["latest_posts", "most_interactions", "most_followers"];
  const { widgetsName, dashboardDynamicData, setTotalPage, totalPage } = props;

  const metricsValues = dashboardDynamicData?.metrics_values;

  const handleChange = (event) => {
    setSortBy(event.target.value);
    props.setFilterParams({
      ...props.filterParams,
      sort_by: event.target.value,
    });
    props.applyFilter(props.startDate, props.endDate, {
      ...props.filterParams,
      sort_by: event.target.value,
    });
  };
  useEffect(() => {
    if (props.filterParams["sort_by"] !== undefined) {
      setSortBy(props.filterParams["sort_by"]);
    } else {
      setSortBy("latest_posts");
    }
  }, [props.filterParams["sort_by"]]);
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {widgetsName?.includes("top_authors") && (
        <div>
          {totalPage !== 0 && (
            <Header
              title="total_authors"
              titleValue={totalPage}
              sortByData={sortByData}
              sortBy={sortBy}
              handleChange={handleChange}
            />
          )}
          <AuthorsBody
            {...props}
            totalPage={totalPage}
            setTotalPage={setTotalPage}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_authors?.data_sources,
            ]}
          />
        </div>
      )}
    </MonitorContainer>
  );
};

export default AuthorsPage;
