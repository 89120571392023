import { Typography } from "@mui/material";
import "./complex-faq.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function flatMap(array, fn) {
  var result = [];
  for (var i = 0; i < array.length; i++) {
    var mapping = fn(array[i]);
    result = result.concat(mapping);
  }
  return result;
}

function updateIconPoint(icon_point_data) {
  let textArray = icon_point_data.text;
  if (icon_point_data.icon === "check") {
    textArray = flatMap(textArray.split(":check:"), function (part) {
      return [part, <CheckCircleIcon />];
    });
    textArray.pop();
  } else if (icon_point_data.icon === "cancel") {
    textArray = flatMap(textArray.split(":cancel:"), function (part) {
      return [part, <CancelIcon />];
    });
    textArray.pop();
  }
  return textArray;
}

const ComplexFaq = ({ data }) => {
  return (
    <div class="complex-faq">
      {data &&
        data.map((data) => (
          <div className="sub-complex-faq">
            {data.title1 && <Typography variant="h6">{data.title1}</Typography>}
            {data.title2 && (
              <Typography
                variant="subtitle2"
                sx={{ mt: data.title1 ? "10px" : "0px" }}
              >
                {data.title2}
              </Typography>
            )}
            {data.text &&
              data.text.map((text) => (
                <Typography variant="body2">{text}</Typography>
              ))}
            {data.image && (
              <img
                src={data.image}
                className={
                  data.image_class
                    ? `text-image ${data.image_class}`
                    : "text-image"
                }
                style={data.width && { width: data.width, height: data.height }}
              />
            )}
            {data.ul_number && (
              <ol>
                {data.ul_number.map((li_number_data) => (
                  <li>
                    <Typography variant="span">
                      {li_number_data.text}
                    </Typography>
                    {li_number_data.text_poniter && (
                      <ul className="li-number-text-pointer">
                        {li_number_data.text_poniter.map((li_poniter_data) => (
                          <li>{li_poniter_data}</li>
                        ))}
                      </ul>
                    )}
                    {li_number_data.image && (
                      <img
                        src={li_number_data.image}
                        className={
                          li_number_data.image_class
                            ? `${li_number_data.image_class}`
                            : ""
                        }
                        style={
                          li_number_data.width
                            ? {
                                width: li_number_data.width,
                                height: li_number_data.height,
                                "margin-bottom": li_number_data.mb || 0,
                              }
                            : { "margin-bottom": li_number_data.mb || 0 }
                        }
                      />
                    )}
                  </li>
                ))}
              </ol>
            )}
            {data.ul_point && (
              <ul>
                {data.ul_point.map((li_point_data) => (
                  <li>
                    {li_point_data.text} <br />
                    {li_point_data.image && (
                      <img
                        src={li_point_data.image}
                        className={
                          li_point_data.image_class
                            ? `${li_point_data.image_class}`
                            : ""
                        }
                        // style={{ "margin-bottom": li_point_data.mb || 0 }}
                        style={
                          li_point_data.width
                            ? {
                                width: li_point_data.width,
                                height: "auto",
                                "margin-bottom": li_point_data.mb || 0,
                              }
                            : { "margin-bottom": li_point_data.mb || 0 }
                        }
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}
            {data.table && <TableData data={data.table} />}
          </div>
        ))}
    </div>
  );
};

const TableData = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        {data.thead && (
          <TableHead>
            <TableRow>
              {data.thead.map((col_value) => (
                <TableCell>{col_value}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        {data.tbody && (
          <TableBody>
            {data.tbody.map((tbody_data, row_index) => (
              <TableRow key={data.key + "_" + row_index}>
                {tbody_data.map((td_data) => (
                  <TableCell rowSpan={td_data.row_span || 1}>
                    {td_data.text && (
                      <Typography
                        variant={
                          td_data.text_type && td_data.text_type === "bold"
                            ? "subtitle2"
                            : "span"
                        }
                      >
                        {td_data.text}
                      </Typography>
                    )}
                    {td_data.textArray &&
                      td_data.textArray.map((a_text) => (
                        <Typography variant="span">{a_text}</Typography>
                      ))}
                    {td_data.ul_point && (
                      <ul>
                        {td_data.ul_point.map((li_point_data) => (
                          <li>{li_point_data}</li>
                        ))}
                      </ul>
                    )}
                    {td_data.icon_point &&
                      td_data.icon_point.map((icon_point_data) => (
                        <Typography
                          variant={
                            icon_point_data.text_type &&
                            icon_point_data.text_type === "bold"
                              ? "subtitle2"
                              : "span"
                          }
                        >
                          {icon_point_data.text === "\n" ? (
                            <div>&nbsp;</div>
                          ) : (
                            updateIconPoint(icon_point_data)
                          )}
                        </Typography>
                      ))}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ComplexFaq;
