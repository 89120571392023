import { checkQuotationMark } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/advancedKeyword";

//__________________handleInputChange all ddatasources________________________
export const handleInputChange = (
  event,
  keywordType,
  source,
  index,
  indexes,
  keywords,
  setErr,
  handleValidation,
  setKeywords,
) => {
  const { value } = event.target;
  const keyCopy = [...keywords];
  source !== "newsBlogs" && setErr({ errMsg: "", keyword: "" });

  Object.values(keywords[indexes]).map((val) => {
    val[index]["copy"] = false;
  });

  handleValidation(event, keywordType, source, index);
  if (
    source === "twitter" &&
    (keywordType === "keywords" ||
      keywordType === "spam_keywords" ||
      keywordType === "collect_tweets" ||
      keywordType === "ignore_tweets")
  ) {
    keywords[indexes].twitter[index].answer = value;
  }
  if (
    source === "facebook" &&
    (keywordType === "keywords" || keywordType === "spam_keywords")
  ) {
    keywords[indexes].facebook[index].answer = value;
    keywords[indexes].facebook[index].errMsg = "";
  }
  if (
    source === "instagram" &&
    (keywordType === "hashtags" || keywordType === "spam_hashtags")
  ) {
    keywords[indexes].instagram[index].answer = value;
  }
  if (
    source === "newsBlogs" &&
    (keywordType === "keywords" || keywordType === "spam_keywords")
  ) {
    keyCopy[indexes].newsBlogs[index].answer = value;
    keywords[indexes].newsBlogs[index].errMsg = "";
  }
  source === "newsBlogs" && setKeywords(keyCopy);
};

// function to check placeholder text and make condition on it with object litrals
export const checkPlaceHolderText = (keyword) => {
  let keywordTranslate = {
    keywords: "scan_keyword_hashtags",
    hashtags: "scan_keyword_hashtags",
    spam_keywords: "exclude_posts",
    spam_hashtags: "exclude_posts",
    collect_tweets: "insert_username_then_enter_with_add_button",
    default: "insert_username_then_enter_with_add_button",
  };
  return keywordTranslate[keyword] || keywordTranslate.default;
};
//handle input field name
export const handleInputFieldName = (keyword) => {
  let keywordTranslate = {
    keywords: "keywords_tab_title",
    hashtags: "hashtags",
    spam_keywords: "spam_keywords",
    spam_hashtags: "spam_hashtags",
    collect_tweets: "collect_tweets",
    default: "ignore_tweets",
  };
  return keywordTranslate[keyword] || keywordTranslate.default;
};
//check field exist or not
export const checkAllInputsField = (keyword, keywordsInfo) => {
  return keywordsInfo.includes(keyword);
};

export const checkKeywordPopup = (keyword) => {
  let keywordTranslate = {
    keywords: "keywords",
    hashtags: "hashtags",
    spam_keywords: "spam_keywords",
    spam_hashtags: "spam_hashtags",
    collect_tweets: "collect_tweets",
    default: "ignore_tweets",
  };
  return keywordTranslate[keyword] || keywordTranslate.default;
};

//check lang direction
export const checkDirectionLang = (text) => {
  let textValid = text && checkQuotationMark(text?.trim())?.trim();
  let result;
  var pattern = /[\u0600-\u06FF\u0750-\u077F]/;
  text &&
  (pattern?.test(textValid[0]) ||
    (textValid[0] === "#" && pattern?.test(textValid[1])))
    ? (result = "lang-direction-ar")
    : (result = "lang-direction-en");
  return result;
};

// start - check Company Sources Reached
export const checkCompanyDatasources = (copmanySources) => {
  let companySources = [];
  copmanySources?.map((src) => {
    let company_source = src.attributes.source;
    switch (company_source) {
      case "TWITTER":
        companySources.push("twitter");
        break;
      case "FACEBOOK":
        companySources.push("facebook");
        break;
      case "INSTAGRAM":
        companySources.push("instagram");
        break;

      case "TALKWALKER":
        companySources.push("news_and_blogs");
        break;
      default:
    }
  });
  return companySources;
};

//check keywords source
export const checkKeywordsSource = (keywords) => {
  let keySource = [];
  keywords.map((i) => {
    keySource.push(Object.keys(i)[0]);
  });
  return keySource;
};
//check label in edit settings
export const checkLabelTextEdit = (keyword) => {
  let keywordTranslate = {
    keywords: "scan_keyword_hashtags",
    spam_keywords: "exclude_posts",
    hashtags: "scan_posts_hashtags",
    spam_hashtags: "exclude_posts_edit_hashtags",
    default: "insert_username_then_enter_with_add_button",
  };
  return keywordTranslate[keyword] || keywordTranslate.default;
};

//check limits of all keywords type
export const checkKeywordsLimits = (twLimit, fbLimit, igLimit, nbLimit) => {
  let checkExceedLimited = twLimit || fbLimit || igLimit || nbLimit;
  let messageLimits;
  let titleLimits = "";
  if (twLimit) {
    messageLimits = "reached_maximum_limit_in_twitter";
    titleLimits = "keyword_limit_exceeded";
  } else if (fbLimit) {
    messageLimits = "reached_maximum_limit_in_facebook";
    titleLimits = "";
  } else if (igLimit) {
    messageLimits = "reached_maximum_limit_in_instagram";
    titleLimits = "";
  } else if (nbLimit) {
    messageLimits = "reached_maximum_limit_in_news_and_blogs";
    titleLimits = "";
  } else {
    messageLimits = "";
    titleLimits = "";
  }
  return { checkExceedLimited, messageLimits, titleLimits };
};

export const handleInputMouseDown = (event) => {
  event.preventDefault();
};
