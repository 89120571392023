import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { TeamAndSLAs } from "../components/teamAndSLAs/teamAndSLAs";

export const EditRoutingTeamAndSLA = ({
  teamAndSLA,
  setTeamAndSLA,
  teamAndSLAsLoading,
  getSLAs,
  getTeams,
}) => {
  const intl = useIntl();
  return (
    <Box className="edit-inbound-routing-section">
      <Typography className="edit-inbound-routing-section-title">
        {CheckValueLocale("teams_and_slas_assigning_edit", "", {}, intl)}
      </Typography>
      <Box className="edit-inbound-routing-section-body">
        <TeamAndSLAs
          teamAndSLA={teamAndSLA}
          setTeamAndSLA={setTeamAndSLA}
          teamAndSLAsLoading={teamAndSLAsLoading}
          getSLAs={getSLAs}
          getTeams={getTeams}
        />
      </Box>
    </Box>
  );
};
