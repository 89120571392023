import EngagementsBody from "./engagementsBody";
import { MonitorContainer } from "../../../../Monitors/Components/sidebar/monitor.styles";

const EngagementsPage = (props) => {
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <EngagementsBody {...props} />
    </MonitorContainer>
  );
};

export default EngagementsPage;
