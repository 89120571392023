import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCkqaW99OghLm9slZxFHZ6qx6fiD3fzrNo",
  authDomain: "lucidya-notifications.firebaseapp.com",
  projectId: "lucidya-notifications",
  storageBucket: "lucidya-notifications.appspot.com",
  messagingSenderId: "165203963040",
  appId: "1:165203963040:web:64da65b25ffd40b66f2dc5",
  measurementId: "G-E61L2C9QJC",
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

export { messaging };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
