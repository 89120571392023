import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import PageHeader from "components/pageHeader";
import LucButton from "shared/lucButton/lucButton";

const ThemesPageHeader = ({
  disableNewTheme,
  setOpenDialog,
  setThemeDialogStats,
}) => {
  const intl = useIntl();

  return (
    <Box className="custom-themes-page-header">
      <PageHeader
        title="themes"
        subTitle="themes_page_header_subtitle"
        subTitleStyle="sub-title"
      />
      <LucButton
        id="new-theme-button"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        disabled={
          disableNewTheme || !handlelUserRoles("GENERAL", "CREATE_SUBTHEME")
        }
        onClick={() => {
          setThemeDialogStats({ isEdit: false });
          setOpenDialog(true);
        }}
      >
        {CheckValueLocale("new_theme", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default ThemesPageHeader;
