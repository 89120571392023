import {
  isNotValidExcludedSource,
  isValidSourceLengthMessage,
  changeSourceURLToValidFormat,
  isValidURL,
} from "./newsBlogsSources";
import { isPublicSpecialCharactersOrEmojis } from "./specialCharOrEmojis";
import { checkInvalidCases } from "./advancedKeyword";
import CreateMonitorController from "services/controllers/createMonitorController";
import { CheckValueLocale } from "utils/helpers";

//______________________exceedNewsBlogsCountLimits________________________________
export const exceedNewsBlogsCountLimits = (nbLimit, ...args) => {
  return args.reduce((total, current) => total + current) >= nbLimit;
};

//_____________________________handleNbExcludedSourcesChange__________________________________

export const handleNbExcludedSourcesChange = (
  event,
  setExcludedSource,
  nbExcludedSource,
  setExcludedSourcesErrorMsg,
  setNbExcludedSourceError,
  intl,
) => {
  let ex_source = event.target.value,
    exclude_source_error_msg = "";

  if (isNotValidExcludedSource(ex_source)) {
    exclude_source_error_msg = "invalid_source_input_char_emojis";
  } else if (isValidSourceLengthMessage(ex_source) !== "") {
    exclude_source_error_msg = isValidSourceLengthMessage(ex_source);
  } else {
    setExcludedSource(ex_source);
  }

  // Check if the URL Sources is Duplicated or Not
  if (
    nbExcludedSource.includes(changeSourceURLToValidFormat(ex_source.trim()))
  ) {
    exclude_source_error_msg = "source_cant_be_duplicate";
  } else {
    setExcludedSource(ex_source);
  }

  setExcludedSourcesErrorMsg(exclude_source_error_msg);
  setNbExcludedSourceError(exclude_source_error_msg);
};

//______________________________handleTitleSearchKeywordChange___________________________________

export const handleTitleSearchKeywordChange = (
  event,
  setTitleSearch,
  titleSearchKeyword,
  setTitleSearchErrorMsg,
  setTitleSearchKeywordError,
  intl,
) => {
  let title_search = event.target.value,
    titleSearchKeywordsErrorMsg = "";

  if (isPublicSpecialCharactersOrEmojis(title_search, false, true)) {
    titleSearchKeywordsErrorMsg = "invalid_input_char_emojis";
  } else if (title_search.length > 60) {
    titleSearchKeywordsErrorMsg =
      "reached_maximum_characters_numbers_in_keywords_tooltip";
  } else if (titleSearchKeyword.includes(title_search.trim())) {
    titleSearchKeywordsErrorMsg = "keyword_cant_be_duplicate";
  } else if (checkInvalidCases(title_search)) {
    titleSearchKeywordsErrorMsg = "invalid_keyword";
  } else {
    titleSearchKeywordsErrorMsg = "";
  }
  setTitleSearch(title_search);
  setTitleSearchErrorMsg(titleSearchKeywordsErrorMsg); // status in this file
  setTitleSearchKeywordError(titleSearchKeywordsErrorMsg); // status for all keywords
};

export const handleValidationSourcesNb = (
  event,
  source,
  setExcludedSourcesErrorMsg,
  setNbExcludedSourceError,
  excludedSourcesErrorMsg,
  nbExcludedSource,
  setExcludedSource,
  setCopyBtn,
  setNbExcludedSource,
  intl,
  clickType,
) => {
  if (
    ((event.key === "Enter" || (clickType === "btn" && source)) &&
      !isNotValidExcludedSource(source)) ||
    ((event.key === "Enter" || (clickType === "btn" && source)) &&
      !isValidURL(source))
  ) {
    if (!isValidURL(changeSourceURLToValidFormat(source))) {
      setExcludedSourcesErrorMsg("invalid_source_URL");
      setNbExcludedSourceError("invalid_source_URL");
    } else {
      if (excludedSourcesErrorMsg === "" || excludedSourcesErrorMsg === null) {
        event.target.value
          .trim()
          .toString()
          .split(/[,،]+/)
          .map((word) => {
            nbExcludedSource.push(changeSourceURLToValidFormat(word));
          });

        setExcludedSource("");
        setCopyBtn(CheckValueLocale("click_to_copy", "", {}, intl));
      }
    }
  }
  setNbExcludedSource([...nbExcludedSource]);
};

export const handleValidationTitleSearchNb = (
  event,
  title,
  titleSearchErrorMsg,
  titleSearchKeyword,
  setTitleSearch,
  setCopyBtn,
  setTitleSearchKeyword,
  intl,
  clickType,
) => {
  if (
    (event.key === "Enter" || (clickType === "btn" && title)) &&
    !isPublicSpecialCharactersOrEmojis(title, true)
  ) {
    if (titleSearchErrorMsg === "" || titleSearchErrorMsg === null) {
      event.target.value
        .trim()
        .toString()
        .split(/[,،]+/)
        .map((word) => {
          titleSearchKeyword.push(word);
        });

      setTitleSearch("");
      setCopyBtn(CheckValueLocale("click_to_copy", "", {}, intl));
    }
  }
  setTitleSearchKeyword([...titleSearchKeyword]);
};

export const getDataNbMonitorData = (setResp) => {
  CreateMonitorController.getCreateMonitorData({
    data_source: "talkwalker",
  }).then((data) => {
    if (data?.data?.data) {
      setResp(data.data.data);
    }
  });
};

export const handleDeleteTitle = (
  index,
  titleSearchKeyword,
  setTitleSearchErrorMsg,
  setTitleSearchKeyword,
) => {
  titleSearchKeyword.splice(index, 1);
  setTitleSearchErrorMsg("");
  setTitleSearchKeyword([...titleSearchKeyword]);
};
