import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import tokensIconBlue from "../../../../images/luci-images/tokens-icon-blue.svg";
import noDataluci from "../../../../images/luci-images/luci-data.svg";
import { useIntl } from "react-intl";
import { faCog, faTimes } from "@fortawesome/pro-regular-svg-icons";
import "./noData.scss";
import "./../headerTokens/headerTokens.scss";
import {
  CheckValueLocale,
  commasAfterDigit,
  handlelUserRoles,
} from "utils/helpers";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";

const HeaderTokens = ({ tokens }) => {
  return (
    <Box className="header-tokens">
      <img src={tokensIconBlue} />
      {tokens ? commasAfterDigit(tokens) : "0"}
    </Box>
  );
};
const NoData = (props) => {
  const intl = useIntl();
  const {
    isEmpty,
    tokens,
    productName,
    setShowRequests,
    isBenchmark,
    requestedData,
    handleOpenSettings,
  } = props;

  const noDataProductName = isBenchmark
    ? !requestedData
      ? "luci_start_benchmarking"
      : "luci_no_data_sl_ca"
    : productName == "surveys"
      ? "luci_no_dataـsurvey"
      : "luci_no_data_sl_ca";

  const userRoles = JSON.parse(window?.localStorage?.getItem("user_roles"));

  return (
    <Box className="no-data-parant">
      <Box className="no-data-head">
        <Button
          className="btn-close-side-bar"
          onClick={() => props?.closeSideBar()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Box className="luci-btns-container">
          <HeaderTokens tokens={tokens} />
          {userRoles?.GENERAL !== undefined &&
            handlelUserRoles("GENERAL", "VIEW_SETTING") &&
            handlelUserRoles("GENERAL", "EDIT_SETTING") && (
              <LucButton
                id="luci-settings-btn"
                className="luci-settings-btn"
                variant="flat"
                type="secondary"
                minWidth={35}
                onClick={handleOpenSettings}
              >
                <FontAwesomeIcon icon={faCog} size="lg" />
              </LucButton>
            )}
        </Box>
      </Box>
      <Box className="no-data-content">
        <Box component={"span"}>
          <img src={noDataluci} />
        </Box>
        <Box component={"h2"}>
          {CheckValueLocale(
            isEmpty
              ? requestedData === false
                ? "luci_start_benchmarking_title"
                : "luci_no_data_found"
              : "luci_analyze",
            "",
            {},
            intl,
          )}
        </Box>
        <Typography>
          {CheckValueLocale(
            isEmpty ? noDataProductName : "luci_analyze_not_sure_what",
            "",
            {},
            intl,
          )}
        </Typography>
      </Box>
      <Button
        className={`show-prev-requests-btn nodata-show-prev`}
        onClick={() => {
          setShowRequests(true);
        }}
      >
        <FontAwesomeIcon icon={faHistory} />
        {CheckValueLocale("luci_show_prev_requests", "", {}, intl)}
      </Button>
    </Box>
  );
};
export default NoData;
