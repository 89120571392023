import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useIntl } from "react-intl";
import {
  faArrowUpRightFromSquare,
  faGrid2Plus,
} from "@fortawesome/pro-regular-svg-icons";
import { useHistory } from "react-router-dom";

export default function MonitorLiveDashboardMenu({
  monitorLiveDashboardList,
  urlParams,
  monitorDetails,
  monitorLiveDashboardListLoading,
}) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const userId = localStorage.getItem("user_id");
  const isUserHaveLiveDashboard = monitorLiveDashboardList?.some(
    (dashboard) => +dashboard?.user_dashboard_id === +userId,
  );
  const enabledCreate = handlelUserRoles("SM", "CREATE_DASHBOARD");
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const anchorOrigin =
    intl?.locale === "en"
      ? { vertical: "bottom", horizontal: "right" }
      : { vertical: "bottom", horizontal: "left" };

  const transformOrigin =
    intl?.locale === "en"
      ? { vertical: "top", horizontal: "right" }
      : { vertical: "top", horizontal: "left" };

  return (
    <Box className="live-dashboard-menu-btn">
      <LucButton
        id="live-dashboard-menu-btn"
        variant="outline"
        type="secondary"
        size="small"
        endIcon={<FontAwesomeIcon icon={open ? faCaretDown : faCaretUp} />}
        onClick={handleClick}
        loading={monitorLiveDashboardListLoading}
      >
        {CheckValueLocale("go_to_create_live_dashboard", "", {}, intl)}
      </LucButton>

      <Menu
        elevation={0}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        id="live-dashboard-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!isUserHaveLiveDashboard && enabledCreate ? ( // if user don't have dashboard for this monitor
          <MenuItem
            onClick={() =>
              history.push({
                pathname: `/create-live-dashboard/${urlParams?.monitorType}/${urlParams?.monitorId}`,
                state: monitorDetails,
              })
            }
          >
            <Box className="live-dashboard-item-menu">
              <Box className="title-create-live-dashboard-item">
                {CheckValueLocale("create_live_dashboard", "", {}, intl)}
              </Box>
              <FontAwesomeIcon icon={faGrid2Plus} />
            </Box>
          </MenuItem>
        ) : null}
        {monitorLiveDashboardList?.length ? (
          monitorLiveDashboardList?.map((dashboard, index) => (
            <MenuItem
              onClick={() =>
                history.push({
                  pathname: `/live-dashboard/private/${urlParams?.monitorType}/${urlParams?.monitorId}/${dashboard?.user_dashboard_id}`,
                  state: monitorDetails,
                })
              }
              key={index}
            >
              <Box className="live-dashboard-item-menu">
                <Box className="title-live-dashboard-item">
                  {dashboard?.dashboard_name}
                  <Box className="created-by-live-dashboard-item">
                    {CheckValueLocale("by_live_dashboard", "", {}, intl)}
                    {dashboard?.creator_name}
                    {+dashboard?.user_dashboard_id === +userId
                      ? CheckValueLocale("you_live_dashboard", "", {}, intl)
                      : ""}
                    {dashboard?.user_is_deleted
                      ? CheckValueLocale(
                          "deleted_user_live_dashboard",
                          "",
                          {},
                          intl,
                        )
                      : ""}
                  </Box>
                </Box>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Box>
            </MenuItem>
          ))
        ) : (
          <Typography className="live-dashboard-menu-empty">
            {CheckValueLocale("live_dashboard_menu_empty", "", {}, intl)}
          </Typography>
        )}
      </Menu>
    </Box>
  );
}
