import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";

export const useSentimentsOptions = ({ locale, disableFetch }) => {
  const urlParams = useParams();
  return useQuery({
    queryKey: ["sentimentsOptions", locale],
    queryFn: () => {
      if (window?.location?.href?.includes("/live-dashboard/public/")) {
        return InteractionsController.getSentimentsPublic({
          monitor_id: urlParams?.monitorId,
          dashboard_user_id: +urlParams?.userDashboardId,
        });
      } else {
        return InteractionsController.getSentiments();
      }
    },
    enabled: !disableFetch,
    retry: false,
    select: (data) => {
      // To set sentiments in descending order [positive, neutral, negative]
      const sortedObj = Object?.fromEntries(
        Object?.entries(data?.data)?.sort()?.reverse(),
      );
      return sortedObj;
    },
  });
};
