//THIS FILE CONTAINS COMMON FUNCTION USED IN CREATE NEW MANAGE CHANGE AND CREATE NEW MONITOR

import AuthController from "services/controllers/authController";
import CompaniesController from "services/controllers/companiesController";

// Get Company Apps
const getCompanyApps = (setCompanyApps) => {
  CompaniesController.getCompanyApps().then((result) => {
    setCompanyApps(result.data.data);
  });
};

//filtered company apps
const getCompanyAppDatasource = (companyApps, datasource_name) => {
  let companyAppDatasource = companyApps?.filter(
    (item) =>
      item?.attributes?.data_source_name?.toLocaleLowerCase() ===
      datasource_name?.toLocaleLowerCase(),
  );
  return companyAppDatasource[0];
};

// Authentication for Twitter
const twitterAuth = (
  companyApps,
  redirectUrl,
  datasource_name,
  isSocialListening,
  is_company_accoun,
) => {
  let companyAppDatasource = getCompanyAppDatasource(
    companyApps,
    datasource_name,
  );
  let consumer_key = companyAppDatasource?.attributes.consumer_key,
    oauth2_flow_init_url =
      companyAppDatasource?.attributes.oauth2_flow_init_url;
  let is_company_account = is_company_accoun == false ? false : true;
  let productId = isSocialListening
    ? window.localStorage.sm_id
    : window.localStorage.cxm_id;
  AuthController.twitterAuth(
    consumer_key,
    redirectUrl,
    productId,
    oauth2_flow_init_url,
    is_company_account,
  );
};

// Authentication for Facebook / Instagram / Whatsapp
const facebookAuth = (
  companyApps,
  redirectUrl,
  datasource_name,
  isSocialListening,
  is_company_accoun,
  data_source_name_whatsapp,
) => {
  let companyAppDatasource = getCompanyAppDatasource(
    companyApps,
    datasource_name,
  );
  let is_company_account = is_company_accoun == false ? false : true;
  let consumerKey = companyAppDatasource?.attributes.consumer_key;

  let oauth2_flow_init_url =
    companyAppDatasource?.attributes.oauth2_flow_init_url;

  let productId = isSocialListening
    ? window.localStorage.sm_id
    : window.localStorage.cxm_id;

  AuthController.facebookAuth(
    consumerKey,
    redirectUrl,
    productId,
    oauth2_flow_init_url,
    is_company_account,
    data_source_name_whatsapp,
  );
};

const gmailAuth = (
  companyApps,
  redirectUrl,
  datasource_name,
  isSocialListening,
) => {
  let companyAppDatasource = getCompanyAppDatasource(
    companyApps,
    datasource_name,
  );
  let consumer_key = companyAppDatasource?.attributes.consumer_key,
    oauth2_flow_init_url =
      companyAppDatasource?.attributes.oauth2_flow_init_url;
  let productId = isSocialListening
    ? window.localStorage.sm_id
    : window.localStorage.cxm_id;
  AuthController.gmailAuth(
    consumer_key,
    redirectUrl,
    productId,
    oauth2_flow_init_url,
    true,
  );
};

//GMB
const gmbAuth = (
  companyApps,
  redirectUrl,
  datasource_name,
  isSocialListening,
) => {
  let companyAppDatasource = getCompanyAppDatasource(
    companyApps,
    datasource_name,
  );

  let consumer_key = companyAppDatasource?.attributes.consumer_key,
    oauth2_flow_init_url =
      companyAppDatasource?.attributes.oauth2_flow_init_url;
  let productId = isSocialListening
    ? window.localStorage.sm_id
    : window.localStorage.cxm_id;
  AuthController.gmbAuth(
    consumer_key,
    redirectUrl,
    productId,
    oauth2_flow_init_url,
    true,
  );
};

// Authentication for LinkedIn
const linkedInAuth = (
  companyApps,
  redirectUrl,
  datasource_name,
  isSocialListening,
  isCompanyAcccount,
) => {
  let companyAppDatasource = getCompanyAppDatasource(
    companyApps,
    datasource_name,
  );
  let consumer_key = companyAppDatasource?.attributes.consumer_key,
    oauth2_flow_init_url =
      companyAppDatasource?.attributes.oauth2_flow_init_url;
  const redirectTo = companyAppDatasource?.attributes?.frontend_redirection;
  let companyAccount = isCompanyAcccount === false ? false : true;
  let productId = isSocialListening
    ? window.localStorage.sm_id
    : window.localStorage.cxm_id;

  AuthController.linkedInAuth(
    consumer_key,
    redirectUrl,
    productId,
    oauth2_flow_init_url,
    companyAccount,
    datasource_name,
    redirectTo,
  );
};

const genesysLastStepAuth = (
  dataSourceId,
  code,
  setGenesysCodeLoading,
  setGenesysStatus,
  setFailSnackBarData,
  setOpenSnackBarAllSource,
  getApiData,
) => {
  setGenesysCodeLoading && setGenesysCodeLoading(true);
  AuthController.genesysLastStepAuth(
    window.localStorage?.cxm_id,
    dataSourceId,
    code,
    window.location.href.split("?")[0],
  ).then((data) => {
    setGenesysStatus && setGenesysStatus(data?.errorMsg);
    setGenesysCodeLoading && setGenesysCodeLoading(false);
    getApiData && getApiData(1);
    if (data?.errorMsg) {
      if (
        data?.errorMsg?.response?.data?.error?.detail ===
        "the_organization_already_exist_and_has_super_admin"
      ) {
        setFailSnackBarData({
          message: "genesys_invalid_request_message",
          severity: "error",
          title: "genesys_invalid_request_title",
        });
      } else {
        setFailSnackBarData({
          message: "failed_error",
          severity: "error",
        });
      }
      setOpenSnackBarAllSource(true);
    } else {
      if (window.location?.search?.includes("?code"))
        setFailSnackBarData({
          message: "authenticate_success_msg",
          severity: "success",
        });
      setOpenSnackBarAllSource(true);
    }

    //reset url from query parameters
    window.history.pushState({}, "", window.location.pathname);
  });
};

// Authentication for tiktok
const tiktokAuth = (
  redirectUrl,
  data_source_id,
  setSnackbarData,
  setOpenSnackbar,
) => {
  let queryData = {
    redirection_url: redirectUrl,
    product_id: window.localStorage?.cxm_id,
    data_source_id: data_source_id,
  };
  AuthController.tiktokAuth(queryData).then((data) => {
    if (data?.data?.url) {
      window.location.href = data?.data?.url;
    } else {
      if (data?.errorMsg) {
        setOpenSnackbar(true);
        setSnackbarData({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
        });
      }
    }
  });
};

export {
  getCompanyApps,
  twitterAuth,
  facebookAuth,
  gmailAuth,
  gmbAuth,
  linkedInAuth,
  genesysLastStepAuth,
  tiktokAuth,
};
