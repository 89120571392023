import React, { useState } from "react";
import {
  TextField,
  Box,
  Alert,
  Snackbar,
  IconButton,
  Collapse,
} from "@mui/material";
import { Add, Close, Search } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./monitorsListHeader.scss";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  handleActiveFeature,
  handlelUserRoles,
} from "utils/helpers";
import CircularLoading from "components/circularProgress";
import { useHistory } from "react-router-dom";
import PageHeader from "components/pageHeader";
import LucButton from "shared/lucButton/lucButton";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";

const MonitorsListHeader = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && !validationError) {
      // Set redirected_monitor from history of needs attention click with NULL
      history?.push({ ...history?.location, needsAttentionData: null });

      props?.setSearchInput(props?.searchText);
      props?.setSearchActivated(true);
      e.target.blur();
      // If page is already 1, setPage will not work so we will call getmonitors directly
      if (props?.page !== 1) props.setPage(1);
    }
  };

  const searchIconOnClick = () => {
    if (!validationError) {
      // Set redirected_monitor from history of needs attention click with NULL
      history?.push({ ...history?.location, needsAttentionData: null });

      // If page is already 1, setPage will not work so we will call getMonitors directly
      props?.setSearchInput(props?.searchText);
      if (props?.page !== 1) props.setPage(1);
    }
  };

  const handleCloseSnack = () => {
    setOpenAlert(false);
  };

  let createMonitorFlag =
    handlelUserRoles("SM", "CREATE_MONITOR") && props?.companyCanCreateMonitor
      ? false
      : true;

  return (
    <>
      {isLoading ? <CircularLoading /> : false}
      <Box className="header-table">
        <Box container mb={"20px"} className="title-header-box">
          <PageHeader
            title="monitors_social_title"
            titleAttributeName="num"
            titleAttributeValue={props?.monitors && props?.totalMonitors}
          />
          <Box id="title-button-box">
            {handleActiveFeature("SM", "is_explore_enabled") &&
            handlelUserRoles("SM", "EXPLORE") ? (
              <LucButton
                onClick={() => history.push(`/social/explore`)}
                variant="outline"
                className="quick-search-btn"
                startIcon={<Search />}
                id="qiuck-search-btn"
              >
                {CheckValueLocale("quick_search", "", {}, intl)}
              </LucButton>
            ) : null}
            <LucButton
              onClick={() => history.push(`/social/monitors/create_monitor`)}
              variant="filled"
              startIcon={<Add />}
              disabled={createMonitorFlag}
              id="monitor-create-new-monitor-btn"
            >
              {CheckValueLocale("new_monitor", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
        <Box className="search-desblay">
          {props?.monitors?.length > 0 || props?.searchInput ? (
            <Box className="search-company">
              <Box className="search-box">
                <TextField
                  className="search-field"
                  label={CheckValueLocale("search_by_name", "", {}, intl)}
                  variant="outlined"
                  value={props?.searchText}
                  onChange={(e) => {
                    const invalidText = isPublicSpecialCharactersOrEmojis(
                      e?.target?.value,
                      undefined,
                      true,
                    );
                    setValidationError(invalidText);
                    props?.setSearchActivated(true);
                    props?.setSearchText(e.target.value);
                  }}
                  onBlur={() => setSearchBoxClicked(false)}
                  onFocus={() => setSearchBoxClicked(true)}
                  onKeyPress={handleEnterKeyPress}
                  id="social-media-search-by-name-text"
                />
                <Box
                  className={
                    "search-icon-container " +
                    (searchBoxClicked ? "search-icon-container-blue" : "")
                  }
                  onClick={() => searchIconOnClick()}
                  id="social-media-search-by-name-icon-box"
                >
                  <FontAwesomeIcon
                    className="search-icon"
                    icon={faSearch}
                    id="social-media-search-by-name-icon"
                  />
                </Box>
              </Box>
              <Box variant="p" className="search-input-validation-error">
                {validationError
                  ? CheckValueLocale("spam_keywords_rules", "", {}, intl)
                  : null}
              </Box>
            </Box>
          ) : null}

          <Box>
            {!props?.companyCanCreateMonitor ? (
              <Collapse in={openAlert}>
                <Snackbar
                  open={true}
                  onClose={handleCloseSnack}
                  autoHideDuration={7000}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  sx={{ top: "63px !important" }}
                  className="alert_expired_wrapper"
                >
                  <Alert
                    severity="error"
                    sx={{ width: "50%" }}
                    className="expired_wrapper"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                        id="social-media-alert-close-icon-btn"
                      >
                        <Close
                          fontSize="inherit"
                          id="social-media-alert-close-icon"
                        />
                      </IconButton>
                    }
                  >
                    <Box component="span" className="expired_error_head">
                      {CheckValueLocale(
                        "reaching_max_numbers_of_monitors_error_message",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  </Alert>
                </Snackbar>
              </Collapse>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default MonitorsListHeader;
