import React, { useEffect, useState } from "react";
import { Box, Switch, TextField } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../surveyBuilder.scss";

const FreeAnswer = (props) => {
  const {
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);
  const [switchReq, setSwitchReq] = useState(false);
  const [switchMax, setSwitchMax] = useState(false);
  const [numberValue, setNumberValue] = useState(-1);

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setSwitchReq(question?.constraints?.required);
      setNumberValue(question?.constraints?.max_chars);
      if (question?.constraints?.max_chars > 0) setSwitchMax(true);
    }
  }, [question]);

  const handleRequiredSwitch = (e) => {
    setSwitchReq(e.target.checked);
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints["required"] = e.target.checked;
    setSurveySections([...surveySections]);
  };

  const handleMaxSwitch = (e) => {
    if (e.target.checked) {
      setSwitchMax(true);
      setNumberValue(1);
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints["max_chars"] = 1;
    } else {
      setSwitchMax(false);
      setNumberValue(-1);
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints["max_chars"] = -1;
    }
    setSurveySections([...surveySections]);
  };

  const handleNumberValue = (e) => {
    if (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 999999) {
      setNumberValue(e.target.value);
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints["max_chars"] = e.target.value;
      setSurveySections([...surveySections]);
    }
  };

  return (
    <>
      <Box
        className={`constraints-text ${editable === false && `disabled-color`}`}
      >
        <Box>{CheckValueLocale("required", "", {}, intl)}</Box>
        <Switch
          data-testid="required_switch"
          onChange={(e) => handleRequiredSwitch(e)}
          checked={switchReq}
        />
      </Box>
      <Box
        className={`constraints-text ${editable === false && `disabled-color`}`}
      >
        <Box>{CheckValueLocale("max_chars", "", {}, intl)}</Box>
        <Switch
          data-testid="required_switch"
          onChange={(e) => handleMaxSwitch(e)}
          checked={switchMax}
          disabled={editable === false ? true : false}
        />
      </Box>
      {switchMax === true && (
        <TextField
          disabled={editable === false ? true : false}
          id="outlined-basic"
          name="max_chars"
          placeholder="0-999999"
          className="max-chars"
          type="number"
          onChange={handleNumberValue}
          value={numberValue !== -1 ? numberValue : ""}
        />
      )}
    </>
  );
};

export default FreeAnswer;
