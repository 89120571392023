import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { AllInclusiveRounded } from "@mui/icons-material";
import FaqsListHeader from "pages/faqs/faqs/FaqsListHeader";
import FaqsList from "pages/faqs/faqs/FaqsList";
import faqsListArray from "pages/faqs/faqs/faqs.json";

const FaqsPage = () => {
  const [faqsExpanded, setFaqsExpanded] = useState("none");
  const [selectedfaqs, setSelectedfaqs] = useState([]);
  const [appLang, setAppLang] = useState("en");

  useEffect(() => {
    setAppLang(localStorage.getItem("lang"));
  }, [localStorage.getItem("lang")]);

  useEffect(() => {
    const faqs_length = faqsListArray["en"].length;
    selectedfaqs.length > 0 &&
      (selectedfaqs.length >= faqs_length
        ? setFaqsExpanded("all")
        : setFaqsExpanded("none"));
  }, [selectedfaqs]);

  const handleChange = (panel) => (event, newExpanded) => {
    if (selectedfaqs.includes(panel)) {
      const updatedPanelValue = selectedfaqs.filter((item) => item !== panel);
      setSelectedfaqs([...updatedPanelValue]);
    } else {
      panel != "" && setSelectedfaqs((val) => [...val, panel]);
    }
  };

  const handleCollapseAll = () => {
    setFaqsExpanded(faqsExpanded === "all" ? "none" : "all");
    setSelectedfaqs([]);
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <FaqsListHeader
          faqsExpanded={faqsExpanded}
          handleCollapseAll={handleCollapseAll}
        />
        <FaqsList
          appLang={appLang}
          faqsExpanded={faqsExpanded}
          handleChange={handleChange}
          selectedfaqs={selectedfaqs}
          faqsList={faqsListArray}
        />
      </Box>
    </Container>
  );
};

export default FaqsPage;
