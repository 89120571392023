import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import MonitorsController from "services/controllers/monitorsController";
import MonitorList, {
  getMonitorListTableHeader,
} from "./components/monitorList/monitorList";
import CircularLoading from "components/circularProgress";
import MonitorsListHeader from "../components/monitorListHeader/monitorsListHeader";
import { Box } from "@mui/system";
import Snackbar from "components/snackBar";
import { useIntl } from "react-intl";
import Pagination from "@mui/material/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import "./monitorSocial.scss";
import { CheckValueLocale } from "utils/helpers";
import { useDispatch } from "react-redux";
import { clear } from "utils/redux/features/SocialListening/socialListeningSlice.js";
import { useSortableTable } from "hooks/useSortableTable";
import BenchmarkController from "services/controllers/benchmarkController";

const MonitorListPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const [monitors, setMonitors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [isBenchmarkPolicy, setIsBenchmarkPolicy] = useState(false);
  const isLucidyaAccount = window.localStorage.is_lucidya_account;
  const [companyCanCreateMonitor, setCompanyCanCreateMonitor] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [monitorSnackBar, setMonitorSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const handleCloseMonitorSnackbar = () => {
    setMonitorSnackBar({
      openSnackBar: false,
    });
  };
  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPage(1);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getMonitorListTableHeader(intl),
    onSortChangeCallback,
    defaultSelectedColumn: "created_at",
    defaultDirection: "desc",
  });

  useEffect(() => {
    let language = window.localStorage.lang;
    var arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;

    if (location?.state?.monitorName) {
      setSuccess(true);
      setSuccessMsg(
        language !== "ar"
          ? CheckValueLocale("your_new_monitor", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("was_created_successfully", "", {}, intl)
          : arabicAlphabetDigits?.test(location?.state?.monitorName)
            ? CheckValueLocale("your_new_monitor", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("was_created_successfully", "", {}, intl)
            : CheckValueLocale("was_created_successfully", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("your_new_monitor", "", {}, intl),
      );

      setTimeout(() => {
        setSuccess(null);
        setSuccessMsg("");
      }, 10000);
    } else if (location?.state?.fail) {
      setSuccess(false);
      setErrorMsg(CheckValueLocale(location?.state?.fail, "", {}, intl));
      setTimeout(() => {
        setSuccess(null);
        setErrorMsg("");
      }, 10000);
    }
  }, [location]);

  useEffect(() => {
    const needsAttentionData = history?.location?.needsAttentionData;
    if (needsAttentionData?.redirected_monitor) {
      getMonitors(needsAttentionData?.redirected_monitor);
    } else getMonitors();
  }, [page, sortColumn, sortDirection, history?.location]);

  useEffect(() => {
    if (
      localStorage.edit_monitor === "true" &&
      window.location.pathname === "/social/monitor_list"
    ) {
      setSuccess(true);
      setSuccessMsg("monitor_edited");
      setTimeout(() => {
        setSuccess(null);
        window.localStorage.setItem("edit_monitor", false);
      }, 10000);
    }
  }, [location]);

  useEffect(() => {
    if (isLucidyaAccount === "1") {
      getCompanyList();
    }
    dispatch(clear());
  }, []);
  const getMonitors = (redirectedMonitor) => {
    if (!isSortableLoading) setLoading(true);
    MonitorsController.monitors(
      window.localStorage.getItem("sm_id"),
      searchText,
      page,
      sortColumn,
      sortDirection,
      redirectedMonitor,
    ).then((res) => {
      if (res?.errorMsg) {
        setMonitors([]);
        setPagination(1);
        setMonitorSnackBar({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      } else {
        setMonitors(res?.data);
        setPagination(res?.pagination);
        setCompanyCanCreateMonitor(res?.companyCanCreateMonitor);
      }
      setLoading(false);
      setIsSortableLoading(false);
    });
  };
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const getCompanyList = () => {
    MonitorsController.getCompanyList().then((res) => {
      setCompanies(res.data);
    });
  };
  // ________________________________________________________________
  //This part is related to Edit monitor settings  Page in case of twitter monitor if user selected (save and compute)
  //user gets directed to monitor list page and Success snack bar appears
  const [showMonitorRecomputedSnackBar, setShowMonitorRecomputedSnackBar] =
    useState(false);
  const handleCloseMonitorRecomputedSnackBar = () => {
    setShowMonitorRecomputedSnackBar(false);
    window.localStorage.setItem("monitorRecomputed", false);
  };
  useEffect(() => {
    setShowMonitorRecomputedSnackBar(window.localStorage.monitorRecomputed);
    // }
  }, [window.localStorage.monitorRecomputed]);
  // ________________________________________________________________

  //benchmark policy popup
  const getBenchmarkPolicy = () => {
    BenchmarkController.getApplyPolicy().then((result) => {
      if (result?.data?.length > 0) {
        result?.data?.map((item) => {
          if (item?.policy_type == "benchmark_v4") {
            setIsBenchmarkPolicy(false);
          }
        });
      } else {
        setIsBenchmarkPolicy(true);
      }
    });
  };

  useEffect(() => {
    getBenchmarkPolicy();
  }, []);

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularLoading />
      ) : (
        <Box>
          <MonitorsListHeader
            totalMonitors={pagination?.count}
            monitors={monitors}
            getMonitors={getMonitors}
            searchText={searchText}
            setSearchActivated={setSearchActivated}
            setSearchText={setSearchText}
            setPage={setPage}
            page={page}
            companyCanCreateMonitor={companyCanCreateMonitor}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <MonitorList
            setPage={setPage}
            page={page}
            monitors={monitors}
            setSuccess={setSuccess}
            setSuccessMsg={setSuccessMsg}
            setErrorMsg={setErrorMsg}
            getMonitors={getMonitors}
            setLoading={setLoading}
            searchActivated={searchActivated}
            searchText={searchText}
            tableHead={tableHead}
            isSortableLoading={isSortableLoading}
            setIsBenchmarkPolicy={setIsBenchmarkPolicy}
            isBenchmarkPolicy={isBenchmarkPolicy}
          />
          {pagination?.pages > 1 && !isSortableLoading ? (
            <Pagination
              onChange={handleChangePage}
              page={page}
              className="pagination-monitor-list"
              count={pagination?.pages}
              variant="outlined"
              rowsPerPage={pagination?.items}
            />
          ) : (
            <div className="pagination-one-page"></div>
          )}
        </Box>
      )}
      {success != null ? (
        <Snackbar
          lang={window.localStorage.lang}
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={success ? successMsg : errorMsg}
          alertStyle={
            !companyCanCreateMonitor
              ? { width: "100%", marginTop: "85px" }
              : { width: "100%" }
          }
        />
      ) : (
        ""
      )}
      {showMonitorRecomputedSnackBar == "true" && (
        <Snackbar
          open={showMonitorRecomputedSnackBar}
          handleClose={handleCloseMonitorRecomputedSnackBar}
          severity={"success"}
          message={CheckValueLocale(
            "save_monitor_settings_compute",
            "",
            {},
            intl,
          )}
        />
      )}
      <Snackbar
        open={monitorSnackBar?.openSnackBar}
        severity={monitorSnackBar?.severity}
        message={CheckValueLocale(monitorSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(monitorSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseMonitorSnackbar}
      />
    </Container>
  );
};

export default MonitorListPage;
