import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { NestedMenuItem } from "mui-nested-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import "./contentTab/contentTab.scss";
import { dummyQuestions } from "../dummyQuestions";

const ContentAddBtn = (props) => {
  const {
    surveySections,
    setSurveySections,
    filteredSections,
    setSQIndex,
    sqIndex,
    setSSIndex,
    ssIndex,
    setOperationPerformed,
    emptySection,
    setEmptySection,
    scrollToIdFunc,
  } = props;

  const intl = useIntl();

  const [questionsAnchorEl, setQuestionsAnchorEl] = useState(null);
  const questionsOpen = Boolean(questionsAnchorEl);

  const dropdownOptions = {
    mainOptions: [
      "yes_no",
      "multiple_choice",
      "dropdown",
      "number",
      "short_text",
    ],
    contactInfoOptions: ["email", "phone_number", "name", "social_media"],
    NPSquestion: ["nps"],
    scaleQuestion: ["scale"],
  };

  const handleClick = (e) => setQuestionsAnchorEl(e.currentTarget);
  const handleClose = () => setQuestionsAnchorEl(null);

  const handleAddQuestion = (e, sectionIndex, type) => {
    e.stopPropagation();
    let sectionQuestions = [...filteredSections[sectionIndex]?.questions];

    dummyQuestions?.map((question) => {
      if (question?.question?.type === type) {
        const items = {};
        items.question = {};
        items.question.text = CheckValueLocale(
          question?.question?.text,
          "",
          {},
          intl,
        );
        items.question.description = question?.question?.description;
        items.question.editable = question?.question?.editable;
        items.question.type = question?.question?.type;
        items.is_deleted = question?.is_deleted;
        if (question?.is_proceed_on_answer !== undefined)
          items.is_proceed_on_answer = false;
        if (question?.is_proceed_editable !== undefined)
          items.is_proceed_editable = true;

        items.question.constraints = {};

        Object.keys(question?.question?.constraints).forEach((key) => {
          if (key === "min_number" || key === "max_number") {
            items.question.constraints[key] = {};
            items.question.constraints[key].checked =
              question?.question?.constraints[key]?.checked;
            items.question.constraints[key].value =
              question?.question?.constraints[key]?.value;
          } else {
            items.question.constraints[key] =
              question?.question?.constraints[key];
          }
        });

        if (
          question?.question?.items !== undefined ||
          question?.question?.items?.length >= 0
        ) {
          let arr = [];
          if (question?.question?.items?.length > 0) {
            question.question.items.map((i) => {
              const items = {};
              items[Object.keys(i)[0]] = Object.values(i)[0];
              arr.push(items);
            });
          }
          items.question.items = arr;
        }

        sectionQuestions?.push(items);
      }
    });

    if (emptySection === sectionIndex) setEmptySection(null);
    filteredSections[sectionIndex].questions = sectionQuestions;
    setSurveySections([...surveySections]);
    setSQIndex(sectionQuestions?.length);
    setSSIndex(sectionIndex + 1);
    setOperationPerformed("operationsPerformed");
    setQuestionsAnchorEl(null);
    scrollToIdFunc(
      `section-${sectionIndex}-question-${sectionQuestions?.length - 1}`,
    );
  };

  return (
    <>
      <Tooltip
        placement="bottom"
        arrow
        title={CheckValueLocale("new_question", "", {}, intl)}
      >
        <Box>
          <LucButton
            className="add-question-btn"
            variant="flat"
            size="small"
            onClick={handleClick}
            minWidth={36}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </LucButton>
        </Box>
      </Tooltip>
      <Menu
        id="survey-control-section-long-button"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={questionsAnchorEl}
        open={questionsOpen}
        onClose={handleClose}
      >
        <NestedMenuItem
          label={CheckValueLocale("contact_info", "", {}, intl)}
          parentMenuOpen={questionsOpen}
          style={{ padding: "7px 8px" }}
        >
          {dropdownOptions?.contactInfoOptions?.map((option, i) => (
            <MenuItem
              key={i}
              onClick={(e) => handleAddQuestion(e, 0, option)}
              id={`survey-builder-contact-info-${option ?? "option"}`}
            >
              {CheckValueLocale(option, "", {}, intl)}
            </MenuItem>
          ))}
        </NestedMenuItem>
        {dropdownOptions?.mainOptions?.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(e) => handleAddQuestion(e, 0, option)}
            id={`survey-builder-options-${option ?? "option"}`}
          >
            {CheckValueLocale(option, "", {}, intl)}
          </MenuItem>
        ))}
        {dropdownOptions?.NPSquestion?.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(e) => handleAddQuestion(e, 0, option)}
            id={`survey-builder-nps-mark-${option ?? "option"}`}
          >
            {CheckValueLocale("nps_mark", "", {}, intl)}
          </MenuItem>
        ))}
        {dropdownOptions?.scaleQuestion?.map((option, index) => (
          <MenuItem
            key={option}
            onClick={(e) => handleAddQuestion(e, 0, option)}
            id={`survey-builder-nps-mark-${option ?? "option"}`}
          >
            {CheckValueLocale("scale", "", {}, intl)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default ContentAddBtn;
