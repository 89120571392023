import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import "./selectFieldDropdown.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const defaultRenderItem = (
  option,
  selected,
  multiple,
  intl,
  useLocales = true,
) => {
  return (
    <>
      {multiple ? <Checkbox checked={selected} /> : null}
      <Typography
        component="span"
        className="select-dropdown-field-controll-list-label"
      >
        {useLocales
          ? CheckValueLocale(option?.name, "", {}, intl)
          : option?.name}
      </Typography>
    </>
  );
};

export const SelectFieldDropdown = ({
  label,
  value,
  onChange,
  options,
  multiple,
  renderItem = defaultRenderItem,
  emptyListMessage,
  isLoading,
  useLocales = true,
  listItemClassName,
  listMenuClassName,
  rootClassName,
  menuProps = MenuProps,
  ...props
}) => {
  const intl = useIntl();

  return (
    <FormControl
      className={classNames("select-dropdown-controll", props?.className)}
      size="small"
    >
      <InputLabel id="select-small-label">{label}</InputLabel>
      <Select
        id="select-small-label"
        className="select-with-shrink-label"
        value={value}
        onChange={onChange}
        input={
          <OutlinedInput
            label={label}
            className="input-with-shrink-label"
            size="small"
          />
        }
        multiple={multiple}
        // add className a to the menu list
        MenuProps={{
          ...menuProps,
          classes: {
            root: rootClassName,
            list: classNames(
              "select-dropdown-field-controll-list",
              listMenuClassName,
            ),
          },
        }}
        {...props}
      >
        {emptyListMessage && !options?.length ? (
          isLoading ? (
            <MenuItem
              value=""
              disabled
              className="user-tabs-select-dropdown-empty-message empty-loading"
            >
              {CheckValueLocale("dropdown_loading", "", {}, intl)}
            </MenuItem>
          ) : (
            <MenuItem
              value=""
              disabled
              className="user-tabs-select-dropdown-empty-message"
            >
              {CheckValueLocale(emptyListMessage, "", {}, intl)}
            </MenuItem>
          )
        ) : null}
        {options?.map((option) => {
          const selected = multiple ? value?.includes(option?.id) : false;
          return (
            <MenuItem
              value={option?.id}
              key={option?.id}
              className={classNames(
                "select-dropdown-field-controll-item",
                listItemClassName,
              )}
            >
              {renderItem(option, selected, multiple, intl, useLocales)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
