import { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import CircularLoading from "components/circularProgress";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import MonitorsController from "services/controllers/monitorsController";
import GeneralSection from "./components/generalSection";
import InfoSection from "./components/infoSection";
import MonitorSettingsHeader from "./components/monitorSettingsHeader";
import SaveChangesFooter from "./components/saveChangesFooter";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { CheckValueLocale, isEmptyArray, isEmptyObj } from "utils/helpers";
import MonitorTypeData from "./monitorTypeData";
import SharedFunctions from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctions";
import CommonFuntions from "../createMonitor/createCommonFunctions/createMonitorCommonFunctions";
import TopicsFuntions from "../MonitorsShared/SharedComponent/manualTopics/components/topicsFunctions";
import { getKeywordsLength } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordsLength";

import Topic from "../MonitorsShared/SharedComponent/manualTopics/index";
import HasInactiveHashtags from "./components/hasInactiveHashtags";
import AddAutoSummaryWidget from "../MonitorsShared/SharedComponent/AddAutoSummaryWidget/addAutoSummaryWidget";

const MonitorSettings = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    facebookPages,
    setFacebookPages,
    getFacebookPages,
    isLoadingSelectList,
  } = CommonFuntions();

  const {
    handleTopicsData,
    handleMonitorTopicsUsage,
    manualTopicStats,
    disabledManualTopic,
  } = TopicsFuntions();

  /*______________________________________________________________*/
  //to disable the save button if the required data is empty
  const [disableSave, setDisableSave] = useState(false);
  /*______________________________________________________________*/
  //get All used monitor details from url link
  const urlParams = useParams();
  const monitorId = urlParams.monitorId;
  /*______________________________________________________________*/
  const [monitorDetails, setMonitorDetails] = useState([]);
  const [monitorAutoSummaryWidget, setMonitorAutoSummaryWidget] = useState({
    auto_summary_widget: false,
    updated: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingIgHashtags, setIsLoadingIgHashtags] = useState(true);
  const [snackBarData, setSnackBarData] = useState({
    severity: "success",
    message: "",
  });
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [updatedParams, setUpdatedParams] = useState({});
  const [monitorNameError, setMonitorNameError] = useState("");
  const [nbExeedLimit, setNBexceedLimit] = useState(false);
  // #################### Public Account / Managed Account Type Error ##################################
  const [monitorErrors, setMonitorErrors] = useState({
    // General Errors in all sources
    monitorName: "",
    monitorDescription: "",
    // Public / Managed Account Errors
    userName: "",
    pageName: "",
    cstCareAccount: "",
    spamKeywords: "",
    // Keyword Errors
    tw_keywords: "",
    tw_spam_keywords: "",
    tw_include_users: "",
    tw_exclude_users: "",
    fb_keywords: "",
    fb_spam_keywords: "",
    ig_hashtags: "",
    ig_spam_hashtags: "",
    nb_keywords: "",
    nb_spam_keywords: "",
    nb_title: "",
    nb_exclude_sources: "",
  });
  const [hasInactiveHashtags, setHasInactiveHashtags] = useState(false);
  const {
    keywords,
    handleKeywords,
    setKeywords,
    answer,
    collectTweets,
    setCollecttweets,
    spamAnswer,
    ignoreTweets,
    setIgnoretweets,
    isVarifiedAccounts,
    setIsVarifiedAccounts,
    ignoretweetsFlag,
    setIgnoreTweetsFlag,
    sQuestion,
    setSQuestion,
    handleDelete,
    handleValidation,
    handleData,
    newsBlogCount,
    wordCountNews,
    wordCountNewsSpamKeyword,
    wordCountFacebook,
    wordCountInstagram,
    language,
    setLanguage,
    countries,
    setCountries,
    majorSource,
    setMajorSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    nbExcludedSource,
    setNbExcludedSource,
    setSelectedSource,
    selectedSource,
    titleSearchKeyword,
    setTitleSearchKeyword,
    setTitleSearchKeywordError,
    setNbExcludedSourceError,
    setWordCountFacebook,
    setTwitterWordsCounter,
    setWordCountInstagram,
    setWordCountNews,
    setWordCountNewsSpamKeyword,
    twitterCountries,
    setTwitterCountries,
    twitterLanguages,
    setTiwtterLanguages,
    twitterWordsCounter,
    selectedFacebookPages,
    setSelectedFacebookPages,
    monitorInstagramOldHashtags,
    setMonitorInstagramOldHashtags,
    monitorInstagramSettingHashtags,
    setMonitorInstagramSettingHashtags,
  } = SharedFunctions({
    selectedMonitorType: monitorDetails?.attributes?.monitor_type_name,
    isTwitterOpen:
      monitorDetails?.attributes?.data_sources?.includes("TWITTER"),
  });

  /*______________________________________________________________*/

  useEffect(() => {
    getMonitorInfo();
    handleMonitorTopicsUsage();
  }, []);

  /*______________________________________________________________*/

  const getMonitorInfo = () => {
    MonitorsController.getMonitorInfo(
      monitorId,
      window?.localStorage?.sm_id,
    ).then((data) => {
      setMonitorDetails(data?.data);
      setMonitorAutoSummaryWidget({
        auto_summary_widget: data?.data?.attributes?.auto_summary_widget,
        updated: false,
      });
      if (data?.data?.attributes?.monitor_type_name === "KEYWORD") {
        //this function is fired in keywords monitors to get previously saved keywords,spams,hashytags...ets
        getKeywordsMonitorsData(data?.data);
      }
      if (
        data?.data?.attributes?.monitor_type_name === "ACCOUNT_ANALYSIS" &&
        data?.data?.attributes?.data_sources[0] === "TWITTER"
      ) {
        getAccountAnalysisSpams(data?.data);
      }
      !isEmptyArray(data?.data?.attributes?.topics) &&
        data?.data?.attributes?.topics &&
        setMonitorTopics(data?.data?.attributes?.topics);
      setIsLoading(false);
    });
  };
  //___________________________________________________________________________
  const getAccountAnalysisSpams = (monitorDetails) => {
    let twitterTotallCount = 0;
    if (
      monitorDetails?.attributes?.spam_keywords?.TWITTER &&
      !isEmptyArray(monitorDetails?.attributes?.spam_keywords?.TWITTER)
    ) {
      //twitter spam keywords
      Object.values(keywords[0])[0][1].spam_keywords =
        monitorDetails.attributes.spam_keywords?.TWITTER;
      twitterTotallCount += getKeywordsLength(
        monitorDetails?.attributes?.spam_keywords?.TWITTER,
      );
    }
    if (monitorDetails?.attributes?.excluded_accounts) {
      const newListIgnoreTweet =
        monitorDetails?.attributes?.excluded_accounts?.split(",");
      Object.values(keywords[0])[0][3].ignore_tweets = [...newListIgnoreTweet];
      twitterTotallCount += newListIgnoreTweet?.length;
    }
    // those can be strings e.g: "*", or array of selected options.
    const countries =
      monitorDetails?.attributes?.tw_countries?.map?.((item) => item?.id) || [];
    const languages =
      monitorDetails?.attributes?.tw_languages?.map?.((item) => item?.id) || [];
    if (countries?.length) {
      twitterTotallCount += countries.length;
      setTwitterCountries(countries);
    }
    if (languages?.length) {
      twitterTotallCount += languages.length;
      setTiwtterLanguages(languages);
    }
    setTwitterWordsCounter(twitterTotallCount);
  };
  //___________________________________________________________________________

  const getKeywordsMonitorsData = (monitorDetails) => {
    //Twitter___________________________________________________________________________
    let twitterTotallCount = 0;
    // those can be strings e.g: "*", or array of selected options.
    const countries =
      monitorDetails?.attributes?.tw_countries?.map?.((item) => item?.id) || [];
    const languages =
      monitorDetails?.attributes?.tw_languages?.map?.((item) => item?.id) || [];

    if (
      monitorDetails?.attributes?.keywords?.TWITTER &&
      !isEmptyArray(monitorDetails?.attributes?.keywords?.TWITTER)
    ) {
      //twitter keywords
      Object.values(keywords[0])[0][0].keywords =
        monitorDetails?.attributes?.keywords?.TWITTER;
      setIsVarifiedAccounts(
        monitorDetails?.attributes?.is_varified_accounts || false,
      );
      setIgnoreTweetsFlag(monitorDetails?.attributes?.ignore_retweets || false);
    }
    if (
      monitorDetails?.attributes?.spam_keywords?.TWITTER &&
      !isEmptyArray(monitorDetails?.attributes?.spam_keywords?.TWITTER)
    ) {
      const twitterSpamKeywords = getKeywordsLength(
        monitorDetails?.attributes?.spam_keywords?.TWITTER,
      );
      twitterTotallCount += twitterSpamKeywords;
      //twitter spam keywords
      Object.values(keywords[0])[0][1].spam_keywords =
        monitorDetails?.attributes?.spam_keywords?.TWITTER;
    }
    if (
      monitorDetails?.attributes?.included_twitter_accounts &&
      !isEmptyArray(monitorDetails?.attributes?.included_twitter_accounts)
    ) {
      const twitterIncludedAccounts = getKeywordsLength(
        monitorDetails?.attributes?.included_twitter_accounts,
      );
      twitterTotallCount += twitterIncludedAccounts;
      //twitter included_twitter_accounts
      setCollecttweets(monitorDetails?.attributes?.included_twitter_accounts);
      Object.values(keywords[0])[0][2].collect_tweets =
        monitorDetails?.attributes?.included_twitter_accounts;
    }
    if (
      monitorDetails?.attributes?.excluded_twitter_accounts &&
      monitorDetails?.attributes?.excluded_twitter_accounts !== ""
    ) {
      const twitterExcludedAccounts = getKeywordsLength(
        monitorDetails?.attributes?.excluded_twitter_accounts,
      );
      twitterTotallCount += twitterExcludedAccounts;
      //twitter excluded_twitter_accounts
      setIgnoretweets(monitorDetails?.attributes?.excluded_twitter_accounts);
      Object.values(keywords[0])[0][3].ignore_tweets =
        monitorDetails?.attributes?.excluded_twitter_accounts;
    }
    if (countries?.length) {
      twitterTotallCount += countries.length;
      setTwitterCountries(countries);
    }
    if (languages?.length) {
      twitterTotallCount += languages.length;
      setTiwtterLanguages(languages);
    }
    setTwitterWordsCounter(twitterTotallCount);
    //Facebook___________________________________________________________________________
    if (
      monitorDetails?.attributes?.keywords?.FACEBOOK &&
      !isEmptyArray(monitorDetails?.attributes?.keywords?.FACEBOOK)
    ) {
      setWordCountFacebook(
        getKeywordsLength(monitorDetails?.attributes?.keywords?.FACEBOOK),
      ); //set number of pre-used keywords for FB
      Object.values(keywords[2])[0][0].keywords =
        monitorDetails?.attributes?.keywords?.FACEBOOK;
    }
    if (!isEmptyArray(monitorDetails?.attributes?.spam_keywords?.FACEBOOK)) {
      Object.values(keywords[2])[0][1].spam_keywords =
        monitorDetails?.attributes?.spam_keywords.FACEBOOK;
    }
    if (monitorDetails.attributes?.facebook_keywords_pages?.length) {
      setSelectedFacebookPages(
        monitorDetails.attributes?.facebook_keywords_pages,
      );
      setFacebookPages(monitorDetails.attributes?.facebook_keywords_pages);
    }
    //Instagram___________________________________________________________________________
    if (
      monitorDetails?.attributes?.keywords?.INSTAGRAM &&
      !isEmptyArray(monitorDetails?.attributes?.keywords?.INSTAGRAM)
    ) {
      Object.values(keywords[1])[0][0].hashtags =
        monitorDetails?.attributes?.keywords?.INSTAGRAM;
      setMonitorInstagramSettingHashtags(
        monitorDetails?.attributes?.keywords?.INSTAGRAM?.map((hashtag) =>
          hashtag?.replace("#", ""),
        ) || [],
      );
    }
    if (!isEmptyArray(monitorDetails?.attributes?.spam_keywords?.INSTAGRAM)) {
      Object.values(keywords[1])[0][1].spam_hashtags =
        monitorDetails?.attributes?.spam_keywords?.INSTAGRAM;
    }
    //News And Blogs_______________________________________________________________________
    if (
      monitorDetails.attributes?.keywords?.TALKWALKER &&
      !isEmptyArray(monitorDetails?.attributes?.keywords?.TALKWALKER)
    ) {
      Object.values(keywords[3])[0][0].keywords =
        monitorDetails?.attributes?.keywords?.TALKWALKER;
      setWordCountNews(
        getKeywordsLength(monitorDetails?.attributes?.keywords?.TALKWALKER),
      );
    }

    if (
      monitorDetails?.attributes?.spam_keywords?.TALKWALKER &&
      !isEmptyArray(monitorDetails?.attributes?.spam_keywords?.TALKWALKER)
    ) {
      Object.values(keywords[3])[0][1].spam_keywords =
        monitorDetails?.attributes?.spam_keywords?.TALKWALKER;
      setWordCountNewsSpamKeyword(
        getKeywordsLength(
          monitorDetails?.attributes?.spam_keywords?.TALKWALKER,
        ),
      );
    }
    if (
      monitorDetails?.attributes?.nb_title_search_keywords &&
      !isEmptyArray(monitorDetails?.attributes?.nb_title_search_keywords)
    ) {
      setTitleSearchKeyword(
        monitorDetails?.attributes?.nb_title_search_keywords,
      );
    }
    // ________________________________________________________
    var nbCountries = [];
    if (
      monitorDetails?.attributes?.nb_countries &&
      !isEmptyArray(monitorDetails?.attributes?.nb_countries) &&
      monitorDetails?.attributes?.nb_countries !== "*"
    ) {
      monitorDetails?.attributes?.nb_countries.map((i) => {
        nbCountries.push(i.id);
      });
      setCountries(nbCountries);
    }
    // ________________________________________________________
    if (monitorDetails?.attributes?.nb_ranks)
      setNbRanks(monitorDetails?.attributes?.nb_ranks);
    // ________________________________________________________
    if (
      monitorDetails?.attributes?.nb_duplicated !== undefined &&
      typeof monitorDetails?.attributes?.nb_duplicated === "boolean"
    )
      setNbDuplicated(monitorDetails?.attributes?.nb_duplicated);
    // ________________________________________________________
    var nbLanguages = [];
    if (
      monitorDetails?.attributes?.nb_languages &&
      !isEmptyArray(monitorDetails?.attributes?.nb_languages) &&
      monitorDetails?.attributes?.nb_languages !== "*"
    ) {
      monitorDetails?.attributes?.nb_languages.map((i) => {
        nbLanguages.push(i.id);
      });
      setLanguage(nbLanguages);
    }
    // ________________________________________________________
    var nbSources = [];
    if (
      monitorDetails?.attributes?.nb_sources &&
      !isEmptyArray(monitorDetails?.attributes?.nb_sources) &&
      monitorDetails?.attributes?.nb_sources !== "*"
    ) {
      monitorDetails?.attributes?.nb_sources.map((i) => {
        nbSources.push(i.id);
      });
      setMajorSource(nbSources);
      setSelectedSource("major_sources");
    }
    if (
      monitorDetails?.attributes?.nb_excluded_sources &&
      !isEmptyArray(monitorDetails?.attributes?.nb_excluded_sources)
    ) {
      setNbExcludedSource(monitorDetails?.attributes?.nb_excluded_sources);
    }
  };
  /*______________________________________________________________*/
  //this function tests saveAndCompute or SaveOnly +set product_id in all casses + set snackbar details
  const handleSaveSettings = (saveAndCompute) => {
    const { twitterCountries, twitterLanguages, ...rest } = updatedParams;
    const params = rest;
    if (twitterCountries) {
      params.tw_countries = twitterCountries?.join(",");
    }
    if (twitterLanguages) {
      params.tw_languages = twitterLanguages?.join(",");
    }
    if (saveAndCompute) {
      setUpdatedParams({
        ...params,
        apply_old_tweets: true,
        product_id: window.localStorage.sm_id,
      });
    } else {
      setUpdatedParams({
        ...params,
        product_id: window.localStorage.sm_id,
      });
      setSnackBarData({
        severity: "success",
        message: CheckValueLocale("save_monitor_settings", "", {}, intl),
      });
    }
  };
  /*______________________________________________________________*/
  let showTopics =
    monitorDetails?.attributes?.monitor_type_name === "ACCOUNT_ANALYSIS" &&
    monitorDetails?.attributes?.data_sources[0] === "INSTAGRAM";

  let showAutoSummary =
    monitorDetails?.auto_summary_package &&
    !(
      monitorDetails?.attributes?.monitor_type_name === "ACCOUNT_ANALYSIS" &&
      monitorDetails?.attributes?.data_sources?.includes("INSTAGRAM")
    );

  useEffect(() => {
    //if product_id is added to updatedParams so Edit Monitor call is fired
    if (updatedParams?.product_id) {
      handleEditMonitorInfo(updatedParams);
    }
  }, [updatedParams]);
  /*______________________________________________________________*/
  const handleEditMonitorInfo = (updatedParams) => {
    updatedParams.customer_care_accounts = updatedParams
      ?.customer_care_accounts?.[0]?.length
      ? updatedParams?.customer_care_accounts
      : updatedParams?.customer_care_accounts?.[0] == ""
        ? []
        : monitorDetails?.attributes?.customer_care_accounts;

    MonitorsController.editMonitorInfo(monitorId, updatedParams).then(
      (data) => {
        if (data?.data?.status === 204 || data?.data?.status === 202) {
          if (updatedParams?.apply_old_tweets) {
            window.localStorage.setItem("monitorRecomputed", true);
            history.push("/social/monitor_list");
          } else {
            getMonitorInfo();
            getHashtagsLimit();
            setWordCountInstagram(0);
          }
          setShowSaveModal(false); //close save modal
          setUpdatedParams({}); //empty updated params after saving
          setShowSnackBar(true);
          setSnackBarData({
            severity: "success",
            message: CheckValueLocale(
              "your_changes_saved_successfully",
              "",
              {},
              intl,
            ),
          });
        } else {
          setUpdatedParams({});
          setShowSaveModal(false);
          setShowSnackBar(true);
          setSnackBarData({
            severity: "error",
            message: CheckValueLocale(
              data?.errorMsg?.response?.data?.exception?.message ||
                "something_went_wrong",
              "",
              {},
              intl,
            ),
          });
        }
      },
    );
  };

  const [monitorTwitterKeywordLimit, setMonitorTwitterKeywordLimit] =
    useState(0);
  const [monitorTwitterOtherFieldLimit, setMonitorTwitterOtherFieldLimit] =
    useState(0);
  const [monitorInstagramHashtagLimit, setMonitorInstagramHashtagLimit] =
    useState(0);
  const [monitorFaceBookKeywordLimit, setMonitorFaceBookKeywordLimit] =
    useState(0);
  const [monitorFaceBookOtherFieldLimit, setMonitorFaceBookOtherFieldLimit] =
    useState(0);

  const getKeywordsLimit = () => {
    MonitorsController?.getKeywordsLimit()?.then((res) => {
      if (res?.data) {
        const twitterlimits = res?.data?.find(
          (ob) => ob?.data_source_name === "TWITTER",
        );
        setMonitorTwitterKeywordLimit(twitterlimits?.keywords || 0);
        setMonitorTwitterOtherFieldLimit(twitterlimits?.keywords_filters || 0);

        const FaceBookLimits = res?.data?.find(
          (ob) => ob?.data_source_name === "FACEBOOK",
        );
        setMonitorFaceBookKeywordLimit(FaceBookLimits?.keywords || 0);
        setMonitorFaceBookOtherFieldLimit(
          FaceBookLimits?.keywords_filters || 0,
        );
      }
    });
  };
  const getHashtagsLimit = () => {
    let queryData = {
      product_id: window?.localStorage?.sm_id,
      monitor_id: monitorId,
    };
    MonitorsController?.getHashtagsLimit(queryData)?.then((res) => {
      if (res?.data) {
        setMonitorInstagramHashtagLimit(
          res?.data?.maximum_hashtags - res?.data?.used_hashtags || 0,
        );
        setMonitorInstagramOldHashtags(res?.data?.old_hashtags || []);
        setHasInactiveHashtags(res?.data?.has_inactive_hashtags || false);
      }
      setIsLoadingIgHashtags(false);
    });
  };

  useEffect(() => {
    getKeywordsLimit();
    getHashtagsLimit();
  }, []);

  useEffect(() => {
    if (monitorAutoSummaryWidget?.updated) {
      setUpdatedParams({
        ...updatedParams,
        auto_summary_widget: monitorAutoSummaryWidget?.auto_summary_widget,
      });
    }
  }, [monitorAutoSummaryWidget]);

  const limitCount =
    monitorDetails?.attributes?.monitor_type_name === "KEYWORD"
      ? monitorTwitterOtherFieldLimit
      : 100;

  return (
    <Container className="settings-container">
      {isLoading && isLoadingIgHashtags ? (
        <CircularLoading />
      ) : (
        <>
          <MonitorSettingsHeader monitorDetails={monitorDetails} />
          {hasInactiveHashtags &&
          monitorDetails?.attributes?.data_sources?.includes("INSTAGRAM") ? (
            <HasInactiveHashtags />
          ) : null}
          <InfoSection
            monitorDetails={monitorDetails}
            setShowSnackBar={setShowSnackBar}
            setSnackBarData={setSnackBarData}
          />
          <GeneralSection
            monitorDetails={monitorDetails}
            updatedParams={updatedParams}
            setUpdatedParams={setUpdatedParams}
            monitorErrors={monitorErrors}
            setMonitorErrors={setMonitorErrors}
          />
          <MonitorTypeData
            monitorDetails={monitorDetails}
            setUpdatedParams={setUpdatedParams}
            updatedParams={updatedParams}
            keywords={keywords}
            handleKeywords={handleKeywords}
            setKeywords={setKeywords}
            answer={answer}
            spamAnswer={spamAnswer}
            sQuestion={sQuestion}
            setSQuestion={setSQuestion}
            handleDelete={handleDelete}
            handleValidation={handleValidation}
            handleData={handleData}
            wordCountFacebook={wordCountFacebook}
            wordCountInstagram={wordCountInstagram}
            titleSearchKeyword={titleSearchKeyword}
            collectTweets={collectTweets}
            ignoreTweets={ignoreTweets}
            isVarifiedAccounts={isVarifiedAccounts}
            setIsVarifiedAccounts={setIsVarifiedAccounts}
            ignoretweetsFlag={ignoretweetsFlag}
            setIgnoreTweetsFlag={setIgnoreTweetsFlag}
            wordCountNewsSpamKeyword={wordCountNewsSpamKeyword}
            nbExcludedSource={nbExcludedSource}
            setLanguage={setLanguage}
            language={language}
            setCountries={setCountries}
            countries={countries}
            setMajorSource={setMajorSource}
            majorSource={majorSource}
            nbRanks={nbRanks}
            setNbRanks={setNbRanks}
            nbDuplicated={nbDuplicated}
            setNbDuplicated={setNbDuplicated}
            wordCountNews={wordCountNews}
            setSelectedSource={setSelectedSource}
            selectedSource={selectedSource}
            setNbExcludedSource={setNbExcludedSource}
            setTitleSearchKeyword={setTitleSearchKeyword}
            setTitleSearchKeywordError={setTitleSearchKeywordError}
            setNbExcludedSourceError={setNbExcludedSourceError}
            newsBlogCount={newsBlogCount}
            monitorErrors={monitorErrors}
            setMonitorErrors={setMonitorErrors}
            setNBexceedLimit={setNBexceedLimit}
            twitterCountries={twitterCountries}
            setTwitterCountries={setTwitterCountries}
            twitterLanguages={twitterLanguages}
            setTiwtterLanguages={setTiwtterLanguages}
            twitterWordsCounter={twitterWordsCounter}
            setTwitterWordsCounter={setTwitterWordsCounter}
            twitterLanguagesAndCountries={twitterLanguagesAndCountries}
            getTwitterLanguagesAndCountries={getTwitterLanguagesAndCountries}
            selectedFacebookPages={selectedFacebookPages}
            setSelectedFacebookPages={setSelectedFacebookPages}
            facebookPages={facebookPages}
            setFacebookPages={setFacebookPages}
            getFacebookPages={getFacebookPages}
            isLoadingSelectList={isLoadingSelectList}
            setDisableSave={setDisableSave}
            monitorTwitterOtherFieldLimit={monitorTwitterOtherFieldLimit}
            monitorTwitterKeywordLimit={monitorTwitterKeywordLimit}
            monitorInstagramHashtagLimit={monitorInstagramHashtagLimit}
            monitorInstagramOldHashtags={monitorInstagramOldHashtags}
            monitorInstagramSettingHashtags={monitorInstagramSettingHashtags}
            monitorFaceBookOtherFieldLimit={monitorFaceBookOtherFieldLimit}
            monitorFaceBookKeywordLimit={monitorFaceBookKeywordLimit}
          />

          {!showTopics && (
            <Box>
              <Topic
                monitorTopics={monitorTopics}
                setMonitorTopics={setMonitorTopics}
                errorKeywordsMsg={errorKeywordsMsg}
                setErrorKewyordsMsg={setErrorKewyordsMsg}
                errorExKeywordsMsg={errorExKeywordsMsg}
                setExErrorKewyordsMsg={setExErrorKewyordsMsg}
                displayNameMsgError={displayNameMsgError}
                setDisplayNameMsgError={setDisplayNameMsgError}
                updatedParams={updatedParams}
                setUpdatedParams={setUpdatedParams}
                disabledManualTopic={disabledManualTopic}
                manualTopicStats={manualTopicStats}
                isMonitorSetting
                monitorSettings
              />
            </Box>
          )}
          {showAutoSummary ? (
            <AddAutoSummaryWidget
              setWidgetData={setMonitorAutoSummaryWidget}
              widgetData={monitorAutoSummaryWidget}
              summaryTokens={monitorDetails?.attributes?.luci_summary_tokens}
              monitorSettings
            />
          ) : null}
          {!isEmptyObj(updatedParams) && ( //this section is hidden unless updatedParams has new data inserted by user
            <SaveChangesFooter
              monitorDetails={monitorDetails}
              handleSaveSettings={handleSaveSettings}
              setShowSaveModal={setShowSaveModal}
              updatedParams={updatedParams}
              setUpdatedParams={setUpdatedParams}
              errorKeywordsMsg={errorKeywordsMsg}
              errorExKeywordsMsg={errorExKeywordsMsg}
              displayNameMsgError={displayNameMsgError}
              handleTopicsData={handleTopicsData}
              monitorTopics={monitorTopics}
              monitorErrors={monitorErrors}
              nbExeedLimit={nbExeedLimit}
              twitterWordsExceedLimit={twitterWordsCounter > limitCount}
              selectedSource={selectedSource}
              majorSource={majorSource}
              disableSave={disableSave}
            />
          )}
        </>
      )}

      {/* ______________________________________________________________*/}

      {showSaveModal && (
        <PopupModal
          title={CheckValueLocale("save_changes", "", {}, intl)}
          body={CheckValueLocale("save_monitor_settings", "", {}, intl)}
          leftBtn={CheckValueLocale("recompute_and_save", "", {}, intl)}
          rightBtn={CheckValueLocale("save_only", "", {}, intl)}
          open={() => setShowSaveModal(true)} //open Modal
          close={() => setShowSaveModal(false)} //close Modal
          accept={handleSaveSettings} //save only option
          acceptOtherOption={() => handleSaveSettings(true)} //saveAndCompute option
        />
      )}
      {/* ______________________________________________________________*/}

      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          severity={snackBarData.severity}
          message={snackBarData.message}
          handleClose={() => {
            setShowSnackBar(false);
          }}
          alertStyle={
            snackBarData.message ===
            CheckValueLocale("max_numbers_of_Topics_company", "", {}, intl)
              ? { width: "60%" }
              : { width: "100%" }
          }
        />
      )}
    </Container>
  );
};

export default MonitorSettings;
