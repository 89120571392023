import { useIntl } from "react-intl";
import { Box, Button } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { Add } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import Filter from "shared/general-filter/filter";
import "./exploreQuickSearchHeader.scss";

const ExploreQuickSearchHeader = (props) => {
  const {
    handleCreateMonitor,
    keywords,
    selectedDatasource,
    handleApplyFilter,
    assignActiveFilters,
    setAssignActiveFilters,
    handleClearFilterParams,
    getFiltersFileds,
    filterFields,
    filterParams,
    setFilterParams,
    allActiveFilters,
    checkDataSourceSelected,
    isFilteredFieldsLoaded,
  } = props;

  const filtersData = {
    assignActiveFilters,
    setAssignActiveFilters,
    handleClearFilterParams,
    getFiltersFileds,
    filterFields,
    filterParams,
    setFilterParams,
    allActiveFilters,
    checkDataSourceSelected,
    isFilteredFieldsLoaded,
  };

  const intl = useIntl();

  return (
    <Box className="explore-quick-search-header">
      <Box className="quick-search-left-head">
        {CheckValueLocale("quick_search", "", {}, intl)}
      </Box>
      <Box className="quick-search-right-head">
        <Button
          startIcon={<Add />}
          onClick={handleCreateMonitor}
          className="explore-create-monitor"
          id="channel-analytics-insights-add-new-channel-btn"
          disabled={!keywords?.length || !selectedDatasource?.length}
        >
          {CheckValueLocale("explore_create_monitor", "", {}, intl)}
        </Button>

        <Filter
          applyFilter={handleApplyFilter}
          isAudiencePage
          isQuickSearch
          {...filtersData}
        />
      </Box>
    </Box>
  );
};

export default ExploreQuickSearchHeader;
