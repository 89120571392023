import { useState, useEffect } from "react";
import { injectIntl, useIntl } from "react-intl";
import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { checkProgressExceeded, getPercentage } from "utils/helpers";
import { commasAfterDigit } from "utils/helpers";
import "./subscription.scss";
import { CheckValueLocale } from "utils/helpers";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

const CxmCorePackage = (props) => {
  const { cxmCoreInfo, cxmCoreActive } = props;
  const intl = useIntl();

  return (
    <div id="social-listening">
      <h3 className="sub__head">
        {CheckValueLocale("cxm_package", "", {}, intl)}
      </h3>
      <Card className="cxm-package body">
        {cxmCoreActive ? (
          <Box className="cxm-package-box">
            <Card className="cxm-package-percentage">
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={10}
                p={2}
              >
                <div>
                  <Typography className="head-social-list">
                    {CheckValueLocale("package_name", "", {}, intl)}
                  </Typography>
                  <Typography>{cxmCoreInfo?.attributes?.name}</Typography>
                </div>
              </Stack>
            </Card>
            <Card className="cxm-package-percentage">
              <CardContent>
                <Grid container lg={12} md={12} sm={12} spacing={5}>
                  <Grid item lg={12} md={12} sm={12}>
                    <Typography className="head-social-list">
                      {CheckValueLocale("number_users", "", {}, intl)}
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mb={1}
                    >
                      <Box className="box-number-of">
                        {commasAfterDigit(
                          cxmCoreInfo?.attributes?.package_used_users,
                        )}{" "}
                        {CheckValueLocale("of", "", {}, intl)}{" "}
                        {commasAfterDigit(cxmCoreInfo?.attributes?.max_users)}
                      </Box>
                      <div>{`${
                        cxmCoreInfo?.attributes?.package_used_users ==
                        cxmCoreInfo?.attributes?.max_users
                          ? "100"
                          : getPercentage(
                              cxmCoreInfo?.attributes?.package_used_users,
                              cxmCoreInfo?.attributes?.max_users,
                            )
                      }%`}</div>
                    </Stack>
                    <BorderLinearProgress
                      id="linear-progress-color"
                      variant="determinate"
                      className={
                        cxmCoreInfo?.attributes?.package_used_users >=
                        cxmCoreInfo?.attributes?.max_users
                          ? "red-color"
                          : ""
                      }
                      value={
                        checkProgressExceeded(
                          cxmCoreInfo?.attributes?.package_used_users,
                          cxmCoreInfo?.attributes?.max_users,
                        ) ??
                        getPercentage(
                          cxmCoreInfo?.attributes?.package_used_users,
                          cxmCoreInfo?.attributes?.max_users,
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
};
export default injectIntl(CxmCorePackage);
