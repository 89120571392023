import { useState } from "react";
import LuciController from "services/controllers/luciController";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { isEmptyObj } from "utils/helpers";
import SummaryWidgetController from "services/controllers/summaryWidgetController";
import moment from "moment";

const SummaryFunction = () => {
  const [snackBarStatus, setSnackBarStatus] = useState({
    success: null,
    snackBarHeading: "",
  });
  const [lastQuestionAnswerLoading, setLastQuestionAnswerLoading] =
    useState(true);
  const [summaryListAnswerLoading, setSummaryListAnswerLoading] =
    useState(false);

  const [isUserThinking, setIsUserThinking] = useState(false);
  const [luciMenuId, setMenuId] = useState("");
  const [currentToken, setCurrentTokens] = useState("");
  const [staticQuestions, setStaticQuestions] = useState([]);
  const [summaryLastQuestion, setSummaryLastQuestion] = useState(null);
  const [summaryLastAnswer, setSummaryLastAnswer] = useState([]);
  const [openAppliedFiltersModal, setOpenAppliedFiltersModal] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  const { changeFilterParamsToString } = CommonFunctions();

  const handleAppliedFiltersModal = () =>
    setOpenAppliedFiltersModal(!openAppliedFiltersModal);

  const applySummaryStaticQuestions = (summaryStaticQuestions) => {
    setStaticQuestions(summaryStaticQuestions?.questions);
    setCurrentTokens(summaryStaticQuestions?.current_token);
    setMenuId(summaryStaticQuestions?.menu_id);
    setIsUserThinking(summaryStaticQuestions?.user_is_thinking);
  };

  const getLastQuestionAnswer = (pageParams) => {
    const queryData = {
      section_name: pageParams?.sectionName,
      product_id: pageParams?.productId,
      luci_feature: pageParams?.luciFeature,
      data_source_id: pageParams?.dataSourceId,
      monitor_id: pageParams?.monitorId,
    };
    setLastQuestionAnswerLoading(true);
    SummaryWidgetController.getLastQuestionAnswer(queryData).then((result) => {
      if (result?.data) {
        setSummaryLastQuestion(result?.data?.question);
        setSummaryLastAnswer(getAnswerValue(result?.data?.answer?.answer));
      }
      setLastQuestionAnswerLoading(false);
    });
  };

  const createLuciQuestion = (
    pageParams,
    getSummaryStaticQuestions,
    isCAPage,
  ) => {
    const filtersValues = isCAPage
      ? pageParams?.luciFiltersCA
      : pageParams?.filterParams;
    const queryData = {
      text: summaryLastQuestion?.attributes?.text,
      luci_static_question_id: staticQuestions?.[0]?.id,
      start_date: pageParams?.startDate,
      end_date: pageParams?.endDate,
      monitor_id: pageParams?.monitorId, // only in SM Pages
      luci_menu_id: luciMenuId,
      data_source_id: pageParams?.dataSourceId,
      profile_id: pageParams?.profileId,
      survey_id: pageParams?.surveyId,
      filters:
        pageParams?.filterParams &&
        changeFilterParamsToString(filtersValues, isCAPage),
      auto_summary_widget: true,
    };

    if (queryData?.filters?.manual_topics) {
      if (queryData?.filters?.manual_topics?.length) {
        queryData.filters.manual_topics =
          pageParams?.filterParams?.manual_topics;
      } else {
        delete queryData?.filters.manual_topics;
        if (isEmptyObj(queryData?.filters)) {
          delete queryData?.filters;
        }
      }
    }
    LuciController.createLuciQuestion(queryData).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        getSummaryListAnswer(
          res?.data?.data?.question_id,
          pageParams,
          getSummaryStaticQuestions,
        );
        setRefreshCount(refreshCount + 1);
      } else {
        setSnackBarStatus({
          success: false,
          snackBarHeading: "somethings_went_wrong",
        });
      }
    });
  };
  const getSummaryListAnswer = (id, pageParams, getSummaryStaticQuestions) => {
    setSummaryListAnswerLoading(true);
    LuciController.getLuciListAnswer(id).then((result) => {
      if (result?.data?.data) {
        setSummaryLastQuestion(result?.data?.data?.question);
        setSummaryLastAnswer(
          getAnswerValue(result?.data?.data?.answer?.answer),
        );
        getSummaryStaticQuestions(pageParams?.dataSourceId);
      } else {
        setSummaryLastQuestion(null);
        setSummaryLastAnswer([]);
      }
      setSummaryListAnswerLoading(false);
    });
  };

  const postQuestionStatus = (status, pageParams) => {
    const queryData = {
      question_id: summaryLastQuestion?.attributes?.id,
      liked_status: status === "like",
    };
    SummaryWidgetController.postQuestionStatus(queryData).then((res) => {
      if (res?.status_code === 201 || res?.status_code === 200) {
        getLastQuestionAnswer(pageParams);
      } else {
        setSnackBarStatus({
          success: false,
          snackBarHeading: "somethings_went_wrong",
        });
      }
    });
  };

  const getAnswerValue = (answer) => {
    let valArr = answer?.includes("\\n")
      ? answer?.split("\\n").filter((ans) => ans !== "")
      : answer?.split("\n").filter((ans) => ans !== "");

    return valArr?.length ? valArr?.slice(1) : answer;
  };
  const getQuestionStatus = () => {
    const currentsDate = new Date();
    const createdAtDate = new Date(
      summaryLastQuestion?.attributes?.created_at * 1000,
    );
    const questionStatus =
      currentsDate.getDate() === createdAtDate.getDate() &&
      currentsDate.getMonth() === createdAtDate.getMonth() &&
      currentsDate.getFullYear() === createdAtDate.getFullYear();
    return questionStatus;
  };
  const getNextUpdateTime = () => {
    let requestDate = calcDays(
      "add",
      summaryLastQuestion?.attributes?.created_at,
      2,
    );
    return window.localStorage.lang === "en"
      ? moment(requestDate).format("D MMM YYYY - h:mm A")
      : moment(requestDate).format("D MMM YYYY")?.split(" ")[0] +
          " " +
          moment(requestDate).format("D MMM YYYY")?.split(" ")[1] +
          " " +
          moment(requestDate).format("D MMM YYYY")?.split(" ")[2] +
          " - " +
          moment(requestDate)?.format("D MMM YYYY - h:mm A")?.split(" ")[4] +
          " " +
          moment(requestDate)?.format("D MMM YYYY - h:mm A")?.split(" ")[5];
  };

  const calcDays = (status, day, days) => {
    const createdAtDate = new Date(day * 1000);
    createdAtDate.setDate(
      status === "add"
        ? createdAtDate.getDate() + parseInt(days)
        : createdAtDate.getDate() - parseInt(days),
    );
    return createdAtDate;
  };

  const getRemainingEnergy = () => currentToken >= staticQuestions?.[0]?.token;

  const localizedDateTime = (requestDate) => {
    return window.localStorage.lang === "en"
      ? moment.unix(requestDate).format("D MMM - h:mm A")
      : moment.unix(requestDate).format("D MMM")?.split(" ")[0] +
          " " +
          moment.unix(requestDate).format("D MMM")?.split(" ")[1] +
          " - " +
          moment?.unix(requestDate)?.format("D MMM - h:mm A")?.split(" ")[3] +
          " " +
          moment?.unix(requestDate)?.format("D MMM - h:mm A")?.split(" ")[4];
  };

  return {
    openAppliedFiltersModal,
    handleAppliedFiltersModal,
    applySummaryStaticQuestions,
    createLuciQuestion,
    getLastQuestionAnswer,
    lastQuestionAnswerLoading,
    summaryListAnswerLoading,
    postQuestionStatus,
    summaryLastQuestion,
    summaryLastAnswer,
    getQuestionStatus,
    getRemainingEnergy,
    getNextUpdateTime,
    calcDays,
    isUserThinking,
    snackBarStatus,
    setSnackBarStatus,
    getSummaryListAnswer,
    refreshCount,
    setRefreshCount,
    staticQuestions,
    currentToken,
    localizedDateTime,
  };
};

export default SummaryFunction;
