import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";

const handelValidation = (value, compareList, oldList) => {
  let errorMessage = "";
  value?.split(/[,]+/)?.map((keyword) => {
    if (keyword?.length > 15) errorMessage = "username_max_char";
    else if (oldList?.includes(keyword))
      errorMessage = "userNames_collect_ignore_error_exists";
    else if (compareList?.includes(keyword))
      errorMessage =
        "usernames_collect_ignore_tweets_error_exists_other_fields";
    else if (
      isPublicSpecialCharactersOrEmojis(
        keyword,
        undefined,
        undefined,
        false,
        true,
      )
    ) {
      errorMessage = "usernames_collect_ignore_tweets_error_name";
    }
  });

  return errorMessage;
};
export const handleChangeInput = (
  event,
  setString,
  keyFeild,
  listToCheckDuplication,
  setError,
  monitorData,
) => {
  const { value } = event.target;
  setString(value);
  if (value) {
    const keywordsCopy = [...monitorData?.[keyFeild]];
    const errorMessage = handelValidation(
      value,
      listToCheckDuplication ?? [],
      keywordsCopy?.length ? keywordsCopy : [],
    );

    setError((prev) => ({
      ...prev,
      [keyFeild]: errorMessage,
    }));
  } else {
    setError((prev) => ({
      ...prev,
      [keyFeild]: "",
    }));
  }
};

const handelValidationLimit = (
  value,
  keyFeild,
  listToCheckDuplication,
  monitorData,
  keywordsCount,
  limitCountKeywords,
) => {
  const listKeywords = Array.from(new Set(value?.split(",")));
  const keywordsCopy = [...monitorData?.[keyFeild]];
  const errorMessage = handelValidation(
    value,
    listToCheckDuplication ?? [],
    keywordsCopy?.length ? keywordsCopy : [],
    monitorData,
  );
  if (errorMessage) {
    return { errorMsg: errorMessage };
  }
  if (listKeywords?.length + keywordsCount > limitCountKeywords) {
    return { errorMsg: "public_channel_limit_excceds" };
  }
  return { [keyFeild]: [...listKeywords] };
};

export const handleAddKeywordsTolist = (
  eventValue,
  setString,
  keyFeild,
  listToCheckDuplication,
  setError,
  setMonitorData,
  monitorData,
  limitCountKeywords,
) => {
  if (eventValue && eventValue?.trim() !== "") {
    const keywordsCount = monitorData?.[keyFeild]?.length;
    const objData = handelValidationLimit(
      eventValue,
      keyFeild,
      listToCheckDuplication,
      monitorData,
      keywordsCount,
      limitCountKeywords,
    );
    if (!objData?.errorMsg) {
      setMonitorData((prev) => ({
        ...prev,
        [keyFeild]: [...prev?.[keyFeild], ...objData?.[keyFeild]],
      }));
      setString("");
      setError((prev) => ({
        ...prev,
        [keyFeild]: "",
      }));
    } else {
      setString(eventValue);
      setError((prev) => ({
        ...prev,
        [keyFeild]: objData?.errorMsg,
      }));
    }
  }
};
