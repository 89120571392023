import notsupported from "images/user-side-bar/not-supported.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";

const NotSupported = ({ dataSourceName }) => {
  const type = dataSourceName.toLowerCase();
  const intl = useIntl();
  const getSocialText = (type) => {
    return type + "_not_supported";
  };
  return (
    <Box className="side-bar-notsupported">
      <Box className="notsupported-icon-box">
        <img
          src={notsupported}
          alt="notsupported"
          className="notsupported-icon"
        />
      </Box>

      <Typography className="notsupported-text">
        {CheckValueLocale(getSocialText(type), "", {}, intl)}
      </Typography>
    </Box>
  );
};
export default NotSupported;
