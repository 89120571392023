import { useEffect, useState } from "react";
import PostsBody from "./postsBody";
import Header from "pages/Monitors/Components/header";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
import Luci from "pages/luci";
import { useSelector } from "react-redux";
import SummaryWidget from "shared/summaryWidget/summaryWidget";
/*---------------------------------------------------------*/

const InstagramPostsPage = (props) => {
  const {
    response,
    preLoaderTrackerDataSources,
    resetAllFields,
    changePagination,
    monitorId,
    assignActiveFilters,
    pageNumber,
    startDate,
    endDate,
    activeTab,
    dataSourceId,
    monitorData,
    monitorType,
    getSummaryStaticQuestions,
    summaryStaticQuestions,
    summaryStaticQuestionsLoading,
  } = props;

  const [openLuci, setOpenLuci] = useState(false);
  const [mainQuestionNotification, setMainQuestionNotification] = useState({
    title: "",
    body: "",
    status: "",
    questionId: "",
    sectionName: "",
    monitorId: "",
    summaryWidget: false,
  });
  const [totalPage, setTotalPage] = useState("");
  const [sortBy, setSortBy] = useState("latest_posts");
  const sortByData = ["latest_posts", "most_interactions"];
  let showLuci = window.location?.pathname?.includes("KEYWORD/INSTAGRAM/posts");

  const handleChange = (event) => {
    setSortBy(event.target.value);
    props.setFilterParams({
      ...props.filterParams,
      sort_by: event.target.value,
    });
    props.applyFilter(props.startDate, props.endDate, {
      ...props.filterParams,
      sort_by: event.target.value,
    });
  };
  useEffect(() => {
    if (props.filterParams["sort_by"] !== undefined) {
      setSortBy(props.filterParams["sort_by"]);
    } else {
      setSortBy("latest_posts");
    }
  }, [props.filterParams["sort_by"]]);

  //  Start Redux Declarations
  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );
  //  End Redux Declarations

  const showSummaryWidget =
    summaryStaticQuestions?.questions?.length &&
    monitorData?.attributes?.auto_summary_widget &&
    monitorData?.auto_summary_package;
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {props.response?.eventData !== undefined && (
        <Header
          title="total_posts"
          titleValue={totalPage}
          sortByData={sortByData}
          sortBy={sortBy}
          handleChange={handleChange}
        />
      )}
      <PostsBody
        response={response}
        changePagination={changePagination}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        resetAllFields={resetAllFields}
        pageNumber={props.pageNumber}
        setTotalPage={setTotalPage}
        totalPage={totalPage}
        {...props}
      />
      {showLuci ? (
        <Luci
          isInactive={false}
          pageParams={{
            monitorId: monitorId,
            filterParams: assignActiveFilters,
            pageNumber: pageNumber,
            startDate: reduxFilterStartDateUX,
            endDate: reduxFilterEndDateUX,
            sectionName: activeTab,
            productId: window.localStorage.sm_id,
            luciFeature: "Keywords",
            dataSourceId: dataSourceId,
            monitorName: monitorData?.attributes?.account_name,
            monitorProduct: `Luci ${monitorData?.attributes?.data_category}`,
            dataLoaded: props.checkLuciAllDataExist,
            dataEmpty: props.checkLuciDataIsEmpty,
          }}
          openLuci={openLuci}
          setOpenLuci={setOpenLuci}
          mainQuestionNotification={mainQuestionNotification}
          setMainQuestionNotification={setMainQuestionNotification}
        />
      ) : null}
    </MonitorContainer>
  );
};

export default InstagramPostsPage;
