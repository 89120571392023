import { useMutation } from "@tanstack/react-query";
import InteractionsController from "services/controllers/interactionsController";

export const useEditCategories = () => {
  return useMutation({
    mutationFn: async ({ apiVars, queryData }) => {
      await InteractionsController.editCategories(apiVars, queryData);
    },
  });
};
