import { CheckValueLocale } from "utils/helpers";
import * as Yup from "yup";

export const addChannelFormikValidationSchema = (intl) => {
  return Yup.object({
    isBot: Yup.number().required("Required"),
    clientConversationEnd: Yup.number()
      .positive(CheckValueLocale("add_only_positive_numbers", "", {}, intl))
      .required(CheckValueLocale("this_field_required", "", {}, intl)),
    agentConversationEnd: Yup.number()
      .positive(CheckValueLocale("add_only_positive_numbers", "", {}, intl))
      .required(CheckValueLocale("this_field_required", "", {}, intl)),
  });
};

export const editChannelConfigurationsFormikValidationSchema = (intl) => {
  return Yup.object({
    isBot: Yup.number().required("Required"),
    clientConversationEnd: Yup.number()
      .positive(CheckValueLocale("add_only_positive_numbers", "", {}, intl))
      .required(CheckValueLocale("this_field_required", "", {}, intl)),
    agentConversationEnd: Yup.number()
      .positive(CheckValueLocale("add_only_positive_numbers", "", {}, intl))
      .required(CheckValueLocale("this_field_required", "", {}, intl)),
  });
};
