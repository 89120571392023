import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import {
  handleXlsData,
  handleAuthorActivity,
  handleSortedCase,
} from "../../excelCommonFn";

const linkedIn_public = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var overview = [],
      interaction = [],
      sentimentAnalysis = [],
      channelAccounts = [],
      excelContentType = [],
      theme = [],
      subTheme = [],
      themesTrend = [],
      subThemesTrend = [],
      dialects = [],
      subDialects = [],
      authorActivity = [],
      topKeywords = [],
      topHashtags = [],
      topImages = [],
      topVideos = [],
      contactTrend = [],
      excelGenderDistribution = [],
      reactions = [],
      topEngagedAuthors = [],
      latestInteractions = [],
      allSheetData = [];

    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "overview":
            value?.map((i) => {
              overview.push({
                [lang.xlsx_name]: lang[i?.name],
                [lang.xlsx_value]:
                  i?.name == "net_sentiment"
                    ? lang[i?.value[0]?.name]
                    : i?.value,
              });
            });
            allSheetData.push([overview, lang.xlxs_overview]);
            break;

          case "interaction":
            value?.map((i) => {
              interaction.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([interaction, lang.interactions]);
            break;

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i.name == value?.neutral[index].name) {
                let total =
                  value?.neutral[index].value +
                  value?.positive[index].value +
                  value?.negative[index].value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.positive]:
                    total !== 0
                      ? ((value?.positive[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.negative]:
                    total !== 0
                      ? ((value?.negative[index].value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([
              sentimentAnalysis,
              lang.sentiment_overtime_legend,
            ]);
            break;

          case "channelAccounts":
            value?.map((i) => {
              let name = i?.name?.replace("@", "");
              channelAccounts.push({
                [lang.xlsx_name]: "@" + name,
                [lang.xlsx_value]: i?.value,
              });
            });
            allSheetData.push([channelAccounts, lang.channel_accounts]);
            break;

          case "themesXlsx":
            //themes
            let mainThemes = value?.theme_analysis?.main_themes_view;
            mainThemes &&
              Object.entries(mainThemes)?.forEach(([key1, value1]) => {
                let subThemes = value1?.sub_themes;
                subThemes &&
                  Object.entries(subThemes)?.forEach(([key2, value2]) => {
                    theme?.push({
                      [lang?.excel_categories]: `${lang?.[key1]} (${lang?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([theme, lang?.excel_categories]);
            //sub-themes
            let mainSubThemes = value?.theme_analysis?.sub_themes_view;
            mainSubThemes &&
              Object.entries(mainSubThemes)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes;
                mainThemes &&
                  Object.entries(mainThemes)?.forEach(([key2, value2]) => {
                    subTheme?.push({
                      [lang?.excel_themes]: `${lang?.[key1]} (${lang?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([subTheme, lang?.excel_themes]);
            //themes-trend
            let mainthemesTrend = value?.theme_trend;
            mainthemesTrend &&
              Object.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes_view;
                let rawObj = {};
                mainThemes &&
                  Object.entries(mainThemes)?.forEach(([key2, value2]) => {
                    let subThemes = value2?.sub_themes;
                    subThemes &&
                      Object.entries(subThemes)?.forEach(([key3, value3]) => {
                        rawObj = {
                          ...rawObj,
                          [`${lang?.[key2]} (${lang?.[key3]})`]: value3,
                        };
                      });
                  });
                themesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([themesTrend, lang?.excel_categories_trend]);

            //sub-themes-trend
            mainthemesTrend &&
              Object.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let subThemesTrendObj = value1?.sub_themes_view;
                let rawObj = {};
                subThemesTrendObj &&
                  Object.entries(subThemesTrendObj)?.forEach(
                    ([key2, value2]) => {
                      let mainThemes = value2?.main_themes;
                      mainThemes &&
                        Object.entries(mainThemes)?.forEach(
                          ([key3, value3]) => {
                            rawObj = {
                              ...rawObj,
                              [`${lang?.[key2]} (${lang?.[key3]})`]: value3,
                            };
                          },
                        );
                    },
                  );
                subThemesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([subThemesTrend, lang?.excel_themes_trend]);
            break;
          // //__________________________________________
          case "channelAccounts":
            value?.map((i) => {
              channelAccounts.push({
                [lang.accounts]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([channelAccounts, lang.channel_accounts]);
            break;
          //
          case "excelContentType":
            value?.map((i) => {
              excelContentType.push({
                [lang.xls_account_types]: lang[i?.name]
                  ? lang[i?.name]
                  : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([excelContentType, lang["content_type"]]);
            break;

          case "excelDialects":
            handleSortedCase(value, dialects, lang.dialect, lang.number, lang);
            allSheetData.push([dialects, lang.main_dialect_filter]);
            break;

          case "excelSubDialects":
            handleSortedCase(
              value,
              subDialects,
              lang.sub_dialects,
              lang.number,
              lang,
            );
            allSheetData.push([subDialects, lang.sub_dialects]);
            break;

          case "authorActivity":
            handleAuthorActivity(value[0], value[1], value[2], lang)?.map(
              (i) => {
                authorActivity?.push({
                  [lang.day]: i[0],
                  [lang.hour_group]: i[1],
                  [lang.no_of_activity]: i[2],
                });
              },
            );
            allSheetData.push([authorActivity, lang.contact_activity]);
            break;
          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords_linkedin]: i?.id,
                [lang.interactions]: i?.stats_count,
              });
            });
            allSheetData.push([topKeywords, lang.top_keywords]);

            break;
          case "topHashtags":
            value?.map((i) => {
              topHashtags.push({
                [lang.hashtags_linkedin]: i?.id,
                [lang.interactions]: i?.stats_count,
              });
            });
            allSheetData.push([topHashtags, lang.top_hashtags]);

            break;

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.src });
            });
            allSheetData.push([topImages, lang.insights_top_images]);

            break;
          case "topVideos":
            value?.map((i) => {
              topVideos.push({ [lang.video]: i?.src });
            });
            allSheetData.push([topVideos, lang.insights_top_videos]);

            break;

          case "contactTrend":
            value?.map((i) => {
              contactTrend.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([contactTrend, lang.contacts_trend_linkedin]);
            break;
          case "excelGenderDistribution":
            value?.map((i) => {
              excelGenderDistribution.push({
                [lang.gender]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([
              excelGenderDistribution,
              lang.genders_distribution,
            ]);
            break;
          case "reactions":
            value?.map((i) => {
              reactions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.like_reaction]: i?.value[0],
                [lang.celebrate_reaction]: i?.value[1],
                [lang.curious_reaction]: i?.value[2],
                [lang.love_reaction]: i?.value[3],
                [lang.insightful_reaction]: i?.value[4],
                [lang.support_reaction]: i?.value[5],
                [lang.total]:
                  i?.value[0] +
                  i?.value[1] +
                  i?.value[2] +
                  i?.value[3] +
                  i?.value[4] +
                  i?.value[5],
              });
            });
            allSheetData.push([reactions, lang.linkedIn_posts_reactions]);
            break;

          case "topEngagedAuthors":
            value?.map((i) => {
              topEngagedAuthors.push({
                [lang.user_author]: i?.name,
                [lang.interactions]: i?.value,
              });
            });
            allSheetData.push([topEngagedAuthors, lang.most_engaged_contacts]);
            break;
          case "latestInteractions":
            value?.map((i) => {
              latestInteractions.push({
                [lang.latest_interactions]: i?.url,
              });
            });
            allSheetData.push([
              latestInteractions,
              lang.latest_interactions_linkedin,
            ]);
            break;
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { linkedIn_public };
