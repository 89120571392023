import { useState } from "react";
import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";

import { faArrowToBottom, faEdit } from "@fortawesome/pro-solid-svg-icons";
import SnackBar from "components/snackBar";
import AudienceController from "services/controllers/audienceController";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import PopupModal from "components/popupModal.js";
import "./optionsDropDown.scss";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();
  const {
    segmentId,
    segmentNumOfProfiles,
    getListOfSegments,
    setSegment,
    segment,
  } = props;

  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });

  const [exportSegmentStats, setExportSegmentStats] = useState({
    anchorEl: null,
    openExportModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });

  const [success, setSuccess] = useState(null);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const open = Boolean(stats.anchorEl);

  const handleClick = (event) => {
    setStats({
      ...stats,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };

  const handleOpenDeleteModal = () => {
    setStats({
      openDeleteModal: true,
      title: `${CheckValueLocale("segments_delete_segment", "", {}, intl)}`,
      body: (
        <Box className="shared-dialog-body">
          <p>
            {CheckValueLocale(
              "segments_want_delete_segments",
              "",
              {
                num_users: segmentNumOfProfiles,
              },
              intl,
            )}
            <br />
            {CheckValueLocale("segments_profiles_will_stay", "", {}, intl)}
          </p>
        </Box>
      ),

      modalRightBtn: CheckValueLocale("delete", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      warning: true,
    });
  };

  const handleOpenExportSegmentModal = () => {
    setExportSegmentStats({
      openExportModal: true,
      title: `${CheckValueLocale("exporting_segments_data", "", {}, intl)}`,
      body: (
        <Box className="shared-dialog-body">
          <p>
            {CheckValueLocale(
              "number_profiles_will_be_exported",
              "",
              {
                num_users: segmentNumOfProfiles,
              },
              intl,
            )}
          </p>
        </Box>
      ),

      modalRightBtn: CheckValueLocale("export", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
    });
  };

  const handleModalEditModal = () => {
    setSegment(segment);
  };

  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
  };

  const handleCloseExportSegmentModal = () => {
    setExportSegmentStats({ openExportModal: false });
  };

  const handleAcceptDeleteModal = () => {
    setButtonLoading(true);
    AudienceController.deleteSegment(segmentId).then((data) => {
      if (data?.data?.status === 204 || data?.data?.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(
          CheckValueLocale("segments_deleted_success", "", {}, intl),
        );
        setSuccessStatus("success");
        showSnackBar("delete", "success");
        setTimeout(() => {
          getListOfSegments();
        }, 3000);
      } else {
        setSuccess(true);
        setSuccessStatus("error");
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(CheckValueLocale("wrong_msg", "", {}, intl));
        setSnackBarTitle(CheckValueLocale("failed_error", "", {}, intl));
        showSnackBar("delete", "warning");
        setTimeout(() => {
          getListOfSegments();
        }, 3000);
      }
      setButtonLoading(false);
    });
  };

  const handleExportSegmentModal = () => {
    setButtonLoading(true);
    const queryData = {
      data: {
        cdp: {
          filters: { segment: [`${segment.name}`] },
        },
      },
    };
    AudienceController.exportCDPExcelFile(queryData).then((data) => {
      if (data?.data?.status === 204 || data?.data?.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarTitle(
          CheckValueLocale(
            "segments_exported_excel_file_in_the_way",
            "",
            {},
            intl,
          ),
        );
        setSnackBarMsg(CheckValueLocale("segments_success_csv", "", {}, intl));
        setSuccessStatus("success");
        showSnackBar("delete", "success");
        setExportSegmentStats({ openExportModal: false });
        handleClose();
      } else {
        setSuccess(true);
        setSuccessStatus("error");
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(CheckValueLocale("wrong_msg", "", {}, intl));
        setSnackBarTitle(CheckValueLocale("failed_error", "", {}, intl));
        showSnackBar("delete", "warning");
        setExportSegmentStats({ openExportModal: false });
        handleClose();
      }
      setButtonLoading(false);
    });
  };

  const showSnackBar = (action, severity) => {
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      openDeleteModal: false,
      openPauseResumeModal: false,
      snackBarMsg: "",
    });
    window.scrollTo(0, 0);
    if (action === "delete" && severity === "success") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: intl.formatMessage(
          { id: "remove_pofileuser" },
          {
            name: CheckValueLocale(
              props.username ? props.username : "no_name",
              "",
              {},
              intl,
            ),
          },
        ),
      });
    } else if (action === "delete" && severity === "warning") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: CheckValueLocale("wrong_msg", "", {}, intl),
        snackBarTitle: CheckValueLocale("fail_req", "", {}, intl),
      });
    }
  };

  return (
    <Box className="menu-list">
      {(handlelUserRoles("CDP", "DESTROY_PROFILE") ||
        handlelUserRoles("CDP", "DESTROY_SEGMENT") ||
        handlelUserRoles("CDP", "EXPORT_SEGMENT") ||
        handlelUserRoles("CDP", "EDIT_SEGMENT")) && (
        <CustomizedTooltip
          id="menu-item-list-tooltip"
          title={
            <Box className="item-title-tooltip">
              {CheckValueLocale("items_ellipsis", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <IconButton
            aria-label="more"
            id="audience-manage-audience-profile-long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            onClose={handleClose}
            className={
              "menu-items menu-item-container " +
              (open ? "menu-item-active" : "")
            }
          >
            <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
          </IconButton>
        </CustomizedTooltip>
      )}

      {handlelUserRoles("CDP", "DESTROY_SEGMENT") && (
        <Menu
          id="manage-audience-menu-dropdown"
          MenuListProps={{
            "aria-labelledby": "monitor-menu-button",
          }}
          anchorEl={stats.anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: "top",
            horizontal: intl.locale == "ar" ? "left" : "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: intl.locale == "ar" ? "left" : "right",
          }}
        >
          {handlelUserRoles("CDP", "DESTROY_PROFILE") && (
            <MenuItem
              onClick={handleOpenDeleteModal}
              disableRipple
              id={`manage-audience-menu-delete-segment-${
                segmentId ?? "segment-id"
              }`}
            >
              <FontAwesomeIcon
                className="manage-audience-options-icon"
                icon={faTrash}
              />
              {CheckValueLocale("segments_delete_segment", "", {}, intl)}
            </MenuItem>
          )}
          {handlelUserRoles("CDP", "EXPORT_SEGMENT") && (
            <MenuItem
              onClick={handleOpenExportSegmentModal}
              disableRipple
              id={`manage-audience-menu-export-segment-${
                segmentId ?? "segment-id"
              }`}
            >
              <FontAwesomeIcon
                className="manage-audience-options-icon"
                icon={faArrowToBottom}
              />

              {CheckValueLocale("segments_export_segment_csv", "", {}, intl)}
            </MenuItem>
          )}
          {handlelUserRoles("CDP", "EDIT_SEGMENT") && (
            <MenuItem
              disableRipple
              onClick={handleModalEditModal}
              id={`manage-audience-menu-rename-segment-${
                segmentId ?? "segment-id"
              }`}
            >
              <FontAwesomeIcon
                className="manage-audience-options-icon"
                icon={faEdit}
              />

              {CheckValueLocale("segments_rename_segment", "", {}, intl)}
            </MenuItem>
          )}
        </Menu>
      )}

      {stats.openDeleteModal && (
        <PopupModal
          title={stats?.title}
          body={stats?.body}
          leftBtn={stats?.modalLeftBtn}
          rightBtn={stats?.modalRightBtn}
          open={stats?.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={() => handleAcceptDeleteModal(segmentId)}
          warning={stats.warning}
          minWidth="600px"
          classeName="actions-buttons-shared"
          isRightBtnLoading={buttonLoading}
          disabled={buttonLoading}
        />
      )}

      {exportSegmentStats.openExportModal && (
        <PopupModal
          title={exportSegmentStats?.title}
          body={exportSegmentStats?.body}
          leftBtn={exportSegmentStats?.modalLeftBtn}
          rightBtn={exportSegmentStats?.modalRightBtn}
          open={exportSegmentStats?.openExportModal}
          close={handleCloseExportSegmentModal}
          accept={() => handleExportSegmentModal(segment?.name)}
          warning={exportSegmentStats?.warning}
          minWidth="600px"
          classeName="actions-buttons-shared"
          isRightBtnLoading={buttonLoading}
          disabled={buttonLoading}
        />
      )}

      {success && (
        <SnackBar
          open={success != null ? true : false}
          title={snackBarTitle}
          severity={successStatus}
          message={snackBarMsg}
          showCloseBtn={true}
        />
      )}
    </Box>
  );
};

export default OptionsDropDown;
