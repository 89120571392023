import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import "../InteractionsList.scss";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import user from "images/monitor_revamp/user.svg";
import { CheckValueLocale } from "utils/helpers";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#faaf00",
  },
});

const GMBInteractionsListHeader = (props) => {
  let intl = useIntl();
  const [iconToDisplay, setIconToDisplay] = useState(user);
  const { icon } = props;

  useEffect(() => {
    (async () => {
      const image = new Image();
      image.src = icon;
      image.onload = () => {
        setIconToDisplay(icon);
      };
    })();
  }, []);

  return (
    <Box
      className={"gmb-interaction-header latest-interaction-page"}
      component="div"
    >
      <div className="header-interaction">
        <div className="interaction-list__header clearfix display-in-row">
          {icon !== "" && (
            <img src={iconToDisplay} alt="profile" className="gmb-review-img" />
          )}
          <div className="rating-header">
            <StyledRating
              name="customized-color"
              value={parseInt(props.rating)}
              precision={0.5}
              readOnly
              icon={<StarRoundedIcon fontSize="small" />}
              emptyIcon={<StarOutlineRoundedIcon fontSize="small" />}
            />
            <div className="header-user-name">{props?.sub_name}</div>
          </div>
        </div>
        {props.text === "" ? null : (
          <button className="view-full" id="interactions-review-details-btn">
            <Link
              to={`${props.link}/${props?.id}/${props?.tracker_id}/${props?.channel_id}`}
            >
              {CheckValueLocale("reviews_details", "", {}, intl)}
            </Link>
          </button>
        )}
      </div>
    </Box>
  );
};

export default GMBInteractionsListHeader;
