import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import "pages/Monitors/monitors.scss";
import faInstagram from "images/social-icons/ig-profile-icon.svg";
import QuestionCard from "pages/Monitors/Components/questionCard";
/*---------------------------------------------------------*/

const IGQuestionsBody = (props) => {
  const { changePagination, setTotalPage, totalPage, response } = props;
  const intl = useIntl();

  //handle response
  const [topPosts, setTopPosts] = useState([]);
  const [page, setPage] = useState(1);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    changePagination(page);
  };
  // Values of DataSources totrack each widget preloader
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Instagram__QuestionsPage__latest_unanswered_questions":
        setTopPosts(response.eventData.latest_unanswered_questions);
        if (props.pageNumber == 1) {
          setTotalPage(response.eventData.number_of_unanswered_questions);
          setPage(1);
        }
        setTopPostsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTopPosts([]);
      // Values of DataSources toTrack each widget preloader
      setTopPostsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(topTopPostsPreLoaderDataSources);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(topPosts);
  }, [topTopPostsPreLoaderDataSources, topPosts]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      topTopPostsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([topPosts]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    topPosts,
    topTopPostsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);
  useEffect(() => {
    props?.setTotalPostsCount?.(totalPage);
  }, [totalPage]);

  return (
    <>
      <Box mt={2} className="ig-posts-wrapper">
        <QuestionCard
          unixDate={props.unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={topPosts}
          latestUnanswerPreloader={topTopPostsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faInstagram}
          iconColor={"instagram_icon"}
          showHeart={true}
          showDefaultImg
          hideChartBar
          className={"no-data-found-question"}
          widget="comments_mentions"
          ShowPagination
          setPage={setPage}
          page={page}
        />
        {totalPage !== 0 && totalPage > 10 && topPosts?.length > 0 && (
          <Pagination
            onChange={handleChangePage}
            page={page}
            className="pagination-monitor-list"
            count={Math.ceil(totalPage / 10)}
            variant="outlined"
          />
        )}
      </Box>
    </>
  );
};

export default IGQuestionsBody;
