import { useIntl } from "react-intl";
import { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import "./userGuide.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

const UserGuide = ({ children }) => {
  const intl = useIntl();
  const [selected, setSelected] = useState(null);

  const data = [
    {
      title: CheckValueLocale("facebook_guidelines", "", {}, intl),
      description: CheckValueLocale("best_practices_posting", "", {}, intl),
      content: ["guidel_supported_photo"],
      icon: (
        <Box className="icon facebook-icon">
          <FontAwesomeIcon icon={faFacebook} />
        </Box>
      ),
    },
    {
      title: CheckValueLocale("instagram_guidelines", "", {}, intl),
      description: CheckValueLocale("best_practices_posting", "", {}, intl),
      content: [
        "guidel_maximum_number",
        "guidel_minimum_aspect",
        "guidel_maximum_ratio",
      ],
      icon: (
        <Box className="icon instagram-icon">
          <FontAwesomeIcon icon={faInstagram} />
        </Box>
      ),
    },
    {
      title: CheckValueLocale("twitter_guidelines", "", {}, intl),
      description: CheckValueLocale("best_practices_posting", "", {}, intl),
      content: [
        "guidel_maximum_allowed",
        "guidel_supported_formats",
        "guidel_character_limit",
        "guidel_verified_accounts",
      ],
      icon: (
        <Box className="icon twitter-icon">
          <img src={xPlatform} alt="x-platform-logo" />
        </Box>
      ),
    },
  ];
  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      <Box className="content-guide-parant container-guide">
        <Container maxWidth="xl">
          <Typography component={"h3"} className="main-title-guide">
            {CheckValueLocale("social_media_guide", "", {}, intl)}
          </Typography>
          <Box className="accordion">
            {data?.map((item, i) => {
              return (
                <Box className="accordion-item">
                  <Box className="group-btns">
                    {item?.icon}
                    <Box className="content-head">
                      <Typography
                        className="item-title"
                        component={"h3"}
                        onClick={() => toggle(i)}
                      >
                        {item?.title}
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className={selected === i ? "rotate" : ""}
                        />
                      </Typography>
                      <Typography
                        className="item-description"
                        component={"span"}
                      >
                        {item?.description}
                      </Typography>
                    </Box>
                  </Box>
                  {}
                  <Typography
                    className={
                      selected === i
                        ? "item-content show"
                        : "item-content hidden"
                    }
                  >
                    {item?.content?.map((el) => {
                      return <li>{CheckValueLocale(el, "", {}, intl)}</li>;
                    })}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default UserGuide;
