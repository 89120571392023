import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./delete-report.scss";
import LucButton from "shared/lucButton/lucButton";

const DeleteReportModal = ({
  modalData,
  handleClose,
  handleDeleteReport,
  buttonLoading,
}) => {
  const intl = useIntl();
  let title = CheckValueLocale("report_delete_text1", "", {}, intl);
  title = title?.replace("#", `${modalData?.name}`);
  return (
    <Dialog
      open={modalData?.open}
      onClose={handleClose}
      className="report-delete-dialog actions-buttons-shared"
      disableScrollLock={false}
    >
      <DialogTitle>
        {CheckValueLocale("delete_report", "", {}, intl)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {title}

          <h2> {CheckValueLocale("report_delete_text2", "", {}, intl)}</h2>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LucButton
          onClick={handleClose}
          variant="flat"
          type="secondary"
          id="report-delete-report-modal-cancel-btn"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
        <LucButton
          onClick={handleDeleteReport}
          type="red"
          className="delete-btn"
          id="report-delete-report-modal-delete-btn"
          loading={buttonLoading}
          disabled={buttonLoading}
        >
          {CheckValueLocale("delete_confirm", "", {}, intl)}
        </LucButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteReportModal;
