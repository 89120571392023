import { Box, List, ListItem } from "@mui/material";
import { useIntl } from "react-intl";
import Services from "services/api/Services";
import { CheckValueLocale } from "utils/helpers";

const BackgroundFooter = () => {
  const intl = useIntl();
  return (
    <Box className="background-footer-wrapper">
      {Services.showThirdCompany ? (
        <List className="background-footer-menu">
          <ListItem>
            <a
              href="https://lucidya.com/privacy-policy/"
              target="_blank"
              rel="noopener"
            >
              {CheckValueLocale("privacy_policy", "", {}, intl)}
            </a>
          </ListItem>
          <ListItem>
            <a
              href="https://lucidya.com/service-agreement/"
              target="_blank"
              rel="noopener"
            >
              {CheckValueLocale("service_greement", "", {}, intl)}
            </a>
          </ListItem>
          <ListItem>
            <a
              href="https://lucidya.com/author-privacy-stateme/"
              target="_blank"
              rel="noopener"
            >
              {CheckValueLocale("author_privacy", "", {}, intl)}
            </a>
          </ListItem>
        </List>
      ) : null}
    </Box>
  );
};
export default BackgroundFooter;
