import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useIntl } from "react-intl";
import Header from "../header/header";
import ApisList from "../apisList/apisList";
import CircularLoading from "components/circularProgress";
import CompaniesController from "services/controllers/companiesController";
import { CheckValueLocale } from "utils/helpers";
import SnackBar from "components/snackBar";
import CreateApi from "../createApi/createApi";
import { LucidyaApisContext } from "../lucidyaApisContext";
import ApiActions from "../apiActions/apiActions";

const Content = ({ id }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [snackBarHeading, setSnackBarHeading] = useState("");
  const [openCreateApiDialog, setOpenCreateApiDialog] = useState(false);
  const [openApiActionsDialog, setOpenApiActionsDialog] = useState(false);
  const [apiDialogType, setApiDialogType] = useState("");
  const [apiDialogItem, setApiDialogItem] = useState("");
  const [apisListData, setApisListData] = useState([]);
  const [tokenLimitReached, setTokenLimitReached] = useState(true);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const handleCreateApiDialog = () =>
    setOpenCreateApiDialog(!openCreateApiDialog);
  const handleApiActionsDialog = () =>
    setOpenApiActionsDialog(!openApiActionsDialog);

  //Get Company Users
  const getLucidyaApisList = (page = 1) => {
    setLoading(true);
    CompaniesController.getLucidyaApisList(page, id).then((result) => {
      if (!result?.errorMsg) {
        setApisListData(result?.data?.message?.data);
        setTokenLimitReached(result?.data?.token_limit_reached);
        setNumberOfPages(result?.data?.pagy?.pages);
      } else {
        setSnackBarHeading("connection_failed");
        setSuccess(false);
      }
      setLoading(false);
    });
  };
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    getLucidyaApisList(page);
    setPage(page);
  };

  //Render Componenet
  useEffect(() => {
    (async () => {
      await getLucidyaApisList();
    })();
  }, []);

  return (
    <LucidyaApisContext.Provider
      value={{
        getLucidyaApisList,
        setSuccess,
        setSnackBarHeading,
        handleCreateApiDialog,
        handleApiActionsDialog,
        setApiDialogType,
        apiDialogType,
        setApiDialogItem,
        apiDialogItem,
        apisListData,
        page,
        setPage,
      }}
    >
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <Header tokenLimitReached={tokenLimitReached} />
          <ApisList apisListData={apisListData} />
          {numberOfPages > 1 ? (
            <Pagination
              onChange={handleChangePage}
              page={page}
              className="pagination-monitor-list alert-pagination-list"
              count={numberOfPages}
              variant="outlined"
            />
          ) : null}
        </>
      )}
      {openCreateApiDialog ? (
        <CreateApi open={openCreateApiDialog} apisListData={apisListData} />
      ) : null}
      {openApiActionsDialog ? <ApiActions open={openApiActionsDialog} /> : null}
      {success != null ? (
        <SnackBar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            <div className="lucidya-apis-snackbar-wrapper">
              <span className="lucidya-apis-snackbar-heading">
                {CheckValueLocale(snackBarHeading, "", {}, intl)}
              </span>
            </div>
          }
        />
      ) : (
        ""
      )}
    </LucidyaApisContext.Provider>
  );
};
export default Content;
