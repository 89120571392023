import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import faInstagram from "images/social-icons/ig-profile-icon.svg";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import MediaTabs from "../mediaTabs/mediaTabs";
import QuestionCard from "pages/Monitors/Components/questionCard";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

const AllPosts = (props) => {
  const {
    igTopPosts,
    twTopPosts,
    fbTopPosts,
    nbTopPosts,
    igTopPostsPreLoaderDataSources,
    twTopPostsPreLoaderDataSources,
    fbTopPostsPreLoaderDataSources,
    nbTopPostsPreLoaderDataSources,
    insightsID,
    response,
    dates,
    updateAnalysis,
    singleTweetAnalysis,
    allDatasources,
    isPostsPage,
    setPage,
    page,
    isAggregateAccountPage,
    setIsActivePostsTab,
    activePostsTab,
    hideChangeSentiment,
    hideWhenRetweeted,
    unixDate,
  } = props;

  const [value, setValue] = useState("");
  const { monitorType } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    activePostsTab && setIsActivePostsTab(newValue);
  };
  useEffect(() => {
    allDatasources?.length && !activePostsTab
      ? setValue(allDatasources[0])
      : setValue(activePostsTab);
  }, []);
  const isManaged =
    monitorType === "MANAGED_PAGE" || monitorType === "MANAGED_ACCOUNT";
  return (
    <>
      <Box className="social-posts-tabs">
        {allDatasources?.length > 1 && (
          <MediaTabs
            handleChange={handleChange}
            value={value}
            allDatasource={allDatasources}
          />
        )}
      </Box>
      <Box mb={5}>
        {value === "TWITTER" && (
          <QuestionCard
            unixDate={unixDate}
            showProfile={monitorType === "KEYWORD"}
            productId={window.localStorage?.getItem("sm_id")}
            latestUnanswer={twTopPosts}
            latestUnanswerPreloader={twTopPostsPreLoaderDataSources}
            preLoaderTrackerDataSources={["TWITTER"]}
            icon={xPlatform}
            singleTweetAnalysis={singleTweetAnalysis}
            iconColor={"twitter_icon"}
            showRetweet={true}
            showBanIcon={monitorType === "KEYWORD" && true}
            showScreenName={true}
            showHeart={true}
            updateAnalysis={updateAnalysis}
            noOfChar={15}
            ShowPagination={isPostsPage && true}
            setPage={setPage}
            page={page}
            isPostsAggregate
            hideChangeSentiment={hideChangeSentiment}
            hideWhenRetweeted={hideWhenRetweeted}
            isAggregateDashboard
            activePostsTab={activePostsTab}
            datasourcePostsType="TWITTER"
            showDefaultImg
          />
        )}
        {value === "FACEBOOK" && (
          <QuestionCard
            unixDate={unixDate}
            showProfile={monitorType === "KEYWORD"}
            productId={window.localStorage?.getItem("sm_id")}
            latestUnanswer={fbTopPosts}
            widget={"top_posts"}
            latestUnanswerPreloader={fbTopPostsPreLoaderDataSources}
            preLoaderTrackerDataSources={["FACEBOOK"]}
            icon={faFacebook}
            iconColor={"facebook_icon"}
            showHeart={true}
            showDefaultImg={true}
            hideChartBar={isManaged ? false : true}
            className={"no-data-found-question"}
            insightsID={isManaged ? insightsID : undefined}
            response={response}
            dates={dates}
            ShowPagination={isPostsPage && true}
            setPage={setPage}
            page={page}
            monitorType={monitorType?.toLowerCase()}
            datasourcePostsType="FACEBOOK"
            isPostsManagedWidget={isAggregateAccountPage && isManaged}
            isPostsAggregate
            hideChangeSentiment={hideChangeSentiment}
            isAggregateDashboard
            activePostsTab={activePostsTab}
          />
        )}
        {value === "INSTAGRAM" && (
          <QuestionCard
            unixDate={unixDate}
            showProfile={monitorType === "KEYWORD"}
            productId={window.localStorage?.getItem("sm_id")}
            latestUnanswer={igTopPosts}
            latestUnanswerPreloader={igTopPostsPreLoaderDataSources}
            preLoaderTrackerDataSources={["INSTAGRAM"]}
            icon={faInstagram}
            iconColor={"instagram_icon"}
            showHeart={true}
            hideChartBar={isManaged ? false : true}
            widget={"top_posts"}
            showDefaultImg
            className={"no-data-found-question"}
            instagramAccount={isManaged && true}
            insightsID={isManaged ? insightsID : undefined}
            response={response}
            dates={dates}
            ShowPagination={isPostsPage && true}
            setPage={setPage}
            page={page}
            datasourcePostsType="INSTAGRAM"
            isPostsManagedWidget={isAggregateAccountPage && isManaged}
            isPostsAggregate
            hideChangeSentiment={hideChangeSentiment}
            isAggregateDashboard
            activePostsTab={activePostsTab}
          />
        )}
        {value === "NEWSBLOGS" && (
          <Box mt={3} className="nb-posts-wrapper">
            <QuestionCard
              latestUnanswer={nbTopPosts}
              latestUnanswerPreloader={nbTopPostsPreLoaderDataSources}
              preLoaderTrackerDataSources={["NEWSBLOGS"]}
              icon={faNewspaper}
              iconColor={"news-blogs-icon"}
              showHeart
              hideVector={true}
              hideChartBar={true}
              showFlash
              showEye
              showEarth
              newsBlogImg={true}
              ShowPagination={isPostsPage && true}
              setPage={setPage}
              page={page}
              datasourcePostsType="NEWSBLOGS"
              isPostsAggregate
              isAggregateDashboard
              activePostsTab={activePostsTab}
              showBanIcon={true}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AllPosts;
