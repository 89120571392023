import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import WordCloudWidget from "components/widgets/wordCloudWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends.js";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";
import OverView from "components/widgets/overViewWidget.js";
import { getNoOfDays } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers/index";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import _ from "lodash";
const IntercomBody = (props) => {
  const intl = useIntl();
  let { response, startDate, endDate, setExcelSheetData } = props;

  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoaderDataSources: [],

    //interaction widget
    interactions: [],
    totalInteractions: [],
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentOvertime: [],
    sentimentPiechart: [],
    sentimentAnalysisPreLoaderDataSources: [],

    //theme widget
    themeAnalysis: [],
    themeTrend: [],
    themeAnalysisPreLoaderDataSources: [],
    themeTrendPreLoaderDataSources: [],
    themeXlxs: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],

    //genders widget
    excelGenders: [],
    genders: [],
    gendersPreLoaderDataSources: [],

    //countries widget
    countries: [],
    countriesPreLoaderDataSources: [],
    excelTopCountries: [],

    //cities widget
    cities: [],
    citiesPreLoaderDataSources: [],
    excelCities: [],

    //dialects sub dialects widget
    dialects: [],
    subDialects: [],
    dialectsPreLoaderDataSources: [],
    excelDialects: [],
    excelSubDialects: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //latest interaction widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],
  };

  const [intercomWidgets, setIntercomWidgets] = useState(initialWidgetsState);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Intercom__CASingleDataSourceInsightsPage__overview":
        if (response?.eventData?.overview) {
          let overViewArray = Object?.entries(
            response?.eventData?.overview,
          )?.map((key) => ({
            name: key[0],
            value: key[1],
          }));
          setIntercomWidgets({
            ...intercomWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "Intercom__CASingleDataSourceInsightsPage__top_keywords":
        setIntercomWidgets({
          ...intercomWidgets,
          topKeywords: response?.eventData?.top_keywords?.map((item) => {
            return { name: item?.id, value: item?.stats_count };
          }),
          topKeywordsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Intercom__CASingleDataSourceInsightsPage__dialects_and_sub_dialects":
        setIntercomWidgets({
          ...intercomWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsPreLoaderDataSources: [response?.data_source],
          excelDialects: _.cloneDeep([...response?.eventData?.dialects]),
          excelSubDialects: _.cloneDeep([...response?.eventData?.sub_dialects]),
        });
        break;
      case "Intercom__CASingleDataSourceInsightsPage__gender_distribution":
        setIntercomWidgets({
          ...intercomWidgets,
          genders: response?.eventData?.gender_distribution,
          gendersPreLoaderDataSources: [response?.data_source],
          excelGenders: _.cloneDeep([
            ...response?.eventData?.gender_distribution,
          ]),
        });
        break;
      case "Intercom__CASingleDataSourceInsightsPage__top_cities":
        setIntercomWidgets({
          ...intercomWidgets,
          cities: response?.eventData?.top_cities,
          citiesPreLoaderDataSources: [response?.data_source],
          excelCities: _.cloneDeep([...response?.eventData?.top_cities]),
        });
        break;
      case "Intercom__CASingleDataSourceInsightsPage__top_countries":
        setIntercomWidgets({
          ...intercomWidgets,
          countries: response?.eventData?.top_countries,
          excelTopCountries: _.cloneDeep([...response.eventData.top_countries]),
          countriesPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Intercom__CASingleDataSourceInsightsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setIntercomWidgets({
          ...intercomWidgets,
          interactions: response?.eventData?.interactions,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteractions: response?.eventData?.total_interactions,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Intercom__CASingleDataSourceInsightsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return intercomWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return intercomWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return intercomWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setIntercomWidgets({
          ...intercomWidgets,
          sentimentOvertime: {
            negative: intercomWidgets?.negativeArray,
            positive: intercomWidgets?.positiveArray,
            neutral: intercomWidgets?.neutralArray,
          },
          sentimentPiechart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoaderDataSources: [response?.data_source],
        });

        break;
      case "Intercom__CASingleDataSourceInsightsPage__themes_and_sub_themes":
        setIntercomWidgets({
          ...intercomWidgets,
          themeXlxs: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisPreLoaderDataSources: [response?.data_source],
          themeTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Intercom__CASingleDataSourceInsightsPage__top_posts_comments":
        setIntercomWidgets({
          ...intercomWidgets,
          latestInteractions: response?.eventData?.top_posts,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.interactions_count,
        });
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setIntercomWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: intercomWidgets?.totalInteraction,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: intercomWidgets?.avgInteractions,
    },
  ];

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      intercomWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([intercomWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    intercomWidgets?.latestInteractions,
    intercomWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      intercomWidgets?.overViewPreLoaderDataSources,
      intercomWidgets?.topKeywordsPreLoaderDataSources,
      intercomWidgets?.interactionsPreLoaderDataSources,
      intercomWidgets?.dialectsPreLoaderDataSources,
      intercomWidgets?.countriesPreLoaderDataSources,
      intercomWidgets?.latestInteractionsPreLoaderDataSources,
      intercomWidgets?.sentimentAnalysisPreLoaderDataSources,
      intercomWidgets?.themeAnalysisPreLoaderDataSources,
      intercomWidgets?.themeTrendPreLoaderDataSources,
      intercomWidgets?.citiesPreLoaderDataSources,
    ];
    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      props?.preLoaderTrackerDataSources,
    );
    props?.setCheckAllDataFetchingExport(result);
    let excelData = { ...intercomWidgets };
    props?.setExcelSheetData([{ ...excelData }]);
  }, [intercomWidgets]);

  return (
    <>
      <OverView
        data={intercomWidgets?.overview}
        isChat
        overViewPreLoaderDataSources={
          intercomWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />

      <Typography variant="h6" mt={3} mb={6}>
        {CheckValueLocale("metrics", "", {}, intl)}
      </Typography>
      <LineChartWidget
        title={"conversations"}
        data={intercomWidgets?.interactions}
        showGroupBy={true}
        showChartDetailsWidget
        statsDetailsData={chartStatsData}
        redirectTo={"/interactions/chat/intercom/"}
        toolTipLabel={"total_interactions"}
        showDownloadIcon
        bigImg
        lineChartPreLoaderDataSources={
          intercomWidgets?.interactionsPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />
      <Box my={3}>
        <StackedLineWidget
          title={"sentiment_analysis"}
          data={intercomWidgets?.sentimentOvertime}
          dataPiechart={intercomWidgets?.sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            intercomWidgets?.sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Box mt={3}>
        <ThemeAnalysis
          title={"themes"}
          data={intercomWidgets?.themeAnalysis}
          themeAnalysisPreLoaderDataSources={
            intercomWidgets?.themeAnalysisPreLoaderDataSources
          }
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          data={intercomWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={
            intercomWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Grid container spacing={2} my={3}>
        <Grid item md={4}>
          <PieChartWidget
            title={"genders"}
            data={intercomWidgets?.genders}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#6D737A", "#009BDF", "#F680BC"]}
            chartType={"gender"}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={
              intercomWidgets?.gendersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"countries"}
            data={intercomWidgets?.countries}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={
              intercomWidgets?.countriesPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"cities"}
            data={intercomWidgets?.cities}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            pieChartPreLoaderDataSources={
              intercomWidgets?.citiesPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={3}>
        <Grid item md={4}>
          <PieChartWidget
            title={"dialects_subdialects"}
            data={intercomWidgets?.dialects}
            subData={intercomWidgets?.subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={
              intercomWidgets?.dialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={8} className="topkewords_wrapper">
          <WordCloudWidget
            title={"top_keywords"}
            data={intercomWidgets?.topKeywords}
            errorMessage={"no_keywords_found"}
            showDownloadIcon
            bigImg
            wordCloudPreLoaderDataSources={
              intercomWidgets?.topKeywordsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Box my={3}>
        <InteractionsList
          showProfile={true}
          data={intercomWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isIntercom
          latestInteractionsPreLoaderDataSources={
            intercomWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showViewMoreButton={intercomWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default IntercomBody;
