import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getPercentage } from "utils/helpers";
import Snackbar from "components/snackBar";
import { useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import {
  commasAfterDigit,
  CheckValueLocale,
  handlelUserRoles,
} from "utils/helpers";
import { useIntl } from "react-intl";
import "./subscription.scss";
import LucButton from "shared/lucButton/lucButton";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme?.palette?.grey[theme?.palette?.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme?.palette?.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const AiApiPackage = (props) => {
  const {
    success,
    aiApiInfo,
    setSuccess,
    sendPackageUpgrade,
    isLoadingUpgrade,
  } = props;
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [aiApiRequest, setAiApiRequest] = useState(null);
  const [aiApiSuccess, setAiApiSuccess] = useState(null);
  const [upgradePackageRequest, setUpgradePackageRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const requestChannelAnalDemo = () => {
    let queryData = {
      service_name: "request_ai_api_demo",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result?.errorMsg) {
        setAiApiRequest(true);
        setSuccessMsg("request_demo");
        setAiApiSuccess(true);
      } else {
        setAiApiRequest(false);
        setErrorMsg("request_demo_failed");
        setAiApiSuccess(false);
      }
      setIsLoading(false);
    });
  };

  return (
    <div id="channel-analytics">
      <h3 className="sub__head">{CheckValueLocale("ai_api", "", {}, intl)}</h3>
      <Card className="body">
        {props.aiApiActive ? (
          <>
            <Card justifyContent="center">
              {aiApiInfo?.packageInfo ? (
                <div>
                  <Typography>
                    {CheckValueLocale("package_info", "", {}, intl)}
                  </Typography>
                  <Card className="percentage">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={10}
                      p={2}
                    >
                      <div>
                        <Typography>
                          {CheckValueLocale("package_name", "", {}, intl)}
                        </Typography>
                        <Typography>{aiApiInfo?.packageInfo?.name}</Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_start", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              aiApiInfo?.packageInfo?.subscribe_start,
                            )?.split(" ")[0]
                          }
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {CheckValueLocale("subscribe_end", "", {}, intl)}
                        </Typography>
                        <Typography>
                          {
                            String(
                              aiApiInfo?.packageInfo?.subscribe_end,
                            )?.split(" ")[0]
                          }
                        </Typography>
                      </div>
                    </Stack>
                  </Card>
                  {aiApiInfo?.packageInfo?.ai_api_qouta?.length > 0 ? (
                    <Card justifyContent="center">
                      <Typography>
                        {CheckValueLocale("package_stats", "", {}, intl)}
                      </Typography>
                      <Grid container spacing={5}>
                        {aiApiInfo?.packageInfo?.ai_api_qouta?.map(
                          (qouta, i) => {
                            const limitReached =
                              aiApiInfo?.packageInfo?.ai_api_qouta_used[
                                qouta?.feature_name + "_count"
                              ] == qouta?.max_requests_per_month;
                            const countQouta = i > 1 ? "qouta-box" : false;
                            return (
                              <Grid item xs={6} className={`${countQouta}`}>
                                <Card className="percentage">
                                  <CardContent>
                                    <Grid
                                      container
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      spacing={5}
                                    >
                                      <Grid item lg={12} md={12} sm={12}>
                                        <Typography className="head-social-list">
                                          {CheckValueLocale(
                                            qouta?.feature_name + "_ai_api",
                                            "",
                                            {},
                                            intl,
                                          )}
                                        </Typography>
                                        <Stack
                                          direction="row"
                                          justifyContent="space-between"
                                          mb={1}
                                        >
                                          <div>
                                            {commasAfterDigit(
                                              aiApiInfo?.packageInfo
                                                ?.ai_api_qouta_used[
                                                qouta?.feature_name + "_count"
                                              ],
                                            )}{" "}
                                            {CheckValueLocale(
                                              "of",
                                              "",
                                              {},
                                              intl,
                                            )}{" "}
                                            {commasAfterDigit(
                                              qouta?.max_requests_per_month,
                                            )}
                                          </div>
                                          <div className="percentage">
                                            {`${getPercentage(
                                              aiApiInfo?.packageInfo
                                                ?.ai_api_qouta_used[
                                                qouta?.feature_name + "_count"
                                              ],
                                              qouta?.max_requests_per_month,
                                            )}%`}
                                          </div>
                                        </Stack>
                                        <BorderLinearProgress
                                          className={
                                            `${
                                              limitReached
                                                ? "limit-reached"
                                                : ""
                                            }` &&
                                            ` ${
                                              aiApiInfo?.packageInfo
                                                ?.ai_api_qouta_used >=
                                              qouta?.max_requests_per_month
                                                ? "red-color"
                                                : ""
                                            }`
                                          }
                                          variant="determinate"
                                          value={getPercentage(
                                            aiApiInfo?.packageInfo
                                              ?.ai_api_qouta_used[
                                              qouta?.feature_name + "_count"
                                            ],
                                            qouta?.max_requests_per_month,
                                          )}
                                          id="linear-progress-color"
                                        />
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          },
                        )}
                      </Grid>
                    </Card>
                  ) : null}
                </div>
              ) : (
                <Typography>
                  {CheckValueLocale("channel_analytics_demo", "", {}, intl)}
                </Typography>
              )}
            </Card>
            {handlelUserRoles("GENERAL", "CREATE_REQUEST") && (
              <Stack direction="row" justifyContent="flex-end">
                <LucButton
                  onClick={() => {
                    sendPackageUpgrade(aiApiInfo?.id);
                    setUpgradePackageRequest(true);
                  }}
                  id="settings-subscription-ai-api-package-btn"
                  loading={isLoadingUpgrade}
                >
                  {CheckValueLocale("request_upgrade", "", {}, intl)}
                </LucButton>
              </Stack>
            )}
          </>
        ) : (
          <>
            <Typography>
              {CheckValueLocale("ai_api_request_demo", "", {}, intl)}
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={() => {
                  requestChannelAnalDemo();
                }}
                id="settings-subscription-ai-api-package-btn"
              >
                {isLoading ? (
                  <span className="sending-request-btn-text">
                    <CircularProgress className="circular-progress-subscription circular-progress" />
                    {CheckValueLocale("sending_request", "", {}, intl)}
                  </span>
                ) : (
                  CheckValueLocale("request_demo", "", {}, intl)
                )}
              </Button>
            </Stack>
          </>
        )}
      </Card>
      {success != null ? (
        <Snackbar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            success ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span>
                  <br />
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_success", "", {}, intl)
                    : CheckValueLocale(successMsg, "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {upgradePackageRequest
                    ? CheckValueLocale("request_upgrade_failed", "", {}, intl)
                    : CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span>
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
      {aiApiSuccess != null ? (
        <Snackbar
          open={aiApiSuccess != null ? true : false}
          handleClose={() => {
            setAiApiSuccess(null);
          }}
          severity={aiApiSuccess ? "success" : "error"}
          message={
            aiApiSuccess ? (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale("request_sent_subscription", "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("request_demo_success", "", {}, intl)}
                </span>
              </div>
            ) : (
              <div>
                <span className="subscription-snackbar-msg">
                  {CheckValueLocale(errorMsg, "", {}, intl)}
                </span>
                <span className="subscription-snackbar-body">
                  <br />
                  {CheckValueLocale("try_again", "", {}, intl)}
                </span>
              </div>
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default AiApiPackage;
