import { useState, useRef, useEffect } from "react";

const counterChipBoxWidth = 30; // the fixed width for the counter box
const gapWidth = 10; // gap between chips
const emptyTruncatedChipMinimumWidth = 45; // the min width for empty chip that will be @(n chars) + ... + (X icon)

const getWidthForDomElement = (element) => {
  return element?.getBoundingClientRect?.()?.width ?? 0;
};

export const useTwitterReplyTo = ({ setSelectedUsers, selectedUsers = [] }) => {
  /*
    here we are maintaining different state that holding the users list
    1. selectedUsers: this state will partial list from the users that will hold the list of users that we have selected to reply to
    2. visibleUsersForHiddenReplyRow: this state will hold the list of users that will be visible in the hidden reply row
    3. visibleUsersForShowedReplyRow: this state will hold the list of users that will be visible in the showed reply row
  */

  const [visibleUsersForHiddenReplyRow, setVisibleUsersForHiddenReplyRow] =
    useState([...selectedUsers]);
  const [visibleUsersForShowedReplyRow, setVisibleUsersForShowedReplyRow] =
    useState([]);
  const [hiddenUsersCount, setHiddenUsersCount] = useState(0);
  // this state will hold the max width for the truncated user chip in the visible row
  const [
    maxWidthForTrucatedLastVisibleUser,
    setMaxWidthForTrucatedLastVisibleUser,
  ] = useState(0);
  // this state will hold the user that will be truncated in the visible row
  const [truncatedUser, setTruncatedUser] = useState(null);

  const containerRef = useRef(null);
  const textRef = useRef(null);
  const menuRef = useRef(null);
  const userListRef = useRef(null);

  const handleUserDelete = (id) => {
    setSelectedUsers((prev) => prev?.filter?.((user) => user?.id !== id));
  };

  const handleUserAdd = (user) => {
    setSelectedUsers((prev) => [...prev, user]);
  };

  const calculateVisibleUsers = () => {
    // Check if the users list is available and the container is available
    if (
      !selectedUsers?.length ||
      !userListRef?.current ||
      !containerRef?.current
    )
      return;
    // here usersListScrollWidth will represent the whole width for the users list included also the overflow part
    const usersListScrollWidth = userListRef.current?.scrollWidth;
    // usersListClientWidth will represent the actuall width for the users list execluded the overflow part
    const usersListClientWidth =
      getWidthForDomElement(containerRef.current) -
      getWidthForDomElement(menuRef.current) -
      getWidthForDomElement(textRef.current) -
      2 * gapWidth; // gep is the padding
    let usersListCurrentWidth = 0; // this will hold the aggregeate width for the users list when calc each child in the users list
    let visibleUsersCount = 0; // the number of visible users that will be previewed
    const shouldCounterChipBeVisible =
      usersListScrollWidth > usersListClientWidth; // check if the counter should be visible or not
    if (!shouldCounterChipBeVisible) {
      setVisibleUsersForHiddenReplyRow([...selectedUsers]);
      setVisibleUsersForShowedReplyRow([...selectedUsers]);
      setHiddenUsersCount(0);
      setMaxWidthForTrucatedLastVisibleUser(0);
      setTruncatedUser(null);
      return;
    }
    // execulde the truncated user box and the counter box before making the calc
    const userChips = Array.from(
      userListRef?.current?.children || [],
    )?.filter?.(
      (child) =>
        !child?.classList?.contains?.("twitter-reply-to-hidden-users") &&
        !child?.classList?.contains?.("twitter-reply-to-truncated-user"),
    );
    // Check if the counter chip is visible
    const counterChipWidthWithGap = counterChipBoxWidth + gapWidth; // Width of the counter chip
    let shouldCounterBeVisible = true;
    let hasTruncatedUser = false;
    let isFirstChipHidden;

    userChips?.forEach((chip, idx) => {
      const chipWidth = getWidthForDomElement(chip);
      usersListCurrentWidth += chipWidth + (idx > 0 ? gapWidth : 0);

      if (
        usersListCurrentWidth + counterChipWidthWithGap <=
        usersListClientWidth
      ) {
        visibleUsersCount++;
      } else if (!isFirstChipHidden) {
        isFirstChipHidden = true;
        // here we will handle the trucation for the first chip that was hidden and all the following chips will be visible
        shouldCounterBeVisible = idx < userChips?.length - 1;
        // here we check if there are more chips and we have remaining space we add new gap + the width of the counter chip
        const extraWidth = shouldCounterBeVisible ? counterChipWidthWithGap : 0;
        const remainingWidth =
          usersListClientWidth -
          (usersListCurrentWidth - chipWidth + extraWidth);
        if (remainingWidth >= emptyTruncatedChipMinimumWidth) {
          setMaxWidthForTrucatedLastVisibleUser(remainingWidth);
          setTruncatedUser(selectedUsers?.[visibleUsersCount]);
          hasTruncatedUser = true;
        }
      }
    });
    if (!hasTruncatedUser) {
      setMaxWidthForTrucatedLastVisibleUser(0);
      setTruncatedUser(null);
    }
    const showedUsers = selectedUsers?.slice(0, visibleUsersCount);
    const notShowedUsersLength =
      selectedUsers?.length - visibleUsersCount - (hasTruncatedUser ? 1 : 0);

    setVisibleUsersForHiddenReplyRow(showedUsers);
    setVisibleUsersForShowedReplyRow(showedUsers);
    setHiddenUsersCount(Math.max(notShowedUsersLength, 0));
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // reset the visible users list for the hidden reply row
      // so it can be recalculated from the start
      setVisibleUsersForHiddenReplyRow([...selectedUsers]);
      setTimeout(() => {
        calculateVisibleUsers();
      }, 0); // Defer the layout calculation to avoid ResizeObserver loop errors
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [selectedUsers]);

  return {
    visibleUsersForHiddenReplyRow,
    visibleUsersForShowedReplyRow,
    hiddenUsersCount,
    maxWidthForTrucatedLastVisibleUser,
    truncatedUser,
    containerRef,
    textRef,
    menuRef,
    userListRef,
    handleUserDelete,
    handleUserAdd,
  };
};
