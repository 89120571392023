import { useEffect, useState } from "react";
import PostsBody from "./postsBody";
import Header from "pages/Monitors/Components/header";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
/*---------------------------------------------------------*/

const InstagramPostsPage = (props) => {
  const {
    response,
    preLoaderTrackerDataSources,
    resetAllFields,
    changePagination,
  } = props;
  const [totalPage, setTotalPage] = useState("");
  const [sortBy, setSortBy] = useState("latest_posts");
  const sortByData = ["latest_posts", "most_interactions"];

  const handleChange = (event) => {
    setSortBy(event.target.value);
    props.setFilterParams({
      ...props.filterParams,
      sort_by: event.target.value,
    });
    props.applyFilter(props.startDate, props.endDate, {
      ...props.filterParams,
      sort_by: event.target.value,
    });
  };
  useEffect(() => {
    if (props.filterParams["sort_by"] !== undefined) {
      setSortBy(props.filterParams["sort_by"]);
    } else {
      setSortBy("latest_posts");
    }
  }, [props.filterParams["sort_by"]]);
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {props.response?.eventData !== undefined && (
        <Header
          title="total_posts"
          titleValue={totalPage}
          sortByData={sortByData}
          sortBy={sortBy}
          handleChange={handleChange}
        />
      )}
      <PostsBody
        response={response}
        changePagination={changePagination}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        resetAllFields={resetAllFields}
        pageNumber={props.pageNumber}
        setTotalPage={setTotalPage}
        totalPage={totalPage}
        insightsID={props.insightsID}
        insightsResponse={props.insightsResponse}
        dates={props.dates}
        {...props}
      />
    </MonitorContainer>
  );
};

export default InstagramPostsPage;
