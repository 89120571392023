import { Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const Dropdownlabel = ({ tooltip, label, tooltipPosition }) => {
  return (
    <>
      <Typography variant="caption" className="dropdown-label">
        <span>{label}</span>
        {tooltip ? (
          <Tooltip title={tooltip} arrow placement={tooltipPosition}>
            <InfoIcon />
          </Tooltip>
        ) : null}
      </Typography>
    </>
  );
};

export default Dropdownlabel;
