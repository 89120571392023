import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import SingleDataSource from "./singleDataSource";

const LiveDashBoardDataSources = ({
  monitorWidgets,
  getSourceName,
  handleChangeCollapse,
  expanded,
  selectedWidgets,
  handleSelectWidget,
  getSelectedWidgetsLength,
  getDataSourceTabsSorted,
  getSocialIcon,
}) => {
  const intl = useIntl();

  return (
    <Box className="create-live-dashboard-group">
      <Box component="p" className="create-live-dashboard-label">
        {CheckValueLocale("select_widgets", "", {}, intl)}
      </Box>
      <Box component="p" className="create-live-dashboard-hint">
        {CheckValueLocale("select_widgets_hint", "", {}, intl)}
      </Box>
      <Box className="create-live-dashboard-list">
        {Object.keys(monitorWidgets || {})?.map((dataSource) => (
          <SingleDataSource
            getSourceName={getSourceName}
            dataSource={dataSource}
            dataSourceTabs={monitorWidgets?.[dataSource]}
            handleChangeCollapse={handleChangeCollapse}
            expanded={expanded}
            selectedWidgets={selectedWidgets}
            handleSelectWidget={handleSelectWidget}
            getSelectedWidgetsLength={getSelectedWidgetsLength}
            getDataSourceTabsSorted={getDataSourceTabsSorted}
            getSocialIcon={getSocialIcon}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LiveDashBoardDataSources;
