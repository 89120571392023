import { Box, Tooltip } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorIcon from "@mui/icons-material/Error";
import { TooltipText } from "./TooltipText";
import { CheckValueLocale, monitorErrors } from "utils/helpers";
import "../manageChannels.scss";

export const AccountBodyCell = ({ channel }) => {
  const intl = useIntl();
  let errorList = monitorErrors(channel);
  return (
    <Box
      className={intl.locale == "ar" ? " name-text name-text-ar" : "name-text"}
    >
      <Box>
        {errorList?.length ? (
          <>
            <Tooltip
              title={
                <>
                  {errorList?.map((error, i) => {
                    return (
                      <Box key={i} className="worning-text">
                        <FormattedMessage
                          id={error?.errorMsg}
                          values={{
                            datasource: CheckValueLocale(
                              error?.dataSource,
                              "",
                              {},
                              intl,
                            ),
                          }}
                        />
                      </Box>
                    );
                  })}
                </>
              }
              id="warning-tooltip-report"
              sx={{ cursor: "default" }}
              arrow
              placement="bottom"
            >
              <Box className="warning-icon-countainer">
                <ErrorIcon
                  className="dashboard-warning-icon"
                  fontSize="small"
                />
              </Box>
            </Tooltip>
          </>
        ) : null}
      </Box>
      <Box>
        <TooltipText
          text={
            channel?.attributes?.account_name == null
              ? "—"
              : channel?.attributes?.account_name?.trim()?.length !== 0
                ? channel?.attributes?.channel?.includes("TWITTER")
                  ? `@${channel?.attributes?.account_name}`
                  : channel?.attributes?.account_name
                : "—"
          }
          textLength={18}
        />
      </Box>
    </Box>
  );
};
