export const SetUserData = (userData) => {
  for (const [key, value] of Object.entries(userData.data.data.attributes)) {
    localStorage.setItem(key, value);
  }
  localStorage.setItem("user_id", userData.data.data.id);
  for (const [key, value] of Object.entries(
    userData.data.data.relationships.company.data,
  )) {
    localStorage.setItem("company_" + key, value);
  }
  localStorage.setItem("user_type", userData.data.data.type);
  userData.data.included.map((included) => {
    for (const [key, value] of Object.entries(included)) {
      localStorage.setItem(key, value);
    }
  });
};
