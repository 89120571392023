import { Tooltip, Box, Chip } from "@mui/material";
import { startWithEnglishCharacter } from "../../../MonitorsShared/SharedFunctions/components/keywordLanguage";

import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { checkDirectionLang } from "../../SharedFunctions/components/kewordsSharedFunctions";

const KeywordTooltipChip = (props) => {
  const {
    labels,
    dataSource,
    index,
    keywordSetting,
    onDelete,
    disableChip,
    usedLabel = false,
    lastLabel = false,
  } = props;
  const intl = useIntl();

  const usedChipClass = usedLabel ? "used-chip-style" : "";
  const getTooltipTitle = () => {
    let tooltipTitle = "";
    if (labels.length > 36) {
      tooltipTitle = labels;
    } else if (lastLabel) {
      tooltipTitle = CheckValueLocale(
        "cannot_remove_last_hashtags",
        "",
        {},
        intl,
      );
    } else if (usedLabel) {
      tooltipTitle = CheckValueLocale("used_hashtags_ig_tooltip", "", {}, intl);
    }
    return tooltipTitle ? (
      <Box className="instagram-hashtags-tooltip">{tooltipTitle}</Box>
    ) : (
      tooltipTitle
    );
  };

  const labelStartWithHashtag = labels.startsWith("#");
  const isArabic = checkDirectionLang(labels);
  const reversedKeyword =
    labelStartWithHashtag && isArabic ? labels.slice(1) + "#" : labels;

  return (
    <>
      <Tooltip title={getTooltipTitle()} placement="top" arrow>
        <Chip
          key={index}
          label={reversedKeyword}
          className={`chip-style ${usedChipClass}`}
          onDelete={onDelete}
          disabled={
            (keywordSetting && !handlelUserRoles("SM", "EDIT_MONITOR")) ||
            disableChip
          }
        />
      </Tooltip>
    </>
  );
};

export default KeywordTooltipChip;
