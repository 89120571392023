import { useState, useRef } from "react";
import VideoFrame from "react-video-thumbnail";
import axios from "axios";
import { useIntl } from "react-intl";
import { TextField, Box, Popover, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash, faPlay } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import { faGrinAlt } from "@fortawesome/pro-regular-svg-icons";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import image from "images/engagements-images/image.svg";
import gifSolid from "images/engagements-images/gif-solid.svg";
import "pages/engagements/components/replyModal/reply.scss";
import "./newConversationPopup.scss";

const NewDMContentMedia = (props) => {
  const intl = useIntl();
  const inputRefImg = useRef(null);

  const {
    selectedFile,
    setSelectedFile,
    base64Code,
    setBase64Code,
    attachments,
    setAttachments,
    text,
    setText,
    mediaFile,
    setMediaFile,
    loading,
    setContentSelected,
    accountsSelected,
    setContentErrorMedia,
    getMediaLessLimits,
    accountsPublishSelected,
    setSendBtnDisabled,
    sendBtnDisabled,
  } = props;

  const [anchorElEmoji, setAnchorElEmoji] = useState(null);

  const handleChange = (event) => {
    setContentErrorMedia("");
    setText(event.target.value);
    if (event.target.value?.length > getMediaLessLimits()?.minimumValueText) {
      setContentErrorMedia("publish_content_limit_text");
    }
  };
  const handleAttachmentsUpload = async (event) => {
    setContentErrorMedia("");
    const file = event.target?.files[0];
    let convertFileToKB =
      getMediaLessLimits()?.minimumValueImgSize / Math.pow(1024, 2);
    if (file) {
      if (Math.round(file?.size) > getMediaLessLimits()?.minimumValueImgSize) {
        setContentErrorMedia("size_media_err_dm");
      } else if (
        event.target.id === "gif-input" &&
        file?.type !== "image/gif"
      ) {
        setContentErrorMedia("gif_media_err");
      } else if (
        event.target.id === "video-input" &&
        file?.type !== "video/mp4"
      ) {
        setContentErrorMedia("vid_media_err");
      } else if (
        event.target.id === "image-input-dm" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpeg"
      ) {
        setContentErrorMedia("img_media_err");
      } else {
        setContentErrorMedia("");
        const reader = new FileReader();
        reader.onload = () => {
          setBase64Code([...base64Code, reader.result]);
        };
        reader.readAsDataURL(file);
        setSelectedFile([...selectedFile, file]);
        setMediaFile([...mediaFile, URL.createObjectURL(file)]);
      }
    }
    const formData = new FormData();
    formData.append("upload_file", file);
    formData.append("publisher_name", accountsPublishSelected?.user_name);
    formData.append("account_info_id", accountsPublishSelected?.reference_id);
    formData.append(
      "monitor_id",
      parseInt(accountsPublishSelected?.monitor_id),
    );
    formData.append("data_source_name", accountsPublishSelected?.data_source);
    formData.append("product_id", window.localStorage.engagements_id);
    formData.append("media_type", file?.type);

    // "https://engupload.lucidya.com/upload", ///staging
    // "https://upload.lucidya.com/upload", ///production
    try {
      setSendBtnDisabled(true);
      const response = await axios.post(
        "https://eng-upload.prod.lucidya.com/upload",
        formData,
        {
          headers: {
            "luc-authorization": window.localStorage.user_token,
          },
        },
      );
      setAttachments([...attachments, response?.data]);
      setSendBtnDisabled(false);
    } catch (error) {
      setSendBtnDisabled(false);
    }
  };

  const handleRemoveAttachments = (index) => {
    setContentErrorMedia("");
    const attachments = [...selectedFile];
    attachments?.splice(index, 1);
    setSelectedFile(attachments);

    mediaFile?.splice(index, 1);
    setMediaFile(mediaFile);

    const base64 = [...base64Code];
    base64?.splice(index, 1);
    setBase64Code(base64);

    const attachment = [...attachments];
    attachment?.splice(index, 1);
    setAttachments(attachment);

    if (inputRefImg?.current) {
      inputRefImg.current.value = null;
    }
  };

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const mediaLength =
    selectedFile?.length >= getMediaLessLimits()?.minimumValueNoMedia;

  const disabledCondition =
    isEmptyObj(accountsPublishSelected) || mediaLength ? true : false;
  const disabledStyleFirst = disabledCondition;

  // emoji Dropdown HANDLER
  const handleClickEmoji = (event) => {
    setAnchorElEmoji(event.currentTarget);
  };
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };
  const openEmoji = Boolean(anchorElEmoji);

  // add emoji to text after select and hide emoji Dropdown
  const emojiSelect = (e) => {
    setContentErrorMedia("");
    setText(text + e);
    handleCloseEmoji();
  };

  return (
    <Box className="engagements-reply dm">
      {loading ? (
        <div className="loading-style">
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>
      ) : (
        <>
          <div className="reply-container">
            <TextField
              value={text}
              onChange={handleChange}
              multiline
              maxRows={7}
              variant="outlined"
              placeholder={CheckValueLocale(
                "engagement_type_your_message",
                "",
                {},
                intl,
              )}
              className="reply-textarea"
              onFocus={() => setContentSelected(true)}
              onBlur={() => setContentSelected(false)}
              disabled={loading}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "#00000099 !important",
                    opacity: 1,
                    fontSize: 14,
                    fontFamily: 400,
                  },
                },
              }}
            />
            {base64Code?.length !== 0 && (
              <div className="reply-attachments-container">
                {base64Code?.map((base64, index) => {
                  return (
                    <div className="reply-attachment">
                      {selectedFile[index]?.type?.includes("image") ? (
                        <div className="attachments-img-box">
                          <img
                            src={base64}
                            alt="Uploaded"
                            className="attachments-img"
                          />
                          {selectedFile[index]?.type === "image/gif" ? (
                            <div className="attachments-icon-box">
                              <img src={gifSolid} />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="attachments-video-box">
                          <VideoFrame
                            videoUrl={base64}
                            thumbnailHandler={(thumbnail) => (
                              <img
                                src={thumbnail}
                                alt="Video Thumbnail"
                                style={{ maxWidth: "100%" }}
                              />
                            )}
                            width={33}
                            height={33}
                          />
                          <div className="attachments-icon-box">
                            <FontAwesomeIcon icon={faPlay} />
                          </div>
                        </div>
                      )}
                      <div
                        className="remove-attachments-reply"
                        onClick={() => handleRemoveAttachments(index)}
                      >
                        <span className="trash-wrap">
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <Box className="reply-footer">
            <div className="reply-footer-box">
              <Tooltip
                title={CheckValueLocale("emoji_tooltip", "", {}, intl)}
                placement="bottom"
                arrow
              >
                <Box
                  className="reply-footer-icon emoji-icon"
                  onClick={handleClickEmoji}
                >
                  <FontAwesomeIcon icon={faGrinAlt} />
                </Box>
              </Tooltip>
              <Popover
                anchorOrigin={anchorPosition}
                transformOrigin={transformPosition}
                onClose={handleCloseEmoji}
                open={openEmoji}
                anchorEl={anchorElEmoji}
              >
                <Picker
                  data={data}
                  onEmojiSelect={(e) => emojiSelect(e?.native)}
                />
              </Popover>
              <Tooltip
                title={CheckValueLocale("images_tooltip", "", {}, intl)}
                placement="bottom"
                arrow
              >
                <label
                  htmlFor="image-input-dm"
                  className={`reply-footer-icon ${
                    disabledStyleFirst ? "reply-footer-icon-disabled" : ""
                  }`}
                >
                  <img src={image} />
                  <input
                    id="image-input-dm"
                    type="file"
                    accept="image/png,image/jpg,image/jpeg"
                    onChange={handleAttachmentsUpload}
                    className="modal-input-style"
                    ref={inputRefImg}
                    disabled={disabledCondition}
                  />
                </label>
              </Tooltip>
            </div>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NewDMContentMedia;
