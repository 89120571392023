import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Menu, MenuItem, IconButton, Tooltip, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import PopupModal from "components/popupModal.js";
import Snackbar from "components/snackBar";
import {
  facebookAuth,
  gmailAuth,
  twitterAuth,
  gmbAuth,
  tiktokAuth,
  linkedInAuth,
} from "../../../../channelAnalytics/manageChannels/manageChannels.hooks";
import { handlelUserRoles } from "utils/helpers";
import AuthController from "../../../../../services/controllers/authController";
import { CheckValueLocale } from "utils/helpers";
import DisConnect from "images/shared-images/dis-connect.svg";
import Repeat from "images/shared-images/repeat.svg";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accountAuthenticated, setAccountAuthenticated] = useState(false);
  const [openAccountAuthSnakbar, setOpenAccountAuthSnakbar] = useState(false);
  const [openAccountReAuthSnakbar, setOpenAccountReAuthSnakbar] =
    useState(false);
  const [accountReAuthenticated, setAccountReAuthenticated] = useState(false);

  const open = Boolean(stats.anchorEl);

  const handleClick = (event) => {
    setStats({ anchorEl: event.currentTarget });
  };
  const handleClose = () => {
    setStats({ anchorEl: null });
  };
  let is_company_account = true,
    user_id = localStorage.user_id,
    { data_source, id, accountInfoOwner, accountType } = props;

  let excludedDataSources =
    // data_source === "whatsapp" ||
    data_source === "intercom";

  let disableDropdownOptions = props?.user_id == user_id;

  // RE-Authentication
  const handleReAuth = (accountType) => {
    const redirectUrl = window.location.href;
    switch (accountType) {
      case "TWITTER":
        twitterAuth(props?.companyApps, redirectUrl, accountType);
        break;
      case "FACEBOOK":
        facebookAuth(props?.companyApps, redirectUrl, accountType);
        break;
      case "WHATSAPP":
        facebookAuth(
          props?.companyApps,
          redirectUrl,
          accountType,
          undefined,
          undefined,
          "whatsapp",
        );
        break;
      case "GMAIL":
        gmailAuth(props?.companyApps, redirectUrl, accountType);
        break;
      case "INSTAGRAM":
        facebookAuth(props?.companyApps, redirectUrl, accountType);
        break;
      case "GOOGLEMYBUSINESS":
      case "googlemybusiness":
        gmbAuth(props?.companyApps, redirectUrl, accountType);
        break;
      case "GENESYS":
        props?.setOpenGenesysModal(true);
        break;
      case "TIKTOK":
        tiktokAuth(
          redirectUrl,
          props?.tiktokDataSourceId,
          props?.setFailSnackBarData,
          props?.setOpenSnackBarAllSource,
        );
        break;
      case "LINKEDIN":
        linkedInAuth(props?.companyApps, redirectUrl, accountType);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      window.location.href.includes(
        "/settings/accounts_management/company_accounts?success=1",
      )
    ) {
      setAccountReAuthenticated(true);
      setOpenAccountReAuthSnakbar(true);
      history?.push({
        pathname: "/settings/accounts_management/company_accounts",
      });
    } else if (
      window.location.href?.includes(
        "/settings/accounts_management/company_accounts?success=0",
      ) ||
      window.location.href?.includes(
        "/settings/accounts_management/company_accounts?auth=failed",
      )
    ) {
      setAccountReAuthenticated(false);
      setOpenAccountReAuthSnakbar(true);
      history?.push({
        pathname: "/settings/accounts_management/company_accounts",
      });
    }
  }, [window.location.href]);

  const handleCloseReAuthSnackBar = () => {
    setOpenAccountReAuthSnakbar(false);
  };

  // Un-Authentication
  const deleteAuthAccount = async () => {
    AuthController.unAuthAccount(
      data_source,
      id,
      user_id,
      is_company_account,
    ).then((result) => {
      if (!result.errorMsg) {
        // success
        setAccountAuthenticated(true);
        window.location.reload();
      } else {
        // failed
        setAccountAuthenticated(false);
        handleClose();
      }
      setShowDeleteModal(false);
      setOpenAccountAuthSnakbar(true);
    });
  };

  const handleOpenModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseUnAuthSnackBar = () => {
    setOpenAccountAuthSnakbar(false);
  };

  return (
    <div>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          aria-label="more"
          id="settings-company-info-long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          // disabled={!disableDropdownOptions}
        >
          <FontAwesomeIcon
            size="small"
            icon={faEllipsis}
            className="ellipsis-icon"
          />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={
          intl.locale !== "ar" && {
            vertical: "bottom",
            horizontal: "right",
          }
        }
        transformOrigin={
          intl.locale !== "ar" && {
            vertical: "top",
            horizontal: "right",
          }
        }
        className="accounts-action-dropdown"
      >
        <MenuItem
          onClick={() => {
            handleReAuth(props?.accountType);
          }}
          disableRipple
          disabled={
            !accountInfoOwner ||
            (data_source === "whatsapp" &&
              !!props?.whatsappDisableReauth === false)
          }
          className="auth__option"
          id={`company-info-dropdown-re-authenticate-${props?.accountType.toLowerCase()}`}
        >
          <img src={Repeat} />
          {CheckValueLocale("reauthenticate", "", {}, intl)}
        </MenuItem>

        {/* Start Un AUTH ELEMENTS */}
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenModal();
          }}
          disabled={
            (data_source === "whatsapp" && props?.whatsappDisableUnauth) ||
            excludedDataSources ||
            !(
              handlelUserRoles("GENERAL", "DESTROY_USER_AUTH") ||
              handlelUserRoles("GENERAL", "DESTROY_COMPANY_AUTH")
            )
              ? true
              : false
          }
          disableRipple
          className="auth__option"
          id={`company-info-dropdown-un-authenticate-${props?.accountType.toLowerCase()}`}
        >
          <img src={DisConnect} />
          {CheckValueLocale("unauthenticate", "", {}, intl)}
        </MenuItem>
      </Menu>

      {showDeleteModal === true && (
        <PopupModal
          title={CheckValueLocale("unauthenticate_title_popup", "", {}, intl)}
          body={CheckValueLocale(
            "unauthenticate_text_popup",
            "",
            {
              accountIdentifier: props?.accountIdentifier,
              accountUsedIn: props?.accountUsedIn,
            },
            intl,
          )}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("unauthenticate", "", {}, intl)}
          open={true}
          warning={true}
          close={handleCloseModal}
          accept={deleteAuthAccount}
        />
      )}

      <Snackbar
        open={openAccountAuthSnakbar}
        severity={accountAuthenticated ? "success" : "error"}
        message={
          accountAuthenticated
            ? CheckValueLocale("unauthenticate_success_msg", "", {}, intl)
            : CheckValueLocale("try_again", "", {}, intl)
        }
        title={
          accountAuthenticated
            ? null
            : CheckValueLocale("failed_error", "", {}, intl)
        }
        handleClose={handleCloseUnAuthSnackBar}
      />
      {/* End Un AUTH ELEMENTS */}

      <Snackbar
        open={openAccountReAuthSnakbar}
        handleClose={handleCloseReAuthSnackBar}
        severity={accountReAuthenticated ? "success" : "error"}
        message={
          accountReAuthenticated
            ? CheckValueLocale("authenticate_success_msg", "", {}, intl)
            : CheckValueLocale("authenticate_error_msg", "", {}, intl)
        }
        autoHideDuration={7000}
      />
    </div>
  );
};
export default OptionsDropDown;
