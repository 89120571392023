import { useIntl } from "react-intl";
import { Box, Chip } from "@mui/material";
import { useState } from "react";
import "../testYourKeyword.scss";
import { CheckValueLocale } from "utils/helpers";

const TestKeywordShowMoreLess = (props) => {
  const intl = useIntl();
  const { keywords, spamKeywords } = props;

  //  Keyword Slice first 5 items when is greater than 5
  const [isKeywordBtn, setIsKeywordBtn] = useState(false);
  const [keywordShowMoreLess, setKeywordShowMoreLess] = useState(false);
  let cloneKeywordArr = [...keywords];
  let extractedKeywordArr = cloneKeywordArr.slice(0, 5);
  // Spam Keyword Slice first 5 items when is greater than 5
  const [isSpamKeywordBtn, setIsSpamKeywordBtn] = useState(false);
  const [spamKeywordShowMoreLess, SetSpamKeywordShowMoreLess] = useState(false);
  let cloneSpamKeywordArr = [...spamKeywords];
  let extractedSpamKeywordArr = cloneSpamKeywordArr.slice(0, 5);
  // ____________________________________________________________________
  //show more and less spams
  const showMoreKeywords = () => {
    var element = document.getElementById("show-keywords-title");
    element.classList.toggle("show-active-keywords");
    setKeywordShowMoreLess(!keywordShowMoreLess);
    setIsKeywordBtn(!isKeywordBtn);
  };
  const showMoreSpamKeywords = () => {
    var element = document.getElementById("show-spam-keywords-title");
    element.classList.toggle("show-active-spam-keywords");
    SetSpamKeywordShowMoreLess(!spamKeywordShowMoreLess);
    setIsSpamKeywordBtn(!isSpamKeywordBtn);
  };
  // ____________________________________________________________________

  return (
    <>
      <Box className="hint-keyword-text">
        <Box component="span">
          {CheckValueLocale("enter_text_post", "", {}, intl)}
        </Box>
        <Box component="span">
          {CheckValueLocale("test_keyword_based_on", "", {}, intl)}
        </Box>
      </Box>

      {keywords.length !== 0 && (
        <Box className="test-kewyord-chips">
          <Box className="test-keyword-wrapper">
            <Box component="p">
              {CheckValueLocale("keywords", "", {}, intl)}
            </Box>
          </Box>
          <Box
            id="show-keywords-title"
            className={keywords.length > 5 && "show-active-keywords"}
          >
            {/* check if # of keywords is greater than 5 */}
            {keywords.length > 5 && !isKeywordBtn
              ? extractedKeywordArr.map((keyword) => (
                  <Chip label={keyword} className="test-keyword-chip" />
                ))
              : keywords.map((keyword) => (
                  <Chip label={keyword} className="test-keyword-chip" />
                ))}
            {/* show more & show less */}
            {keywords.length > 5 ? (
              keywordShowMoreLess ? (
                <Chip
                  label={CheckValueLocale("show_less", "", {}, intl)}
                  variant="outlined"
                  onClick={showMoreKeywords}
                  className="show-hidden-spam-keywords"
                />
              ) : (
                <Chip
                  label={CheckValueLocale("show_more", "", {}, intl)}
                  variant="outlined"
                  onClick={showMoreKeywords}
                  className="show-hidden-spam-keywords"
                />
              )
            ) : null}
          </Box>
        </Box>
      )}

      {/* spam Keywords */}
      {spamKeywords.length !== 0 && (
        <Box className="test-kewyord-chips">
          <Box className="test-keyword-wrapper">
            <Box component="p">
              {CheckValueLocale("spam_keywords", "", {}, intl)}
            </Box>
          </Box>
          <Box
            id="show-spam-keywords-title"
            className={spamKeywords.length > 5 && "show-active-spam-keywords"}
          >
            {/* check if # of Spam keywords is greater than 5 */}
            {spamKeywords.length > 5 && !isSpamKeywordBtn
              ? extractedSpamKeywordArr.map((keyword) => (
                  <Chip label={keyword} className="test-keyword-chip" />
                ))
              : spamKeywords.map((keyword) => (
                  <Chip label={keyword} className="test-keyword-chip" />
                ))}
            {/* show more & show less */}
            {spamKeywords.length > 5 ? (
              spamKeywordShowMoreLess ? (
                <Chip
                  label={CheckValueLocale("show_less", "", {}, intl)}
                  variant="outlined"
                  onClick={showMoreSpamKeywords}
                  className="show-hidden-spam-keywords"
                />
              ) : (
                <Chip
                  label={CheckValueLocale("show_more", "", {}, intl)}
                  variant="outlined"
                  onClick={showMoreSpamKeywords}
                  className="show-hidden-spam-keywords"
                />
              )
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
};

export default TestKeywordShowMoreLess;
