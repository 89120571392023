import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import InActiveQuestionAccordion from "./component/inActiveQuestionAccordion";
import "./inactiveQuestions.scss";

const InActiveQuestions = () => {
  const intl = useIntl();

  const sectionsHeaderTitle = [
    {
      titleHeader: "social_listening",
      steps: [
        "luci_inactive_1st_step",
        "luci_inactive_social_2st_step",
        "luci_inactive_social_3st_step",
      ],
    },
    {
      titleHeader: "channel_analytics",
      steps: [
        "luci_inactive_1st_step",
        "luci_inactive_social_2st_step",
        "luci_inactive_social_3st_step",
      ],
    },
    {
      titleHeader: "audience",
      steps: [
        "luci_inactive_1st_step",
        "luci_inactive_audience_3st_step",
        "luci_inactive_social_3st_step",
      ],
    },
    {
      titleHeader: "surveys",
      steps: [
        "luci_inactive_1st_step",
        "luci_inactive_survey_2st_step",
        "luci_inactive_social_3st_step",
      ],
    },
    {
      titleHeader: "utilities",
      type: [
        {
          typeTitle: "benchmark",
          steps: [
            "luci_inactive_utilities_1st_step",
            "luci_inactive_benchmark_2nd_step",
            "luci_inactive_benchmark_3rd_step",
          ],
        },
        {
          typeTitle: "reports",
          steps: [
            "luci_inactive_utilities_1st_step",
            "luci_inactive_reports_2nd_step",
            "luci_inactive_reports_3rd_step",
          ],
        },
      ],
    },
  ];

  return (
    <Box className="luci-inactive-ques-wrapper">
      <Box className="luci-inactive-header">
        {CheckValueLocale("luci_unactive_question_header", "", {}, intl)}
      </Box>
      {sectionsHeaderTitle?.map(({ titleHeader, steps, type }, index) => {
        return (
          <InActiveQuestionAccordion
            key={index}
            index={index}
            titleHeader={titleHeader}
            steps={steps}
            type={type || []}
          />
        );
      })}
    </Box>
  );
};

export default InActiveQuestions;
