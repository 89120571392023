const Permissions = {
  general_setting: [
    {
      permission: "user_management",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "third_party_integrations",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "company_level_auth",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "user_level_auth",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "view_users_auth",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "company_settings",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "manage_utilites",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "delete_and_edit_other_users_utilities",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "manage_themes",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "view_themes",
      manager: true,
      moderator: true,
      viwer: true,
    },
  ],

  social_listening: [
    {
      permission: "manage_monitors",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "aggregate_dashboard_permission_create_edit",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "aggregate_dashboard_permission_view",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "aggregate_dashboard_permission_delete",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "delete_and_edit_other_user_monitors",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "Export",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "change_post_attributes",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "quick_search",
      manager: true,
      moderator: false,
      viwer: false,
    },
  ],

  channel_analytics: [
    {
      permission: "manage_channels",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "delete_edit_other_user_channels",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "historical_request",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "aggregate_dashboard_permission_create_edit",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "aggregate_dashboard_permission_view",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "aggregate_dashboard_permission_delete",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "change_post_attributes",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "access_interactions_list",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "create_contacts",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "export_dashboard",
      manager: true,
      moderator: true,
      viwer: true,
    },
  ],

  surveys: [
    {
      permission: "create_survey",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "view_survey",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "edit_survey",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "destroy_survey",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "view_survey_list",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "export_survey",
      manager: true,
      moderator: false,
      viwer: false,
    },
  ],

  audience: [
    {
      permission: "view_audience_list",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "view_user_profile_page",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "edit_user_profile_page",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "delete_profile_page",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "view_interactions_details",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "view_survey_details",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "create_segment",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "update_segment",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "destroy_segment",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "view_segment",
      manager: true,
      moderator: true,
      viwer: true,
    },
    {
      permission: "import_segment",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "export_segment",
      manager: true,
      moderator: false,
      viwer: false,
    },
  ],

  engagement: [
    {
      permission: "egag_acc",
      team_leader: true,
      agent_egagment: true,
      no_acc: false,
    },
    {
      permission: "sl_egag_acc",
      team_leader: true,
      agent_egagment: true,
      no_acc: false,
    },
    {
      permission: "cxm_egag_acc",
      team_leader: true,
      agent_egagment: true,
      no_acc: false,
    },
    {
      permission: "engag_setting_acc",
      team_leader: true,
      agent_egagment: false,
      no_acc: false,
    },
  ],

  ai_agent: [
    {
      permission: "view_ai_agent_settings",
      team_leader: true,
      agent_egagment: true,
      no_acc: false,
    },
    {
      permission: "ai_agent_inbox",
      team_leader: true,
      agent_egagment: true,
      no_acc: false,
    },
    {
      permission: "create_ai_agent",
      team_leader: true,
      agent_egagment: false,
      no_acc: false,
    },
    {
      permission: "pause_resume_ai_agent",
      team_leader: true,
      agent_egagment: false,
      no_acc: false,
    },
    {
      permission: "edit_ai_agent_settings",
      team_leader: true,
      agent_egagment: false,
      no_acc: false,
    },
  ],

  lucidya_api: [
    {
      permission: "manage_public_apis",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "view_public_apis",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "generate_apis_keys",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "re_generate_apis_keys",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "edit_apis_keys",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "delete_apis_keys",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "create_webhook_urls",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "edit_webhook_urls",
      manager: true,
      moderator: false,
      viwer: false,
    },
    {
      permission: "view_webhook_urls",
      manager: true,
      moderator: true,
      viwer: false,
    },
    {
      permission: "delete_webhook_urls",
      manager: true,
      moderator: false,
      viwer: false,
    },
  ],
};
export default Permissions;
