import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import LuciQuestionItem from "./questionItem";
import CircularLoading from "components/circularProgress";
import FreeQuestion from "../freeQuestion/freeQuestion";
import SuccessAnswer from "./components/successAnswer/successAnswer";
import FailedAnswer from "./components/failedAnswer/failedAnswer";
import InActiveQuestions from "./inActiveQuestions/inactiveQuestions";
import { useState } from "react";

const LuciListQuestions = (props) => {
  const {
    luciStaticQuestions,
    questionsLoading,
    notificationStatus,
    isInactive,
    setShowLuciAnswers,
    notificationquestionId,
    setQuestionIdAnswer,
    isSurvey,
    individualSurvey,
    setIndividualSurvey,
    page,
    setPage,
    showConfirmationPopup,
    showSurvey,
    setShowSurvey,
  } = props;

  const intl = useIntl();
  const [showSnackbarShow, setSnackbarShow] = useState(true);

  const getItem = (item) => {
    if (item?.is_free_question === true) {
      return <FreeQuestion {...props} question={item} />;
    }

    return <LuciQuestionItem question={item} {...props} />;
  };

  return (
    <Box className="luci-questions-wrapper">
      {questionsLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Box className="list-questions-container">
            {luciStaticQuestions?.map((item) => {
              return getItem(item);
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default LuciListQuestions;
