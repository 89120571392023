import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import "pages/Monitors/monitors.scss";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

/*---------------------------------------------------------*/
const CommentsMentionsBody = (props) => {
  const {
    response,
    preLoaderTrackerDataSources,
    resetAllFields,
    changePagination,
    setTotalPage,
    totalPage,
    activeTab,
  } = props;

  const [commentsMentions, setCommentsMentions] = useState([]);

  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );

  const [page, setPage] = useState(1);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    changePagination(page);
  };

  // Refresh all values at Each request to default values
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__CommentsMentionsPage__top_posts_comments":
        setCommentsMentions(response.eventData.top_posts);
        if (props.pageNumber == 1) {
          setTotalPage(response.eventData.top_posts_count);
          setPage(1);
        }
        setCommentsMentionsPreloader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  //______________________________________________________________________
  useEffect(() => {
    props.setExcelSheetData(commentsMentions);
  }, [commentsMentions]);

  useEffect(() => {
    props.checkDataIsFounded(commentsMentions);

    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(commentsMentionsPreloader);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );
  }, [commentsMentionsPreloader, commentsMentions]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      commentsMentionsPreloader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([commentsMentions]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    commentsMentions,
    commentsMentionsPreloader,
    props.preLoaderTrackerDataSources,
  ]);

  //______________________________________________________________________

  //handle response with useEffect (when load page & any update)
  useEffect(() => {
    if (resetAllFields !== 1) {
      setCommentsMentions([]);
      setCommentsMentionsPreloader([]);
    }
  }, [resetAllFields]);

  useEffect(() => {
    props?.setTotalPostsCount?.(totalPage);
  }, [totalPage]);

  return (
    <Box mt={2}>
      <QuestionCard
        unixDate={props.unixDate}
        showProfile={true}
        productId={window.localStorage?.getItem("sm_id")}
        latestUnanswer={commentsMentions}
        unAnsweredQuestion={totalPage}
        ShowPagination
        changePagination={changePagination}
        latestUnanswerPreloader={commentsMentionsPreloader}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        icon={faFacebook}
        iconColor={"facebook_icon"}
        hideChartBar
        showHeart={true}
        showDefaultImg={true}
        className={"no-data-found-question"}
        widget={"comments_mentions"}
        monitorType="keyword"
      />
      {totalPage !== 0 && totalPage > 10 && commentsMentions?.length > 0 && (
        <Pagination
          onChange={handleChangePage}
          page={page}
          className="pagination-monitor-list"
          count={Math.ceil(totalPage / 10)}
          variant="outlined"
        />
      )}
    </Box>
  );
};

export default CommentsMentionsBody;
