import { IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import AIAgentTestModalLoading from "./aiAgentTestModalLoading";
import AIAgentChat from "./aiAgentChat";
import AIAgentTextInput from "./aiAgentTextInput";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import io from "socket.io-client";
import Services from "services/api/Services.js";
import { useContext, useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { addChatMessage } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import { useRunAiAgentTest } from "pages/engagements/components/aiAgents/aiAgentsHooks/useRunAiAgentTest";

const RunTestModal = ({ open, handleClose, aiAgentDetails }) => {
  const intl = useIntl();
  const { aiAgentSelected, selectedAiAgentPublishedVersion } =
    useContext(AiAgentsContext);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [isAiAgentThinking, setIsAiAgentThinking] = useState(false);

  const { isPending } = useRunAiAgentTest(aiAgentSelected, open);

  ///engagements
  const socket = useMemo(() => {
    return io(Services.socketUrl + "engagements", {
      path: "/ws/",
      transports: ["websocket"],
      auth: {
        "luc-authorization": localStorage.getItem("user_token"),
      },
    });
  }, []);
  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, [socket]);
  useEffect(() => {
    socket.emit("join_ai_room", {
      company_id: localStorage.getItem("company_id"),
      ai_agent_id: aiAgentSelected,
      user_id: localStorage.getItem("user_id"),
    });
  }, []);

  useEffect(() => {
    socket.on("ai_agent_response", (data) => {
      dispatch(
        addChatMessage({
          text: data?.response,
          isSent: false,
          agentId: aiAgentSelected,
          versionId: selectedAiAgentPublishedVersion || 0,
        }),
      );
      setIsAiAgentThinking(false);
    });
    return () => {
      socket.off("ai_agent_response");
    };
  }, []);

  const handleReply = () => {
    setIsAiAgentThinking(true);
    const request = {
      company_id: localStorage.getItem("company_id"),
      ai_agent_id: aiAgentSelected,
      message,
      user_id: localStorage.getItem("user_id"),
    };
    setMessage("");
    dispatch(
      addChatMessage({
        text: message,
        isSent: true,
        agentId: aiAgentSelected,
        versionId: selectedAiAgentPublishedVersion,
      }),
    );
    socket.emit("ai_agent_request", request);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="ai-agent-chat-container">
        <div className="ai-agent-chat-header">
          <div className="ai-agent-chat-header-stack">
            <Typography className="ai-agent-chat-header-title">
              {CheckValueLocale("ai-agent-modal-title", "", {}, intl)}
            </Typography>
            <Tooltip
              title={CheckValueLocale(
                "ai-agent-modal-tooltip-label",
                "",
                {},
                intl,
              )}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ai-agent-chat-header-info-icon"
              />
            </Tooltip>
          </div>
          <IconButton onClick={handleClose}>
            <Tooltip
              title={CheckValueLocale("ai-agent-modal-close", "", {}, intl)}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="ai-agent-drawer-exit"
              />
            </Tooltip>
          </IconButton>
        </div>
        {isPending ? (
          <div className="ai-agent-chat-loading-container">
            <AIAgentTestModalLoading />
          </div>
        ) : (
          <div className="ai-agent-chat-relative-box">
            <AIAgentChat
              aiAgentDetails={aiAgentDetails}
              isAiAgentThinking={isAiAgentThinking}
            />
            <AIAgentTextInput
              message={message}
              setMessage={setMessage}
              handleReply={handleReply}
              isAiAgentThinking={isAiAgentThinking}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RunTestModal;
