import Services from "./Services";

var Notifications = {};

Notifications.getNotifications = (queryData) => {
  return Services.getData("api/v3/notifications", queryData);
};

Notifications.getNotificationsCount = () => {
  return Services.getData("api/v3/notifications/count");
};

Notifications.getNotificationsDownloadLink = (id) => {
  return Services.getData(`api/v3/notifications/${id}`);
};
Notifications.getNotificationsXlsxDownload = (id) => {
  return Services.getData(`api/v3/notifications/${id}/xlsx_download`);
};
export default Notifications;
