import { useIntl } from "react-intl";
import { Box, Typography, Tooltip } from "@mui/material";
import { CheckValueLocale, isEmptyArray, truncate } from "utils/helpers";
import moment from "moment";
import "./appliedFilters.scss";

const AppliedFilters = ({
  filterParams,
  startDate,
  endDate,
  isAnswerPage,
  showSurveyquestion,
  isBenchmark,
}) => {
  const intl = useIntl();
  const showFiltersText = (text, textLength, filterName) => {
    return (
      <>
        {text?.length > textLength && filterName !== "monitors" ? (
          <Tooltip
            title={
              <Box className="tooltip-container">
                <Typography className="filters-title-tooltip">
                  {CheckValueLocale(filterName, "", {}, intl)}:
                </Typography>
                <Typography className="filters-title-val-tooltip">
                  {text}
                </Typography>
              </Box>
            }
            arrow
            placement={
              window?.localStorage?.lang === "en" ? "left-start" : "right-start"
            }
            id="filters-content-tooltip"
          >
            <Typography className="filter-value">
              {truncate(text, textLength)}
            </Typography>
          </Tooltip>
        ) : (
          <Typography className="filter-value">
            {CheckValueLocale(text, "", {}, intl)}
          </Typography>
        )}
      </>
    );
  };
  const getFiltersValues = (data) => {
    let filteredData = [];
    if (Array?.isArray(data) && !isEmptyArray(data)) {
      data?.map((val) => {
        filteredData.push(CheckValueLocale(val, "", {}, intl));
      });
    } else if (typeof data === "string" && data !== "" && isAnswerPage) {
      data?.split(",")?.map((val) => {
        filteredData.push(CheckValueLocale(val, "", {}, intl));
      });
    } else if (
      (typeof data === "string" && data !== "") ||
      typeof data === "boolean"
    ) {
      filteredData.push(data);
    }

    return filteredData?.join(", ");
  };
  let isRangeDate = startDate || endDate;

  return (
    <Box className="all-applied-filters">
      <Box className="applied-filter-item">
        {isRangeDate ? (
          <Typography className="filter-name">
            {CheckValueLocale("luci_date_range", "", {}, intl)}:
          </Typography>
        ) : null}
        <Typography className="filter-value">
          {startDate ? moment.unix(startDate).utc().format("DD/MM/YYYY ") : ""}{" "}
          -{endDate ? moment.unix(endDate).utc().format("DD/MM/YYYY ") : ""}
        </Typography>
      </Box>

      {filterParams
        ? Object.entries(filterParams)
            ?.sort(([keyA], [keyB]) => {
              if (isBenchmark === true) {
                if (keyA === "monitors") return 1;
                if (keyB === "monitors") return -1;
              }
              return 0;
            })
            ?.map(([item, index]) => (
              <Box className="applied-filter-item" key={item}>
                {item && getFiltersValues(filterParams[item]) ? (
                  <Typography className="filter-name">
                    {CheckValueLocale(item, "", {}, intl)}:
                  </Typography>
                ) : null}
                {showFiltersText(
                  getFiltersValues(
                    item === "manual_topics"
                      ? filterParams[item]?.map((el) => el?.value)
                      : filterParams[item],
                  ),
                  30,
                  item,
                )}
              </Box>
            ))
        : null}
    </Box>
  );
};

export default AppliedFilters;
