import { Container, Box } from "@mui/material";
import AlertTitleDate from "../alertTitleDate/alertTitleDate.js";
import "./mainAlertView.scss";
import SLDatasourcesAlerts from "./components/SLDatasources/SLDatasourcesAlerts.js";
import CADatasourcesAlerts from "./components/CADatasources/CADatasourcesAlerts.js";
import SurveyResponseAlert from "./components/surveys/surveyResponseAlert.js";

const MainAlertView = (props) => {
  let {
    response,
    alertDetailType,
    alertDetailDataSource,
    alertCreatedAt,
    alertName,
    alertDetailMonitorId,
    alertDetailMonitorName,
    resetAllFields,
    alertMonitorType,
    alertDetailProductType,
    surveyPreloaderDatasource,
    page,
    setPage,
    alertsDataWidgets,
  } = props;
  return alertDetailProductType === "survey" ? (
    <Box>
      <SurveyResponseAlert
        response={response}
        alertDetailDataSource={alertDetailDataSource}
        alertDetailType={alertDetailType}
        alertDetailMonitorId={alertDetailMonitorId}
        resetAllFields={resetAllFields}
        alertMonitorType={alertMonitorType}
        alertDetailProductType={alertDetailProductType}
        surveyName={alertDetailMonitorName}
        surveyPreloaderDatasource={surveyPreloaderDatasource}
        alertCreatedAt={alertCreatedAt}
      />
    </Box>
  ) : (
    <Container maxWidth="xl" className="main-alert-view">
      <AlertTitleDate
        alertDetailType={alertDetailType}
        alertDetailDataSource={alertDetailDataSource}
        endhour={alertCreatedAt}
        alertName={alertName}
        alertDetailMonitorName={alertDetailMonitorName}
        alertDetailProductType={alertDetailProductType}
      />

      <>
        {alertDetailProductType === "cxm" ? (
          <CADatasourcesAlerts
            response={response}
            alertDetailDataSource={alertDetailDataSource}
            alertDetailType={alertDetailType}
            alertDetailMonitorId={alertDetailMonitorId}
            resetAllFields={resetAllFields}
            alertMonitorType={alertMonitorType}
            alertDetailProductType={alertDetailProductType}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SLDatasourcesAlerts
            response={response}
            alertDetailDataSource={alertDetailDataSource}
            alertDetailType={alertDetailType}
            alertDetailMonitorId={alertDetailMonitorId}
            resetAllFields={resetAllFields}
            alertMonitorType={alertMonitorType}
            alertDetailProductType={alertDetailProductType}
            page={page}
            setPage={setPage}
            alertsDataWidgets={alertsDataWidgets}
          />
        )}
      </>
    </Container>
  );
};

export default MainAlertView;
