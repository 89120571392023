import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import OverView from "components/widgets/overViewWidget";
import { CheckValueLocale } from "utils/helpers/index";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import { getNoOfDays } from "utils/helpers";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import _ from "lodash";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import xPlatform from "images/social-icons/x-platform-icon.svg";
const TwitterBody = (props) => {
  const intl = useIntl();
  let {
    response,
    startDate,
    endDate,
    setExcelSheetData,
    setCheckAllDataFetchingExport,
    preLoaderTrackerDataSources,
  } = props;

  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoaderDataSources: [],

    //interaction widget
    interactions: [],
    totalInteractions: [],
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentOvertime: [],
    sentimentPiechart: [],
    sentimentAnalysisPreLoaderDataSources: [],
    negativeArray: [],
    positiveArray: [],
    neutralArray: [],

    //theme widget
    themeAnalysis: [],
    themeTrend: [],
    themesXlsx: [],
    themeAnalysisPreLoaderDataSources: [],
    themeTrendPreLoaderDataSources: [],

    //dialects sub dialects widget
    excelDialects: [],
    excelSubDialects: [],
    dialects: [],
    subDialects: [],
    dialectsPreLoaderDataSources: [],

    //channel account widget
    channelAccounts: [],
    channelAccountsPreLoaderDataSources: [],

    //content type widget
    excelContentType: [],
    contentType: [],
    contentTypePreLoaderDataSources: [],

    authorActivity: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //gender widget
    genders: [],
    excelGenderDistribution: [],
    gendersPreLoaderDataSources: [],

    //most engaged widget
    mostEngagedContacts: [],
    mostInfluentialContacts: [],
    verifiedContacts: [],
    mostEngagedContactsPreLoaderDataSources: [],
    mostInfluentialContactsPreLoaderDataSources: [],
    verifiedContactsPreLoaderDataSources: [],

    //latest interaction widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],

    //contact activity widget
    contactActivity: [],
    minValueContactActivity: 0,
    maxValueContactActivity: 0,
    contactActivityPreLoaderDataSources: [],
  };

  const [twitterWidgets, setTwitterWidgets] = useState(initialWidgetsState);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  const twitterIcon = (
    <>
      <img
        src={xPlatform}
        alt="x-platform-logo"
        className="icon-after-title twitter-icon"
      />
    </>
  );
  useEffect(() => {
    let x = checkLuciAllDataFounded(
      twitterWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([twitterWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    twitterWidgets?.latestInteractions,
    twitterWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  useEffect(() => {
    switch (response?.eventName) {
      case "overview":
        if (response?.eventData?.overview) {
          let overViewArray = Object?.entries(
            response?.eventData?.overview,
          )?.map((key) => ({
            name: key[0],
            value: key[1],
          }));
          setTwitterWidgets({
            ...twitterWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "interactions":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setTwitterWidgets({
          ...twitterWidgets,
          interactions: response?.eventData?.interactions,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteractions: response?.eventData?.total_interactions,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "top_keywords":
        setTwitterWidgets({
          ...twitterWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "dialects":
        setTwitterWidgets({
          ...twitterWidgets,
          excelDialects: _.cloneDeep([...response.eventData.dialects]),
          excelSubDialects: _.cloneDeep([...response.eventData.sub_dialects]),
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          dialectsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "genders":
        setTwitterWidgets({
          ...twitterWidgets,
          genders: response?.eventData?.genders,
          excelGenderDistribution: _.cloneDeep([...response.eventData.genders]),
          gendersPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "channel_accounts":
        let newChannelAccounts = [];
        response?.eventData?.channel_accounts?.map((channel) => {
          newChannelAccounts?.push({
            name:
              window?.localStorage?.lang === "en"
                ? "@" + channel?.name
                : channel?.name + "@",
            value: channel?.value,
          });
        });
        setTwitterWidgets({
          ...twitterWidgets,
          channelAccounts: newChannelAccounts,
          channelAccountsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "content_type":
        setTwitterWidgets({
          ...twitterWidgets,
          excelContentType: _.cloneDeep([
            ...response?.eventData?.content_type_piechart,
          ]),
          contentType: response?.eventData?.content_type_piechart,
          contentTypePreLoaderDataSources: [response?.data_source],
        });
        break;
      case "latest_interactions":
        setTwitterWidgets({
          ...twitterWidgets,
          latestInteractions: response?.eventData?.latest_interactions,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.interactions_count,
        });
        break;

      case "sentiment":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return twitterWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return twitterWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return twitterWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setTwitterWidgets({
          ...twitterWidgets,
          sentimentOvertime: {
            negative: twitterWidgets?.negativeArray,
            positive: twitterWidgets?.positiveArray,
            neutral: twitterWidgets?.neutralArray,
          },
          sentimentPiechart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoaderDataSources: [response?.data_source],
        });

        break;
      case "themes":
        setTwitterWidgets({
          ...twitterWidgets,
          themesXlsx: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysisPreLoaderDataSources: [response?.data_source],
          themeTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "top_engaged_authors":
        setTwitterWidgets({
          ...twitterWidgets,
          mostEngagedContacts: response?.eventData?.top_engaged_authors,
          mostEngagedContactsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "top_influencers":
        setTwitterWidgets({
          ...twitterWidgets,
          mostInfluentialContacts: response?.eventData?.top_influencers,
          mostInfluentialContactsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "verified_authors":
        setTwitterWidgets({
          ...twitterWidgets,
          verifiedContacts: response?.eventData?.verified_authors,
          verifiedContactsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "channel_activity":
        setTwitterWidgets({
          ...twitterWidgets,
          minValueContactActivity:
            response?.eventData?.channel_activity?.min_value_client,
          maxValueContactActivity:
            response?.eventData?.channel_activity?.max_value_client,
          contactActivity: response?.eventData?.channel_activity?.client,
          contactActivityPreLoaderDataSources: [response?.data_source],
        });
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTwitterWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: twitterWidgets?.totalInteractions,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: twitterWidgets?.avgInteractions,
    },
  ];
  useEffect(() => {
    let x = checkLuciAllDataFounded(
      twitterWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([twitterWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    twitterWidgets?.latestInteractions,
    twitterWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  useEffect(() => {
    let excelData = { ...twitterWidgets };
    let authorAcc = [
      twitterWidgets?.contactActivity,
      twitterWidgets?.minValueContactActivity,
      twitterWidgets?.maxValueContactActivity,
    ];
    excelData["authorActivity"] = [...authorAcc];
    setExcelSheetData([{ ...excelData }]);
  }, [twitterWidgets]);

  // check fetching for export excel
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      twitterWidgets?.overViewPreLoaderDataSources,
      twitterWidgets?.interactionsPreLoaderDataSources,
      twitterWidgets?.sentimentAnalysisPreLoaderDataSources,
      twitterWidgets?.themeAnalysisPreLoaderDataSources,
      twitterWidgets?.themeTrendPreLoaderDataSources,
      twitterWidgets?.dialectsPreLoaderDataSources,
      twitterWidgets?.channelAccountsPreLoaderDataSources,
      twitterWidgets?.contentTypePreLoaderDataSources,
      twitterWidgets?.contactActivityPreLoaderDataSources,
      twitterWidgets?.topKeywordsPreLoaderDataSources,
      twitterWidgets?.gendersPreLoaderDataSources,
      twitterWidgets?.mostEngagedContactsPreLoaderDataSources,
      twitterWidgets?.mostInfluentialContactsPreLoaderDataSources,
      twitterWidgets?.verifiedContactsPreLoaderDataSources,
      twitterWidgets?.latestInteractionsPreLoaderDataSources,
    ];
    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      preLoaderTrackerDataSources,
    );
    setCheckAllDataFetchingExport(result);
  }, [
    twitterWidgets?.overViewPreLoaderDataSources,
    twitterWidgets?.interactionsPreLoaderDataSources,
    twitterWidgets?.sentimentAnalysisPreLoaderDataSources,
    twitterWidgets?.themeAnalysisPreLoaderDataSources,
    twitterWidgets?.themeTrendPreLoaderDataSources,
    twitterWidgets?.dialectsPreLoaderDataSources,
    twitterWidgets?.channelAccountsPreLoaderDataSources,
    twitterWidgets?.contentTypePreLoaderDataSources,
    twitterWidgets?.contactActivityPreLoaderDataSources,
    twitterWidgets?.topKeywordsPreLoaderDataSources,
    twitterWidgets?.gendersPreLoaderDataSources,
    twitterWidgets?.mostEngagedContactsPreLoaderDataSources,
    twitterWidgets?.mostInfluentialContactsPreLoaderDataSources,
    twitterWidgets?.verifiedContactsPreLoaderDataSources,
    twitterWidgets?.latestInteractionsPreLoaderDataSources,
  ]);
  const unixDate = {
    start: startDate,
    end: endDate,
  };
  return (
    <>
      <OverView
        data={twitterWidgets?.overview}
        overViewPreLoaderDataSources={
          twitterWidgets?.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        directMessage={true}
      />
      <Box my={4}>
        <LineChartWidget
          title={"conversation_interactions"}
          titleToolTip="conversation_interaction_tooltip"
          data={twitterWidgets?.interactions}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/social-media/x-platform-private/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          iconAfterTitle={twitterIcon}
          lineChartPreLoaderDataSources={
            twitterWidgets?.interactionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      {/* //__________________________________________________ */}
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl
          .formatMessage({ id: "closed_conversation_overview" })
          .toUpperCase()}
      </Typography>
      <Box my={4}>
        <StackedLineWidget
          title={"sentiment_analysis"}
          titleToolTip="sentiments_dm_tooltip"
          data={twitterWidgets?.sentimentOvertime}
          dataPiechart={twitterWidgets?.sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            twitterWidgets?.sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={twitterIcon}
          showScales
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysisShowCharts
        />
      </Box>

      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          title={"themes"}
          titleToolTip="themes_dm_tooltip"
          data={twitterWidgets?.themeAnalysis}
          showDownloadIcon
          bigImg
          iconAfterTitle={twitterIcon}
          themeAnalysisPreLoaderDataSources={
            twitterWidgets?.themeAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          titleToolTip="themes_trend_dm_tooltip"
          data={twitterWidgets?.themeTrend}
          showGroupBy={false}
          showDownloadIcon
          bigImg
          iconAfterTitle={twitterIcon}
          themeTrendPreLoaderDataSources={
            twitterWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Grid container spacing={2} my={4}>
        <Grid item md={4} className="dialect-subdialect-widget">
          <PieChartWidget
            title={"dialects_subdialects"}
            titleToolTip="dialects_subdialects_dm_tooltip"
            data={twitterWidgets?.dialects}
            subData={twitterWidgets?.subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            iconAfterTitle={twitterIcon}
            pieChartPreLoaderDataSources={
              twitterWidgets?.dialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>

        <Grid item md={4}>
          <PieChartWidget
            title={"channel_accounts"}
            titleToolTip="channel_accounts_dm_tooltip"
            data={twitterWidgets?.channelAccounts}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            iconAfterTitle={twitterIcon}
            color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
            pieChartPreLoaderDataSources={
              twitterWidgets?.channelAccountsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            isChannelAccounts
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"content_type"}
            titleToolTip="content_type_dm_tooltip"
            data={twitterWidgets?.contentType}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            iconAfterTitle={twitterIcon}
            pieChartPreLoaderDataSources={
              twitterWidgets?.contentTypePreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Box my={4} className="channel-activity">
        <PunchCardWidget
          title="contact_activity"
          titleToolTip="channel_activity_dm_tooltip"
          clientActivity={twitterWidgets?.contactActivity}
          minValueClient={twitterWidgets?.minValueContactActivity}
          maxValueClient={twitterWidgets?.maxValueContactActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={twitterIcon}
          authorActivityPreLoaderDataSources={
            twitterWidgets?.contactActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      {/* //__________________________________________________ */}
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl
          .formatMessage({ id: "closed_conversation_depth_analysis" })
          .toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            wordsList
            title={"insights_top_keywords"}
            titleToolTip="top_kewords_dm_tooltip"
            data={twitterWidgets?.topKeywords}
            leftColomnTitle={"keywords_tab_title"}
            rightColomnTitle={"number_of_conversations"}
            tableChartPreLoaderDataSources={
              twitterWidgets?.topKeywordsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            keywords
            bigImg
            tablePagination
            showDownloadIcon
            count={twitterWidgets?.topKeywords.length}
            iconAfterTitle={twitterIcon}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale("contacts_overview", "", {}, intl).toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PieChartWidget
            title={"genders_distribution"}
            titleToolTip="genders_tooltip"
            data={twitterWidgets?.genders}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#2AAAE2", "#FF4081"]}
            chartType={"gender"}
            iconAfterTitle={twitterIcon}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={
              twitterWidgets?.gendersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
        {/* {Keep it may be use them later} */}
        {/* <Grid item md={4}>
          <PieChartWidget
            title={"top_countries"}
            data={countries}
            titleToolTip="top_countries_tooltip"
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            iconAfterTitle={twitterIcon}
            pieChartPreLoaderDataSources={countriesPreLoaderDataSources}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>

        <Grid item md={4}>
          <PieChartWidget
            title={"top_cities"}
             titleToolTip="top_cities_tooltip"
             data={cities}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            iconAfterTitle={twitterIcon}
             pieChartPreLoaderDataSources={citiesPreLoaderDataSources}
             preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid> */}
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale(
          "contacts_in_depth_analysis",
          "",
          {},
          intl,
        ).toUpperCase()}
      </Typography>
      <Grid container spacing={2} my={4}>
        <Grid item xs={4}>
          <TableWidget
            circularPP={true}
            itemsList
            title={"most_engaged_contacts"}
            titleToolTip="most_engaged_dm_tooltip"
            data={twitterWidgets?.mostEngagedContacts}
            leftColomnTitle={"user_author"}
            rightColomnTitle={"interactions"}
            tableChartPreLoaderDataSources={
              twitterWidgets?.mostEngagedContactsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            tablePagination
            showDownloadIcon
            bigImg
            count={twitterWidgets?.mostEngagedContacts?.length}
            iconAfterTitle={twitterIcon}
          />
        </Grid>
        <Grid item md={4}>
          <TableWidget
            circularPP={true}
            itemsList
            title={"most_influential_contacts"}
            titleToolTip="most_influential_tooltip"
            data={twitterWidgets?.mostInfluentialContacts}
            leftColomnTitle={"user_author"}
            rightColomnTitle={"personal_followers"}
            tableChartPreLoaderDataSources={
              twitterWidgets?.mostInfluentialContactsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            twitterIcon={twitterIcon}
            tablePagination
            bigImg
            showDownloadIcon
            count={twitterWidgets?.mostInfluentialContacts?.length}
            iconAfterTitle={twitterIcon}
          />
        </Grid>

        <Grid item md={4}>
          <TableWidget
            circularPP={true}
            itemsList
            title={"verified_contacts"}
            titleToolTip="verified_contacts_tooltip"
            data={twitterWidgets?.verifiedContacts?.authors}
            leftColomnTitle={"user_author"}
            rightColomnTitle={"personal_followers"}
            tableChartPreLoaderDataSources={
              twitterWidgets?.verifiedContactsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            twitterIcon={twitterIcon}
            tablePagination
            showDownloadIcon
            bigImg
            count={twitterWidgets?.verifiedContacts?.authors?.length}
            statsDetailsData={[
              {
                name: "verified_contacts",
                value: twitterWidgets?.verifiedContacts?.count,
              },
              {
                name: "total_accounts",
                value: twitterWidgets?.verifiedContacts?.percentage + "%",
                showStringValue: true,
              },
            ]}
            showChartDetailsWidget
            iconAfterTitle={twitterIcon}
            isVerifiedContacts
          />
        </Grid>
      </Grid>

      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={unixDate}
          data={twitterWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isPrivateTwitter
          latestInteractionsPreLoaderDataSources={
            twitterWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={twitterIcon}
          showViewMoreButton={twitterWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default TwitterBody;
