//this shared components is to check for all keyword length.

// function to check keywotd length and split any special character [ ' , ^ , # , ""] from keyword
export const checkKeywordLength = (word) => {
  let newWords = "";

  word.split("").map((i) => {
    if (i === "'" || i === '"' || i === "^" || i === "#" || i === "") {
      return false;
    } else {
      newWords += i;
    }
  });
  if (newWords.length > 60) {
    return true;
  } else {
    return false;
  }
};

// function to get get keyword and check length then split any special character.
export const getKeywordsNewsLength = (keywords) => {
  let keywordsCount = 0;
  let wordsInQoute = null;

  keywords.forEach((keyword) => {
    if (
      (keyword[0] === "'" && keyword[keyword.length - 1] === "'") ||
      (keyword[0] === '"' && keyword[keyword.length - 1] === '"')
    ) {
      if (!checkKeywordLength(keyword)) {
        keywordsCount = keyword.toString().split(/[,،' ']+/).length;
      }
    } else {
      wordsInQoute = keyword.split(/[\s,]+/);
      if (!checkKeywordLength(keyword)) {
        keywordsCount = keywordsCount + wordsInQoute.length;
      }
    }
  });

  return keywordsCount;
};

// function to retun keyword length as counter in top of every dataSource
export const getKeywordsLength = (keywords) => {
  let keywordsCount = 0;
  let wordsInQoute = null;

  keywords.forEach((keyword) => {
    if (
      (keyword[0] === "'" && keyword[keyword.length - 1] === "'") ||
      (keyword[0] === '"' && keyword[keyword.length - 1] === '"') ||
      (keyword[0] === "^" &&
        keyword[1] === '"' &&
        keyword[keyword.length - 1] === '"') ||
      (keyword[keyword.length - 1] === "^" &&
        keyword[0] === '"' &&
        keyword[keyword.length - 2] === '"' &&
        !keyword.match(/[a-z]/i))
    ) {
      if (!checkKeywordLength(keyword)) {
        keywordsCount++;
      }
    } else {
      wordsInQoute = keyword.split(/[\s,]+/);
      if (!checkKeywordLength(keyword)) {
        keywordsCount = keywordsCount + wordsInQoute.length;
      }
    }
  });

  return keywordsCount;
};
