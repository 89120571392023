import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  MenuItem,
  Select,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import _ from "lodash";

import { Calendar, momentLocalizer } from "react-big-calendar";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { CheckValueLocale, getTimeZone } from "utils/helpers";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Filter from "shared/general-filter/filter";
import "./calender.scss";
import { Add } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment-timezone";
import LucButton from "shared/lucButton/lucButton";
import dayjs from "dayjs";
const localizer = momentLocalizer(moment);

const PublishCalendar = (props) => {
  const [defaultDate, setDefaultDate] = useState("");

  const intl = useIntl();

  moment.tz.setDefault(
    parseInt(localStorage.companyInfo) == 0
      ? "Etc/GMT"
      : `Etc/GMT${
          parseInt(localStorage.companyInfo) < 0
            ? `+${parseInt(localStorage.companyInfo) * -1}`
            : `-${parseInt(localStorage.companyInfo)}`
        }`,
  );

  const {
    date,
    setDate,
    openPopupPublish,
    setOpenPublish,
    publishTypeEvent,
    setPublishTypeEvent,
    publishSpecifiedDate,
    setPublishSpecifiedDate,
    setPublishedTypeSelected,
    setShowSnackBar,
    accountData,
    calendarData,
    getCalendarData,
    selectedAccoutnts,
    handleDateFormat,
    assignActiveFilters,
    setSelectedEventDetails,
    getDetailsPopupPublish,
    setIdPublishedShow,
    accountsLoader,
  } = props;

  let viewList = ["month", "week", "day"];
  const [currentView, setCurrentView] = useState("week");
  //  need it when start integration
  const [rightToLeft, setRightToLeft] = useState(false);
  const [culture, setCulture] = useState("ar");
  const [monthValue, setMonthValue] = useState(date?.getMonth() + 1);
  const [yearValue, setYearValue] = useState(date.getFullYear());
  const handleViewChange = (view) => {
    setCurrentView(view);
  };
  const handleNavigate = (action) => {
    switch (action) {
      case "PREV":
        if (currentView === "month") {
          setDate(moment(date).subtract(1, "month").toDate());
        } else if (currentView === "week") {
          setDate(moment(date).subtract(1, "week").toDate());
        } else if (currentView === "day") {
          setDate(moment(date).subtract(1, "day").toDate());
        }
        break;
      case "NEXT":
        if (currentView === "month") {
          setDate(moment(date).add(1, "month").toDate());
        } else if (currentView === "week") {
          setDate(moment(date).add(1, "week").toDate());
        } else if (currentView === "day") {
          setDate(moment(date).add(1, "day").toDate());
        }
        break;
      case "TODAY":
        setDate(new Date());
        break;
      default:
        break;
    }
  };

  //format date
  const formatDate = (date) => {
    if (currentView === "day") {
      return date?.toLocaleDateString(intl.locale == "en" ? "en-us" : "ar", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    return date?.toLocaleDateString(intl.locale == "en" ? "en-us" : "ar", {
      year: "numeric",
      month: "long",
    });
  };

  const handleApiCalender = (dateValue) => {
    let { startDate, endDate } = handleDateFormat(dateValue);
    getCalendarData(startDate, endDate, assignActiveFilters, selectedAccoutnts);
  };

  //custom toolbar
  // here we call api if we change month year filters account
  useEffect(() => {
    setMonthValue(date?.getMonth() + 1);
    setYearValue(date.getFullYear());
  }, [date]);
  useEffect(() => {
    if (accountsLoader) handleApiCalender(date);
  }, [monthValue, yearValue, accountsLoader]);
  const CustomToolbar = ({ date, view, onView, handleNavigate }) => {
    //here we change view
    const handleViewChange = (event) => {
      onView(event.target.value);
    };
    // handle validation for datePicker
    const handleDateChange = (val) => {
      // TO CHECK IF VALID DATE S
      if (!isNaN(Date.parse(val))) {
        setCurrentView("day");
        onView("day");
        setDate(val?.toDate());
      }
    };
    const [openPicker, setOpenPicker] = useState(false);
    const handleTogglePicker = () => {
      setOpenPicker((prevOpenPicker) => !prevOpenPicker);
    };
    return (
      <Toolbar className="toolbar-calender">
        <Box className="date-format-steps">
          <Box className="navigate-steps-buttons">
            <Tooltip
              arrow
              placement="bottom"
              title={CheckValueLocale(
                `previous_${currentView?.toLowerCase()}`,
                "",
                {},
                intl,
              )}
            >
              <IconButton onClick={() => handleNavigate("PREV")}>
                <ChevronLeft />
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              placement="bottom"
              title={CheckValueLocale(
                `next_${currentView?.toLowerCase()}`,
                "",
                {},
                intl,
              )}
            >
              <IconButton onClick={() => handleNavigate("NEXT")}>
                <ChevronRight />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography component={"p"} className="date-format-title">
            {formatDate(date)}
          </Typography>
        </Box>
        <Box className="date-view-content">
          <Button
            variant="outlined"
            onClick={() => handleNavigate("TODAY")}
            className="today-btn-content"
          >
            {CheckValueLocale("today", "", {}, intl)}
          </Button>
          <Box
            className={`date-picker-publish-header ${
              openPicker && "open-picker"
            }`}
            onClick={() => setOpenPicker(true)}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={dayjs(date)}
                onChange={handleDateChange}
                open={openPicker}
                onOpen={handleTogglePicker}
                onClick={() => setOpenPicker(true)}
                onClose={handleTogglePicker}
                renderInput={(params) => (
                  <TextField {...params} onClick={() => setOpenPicker(true)} />
                )}
                className="date-picker-calender"
              />
            </LocalizationProvider>
          </Box>
          <Select
            value={view}
            onChange={handleViewChange}
            className="select-view-items-cale"
          >
            {viewList?.map((item) => {
              return (
                <MenuItem value={item}>
                  {CheckValueLocale(item?.toLowerCase(), "", {}, intl)}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box></Box>
      </Toolbar>
    );
  };

  //if  need to return formatof title

  const lang = {
    en: null,
    "ar-AE": {
      week: "أسبوع",
      work_week: "أسبوع العمل",
      day: "يوم",
      month: "شهر",
      previous: "سابق",
      next: "التالي",
      today: "اليوم",

      showMore: (total) => `+${total} إضافي`,
    },
  };

  const cultureOnClick = useCallback(
    ({ target: { value } }) => {
      // really better to useReducer for simultaneously setting multiple state values
      setCulture(value);
      setRightToLeft(value === "ar-AE");
    },
    [setCulture],
  );
  // const { defaultDate, messages } = useMemo(
  //   () => ({
  //     defaultDate: new Date(2015, 3, 1),
  //     messages: lang[culture],
  //   }),
  //   [culture]
  // );

  //  need it when start integration
  const handleSelectSlot = (slotInfo) => {
    let correct_value = parseInt(localStorage.companyInfo) - getTimeZone();
    slotInfo?.slots?.map((dateTime) => {
      const originalDate = new Date(dateTime);
      const updatedDate = new Date(
        originalDate.getTime() + correct_value * 60 * 60 * 1000,
      ); // Add 2 hours
      return updatedDate.toISOString(); // Convert back to ISO string format
    });

    slotInfo.start = new Date(
      new Date(slotInfo.start).getTime() + correct_value * 60 * 60 * 1000,
    );

    //when empty cell fire events
    // Handle event when a slot (empty space) is selected
    setShowSnackBar(false);
    setPublishedTypeSelected("scheduled");
    setOpenPublish(true);
    setPublishTypeEvent("create_publish");
    setPublishSpecifiedDate(slotInfo.start);
  };

  const handleSelectEvent = (event) => {
    //when no empty  had events cell fire events
    // Handle event when an event is selected
    let correct_value = parseInt(localStorage.companyInfo) - getTimeZone();
    event?.slots?.map((dateTime) => {
      const originalDate = new Date(dateTime);
      const updatedDate = new Date(
        originalDate.getTime() + correct_value * 60 * 60 * 1000,
      );
      return updatedDate.toISOString();
    });

    event.start = new Date(
      new Date(event.start).getTime() + correct_value * 60 * 60 * 1000,
    );

    setShowSnackBar(false);
    setPublishedTypeSelected("scheduled");
    setOpenPublish(true);
    setPublishTypeEvent("show_publish");
    setPublishSpecifiedDate(event.start);
    setIdPublishedShow(event?.id);
  };

  let checkLocale = window.localStorage.companyInfo;

  const handleAddTimeHeader = () => {
    let cuuDateUNix =
      moment(new Date()).unix() -
      getTimeZone() * 60 * 60 +
      parseInt(localStorage.companyInfo) * 60 * 60 +
      60 * 60;
    let dateNews = new Date(cuuDateUNix * 1000);
    let formattedDates = dateNews?.toISOString();
    const curentdateVal = new Date(formattedDates);

    setOpenPublish(true);
    setPublishedTypeSelected("direct");
    setPublishTypeEvent("create_publish");
    setPublishSpecifiedDate(curentdateVal);
  };

  const formats = {
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, "hh:mm a", culture),
    weekdayFormat: (date, culture, localizer) =>
      CheckValueLocale(localizer.format(date, "ddd", culture), "", {}, intl),
    dayFormat: (date, culture, localizer) =>
      CheckValueLocale(localizer.format(date, "ddd", culture), "", {}, intl) +
      " " +
      localizer.format(date, "DD", culture),
  };

  const eventRenderProps = (event, start, end, isSelected) => {
    let result = { className: event?.status + "-event" };
    return result;
  };

  const getonvertDateCalender = (datesVal) => {
    let ss =
      moment(datesVal).unix() +
      parseInt(localStorage.companyInfo) * 60 * 60 -
      getTimeZone() * 60 * 60;
    let dateS = new Date(ss * 1000);
    return dateS;
  };
  return (
    <Box
      className={`calendar-section-container ${
        currentView === "day" ? "day-calendar-section" : ""
      }`}
    >
      {currentView !== "month" ? (
        <Box className="calendar-gmt">
          GMT{checkLocale >= 0 ? "+" + checkLocale : checkLocale}
        </Box>
      ) : null}
      <Box className="publish-filters-content">
        <Filter isAudiencePage {...props} isPublishPage me={"caelnder"} />
        <LucButton
          onClick={handleAddTimeHeader}
          startIcon={<Add />}
          size="small"
          id="add-publish-btn"
          disabled={!accountData?.length}
        >
          {CheckValueLocale("add_publish", "", {}, intl)}
        </LucButton>
      </Box>
      {/* <select
        className="form-control"
        style={{ width: 200, display: "inline-block" }}
        defaultValue={"en"}
        onChange={cultureOnClick}
      >
        {cultures.map((c, idx) => (
          <option key={idx} value={c}>
            {c}
          </option>
        ))}
      </select> */}
      <Calendar
        culture={culture}
        // defaultLocale="ar"
        // localizer={localizer}
        tooltipAccessor={() => null}
        localizer={localizer}
        events={calendarData}
        startAccessor="start"
        endAccessor="end"
        defaultDate={defaultDate}
        style={{ height: "100%" }}
        defaultView="week" // make default week from filterations
        views={viewList} //to make filtersation these only remove agenda
        // onSelectEvent={handleEventClick} //fire on events only
        //   onRangeChange={handleRangeChange}
        selectable={true}
        onSelecting={(slot) => false}
        onSelectSlot={handleSelectSlot} // Handle slot selection event
        onSelectEvent={handleSelectEvent} // Handle event selection event
        date={date}
        onNavigate={(date) => {
          setDate(getonvertDateCalender(date));
        }}
        onView={handleViewChange} // to determine which type view selected
        formats={formats}
        components={{
          toolbar: (props) => (
            <CustomToolbar {...props} handleNavigate={handleNavigate} />
          ),
        }}
        eventPropGetter={eventRenderProps}
      />
    </Box>
  );
};

export default PublishCalendar;
