import { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import UserProfilerHeader from "./userProfilerHeader";
import UserProfileWidgets from "./userProfileWidgets";
import AudienceAboutPage from "./audienceAboutPage";
import AudienceSegments from "./segments/audienceSegments";
import { useIntl } from "react-intl";
import AudienceController from "services/controllers/audienceController";
import CircularLoading from "components/circularProgress";
import SaveAboutChanges from "../components/saveAboutChanges";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import MainUserActivites from "../userActivites/mainUserActivites";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const MainUserProfile = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const id = urlParams?.id;

  const [loading, setLoading] = useState(false);
  const [userProfileData, setUserProfileData] = useState([]);
  const [updatedSavedValues, setUpdatedSavedValues] = useState({ data: [] });
  const [segmentList, setSegmentList] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openProfileSnackBar, setOpenProfileSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [showSnackBar, setShowSnackBar] = useState(false);

  const handleAlert = (severity, message, title) => {
    setOpenProfileSnackBar({
      message: message,
      title: title,
      severity: severity,
      openSnackBar: true,
    });
    severity === "success" && getUserProfile();
  };

  const getUserProfile = () => {
    setLoading(true);
    AudienceController.getUserProfile(id).then((result) => {
      setUserProfileData(result);
      setLoading(false);
    });
  };

  const getSegmentList = () => {
    AudienceController?.getSegmentList().then((result) => {
      setSegmentList(result?.data?.data);
    });
  };

  const handleEditProfileData = () => {
    AudienceController.editProfileData(id, updatedSavedValues).then((data) => {
      if (data?.data?.status === 204 || data?.data?.status === 200) {
        setShowSaveModal(false);
        setShowSnackBar(true);
        setSnackBarData({
          severity: "success",
          message: CheckValueLocale(
            "your_changes_saved_successfully",
            "",
            {},
            intl,
          ),
        });
        getUserProfile();
        setUpdatedSavedValues({ data: [] });
      } else {
        setUpdatedSavedValues({ data: [] });
        setShowSaveModal(false);
        setShowSnackBar(true);
        setSnackBarData({
          severity: "error",
          message: CheckValueLocale("wrong_msg", "", {}, intl),
          title: CheckValueLocale("fail_req", "", {}, intl),
        });
        getUserProfile();
      }
    });
  };

  useEffect(() => {
    (async () => {
      await getUserProfile();
    })();
  }, []);

  useEffect(() => {
    getSegmentList();
  }, []);

  let body = (
    <div className="delete-pop">
      <div className="delete-pop-title">
        <p className="popup-user">
          {CheckValueLocale("change_are_saved", "", {}, intl)}
        </p>
        <span className="question">?</span>
      </div>
    </div>
  );

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  const handleCloseAudienceExportSnackBar = () => {
    setOpenProfileSnackBar({ openSnackBar: false });
  };

  return (
    <Container maxWidth="xl">
      {loading ? (
        <CircularLoading />
      ) : (
        <Box>
          <UserProfilerHeader />
          <UserProfileWidgets userProfileData={userProfileData} />
          <AudienceSegments
            segmentsData={userProfileData}
            getUserProfile={getUserProfile}
            segmentList={segmentList}
            handleAlert={handleAlert}
          />
          <AudienceAboutPage
            userProfileData={userProfileData}
            updatedSavedValues={updatedSavedValues}
            setUpdatedSavedValues={setUpdatedSavedValues}
          />
          <MainUserActivites profileId={id} userProfileData={userProfileData} />
          {handlelUserRoles("CDP", "EDIT_PROFILE") &&
            updatedSavedValues.data.length > 0 && (
              <SaveAboutChanges
                updatedSavedValues={updatedSavedValues}
                setUpdatedSavedValues={setUpdatedSavedValues}
                setShowSaveModal={setShowSaveModal}
                getUserProfile={getUserProfile}
              />
            )}
        </Box>
      )}

      {showSaveModal && (
        <PopupModal
          title={CheckValueLocale("save_changes", "", {}, intl)}
          body={body}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("save", "", {}, intl)}
          open={() => setShowSaveModal(true)}
          close={() => setShowSaveModal(false)}
          accept={handleEditProfileData}
        />
      )}

      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          severity={snackBarData.severity}
          message={snackBarData.message}
          title={snackBarData.title}
          handleClose={handleCloseSnackBar}
        />
      )}

      <SnackBar
        open={openProfileSnackBar?.openSnackBar}
        severity={openProfileSnackBar?.severity}
        message={CheckValueLocale(openProfileSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(openProfileSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseAudienceExportSnackBar}
      />
    </Container>
  );
};

export default MainUserProfile;
