import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import "./customCard.scss";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import avatar from "images/dashboard/Avatar.svg";

const MonitorCard = (props) => {
  const intl = useIntl();
  const {
    selectedOptions,
    selectedProduct,
    setSelectedOptions,
    setSnackBar,
    monitorData,
    loading,
    accountData,
    maxMonitors,
  } = props;
  const slProduct = window.localStorage.getItem("sm_id");

  const handleCheckboxChange = (option) => {
    const currentIndex = selectedOptions?.indexOf(option);
    const newSelectedOptions = [...selectedOptions];
    if (newSelectedOptions?.length >= maxMonitors - 1)
      setSnackBar({
        open: true,
        title:
          selectedProduct == slProduct
            ? "maximum_monitors_reached_title"
            : "maximum_accounts_reached_title",
        message:
          selectedProduct == slProduct
            ? "maximum_monitors_reached_msg"
            : "maximum_accounts_reached_msg",
        severity: "info",
        maxMonitors: maxMonitors,
      });
    if (currentIndex === -1) {
      if (newSelectedOptions?.length < maxMonitors)
        newSelectedOptions?.push(option);
    } else {
      newSelectedOptions?.splice(currentIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
  };

  const skeletonData = ["0", "1", "2", "3", "4", "5", "6", "7"];

  const renderPlatformItems = (platform, isDm) => {
    return (
      <Box>
        {accountData?.grouped_monitors?.[platform]?.filter(
          (item) => item?.attributes?.is_dm === isDm,
        )?.length ? (
          <>
            <Typography component={"h5"} className="dashboard-platform-title">
              {getSocialIcon(isDm ? `${platform}_private` : platform)}
              {CheckValueLocale(
                isDm ? `${platform?.toLowerCase()}_dm` : platform,
                "",
                {},
                intl,
              )}
            </Typography>
            <FormGroup className="card-group">
              {accountData?.grouped_monitors[platform]
                ?.filter((item) => item?.attributes?.is_dm === isDm)
                ?.map((account) => (
                  <FormControlLabel
                    className={`custom-monitor-card ${selectedOptions?.indexOf(account) !== -1 ? "selected-card" : ""}`}
                    key={account}
                    label={
                      <Box className="item-card-box item-card-box-account">
                        <Box>
                          <ul>
                            <li>
                              <Typography
                                component={"span"}
                                className="account-name"
                              >
                                <img src={avatar} alt="" />
                                {account?.attributes?.name}
                              </Typography>
                            </li>
                          </ul>
                        </Box>
                        {selectedOptions?.indexOf(account) !== -1 ? (
                          <FontAwesomeIcon icon={faCircleCheck} />
                        ) : null}
                      </Box>
                    }
                    control={
                      <Checkbox
                        className="checkbox-item"
                        checked={selectedOptions?.indexOf(account) !== -1}
                        onChange={() => handleCheckboxChange(account)}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </>
        ) : null}
      </Box>
    );
  };
  return (
    <Box className="monitor-card-container">
      {loading ? (
        selectedProduct == slProduct ? (
          <Box className="card-group">
            {skeletonData?.map(() => (
              <Stack className="custom-monitor-card" spacing={1}>
                <Skeleton
                  variant="rectangular"
                  className="custom-dashboard-skelton-gradient"
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  className="custom-dashboard-skelton-gradient"
                  width={107}
                  height={10}
                />
                <Skeleton
                  animation={false}
                  variant="rectangular"
                  className="custom-dashboard-skelton-gradient"
                  width={112}
                  height={10}
                />
              </Stack>
            ))}
          </Box>
        ) : (
          <Box className="card-group">
            {skeletonData?.map(() => (
              <Box className="custom-monitor-card skeleton-cxm" spacing={1}>
                <Skeleton
                  className="custom-dashboard-skelton-gradient"
                  variant="rectangular"
                  width={24}
                  height={24}
                />
                <Skeleton
                  className="custom-dashboard-skelton-gradient"
                  animation="wave"
                  variant="rectangular"
                  width={161}
                  height={12}
                />
              </Box>
            ))}
          </Box>
        )
      ) : (
        <>
          {selectedProduct == slProduct ? (
            <Box>
              <FormGroup className="card-group">
                {monitorData?.map((item, index) => (
                  <FormControlLabel
                    className={`custom-monitor-card ${selectedOptions?.indexOf(item) !== -1 ? "selected-card" : ""}`}
                    key={item?.id}
                    label={
                      <Box className="item-card-box">
                        <Box>
                          <Tooltip
                            placement="top"
                            arrow
                            title={
                              item?.attributes?.name?.length > 20
                                ? CheckValueLocale(
                                    item?.attributes?.name,
                                    "",
                                    {},
                                    intl,
                                  )
                                : null
                            }
                          >
                            <Typography component={"h4"}>
                              {item?.attributes?.name?.length > 20
                                ? item?.attributes?.name?.substring(0, 20) +
                                  "..."
                                : item?.attributes?.name?.substring(0, 20)}
                            </Typography>
                          </Tooltip>

                          <ul>
                            <li>
                              {CheckValueLocale("type", "", {}, intl)}:
                              <Typography component={"span"}>
                                {CheckValueLocale(
                                  item?.attributes?.monitor_type_name,
                                  "",
                                  {},
                                  intl,
                                )}
                              </Typography>
                            </li>
                            <li>
                              {CheckValueLocale("data_source", "", {}, intl)}:
                              {item?.attributes?.data_sources.map(
                                (social, i) => {
                                  return (
                                    <Box key={i}>{getSocialIcon(social)}</Box>
                                  );
                                },
                              )}
                            </li>
                          </ul>
                        </Box>
                        {selectedOptions?.indexOf(item) !== -1 ? (
                          <FontAwesomeIcon icon={faCircleCheck} />
                        ) : null}
                      </Box>
                    }
                    control={
                      <Checkbox
                        className="checkbox-item"
                        checked={selectedOptions?.indexOf(item) !== -1}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </Box>
          ) : (
            <Box>
              {accountData?.grouped_monitors
                ? Object.keys(accountData?.grouped_monitors)?.map(
                    (platform) => (
                      <>
                        <Box className="platform-item">
                          {renderPlatformItems(platform, false)}
                        </Box>
                        <Box className="platform-item">
                          {renderPlatformItems(platform, true)}
                        </Box>
                      </>
                    ),
                  )
                : null}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default MonitorCard;
