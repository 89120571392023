import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import FiltersController from "services/controllers/filtersController";
import InteractionsController from "services/controllers/interactionsController";
import {
  checkKeyboardLanguage,
  CheckValueLocale,
  isEmptyArray,
} from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  filters,
  reduxSavedFilterName,
  savedFilter,
} from "utils/redux/features/SocialListening/socialListeningSlice";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";

const SavedFiltersFunctions = (
  monitorId,
  activeTab,
  monitorDataSource,
  monitorType,
  startDate,
  endDate,
  applyFilter,
) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );

  const reduxSaveFilterId = useSelector(
    (state) => state?.socialListening?.savedFilterId,
  );

  const reduxSaveFilterName = useSelector(
    (state) => state?.socialListening?.savedFilterName,
  );

  const reduxSavedFilter = useSelector(
    (state) => state?.socialListening?.savedFilter,
  );

  const [showSaveAndApplyModal, setShowSaveAndApplyModal] = useState(false);
  const [filterName, setFilterName] = useState(reduxSaveFilterName);
  const [showSnackBar, setshowSnackBar] = useState(false);
  const [savedFilters, setSavedFilters] = useState(reduxSavedFilter);
  const [filterFields, setFilterFields] = useState([]);
  const [savedFilterId, setSavedFilterId] = useState(reduxSaveFilterId);
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
  const [assignActiveFilters, setAssignActiveFilters] =
    useState(reduxFilterParams);

  const handleCloseDeleteFilterModal = () => {
    setShowDeleteFilterModal(false);
  };
  const [snackBarDetails, setsnackBarDetails] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const { changeFilterParamsToString } = CommonFunctions();
  // ______________________________________________________________________________________
  //set initial  Empty filterParams
  const [filterParams, setFilterParams] = useState(reduxFilterParams);
  // _____________________________________________________________________________________
  useEffect(() => {
    setSavedFilters(reduxSavedFilter);
  }, [reduxSavedFilter]);
  // ______________________________________________________________________________________
  // ______________________________________________________________________________________
  //this function empty all filter fields
  const handleClearFilterParams = () => {
    setSavedFilterId("");
    setFilterName("");
    setFilterParams({});
  };
  // ______________________________________________________________________________________
  //this one handle the new saved filter name
  const handleFilterName = (e) => {
    setFilterName(e.target.value);
  };
  // ______________________________________________________________________________________
  //this is the save filter Name Modal Body
  const modalBody = (
    <TextField
      fullWidth
      varient="outlined"
      label={CheckValueLocale("filter_name", "", {}, intl)}
      style={{
        direction: checkKeyboardLanguage(filterName) ? "rtl" : "ltr",
      }}
      value={filterName}
      onChange={handleFilterName}
      placeholder={CheckValueLocale("filter_name", "", {}, intl)}
    />
  );
  // ______________________________________________________________________________________

  const handleShowSaveAndApplyModal = (filterParams) => {
    setShowSaveAndApplyModal(true);
    setFilterName("");
    setFilterParams(filterParams);
  };
  // ______________________________________________________________________________________

  const handleCloseApplySaveFilter = () => {
    setShowSaveAndApplyModal(false);
    setFilterName("");
  };
  // ______________________________________________________________________________________

  const handleCloseSnackBar = () => {
    setshowSnackBar(false);
  };
  // ______________________________________________________________________________________
  //Click on Save & Apply in popup Modal to create new filter
  const handleApplySaveFilter = (savedFilterId) => {
    if (filterName === "") {
      return false;
    } else {
      FiltersController.createSavedFilter(
        monitorId,
        window.localStorage.sm_id,
        activeTab, //chosen active section/tab from side menu
        filterName,
        filterParams &&
          //format FilterParams to be string instead of array and remove empty array params
          changeFilterParamsToString(filterParams),
        dataSourceId,
        startDate,
        endDate,
      ).then((data) => {
        setShowSaveAndApplyModal(false);
        setshowSnackBar(true); // show save and apply snackbar
        setFilterName(filterName); // remove old filter name
        setAssignActiveFilters(filterParams); // set selected data to be shown in active filters section
        applyFilter(startDate, endDate, filterParams); // send request to rabbit mq
        if (data.data.status !== 400) {
          getAllSavedFilters();
          setsnackBarDetails({
            severity: "success",
            message: CheckValueLocale(
              "you_can_check_saved_filters_inside_filter_panel",
              "",
              {},
              intl,
            ),
            title: CheckValueLocale("filter_saved_successfully", "", {}, intl),
          });
        } else {
          setsnackBarDetails({
            severity: "error",
            message: CheckValueLocale("repeated_name_error", "", {}, intl),
          });
        }
      });
    }
  };
  // ______________________________________________________________________________________

  const getSavedFilterData = (savedFilterId) => {
    FiltersController.getSavedFilterData(
      savedFilterId,
      monitorId,
      window.localStorage.sm_id,
      dataSourceId,
    ).then((data) => {
      setFilterName(data?.data?.attributes?.name_filter);
      dispatch(reduxSavedFilterName(data?.data?.attributes?.name_filter));
      setFilterParams(data?.data?.attributes?.params_filter);
      dispatch(filters(data?.data?.attributes?.params_filter));
    });
  };
  // ______________________________________________________________________________________
  //this fn deletes a previously saved filter
  const handleDeleteFilter = () => {
    FiltersController.deleteSavedFilterData(
      savedFilterId,
      monitorId,
      window.localStorage.sm_id,
      dataSourceId,
    ).then((data) => {
      setShowDeleteFilterModal(false);
      setshowSnackBar(true);
      handleClearFilterParams(); //empty all selected filters
      applyFilter(startDate, endDate, {}); //request to connect with rabbit mq with empty active filters
      if (data.data.status === 202) {
        getAllSavedFilters();
        setsnackBarDetails({
          severity: "success",
          message: CheckValueLocale(
            "filter_deleted_successfully",
            "",
            {},
            intl,
          ),
        });
      } else {
        setsnackBarDetails({
          severity: "error",
          message: CheckValueLocale("failed_error", "", {}, intl),
        });
      }
    });
  };
  /*______________________________________________________________*/
  //This Call is to get All filter fields for active tab
  const getFiltersFileds = () => {
    FiltersController.getMonitorFiltersFileds(
      activeTab, //chosen active section/tab from side menu
      monitorId, //from url
      monitorDataSource, //from url
      window.localStorage.getItem("sm_id"), //from localstorage
      monitorType, //from url
    ).then((data) => {
      setFilterFields(data.data);
      getAllSavedFilters();
    });
  };
  // ______________________________________________________________________________________
  // this call return all Previously Saved Filters inside (saved filters) dropdown
  const [dataSourceId, setDataSourceId] = useState("");

  const getAllSavedFilters = () => {
    InteractionsController.getDatasources().then((data) => {
      //1st step :get data source id
      data?.data?.data?.filter((dataSource) => {
        if (monitorDataSource === "nb" || monitorDataSource === "NEWSBLOGS") {
          //change nb back to talkwalker to be read correctly withing the datasources
          monitorDataSource = "TALKWALKER"; // we can't change it until it changed from backend as it crashes the filters
        }
        if (monitorDataSource === dataSource.source) {
          setDataSourceId(dataSource.id); //save the data source id to be used in other calls
          getAllFilters(dataSource.id); //2nd step : get all saved filters
        }
      });
    });
  };
  const getAllFilters = (dataSourceId) => {
    FiltersController.getAllSavedFilters(
      monitorId,
      window.localStorage.sm_id,
      dataSourceId,
      activeTab, //chosen active section/tab from side menu
    ).then((data) => {
      if (!isEmptyArray(data?.data)) {
        let savedFilterIds = data?.data?.map((a) => a?.id);
        let reduxSavedFiltersIDs = reduxSavedFilter?.map((a) => a?.id);
        let savedFilters = [];
        savedFilterIds?.map((id, index) => {
          if (!reduxSavedFiltersIDs?.includes(id)) {
            savedFilters?.push(data?.data[index]);
          }
        });
        dispatch(savedFilter([].concat(reduxSavedFilter, savedFilters)));
      }
    });
  };
  // ______________________________________________________________________________________

  return {
    modalBody,
    handleFilterName,
    handleDeleteFilter,
    getSavedFilterData,
    handleApplySaveFilter,
    handleShowSaveAndApplyModal,
    handleCloseSnackBar,
    showSaveAndApplyModal,
    setShowSaveAndApplyModal,
    showSnackBar,
    handleClearFilterParams,
    setFilterParams,
    filterParams,
    handleCloseApplySaveFilter,
    snackBarDetails,
    filterFields,
    getFiltersFileds,
    savedFilters,
    setSavedFilterId,
    savedFilterId,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    setShowDeleteFilterModal,
    filterName,
    assignActiveFilters,
    setAssignActiveFilters,
    dataSourceId,
  };
};
export default SavedFiltersFunctions;
