import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { InputWithShrinkLabel } from "pages/settings/components/teams/components/inputWithShrinkLabel/inputWithShrinkLabel";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale, classNames } from "utils/helpers";

export const AddSkillPopup = ({
  open,
  setOpen,
  currentSkills,
  getCompanySkills,
}) => {
  const intl = useIntl();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameFoundError, setNameFoundError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isDesriptionError = description?.length > 280;
  const [snackbarData, setSanckbarData] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const handleCloseSnackBar = () => {
    setSanckbarData({
      openSnackBar: false,
    });
  };

  const handleClosePopup = () => {
    if (isLoading) return;
    setName("");
    setDescription("");
    setNameFoundError(false);
    setOpen(false);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (
      currentSkills?.find(
        (skill) => skill?.name?.toLowerCase?.() === value?.toLowerCase?.(),
      )
    ) {
      setNameFoundError(true);
    } else {
      setNameFoundError(false);
    }
  };
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
  };

  const handleSaveSkill = () => {
    setIsLoading(true);
    const newSkill = {
      title: name,
      description,
    };
    CompaniesController.addCompanySkills(newSkill).then((res) => {
      if (res?.errorMsg) {
        const isDublicate =
          res?.errorMsg?.response?.data?.errors?.[0]?.detail?.toLowerCase?.() ===
          "has already been taken";
        if (isDublicate) {
          setNameFoundError(true);
        } else {
          setSanckbarData({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          });
        }
      } else {
        setSanckbarData({
          message: "skill_added_success",
          severity: "success",
          openSnackBar: true,
        });
        setName("");
        setDescription("");
        setOpen(false);
      }
      getCompanySkills();
      setIsLoading(false);
    });
  };
  return (
    <>
      <PopupModal
        open={open}
        close={handleClosePopup}
        fixPadding
        addClasses="user-tab-add-skill-popup"
        body={
          <>
            <Box className="add-skill-popup-header">
              <Typography variant="h4" className="add-skill-popup-title">
                {CheckValueLocale("add_new_skill", "", {}, intl)}
              </Typography>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={handleClosePopup}
                className="add-skill-popup-close"
              />
            </Box>
            <Box className="add-skill-popup-content">
              <InputWithShrinkLabel
                label={CheckValueLocale("skill_name", "", {}, intl)}
                value={name}
                onChange={handleNameChange}
                error={nameFoundError}
                helperText={
                  nameFoundError
                    ? CheckValueLocale("skill_exists", "", {}, intl)
                    : null
                }
              />
              <Box className="add-skill-popup-desc">
                <InputWithShrinkLabel
                  label={CheckValueLocale("skill_description", "", {}, intl)}
                  value={description}
                  onChange={(e) => handleDescriptionChange(e)}
                  error={isDesriptionError}
                  multiline
                  maxRows={6}
                  minRows={3}
                />
                <Typography
                  component="span"
                  variant="body2"
                  className={classNames(
                    "add-skill-popup-desc-count",
                    isDesriptionError && "desc-error",
                  )}
                >
                  {description?.length || 0}
                  <Typography component="span">/280</Typography>
                </Typography>
              </Box>
            </Box>
            <Box className="add-skill-popup-footer">
              <LucButton
                variant="flat"
                onClick={handleClosePopup}
                type="secondary"
              >
                {CheckValueLocale("cancel", "", {}, intl)}
              </LucButton>
              <LucButton
                loading={isLoading}
                disabled={
                  isDesriptionError || nameFoundError || !name?.trim()?.length
                }
                onClick={handleSaveSkill}
              >
                {CheckValueLocale("save_and_add", "", {}, intl)}
              </LucButton>
            </Box>
          </>
        }
      />
      <SnackBar
        open={snackbarData?.openSnackBar}
        severity={snackbarData?.severity}
        message={CheckValueLocale(snackbarData?.message, "", {}, intl)}
        title={CheckValueLocale(snackbarData?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};
