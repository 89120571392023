import { Box } from "@mui/material";
import powerLogoEn from "images/live-dashboard-images/power-logo-en.svg";
import powerLogoAr from "images/live-dashboard-images/power-logo-ar.svg";

const PowerLogoContent = () => {
  const powerLogo =
    window.localStorage.lang === "en" ? powerLogoEn : powerLogoAr;
  return (
    <Box className="power-logo-wrapper">
      <img src={powerLogo} alt="powerlivedashboard" />
    </Box>
  );
};
export default PowerLogoContent;
