import React, { useEffect, useState } from "react";
import EngagementsController from "services/controllers/engagementsController";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./translationRating.scss";
const labels = {
  1: "cant_understand",
  2: "cant_understand_most",
  3: "understand_enough",
  4: "understand_most",
  5: "understand_everything",
};
const TranslationRating = (props) => {
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(-1);
  const [rated, setRated] = useState(false);

  const handleClick = (event, newValue) => {
    setValue(newValue);
    setRated(true);
    addRateData(newValue);
  };
  let {
    translationSource,
    translationTarget,
    translation,
    selectedCard,
    engagementId,
  } = props;

  const addRateData = (newValue) => {
    EngagementsController?.addRate(
      +window?.localStorage.getItem("engagementsProductId"),
      +selectedCard?.tracker_id,
      selectedCard?.data_source,
      String(newValue),
      translation,
      translationSource,
      translationTarget,
      engagementId,
    ).then((result) => {});
  };
  const intl = useIntl();
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  useEffect(() => {
    getLabelText();
  }, []);

  return (
    <>
      <Box className="rateing-container">
        <Box className={rated ? "rate-d-none" : "rate-d-flex"}>
          <span className="rate-title">
            {CheckValueLocale("rate_translation", "", {}, intl)}
          </span>
          <Tooltip
            title={CheckValueLocale(
              labels[hover !== -1 ? hover : value],
              "",
              {},
              intl,
            )}
            placement="top"
            arrow
          >
            <Box display="inline-block">
              <Rating
                name="hover-feedback"
                value={value}
                getLabelText={getLabelText}
                onChange={(event, newValue) => handleClick(event, newValue)}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
            </Box>
          </Tooltip>
        </Box>
        <Typography
          className={
            !rated
              ? "rate-d-none rated-text"
              : "rated-text"
                ? "rated-text rated-color-" + value
                : "rated-text rated-color-" + value
          }
          component={"span"}
        >
          {parseInt(value)}
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Subtract"
              d="M2.81307 4.67807L2.77148 5.17634L2.47253 5.15138L2.35386 4.87587L2.81307 4.67807ZM7.45117 5.06527L7.49277 4.567L8.68927 4.66689L7.77553 5.44578L7.45117 5.06527ZM3.92906 8.0676L3.44254 8.18295L3.37201 7.88544L3.6047 7.68709L3.92906 8.0676ZM4.98698 12.5298L5.47349 12.4145L5.74551 13.5619L4.73167 12.9597L4.98698 12.5298ZM1.45962 0.27193L3.27229 4.48028L2.35386 4.87587L0.541196 0.667524L1.45962 0.27193ZM2.85467 4.17981L7.49277 4.567L7.40958 5.56353L2.77148 5.17634L2.85467 4.17981ZM7.77553 5.44578L4.25341 8.44812L3.6047 7.68709L7.12681 4.68475L7.77553 5.44578ZM4.41557 7.95226L5.47349 12.4145L4.50047 12.6452L3.44254 8.18295L4.41557 7.95226ZM4.73167 12.9597L0.745094 10.5922L1.25572 9.73236L5.2423 12.0999L4.73167 12.9597Z"
              fill="#4CAF50"
            />
          </svg>

          {CheckValueLocale(labels[hover !== -1 ? hover : value], "", {}, intl)}
        </Typography>
      </Box>
    </>
  );
};

export default TranslationRating;
