import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
  ListItemText,
  OutlinedInput,
  TextField,
  Button,
  Snackbar,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopupModal from "components/popupModal";
import CompaniesController from "services/controllers/companiesController";
import CancelIcon from "@mui/icons-material/Cancel";
import ReportController from "services/controllers/reportController";
import LuciInReports from "../components/luci/luciInReports";
import moment from "moment";
import BrandingInReports from "../components/branding/brandingComponent";

const ReportTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "5px 8px",
    fontWeight: "500",
    "& span": {
      color: "rgba(97, 97, 97, 0.9) !important",
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 160,
      width: 475,
    },
  },
};

const StepThree = ({
  fieldError,
  step3FormFields,
  setStep3FormFields,
  step2FormFields,
  setCheckDisable,
}) => {
  const intl = useIntl();
  const [companyUsers, setCompanyUsers] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [startDate, setStartDate] = useState({});
  const [isRequestError, setIsRequestError] = useState(false);
  const [luciPerReport, setLuciPerReport] = useState(0);
  const [luciLoading, setLuciLoading] = useState(true);

  const loginUserData = {
    id: parseInt(window.localStorage?.getItem("user_id")),
    email: window.localStorage?.getItem("email"),
    name: window.localStorage?.getItem("name"),
  };

  const {
    reportEmails,
    reportFrequancy,
    reportFrequancyId,
    reportCustomDay,
    reportLanguage,
    reportStartDate,
    reportPPTTemplete,
    reportBranding,
  } = step3FormFields;

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const updateFieldValue = (event) => {
    const {
      target: { value, name },
    } = event;
    setStep3FormFields((val) => ({ ...val, [name]: value }));
  };

  const handleDateValue = (event) => {
    setStartDate(event);
  };

  const getComanyEmails = () => {
    CompaniesController.getCompanyActiveUsers().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      const usersData = res?.data?.data
        ? res?.data?.data?.map((val) => ({
            id: val?.attributes?.id,
            email: val?.attributes?.email,
            name: val?.attributes?.name,
          }))
        : [{ ...loginUserData }];
      setCompanyUsers(usersData);
    });
  };

  useEffect(() => {
    getComanyEmails();
  }, []);
  const disableRegularPPT =
    (step2FormFields?.KeywordDataSource &&
      step2FormFields?.KeywordDataSource?.filter((val) =>
        [2, 3]?.includes(val),
      )) ||
    [];
  useEffect(() => {
    if (companyUsers.length > 0) {
      setCheckDisable(false);
    } else {
      setCheckDisable(true);
    }
  }, [companyUsers]);
  const handleDelEmails = (val) => {
    let value = reportEmails?.filter((x) => x != val);
    setStep3FormFields((val) => ({
      ...val,
      ["reportEmails"]: value,
    }));
  };
  const handleCreateDate = () => {
    setStep3FormFields({ ...step3FormFields, reportStartDate: startDate });
    setOpenPopUp(false);
  };
  const dateBody = () => {
    return (
      <Box dir="ltr">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateTimePicker
            className="date-time"
            fullWidth
            disablePast
            renderInput={(params) => <TextField {...params} />}
            name="reportStartDate"
            value={startDate}
            onChange={handleDateValue}
          />
        </LocalizationProvider>
        <Box className="start-date-btns">
          <Box>
            <Button onClick={() => setOpenPopUp(false)}>CANCEL</Button>
          </Box>
          <Box>
            <Button onClick={handleCreateDate}>OK</Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const monthDays = [];
  for (let i = 1; i <= 28; i++) {
    monthDays.push(i);
  }

  const weekDays = [
    { name: "Sunday", value: 0 },
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
  ];

  const [frequenciesList, setFrequenciesList] = useState([]);
  const [freqId, setFreqId] = useState(0);
  const [selectedFreq, setSelectedFreq] = useState("");
  const [monthlyType, setMonthlyType] = useState("specific");
  const [specificSelect, setSpecificSelect] = useState(1);
  const [specDay, setSpecDay] = useState("");
  const [dayType, setDayType] = useState("first_day");
  const [selectedDay, setSelectedDay] = useState(0);
  const [companyhasBranding, setCompanyhasBranding] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(0);

  useEffect(() => {
    getFrequenciesList();
    if (step2FormFields?.reportType !== 2)
      getReportLuciToken(step2FormFields?.reportType);
  }, []);

  const getFrequenciesList = () => {
    ReportController.getReportFrequencies().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.data) setFrequenciesList(res.data);
    });
  };

  const getReportLuciToken = (reportTypeId) => {
    ReportController.getReportLuciToken(reportTypeId).then((res) => {
      setLuciLoading(false);
      if (res?.status >= 200) {
        setLuciPerReport(res?.data?.luci_token);
        setCompanyhasBranding(res?.data?.is_company_branding);
        setLastUpdated(res?.data?.company_branding_last_update);
      }
    });
  };

  const onChangeFrequeny = (e) => {
    setSelectedFreq(e.target.value);
    if (e.target.value === "daily") {
      setSpecDay("");
      frequenciesList?.map((freq) => {
        if (freq?.name === "daily") {
          freq?.report_recurrences?.map((rec) => {
            if (rec?.recurrence === 24) setFreqId(rec?.id);
          });
        }
      });
    } else if (e.target.value === "monthly") {
      setSpecDay(1);
      if (monthlyType === "specific") {
        frequenciesList?.map((freq) => {
          if (freq?.name === "monthly")
            freq?.report_recurrences?.map((rec) => {
              if (rec?.recurrence_type === "day") setFreqId(rec?.id);
            });
        });
      } else {
        frequenciesList?.map((freq) => {
          if (freq?.name === "monthly")
            freq?.report_recurrences?.map((rec) => {
              if (
                rec?.recurrence_type === dayType &&
                rec?.recurrence === selectedDay
              )
                setFreqId(rec?.id);
            });
        });
      }
    } else {
      frequenciesList?.map((freq) => {
        if (e.target.value === freq?.name)
          setFreqId(freq?.report_recurrences[0]?.id);
      });
      setSpecDay("");
    }
  };

  const onChangeMonthlyType = (e) => {
    setMonthlyType(e.target.value);
    if (e.target.value === "specific") {
      setSpecDay(1);
      frequenciesList?.map((freq) => {
        freq?.report_recurrences?.map((rec) => {
          if (rec?.recurrence_type === "day") setFreqId(rec?.id);
        });
      });
    } else {
      setSpecDay("");
      frequenciesList?.map((freq) => {
        freq?.report_recurrences?.map((rec) => {
          if (
            rec?.recurrence_type === dayType &&
            rec?.recurrence === selectedDay
          )
            setFreqId(rec?.id);
        });
      });
    }
  };

  useEffect(() => {
    frequenciesList?.map((freq) => {
      if (freq?.name === "monthly")
        freq?.report_recurrences?.map((rec) => {
          if (
            rec?.recurrence === selectedDay &&
            rec?.recurrence_type === dayType
          )
            setFreqId(rec?.id);
        });
    });
  }, [dayType, selectedDay]);

  const onChangeSpecificSelect = (e) => {
    setSpecificSelect(e.target.value);
    if (e.target.value === "custom") setSpecDay(1);
    else setSpecDay(e.target.value);
  };

  useEffect(() => {
    setStep3FormFields((val) => ({
      ...val,
      ["reportFrequancy"]: selectedFreq,
    }));
    setStep3FormFields((val) => ({ ...val, ["reportFrequancyId"]: freqId }));
    setStep3FormFields((val) => ({ ...val, ["reportCustomDay"]: specDay }));
  }, [selectedFreq, freqId, specDay]);

  const handleChangeBranding = (e) => {
    setStep3FormFields({
      ...step3FormFields,
      isBranding: JSON.parse(e.target.value),
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" className="create-report-body-title">
        {CheckValueLocale("delivery_requirements", "", {}, intl)}:
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("report_report_emails", "", {}, intl)}
            </InputLabel>
            <Select
              fullWidth
              multiple
              name="reportEmails"
              value={reportEmails}
              onChange={updateFieldValue}
              input={<OutlinedInput />}
              disabled={companyUsers.length ? false : true}
              renderValue={() => CheckValueLocale("select", "", {}, intl)}
              MenuProps={MenuProps}
              error={fieldError?.reportEmails ? true : false}
              displayEmpty
            >
              <MenuItem value={""} disabled className="report-multi-menuItem">
                <Checkbox checked={false} />
                <ListItemText
                  primary={CheckValueLocale("select", "", {}, intl)}
                />
              </MenuItem>
              {companyUsers?.map((val) => (
                <MenuItem
                  key={`companyUsers-${val?.id}`}
                  value={val?.id}
                  className="report-multi-menuItem"
                  id={`reports-company-users-${val?.id ?? "id"}`}
                >
                  <Checkbox checked={reportEmails?.indexOf(val?.id) > -1} />
                  <ListItemText primary={val?.email} />
                </MenuItem>
              ))}
            </Select>
            {fieldError?.reportEmails && (
              <FormHelperText error>{fieldError?.reportEmails}</FormHelperText>
            )}
          </Box>
          {reportEmails?.length > 0 ? (
            <Box sx={{ mt: "8px" }}>
              {companyUsers?.map((val) => {
                return reportEmails?.includes(val?.id) ? (
                  <Button
                    key={val?.id}
                    variant="outlined"
                    className="selected-email-lable"
                    endIcon={
                      <CancelIcon onClick={() => handleDelEmails(val?.id)} />
                    }
                    id="report-edit-report-selected-email-btn"
                  >
                    {val?.email}
                  </Button>
                ) : null;
              })}
            </Box>
          ) : null}
        </Grid>
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("report_language", "", {}, intl)}
            </InputLabel>
            <Select
              name="reportLanguage"
              value={reportLanguage}
              fullWidth
              onChange={updateFieldValue}
              error={fieldError?.reportLanguage ? true : false}
              displayEmpty
            >
              <MenuItem
                value=""
                disabled
                className="report-menu-items"
                id={`report-menu-items-select`}
              >
                {CheckValueLocale("select", "", {}, intl)}
              </MenuItem>
              <MenuItem
                value={"ar-SA"}
                className="report-menu-items"
                id={`report-menu-items-arabic`}
              >
                {CheckValueLocale("arabic", "", {}, intl)}
              </MenuItem>
              <MenuItem
                value={"en-US"}
                className="report-menu-items"
                id={`report-menu-items-english`}
              >
                {CheckValueLocale("english", "", {}, intl)}
              </MenuItem>
            </Select>
            {fieldError?.reportLanguage && (
              <FormHelperText error>
                {fieldError?.reportLanguage}
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item lg={12} xl={12} sx={{ mt: 4 }}>
          <Typography variant="h6" className="create-report-body-title">
            {CheckValueLocale("scheduled_posts", "", {}, intl)}:
          </Typography>
        </Grid>
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("report_frequency", "", {}, intl)}
            </InputLabel>
            <Select
              name="reportFrequancy"
              value={selectedFreq}
              onChange={onChangeFrequeny}
              error={fieldError?.reportFrequancy ? true : false}
              displayEmpty
              fullWidth
            >
              <MenuItem value={""} disabled className="report-menu-items">
                {CheckValueLocale("select", "", {}, intl)}
              </MenuItem>
              {frequenciesList?.map((freq, index) => (
                <MenuItem
                  key={index}
                  value={freq?.name}
                  className="report-menu-items"
                  id={`reports-frequency-list-${freq?.index ?? "id"}`}
                >
                  {CheckValueLocale(`report_${freq?.name}`, "", {}, intl)}
                </MenuItem>
              ))}
            </Select>
            {fieldError?.reportFrequancy && (
              <FormHelperText error>
                {fieldError?.reportFrequancy}
              </FormHelperText>
            )}
          </Box>
        </Grid>

        {selectedFreq === "daily" ? (
          <Grid item lg={6} xl={6}>
            <Box className="text-field">
              <InputLabel>
                {CheckValueLocale("daily_recurrence", "", {}, intl)}
              </InputLabel>
              <Select
                name="dailyRecurrence"
                value={freqId}
                onChange={(e) => setFreqId(e.target.value)}
                error={freqId === 0 ? true : false}
                displayEmpty
                fullWidth
              >
                {frequenciesList[0]?.report_recurrences
                  ?.toReversed()
                  ?.map((rec) => (
                    <MenuItem
                      key={rec?.id}
                      value={rec?.id}
                      className="report-menu-items"
                      id={`reports-daily-list-${rec?.id ?? "id"}`}
                    >
                      {`${CheckValueLocale(
                        "every",
                        "",
                        {},
                        intl,
                      )} ${CheckValueLocale(
                        `${rec?.recurrence}_hours`,
                        "",
                        {},
                        intl,
                      )} (${CheckValueLocale(
                        `${24 / rec?.recurrence}_reports_per_day`,
                        "",
                        {},
                        intl,
                      )})`}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
        ) : null}

        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("report_start_date", "", {}, intl)}
            </InputLabel>
            <Button id="step3-date-btn" onClick={() => setOpenPopUp(true)}>
              <Box>{reportStartDate?.$d?.toLocaleString("es-ES", options)}</Box>
              <Box>
                <FontAwesomeIcon icon={faCalendar} />
              </Box>
            </Button>

            {fieldError?.reportStartDate && (
              <FormHelperText error>
                {fieldError?.reportStartDate}
              </FormHelperText>
            )}
          </Box>
          {/* <p className="create-report-start-date-note">
            {CheckValueLocale("report_start_date_timezone_note", "", {}, intl)}
          </p> */}
        </Grid>

        {selectedFreq === "monthly" ? (
          <Grid item lg={6} xl={6} sx={{ mt: 2 }}>
            <Typography variant="h6" className="create-report-body-title">
              {CheckValueLocale("sending_recurrence", "", {}, intl)}:
            </Typography>
            <Grid container spacing={2}>
              <Grid item lg={6} xl={6}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={monthlyType}
                    onChange={onChangeMonthlyType}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="specific"
                      control={<Radio />}
                      label={CheckValueLocale("on_spec_day", "", {}, intl)}
                      id="radio-button-on-spec-day"
                    />
                    <FormControlLabel
                      value="every"
                      control={<Radio />}
                      label={CheckValueLocale("every", "", {}, intl)}
                      id="radio-button-every"
                      sx={{ mt: 2 }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item lg={6} xl={6}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    lg={specificSelect === "custom" ? 8 : 12}
                    xl={specificSelect === "custom" ? 8 : 12}
                  >
                    <Box className="text-field">
                      <Select
                        name="specificSelect"
                        fullWidth
                        displayEmpty
                        disabled={monthlyType !== "specific" ? true : false}
                        value={specificSelect}
                        onChange={onChangeSpecificSelect}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem
                          value={1}
                          className="report-menu-items"
                          id="specific-select-first"
                        >
                          {CheckValueLocale("first_of_month", "", {}, intl)}
                        </MenuItem>
                        <MenuItem
                          value={31}
                          className="report-menu-items"
                          id="specific-select-last"
                        >
                          {CheckValueLocale("end_of_month", "", {}, intl)}
                        </MenuItem>
                        <MenuItem
                          value={"custom"}
                          className="report-menu-items"
                          id="specific-select-custom"
                        >
                          {CheckValueLocale("custom_day", "", {}, intl)}..
                        </MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  {specificSelect === "custom" ? (
                    <Grid item lg={4} xl={4}>
                      <Box className="text-field">
                        <Select
                          name="specificSelect"
                          fullWidth
                          displayEmpty
                          value={specDay}
                          onChange={(e) => setSpecDay(e.target.value)}
                          disabled={monthlyType !== "specific" ? true : false}
                          className="select-input"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem
                            value=""
                            className="report-menu-items"
                            id={`custom-day-select`}
                            disabled
                          >
                            {CheckValueLocale("select", "", {}, intl)}{" "}
                          </MenuItem>

                          {monthDays.map((i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={i}
                                className="report-menu-items"
                                id={`custom-day-${i}`}
                              >
                                {i}
                              </MenuItem>
                            );
                          })}
                          <MenuItem
                            value={29}
                            className="report-menu-items"
                            id="custom-day-29"
                          >
                            29 (
                            {CheckValueLocale(
                              "or_end_of_the_month",
                              "",
                              {},
                              intl,
                            )}
                            )
                          </MenuItem>
                          <MenuItem
                            value={30}
                            className="report-menu-items"
                            id="custom-day-30"
                          >
                            30 (
                            {CheckValueLocale(
                              "or_end_of_the_month",
                              "",
                              {},
                              intl,
                            )}
                            )
                          </MenuItem>
                          <MenuItem
                            value={31}
                            className="report-menu-items"
                            id="custom-day-31"
                          >
                            31 (
                            {CheckValueLocale(
                              "or_end_of_the_month",
                              "",
                              {},
                              intl,
                            )}
                            )
                          </MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                  ) : null}

                  <Grid item lg={12} xl={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item lg={6} xl={6}>
                        <Box className="text-field">
                          <Select
                            name="dayTypeSelect"
                            fullWidth
                            displayEmpty
                            disabled={monthlyType === "specific" ? true : false}
                            value={dayType}
                            onChange={(e) => setDayType(e.target.value)}
                          >
                            <MenuItem
                              value="first_day"
                              className="report-menu-items"
                              id="day-type-select-first"
                            >
                              {CheckValueLocale("first", "", {}, intl)}
                            </MenuItem>
                            <MenuItem
                              value="last_day"
                              className="report-menu-items"
                              id="day-type-select-last"
                            >
                              {CheckValueLocale("last", "", {}, intl)}
                            </MenuItem>
                          </Select>
                        </Box>
                      </Grid>
                      <Grid item lg={6} xl={6}>
                        <Box className="text-field">
                          <Select
                            name="selectDaySelect"
                            fullWidth
                            displayEmpty
                            disabled={monthlyType === "specific" ? true : false}
                            value={selectedDay}
                            onChange={(e) => setSelectedDay(e.target.value)}
                          >
                            {weekDays.map((day) => {
                              return (
                                <MenuItem
                                  key={day.value}
                                  value={day.value}
                                  className="report-menu-items"
                                  id={`day-select-${day.value}`}
                                >
                                  {CheckValueLocale(
                                    `${day.name}`,
                                    "",
                                    {},
                                    intl,
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {/* Commented Till Asiri request till NOW */}
        {/* <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("branding", "", {}, intl)}
            </InputLabel>
            <RadioGroup
              row
              name="reportBranding"
              value={reportBranding}
              onChange={updateFieldValue}
            >
              <FormControlLabel
                value="standard"
                control={<Radio />}
                label={CheckValueLocale("standard", "", {}, intl)}
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label={
                  <div className="report-company-brand-div">
                    <Typography>
                      {CheckValueLocale("report_company_brand", "", {}, intl)}
                    </Typography>
                    <ReportTooltip
                      title={CheckValueLocale(
                        "check_company_branding_settings",
                        "",
                        {},
                        intl
                      )}
                      placement="top"
                      arrow
                    >
                      <IconButton>
                        <HelpIcon />
                      </IconButton>
                    </ReportTooltip>
                  </div>
                }
                disabled
              />
            </RadioGroup>
          </Box>
        </Grid> */}
      </Grid>

      {step2FormFields?.reportType !== 2 ? (
        <BrandingInReports
          isCreate={true}
          brandingValue={step3FormFields?.isBranding}
          handleChangeBranding={handleChangeBranding}
          companyHasBranding={companyhasBranding}
          lastUpdated={lastUpdated}
        />
      ) : null}

      {step2FormFields?.reportType !== 2 ? (
        <LuciInReports
          step3FormFields={step3FormFields}
          setStep3FormFields={setStep3FormFields}
          luciLoading={luciLoading}
          luciPerReport={luciPerReport}
        />
      ) : null}
      {/* <Grid item lg={6} xl={6}>
           <Box className="text-field">
            <InputLabel>
              {CheckValueLocale( "PPT_templete","",{},intl )}
            </InputLabel>
            <RadioGroup
              row
              name="reportPPTTemplete"
              value={reportPPTTemplete}
              onChange={updateFieldValue}
            >
              <FormControlLabel
                value="old_design"
                control={<Radio />}
                label={CheckValueLocale( "regular_ppt_report" ,"",{},intl)}
                disabled={disableRegularPPT.length > 0}
              /> 
              <FormControlLabel
                value="dynamic_design"
                control={<Radio />}
                label={CheckValueLocale( "enhanced_ppt_report" ,"",{},intl)}
              />
            </RadioGroup>
          </Box>
        </Grid> */}
      <PopupModal
        body={dateBody()}
        open={openPopUp}
        close={() => setOpenPopUp(false)}
        classeName={"date-popup-wrapper"}
        minWidth="350px"
      />
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </React.Fragment>
  );
};

export default StepThree;
