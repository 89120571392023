import MainHeader from "../../shared/mainHeader/mainHeader";

const MainPage = (props) => {
  return (
    <>
      <MainHeader
        switchLang={props.switchLang}
        lang={props.lang === undefined ? "en" : props.lang}
      />
    </>
  );
};
export default MainPage;
