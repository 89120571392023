import pageActions from "images/live-dashboard-images/faceBook/Facebook__AccountPage__page_actions.png";
import likesUnlikes from "images/live-dashboard-images/faceBook/Facebook__AccountPage__page_likes_unlikes.png";
import reachImpressions from "images/live-dashboard-images/faceBook/Facebook__AccountPage__page_reach_impressions.png";
import videosViews from "images/live-dashboard-images/faceBook/Facebook__AccountPage__page_videos_views.png";
import views from "images/live-dashboard-images/faceBook/Facebook__AccountPage__page_views.png";
import reactions from "images/live-dashboard-images/faceBook/Facebook__AccountPage__reactions.png";
import engagersVolume from "images/live-dashboard-images/faceBook/Facebook__EngagementsPage__engagers_volume.png";
import topReels from "images/live-dashboard-images/faceBook/Facebook__AccountPage__top_reels.png";
import contactDetailsInteractions from "images/live-dashboard-images/faceBook/Facebook__AccountPage__page_contact_details_interactions.png";

const faceBookImagesWidget = {
  page_actions: pageActions,
  page_likes_unlikes: likesUnlikes,
  page_reach_impressions: reachImpressions,
  page_videos_views: videosViews,
  page_views: views,
  reactions: reactions,
  engagers_volume: engagersVolume,
  top_reels: topReels,
  page_contact_details_interactions: contactDetailsInteractions,
};

export default faceBookImagesWidget;
