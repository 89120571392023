import { useState } from "react";
import {
  Typography,
  Box,
  Tooltip,
  Grid,
  InputAdornment,
  Button,
  OutlinedInput,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import KeywordTooltipChip from "../../../../../../MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import {
  handleInputChange,
  checkPlaceHolderText,
  handleInputFieldName,
  checkAllInputsField,
  checkKeywordPopup,
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import {
  handleCopyBtn,
  handleKeywordsPopUp,
  checkValue,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import CopyIcon from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import { handleTitleSearchKeywordChange } from "../../../../../../MonitorsShared/SharedFunctions/components/newBlogsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";

const KeywordsSection = (props) => {
  const intl = useIntl();
  const {
    resp,
    keywordsInfo,
    keywords,
    indexes,
    titleSearchKeyword,
    handleData,
    titleSearchErrorMsg,
    titleSearch,
    handleTitleskeyboardPress,
    handleDeleteTitleSearch,
    copyBtn,
    handleKeywords,
    handleDelete,
    handleValidation,
    setKeywords,
    setCopyBtn,
    setTitleSearch,
    setTitleSearchErrorMsg,
    setTitleSearchKeywordError,
  } = props;
  // input Foucsed Case
  const [focused, setFocused] = useState(false);
  return (
    <>
      {keywordsInfo.map((keyword, index) => {
        const nbValues = Object.values(keywords[indexes].newsBlogs[index]);
        return (
          <>
            <Grid item xs={6} className="keywords-inputs-fields">
              <Typography
                variant="caption"
                className="keywords keyword-head-title"
              >
                {
                  <Tooltip
                    title={CheckValueLocale(
                      "keywords_spam_keywords_tooltip",
                      "",
                      {},
                      intl,
                    )}
                    arrow
                    placement="bottom"
                  >
                    <Typography variant="caption" className="media-input-label">
                      {CheckValueLocale(
                        handleInputFieldName(keyword),
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  </Tooltip>
                }
              </Typography>
              {/* input fields */}
              <OutlinedInput
                variant="outlined"
                className={` keywords-field keyword-input-field ${checkDirectionLang(
                  keywords[indexes].newsBlogs[index]?.answer,
                )}`}
                onFocus={() => setFocused(true)}
                onBlur={() => {
                  // Delete Error message when leaving the Keyword box
                  if (
                    keywords[indexes]?.newsBlogs[index]?.answer?.length === 0
                  ) {
                    keywords[indexes].newsBlogs[index].errorMsg = "";
                  }
                  setFocused(false);
                }}
                endAdornment={
                  focused && (
                    <InputAdornment onMouseDown={handleInputMouseDown}>
                      <Button
                        className="add-btn-with-plus"
                        onClick={(event) =>
                          handleKeywords(
                            event,
                            keyword,
                            "newsBlogs",
                            index,
                            "btn",
                            resp?.allowed_conditions,
                          )
                        }
                        value={
                          Object.keys(
                            keywords[indexes]?.newsBlogs[index],
                          )[0] === keyword
                            ? keywords[indexes].newsBlogs[index].answer
                            : ""
                        }
                        id="monitor-options-nb-add-keyword-btn"
                      >
                        {CheckValueLocale("add_plus", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  )
                }
                error={
                  (keywords[indexes].newsBlogs[index].errorMsg !== "" &&
                    titleSearchKeyword.length === 0 &&
                    keywords[indexes].newsBlogs[index].errorMsg ===
                      CheckValueLocale(
                        "keywords_is_required_to_monitor_data",
                        "",
                        {},
                        intl,
                      )) ||
                  (keywords[indexes].newsBlogs[index].errorMsg !== "" &&
                    Object.keys(keywords[indexes].newsBlogs[index])[0] ===
                      keyword &&
                    titleSearchKeyword.length === 0) ||
                  (keywords[indexes].newsBlogs[index].errorMsg !== "" &&
                    titleSearchKeyword.length === 0)
                }
                placeholder={CheckValueLocale(
                  checkPlaceHolderText(keyword),
                  "",
                  {},
                  intl,
                )}
                onChange={(e) => {
                  handleInputChange(
                    e,
                    keyword,
                    "newsBlogs",
                    index,
                    indexes,
                    keywords,
                    null,
                    handleValidation,
                    setKeywords,
                  );
                }}
                value={
                  Object.keys(keywords[indexes]?.newsBlogs[index])[0] ===
                  keyword
                    ? keywords[indexes].newsBlogs[index].answer
                    : ""
                }
                onKeyDown={(event) =>
                  handleKeywords(
                    event,
                    keyword,
                    "newsBlogs",
                    index,
                    undefined,
                    resp?.allowed_conditions,
                  )
                }
              />
              {keywords[indexes]?.newsBlogs?.map((obj) => {
                if (
                  Object.values(obj)[1] !== "" &&
                  Object.keys(obj)[0] === keyword
                ) {
                  return (
                    <Box className="err-msg">
                      {keywords[indexes].newsBlogs[index].errorMsg !==
                      CheckValueLocale(
                        "keywords_is_required_to_monitor_data",
                        "",
                        {},
                        intl,
                      )
                        ? CheckValueLocale(Object.values(obj)[1], "", {}, intl)
                        : null}
                    </Box>
                  );
                }
              })}

              <Box className="keyword-spam-wrapper">
                {keywords[indexes]?.newsBlogs?.map((obj) => {
                  if (
                    Object.values(obj)[0].length > 0 &&
                    Object.keys(obj)[0] === keyword
                  ) {
                    return checkValue(Object.values(obj)[0]).map(
                      (labels, index) => (
                        <>
                          <KeywordTooltipChip
                            onDelete={(e) =>
                              handleDelete(
                                e,
                                index,
                                Object.keys(obj)[0],
                                indexes,
                                "newsBlogs",
                              )
                            }
                            dataSource={"newsBlogs"}
                            index={index}
                            indexes={indexes}
                            labels={labels}
                          />
                        </>
                      ),
                    );
                  }
                })}

                {checkAllInputsField(keyword, keywordsInfo) && (
                  <KeywordPopupModal
                    keywords={handleKeywordsPopUp(
                      keyword,
                      keywords[3].newsBlogs[0]?.keywords,
                      keywords[3].newsBlogs[1]?.spam_keywords,
                    )}
                    handleDelete={handleDelete}
                    dataSourceType={"newsBlogs"}
                    keywordType={checkKeywordPopup(keyword)}
                    dataSourceIndex={3} // dataSource index to handle delete keyword
                  />
                )}

                {/* button for copy Spam keyword items */}
                {nbValues[0].length !== 0 && (
                  <CopyIcon
                    checkCopyValue={nbValues[2]}
                    handleData={(e) =>
                      handleData(e, nbValues[0], indexes, index)
                    }
                  />
                )}
              </Box>
            </Grid>

            {/* start Title keywords section */}
            {keyword === "spam_keywords" && (
              <Grid item xs={6} className="keywords-inputs-fields">
                <Typography
                  variant="caption"
                  className="keywords keyword-head-title"
                >
                  {
                    <Tooltip
                      title={
                        <Box className="title-search-tooltip">
                          {intl.formatMessage(
                            {
                              id: "title_search_keyword_tooltip",
                            },
                            {
                              break: <br />,
                            },
                          )}
                        </Box>
                      }
                      arrow
                      placement="bottom"
                    >
                      <Typography
                        variant="caption"
                        className="media-input-label"
                      >
                        {CheckValueLocale("title_search_keyword", "", {}, intl)}
                      </Typography>
                    </Tooltip>
                  }
                </Typography>

                {/* input field */}
                <OutlinedInput
                  className={` keywords-field keyword-input-field ${checkDirectionLang(
                    titleSearch,
                  )}`}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  endAdornment={
                    focused && (
                      <InputAdornment onMouseDown={handleInputMouseDown}>
                        <Button
                          className="add-btn-with-plus"
                          onClick={(event) =>
                            handleTitleskeyboardPress(event, "btn")
                          }
                          value={titleSearch}
                          id="monitor-options-nb-add-keyword-btn"
                        >
                          {CheckValueLocale("add_plus", "", {}, intl)}
                        </Button>
                      </InputAdornment>
                    )
                  }
                  error={
                    titleSearchErrorMsg !== "" ||
                    (titleSearchKeyword.length === 0 &&
                      keywords[indexes].newsBlogs[index].errorMsg ===
                        CheckValueLocale(
                          "keywords_is_required_to_monitor_data",
                          "",
                          {},
                          intl,
                        ))
                  }
                  placeholder={CheckValueLocale(
                    "scan_posts_have_titles",
                    "",
                    {},
                    intl,
                  )}
                  onKeyPress={handleTitleskeyboardPress}
                  onChange={(e) =>
                    handleTitleSearchKeywordChange(
                      e,
                      setTitleSearch,
                      titleSearchKeyword,
                      setTitleSearchErrorMsg,
                      setTitleSearchKeywordError,
                      intl,
                    )
                  }
                  value={titleSearch}
                />
                {titleSearchErrorMsg !== "" && (
                  <Box component="p" className="err-msg">
                    {CheckValueLocale(titleSearchErrorMsg, "", {}, intl)}
                  </Box>
                )}

                <Box className="keyword-spam-wrapper">
                  {checkValue(titleSearchKeyword)?.map((title, i) => {
                    return (
                      <KeywordTooltipChip
                        onDelete={() => handleDeleteTitleSearch(i)}
                        dataSource={"news"}
                        index={i}
                        indexes={indexes}
                        labels={title}
                      />
                    );
                  })}
                  {(keyword !== "keywords" || keyword !== "spam_keywords") && (
                    <KeywordPopupModal
                      keywords={titleSearchKeyword}
                      handleDelete={handleDeleteTitleSearch}
                      dataSourceType={"newsBlogs"}
                      keywordType={"title_search_keyword"}
                      isTitleSearch
                    />
                  )}

                  {/* button for copy Spam keyword items */}
                  {titleSearchKeyword.length !== 0 && (
                    <Tooltip title={copyBtn}>
                      <ContentCopyIcon
                        className="copy-icon"
                        onClick={() =>
                          handleCopyBtn(
                            setCopyBtn,
                            CheckValueLocale("copied", "", {}, intl),
                            titleSearchKeyword,
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            )}
            {/* end Title keywords section */}
          </>
        );
      })}
    </>
  );
};

export default KeywordsSection;
