import {
  Divider,
  Typography,
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray, isEmptyObj } from "utils/helpers";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import CopyIcon from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import {
  checkAllInputsField,
  checkLabelTextEdit,
  checkKeywordPopup,
  handleInputChange,
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import {
  handleKeywordsPopUp,
  checkValue,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import TestYourKeyword from "../../../../MonitorsShared/SharedComponent/testYourKeyword/testYourKeyword";
import HashtagUsedNumber from "pages/socialListing/MonitorsOptions/createMonitor/MonitorTypes/KeywordsMonitor/components/keywordHeader/hashtagUsedNumber";
import HashtagsLimitReached from "pages/settings/components/InstagramHashtags/components/hashtagsHeader/hashtagsLimitReached";
import LucButton from "shared/lucButton/lucButton";
import { useHistory } from "react-router-dom";

const InstagramKeywords = (props) => {
  const intl = useIntl();
  let history = useHistory();
  const {
    setSQuestion,
    handleKeywords,
    keywords,
    handleDelete,
    handleValidation,
    handleData,
    wordCountInstagram,
    setUpdatedParams,
    updatedParams,
    monitorDetails,
    indexes,
    monitorErrors,
    setMonitorErrors,
    monitorInstagramHashtagLimit,
    monitorInstagramOldHashtags,
    monitorInstagramSettingHashtags,
  } = props;
  const [focused, setFocused] = useState(false);

  let sections = ["hashtags", "spam_hashtags"];
  const [err, setErr] = useState();
  const handleKeyDown = (event, keywordType, index, clickType) => {
    if (event.keyCode === 13 || clickType === "btn") {
      if (
        (keywordType === "hashtags" &&
          !(event.target.value.trim().length === 0)) ||
        keywordType === "spam_hashtags"
      ) {
        handleKeywords(
          event,
          keywordType,
          "instagram",
          index,
          clickType,
          monitorInstagramHashtagLimit,
        );
        handleUpdateParams(keywordType);
      }
    }
  };

  const handleUpdateParams = (keywordType) => {
    if (keywordType === "hashtags") {
      setUpdatedParams({
        ...updatedParams,
        keywords: {
          ...updatedParams.keywords,
          INSTAGRAM: Object.values(keywords[1])[0][0]?.hashtags?.join(),
        },
      });
    }
    if (keywordType === "spam_hashtags") {
      setUpdatedParams({
        ...updatedParams,
        spam_keywords: {
          ...updatedParams.spam_keywords,
          INSTAGRAM: Object.values(keywords[1])[0][1].spam_hashtags?.join(),
        },
      });
    }
  };

  const handleDeleteWord = (e, index, keywordType) => {
    handleDelete(e, index, keywordType, indexes, "instagram");
    if (keywordType === "hashtags" || keywordType === "spam_hashtags") {
      handleUpdateParams(keywordType);
    }
  };

  // Keep tracking for any error related to IG hashtags
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      ig_hashtags: keywords[indexes]?.instagram[0]?.errorMsg,
    });
  }, [keywords[indexes]?.instagram[0]?.errorMsg]);
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      ig_spam_hashtags: keywords[indexes]?.instagram[1]?.errorMsg,
    });
  }, [keywords[indexes]?.instagram[1]?.errorMsg]);

  const igHashManagerDisabled =
    updatedParams?.keywords?.INSTAGRAM !== undefined
      ? !isEmptyObj(updatedParams?.keywords?.INSTAGRAM)
      : false;

  return (
    <>
      <Box className="inline-align">
        <Box>
          <Typography className="section-header">
            {CheckValueLocale("instagram", "", {}, intl)}
          </Typography>
          <Typography className="posts-used">
            {CheckValueLocale("posts_used", "", {}, intl)}:{" "}
            {monitorDetails?.posts_count_stats?.INSTAGRAM
              ? monitorDetails?.posts_count_stats?.INSTAGRAM.toLocaleString()
              : 0}
          </Typography>
        </Box>
        {/* __________________________________________________________ */}

        <Box className="inline-flex data-source-setting instagram-setting-manager">
          <HashtagUsedNumber
            wordCountDataSource={wordCountInstagram}
            limitationOfDataSource={monitorInstagramHashtagLimit}
            instaHashtag
          />
          <Tooltip
            title={
              igHashManagerDisabled ? (
                <Box className="instagram-hashtags-tooltip">
                  {CheckValueLocale("ig_hashtags_manager_hint", "", {}, intl)}
                </Box>
              ) : (
                ""
              )
            }
            placement="top"
            arrow
          >
            <Box>
              <LucButton
                variant="outline"
                size="small"
                onClick={() => history.push(`/settings/instagram_hashtags`)}
                disabled={igHashManagerDisabled}
                id="monitor-options-ig-hashtags-manager-btn"
              >
                {CheckValueLocale("ig_hashtags_manager", "", {}, intl)}
              </LucButton>
            </Box>
          </Tooltip>
          <Divider
            orientation="vertical"
            className="vertical-line-hashtags-manager"
          />
          <TestYourKeyword
            keywords={monitorDetails.keywords.INSTAGRAM}
            spamKeywords={monitorDetails.spam_keywords.INSTAGRAM}
          />
        </Box>
      </Box>
      {/* __________________________________________________________ */}
      <Box className="section-box">
        {sections.map((keyword, index) => {
          console.assert(keyword);
          const instaValues = Object.values(
            keywords[indexes]?.instagram[index],
          );
          return (
            <>
              <Box className="section-box-data-keyword-setting">
                <Tooltip
                  title={CheckValueLocale(
                    "keywords_ig_spam_keywords_tooltip",
                    "",
                    {},
                    intl,
                  )}
                  arrow
                  placement="bottom"
                >
                  <Typography className="main-titles underlined">
                    {CheckValueLocale(keyword, "", {}, intl)}
                  </Typography>
                </Tooltip>
                {/* __________________________________________________________ */}
                <TextField
                  id="outlined-email-input"
                  label={CheckValueLocale(
                    checkLabelTextEdit(keyword),
                    "",
                    {},
                    intl,
                  )}
                  className={`
                  spam-text-field input-field-edit 
                  ${
                    keyword === "hashtags"
                      ? "input-keyword-field"
                      : "input-field-spam"
                  }
                  ${checkDirectionLang(
                    keywords[indexes].instagram[index]?.answer,
                  )}`}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    endAdornment: focused && (
                      <InputAdornment onMouseDown={handleInputMouseDown}>
                        <Button
                          className="add-btn-with-plus add-btn-plus-setting"
                          onClick={(event) =>
                            handleKeyDown(event, keyword, index, "btn")
                          }
                          value={
                            Object.keys(
                              keywords[indexes]?.instagram[index],
                            )[0] === keyword
                              ? keywords[indexes].instagram[index].answer
                              : ""
                          }
                          id="monitor-options-ig-kw-add-keyword-btn"
                        >
                          {CheckValueLocale("add_plus", "", {}, intl)}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  error={
                    keywords[indexes]?.instagram[index]?.errorMsg !== "" &&
                    Object.keys(keywords[indexes]?.instagram[index])[0] ===
                      keyword
                  }
                  onChange={(e) => {
                    setSQuestion("instagram");
                    handleInputChange(
                      e,
                      keyword,
                      "instagram",
                      index,
                      indexes,
                      keywords,
                      setErr,
                      handleValidation,
                    );
                  }}
                  value={
                    Object.keys(keywords[indexes]?.instagram[index])[0] ===
                    keyword
                      ? keywords[indexes].instagram[index].answer
                      : ""
                  }
                  onKeyDown={(event) => handleKeyDown(event, keyword, index)}
                  disabled={
                    keyword === "hashtags"
                      ? wordCountInstagram >= monitorInstagramHashtagLimit
                      : false
                  }
                />
              </Box>
              {/* ___________________________________________________________________________________ */}
              {keywords[indexes]?.instagram?.map((obj) => {
                if (
                  Object.values(obj)[1] !== "" &&
                  Object.keys(obj)[0] === keyword
                ) {
                  return (
                    <Box className="error-msg-keyword">
                      <Typography className="main-titles"></Typography>
                      <Box
                        component="p"
                        className="spam-text-field err-msg-text keywords-error-msg"
                      >
                        {CheckValueLocale(
                          Object.values(obj)[1],
                          "",
                          { limit: monitorInstagramHashtagLimit },
                          intl,
                        )}
                      </Box>
                    </Box>
                  );
                }
              })}
              {/* Error Msg is fired in case of hashtags input filed is empty and save btn will not work  */}
              {keyword === "hashtags" &&
                isEmptyArray(keywords[indexes]?.instagram[0]?.hashtags) && (
                  <Box className="section-box-data">
                    <Typography className="main-titles"></Typography>
                    <Box
                      component="p"
                      className="spam-text-field err-msg-text keywords-error-msg"
                    >
                      {CheckValueLocale("this_field_required", "", {}, intl)}
                    </Box>
                  </Box>
                )}
              {/* ____________________________________________________________________________________ */}
              <Box className="chip-keywords-wrapper">
                <Box></Box>
                <Box className="spam-keywords-area">
                  {keywords[indexes]?.instagram?.map((obj) => {
                    if (
                      Object.values(obj)[0].length > 0 &&
                      Object.keys(obj)[0] === keyword
                    ) {
                      return checkValue(Object.values(obj)[0]).map(
                        (labels, index) => (
                          <>
                            <KeywordTooltipChip
                              onDelete={(e) =>
                                checkValue(Object.values(obj)[0]).length !== 1
                                  ? handleDeleteWord(
                                      e,
                                      index,
                                      Object.keys(obj)[0],
                                    )
                                  : null
                              }
                              dataSource={"instagram"}
                              indexes={indexes}
                              labels={labels}
                              index={index}
                              keywordSetting
                              usedLabel={
                                monitorInstagramOldHashtags?.includes(
                                  labels.replace("#", ""),
                                ) &&
                                !monitorInstagramSettingHashtags?.includes(
                                  labels.replace("#", ""),
                                )
                              }
                              lastLabel={
                                checkValue(Object.values(obj)[0]).length === 1
                              }
                            />
                          </>
                        ),
                      );
                    }
                  })}
                  {checkAllInputsField(keyword, sections) && (
                    <KeywordPopupModal
                      keywords={handleKeywordsPopUp(
                        keyword,
                        keywords[1].instagram[0]?.hashtags,
                        keywords[1].instagram[1]?.spam_hashtags,
                      )}
                      handleDelete={handleDeleteWord}
                      dataSourceType={"instagram"}
                      keywordType={checkKeywordPopup(keyword)}
                      dataSourceIndex={1} // dataSource index to handle delete keyword
                      usedkeywords={monitorInstagramOldHashtags}
                      settingkeywords={monitorInstagramSettingHashtags}
                    />
                  )}
                  {/* Copy Icon___________________________________________________________________________ */}
                  {instaValues[0].length !== 0 && (
                    <CopyIcon
                      checkCopyValue={instaValues[2]}
                      handleData={(e) =>
                        handleData(e, instaValues[0], indexes, index)
                      }
                    />
                  )}
                </Box>
              </Box>
              {wordCountInstagram >= monitorInstagramHashtagLimit &&
              keyword === "hashtags" ? (
                <Box className="setting-hashtags-limit-wrapper">
                  <HashtagsLimitReached />
                </Box>
              ) : null}
              <Divider />
            </>
          );
        })}
      </Box>
    </>
  );
};

export default InstagramKeywords;
