import React, { useEffect, useState } from "react";
import { Box, Switch, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../surveyBuilder.scss";

const YesNo = (props) => {
  const {
    rootQuestion,
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
    hasProceedSec,
    hasProceedQues,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
    }
  }, [question]);

  const handleSwitch = (e, constraint) => {
    if (constraint === "is_proceed_on_answer") {
      const setNextFunc = (item) => {
        if (e.target.checked === true) {
          item["next_section"] =
            sectionIndex === filteredSections?.length - 1
              ? -1
              : filteredSections[sectionIndex].order + 1;
        } else {
          delete item["next_section"];
        }
      };
      const filteredSectionsNotDeleted = filteredSections[
        sectionIndex
      ].questions.filter((question) => question?.is_deleted !== true);
      filteredSectionsNotDeleted?.[questionIndex].question.items.forEach(
        setNextFunc,
      );

      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex].is_proceed_on_answer = e.target.checked;
      setSurveySections([...surveySections]);
      return;
    }

    filteredSections[sectionIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex].question.constraints[constraint] = e.target.checked;
    setSurveySections([...surveySections]);
  };

  return (
    <>
      {Object.entries(question.constraints).map((constraint, index) => {
        return (
          <Box
            className={`constraints-text ${
              editable === false && `disabled-color`
            }`}
            key={index}
          >
            <Box>{CheckValueLocale(constraint[0], "", {}, intl)}</Box>
            <Switch
              data-testid="required_switch"
              onChange={(e) => handleSwitch(e, constraint[0])}
              checked={Object.values(constraint)[1]}
              disabled={
                editable === false && constraint[0] !== "required"
                  ? true
                  : false
              }
            />
          </Box>
        );
      })}
      <Box
        className={`constraints-text ${
          (filteredSections?.length < 2 ||
            rootQuestion?.is_proceed_editable === false) &&
          `disabled-color`
        } ${
          hasProceedSec === sectionIndex &&
          hasProceedQues !== questionIndex &&
          `disabled-color`
        }`}
      >
        <Box>{CheckValueLocale("is_proceed_on_answer", "", {}, intl)}</Box>
        {hasProceedSec === sectionIndex && hasProceedQues !== questionIndex ? (
          <Tooltip
            title={CheckValueLocale("proceed_disabled_msg", "", {}, intl)}
            placement="left"
            arrow
            componentsProps={{
              tooltip: {
                sx: { width: "160px", padding: "13px", lineHeight: "16px" },
              },
            }}
          >
            <Box className="disabled-proceed-switch">
              <Switch
                data-testid="proceed_on_answer_switch"
                onChange={(e) => handleSwitch(e, "is_proceed_on_answer")}
                checked={false}
                disabled={true}
              />
            </Box>
          </Tooltip>
        ) : (
          <Switch
            data-testid="proceed_on_answer_switch"
            onChange={(e) => handleSwitch(e, "is_proceed_on_answer")}
            checked={rootQuestion?.is_proceed_on_answer}
            disabled={
              filteredSections?.length < 2 ||
              rootQuestion?.is_proceed_editable === false
            }
          />
        )}
      </Box>
    </>
  );
};

export default YesNo;
