import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Paper, Typography, Chip } from "@mui/material";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale, testChannelBotMessage } from "utils/helpers/index";
import "./addChannel.scss";

export default function BotConfiguration(props) {
  const formik = props?.formik;
  const intl = useIntl();
  const [botMessage, setBotMessage] = useState("");
  const initialBotMessages = formik?.values.botMessageArray;

  const [botErrorMessage, setBotErrorMessage] = useState("");

  const handleDelete = (_, index) => {
    const messagesArray = formik?.values.botMessageArray;
    messagesArray.splice(index, 1);
    formik?.setFieldValue("botMessageArray", messagesArray);
  };

  const handleEnter = (e) => {
    if (
      e.keyCode === 13 &&
      e.target.value !== "" &&
      e.target.value.trim().length !== 0 &&
      botErrorMessage === ""
    ) {
      e.preventDefault();
      formik?.setFieldValue("botMessageArray", [
        ...formik?.values.botMessageArray,
        e.target.value,
      ]);
      setBotMessage("");
    }
  };

  const handleChangeBotMessage = (e) => {
    const value = e.target.value;
    setBotMessage(value);
    if (!testChannelBotMessage(value)) {
      setBotErrorMessage(
        CheckValueLocale("bot_session_configuration_error_msg", "", {}, intl),
      );
    } else {
      setBotErrorMessage("");
    }
  };

  const handleChangeBot = (e) => {
    const intValue = parseInt(e.target.value);
    formik?.setFieldValue("isBot", intValue);
    if (intValue === 0) {
      formik?.setFieldValue("botMessageArray", []);
    } else {
      formik?.setFieldValue("botMessageArray", initialBotMessages);
    }
  };

  return (
    <>
      <Paper
        className={
          props?.editBot
            ? "edit-bot-configuration bot-configuration"
            : "bot-configuration"
        }
      >
        {!props?.loading ? (
          <>
            <Typography
              className={props?.editBot ? "edit-dm-bot-heading" : "bot-heading"}
            >
              {props?.editBot ? "1. " : "3. "}
              {CheckValueLocale("bot_configuration_dm", "", {}, intl)}
            </Typography>

            <FormControl className="form-control-typo">
              <Typography
                sx={{ margin: "revert" }}
                className={props?.editBot ? "edit-select-text" : "select-text"}
              >
                {CheckValueLocale("do_you_use_chatbot_dm", "", {}, intl)}
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={formik?.values.isBot}
                name="radio-buttons-group"
                className="radiobuttons"
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  className={
                    props?.editBot ? "radio-dm-config-btn" : "radio-btn"
                  }
                  label={CheckValueLocale("yes", "", {}, intl)}
                  onChange={(e) => {
                    handleChangeBot(e);
                  }}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  className={
                    props?.editBot ? "radio-dm-config-btn" : "radio-btn"
                  }
                  label={CheckValueLocale("no", "", {}, intl)}
                  onChange={(e) => {
                    handleChangeBot(e);
                  }}
                />
              </RadioGroup>
              {Number(formik?.values.isBot) === 1 ? (
                <>
                  <Typography className="bot-textarea-head">
                    {CheckValueLocale(
                      "bot_to_human_handover_message",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                  <div className="text-area-style">
                    <TextField
                      id="standard-basic"
                      placeholder={CheckValueLocale(
                        "enter_the_message_then_press",
                        "",
                        {},
                        intl,
                      )}
                      multiline
                      className="textarea-configuration"
                      error={botErrorMessage !== "" ? botErrorMessage : ""}
                      name="text"
                      onKeyDown={(e) => handleEnter(e)}
                      onChange={handleChangeBotMessage}
                      value={botMessage}
                      InputProps={{
                        inputProps: {
                          style: {
                            height: "70px",
                            width: "800px",
                          },
                        },
                      }}
                    />

                    <p>
                      {botErrorMessage !== "" ? (
                        <p className="err-msg">
                          {CheckValueLocale(
                            "bot_session_configuration_error_msg",
                            "",
                            {},
                            intl,
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </p>

                    <div
                      className={
                        props?.editBot ? "edit-tagbox tag-box" : "tag-box"
                      }
                    >
                      {formik?.values.botMessageArray.length
                        ? formik?.values.botMessageArray?.map(
                            (message, index) => (
                              <>
                                {message !== "" ? (
                                  <Chip
                                    style={{
                                      textAlign: "initial !important",
                                    }}
                                    label={message}
                                    onDelete={(_) => handleDelete(_, index)}
                                    className="chip-style"
                                  />
                                ) : null}
                              </>
                            ),
                          )
                        : null}
                    </div>
                  </div>
                </>
              ) : null}
            </FormControl>
          </>
        ) : (
          <div className="loader-box bot-configuration-loading">
            <CircularLoading />
          </div>
        )}
      </Paper>
    </>
  );
}
