import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useIntl } from "react-intl";
import { Box, Tooltip, Typography } from "@mui/material";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const TitleBox = (props) => {
  const { title, tooltipText, customerView, isDashBoard } = props;
  const intl = useIntl();

  return (
    <Box className="title-box-container">
      <Typography component={"h2"} className="title-box">
        {CheckValueLocale(title, "", {}, intl)}
        {tooltipText ? (
          <Tooltip
            title={CheckValueLocale(tooltipText, "", {}, intl)}
            placement="top"
            arrow
            id="homePageTooltip"
          >
            <Typography component={"span"}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Typography>
          </Tooltip>
        ) : null}
        {isDashBoard ? (
          <Box component={"span"} className="new-box">
            {CheckValueLocale("new", "", {}, intl)}
          </Box>
        ) : null}
      </Typography>
      <Typography component={"h3"} className="title-box">
        {CheckValueLocale(customerView, "", {}, intl)}
      </Typography>
    </Box>
  );
};

export default TitleBox;
