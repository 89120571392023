import { CircularProgress } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";

const FormButton = (props) => {
  const { text, ...rest } = props;
  return (
    <LucButton className="button-form" fullWidth {...rest}>
      {text}
    </LucButton>
  );
};
export default FormButton;
