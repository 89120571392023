import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faClone,
  faEllipsisH,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import DragableQuestions from "./DragableQuestions";
import { NestedMenuItem } from "mui-nested-menu";
import { dummyQuestions } from "pages/SurveyBuilder/dummyQuestions";
import PopupModal from "components/popupModal";
import { faAlignLeft, faAlignRight } from "@fortawesome/free-solid-svg-icons";

const SectionsContainer = (props) => {
  const {
    surveySections,
    setSurveySections,
    filteredSections,
    emptySection,
    setEmptySection,
    sqIndex,
    setSQIndex,
    ssIndex,
    setSSIndex,
    anchorEl,
    setAnchorEl,
    open,
    setOperationPerformed,
    setNewIndex,
    setStats,
    handleDuplicateSection,
    handleDeleteSection,
    scrollToIdFunc,
    proceedError,
    afterSecError,
    secTitleErr,
    handleSectionDir,
  } = props;

  const intl = useIntl();

  const dropdownOptions = {
    mainOptions: [
      "yes_no",
      "multiple_choice",
      "dropdown",
      "number",
      "short_text",
    ],
    contactInfoOptions: ["email", "phone_number", "name", "social_media"],
    NPSquestion: ["nps"],
    scaleQuestion: ["scale"],
  };

  const [sectionStats, setSectionStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const handleDeleteSectionModal = (e, index, sectionName) => {
    e.stopPropagation();
    handleCloseMoreMenu(e);
    setNewIndex(index);
    setSectionStats({
      openDeleteModal: true,
      addClasses: "model-buttons ",
      title: CheckValueLocale("delete_entire_section", "", {}, intl),
      body: (
        <Box className="delete-pop">
          <Box className="delete-popup-title">
            {filteredSections?.length == 2
              ? CheckValueLocale(
                  "deleting_section_name",
                  "",
                  { sectionName: <strong>{sectionName}</strong> },
                  intl,
                )
              : CheckValueLocale(
                  "deleting_section_name_sure",
                  "",
                  { sectionName: <strong>{sectionName}</strong> },
                  intl,
                )}
            <br />
            {filteredSections?.length !== 2 &&
              CheckValueLocale("delete_all_questions", "", {}, intl)}
            {filteredSections?.length !== 2 && (
              <span>
                {CheckValueLocale("you_can_delete_questions", "", {}, intl)}
              </span>
            )}
          </Box>
        </Box>
      ),
      modalRightBtn: CheckValueLocale("delete_section_btn", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      warning: true,
      secIndex: index,
    });
  };

  const handleAddQuestion = (e, sectionIndex, type) => {
    e.stopPropagation();
    handleCloseAddMenu(e);
    let sectionQuestions = [...filteredSections[sectionIndex]?.questions];

    dummyQuestions?.map((question) => {
      if (question?.question?.type === type) {
        const items = {};
        items.question = {};
        items.question.text = CheckValueLocale(
          question?.question?.text,
          "",
          {},
          intl,
        );
        items.question.description = question?.question?.description;
        items.question.editable = question?.question?.editable;
        items.question.type = question?.question?.type;
        items.is_deleted = question?.is_deleted;
        if (question?.is_proceed_on_answer !== undefined)
          items.is_proceed_on_answer = false;
        if (question?.is_proceed_editable !== undefined)
          items.is_proceed_editable = true;

        items.question.constraints = {};

        Object.keys(question?.question?.constraints).forEach((key) => {
          if (key === "min_number" || key === "max_number") {
            items.question.constraints[key] = {};
            items.question.constraints[key].checked =
              question?.question?.constraints[key]?.checked;
            items.question.constraints[key].value =
              question?.question?.constraints[key]?.value;
          } else {
            items.question.constraints[key] =
              question?.question?.constraints[key];
          }
        });

        if (
          question?.question?.items !== undefined ||
          question?.question?.items?.length >= 0
        ) {
          let arr = [];
          if (question?.question?.items?.length > 0) {
            question.question.items.map((i) => {
              const items = {};
              items[Object.keys(i)[0]] = Object.values(i)[0];
              arr.push(items);
            });
          }
          items.question.items = arr;
        }

        sectionQuestions?.push(items);
      }
    });

    if (emptySection === sectionIndex) setEmptySection(null);
    filteredSections[sectionIndex].questions = sectionQuestions;
    setSurveySections([...surveySections]);
    setSQIndex(sectionQuestions?.length);
    setSSIndex(sectionIndex + 1);
    setOperationPerformed("operationsPerformed");
    // setQuestionsAnchorEl(null);
    scrollToIdFunc(
      `section-${sectionIndex}-question-${sectionQuestions?.length - 1}`,
    );
  };
  const handleCloseDeleteModal = () => {
    return setSectionStats({ openDeleteModal: false });
  };

  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [openedAddMenuIndex, setOpenedAddMenuIndex] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const [openedMoreMenuIndex, setOpenedMoreMenuIndex] = useState(null);

  const handleClickAddMenu = (e, index) => {
    e.stopPropagation();
    setOpenedAddMenuIndex(index);
    setAddAnchorEl(e.currentTarget);
  };

  const handleCloseAddMenu = (e) => {
    e.stopPropagation();
    setOpenedAddMenuIndex(null);
    setAddAnchorEl(null);
  };

  const handleClickMoreMenu = (e, index) => {
    e.stopPropagation();
    setOpenedMoreMenuIndex(index);
    setMoreAnchorEl(e.currentTarget);
  };

  const handleCloseMoreMenu = (e) => {
    e.stopPropagation();
    setOpenedMoreMenuIndex(null);
    setMoreAnchorEl(null);
  };

  // handle move question
  const handleMoveQuestion = async (
    e,
    sectionIndex,
    questionIndex,
    direction,
  ) => {
    e.stopPropagation();

    const targetSecIndex =
      direction === "up" ? sectionIndex - 1 : sectionIndex + 1;

    let questionToMove = {
      ...(filteredSections[sectionIndex]?.questions?.filter(
        (ques) => !ques?.is_deleted,
      )?.[questionIndex] || {}),
    };

    if (questionToMove?.question?.items?.length) {
      questionToMove.question.items = questionToMove?.question?.items?.map(
        (item) => {
          const { next_section, ...updatedItem } = item;
          return updatedItem;
        },
      );
    }

    delete questionToMove?.question_id;
    questionToMove.is_deleted = false;
    questionToMove.is_proceed_on_answer = false;
    questionToMove.is_proceed_editable = true;
    questionToMove.question.editable = true;

    filteredSections[sectionIndex].questions.filter(
      (ques) => !ques?.is_deleted,
    )[questionIndex].is_deleted = true;

    filteredSections[targetSecIndex].questions = [
      ...filteredSections?.[targetSecIndex]?.questions,
      questionToMove,
    ];

    const updatedSurveySections = surveySections?.map((section) => ({
      ...section,
      questions: section?.questions?.filter((ques) =>
        !ques?.question_id && ques?.is_deleted ? false : true,
      ),
    }));

    await setSurveySections([...updatedSurveySections]);

    const sectionNewLength = filteredSections[
      targetSecIndex
    ]?.questions?.filter((ques) => !ques?.is_deleted)?.length;

    setSSIndex(targetSecIndex + 1);
    setSQIndex(sectionNewLength);
    scrollToIdFunc(
      `section-${targetSecIndex}-question-${sectionNewLength - 1}`,
    );
  };

  return (
    <Box>
      {filteredSections?.map((section, index) => {
        const isAddOpen = openedAddMenuIndex === index;
        const isMoreOpen = openedMoreMenuIndex === index;

        return (
          <Accordion className="section-accordion" key={index} defaultExpanded>
            <AccordionSummary
              aria-controls={`content-${index + 1}`}
              id={`header-${index + 1}`}
              className={`section-accordion-header sticky-summary ${
                (afterSecError === index ||
                  emptySection === index ||
                  secTitleErr === index) &&
                `section-error`
              }`}
              expandIcon={
                <FontAwesomeIcon icon={faAngleRight} className="expand-icon" />
              }
            >
              <Box className="accordion-header-container">
                {section?.name?.length > 25 ? (
                  <Tooltip
                    arrow
                    placement="top"
                    title={section?.name}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: "270px",
                          padding: "13px",
                          margin: "10px",
                          lineHeight: "16px",
                        },
                      },
                    }}
                  >
                    <Typography className="accordion-section-title">
                      {CheckValueLocale(section?.name, "", {}, intl)?.substring(
                        0,
                        23,
                      ) + "..."}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography className="accordion-section-title">
                    {CheckValueLocale(section?.name, "", {}, intl)}
                  </Typography>
                )}
                <Box className="btns-container">
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={CheckValueLocale("more", "", {}, intl)}
                  >
                    <Box className={`more-btn-box ${isMoreOpen && `active`}`}>
                      <LucButton
                        id="more-button"
                        aria-controls={isMoreOpen ? "more-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={isMoreOpen ? "true" : undefined}
                        variant="flat"
                        size="small"
                        minWidth={36}
                        type={emptySection === index ? `red` : `primary`}
                        onClick={(e) => handleClickMoreMenu(e, index)}
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </LucButton>
                    </Box>
                  </Tooltip>
                  <Menu
                    id="more-menu"
                    anchorEl={moreAnchorEl}
                    open={isMoreOpen}
                    onClose={(e) => handleCloseMoreMenu(e)}
                  >
                    <MenuItem
                      id={`duplicate-section-${index}`}
                      onClick={(e) => {
                        handleCloseMoreMenu(e, index);
                        handleDuplicateSection(e, index);
                      }}
                    >
                      <FontAwesomeIcon icon={faClone} fontSize="small" />
                      <Typography component="h1" mx={1}>
                        {CheckValueLocale("duplicate_section", "", {}, intl)}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      id={`direction-section-${index}`}
                      disabled={
                        filteredSections?.[index]?.text_direction === "ltr"
                      }
                      onClick={(e) => {
                        handleCloseMoreMenu(e, index);
                        handleSectionDir("ltr", index);
                      }}
                    >
                      <FontAwesomeIcon icon={faAlignLeft} fontSize="small" />
                      <Typography component="h1" mx={1}>
                        {CheckValueLocale("direction_ltr", "", {}, intl)}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      id={`direction-section-${index}`}
                      disabled={
                        filteredSections?.[index]?.text_direction === "rtl"
                      }
                      onClick={(e) => {
                        handleCloseMoreMenu(e, index);
                        handleSectionDir("rtl", index);
                      }}
                    >
                      <FontAwesomeIcon icon={faAlignRight} fontSize="small" />

                      <Typography component="h1" mx={1}>
                        {CheckValueLocale("direction_rtl", "", {}, intl)}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      id={`delete-section-${index}`}
                      onClick={(e) =>
                        handleDeleteSectionModal(e, index, section?.name)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} fontSize="small" />
                      <Typography component="h1" mx={1}>
                        {CheckValueLocale("delete_section", "", {}, intl)}
                      </Typography>
                    </MenuItem>
                  </Menu>
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={CheckValueLocale("new_question", "", {}, intl)}
                  >
                    <Box className="add-btn-box">
                      <LucButton
                        id="add-button"
                        aria-controls={isAddOpen ? "add-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={isAddOpen ? "true" : undefined}
                        variant="flat"
                        size="small"
                        minWidth={36}
                        type={emptySection === index ? `red` : `primary`}
                        onClick={(e) => handleClickAddMenu(e, index)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </LucButton>
                    </Box>
                  </Tooltip>
                  <Menu
                    id="add-menu"
                    anchorEl={addAnchorEl}
                    open={isAddOpen}
                    onClose={(e) => handleCloseAddMenu(e)}
                  >
                    <NestedMenuItem
                      label={CheckValueLocale("contact_info", "", {}, intl)}
                      parentMenuOpen={openedAddMenuIndex === index}
                      style={{ padding: "7px 8px" }}
                    >
                      {dropdownOptions?.contactInfoOptions?.map((option, i) => (
                        <MenuItem
                          key={i}
                          onClick={(e) => handleAddQuestion(e, index, option)}
                          id={`survey-builder-contact-info-${
                            option ?? "option"
                          }`}
                        >
                          {CheckValueLocale(option, "", {}, intl)}
                        </MenuItem>
                      ))}
                    </NestedMenuItem>
                    {dropdownOptions?.mainOptions?.map((option, i) => (
                      <MenuItem
                        key={i}
                        onClick={(e) => handleAddQuestion(e, index, option)}
                        id={`survey-builder-options-${option ?? "option"}`}
                      >
                        {CheckValueLocale(option, "", {}, intl)}
                      </MenuItem>
                    ))}
                    {dropdownOptions?.NPSquestion?.map((option, i) => (
                      <MenuItem
                        key={i}
                        onClick={(e) => handleAddQuestion(e, index, option)}
                        id={`survey-builder-nps-mark-${option ?? "option"}`}
                      >
                        {CheckValueLocale("nps_mark", "", {}, intl)}
                      </MenuItem>
                    ))}
                    {dropdownOptions?.scaleQuestion?.map((option, i) => (
                      <MenuItem
                        key={i}
                        onClick={(e) => handleAddQuestion(e, index, option)}
                        id={`survey-builder-nps-mark-${option ?? "option"}`}
                      >
                        {CheckValueLocale("scale", "", {}, intl)}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="section-accordion-details">
              {section?.questions?.length < 1 ? (
                <Box className="empty-section-title">
                  <Typography component="p">
                    {CheckValueLocale(
                      "add_questions_will_appear",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                </Box>
              ) : (
                <DragableQuestions
                  sectionQuestions={section?.questions}
                  surveySections={surveySections}
                  setSurveySections={setSurveySections}
                  filteredSections={filteredSections}
                  sectionIndex={index}
                  sqIndex={sqIndex}
                  setSQIndex={setSQIndex}
                  ssIndex={ssIndex}
                  setSSIndex={setSSIndex}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  open={open}
                  setOperationPerformed={setOperationPerformed}
                  setNewIndex={setNewIndex}
                  setStats={setStats}
                  scrollToIdFunc={scrollToIdFunc}
                  proceedError={proceedError}
                  handleMoveQuestion={handleMoveQuestion}
                />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}

      {sectionStats?.openDeleteModal && (
        <PopupModal
          title={sectionStats?.title}
          body={sectionStats?.body}
          leftBtn={sectionStats?.modalLeftBtn}
          rightBtn={sectionStats?.modalRightBtn}
          open={sectionStats?.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={(e) => {
            handleCloseDeleteModal();
            handleDeleteSection(e, sectionStats?.secIndex);
          }}
          warning={sectionStats?.warning}
          classeName="delete-sec-modal"
        />
      )}
    </Box>
  );
};

export default SectionsContainer;
