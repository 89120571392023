import Luci from "../api/luci.js";

var LuciController = {};
LuciController.getLuciRequests = (page, status) => {
  const queryData = {
    page,
    status,
  };
  return new Promise(function (resolve) {
    Luci.getRequests(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
          total_count: response?.data?.pagination || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LuciController.getLuciStaticQuestions = (queryData) => {
  return new Promise(function (resolve) {
    Luci.getLuciStaticQuestions(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LuciController.createLuciQuestion = (queryData) => {
  return new Promise(function (resolve) {
    Luci.createLuciQuestion({
      ...queryData,
    })
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          status: error?.response?.status,
        });
      });
  });
};
LuciController.getLuciPolicy = () => {
  return new Promise(function (resolve) {
    Luci.getLuciPolicy()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LuciController.showLuciBusinessDetails = (id) => {
  return new Promise(function (resolve) {
    Luci.showLuciBusinessDetails(id)
      .then((response) => {
        resolve({
          data: response?.data?.data?.attributes,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LuciController.updateLuciBusinessDetails = (id, queryData) => {
  return new Promise(function (resolve) {
    Luci.updateLuciBusinessDetails(id, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LuciController.createLuciBusinessDetails = (queryData) => {
  return new Promise(function (resolve) {
    Luci.createLuciBusinessDetails(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
LuciController.LuciBusinessDetailsInformel = () => {
  return new Promise(function (resolve) {
    Luci.LuciBusinessDetailsInformel()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LuciController.getLuciListAnswer = (id) => {
  return new Promise(function (resolve) {
    Luci.getLuciListAnswer(id)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LuciController.getLuciCurrentTokens = () => {
  return new Promise(function (resolve) {
    Luci.getLuciCurrentTokens()
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default LuciController;
