import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./businessDetails.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import SharedBusinessForm from "./sharedBusinessForm.js";
import LuciController from "services/controllers/luciController.js";
import CircularProgress from "@mui/material/CircularProgress";

function BusinessDetails(props) {
  const intl = useIntl();
  const [pageNum, setPageNum] = useState(1);
  const [selectedBusinessFormData, setSelectedBusinessFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleNextStep = () => {
    let isDone = false;
    let isDoneHandle = (x) => (isDone = x);
    setLoading(false);

    if (pageNum == 1) {
      selectedBusinessFormData["business_hq"] = selectedBusinessFormData[
        "business_hq"
      ]?.length
        ? selectedBusinessFormData["business_hq"]
        : "";

      selectedBusinessFormData["business_type"] = selectedBusinessFormData[
        "business_type"
      ]?.length
        ? selectedBusinessFormData["business_type"]
        : [];

      selectedBusinessFormData["industry"] = selectedBusinessFormData[
        "industry"
      ]?.length
        ? selectedBusinessFormData["industry"]
        : [];

      selectedBusinessFormData["main_customer_facing_channels"] =
        selectedBusinessFormData["main_customer_facing_channels"]?.length
          ? selectedBusinessFormData["main_customer_facing_channels"]
          : [];

      setSelectedBusinessFormData({ ...selectedBusinessFormData });

      selectedBusinessFormData["main_customer_facing_channels"]?.length &&
      selectedBusinessFormData["industry"]?.length &&
      selectedBusinessFormData["business_type"]?.length &&
      selectedBusinessFormData["business_hq"]?.length
        ? setPageNum(2)
        : setPageNum(1);
    } else if (pageNum == 2) {
      selectedBusinessFormData["customers_geographic"] =
        selectedBusinessFormData["customers_geographic"]?.length
          ? selectedBusinessFormData["customers_geographic"]
          : "";

      selectedBusinessFormData["customer_demographics_age"] =
        selectedBusinessFormData["customer_demographics_age"]?.length
          ? selectedBusinessFormData["customer_demographics_age"]
          : [];

      selectedBusinessFormData["customer_demographics_gender"] =
        selectedBusinessFormData["customer_demographics_gender"]?.length
          ? selectedBusinessFormData["customer_demographics_gender"]
          : [];

      selectedBusinessFormData["customer_demographics_income"] =
        selectedBusinessFormData["customer_demographics_income"]?.length
          ? selectedBusinessFormData["customer_demographics_income"]
          : [];

      selectedBusinessFormData["customer_demographics_Behaviors_preferences"] =
        selectedBusinessFormData["customer_demographics_Behaviors_preferences"]
          ?.length
          ? selectedBusinessFormData[
              "customer_demographics_Behaviors_preferences"
            ]
          : [];

      setSelectedBusinessFormData({ ...selectedBusinessFormData });

      selectedBusinessFormData["main_customer_facing_channels"]?.length &&
      selectedBusinessFormData["industry"]?.length &&
      selectedBusinessFormData["business_type"]?.length &&
      selectedBusinessFormData["business_hq"]?.length &&
      selectedBusinessFormData["customers_geographic"]?.length &&
      selectedBusinessFormData["customer_demographics_age"]?.length &&
      selectedBusinessFormData["customer_demographics_gender"]?.length &&
      selectedBusinessFormData["customer_demographics_income"]?.length &&
      selectedBusinessFormData["customer_demographics_Behaviors_preferences"]
        ?.length
        ? isDoneHandle(true)
        : setPageNum(2);
    }
    if (isDone) {
      setLoading(true);
      selectedBusinessFormData["customer_demographics_gender"] = [
        selectedBusinessFormData?.customer_demographics_gender,
      ];
      LuciController.createLuciBusinessDetails({
        business_details: selectedBusinessFormData,
      }).then((data) => {
        if (!data?.errorMsg) {
          props?.setShowConfirmationPopup(true);
        }
        props?.setShowBusinessFormPopup(false);
        setLoading(false);
      });
    }
  };
  const handleBackStep = () => {
    setPageNum(1);
  };

  return (
    <Box className="business-details-parant confirmation-pop-up-container">
      <Button
        id="btn-close-business"
        onClick={() => props?.setShowBusinessFormPopup(false)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>
      <Box className="title-details">
        {pageNum === 1 ? (
          <>
            <Typography component={"h3"}>
              {CheckValueLocale("luci_boost_answers", "", {}, intl)}
            </Typography>
            <Typography>
              {CheckValueLocale("luci_fill_out_below", "", {}, intl)}
            </Typography>
          </>
        ) : null}
        <Box className="form-control">
          <SharedBusinessForm
            formData={props?.formData}
            selectedBusinessFormData={selectedBusinessFormData}
            setSelectedBusinessFormData={setSelectedBusinessFormData}
            pageNum={pageNum}
            isUpdateForm={false}
          />
        </Box>
        {pageNum === 1 ? (
          <Button className="business-details-next" onClick={handleNextStep}>
            {CheckValueLocale("luci_next", "", {}, intl)}
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        ) : null}
        {pageNum === 2 ? (
          <Box className="save-back-step">
            <Button className="business-back" onClick={handleBackStep}>
              <FontAwesomeIcon icon={faArrowLeft} />
              {CheckValueLocale("luci_back", "", {}, intl)}
            </Button>
            <Button
              className={
                loading
                  ? "business-save business-save-loading"
                  : "business-save"
              }
              disabled={loading}
              onClick={handleNextStep}
            >
              {loading ? <CircularProgress /> : null}
              {!loading ? CheckValueLocale("luci_save", "", {}, intl) : null}
              {!loading ? <FontAwesomeIcon icon={faArrowRight} /> : null}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default BusinessDetails;
