// Specific Widgets List
const specificWidgets = [
  "top_keywords",
  "top_hashtags",
  "interactions",
  "reactions",
  "associated_topics",
  "sentiment_analysis",
  "content_type",
  "content_style",
];

const checkSpecialCases = (
  widgetName,
  product,
  pageName,
  dataSource,
  monitorType,
  isEngagementPage,
) => {
  switch (widgetName) {
    case "interactions":
      if (
        dataSource === "instagram" &&
        pageName === "engagements_page" &&
        monitorType === "account_analysis"
      )
        return "posts_interactions";
      break;

    case "volume_overtime":
      if (
        product === "cxm" &&
        ["twitter_private", "facebook_private", "instagram_private"]?.includes(
          pageName,
        )
      )
        return `conversation_interactions`;
      else if (pageName === "linkedin_public") return `interactions`;
      else if (
        (dataSource === "instagram" && monitorType === "managed_account") ||
        (dataSource === "facebook" &&
          ["managed_page", "account_analysis"]?.includes(monitorType))
      )
        return `${isEngagementPage ? "comments" : "posts"}_volume`;
      else return "posts_volume";

    case "top_videos":
      if (dataSource === "instagram") return "top_reels";
      if (
        product === "sm" &&
        dataSource === "twitter" &&
        monitorType === "account_analysis"
      )
        return `${isEngagementPage ? "engagements_analysis" : "account_analysis"}_${widgetName}`;
      if (dataSource === "twitter" && monitorType === "account_analysis")
        return `${isEngagementPage ? "comments" : "posts"}_${widgetName}`;
      break;

    case "posts_top_videos":
      if (dataSource === "instagram") return "top_reels";
      if (dataSource === "twitter" && monitorType === "account_analysis")
        return `${isEngagementPage ? "comments" : "posts"}_${widgetName}`;
      break;

    case "posts_top_images":
      if (pageName === "twitter_public")
        return `${isEngagementPage ? "comments" : "posts"}_${widgetName}`;
      break;

    case "top_images":
      if (
        product === "sm" &&
        dataSource === "twitter" &&
        monitorType === "account_analysis"
      )
        return `${isEngagementPage ? "engagements_analysis" : "account_analysis"}_${widgetName}`;
      else if (dataSource === "twitter" && monitorType === "account_analysis")
        return `${isEngagementPage ? "comments" : "posts"}_${widgetName}`;
      break;

    case "sentiment_analysis":
    case "themes":
    case "associated_topics":
      if (dataSource === "tiktok") return `comments_${widgetName}`;
      else if (dataSource === "linkedin" && widgetName === "sentiment_analysis")
        return `comments_${widgetName}`;
      break;

    case "dialects":
      if (dataSource === "tiktok") return "dialects_subdialects";
      break;

    case "gender":
      if (dataSource === "tiktok")
        return "comments_comments_gender_distribution";
      break;

    case "top_languages":
      if (dataSource === "tiktok") return "comments_comments_top_languages";
      break;

    case "top_engagers":
      if (
        [
          "twitter_private",
          "facebook_private",
          "instagram_private",
          "linkedin_public",
        ]?.includes(pageName)
      )
        return "most_engaged_contacts";
      break;

    case "comments_top_engagers":
      if (pageName === "instagram_public") return "top_authors";
      break;

    case "account_authors_activity":
      if (pageName === "linkedin_public") return "contact_activity";
      break;

    case "comments_account_authors_activity":
      if (pageName === "facebook_public") return "engagers_activity";
      break;

    default:
      break;
  }

  return null;
};

export const useWidgetName = (
  product,
  widgetName,
  monitor_type,
  page_name,
  data_source,
) => {
  const pageName = page_name?.toLowerCase();
  const dataSource = data_source?.toLowerCase();
  const monitorType = monitor_type?.toLowerCase();
  const isEngagementPage = ["engagements_page", "engagementspage"]?.includes(
    pageName,
  );

  // Check for special cases
  const specialCaseWidgetName = checkSpecialCases(
    widgetName,
    product,
    pageName,
    dataSource,
    monitorType,
    isEngagementPage,
  );
  if (specialCaseWidgetName) return specialCaseWidgetName;

  // Main logic for specific widgets
  if (specificWidgets?.includes(widgetName) && product === "sm") {
    if (monitorType === "keyword") return `posts_${widgetName}`;
    return `${isEngagementPage ? "comments" : "posts"}_${widgetName}`;
  }

  return widgetName;
};
