import { Typography, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./sectionTitle.scss";

const SectionTitle = ({ sectionTitle }) => {
  const intl = useIntl();
  return (
    <Typography variant="h6" className="section-title-head">
      {CheckValueLocale(`${sectionTitle}`, "", {}, intl).toUpperCase()}
    </Typography>
  );
};
export default SectionTitle;
