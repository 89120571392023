import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Divider, Box, Typography, Button } from "@mui/material";
import {
  getTimeZone,
  isEmptyObj,
  CheckValueLocale,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useEditSentiment } from "pages/channelAnalytics/InsightsPage/hooks/useEditSentiment";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import InteractionListPrivateMessage from "components/interactions-list/components/InteractionListPrivateMessage";
import dummyImage from "images/shared-images/image-placeholder.svg";
import dummyVideo from "images/shared-images/video-placeholder.svg";
import NoDataFound from "components/no-Data/noDataFound";
import SnackBar from "components/snackBar";
import Luci from "pages/luci";
import ReplyModal from "../../../../../replyModal/replyModal";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import MonitorsController from "services/controllers/monitorsController";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import { getUnixEndDate } from "utils/helpers";

const SingleTweetInteraction = (props) => {
  const urlParams = useParams();
  const interactionId = urlParams?.id;
  const trackerId = urlParams?.tracker_id;
  const dataSourceId = urlParams?.data_source_id;

  const intl = useIntl();
  const [openProfile, setOpenProfile] = useState(false);
  const [conversation, setConversation] = useState({});
  const [conversationMessages, setConersationMessgaes] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);

  const [text, setText] = useState("");
  const [replyModalData, setReplyModalData] = useState([
    {
      selectedValue: "",
      replyTime: "",
    },
  ]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  const [allawedTrackers, setAllawedTrackers] = useState([]);

  let apiVars = "social_media/twitter/private";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");

  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true"
          ? `/x-platform-private-channel/${dataSourceId}`
          : ""
      }`,
    },
  ];

  const getSingleInteractions = () => {
    setPageNumber(pageNumber + 1);
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
      pageNumber,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result.data)) {
        if (result?.data?.msg) {
          setConversation(result?.data?.msg?.single_interaction);
          setConersationMessgaes((oldArray) => [
            ...oldArray,
            ...result?.data?.msg?.single_interaction?.conversation,
          ]);
          setSuccess(true);
          if (
            handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
            retrieveActiveProductsArray()?.includes("ENGAGEMENTS")
          ) {
            MonitorsController.getAllawedMonitors(
              window?.localStorage?.cxm_id,
            ).then((AllawedData) => {
              if (AllawedData?.data?.data?.monitors_list?.length) {
                setAllawedTrackers([...AllawedData?.data?.data?.monitors_list]);
              }
            });
          }
        }
        setLoading(false);
        setSuccess(false);
      }
    });
  };
  useEffect(() => {
    getSingleInteractions();
  }, []);
  const handleLoadMoreMessgaes = () => {
    getSingleInteractions();
  };
  const parseJsonData = (data) => {
    return JSON.parse(data);
  };
  const handleImageError = (event) => {
    event.target.src = dummyImage;
  };

  const handleVideoError = (event) => {
    event.target.src = dummyVideo;
  };

  const handleCloseRepliedSnack = () => {
    setShowErrorSnackBar(false);
  };
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  const unixDate = {
    start: conversation?.created_at,
    end: getUnixEndDate(conversation?.created_at),
  };

  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: themesOptions } = useThemesOptions({
    locale: intl?.locale,
  });

  const { mutate: editSentiment, isPending: isSentimentPending } =
    useEditSentiment();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();
  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();

  const preQueryData = {
    id: interactionId,
    created_at: conversation?.created_at,
    data_source_id: conversation?.channel_id,
    product_id: window.localStorage.cxm_id,
    tracker_id: conversation?.tracker_id,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: conversation?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, themes: updatedCategories })),
        onError: () => setShowErrorSnackBar(true),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: conversation?.sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sub_themes: updatedThemes })),
        onError: () => setShowErrorSnackBar(true),
      },
    );
  };

  const handleEditSentiment = (updatedSentiment) => {
    editSentiment(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedSentiment,
          old_label: conversation?.sentiment,
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({ ...prev, sentiment: updatedSentiment })),
        onError: () => setShowErrorSnackBar(true),
      },
    );
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          unixDate={unixDate}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: conversation?.client_username,
            user_name: conversation?.client_name,
            profile_image: conversation.client_profile_image_url,
          }}
          dataSourceName={"TWITTER"}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {loading ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="twitter" />
            {isEmptyObj(conversation) ? (
              <>
                <SnackBar
                  open={success != null ? true : false}
                  severity={"error"}
                  handleClose={() => {
                    setSuccess(null);
                  }}
                  message={CheckValueLocale(
                    "please_wait_and_try_again_later",
                    "",
                    {},
                    intl,
                  )}
                  title={CheckValueLocale("failed_error", "", {}, intl)}
                />
                <Box
                  component="div"
                  className="interaction-list single-interaction-empty-data"
                >
                  <NoDataFound showApplyFilterMsg={false} />
                </Box>
              </>
            ) : (
              <Box
                className="interaction-list interaction-list-main"
                component="div"
              >
                <div className="header-interaction interaction-head">
                  <div className="interaction-list-header interaction-header-align">
                    <Box
                      onClick={handleSideBar}
                      className={`user-single-header hover-show  ${
                        props.showProfile ? " hover-user" : ""
                      }`}
                    >
                      <span className="interaction-list__icon interaction-list__icon--twitter">
                        <img src={xPlatform} alt="x-platform-logo" />
                      </span>
                      <span className="interaction-list-user-data">
                        <p className="label label-name">
                          {conversation?.client_name}
                        </p>
                        <p className="label label-username">
                          @{conversation?.client_username}
                        </p>
                      </span>
                    </Box>

                    <Divider
                      orientation="vertical"
                      className="vertical-line-list-header"
                    />
                    <Typography className="interaction-list-type">
                      {CheckValueLocale(
                        conversation?.channel_type === "private"
                          ? "type_conversation"
                          : "type_post",
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  </div>

                  <Box>
                    <SentimentCategoriesThemes
                      themesData={{
                        btnId: "xplatform-dm-single-interaction-themes-btn",
                        options: themesOptions,
                        selected: conversation?.sub_themes,
                        handleChange: handleEditTheme,
                        loading: isThemesPending,
                      }}
                      categoriesData={{
                        btnId: "xplatform-dm-single-interaction-categories-btn",
                        options: categoriesOptions,
                        selected: conversation?.themes,
                        handleChange: handleEditCategories,
                        loading: isCategoriesPending,
                      }}
                      sentimentData={{
                        btnId: "xplatform-dm-single-interaction-sentiment-btn",
                        options: sentimentsOptions,
                        selected: conversation?.sentiment,
                        handleChange: handleEditSentiment,
                        loading: isSentimentPending,
                      }}
                      dividers={[1, 2]}
                    />
                  </Box>
                </div>
                <InteractionListPrivateMessage
                  isRemoveBox
                  waitingTime={conversation?.waiting_time}
                  handlingTime={conversation?.handling_time}
                  status={
                    conversation?.status?.toLowerCase() === "active"
                      ? "active_status"
                      : conversation?.status?.toLowerCase() === "pending"
                        ? "pending_status"
                        : conversation?.status
                  }
                />
                <div className="message-container message-container-private chat-main">
                  {conversationMessages?.map((data) => (
                    <Box
                      pt={2}
                      className={
                        data?.direction === "inbound"
                          ? "interaction-list-header chat-main-box clearfix"
                          : "interaction-list__header chat-main-box chat-main-box-right clearfix"
                      }
                      key={data?.message_id}
                    >
                      <span className="interaction-list-user-data">
                        <span className="label">{data.name}</span>
                        <Typography
                          component="p"
                          className="label-user-name"
                          sx={{ paddingTop: "6px" }}
                        >
                          {data?.username?.charAt(0) === "@"
                            ? data?.username
                            : "@" + data?.username}
                        </Typography>
                      </span>
                      <div
                        className={`${
                          data.content.text !== "" ? "chat-message" : ""
                        }`}
                      >
                        {data.content.media && (
                          <div className="chat-message-media">
                            <div className="single-interactions-media">
                              {parseJsonData(data?.content?.media)?.type ===
                                "photo" && (
                                <img
                                  src={
                                    parseJsonData(data?.content?.media)
                                      ?.media_url
                                  }
                                  alt=""
                                  onError={handleImageError}
                                />
                              )}
                              {/* video placeholder */}
                              {parseJsonData(data?.content?.media)?.type ===
                                "video" && (
                                <video controls>
                                  <source
                                    src={
                                      parseJsonData(data?.content?.media)
                                        ?.media_url
                                    }
                                    onError={handleVideoError}
                                  />
                                </video>
                              )}
                            </div>
                          </div>
                        )}
                        {data.content.text && data.content.text !== "" && (
                          <p className="data-content-text">
                            {data.content.text}
                          </p>
                        )}
                      </div>
                      <div className="date-chat">
                        {data.direction === "inbound" ? (
                          <>
                            <span>
                              {moment
                                .unix(data.created_at)
                                .utc()
                                .format("YYYY/MM/DD ")}
                            </span>
                            <span className="time-zone-space">
                              {moment.unix(data.created_at).utc().format("LT")}
                              {getTimeZone() > 0
                                ? `(${CheckValueLocale(
                                    "gmt",
                                    "",
                                    {},
                                    intl,
                                  )} +${getTimeZone()})`
                                : `(${CheckValueLocale(
                                    "gmt",
                                    "",
                                    {},
                                    intl,
                                  )} -${getTimeZone()})`}
                            </span>
                          </>
                        ) : (
                          <>
                            <span>
                              {moment.unix(data.created_at).utc().format("LT")}
                              {getTimeZone() > 0
                                ? `(${CheckValueLocale(
                                    "gmt",
                                    "",
                                    {},
                                    intl,
                                  )} +${getTimeZone()})`
                                : `(${CheckValueLocale(
                                    "gmt",
                                    "",
                                    {},
                                    intl,
                                  )} -${getTimeZone()})`}
                            </span>
                            <span className="time-zone-space">
                              {moment
                                .unix(data.created_at)
                                .utc()
                                .format("YYYY/MM/DD ")}
                            </span>
                          </>
                        )}
                      </div>
                    </Box>
                  ))}
                  {conversation?.conversation?.length >= 10 && (
                    <Button
                      variant="contained"
                      className="view-more load-more-btn"
                      onClick={handleLoadMoreMessgaes}
                      id="channel-analytics-sm-tw-private-channel-interactions-load-more-btn"
                    >
                      {CheckValueLocale("load_more", "", {}, intl)}
                    </Button>
                  )}
                </div>
                {handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
                retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
                allawedTrackers?.includes(+trackerId) ? (
                  <ReplyModal
                    replyData={conversation}
                    setText={setText}
                    text={text}
                    replyModalData={replyModalData}
                    setReplyModalData={setReplyModalData}
                    setShowSuccessModal={setShowSuccess}
                    showSuccessModal={showSuccess}
                    setShowErrorSnackBar={setShowErrorSnackBar}
                    setConversationMessages={setConersationMessgaes}
                    conversationMessages={conversationMessages}
                    isPrivateInteraction
                  />
                ) : null}
              </Box>
            )}
            {showErrorSnackBar ? (
              <SnackBar
                open={showErrorSnackBar}
                autoHideDuration={5000}
                severity="error"
                message={CheckValueLocale("please_try_again", "", {}, intl)}
                title={CheckValueLocale(
                  "wrong_request_parameter",
                  "",
                  {},
                  intl,
                )}
                handleClose={handleCloseRepliedSnack}
              />
            ) : null}
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleTweetInteraction;
