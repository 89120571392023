import { useState } from "react";
import { Grid, Typography, Divider, Chip, Box } from "@mui/material";
//Locals
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import { handlelUserRoles } from "utils/helpers";
import DeleteSurveyModal from "../components/deleteSurveyModal";
import PublishSurveyModal from "../../SurveyBuilder/Header/publishSurveyModal";
import OptionsDropDown from "./optionsDropDown";
import { CheckValueLocale } from "utils/helpers";

const CardData = (props) => {
  const intl = useIntl();
  let history = useHistory();

  const { survey } = props;
  const hideOptionsDropDown =
    survey?.status === "draft"
      ? handlelUserRoles("SURVEY", "EDIT_SURVEY")
        ? true
        : false
      : true;
  const [openDeleteSurveyModal, setOpenDeleteSurveyModal] = useState(false);
  const [openPublishSurveyModal, setOpenPublishSurveyModal] = useState(false);
  const [openShareSurveyModal, setOpenShareSurveyModal] = useState(false);
  const icons = (
    <Grid container justifyContent="flex-end">
      {hideOptionsDropDown && (
        <OptionsDropDown
          setOpenPublishSurveyModal={setOpenPublishSurveyModal}
          setOpenShareSurveyModal={setOpenShareSurveyModal}
          openPublishSurveyModal={openPublishSurveyModal}
          setOpenDeleteSurveyModal={setOpenDeleteSurveyModal}
          survey={survey}
          surveyLimitReach={props.surveyLimitReach}
        />
      )}
    </Grid>
  );

  const localizedDate =
    intl?.locale == "en"
      ? moment.unix(survey?.published_at).utc().format("D MMM, YYYY")
      : `${moment.unix(survey?.published_at).utc().format("D")} ${CheckValueLocale(
          moment.unix(survey?.published_at).utc().format("MMM"),
          "",
          {},
          intl,
        )} ${moment.unix(survey?.published_at).utc().format("YYYY")} `;

  const publishedAt =
    CheckValueLocale(
      survey?.status == "active" ? "publish_date_survey" : "unpublish_date",
      "",
      {},
      intl,
    ) +
    " :   " +
    localizedDate;
  return (
    <>
      <Grid container className="header-survey-card">
        <Grid item>
          <Typography className="header-name">{survey?.name}</Typography>
        </Grid>
        <Grid item>
          <Chip
            className={`header-status header-status-` + survey?.status}
            label={CheckValueLocale(
              survey?.status == "deleted"
                ? "archive_status"
                : survey?.status + "_status_survey",
              "",
              {},
              intl,
            )}
            variant="outlined"
          />
        </Grid>
        <Grid item xs>
          {icons}
        </Grid>
      </Grid>
      {survey?.published_at ? (
        <Typography component={"span"} className="publish-date">
          {publishedAt}
        </Typography>
      ) : (
        <Box className="empty-publish-date"> </Box>
      )}
      <Divider />
      {openDeleteSurveyModal && (
        <DeleteSurveyModal
          surveyName={survey?.name}
          surveyId={survey?.id}
          openDeleteSurveyModal={openDeleteSurveyModal}
          setOpenDeleteSurveyModal={setOpenDeleteSurveyModal}
          getSurveys={props?.getSurveys}
        />
      )}
      {openPublishSurveyModal && (
        <PublishSurveyModal
          openPublishSurveyModal={openPublishSurveyModal}
          setOpenPublishSurveyModal={setOpenPublishSurveyModal}
          surveyData={survey}
          openShareSurveyModal={openShareSurveyModal}
        />
      )}
    </>
  );
};
export default CardData;
