import { useIntl } from "react-intl";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Table,
  TableCell,
  Box,
  Tooltip,
  Zoom,
  Typography,
  CircularProgress,
} from "@mui/material";
import "./orderedTable.scss";
import { CheckValueLocale, classNames } from "utils/helpers";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect, useRef } from "react";

const OrderedTable = ({
  tableHeader,
  tableBody,
  emptyMsgId,
  emptysubMsgId,
  loading,
  className,
  emptyBody,
  mainBox,
  tableClassName,
  headerRowClassName,
  headClassName,
  bodyClassName,
  tableAriaLabel,
  containerStyle,
  tableMinWidth,
  isSharedStyle,
  isHashTable,
  handleDragEnd,
}) => {
  const intl = useIntl();
  const cellsWidthRef = useRef([]);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(97, 97, 97, 0.9)",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "4px 8px",
      fontWeight: "500",
    },
  }));
  const showTooltipText = (text, textLength) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Box component="span">{text?.substring(0, textLength)}...</Box>
          </BootstrapTooltip>
        ) : (
          <Typography component="p">{text}</Typography>
        )}
      </Box>
    );
  };
  useEffect(() => {
    // here we will store the sizes for the cell
    const id = "table-ordered-row-0";
    // If you want to get the width of the cell, you can use ref
    const rowCells = document.getElementById(id)?.children || [];
    let cellsWidth = [];
    // // Loop through each cell and store its height and width
    Array.from(rowCells)?.forEach((cell) => {
      const rect = cell?.getBoundingClientRect?.();
      cellsWidth.push(rect);
    });
    cellsWidthRef.current = cellsWidth;
  }, [tableBody]); // Empty dependency array ensures the effect runs only once after mounting
  return (
    <TableContainer
      component={Paper}
      id={isSharedStyle && "ordered-table"}
      className={`${loading ? "ordered-table-loading" : ""} ${
        className ? className : ""
      }`}
      style={containerStyle}
      sx={isHashTable ? { margin: "0px !important" } : null}
    >
      {mainBox ? mainBox : null}
      <Table
        sx={{ minWidth: tableMinWidth ? tableMinWidth : 700 }}
        aria-label={tableAriaLabel ? tableAriaLabel : "table"}
        className={tableClassName ? tableClassName : ""}
      >
        <TableHead className={headClassName ? headClassName : ""}>
          <TableRow className={headerRowClassName ? headerRowClassName : ""}>
            {tableHeader?.map((item, x) => {
              return (
                (item?.permission == false ? false : true) && (
                  <TableCell
                    rowSpan={item?.rowSpan ? item?.rowSpan : undefined}
                    onClick={item?.onClick ? item?.onClick : null}
                    key={x}
                    sortDirection={
                      item?.sortDirection ? item?.sortDirection : ""
                    }
                    component={item?.component ? item?.component : ""}
                    scope={item?.scope ? item?.scope : ""}
                    className={item?.className || ""}
                    sx={item?.sx || { width: item?.width || "auto" }}
                    style={item?.style}
                  >
                    {item?.body ? item?.body : ""}
                  </TableCell>
                )
              );
            })}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => {
              return (
                <TableBody
                  className={bodyClassName ? bodyClassName : ""}
                  id="ordered-table-body"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tableBody?.length > 0 &&
                    tableBody?.map((row, x) => {
                      return (
                        <Draggable
                          key={row?.id || x}
                          draggableId={"ordered-row" + row?.id || x}
                          index={x}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              id={"table-ordered-row-" + x}
                              className={classNames(
                                row?.className,
                                snapshot?.isDragging && "dragging-row",
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {row?.data?.length > 0 &&
                                row?.data?.map((cell, idx) => {
                                  const { draggable } = cell;

                                  return (
                                    (cell?.permission == false
                                      ? false
                                      : true) && (
                                      <TableCell
                                        rowSpan={
                                          cell?.rowSpan
                                            ? cell?.rowSpan
                                            : undefined
                                        }
                                        onClick={
                                          cell?.onClick ? cell?.onClick : null
                                        }
                                        sortDirection={
                                          cell?.sortDirection
                                            ? cell?.sortDirection
                                            : ""
                                        }
                                        component={
                                          cell?.component ? cell?.component : ""
                                        }
                                        scope={cell?.scope ? cell?.scope : ""}
                                        key={idx}
                                        className={classNames(
                                          cell?.className,
                                          draggable && "draggable-cell",
                                          snapshot?.isDragging &&
                                            "dragging-cell",
                                        )}
                                        sx={cell?.sx || ""}
                                        style={{
                                          width: snapshot?.isDragging
                                            ? cellsWidthRef?.current?.[idx]
                                                ?.width || "auto"
                                            : "auto",
                                          height: snapshot?.isDragging
                                            ? cellsWidthRef?.current?.[idx]
                                                ?.height || "auto"
                                            : "auto",
                                        }}
                                        {...(draggable
                                          ? provided.dragHandleProps
                                          : {})}
                                      >
                                        {cell?.isCreatedBy
                                          ? showTooltipText(cell?.body, 30)
                                          : cell?.body
                                            ? cell?.body
                                            : ""}
                                      </TableCell>
                                    )
                                  );
                                })}
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Table>
      {loading ? (
        <div className="ordered-table-loader-circular">
          <CircularProgress id="circular-progress-component" />
        </div>
      ) : null}
      {tableBody?.length == 0 && !loading ? (
        emptyBody || emptyMsgId || emptysubMsgId ? (
          <Box className="empty-item">
            {emptyBody ? (
              emptyBody
            ) : emptyMsgId || emptysubMsgId ? (
              <Box>
                <h2>{CheckValueLocale(emptyMsgId, "", {}, intl)}</h2>
                <p>{CheckValueLocale(emptysubMsgId, "", {}, intl)}</p>
              </Box>
            ) : null}
          </Box>
        ) : null
      ) : null}
    </TableContainer>
  );
};
export default OrderedTable;
