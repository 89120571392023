import { useIntl } from "react-intl";
import Snackbar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";

const AddChannelSocialListingSnackbar = ({
  socialListeningSuccess,
  setSocialListeningSuccess,
}) => {
  const intl = useIntl();

  const handleClose = () => {
    setSocialListeningSuccess(null);
  };
  return (
    <Snackbar
      open={!!socialListeningSuccess}
      handleClose={handleClose}
      severity={socialListeningSuccess ? "success" : "error"}
      message={
        socialListeningSuccess ? (
          <div>
            <span className="manage-channel-addons-success">
              {CheckValueLocale("addons_success", "", {}, intl)}
            </span>
            <span className="manage-channel-out-team--will-be-in-touch">
              <br />
              {CheckValueLocale("our_team_will_touch", "", {}, intl)}
            </span>
          </div>
        ) : (
          <div>
            <span className="manage-channel-request-failed">
              {CheckValueLocale("request_failed", "", {}, intl)}
            </span>
            <span className="manage-channel-try-again">
              <br />
              {CheckValueLocale("try_again", "", {}, intl)}
            </span>
          </div>
        )
      }
    />
  );
};

export default AddChannelSocialListingSnackbar;
