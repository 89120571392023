import React, { useEffect, useState } from "react";
import { Box, Switch } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../surveyBuilder.scss";

const SocialMedia = (props) => {
  const {
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
  } = props;

  const intl = useIntl();
  const [switchValues, setSwitchValues] = useState(false);

  useEffect(() => {
    if (question) {
      setSwitchValues(question?.constraints?.required);
    }
  }, [question]);

  const handleSwitch = (e) => {
    setSwitchValues(e.target.checked);
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints.required = e.target.checked;
    setSurveySections([...surveySections]);
  };

  return (
    <>
      <Box className="constraints-text">
        <Box>{CheckValueLocale("required", "", {}, intl)}</Box>
        <Switch
          data-testid="required_switch"
          onChange={(e) => handleSwitch(e)}
          checked={switchValues}
        />
      </Box>
    </>
  );
};

export default SocialMedia;
