import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import { Box, Typography, FormHelperText } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const WordFileUpload = (props) => {
  const intl = useIntl();
  const { selectedData, setSelectedData, displayUploadErrorMessage } = props;
  let uploadErrorMessage = displayUploadErrorMessage();
  const maxSize = 52428800;

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setSelectedData({
      ...selectedData,
      stepTwo: {
        ...selectedData?.stepTwo,
        files: [],
        isFileUploaded: false,
        knowledge_base_url: "",
      },
    });
    const mapAcc = acceptedFiles?.map((file) => ({
      file,
      errors: [],
    }));
    setSelectedData({
      ...selectedData,
      stepTwo: {
        ...selectedData?.stepTwo,
        files: rejectedFiles[0]?.errors?.length
          ? [...rejectedFiles]
          : [...mapAcc],
        isFileUploaded: rejectedFiles[0]?.errors?.length ? false : true,
      },
    });
  };

  return (
    <Box className="engagment-upload-file-container">
      <Box
        className={`upload-file-card ${
          uploadErrorMessage && "upload-error-message"
        }`}
      >
        <Dropzone onDrop={onDrop} accept=".docx,.doc" maxSize={maxSize}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Box className="upload-file-data">
                <CloudUploadIcon className="import-upload-icon" />
                <Box className="drop-file-content">
                  <Typography component="p" className="upload-file-header">
                    {CheckValueLocale("drag_file", "", {}, intl)}
                  </Typography>
                  <Typography component="span" className="import-browse-file">
                    {CheckValueLocale("audience_import_browse", "", {}, intl)}
                  </Typography>
                </Box>
              </Box>
            </div>
          )}
        </Dropzone>
      </Box>
    </Box>
  );
};

export default WordFileUpload;
