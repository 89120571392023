import { isEmptyArray, removeTrailingZeros } from "utils/helpers";
import { handleXlsData } from "pages/Monitors/Components/exportButton/exportExcel/ExportExcelSheet/excelCommonFn";

const positive_vs_negative = (response, lang, convertSeconds) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var allSheetData = [],
      positiveNegative = [];

    response?.map((i) => {
      Object?.entries(i)?.forEach(([key, value]) => {
        switch (key) {
          case "positiveNegative":
            let headArr = [lang["negative"], lang["positive"]];
            let tempObj = {};

            value?.map((i) => {
              if (i?.name === "pdf_metrics") {
                return;
              } else if (
                i?.name === "pdf_total_posts" ||
                i?.name === "pdf_avg_posts" ||
                i?.name === "pdf_total_engagement" ||
                i?.name === "pdf_average_engagement_per_tweet"
              ) {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[Number(!index)]) ||
                    i?.value[Number(!index)] === null
                      ? `—`
                      : removeTrailingZeros(
                          i?.value[Number(!index)]?.toFixed(2),
                        );
                });
              } else if (i?.name === "pdf_total_engagement_per_follower") {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[index]) || i?.value[index] === null
                      ? `—`
                      : i?.value[index] % 1 === 0
                        ? i?.value[index]
                        : i?.value[index]?.toFixed(3);
                });
              } else if (i?.name === "pdf_response_rate") {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[index]) || i?.value[index] === null
                      ? `—`
                      : `${removeTrailingZeros(
                          (i?.value[index] * 100)?.toFixed(2),
                        )}%`;
                });
              } else if (i?.name === "pdf_resp_time") {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    i?.value[index] === 0
                      ? 0
                      : isNaN(i?.value[index]) || i?.value[index] === null
                        ? `—`
                        : `${convertSeconds(parseFloat(i?.value[index]))}`;
                });
              } else {
                headArr?.map((item, index) => {
                  tempObj[`${item}`] =
                    isNaN(i?.value[index]) || i?.value[index] === null
                      ? `—`
                      : removeTrailingZeros(i?.value[index]?.toFixed(2));
                });
              }

              if (i?.name !== "positive_negative")
                positiveNegative?.push({
                  [lang.metrics]: lang[i?.name],
                  ...tempObj,
                });
            });
            allSheetData?.push([positiveNegative, "Sheet1"]);
            break;

          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { positive_vs_negative };
