import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SaveAboutChanges = (props) => {
  const intl = useIntl();

  const handleCancle = () => {
    props.setUpdatedSavedValues({ data: [] });
    props.getUserProfile();
  };

  return (
    <AppBar className="save-footer">
      <Toolbar className="save-footer-toolbar">
        <Button
          className="save-btn"
          onClick={() => props.setShowSaveModal(true)}
          id="audience-save-about-changes-save-btn"
        >
          {CheckValueLocale("save", "", {}, intl)}
        </Button>
        <Button
          className="cancel-btn"
          onClick={handleCancle}
          id="audience-save-about-changes-cancel-btn"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </Button>
        <Typography>
          {CheckValueLocale(
            "you_have_changed",
            "",
            { number: props.updatedSavedValues.data.length },
            intl,
          )}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default SaveAboutChanges;
