import { Box, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import DefaultUser from "images/shared-images/user-management-default.svg";

const DetailsCard = ({ children, title }) => {
  const intl = useIntl();
  return (
    <Grid item xs={6} sm={4} lg={3} className="view-team-details-item">
      <Box className="view-team-card">
        <Typography variant="h5" className="view-team-card-title">
          {CheckValueLocale(title, "", {}, intl)}
        </Typography>
        {children}
      </Box>
    </Grid>
  );
};

const CardContent = ({ content, isExceeding = false }) => {
  if (isExceeding)
    return (
      <Tooltip
        title={content}
        placement="top"
        arrow
        classes={{ tooltip: "teams-members-preview-tooltip" }}
      >
        <Typography
          className="view-team-card-content content-turncate"
          variant="body1"
        >
          {content}
        </Typography>
      </Tooltip>
    );
  return (
    <Typography className="view-team-card-content" variant="body1">
      {content}
    </Typography>
  );
};
export const TeamDetailsCards = ({ teamDetails }) => {
  const intl = useIntl();
  const { created_by, created_at, product } = teamDetails;
  const team_name = teamDetails?.team_name;

  return (
    <Grid
      className="view-team-details-cards"
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <DetailsCard title={CheckValueLocale("team_creator", "", {}, intl)}>
        <Box className="view-team-card-desc">
          <Box className="view-team-card-img">
            <img
              src={created_by?.avatar || DefaultUser}
              alt={created_by?.name}
              onError={(e) => {
                e.target.src = DefaultUser;
              }}
            />
          </Box>
          <Box className="view-team-card-info">
            <Typography variant="h6" className="view-team-card-info-name">
              {created_by?.name}
            </Typography>
            <Typography variant="body2" className="view-team-card-info-email">
              {created_by?.email}
            </Typography>
          </Box>
        </Box>
      </DetailsCard>
      <DetailsCard title="created_at">
        <CardContent content={moment(created_at).format("Do MMM, YYYY")} />
      </DetailsCard>
      <DetailsCard title="new_name">
        <CardContent content={team_name} isExceeding={true} />
      </DetailsCard>
      <DetailsCard title="alert_product">
        <CardContent content={CheckValueLocale(product, "", {}, intl)} />
      </DetailsCard>
    </Grid>
  );
};
