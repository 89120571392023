import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Input } from "@mui/material";
import { Box } from "@mui/system";
import "../surveyQuestion.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";

const SocialMediaQuestion = (props) => {
  const intl = useIntl();
  const { question, questionNo, questionAnswer } = props;

  return (
    <Card className="cardStyle noError audience">
      <CardContent>
        <Typography className="questionStyle audience">
          <span>{`${questionNo}.`}</span>
          {question?.question}
        </Typography>
        {!isEmptyArray(questionAnswer) && typeof questionAnswer == "object" ? (
          questionAnswer?.map((item) => {
            const pfImg =
              Object.keys(item)[0] === "twitter"
                ? "x-platform"
                : Object.keys(item)[0];
            return (
              <Box className="socail-media-survey audience">
                <img
                  src={`/survey_builder_icons/${pfImg}.png`}
                  alt="logo"
                  className="imgStyle socialMediaIcons"
                />
                <Box className="social-input audience">
                  <Input
                    readOnly
                    id="standard-basic"
                    placeholder={CheckValueLocale(
                      "you_didnt_answer",
                      "",
                      {},
                      intl,
                    )}
                    variant="standard"
                    className="textFieldStyle audience"
                    value={item[Object.keys(item)[0]]}
                  />
                </Box>
              </Box>
            );
          })
        ) : (
          <Box>
            <Box className="socail-media-survey audience">
              <img
                src={`/survey_builder_icons/x-platform.png`}
                alt="logo"
                className="imgStyle socialMediaIcons"
              />
              <Box className="social-input audience">
                <Input
                  readOnly
                  id="standard-basic"
                  placeholder={CheckValueLocale(
                    "you_didnt_answer",
                    "",
                    {},
                    intl,
                  )}
                  variant="standard"
                  className="textFieldStyle audience"
                />
              </Box>
            </Box>
            <Box className="socail-media-survey audience">
              <img
                src={`/survey_builder_icons/facebook.png`}
                alt="logo"
                className="imgStyle socialMediaIcons"
              />
              <Box className="social-input audience">
                <Input
                  readOnly
                  id="standard-basic"
                  placeholder={CheckValueLocale(
                    "you_didnt_answer",
                    "",
                    {},
                    intl,
                  )}
                  variant="standard"
                  className="textFieldStyle audience"
                />
              </Box>
            </Box>
            <Box className="socail-media-survey audience">
              <img
                src={`/survey_builder_icons/snapchat.png`}
                alt="logo"
                className="imgStyle socialMediaIcons"
              />
              <Box className="social-input audience">
                <Input
                  readOnly
                  id="standard-basic"
                  placeholder={CheckValueLocale(
                    "you_didnt_answer",
                    "",
                    {},
                    intl,
                  )}
                  variant="standard"
                  className="textFieldStyle audience"
                />
              </Box>
            </Box>
            <Box className="socail-media-survey audience">
              <img
                src={`/survey_builder_icons/instagram.png`}
                alt="logo"
                className="imgStyle socialMediaIcons"
              />
              <Box className="social-input audience">
                <Input
                  readOnly
                  id="standard-basic"
                  placeholder={CheckValueLocale(
                    "you_didnt_answer",
                    "",
                    {},
                    intl,
                  )}
                  variant="standard"
                  className="textFieldStyle audience"
                />
              </Box>
            </Box>
            <Box className="socail-media-survey audience">
              <img
                src={`/survey_builder_icons/tiktok.png`}
                alt="logo"
                className="imgStyle socialMediaIcons"
              />
              <Box className="social-input audience">
                <Input
                  readOnly
                  id="standard-basic"
                  placeholder={CheckValueLocale(
                    "you_didnt_answer",
                    "",
                    {},
                    intl,
                  )}
                  variant="standard"
                  className="textFieldStyle audience"
                />
              </Box>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default SocialMediaQuestion;
