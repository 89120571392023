import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import "../../surveyBuilder.scss";
import { Box, Card, CardContent, Input } from "@mui/material";

const SocialMediaQuestion = (props) => {
  const {
    filteredSections,
    setSurveySections,
    surveySections,
    question,
    questionIndex,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    handleCardClick,
  } = props;

  const intl = useIntl();
  const [platforms, setPlatforms] = useState([
    "facebook",
    "instagram",
    "twitter",
    "tiktok",
    "snapchat",
  ]);
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState({});
  const [platformsAdded, setPlatformsAdded] = useState([]);

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setValue({ title: question?.text, description: question?.description });
    }
  }, [question]);

  useEffect(() => {
    if (
      surveySections &&
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex]?.question?.constraints?.platforms
    )
      setPlatformsAdded(
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex]?.question?.constraints?.platforms,
      );
  }, [surveySections]);

  const handlePlatformsAdded = (pf) => {
    if (editable !== false) {
      setPlatformsAdded([...platformsAdded, pf]);
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex].question.constraints.platforms = [...platformsAdded, pf];
      setSurveySections([...surveySections]);
    }
  };

  const handleRemovePlatform = (pf) => {
    const index = platformsAdded?.indexOf(pf);
    platformsAdded?.splice(index, 1);
    if (editable !== false) {
      setPlatformsAdded([...platformsAdded]);
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex].question.constraints.platforms = platformsAdded;
      setSurveySections([...surveySections]);
    }
  };

  return (
    <Card
      className={`cardStyle card-direction-container${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        "noError"
      }`}
      onClick={() => handleCardClick(sectionIndex, questionIndex)}
    >
      <CardContent className="card-body title-question">
        <Box className="question-title-container">
          <Box component="span" className="questionNoStyle">
            {questionIndex + 1}.
          </Box>
          <Input
            fullWidth
            multiline
            className="questionStylee"
            style={{
              direction: checkKeyboardLanguage(value?.title) ? "rtl" : "ltr",
            }}
            id={questionIndex}
            value={value?.title}
            onChange={(e) => {
              handleQuestionTitle(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            name="title"
            placeholder={CheckValueLocale(
              "social_media_question",
              "",
              {},
              intl,
            )}
            disableUnderline={true}
          />
        </Box>
        <Input
          variant="outlined"
          className="subtitle"
          id={questionIndex}
          value={value?.description}
          onChange={(e) => {
            handleQuestionDesc(e, sectionIndex, questionIndex, value, setValue);
          }}
          style={{
            direction: checkKeyboardLanguage(value?.description)
              ? "rtl"
              : "ltr",
          }}
          multiline
          inputProps={{ maxLength: 145 }}
          name="subtitle"
          placeholder={CheckValueLocale("question_description", "", {}, intl)}
          disableUnderline={true}
        />
        {platforms?.map((pf, index) => {
          const pfImg = pf === "twitter" ? "x-platform" : pf;
          return (
            <Box
              className="flexStyle"
              style={{ alignItems: "baseline" }}
              key={index}
            >
              <Box
                style={{ width: "64%", position: "relative" }}
                className={`${
                  !platformsAdded?.includes(pf)
                    ? "dashedTBorder"
                    : "dashedBorder"
                } ${"flexStylee"}`}
              >
                <img
                  src={`/survey_builder_icons/${pfImg}.png`}
                  alt="logo"
                  className="imgStyle socialMediaIcons"
                />
                <Input
                  id="standard-basic"
                  placeholder={
                    pf === "twitter"
                      ? "@user_name"
                      : CheckValueLocale(
                          "write_your_username_or_profile_link",
                          "",
                          {},
                          intl,
                        )
                  }
                  variant="standard"
                  className="textFieldStyle widthClass disabledFieldStyle"
                  style={{ width: "77%" }}
                  readOnly
                />
                {platformsAdded?.includes(pf) && (
                  <CancelIcon
                    className={"crossStyle"}
                    onClick={() => handleRemovePlatform(pf)}
                  />
                )}
              </Box>
              {!platformsAdded?.includes(pf) ? (
                <Box
                  style={{
                    color: "#456EB2 ",
                    cursor: "pointer",
                    width: "80px",
                    justifyContent: "center",
                  }}
                  className={
                    editable === false
                      ? "flexStylee disabled-color"
                      : "flexStylee"
                  }
                  onClick={() => handlePlatformsAdded(pf)}
                >
                  <AddIcon style={{ marginRight: "5px" }} />
                  <Box className={"addBtn"}>
                    {CheckValueLocale("add", "", {}, intl)}
                  </Box>
                </Box>
              ) : (
                <Box style={{ color: "#4CAF50" }} className={"flexStylee "}>
                  <img
                    src="/survey_builder_icons/correct.png"
                    alt="correct"
                    className="added-icon"
                  />
                  <Box className={"addBtn"}>
                    {CheckValueLocale("added", "", {}, intl)}
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default SocialMediaQuestion;
