// Make static data until BE ready later
export const getStaticAlertsData = {
  instagram_keyword: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  instagram_managed_account: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "TopEngagersWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  facebook_managed_account: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  facebook_managed_page: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  facebook_keyword: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  facebook_account_analysis: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  twitter_account_analysis: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "TopEngagersWidget",
    "TopInfluencersWidget",
    "TopVerifiedEngagersWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "AccountTypesWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  twitter_keyword: [
    "HeaderAlertWidget",
    "VolumeOvertimeWidget",
    "TopPostsWidget",
    "TopEngagersWidget",
    "TopInfluencersWidget",
    "TopVerifiedEngagersWidget",
    "SentimentAnalysisWidget",
    "DialectsSubDialectsWidget",
    "AccountTypesWidget",
    "TopKeywordsWidget",
    "TopHashTagsWidget",
  ],
  newsblogs_keyword: [
    "HeaderAlertWidget",
    "TopPostsWidget",
    "TopEngagedSitesWidget",
    "TopSitesWidget",
    "TopLanguagesWidget",
    "SentimentAnalysisWidget",
  ],
};
