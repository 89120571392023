import { useState } from "react";
import LuciController from "services/controllers/luciController";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { isEmptyObj } from "utils/helpers";

const LuciFunctions = () => {
  const [activeQuestion, setActiveQuestion] = useState({
    questionId: 0,
    questionName: "",
    token: 0,
  });
  const [freeQuestionId, setFreeQuestionId] = useState({});
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [luciStaticQuestions, setLuciStaticQuestions] = useState([]);
  const [isUserThinking, setIsUserThinking] = useState(true);
  const [luciMenuId, setMenuId] = useState("");
  const [currentToken, setCurrentTokens] = useState("");
  const { changeFilterParamsToString } = CommonFunctions();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [isCreateQuestionLoading, setIsCreateQuestionLoading] = useState(false);
  const [showLuciAnswers, setShowLuciAnswers] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [hasCompanyOption, setHasCompanyOption] = useState(true);
  const [showBusinessFormPopup, setShowBusinessFormPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const getBusinessDetails = () => {
    setFormData({});
    LuciController.LuciBusinessDetailsInformel().then((data) => {
      setFormData(data?.data?.informer);
    });
  };
  const [questionIdAnswer, setQuestionIdAnswer] = useState("");
  const [showRequests, setShowRequests] = useState(false);
  const [currentTokensInactive, setGetCurrentInactive] = useState([]);

  const questionNameLocale = (question) => {
    return localStorage?.lang === "en" ? question?.en_text : question?.ar_text;
  };
  const handleCreateFreeQuestions = (question, value) => {
    if (question?.has_company_option === true && hasCompanyOption == false) {
      setShowBusinessFormPopup(true);
    } else {
      setShowConfirmationPopup(true);
    }
    setActiveQuestion({
      questionId: question?.id,
      questionName: value,
      token: question?.token,
      surveyQuestionId: question?.question_id,
      surveyQuestionques: question?.question,
    });
  };

  const handleRequestClick = (id) => {
    setShowLuciAnswers(true);
    setShowRequests(false);
    setQuestionIdAnswer(id);
  };
  const getLuciStaticQuestions = (pageParams) => {
    const queryData = {
      section_name: pageParams?.sectionName,
      product_id: pageParams?.productId,
      luci_feature: pageParams?.luciFeature,
      data_source_id: pageParams?.dataSourceId,
    };
    setActiveQuestion({});
    setQuestionsLoading(true);
    setLuciStaticQuestions([]);
    setIsUserThinking(true);
    LuciController.getLuciStaticQuestions(queryData).then((result) => {
      if (result?.data) {
        setLuciStaticQuestions(result?.data?.questions);
        setIsUserThinking(result?.data?.user_is_thinking);
        setFreeQuestionId(result?.data?.free_question_id);
        setMenuId(result?.data?.menu_id);
        setCurrentTokens(result?.data?.current_token);
        setHasCompanyOption(result?.data?.has_company_option);
      }
      setQuestionsLoading(false);
    });
  };

  const createLuciQuestion = (pageParams, isCAPage) => {
    setIsCreateQuestionLoading(true);
    const filtersValues = isCAPage
      ? pageParams?.luciFiltersCA
      : pageParams?.filterParams;
    const queryData = {
      text: activeQuestion?.questionName,
      luci_static_question_id: activeQuestion?.questionId,
      start_date: pageParams?.startDate,
      end_date: pageParams?.endDate,
      monitor_id: pageParams?.monitorId, // only in SM Pages
      luci_menu_id: luciMenuId,
      data_source_id: pageParams?.dataSourceId,
      profile_id: pageParams?.profileId,
      survey_id: pageParams?.surveyId,

      filters: activeQuestion?.surveyQuestionId
        ? {
            question_id: activeQuestion?.surveyQuestionId,
            question: activeQuestion?.surveyQuestionques,
          }
        : pageParams?.filterParams &&
          changeFilterParamsToString(filtersValues, isCAPage),

      ...(pageParams?.luciFeature === "Benchmark"
        ? pageParams?.benchmarkData
        : {}),
    };

    if (queryData?.filters?.manual_topics) {
      if (queryData?.filters?.manual_topics?.length) {
        queryData.filters.manual_topics =
          pageParams?.filterParams?.manual_topics;
      } else {
        delete queryData?.filters.manual_topics;
        if (isEmptyObj(queryData?.filters)) {
          delete queryData?.filters;
        }
      }
    }
    LuciController.createLuciQuestion(queryData).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        setQuestionIdAnswer(res?.data?.data?.question_id);

        setShowLuciAnswers(true);
      } else {
        setShowConfirmationPopup(false);
      }

      setIsCreateQuestionLoading(false);
      setShowSnackBar(true);
    });
  };

  const getLuciCurrentTokens = () => {
    LuciController.getLuciCurrentTokens().then((result) => {
      if (result?.data) {
        setGetCurrentInactive(result?.data);
      }
    });
  };

  // this Function used for check all data between preloader and tracker datasource is loaded
  const checkLuciAllDataFounded = (
    totalPreLoaderDataSources,
    preLoaderTracker,
  ) => {
    if (preLoaderTracker?.length === 0) return false;
    else {
      let result =
        totalPreLoaderDataSources?.length === preLoaderTracker?.length;
      return result;
    }
  };

  // this Function to check if any data founded or not with [ LUCI ]
  const checkLuciDataIsFounded = (totalData) => {
    let checkEmptyValue = totalData?.some(
      (item) => item !== undefined && item?.length > 0,
    );
    return !checkEmptyValue;
  };

  // Luci settings tab stats and functions
  const [settingsOpen, setSettingsOpen] = useState(false);
  const handleOpenSettings = () => setSettingsOpen(true);
  const handleCloseSettings = () => setSettingsOpen(false);

  return {
    getLuciStaticQuestions,
    luciStaticQuestions,
    questionsLoading,
    isUserThinking,
    createLuciQuestion,
    showSnackBar,
    activeQuestion,
    setActiveQuestion,
    freeQuestionId,
    currentToken,
    setCurrentTokens,
    isCreateQuestionLoading,
    showConfirmationPopup,
    setShowConfirmationPopup,
    showLuciAnswers,
    setShowLuciAnswers,
    questionNameLocale,
    handleCreateFreeQuestions,
    showBusinessFormPopup,
    setShowBusinessFormPopup,
    getBusinessDetails,
    formData,
    hasCompanyOption,
    questionIdAnswer,
    setQuestionIdAnswer,
    handleRequestClick,
    showRequests,
    setShowRequests,
    getLuciCurrentTokens,
    currentTokensInactive,
    checkLuciDataIsFounded,
    checkLuciAllDataFounded,
    settingsOpen,
    handleOpenSettings,
    handleCloseSettings,
  };
};

export default LuciFunctions;
