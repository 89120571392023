import CompaniesController from "services/controllers/companiesController";
import InteractionsController from "services/controllers/interactionsController";
import MonitorsController from "services/controllers/monitorsController";
import { isEmptyArray } from "utils/helpers";

const getDataSources = (setDataSources) => {
  InteractionsController.getDatasources().then((data) => {
    setDataSources(data.data.data);
  });
};

const getDataSourceCategories = (channelName, setIsActive, setIsLoading) => {
  CompaniesController.getSidebarCategories().then((data) => {
    if (channelName === "Insights") {
      data?.data?.every((element) => {
        isEmptyArray(element.data_sources)
          ? setIsActive(false)
          : setIsActive(true);

        setIsLoading(false);
      });
    }

    data?.data?.map((element) => {
      if (element.name === channelName) {
        if (!isEmptyArray(element.data_sources)) {
          setIsActive(true);
        }
      }
      setIsLoading(false);
    });
  });
};

const getChannelsUsage = (setCompanyCanCreateChannel) => {
  MonitorsController.getChannelsUsage().then((data) => {
    setCompanyCanCreateChannel(data?.data?.company_can_create_channel);
  });
};

export { getDataSources, getDataSourceCategories, getChannelsUsage };
