import { useQuery } from "@tanstack/react-query";
import LiveDashboardController from "services/controllers/liveDashboardController";
export const useAuthPublicLiveDashboard = (queryData) => {
  return useQuery({
    queryKey: [
      "authPublicLiveDashboard",
      queryData?.challenge_string,
      queryData?.monitor_id,
      queryData?.dashboard_user_id,
    ],
    queryFn: () =>
      LiveDashboardController.authenticationPublicLiveDashboard(queryData),
    enabled:
      !!queryData?.challenge_string &&
      !!queryData?.monitor_id &&
      !!queryData?.dashboard_user_id,
    retry: false,
  });
};
