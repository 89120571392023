import { Box, Divider, Tooltip, Typography } from "@mui/material";
import PageHeader from "components/pageHeader";
import ExportBtn, {
  xlsxSupportedExportTabs,
} from "pages/Monitors/Components/exportButton/exportButton";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DateRangePicker from "shared/reduxComponents/dateRangePicker/dateRangePicker";
import {
  CheckValueLocale,
  convertXPlateformToTwitter,
  handlelUserRoles,
  isEmptyArray,
} from "utils/helpers";
import BreadCrumb from "../../../../components/breadCrumb";
import Filter from "../../../../shared/reduxComponents/filters/general/filter";
import "./monitorHeader.scss";
import HelpIcon from "@mui/icons-material/Help";
import MonitorCreateAlert from "../actionButtons/monitorCreateAlert";
import MonitorCreateReport from "../actionButtons/monitorCreateReport";
import MonitorLiveDashboardMenu from "../actionButtons/monitorLiveDashboardMenu";

const MonitorHeader = (props) => {
  const intl = useIntl();
  const [showExportBtn, setShowExportBtn] = useState(false);
  const urlParams = useParams();
  const history = useHistory();
  const dataSourceName = convertXPlateformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorDataSource =
    dataSourceName === "TALKWALKER" || dataSourceName === "NEWSBLOGS"
      ? "News & Blogs"
      : dataSourceName;

  let monitorActiveTab = urlParams.activeTab;
  monitorActiveTab =
    monitorActiveTab.charAt(0).toUpperCase() + monitorActiveTab.slice(1);
  const monitorDetails = props.monitorData;
  const isAuthorProfile =
    useHistory().location.pathname.includes("author-profile"); //if avaliable redirect user to AuthorsProfilePage.j
  const isDeepInsights =
    useHistory().location.pathname.includes("deep-insights"); //if avaliable redirect user to DeepInsightsPage.js

  const exportOptionDisabled = true;

  let showButtons = !isAuthorProfile && !isDeepInsights;

  /*______________________________________________________________*/
  //Bread Crumb Links

  const links = [
    {
      name: "monitors_list",
      url: "social/monitor_list",
      addNameLocal: true,
    },
    {
      name: isAuthorProfile
        ? `${monitorDetails?.id} - ${monitorDetails?.attributes.name}` +
          ` (${CheckValueLocale(
            monitorDataSource.toLowerCase(),
            "",
            {},
            intl,
          )})` +
          `    /  ${monitorActiveTab}`
        : `${monitorDetails?.id} - ${monitorDetails?.attributes?.name}`,
      url: `monitor/${urlParams.monitorType}/${urlParams.monitorDataSource}/${urlParams.activeTab}/${urlParams.monitorId}`,
      addNameLocal: false,
      state: monitorDetails,
    },
  ];

  let header = isAuthorProfile
    ? CheckValueLocale("engager_profile", "", {}, intl)
    : isDeepInsights
      ? CheckValueLocale("profile_analytics", "", {}, intl)
      : CheckValueLocale(urlParams.activeTab, "", {}, intl);

  let monitorActiveTabId = `${monitorDataSource?.toLowerCase()}-${monitorActiveTab?.toLowerCase()}-${
    urlParams?.monitorId
  }`;

  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );

  const reduxFilterParamsCount = useSelector(
    (state) => state?.socialListening?.filterParamsCount,
  );

  const fildesNames = props?.filterFields?.map((item) => item?.name);
  const inApplicableFilters = {};
  Object.keys(reduxFilterParams)?.map((key) => {
    if (!fildesNames?.includes(key)) {
      inApplicableFilters[key] = reduxFilterParams[key];
    }
  });

  let inApplicableFiltersArray = Object?.entries(inApplicableFilters)?.map(
    (key) => {
      return {
        name: key[0],
        value: key[1],
      };
    },
  );

  let toolTipText = !isEmptyArray(inApplicableFiltersArray)
    ? inApplicableFiltersArray?.map((item, index) => {
        return (
          <li key={index}>
            {`${CheckValueLocale(item?.name, "", {}, intl)}: ${
              Array.isArray(item?.value)
                ? CheckValueLocale(item?.value?.join(", "), "", {}, intl)
                : item?.value.replace(",", ", ") ?? null
            }`}
          </li>
        );
      })
    : null;
  /*______________________________________________________________*/
  return (
    <>
      {showButtons ? (
        <Box className="export-monitor-box-container">
          <Box className="export-monitor-box">
            <>
              <Box className="monitor-header-actions-box">
                {handlelUserRoles("GENERAL", "VIEW_UTILITIES") &&
                handlelUserRoles("GENERAL", "CREATE_UTILITIES") ? (
                  <MonitorCreateReport monitorData={props?.monitorData} />
                ) : null}
                {handlelUserRoles("GENERAL", "VIEW_UTILITIES") &&
                handlelUserRoles("GENERAL", "CREATE_UTILITIES") &&
                !props?.monitorData?.attributes?.paused ? (
                  <MonitorCreateAlert monitorData={props?.monitorData} />
                ) : null}
              </Box>
              {handlelUserRoles("SM", "VIEW_DASHBOARD") ? (
                <MonitorLiveDashboardMenu
                  monitorLiveDashboardList={props?.monitorLiveDashboardList}
                  urlParams={urlParams}
                  monitorDetails={monitorDetails}
                  monitorLiveDashboardListLoading={
                    props?.monitorLiveDashboardListLoading
                  }
                />
              ) : null}
              <Divider
                orientation="vertical"
                className="monitor-header-actions-divider"
              />
              {props?.showFilterBtn || !!reduxFilterParamsCount ? (
                <>
                  {!isEmptyArray(props?.filterFields) &&
                  !isEmptyArray(inApplicableFiltersArray) &&
                  props?.showFilterBtn ? (
                    <Box className="monitor-inapplicable-filters">
                      <Tooltip
                        title={
                          <div className="monitor-inapplicable-tooltip">
                            {CheckValueLocale(
                              "not_applicable_filters",
                              "",
                              {},
                              intl,
                            )}
                            <ul>{toolTipText}</ul>
                          </div>
                        }
                        arrow
                        placement="bottom"
                      >
                        <Typography variant={"h7"}>
                          <HelpIcon />
                          {CheckValueLocale(
                            "inapplicable_filters",
                            "",
                            { num: inApplicableFiltersArray?.length },
                            intl,
                          )}
                        </Typography>
                      </Tooltip>
                    </Box>
                  ) : null}
                  <Filter
                    {...props}
                    id="monitors-list-filter"
                    inApplicableFiltersArray={inApplicableFiltersArray}
                    monitorActiveTabFilterID={monitorActiveTabId}
                    isSocialGlobalFilters
                  />
                </>
              ) : null}
              <DateRangePicker
                {...props}
                openCustomDateLeft={true}
                monitorActiveTabId="monitor-header-date-range-picker"
                datePickerPosition="monitorheader-datepicker-position"
                id="date-range-picker-monitor"
              />
            </>
          </Box>
        </Box>
      ) : null}
      <Box className="view-monitor-detail">
        <Box className="monitor-header-box">
          <Box>
            <BreadCrumb id="breadcrumb-style-monitors" links={links} />
            <PageHeader title={header} withBreadCrumb={true} />
          </Box>
          {showButtons ? (
            <>
              {exportOptionDisabled ? (
                <ExportBtn {...props} setShowExportBtn={setShowExportBtn} />
              ) : null}
            </>
          ) : null}
        </Box>
        <Divider id="monitors-divider" />
      </Box>
    </>
  );
};
export default MonitorHeader;
