import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Card, CardContent, Input } from "@mui/material";
import { Add } from "@mui/icons-material";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import ChoiceSection from "./choiceSection";
import "../../surveyBuilder.scss";

const MultipleChoiceQuestion = (props) => {
  const {
    setSurveySections,
    surveySections,
    filteredSections,
    rootQuestion,
    question,
    questionIndex,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    setSaveChangesFlag,
    handleCardClick,
    handleChangeProceed,
    proceedError,
  } = props;
  const intl = useIntl();
  const [value, setValue] = useState({});
  const [choices, setChoices] = useState([]);
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    if (question) {
      setValue({ title: question?.text, description: question?.description });
      setChoices(question?.items);
      setEditable(question?.editable);
    }
  }, [question]);
  const handleAddChoice = () => {
    if (editable !== false) {
      if (choices?.length <= 9) {
        const myKey = choices?.length + 1;
        const mcqValue = `${CheckValueLocale("dropdown_option", "", {}, intl)} ${choices?.length + 1}`;
        var obj = { [myKey]: mcqValue };
        const proceedOnAnswer = filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].is_proceed_on_answer;
        if (proceedOnAnswer) {
          if (sectionIndex === filteredSections?.length - 1)
            obj.next_section = -1;
          else obj.next_section = filteredSections[sectionIndex].order + 1;
        }
        setChoices([...choices, obj]);
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].question.items = [...choices, obj];
      }
      setSaveChangesFlag(true);
    } else return false;
  };

  return (
    <Card
      className={`cardStyle card-direction-container ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        "noError"
      } ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        proceedError === questionIndex &&
        "error"
      }`}
      onClick={() => handleCardClick(sectionIndex, questionIndex)}
    >
      <CardContent className="card-body title-question">
        <Box className="question-title-container">
          <Box component="span" className="questionNoStyle">
            {questionIndex + 1}.
          </Box>
          <Input
            fullWidth
            multiline
            className="questionStylee"
            style={{
              direction: checkKeyboardLanguage(value?.title) ? "rtl" : "ltr",
            }}
            id={questionIndex}
            value={value?.title}
            onChange={(e) => {
              handleQuestionTitle(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            name="title"
            placeholder={CheckValueLocale("multiple_choice", "", {}, intl)}
            disableUnderline={true}
          />
        </Box>
        <Input
          variant="filled"
          className="subtitle"
          id={questionIndex}
          value={value?.description}
          onChange={(e) => {
            handleQuestionDesc(e, sectionIndex, questionIndex, value, setValue);
          }}
          style={{
            direction: checkKeyboardLanguage(value?.description)
              ? "rtl"
              : "ltr",
          }}
          multiline
          inputProps={{ maxLength: 145 }}
          name="subtitle"
          placeholder={CheckValueLocale("question_description", "", {}, intl)}
          disableUnderline={true}
        />
        <ChoiceSection
          filteredSections={filteredSections}
          sectionIndex={sectionIndex}
          questionIndex={questionIndex}
          rootQuestion={rootQuestion}
          question={question}
          choices={choices}
          setChoices={setChoices}
          editable={editable}
          surveySections={surveySections}
          setSurveySections={setSurveySections}
          handleChangeProceed={handleChangeProceed}
        />
        <Box mt={1}>
          <LucButton
            variant="flat"
            size="small"
            startIcon={<Add />}
            onClick={handleAddChoice}
            disabled={editable === false && true}
          >
            {CheckValueLocale("add_choice", "", {}, intl)}
          </LucButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MultipleChoiceQuestion;
