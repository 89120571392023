import { useIntl } from "react-intl";
import "./SingleInteraction.scss";
import BreadCrumb from "components/breadCrumb";
import { Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const SingleInteractionHeader = (props) => {
  const intl = useIntl();

  return (
    <>
      <div className="page-header">
        <div className="single-page-title bread-crumb-page-style">
          <BreadCrumb links={props.links} />
          <Typography
            variant="h5"
            component="h5"
            className="social-media-title"
          >
            {CheckValueLocale(props.dataSource, "", {}, intl)}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SingleInteractionHeader;
