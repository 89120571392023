import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import "./apisList.scss";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import OptionsDropDown from "./optionsDropDown";
import SharedTable from "shared/table/sharedTable";
import {
  tooltipCell,
  ipSubnetCell,
  dateCell,
  statusCell,
  lucidyaApisTypeLocale,
} from "./apisListBodyFunctions";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";

const ApisList = ({ apisListData }) => {
  const intl = useIntl();
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const nameCell = (name, types) => {
    return (
      <>
        {name ? tooltipCell(name, 15) : "—"}
        <span className="time-span">
          {types?.length > 0
            ? types
                .map((type) =>
                  CheckValueLocale(lucidyaApisTypeLocale(type), "", {}, intl),
                )
                .join(", ")
            : "—"}
        </span>
      </>
    );
  };
  const statusBody = (status) => {
    return (
      <Box className={"status " + statusCell(status)}>
        {CheckValueLocale(statusCell(status), "", {}, intl)}
      </Box>
    );
  };

  const optionsBodyCell = (item, index) => {
    return <OptionsDropDown item={item} />;
  };

  const tableHead = [
    {
      body: CheckValueLocale("api_name", "", {}, intl),
      width: 150,
    },
    {
      body: CheckValueLocale("lucidya_api_last_used", "", {}, intl),
      width: 100,
    },
    {
      body: CheckValueLocale("lucidya_api_ip_subnet", "", {}, intl),
      width: 150,
    },
    {
      body: CheckValueLocale("lucidya_api_created_by", "", {}, intl),
      width: 100,
    },
    {
      body: CheckValueLocale("lucidya_api_creation_date", "", {}, intl),
      width: 100,
    },
    {
      body: CheckValueLocale("status", "", {}, intl),
      width: 50,
    },
    {
      body: null,
      permission: handlelUserRoles("PUBLIC_API", "MANAGE_PUBLIC_API"),
      width: 30,
    },
  ];

  useEffect(() => {
    const tabBody =
      apisListData?.length == 0
        ? []
        : apisListData?.map((item, index) => {
            return {
              id: item?.id,
              data: [
                {
                  body: nameCell(
                    item?.attributes?.token_name,
                    item?.attributes?.api_type,
                  ),
                },
                {
                  body: item?.attributes?.last_used
                    ? dateCell(
                        item?.attributes?.last_used,
                        localStorage.getItem("lang"),
                      )
                    : "—",
                },
                {
                  body:
                    item?.attributes?.ip_subnet?.length > 0
                      ? ipSubnetCell(item?.attributes?.ip_subnet)
                      : "—",
                },
                {
                  body: item?.attributes?.created_by
                    ? item?.attributes?.created_by
                    : "—",
                },
                {
                  body: item?.attributes?.created_at
                    ? dateCell(
                        item?.attributes?.created_at,
                        localStorage.getItem("lang"),
                      )
                    : "—",
                },
                {
                  body: statusBody(item?.attributes?.is_valid),
                },
                {
                  body: optionsBodyCell(item, index),
                  permission: handlelUserRoles(
                    "PUBLIC_API",
                    "MANAGE_PUBLIC_API",
                  ),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(apisListData));
  }, [apisListData, intl?.locale]);

  const emptyBody = (apisListData) => {
    return (
      <>
        {apisListData?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image={"lucidyaAPI"}
              title={"generate_lucidya_API_key"}
              description="generate_lucidya_API_key_dec"
              linkOne="how_to_use"
              orCase="or_visit_the"
              linkOneHref={NoDataLinks.api_list}
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <>
      <SharedTable
        className="apis-table"
        isSharedStyle
        tableHeader={tableHead}
        tableBody={tableBody}
        emptyBody={tableEmptyBody}
      />
    </>
  );
};
export default ApisList;
