import { useEffect, useState } from "react";
import _ from "lodash";

const ScaleProceedFunctions = (
  surveySections,
  setSurveySections,
  filteredSections,
  sectionIndex,
  question,
  questionIndex,
) => {
  const [openModal, setOpenModal] = useState(false);

  const [remainingValues, setRemainingValues] = useState();

  const [remainingValueGoTo, setRemainingValueGoTo] = useState(null);
  const [tempRemainingValueGoTo, setTempRemainingValueGoTo] = useState(null);

  const [proceedValues, setProceedValues] = useState([]);
  const [tempProceedValues, setTempProceedValues] = useState([]);

  useEffect(() => {
    if (question?.items) {
      const areAllNextSectionsSame = question?.items?.every(
        (item, _, arr) => item?.next_section === arr?.[0]?.next_section,
      );

      if (areAllNextSectionsSame) {
        setProceedValues([{ value: null, next_section: null }]);
        setTempProceedValues([{ value: null, next_section: null }]);

        // Set the remaining val on openModal with any item next_section
        const nextSection = question?.items?.[0]?.next_section;
        setRemainingValueGoTo(nextSection);
        setTempRemainingValueGoTo(nextSection);
      } else {
        // Set the remaining val on openModal with next_section of unconditional item
        const remainingVal = question?.items?.find(
          (item) => !item?.is_conditional,
        )?.next_section;
        setRemainingValueGoTo(remainingVal);
        setTempRemainingValueGoTo(remainingVal);

        // Filter items with is_conditional=true and group them by next_section
        const conditionalItems = question?.items?.filter(
          (item) => item?.is_conditional,
        );
        const groupedConditionalItems = conditionalItems?.reduce(
          (acc, item) => {
            const nextSection = item?.next_section;
            const value = Object?.values(item)?.[0];

            // Check if we already have a group for this next_section
            const existingGroup = acc?.find(
              (group) => group?.next_section === nextSection,
            );
            if (existingGroup)
              existingGroup?.value?.push(value); // Add the value to the existing group
            else acc.push({ value: [value], next_section: nextSection }); // Create a new group

            return acc;
          },
          [],
        );

        setProceedValues(
          groupedConditionalItems || [{ value: null, next_section: null }],
        );
        setTempProceedValues(
          _.cloneDeep(groupedConditionalItems) || [
            { value: null, next_section: null },
          ],
        );
      }
    }
  }, [
    openModal,
    question?.items,
    question?.constraints?.min_value,
    question?.constraints?.max_value,
  ]);

  useEffect(() => {
    const newItemsArr = [];
    for (
      let i = question?.constraints?.min_value;
      i <= question?.constraints?.max_value;
      i++
    ) {
      newItemsArr?.push(i?.toString()); // Convert to string to match item format
    }

    // Access the current question's items
    let currentItems = filteredSections?.[sectionIndex]?.questions?.filter(
      (ques) => !ques.is_deleted,
    )?.[questionIndex]?.question?.items;

    // Remove items from currentItems if they are not in newItemsArr
    currentItems = currentItems?.filter((item) =>
      newItemsArr?.includes(Object?.values(item)?.[0]),
    );

    // Add any missing values from newItemsArr that are not in currentItems
    newItemsArr?.forEach((newItemValue) => {
      const itemExists = currentItems?.some(
        (item) => Object?.values(item)?.[0] === newItemValue,
      );

      // Add the new item if it doesn't exist
      if (!itemExists)
        currentItems?.push({
          [newItemValue]: newItemValue,
          next_section: remainingValueGoTo,
        });
    });

    // Sort the items by their numeric value
    currentItems?.sort((a, b) => {
      const aValue = parseInt(Object?.values(a)?.[0], 10);
      const bValue = parseInt(Object?.values(b)?.[0], 10);
      return aValue - bValue;
    });

    // Update the filteredSections with the modified and sorted items array
    filteredSections[sectionIndex].questions.filter(
      (ques) => !ques?.is_deleted,
    )[questionIndex].question.items = currentItems;

    // Update the surveySections state to trigger a re-render
    setSurveySections([...surveySections]);
  }, [
    question?.constraints?.min_value,
    question?.constraints?.max_value,
    remainingValueGoTo,
  ]);

  // set remaining values for unconditional items
  useEffect(() => {
    if (tempProceedValues) {
      let remainingArr = [
        ...(question?.items?.map((item) => Object?.values(item)?.[0]) || []),
      ];
      const valuesToRemove = new Set();
      // Add items to the set valuesToRemove if they are present in tempProceedValues
      tempProceedValues?.forEach((val) =>
        val?.value?.forEach((item) => valuesToRemove?.add(item)),
      );

      // Filter remainingArr to exclude items present in valuesToRemove
      remainingArr = remainingArr?.filter((item) => !valuesToRemove?.has(item));
      setRemainingValues(remainingArr);
    }
  }, [tempProceedValues]);

  const handleChangeProceedSwitch = (checked) => {
    const setNextFunc = (item) => {
      if (checked === true) {
        setOpenModal(true);
        item["next_section"] =
          sectionIndex === filteredSections?.length - 1
            ? -1
            : filteredSections[sectionIndex].order + 1;
      } else {
        setOpenModal(false);
        delete item["next_section"];
        delete item["is_conditional"];
      }
    };
    const filteredSectionsNotDeleted = filteredSections?.[
      sectionIndex
    ]?.questions?.filter((question) => !question?.is_deleted);

    filteredSectionsNotDeleted?.[questionIndex]?.question?.items?.forEach(
      setNextFunc,
    );

    filteredSections[sectionIndex].questions.filter(
      (ques) => !ques?.is_deleted,
    )[questionIndex].is_proceed_on_answer = checked;
    setSurveySections([...surveySections]);
  };

  const handleChangeProceedValue = (value, index) => {
    const tempProceedValuesCopy = [...tempProceedValues];
    tempProceedValuesCopy[index].value = value;
    setTempProceedValues(tempProceedValuesCopy);
  };

  const handleChangeProceedGoTo = (value, index) => {
    const tempProceedValuesCopy = [...tempProceedValues];
    tempProceedValuesCopy[index].next_section = value;
    setTempProceedValues(tempProceedValuesCopy);
  };

  const handleRemoveCondition = (index) => {
    let updatedArray = [...(tempProceedValues || [])];

    if (updatedArray?.length === 1) {
      setTempProceedValues([{ value: null, next_section: null }]);
      return;
    } else {
      updatedArray?.splice(index, 1);
      setTempProceedValues([...(updatedArray || [])]);
    }
  };

  const handleApply = async () => {
    let newItems = [...(question?.items || [])]; // Create a copy of the items array

    let remainingItems = [
      ...filteredSections[sectionIndex].questions
        .filter((ques) => !ques?.is_deleted)
        ?.[
          questionIndex
        ]?.question?.items?.map((item) => Object?.values(item)?.[0]),
    ]; // Create a copy of the remaining items array

    // Update the next_section and is_conditional properties of conditional items
    tempProceedValues?.map((tempProceedItem) => {
      if (tempProceedItem?.value?.length && tempProceedItem?.next_section) {
        tempProceedItem?.value?.forEach((val) => {
          remainingItems = remainingItems?.filter((item) => item !== val); // Remove the value from the remaining items array

          newItems?.forEach((item) => {
            if (val === Object?.values(item)?.[0]) {
              // Set the is_conditional false if the next_section is the same as the remainingValueGoTo
              if (tempProceedItem?.next_section === tempRemainingValueGoTo)
                return delete item.is_conditional;
              // Set the is_conditional property to true if the next_section is not the same as the remainingValueGoTo
              else item.is_conditional = true;

              item.next_section = tempProceedItem?.next_section; // Set the next_section of the item
            }
          });
        });
      }
    });

    // Update the next_section and is_conditional properties of remaining items (unconditional items)
    remainingItems?.map((item) => {
      newItems?.forEach((val) => {
        if (item === Object?.values(val)?.[0]) {
          val.next_section = tempRemainingValueGoTo;
          delete val.is_conditional;
        }
      });
    });

    // Update the items array in the filteredSections array with the new items array
    filteredSections[sectionIndex].questions.filter(
      (ques) => !ques?.is_deleted,
    )[questionIndex].question.items = newItems;
    setSurveySections([...surveySections]);

    await setOpenModal(false); // Close the modal after applying the changes
  };

  const handleDisableApply = () => {
    if (
      tempRemainingValueGoTo == remainingValueGoTo &&
      _.isEqual(tempProceedValues, proceedValues)
    )
      return true; // Check if tempProceedValues == proceedValues (No Changes in Conditions)

    if (tempProceedValues?.length === 1) {
      if (Object?.values(tempProceedValues[0])?.every((item) => !item))
        return false; // Check if all values in tempProceedValues[0] are falsy

      return Object?.values(tempProceedValues[0])?.some((item) => !item); // Check for any falsy values in tempProceedValues[0]
    } else
      return tempProceedValues?.some((obj) =>
        Object?.values(obj)?.some((value) => !value),
      ); // Check if any object in tempProceedValues has any falsy value
  };

  const handleItemsList = (index) => {
    let itemsList = [
      ...(question?.items?.map((item) => Object?.values(item)?.[0]) || []),
    ];

    let itemsToRemove = new Set();
    tempProceedValues?.map((proceedItem, proceedItemIndex) => {
      if (proceedItemIndex !== index)
        proceedItem?.value?.forEach((val) => itemsToRemove?.add(val));
    });
    itemsList = itemsList?.filter((item) => !itemsToRemove?.has(item));

    return itemsList;
  };

  const showRemoveBtn =
    tempProceedValues?.length > 1 ||
    (tempProceedValues?.length === 1 &&
      (!!tempProceedValues?.[0]?.value ||
        !!tempProceedValues?.[0]?.next_section));

  return {
    openModal,
    setOpenModal,
    handleChangeProceedSwitch,
    tempRemainingValueGoTo,
    setTempRemainingValueGoTo,
    remainingValues,
    proceedValues,
    tempProceedValues,
    setTempProceedValues,
    showRemoveBtn,
    handleChangeProceedValue,
    handleChangeProceedGoTo,
    handleRemoveCondition,
    handleApply,
    handleDisableApply,
    handleItemsList,
  };
};

export default ScaleProceedFunctions;
