import { useMutation, useQueryClient } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useUpgradeCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ queryData }) =>
      CompaniesController.requestService(queryData),
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
