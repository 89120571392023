import { useQuery } from "@tanstack/react-query";
import LiveDashboardController from "services/controllers/liveDashboardController";
export const useAccessTypesList = () => {
  return useQuery({
    queryKey: ["accessTypesList"],
    queryFn: () => LiveDashboardController.getAccessTypes(),
    select: (data) => data?.data?.options,
    retry: false,
  });
};
