import "./hashtagsFilter.scss";
import { Box } from "@mui/material";

import HashtagsSearch from "./hashtagsSearch";
import HashtagsStatus from "./hashtagsStatus";

const HashtagsFilter = ({
  hashtagsHeader,
  searchHashtags,
  setSearchHashtags,
  hanldeSearchIconClick,
  statusHashtags,
  setStatusHashtags,
}) => {
  return (
    <Box className="hashtags-filter-wrapper">
      <HashtagsSearch
        searchHashtags={searchHashtags}
        setSearchHashtags={setSearchHashtags}
        hanldeSearchIconClick={hanldeSearchIconClick}
      />
      <HashtagsStatus
        hashtagsHeader={hashtagsHeader}
        statusHashtags={statusHashtags}
        setStatusHashtags={setStatusHashtags}
      />
    </Box>
  );
};
export default HashtagsFilter;
