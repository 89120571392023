import { customDashboardCxmTooltips } from "./customDashboardCxmTooltips";
import { customDashboardSmTooltips } from "./customDashboardSmTooltips";

export const useCustomDashboardWidgetTooltip = (
  product,
  data_source,
  monitor_type,
  widget_name,
  isDm,
) => {
  let productName = product?.toLowerCase();
  let dataSource =
    productName === "sm"
      ? data_source?.toLowerCase()
      : isDm
        ? `${data_source?.toLowerCase()}_dm`
        : data_source?.toLowerCase();
  let monitorType = monitor_type?.toLowerCase();
  let widgetName = widget_name?.toLowerCase();

  let tooltipKey =
    productName === "sm"
      ? `${productName}_${dataSource}_${monitorType}_${widgetName}`
      : `${productName}_${dataSource}_${widgetName}`;

  if (product && dataSource && monitorType && widgetName) {
    if (productName === "sm" && customDashboardSmTooltips?.[tooltipKey])
      return customDashboardSmTooltips?.[tooltipKey];
    else if (productName === "cxm" && customDashboardCxmTooltips?.[tooltipKey])
      return customDashboardCxmTooltips?.[tooltipKey];
    else return tooltipKey;
  }
};
