import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { CustomerCareBody } from "./CustomerCareBody";
import { useState } from "react";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { useEditCustomerCare } from "../hooks/useEditCustomerCare";
import "../manageChannels.scss";
import CustomerCareChannel from "../addChannel/customerCareChannel";
import InputWithAddButton from "components/inputWithAddButton/InputWithAddButton";
import {
  handleAddKeywordsTolist,
  handleChangeInput,
} from "components/inputWithAddButton/inputWithAddButtonUtils";
import _ from "lodash";

const defaultMonitorError = {
  ignoreTweets: "",
  customerCareVal: "",
};

export const EditCustomerCareModal = ({
  open,
  close,
  channelId,
  channelDataSource,
  customerCare,
  ignoreTweets,
}) => {
  const intl = useIntl();
  const defaultMonitordData = {
    ignoreTweets: ignoreTweets ? ignoreTweets?.split(",") : [],
    customerCareVal: customerCare?.length ? customerCare[0] : "",
  };
  const [monitorErrors, setMonitorErrors] = useState(defaultMonitorError);
  const [monitorData, setMonitorData] = useState(defaultMonitordData);
  const [ignoreTweet, setIgnoreTweet] = useState("");
  const [errorCustomerCare, setErrorCustomerCare] = useState(false);
  const { mutate: editCustomerCare, isPending: isEditCustomerCarePending } =
    useEditCustomerCare();

  const handleEditCustomerCare = () => {
    if (errorCustomerCare) {
      return;
    }
    editCustomerCare(
      {
        channelId,
        customerCareVal: [monitorData?.customerCareVal],
        ignoreTweets:
          channelDataSource === "TWITTER"
            ? monitorData?.ignoreTweets?.join(",")
            : undefined,
      },
      {
        onSuccess: () => {
          close();
        },
      },
    );
  };

  const handleInputChange = (event) => {
    let val = event?.target?.value;
    setMonitorData((prev) => ({
      ...prev,
      customerCareVal: val,
    }));
    if (isPublicSpecialCharactersOrEmojis(val)) {
      setErrorCustomerCare(true);
    } else {
      setErrorCustomerCare(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
    >
      <Box className="customer-care-modal">
        <DialogTitle id="alert-dialog-title">
          {CheckValueLocale("customer_care_account_options", "", {}, intl)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {channelDataSource !== "TWITTER" ? (
              <CustomerCareBody
                handleInputChange={handleInputChange}
                customerCareVal={monitorData?.customerCareVal}
                errorCustomerCare={errorCustomerCare}
              />
            ) : (
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <CustomerCareChannel
                    monitorData={monitorData}
                    setMonitorData={setMonitorData}
                    setMonitorErrors={setMonitorErrors}
                    monitorErrors={monitorErrors}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputWithAddButton
                    labelName={"ignore_tweets"}
                    setMonitorErrors={setMonitorErrors}
                    monitorErrors={monitorErrors}
                    setMonitorData={setMonitorData}
                    monitorData={monitorData}
                    keyFeild={"ignoreTweets"}
                    placeholder={"insert_username_then_enter_with_add_button"}
                    handleChange={(e) =>
                      handleChangeInput(
                        e,
                        setIgnoreTweet,
                        "ignoreTweets",
                        [monitorData?.customerCareVal],
                        setMonitorErrors,
                        monitorData,
                      )
                    }
                    handleAddKeywordsTolist={() =>
                      handleAddKeywordsTolist(
                        ignoreTweet,
                        setIgnoreTweet,
                        "ignoreTweets",
                        [monitorData?.customerCareVal],
                        setMonitorErrors,
                        setMonitorData,
                        monitorData,
                        100,
                      )
                    }
                    value={ignoreTweet}
                    limitCount={100}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <LucButton
            onClick={close}
            id="pop-up-left-btn"
            variant="flat"
            type="secondary"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>

          <LucButton
            disabled={
              isEditCustomerCarePending ||
              errorCustomerCare ||
              Object?.values(monitorErrors)?.some(
                (error) => error?.length !== 0,
              ) ||
              _.isEqual(defaultMonitordData, monitorData)
            }
            onClick={handleEditCustomerCare}
            id="pop-up-right-btn"
            variant="filled"
            className="popup__rightbtn"
            loading={isEditCustomerCarePending}
          >
            {CheckValueLocale("apply_ingagement", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
