import "./mediaPreviewer.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef, useState } from "react";
import PopupModal from "components/popupModal";
import { Box, Link, Typography } from "@mui/material";
import { CheckValueLocale, checkArabicText, classNames } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPlay,
} from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import dummyImage from "images/shared-images/image-placeholder.svg";
import dummyVideo from "images/shared-images/video-placeholder.svg";

// when click on an image or video in the engagement, it should open a modal to display the media in bigger size.
// in the modal there are two cases:
// 1- if the media contains only one item, it should show the media in the center of the modal.
// 2- if the media contains more than one item, it should show a slider to navigate between them,
// and in the bottom of the modal, it should show the media as a list of items.(images or videos)
// and when click on an item in the list, it should show that item in slider.
// we are using swiper react library to implement the slider.

/**
 * MediaPreviewer component is responsible for displaying media such as images and videos from outside the component.
 * @param {media} media is an array of objects that contains the media items to be displayed in the media previewer. each object should contain the following properties:
 * 1- url: the url of the media item (image or video).
 * 2- type: the type of the media item (image or video).
 * @param {showMediaPreviewer} showMediaPreviewer is a boolean value to control the visibility of the media previewer.
 * @param {setShowMediaPreviewer} setShowMediaPreviewer is a function to set the visibility of the media previewer.
 * @param {defaultIndex} defaultIndex is a number to set the default index from the array of media items to be displayed in the media previewer.
 * @usage
 *
 * const [media, setMedia] = useState([]);
 * const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
 *
 * const handleMediaClick = (media) => {
 *  setMedia(media?.map((item) => ({
 *   url: item?.url,
 *  type: item?.type,
 * })));
 * setShowMediaPreviewer(true);
 * }
 *
 * { showMediaPreviewer ? (
 *  <MediaPreviewer
 *  media={media}
 * showMediaPreviewer={showMediaPreviewer}
 * setShowMediaPreviewer={setShowMediaPreviewer}
 * defaultIndex={0} // default is 0
 * />
 * ) : null }
 *
 *
 *
 */
export const MediaPreviewer = ({
  media,
  showMediaPreviewer,
  setShowMediaPreviewer,
  postText,
  monitorDataSource,
  defaultIndex = 0,
}) => {
  const intl = useIntl();
  // swiperRef is a reference to the swiper component to control it.
  const swiperRef = useRef(null);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(defaultIndex);
  // currentVideoPlaying is a reference to the current video that is playing.
  // we can use it to pause the video when we navigate to another media item.
  const [currentVideoPlaying, setCurrentVideoPlaying] = useState(null);
  // playingVideos is an array of boolean values to control the playing state of each video.
  // if the video in the index 1 is playing, then playingVideos[1] should be true.
  const [playingVideos, setPlayingVideos] = useState(
    Array(media?.length || 0).fill(false),
  );

  const handleSLideChange = (swiper) => {
    if (currentVideoPlaying) {
      // when we navigate to another media item, we should pause the current video.
      currentVideoPlaying?.pause?.();
      setCurrentVideoPlaying(null);
    }
    // reset the playing state of all videos to be false (not playing)
    setPlayingVideos(Array(media?.length).fill(false));
    // update the selected media index to be the active index of the swiper.
    setSelectedMediaIndex(swiper?.activeIndex);
  };
  //handleVideoPlay function is responsible for playing when the user clicks on the play icon button.
  const handleVideoPlay = (index) => {
    // get the video element by its id.
    const videoName = `media-previewer-video-${index}`;
    const video = document.getElementById(videoName);
    if (video) {
      // play it.
      video?.play?.();
      // set the playing state of the video to be true.
      const updatedPlayingVideos = [...playingVideos];
      updatedPlayingVideos[index] = true;
      setPlayingVideos(updatedPlayingVideos);
      setCurrentVideoPlaying(video);
    }
  };
  // handleClickedFooterMedia function is responsible for navigating to the selected media item in the slider.
  // in the footer when there are more than one media item.
  // when the user clicks on one media the slider should navigate to that media item.
  const handleClickedFooterMedia = (index) => {
    setSelectedMediaIndex(index);
    swiperRef?.current?.swiper?.slideTo?.(index);
  };
  const handleImageLoadError = (e) => {
    e.target.src = dummyImage;
  };

  return (
    <PopupModal
      open={showMediaPreviewer}
      close={() => setShowMediaPreviewer(false)}
      fixPadding={true}
      classeName="chat-media-previewer-popup"
      addClasses="chat-media-previewer-popup-outer"
      modalStyle="chat-media-previewer-popup-inner"
      body={
        <Box className="chat-media-previewer-popup-content">
          {/* header which contains the title and the close button. */}
          <Box className="chat-media-previewer-popup-content-header">
            <Typography
              variant="h6"
              className="chat-media-previewer-popup-content-header-title"
            >
              {CheckValueLocale("media_previewer_title", "", {}, intl)}
            </Typography>
            <Box
              className="chat-media-previewer-popup-content-header-close"
              onClick={() => setShowMediaPreviewer(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Box>
          </Box>
          {/* body which contains the slider. */}
          {monitorDataSource === "TWITTER" ||
          monitorDataSource === "NEWSBLOGS" ||
          monitorDataSource === "TWITTER_PUBLIC" ? (
            <Typography
              className={
                checkArabicText(postText)
                  ? "post-content post-content-ar"
                  : "post-content"
              }
            >
              {postText}
            </Typography>
          ) : null}

          <Box className="chat-media-previewer-popup-content-body">
            <Swiper
              slidesPerView={1}
              initialSlide={defaultIndex}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              pagination={{ clickable: true }}
              className="chat-media-previewer-swiper"
              ref={swiperRef}
              onSlideChange={handleSLideChange}
            >
              {media?.map((item, index) => (
                <SwiperSlide key={index}>
                  {item?.type === "image" ? (
                    <img
                      src={item?.url || dummyImage}
                      alt="media"
                      className="chat-media-previewer-image"
                      onError={handleImageLoadError}
                    />
                  ) : (
                    <Box className="chat-media-previewer-video-container">
                      {(monitorDataSource === "TWITTER" ||
                        monitorDataSource === "TWITTER_PUBLIC") &&
                      (item?.type == "video" ||
                        item?.type == "animated_gif") ? (
                        <Box className="chat-media-previewer-video">
                          <img
                            src={item?.img_preview}
                            id={`media-previewer-video-${index}`}
                            controls={playingVideos[index]}
                            className="chat-media-previewer-video"
                          />
                          <Box className="play-button play-button-video">
                            <a
                              target="_blank"
                              href={item?.url}
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon icon={faPlay} />
                            </a>
                          </Box>
                        </Box>
                      ) : (
                        <video
                          id={`media-previewer-video-${index}`}
                          controls={playingVideos[index]}
                          className="chat-media-previewer-video"
                        >
                          <source src={item?.url} type="video/mp4" />
                          <source src={item?.url} type="video/ogg" />
                          <source src={item?.url} type="video/avi" />
                          <img src={dummyVideo} />
                        </video>
                      )}

                      {!playingVideos[index] &&
                      monitorDataSource !== "TWITTER" &&
                      monitorDataSource !== "TWITTER_PUBLIC" ? (
                        <Box
                          className="play-button"
                          onClick={() => handleVideoPlay(index)}
                        >
                          <FontAwesomeIcon icon={faPlay} />
                        </Box>
                      ) : null}
                    </Box>
                  )}
                </SwiperSlide>
              ))}
              <div className="swiper-button-prev custom-swiper-button">
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              <div className="swiper-button-next custom-swiper-button">
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Swiper>
          </Box>
          {/* footer which contains the media items as a list. */}
          {media?.length > 1 ? (
            <Box className="chat-media-previewer-popup-content-footer">
              {media?.map((item, index) => (
                <Box
                  key={index}
                  className={classNames(
                    "chat-media-previewer-list-item",
                    index == selectedMediaIndex ? "selected" : null,
                  )}
                >
                  {item?.type === "video" ? (
                    <Box
                      className="chat-media-previewer-video-container"
                      onClick={() => {
                        handleClickedFooterMedia(index);
                      }}
                    >
                      {(monitorDataSource === "TWITTER" ||
                        monitorDataSource === "TWITTER_PUBLIC") &&
                      (item?.type == "video" ||
                        item?.type == "animated_gif") ? (
                        <Box className="chat-media-previewer-video">
                          <img
                            src={item?.img_preview}
                            id={`media-previewer-video-${index}`}
                            controls={playingVideos[index]}
                            className="chat-media-previewer-video"
                          />
                          <a
                            className="play-button"
                            target="_blank"
                            href={item?.url}
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faPlay} />
                          </a>
                        </Box>
                      ) : (
                        <video
                          alt="media"
                          className="chat-media-previewer-video"
                        >
                          <source src={item?.url} type="video/mp4" />
                          <source src={item?.url} type="video/ogg" />
                          <source src={item?.url} type="video/avi" />
                          <img src={dummyVideo} />
                        </video>
                      )}
                      <Box className="play-button">
                        <FontAwesomeIcon icon={faPlay} />
                      </Box>
                    </Box>
                  ) : (
                    <img
                      onClick={() => {
                        handleClickedFooterMedia(index);
                      }}
                      src={item?.url}
                      alt="media"
                      className="chat-media-previewer-image"
                      onError={handleImageLoadError}
                    />
                  )}
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      }
    />
  );
};
