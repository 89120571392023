import { useIntl } from "react-intl";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Table,
  TableCell,
  Box,
  Tooltip,
  Zoom,
  Typography,
  CircularProgress,
} from "@mui/material";
import "./sharedTable.scss";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale } from "utils/helpers";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import NoDataFoundProducts from "components/no-Data";

const SharedTable = ({
  tableHeader,
  tableBody,
  emptyMsgId,
  emptysubMsgId,
  loading,
  className,
  emptyBody,
  mainBox,
  tableClassName,
  headerRowClassName,
  headClassName,
  bodyClassName,
  tableAriaLabel,
  containerStyle,
  tableMinWidth,
  isSharedStyle,
  isHashTable,
  image,
  title,
  description,
  linkOne,
  linkOneHref,
  orCase,
  linkTwo,
}) => {
  const intl = useIntl();
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(97, 97, 97, 0.9)",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "4px 8px",
      fontWeight: "500",
    },
  }));
  const showTooltipText = (text, textLength) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Box component="span">{text?.substring(0, textLength)}...</Box>
          </BootstrapTooltip>
        ) : (
          <Typography component="p">{text}</Typography>
        )}
      </Box>
    );
  };

  return (
    <TableContainer
      component={Paper}
      id={isSharedStyle && "shared-table"}
      className={`${loading ? "shared-table-loading" : ""} ${className ? className : ""}`}
      style={containerStyle}
      sx={isHashTable ? { margin: "0px !important" } : null}
    >
      {mainBox ? mainBox : null}
      <Table
        sx={{ minWidth: tableMinWidth ? tableMinWidth : 700 }}
        aria-label={tableAriaLabel ? tableAriaLabel : "table"}
        className={tableClassName ? tableClassName : ""}
      >
        <TableHead className={headClassName ? headClassName : ""}>
          <TableRow className={headerRowClassName ? headerRowClassName : ""}>
            {tableHeader?.map((item, x) => {
              return (
                (item?.permission == false ? false : true) && (
                  <TableCell
                    rowSpan={item?.rowSpan ? item?.rowSpan : undefined}
                    onClick={item?.onClick ? item?.onClick : null}
                    key={x}
                    sortDirection={
                      item?.sortDirection ? item?.sortDirection : ""
                    }
                    component={item?.component ? item?.component : ""}
                    scope={item?.scope ? item?.scope : ""}
                    className={item?.className || ""}
                    sx={item?.sx || { width: item?.width || "auto" }}
                    style={item?.style}
                  >
                    {item?.body ? item?.body : ""}
                  </TableCell>
                )
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          className={bodyClassName ? bodyClassName : ""}
          id="shared-table-body"
        >
          {tableBody?.length > 0 &&
            tableBody?.map((row, x) => {
              return (
                <TableRow
                  key={row?.id || x}
                  className={row?.className ? row?.className : ""}
                >
                  {row?.data?.length > 0 &&
                    row?.data?.map((cell, x) => {
                      return (
                        (cell?.permission == false ? false : true) && (
                          <TableCell
                            rowSpan={cell?.rowSpan ? cell?.rowSpan : undefined}
                            onClick={cell?.onClick ? cell?.onClick : null}
                            sortDirection={
                              cell?.sortDirection ? cell?.sortDirection : ""
                            }
                            component={cell?.component ? cell?.component : ""}
                            scope={cell?.scope ? cell?.scope : ""}
                            key={x}
                            className={cell?.className ? cell?.className : ""}
                            sx={cell?.sx || ""}
                            style={cell?.style}
                          >
                            {cell?.isCreatedBy
                              ? showTooltipText(cell?.body, 30)
                              : cell?.body
                                ? cell?.body
                                : ""}
                          </TableCell>
                        )
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {loading ? (
        <div className="shared-table-loader-circular">
          <CircularProgress id="circular-progress-component" />
        </div>
      ) : null}
      {tableBody?.length == 0 && !loading ? (
        emptyBody || emptyMsgId || emptysubMsgId ? (
          <Box className="empty-item">
            {emptyBody ? (
              emptyBody
            ) : emptyMsgId || emptysubMsgId ? (
              <Box>
                {image ? (
                  <NoDataFoundProducts
                    image={image}
                    title={title}
                    description={description}
                    linkOne={linkOne}
                    linkOneHref={linkOneHref}
                    orCase={orCase}
                    linkTwo={linkTwo}
                  />
                ) : (
                  <Box>
                    <h2>{CheckValueLocale(emptyMsgId, "", {}, intl)}</h2>
                    <p>{CheckValueLocale(emptysubMsgId, "", {}, intl)}</p>
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        ) : null
      ) : null}
    </TableContainer>
  );
};
export default SharedTable;
