import { Box, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { makeStyles } from "@mui/styles";
import OptionsDropDown from "../optionsDropDown/optionsDropDown";
import SnackBar from "components/snackBar";
import {
  CheckValueLocale,
  classNames,
} from "../../../../../utils/helpers/index.js";
import DashboardFunctions from "../../../dashboardFunctions";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import SharedTable from "../../../../../shared/table/sharedTable";
import ErrorIcon from "@mui/icons-material/Error";
import { sortedColumnTypes } from "hooks/useSortableTable";
import NoDataFoundProducts from "components/no-Data";
import sl from "images/home-page/sl.svg";
import cxm from "images/home-page/oc.svg";

export const getDashboardTableHeader = (intl) => {
  return [
    {
      body: CheckValueLocale("dashboard_name", "", {}, intl),
      width: "32.42%",
      name: "name",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("dashboards_type", "", {}, intl),
      width: "13.04%",
      name: "type",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("monitors_or_channels", "", {}, intl),
      width: "32.42%",
    },

    {
      body: CheckValueLocale("dashboards_product_access", "", {}, intl),
      width: "16.38%",
      name: "product",
      type: sortedColumnTypes.number,
    },
    {
      body: null,
      width: "5.75%",
    },
  ];
};
const useStyles = makeStyles((theme) => ({
  customWidth: {
    padding: "13px",
    maxWidth: "500px",
  },
}));

const DashboardList = (props) => {
  const {
    page,
    setPage,
    dashboards,
    getDashboards,
    snackBarData,
    setSnackBarData,
    openSnackBarDelete,
    setOpenSnackBarDelete,
    companyHasDashoboards,
    companyHasMonitors,
    searchActivated,
    searchText,
    isSortableLoading,
    tableHead,
  } = props;
  const intl = useIntl();
  const [tableBody, setTableBody] = useState([]);

  const { tooltipText } = DashboardFunctions();

  const history = useHistory();
  const classes = useStyles();

  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
  };

  // _________________________________________________________

  const typeFormat = (type) => {
    const dashboardType = {
      AGGREGATE_DASHBOARD: CheckValueLocale("aggregate", "", {}, intl),
      CUSTOM_DASHBOARD: CheckValueLocale("custom_dashboard", "", {}, intl),
    };
    return dashboardType[type];
  };
  // _________________________________________________________

  const showDashboardText = (text, textLength) => {
    return (
      <>
        {text?.length > textLength ? (
          <Tooltip title={text} arrow placement="top">
            <div>{text.substring(0, textLength)}...</div>
          </Tooltip>
        ) : (
          text
        )}
      </>
    );
  };

  const showWarnings = (monitors) => {
    return (
      <Tooltip
        title={
          <Box>
            {monitors?.length === 1 ? (
              monitors?.map((monitor) => {
                if (monitor?.monitor_warning !== "") {
                  return tooltipText(
                    monitor?.name,
                    monitor?.monitor_warning,
                    "dashboard-tooltip",
                  );
                }
              })
            ) : (
              <div className="dashboard-tooltip-ul">
                {monitors?.map((monitor) => {
                  if (monitor?.monitor_warning !== "") {
                    return tooltipText(
                      monitor?.name,
                      monitor?.monitor_warning,
                      "dashboard-tooltip-li",
                    );
                  }
                })}
              </div>
            )}
          </Box>
        }
        arrow
        placement="bottom"
        classes={{ tooltip: classes?.customWidth }}
      >
        <Box className="warning-icon-countainer">
          <ErrorIcon className="dashboard-warning-icon" fontSize="small" />
        </Box>
      </Tooltip>
    );
  };

  // _________________________________________________________
  //this function to redirect user to the correct datasource url from datasourceList page
  const redirectToDashbaord = (dashboard) => {
    const dashboardID = dashboard?.id;
    const monitorType = dashboard?.attributes?.dashboard_monitor_type;
    const dashboardActiveTab =
      dashboard?.attributes?.dashboard_side_bar?.[0]?.analytics?.[0]?.tabName;

    history.push({
      pathname:
        dashboard?.attributes?.dashboard_type == "CUSTOM_DASHBOARD"
          ? `/dashboard/custom/view/${dashboardID}`
          : `/dashboard/${monitorType}/${dashboardActiveTab}/${dashboardID}`,
    });
  };
  const nameCell = (dashboard, i) => {
    let monitorStatus = [];
    return (
      <Box
        className="monitor-text display-in-start dashboard-head"
        id={`dashboard-${i}`}
      >
        {dashboard?.attributes?.monitors?.data?.map((monitor) => {
          if (monitor?.attributes?.monitor_warning !== "") {
            monitorStatus?.push(monitor?.attributes);
          }
        })}

        {!dashboard.attributes.is_accessible ? (
          <Tooltip
            title={CheckValueLocale("dashboard_warning_msg", "", {}, intl)}
            arrow
            placement="bottom"
          >
            <Box className="warning-icon-countainer">
              <ErrorIcon className="dashboard-warning-icon" fontSize="small" />
            </Box>
          </Tooltip>
        ) : (
          monitorStatus?.length !== 0 && showWarnings(monitorStatus)
        )}
        <Box
          onClick={() =>
            !dashboard?.attributes?.is_accessible &&
            dashboard?.attributes?.dashboard_type !== "CUSTOM_DASHBOARD"
              ? {}
              : redirectToDashbaord(dashboard)
          }
        >
          {showDashboardText(dashboard?.attributes?.name, 30)}
        </Box>
      </Box>
    );
  };
  const valuesObj = (monitorName) => {
    const names = monitorName?.split(",").map((name) => name?.trim());
    const maxVisibleNames = 2;
    const visibleNames = names?.slice(0, maxVisibleNames);
    const hiddenNamesCount = names?.length - maxVisibleNames;

    return (
      <div>
        {visibleNames?.join(", ")}
        {hiddenNamesCount > 0 && `, +${hiddenNamesCount}`}
      </div>
    );
  };
  const monitorNameCell = (dashboard) => {
    let monitorName = "";

    dashboard?.attributes?.monitors?.data?.length
      ? dashboard?.attributes?.monitors?.data?.map((monitor, index) => {
          let obj = {};
          if (index === 0) {
            monitorName = monitor?.attributes?.name;
          } else {
            monitorName = monitorName + ", " + monitor?.attributes?.name;
          }
        })
      : (monitorName = "—");

    return <span>{valuesObj(monitorName)}</span>;
  };
  const checkMonitorWorning = (dashboard) => {
    dashboard?.attributes?.monitors?.data?.map((monitor) => {
      monitor?.attributes?.monitor_warning?.length;
      return false;
    });
  };
  useEffect(() => {
    const tabBody =
      dashboards?.length == 0
        ? []
        : dashboards?.map((dashboard, i) => {
            return {
              className:
                !dashboard?.attributes?.is_accessible ||
                checkMonitorWorning(dashboard)
                  ? "warning gg"
                  : "",
              data: [
                {
                  body: nameCell(dashboard, i),
                  className:
                    dashboard?.attributes?.is_accessible !== true &&
                    dashboard?.attributes?.dashboard_type !== "CUSTOM_DASHBOARD"
                      ? ""
                      : "monitor-name",
                },
                {
                  className: "dashboard-type",
                  body: typeFormat(dashboard?.attributes?.dashboard_type),
                },
                {
                  className: "monitor-or-channel",
                  body: monitorNameCell(dashboard),
                },
                {
                  body: (
                    <Box className="icons-dashboard-table">
                      <img
                        src={dashboard?.attributes?.product_id === 1 ? sl : cxm}
                        alt=""
                      />
                      {dashboard?.attributes?.product_id === 1
                        ? CheckValueLocale("social_listening", "", {}, intl)
                        : CheckValueLocale("channel_analytics", "", {}, intl)}
                    </Box>
                  ),
                },

                {
                  body: (
                    <OptionsDropDown
                      page={page}
                      setPage={setPage}
                      dashboardItem={dashboard}
                      dashboards={dashboards}
                      getDashboards={getDashboards}
                      setSnackBarData={setSnackBarData}
                      setOpenSnackBarDelete={setOpenSnackBarDelete}
                    />
                  ),
                },
              ],
            };
          });
    setTableBody(tabBody);
  }, [dashboards, intl.locale]);

  const emptyBody = () => {
    return (
      <>
        {
          // Case if company has 0 dashboards and no search is running
          // Case if search is running and search is empty and company has no dashboards
          (dashboards?.length === 0 && !searchActivated) ||
          (dashboards?.length === 0 &&
            searchActivated &&
            props.searchText === "") ? (
            <Box className="empty-item">
              {!companyHasMonitors && !companyHasDashoboards ? (
                <NoDataFoundProducts
                  image="Dashboard"
                  title="create_your_dashboard"
                  subTitle="create_your_dashboard_msg"
                />
              ) : companyHasMonitors && !companyHasDashoboards ? (
                <NoDataFoundProducts
                  image="Dashboard"
                  title="create_your_dashboard"
                  subTitle="create_your_dashboard_msg"
                />
              ) : null}
            </Box>
          ) : // Case if Company has 0 dashboards and search is activated and search text is not empty
          dashboards?.length === 0 && searchActivated && searchText !== "" ? (
            <Box className="empty-item">
              <NoDataFoundProducts
                image="noDataImg"
                title="no_data_found"
                subTitle="sorry_there_is_no_data_dashboard"
              />
            </Box>
          ) : null
        }
      </>
    );
  };

  return (
    <>
      <SharedTable
        className="monitor-table table-dashboard-list"
        isSharedStyle
        tableHeader={tableHead}
        tableBody={tableBody}
        emptyBody={emptyBody()}
        loading={isSortableLoading}
      />
      {openSnackBarDelete && (
        <SnackBar
          open={openSnackBarDelete}
          handleClose={handleCloseSnackBar}
          severity={snackBarData?.severity}
          message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
        />
      )}
    </>
  );
};
export default DashboardList;
