import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { Box, Tooltip, Typography } from "@mui/material";
import PublishSidebar from "./componenets/sidebarContent/publishSidebar";
import PublishCalendar from "./componenets/sidebarContent/calenderContent/calender";
import XPIcon from "images/engagements-images/xp-icon-eng-round.svg";
import FBIcon from "images/engagements-images/fb-round-icon.svg";
import IGIcon from "images/engagements-images/ig-puplish-icon.svg";
import { getActiveParams } from "shared/general-filter/activeFilters/activeFilters";
import MonitorsController from "services/controllers/monitorsController";
import EngagementsController from "services/controllers/engagementsController";

import "./style.scss";
import moment from "moment";
import PublishPopupContent from "./componenets/sidebarContent/components/publishPopup/publishPopupContent";
import PublishPopUp from "./componenets/sidebarContent/components/publishPopup/publishPopUp";
import SnackBar from "components/snackBar";
import { CheckValueLocale, getTimeZone } from "utils/helpers";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailsPopup from "./componenets/sidebarContent/components/publishPopup/detailsPopup";
import xPlatform from "images/social-icons/x-platform-icon.svg";

const EngagementPublish = () => {
  const intl = useIntl();

  const [selectedAccoutnts, setSelectedAccounts] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [filterFields, setFilterFields] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  //calender

  const [date, setDate] = useState(new Date());
  const [accountData, setAccountsData] = useState([]);
  const [allawedAccountData, setAllawedAccountsData] = useState([]);
  const [accountsLoader, setAccountsLoader] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [accountsPublishSelected, setAccountsPublishSelected] = useState([]);
  const [openPopupPublish, setOpenPublish] = useState(false);
  const [publishedTypeSelected, setPublishedTypeSelected] =
    useState("scheduled");
  const [publishSpecifiedDate, setPublishSpecifiedDate] = useState("");
  const [publishTypeEvent, setPublishTypeEvent] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);

  //content
  const [selectedFile, setSelectedFile] = useState([]);
  const [base64Code, setBase64Code] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [text, setText] = useState("");
  const [mediaFile, setMediaFile] = useState([]);
  const [mediaLoader, setMediaLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [contentSelected, setContentSelected] = useState(false);
  const [mediaErr, setMediaErr] = useState("");
  const [contentErrormedia, setContentErrorMedia] = useState("");
  const [twLimitMedia, setTwLimitMeda] = useState({});
  const [fbLimitMedia, setFbLimitMeda] = useState({});
  const [igLimitMedia, setIGLimitMeda] = useState({});
  const [limitDatasouce, setLimitDatasouce] = useState({
    text: "",
    media: "",
  });
  const [showAlertActionMessage, setShowAlertActionMessage] = useState({
    message: "",
    severity: "",
    title: "",
  });
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const [calenderLoader, setCalenderLoader] = useState(false);
  const [upcomingLoader, setupcomingLoader] = useState(false);
  const [upcommingList, setUpComingList] = useState([]);

  const [selectedEventDetails, setSelectedEventDetails] = useState({});
  const [imagesDataEdit, setImagesDataEdit] = useState([]);
  const [idPublishedShow, setIdPublishedShow] = useState("");
  const [attachementsDeleted, setAttachmentsDeleted] = useState([]);
  const [delPopup, setDelPopup] = useState({
    open: false,
    loading: false,
  });
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);
  const [navigationMenuStatus, setNavigationMenuStatus] = useState("publish");
  const [openSetupFlowFirst, setOpenSetupFlowFirst] = useState(false);
  const [openSetupFlow, setOpenSetupFlow] = useState(false);
  const [isDoneSetup, setIsDoneSetup] = useState(true);

  const propsOptions = {
    openNavigationMenu,
    setOpenNavigationMenu,
    navigationMenuStatus,
    setNavigationMenuStatus,
    openSetupFlow,
    setOpenSetupFlow,
    openSetupFlowFirst,
    setOpenSetupFlowFirst,
    isDoneSetup,
    setIsDoneSetup,
  };

  //conevrt date to utc 0
  const getConvertDateUtc = (dateValData) => {
    return moment(dateValData).utc().unix();
  };

  const handleTruncateName = (input, count) => {
    return input?.length > count
      ? intl.locale === "en"
        ? `${input?.substring(0, count)}...`
        : `...${input?.substring(0, count)}`
      : input;
  };
  const handleDatasourcesIcon = (datasource) => {
    let datasourcesLIst = {
      twitter: XPIcon,
      facebook: FBIcon,
      instagram: IGIcon,
      default: XPIcon,
    };

    return datasourcesLIst[datasource] || datasourcesLIst.default;
  };
  const handleIconDataSources = (datasource) => {
    let datasourcesLIst = {
      facebook: faFacebookF,
      instagram: faInstagram,
    };
    return datasourcesLIst[datasource] || datasourcesLIst.default;
  };
  const handleClearFilterParams = () => {
    setFilterName("");
    setFilterParams({});
  };

  // call BE accounts
  const getAccountsDataApi = () => {
    setAccountsLoader(false);
    MonitorsController.getAllawedAccounts(
      window?.localStorage?.engagements_id,
      "publish",
    ).then((AllawedData) => {
      let allawedAccounts = AllawedData?.data?.data?.user_accounts;
      MonitorsController.getAllAccounts(
        window?.localStorage?.engagements_id,
        true,
      ).then((data) => {
        let accountsArr = [];
        let allawedArr = [];
        data?.data?.data?.TWITTER?.data?.map((item) => {
          if (item?.attributes?.can_publish) {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_source: "TWITTER",
              },
            });
            if (allawedAccounts?.TWITTER?.includes(+item?.id)) {
              allawedArr.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_source: "TWITTER",
                },
              });
            }
          }
        });

        data?.data?.data?.INSTAGRAM?.included?.map((item) => {
          if (item?.attributes?.can_publish) {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_source: "INSTAGRAM",
              },
            });
            if (allawedAccounts?.INSTAGRAM?.includes(+item?.id)) {
              allawedArr.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_source: "INSTAGRAM",
                },
              });
            }
          }
        });
        data?.data?.data?.FACEBOOK?.included?.map((item) => {
          if (item?.attributes?.can_publish) {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_source: "FACEBOOK",
              },
            });
            if (allawedAccounts?.FACEBOOK?.includes(+item?.id)) {
              allawedArr.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_source: "FACEBOOK",
                },
              });
            }
          }
        });
        let accountsDataSelected = [];
        accountsArr?.map((item) => {
          accountsDataSelected.push({
            reference_id: item?.id,
            data_source: item?.attributes?.data_source,
          });
        });
        setSelectedAccounts([...accountsDataSelected]);
        setAccountsData([...accountsArr]);
        setAllawedAccountsData([...allawedArr]);
        setAccountsLoader(true);
      });
    });
  };
  useEffect(() => {
    getAccountsDataApi();
    getValidationsMedia();
  }, []);
  let startDateInitial = moment().startOf("month").tz("utc", true).unix();
  let endDateInitial = moment().endOf("month").tz("utc", true).unix();

  //getCalendarData
  const getCalendarData = (
    sDate,
    eDate,
    filters = { ...assignActiveFilters },
    accounts,
  ) => {
    setCalendarData([]);
    setIsPostsLoading(true);
    let obj = { ...filters };
    if (accounts?.length) {
      obj.reference_id = getFormatSelectedAccounts(accounts);
    }
    setCalenderLoader(false);
    EngagementsController.getCalendarData(
      sDate,
      eDate,
      obj,
      window?.localStorage?.engagements_id,
    ).then((result) => {
      setCalenderLoader(true);
      result?.data?.data &&
        setCalendarData(getDataCalnderList(result?.data?.data?.data));
      getUpcomingAllData();
    });
  };
  /*********calender fn************** */
  const getDataCalnderList = (lists) => {
    // Access the array of scheduled posts
    let newData = [];
    let scheduledPosts = lists;
    // Create an object to store scheduled posts grouped by date
    let groupedPostsByDate = {};
    // Helper function to extract date part from datetime
    // Iterate through the scheduled posts
    scheduledPosts?.forEach((post) => {
      let { id, attributes: postAttributes } = post;
      let {
        post_scheduled_times,
        text,
        data_source_name,
        status,
        publisher_name,
      } = postAttributes;
      // Iterate through the scheduled times and group posts by date
      post_scheduled_times?.forEach((ss) => {
        // const dateOnly = converdate(scheduledTime.scheduled_time);
        let timeD = moment(ss?.scheduled_time).unix();
        let dateNews = new Date(timeD * 1000);
        let formattedDates = dateNews?.toISOString();
        const dateOnly = new Date(formattedDates);

        if (!groupedPostsByDate[dateOnly]) {
          groupedPostsByDate[dateOnly] = [];
        }
        newData.push({
          id: id,
          title: tooltipFun(text, data_source_name, publisher_name),
          start: dateOnly,
          end: new Date(dateOnly?.getTime() + 1 * 1000), // Add 30 minutes to the scheduled time
          dataSource: data_source_name,
          status: status,
        });
      });
    });
    // Now, groupedPostsByDate object contains scheduled posts grouped by date
    return newData;
  };

  const tooltipFun = (Name, dataSource, publisherName) => {
    return (
      <div>
        <Tooltip
          arrow
          id="tooltip-calender-content"
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                width: 220,
              },
            },
          }}
          title={
            <Box className="tooltip-calender-content">
              <Typography component={"h4"} className="tooltip-calender-title">
                {CheckValueLocale("content", "", {}, intl)}
              </Typography>
              <Box component="p">{Name}</Box>
            </Box>
          }
          placement="top"
        >
          <Box className="tooltip-calender-btn">
            <Typography component={"span"}>
              {dataSource === "FACEBOOK" || dataSource === "INSTAGRAM" ? (
                <FontAwesomeIcon
                  icon={handleIconDataSources(dataSource?.toLowerCase())}
                />
              ) : (
                <img src={xPlatform} alt="x-platform-logo" />
              )}
              <Typography className="publish-name">{publisherName}</Typography>
            </Typography>
          </Box>
        </Tooltip>
      </div>
    );
  };
  // Create an empty array to store the new objects

  //get api validations
  const getValidationsMedia = () => {
    EngagementsController.getPublishMediaValidations(
      window?.localStorage?.engagements_id,
    ).then((data) => {
      setTwLimitMeda(data?.data?.TWITTER);
      setFbLimitMeda(data?.data?.FACEBOOK);
      setIGLimitMeda(data?.data?.INSTAGRAM);
    });
  };
  //handle close popup new post
  const handleCloseNewPost = () => {
    setOpenPublish(false);
    setAccountsPublishSelected([]);
    setAttachments([]);
    setSelectedValue("");
    setBase64Code([]);
    setText("");
    setSelectedFile([]);
    setMediaErr("");
    setContentErrorMedia("");
    setShowSnackBar(false);
    setAccountsPublishSelected([]);
    setImagesDataEdit([]);
    setSelectedEventDetails({});
    setAttachmentsDeleted([]);
    setIdPublishedShow("");
  };

  const handleEditPublishClose = () => {
    setOpenPublish(true);
    setSelectedEventDetails({});
    setImagesDataEdit({});
    setSelectedEventDetails({});
    setAccountsPublishSelected([]);
    setBase64Code([]);
    setText("");
    setSelectedFile([]);
    setMediaErr("");
    setAttachments([]);
    setContentErrorMedia("");
    setAttachmentsDeleted([]);
  };

  const handleDateFormat = (DateVal) => {
    let startOfMonth = moment(DateVal).startOf("month").subtract(7, "days");
    let endOfMonth = moment(DateVal).endOf("month").add(7, "days");
    let startDate = moment(startOfMonth).tz("utc", true).unix();
    let endDate = moment(endOfMonth).tz("utc", true).unix();

    return { startDate, endDate };
  };

  const getFiltersFileds = () => {
    EngagementsController.enabledFiltersPublish(
      window?.localStorage?.engagements_id,
    ).then((data) => {
      setFilterFields([...data?.data]);
    });
  };
  let { startDate, endDate } = handleDateFormat(date);
  const getFormatSelectedAccounts = (dataAccounts) => {
    let arrData = [];
    dataAccounts?.map((el) => {
      arrData.push(el?.reference_id);
    });
    return arrData;
  };
  const applyFilter = (
    startDate,
    endDate,
    activeFilterParams,
    pageNo,
    isAppliedFilter,
  ) => {
    let selectedFilterParams = !isAppliedFilter
      ? assignActiveFilters
      : activeFilterParams !== undefined
        ? activeFilterParams
        : filterParams;
    getCalendarData(
      startDate,
      endDate,
      selectedFilterParams,
      selectedAccoutnts,
    );

    setAssignActiveFilters(selectedFilterParams);
  };
  const getUpcomingAllData = () => {
    let startDateVal =
      moment().tz("utc", true).unix() - parseInt(companyTimeZone) * 60 * 60;

    let endDateVal =
      moment().add(1, "d").endOf("day").unix() +
      parseInt(companyTimeZone) * 60 * 60;
    let queryData = {
      start_date: startDateVal,
      end_date: endDateVal,
      product_id: window?.localStorage?.engagements_id,
    };
    setupcomingLoader(false);
    EngagementsController.getUpcomingData(queryData).then((data) => {
      setupcomingLoader(true);
      let dataListUpcoming = getDataGroupingList(data?.data);
      let upCommimgLists = [];
      data?.data &&
        Object.keys(dataListUpcoming)?.forEach((prop) => {
          if (getContentTitle(prop))
            upCommimgLists.push({
              title: getContentTitle(prop).name,
              accountList: dataListUpcoming[prop],
              value: getContentTitle(prop).value,
            });
        });
      let sortedArray = upCommimgLists?.length
        ? upCommimgLists?.sort((a, b) => a?.value - b?.value)
        : upCommimgLists;
      setUpComingList(sortedArray);
      setIsPostsLoading(false);
      if (publishTypeEvent === "edit_publish") {
        handleEditPublishClose();
        setPublishTypeEvent("show_publish");
      } else {
        handleCloseNewPost();
        setPublishTypeEvent("");
        if (delPopup?.open) {
          setDelPopup({
            open: false,
            loading: false,
          });
          setShowAlertActionMessage({
            message: "post_del",
            severity: "success",
            title: "",
          });
          setShowSnackBar(true);
        }
      }
    });
  };
  // Get the current date title
  const getContentTitle = (val) => {
    // add time zone copmany
    let dateParts = val.split("-");
    let getDayValue = dateParts[2];
    let ss =
      moment().unix() +
      parseInt(companyTimeZone) * 60 * 60 -
      getTimeZone() * 60 * 60;
    let dateS = new Date(ss * 1000);
    let currentDay = dateS.getDate();
    let tomorrowDate = dateS.getDate() + 1;
    let res = "";
    if (getDayValue == currentDay) {
      res = { name: "today", value: +getDayValue };
    }
    // Check if the given date is tomorrow
    else if (getDayValue == tomorrowDate) {
      res = { name: "tomorrow", value: +getDayValue };
    }
    // The given date is neither today nor tomorrow
    else {
      res = "";
    }
    return res;
  };
  let companyTimeZone = window.localStorage.companyInfo;

  const getFullTimeDate = (timeDateValues) => {
    let ss =
      moment(timeDateValues).unix() -
      getTimeZone() * 60 * 60 +
      parseInt(companyTimeZone) * 60 * 60;
    const dateS = new Date(ss * 1000);
    const hours = dateS.getHours();
    const minutes = dateS.getMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    let formattedHours = hours;
    let amPm = hours < 12 ? "AM" : "PM";
    if (hours > 12) {
      formattedHours -= 12;
    } else if (hours === 0) {
      formattedHours = 12;
    }
    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  };

  const getFormatTimeZoneDate = (dateNewVal) => {
    let timeD =
      moment(dateNewVal).unix() -
      getTimeZone() * 60 * 60 +
      parseInt(companyTimeZone) * 60 * 60;
    let dateNews = new Date(timeD * 1000);
    let formattedDates = dateNews?.toISOString();

    return formattedDates;
  };

  const converdate = (dd) => {
    let timeD =
      moment(dd).unix() + parseInt(localStorage.companyInfo) * 60 * 60;
    let dateNews = new Date(timeD * 1000);
    let formattedDates = dateNews?.toISOString();
    return formattedDates;
  };
  const getDataGroupingList = (lists) => {
    // Access the array of scheduled posts
    let scheduledPosts = lists;
    // Create an object to store scheduled posts grouped by date
    let groupedPostsByDate = {};
    // Helper function to extract date part from datetime
    const extractDate = (datetime) => datetime.split("T")[0];
    // Iterate through the scheduled posts
    scheduledPosts?.forEach((post) => {
      let { id, attributes: postAttributes } = post;
      let { post_scheduled_times, text, publisher_name, data_source_name } =
        postAttributes;
      // Iterate through the scheduled times and group posts by date
      post_scheduled_times?.forEach((scheduledTime) => {
        const dateOnly = extractDate(converdate(scheduledTime.scheduled_time));

        if (!groupedPostsByDate[dateOnly]) {
          groupedPostsByDate[dateOnly] = [];
        }

        groupedPostsByDate[dateOnly].push({
          id,
          content: text,
          name: publisher_name,
          datasource: data_source_name,
          time: getFullTimeDate(scheduledTime?.scheduled_time),
        });
      });
    });
    // Now, groupedPostsByDate object contains scheduled posts grouped by date
    return groupedPostsByDate;
  };

  const getDetailsPopupPublish = (id) => {
    EngagementsController.showDetailsPublish(
      id,
      window?.localStorage?.engagements_id,
    ).then((data) => {
      setSelectedEventDetails(data?.data);
    });
  };
  const [timesEdite, setTimesEdit] = useState([]);

  const childProps = {
    selectedAccoutnts,
    setSelectedAccounts,
    handleTruncateName,
    accountData,
    allawedAccountData,
    setAccountsData,
    upcommingList,
    handleDatasourcesIcon,
    filterParams,
    setFilterParams,
    filterFields,
    handleClearFilterParams,
    applyFilter,
    setAssignActiveFilters,
    assignActiveFilters,
    allActiveFilters: getActiveParams(assignActiveFilters, filterFields), //showing active selected filters on top of the page,
    date,
    setDate,
    accountsLoader,
    accountsPublishSelected,
    setAccountsPublishSelected,
    openPopupPublish,
    setOpenPublish,
    publishTypeEvent,
    setPublishTypeEvent,
    publishedTypeSelected,
    setPublishedTypeSelected,
    publishSpecifiedDate,
    setPublishSpecifiedDate,
    selectedFile,
    setSelectedFile,
    base64Code,
    setBase64Code,
    attachments,
    setAttachments,
    text,
    setText,
    mediaFile,
    setMediaFile,
    mediaLoader,
    setMediaLoader,
    selectedValue,
    setSelectedValue,
    contentSelected,
    setContentSelected,
    mediaErr,
    setMediaErr,
    contentErrormedia,
    setContentErrorMedia,
    twLimitMedia,
    fbLimitMedia,
    igLimitMedia,
    limitDatasouce,
    setLimitDatasouce,
    showAlertActionMessage,
    setShowAlertActionMessage,
    showSnackBar,
    setShowSnackBar,
    handleCloseNewPost,
    getCalendarData,
    isPostsLoading,
    startDateInitial,
    endDateInitial,
    handleDateFormat,
    startDate,
    endDate,
    getFiltersFileds,
    getFormatSelectedAccounts,
    upcomingLoader,
    getConvertDateUtc,
    getUpcomingAllData,
    calenderLoader,
    setCalenderLoader,
    setupcomingLoader,
    companyTimeZone,
    setIsPostsLoading,
    selectedEventDetails,
    setSelectedEventDetails,
    getDetailsPopupPublish,
    timesEdite,
    setTimesEdit,
    getFormatTimeZoneDate,
    imagesDataEdit,
    setImagesDataEdit,
    idPublishedShow,
    setIdPublishedShow,
    setCalendarData,
    attachementsDeleted,
    setAttachmentsDeleted,
    delPopup,
    setDelPopup,
    getAccountsDataApi,
    setUpComingList,
  };
  const getFilteredAccount = (account) => {
    let filteredArray = [...accountData];
    let res = [];
    filteredArray?.map((el) => {
      if (el?.id == account.attributes?.account_info_reference_id) {
        res.push({
          reference_id: el?.id,
          data_source: el?.attributes?.data_source,
          imageUrl: el?.attributes?.image_url,
          name: el?.attributes?.name,
        });
      }
    });
    setAccountsPublishSelected(res);
    return res;
  };
  useEffect(() => {
    if (selectedEventDetails?.id) {
      getFilteredAccount(selectedEventDetails);
      setText(selectedEventDetails?.attributes?.text);
      setTimesEdit(selectedEventDetails?.attributes?.post_scheduled_times);
      setPublishedTypeSelected(
        selectedEventDetails?.attributes?.publishing_type,
      );
      setImagesDataEdit(selectedEventDetails?.attributes?.attachments);
    }
  }, [selectedEventDetails]);
  const createPopupBody = (
    <PublishPopupContent
      popupTitle={
        publishTypeEvent == "create_publish"
          ? "publish_new_post"
          : "publish_edit_post"
      }
      setOpen={setOpenPublish}
      setPublishTypeEvent={setPublishTypeEvent}
      handleClose={handleCloseNewPost}
      {...propsOptions}
      {...childProps}
      setOpenPublish={setOpenPublish}
      openPopup={openPopupPublish}
    />
  );
  const detailsPopup = (
    <DetailsPopup
      {...propsOptions}
      {...childProps}
      handleClose={handleCloseNewPost}
      selectedEventDetails={selectedEventDetails}
      setSelectedEventDetails={setSelectedEventDetails}
      accountsPublishSelected={accountsPublishSelected}
    />
  );
  let bodyPopUP =
    publishTypeEvent == "show_publish" ? detailsPopup : createPopupBody;

  return (
    <Box className="publish-engagement-container">
      <PublishSidebar {...propsOptions} {...childProps} />
      <PublishCalendar
        {...propsOptions}
        {...childProps}
        calendarData={calendarData}
      />

      {openPopupPublish ? (
        <Box
          className="pop-up-wrapper-publish"
          onClick={handleCloseNewPost}
        ></Box>
      ) : null}

      {openPopupPublish ? (
        <PublishPopUp
          popupBody={bodyPopUP}
          setOpen={setOpenPublish}
          setPublishTypeEvent={setPublishTypeEvent}
          handleClose={handleCloseNewPost}
          setOpenPublish={setOpenPublish}
          {...propsOptions}
          {...childProps}
        />
      ) : null}
      <SnackBar
        open={showSnackBar}
        severity={showAlertActionMessage?.severity}
        message={CheckValueLocale(
          showAlertActionMessage?.message,
          "",
          {},
          intl,
        )}
        title={CheckValueLocale(showAlertActionMessage?.title, "", {}, intl)}
        handleClose={() => {
          setShowSnackBar(false);
        }}
      />
    </Box>
  );
};

export default EngagementPublish;
