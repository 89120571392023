import { useState } from "react";
import ForwardLinker from "./ForwardLinker";
import { Box, Typography, Tooltip, Zoom } from "@mui/material";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";
import { CheckValueLocale, checkIfWeNeedShowMoreTweet } from "utils/helpers";
import SnackBar from "components/snackBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faBan } from "@fortawesome/pro-regular-svg-icons";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import EngagementsController from "services/controllers/engagementsController";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import { getUnixEndDate } from "utils/helpers";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses?.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses?.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "10px 8px",
    fontWeight: "500",
  },
}));

const generateDefaultPlaceholder = (username) => {
  if (!username) return null;
  const fullName = username.split(" ");
  let twoLetters = username[0] + username[1];
  if (fullName.length > 1) {
    twoLetters = `${fullName[0][0]}${fullName[1][0]}`;
  }
  return twoLetters.toUpperCase();
};

const InteractionContentRenderer = ({
  content,
  imgURL,
  username,
  userHandle,
  interactionURL,
  isOnlyUsername,
  interactionId,
  trackerId,
  dataSourceId,
  interactionType,
  dataSourceName,
  isEngage,
  createdAt,
  conversation,
}) => {
  const [hasImageFailToLoad, setHasImageFallToLoad] = useState(false);
  const intl = useIntl();
  const isRTL = intl?.locale === "ar";

  const [openDeleteModalStats, setOpenDeleteModalStats] = useState({
    openDeleteModal: false,
    snackBarAction: "",
    snackBarSeverity: "",
  });
  const [snackBarDeleteData, setSnackBarDeleteData] = useState({
    severity: "",
    message: "",
    title: "",
  });

  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [isDeletedResponse, setIsDeletedResponse] = useState(false);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [showFullTextTweet, setShowFullTextTweet] = useState(false);

  const getModelDeletePostdBody = () => {
    return (
      <Box className="delete-post-body-modal">
        <Typography component={"p"}>
          {CheckValueLocale("delete_post_body", "", {}, intl)}
        </Typography>
        <Typography component={"p"}>
          {CheckValueLocale("delete_post_body_two", "", {}, intl)}
          <Box component="span">
            {CheckValueLocale("delete_post_body_undone", "", {}, intl)}
          </Box>
        </Typography>
      </Box>
    );
  };

  //Delete Message
  const deleteReplyMsg = () => {
    return (
      <Box className="delete-msg-wrapper">
        <Box className="delete-msg-comments">
          <FontAwesomeIcon icon={faBan} />
          {CheckValueLocale("comment_was_deleted", "", {}, intl)}{" "}
        </Box>
      </Box>
    );
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: false,
    });
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
  };

  const handleDeletePost = () => {
    setDisabledDeleteBtn(true);
    EngagementsController.deleteEngagementPost(
      interactionId,
      dataSourceId,
      trackerId,
      interactionType,
      window?.localStorage?.cxm_id,
    ).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        setDisabledDeleteBtn(false);
        handleCloseDeleteModal();
        setOpenSnackBarDelete(true);
        setIsDeletedResponse(res?.data?.data?.is_deleted);
        setSnackBarDeleteData({
          severity: "success",
          title: "post_was_deleted",
        });
      } else {
        handleCloseDeleteModal();
        setDisabledDeleteBtn(false);
        setSnackBarDeleteData({
          title: "something_went_wrong",
          severity: "error",
        });
        setOpenSnackBarDelete(true);
      }
    });
  };

  const handleOpenDeleteModal = (event) => {
    event.stopPropagation();
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: true,
    });
  };
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  const unixDate = {
    start: createdAt,
    end: getUnixEndDate(createdAt),
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          unixDate={unixDate}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: userHandle,
            user_name: username,
            profile_image: imgURL && !hasImageFailToLoad ? imgURL : "",
          }}
          dataSourceName={dataSourceName}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Box className="interaction-content-render">
        <Box className="interaction-row">
          <Box
            className="interaction-user-profile hover-show"
            onClick={handleSideBar}
          >
            <Box className="interaction-img-placeholder">
              {imgURL && !hasImageFailToLoad ? (
                <img
                  src={imgURL}
                  alt="user profile"
                  onError={() => {
                    setHasImageFallToLoad(true);
                  }}
                />
              ) : (
                <Box component="span">
                  {generateDefaultPlaceholder(username)}
                </Box>
              )}
            </Box>
            <Box className="interaction-user-info">
              <Box component="span" className="interaction-user-info-name">
                {isOnlyUsername
                  ? isRTL
                    ? username + "@"
                    : "@" + username
                  : username}
              </Box>
              {userHandle ? (
                <Box component="span" className="interaction-user-info-handle">
                  {isRTL ? userHandle + "@" : "@" + userHandle}
                </Box>
              ) : null}
            </Box>
          </Box>
          {isEngage ? (
            <Box className="delete-open-link">
              {dataSourceName == "FACEBOOK" || dataSourceName == "INSTAGRAM" ? (
                <BootstrapTooltip
                  title={CheckValueLocale("delete_post", "", {}, intl)}
                  arrow
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <Box
                    onClick={(event) => {
                      handleOpenDeleteModal(event);
                    }}
                    className={`delete-icon-wrapper ${
                      isDeletedResponse && "disable-del-edit"
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="saved-result-delete-icon"
                    />
                  </Box>
                </BootstrapTooltip>
              ) : null}
              <ForwardLinker url={interactionURL} />
            </Box>
          ) : null}
        </Box>
        {isDeletedResponse ? (
          deleteReplyMsg()
        ) : (
          <Typography className="interaction-content">
            {content}
            {dataSourceName === "TWITTER" &&
            checkIfWeNeedShowMoreTweet(
              content,
              conversation?.tweet_full_text,
            ) ? (
              <Box
                component="span"
                className="tweet-full-text-read-all-btn"
                onClick={() => setShowFullTextTweet(true)}
              >
                {CheckValueLocale("read_all", "", {}, intl)}
              </Box>
            ) : null}
          </Typography>
        )}
      </Box>

      {/* Delete Modal Popup [FACEBOOK & INSTAGRAM Only] */}
      <PopupModal
        title={CheckValueLocale("delete_post", "", {}, intl)}
        body={getModelDeletePostdBody()}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete", "", {}, intl)}
        open={openDeleteModalStats?.openDeleteModal}
        close={handleCloseDeleteModal}
        accept={() => handleDeletePost()}
        rightColor="error"
        disabled={disabledDeleteBtn}
        warning
        minWidth="600px"
        addClasses="delete-engagement-buttons"
      />

      <SnackBar
        open={openSnackBarDelete}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(snackBarDeleteData?.title, "", {}, intl)}
        severity={snackBarDeleteData?.severity}
        message={CheckValueLocale(snackBarDeleteData?.message, "", {}, intl)}
      />
      {showFullTextTweet ? (
        <FullTextTweetPopUp
          closeFilter={() => setShowFullTextTweet(false)}
          post={conversation}
          showRetweet={true}
          showHeart={true}
          ShowComments={true}
        />
      ) : null}
    </>
  );
};

export default InteractionContentRenderer;
