import Services from "./Services";

var Themes = {};

// Get Themes list API
Themes.getThemesList = () => {
  return Services.getData("api/v4/utilities/subthemes/get_subthemes");
};

// Reorder Themes API
Themes.reorderThemes = (queryData) => {
  return Services.postData(
    "api/v4/utilities/subthemes/order_subthemes",
    queryData,
  );
};

// Get all colors for Themes (used and unused) API
Themes.getThemesColors = () => {
  return Services.getData("api/v4/utilities/subthemes/get_subthemes_colors");
};

// Create New Theme API
Themes.createNewTheme = (queryData) => {
  return Services.postData(
    "api/v4/utilities/subthemes/add_subtheme",
    queryData,
  );
};

// Update Theme API
Themes.updateTheme = (queryData) => {
  return Services.postData(
    "api/v4/utilities/subthemes/update_subtheme",
    queryData,
  );
};

// Delete Theme API
Themes.deleteTheme = (queryData) => {
  return Services.getData(
    "api/v4/utilities/subthemes/delete_subtheme",
    queryData,
  );
};

export default Themes;
