import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";

import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";

import TopHashtagsController from "services/controllers/topHashtagsController";
import SeparatorIcon from "images/SeparatorIcon.svg";
import CircularLoading from "components/circularProgress";
import LineChart from "components/echarts/lineChart";
import "./hashtag-details.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import SharedTable from "../../../../shared/table/sharedTable";
import { CheckValueLocale } from "utils/helpers";

const periodData = [
  "09/12/2022 at 18:06pm GMT",
  "09/12/2022 at 19:06pm GMT",
  "09/12/2022 at 20:06pm GMT",
  "09/12/2022 at 21:06pm GMT",
  "09/12/2022 at 23:06pm GMT",
  "09/12/2022 at 08:06pm GMT",
  "09/12/2022 at 08:36pm GMT",
  "09/12/2022 at 02:06pm GMT",
];
const overallData = [
  {
    row: 1,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 2,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 3,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 4,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 5,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 6,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 7,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 8,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 9,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 10,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 11,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 12,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 13,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 14,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 15,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 16,
    Region: "Saudi Arabia",
    Hours: 65,
  },
  {
    row: 17,
    Region: "Saudi Arabia",
    Hours: 65,
  },
];

const displayedPaginationRows = (from, to, count, intl) => {
  return `${from}–${to} ${CheckValueLocale("pagination_of", "", {}, intl)} ${
    count !== -1 ? count : `more than ${to}`
  }`;
};

const dialectstData = [
  { value: 186, name: "Khaleji" },
  { value: 63, name: "Saudi" },
  { value: 121, name: "Shami" },
  { value: 99, name: "Modern Arabic" },
  { value: 79, name: "Egyptian" },
  { value: 80, name: "Iraqi" },
  { value: 70, name: "Maghrebi" },
];

const HashtagDetails = () => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tableBody, setTableBody] = useState([]);

  const [hashtagData, setHashtagData] = useState({});
  const [period, setPeriod] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [hashtagAnalyzeCluster, setHashtagAnalyzeCluster] = useState({});
  const [hashtagAnalyzeData, setHashtagAnalyzeData] = useState({});
  const paginationFrom = page > 0 ? rowsPerPage * page + 1 : 1;
  const rowsPageCal = rowsPerPage * page + rowsPerPage;
  const paginationTo =
    page > 0
      ? rowsPageCal > hashtagAnalyzeData?.trending_per_region.length
        ? hashtagAnalyzeData?.trending_per_region.length
        : rowsPageCal
      : rowsPerPage;

  const periodAT = CheckValueLocale("hashtag_period_at", "", {}, intl);

  const periodGMT = CheckValueLocale("hashtag_period_gmt", "", {}, intl);

  const periodAM = CheckValueLocale("hashtag_period_am", "", {}, intl);

  const periodPM = CheckValueLocale("hashtag_period_pm", "", {}, intl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getHashtagAnalyzeData = (keyword) => {
    setLoading(true);
    TopHashtagsController.getAnalyzeData(keyword).then((res) => {
      setLoading(false);
      res.data && setHashtagAnalyzeData(res.data);
      res.data.cluster && setHashtagAnalyzeCluster(res.data.cluster);
    });
  };

  const handleChangePeriod = (keyValue) => {
    const clusterData = hashtagAnalyzeCluster[keyValue];
    if (typeof clusterData !== "undefined") {
      setHashtagAnalyzeData(clusterData);
      setPeriod(keyValue);
    }
  };
  const handelResetPeriod = () => {
    setPeriod("");
    setLoading(false);
    getHashtagAnalyzeData(hashtagData.hashtag);
  };
  useEffect(() => {
    if (location.state) {
      if (location.state.detail) {
        setHashtagData({ ...location.state.detail });
        setLoading(false);
        location.state.detail?.hashtag &&
          getHashtagAnalyzeData(location.state.detail.hashtag);
      }
    }
  }, [location.state]);

  const tableHead = [
    {
      body: CheckValueLocale("rank", "", {}, intl),
    },
    {
      body: CheckValueLocale("region", "", {}, intl),
    },
    {
      body: CheckValueLocale("trending_hours", "", {}, intl),
    },
  ];

  useEffect(() => {
    const tabBody =
      hashtagAnalyzeData?.trending_per_region?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      )?.length == 0
        ? []
        : hashtagAnalyzeData?.trending_per_region
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((val, index) => {
              return {
                id: val?.woe_id,
                data: [
                  {
                    body: paginationFrom + index,
                    width: "15%",
                  },
                  {
                    body: val?.woe_id,
                  },
                  {
                    body: val?.hour_count,
                  },
                ],
              };
            });
    setTableBody(tabBody);
  }, [hashtagAnalyzeData, paginationFrom, intl?.locale]);

  return (
    <Container maxWidth="xl" className="view-hashtag-detail">
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <Box className="view-hashtag-header">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                onClick={() => {
                  history.replace({
                    pathname: "/top-trends",
                    state: {},
                  });
                }}
                className="top-hashtag-bread-crumb"
              >
                {CheckValueLocale("top_hashtags_title", "", {}, intl)}
              </Link>

              <Link
                color="inherit"
                onClick={() => {}}
                className="top-hashtag-detail-bread-crumb"
              >
                {CheckValueLocale("hashtag_details", "", {}, intl)}
              </Link>
            </Breadcrumbs>
            <Box>
              <Box>
                <Typography variant="h6" className="hashtag-title">
                  {hashtagData.hashtag}
                </Typography>
                <Divider orientation="vertical" />
                <Box className="hashtags-view-container">
                  <a
                    href={hashtagData.url}
                    target="_blank"
                    rel="noreferrer"
                    className="twitter-link"
                  >
                    {CheckValueLocale("view_in_twitter", "", {}, intl)}
                  </a>
                  <FontAwesomeIcon
                    className="twitter-link-icon"
                    icon={faExternalLink}
                  />
                </Box>
              </Box>
              <Typography
                variant="body1"
                onClick={() => {
                  history.replace({
                    pathname: "/top-trends",
                    state: {},
                  });
                }}
              >
                {CheckValueLocale("back_to_hashtags_list", "", {}, intl)}
                <ArrowForwardIosIcon />
              </Typography>
            </Box>
            <Divider />
          </Box>
          <Box className="view-hashtag-filter">
            <Card>
              <CardContent>
                <Typography>
                  <FormattedMessage
                    id="view_message"
                    values={{
                      startDateTime: <b>{hashtagAnalyzeData.start_date}</b>,
                      startLocation: <b>{hashtagAnalyzeData.start_region}</b>,
                      lastDateTime: <b>{hashtagAnalyzeData.end_date}</b>,
                      lastLocation: <b>{hashtagAnalyzeData.end_region}</b>,
                      tweetsCount: (
                        <b>
                          {intl.formatNumber(hashtagAnalyzeData.total_tweets)}
                        </b>
                      ),
                    }}
                  />
                </Typography>
                <Box className="filter-box">
                  <Box className="filter-field">
                    <FormControl fullWidth>
                      <InputLabel id="period-select-label">
                        {CheckValueLocale("view_select_period", "", {}, intl)}
                      </InputLabel>
                      <Select
                        labelId="period-select-label"
                        id="period-select"
                        value={period}
                        onChange={(e) => handleChangePeriod(e.target.value)}
                      >
                        {hashtagAnalyzeCluster &&
                          Object.keys(hashtagAnalyzeCluster).map((val) => {
                            let start_date =
                              hashtagAnalyzeCluster[val].start_date;
                            if (start_date) {
                              start_date =
                                moment
                                  .utc(start_date, "YY-MM-DD hh:mm")
                                  .format("DD/MM/YYYY - hh:mma ")
                                  .replace("-", `${periodAT}`)
                                  .replace("am", `${periodAM}`)
                                  .replace("pm", `${periodPM}`) + periodGMT;
                              return (
                                <MenuItem
                                  className="hashtag-detail-period-menu-item"
                                  key={`period${val}`}
                                  value={val}
                                  id={`hashtag-detail-period-${
                                    val ?? "start-date-value"
                                  }`}
                                >
                                  {start_date}
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      className="reset-btn"
                      onClick={handelResetPeriod}
                      id="top-hashtags-view-reset-btn"
                    >
                      {CheckValueLocale("view_Reset", "", {}, intl)}
                    </Button>
                  </Box>
                  <Button
                    className="create-monitor-btn"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      window.location.href = `/social/monitors/create_monitor`;
                    }}
                    id="top-hashtags-view-create-monitor-btn"
                  >
                    <FormattedMessage
                      id="view_create_monitor"
                      values={{ name: `${hashtagData.hashtag}` }}
                    />
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ flexGrow: 1, pt: "20px" }}>
            <Grid container spacing={2.5} className="view-hashtag-data">
              <Grid item xs={6}>
                <Card className="card-graph">
                  <Typography variant="h6">
                    {CheckValueLocale("hashtag_volume", "", {}, intl)}
                  </Typography>
                  {hashtagAnalyzeData?.volume_over_time && (
                    <LineChart
                      zoomPercentage={0}
                      xAxisData={hashtagAnalyzeData?.volume_over_time.map(
                        (val) => val.created_at,
                      )}
                      seriesData={hashtagAnalyzeData?.volume_over_time.map(
                        (val) => val.tweet_volume,
                      )}
                      haveData={true}
                      toolTipLabel={"hashtag_volume"}
                      showDataZoom={true}
                      height={"360px"}
                      color="#456EB2 "
                    />
                  )}
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className="top-hashtags-cards card-table">
                  <Typography variant="h6">
                    {CheckValueLocale(
                      "trending_duration_per_region",
                      "",
                      {},
                      intl,
                    )}
                  </Typography>
                  {hashtagAnalyzeData?.trending_per_region && (
                    <>
                      <SharedTable
                        tableMinWidth="400px"
                        tableHeader={tableHead}
                        tableBody={tableBody}
                        isSharedStyle
                        isHashTable
                      />

                      <TablePagination
                        rowsPerPageOptions={[4, 8, 16]}
                        nextIconButtonProps={
                          paginationTo ==
                          hashtagAnalyzeData?.trending_per_region?.length
                            ? {
                                disabled: true,
                              }
                            : undefined
                        }
                        component="div"
                        count={hashtagAnalyzeData.trending_per_region.length}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={""}
                        labelDisplayedRows={() =>
                          displayedPaginationRows(
                            paginationFrom,
                            paginationTo,
                            hashtagAnalyzeData.trending_per_region.length,
                            intl,
                          )
                        }
                      />
                    </>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Container>
  );
};
export default HashtagDetails;
