import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
import { Typography, Box } from "@mui/material";
import "../../../monitors.scss";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import SummaryWidget from "shared/summaryWidget/summaryWidget";

/*---------------------------------------------------------*/

const EngagementsBody = (props) => {
  const intl = useIntl();
  const { response, preLoaderTrackerDataSources, resetAllFields } = props;
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );
  // ___________________________________________________________
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];
  // ___________________________________________________________

  const instagramIcon = (
    <span className="topics-twitter-icon">
      <img src={publicInstagram} className="icon-after-title instagram" />
    </span>
  );

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (
      response.eventName === "Instagram__EngagementsPage__posts_interactions"
    ) {
      setCommentsInteractions(
        response?.eventData?.posts_interactions?.posts_interactions_over_time,
      );
      setCommentsInteractionsLegend(
        response?.eventData?.posts_interactions?.posts_interactions_legends,
      );

      setTotalValuePostsInteractions(
        response?.eventData?.posts_interactions?.total_interactions,
      );

      setMaxValuePostsInteractions(
        response?.eventData?.posts_interactions?.max_interactions,
      );
      let date = handleUnixFormat(
        response?.eventData?.posts_interactions?.max_interaction_date,
      );
      setMaxValueInteractionsDate(date);

      setAvgValuePostsInteractions(
        response?.eventData?.posts_interactions?.average_interactions,
      );

      setCommentsInteractionsPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  //_____________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([{ commentsInteractions: commentsInteractions }]);
  }, [commentsInteractions]);
  //_____________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setCommentsInteractions([]);
      setCommentsInteractionsPreLoader([]);
      setCommentsInteractionsLegend([]);

      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setAvgValuePostsInteractions(0);
    }
  }, [resetAllFields]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(commentsInteractionsPreLoader);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(commentsInteractions);
  }, [commentsInteractionsPreLoader, commentsInteractions]);

  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <>
        <Typography className="section-name">
          {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
        </Typography>
        <Box mt={3}>
          <StackedBarWidget
            title={"posts_interactions"}
            titleToolTip={"posts_interactions_ig_tooltip"}
            showDownloadIcon
            bigImg
            posts={commentsInteractions}
            colors={["#2AAAE2", "#80868C"]}
            labels={["comments", "likes"]}
            showZoomPercentage
            showScales
            iconAfterTitle={instagramIcon}
            postsColumnPreLoaderDataSources={commentsInteractionsPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            hideLegend
            dataPiechart={commentsInteractionsLegend}
            showChartDetailsWidget
            statsDetailsData={interactionsStats}
            isInteractionStatsWrapperStyle
          />
        </Box>
      </>
    </MonitorContainer>
  );
};

export default EngagementsBody;
