//this widget already used for MONITOR only , as it has one case for punchCard
import StackedHorizontalBar from "../echarts/StackedHorizontalBar.js";
import WidgetHeader from "components/widgets/widgetHeader";
import _ from "lodash";
import { Box } from "@mui/material";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index.js";

const StackedHorizontalBarWidget = (props) => {
  const intl = useIntl();
  const [dataPieChartValue, setDataPieChartValue] = useState([]);
  const [seriesData, setSeriesData] = useState({});
  let totalSentiment = props.dataPiechart?.reduce(function (prev, current) {
    return prev + +current.value;
  }, 0);
  const handleDataChartValue = (e, value) => {
    // This Will work only in Sentiment chart
    // Hide/Display sentiment in Pie chart
    const arr = [...dataPieChartValue];
    const arrIndex = arr.indexOf(
      CheckValueLocale(value.toLowerCase(), "", {}, intl),
    );
    if (arrIndex === -1) {
      arr.push(CheckValueLocale(value.toLowerCase(), "", {}, intl));
    } else {
      arr.splice(arrIndex, 1);
    }
    setDataPieChartValue(arr);

    // Trackers for sentiment if clicked or not and its index
    var sentiment_clicked, index_of_sentiment;
    var new_series_data = seriesData.series_data;
    seriesData.series_data.map((sentiment_obj, index) => {
      // Sentiment clicked to be removed
      if (sentiment_obj?.name === value) {
        sentiment_clicked = sentiment_obj;
        index_of_sentiment = index;
      }
    });
    // In case of the data is found and we want to remove it
    if (sentiment_clicked) {
      new_series_data.splice(index_of_sentiment, 1);
      seriesData.series_data = new_series_data;
    } else {
      // here we return data to be displayed after hide
      props.data.series_data.map((sentiment_obj, index) => {
        // Sentiment clicked to be added
        if (sentiment_obj?.name === value) {
          sentiment_clicked = sentiment_obj;
          index_of_sentiment = index;
        }
      });
      if (sentiment_clicked) {
        new_series_data.splice(index_of_sentiment, 0, sentiment_clicked);
        seriesData.series_data = new_series_data;
      }
    }
  };

  useEffect(() => {
    setSeriesData(_.cloneDeep(props.data));
    setDataPieChartValue([]);
  }, [props.data]);

  return (
    <div
      className={
        props.increaseHeight ? "horizontal-bar-widget" : "horizontal-bar-chart"
      }
      id={props.title}
    >
      <WidgetHeader
        title={props.title}
        titleToolTip={props.titleToolTip}
        showDownloadIcon={props.showDownloadIcon}
        dataPiechart={props?.dataPiechart}
        labelColors={props?.labelColors}
        hideLegend={props.hideLegend}
        totalSentiment={totalSentiment}
        chartId={props.title}
        bigImg={props.bigImg}
        iconAfterTitle={props.iconAfterTitle}
        showAI={props.showAI}
        handleDataChartValue={handleDataChartValue}
        dataPieChartValue={dataPieChartValue}
      />
      {_.isEqual(
        props.stackedHorizontalChartPreLoaderDataSources.sort(),
        props.preLoaderTrackerDataSources.sort(),
      ) !== true ? (
        <Box sx={{ position: "relative", top: "45%", right: 0 }}>
          <Box>
            <FetchingData />
          </Box>
        </Box>
      ) : !(
          Array.isArray(props?.data?.series_data) &&
          Array.isArray(props?.data?.y_axis_data) &&
          props?.data?.series_data.length !== 0 &&
          props?.data?.y_axis_data.length !== 0
        ) ? (
        <Box className="no-data-chart">
          <NoDataFound />
        </Box>
      ) : (
        <StackedHorizontalBar
          data={seriesData}
          isSentimentAnalysis={props?.isSentimentAnalysis}
          all_topics_colors={props.all_topics_colors}
          all_sentiment_values={props?.all_sentiment_values}
          legendPosition={window.localStorage.lang === "en" ? "left" : "right"}
          hideLegend={props?.hideLegend}
          increaseHeight={props.increaseHeight}
        />
      )}
    </div>
  );
};

export default StackedHorizontalBarWidget;
