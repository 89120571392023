import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channelAdded: false,
  channelFailed: false,
  channelFailedMsg: "",
  isSnackBarOpen: false,
};

const manageChannels = createSlice({
  name: "manageChannels",
  initialState,
  reducers: {
    setChannelAdded: (state, action) => {
      state.channelAdded = action.payload;
      state.isSnackBarOpen = true;
    },
    setChannelFailed: (state, action) => {
      state.channelFailed = true;
      state.channelFailedMsg = action.payload;
      state.isSnackBarOpen = true;
    },
    resetSnackbar: (state) => {
      state.channelAdded = false;
      state.channelFailed = false;
      state.channelFailedMsg = "";
      state.isSnackBarOpen = false;
    },
  },
});

export const { setChannelAdded, setChannelFailed, resetSnackbar } =
  manageChannels.actions;

export default manageChannels.reducer;
