import Monitors from "../api/monitors.js";

var MonitorsController = {};

MonitorsController.monitors = (
  product_id,
  search,
  page,
  sort_by,
  order_by,
  redirected_monitor,
) => {
  var queryData = {
    product_id,
    search,
    page,
    sort_by,
    order_by,
    redirected_monitor,
  };

  return new Promise(function (resolve) {
    Monitors.monitors(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
          pagination: response?.data?.pagination,
          companyCanCreateMonitor: response?.data?.company_can_create_monitor,
          companyCanCreateChannel: response?.data?.company_can_create_channel,
          interactionsLimitReach: response?.data?.interactions_limit_reach,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Manage Channels Page (Get Channels List)
MonitorsController.channels = (
  product_id,
  search,
  page,
  sort_by,
  order_by,
  redirected_monitor,
) => {
  var queryData = {
    product_id,
    search_account_name: search,
    page,
    sort_by,
    order_by,
    redirected_monitor,
  };

  return new Promise(function (resolve) {
    Monitors.monitors(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
          pagination: response?.data?.pagination,
          companyCanCreateMonitor: response?.data?.company_can_create_monitor,
          companyCanCreateChannel: response?.data?.company_can_create_channel,
          interactionsLimitReach: response?.data?.interactions_limit_reach,
        });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

MonitorsController.getAllMonitors = (product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Monitors.getAllMonitors(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getCompanyList = (queryData) => {
  return new Promise(function (resolve) {
    Monitors.getCompanyList(queryData)
      .then((response) => {
        resolve({
          data: response.data.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.assignUserToCompany = (queryData) => {
  return new Promise(function (resolve) {
    Monitors.assignUserToCompany(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.deleteMonitor = (monitorId) => {
  return new Promise(function (resolve) {
    Monitors.deleteMonitor(monitorId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.resumeOrPauseMonitors = (monitorId) => {
  return new Promise(function (resolve) {
    Monitors.resumeOrPauseMonitors("", monitorId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.createNewMonitor = (
  monitor_model,
  monitor_options,
  data_sources,
  customer_care_accounts,
) => {
  const queryData = {
    monitor_model,
    monitor_options,
    data_sources,
    customer_care_accounts,
  };

  return new Promise(function (resolve) {
    Monitors.createNewMonitor(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
MonitorsController.getAuthorizedMonitorType = (product_id) => {
  return new Promise(function (resolve) {
    Monitors.getAuthorizedMonitorType("", product_id)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getSocialAccounts = (id) => {
  var queryData = { id };
  return new Promise(function (resolve) {
    Monitors.getSocialAccounts(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
          included: response.data.included,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getUserSocialAccounts = (id) => {
  var queryData = { id };
  return new Promise(function (resolve) {
    Monitors.getUserSocialAccounts(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
          included: response.data.included,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getCompanySources = (id, company_accounts) => {
  var queryData = { product_id: id, company_accounts };
  return new Promise(function (resolve) {
    Monitors.getCompanySources(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.testKeywords = (keywords, spam_keywords, testText) => {
  var queryData = {
    keywords: keywords,
    spam_keywords: spam_keywords,
    text: testText,
  };

  return new Promise(function (resolve, reject) {
    Monitors.testKeywords(queryData)
      .then(function (response) {
        resolve({
          data: response.data.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

MonitorsController.getSocialMediaMonitorData = (
  monitorDataSource, //sent to connect with correct API dynamically based on monitor data source
  monitor_id,
  product_id,
  section_name,
  start_date,
  end_date,
  filters,
  page_number,
) => {
  var queryData = {
    product_id,
    section_name,
    start_date,
    end_date,
    filters: JSON.stringify(filters),
    page_number,
  };

  return new Promise(function (resolve, reject) {
    Monitors.getSocialMediaMonitorData(monitorDataSource, monitor_id, queryData)
      .then(function (response) {
        resolve({
          data: response.data.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

//get deppInsight Profile
MonitorsController.getDeepInsightData = (
  monitorDataSource,
  monitor_id,
  product_id,
  username,
  user_account_id,
  cap_count, // count of [ usage from quota ] when user call deep insights
  startDate,
  endDate,
) => {
  var queryData = {
    product_id,
    username,
    user_account_id,
    cap_count,
    start_date: startDate,
    end_date: endDate,
  };

  return new Promise(function (resolve, reject) {
    Monitors.getDeepInsightData(monitorDataSource, monitor_id, queryData)
      .then(function (response) {
        resolve({
          data: response,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error?.response?.data?.error?.detail,
          status: error?.response?.status,
        });
      });
  });
};

//get author Profile
MonitorsController.getAuthorProfileData = (
  monitorDataSource,
  monitor_id,
  product_id,
  section_name,
  page_number,
  tweet_id,
  account_user_id,
) => {
  var queryData = {
    product_id,
    section_name,
    page_number,
    tweet_id,
    account_user_id,
  };

  return new Promise(function (resolve, reject) {
    Monitors.getAuthorProfileData(monitorDataSource, monitor_id, queryData)
      .then(function (response) {
        resolve({
          data: response.data.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

MonitorsController.getMonitorInfo = (monitor_id, product_id) => {
  var queryData = {
    product_id,
  };
  return new Promise(function (resolve, reject) {
    Monitors.getMonitorInfo(queryData, monitor_id)
      .then(function (response) {
        resolve({
          data: response.data.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

MonitorsController.getQuickInspectData = (
  monitorDataSource, //sent to connect with correct API dynamically based on monitor data source
  monitor_id,
  product_id,
  start_date,
  end_date,
  inspector_name,
  inspector_value,
  sentiment_value,
  filters,
) => {
  var queryData = {
    monitor_id,
    product_id,
    start_date,
    end_date,
    inspector_name,
    inspector_value,
    sentiment_value,
    filters: JSON.stringify(filters),
  };

  return new Promise(function (resolve, reject) {
    Monitors.getQuickInspectData(monitorDataSource, monitor_id, queryData)
      .then(function (response) {
        resolve({
          data: response.data.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

MonitorsController.editMonitorInfo = (monitor_id, updatedParams) => {
  return new Promise(function (resolve) {
    Monitors.editMonitorInfo(updatedParams, monitor_id)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getEditConfigData = (monitorId, dm_Configuration_Id) => {
  return new Promise(function (resolve) {
    Monitors.getEditConfigData(monitorId, dm_Configuration_Id)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.putEditConfigData = (
  monitorId,
  dm_Configuration_Id,
  queryData,
) => {
  return new Promise(function (resolve) {
    Monitors.putEditConfigData(monitorId, dm_Configuration_Id, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.editSentimentInteractions = (
  monitorDataSource,
  product_id,
  id,
  monitor_id,
  new_label,
  old_label,
) => {
  var queryData = {
    product_id,
    id,
    monitor_id,
    new_label,
    old_label,
  };
  return new Promise(function (resolve) {
    Monitors.editSentimentInteractions(monitorDataSource, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.postSpamTweets = (monitor_id, product_id, questionBody) => {
  var queryData = {
    tweet_data: questionBody,
  };

  return new Promise(function (resolve) {
    Monitors.postSpamTweets(
      queryData,
      monitor_id,
      product_id,
      questionBody.tweet_id,
    )
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.markSpamPost = (monitor_id, data_source, product_id, id) => {
  var queryData = { monitor_id, data_source, product_id };
  return new Promise(function (resolve) {
    Monitors.markSpamPost(queryData, id)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

MonitorsController.undoSpamPost = (monitor_id, data_source, product_id, id) => {
  var queryData = { monitor_id, data_source, product_id };
  return new Promise(function (resolve) {
    Monitors.undoSpamPost(queryData, id)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};

MonitorsController.getMoreInsights = (
  monitorDataSource,
  monitor_id,
  post_id,
  product_id,
) => {
  var queryData = {
    monitor_id,
    product_id,
    post_id,
  };

  return new Promise(function (resolve) {
    Monitors.getMoreInsights(monitorDataSource, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getSocialMediaPPT = (
  monitorDataSource, //sent to connect with correct API dynamically based on monitor data source
  monitor_id,
  product_id,
  section_name,
  start_date,
  end_date,
  filters,
  page_number,
  language,
  xls,
) => {
  var queryData = {
    product_id,
    section_name,
    start_date,
    end_date,
    filters: JSON.stringify(filters),
    page_number,
    language,
    export: xls,
  };

  return new Promise(function (resolve, reject) {
    Monitors.getSocialMediaPPT(monitorDataSource, monitor_id, queryData)
      .then(function (response) {
        resolve({
          data: response,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

MonitorsController.getExportExcel = (queryData) => {
  return new Promise(function (resolve, reject) {
    Monitors.getExportExcel(queryData)
      .then(function (response) {
        resolve({
          data: response,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

MonitorsController.getSLExportXLSX = (queryData) => {
  return new Promise(function (resolve, reject) {
    Monitors.getSLExportXLSX({
      ...queryData,
      filters: JSON.stringify(queryData?.filters),
    })
      .then(function (response) {
        resolve({
          data: response,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

// Monitor Topics controllers

MonitorsController.getTopicsMonitors = (queryData) => {
  return new Promise(function (resolve) {
    Monitors.getTopicsMonitors(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getTopicsForMonitor = (monitor_id) => {
  return new Promise(function (resolve, reject) {
    Monitors.getTopicsForMonitor(monitor_id)
      .then(function (response) {
        resolve({
          data: response.data.data,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

MonitorsController.getTopicsUsage = (queryData) => {
  return new Promise(function (resolve) {
    Monitors.getTopicsUsage(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.deleteTopicsFromMonitor = (monitorId, queryData) => {
  return new Promise(function (resolve) {
    Monitors.deleteTopicsFromMonitor(monitorId, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getChannelsUsage = (queryData) => {
  return new Promise(function (resolve) {
    Monitors.getChannelUsage(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getAllAccounts = (product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Monitors.getAllAccounts(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getAllawedAccounts = (product_id, engagement_type) => {
  let queryData = {
    product_id,
    engagement_type,
  };
  return new Promise(function (resolve) {
    Monitors.getAllawedAccounts(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getAllawedMonitors = (product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Monitors.getAllawedMonitors(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.postReplies = (
  product_id,
  account_info_id,
  publisher_name,
  data_source_name,
  monitor_id,
  parent_id,
  item_type,
  text,
  recipient_id,
  attachments,
) => {
  let queryData = {
    product_id,
    account_info_id,
    publisher_name,
    data_source_name,
    monitor_id,
    parent_id,
    item_type,
    text,
    recipient_id,
  };
  if (attachments?.length > 0) {
    queryData = {
      ...queryData,
      attachments,
    };
  }
  return new Promise(function (resolve) {
    Monitors.postReplies(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getAllSavedReplies = (product_id, page) => {
  let queryData = {
    product_id,
    page,
  };
  return new Promise(function (resolve) {
    Monitors.getAllSavedReplies(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.createSavedReplies = (product_id, title, text) => {
  var queryData = {
    product_id,
    title,
    text,
  };
  return new Promise(function (resolve) {
    Monitors.createSavedReplies(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
        });
      });
  });
};

MonitorsController.deleteSavedReplies = (id, product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Monitors.deleteSavedReplies(id, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//get Export PPT Pages in SL // export PPT file with all pages
MonitorsController.getExportPages = (
  product_id,
  data_source_id,
  monitor_id,
) => {
  var queryData = {
    product_id,
    data_source_id,
    monitor_id,
  };

  return new Promise(function (resolve, reject) {
    Monitors.getExportPages(queryData)
      .then(function (response) {
        resolve({
          data: response,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error?.status,
        });
      });
  });
};

MonitorsController.exportPPTAnalyticsPage = (
  product_id,
  data_source_id,
  monitor_id,
  lang,
  start_date,
  end_date,
) => {
  var queryData = {
    product_id,
    data_source_id,
    monitor_id,
    lang,
    start_date,
    end_date,
  };

  return new Promise(function (resolve, reject) {
    Monitors.exportPPTAnalyticsPage(queryData)
      .then(function (response) {
        resolve({
          data: response,
        });
      })
      .catch(function (error) {
        resolve({
          errorMsg: error,
          status: error?.status,
        });
      });
  });
};

MonitorsController.getTwitterSources = (queryData) => {
  return new Promise(function (resolve) {
    Monitors.getTwitterSources(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
          status: error?.status,
        });
      });
  });
};

MonitorsController.getKeywordsLimit = () => {
  return new Promise(function (resolve) {
    Monitors.getKeywordsLimit()
      .then(function (response) {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.getHashtagsLimit = (queryData) => {
  return new Promise(function (resolve) {
    Monitors.getHashtagsLimit(queryData)
      .then(function (response) {
        resolve({
          data: response?.data,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

MonitorsController.exportExcelLogExport = (monitor_id) => {
  return new Promise(function (resolve) {
    Monitors.exportExcelLogExport(monitor_id)
      .then(function (response) {
        resolve({
          data: response,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default MonitorsController;
