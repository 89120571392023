import { useState } from "react";
import { useIntl } from "react-intl";
import "./list.scss";
import {
  Typography,
  Card,
  Button,
  Select,
  Box,
  MenuItem,
  Checkbox,
  OutlinedInput,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";
import PageHeader from "components/pageHeader.js";

import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import { CheckValueLocale } from "utils/helpers";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 268,
    },
  },
};
const notificationTypes = [
  {
    key: "single_tweet_analysis",
    value: "notification_single_tweet_analysis",
  },
  {
    key: "nb_report",
    value: "notification_nb_report",
  },
  {
    key: "New Post",
    value: "notification_new_post",
  },
  {
    key: "High Volume Reach",
    value: "notification_high_volume_reach",
  },
  {
    key: "Viral Tweet",
    value: "notification_viral_tweet",
  },
  {
    key: "Influencer",
    value: "notification_influencer",
  },
  {
    key: "Verified Author",
    value: "notification_virified_author",
  },
  {
    key: "Negative Posts",
    value: "notification_negative_posts",
  },
  {
    key: "Trending",
    value: "notification_trending",
  },
  {
    key: "excel_export",
    value: "notification_excel_export",
  },
  {
    key: "published_survey",
    value: "notification_published_survey",
  },
  {
    key: "Completed Conversation",
    value: "notification_completed_conversation",
  },
];

const ListHeader = ({
  handelFilters,
  filterData,
  oldDate,
  notificationType,
  setNotificationType,
}) => {
  const intl = useIntl();

  const [startDate, setStartDate] = useState(filterData.startDate);
  const [endDate, setEndDate] = useState(filterData.endDate);

  const applyDatepickerFilter = (startDate, endDate, filterParams) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const applyFilter = () => {
    handelFilters({ notificationType, startDate, endDate });
  };

  return (
    <>
      <PageHeader title="notifications_center" />
      <Card className="filter-card">
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="notificationType-multiple-name-label">
              {CheckValueLocale("notification_type", "", {}, intl)}
            </InputLabel>
            <Select
              className="notificationType-multiple-name"
              labelId="notificationType-multiple-name-label"
              id="notificationType-multiple-name"
              multiple
              value={notificationType}
              onChange={(e) => setNotificationType(e.target.value)}
              input={
                <OutlinedInput
                  label={CheckValueLocale("notification_type", "", {}, intl)}
                />
              }
              renderValue={(selected) => {
                return notificationTypes
                  .filter((val) => selected.includes(val.key))
                  .map((val) => CheckValueLocale(val.value, "", {}, intl))
                  .join(",");
              }}
              MenuProps={MenuProps}
            >
              {notificationTypes.map((val) => (
                <MenuItem
                  key={`notificationTypes-${val.key}`}
                  value={val.key}
                  className="notifications-multi-menuItem"
                  id={`notification-dropdown-item-${
                    val?.value ?? "notification-type"
                  }`}
                >
                  <Checkbox checked={notificationType.indexOf(val.key) > -1} />

                  <ListItemText
                    primary={CheckValueLocale(val.value, "", {}, intl)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <DateRangePicker
              applyFilter={applyDatepickerFilter}
              startDate={startDate}
              endDate={endDate}
              oldestDate={oldDate}
              openCustomDateRight={true}
              datePickerPosition="notifications-datepicker-position"
              id="date-range-picker-notifications-center"
              monitorActiveTabId="notification"
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          className="filter-results-btn"
          onClick={applyFilter}
          id="notification-filter-results-btn"
        >
          {CheckValueLocale("filter_notification", "", {}, intl)}
        </Button>
      </Card>
    </>
  );
};
export default ListHeader;
