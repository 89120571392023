import { useEffect, useState } from "react";
import Header from "../../../Components/header";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
import CommentsMentionsBody from "./commentsMentionsBody";
import Luci from "pages/luci";
import { useSelector } from "react-redux";
import SummaryWidget from "shared/summaryWidget/summaryWidget";
/*---------------------------------------------------------*/

const KeywordFBCommentsMentionsPage = (props) => {
  const {
    monitorId,
    assignActiveFilters,
    pageNumber,
    startDate,
    endDate,
    activeTab,
    dataSourceId,
    monitorData,
    getSummaryStaticQuestions,
    summaryStaticQuestions,
    summaryStaticQuestionsLoading,
  } = props;

  const [openLuci, setOpenLuci] = useState(false); //Firebase State
  const [mainQuestionNotification, setMainQuestionNotification] = useState({
    title: "",
    body: "",
    status: "",
    questionId: "",
    sectionName: "",
    monitorId: "",
    summaryWidget: false,
  });
  //  Start Redux Declarations
  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );
  //  End Redux Declarations

  const [sortBy, setSortBy] = useState("newest");
  const sortByData = ["newest", "most_interactions"];
  const [totalPage, setTotalPage] = useState("");

  const handleChange = (event) => {
    setSortBy(event.target.value);
    props.setFilterParams({
      ...props.filterParams,
      sort_by: event.target.value,
    });
    props.applyFilter(props.startDate, props.endDate, {
      ...props.filterParams,
      sort_by: event.target.value,
    });
  };
  useEffect(() => {
    if (props.filterParams["sort_by"] !== undefined) {
      setSortBy(props.filterParams["sort_by"]);
    } else {
      setSortBy("newest");
    }
  }, [props.filterParams["sort_by"]]);
  const showSummaryWidget =
    summaryStaticQuestions?.questions?.length &&
    monitorData?.attributes?.auto_summary_widget &&
    monitorData?.auto_summary_package;
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {showSummaryWidget ? (
        <SummaryWidget
          pageParams={{
            monitorId: monitorId,
            filterParams: assignActiveFilters,
            startDate: reduxFilterStartDateUX,
            endDate: reduxFilterEndDateUX,
            sectionName: activeTab,
            productId: window.localStorage.sm_id,
            luciFeature: "Keywords",
            dataSourceId: dataSourceId,
            dataLoaded: props.checkLuciAllDataExist,
            dataEmpty: props.checkLuciDataIsEmpty,
            monitorluciSummaryTokens:
              monitorData?.attributes?.luci_summary_tokens,
          }}
          setOpenLuci={setOpenLuci}
          getSummaryStaticQuestions={getSummaryStaticQuestions}
          summaryStaticQuestions={summaryStaticQuestions}
          summaryStaticQuestionsLoading={summaryStaticQuestionsLoading}
          mainQuestionNotification={mainQuestionNotification}
        />
      ) : null}
      {props.response?.eventData !== undefined && (
        <Header
          title="total_posts"
          titleValue={totalPage}
          sortByData={sortByData}
          sortBy={sortBy}
          handleChange={handleChange}
        />
      )}

      <CommentsMentionsBody
        {...props}
        setTotalPage={setTotalPage}
        totalPage={totalPage}
      />
      <Luci
        isInactive={false}
        pageParams={{
          monitorId: monitorId,
          filterParams: assignActiveFilters,
          pageNumber: pageNumber,
          startDate: reduxFilterStartDateUX,
          endDate: reduxFilterEndDateUX,
          sectionName: activeTab,
          productId: window.localStorage.sm_id,
          luciFeature: "Keywords",
          dataSourceId: dataSourceId,
          monitorName: monitorData?.attributes?.account_name,
          monitorProduct: `Luci ${monitorData?.attributes?.data_category}`,
          dataLoaded: props.checkLuciAllDataExist,
          dataEmpty: props.checkLuciDataIsEmpty,
        }}
        openLuci={openLuci}
        setOpenLuci={setOpenLuci}
        mainQuestionNotification={mainQuestionNotification}
        setMainQuestionNotification={setMainQuestionNotification}
      />
    </MonitorContainer>
  );
};

export default KeywordFBCommentsMentionsPage;
