import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";

const EditReportButtons = ({ handelSaveChanges }) => {
  const intl = useIntl();
  const history = useHistory();
  return (
    <Box className="edit-report-footer">
      <LucButton
        variant="flat"
        type="secondary"
        onClick={() => {
          history.replace({
            pathname: "/reports/list",
            state: {},
          });
        }}
        id="report-edit-report-cancel-btn"
      >
        {CheckValueLocale("cancel", "", {}, intl)}
      </LucButton>
      <LucButton
        onClick={handelSaveChanges}
        id="report-edit-report-save-changes-btn"
      >
        {CheckValueLocale("save_changes", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default EditReportButtons;
