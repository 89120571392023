import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Pagination } from "@mui/material";
import { useSortableTable } from "hooks/useSortableTable";
import { handlelUserRoles } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import CompaniesController from "services/controllers/companiesController";
import UsersAccountsData, {
  getUsersAccountsTableHeader,
} from "./usersAccountsData";

const UsersAccountsTab = () => {
  const intl = useIntl();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [pageNum, setPageNum] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [usersAccounts, setUsersAccounts] = useState([]);
  const [apps, setApps] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isNeedsAttentionData, setIsNeedsAttentionData] = useState(false);

  useEffect(() => {
    if (handlelUserRoles("GENERAL", "VIEW_OTHERS_AUTH")) {
      getCompanyApps();
    } else setIsLoading(false);
  }, []);

  const getCompanyApps = () => {
    CompaniesController.getCompanyApps().then((result) => {
      if (result?.data?.data) setApps(result?.data?.data);
    });
  };

  const getUsersAccounts = (pagination, createdBy, dataSource) => {
    setIsLoading(true);
    setIsNeedsAttentionData(false);
    CompaniesController.getUsersAuthorizations(
      sortColumn,
      sortDirection,
      pagination ? pagination : pageNum,
      searchText,
      createdBy,
      dataSource,
    ).then((result) => {
      if (!result.errorMsg) {
        setUsersAccounts(result?.data?.users_social_accounts);
        setPaginationData(result?.data?.pagination);
        // Set flag for empty response in case of Needs Attentin redirection
        if (
          history?.location?.needsAttentionData &&
          createdBy &&
          !result?.data?.users_social_accounts?.length
        ) {
          setIsNeedsAttentionData(true);
        }
      } else {
        setUsersAccounts([]);
        setPaginationData({});
      }
      setIsLoading(false);
      setIsSortableLoading(false);
    });
  };
  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPageNum(1);
  };

  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getUsersAccountsTableHeader(intl),
    onSortChangeCallback,
    defaultSelectedColumn: "configuration_date",
    defaultDirection: "desc",
  });

  useEffect(() => {
    if (
      history?.location?.needsAttentionData?.created_by_id &&
      handlelUserRoles("GENERAL", "VIEW_OTHERS_AUTH")
    ) {
      setSearchInput(
        history?.location?.needsAttentionData?.search_account_name,
      );
      getUsersAccounts(
        1,
        history?.location?.needsAttentionData?.created_by_id,
        history?.location?.needsAttentionData?.data_source,
      );
    } else {
      getUsersAccounts();
    }
  }, [pageNum, sortColumn, sortDirection, history?.location]);

  const handleChangePagination = (e, value) => setPageNum(value);

  return (
    <Box id="users-accounts-tab">
      {isLoading === true ? (
        <CircularLoading />
      ) : (
        <Box>
          <UsersAccountsData
            usersAccounts={usersAccounts}
            tableHead={tableHead}
            isSortableLoading={isSortableLoading}
            getUsersAccounts={getUsersAccounts}
            apps={apps}
            searchText={searchText}
            setSearchText={setSearchText}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            page={pageNum || 1}
            setPage={setPageNum}
            isNeedsAttentionData={isNeedsAttentionData}
          />
          {usersAccounts?.length && paginationData?.pages > 1 ? (
            <Pagination
              className="pagination-monitor-list"
              variant="outlined"
              count={paginationData?.pages}
              page={pageNum || 1}
              onChange={handleChangePagination}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default UsersAccountsTab;
