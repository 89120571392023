import { Button, Divider, Menu, MenuItem, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MonitorsController from "services/controllers/monitorsController";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "../../../Components/questionCard/questionCard.scss";
import {
  faAngry,
  faLaughBeam,
  faMeh,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";

const PostSentimentItem = (props) => {
  const { question, monitorDataSource, monitorId } = props;
  const intl = useIntl();
  const [selectedSentiment, setSelectedSentiment] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  var sentimentMenu = [
    {
      name: "positive",
      label: 1,
      icon: (
        <FontAwesomeIcon
          className={"smiliesStyle positive"}
          icon={faLaughBeam}
          style={{ color: "#8CC63F" }}
        />
      ),
    },
    {
      name: "neutral",
      label: 2,
      icon: (
        <FontAwesomeIcon
          className={"smiliesStyle neutral"}
          icon={faMeh}
          style={{ color: "#F9A700" }}
        />
      ),
    },
    {
      name: "negative",
      label: 0,
      icon: (
        <FontAwesomeIcon className={"smiliesStyle negative"} icon={faAngry} />
      ),
    },
  ];
  // handleEditSentiment
  useEffect(() => {
    handleSelectedSentiment(question?.sentiment);
  }, []);
  // __________________________________________________________________________

  const handleSelectedSentiment = (newLabel) => {
    sentimentMenu?.map((sentiment, i) => {
      if (newLabel === sentiment.label || newLabel === sentiment.name) {
        setSelectedSentiment(sentiment);
        handleClose();
      }
    });
  };
  // ~~~~~~~~~~~~~~~handleEditSentiment

  const handleEditSentiment = (tweetId, newLabel, oldLabel) => {
    var previousLabel =
      oldLabel === "positive"
        ? 1
        : oldLabel === "negative"
          ? 0
          : oldLabel === "neutral"
            ? 2
            : null;

    MonitorsController.editSentimentInteractions(
      monitorDataSource === "NEWSBLOGS" ? "nb" : monitorDataSource,
      window.localStorage.sm_id,
      tweetId,
      monitorId,
      newLabel,
      previousLabel,
    ).then((data) => {
      if (data.data.status === 200) {
        handleSelectedSentiment(newLabel);
      }
    });
  };
  return (
    <Box>
      <Divider
        orientation="vertical"
        sx={{ ml: 1 }}
        className="footer-divider"
      />

      <Tooltip
        title={CheckValueLocale("change_sentiment", "", {}, intl)}
        placement="top"
        arrow
      >
        <>
          <Button
            id="monitor-top-posts-sentiments-btn"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className={`sentiment-btn ${
              selectedSentiment.name ? selectedSentiment.name : "none"
            }`}
          >
            {selectedSentiment.icon ? (
              selectedSentiment.icon
            ) : (
              <HelpOutlineIcon
                className="smiliesStyle"
                style={{ color: "#64748B" }}
              />
            )}
            {selectedSentiment.name
              ? CheckValueLocale(selectedSentiment.name, "", {}, intl)
              : CheckValueLocale("none", "", {}, intl)}
            <ArrowDropDownIcon className="icon-angle" />
          </Button>
        </>
      </Tooltip>

      {/* Sentiment Drop Down Menu __________________________________________________________________________ */}
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "monitor-top-posts-sentiments-btn",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {sentimentMenu.map((sentiment, i) => {
          return (
            <MenuItem
              onClick={() =>
                handleEditSentiment(
                  monitorDataSource === "NEWSBLOGS"
                    ? question.id
                    : question.tweet_id,
                  sentiment.label,
                  question.sentiment,
                )
              }
              className={`${sentiment.name} ul-sentiment`}
              id={`monitors-question-posts-sentiment-${sentiment?.name ?? "sentiment-name"}-fa-icon`}
            >
              {sentiment.icon}
              {CheckValueLocale(sentiment.name, "", {}, intl)}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default PostSentimentItem;
