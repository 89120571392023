import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import WordCloudWidget from "components/widgets/wordCloudWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import OverView from "components/widgets/overViewWidget";
import { handleArraySum } from "../insightsCommonFunctions";
import moment from "moment";
import { CheckValueLocale } from "utils/helpers/index";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";

const EmailBody = (props) => {
  let { response } = props;
  const intl = useIntl();
  const [overviewArray, setOverviewArray] = useState([]);
  const [topKeywords, setTopkeywords] = useState([]);
  const [topKeywordsArray, setTopkeywordsArray] = useState([]);
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [interactionsArray, setInteractionsArray] = useState([]);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [interactions, setInteractions] = useState([]);
  const [summedInteractions, setSummedInteractions] = useState({});
  const [sentimentOvertime, setSentimentOvertime] = useState([]);
  const [sentimentPiechart, setSentimentPiechart] = useState([]);
  const [sentimentPieArray, setSentimentPieArray] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [overview, setOverview] = useState([]);
  const [netSentiment, setNetSentiment] = useState({
    negative: 0,
    positive: 0,
    neutral: 0,
  });
  // Values of DataSources totrack each widget preloader
  const [overViewPreLoaderDataSources, setOverViewPreLoaderDataSources] =
    useState([]);
  const [topKeywordsPreLoaderDataSources, setTopKeywordsPreLoaderDataSources] =
    useState([]);
  const [
    interactionsPreLoaderDataSources,
    setInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);
  const [
    sentimentAnalysisPreLoaderDataSources,
    setSentimentAnalysisPreLoaderDataSources,
  ] = useState([]);
  const [themeTrendPreLoaderDataSources, setThemeTrendPreLoaderDataSources] =
    useState([]);
  const [
    themeAnalysisPreLoaderDataSources,
    setThemeAnalysisPreLoaderDataSources,
  ] = useState([]);

  var sentimentPieResult = [],
    overviewResult = [],
    interactionsResult = [],
    interactionsArray2 = [],
    topKeywordsResult = [],
    interactions2 = [];

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    if (response?.eventName === "overview") {
      if (response?.eventData.overview) {
        Object.entries(response?.eventData.overview).map((i) => {
          if (i[0] === "net_sentiment") {
            i[1].map((sentiment) => {
              if (sentiment.name == "negative") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  negative: netSentiment["negative"] + sentiment.value,
                }));
              }
              if (sentiment.name == "positive") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  positive: netSentiment["positive"] + sentiment.value,
                }));
              }
              if (sentiment.name == "neutral") {
                setNetSentiment((netSentiment) => ({
                  ...netSentiment,
                  neutral: netSentiment["neutral"] + sentiment.value,
                }));
              }
            });
          } else {
            const condition = i[1] === "None" || i[1] === null || i[1] === 0;
            if (i[0] === "average_waiting_time" && condition) {
              overviewArray.push({ name: i[0], value: 0.0 });
            } else overviewArray.push({ name: i[0], value: i[1] });
          }
        });
        setOverViewPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
      }
    } else if (response?.eventName === "top_keywords") {
      response?.eventData.top_keywords?.map((i) => {
        return topKeywordsArray.push(i);
      });
      handleArraySum(topKeywordsArray, topKeywordsResult, setTopkeywords);
      setTopKeywordsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "latest_interactions") {
      response?.eventData.map((interaction) => {
        setLatestInteractions((oldArray) => [...oldArray, interaction]);
      });
      setLatestInteractionsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "interactions") {
      response?.eventData.interactions?.map((i) => {
        return interactionsArray.push(i);
      });
      interactionsArray.map((data) => [
        interactionsArray2.push({
          name: data.name,
          value: data.value,
        }),
      ]);
      handleArraySum(
        interactionsArray2,
        interactionsResult,
        setInteractions,
        true,
      );
      setInteractionsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "sentiment") {
      response?.eventData.sentiment_piechart.map((i) => {
        sentimentPieArray.push(i);
      });
      handleArraySum(
        sentimentPieArray,
        sentimentPieResult,
        setSentimentPiechart,
      );
      Object.entries(response?.eventData.sentiment_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentOvertime({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentAnalysisPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else if (response?.eventName === "themes") {
      setThemeAnalysis(response?.eventData.themes.theme_analysis);
      setThemeTrend(response?.eventData.themes.theme_trend);
      setThemeTrendPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
      setThemeAnalysisPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response?.data_source,
      ]);
    } else {
      return null;
    }
  }, [response]);

  const handleNetSentimnet = (netSentiment) => {
    let highest_sentiment = Object.keys(netSentiment).reduce(function (a, b) {
      return netSentiment[a] > netSentiment[b] ? a : b;
    });
    // Don't put any widget if it is equals to 0
    if (netSentiment[highest_sentiment] <= 0) {
      highest_sentiment = "none";
    }
    handleArraySum(
      overviewArray,
      overviewResult,
      setOverview,
      false,
      highest_sentiment,
    );
  };
  useEffect(() => {
    // Avoid Empty Array state
    let sum;
    let interactions_sum = 0;
    if (interactions.length > 0) {
      interactions.map((data) => {
        // Push data to interactions2
        interactions2.push({
          name: moment(data.name).unix(),
          value: data.value,
        });
        interactions_sum += data.value;
      });
      setSummedInteractions(interactions2);

      // Here we just need new Array of Refactor to be One Array
      setTotalInteractions(interactions_sum);
      //END TOTAL INTERACTIONS COUNT
    }
  }, [interactions]);

  useEffect(() => {
    handleNetSentimnet(netSentiment);
  }, [netSentiment]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setOverview([]);
      setOverviewArray([]);
      setTopkeywords([]);
      setTopkeywordsArray([]);
      setLatestInteractions([]);
      setSentimentOvertime([]);
      setSentimentPiechart([]);
      setSentimentPieArray([]);
      setThemeTrend([]);
      setThemeAnalysis([]);
      setTotalInteractions(0);
      setInteractionsArray([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);

      // Values of DataSources totrack each widget preloader
      setOverViewPreLoaderDataSources([]);
      setTopKeywordsPreLoaderDataSources([]);
      setInteractionsPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
      setSentimentAnalysisPreLoaderDataSources([]);
      setThemeTrendPreLoaderDataSources([]);
      setThemeAnalysisPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  return (
    <>
      <OverView
        data={overview}
        overViewPreLoaderDataSources={overViewPreLoaderDataSources}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />

      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale("metrics", "", {}, intl).toUpperCase()}
      </Typography>

      <LineChartWidget
        title={"emails"}
        totalLabel={"total_interactions"}
        totalValue={totalInteractions}
        data={interactions}
        showGroupBy={true}
        toolTipLabel={"total_interactions"}
        showDownloadIcon
        bigImg
        lineChartPreLoaderDataSources={interactionsPreLoaderDataSources}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />
      <Box my={3}>
        {/* <StackedLineWidget
          title={"sentiment_analysis"}
          data={sentimentOvertime}
          dataPiechart={sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        /> */}
      </Box>
      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        {/* <ThemesAnalysis
          title={"themes"}
          data={themeAnalysis}
          themeAnalysisPreLoaderDataSources={themeAnalysisPreLoaderDataSources}
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        /> */}
      </Box>
      <Box mt={3}>
        {/* <ThemesTrends
          title={"themes_trend"}
          data={themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={themeTrendPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        /> */}
      </Box>

      <Box my={3} className="topkewords_wrapper">
        <WordCloudWidget
          title={"top_keywords"}
          data={topKeywords}
          errorMessage={"no_keywords_found"}
          showDownloadIcon
          bigImg
          wordCloudPreLoaderDataSources={topKeywordsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={3}>
        <InteractionsList
          showProfile={true}
          data={latestInteractions.slice(0, 10)}
          title={"latest_interactions"}
          dataSources={props.dataSources}
          isGeneralInteractionPage
          latestInteractionsPreLoaderDataSources={
            latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showViewMoreButton={totalInteractions > 10}
        />
      </Box>
    </>
  );
};

export default EmailBody;
