import Services from "services/api/Services.js";
import _ from "lodash";
import { HmsToSeconds } from "utils/helpers";
import { isEmptyArray } from "utils/helpers";
const sendRabbitMQRequest = (
  mqttConnectionClosed,
  clientMQTT,
  startDate,
  endDate,
  getCredentials,
  getFiltersFileds,
) => {
  const script = document.createElement("script");
  script.src = "https://unpkg.com/paho-mqtt@1.0.4/paho-mqtt.js";
  document.head.appendChild(script);
  window.scrollTo(0, 0);

  getCredentials(startDate, endDate);
  getFiltersFileds();
  return () => {
    if (clientMQTT.current && mqttConnectionClosed.current === false) {
      clientMQTT.current.disconnect();
    }
    document.head.removeChild(script);
  };
};

const connectWithRabbitMQ = (
  mqttConnectionClosed,
  clientMQTT,
  data,
  setResponse,
) => {
  const { wsbroker, wsport } = Services;
  var client = new window.Paho.MQTT.Client(
    wsbroker,
    wsport,
    "/ws",
    data.queue_name,
  );
  // Disconnect From old MQTT
  if (clientMQTT.current) {
    clientMQTT.current.disconnect();
  }

  clientMQTT.current = client;

  client.onConnectionLost = function (responseObject) {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("CONNECTION LOST - " + responseObject.errorMessage);
    }
    if (responseObject.reconnect) {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log("Automatic reconnect is currently active.");
      }
    } else {
      //alert('Lost connection to host.')
      mqttConnectionClosed.current = true;
    }
  };

  client.onMessageArrived = (message) => {
    // Checking that the message Destination name equals to our current client id
    // This will be used to ensure that we are connected to the right client
    // In case of message arrived before Disconnect function of old client, we can ignore it easily
    // Here we ensure always that we will depend on that each clientId contains (frontend_) at the beginning
    // We slice after clientId and it must always start with (frontend_)
    if (clientMQTT.current.clientId.slice(9) == message.destinationName) {
      var data = JSON.parse(message.payloadString);
      // Temporary Solution to ignore Email at Insights to avoid Errors
      if (window.location.host !== "cxm.lucidya.com") {
        console.log(_.cloneDeep(data)); //[Commented as it still needed by team members]
      }
      setResponse(data);
    }
  };
  var options = {
    timeout: 120,
    keepAliveInterval: 10,
    reconnect: false, // Enable automatic reconnect
    useSSL: true,
    onSuccess: () => {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log("CONNECTION SUCCESS");
      }
      client.subscribe(data.routing_key, { qos: 1 });
    },
    onFailure: (message) => {
      if (window.location.host !== "cxm.lucidya.com") {
        console.log("CLIENT CONNECTION FAILURE - " + message.errorMessage);
      }
    },
    userName: data.username,
    password: data.password,
  };
  client.connect(options);
};

const handleArraySum = (array, result, setData, sort, highest_sentiment) => {
  let dataSouecesCount = 0;
  let secondsCount = 0;
  array.forEach(function (a) {
    if (!this[a?.name?.toString()?.toLowerCase()]) {
      this[a?.name?.toString()?.toLowerCase()] = {
        name: a?.name?.toString()?.toLowerCase(),
        value: 0,
      };
      result.push(this[a?.name?.toString()?.toLowerCase()]);
    }
    if (a?.name === "average_waiting_time") {
      ++dataSouecesCount;
      let time = a?.value;
      if (typeof time === "string" && a?.value?.includes(":")) {
        if (a?.value?.includes("day") || a?.value?.includes("days")) {
          const dayAndTime = a?.value?.split(",");
          const days = dayAndTime[0]?.split(" ");
          time = HmsToSeconds(days[0], dayAndTime[1]);
        } else time = HmsToSeconds(0, a.value);
      }
      secondsCount = secondsCount + time;
      this[a?.name?.toString()?.toLowerCase()].value =
        secondsCount / dataSouecesCount;
    } else this[a?.name?.toString()?.toLowerCase()].value += a?.value;
  }, Object.create(null));
  if (highest_sentiment) {
    //NetSentiment in overview
    result.push({
      name: "net_sentiment",
      value: [{ name: highest_sentiment, value: 100 }], // 100 is fixed number, just to distinguish between net_sentiment with 0 values which should be None
    });

    setData(result);
  } else {
    if (sort) {
      //Sorting array in interactions line chart/insights page
      result.sort((a, b) => (a.name > b.name ? 1 : -1));
      setData(result);
    } else {
      setData(result);
    }
  }
};

var output = {};
let aggregate = (input, setThemeData) => {
  for (var i = 0; i < input.length; i++) {
    //first loop themes add it to output directly
    if (i == 0) {
      output = input[0];
      continue;
    }
    //starting from second loop
    //themesbody=trend or analysis
    //
    for (let themesbody in input[i]) {
      if (themesbody == "event_name") {
        continue;
      }
      //check theme_trend and themes_analysis in output
      if (themesbody in output) {
        //loop on themes_trend and themes_analysis
        for (let themes in input[i][themesbody]) {
          //check if each unixtime in theme_trend and main_themes or sub_themes in theme_analysis
          if (themes in output[themesbody]) {
            //handle theme_trend and theme_analysis separately
            if (themesbody == "theme_trend") {
              for (let main_sub_themes in input[i][themesbody][themes]) {
                if (main_sub_themes in output[themesbody][themes]) {
                  for (let theme_object in input[i][themesbody][themes][
                    main_sub_themes
                  ]) {
                    if (
                      theme_object in
                      output[themesbody][themes][main_sub_themes]
                    ) {
                      let counter = 0;
                      for (let theme_type in input[i][themesbody][themes][
                        main_sub_themes
                      ][theme_object]) {
                        if (
                          theme_type in
                          output[themesbody][themes][main_sub_themes][
                            theme_object
                          ]
                        ) {
                          if (
                            theme_type == "main_themes" ||
                            theme_type == "sub_themes"
                          ) {
                            for (let kind in input[i][themesbody][themes][
                              main_sub_themes
                            ][theme_object][theme_type]) {
                              if (
                                kind in
                                output[themesbody][themes][main_sub_themes][
                                  theme_object
                                ][theme_type]
                              ) {
                                output[themesbody][themes][main_sub_themes][
                                  theme_object
                                ][theme_type][kind] =
                                  output[themesbody][themes][main_sub_themes][
                                    theme_object
                                  ][theme_type][kind] +
                                  input[i][themesbody][themes][main_sub_themes][
                                    theme_object
                                  ][theme_type][kind];
                                counter =
                                  counter +
                                  input[i][themesbody][themes][main_sub_themes][
                                    theme_object
                                  ][theme_type][kind];
                              } else {
                                output[themesbody][themes][main_sub_themes][
                                  theme_object
                                ][theme_type][kind] =
                                  input[i][themesbody][themes][main_sub_themes][
                                    theme_object
                                  ][theme_type][kind];
                                counter =
                                  counter +
                                  input[i][themesbody][themes][main_sub_themes][
                                    theme_object
                                  ][theme_type][kind];
                              }
                            }
                          } else if (theme_type == "count") {
                            output[themesbody][themes][main_sub_themes][
                              theme_object
                            ][theme_type] =
                              output[themesbody][themes][main_sub_themes][
                                theme_object
                              ][theme_type] + counter;
                          }
                        } else {
                          output[themesbody][themes][main_sub_themes][
                            theme_object
                          ][theme_type] =
                            input[i][themesbody][themes][main_sub_themes][
                              theme_object
                            ][theme_type];
                        }
                      }
                    } else {
                      output[themesbody][themes][main_sub_themes][
                        theme_object
                      ] =
                        input[i][themesbody][themes][main_sub_themes][
                          theme_object
                        ];
                    }
                  }
                } else {
                  output[themesbody][themes][main_sub_themes] =
                    input[i][themesbody][themes][main_sub_themes];
                }
              }
            } else if (themesbody == "theme_analysis") {
              for (let theme_object in input[i][themesbody][themes]) {
                if (theme_object in output[themesbody][themes]) {
                  let counter = 0;
                  for (let theme_type in input[i][themesbody][themes][
                    theme_object
                  ]) {
                    if (
                      theme_type in output[themesbody][themes][theme_object]
                    ) {
                      if (
                        theme_type == "main_themes" ||
                        theme_type == "sub_themes"
                      ) {
                        for (let kind in input[i][themesbody][themes][
                          theme_object
                        ][theme_type]) {
                          if (
                            kind in
                            output[themesbody][themes][theme_object][theme_type]
                          ) {
                            output[themesbody][themes][theme_object][
                              theme_type
                            ][kind] =
                              output[themesbody][themes][theme_object][
                                theme_type
                              ][kind] +
                              input[i][themesbody][themes][theme_object][
                                theme_type
                              ][kind];

                            counter =
                              counter +
                              input[i][themesbody][themes][theme_object][
                                theme_type
                              ][kind];
                          } else {
                            output[themesbody][themes][theme_object][
                              theme_type
                            ][kind] =
                              input[i][themesbody][themes][theme_object][
                                theme_type
                              ][kind];
                            counter =
                              counter +
                              input[i][themesbody][themes][theme_object][
                                theme_type
                              ][kind];
                          }
                        }
                      } else if (theme_type == "count") {
                        output[themesbody][themes][theme_object][theme_type] =
                          output[themesbody][themes][theme_object][theme_type] +
                          counter;
                      }
                    } else {
                      output[themesbody][themes][theme_object][theme_type] =
                        input[i][themesbody][themes][theme_object][theme_type];
                    }
                  }
                } else {
                  output[themesbody][themes][theme_object] =
                    input[i][themesbody][themes][theme_object];
                }
              }
            }
          } else {
            output[themesbody][themes] = input[i][themesbody][themes];
          }
        }
      } else {
        output[themesbody] = input[i][themesbody];
      }
    }
  }
  for (let k in output) {
    if (k == "theme_trend") {
      var dates = [];
      let i = 0;
      for (let old_date in output[k]) {
        dates[i] = old_date;
        i = i + 1;
      }
      dates.sort(function (a, b) {
        return a - b;
      });
      var arranged_output = {};
      for (i = 0; i < dates.length; i++) {
        arranged_output[dates[i]] = output[k][dates[i]];
      }
      output[k] = arranged_output;
    }
  }
  setThemeData && setThemeData({ ...output });
};

const checkIsDataFecthing = (totalPreLoaderDataSources, preLoaderTracker) => {
  if (preLoaderTracker?.length == 0) return false;
  else {
    let result = totalPreLoaderDataSources?.every(
      (item) => _.isEqual(item?.sort(), preLoaderTracker?.sort()) == true,
    );
    return result;
  }
};

const getSortedArray = (orignalData) => {
  // make here custom order for datasources icons
  const customOrder = [
    "twitter_private",
    "twitter_public",
    "facebook_private",
    "facebook_public",
    "instagram_private",
    "instagram_public",
    "tiktok_public",
    "whatsapp",
    "gmail",
    "intercom",
    "linkedin_public",
    "googlemybusiness",
    "genesys",
  ];

  const originalArray = [...orignalData];
  const sortedArray = originalArray?.sort((a, b) => {
    return customOrder?.indexOf(a) - customOrder?.indexOf(b);
  });
  return sortedArray;
};

const getSpecificChartsIcons = (allActiveDatasources) => {
  let activeDatasources = [];
  getSortedArray(allActiveDatasources)?.map((dataSource) => {
    let datasourceName = dataSource?.split("_")[0];
    if (!activeDatasources?.includes(datasourceName)) {
      activeDatasources.push(datasourceName);
    }
  });
  return activeDatasources;
};

export {
  sendRabbitMQRequest,
  connectWithRabbitMQ,
  handleArraySum,
  aggregate,
  output,
  checkIsDataFecthing,
  getSpecificChartsIcons,
};
