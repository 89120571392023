import { useQuery } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useLookupMessages = (enabled) => {
  return useQuery({
    queryKey: ["lookupMessages"],
    queryFn: () => EngagementsController.getLookupMessages(),
    enabled,
  });
};
