import { Pagination } from "@mui/material";
import { useIntl } from "react-intl";
import SharedTable from "shared/table/sharedTable";
import { CheckValueLocale } from "utils/helpers";

export const TeamsTable = ({
  page,
  handleChangePage,
  pagination,
  tableBody,
  tableEmptyBody,
}) => {
  const intl = useIntl();

  const tableHeaders = [
    {
      body: CheckValueLocale("team_name", "", {}, intl),
      width: "23.75%",
    },
    {
      body: CheckValueLocale("created_at", "", {}, intl),
      width: "23.75%",
    },
    {
      body: CheckValueLocale("product_access", "", {}, intl),
      width: "23.75%",
    },
    {
      body: CheckValueLocale("team_members", "", {}, intl),
      width: "23.75%",
    },
    {
      body: " ",
      width: "5%",
    },
  ];
  return (
    <>
      <SharedTable
        isSharedStyle
        tableHeader={tableHeaders}
        tableBody={tableBody}
        emptyBody={tableEmptyBody}
      />
      {pagination?.pages > 1 ? (
        <Pagination
          onChange={handleChangePage}
          page={page}
          className="pagination-monitor-list"
          count={pagination?.pages}
          variant="outlined"
          rowsPerPage={pagination?.items}
        />
      ) : null}
    </>
  );
};
