import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import Energy from "images/luci-images/energy.svg";
import NoData from "images/summary-widget/no-data.svg";
import Progress from "images/summary-widget/summary-loading.svg";

const SummaryEmpty = ({ emptyType }) => {
  const intl = useIntl();

  let textSummaryEmpty = null;

  switch (emptyType) {
    case "noData":
      textSummaryEmpty = {
        img: NoData,
        title: "summary_empty_title",
        hint: "summary_empty_text",
      };
      break;
    case "noEnergy":
      textSummaryEmpty = {
        img: Energy,
        title: "summary_no_energy_title",
        hint: "summary_no_energy_text",
      };
      break;
    case "inProgress":
      textSummaryEmpty = {
        img: Progress,
        hint: "summary_in_progress_text",
      };
      break;
    case "failed":
      textSummaryEmpty = {
        title: "summary_error_title",
        hint: "summary_error_text",
      };
      break;
    default:
      break;
  }
  const { img, title, hint } = textSummaryEmpty;

  return (
    <Box className="summary-empty-wrapper">
      {img ? (
        <Box className="summary-empty-img">
          <img src={img} alt="summary-empty-img" />
        </Box>
      ) : null}
      <Box className="summary-empty-text">
        {title ? (
          <Box className="summary-empty-title" component="p">
            {CheckValueLocale(title, "", {}, intl)}
          </Box>
        ) : null}
        <Box className="summary-empty-hint" component="p">
          {CheckValueLocale(hint, "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryEmpty;
