import ReactEcharts from "echarts-for-react";

import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";

const PunchCard = (props) => {
  const intl = useIntl();
  const [agentActivity, setAgentActivity] = useState([]);
  const [clientActivity, setClientActivity] = useState([]);
  const [selectedData, setSelectedData] = useState("clients");

  const mapping_number_to_range = (num, selected) => {
    // This function used to mapp any number between 2 values 10 & 30
    // We choose 10 & 30 after a lot of testing which avoid any overlap between circles at worst case scenario
    // We will depend on num along with 2 values (min & max), and apply this Equation:
    // output = output_start + ((output_end - output_start) / (input_end - input_start)) * (input - input_start)
    // where:
    //      output: number after mapping
    //      output_start: 10 the min number we can reach to
    //      output_end  : 30 the min number we can reach to
    //      input_start : The minimum number for Agent or Channel activity
    //      input_end: The max number for Agent of Channel activity
    //      input: The actual value of num.
    let min, max;
    if (selected == "clients") {
      min = props.minValueClient;
      max = props.maxValueClient;
    } else if (selected == "agent") {
      min = props.minValueAgent;
      max = props.maxValueAgent;
    }
    // Avoid Zeros case
    if (min === 0 && max === 0 && num === 0) {
      return 10;
    } else {
      return 10 + ((30 - 10) / (max - min)) * (num - min);
    }
  };

  const reverse_mapping_number_to_range = (num) => {
    // Reverse the above function
    let min, max;
    if (selectedData == "clients") {
      min = props.minValueClient;
      max = props.maxValueClient;
    } else if (selectedData == "agent") {
      min = props.minValueAgent;
      max = props.maxValueAgent;
    }
    return (num - 10) / ((30 - 10) / (max - min)) + min;
  };

  const hours_for_hover = [
    CheckValueLocale("12am", "", {}, intl),
    CheckValueLocale("1am", "", {}, intl),
    CheckValueLocale("2am", "", {}, intl),
    CheckValueLocale("3am", "", {}, intl),
    CheckValueLocale("4am", "", {}, intl),
    CheckValueLocale("5am", "", {}, intl),
    CheckValueLocale("6am", "", {}, intl),
    CheckValueLocale("7am", "", {}, intl),
    CheckValueLocale("8am", "", {}, intl),
    CheckValueLocale("9am", "", {}, intl),
    CheckValueLocale("10am", "", {}, intl),
    CheckValueLocale("11am", "", {}, intl),
    CheckValueLocale("12pm", "", {}, intl),
    CheckValueLocale("1pm", "", {}, intl),
    CheckValueLocale("2pm", "", {}, intl),
    CheckValueLocale("3pm", "", {}, intl),
    CheckValueLocale("4pm", "", {}, intl),
    CheckValueLocale("5pm", "", {}, intl),
    CheckValueLocale("6pm", "", {}, intl),
    CheckValueLocale("7pm", "", {}, intl),
    CheckValueLocale("8pm", "", {}, intl),
    CheckValueLocale("9pm", "", {}, intl),
    CheckValueLocale("10pm", "", {}, intl),
    CheckValueLocale("11pm", "", {}, intl),
  ];

  const hours = [
    CheckValueLocale("12am", "", {}, intl),
    "",
    CheckValueLocale("2am", "", {}, intl),
    "",
    CheckValueLocale("4am", "", {}, intl),
    "",
    CheckValueLocale("6am", "", {}, intl),
    "",
    CheckValueLocale("8am", "", {}, intl),
    "",
    CheckValueLocale("10am", "", {}, intl),
    "",
    CheckValueLocale("12pm", "", {}, intl),
    "",
    CheckValueLocale("2pm", "", {}, intl),
    "",
    CheckValueLocale("4pm", "", {}, intl),
    "",
    CheckValueLocale("6pm", "", {}, intl),
    "",
    CheckValueLocale("8pm", "", {}, intl),
    "",
    CheckValueLocale("10pm", "", {}, intl),
    "",
  ];

  // days-format
  const days = [
    CheckValueLocale("Friday", "", {}, intl), // Equals to 0 at channel Activity Array
    CheckValueLocale("Thursday", "", {}, intl), // Equals to 1 at channel Activity Array
    CheckValueLocale("Wednesday", "", {}, intl), // Equals to 2 at channel Activity Array
    CheckValueLocale("Tuesday", "", {}, intl), // Equals to 3 at channel Activity Array
    CheckValueLocale("Monday", "", {}, intl), // Equals to 4 at channel Activity Array
    CheckValueLocale("Sunday", "", {}, intl), // Equals to 5 at channel Activity Array
    CheckValueLocale("Saturday", "", {}, intl), // Equals to 6 at channel Activity Array
  ];

  useEffect(() => {
    setSelectedData(props.selectedValue);
  }, [props.selectedValue]);

  useEffect(() => {
    let clientActivity = [];
    // Define colors
    props.clientActivity.forEach((arr) => {
      let color;
      let num = mapping_number_to_range(arr[2], "clients");
      if (num === 10) {
        color = "#97D7F3";
      } else if (num > 10 && num < 20) {
        color = "#027EB7";
      } else if (num >= 20 && num <= 30) {
        color = "#003D59";
      }
      clientActivity.push({
        value: [arr[1], arr[0], num],
        itemStyle: { color: color },
      });
    });
    setClientActivity(clientActivity);
  }, [props.clientActivity]);

  useEffect(() => {
    let agentActivity = [];
    // Define colors
    props.agentActivity.forEach((arr) => {
      let color;
      let num = mapping_number_to_range(arr[2], "agent");
      if (num === 10) {
        color = "#97D7F3";
      } else if (num > 10 && num < 20) {
        color = "#027EB7";
      } else if (num >= 20 && num <= 30) {
        color = "#003D59";
      }
      agentActivity.push({
        value: [arr[1], arr[0], num],
        itemStyle: { color: color },
      });
    });
    setAgentActivity(agentActivity);
  }, [props.agentActivity]);

  var option;
  option = {
    tooltip: {
      position: "top",
      backgroundColor: "#fff",
      formatter: function (params) {
        return (
          // If first decimal value is greater than 0 we will use Math.Ceil, otherwise we use Math.round
          Math.round(reverse_mapping_number_to_range(params.value[2])) +
          " " +
          CheckValueLocale("activity_in", "", {}, intl) +
          " " +
          hours_for_hover[params.value[0]] +
          " " +
          CheckValueLocale("of_day", "", {}, intl) +
          " " +
          days[params.value[1]]
        );
      },
    },
    grid: {
      left: 2,
      bottom: 30,
      right: 10,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: hours,
      axisTick: { alignWithLabel: true, show: true },
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: { color: "#000" },
        show: true,
      },
    },
    yAxis: {
      type: "category",
      data: days,
      axisTick: { alignWithLabel: true, show: false },
      axisLine: {
        show: false,
      },
    },
    series: [
      {
        type: "scatter",
        symbolSize: function (val) {
          return val[2];
        },
        data: selectedData === "clients" ? clientActivity : agentActivity,
        animationDelay: function (idx) {
          return idx * 5;
        },
      },
    ],
  };
  return (
    <ReactEcharts
      option={option}
      style={{ height: "392px", width: "100%", top: "-50px" }}
      theme="custom_theme"
    />
  );
};

export default PunchCard;
