import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Avatar, Stack, Typography } from "@mui/material";
import AiAgentPublishedIcon from "images/ai-agents/ai-agent-published.svg";
import AiAgentPausedIcon from "images/ai-agents/ai-agent-paused.svg";
import AiAgentCloudIcon from "images/ai-agents/ai-agent-cloud.svg";
import AiAgentResumedIcon from "images/ai-agents/ai-agent-resume.svg";
import AiAgentSwaggerApproveIcon from "images/ai-agents/ai-agent-swagger-approve.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import moment from "moment";
import AiAgentFunction from "../../../aiAgentFunction";
import UserAvatar from "shared/unifiedUserImage/userAvatar";

const ActivityLogItem = ({
  status,
  date,
  img,
  changedBy,
  fileName,
  isLast,
  isFirst,
  itemRef,
  inside_subentity_name,
}) => {
  const intl = useIntl();
  const { truncateFileName } = AiAgentFunction();
  const formattedDate = moment(date).tz("utc", true).format("D MMM, hh:mm A");

  const handleRenderIcon = (logStatus) => {
    switch (logStatus) {
      case "published":
        return AiAgentPublishedIcon;
      case "paused":
        return AiAgentPausedIcon;
      case "resumed":
        return AiAgentResumedIcon;
      case "Policy Approved":
        return AiAgentSwaggerApproveIcon;
      default:
        return AiAgentCloudIcon;
    }
  };

  const mapStatusToTitle = {
    changed: CheckValueLocale("ai_agent_name_changed", "", {}, intl),
    published: CheckValueLocale("ai_agent_published", "", {}, intl),
    paused: CheckValueLocale("ai_agent_paused", "", {}, intl),
    "Routing to a human Enabled": CheckValueLocale(
      "ai_agent_assigned_to_human_enabled",
      "",
      {},
      intl,
    ),
    resumed: CheckValueLocale("ai_agent_resumed", "", {}, intl),
    "Inbound Routing Changed": CheckValueLocale(
      "ai_agent_inbound_routing_changed",
      "",
      {},
      intl,
    ),
    "Routing to a human Disabled": CheckValueLocale(
      "ai_agent_assigned_to_human_disabled",
      "",
      {},
      intl,
    ),
    uploaded: CheckValueLocale(
      "ai_agent_file_uploaded",
      "",
      {
        fileName: truncateFileName(fileName) || "",
      },
      intl,
    ),
    created: CheckValueLocale(
      "ai_agent_created",
      "",
      {
        name: inside_subentity_name || "",
      },
      intl,
    ),
    "Policy Approved": CheckValueLocale(
      "ai_agent_policy_approved",
      "",
      {},
      intl,
    ),
  };

  return (
    <TimelineItem ref={itemRef}>
      <TimelineSeparator>
        <TimelineConnector
          className={`timeline-connector ${!isFirst ? "not-first" : ""}`}
        />
        <TimelineDot className="timeline-dot" variant="outlined">
          <img src={handleRenderIcon(status)} alt="Ai Agent Published" />
        </TimelineDot>
        <TimelineConnector
          className={`ai-agent-timeline-connector-long ${!isLast ? "not-last" : ""}`}
        />
      </TimelineSeparator>
      <TimelineContent className="ai-agent-timeline-content">
        <Typography className="ai-agent-typography-title">
          {mapStatusToTitle?.[status] ||
            CheckValueLocale("ai_agent_published", "", {}, intl)}
        </Typography>
        <Stack direction="row" alignItems="center" gap="8px">
          <Stack direction="row" alignItems="center" gap="4px">
            <UserAvatar
              avatarSrc={img}
              fullName={changedBy}
              sizes={{ ratio: "16px", fontSize: "8px" }}
            />
            <Typography className="ai-agent-typography-info">
              {changedBy}
            </Typography>
          </Stack>
          <Typography className="ai-agent-typography-info">—</Typography>
          <Typography className="ai-agent-typography-info-direction">
            {formattedDate}
          </Typography>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ActivityLogItem;
