import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { TextField, Box } from "@mui/material";

const HashtagsSearch = ({
  searchHashtags,
  setSearchHashtags,
  hanldeSearchIconClick,
}) => {
  const intl = useIntl();
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);

  //Change Handler
  const changeHandler = (text) => {
    setSearchHashtags(text);
  };
  const handleEnterKeyPress = (e) => {
    if (e?.key === "Enter") {
      e?.target?.blur();
      hanldeSearchIconClick();
    }
  };

  return (
    <Box className="hashtags-search-wrapper search-box">
      <TextField
        className="search-field"
        label={CheckValueLocale("search_hashtags", "", {}, intl)}
        variant="outlined"
        value={searchHashtags}
        onChange={(e) => {
          changeHandler(e?.target?.value);
        }}
        onBlur={() => setSearchBoxClicked(false)}
        onFocus={() => setSearchBoxClicked(true)}
        onKeyPress={handleEnterKeyPress}
        id="settings-instagram-hashtags-search-input"
      />
      <Box
        className={
          "search-icon-container " +
          (searchBoxClicked ? "search-icon-container-blue" : "")
        }
        onClick={hanldeSearchIconClick}
        id="settings-instagram-hashtags-search-icon-box"
      >
        <FontAwesomeIcon
          className="search-icon"
          icon={faSearch}
          id="settings-instagram-hashtags-search-by-name-icon"
        />
      </Box>
    </Box>
  );
};
export default HashtagsSearch;
