import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Typography,
  Grid,
  OutlinedInput,
  Divider,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Tooltip,
} from "@mui/material";

import { Box } from "@mui/system";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale } from "utils/helpers";
import "../style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarChristmas } from "@fortawesome/pro-solid-svg-icons";
import { faObjectGroup } from "@fortawesome/free-solid-svg-icons";

const DashboardInformation = (props) => {
  const { setIsValidDashboardInfo, enabledAggregate, enabledCustomDashboard } =
    props;
  const intl = useIntl();
  const {
    handleInputChange,
    selectDashboardType,
    dashboardData,
    dashboardErrors,
    dashboardList,
    selectedDashboardType,
    dashboardTypesLoading,
    canCreateDashboard,
  } = props;

  const [dashboardType, setDashboardType] = useState(null);

  var fieldRequiredMessage = CheckValueLocale(
    "dashboard_field_required",
    "",
    {},
    intl,
  );

  useEffect(() => {
    let validDashboardInfo =
      dashboardData?.dashabordName !== "" &&
      dashboardErrors?.dashabordName === "" &&
      dashboardErrors?.dashabordName !== fieldRequiredMessage &&
      dashboardErrors?.dashabordName === "" &&
      dashboardData?.dashboardTypeID !== null &&
      dashboardErrors?.dashboardType === "";

    setIsValidDashboardInfo(validDashboardInfo);
  }, [dashboardData?.dashabordName]);

  const handleChangeType = (event, id, typeName) => {
    selectDashboardType(id, typeName);
  };

  useEffect(() => {
    setDashboardType(dashboardData?.dashboardTypeID);
  }, [dashboardData]);

  return (
    <div className="monitor-type dashboard-monitor-type">
      <Box className="dashboard-name-container">
        <Grid
          item
          xs={6}
          id="dashboard-field"
          className="social-media-field-grid"
        >
          <Typography
            variant="caption"
            sx={{ display: "inline-block" }}
            className="dashboard-title"
          >
            {`1. ${CheckValueLocale("dashboard_name", "", {}, intl)}`}
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          ></Grid>
          <OutlinedInput
            variant="outlined"
            className="social-media-field"
            value={dashboardData?.dashabordName}
            placeholder={CheckValueLocale(
              "type_the_dashboard_name",
              "",
              {},
              intl,
            )}
            onChange={(e) => handleInputChange(e)}
          />
          {dashboardErrors.dashabordName !== "" && (
            <Box component="p" className="dashboard-error-name">
              {dashboardErrors?.dashabordName}
            </Box>
          )}
        </Grid>
      </Box>

      <Divider id="dashboard-divider" sx={{ bgcolor: "#EAEDF1" }} />

      <Box className="dashboard-type-container">
        <Typography
          variant="caption"
          sx={{ display: "inline-block" }}
          className="dashboard-title"
        >
          {`2. ${CheckValueLocale("dashboard_type", "", {}, intl)}`}
        </Typography>
        {!dashboardTypesLoading ? (
          <FormControl className="form-control-dashboard">
            {dashboardList?.map((dashboard) => {
              let disabledType =
                dashboard?.attributes?.type_name === "CUSTOM_DASHBOARD"
                  ? !enabledCustomDashboard
                  : !enabledAggregate;

              return (
                <Box>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={dashboardType}
                    disabled={disabledType}
                    onChange={(event) => {
                      handleChangeType(
                        event,
                        dashboard?.attributes?.type_name,
                        dashboard?.attributes?.id,
                      );
                    }}
                  >
                    <Tooltip
                      arrow
                      placement="top"
                      title={CheckValueLocale(
                        !canCreateDashboard &&
                          dashboard?.attributes?.type_name ===
                            "AGGREGATE_DASHBOARD"
                          ? "create_dashboard_limit_reached_msg"
                          : "",
                        "",
                        {},
                        intl,
                      )}
                    >
                      <FormControlLabel
                        className={`dashboard-form-control-label ${disabledType ? "disabled" : ""}`}
                        value={dashboard?.id}
                        control={<Radio disabled={disabledType} />}
                        label={
                          <Box className="card-container">
                            <Box className="card-dashboard-heading">
                              <FontAwesomeIcon
                                icon={
                                  dashboard?.attributes?.type_name ===
                                  "AGGREGATE_DASHBOARD"
                                    ? faObjectGroup
                                    : faStarChristmas
                                }
                              />
                              {CheckValueLocale(
                                `${dashboard.attributes.type_name}`,
                                "",
                                {},
                                intl,
                              )}
                            </Box>
                            <Box className="card-sub-text">
                              {dashboard?.attributes?.type_name ===
                              "AGGREGATE_DASHBOARD"
                                ? CheckValueLocale(
                                    "aggregate_dashboard_card_description",
                                    "",
                                    {},
                                    intl,
                                  )
                                : CheckValueLocale(
                                    "custom_dashboard_card_description",
                                    "",
                                    {},
                                    intl,
                                  )}
                            </Box>
                          </Box>
                        }
                      />
                    </Tooltip>
                  </RadioGroup>
                </Box>
              );
            })}
          </FormControl>
        ) : (
          <CircularLoading />
        )}
      </Box>
    </div>
  );
};

export default DashboardInformation;
