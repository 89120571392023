import { useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Typography,
} from "@mui/material";
import CompaniesController from "services/controllers/companiesController";
import { CheckValueLocale } from "utils/helpers";

import LucButton from "shared/lucButton/lucButton";

const IntegrationPart = (props) => {
  const { setSuccess, setErrorMsg, success, setIsConfigured, isConfigured } =
    props;
  const intl = useIntl();
  const [intercomInfo, setIntercom] = useState({
    account_name: "",
    token_secret: "",
    intercomErrorMsg: false,
  });
  const [zendeskAccount, setZendesk] = useState({
    subdomain: "",
    username: "",
    api_token: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const user_name = props?.thirdPartyIntercom?.attributes?.user_name,
    user_name_zendesk = props?.thirdPartyZendesk?.attributes?.user_name,
    sub_domain = props?.thirdPartyZendesk?.attributes?.sub_domain;

  //Handle Change Value
  const changeHandler = (e) => {
    if (props.type === "zendesk") {
      setZendesk({ ...zendeskAccount, [e.target.name]: e.target.value });
    } else {
      setIntercom({ ...intercomInfo, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    const isValid = checkValid();
    if (isValid) {
      setButtonLoading(true);
      if (props.type !== "zendesk") {
        await CompaniesController.integrateIntercom(intercomInfo).then(
          (result) => {
            if (!result.errorMsg) {
              setIsConfigured({
                intercom: true,
                zendesk: isConfigured.zendesk,
              });
              setSuccess(true);
              props.handleDialog();
            } else {
              setIntercom({ ...intercomInfo, intercomErrorMsg: true });
              setIsConfigured({
                intercom: false,
                zendesk: isConfigured.zendesk,
              });
              setErrorMsg("failed_error");
              setSuccess(false);
            }
            setButtonLoading(false);
          },
        );
      } else {
        await CompaniesController.integrateZendesk(zendeskAccount).then(
          (result) => {
            if (!result.errorMsg) {
              setIsConfigured({
                intercom: isConfigured.intercom,
                zendesk: true,
              });
              setSuccess(true);
              props.handleDialog();
            } else {
              setIsConfigured({
                intercom: isConfigured.intercom,
                zendesk: false,
              });
              setErrorMsg("failed_error");
              setSuccess(false);
            }
            setButtonLoading(false);
          },
        );
      }
    } else {
      if (success == null) {
        setErrorMsg("failed_error");
        setSuccess(false);
      }
    }
  };

  const checkValid = () => {
    if (props.type == "zendesk") {
      if (
        zendeskAccount.subdomain === "" ||
        zendeskAccount.api_token === "" ||
        zendeskAccount.username === ""
      ) {
        return false;
      }
    } else {
      if (intercomInfo.account_name === "" || intercomInfo.api_token === "") {
        return false;
      }
    }
    return true;
  };

  const configureAccount = () => {};
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleDialog();
      }}
    >
      <div id="integration_part">
        <DialogTitle className="text-transform-letter integration__head">
          {CheckValueLocale(`${props?.type}_integration`, "", {}, intl)}
        </DialogTitle>
        <DialogContent className="intergation__wrapper">
          {props?.type === "intercom" ? (
            <div>
              {/* <div className="intercom__desc">
                To be able to track and analyze your chats with your customer,
                we need you to allow the following <span>Permissions.</span>
              </div> */}
              <div className="intercom__desc">
                {CheckValueLocale("intercom_desc_pop_up", "", {}, intl)}
              </div>
              <InputLabel className="integrate__label">
                {CheckValueLocale("account_name", "", {}, intl)}
                <Typography component="span" className="required ">
                  *
                </Typography>
              </InputLabel>

              <TextField
                autoFocus
                margin="dense"
                name="account_name"
                fullWidth
                onChange={changeHandler}
                defaultValue={user_name ? user_name : null}
              />
              <div className="box-reletive">
                <InputLabel className="integrate__label">
                  {CheckValueLocale("access_token_intercom", "", {}, intl)}
                  <Typography component="span" className="required ">
                    *
                  </Typography>
                </InputLabel>
                <TextField
                  autoFocus
                  margin="dense"
                  name="token_secret"
                  fullWidth
                  onChange={changeHandler}
                />
                {intercomInfo.intercomErrorMsg && (
                  <img
                    className="warning-img-settings warning-img-settings-ar"
                    src="/exclamation-triangle.png"
                    alt="drop"
                  />
                )}
              </div>
              {intercomInfo.intercomErrorMsg && (
                <span className="inter-error-message-settings">
                  {CheckValueLocale("access_token_missing", "", {}, intl)}
                </span>
              )}
            </div>
          ) : props.type === "zendesk" ? (
            <div>
              <InputLabel className="integrate__label">
                {CheckValueLocale("sub_domain_zendesk", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
              </InputLabel>
              <TextField
                autoFocus
                margin="dense"
                name="subdomain"
                fullWidth
                onChange={changeHandler}
                placeholder="EX:Lucidya.zendesk.com"
                defaultValue={sub_domain ? sub_domain : null}
              />
              <InputLabel className="integrate__label">
                {CheckValueLocale("email", "", {}, intl)}
                <span className="emai-sub-title-zendesk">
                  ({CheckValueLocale("email_sub_zendesk", "", {}, intl)})
                </span>
                <Typography
                  component="span"
                  className="required required-star-icon "
                >
                  *
                </Typography>
              </InputLabel>
              <TextField
                autoFocus
                margin="dense"
                name="username"
                fullWidth
                onChange={changeHandler}
                placeholder="example@lucidya.com"
                defaultValue={user_name_zendesk ? user_name_zendesk : null}
              />
              <InputLabel className="integrate__label">
                {CheckValueLocale("api_token", "", {}, intl)}
                <Typography
                  component="span"
                  className="required required-star-icon"
                >
                  *
                </Typography>
              </InputLabel>
              <TextField
                autoFocus
                margin="dense"
                name="api_token"
                fullWidth
                onChange={changeHandler}
                placeholder="API Example"
              />
            </div>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <LucButton
            id="settings-third-party-integration-cancel-btn"
            onClick={() => {
              props.handleDialog();
            }}
            variant="flat"
            type="secondary"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>

          <LucButton
            id="settings-third-party-integration-configure-btn"
            onClick={() => {
              handleSubmit();
            }}
            variant="filled"
            type="primary"
            loading={buttonLoading}
            disabled={buttonLoading}
          >
            {CheckValueLocale("configure", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default IntegrationPart;
