import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faFaceSmile,
  faLocationDot,
  faPhone,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { faMessageLines } from "@fortawesome/pro-solid-svg-icons";

const ProfileInfo = ({ infoData, infoArr }) => {
  const intl = useIntl();

  const getInfoImage = (type) => {
    const infoImage = {
      gender: <FontAwesomeIcon icon={faVenusMars} />,
      dialects: <FontAwesomeIcon icon={faMessageLines} />,
      location: <FontAwesomeIcon icon={faLocationDot} />,
      sentiment: <FontAwesomeIcon icon={faFaceSmile} />,
      phone_number: <FontAwesomeIcon icon={faPhone} />,
      email: <FontAwesomeIcon icon={faEnvelope} />,
    };
    return infoImage[type];
  };

  const toolTipAppear = (name, count) =>
    name?.length > count ? (
      <Tooltip title={name} arrow placement="top">
        <span>{name?.substring(0, count) + "..."}</span>
      </Tooltip>
    ) : (
      name
    );

  return (
    <Box className="profile-side-bar-content">
      <h4 className="content-side-bar-heading no-margin">
        {CheckValueLocale("user_info_profile", "", {}, intl)}:
      </h4>
      <Box className="content-side-bar-lines">
        {infoArr?.map((info, i) => {
          return (
            <Box className="content-box" key={i}>
              <span className="title-box">
                {getInfoImage(info)}
                {CheckValueLocale(info + "_profile", "", {}, intl)}:
              </span>
              <span className="value-box">
                {info === "gender" &&
                (infoData[info] === "f" ||
                  infoData[info] === "m" ||
                  infoData[info] === "u")
                  ? infoData[info]?.length
                    ? toolTipAppear(
                        CheckValueLocale(
                          infoData[info] + "_gender",
                          "",
                          {},
                          intl,
                        ),
                        18,
                      )
                    : "-"
                  : info === "location"
                    ? infoData[info]?.country?.length ||
                      infoData[info]?.city?.length
                      ? infoData[info]?.country?.length &&
                        infoData[info]?.city?.length
                        ? toolTipAppear(
                            CheckValueLocale(
                              infoData[info]?.country,
                              "",
                              {},
                              intl,
                            ) +
                              " " +
                              "," +
                              " " +
                              CheckValueLocale(
                                infoData[info]?.city,
                                "",
                                {},
                                intl,
                              ),
                            18,
                          )
                        : toolTipAppear(
                            CheckValueLocale(
                              infoData[info]?.country,
                              "",
                              {},
                              intl,
                            ) +
                              " " +
                              CheckValueLocale(
                                infoData[info]?.city,
                                "",
                                {},
                                intl,
                              ),
                            18,
                          )
                      : "-"
                    : info === "phone_number"
                      ? infoData[info]?.length
                        ? toolTipAppear(infoData[info], 18)
                        : "-"
                      : infoData[info]?.length
                        ? toolTipAppear(
                            CheckValueLocale(infoData[info], "", {}, intl),
                            18,
                          )
                        : "-"}
              </span>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default ProfileInfo;
