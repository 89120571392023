import { useIntl } from "react-intl";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  OutlinedInput,
  ListItemText,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import {
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import FormHelperError from "./formHelperError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
const AdavancedAlertsFields = (props) => {
  const intl = useIntl();
  const {
    formFieldErrors,
    selectedMonitors,
    handleChangeMultipleSelection,
    companyUsers,
    selectedEmails,
    emailsEnabled,
    setEmailsEnabled,
    keywordsFocused,
    setKeywordsFocused,
    alertKeywords,
    setAlertKeywords,
    selectedKeywords,
    handleKeywordChange,
    handleKeywords,
    handelPostUsers,
    postUsers,
    handlePostsUsersChange,
    selectedPostUsers,
    handleDeleteInputField,
    setSelectedPostUsers,
    MenuProps,
    showAdavancedKeywords,
    isCreateAlert,
    isInstagramDatasource,
    isInfluncerType,
    activeProductName,
    handleChangeAlertsMaximum,
    maxmNumberEmailsValue,
    maxmNumValue,
    informerMaxEmails,
    selectedSources,
  } = props;
  let isRequiredUsers = isInstagramDatasource && isInfluncerType;

  return (
    <>
      <Typography component="p" className="alerts-divider"></Typography>
      <Grid item xs={12}>
        <Typography variant="h6" className="alert-advanced-title">
          {CheckValueLocale("advanced_alerts", "", {}, intl)}
        </Typography>
      </Grid>
      {showAdavancedKeywords() &&
      activeProductName !== "survey" &&
      activeProductName !== "engagements" ? (
        <>
          <Grid item xs={12}>
            <Box className={`input-form-control`}>
              <InputLabel>
                {CheckValueLocale("keywords", "", {}, intl)}
                <Typography component="span" className="optional-title-alert">
                  ({CheckValueLocale("optional", "", {}, intl)})
                </Typography>
              </InputLabel>
              <FormGroup>
                <TextField
                  id="outlined-email-input"
                  className={`alerts-keywords-field keywords-field ${checkDirectionLang(
                    selectedKeywords,
                  )}`}
                  variant="outlined"
                  onFocus={() => setKeywordsFocused(true)}
                  onBlur={() => setKeywordsFocused(true)}
                  InputProps={{
                    endAdornment: keywordsFocused ? (
                      <InputAdornment onMouseDown={handleInputMouseDown}>
                        <Button
                          className="add-btn-with-plus alert-add-btn-keyword"
                          onClick={(event) => {
                            handleKeywords(event, "btn");
                          }}
                          value={selectedKeywords}
                          id="alert-form-add-keywords-field-btn"
                        >
                          {CheckValueLocale("add_plus", "", {}, intl)}
                        </Button>
                      </InputAdornment>
                    ) : null,
                  }}
                  value={selectedKeywords}
                  error={formFieldErrors?.keywordError !== "" ? true : false}
                  placeholder={CheckValueLocale("type_keywords", "", {}, intl)}
                  onKeyDown={(event) => handleKeywords(event)}
                  onChange={(event) => handleKeywordChange(event)}
                  disabled={!isCreateAlert}
                />
              </FormGroup>
              <FormHelperError value={formFieldErrors?.keywordError} />
              <Box className="keyword-spam-wrapper">
                {alertKeywords?.map((keyword, i) => {
                  return (
                    <KeywordTooltipChip
                      onDelete={() =>
                        handleDeleteInputField(
                          i,
                          alertKeywords,
                          setAlertKeywords,
                        )
                      }
                      index={i}
                      dataSource={"twitter"}
                      labels={keyword}
                      disableChip={!isCreateAlert}
                    />
                  );
                })}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box className="input-form-control">
              <InputLabel>
                {CheckValueLocale("alerts_collect_posts", "", {}, intl)}
                <Typography
                  component="span"
                  className={
                    isRequiredUsers ? "required" : "optional-title-alert"
                  }
                >
                  {isRequiredUsers
                    ? "*"
                    : `(${CheckValueLocale("optional", "", {}, intl)})`}
                </Typography>
              </InputLabel>
              <FormGroup className="alerts-form-group">
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={CheckValueLocale("username", "", {}, intl)}
                  value={postUsers}
                  onKeyDown={(event) => handelPostUsers(event)}
                  error={formFieldErrors?.userPostsError !== "" ? true : false}
                  onFocus={() => setKeywordsFocused(true)}
                  onBlur={() => setKeywordsFocused(true)}
                  onChange={(e) => handlePostsUsersChange(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">@</InputAdornment>
                    ),
                    endAdornment: keywordsFocused ? (
                      <InputAdornment onMouseDown={handleInputMouseDown}>
                        <Button
                          className="add-btn-with-plus alert-add-btn-keyword"
                          onClick={(event) => {
                            handelPostUsers(event, "btn");
                          }}
                          value={postUsers}
                          id="alert-form-add-keywords-btn"
                        >
                          {CheckValueLocale("add_plus", "", {}, intl)}
                        </Button>
                      </InputAdornment>
                    ) : null,
                  }}
                  disabled={!isCreateAlert}
                />
              </FormGroup>
              <FormHelperError value={formFieldErrors?.userPostsError} />
              <Box className="keyword-spam-wrapper">
                {selectedPostUsers?.map((user, i) => {
                  return (
                    <KeywordTooltipChip
                      onDelete={() =>
                        handleDeleteInputField(
                          i,
                          selectedPostUsers,
                          setSelectedPostUsers,
                        )
                      }
                      index={i}
                      labels={user}
                      disableChip={!isCreateAlert}
                    />
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </>
      ) : null}
      <Grid item xs={12}>
        <Box className="input-form-control">
          <InputLabel>
            {CheckValueLocale("alert_form_notify_other_users", "", {}, intl)}
          </InputLabel>
          <Select
            id="notify-other-users-select"
            fullWidth
            multiple
            name="alertUserEmails"
            value={selectedEmails}
            onChange={handleChangeMultipleSelection}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              return selected?.length === 0
                ? CheckValueLocale("select_placeholder", "", {}, intl)
                : selected?.length > 0
                  ? selected?.map((item) => item?.email)?.join(", ")
                  : selected?.map((item) => item?.email);
            }}
            MenuProps={MenuProps}
            displayEmpty
            error={formFieldErrors?.alertsEmails ? true : false}
            className="form-select"
            disabled={
              (activeProductName === "engagements"
                ? !selectedSources?.length
                : !selectedMonitors?.length) ||
              !companyUsers?.length ||
              !isCreateAlert
            }
          >
            {companyUsers?.map((val) => (
              <MenuItem
                className="alert-monitors-menuItem alert-dubai-text"
                key={`user-emails-${val?.attributes?.id}`}
                value={val?.attributes}
                id={`alert-monitors-menuItem-${
                  val?.attributes ?? "company-user-attribute"
                }`}
              >
                <Checkbox
                  checked={
                    selectedEmails?.findIndex((item) => +item.id === +val.id) >=
                    0
                  }
                />
                <ListItemText primary={val?.attributes?.email} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperError value={formFieldErrors?.alertsEmails} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className="alerts-checked-email-notification"
                disabled={!selectedEmails?.length || !isCreateAlert}
                checked={emailsEnabled}
                onChange={() => setEmailsEnabled(!emailsEnabled)}
              />
            }
            label={CheckValueLocale("alert_form_send_emails", "", {}, intl)}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <Box className="max-number-emails input-form-control ">
            <Box className="alert-email-label-container">
              <InputLabel id="email-label-content">
                {CheckValueLocale("alerts_Maximumـemails", "", {}, intl)}
              </InputLabel>
              <Tooltip
                id="alert-tooltip"
                title={
                  <Box className="icon-alert-tooltip">
                    {CheckValueLocale(
                      "alerts_default_Maximumـemails",
                      "",
                      { num: informerMaxEmails },
                      intl,
                    )}
                  </Box>
                }
                placement="top"
                arrow
              >
                <Box component="span" ml={1}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Box>
              </Tooltip>
            </Box>
            <TextField
              // min={1}
              type="number"
              id="outlined-basic"
              className="alert-recieve-every-field"
              variant="outlined"
              value={isCreateAlert ? maxmNumValue : maxmNumberEmailsValue}
              error={formFieldErrors?.errAlertMax !== ""}
              onChange={(e) => {
                handleChangeAlertsMaximum(e);
              }}
              InputProps={{
                min: 1,
              }}
              disabled={!isCreateAlert}
            />
            <FormHelperError value={formFieldErrors?.errAlertMax} />
          </Box>
        </FormGroup>
      </Grid>
    </>
  );
};
export default AdavancedAlertsFields;
