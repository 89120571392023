import { faHandSparkles, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import postQuestion from "../../../../../images/monitor_revamp/postQues.svg";
import postQuestionAr from "../../../../../images/monitor_revamp/postQuesAr.svg";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import InsightsController from "services/controllers/insightsController";

const PostQuestionPopup = (props) => {
  const intl = useIntl();
  let { openPopup, setOpenPopup, setIsThemesPolicy } = props;
  const getPolicy = () => {
    InsightsController.getPolicy("themes").then((result) => {
      setIsThemesPolicy(false);
    });
  };

  const handelClosePopup = () => {
    setOpenPopup({ open: false, item: {} });
    getPolicy();
  };
  const popupBody = () => {
    return (
      <Box className="popup-body-container">
        <FontAwesomeIcon
          className="close-icon"
          onClick={handelClosePopup}
          icon={faTimes}
        />
        <Box className="content-box">
          <Typography component={"span"}>
            <FontAwesomeIcon icon={faHandSparkles} />
            {CheckValueLocale("new", "", {}, intl)}
          </Typography>
          <Typography component={"h2"}>
            {CheckValueLocale("mark_post_ques", "", {}, intl)}
          </Typography>
          <Typography>
            {CheckValueLocale("label_question_description", "", {}, intl)}
          </Typography>
        </Box>
        <Box className="img-box">
          {intl?.locale === "en" ? (
            <img
              src={postQuestion}
              alt="verify"
              className="post-question-img"
            />
          ) : (
            <img
              src={postQuestionAr}
              alt="verify"
              className="post-question-img"
            />
          )}
        </Box>
      </Box>
    );
  };
  return (
    <PopupModal
      body={popupBody()}
      open={openPopup?.open}
      fixPadding={true}
      close={() => handelClosePopup}
      classeName="post-qustion-popup"
    />
  );
};

export default PostQuestionPopup;
