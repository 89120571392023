import {
  faClockRotateLeft,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

export const LoadEngagementHistoryButton = ({
  show,
  isLoadingDmHistory,
  getInteractionDmHistory,
}) => {
  const intl = useIntl();

  if (!show) return null;
  return (
    <Box className="load-engagement-history-button">
      {isLoadingDmHistory ? (
        <Box className="history-spinner-container">
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </Box>
      ) : (
        <LucButton
          variant="text"
          size="small"
          startIcon={<FontAwesomeIcon icon={faClockRotateLeft} />}
          onClick={getInteractionDmHistory}
          id="load-engagement-full-history-dm"
        >
          {CheckValueLocale("load_engagement_history", "", {}, intl)}
        </LucButton>
      )}
    </Box>
  );
};
