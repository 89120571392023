import React from "react";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, Button, Tooltip, Divider, Alert } from "@mui/material";
import TopicCard from "./components/topicCard/topicCard";
import Snackbar from "components/snackBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faFileImport } from "@fortawesome/pro-light-svg-icons";

import TopicsFuntions from "./components/topicsFunctions";
import TopicImport from "./components/topicImport/topicImport";
import TopicManage from "./components/topicManage/topicManage";
import UpdateTopics from "../../../../../Monitors/Components/updateTopics";

import "./topic.scss";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const Topic = (props) => {
  const intl = useIntl();
  const {
    monitorTopics,
    setMonitorTopics,
    monitorSettings,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    updatedParams,
    setUpdatedParams,
    isMonitorSetting,
    addSeperatedLine,
    disabledManualTopic,
    manualTopicStats,
  } = props;
  const { handleAddTopic, checkManualTopicsEmptyArray, isEmptyTopicsArray } =
    TopicsFuntions();

  let topicMaxLength = monitorTopics?.length >= 8;
  let importTopicMaxLength = false,
    count = 0;

  monitorTopics.map((topic, index) => {
    if (topic.name !== "") {
      count = count + 1;
    }
  });

  importTopicMaxLength = count >= 8;

  const [isImportHover, setIsImportHover] = useState(false);
  const [isAddTopicHover, setIsAddTopicHover] = useState(false);

  const [showImportModal, setShowImportModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);

  const [openImportedSnakbar, setOpenImportedSnakbar] = useState(false);
  const [topicImported, setTopicImported] = useState(false);
  const [topicImportedName, setTopicImportedName] = useState("");
  const [topicImportedNumber, setTopicImportedNumber] = useState(0);

  const [openRemovedSnakbar, setOpenRemovedSnakbar] = useState(false);
  const [topicRemoved, setTopicRemoved] = useState(false);
  const [topicDeletedNumber, setTopicDeletedNumber] = useState(0);

  useEffect(() => {
    checkManualTopicsEmptyArray(monitorTopics, setMonitorTopics);
  }, [checkManualTopicsEmptyArray, monitorTopics, setMonitorTopics]);

  const handleImportPopUp = (event) => {
    if (event && !importTopicMaxLength) {
      setShowImportModal(true);
    } else {
      setShowImportModal(false);
    }
  };

  const handleManagePopUp = (event) => {
    if (event) {
      setShowManageModal(true);
    } else {
      setShowManageModal(false);
    }
  };

  const handleCloseSnackBar = () => {
    setOpenImportedSnakbar(false);
    setOpenRemovedSnakbar(false);
  };

  return (
    <div className="topic-style">
      {addSeperatedLine ? (
        <Divider sx={{ marginBottom: "40px", marginTop: "10px" }} />
      ) : (
        ""
      )}

      <Box
        className={
          monitorSettings ? "monitors-settings-box" : "topics-settings-box"
        }
      >
        <div className="topics-settings">
          <p
            className={
              monitorSettings
                ? "topics-settings-title display-in-row"
                : "display-in-row"
            }
          >
            {CheckValueLocale("topics_monitor_settings", "", {}, intl)}
            <div className="optional-style">
              ({CheckValueLocale("optional", "", {}, intl)})
            </div>
          </p>

          {!isEmptyTopicsArray && monitorTopics[0]?.name !== "" && (
            <p className="topics-settings-sub-title">
              {CheckValueLocale("topic_settings_label", "", {}, intl)}
            </p>
          )}
        </div>
        {!manualTopicStats?.limit_reached && (
          <div className={!isEmptyTopicsArray && "buttons-box"}>
            <div className="buttons-case">
              <span>
                <Tooltip
                  title={CheckValueLocale("import_btn_tooltip", "", {}, intl)}
                  placement="bottom"
                  arrow
                  open={importTopicMaxLength && isImportHover}
                >
                  <LucButton
                    variant="flat"
                    size="small"
                    id={
                      importTopicMaxLength && !monitorSettings
                        ? "disabled-import-file-btn"
                        : importTopicMaxLength && monitorSettings
                          ? "disabled-import-file-btn-settings"
                          : monitorSettings
                            ? "import-file-btn-settings"
                            : disabledManualTopic
                              ? "disabled-import-file-btn-settings"
                              : "import-file-btn"
                    }
                    onMouseOver={() => setIsImportHover(true)}
                    onMouseOut={() => setIsImportHover(false)}
                    onClick={(e) => handleImportPopUp(e)}
                    disabled={disabledManualTopic}
                    startIcon={<FontAwesomeIcon icon={faFileImport} />}
                  >
                    {CheckValueLocale("import", "", {}, intl)}
                  </LucButton>
                </Tooltip>
              </span>

              <span>
                <Tooltip
                  title={CheckValueLocale("import_btn_tooltip", "", {}, intl)}
                  placement="bottom"
                  arrow
                  open={topicMaxLength && isAddTopicHover}
                >
                  <LucButton
                    size="small"
                    id={
                      topicMaxLength && !monitorSettings
                        ? "disabled-add-topic-btn"
                        : topicMaxLength && monitorSettings
                          ? "disabled-add-topic-btn-settings"
                          : monitorSettings
                            ? "add-topic-btn-settings"
                            : disabledManualTopic
                              ? "disabled-add-topic-btn-settings"
                              : "add-topic-btn"
                    }
                    onMouseOver={() => setIsAddTopicHover(true)}
                    onMouseOut={() => setIsAddTopicHover(false)}
                    onClick={(event) =>
                      handleAddTopic(event, monitorTopics, setMonitorTopics)
                    }
                    disabled={disabledManualTopic}
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    {CheckValueLocale("add_topic", "", {}, intl)}
                  </LucButton>
                </Tooltip>
              </span>
            </div>
          </div>
        )}
      </Box>
      {/* Manage Topics Part */}
      {!disabledManualTopic ? (
        <>
          {manualTopicStats?.limit_reached && (
            <div
              className={
                monitorTopics?.length > 1
                  ? "manage-topics-alert-msg buttons-box "
                  : "buttons-box"
              }
            >
              <Alert
                id="manage-topics-alert"
                className="manage-topic-alert"
                severity="info"
              >
                <div className="manage-topic-text-msg">
                  {CheckValueLocale(
                    "manage_max_numbers_of_Topics",
                    "",
                    {},
                    intl,
                  )}
                </div>
                <div className="manage-topic-alert-btn">
                  <Button
                    variant="outlined"
                    id="monitor-options-manage-topic-alert-btn"
                    onClick={(e) => handleManagePopUp(e)}
                  >
                    {CheckValueLocale("manage", "", {}, intl)}
                  </Button>
                </div>
              </Alert>
            </div>
          )}
          {showImportModal && (
            <TopicImport
              monitorTopics={monitorTopics}
              monitorSettings={monitorSettings}
              updatedParams={updatedParams}
              setUpdatedParams={setUpdatedParams}
              setMonitorTopics={setMonitorTopics}
              handleOpenImportModal={setShowImportModal}
              openImportModal={showImportModal}
              setTopicImported={setTopicImported}
              setOpenSnakbar={setOpenImportedSnakbar}
              setTopicImportedName={setTopicImportedName}
              setTopicImportedNumber={setTopicImportedNumber}
            />
          )}
          {showManageModal && (
            <TopicManage
              monitorTopics={monitorTopics}
              setMonitorTopics={setMonitorTopics}
              handleOpenManageModal={setShowManageModal}
              openManageModal={showManageModal}
              setTopicRemoved={setTopicRemoved}
              setOpenSnakbar={setOpenRemovedSnakbar}
              setTopicDeletedNumber={setTopicDeletedNumber}
            />
          )}
          <Snackbar
            open={openImportedSnakbar}
            severity={topicImported ? "success" : "error"}
            message={
              topicImported
                ? CheckValueLocale(
                    "topics_success_msg_body",
                    "",
                    {
                      numberOfTopics: topicImportedNumber,
                      Name: topicImportedName,
                    },
                    intl,
                  )
                : CheckValueLocale("import_btn_tooltip", "", {}, intl)
            }
            title={
              topicImported
                ? CheckValueLocale("topics_success_msg_title", "", {}, intl)
                : CheckValueLocale("failed_error", "", {}, intl)
            }
            handleClose={handleCloseSnackBar}
            alertStyle={!topicImported ? { width: "70%" } : { width: "100%" }}
          />
          <Snackbar
            open={openRemovedSnakbar}
            severity={topicRemoved ? "success" : "error"}
            message={
              topicRemoved
                ? CheckValueLocale(
                    "topics_remove_success_msg_body",
                    "",
                    { numberOfTopics: topicDeletedNumber },
                    intl,
                  )
                : CheckValueLocale("try_again", "", {}, intl)
            }
            title={
              topicRemoved
                ? CheckValueLocale(
                    "topics_remove_success_msg_title",
                    "",
                    {},
                    intl,
                  )
                : CheckValueLocale("failed_error", "", {}, intl)
            }
            handleClose={handleCloseSnackBar}
          />
          <ul
            className={
              isEmptyTopicsArray || monitorTopics?.length === 1
                ? "remove-tree-style"
                : "topics-tree-arabic-subtitle topics-tree"
            }
          >
            <TopicCard
              monitorTopics={monitorTopics}
              setMonitorTopics={setMonitorTopics}
              monitorSettings={monitorSettings}
              errorKeywordsMsg={errorKeywordsMsg}
              setErrorKewyordsMsg={setErrorKewyordsMsg}
              errorExKeywordsMsg={errorExKeywordsMsg}
              setExErrorKewyordsMsg={setExErrorKewyordsMsg}
              displayNameMsgError={displayNameMsgError}
              setDisplayNameMsgError={setDisplayNameMsgError}
              updatedParams={updatedParams}
              setUpdatedParams={setUpdatedParams}
              isMonitorSetting={isMonitorSetting}
              isEmptyTopicsArray={isEmptyTopicsArray}
            />
          </ul>
        </>
      ) : (
        <UpdateTopics />
      )}
    </div>
  );
};
export default Topic;
