import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./inActiveQuestionAccordion.scss";
import { Box, Chip } from "@mui/material";

const InActiveQuestionAccordion = ({ titleHeader, steps, type, index }) => {
  const intl = useIntl();

  return (
    <>
      <Accordion className="luci-accordion-wrapper">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a_content`}
          id={`panel${index}d_content`}
          className="luci-accordion-header"
        >
          <Box className="header-container">
            <Typography className="prod-title">
              {CheckValueLocale(`${titleHeader}`, "", {}, intl)}
            </Typography>
            {titleHeader?.toLowerCase() === "utilities" && (
              <Chip
                label={CheckValueLocale("new", "", {}, intl)}
                color="success"
                className="utilities-new-badge"
              />
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className="luci-accordion-summary">
          {titleHeader?.toLowerCase() === "utilities" ? (
            type?.map((prod, index) => {
              return (
                <Box className="luci-utilites-container" key={index}>
                  <Typography component="h1" className="type-title">
                    {CheckValueLocale(`${prod?.typeTitle}`, "", {}, intl)}
                  </Typography>
                  <Typography>
                    <ol>
                      {prod?.steps?.map((item) => {
                        return (
                          <li>{CheckValueLocale(`${item}`, "", {}, intl)}</li>
                        );
                      })}
                    </ol>
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Typography>
              <ol>
                {steps?.map((item) => {
                  return <li>{CheckValueLocale(`${item}`, "", {}, intl)}</li>;
                })}
              </ol>
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default InActiveQuestionAccordion;
