import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

const DeletedMessagePreview = () => {
  const intl = useIntl();
  return (
    <Box component="p" className="data-content-text deleted-message">
      <DoNotDisturbIcon />
      <Box as="span" className="deleted-message-text">
        {CheckValueLocale("interactions_deleted_messages", "", {}, intl)}
      </Box>
    </Box>
  );
};

export default DeletedMessagePreview;
