import { Box, Stepper, Step, StepButton } from "@mui/material";
import "../createHeader/createHeader.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const Steppers = (props) => {
  const intl = useIntl();
  const {
    steps,
    activeStep,
    setActiveStep,
    completed,
    isValidDashboardInfo,
    createDashboard,
  } = props;

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // to handle upper stepper button in create Dashbaord | will be unclickable while dashboard name not typed yet
  let validHandleCreateStep = createDashboard && isValidDashboardInfo;

  return (
    <Box className="steppers">
      <Box className="steppers-width">
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                color="inherit"
                onClick={
                  createDashboard
                    ? validHandleCreateStep
                      ? handleStep(index)
                      : null
                    : handleStep(index)
                }
              >
                {CheckValueLocale(
                  label == "monitors" ? `${label}_channel` : label,
                  "",
                  {},
                  intl,
                )}
                {completed[index]}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default Steppers;
