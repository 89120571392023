import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
const Header = ({ setOpenSidebar }) => {
  const intl = useIntl();

  const handleClose = () => {
    setOpenSidebar(false);
  };

  return (
    <Box className="details-side-bar-header">
      <Box className="side-bar-name-wrapper">
        <span className="side-bar-name">
          {CheckValueLocale("ai_suggestions", "", {}, intl)}
        </span>
      </Box>
      <Box className="side-bar-close-wrapper" onClick={() => handleClose()}>
        <FontAwesomeIcon icon={faTimes} />
      </Box>
    </Box>
  );
};
export default Header;
