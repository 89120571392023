import { useEffect, useState } from "react";
import _ from "lodash";

const NpsProceedFunctions = (
  surveySections,
  setSurveySections,
  filteredSections,
  sectionIndex,
  question,
  questionIndex,
) => {
  const [openModal, setOpenModal] = useState(false);
  const [proceedValues, setProceedValues] = useState([]);
  const [tempProceedValues, setTempProceedValues] = useState([]);

  const [remainingValueGoTo, setRemainingValueGoTo] = useState(null);
  const [tempRemainingValueGoTo, setTempRemainingValueGoTo] = useState(null);

  const typesValues = {
    detractors: [0, 1, 2, 3, 4, 5, 6],
    passives: [7, 8],
    promoters: [9, 10],
  };

  const valuesList = [
    { label: "detractors_values_label", value: "detractors" },
    { label: "passives_values_label", value: "passives" },
    { label: "promoters_values_label", value: "promoters" },
  ];

  useEffect(() => {
    if (question?.items) {
      const areAllNextSectionsSame = question?.items?.every(
        (item, _, arr) => item?.next_section === arr?.[0]?.next_section,
      );

      if (areAllNextSectionsSame) {
        setProceedValues([{ value: null, next_section: null }]);
        setTempProceedValues([{ value: null, next_section: null }]);
        setTempRemainingValueGoTo(question?.items?.[0]?.next_section);
        setRemainingValueGoTo(question?.items?.[0]?.next_section);
      } else {
        const nextSecArr = [];

        const detractors = question?.items?.filter(
          (item, index) => item?.is_conditional && index >= 0 && index <= 6,
        );
        const passives = question?.items?.filter(
          (item, index) => item?.is_conditional && index >= 7 && index <= 8,
        );
        const promoters = question?.items?.filter(
          (item, index) => item?.is_conditional && index >= 9 && index <= 10,
        );

        const remainingValueGoTo = question?.items?.filter(
          (item) => !item?.is_conditional,
        )?.[0]?.next_section;
        setTempRemainingValueGoTo(remainingValueGoTo);
        setRemainingValueGoTo(remainingValueGoTo);

        if (detractors?.length)
          nextSecArr?.push({
            value: "detractors",
            next_section: detractors?.[0]?.next_section,
          });

        if (passives?.length)
          nextSecArr?.push({
            value: "passives",
            next_section: passives?.[0]?.next_section,
          });

        if (promoters?.length)
          nextSecArr?.push({
            value: "promoters",
            next_section: promoters?.[0]?.next_section,
          });

        setProceedValues(nextSecArr);
        setTempProceedValues(_.cloneDeep(nextSecArr));
      }
    }
  }, [question, openModal]);

  const handleChangeValue = (value, index) => {
    let updatedArray = tempProceedValues;
    updatedArray[index].value = value;
    setTempProceedValues([...(updatedArray || [])]);
  };

  const handleChangeGoTo = (value, index) => {
    let updatedArray = tempProceedValues;
    updatedArray[index].next_section = value;
    setTempProceedValues([...(updatedArray || [])]);
  };

  const handleRemoveCondition = (index) => {
    let updatedArray = tempProceedValues;

    if (updatedArray?.length === 1) {
      setTempProceedValues([{ value: null, next_section: null }]);
      return;
    } else {
      updatedArray?.splice(index, 1);
      setTempProceedValues([...(updatedArray || [])]);
    }
  };

  const showRemoveBtn =
    tempProceedValues?.length > 1 ||
    (tempProceedValues?.length === 1 &&
      (!!tempProceedValues?.[0]?.value ||
        !!tempProceedValues?.[0]?.next_section));

  const [remainingValues, setRemainingValues] = useState();

  useEffect(() => {
    if (tempProceedValues) {
      let remainingArr = ["detractors", "passives", "promoters"];

      tempProceedValues?.map((item) => {
        if (item?.value)
          remainingArr = remainingArr?.filter((item2) => item2 !== item?.value);
      });

      setRemainingValues(remainingArr);
    }
  }, [tempProceedValues]);

  const handleApply = async () => {
    let remainingItems = ["detractors", "passives", "promoters"];
    let npsItems = question?.items || [];
    tempProceedValues?.map((item) => {
      if (!!item?.value && !!item?.next_section) {
        typesValues[item?.value]?.map((val) => {
          npsItems[val].next_section = item?.next_section;
          npsItems[val].is_conditional = true;
        });
        remainingItems = remainingItems?.filter((val) => val !== item?.value);
      }
    });

    remainingItems?.map((item) => {
      typesValues[item]?.map((val) => {
        npsItems[val].next_section = tempRemainingValueGoTo;
        delete npsItems[val].is_conditional;
      });
    });

    filteredSections[sectionIndex].questions.filter(
      (ques) => !ques?.is_deleted,
    )[questionIndex].items = npsItems;
    setSurveySections([...surveySections]);
    await setOpenModal(false);
  };

  const handleDisabledApply = () => {
    if (
      tempRemainingValueGoTo == remainingValueGoTo &&
      _.isEqual(tempProceedValues, proceedValues)
    )
      return true; // Check if tempProceedValues == proceedValues (No Changes in Conditions)

    if (tempProceedValues?.length === 1) {
      if (Object?.values(tempProceedValues[0])?.every((item) => !item))
        return false; // Check if all values in tempProceedValues[0] are falsy

      return Object?.values(tempProceedValues[0])?.some((item) => !item); // Check for any falsy values in tempProceedValues[0]
    } else
      return tempProceedValues?.some((obj) =>
        Object?.values(obj)?.some((value) => !value),
      ); // Check if any object in tempProceedValues has any falsy value
  };

  const handleChangeProceedSwitch = (checked) => {
    const setNextFunc = (item) => {
      if (checked === true) {
        setOpenModal(true);
        item["next_section"] =
          sectionIndex === filteredSections?.length - 1
            ? -1
            : filteredSections[sectionIndex].order + 1;
      } else {
        setOpenModal(false);
        delete item["next_section"];
        delete item["is_conditional"];
      }
    };
    const filteredSectionsNotDeleted = filteredSections[
      sectionIndex
    ].questions.filter((question) => question?.is_deleted !== true);
    filteredSectionsNotDeleted?.[questionIndex].question.items.forEach(
      setNextFunc,
    );

    filteredSections[sectionIndex].questions.filter(
      (ques) => !ques?.is_deleted,
    )[questionIndex].is_proceed_on_answer = checked;
    setSurveySections([...surveySections]);
  };

  return {
    handleChangeProceedSwitch,
    openModal,
    setOpenModal,
    proceedValues,
    tempProceedValues,
    handleDisabledApply,
    handleApply,
    valuesList,
    setTempProceedValues,
    tempRemainingValueGoTo,
    setTempRemainingValueGoTo,
    remainingValues,
    handleChangeValue,
    handleChangeGoTo,
    handleRemoveCondition,
    showRemoveBtn,
  };
};

export default NpsProceedFunctions;
