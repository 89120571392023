import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-regular-svg-icons";
import Mylogo from "images/luci-images/logoLuci.svg";
import AppliedFiltersModal from "./appliedFiltersModal";
import tokensIcon from "images/luci-images/tokens-icon.svg";
import moment from "moment";
import { useEffect, useState } from "react";

const SummaryHeader = ({
  summaryLastQuestion,
  getQuestionStatus,
  isRemainingEnergy,
  getNextUpdateTime,
  calcDays,
  isUserThinking,
  pageParams,
  createLuciQuestion,
  openAppliedFiltersModal,
  handleAppliedFiltersModal,
  refreshCount,
  getSummaryStaticQuestions,
  staticQuestions,
  isQuestionInProgress,
  isDataAnalyze,
  isNoData,
  summaryWidgetDisabled,
  localizedDateTime,
}) => {
  const intl = useIntl();
  const [appliedFilters, setAppliedFilters] = useState({});
  const questionUpdateStatus = getQuestionStatus();

  const summaryRefreshDisabled =
    isUserThinking ||
    !isRemainingEnergy ||
    isQuestionInProgress ||
    isNoData ||
    isDataAnalyze;

  const handleRefreshSummary = () =>
    !summaryRefreshDisabled
      ? createLuciQuestion(pageParams, getSummaryStaticQuestions)
      : null;

  useEffect(() => {
    setAppliedFilters(pageParams);
  }, [refreshCount]);

  const getSummaryTooltip = () => {
    let summaryTooltipResult = "";

    if (isDataAnalyze) {
      summaryTooltipResult = CheckValueLocale("luci_analyze", "", {}, intl);
    } else if (isNoData) {
      summaryTooltipResult = CheckValueLocale(
        "luci_no_data_found",
        "",
        {},
        intl,
      );
    } else if (isUserThinking || isQuestionInProgress) {
      summaryTooltipResult = CheckValueLocale(
        "wait_luci_is_think",
        "",
        {},
        intl,
      );
    } else if (!isRemainingEnergy) {
      summaryTooltipResult = (
        <span className="summary-token-span">
          {CheckValueLocale("have_luci_no_energy", "", {}, intl)}
          <img src={tokensIcon} alt="token icon" />
          {CheckValueLocale("to_update_upgrade", "", {}, intl)}
        </span>
      );
    } else {
      summaryTooltipResult = (
        <span className="summary-token-span">
          {CheckValueLocale("update_summary", "", {}, intl)} ( -{" "}
          {staticQuestions?.[0]?.token}{" "}
          <img src={tokensIcon} alt="token icon" /> )
        </span>
      );
    }

    return summaryTooltipResult;
  };

  return (
    <Box className="summary-header-wrapper">
      <Box className="summary-header-description">
        <Box className="summary-header-img">
          <img src={Mylogo} alt="" />
        </Box>
        <Box className="summary-header-text">
          <Box className="summary-header-title" component="p">
            {CheckValueLocale("summary_by_luci", "", {}, intl)}
          </Box>
          <Box className="summary-header-hint" component="p">
            {CheckValueLocale("summary_hint", "", {}, intl)}:
            <Box component="span" onClick={handleAppliedFiltersModal}>
              {CheckValueLocale("view_applied_filters", "", {}, intl)}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="summary-header-info">
        <Box
          className={`summary-header-date ${summaryWidgetDisabled ? "summary-widget-disabled" : ""}`}
          component="p"
        >
          {CheckValueLocale("last_updated", "", {}, intl)} :{" "}
          <Box component="span" className="summary-date">
            {localizedDateTime(summaryLastQuestion?.attributes?.created_at)}
          </Box>
        </Box>
        <Tooltip
          title={
            !questionUpdateStatus && !summaryWidgetDisabled ? (
              <Box className="summary-span-tooltip" component="span">
                {CheckValueLocale("next_update_at", "", {}, intl)}{" "}
                <Box component="span" className="summary-date">
                  {getNextUpdateTime()}
                </Box>
              </Box>
            ) : (
              ""
            )
          }
          placement="top"
          arrow
        >
          <Box
            className={`summary-header-status 
              ${
                questionUpdateStatus
                  ? "summary-status-updated"
                  : "summary-status-outdated"
              }
              ${summaryWidgetDisabled ? "summary-widget-disabled" : ""}
            `}
            component="span"
          >
            {CheckValueLocale(
              questionUpdateStatus ? "updated" : "outdated",
              "",
              {},
              intl,
            )}
          </Box>
        </Tooltip>
        <Tooltip
          title={
            getSummaryTooltip() ? (
              <Box className="summary-span-tooltip" component="span">
                {getSummaryTooltip()}
              </Box>
            ) : null
          }
          placement="top"
          arrow
        >
          <Box
            className={`summary-header-refresh 
              ${summaryRefreshDisabled ? "summary-refresh-disabled" : ""}`}
            component="span"
            onClick={handleRefreshSummary}
          >
            <FontAwesomeIcon icon={faSync} />
          </Box>
        </Tooltip>
      </Box>
      {openAppliedFiltersModal ? (
        <AppliedFiltersModal
          appliedFilters={appliedFilters}
          calcDays={calcDays}
          open={openAppliedFiltersModal}
          handleAppliedFiltersModal={handleAppliedFiltersModal}
        />
      ) : null}
    </Box>
  );
};

export default SummaryHeader;
