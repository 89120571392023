import { Box, Typography } from "@mui/material";
import { SelectFieldDropdown } from "pages/settings/components/InboundRouting/components/selectFieldDropdown/selectFieldDropdown";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 188,
    },
  },
};

const agentsStatusOptions = [
  {
    name: "agent_status_available",
    color: "#4CAF50",
    id: "available",
  },
  {
    name: "agent_status_unavailable",
    color: "#ED6C02",
    id: "unavailable",
  },
];

export const AgentStatus = ({ updateAgentsStatus, value }) => {
  const intl = useIntl();
  const handleChange = (event) => {
    const status = event?.target?.value;
    updateAgentsStatus(status);
  };
  return (
    <Box className="agent-status-dropdown-container">
      <SelectFieldDropdown
        options={agentsStatusOptions}
        value={value}
        multiple={false}
        label={CheckValueLocale("agent_status_label", "", {}, intl)}
        onChange={handleChange}
        rootClassName="agent-status-dropdown-root"
        menuProps={MenuProps}
        renderItem={(option) => {
          return (
            <Box className="agent-status-dropdown-item">
              <Box
                className="agent-status-dropdown-item-circle"
                sx={{ background: option?.color }}
                component="span"
              />
              <Typography
                component="span"
                className="agent-status-dropdown-item-title"
              >
                {CheckValueLocale(option?.name, "", {}, intl)}
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};
