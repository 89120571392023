/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Add } from "@mui/icons-material";
import { Button, Typography, Box, Alert } from "@mui/material";

const DesignSystem = () => {
  return (
    <div
      css={css`
        max-width: 1140px;
        margin: auto;
        margin-top: 50px;
      `}
    >
      <Typography variant="h3">Design System</Typography>
      <Box m={10} />
      <Typography variant="h4">Buttons</Typography>
      <Box m={5} />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Button variant="contained">Primary Button</Button>
          <Box m={3} />
          <Button startIcon={<Add />} variant="contained">
            Primary Button
          </Button>
          <Box m={3} />
          <Button variant="contained" disabled>
            Primary Button
          </Button>
        </Box>

        <Box>
          <Button variant="outlined">Primary Button</Button>
          <Box m={3} />
          <Button startIcon={<Add />} variant="outlined">
            Primary Button
          </Button>
          <Box m={3} />
          <Button startIcon={<Add />} variant="outlined" disabled>
            Primary Button
          </Button>
        </Box>

        <Box>
          <Button variant="text">Primary Button</Button>
          <Box m={3} />
          <Button color="secondary" variant="text">
            Primary Button
          </Button>
          <Box m={3} />
          <Button startIcon={<Add />} variant="text" disabled>
            Primary Button
          </Button>
        </Box>

        <Box>
          <Button variant="contained" color="secondary">
            Primary Button
          </Button>
          <Box m={3} />
          <Button startIcon={<Add />} variant="outlined" color="secondary">
            Primary Button
          </Button>
          <Box m={3} />
          <Button startIcon={<Add />} variant="text" disabled color="secondary">
            Primary Button
          </Button>
        </Box>
      </Box>

      <Box m={10} />

      <Typography variant="h4">Small Buttons</Typography>
      <Box m={3} />
      <Box>
        <Button variant="outlined" color="primary" size="small">
          Request
        </Button>
        <Box m={3} />
        <Button variant="outlined" disabled color="primary" size="small">
          Request
        </Button>
        <Box m={3} />
        <Button variant="outlined" color="secondary" size="small">
          Requested
        </Button>
      </Box>

      <Box m={10} />
      <Typography variant="h4">Alerts</Typography>
      <Box m={3} />

      <Alert severity="error">This is an error alert — check it out!</Alert>
      <Box m={3} />
      <Alert severity="warning">This is a warning alert — check it out!</Alert>
      <Box m={3} />
      <Alert severity="info">This is an info alert — check it out!</Alert>
      <Box m={3} />
      <Alert severity="success">This is a success alert — check it out!</Alert>

      <Box m={10} />
    </div>
  );
};

export default DesignSystem;
