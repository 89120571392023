import "./hashtagsHeader.scss";
import HashtagsTitle from "./hashtagsTitle";
import HashtagsLimitReached from "./hashtagsLimitReached";
import { Box } from "@mui/material";
import StatisticBox from "./statisticBox";

const HashtagsHeader = ({ hashtagsHeader, hashtags, searchInput }) => {
  let statisticKeys = [
    "instagram_accounts",
    "hashtags_count",
    "active_hashtags",
    "inactive_hashtags",
    "registering_hashtags",
    "de_registering_hashtags",
  ];

  const statisticData = statisticKeys?.map((statistic) => {
    return {
      type: statistic,
      num:
        hashtagsHeader[statistic] !== undefined
          ? hashtagsHeader[statistic]
          : "-",
    };
  });

  const limitReached = hashtagsHeader?.limit_reached;

  return (
    <>
      <HashtagsTitle hashtags={hashtags} />
      {hashtags?.length > 0 || searchInput ? (
        <>
          {limitReached ? <HashtagsLimitReached /> : null}
          <Box className="statistics-wrapper">
            {statisticData?.map((statistic) => (
              <StatisticBox statistic={statistic} />
            ))}
          </Box>
        </>
      ) : null}
    </>
  );
};
export default HashtagsHeader;
