import { Button } from "@mui/material";
import { useIntl } from "react-intl";
import CircularLoading from "components/circularProgress";
import { CheckValueLocale } from "utils/helpers/index";
import PopupModal from "components/popupModal.js";
import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import AuthController from "services/controllers/authController";
import "./genesysPopUp.scss";

const GenesysPopup = (props) => {
  const intl = useIntl();
  const {
    setOpenGenesysModal,
    genesysDataSourceId,
    setFailSnackBarData,
    setOpenSnackBarAllSource,
  } = props;
  const [genesysRegions, setGenesysRegions] = useState([]);
  const [genesysRegionsLoading, setGenesysRegionsLoading] = useState(true);
  const [genesysInformerLoading, setGenesysInformerLoading] = useState(true);
  const [genesysRegionType, setGenesysRegionType] = useState("");
  const [genesysInformerData, setGenesysInformerData] = useState({});
  const [genesysInformerClientId, setGenesysInformerClientId] = useState("");
  const [genesysClientSecret, setGenesysClientSecret] = useState("");
  let genesysPopupLoader = genesysRegionsLoading || genesysInformerLoading;
  let disabledOption =
    genesysRegionType === "" ||
    genesysInformerClientId?.length === 0 ||
    genesysClientSecret?.length === 0;

  const modalBody = (
    <Box className="genesys-pop-up">
      {genesysPopupLoader ? (
        <CircularLoading />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box className="input-form-control">
              <InputLabel className="genesys-pop-up-title">
                {CheckValueLocale("genesys_regions_title", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
              </InputLabel>
              <Select
                value={genesysRegionType}
                onChange={(e) => setGenesysRegionType(e.target.value)}
                fullWidth
                className="genesys-regions-form-select"
                labelId="region-type-type-select-label"
                id="region-type-simple-select"
                displayEmpty
              >
                <MenuItem disabled value="" className="region-field-info">
                  {CheckValueLocale("genesys_select", "", {}, intl)}
                </MenuItem>
                {genesysRegions?.map((region) => (
                  <MenuItem
                    className="genesys-form-field"
                    key={`region-type-${region?.attributes?.id}`}
                    value={region?.attributes?.id}
                  >
                    {region?.attributes?.region_name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className="input-form-control">
              <InputLabel className="genesys-pop-up-title">
                {CheckValueLocale("genesys_client_id_title", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                id="fullWidth"
                className="gensys-client-id-field"
                value={genesysInformerClientId}
                onChange={(e) => setGenesysInformerClientId(e.target?.value)}
                placeholder={CheckValueLocale(
                  "genesys_client_id_placeholder",
                  "",
                  {},
                  intl,
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className="input-form-control">
              <InputLabel className="genesys-pop-up-title">
                {CheckValueLocale("genesys_client_secret_title", "", {}, intl)}
                <Typography component="span" className="required">
                  *
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                id="fullWidth"
                className="gensys-client-id-field"
                value={genesysClientSecret}
                onChange={(e) => setGenesysClientSecret(e.target?.value)}
                placeholder={CheckValueLocale(
                  "genesys_client_secret_placeholder",
                  "",
                  {},
                  intl,
                )}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );

  useEffect(() => {
    genesysAuth();
  }, []);

  const genesysAuth = (redirectUrl) => {
    setGenesysRegionsLoading(true);
    setGenesysInformerLoading(true);
    AuthController.getGenesysRegions(
      window.localStorage?.cxm_id,
      genesysDataSourceId,
    ).then((data) => {
      setGenesysRegions(data?.data);
      setGenesysRegionsLoading(false);
      data?.data?.map((val) => {
        val?.attributes?.selected == true &&
          setGenesysRegionType(val?.attributes?.id);
      });
    });
    AuthController.getGenesysInformer(
      window.localStorage?.cxm_id,
      genesysDataSourceId,
    ).then((data) => {
      setGenesysInformerData(data?.data);
      setGenesysInformerClientId(data?.data?.client_id);
      setGenesysClientSecret(data?.data?.client_secret);
      setGenesysInformerLoading(false);
    });
  };
  const genesysIntegrations = () => {
    AuthController.genesysIntegrations(
      window.localStorage?.cxm_id,
      genesysDataSourceId,
      genesysInformerClientId,
      genesysClientSecret,
      genesysRegionType,
    ).then((data) => {
      let env = data?.data?.environment;
      let clientId = data?.data?.client_id;
      let redirectUrl = window.location.href;
      let redirect =
        `https://login.${env}/oauth/authorize?` +
        "response_type=code" +
        "&client_id=" +
        clientId +
        "&redirect_uri=" +
        redirectUrl;
      if (env && clientId && data?.status == 200) {
        window.location.href = redirect;
        props?.setSocialLocalStorages && props?.setSocialLocalStorages();
      } else if (data?.errorMsg) {
        setFailSnackBarData({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
        });
        handleCloseCreateModal();
        setOpenSnackBarAllSource(true);
      }
    });
  };

  const handleCloseCreateModal = () => {
    setOpenGenesysModal(false);
  };
  return (
    <>
      {props?.openGenesysModal ? (
        <PopupModal
          title={CheckValueLocale("genesys_pop_up_title", "", {}, intl)}
          body={modalBody}
          open={props?.openGenesysModal}
          leftBtn={CheckValueLocale("genesys_cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("genesys_next", "", {}, intl)}
          accept={genesysIntegrations}
          close={handleCloseCreateModal}
          classeName="genesys-popup"
          height="325px"
          minWidth="600px"
          disabled={disabledOption}
        />
      ) : null}
    </>
  );
};

export default GenesysPopup;
