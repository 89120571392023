import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { truncate } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import AvatarImg from "images/custom-dashboard/avatar.svg";

const AccountCard = (props) => {
  const { id, name, selected, unselected, handleClick } = props;

  // Any edits in this card will affect Custom Dashboard ----- Editor (Manage Dialog) -----
  return (
    <Box
      id="custom-dashboard-account-card"
      className={`${unselected ? "unselected" : selected ? "selected" : ""}`}
      onClick={() => handleClick(id)}
    >
      <img src={AvatarImg} alt="avatar" />
      <Tooltip
        arrow
        placement="top"
        title={
          name?.length > 12 || ((selected || unselected) && name?.length > 9)
            ? name
            : ""
        }
      >
        <Typography className="account-name">
          {truncate(name, selected || unselected ? 9 : 12)}
        </Typography>
      </Tooltip>
      <Box className="selected-check">
        <FontAwesomeIcon icon={faCheck} size="small" />
      </Box>
    </Box>
  );
};

export default AccountCard;
