let personalityData = {
  ESFP: {
    description:
      "Outgoing, friendly and accepting. Exuberant lovers of life and people",
    strength: ["Working with others", "Uses common sense", "Adapting"],
    weakness: [
      "Long-term commitments",
      "Does not take criticism well",
      "Takes things personally",
    ],
    description_ar: "ودود، محبوب ومتقبل للآخرين. محب للحياة ومن حولهم",
    strength_ar: [
      "العمل مع الآخرين",
      "الاعتماد على المنطق السليم",
      "التأقلم والتكيّف",
    ],
    weakness_ar: [
      "الالتزامات طويلة المدى",
      "عدم تقبل النقد بشكل جيد",
      "أخذ الأمور بشكل شخصي",
    ],
  },
  ESTP: {
    description:
      "Take pragmatic approaches. Enjoy material comforts and styles",
    strength: ["Flexible & tolerant", "Focus on the present", "Learn by doing"],
    weakness: ["Easily bored", "Uknowingly insensitive"],
    description_ar:
      "بفضل الطرق والأساليب الواقعية. يستمتع بالمادّيات بأشكالها المختلفة",
    strength_ar: [
      "المرونة وتقبل الآخر",
      "التركيز على الحاضر",
      "التعلم بالعمل والتطبيق",
    ],
    weakness_ar: ["الملل بسهولة", "عدم احساس لا شعورياً"],
  },
  ISFP: {
    description:
      "Quick, alert and outspoken. Resourceful in solving new and challenging problems",
    strength: ["Loyal & comitted", "Laid back & adaptable", "Good listener"],
    weakness: ["Shies away from conflict", "Hard to get to know", "Withdrawn"],
    description_ar: "هادئ، محبوب، حساس وطيب. يستمتع باللحظة الحاضرة",
    strength_ar: ["الاخلاص والاتزام", "الاسترخاء والتأقلم", "الاستماع الجيد"],
    weakness_ar: [
      "التهرب من المواجهات",
      "صعوبة تعرّف الآخرين إليهم",
      " الانسحاب والانعزال",
    ],
  },
  ISTP: {
    description:
      "Analyzes what makes things work and can organize large amounts of data",
    strength: ["Self-reliant", "Handles conflict well", "Efficient"],
    weakness: ["Emotionally uncomfortable", "Long-term planning"],
    description_ar:
      "يحلل الطرق والأساليب المجدية والفعالة ولديه القدرة على التعامل مع البيانات الضخمة",
    strength_ar: [
      "الكفاءة والإنتاجية",
      "التعامل الجيد مع الخلافات",
      "الاعتماد على الذات",
    ],
    weakness_ar: [
      "التخطيط طويل المدى",
      "عدم التعبير عن العواطف",
      "عدم الاستقرار عاطفياً",
    ],
  },

  ESFJ: {
    description:
      "Warmhearted, conscientious and cooperative. Want harmony in life",
    strength: [
      "Focus on other's needs",
      "Money managment",
      "Honors commitments",
    ],
    weakness: [
      "Dislikes change",
      "Takes blame for others",
      "Trouble with conflict",
    ],
    description_ar: "حنون، ذو ضمير ومتعاون. يبحث عن التناغم في الحياة",
    strength_ar: [
      "تقدير الالتزام",
      "الإدارة المالية",
      "التركيز على احتياجات الآخرين",
    ],
    weakness_ar: [
      "الانزعاج من الخلافات",
      "تحمل اللوم عن الآخرين",
      "لا يحب التغيير",
    ],
  },
  ESTJ: {
    description:
      "Practical, realistic and matter-of-fact. Clear set of logical standards",
    strength: ["Loyal & comitted", "Social & enthusiastic", "Born leader"],
    weakness: [
      "Expressing feeling",
      "Can be blunt & insensitive",
      "Like to always be right",
    ],
    description_ar: "عملي، واقعي ويتعامل بالحقائق والضوابط المنطقية",
    strength_ar: ["قائد بالفطرة", "اجتماعي ومتحمس", "الاخلاص والالتزام"],
    weakness_ar: [
      "الفظاظة واللامبالاة أحياناً",
      "التعبير عن الشعور",
      "الحرص على أن يكون صحيح دائماً",
    ],
  },
  ISFJ: {
    description:
      "Quiet, friendly and responsible. Notice and remember specifics about people they care about",
    strength: ["Good listener", "Eager to serve", "Great organization"],
    weakness: [
      "- Neglect own needs",
      "- Dislikes change",
      "- Takes criticism personally",
    ],
    description_ar:
      "هادئ، محبوب، ويتحمل المسؤولية. ينتبه ويتذكر أدق التفاصيل بخصوص من يهتم بهم",
    strength_ar: ["التنظيم الجيد", "حب المساعدة والخدمة", "الاستماع الجيد"],
    weakness_ar: [
      "يأخذ النقد بشكل شخصي",
      "لا يحب التغيير",
      "ينسى احتياجاته الشخصية",
    ],
  },
  ISTJ: {
    description:
      "Quiet, serious and very responsible. Value traditions and loyalty",
    strength: ["Orderly & oragnized", "Handles criticism well"],
    weakness: ["Too rigid", "Needs to be right", "Not in tune with feelings"],
    description_ar:
      "هادئ، جاد ويتحمل المسؤولية. يقدّر القيم الأصيلة والاخلاص والولاء",
    strength_ar: ["مستمع جيد", "يتلقى النقد بشكل جيد", "منظم ومرتب"],
    weakness_ar: [
      "صلب وغير مرن",
      "الحرص على أن يكون صحيح دائماً",
      "يصعب عليه فهم مشاعره ومشاعر غيره",
    ],
  },

  ENFJ: {
    description:
      "Warm, empathetic, and responsible. FInds potential in everyone and provides inspiring leadership",
    strength: [
      "Communication Affectionate",
      "Affectionate & loya",
      "Honors commitments",
    ],
    weakness: [
      "Harbors hurt feelings",
      "Tendency to manipulate",
      "Tendency to smother",
    ],
    description_ar:
      "ودود، متعاطف، ويتحمل المسؤولية. يبحث عن قدرات وإمكانيات الآخرين ويقودهم بإلهام",
    strength_ar: ["يقدّر الالتزام", "محب ومخلص", "التواصل"],
    weakness_ar: [
      "الميل لتوجيه الأسئلة والأوامر بكثرة",
      "الميل للتلاعب والتحايل",
      "كتم المشاعر السلبية",
    ],
  },
  ENFP: {
    description:
      "Warmly enthusiastic and imaginative. Spontaneous and flexible and can improvise confidently",
    strength: ["Fun & optimistic", "Read others well", "Communication"],
    weakness: ["Trouble with conflict", "Can be maniulated", "Easily bored"],
    description_ar:
      "متحمس وشغوف وواسع الخيال. عفوي ومرن ولديه القدرة على الارتجال بكل ثقة",
    strength_ar: ["التواصل", "يفهم الآخرين بشكل جيد", "مرح ومتفائل"],
    weakness_ar: [
      "يميل للتلاعب والتحايل",
      "لديه صعوبة في الخلافات",
      "يميل للملل بسهولة",
    ],
  },
  INFJ: {
    description:
      "Seek meaning in all things. Want to understand what motivates people",
    strength: ["Good listener", "Communication", "Insightful"],
    weakness: ["Trouble with conflict", "Can be maniulated", "Easily bored"],
    description_ar: "يبحث عن معنى لكل شيء. يهتم بما يحفز الآخرين حوله",
    strength_ar: ["الاطلاع والرؤية الثاقبة", "التواصل", "الاستماع الجيد"],
    weakness_ar: [
      "يميل للملل بسهولة",
      "يميل للتلاعب والتحايل",
      "لديه صعوبة في الخلافات",
    ],
  },
  INFP: {
    description:
      "Seek to understand people and help fulfill their potential. Curious and quick to see possibilities",
    strength: ["Loyal", "Loving & caring", "Reading other's feelings"],
    weakness: [
      "Reacts emotionally",
      "Extreme dislikes of criticism",
      "Blames themselves",
    ],
    description_ar:
      "يرغب في فهم الآخرين ومساعدتهم في الاستفادة من قدراتهم وإمكانياتهم",
    strength_ar: ["يتفهم شعور الآخرين", "محب ومهتم", "مخلص"],
    weakness_ar: [
      "يميل لإلقاء اللوم على نفسه",
      "عدم تقبّل النقد وبشدّة",
      "ردات الفعل العاطفية",
    ],
  },

  ENTJ: {
    description:
      "Frank, decisive, and notices inefficiencies. Enjoys expanding knowledge and sharing it with others",
    strength: ["Excellent with money", "Takes criticism well", "Goal setting"],
    weakness: ["Controlling & intimidating", "Appears angry", "Impulsive"],
    description_ar:
      "صادق، حاسم ويتخذ قراراته سريعاً، وينتبه لمواطن قلة أو عدم الكفاءة. يستمتع بالتوسع في العلم ومشاركته مع الآخرين",
    strength_ar: [
      "يضع أهدافه بوضوح",
      "يتقبل النقد بشكل جيد",
      "جيد في التعامل مع المال",
    ],
    weakness_ar: ["مندفع", "يبدو عليه الغضب", "متحكم ومسيطر"],
  },
  ENTP: {
    description:
      "Quick, alert and outspoken. Resourceful in solving new and challenging problems",
    strength: ["Communication", "Liad back", "Generating ideas"],
    weakness: ["Poor follow-up skills", "Easily bored", "Argumentative"],
    description_ar:
      "سريع، متيقظ وصريح. غزير الأفكار عند حل المشاكل الجديدة والعصيبة",
    strength_ar: ["مبدع بالأفكار", "مسترخٍ", "التواصل"],
    weakness_ar: [
      "يميل لملل بسهولة",
      "صعوبة في المتابعة في التواصل",
      "يميل لكثرة الجدل",
    ],
  },
  INTJ: {
    description:
      "Strong drivefor implementing their ideas and achieving their goals",
    strength: ["Highly intelligent", "Honors commitments", "Independent"],
    weakness: ["Unwelling to take blame", "Arrogant", "Insesitive"],
    description_ar: "حماس وإصرار نحو تنفيذ أفكاره وتحقيق أهدافه",
    strength_ar: ["مستقل", "يقدّر الالتزام", "عالي الذكاء"],
    weakness_ar: ["غير متعاطف", "مغرور", "غير متقبل للوم"],
  },
  INTP: {
    description:
      "Seek to develop logical explanations for everything that interests them. Queit, contained and analytical",
    strength: ["Liad back", "Not demanding", "Imaginative & creative"],
    weakness: ["Explosive", "Distrusing of others", "Critical of others"],
    description_ar:
      "يحرص على الفهم العقلاني والمنطقي لكل ما يهتم به. هادئ، متروّي، ويحلّل الأمور بشكل جيد",
    strength_ar: ["مبدع واسع الخيال", "غير متطلب", "مسترخٍ"],
    weakness_ar: [
      "كثير الانتقاد للآخرين",
      "صعب الثقة في الآخرين",
      "طاقته متفجرةً",
    ],
  },
};

export default personalityData;
