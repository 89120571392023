import React from "react";
import "./style.scss";
import { Box, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import { DrawerContainer } from "./dashboard.styles.js";
import SideMenuTabs from "./sideBarTabs";
import dashboardEdit from "images/dashboardEdit.svg";
import dashboardIcon from "images/dashboardIcon.svg";
import { useHistory } from "react-router-dom";
import {
  getSocialIcon,
  CheckValueLocale,
  handlelUserRoles,
} from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";

const DashboardSideBar = (props) => {
  const history = useHistory();
  const { dashboardDynamicData, dashboardID, monitorType } = props;
  const intl = useIntl();

  let adminORDashboardOwner =
    (handlelUserRoles("SM", "EDIT_DASHBOARD") &&
      handlelUserRoles("SM", "DESTROY_DASHBOARD")) ||
    (handlelUserRoles("SM", "EDIT_DASHBOARD") &&
      dashboardDynamicData?.dashboard_owner);

  return (
    <>
      {/* Dashboards SIDE MENU */}
      <DrawerContainer
        anchor={window.localStorage.lang !== "ar" ? "left" : "right"}
        open={true}
        variant="persistent"
        className="dashboard-sidebar"
      >
        {/* Side Menu Header_______________________________________________________ */}
        <Box className="dashboard-details">
          <Box className="dashboard-name">
            <Box className="aggregate-btn">
              <img src={dashboardIcon} alt="aggregate-dashboard" />
            </Box>
            <Box component="span">
              {CheckValueLocale("sidebar_aggregate", "", {}, intl)}
            </Box>
            {adminORDashboardOwner && (
              <Box className="aggregate-dashboard-btn" id="dashboard-edit-icon">
                <Button
                  disableRipple
                  onClick={() => {
                    history.push({
                      pathname: `/dashboard/edit_dashboard/${dashboardID}`,
                      state: {
                        sideBarData: dashboardDynamicData?.page_side_bar,
                        monitorType: monitorType,
                      },
                    });
                  }}
                  id="dashboard-sidebar-edit-dashboard-btn"
                >
                  <img src={dashboardEdit} alt={"Aggregate"} />
                </Button>
              </Box>
            )}
          </Box>
          <Box className="dashboard-datasources-style display-in-row">
            <Box className="datasources-heading">
              {CheckValueLocale("dashboard_datasources", "", {}, intl)}:
            </Box>
            <Box className="icons-dashboard-page">
              {dashboardDynamicData?.data_sources?.map((social, i) => {
                return getSocialIcon(social);
              })}
            </Box>
          </Box>
        </Box>
        <Divider className="dashboard-divider" />
        {/* ________________________________________________________ */}

        <SideMenuTabs {...props} />
      </DrawerContainer>
    </>
  );
};
export default DashboardSideBar;
