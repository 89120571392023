import React from "react";
import * as fileSaver from "file-saver";
import * as xlsx from "xlsx";
import { excelGeneration } from "./excelGeneration";
import { CheckValueLocale } from "utils/helpers";

const exportExcel = (props, fileName, selectedLanguage, intl, setSnackBar) => {
  const fileType = "xlsx"; //XLSX file type
  let fileDetail = excelGeneration(props, selectedLanguage);
  fileDetail?.map((item, index) => {
    // loop on data that come from BE to convert it as JSON [ XLSX ]
    item["json"] = xlsx?.utils?.json_to_sheet(item[0]?.data);
  });
  const sheetsDataNames = { Sheets: {}, SheetNames: [] };
  fileDetail?.map((item, index) => {
    return (
      (sheetsDataNames.Sheets[item[0]?.name] = item?.json), // sheet JSON Data push to every item
      sheetsDataNames?.SheetNames?.push(item[0]?.name) // sheet Name
    );
  });
  const sheetData = { ...sheetsDataNames };
  const excelBuffer = xlsx?.write(sheetData, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  fileSaver?.saveAs(data, `${fileName ? fileName : "benchmark"}.xlsx`);
  setSnackBar({
    open: true,
    title: CheckValueLocale("benchmark_export_success_title", "", {}, intl),
    msg: "",
    severity: "success",
  });
};

export default exportExcel;
