import { styled } from "@mui/material/styles";
import {
  ListItem,
  Drawer,
  AccordionSummary,
  ListItemText,
  Accordion,
  Container,
  Button,
} from "@mui/material";

/*---------------------------------------------------------*/

export const MonitorContainer = styled(Container)`
  padding-right: 250px !important ;
  padding-left: 250px !important;
`;
export const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    max-width: 220px;
    display: flex;
    align-items: center;
    position: relative;
  }
  position: fixed;
  top: 60px;
  overflow: hidden;
  height: 100%;
  width: 220px;
  left: 0 !important;
  right: 0;
  .MuiDivider-root {
    margin-left: 25px;
    margin-right: 25px;
  }
`;
export const ListItemTextBtn = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: 10px;
    color: #64748b;
    font-weight: 500;
    font-style: normal;
    line-height: 10px;
    margin-top: 5px;
    text-align: center;
  }
`;
export const AccordionSummaryMonitor = styled(AccordionSummary)`
  padding-inline-start: 20px;
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    background-color: transparent;
  }
  min-height: 55px !important;
  height: 55px;
  margin-bottom: 10px;
  svg {
    font-size: 16px;
    color: #64748b;
    line-height: 20px;
  }
`;
export const ListItemWrapper = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px;
  .MuiPaper-root {
    box-shadow: none;
    background-color: transparent;
  }
  &:hover {
    background-color: transparent !important;
  }
`;
export const AccordionWrapper = styled(Accordion)`
  .Mui-expanded {
    .MuiTypography-root {
      color: #2aaae2;
    }
  }
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;
export const MonitorButton = styled(Button)`
  background: #2aaae2;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: #2aaae2;
  }
`;
