import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { TeamMembers } from "../../components/teamMembers";
import { useShowMore } from "hooks/useShowMore";
import { ShowMoreButton } from "../../components/showMoreButton";
import CompaniesController from "services/controllers/companiesController";
import { TeamMembersEmptyCase } from "./teamMembersEmptyCase";

export const TeamMembersDetails = ({ members, setMembers, teamId }) => {
  const intl = useIntl();
  const {
    isSettled,
    setIsSettled,
    setShowedData: setShowedMembers,
    showedData: showedMembers,
    showMore,
    setShowMore,
    isLoading,
    setIsLoading,
    onShowLess,
    nextPage,
    hasPagination,
  } = useShowMore({
    data: members?.data,
    pagination: members?.pagination,
  });

  const onShowMore = () => {
    if (isSettled) {
      setShowedMembers(members?.data);
      setShowMore(false);
      return;
    }
    setIsLoading(true);
    CompaniesController.getSelectedTeamMembers(teamId, nextPage).then((res) => {
      if (!res?.errorMsg) {
        const newMembers = res?.data;
        const newMembersData = [...members?.data, ...newMembers?.data];
        const nextPage = newMembers?.pagination?.next;
        setMembers({
          data: newMembersData,
          pagination: newMembers?.pagination,
        });
        setShowedMembers(newMembersData);
        if (!nextPage) {
          setIsSettled(true);
          setShowMore(false);
        }
      }
      setIsLoading(false);
    });
  };
  return (
    <Box className="view-team-details-section">
      {/* Team Members */}
      <Typography variant="h3" className="view-team-details-title">
        {CheckValueLocale("members_details", "", {}, intl)}
      </Typography>
      <Box className="view-team-details-content">
        {showedMembers?.length ? (
          <>
            <TeamMembers members={showedMembers} selectable={false} />
            <ShowMoreButton
              show={hasPagination}
              loading={isLoading}
              hasMore={showMore}
              onShowLess={onShowLess}
              onShowMore={onShowMore}
            />
          </>
        ) : (
          <TeamMembersEmptyCase id={teamId} />
        )}
      </Box>
    </Box>
  );
};
