import { Box, Typography } from "@mui/material";
import { getGmtOffsetDate, CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const DashboardInfo = (props) => {
  const intl = useIntl();
  const { dashboardDetails, truncateName } = props;

  const infoBoxesMonitors = [
    {
      title: "dashboard_type",
      data: dashboardDetails?.attributes?.dashboard_type
        ? CheckValueLocale(
            dashboardDetails?.attributes?.dashboard_type,
            "",
            {},
            intl,
          )
        : "",
    },
    {
      title: "creator",
      data: dashboardDetails?.attributes?.added_by
        ? truncateName(dashboardDetails?.attributes?.added_by, 30, {}, intl)
        : "",
    },
    {
      title: "start_dates",
      data: getGmtOffsetDate(
        dashboardDetails?.attributes?.created_at_in_unix
          ? dashboardDetails?.attributes?.created_at_in_unix
          : "",
        false,
        "DD/MM/YYYY",
        true,
      ),
    },
  ];

  return (
    <>
      <Typography className="section-header">
        {CheckValueLocale("dashboard_information", "", {}, intl)}
      </Typography>
      <Box className="info-section">
        {infoBoxesMonitors?.map((info, index) => {
          return (
            <Box className="info-box" key={index}>
              <Box>
                <Typography>
                  {info?.title
                    ? CheckValueLocale(info?.title, "", {}, intl)
                    : ""}
                </Typography>
              </Box>
              <Box component="span" className="main-titles">
                {info?.data}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default DashboardInfo;
