import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers/index";
import CompaniesController from "services/controllers/companiesController";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import IpChip from "./ipChip";
import IpPopupModal from "./ipPopupModal";
import CircularLoading from "components/circularProgress";
import { LucidyaApisContext } from "../lucidyaApisContext";
import { ipValidation, prefixValidation } from "./validationApi";
import { lucidyaApisTypeLocale } from "../apisList/apisListBodyFunctions";
import LucButton from "shared/lucButton/lucButton";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 304,
      width: 300,
    },
  },
};

const CreateForm = ({ setGenerateApi, setApikeyResponse }) => {
  const intl = useIntl();
  const {
    handleCreateApiDialog,
    getLucidyaApisList,
    setSuccess,
    setSnackBarHeading,
    apiDialogType,
    apiDialogItem,
    setPage,
    apisListData,
  } = useContext(LucidyaApisContext);
  const ipSubnetArrLength = 7;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSingle, setIsLoadingSingle] = useState(true);
  const [focused, setFocused] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [lucidyaApisTypeNames, setLucidyaApisTypeNames] = useState([]);
  const [isLoadingapiTypes, setIsLoadingapiTypes] = useState(true);
  const [editApiType, setEditApiType] = useState([]);
  const [apiData, setApiData] = useState({
    id: "",
    api_type: [],
    token_name: "",
    ip_subnet: "",
    ip_subnet_arr: ["0.0.0.0/0"],
  });
  const [error, setError] = useState({
    token_name: "",
    ip_subnet: "",
  });

  var activeProducts = JSON.parse(window?.localStorage?.activeProducts);
  let lucidyaApisActive = activeProducts?.find(
    (product) => product?.name == "PUBLIC_API",
  );
  let checkTokenName = apisListData?.map((el) => el?.attributes?.token_name);
  useEffect(
    () =>
      setDisabledBtn(
        !apiData?.token_name ||
          apiData?.ip_subnet_arr.length == 0 ||
          Object.values(error).some((v) => v) ||
          apiData?.api_type.length == 0,
      ),
    [apiData, error],
  );

  const handleApiNameChange = (e) => {
    let apiName = e?.target?.value;
    if (apiName === "") {
      setError({
        ...error,
        token_name: CheckValueLocale("api_name_field_required", "", {}, intl),
      });
    } else {
      if (
        isPublicSpecialCharactersOrEmojis(apiName, undefined, true) ||
        removeSpecialCharacters(apiName)?.length === 0
      ) {
        setError({
          ...error,
          token_name: CheckValueLocale("spam_keywords_rules", "", {}, intl),
        });
      }

      if (
        !isPublicSpecialCharactersOrEmojis(apiName, undefined, true) &&
        !(removeSpecialCharacters(apiName)?.length === 0)
      ) {
        setError({
          ...error,
          token_name: "",
        });

        if (apiName?.length > 100) {
          setError({
            ...error,
            token_name: CheckValueLocale(
              "api_name_max_length_error",
              "",
              {},
              intl,
            ),
          });
        }
        if (apiName?.length < 3) {
          setError({
            ...error,
            token_name: CheckValueLocale(
              "api_name_min_length_error",
              "",
              {},
              intl,
            ),
          });
        }
      }
    }
    setApiData({ ...apiData, token_name: apiName });
  };

  const handleIpSubnetChange = (ip = apiData.ip_subnet) => {
    const ipSubnet = ip;
    if (ipSubnet === "") {
      setError({
        ...error,
        ip_subnet:
          apiData?.ip_subnet_arr?.length === 0
            ? CheckValueLocale("ip_subnet_field_required", "", {}, intl)
            : "",
      });
    } else if (apiData?.ip_subnet_arr?.includes(ipSubnet)) {
      setError({
        ...error,
        ip_subnet: CheckValueLocale("ip_subnet_canot_duplicated", "", {}, intl),
      });
    } else if (ipSubnet.includes("/")) {
      let ipSubnetArr = ipSubnet?.split("/");
      if (ipValidation(ipSubnetArr[0]) && prefixValidation(ipSubnetArr[1])) {
        setError({
          ...error,
          ip_subnet: "",
        });
      } else {
        setError({
          ...error,
          ip_subnet: CheckValueLocale("ip_invalid_format", "", {}, intl),
        });
      }
    } else {
      if (ipValidation(ipSubnet)) {
        setError({
          ...error,
          ip_subnet: "",
        });
      } else {
        setError({
          ...error,
          ip_subnet: CheckValueLocale("ip_invalid_format", "", {}, intl),
        });
      }
    }
    setApiData({ ...apiData, ip_subnet: ipSubnet });
  };

  const handleApiTypeChange = (e) => {
    let apiType = e?.target?.value;
    if (apiType?.length < apiData?.api_type?.length && apiType?.length === 0) {
      setError({
        ...error,
        api_type: CheckValueLocale("api_type_field_required", "", {}, intl),
      });
    } else {
      setError({
        ...error,
        api_type: "",
      });
    }
    setApiData({ ...apiData, api_type: apiType });
  };

  useEffect(() => {
    apiData.ip_subnet && handleIpSubnetChange();
  }, [apiData.ip_subnet_arr]);

  const handleIpSubnetAdding = () => {
    if (apiData?.ip_subnet && error?.ip_subnet === "") {
      setApiData({
        ...apiData,
        ip_subnet_arr: [...apiData?.ip_subnet_arr, apiData.ip_subnet],
        ip_subnet: "",
      });
    }
  };

  const handleInputMouseDown = (event) => {
    event?.preventDefault();
  };

  const handleIpSubnetDelete = (index) => {
    let deleteError =
      !error?.ip_subnet && apiData?.ip_subnet_arr?.length === 1
        ? {
            ...error,
            ip_subnet: CheckValueLocale(
              "ip_subnet_field_required",
              "",
              {},
              intl,
            ),
          }
        : {
            ...error,
          };
    setError(deleteError);
    setApiData({
      ...apiData,
      ip_subnet_arr: apiData?.ip_subnet_arr?.filter((_, i) => i !== index),
    });
  };
  const createLucidyaApisRequest = () => {
    let ipSubnetStr =
      "[" +
      apiData?.ip_subnet_arr?.map((ip) => '"' + ip + '"')?.join(",") +
      "]";
    let apiTypeStr =
      "[" + apiData?.api_type?.map((api) => api)?.join(",") + "]";
    var queryData = {
      api_type: apiTypeStr,
      token_name: apiData?.token_name,
      ip_subnet: ipSubnetStr,
      product_id: lucidyaApisActive?.id,
    };
    setIsLoading(true);
    if (apiDialogType === "editApiKey") {
      CompaniesController.editLucidyaApisRequest(apiData?.id, queryData).then(
        (result) => {
          if (!result?.errorMsg) {
            setSuccess(true);
            setSnackBarHeading("api_updated_success");
            handleCreateApiDialog();
            setPage(1);
            getLucidyaApisList();
          } else {
            if (
              result?.errorMsg?.response?.data?.error?.detail ==
              "TOKEN_LIMIT_REACHED"
            ) {
              setSnackBarHeading("no_more_than_keys_failed");
              setSuccess(false);
              handleCreateApiDialog();
            } else if (
              checkTokenName?.some((name) => name === apiData?.token_name)
            ) {
              setSuccess(false);
              setSnackBarHeading("this_key_name_has_already_used");
              handleCreateApiDialog();
            } else {
              setSuccess(false);
              setSnackBarHeading("api_added_error");
              handleCreateApiDialog();
            }
          }
          setIsLoading(false);
        },
      );
    } else {
      CompaniesController.createLucidyaApisRequest(queryData).then((result) => {
        if (!result?.errorMsg) {
          setSuccess(true);
          setSnackBarHeading("api_added_success");
          setApikeyResponse({
            tokenName: result?.data?.token_name,
            token: result?.data?.token,
          });
          setGenerateApi(true);
          setPage(1);
          getLucidyaApisList();
        } else {
          if (
            result?.errorMsg?.response?.data?.error?.detail ==
            "TOKEN_LIMIT_REACHED"
          ) {
            setSnackBarHeading("no_more_than_keys_failed");
            setSuccess(false);
            handleCreateApiDialog();
          } else if (
            checkTokenName?.some((name) => name === apiData?.token_name)
          ) {
            setSuccess(false);
            setSnackBarHeading("this_key_name_has_already_used");
            handleCreateApiDialog();
          } else {
            setSuccess(false);
            setSnackBarHeading("api_added_error");
            handleCreateApiDialog();
          }
        }
        setIsLoading(false);
      });
    }
  };

  //Get single api
  const getSingleLucidyaApis = () => {
    setIsLoadingSingle(true);
    CompaniesController.getSingleLucidyaApis(apiDialogItem?.id).then(
      (result) => {
        if (!result?.errorMsg) {
          const resultData = result?.data;
          setApiData({
            ...apiData,
            id: resultData?.id,
            token_name: resultData?.attributes?.token_name,
            ip_subnet: "",
            ip_subnet_arr: resultData?.attributes?.ip_subnet?.map((ip) =>
              ip.replaceAll('"', ""),
            ),
          });
          setEditApiType(resultData?.attributes?.api_type);
        } else {
          setSnackBarHeading("connection_failed");
          setSuccess(false);
          handleCreateApiDialog();
        }
        setIsLoadingSingle(false);
      },
    );
  };

  const getLucidyaApisTypes = () => {
    setIsLoadingapiTypes(true);
    CompaniesController?.getLucidyaApisTypes()?.then((res) => {
      if (res?.data) {
        setLucidyaApisTypeNames(
          res?.data?.map((item) => ({
            name: item?.name,
            id: item?.id,
          })),
        );
      }
      setIsLoadingapiTypes(false);
    });
  };

  const handleValues = (values) => {
    const selected = lucidyaApisTypeNames?.filter((item) => {
      if (values?.includes(item?.id)) {
        return item;
      }
    });
    const typesLocales = selected?.map((item) =>
      CheckValueLocale(lucidyaApisTypeLocale(item?.name), "", {}, intl),
    );
    return typesLocales?.join(", ");
  };

  useEffect(() => {
    (async () => {
      if (apiDialogType === "editApiKey") {
        await getSingleLucidyaApis();
      }
      await getLucidyaApisTypes();
    })();
  }, []);

  useEffect(() => {
    if (lucidyaApisTypeNames.length > 0 && editApiType.length > 0) {
      setApiData({
        ...apiData,
        api_type: lucidyaApisTypeNames.reduce((apiTypeArr, type) => {
          if (editApiType.includes(type?.name)) apiTypeArr.push(type?.id);
          return apiTypeArr;
        }, []),
      });
    }
  }, [lucidyaApisTypeNames, editApiType]);

  return (
    <>
      {isLoadingSingle &&
      apiDialogType === "editApiKey" &&
      isLoadingapiTypes ? (
        <Box className="circle-single-loading">
          <CircularLoading />
        </Box>
      ) : (
        <>
          <DialogContent className="create-api-content">
            <Box className="create-api-input-wrapper api-type-form-control actions-buttons-shared">
              <InputLabel
                id="api-type-label"
                className="create-api-input-label"
              >
                {CheckValueLocale("api_type", "", {}, intl)}
                <span className="span-require"> * </span>
              </InputLabel>
              <Select
                labelId="api-type-label"
                id="api-type"
                className="create-api-input"
                multiple
                value={apiData?.api_type}
                error={error?.api_type}
                onChange={handleApiTypeChange}
                renderValue={(selected) => handleValues(selected)}
                MenuProps={MenuProps}
              >
                {lucidyaApisTypeNames?.map((val) => {
                  return (
                    <MenuItem
                      className="api-type-menuItem"
                      key={val?.id}
                      value={val?.id}
                      id={`api-header-type-menu-item-${
                        val?.name ?? "api-type-name"
                      }`}
                    >
                      <Checkbox
                        checked={apiData?.api_type?.includes(val?.id)}
                      />
                      <ListItemText
                        primary={CheckValueLocale(
                          lucidyaApisTypeLocale(val?.name),
                          "",
                          {},
                          intl,
                        )}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
              {error?.api_type && (
                <p className="create-api-error">{error?.api_type}</p>
              )}
            </Box>
            <Box className="create-api-input-wrapper">
              <InputLabel
                id="api-name-label"
                className="create-api-input-label"
              >
                {CheckValueLocale("api_name", "", {}, intl)}
                <span className="span-require"> * </span>
              </InputLabel>
              <OutlinedInput
                labelId="api-name-label"
                id="api-name"
                variant="outlined"
                className="create-api-input"
                value={apiData?.token_name}
                error={error?.token_name}
                onChange={(e) => handleApiNameChange(e)}
              />
              {error?.token_name && (
                <p className="create-api-error">{error?.token_name}</p>
              )}
            </Box>
            <Box className="create-api-input-wrapper">
              <InputLabel
                id="ip-subnet-label"
                className="create-api-input-label"
              >
                {CheckValueLocale("ip_subnet", "", {}, intl)}
                <Tooltip
                  title={CheckValueLocale(
                    "grant_network_access_only_to_specific_ip",
                    "",
                    {},
                    intl,
                  )}
                  placement={`${
                    window.localStorage.lang === "en" ? "right" : "left"
                  }-start`}
                  arrow
                >
                  <InfoOutlined className="info-icon" />
                </Tooltip>
              </InputLabel>
              <OutlinedInput
                labelId="ip-subnet-label"
                id="ip-subnet"
                variant="outlined"
                className="create-api-input"
                value={apiData?.ip_subnet}
                error={error?.ip_subnet}
                onChange={(e) => handleIpSubnetChange(e?.target?.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                endAdornment={
                  focused && (
                    <InputAdornment onMouseDown={handleInputMouseDown}>
                      <Button
                        className="add-btn-plus"
                        onClick={handleIpSubnetAdding}
                        id="monitor-options-tw-pa-add-exclude-keyword-btn"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {CheckValueLocale("add", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  )
                }
                onKeyDown={(event) =>
                  event.keyCode === 13 && handleIpSubnetAdding()
                }
              />
              {error?.ip_subnet && (
                <p className="create-api-error">{error?.ip_subnet}</p>
              )}
              {apiData?.ip_subnet_arr?.length > 0 ? (
                <Box className="ip-chip-wrapper">
                  {apiData?.ip_subnet_arr?.map((label, index) =>
                    index < ipSubnetArrLength ? (
                      <IpChip
                        onDelete={() => handleIpSubnetDelete(index)}
                        label={label}
                        index={index}
                      />
                    ) : null,
                  )}
                  <IpPopupModal
                    ipSubnetArr={apiData?.ip_subnet_arr}
                    ipSubnetArrLength={ipSubnetArrLength}
                    handleDelete={handleIpSubnetDelete}
                  />
                </Box>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions className="create-api-actions">
            <LucButton
              variant="flat"
              type="secondary"
              onClick={handleCreateApiDialog}
              id="create-api-cancel-btn"
              className="cancel-popup-btn"
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </LucButton>
            <LucButton
              onClick={createLucidyaApisRequest}
              disabled={disabledBtn || isLoading}
              id="create-api-generate-btn"
              loading={isLoading}
            >
              {CheckValueLocale(
                apiDialogType === "editApiKey"
                  ? "save_changes_api_key"
                  : "generate_api_key",
                "",
                {},
                intl,
              )}
            </LucButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default CreateForm;
