import { Typography, Grid, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import DropDownComponent from "./dropDownNBComponent";

const DropDownSection = (props) => {
  const intl = useIntl();
  const { dropdown } = props;
  return (
    <>
      <Typography
        variant="caption"
        className="keywords keyword-head-title dropdown-keyword-head"
      >
        {CheckValueLocale("conditions", "", {}, intl)}

        <Box component="span" className="optional-head">
          {CheckValueLocale("optional", "", {}, intl)}
        </Box>
      </Typography>
      {/* Box Hint */}
      <Typography variant="caption" className="lang-country-hint-keyword">
        {CheckValueLocale("hint", "", {}, intl)}
        <Box component="span" className="add-condition-keyword">
          {CheckValueLocale("you_will_get_add_condition", "", {}, intl)}
        </Box>
      </Typography>
      {/* Box Hint */}

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* DROPDOWN VALUES */}
        {dropdown.map((dropdownVal) => (
          <Grid item xs={6} className="news-blog-wrapper">
            <Typography
              variant="caption"
              sx={{ display: "block" }}
              className="keywords keyword-dropdown-header"
            >
              {CheckValueLocale(dropdownVal, "", {}, intl)}
            </Typography>
            {/* shared dropDown component with Monitor Setting Component */}
            <DropDownComponent {...props} dropdownVal={dropdownVal} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DropDownSection;
