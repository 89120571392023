import { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Divider,
  FormControl,
  Select,
  TextField,
  Typography,
  Box,
  InputLabel,
} from "@mui/material";

import { CheckValueLocale } from "../../../../utils/helpers/index.js";

const EditDashboardGeneral = (props) => {
  const intl = useIntl();
  const {
    dashboardDetails,
    dashboardData,
    setdashboardData,
    dashboardErrors,
    handleInputChange,
    setSelectedMonitors,
    setUpdatedValuesParams,
    updatedValuesParams,
    setSelectedDatasourcesMonitors,
  } = props;

  const handleDashboardData = () => {
    let monitors = [];
    let datasources = [];
    dashboardDetails?.attributes?.monitors?.data?.map((i) => {
      monitors.push(i?.attributes?.id);
      i?.attributes?.data_sources?.map((el) => {
        datasources.push(el);
      });
    });
    setdashboardData({
      ...dashboardData,
      dashabordName: dashboardDetails?.attributes?.name,
      dashboardType: dashboardDetails?.attributes?.dashboard_type,
      monitorValue: dashboardDetails?.attributes?.monitor_type,
      dashabordMonitors: monitors,
    });
    setSelectedMonitors(monitors);
    setSelectedDatasourcesMonitors(datasources);
  };

  useEffect(() => {
    handleDashboardData();
  }, []);

  const handleChangeDashboardName = (event) => {
    handleInputChange(event);
    setUpdatedValuesParams({
      ...updatedValuesParams,
      name: event?.target?.value,
    });
  };
  return (
    <>
      <Typography className="section-header">
        {CheckValueLocale("general", "", {}, intl)}
      </Typography>
      <Box className="section-box section-box-edit-dash">
        {/*dashboard Name*/}
        <Box
          className="section-box-data dashboard-name-edit"
          id="edit-name-dashboard"
        >
          <Typography className="main-titles">
            {CheckValueLocale("dashboard_name", "", {}, intl)}
          </Typography>
          <TextField
            id="outlined-required"
            className="dasboard-edit-name-field"
            variant="outlined"
            label={CheckValueLocale("name_dash", "", {}, intl)}
            value={dashboardData?.dashabordName || ""}
            onChange={(e) => handleChangeDashboardName(e)}
          />
        </Box>
        {dashboardErrors?.dashabordName && (
          <Box className="section-box-data">
            <Typography className="main-titles"></Typography>
            <Box
              component="p"
              className="spam-text-field err-msg-text dashboard-name-edit-eror"
            >
              {CheckValueLocale(dashboardErrors?.dashabordName, "", {}, intl)}
            </Box>
          </Box>
        )}
        <Divider />
        {/* dashboard type */}
        <Box className="section-box-data">
          <Typography className="main-titles">
            {CheckValueLocale("dashboard_type", "", {}, intl)}
          </Typography>

          <FormControl className="dash-type-input-field">
            <InputLabel id="demo-multiple-checkbox-label">
              {CheckValueLocale("dashboard_type", "", {}, intl)}
            </InputLabel>
            <Select
              value={dashboardData?.dashboardType || ""}
              placeholder={CheckValueLocale("dashboard_type", "", {}, intl)}
              renderValue={() =>
                dashboardData?.dashboardType
                  ? CheckValueLocale(dashboardData?.dashboardType, "", {}, intl)
                  : ""
              }
              className="select-dashbord-type"
              disabled={true}
              id="dashboard-type-edit"
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default EditDashboardGeneral;
