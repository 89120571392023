import React from "react";
import EngagementsBody from "./engagementBody";
/*---------------------------------------------------------*/

const InstagramEngagementsPage = (props) => {
  return (
    <>
      <EngagementsBody {...props} />
    </>
  );
};

export default InstagramEngagementsPage;
