import React from "react";
import { useIntl } from "react-intl";
import { Box, TextField, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./style.scss";
import TextDirectionSwitcher from "../../textDirectionSwitcher";

const SectionHeader = (props) => {
  const {
    section,
    sectionIndex,
    emptySection,
    secTitleErr,
    handleSectionTitle,
    filteredSections,
    handleSectionDir,
  } = props;
  const intl = useIntl();

  return (
    <Box
      id={`section-header-${sectionIndex}`}
      className={`section-header ${
        (emptySection === sectionIndex || secTitleErr === sectionIndex) &&
        `section-error`
      }`}
    >
      <Box className="section-number">
        {CheckValueLocale(
          "section_of",
          "",
          { num1: sectionIndex + 1, num2: filteredSections?.length },
          intl,
        )}
      </Box>
      <Box className="section-title">
        <TextField
          variant="standard"
          id={`section-title-${sectionIndex}`}
          className="section-title-textfield"
          onChange={(e) => handleSectionTitle(e, sectionIndex)}
          value={section?.name}
        />
        <TextDirectionSwitcher
          handleChangeDir={handleSectionDir}
          value={filteredSections?.[sectionIndex]?.text_direction}
          sectionIndex={sectionIndex}
        />
      </Box>
      {emptySection === sectionIndex || secTitleErr === sectionIndex ? (
        <Box mt={1}>
          <Typography component="h1" className="empty-section-error-msg">
            {CheckValueLocale(
              emptySection === sectionIndex
                ? "empty_section_error_msg"
                : secTitleErr === sectionIndex
                  ? "empty_section_title_error_msg"
                  : null,
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default SectionHeader;
