import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faClock,
  faUser,
  faUserGroup,
  faMessageLines,
} from "@fortawesome/pro-solid-svg-icons";

let iconsObject = {
  inbox: <FontAwesomeIcon icon={faMessageLines} />,
  slas: <FontAwesomeIcon icon={faClock} />,
  teams: <FontAwesomeIcon icon={faUserGroup} />,
  agents: <FontAwesomeIcon icon={faUser} />,
  Insights: <FontAwesomeIcon icon={faChartSimple} />,
};

const SideBar = ({ tabsArr, selectedTab }) => {
  const intl = useIntl();
  const history = useHistory();
  const activeTabs = ["inbox"];
  return (
    <Box className={"analytics-sidebar"}>
      <Box className="menu-analytics-sidebar">
        {CheckValueLocale("menu", "", {}, intl)}
      </Box>
      {tabsArr?.map((item) => (
        <Box
          className={`menu-analytics-sidebar-item ${item == selectedTab ? "selected-analytics-item" : ""} ${activeTabs?.includes(item) ? "" : "disabled"}`}
          onClick={() =>
            activeTabs?.includes(item) &&
            history.push(`/engagements/analytics/${item}`)
          }
          id={`analytics-sidebar-${item}`}
        >
          <Box className="main-tab-details">
            {iconsObject[item]}
            {CheckValueLocale(`${item}_analytics`, "", {}, intl)}
          </Box>
          {!activeTabs?.includes(item) ? (
            <Box className="soon-tab">
              {CheckValueLocale(`soon`, "", {}, intl)}
            </Box>
          ) : null}
        </Box>
      ))}
    </Box>
  );
};

export default SideBar;
