import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import SaveIcon from "@mui/icons-material/Save";
import { CheckValueLocale } from "utils/helpers";
import { Paper } from "@mui/material";

const AddChannelFooter = ({
  activeStep,
  handleStep,
  setGenesysStatus,
  socialMediaDataSource,
  isPrivateChannel,
  selectedCategoryStats,
  activeCategory,
  isEmptyAccountScreen,
  isAnyLoading,
  isAddChannelButtonShown,
  formik,
  selectedAccount,
  handleAddChannel,
  addChannelBtn,
  monitorErrors,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleBackClick = () => {
    handleStep(activeStep - 1, "back");
    setGenesysStatus("");
  };

  const handleCancelClick = () => {
    history.push("/manage-channels/list");
  };

  const renderNextStepButton = () => (
    <LucButton
      onClick={() =>
        handleStep(activeStep + 1, selectedCategoryStats.categoryStatus)
      }
      disabled={activeCategory === "" || isEmptyAccountScreen}
      loading={isAnyLoading}
      startIcon={
        <SaveIcon
          sx={{ display: "none" }}
          id="channel-analytics-new-channel-next-step-btn"
        />
      }
    >
      {CheckValueLocale("next_step", "", {}, intl)}
    </LucButton>
  );

  const renderAddChannelButton = (handleSubmit) => (
    <LucButton
      onClick={handleSubmit}
      disabled={
        activeCategory === "" ||
        isEmptyAccountScreen ||
        !selectedAccount ||
        addChannelBtn ||
        Object?.values(monitorErrors)?.some((error) => error?.length !== 0)
      }
      loading={isAnyLoading}
      id="channel-analytics-new-channel-add-channel-btn"
    >
      {CheckValueLocale("add_channel", "", {}, intl)}
    </LucButton>
  );

  const renderConditionalButtons = () => {
    if (socialMediaDataSource && isPrivateChannel) {
      if (activeStep !== undefined && activeStep !== 3) {
        return renderNextStepButton();
      } else if (isAddChannelButtonShown) {
        return renderAddChannelButton(() => formik.handleSubmit());
      }
    } else if (!socialMediaDataSource || !isPrivateChannel) {
      if (activeStep !== undefined && activeStep !== 1) {
        return renderNextStepButton();
      } else if (activeStep === 1) {
        return renderAddChannelButton(() => handleAddChannel(isPrivateChannel));
      }
    }
    return null;
  };

  return (
    <Paper elevation={3} className="panel-footer">
      {activeStep !== undefined && activeStep > 0 && (
        <LucButton
          variant="outline"
          type="secondary"
          onClick={handleBackClick}
          id="channel-analytics-new-channel-back-btn"
        >
          {CheckValueLocale("back", "", {}, intl)}
        </LucButton>
      )}
      <div>
        <LucButton
          variant="flat"
          type="secondary"
          onClick={handleCancelClick}
          id="channel-analytics-new-channel-cancel-btn"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
        {renderConditionalButtons()}
      </div>
    </Paper>
  );
};

export default AddChannelFooter;
