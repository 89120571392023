import { useQuery } from "@tanstack/react-query";
import InteractionsController from "services/controllers/interactionsController";
import { useParams } from "react-router-dom";

export const useThemesOptions = ({ locale, disableFetch }) => {
  const urlParams = useParams();
  return useQuery({
    queryKey: ["themesOptions", locale],
    queryFn: () => {
      if (window?.location?.href?.includes("/live-dashboard/public/")) {
        return InteractionsController.getSubThemesPublic({
          monitor_id: urlParams?.monitorId,
          dashboard_user_id: +urlParams?.userDashboardId,
          language: locale,
        });
      } else {
        return InteractionsController.getSubThemes();
      }
    },
    enabled: !disableFetch,
    retry: false,
    select: (data) => {
      return data?.data?.data?.data?.sort((a, b) => a?.order - b?.order);
    },
  });
};
