import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import notAccessLiveDashboardIcon from "images/live-dashboard-images/not_access_live_dashboard_icon.svg";

const LiveDashboardNotAccess = ({ dashboardUserName }) => {
  const intl = useIntl();
  const history = useHistory();
  return (
    <Box className="live-dashboard-not-access-page">
      <Box
        component={"img"}
        src={notAccessLiveDashboardIcon}
        alt="not_access_live_dashboard_icon"
      />
      <Box className="live-dashboard-not-access-title">
        {CheckValueLocale("live_dashboard_is_not_access_title", "", {}, intl)}
      </Box>
      <Box className="live-dashboard-not-access-description">
        {CheckValueLocale(
          "live_dashboard_is_not_access_description",
          "",
          { userName: dashboardUserName },
          intl,
        )}
      </Box>
    </Box>
  );
};

export default LiveDashboardNotAccess;
