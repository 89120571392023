import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

import { Button, Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import LucButton from "shared/lucButton/lucButton";

const ExploreStepper = ({
  clearAllData,
  handleDisbleCreateExplore,
  handleCreateExplore,
  handleDiableClearAllExplore,
  resetAllData,
}) => {
  const intl = useIntl();
  const handleExploreCreate = () => {
    resetAllData();
    handleCreateExplore();
  };
  return (
    <Box className="explore-steppers-containter">
      <Box className="explore-steppers-content">
        <Box>
          <LucButton
            type={"secondary"}
            variant="flat"
            onClick={() => clearAllData()}
            disabled={!handleDiableClearAllExplore()}
          >
            {CheckValueLocale("clear_all", "", {}, intl)}
          </LucButton>
        </Box>
        <Box>
          <LucButton
            onClick={handleExploreCreate}
            id="explore-create-btn"
            variant="filled"
            startIcon={<Search />}
            disabled={handleDisbleCreateExplore()}
          >
            {CheckValueLocale("explore", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreStepper;
