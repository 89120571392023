import { useState } from "react";
import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import _ from "lodash";
import { useIntl } from "react-intl";
import moment from "moment";
import { CheckValueLocale, getTimeZone } from "utils/helpers";
import dayjs from "dayjs";

export default function BasicDateTimePicker({
  publishSpecifiedDate,
  dateValue,
  setDatesTimePicker,
  datesTimePicker,
  index,
  isPast,
}) {
  const currentDate = new Date();
  const intl = useIntl();
  const [openPicker, setOpenPicker] = useState(false);
  const handleTogglePicker = () => {
    setOpenPicker((prevOpenPicker) => !prevOpenPicker);
  };
  const handleChangeDate = (newValue) => {
    let cuuDateUNix =
      moment(currentDate).unix() -
      getTimeZone() * 60 * 60 +
      parseInt(localStorage.companyInfo) * 60 * 60;
    let dateNews = new Date(cuuDateUNix * 1000);
    let formattedDates = dateNews?.toISOString();
    const curentdateVal = new Date(formattedDates);

    let allDates = _.cloneDeep([...datesTimePicker]);
    allDates[index].date = newValue?.$d;
    allDates[index].is_past = newValue?.$d.getTime() < curentdateVal?.getTime();
    setDatesTimePicker(allDates);
  };
  const removeTime = () => {
    let filteredTime = _.cloneDeep(datesTimePicker);
    filteredTime?.splice(index, 1);
    setDatesTimePicker(filteredTime);
  };
  return (
    <Box className="publish-time-picker-container">
      <Box
        className={`publish-time-picker-content ${
          datesTimePicker?.length == 1 && "publish-time-picker-content-empty"
        }`}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            renderInput={(props) => {
              const dateItem = props?.inputProps?.value?.split(" ");
              let obj = {
                ...props,
                inputProps: {
                  ...props.inputProps,
                  value: `${dateItem[0]} - ${dateItem[1]} ${dateItem[2]}`,
                },
              };
              return <TextField {...obj} onClick={() => setOpenPicker(true)} />;
            }}
            value={dayjs(dateValue)}
            onChange={(newValue) => handleChangeDate(newValue)}
            className={`time-field-container ${
              isPast && "invalid-date-field"
            } ${datesTimePicker?.length > 1 && "time-field-container-more"}`}
            open={openPicker}
            onOpen={handleTogglePicker}
            onClick={() => setOpenPicker(true)}
            onClose={handleTogglePicker}
          />
        </LocalizationProvider>
      </Box>
      {datesTimePicker?.length > 1 ? (
        <RemoveCircleIcon
          className="publish-time-picker-delete-icon"
          onClick={removeTime}
        />
      ) : null}
    </Box>
  );
}
