import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import Snackbar from "components/snackBar";
import CustomDomainInfo from "./components/customDomainInfo/customDomainInfo";
import CompaniesController from "services/controllers/companiesController";
import { validateURL } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { CheckValueLocale } from "utils/helpers";
import "./customDomain.scss";
import PopupModal from "components/popupModal";
import LucButton from "shared/lucButton/lucButton";
import CustomDomainStepTwo from "./components/customDomainStepTwo";
import CustomDomainStepOne from "./components/customDomainStepOne";

const CustomDomain = (props) => {
  const { getCompanyInfo, customDomain, setCustomDomain, customDomainDetails } =
    props;
  const intl = useIntl();
  const [customDomainName, setCustomDomainName] = useState("");
  const [customDomainError, setCustomDomainError] = useState(false);
  const [formFieldErrors, setFormFieldErrors] = useState({
    name: "",
  });

  const [customDomainLoading, setCustomDomainLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [step, setStep] = useState(1);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const isCustomDomainPending =
    customDomainDetails?.frontend_status == "pending";
  const handleInputChange = (e) => {
    const value = e?.target?.value || "";
    setCustomDomainName(value);
    const errorMessage =
      value === ""
        ? ""
        : isValidDomain(value)
          ? ""
          : "custom_domain_validation";
    setFormFieldErrors({
      ...formFieldErrors,
      name: errorMessage,
    });
    setCustomDomainError(errorMessage);
  };

  const isValidDomain = (value) => {
    return value === "" || !validateURL(value);
  };

  const isBtnDisabled = customDomainName === "" || customDomainError;

  const handleSubmitCustomDomain = () => {
    let customDomainInfo = `survey.${customDomainName}`;
    setCustomDomainLoading(true);
    CompaniesController.addCustomDomain({
      custom_domain: customDomainInfo,
    }).then((res) => {
      if (res?.data?.status === 200) {
        getCompanyInfo();
        setCustomDomainLoading(false);
        setStep(2);
      } else {
        setOpenSnackBar(true);
        setCustomDomainLoading(false);
      }
      setTimeout(() => {
        setOpenSnackBar(false);
      }, 4000);
    });
  };
  const verifyCustomDomain = () => {
    setCustomDomainLoading(true);
    closeModal();

    CompaniesController.verifyCustomDomain().then((res) => {
      if (res?.status !== 200) {
        setOpenSnackBar(true);
      }

      setCustomDomainLoading(false);
    });
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 300);
  };

  const domainData = [
    {
      label: "hostname_txt_name",
      value: customDomainDetails?.txt_record_name || "",
    },
    {
      label: "hostname_txt_value",
      value: customDomainDetails?.txt_record_value || "",
    },
    {
      label: "cname_key",
      value: customDomainDetails?.custom_domain || "",
    },
    {
      label: "cname_value",
      value: customDomainDetails?.target_value || "",
    },
  ];
  const modalBody = (
    <Box>
      {step === 2 ? (
        <Box component="p" id="access-website">
          {CheckValueLocale("access_your_website_host", "", {}, intl)}
        </Box>
      ) : null}

      {step === 1 ? (
        <CustomDomainStepOne
          formFieldErrors={formFieldErrors}
          customDomainError={customDomainError}
          customDomainName={customDomainName}
          handleInputChange={handleInputChange}
        />
      ) : (
        <Box>
          {domainData?.map((field, index) => (
            <CustomDomainStepTwo
              key={index}
              label={field?.label}
              value={field?.value}
              index={index}
            />
          ))}
        </Box>
      )}
    </Box>
  );
  const closeModal = () => {
    setOpenPopup(null);
    setCustomDomainName("");
    setCustomDomainError(false);
  };
  const handleView = () => {
    setStep(2);
    setOpenPopup(true);
  };

  const handleAddCustomDomain = () => {
    if (step === 1) {
      handleSubmitCustomDomain();
    } else {
      if (isCustomDomainPending) verifyCustomDomain();
      else closeModal();
    }
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  return (
    <>
      <Box className="custom-domain-head">
        {CheckValueLocale("company_custom_domain", "", {}, intl)}
      </Box>
      <Box className="custom-domain-wrapper">
        {customDomain == "" || customDomain == null ? (
          <Box>
            <Box className="custom-domain-box">
              <Typography className="custom-domain-title">
                {CheckValueLocale("personalize_survey_link", "", {}, intl)}
              </Typography>
              <LucButton
                variant="outline"
                onClick={() => {
                  setOpenPopup(true);
                  setStep(1);
                }}
                loading={customDomainLoading}
                className="add-domain-btn"
                id="add-domain-btn"
              >
                {CheckValueLocale("add_domain", "", {}, intl)}
              </LucButton>
            </Box>
          </Box>
        ) : (
          <CustomDomainInfo
            customDomain={customDomain}
            setCustomDomain={setCustomDomain}
            setCustomDomainName={setCustomDomainName}
            setCustomDomainError={setCustomDomainError}
            handleView={handleView}
            customDomainDetails={customDomainDetails}
          />
        )}
      </Box>
      <PopupModal
        title={CheckValueLocale(
          step === 1 ? "add_domain" : "domain_details",
          "",
          {},
          intl,
        )}
        body={modalBody}
        leftBtn={step === 1 ? CheckValueLocale("cancel", "", {}, intl) : null}
        rightBtn={CheckValueLocale(
          step === 1
            ? "add"
            : isCustomDomainPending
              ? "custom_domain_verify"
              : "close",
          "",
          {},
          intl,
        )}
        disabled={step === 1 && isBtnDisabled}
        isRightBtnLoading={customDomainLoading}
        open={openPopup}
        close={closeModal}
        accept={() => handleAddCustomDomain()}
        addClasses={"custom-domain-popup"}
      />
      <Snackbar
        open={openSnackBar}
        severity={"error"}
        message={CheckValueLocale("update_not", "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};

export default CustomDomain;
