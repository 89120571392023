import { TextField } from "@mui/material";
import React from "react";
import { classNames } from "utils/helpers";
import "./inputWithShrinkLabel.scss";

export const InputWithShrinkLabel = React.forwardRef((props, ref) => {
  return (
    <TextField
      variant="outlined"
      margin="none"
      fullWidth
      size="small"
      {...props}
      inputRef={ref}
      className={classNames(props.className, "input-with-shrink-label")}
    />
  );
});
