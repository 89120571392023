import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, Grid, Box } from "@mui/material";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget.js";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";

import OverView from "components/widgets/overViewWidget.js";
import PunchCardWidget from "components/widgets/punchCardWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { getNoOfDays, CheckValueLocale } from "utils/helpers";
import Genesys from "../../../../../images/shared-images/genesys-icon.svg";
import _ from "lodash";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";

const GenesysBody = (props) => {
  const intl = useIntl();
  let {
    response,
    preLoaderTrackerDataSources,
    startDate,
    endDate,
    setExcelSheetData,
    setCheckAllDataFetchingExport,
  } = props;

  //___________________________________________________________________________
  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoaderDataSources: [],

    //interaction widget
    interaction: [],
    totalInteraction: 0,
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentOvertime: [],
    sentimentPiechart: [],
    sentimentAnalysisPreLoaderDataSources: [],

    //themes widget
    themeTrend: [],
    themeAnalysis: [],
    themesXlsx: [],
    themeTrendPreLoaderDataSources: [],
    themeAnalysisPreLoaderDataSources: [],

    // channel Accounts
    channelAccounts: [],
    channelAccountsPreLoaderDataSources: [],

    dialects: [],
    dialectsDeep: [],
    subDialects: [],
    subDialectsDeep: [],
    dialectsPreLoaderDataSources: [],

    contactActivity: [],
    minValueContactActivity: 0,
    maxValueContactActivity: 0,
    contactActivityPreLoaderDataSources: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    contactTrend: [],
    avgContactTrend: 0,
    totalContactTrend: 0,
    contactTrendPreLoaderDataSources: [],

    //top Countries
    topCountries: [],
    topCountriesPreLoaderDataSources: [],

    topEngagers: [],
    topEngagersPreLoaderDataSources: [],

    //latestInteractions widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],

    negativeArray: [],
    positiveArray: [],
    neutralArray: [],
  };

  const [genesysWidgets, setGenesysWidgets] = useState(initialWidgetsState);

  useEffect(() => {
    switch (response?.eventName) {
      case "Genesys__CASingleDataSourceInsightsPage__overview":
        if (response?.eventData?.overview) {
          let overViewArray = [];
          for (let [key, values] of Object.entries(
            response?.eventData?.overview,
          )) {
            const condition =
              values === "None" || values === null || values === 0;
            if (key === "avg_waiting_time" && condition) {
              overViewArray.push({ name: key, value: 0.0 });
            } else overViewArray.push({ name: key, value: values });
          }
          setGenesysWidgets({
            ...genesysWidgets,
            overview: [...overViewArray],
            overViewPreLoaderDataSources: [response?.data_source],
          });
        }
        break;
      case "Genesys__CASingleDataSourceInsightsPage__top_keywords":
        setGenesysWidgets({
          ...genesysWidgets,
          topKeywords: response?.eventData.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__dialects_and_sub_dialects":
        setGenesysWidgets({
          ...genesysWidgets,
          dialects: response?.eventData.dialects,
          dialectsDeep: _.cloneDeep([...response.eventData?.dialects]),
          subDialects: response?.eventData.sub_dialects,
          subDialectsDeep: _.cloneDeep([...response.eventData?.sub_dialects]),
          dialectsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__top_engagers":
        setGenesysWidgets({
          ...genesysWidgets,
          topEngagers: response?.eventData.top_engagers,
          topEngagersPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__top_posts_conversations":
        setGenesysWidgets({
          ...genesysWidgets,
          latestInteractions: response?.eventData?.top_posts,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.top_posts_count,
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setGenesysWidgets({
          ...genesysWidgets,
          interaction: response?.eventData.interactions,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteraction: response?.eventData.total_interactions,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__contacts_volume":
        const numOfDays = getNoOfDays(startDate, endDate);
        const avgContacts = response?.eventData?.total_contacts / numOfDays;
        setGenesysWidgets({
          ...genesysWidgets,
          contactTrend: response?.eventData.contacts_volume_over_time,
          avgContactTrend:
            avgContacts % 1 !== 0 ? avgContacts.toFixed(2) : avgContacts,
          totalContactTrend: response?.eventData.total_contacts,
          contactTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return genesysWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return genesysWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return genesysWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );

        setGenesysWidgets({
          ...genesysWidgets,
          sentimentOvertime: {
            negative: genesysWidgets?.negativeArray,
            positive: genesysWidgets?.positiveArray,
            neutral: genesysWidgets?.neutralArray,
          },
          sentimentPiechart: response?.eventData.sentiment_piechart,
          sentimentAnalysisPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__contacts_activity":
        setGenesysWidgets({
          ...genesysWidgets,
          contactActivity: response?.eventData.contacts_activity,
          minValueContactActivity: response?.eventData.min_value,
          maxValueContactActivity: response?.eventData.max_value,
          contactActivityPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__themes_and_sub_themes":
        setGenesysWidgets({
          ...genesysWidgets,
          themesXlsx: response?.eventData?.themes,
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeTrendPreLoaderDataSources: [response?.data_source],
          themeAnalysisPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__channel_accounts":
        let channel_accounts = [];
        response?.eventData?.channel_accounts?.map((x) => {
          channel_accounts.push({
            name: x.name,
            value: x.value,
          });
        });
        setGenesysWidgets({
          ...genesysWidgets,
          channelAccounts: channel_accounts,
          channelAccountsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Genesys__CASingleDataSourceInsightsPage__top_countries":
        setGenesysWidgets({
          ...genesysWidgets,
          topCountries: response?.eventData.top_countries,
          topCountriesPreLoaderDataSources: [response?.data_source],
        });
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setGenesysWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  const chartStatsData = [
    {
      name: "total_interactions",
      value: genesysWidgets?.totalInteraction,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: genesysWidgets?.avgInteractions,
    },
  ];

  const contactTrendchartStatsData = [
    {
      name: "total_interactions",
      value: genesysWidgets?.totalContactTrend,
    },
    {
      name: "insights_avg_interactions_per_day",
      value: genesysWidgets?.avgContactTrend,
    },
  ];

  useEffect(() => {
    let excelData = { ...genesysWidgets };
    let contactActivity = [
      genesysWidgets?.contactActivity,
      genesysWidgets?.minValueContactActivity,
      genesysWidgets?.maxValueContactActivity,
    ];
    excelData["contactActivity"] = [...contactActivity];
    setExcelSheetData([{ ...excelData }]);
  }, [genesysWidgets]);

  // check fetching for export excel
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      genesysWidgets?.overViewPreLoaderDataSources,
      genesysWidgets?.topKeywordsPreLoaderDataSources,
      genesysWidgets?.latestInteractionsPreLoaderDataSources,
      genesysWidgets?.interactionsPreLoaderDataSources,
      genesysWidgets?.sentimentAnalysisPreLoaderDataSources,
      genesysWidgets?.themeTrendPreLoaderDataSources,
      genesysWidgets?.themeAnalysisPreLoaderDataSources,
      genesysWidgets?.channelAccountsPreLoaderDataSources,
      genesysWidgets?.topCountriesPreLoaderDataSources,
      genesysWidgets?.contactTrendPreLoaderDataSources,
      genesysWidgets?.contactActivityPreLoaderDataSources,
      genesysWidgets?.topEngagersPreLoaderDataSources,
      genesysWidgets?.dialectsPreLoaderDataSources,
    ];

    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      preLoaderTrackerDataSources,
    );
    setCheckAllDataFetchingExport(result);
  }, [
    genesysWidgets?.overViewPreLoaderDataSources,
    genesysWidgets?.topKeywordsPreLoaderDataSources,
    genesysWidgets?.latestInteractionsPreLoaderDataSources,
    genesysWidgets?.interactionsPreLoaderDataSources,
    genesysWidgets?.sentimentAnalysisPreLoaderDataSources,
    genesysWidgets?.themeTrendPreLoaderDataSources,
    genesysWidgets?.themeAnalysisPreLoaderDataSources,
    genesysWidgets?.channelAccountsPreLoaderDataSources,
    genesysWidgets?.topCountriesPreLoaderDataSources,
    genesysWidgets?.contactTrendPreLoaderDataSources,
    genesysWidgets?.contactActivityPreLoaderDataSources,
    genesysWidgets?.topEngagersPreLoaderDataSources,
    genesysWidgets?.dialectsPreLoaderDataSources,
  ]);

  const genesysIcons = (
    <img src={Genesys} alt="icon-genesys" width={"20px"} height={"17px"} />
  );
  return (
    <>
      <OverView
        data={genesysWidgets.overview}
        isGenesysPage={true}
        overViewPreLoaderDataSources={
          genesysWidgets.overViewPreLoaderDataSources
        }
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
      />
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {intl.formatMessage({ id: "interactions_overview" }).toUpperCase()}
      </Typography>
      <Box my={4}>
        <LineChartWidget
          title={"interactions_genesys"}
          titleToolTip="interaction_genesys_tooltip"
          data={genesysWidgets?.interaction}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/calls/genesys/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            genesysWidgets?.interactionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={genesysIcons}
        />
      </Box>
      <Box my={4}>
        <StackedLineWidget
          title={"calls_sentiment_analysis"}
          titleToolTip="sentiments_genesys_tooltip"
          data={genesysWidgets?.sentimentOvertime}
          dataPiechart={genesysWidgets?.sentimentPiechart}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          hideLegend
          showAI
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            genesysWidgets?.sentimentAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={genesysIcons}
          showScales
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysis
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
        />
      </Box>
      {/* Themes & Themes-Trend Statues CHART */}
      <Box mt={3}>
        <ThemesAnalysis
          title={"themes"}
          titleToolTip="themes_genesys_tooltip"
          data={genesysWidgets?.themeAnalysis}
          showDownloadIcon
          bigImg
          iconAfterTitle={genesysIcons}
          themeAnalysisPreLoaderDataSources={
            genesysWidgets?.themeAnalysisPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"themes_trend"}
          titleToolTip="themes_trend_genesys_tooltip"
          data={genesysWidgets?.themeTrend}
          showGroupBy={false}
          showDownloadIcon
          bigImg
          iconAfterTitle={genesysIcons}
          themeTrendPreLoaderDataSources={
            genesysWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        />
      </Box>

      <Grid container spacing={2} my={3}>
        <Grid item md={6}>
          <PieChartWidget
            title={"channel_accounts"}
            titleToolTip="channel_genesys_tooltip"
            data={genesysWidgets?.channelAccounts}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#6D737A", "#009BDF", "#F680BC"]}
            showDownloadIcon
            bigImg
            isChannelAccounts
            iconAfterTitle={genesysIcons}
            pieChartPreLoaderDataSources={
              genesysWidgets?.channelAccountsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
        <Grid item md={6}>
          <PieChartWidget
            title={"dialects_subdialects"}
            titleToolTip="dialects_subdialects_genesys_tooltip"
            data={genesysWidgets?.dialects}
            subData={genesysWidgets?.subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            bigImg
            showDownloadIcon
            iconAfterTitle={genesysIcons}
            pieChartPreLoaderDataSources={
              genesysWidgets?.dialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Box my={4} className="channel-activity">
        <PunchCardWidget
          title={"contact_activity"}
          titleToolTip="channel_activity_genesys_tooltip"
          clientActivity={genesysWidgets?.contactActivity}
          minValueClient={genesysWidgets?.minValueContactActivity}
          maxValueClient={genesysWidgets?.maxValueContactActivity}
          showDownloadIcon
          bigImg
          authorActivityPreLoaderDataSources={
            genesysWidgets?.contactActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={genesysIcons}
        />
      </Box>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale(
          "genesys_interactions_in_depth_analysis",
          "",
          {},
          intl,
        ).toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            wordsList
            title={"insights_top_keywords"}
            titleToolTip="top_kewords_genesys_tooltip"
            data={genesysWidgets?.topKeywords}
            leftColomnTitle={"keywords_tab_title"}
            rightColomnTitle={"number_of_mentions"}
            tableChartPreLoaderDataSources={
              genesysWidgets?.topKeywordsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            keywords
            bigImg
            tablePagination
            showDownloadIcon
            count={genesysWidgets?.topKeywords?.length}
            iconAfterTitle={genesysIcons}
            socialKeywordName
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale(
          "contact_overview_genesys",
          "",
          {},
          intl,
        ).toUpperCase()}
      </Typography>
      <Box my={4}>
        <LineChartWidget
          title={"contacts_trend_genesys"}
          titleToolTip="contacts_trend_genesys_tooltip"
          data={genesysWidgets?.contactTrend}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          showChartDetailsWidget
          statsDetailsData={contactTrendchartStatsData}
          redirectTo={"/interactions/calls/genesys/"}
          toolTipLabel={"total_interactions"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            genesysWidgets?.contactTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={genesysIcons}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PieChartWidget
            title={"top_countries"}
            titleToolTip="top_countries_genesys_tooltip"
            data={genesysWidgets?.topCountries}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            iconAfterTitle={genesysIcons}
            pieChartPreLoaderDataSources={
              genesysWidgets?.topCountriesPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        mt={5}
        mb={3}
        sx={{ color: "#A4A9AF", fontSize: "14px", fontWeight: 500 }}
      >
        {CheckValueLocale(
          "contacts_in_depth_analysis_genesys",
          "",
          {},
          intl,
        ).toUpperCase()}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableWidget
            wordsList
            title={"most_engaged_contacts"}
            titleToolTip="most_engaged_genesys_tooltip"
            data={genesysWidgets?.topEngagers}
            leftColomnTitle={"user"}
            rightColomnTitle={"calls"}
            tableChartPreLoaderDataSources={
              genesysWidgets?.topEngagersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            keywords
            bigImg
            tablePagination
            showDownloadIcon
            count={genesysWidgets?.topEngagers.length}
            iconAfterTitle={genesysIcons}
            socialKeywordName
            iconBeforeItemTitle={genesysIcons}
            isGensys={true}
          />
        </Grid>
      </Grid>

      <Box my={4}>
        <InteractionsList
          showProfile={true}
          data={genesysWidgets?.latestInteractions?.slice(0, 5)}
          title={"latest_interactions"}
          dataSources={props?.dataSources}
          isGenesysPage
          latestInteractionsPreLoaderDataSources={
            genesysWidgets?.latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          showViewMoreButton={genesysWidgets?.latestInteractionsCount > 5}
        />
      </Box>
    </>
  );
};

export default GenesysBody;
