import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import SectionTitle from "pages/alerts/components/sectionTitle/sectionTitle";
import QuestionCard from "pages/Monitors/Components/questionCard";
import AlertStatistics from "pages/alerts/components/alertStatistics/alertStatistics";

const NewPostAlertNewsBlogs = ({
  response,
  alertDetailMonitorId,
  resetAllFields,
  alertsDataWidgets,
}) => {
  const initialWidgetsState = {
    //header alert widget
    headerAlertWidget: {},
    headerAlertWidgetPreLoaderDataSources: [],

    //top engaged sites widget
    topEngagedSites: [],
    topEngagedSitesPreLoaderDataSources: [],

    //top engaged sites widget
    topSites: [],
    topSitesPreLoaderDataSources: [],

    //top posts widget
    topPosts: [],
    topPostsPreLoaderDataSources: [],

    //top language widget
    topLanguage: [],
    topLanguagePreLoaderDataSources: [],

    //comments setntiment analysis widget
    commentsSentimentAnalysis: [],
    commentsSentimentAnalysisPreLoaderDataSources: [],

    //engagements distribution widget
    engagementsDistribution: [],
    engagementsDistributionPreLoaderDataSources: [],
  };

  const [alertViewWidgets, setAlertViewWidgets] = useState(initialWidgetsState);

  useEffect(() => {
    switch (response?.eventName) {
      case "Talkwalker__NewPostAlert__header_alert_widget":
      case "Talkwalker__TrendingAlert__header_alert_widget":
      case "Talkwalker__NegativePostsAlert__header_alert_widget":
        setAlertViewWidgets({
          ...alertViewWidgets,
          headerAlertWidget: response?.eventData,
          headerAlertWidgetPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Talkwalker__NewPostAlert__top_engaged_sites":
      case "Talkwalker__TrendingAlert__top_engaged_sites":
      case "Talkwalker__NegativePostsAlert__top_engaged_sites":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topEngagedSites: response?.eventData?.top_engaged_sites,
          topEngagedSitesPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Talkwalker__NewPostAlert__top_sites":
      case "Talkwalker__TrendingAlert__top_sites":
      case "Talkwalker__NegativePostsAlert__top_sites":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topSites: response?.eventData?.top_sites,
          topSitesPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Talkwalker__NewPostAlert__top_posts_comments":
      case "Talkwalker__TrendingAlert__top_posts_comments":
      case "Talkwalker__NegativePostsAlert__top_posts_comments":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topPosts: response?.eventData?.top_posts,
          topPostsPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Talkwalker__NewPostAlert__top_languages":
      case "Talkwalker__TrendingAlert__top_languages":
      case "Talkwalker__NegativePostsAlert__top_languages":
        setAlertViewWidgets({
          ...alertViewWidgets,
          topLanguage: response?.eventData?.top_languages,
          topLanguagePreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Talkwalker__NewPostAlert__sentiment_analysis":
      case "Talkwalker__TrendingAlert__sentiment_analysis":
      case "Talkwalker__NegativePostsAlert__sentiment_analysis":
        setAlertViewWidgets({
          ...alertViewWidgets,
          commentsSentimentAnalysis: response?.eventData?.sentiment_piechart,
          commentsSentimentAnalysisPreLoaderDataSources: [response?.monitor_id],
        });
        break;
      case "Talkwalker__NewPostAlert__posts_interactions":
      case "Talkwalker__TrendingAlert__posts_interactions":
      case "Talkwalker__NegativePostsAlert__posts_interactions":
        setAlertViewWidgets({
          ...alertViewWidgets,
          engagementsDistribution: response?.eventData?.posts_interactions,
          engagementsDistributionPreLoaderDataSources: [response?.monitor_id],
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setAlertViewWidgets({ ...initialWidgetsState });
    }
  }, [resetAllFields]);

  return (
    <>
      {alertsDataWidgets?.includes("HeaderAlertWidget") ? (
        <AlertStatistics
          alertDataSource={"talkwalker"}
          headerAlertWidget={alertViewWidgets?.headerAlertWidget}
          headerAlertWidgetPreLoaderDataSources={
            alertViewWidgets?.headerAlertWidgetPreLoaderDataSources
          }
          preLoaderTrackerDataSources={alertDetailMonitorId}
        />
      ) : null}
      {alertsDataWidgets?.includes("TopPostsWidget") ? (
        <Box mt={3}>
          <SectionTitle sectionTitle="alert_new_posts_section" />
          <QuestionCard
            latestUnanswer={alertViewWidgets?.topPosts?.slice(0, 6)}
            latestUnanswerPreloader={
              alertViewWidgets?.topPostsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={alertDetailMonitorId}
            noOfChar={15}
            showHeart
            hideVector
            hideChartBar
            showFlash
            showEye
            showEarth
            icon={faNewspaper}
            newsBlogImg
            iconColor={"news-blogs-icon"}
            isAlert={true}
            datasourcePostsType="NEWSBLOGS"
          />
        </Box>
      ) : null}
      <Grid container spacing={2.5} mt={2.5}>
        {alertsDataWidgets?.includes("TopEngagedSitesWidget") ? (
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"alert_top_engaged_sites"}
              data={alertViewWidgets?.topEngagedSites}
              leftColomnTitle={"links"}
              rightColomnTitle={"nb_posts"}
              keywords
              tablePagination
              count={alertViewWidgets?.topEngagedSites?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topEngagedSitesPreLoaderDataSources
              }
              showDownloadIcon
            />
          </Grid>
        ) : null}
        {alertsDataWidgets?.includes("TopSitesWidget") ? (
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"alert_top_sites"}
              data={alertViewWidgets?.topSites}
              leftColomnTitle={"links"}
              rightColomnTitle={"reach"}
              hashtags
              tablePagination
              count={alertViewWidgets?.topSites?.length}
              preLoaderTrackerDataSources={alertDetailMonitorId}
              tableChartPreLoaderDataSources={
                alertViewWidgets?.topSitesPreLoaderDataSources
              }
              showDownloadIcon
            />
          </Grid>
        ) : null}
      </Grid>

      <SectionTitle sectionTitle="metrics" />

      <Grid container spacing={2.5} mt={3}>
        {alertsDataWidgets?.includes("TopLanguagesWidget") ? (
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_languages"}
              data={alertViewWidgets?.topLanguage}
              areaStyle={true}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={
                alertViewWidgets?.topLanguagePreLoaderDataSources
              }
              preLoaderTrackerDataSources={alertDetailMonitorId}
            />
          </Grid>
        ) : null}
        {alertsDataWidgets?.includes("SentimentAnalysisWidget") ? (
          <Grid item xs={6}>
            <PieChartWidget
              title={"alert_comments_sentiment_analysis"}
              data={alertViewWidgets?.commentsSentimentAnalysis}
              areaStyle={true}
              showDownloadIcon
              chartType={"sentiment"}
              pieChartPreLoaderDataSources={
                alertViewWidgets?.commentsSentimentAnalysisPreLoaderDataSources
              }
              preLoaderTrackerDataSources={alertDetailMonitorId}
              showAI
              color={["#89BB2A", "#E50035", "#F9A700"]}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default NewPostAlertNewsBlogs;
