import { useEffect } from "react";
import { useIntl } from "react-intl";

// Fontawesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
// Fontawesome Icons
import {
  faChartColumn,
  faSync,
  faGlobeAsia,
} from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import SnackBar from "components/snackBar.js";
import IconFunctions from "pages/Monitors/Components/headerIconFunctions";
import { Tooltip, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  numberFormatter,
  CheckValueLocale,
  isEmptyObj,
} from "utils/helpers/index";

const MediaFbItem = (props) => {
  const intl = useIntl();
  const {
    snackBarData,
    openSnackBar,
    handleCloseSnack,
    getMoreInsights,
    loader,
  } = IconFunctions();

  const { question, insightsID, response, dates, datasourcePostsType } = props;

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v6.0";
    document.head.appendChild(script);
    script.onload = () => {
      window.FB.XFBML?.parse();
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="mediaitem-wrapper">
      <div
        className="fb-post"
        data-height="360"
        data-width={props?.question ? "350px" : "450px"}
        data-show-text={false}
        data-href={`https://www.facebook.com/${props?.media?.page_id}/posts/${
          props?.media.post_id?.split("_")[1]
        }/`}
      ></div>
      {/* {props.hideTotalInteractions !== true && (
        <div className={"top-media__text"}>
          <span>
            {intl.formatMessage({ id: "total_interactions" })} :{" "}
            {props.media.comments_count}
          </span>
          <div className="top-media__icon">
            <FontAwesomeIcon icon={faFacebookF} style={{ color: "#3B6BAD" }} />
          </div>
        </div>
      )} */}
      {props?.isCustomDashboard
        ? null
        : props?.hideTotalInteractions !== true &&
          (insightsID?.indexOf(props?.question?.post_id) === -1 ? (
            <Box
              className="top-media-insights"
              onClick={() =>
                getMoreInsights(
                  props?.question,
                  response,
                  insightsID,
                  dates,
                  false,
                  true,
                  datasourcePostsType,
                )
              }
            >
              {!loader ? (
                <FontAwesomeIcon icon={faChartColumn} />
              ) : (
                <CircularProgress className="circular-progress" />
              )}
              {CheckValueLocale("more_insights", "", {}, intl)}
            </Box>
          ) : (
            <Box mt={1} className="top-media-insights-resp">
              <Box className="question-card-footer">
                <Box className="statistics-icons card-more-insights">
                  <Box display="inherit">
                    {response?.length !== 0 && (
                      <>
                        <Box>
                          <FontAwesomeIcon icon={faEye} />
                          {numberFormatter(
                            response?.[insightsID?.indexOf(question?.post_id)]
                              ?.reach?.[0]?.value,
                          )}
                        </Box>

                        {!isEmptyObj(
                          response?.[insightsID?.indexOf(question?.post_id)]
                            ?.impressions,
                        ) ? (
                          <Box>
                            <FontAwesomeIcon icon={faGlobeAsia} />
                            {parseInt(
                              response?.[insightsID?.indexOf(question?.post_id)]
                                ?.impressions?.[0]?.value,
                            )?.toLocaleString()}
                          </Box>
                        ) : null}
                      </>
                    )}
                  </Box>
                  <Box display="flex" component={"span"}>
                    <Typography
                      margin={0.5}
                      variant={"caption"}
                      color={"#757575"}
                    >
                      {CheckValueLocale("last_update", "", {}, intl)}
                      {dates?.[insightsID?.indexOf(question.post_id)]}
                    </Typography>
                    <Tooltip
                      title={CheckValueLocale("update_insights", "", {}, intl)}
                      placement="top"
                      arrow
                    >
                      <Box component="span">
                        <FontAwesomeIcon
                          icon={faSync}
                          className="more-insights"
                          onClick={() =>
                            getMoreInsights(
                              props?.question,
                              response,
                              insightsID,
                              dates,
                              true,
                              true,
                              datasourcePostsType,
                            )
                          }
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      {openSnackBar && (
        <SnackBar
          open={openSnackBar}
          autoHideDuration={5000}
          severity={snackBarData.severity}
          message={
            snackBarData.message !== "" &&
            CheckValueLocale(snackBarData.message, "", {}, intl)
          }
          title={
            snackBarData.title !== "" &&
            CheckValueLocale(snackBarData.title, "", {}, intl)
          }
          handleClose={handleCloseSnack}
        />
      )}
    </div>
  );
};

export default MediaFbItem;
