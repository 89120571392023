import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import "react-intl-tel-input/dist/main.css";
import { CheckValueLocale, getSortedArray } from "utils/helpers";
import "./editUser.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { MaineEditCard } from "./components/MainEditCard";
import EditHeader from "./components/editHeader";
import CircularLoading from "components/circularProgress";
import CompaniesController from "services/controllers/companiesController";
import { workgingHoursData } from "../addNewUser/tabs/workingHours";
import { TeamsAndTabs } from "./components/teamsAndTabs";
import {
  advancedInfoTabsAdapter,
  countAdvancedTabsDataDifferences,
  countInfoTabsDataDifferences,
  inboxDataAdapter,
  isTeamsDifferent,
} from "./adapterFunctions";
import { EditUserFooter } from "./components/editUserFooter";
import ProductsPermissions from "../addNewUser/componants/productsPermissions/productsPermissions";
import AccessTable from "../addNewUser/componants/accessTables/accessTable";
import _ from "lodash";
import SnackBar from "components/snackBar.js";
import {
  generateUserPayload,
  typeFormatMonitor,
} from "../sharedLogic/inviteUserAdapter";

const EditUser = (props) => {
  const intl = useIntl();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const [numberOfChangesTables, setNumberOfChangesTables] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    tit: null,
    msg: null,
  });
  const originalUserData = useRef({});
  const history = useHistory();

  const [teamsData, setTeamsData] = useState({
    teamsOptions: [],
    teamsSelected: [],
  });
  const [advancedTabsData, setAdvancedTabsData] = useState({
    languages: [{ value: "", subValue: "" }],
    skills: [{ value: "", subValue: "" }],
    workingHours: workgingHoursData,
  });
  const [infoTabsData, setInfoTabsData] = useState({
    sentiments: [],
    themes: [],
    segments: [],
    topics: [
      {
        id: "",
        name: "",
        topics: [],
      },
    ],
    dialects: [],
  });
  const [tabsErrors, setTabsErrors] = useState({
    topicsPreError: false,
    topics: false,
    workgingHoursPreError: false,
    workingHours: false,
  });
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    mail: "",
    phone: "",
    job_title: "",
    Valid: false,
  });

  const [isEngagAcc, setIsEngagAcc] = useState(false);

  const [stepTwoData, setStepTwoData] = useState({
    general_setting: "moderator",
    social_listening: "moderator",
    channel_analytics: "moderator",
    surveys: "moderator",
    audience: "moderator",
    engagement: "no_acc",
    lucidya_api: "moderator",
    ai_agent: "no_acc",
  });
  const [cxmData, setCxmData] = useState({});
  const [smData, setSmData] = useState({});
  const [accData, setAccData] = useState({});
  const [callApiDone, setCallApiDone] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [accTableDetailsData, setAccTableDetailsData] = useState({
    account_access: [],
    account_access_page: 1,
    account_access_counter: 0,
    account_access_pages: 0,
    account_access_loading: false,
    monitors_access: [],
    monitors_access_page: 1,
    monitors_access_counter: 0,
    monitors_access_pages: 0,
    monitors_access_loading: false,
    channels_access: [],
    channels_access_page: 1,
    channels_access_counter: 0,
    channels_access_pages: 0,
    channels_access_loading: false,
  });
  const [accTableData, setAccTableData] = useState({
    engagement_features: [],
    account_access: [],
    monitors_access: [],
    channels_access: [],
  });

  const [accTableSelectedData, setAccTableSelectedData] = useState({
    engagement_features: {
      engag_acc_title: [],
      engag_tabs: [],
      saved_reps_mang: [],
    },
    account_access: {},
    monitors_access: {},
    channels_access: {},
  });

  const [accTableIntialData, setAccTableIntialData] = useState({
    engagement_features: {
      engag_acc_title: [],
      engag_tabs: [],
      saved_reps_mang: [],
    },
    account_access: {},
    monitors_access: {},
    channels_access: {},
  });

  const [stepTwoIntialData, setStepTwoIntialData] = useState({});
  const [stepOneIntialData, setStepOneIntialData] = useState({
    first_name: "",
    last_name: "",
    mail: "",
    phone: "",
    job_title: "",
  });
  const [tableCols, setTableCols] = useState({});

  const [accEngagId, setAccEngagId] = useState({
    engag_acc_title: null,
    engag_tabs: null,
    saved_reps_mang: null,
  });

  let activeProducts = JSON.parse(window?.localStorage?.activeProducts);
  let smId = activeProducts?.filter(
    (item) => item?.name == "SM" && item?.active,
  )?.[0]?.id;

  let cxmId = activeProducts?.filter(
    (item) => item?.name == "CXM" && item?.active,
  )?.[0]?.id;

  let isEngagActive = activeProducts?.filter(
    (item) => item?.name == "ENGAGEMENTS" && item?.active,
  )?.[0]?.id;

  const handleCancel = () => {
    history.push("/settings/user_managements");
  };

  const getAccount = async (pageNum, limit = 6) => {
    setAccTableDetailsData({
      ...accTableDetailsData,
      account_access_loading: true,
    });
    await CompaniesController.getAccountsEdit(pageNum, limit, id).then(
      (result) => {
        if (result?.data?.data?.length) {
          let data = [];
          result?.data?.data?.map((item) => {
            if (item?.attributes?.account_type == "TWITTER") {
              data.push({
                id: `TWITTER-${item?.attributes?.id}`,
                data_source: item?.attributes?.account_type,
                profileImg: item?.attributes?.image_url,
                name: item?.attributes?.name,
                handleName: item?.attributes?.user_name
                  ? item?.attributes?.user_name?.includes("@")
                    ? item?.attributes?.user_name
                    : "@" + item?.attributes?.user_name
                  : null,
              });
            }
          });

          result?.data?.included?.map((item) => {
            data.push({
              id: `${
                item?.type == "facebook_page" ? "FACEBOOK" : "INSTAGRAM"
              }-${item?.attributes?.id}`,
              data_source:
                item?.type == "facebook_page" ? "FACEBOOK" : "INSTAGRAM",
              profileImg: item?.attributes?.image_url,
              name: item?.attributes?.name,
              handleName: item?.attributes?.user_name
                ? item?.attributes?.user_name?.includes("@")
                  ? item?.attributes?.user_name
                  : "@" + item?.attributes?.user_name
                : null,
            });
          });
          setAccTableData({
            ...accTableData,
            account_access: [...accTableData?.account_access, ...data],
          });
          setAccTableDetailsData({
            ...accTableDetailsData,
            account_access: [...accTableDetailsData?.account_access, ...data],
            account_access_counter: result?.data?.pagination?.next
              ? result?.data?.pagination?.count +
                [...accTableDetailsData?.account_access, ...data]?.length
              : [...accTableDetailsData?.account_access, ...data]?.length,
            account_access_pages: result?.data?.pagination?.pages,
            account_access_loading: false,
          });
          setAccData({
            account_access: [...data],
            account_access_counter: result?.data?.pagination?.next
              ? result?.data?.pagination?.count +
                [...accTableDetailsData?.account_access, ...data]?.length
              : [...accTableDetailsData?.account_access, ...data]?.length,
            account_access_pages: result?.data?.pagination?.pages,
            account_access_loading: false,
          });
        }
      },
    );
  };

  const getMonitors = async (pageNum, limit = 6) => {
    setAccTableDetailsData({
      ...accTableDetailsData,
      monitors_access_loading: true,
    });
    await CompaniesController.getMonitorsOrChannelsEdit(
      smId,
      pageNum,
      limit,
      id,
    ).then((result) => {
      if (result?.data?.data?.length) {
        let data = [];
        result?.data?.data?.map((item) =>
          data.push({
            id: item?.attributes?.id,
            data_source: item?.attributes?.data_sources || [],
            type: typeFormatMonitor(item?.attributes?.monitor_type_name),
            name: item?.attributes?.account_name,
          }),
        );
        setAccTableData({
          ...accTableData,
          monitors_access: [...accTableData?.monitors_access, ...data],
        });
        setAccTableDetailsData({
          ...accTableDetailsData,
          monitors_access: [...accTableDetailsData?.monitors_access, ...data],
          monitors_access_counter: result?.data?.pagination?.count,
          monitors_access_pages: result?.data?.pagination?.pages,
          monitors_access_loading: false,
        });
        setSmData({
          monitors_access: [...data],
          monitors_access_counter: result?.data?.pagination?.count,
          monitors_access_pages: result?.data?.pagination?.pages,
          monitors_access_loading: false,
        });
      }
    });
  };

  const getChannels = async (pageNum, limit = 6) => {
    setAccTableDetailsData({
      ...accTableDetailsData,
      channels_access_loading: true,
    });
    await CompaniesController.getMonitorsOrChannelsEdit(
      cxmId,
      pageNum,
      limit,
      id,
    ).then((result) => {
      if (result?.data?.data?.length) {
        let data = [];
        result?.data?.data?.map((item) =>
          data.push({
            id: item?.attributes?.id,
            data_source: item?.attributes?.data_sources || [],
            type: item?.attributes?.is_dm ? "dm_cxm" : "public_cxm",
            name: item?.attributes?.account_name,
          }),
        );
        setAccTableData({
          ...accTableData,
          channels_access: [...accTableData?.channels_access, ...data],
        });
        setAccTableDetailsData({
          ...accTableDetailsData,
          channels_access: [...accTableDetailsData?.channels_access, ...data],
          channels_access_counter: result?.data?.pagination?.count,
          channels_access_pages: result?.data?.pagination?.pages,
          channels_access_loading: false,
        });
        setCxmData({
          channels_access: [...data],
          channels_access_counter: result?.data?.pagination?.count,
          channels_access_pages: result?.data?.pagination?.pages,
          channels_access_loading: false,
        });
      }
    });
  };

  const getEngagPermissions = async () => {
    await CompaniesController.getEngagPermissions().then((result) => {
      if (result?.data) {
        setAccEngagId({
          engag_acc_title:
            result?.data?.engagement_features?.engagement_access?.id,
          engag_tabs: result?.data?.engagement_features?.engagement_tabs?.id,
          saved_reps_mang: result?.data?.engagement_features?.saved_replies?.id,
        });
        setTableCols({ ...result?.data });
      }
    });
  };

  const getUserView = async (id) => {
    await CompaniesController.getUserView(id).then((res) => {
      if (!res?.errorMsg) {
        setUserData({
          id: id,
          name: `${res?.data?.included[0]?.attributes?.first_name?.toString()} ${res?.data?.included[0]?.attributes?.last_name?.toString()}`,
          first_name:
            res?.data?.included[0]?.attributes?.first_name?.toString(),
          last_name: res?.data?.included[0]?.attributes?.last_name?.toString(),
          mail: res?.data?.data?.attributes?.email?.toString(),
          phone: res?.data?.included[0]?.attributes?.phone_number?.toString(),
          job_title: res?.data?.included[0]?.attributes?.job_title?.toString(),
          avatar: res?.data?.data?.attributes?.avatar,
        });
        setStepOneIntialData({
          first_name:
            res?.data?.included[0]?.attributes?.first_name?.toString(),
          last_name: res?.data?.included[0]?.attributes?.last_name?.toString(),
          mail: res?.data?.data?.attributes?.email?.toString(),
          phone: res?.data?.included[0]?.attributes?.phone_number?.toString(),
          job_title: res?.data?.included[0]?.attributes?.job_title?.toString(),
          avatar: res?.data?.data?.attributes?.avatar,
        });
        const data = res?.data?.data?.attributes;
        const advancedTabsData = advancedInfoTabsAdapter(data);
        const inboxTabsData = inboxDataAdapter(data);
        setTeamsData({
          teamsSelected: data?.user_teams || [],
          teamsOptions: [],
        });
        setAdvancedTabsData(advancedTabsData);
        setInfoTabsData(inboxTabsData);
        originalUserData.current = {
          teams: data?.user_teams || [],
          // deep copy of data
          advancedTabsData: JSON.parse(JSON.stringify(advancedTabsData)),
          infoTabsData: JSON.parse(JSON.stringify(inboxTabsData)),
        };

        //permissions
        let productNames = {
          GENERAL: "general_setting",
          CXM: "channel_analytics",
          SM: "social_listening",
          SURVEY: "surveys",
          CDP: "audience",
          ENGAGEMENTS: "engagement",
          PUBLIC_API: "lucidya_api",
          AI_API: "lucidya_api",
          AI_AGENT: "ai_agent",
        };

        let roleMapper = {
          product_admin: "manager",
          viewer: "viwer",
          user: "moderator",
          team_leader_egag: "team_leader",
          agent_egag: "agent_egagment",
          no_access_egag: "no_acc",
        };

        let selectedObj = {
          general_setting: "moderator",
          social_listening: "moderator",
          channel_analytics: "moderator",
          surveys: "moderator",
          audience: "moderator",
          engagement: "no_acc",
          lucidya_api: "moderator",
          ai_agent: "no_acc",
        };

        res?.data?.data?.attributes?.permissions?.map((item) => {
          selectedObj[productNames[item?.product]] =
            roleMapper[
              item?.product == "ENGAGEMENTS" || item?.product == "AI_AGENT"
                ? `${item?.role}_egag`
                : item?.role
            ];
        });
        setStepTwoIntialData({ ...selectedObj });
        setIsEngagAcc(selectedObj["engagement"] !== "no_acc");
        setStepTwoData({ ...selectedObj });

        //acc table engag
        let objIntial = {
          engagement_features: {
            engag_acc_title: [],
            engag_tabs: [],
            saved_reps_mang: [],
          },
          account_access: {},
          monitors_access: {},
          channels_access: {},
        };
        if (
          res?.data?.data?.attributes?.engagements_permissions
            ?.engagement_features?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.engagement_features?.map(
            (item) => {
              if (item?.feature_name === "ENGAGEMENT_ACCESS") {
                objIntial.engagement_features.engag_acc_title =
                  item?.feature_permissions;
              } else if (item?.feature_name === "ENGAGEMENT_TABS") {
                objIntial.engagement_features.engag_tabs =
                  item?.feature_permissions;
              } else {
                objIntial.engagement_features.saved_reps_mang =
                  item?.feature_permissions;
              }
            },
          );
        }

        if (
          res?.data?.data?.attributes?.engagements_permissions?.accounts_access
            ?.data?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.accounts_access?.data?.map(
            (item) => {
              if (item?.attributes?.access_permissions?.length) {
                objIntial.account_access[
                  `${item?.attributes?.data_source}-${item?.attributes?.account_id}`
                ] = item?.attributes?.access_permissions;
              }
            },
          );
        }

        if (
          res?.data?.data?.attributes?.engagements_permissions?.channels_access
            ?.data?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.channels_access?.data?.map(
            (item) => {
              objIntial.channels_access[item?.id] = ["ACCESS"];
            },
          );
        }

        if (
          res?.data?.data?.attributes?.engagements_permissions?.monitors_access
            ?.data?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.monitors_access?.data?.map(
            (item) => {
              objIntial.monitors_access[item?.id] = ["ACCESS"];
            },
          );
        }
        setAccTableIntialData(_.cloneDeep({ ...objIntial }));
        setAccTableSelectedData(_.cloneDeep({ ...objIntial }));
      }
    });
  };
  const handleResponseFunc = async () => {
    setIsLoading(true);
    await getUserView(id);
    if (isEngagActive) {
      await getEngagPermissions();
      await getAccount(accTableDetailsData?.account_access_page, 6);

      smId && (await getMonitors(accTableDetailsData?.monitors_access_page, 6));

      cxmId &&
        (await getChannels(accTableDetailsData?.channels_access_page, 6));
    }
    setCallApiDone(true);
  };
  useEffect(() => {
    handleResponseFunc();
  }, []);

  // handle pagination
  useEffect(() => {
    if (accTableDetailsData?.account_access_page > 1) {
      getAccount(accTableDetailsData?.account_access_page, 6);
    }
  }, [accTableDetailsData?.account_access_page]);

  useEffect(() => {
    if (accTableDetailsData?.monitors_access_page > 1) {
      getMonitors(accTableDetailsData?.monitors_access_page, 6);
    }
  }, [accTableDetailsData?.monitors_access_page]);

  useEffect(() => {
    if (accTableDetailsData?.channels_access_page > 1) {
      getChannels(accTableDetailsData?.channels_access_page, 6);
    }
  }, [accTableDetailsData?.channels_access_page]);

  // handle Data and stop loading
  useEffect(() => {
    if (callApiDone) {
      let objColamsData = {};
      let objData = {
        ...accTableData,
        account_access: accData?.account_access?.length
          ? [...accData?.account_access]
          : [],
      };
      if (smId) {
        objData = {
          ...objData,
          monitors_access: smData?.monitors_access?.length
            ? [...smData?.monitors_access]
            : [],
        };
      }
      if (cxmId) {
        objData = {
          ...objData,
          channels_access: cxmData?.channels_access?.length
            ? [...cxmData?.channels_access]
            : [],
        };
      }
      if (tableCols?.engagement_features) {
        if (tableCols?.engagement_features?.engagement_access) {
          objData.engagement_features = ["engag_acc_title"];
          let dataArr =
            tableCols?.engagement_features?.engagement_access?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features0 = getSortedArray(
            [
              "FULL_ACCESS",
              "INBOX",
              "PUBLISH",
              "ENGAGEMENT_ANALYTICS",
              "SL_ENGAGEMENT",
              "OC_ENGAGEMENT",
            ],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.engagement_tabs) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "engag_tabs",
          ];
          let dataArr =
            tableCols?.engagement_features?.engagement_tabs?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features1 = getSortedArray(
            ["FULL_ACCESS", "ALL_TAB", "UNASSIGNED_TAB"],
            dataArr,
          );
        }
        if (tableCols?.engagement_features?.saved_replies) {
          objData.engagement_features = [
            ...objData?.engagement_features,
            "saved_reps_mang",
          ];
          let dataArr =
            tableCols?.engagement_features?.saved_replies?.permissions?.map(
              (item) => item?.permission_name,
            );
          objColamsData.engagement_features2 = getSortedArray(
            ["FULL_ACCESS", "CREATE_SAVED_REPLY", "DELETE_SAVED_REPLY"],
            dataArr,
          );
        }
      } else {
        delete objData?.engagement_features;
      }

      if (tableCols?.channels_access) {
        objColamsData.channels_access = tableCols?.channels_access?.map(
          (item) => item?.permission_name,
        );
      } else {
        delete objData?.channels_access;
      }
      if (tableCols?.monitors_access) {
        objColamsData.monitors_access = tableCols?.monitors_access?.map(
          (item) => item?.permission_name,
        );
      } else {
        delete objData?.monitors_access;
      }
      if (tableCols?.account_access) {
        let dataArr = tableCols?.account_access?.map(
          (item) => item?.permission_name,
        );
        objColamsData.account_access = getSortedArray(
          ["FULL_ACCESS", "REPLY", "PUBLISH"],
          dataArr,
        );
      } else {
        delete objData?.account_access;
      }
      setTableCols({ ...objColamsData });
      setAccTableData({
        ...objData,
      });
      setAccTableDetailsData({
        ...accTableDetailsData,
        ...smData,
        ...accData,
        ...cxmData,
      });
      setIsLoading(false);
    }
  }, [callApiDone]);

  const links = [
    {
      name: "settings",
      url: "settings",
    },
    {
      name: "user_list",
      url: "settings/user_managements",
    },
    {
      name: `${userData.first_name} ${userData.last_name}`,
      url: `settings/user_managements/view/${id}`,
    },
  ];

  useEffect(() => {
    if (!Object.keys(originalUserData.current)?.length) return;
    try {
      let counter = 0;
      const teamsDiff = isTeamsDifferent(
        teamsData?.teamsSelected,
        originalUserData.current?.teams || [],
      );
      if (teamsDiff) counter++;
      const advancedTabsDiffCounter = countAdvancedTabsDataDifferences(
        advancedTabsData,
        originalUserData.current?.advancedTabsData,
      );
      counter += advancedTabsDiffCounter;
      const infoTabsDiffCounter = countInfoTabsDataDifferences(
        infoTabsData,
        originalUserData.current?.infoTabsData,
      );
      counter += infoTabsDiffCounter;
      setNumberOfChanges(counter);
    } catch (e) {
      // handle error
    }
  }, [teamsData, advancedTabsData, infoTabsData]);

  useEffect(() => {
    let counter = 0;
    if (userData?.avatar !== stepOneIntialData?.avatar) {
      ++counter;
    }
    if (
      userData?.first_name?.trim() !== stepOneIntialData?.first_name?.trim()
    ) {
      ++counter;
    }
    if (userData?.last_name?.trim() !== stepOneIntialData?.last_name?.trim()) {
      ++counter;
    }
    if (userData?.mail !== stepOneIntialData?.mail) {
      ++counter;
    }
    if (userData?.job_title?.trim() !== stepOneIntialData?.job_title?.trim()) {
      ++counter;
    }
    if (userData?.phone !== stepOneIntialData?.phone) {
      ++counter;
    }
    Object.keys(stepTwoData)?.map((item) => {
      if (stepTwoData[item] !== stepTwoIntialData[item]) {
        ++counter;
      }
    });

    if (
      accTableSelectedData?.engagement_features?.engag_acc_title?.length !==
      accTableIntialData?.engagement_features?.engag_acc_title?.length
    ) {
      ++counter;
    } else {
      let done = false;
      accTableSelectedData?.engagement_features?.engag_acc_title?.map(
        (item) => {
          if (
            !accTableIntialData?.engagement_features?.engag_acc_title?.includes(
              item,
            ) &&
            !done
          ) {
            done = true;
            ++counter;
          }
        },
      );
    }

    if (
      accTableSelectedData?.engagement_features?.saved_reps_mang?.length !==
      accTableIntialData?.engagement_features?.saved_reps_mang?.length
    ) {
      ++counter;
    } else {
      let done = false;
      accTableSelectedData?.engagement_features?.saved_reps_mang?.map(
        (item) => {
          if (
            !accTableIntialData?.engagement_features?.saved_reps_mang?.includes(
              item,
            ) &&
            !done
          ) {
            done = true;
            ++counter;
          }
        },
      );
    }
    if (
      accTableSelectedData?.engagement_features?.engag_tabs?.length !==
      accTableIntialData?.engagement_features?.engag_tabs?.length
    ) {
      ++counter;
    } else {
      let done = false;
      accTableSelectedData?.engagement_features?.engag_tabs?.map((item) => {
        if (
          !accTableIntialData?.engagement_features?.engag_tabs?.includes(
            item,
          ) &&
          !done
        ) {
          done = true;
          ++counter;
        }
      });
    }
    if (
      Object.keys(accTableSelectedData?.account_access)?.length !==
      Object.keys(accTableIntialData?.account_access)?.length
    ) {
      ++counter;
    } else {
      let done = false;
      Object.keys(accTableSelectedData?.account_access)?.map((item) => {
        if (
          accTableSelectedData?.account_access[item]?.length !==
            accTableIntialData?.account_access[item]?.length &&
          !done
        ) {
          done = true;
          ++counter;
        } else if (!done) {
          accTableSelectedData?.account_access[item]?.map((perm) => {
            if (!accTableIntialData?.account_access[item]?.includes(perm)) {
              done = true;
              ++counter;
            }
          });
        }
      });
    }

    if (
      Object.keys(accTableSelectedData?.monitors_access)?.length !==
      Object.keys(accTableIntialData?.monitors_access)?.length
    ) {
      ++counter;
    } else {
      let done = false;
      Object.keys(accTableSelectedData?.monitors_access)?.map((item) => {
        if (
          accTableSelectedData?.monitors_access[item]?.length !==
            accTableIntialData?.monitors_access[item]?.length &&
          !done
        ) {
          done = true;
          ++counter;
        } else if (!done) {
          accTableSelectedData?.monitors_access[item]?.map((perm) => {
            if (!accTableIntialData?.monitors_access[item]?.includes(perm)) {
              done = true;
              ++counter;
            }
          });
        }
      });
    }

    if (
      Object.keys(accTableSelectedData?.channels_access)?.length !==
      Object.keys(accTableIntialData?.channels_access)?.length
    ) {
      ++counter;
    } else {
      let done = false;
      Object.keys(accTableSelectedData?.channels_access)?.map((item) => {
        if (
          accTableSelectedData?.channels_access[item]?.length !==
            accTableIntialData?.channels_access[item]?.length &&
          !done
        ) {
          done = true;
          ++counter;
        } else if (!done) {
          accTableSelectedData?.channels_access[item]?.map((perm) => {
            if (!accTableIntialData?.channels_access[item]?.includes(perm)) {
              done = true;
              ++counter;
            }
          });
        }
      });
    }
    setNumberOfChangesTables(counter);
  }, [userData, stepTwoData, accTableSelectedData]);

  const saveEditUser = () => {
    setSaveLoading(true);
    let payLoadObj = generateUserPayload({
      stepTwoData,
      stepOneData: userData,
      isEngagement:
        stepTwoData?.engagement && stepTwoData?.engagement !== "no_acc",
      accTableSelectedData,
      infoTabsData,
      advancedTabsData,
      teamsData,
      accEngagId,
    });

    CompaniesController.editCompanyUsers(payLoadObj, id).then((result) => {
      if (!result?.errorMsg) {
        history.push(
          `/settings/user_managements/view/${id}?status=updated_successfully`,
        );
      } else {
        setOpenSnackBar({
          open: true,
          tit: "wrong_request_parameter",
          msg: "report_fail_try_later",
        });
      }

      setSaveLoading(false);
    });
  };

  useEffect(() => {
    if (!smId) {
      delete accTableData?.monitors_access;
      setAccTableData({ ...accTableData });
    }
    if (!cxmId) {
      delete accTableData?.channels_access;
      setAccTableData({ ...accTableData });
    }
  }, []);

  if (isLoading) return <CircularLoading />;
  return (
    <>
      <Box className="main-edit-user">
        <EditHeader links={links} />
        <Box className="main-edit-user-wrapper">
          {/*edite user card */}
          <MaineEditCard
            userData={userData}
            setUserData={setUserData}
            isEditUser
          />
          {/* teams and tabs */}
          <Box className="main-edit-user-card">
            <Box className="edit-sec-tit">
              {CheckValueLocale("prod_permissions", "", {}, intl)}
            </Box>
            <Box className="edit-sec-container">
              <ProductsPermissions
                stepTwoData={stepTwoData}
                isEngagAcc={isEngagAcc}
                setStepTwoData={setStepTwoData}
              />
            </Box>
          </Box>
          {stepTwoData?.engagement && stepTwoData?.engagement !== "no_acc" ? (
            <>
              <TeamsAndTabs
                advancedTabsData={advancedTabsData}
                setAdvancedTabsData={setAdvancedTabsData}
                infoTabsData={infoTabsData}
                setInfoTabsData={setInfoTabsData}
                tabsErrors={tabsErrors}
                setTabsErrors={setTabsErrors}
                teamsData={teamsData}
                setTeamsData={setTeamsData}
              />
              <Box className="main-edit-user-card">
                <Box className="edit-sec-container">
                  <Box className="edit-sec-tit">
                    {CheckValueLocale("adv_perm", "", {}, intl)}
                  </Box>
                  {Object.keys(accTableData)?.map((table) => {
                    return (
                      <Box
                        className={`main-acc-table-countainer ${table}`}
                        id={table}
                      >
                        {accTableData[table]?.length ? (
                          <Box className="main-table-tit">
                            {CheckValueLocale(
                              table,
                              "",
                              {
                                num1: Object.keys(accTableSelectedData[table])
                                  ?.length,
                                num2: accTableDetailsData[`${table}_counter`],
                              },
                              intl,
                            )}
                            {table == "account_access" ? (
                              <Box className="main-table-acc-prag">
                                {CheckValueLocale(
                                  "account_select_prag",
                                  "",
                                  {},
                                  intl,
                                )}
                              </Box>
                            ) : null}
                          </Box>
                        ) : null}
                        <AccessTable
                          name={table}
                          accTableData={accTableData}
                          accTableSelectedData={accTableSelectedData}
                          setAccTableSelectedData={setAccTableSelectedData}
                          accTableDetailsData={accTableDetailsData}
                          setAccTableDetailsData={setAccTableDetailsData}
                          setAccTableData={setAccTableData}
                          tableCols={tableCols}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>

      {numberOfChanges + numberOfChangesTables > 0 ? (
        <EditUserFooter
          handleCancel={handleCancel}
          numberOfChanges={numberOfChanges + numberOfChangesTables}
          handleSave={saveEditUser}
          isDisabled={
            !userData?.Valid ||
            (stepTwoData?.engagement &&
              stepTwoData?.engagement !== "no_acc" &&
              (tabsErrors?.topicsPreError || tabsErrors?.workgingHoursPreError))
          }
          isLoading={saveLoading}
        />
      ) : null}
      <SnackBar
        open={openSnackBar?.open}
        severity={"error"}
        message={CheckValueLocale(openSnackBar?.msg, "", {}, intl)}
        title={CheckValueLocale(openSnackBar?.tit, "", {}, intl)}
        handleClose={() => setOpenSnackBar({ open: false })}
      />
    </>
  );
};
export default EditUser;
