import { useMutation, useQueryClient } from "@tanstack/react-query";
import ThemesController from "services/controllers/themesController";

export const useDeleteTheme = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ label_id }) => ThemesController.deleteTheme({ label_id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["themes"],
      });
    },
  });
};
