import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InteractionsList from "components/interactions-list/InteractionsList.js";
import { useIntl } from "react-intl";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";

const InstagramInteractionBody = (props) => {
  const intl = useIntl();
  let { response } = props;
  const [latestInteractions, setLatestInteractions] = useState([]);
  const [latestInteractionsCount, setLatestInteractionsCount] = useState("");
  const [
    latestInteractionsPreLoaderDataSources,
    setLatestInteractionsPreLoaderDataSources,
  ] = useState([]);
  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response.eventName) {
      case "Instagram__EngagementsPage__top_posts_comments":
        setLatestInteractions(response?.eventData?.top_posts);
        // we use this case for [latestInteractionsCount Prop] as we use initial value to seperate Pages ,
        // because when move from page to another [latestInteractionsCount] get with value 0 .
        if (props.page === 1) {
          setLatestInteractionsCount(response?.eventData?.top_posts_count);
        }

        if (
          !latestInteractionsPreLoaderDataSources.includes(
            response?.data_source,
          )
        ) {
          setLatestInteractionsPreLoaderDataSources((oldArray) => [
            ...oldArray,
            response.data_source,
          ]);
        }
        break;
      default:
        break;
    }
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setLatestInteractions([]);
      // Values of DataSources totrack each widget preloader
      // setOverViewPreLoaderDataSources([]);
      setLatestInteractionsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);
  useEffect(() => {
    let x = checkLuciAllDataFounded(
      latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    latestInteractions,
    latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  return (
    <>
      <Box my={4}>
        <InteractionsList
          showProfile={true}
          unixDate={props.unixDate}
          data={latestInteractions}
          dataSources={props.dataSources}
          isInteractionPage={true}
          latestInteractionsPreLoaderDataSources={
            latestInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          pagenation={true}
          latestInteractionsCount={latestInteractionsCount}
          handleChangePage={props.handleChangePage}
          page={props.page}
          loader={props.loader}
        />
      </Box>
    </>
  );
};

export default InstagramInteractionBody;
