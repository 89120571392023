import Services from "./Services";

var TopHashtags = {};

TopHashtags.getOverall = (queryData) => {
  return Services.getData("api/v4/top_hashtags/twitter/overall", queryData);
};

TopHashtags.getHourlyDaily = (queryData) => {
  return Services.getData("api/v3/top_hashtags/twitter/timeframe", queryData);
};

TopHashtags.getLocation = (queryData) => {
  return Services.getData("api/v3/top_hashtags/list_of_woe_ids", queryData);
};

TopHashtags.getAnalyzeData = (queryData) => {
  return Services.getData("api/v3/top_hashtags/twitter/analyze", queryData);
};

export default TopHashtags;
