import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { InputField } from "./inputField/inputField";
import "./inboundRoutingSharedStyle.scss";
export const RoutingGeneralInfo = ({
  generalInfo,
  handleGeneralInfoChange,
  routingNameError,
  routingDescriptionError,
}) => {
  const intl = useIntl();
  return (
    <Box className="inbound-routing-general-info">
      <Typography className="inbound-routing-general-info-label">
        {CheckValueLocale("general_routing_info", "", {}, intl)}
      </Typography>
      <Box className="inbound-routing-general-info-form">
        <InputField
          label={CheckValueLocale("input_routing_name", "", {}, intl)}
          value={generalInfo?.name}
          onChange={(e) => handleGeneralInfoChange({ name: e?.target?.value })}
          error={routingNameError}
          helperText={
            routingNameError
              ? CheckValueLocale(routingNameError, "", {}, intl)
              : null
          }
        />
        <InputField
          label={CheckValueLocale("input_routing_description", "", {}, intl)}
          value={generalInfo?.description}
          onChange={(e) =>
            handleGeneralInfoChange({ description: e?.target?.value })
          }
          error={routingDescriptionError}
          helperText={
            routingDescriptionError
              ? CheckValueLocale(routingDescriptionError, "", {}, intl)
              : null
          }
        />
      </Box>
    </Box>
  );
};
