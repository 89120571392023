import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, isEmptyValue } from "utils/helpers";
import {
  AccountsOptionsRenderer,
  DualSelectOptionsRenderer,
  FollowersCountOptionsRenderer,
  KeywordsOptionsRenderer,
  LanguagesAndCountriesOptionsRenderer,
  MultiSelectOptionsRenderer,
  PlatformsOptionsRenderer,
} from "./optionsRenderer";
import { SelectFieldDropdown } from "../selectFieldDropdown/selectFieldDropdown";
import "../inboundRoutingSharedStyle.scss";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Fragment } from "react";
import { conditionsNamesMapper } from "../../sharedLogic/inboundRouteAdapter";
//---------------------------------------------------------------------
// Conditions base
// conditions are a group of rows that holds the condition data, each row has a condition, operator, and value.
// the condition operator and value can be different based on the type of the condition.
// e.g. value can be string, object, array, or a number depending on the condition that is selected from the beginning.
// the operator and value are disabled until the condition is selected.
// value is disabled until the operator is selected.
// when the user change the condition, in any row the operator and value will be reset to the default value.

// here is the structure of each row in the conditions
// conditions selets  +  operators selects +  value input<Array | String | Number>

const conditionValueMapper = {
  [conditionsNamesMapper.platform]: PlatformsOptionsRenderer,
  [conditionsNamesMapper.account]: AccountsOptionsRenderer,
  [conditionsNamesMapper.followers_count]: FollowersCountOptionsRenderer,
  [conditionsNamesMapper.message_type]: DualSelectOptionsRenderer,
  [conditionsNamesMapper.verified_account]: DualSelectOptionsRenderer,
  [conditionsNamesMapper.sentiment]: MultiSelectOptionsRenderer,
  [conditionsNamesMapper.themes]: MultiSelectOptionsRenderer,
  [conditionsNamesMapper.keyword]: KeywordsOptionsRenderer,
  [conditionsNamesMapper.languages]: LanguagesAndCountriesOptionsRenderer,
  [conditionsNamesMapper.countries]: LanguagesAndCountriesOptionsRenderer,
};

// validRegex is a function that checks if the value is a valid regex or not
const validRegex = (value) => {
  try {
    new RegExp(value);
    return true;
  } catch (e) {
    return false;
  }
};
export const isConditionRowValid = (condition) => {
  // and condition and operator should be a string
  // the value could be an array, object, string, or number
  // check the value if not emptys
  let isValueEmpty = isEmptyValue(condition?.value);
  const isOperatorRegexAndNotValid =
    condition?.operator?.toLowerCase?.() === "regex" &&
    !validRegex(condition?.value);
  const isConditionCountAndValueZero =
    condition?.condition === "followers_count" && condition?.value === "0";
  return (
    condition?.condition &&
    condition?.operator &&
    !isValueEmpty &&
    !isOperatorRegexAndNotValid &&
    !isConditionCountAndValueZero
  );
};

// filr the conditions options to remove the conditions that are already selected above
const filteredConditionsOptions = (conditionsOptions, conditions) =>
  conditionsOptions?.filter((condition) => {
    return !conditions?.find((c) => c?.condition === condition?.id);
  });

export const RoutingConditions = ({
  conditions,
  setConditions,
  isAllConditionsValid,
  conditionsOptions,
}) => {
  const intl = useIntl();
  const handleValueCondition = (index, value = {}) => {
    const newConditions = [...conditions];
    let newCondition = newConditions[index];
    if (value?.condition) {
      // if the value is condition, reset the operator and value
      newCondition = {
        condition: value?.condition,
        operator: "",
        value: "",
      };
    } else if (value?.operator) {
      // if the value is operator, reset the value
      newCondition = {
        condition: newCondition?.condition,
        operator: value?.operator,
        value: "",
      };
    } else {
      newCondition = {
        // if the value is value, update the value
        condition: newCondition?.condition,
        operator: newCondition?.operator,
        value: value?.value,
      };
    }
    newConditions[index] = newCondition;
    setConditions(newConditions);
  };
  const handleAddRow = () => {
    setConditions([...conditions, { condition: "", operator: "", value: "" }]);
  };
  const handleRemoveRow = (index) => {
    const rowData = conditions?.[index];
    // if only one row and has data, clear it
    if (conditions?.length === 1 && rowData?.condition) {
      setConditions([{ condition: "", operator: "", value: "" }]);
    } else if (conditions?.length > 1) {
      // if more than one row, remove the row
      setConditions(conditions?.filter((c, i) => i !== index));
    }
  };

  return (
    <Box className="inbound-routing-conditions inbound-routing-section">
      <Box className="inbound-routing-conditions-desc">
        <Typography className="inbound-routing-conditions-desc-title">
          {CheckValueLocale("routing_conditions", "", {}, intl)}
        </Typography>
        <Typography className="inbound-routing-conditions-desc-text">
          {CheckValueLocale("routing_conditions_desc", "", {}, intl)}
        </Typography>
      </Box>
      <Box className="inbound-routing-conditions-options">
        {conditions?.map((condition, index) => {
          // get  the condition options based on the condition id
          const conditionOptions = conditionsOptions?.find(
            (c) => c?.id === condition?.condition,
          );
          // get the value component based on the condition
          const ValueComponent = conditionValueMapper?.[condition?.condition];
          return (
            <Fragment key={index}>
              <Box className="inbound-routing-conditions-options-row">
                <Box className="inbound-routing-conditions-options-item">
                  <SelectFieldDropdown
                    label={CheckValueLocale("routing_condition", "", {}, intl)}
                    value={condition?.condition}
                    options={filteredConditionsOptions(
                      conditionsOptions,
                      conditions,
                    )}
                    onChange={(e) =>
                      handleValueCondition(index, { condition: e.target.value })
                    }
                    multiple={false}
                    renderValue={(selected) => {
                      return selected
                        ? CheckValueLocale(conditionOptions?.name, "", {}, intl)
                        : null;
                    }}
                    emptyListMessage="routing_no_condition_found"
                  />
                  {/*show a helper text under the condition field in case of the selected condition countries or languages*/}
                  {[
                    conditionsNamesMapper.countries,
                    conditionsNamesMapper.languages,
                  ]?.includes(condition?.condition) ? (
                    <Typography
                      component="p"
                      className="inbound-routing-conditions-options-item-helper-text"
                    >
                      {CheckValueLocale(
                        "routing_languages_and_countries_helper_text",
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  ) : null}
                  {/* show a helper text under the themes field*/}
                  {condition?.condition === conditionsNamesMapper.themes ? (
                    <Typography
                      component="p"
                      className="inbound-routing-conditions-options-item-helper-text"
                    >
                      {CheckValueLocale(
                        "routing_themes_helper_text",
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  ) : null}
                </Box>
                <Box className="inbound-routing-conditions-options-item">
                  <SelectFieldDropdown
                    label={CheckValueLocale("routing_operator", "", {}, intl)}
                    value={condition?.operator}
                    options={conditionOptions?.operators}
                    onChange={(e) =>
                      handleValueCondition(index, { operator: e.target.value })
                    }
                    disabled={!condition?.condition}
                  />
                </Box>
                <Box className="inbound-routing-conditions-options-item">
                  {ValueComponent ? (
                    <ValueComponent
                      options={conditionOptions?.values}
                      handleChange={(value) =>
                        handleValueCondition(index, { value })
                      }
                      value={condition?.value}
                      disabled={!condition?.operator}
                      condition={condition?.condition}
                      operator={condition?.operator}
                      label={conditionOptions?.label}
                    />
                  ) : (
                    <SelectFieldDropdown
                      label={CheckValueLocale("routing_value", "", {}, intl)}
                      name="value"
                      value=""
                      disabled={true}
                      fullWidth
                    />
                  )}
                </Box>
                <LucButton
                  variant="outline"
                  type="red"
                  minWidth={40}
                  className="icon-tab-button"
                  id="routing-condition-row-remover-btn"
                  onClick={() => handleRemoveRow(index)}
                  disabled={
                    // disable the remove button if the row has no data and it's the only row
                    conditions?.length === 1 && !isConditionRowValid(condition)
                  }
                >
                  <FontAwesomeIcon icon={faTimes} />
                </LucButton>
              </Box>
              {conditions?.length > 1 && index < conditions?.length - 1 ? (
                <Box className="inbound-routing-conditions-options-divider">
                  <Box className="inbound-routing-conditions-options-divider-line" />
                  <Typography className="inbound-routing-conditions-options-divider-text">
                    {CheckValueLocale("routing_and", "", {}, intl)}
                  </Typography>
                  <Box className="inbound-routing-conditions-options-divider-line" />
                </Box>
              ) : null}
            </Fragment>
          );
        })}
        <Box>
          <LucButton
            minWidth={40}
            className="icon-tab-button"
            id="routing-condition-row-adder-btn"
            disabled={
              // disable the add button if the last row's field are not completed
              !isAllConditionsValid ||
              conditions?.length === conditionsOptions?.length
            }
            onClick={handleAddRow}
          >
            <FontAwesomeIcon icon={faPlus} />
          </LucButton>
        </Box>
      </Box>
    </Box>
  );
};
