import { useState, useContext } from "react";
import FiltersController from "services/controllers/filtersController";
import UserContext from "context/audienceContext";

const FiltersFunctions = () => {
  const [filterName, setFilterName] = useState("");
  const [showSnackBar, setshowSnackBar] = useState(false);
  const [filterFields, setFilterFields] = useState([]);
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const ctx = useContext(UserContext);

  const handleCloseDeleteFilterModal = () => {
    setShowDeleteFilterModal(false);
  };
  const [filterParams, setFilterParams] = useState(ctx?.audienceFilters);
  // ______________________________________________________________________________________
  //this function empty all filter fields
  const handleClearFilterParams = () => {
    setFilterName("");
    setFilterParams({});
  };
  // ______________________________________________________________________________________
  const handleCloseSnackBar = () => {
    setshowSnackBar(false);
  };
  //This Call is to get All filter fields for CDP
  const getFiltersFileds = () => {
    FiltersController.getAudienceFiltersFileds(
      "audience",
      window.localStorage.getItem("cdp_id"),
    ).then((data) => {
      setFilterFields(data.data);
    });
  };
  return {
    handleCloseSnackBar,
    showSnackBar,
    handleClearFilterParams,
    setFilterParams,
    filterParams,
    filterFields,
    getFiltersFileds,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    setShowDeleteFilterModal,
    filterName,
    assignActiveFilters,
    setAssignActiveFilters,
  };
};
export default FiltersFunctions;
