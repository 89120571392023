import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import CompaniesController from "../../../../../services/controllers/companiesController";
import CircularProgress from "@mui/material/CircularProgress";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const QuotaItem = (props) => {
  const intl = useIntl();
  const quotaItem = props.quota;
  const [startUpgrade, setStartUpgrade] = useState(false);
  const [requestUpgradeText, setRequestUpgradeText] =
    useState("request_upgrade");
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [success, setSuccess] = useState(null);

  const handleUpgradeQuota = (quotaName) => {
    let serviceName;
    if (quotaName === "CXM") {
      serviceName = "request_channel_analytics_upgrade";
    } else {
      serviceName = "request_social_listening_upgrade";
    }
    serviceName = "";
    setStartUpgrade(true);
    setRequestUpgradeText("requesting");
    CompaniesController.upgradeQuota(serviceName).then((result) => {
      if (result.status === 201 || result.status === 200) {
        setSuccess(true);
        setRequestUpgradeText("requested");
        setTimeout(() => {
          setSuccess(null);
          setRequestUpgradeText("request_upgrade");
        }, 7000);
        setSnackBarMsg(
          <div className="historical-snackbar historical-snackbar-success">
            <h3>
              {CheckValueLocale(
                "upgrade_request_sent_successfully",
                "",
                {},
                intl,
              )}
            </h3>
            <span>{CheckValueLocale("upgrade_msg_body", "", {}, intl)}</span>
          </div>,
        );
        setSuccessStatus("success");
      } else {
        setSuccess(true);
        setSuccessStatus("error");
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(
          <div className="historical-snackbar historical-snackbar-fail">
            <h3>{CheckValueLocale("upgrade_request_failed", "", {}, intl)}</h3>
            <span>{CheckValueLocale("try_again", "", {}, intl)}</span>
          </div>,
        );
      }
      setStartUpgrade(false);
    });
  };
  return (
    <div id="sm-quota">
      {success && (
        <SnackBar
          open={success != null ? true : false}
          severity={successStatus}
          message={snackBarMsg}
          showCloseBtn={true}
        />
      )}

      <div className="quota-box">
        <div className="quota-header">
          <h3 className="quota-title">
            <span>{CheckValueLocale("platform", "", {}, intl)}</span>
            {CheckValueLocale(quotaItem?.name, "", {}, intl)}
          </h3>
          <LucButton
            variant="outline"
            type="secondary"
            onClick={() => handleUpgradeQuota(quotaItem.name)}
            className={
              requestUpgradeText === "requested" ? "success-upgrade" : null
            }
            disabled={requestUpgradeText !== "request_upgrade"}
            id="setting-historical-request-upgrade-btn"
            loading={startUpgrade}
          >
            {requestUpgradeText === "requested" ? (
              <FontAwesomeIcon icon={faCheck} className="requested-check" />
            ) : null}
            {CheckValueLocale(requestUpgradeText, "", {}, intl)}
          </LucButton>
        </div>
        <div className="quota-body">
          {Object.keys(quotaItem.quota).map((keyName, i) => (
            <div className="quota-single-item">
              <p>
                {CheckValueLocale(keyName + "_hr", "", {}, intl)}
                <span>
                  (
                  {CheckValueLocale(
                    quotaItem?.quota[keyName]?.type?.toLowerCase(),
                    "",
                    {},
                    intl,
                  )}
                  )
                </span>
              </p>
              <div className="data-usage">
                <b>{quotaItem.quota[keyName].used}</b>
                <span>
                  {CheckValueLocale("used_of", "", {}, intl)}{" "}
                  {quotaItem.quota[keyName].limit}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default QuotaItem;
