import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import "./audienceSegments.scss";
import AddSegment from "pages/audience/audiencePage/segmentOption/addSegmentPopUp";
import AudienceController from "services/controllers/audienceController";
import moment from "moment";
import SnackBar from "components/snackBar";
import PopupModal from "components/popupModal";
import LucButton from "shared/lucButton/lucButton";

const AudienceSegments = (props) => {
  const intl = useIntl();
  const { segmentsData, handleAlert, getUserProfile, segmentList } = props;
  const segementProfileData = segmentsData?.data?.details?.segments;
  const [addSegmentFlag, setAddSegmentFlag] = useState(false);
  const [profileSegmentName, setProfileSegmentName] = useState("");
  const [profileSegmentId, setProfileSegmentId] = useState([]);
  const [userId, setUserId] = useState([segmentsData?.data?.id]);
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const [success, setSuccess] = useState(null);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const open = Boolean(stats.anchorEl);

  const handleAddSegment = () => {
    setAddSegmentFlag(true);
  };

  const handleOpenDeleteModal = (id, segmentName) => {
    setProfileSegmentName(segmentName);
    setProfileSegmentId(id);
    setStats({
      openDeleteModal: true,
      title: `${CheckValueLocale(
        "segments_exclude_from_the_segment",
        "",
        {},
        intl,
      )}`,
      body: (
        <Box className="segment-delete-pop user-profile">
          <Box className="segment-delete-pop-title user-profile">
            {intl.formatMessage(
              { id: "segments_profile_exclude_segment" },
              {
                segment_name: (
                  <Box component="span" className="segment-num-deleted-users">
                    {segmentName}
                  </Box>
                ),
              },
            )}
          </Box>
          <Box className="segment-delete-popup-second-title user-profile">
            {CheckValueLocale(
              "segments_you_can_add_segment_again",
              "",
              {},
              intl,
            )}
          </Box>
        </Box>
      ),
      modalRightBtn: CheckValueLocale("exclude_segment_btn", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      warning: false,
    });
  };

  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
  };

  <PopupModal
    title={stats?.title}
    body={stats?.body}
    leftBtn={stats?.modalLeftBtn}
    rightBtn={stats?.modalRightBtn}
    open={stats?.openDeleteModal}
    close={handleCloseDeleteModal}
    accept={() => handleAcceptDeleteModal(userId)}
    warning={stats.warning}
    classeName="segment-delete-popup"
  />;

  const handleAcceptDeleteModal = () => {
    AudienceController.excludeSegmantProfile(
      [profileSegmentId],
      userId,
      false,
      startDate,
      endDate,
      "",
      {},
    ).then((data) => {
      if (data?.data?.status === 204 || data?.data?.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(
          CheckValueLocale(
            "segments_success_msg_profile",
            "",
            { segment_name: profileSegmentName },
            intl,
          ),
        );
        setSuccessStatus("success");
        showSnackBar("delete", "success");
        setTimeout(() => {
          getUserProfile();
        }, 3000);
      } else {
        setSuccess(true);
        setSuccessStatus("error");
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(CheckValueLocale("wrong_msg", "", {}, intl));
        setSnackBarTitle(CheckValueLocale("failed_error", "", {}, intl));
        showSnackBar("delete", "warning");
        setTimeout(() => {
          getUserProfile();
        }, 3000);
      }
    });
  };

  const showSnackBar = (action, severity) => {
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      openDeleteModal: false,
      openPauseResumeModal: false,
      snackBarMsg: "",
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Box className="interaction-page-list audience-segments">
        <Box className="segments-profile-header">
          <Typography className="audience-main-title" varinat="h6">
            {CheckValueLocale("segments_head", "", {}, intl)}
          </Typography>
          {handlelUserRoles("CDP", "CREATE_SEGMENT") && (
            <Box>
              <LucButton
                variant="flat"
                onClick={handleAddSegment}
                id="audience-user-profile-add-segement-btn"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
              >
                {CheckValueLocale("add_segment", "", {}, intl)}
              </LucButton>
            </Box>
          )}
        </Box>
        <Box>
          {segementProfileData?.length == 0 ? (
            <Typography className="audience-segments-empty-msg">
              {CheckValueLocale("segments_empty_msg", "", {}, intl)}
            </Typography>
          ) : (
            segementProfileData?.map(({ id, name: segmentName }) => (
              <Box key={id} className="segment-item">
                {segmentName}
                <Box
                  className="delete-segment-profile"
                  onClick={() => handleOpenDeleteModal(id, segmentName)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>

      {addSegmentFlag && (
        <AddSegment
          segmentList={segmentList}
          open={() => setAddSegmentFlag(true)}
          close={() => setAddSegmentFlag(false)}
          create={handleAlert}
          selectedUsersId={userId}
          isSelectAllProfiles={false}
          startDate={startDate}
          endDate={endDate}
          identifier={""}
          filters={{}}
          isSegementProfile
        />
      )}

      {stats.openDeleteModal && (
        <PopupModal
          title={stats?.title}
          body={stats?.body}
          leftBtn={stats?.modalLeftBtn}
          rightBtn={stats?.modalRightBtn}
          open={stats?.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={() => handleAcceptDeleteModal()}
          warning={stats.warning}
          classeName="segment-delete-popup"
        />
      )}
      {success && (
        <SnackBar
          open={success != null ? true : false}
          title={snackBarTitle}
          severity={successStatus}
          message={snackBarMsg}
          showCloseBtn={true}
        />
      )}
    </>
  );
};

export default AudienceSegments;
