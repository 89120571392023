import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import "../../surveyBuilder.scss";

const DropdownQuestion = (props) => {
  const {
    filteredSections,
    setSurveySections,
    surveySections,
    rootQuestion,
    question,
    questionIndex,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    handleCardClick,
    handleChangeProceed,
    proceedError,
  } = props;

  const intl = useIntl();
  const [choices, setChoices] = useState([]);
  const [value, setValue] = useState({});
  const [editable, setEditable] = useState(false);
  const [proceedSelects, setProceedSelects] = useState([]);

  const MenuProps = { PaperProps: { style: { maxHeight: 265 } } };

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setValue({ title: question?.text, description: question?.description });
      setChoices(question?.items);
    }
  }, [question]);

  useEffect(() => {
    if (filteredSections) {
      let selectsArray = [];
      filteredSections?.map((sec, index) => {
        if (index !== sectionIndex && index !== sectionIndex + 1)
          selectsArray?.push(sec);
      });
      setProceedSelects(selectsArray);
    }
  }, [filteredSections?.length]);

  const handleAddChoice = () => {
    let maxKey = null;
    choices?.forEach((obj) => {
      const key = parseInt(Object.keys(obj)[0]);
      if (maxKey === null || key > maxKey) maxKey = key;
    });
    let obj = {
      [maxKey + 1]: `${CheckValueLocale("dropdown_option", "", {}, intl)} ${
        choices?.length + 1
      }`,
    };
    const proceedOnAnswer = filteredSections[sectionIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex]?.is_proceed_on_answer;
    if (proceedOnAnswer) {
      if (sectionIndex === filteredSections?.length - 1) obj.next_section = -1;
      else obj.next_section = filteredSections[sectionIndex]?.order + 1;
    }
    setChoices([...choices, obj]);
    filteredSections[sectionIndex].questions.filter(
      (ques) => ques?.is_deleted !== true,
    )[questionIndex].question.items = [...choices, obj];
    setSurveySections([...surveySections]);
  };

  const handleChangeChoice = (e, choice) => {
    const key = Object.keys(choice)[0];
    if (e.target.value) {
      setChoices((prevChoices) => {
        const updatedChoices = prevChoices.map((item) => {
          if (Object.keys(item)[0] === key)
            return { ...item, [key]: e.target.value };
          return item;
        });
        filteredSections[sectionIndex].questions.filter(
          (ques) => ques?.is_deleted !== true,
        )[questionIndex].question.items = [...updatedChoices];
        setSurveySections([...surveySections]);
        return updatedChoices;
      });
    }
  };

  const handleRemoveChoice = (index) => {
    if (editable !== false) {
      choices?.splice(index, 1);
      setChoices([...choices]);
      // Update keys of remaining objects in choices array to be as new index + 1
      choices?.forEach((choice, choiceIndex) => {
        const nIndex = choiceIndex + 1;
        const oldIndex = choiceIndex + 2;
        if (choice[oldIndex]) {
          choice[nIndex] = choice[oldIndex];
          delete choice[oldIndex];
        }
      });
      filteredSections[sectionIndex].questions.filter(
        (ques) => ques?.is_deleted !== true,
      )[questionIndex].question.items = choices;
      setSurveySections([...surveySections]);
    }
  };

  return (
    <Card
      className={`cardStyle card-direction-container ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        "noError"
      } ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        proceedError === questionIndex &&
        "error"
      }`}
      onClick={() => handleCardClick(sectionIndex, questionIndex)}
    >
      <CardContent className="card-body title-question">
        <Box className="question-title-container">
          <Box component="span" className="questionNoStyle">
            {questionIndex + 1}.
          </Box>
          <Input
            fullWidth
            multiline
            className="questionStylee"
            style={{
              direction: checkKeyboardLanguage(value?.title) ? "rtl" : "ltr",
            }}
            id={questionIndex}
            value={value?.title}
            onChange={(e) => {
              handleQuestionTitle(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            name="title"
            placeholder={CheckValueLocale("dropdown_question", "", {}, intl)}
            disableUnderline={true}
          />
        </Box>
        <Input
          variant="outlined"
          className="subtitle"
          value={value?.description}
          onChange={(e) => {
            handleQuestionDesc(e, sectionIndex, questionIndex, value, setValue);
          }}
          style={{
            direction: checkKeyboardLanguage(value?.description)
              ? "rtl"
              : "ltr",
          }}
          multiline
          inputProps={{ maxLength: 145 }}
          name="subtitle"
          id={questionIndex}
          placeholder={CheckValueLocale("question_description", "", {}, intl)}
          disableUnderline={true}
        />
        {choices && choices?.length !== 0 && (
          <Box className="dropdown-options">
            {choices?.map((choice, index) => {
              return (
                <Box className="proceed-choice">
                  <TextField
                    variant="standard"
                    id={`choice-textfield-${index}`}
                    className="dropdown-choice"
                    value={CheckValueLocale(
                      Object.values(choice)[0],
                      "",
                      {},
                      intl,
                    )}
                    onChange={(e) => {
                      handleChangeChoice(e, choice);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          disabled={editable === false && true}
                        >
                          {index + 1}.
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          disabled={editable === false && true}
                          onClick={() => handleRemoveChoice(index)}
                        >
                          <IconButton disabled={editable === false && true}>
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={editable === false && true}
                    fullWidth
                  />
                  {rootQuestion?.is_proceed_on_answer && (
                    <Select
                      id={`dropdown-next-section-select-${sectionIndex}`}
                      className="next-section-select"
                      variant="standard"
                      MenuProps={MenuProps}
                      fullWidth
                      value={choice?.next_section}
                      onChange={(e) =>
                        handleChangeProceed(
                          e,
                          sectionIndex,
                          questionIndex,
                          index,
                        )
                      }
                    >
                      {sectionIndex !== filteredSections?.length - 1 && (
                        <MenuItem
                          value={filteredSections[sectionIndex]?.order + 1}
                        >
                          {`${CheckValueLocale(
                            "continue_next_section",
                            "",
                            {},
                            intl,
                          )} (${filteredSections[sectionIndex + 1]?.name})`}
                        </MenuItem>
                      )}
                      <MenuItem value={-1}>
                        {CheckValueLocale("submit_survey", "", {}, intl)}
                      </MenuItem>
                      {proceedSelects?.map((section, i) => {
                        return (
                          <MenuItem key={i} value={section?.order}>
                            {`${CheckValueLocale(
                              "go_to_section",
                              "",
                              {},
                              intl,
                            )} ${section?.order} (${section?.name})`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
        <Box mt={2}>
          <LucButton
            variant="flat"
            size="small"
            startIcon={<Add />}
            onClick={handleAddChoice}
            disabled={editable === false && true}
          >
            {CheckValueLocale("add_option", "", {}, intl)}
          </LucButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DropdownQuestion;
