import { useEffect } from "react";
import {
  Divider,
  Typography,
  Box,
  Tooltip,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  FormHelperText,
  Select,
} from "@mui/material";

import { useIntl } from "react-intl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

import {
  handleCopyBtn,
  checkValue,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";

import ExcludedInputFieldCreate from "./excludedInputFieldCreate";
import ExcludedInputFieldEdit from "pages/socialListing/MonitorsOptions/monitorSettings/monitorTypeData/keywordsType/dataSourceComponents/newsBlogsKeywords/inputs/excludedInputFieldEdit.js";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import RanksComponent from "./ranksComponent";

const KeywordsInputComponent = (props) => {
  const intl = useIntl();
  const {
    selectedSource,
    nbExcludedSource,
    majorSource,
    handleChangeDropdown,
    resp,
    excludedSourcesErrorMsg,
    handleDeleteExcludedSource,
    copyBtn,
    monitorSetting, // props that pass from MonitorSetting File
    createMonitor, // props that paff from CreateMonitor File
    setCopyBtn,
    monitorErrors,
    setMonitorErrors,
    nbRanks,
    setNbRanks,
    updatedParams,
    setUpdatedParams,
  } = props;
  useEffect(() => {
    // In case of create we will not add this because we don't send monitor Errors
    // So we will just need in MonitorSettings
    if (setMonitorErrors) {
      setMonitorErrors({
        ...monitorErrors,
        nb_title: excludedSourcesErrorMsg,
      });
    }
  }, [excludedSourcesErrorMsg]);

  return (
    <>
      <Box>
        {monitorSetting && <Box></Box>}
        <Box className={monitorSetting && "spam-keywords-area"}>
          {selectedSource === "major_sources" && (
            <FormControl
              sx={{ mt: 1 }}
              className={
                monitorSetting ? "drop-down-field" : "news-blog-wrapper"
              }
            >
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={majorSource}
                onChange={(e) => handleChangeDropdown(e, selectedSource, resp)}
                multiple
                renderValue={(selected) =>
                  selected?.length > 0
                    ? CheckValueLocale(
                        "nb_selected_num",
                        "",
                        { num: selected?.length },
                        intl,
                      )
                    : CheckValueLocale("select_sources", "", {}, intl)
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="select-owner"
                disabled={
                  monitorSetting && !handlelUserRoles("SM", "EDIT_MONITOR")
                }
              >
                {resp?.sources.map((val) => {
                  return (
                    <MenuItem
                      value={val?.id}
                      className="nb_dropdown_style"
                      id={`monitor-settings-keywords-major-source-${
                        val?.id ?? "id"
                      }`}
                    >
                      <Checkbox
                        checked={majorSource?.indexOf(val.id) > -1}
                        sx={{ mr: 1 }}
                      />
                      <ListItemText primary={val.name} />
                    </MenuItem>
                  );
                })}
              </Select>

              {majorSource.length > 0 && (
                <FormHelperText sx={{ textAlign: "initial" }}>
                  {CheckValueLocale("contact_us_keyword", "", {}, intl)}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
      </Box>
      {monitorSetting && selectedSource !== "major_sources" && <Divider />}
      {monitorSetting && selectedSource !== "major_sources" && (
        <>
          <Box className="section-box-data keywords-inputs-fields">
            <Typography className="main-titles">
              {CheckValueLocale("ranks", "", {}, intl)}
            </Typography>
            <Box>
              {selectedSource !== "major_sources" ? (
                <Box className="ranks-container">
                  <RanksComponent
                    nbRanks={nbRanks}
                    setNbRanks={setNbRanks}
                    selectedSource={selectedSource}
                    updatedParams={updatedParams}
                    setUpdatedParams={setUpdatedParams}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        </>
      )}
      {monitorSetting && selectedSource !== "major_sources" && <Divider />}
      {/* exclude_sources__________________________________________________ */}
      {selectedSource !== "major_sources" && (
        <>
          <Box className="section-box-data keywords-inputs-fields">
            {createMonitor ? (
              <Typography
                variant="caption"
                className={`keyword-head-title ${createMonitor ? "exclude-sources-title" : ""}`}
              >
                {
                  <Tooltip
                    title={
                      <Box className="title-search-tooltip">
                        {intl.formatMessage(
                          {
                            id: "exclude_sources_tooltip",
                          },
                          {
                            break: <br />,
                          },
                        )}
                      </Box>
                    }
                    arrow
                    placement="bottom"
                  >
                    <Typography variant="caption" className="media-input-label">
                      {CheckValueLocale("exclude_sources", "", {}, intl)}
                    </Typography>
                  </Tooltip>
                }
              </Typography>
            ) : (
              <Typography className="main-titles">
                {CheckValueLocale("exclude_sources", "", {}, intl)}
              </Typography>
            )}
            {createMonitor ? (
              <ExcludedInputFieldCreate {...props} />
            ) : (
              <ExcludedInputFieldEdit {...props} />
            )}
          </Box>
          {excludedSourcesErrorMsg !== "" && (
            <Box className="section-box-data">
              <Typography className="main-titles"></Typography>
              <Box
                component="p"
                className={`spam-text-field err-msg-text ${
                  createMonitor
                    ? "error-msg-create-excluded"
                    : "error-msg-edit-excluded"
                }`}
              >
                {CheckValueLocale(excludedSourcesErrorMsg, "", {}, intl)}
              </Box>
            </Box>
          )}

          {/* Chip Section__________________________________________________ */}
          <Box className={monitorSetting && "section-box-data"}>
            {monitorSetting && <Box></Box>}
            <Box
              className={`${
                createMonitor
                  ? "excluded-keywords-wrapper keyword-spam-wrapper"
                  : "exclude-sources-field"
              } spam-keywords-area`}
            >
              {checkValue(nbExcludedSource)?.map((title, i) => {
                return (
                  <KeywordTooltipChip
                    onDelete={() => handleDeleteExcludedSource(i)}
                    dataSource={"news"}
                    index={i}
                    labels={title}
                  />
                );
              })}
              <KeywordPopupModal
                keywords={nbExcludedSource}
                handleDelete={handleDeleteExcludedSource}
                dataSourceType={"newsBlogs"}
                keywordType={"exclude_sources"}
                isTitleSearch
              />

              {nbExcludedSource.length !== 0 && (
                <Tooltip title={copyBtn}>
                  <ContentCopyIcon
                    className={`copy-icon ${createMonitor && "copy-icon-nb"}`}
                    onClick={() =>
                      handleCopyBtn(
                        setCopyBtn,
                        CheckValueLocale("copied", "", {}, intl),
                        nbExcludedSource,
                      )
                    }
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default KeywordsInputComponent;
