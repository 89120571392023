import { Box, Button } from "@mui/material";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import "./luciHeader.scss";

const LuciHeader = ({
  sectionName,
  handleCloseSideBar,
  setShowPage,
  setShowConfirmationPopup,
  setShowRequests,
  isAnswerPage,
  getLuciStaticQuestions,
  pageParams,
  isRequests,
  isInactive,
  showSurvey,
  setShowSurvey,
}) => {
  const intl = useIntl();

  return (
    <>
      <Box className="luci-header-wrapper">
        <Button
          className="luci-header-icon"
          onClick={() => {
            if (showSurvey?.show) {
              setShowSurvey({ show: false, question: {} });
            } else {
              setShowPage(false);
              setShowConfirmationPopup(false);
              isAnswerPage && setShowRequests(true);
              isRequests && !isInactive && getLuciStaticQuestions(pageParams);
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} sx={{ pointerEvents: "none" }} />
          <Box component="span" className="luci-header-back">
            {CheckValueLocale("back", "", {}, intl)}
          </Box>
        </Button>
        <Box className="luci-header-section-name">
          {CheckValueLocale(sectionName, "", {}, intl)}
        </Box>
        <Button className="luci-header-icon" onClick={handleCloseSideBar}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Box>
    </>
  );
};

export default LuciHeader;
