import { useIntl } from "react-intl";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useState } from "react";

const MenuUsers = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 204,
      overflowX: "auto",
    },
  },
};

const HashtagsStatus = ({
  hashtagsHeader,
  statusHashtags,
  setStatusHashtags,
}) => {
  const intl = useIntl();
  const [openMenu, setOpenMenu] = useState(false);

  const colseMenu = () => {
    setOpenMenu(false);
  };
  window.onscroll = colseMenu;

  return (
    <Box className="hashtags-status-wrapper">
      <FormControl>
        <InputLabel id="hashtags-status-checkbox-label">
          {CheckValueLocale("status", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="hashtags-status-checkbox-label"
          id="hashtags-status-checkbox"
          multiple="true"
          value={statusHashtags}
          open={openMenu}
          onClose={colseMenu}
          onOpen={() => {
            setOpenMenu(true);
          }}
          onChange={(event) => setStatusHashtags(event?.target?.value)}
          renderValue={(selected) => {
            if (selected?.length === hashtagsHeader?.statuses?.length) {
              return CheckValueLocale("all_status", "", {}, intl);
            }
            return CheckValueLocale(
              "num_select",
              "",
              { num: selected?.length },
              intl,
            );
          }}
          MenuProps={MenuUsers}
          label={`${CheckValueLocale("status", "", {}, intl)}`}
        >
          {hashtagsHeader?.statuses?.map((status, i) => (
            <MenuItem
              key={i}
              value={status}
              className="hashtags-status-item"
              id={`hashtags-status-item-${status ?? "item"}`}
            >
              <Checkbox checked={statusHashtags?.indexOf(status) > -1} />
              <ListItemText
                primary={CheckValueLocale(
                  status + "_hashtags_status",
                  "",
                  {},
                  intl,
                )}
              >
                {CheckValueLocale(status + "_hashtags_status", "", {}, intl)}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default HashtagsStatus;
