import { useEffect, useState, useContext } from "react";
import {
  Box,
  Tab,
  Avatar,
  Button,
  Container,
  Menu,
  MenuItem,
  Divider,
  Chip,
  Badge,
  Typography,
  Tooltip,
} from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import SocialListingMenu from "../socialListingMenu/socialListingMenu.js";
import ChannelAnalyticsMenu from "../channelAnalyticsMenu/channelAnalyticsMenu.js";
import UtilitiesMenu from "../utilitiesMenu/utilitiesMenu.js";
import "./mainHeader.scss";
import UserAccount from "services/controllers/userAccountController";
import LoginController from "services/controllers/loginController.js";
import CircularLoading from "components/circularProgress";
import {
  CheckValueLocale,
  classNames,
  deleteCookie,
  handlelUserRoles,
  maskEmail,
} from "utils/helpers";
import Services from "services/api/Services.js";
import LanguageContext from "context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faBell } from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import NotificationsController from "services/controllers/notificationsController";
import UserContext from "context/audienceContext";
import Luci from "pages/luci";
import jwt_decode from "jwt-decode";
import CompaniesController from "services/controllers/companiesController.js";
// declare redux work
import { clear as clearSL } from "../../utils/redux/features/SocialListening/socialListeningSlice";
import { clear as clearCA } from "../../utils/redux/features/ChannelAnalytics/channelAnalyticsSlice";
import { clear as clearExplore } from "../../utils/redux/features/ExplorePage/exploreSlice";
import { useDispatch } from "react-redux";
import { AgentStatus } from "./agentStatus";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar";
import CustomizedTooltip from "components/customizedTooltip";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import UserAvatar from "shared/unifiedUserImage/userAvatar";

const MainHeader = (props) => {
  const intl = useIntl();
  const ctx = useContext(UserContext);
  const dispatch = useDispatch();

  const history = useHistory();
  const [showLuci, setShowLuci] = useState(true);
  const [value, setValue] = useState(0);
  const [activeProductSetState, setActiveProductSetState] = useState([]);
  const [stats, setStats] = useState({
    tabValue: "SM",
    lang: props.lang,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    avatar: {},
  });
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [audienceIsActive, setAudienceIsActive] = useState(false);
  const [engagementsIsActive, setEngagementsIsActive] = useState(false);

  const [activeSurveyBtn, setActiveSurveyBtn] = useState(false);
  const [activeSmBtn, setActiveSmBtn] = useState(false);
  const [activeCxmBtn, setActiveCxmBtn] = useState(false);
  const [activeCDP, setActiveCDP] = useState(false);
  const [activeEngagemet, setActiveEngagemet] = useState(false);
  const [ssoPath, setSsoPath] = useState(window.location.search);
  const [agentStatus, setAgentStatus] = useState(null);
  const [snackbarState, setSnackbarState] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const lucUser =
    window.localStorage.getItem("is_lucidya_account") === "1" ||
    localStorage.email?.includes("@lucidya");
  const thirdPartyFlag =
    !lucUser &&
    window.location.hostname === "cxm.lucidya.com" &&
    window.location.pathname !== "/login";

  // ===========================================================
  // Agent status
  const isEngagementEnabled = !!(
    handlelUserRoles("ENGAGEMENTS", "ACCESS_ENGAGEMENT_SYSTEM") &&
    (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
      handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH")) &&
    activeEngagemet
  );
  const isEngagementInboxEnabled =
    handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
    handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX");
  const isEngagementAnalyticsEnabled =
    handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
    handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS");
  const isUserAvailable = agentStatus === "available";
  // change the agent status
  const handleCloseSnackBar = () => {
    setSnackbarState({
      openSnackBar: false,
    });
  };
  const updateAgentsStatus = (status) => {
    handleClose();
    EngagementsController.updateUserAgentStatus(status).then((res) => {
      if (!res.errorMsg) {
        let message = `agent_status_${status}_snackbar`;
        setSnackbarState({
          message,
          openSnackBar: true,
          severity: "info",
        });
        setAgentStatus(status);
      } else {
        setSnackbarState({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      }
    });
  };
  useEffect(() => {
    getSSoInfo();
  }, []);
  const getSSoInfo = () => {
    if (ssoPath.includes("authorization")) {
      let url = ssoPath;
      let userToken = url.slice(url.indexOf("&luc-authorization=") + 19);
      let sliceFirst = userToken.slice(3);
      let perfectToken = sliceFirst.slice(0, -3);

      localStorage.setItem("user_token", perfectToken);
      let decodeAccessToken = jwt_decode(value);
      window.localStorage.setItem(
        "user_roles",
        JSON.stringify(decodeAccessToken.permissions),
      );
      window.localStorage.setItem(
        "active_features",
        JSON.stringify(decodeAccessToken.active_features),
      );
      getNewUserData();
    }
  };

  const getNewUserData = async () => {
    await UserAccount.getNewUserData().then((result) => {
      if (result.data?.data?.id) return getCompanyProducts();
    });
  };
  const getCompanyProducts = () => {
    CompaniesController.getCompanyProducts().then((data) => {
      const activeProducts = data.data.filter((product) => product.active);

      //SET ALL PRODUCTS ID IN LOCAL STORAGE TEMPORARLY TO TEST BACKEND IN INSIGHTS
      var systemsList = [];

      activeProducts?.map((product) => {
        systemsList?.push(product?.name);
        if (product?.name === "CXM") {
          return window.localStorage.setItem(
            "cxm_id",
            JSON.stringify(product?.id),
          );
        } else if (product?.name === "SM") {
          window.localStorage.setItem("sm_id", JSON.stringify(product?.id));
        }
      });

      //END OF TEMP. TESTING CODE
      if (activeProducts?.length > 0) {
        window.localStorage.setItem(
          "activeProducts",
          JSON.stringify(activeProducts),
        );

        let baseUrl = Services?.switchUrl;
        if (
          handlelUserRoles("SM", "VIEW_MONITOR_LIST") === undefined &&
          handlelUserRoles("CXM", "VIEW_CHANNEL") === undefined &&
          handlelUserRoles("SURVEY", "VIEW_SURVEY") === undefined &&
          handlelUserRoles("GENERAL", "VIEW_USER") === true
        ) {
          window.location.href = `/profile`;
        } else {
          if (
            systemsList?.includes("SM") &&
            handlelUserRoles("SM", "VIEW_MONITOR_LIST")
          ) {
            window.location.href = `/social/monitor_list`;
          } else if (
            systemsList?.includes("CXM") &&
            handlelUserRoles("CXM", "VIEW_CHANNEL")
          ) {
            window.location.href = `/insights/main`;
          } else if (
            systemsList?.includes("SURVEY") &&
            handlelUserRoles("SURVEY", "VIEW_SURVEY")
          ) {
            window.location.href = `/survey`;
          } else {
            window.location.href = `/profile`;
          }
        }

        setStats({ ...stats, isStepLoading: false });
      } else {
        window.location.href = `/profile`;
      }
    });
  };
  // Check if Audience List [ subRoutes ] is Active
  const audienceSubMenuPaths = [
    "/audience/list",
    "/audience/manage-audience",
    "/audience/user-profile",
  ];

  const engagementsPaths = [
    "/engagements/main",
    "/engagements/setup",
    "/engagements/edit",
    "/engagements/main/publish",
    "/engagements/main/inbox",
    "/engagements/analytics/inbox",
    "/engagements/analytics/slas",
    "/engagements/analytics/teams",
    "/engagements/analytics/agents",
    "/engagements/analytics/Insights",
    "/engagements/ai-agents",
  ];
  useEffect(() => {
    setAudienceIsActive(
      audienceSubMenuPaths.includes(history.location.pathname),
    );
    setEngagementsIsActive(
      engagementsPaths.includes(history.location.pathname),
    );
    for (let [key, value] of Object.entries(audienceSubMenuPaths)) {
      if (history.location.pathname.includes(`${value}`)) {
        setAudienceIsActive(true);
      }
    }
  }, [history.location.pathname]);
  // Check if Audience List [ subRoutes ] is Active

  const subMenuPaths = ["/profile"];
  useEffect(() => {
    setIsActive(subMenuPaths.includes(history.location.pathname));
    let activeProducts = [];
    if (
      window.localStorage?.activeProducts &&
      window.localStorage?.activeProducts?.length > 0
    ) {
      activeProducts = JSON.parse(window.localStorage?.activeProducts).filter(
        (product) => product.active,
      );
      setActiveProductSetState(activeProducts);
      activeProducts.map((product) => {
        if (product.name === "CXM") {
          setActiveCxmBtn(true);
          return window.localStorage.setItem(
            "cxm_id",
            JSON.stringify(product.id),
          );
        } else if (product.name === "SM") {
          setActiveSmBtn(true);
          window.localStorage.setItem("sm_id", JSON.stringify(product.id));
        } else if (product.name === "SURVEY") {
          setActiveSurveyBtn(true);
          window.localStorage.setItem("survey_id", JSON.stringify(product.id));
        } else if (product.name === "CDP") {
          setActiveCDP(true);
          window.localStorage.setItem("cdp_id", JSON.stringify(product.id));
        } else if (product.name === "ENGAGEMENTS") {
          window.localStorage.setItem(
            "engagements_id",
            JSON.stringify(product?.id),
          );
        }
      });
    }
    //END OF TEMP. TESTING CODE

    if (activeProducts.length > 0) {
      window.localStorage.setItem(
        "activeProducts",
        JSON.stringify(activeProducts),
      );
    }
  }, [history.location.pathname]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    let currentLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : window.navigator.language.split("-")[0];
    LoginController.userlogout().then((data) => {
      localStorage.clear();
      dispatch(resetChatMessages());
      deleteCookie("email");
      window.localStorage.setItem("lang", currentLang);
      //to stop connection with churnzero when user logout________________________
      if (thirdPartyFlag) {
        var churnzeroScript = document.getElementById(
          "churnzero-script-logout",
        );
        churnzeroScript.innerHTML = `ChurnZero.push(["trackEvent", "Logout"]);
        ChurnZero.push(["stop"]);`;
      }
      //__________________________________________________________________________
      window.location.href = "/login";
    });
  };
  //___________________________________________________________________________________________

  const getUserInfo = async () => {
    setUserDataLoading(true);
    await UserAccount.getUserInfoV4()
      .then((result) => {
        if (result) {
          const availabilityStatus =
            result?.user?.data?.attributes?.availability_status;
          if (availabilityStatus) {
            setAgentStatus(availabilityStatus);
          }
          //connecting with churnzero in case user and company are for client______________
          if (result?.user?.data?.attributes?.luci_policy) {
            let luci_policy = result?.user?.data?.attributes?.luci_policy;
            window.localStorage.setItem(
              "is_policy_active",
              luci_policy?.is_policy_active,
            );
            window.localStorage.setItem(
              "luci_appear",
              luci_policy?.luci_appear,
            );
          }
          if (
            result?.user?.data?.attributes?.is_client_account === true &&
            result?.user?.data?.attributes?.external_contact_id !==
              (null && "0") &&
            result?.user?.included[0].attributes?.external_company_id !==
              (null && "0")
          ) {
            var churnzeroScript = document.getElementById("churnzero-script");

            let externalCompanyId =
                result?.user?.included[0].attributes?.external_company_id,
              email = result?.user?.data?.attributes.email,
              hashedExternalCompanyId =
                result?.user?.included[0].attributes
                  ?.hashed_external_company_id,
              hashedEmail = result?.user?.data?.attributes.hashed_email;
            if (thirdPartyFlag) {
              churnzeroScript.innerHTML = `
            var ChurnZero = ChurnZero || [];
            (function() {
              var cz = document.createElement('script');
              cz.type = 'text/javascript';
              cz.async = true;
              cz.src = '${process.env.REACT_APP_CHURNZERO_URL}';
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(cz, s);
          })();
          ChurnZero.push([
            "setAppKey",
            '${process.env.REACT_APP_CHURNZERO_ID}',
          ]);
          ChurnZero.push(['setContact', '${externalCompanyId}','${email}', '${hashedExternalCompanyId}', '${hashedEmail}'])
          `;
            }
          }
          //_________________________________________________________________________________
        }
        let data = result?.user?.data?.attributes;
        let included =
          result?.user?.included?.length > 0
            ? result?.user?.included[0]?.attributes
            : {};
        setUserInfo({
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone_number: data?.phone_number,
          email: data?.email,
          avatar: data?.avatar,
        });
        localStorage.setItem("info_attributes", JSON.stringify(data));
        localStorage.setItem("name", `${data?.first_name} ${data?.last_name}`);
        localStorage.setItem("avatar", `${data?.avatar}`);
        localStorage.setItem("firstName", `${data?.first_name}`);
        localStorage.setItem("companyInfo", included?.time_zone);
        //set  lang to locale storeage
        if (data?.lang) {
          setStats({ ...stats, lang: data.lang });
          localStorage.setItem("lang", data.lang);
          props.switchLang(data.lang);
        }
        if (data?.avatar) {
          // Set Headers that will be accepted by request to have image data
          let headers = new Headers();
          headers.append(
            "Access-Control-Request-Headers",
            "access-control-allow-origin",
          );
          headers.append("Access-Control-Request-Method", "GET");
          headers.append("Access-Control-Allow-Origin", "*");
          fetch(data.avatar, {
            method: "GET",
            headers: headers,
          })
            .then((res) => {
              if (res) {
                return res.blob();
              } else {
                return false;
              }
            })
            .then((blob) => {
              if (blob) {
                blobToBase64(blob).then((res) => {
                  localStorage.setItem("profile_image", res);
                });
              }
            })
            .catch((err) => false);
        }
        setUserDataLoading(false);
      })
      .catch((err) => {
        setUserDataLoading(false);
      });
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  useEffect(() => {
    (async () => {
      await getUserInfo();
    })();

    if (
      window.localStorage.activeProducts &&
      localStorage.getItem("user_token")
    ) {
      setActiveProductSetState(JSON.parse(window.localStorage.activeProducts));
    }
  }, []);

  useEffect(() => {
    if (activeProductSetState?.length) {
      setActiveEngagemet(false);
      activeProductSetState?.map((item) => {
        if (item?.name == "ENGAGEMENTS" && item?.active) {
          setActiveEngagemet(true);
        }
      });
    }
  }, [activeProductSetState]);

  const [isLoading, setLoading] = useState(false);
  const user_roles = JSON.parse(window.localStorage.getItem("user_roles"));

  const getSmData = async (page) => {
    setLoading(true);
    let baseUrl = Services.switchUrl;

    await UserAccount.getSmData().then((result) => {
      if (result.data !== undefined) {
        localStorage.setItem("user_token", result.data.data.jwt);
        window.open(
          baseUrl +
            page +
            "?lang=" +
            localStorage.lang +
            "&sm_token=" +
            result.data.data.jwt,
          "_self",
        );
      }
    });
  };

  let isSafari =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  const handleSwitchLang = () => {
    let lang = "";
    if (stats.lang === "ar") {
      lang = "en";
    } else {
      lang = "ar";
    }
    UserAccount.setUserLang(lang).then((res) => {
      if (res?.errorMsg?.response?.status === 401) {
        localStorage.clear();
        dispatch(resetChatMessages());
        deleteCookie("email");
        window.location.href = "/login";
      }
      if (res?.data?.status == 200) {
        setStats({ ...stats, lang: lang });
        props.switchLang(lang);
      }
    });
  };
  const handleSwitchLangSafari = () => {
    window.location.reload();
    let lang = "";
    if (stats.lang === "ar") {
      lang = "en";
    } else {
      lang = "ar";
    }
    UserAccount.setUserLang(lang).then((res) => {
      if (res?.data?.status == 200) {
        setStats({ ...stats, lang: lang });
        props.switchLang(lang);
      }
    });
  };
  let changeLang = isSafari ? handleSwitchLangSafari : handleSwitchLang;

  const [notificationCount, setNotificationCount] = useState(0);
  useEffect(() => {
    NotificationsController.getNotificationsCount().then((res) => {
      setNotificationCount(res?.count <= 9 ? res?.count : "+9");
      window.localStorage.setItem("cdp_all_times", res?.oldest_date);
    });
  }, []);

  const logoRedirect = () => {
    if (activeSmBtn && handlelUserRoles("SM", "VIEW_MONITOR_LIST")) {
      window.location.href = `/home_page`;
    } else if (activeCxmBtn && handlelUserRoles("CXM", "VIEW_CHANNEL")) {
      window.location.href = `/insights/main`;
    } else if (activeSurveyBtn && handlelUserRoles("SURVEY", "VIEW_SURVEY")) {
      window.location.href = `/survey`;
    } else if (activeCDP && handlelUserRoles("CDP", "VIEW_PROFILE")) {
      window.location.href = `/aduience/list`;
    } else {
      window.location.href = `/profile`;
    }
    return false;
  };
  //Here we set audience filter whem change route
  useEffect(() => {
    if (!window.location.href?.includes("audience")) ctx.audienceFilters = {};
  }, [window.location.href]);

  let profileImage =
    userInfo?.first_name?.charAt(0)?.toUpperCase() +
    userInfo?.last_name?.charAt(0).toUpperCase();

  let userPicture = userInfo?.avatar;
  let userImage = userPicture ? (
    <Avatar src={userPicture} />
  ) : (
    <p className="user-img-navbar">{profileImage}</p>
  );

  let menuDirection = intl.locale === "ar" ? "left" : "right";
  let surveyRoles = activeSurveyBtn && user_roles && user_roles.SURVEY;
  let smRoles = activeSmBtn && user_roles && user_roles.SM;
  let cxmRoles = activeCxmBtn && user_roles && user_roles.CXM;
  let isActiveBtn = isActive
    ? "profile-btn selected-route btn-group"
    : "profile-btn btn-group";

  const activeLuciSlAcc = [
    "engagements",
    "customer_care",
    "comments_mentions",
    "questions",
  ];
  const activeLuciSlKeyword = ["engagements", "posts"];

  useEffect(() => {
    const currentLocation = window.location.pathname;
    setShowLuci(true);
    if (
      currentLocation.includes("monitor/ACCOUNT_ANALYSIS/INSTAGRAM/engagements")
    ) {
      setShowLuci(true);
    } else if (
      currentLocation.includes("monitor/ACCOUNT_ANALYSIS") ||
      currentLocation.includes("monitor/MANAGED_ACCOUNT") ||
      currentLocation.includes("monitor/MANAGED_PAGE")
    ) {
      activeLuciSlAcc.map((val) => {
        if (currentLocation.includes(val)) {
          setShowLuci(false);
        }
      });
    } else if (currentLocation.includes("KEYWORD/FACEBOOK/comments_mentions")) {
      setShowLuci(false);
    } else if (currentLocation.includes("monitor/KEYWORD")) {
      activeLuciSlKeyword.map((val) => {
        if (currentLocation.includes(val)) {
          setShowLuci(false);
        }
      });
    } else if (
      currentLocation.includes("/insights") ||
      currentLocation.includes("/interactions") ||
      currentLocation.includes("/survey/survey-insights") ||
      currentLocation.includes("/audience/user-profile") ||
      currentLocation.includes("/login") ||
      currentLocation.includes("/engagements/main") ||
      currentLocation.includes("/engagements/setup") ||
      currentLocation.includes("/engagements/edit") ||
      currentLocation.includes("/social/benchmark") ||
      currentLocation.includes("/engagements/analytics")
    ) {
      setShowLuci(false);
    }
  }, [window.location.pathname]);

  // clear Global Filters & Dates when switching to another path
  useEffect(() => {
    const currentLocation = window.location.pathname;

    if (!currentLocation.includes("/monitor")) {
      dispatch(clearSL());
    }
    if (
      !currentLocation.includes("/insights") &&
      !currentLocation.includes("/interactions")
    ) {
      dispatch(clearCA());
    }
    if (!currentLocation.includes("/monitors/create_monitor"))
      dispatch(clearExplore());
  }, [window.location.pathname]);
  return (
    <Box className="header-container">
      <Box id="mainHeader">
        {isLoading ? <CircularLoading /> : false}
        <Box className="custom-container">
          {showLuci ? <Luci isInactive={true} /> : null}
          <Box className="navbar-padding" value={value}>
            <Box className="navbar-header">
              <Tab
                className="header-logo"
                label={
                  <div className="header-img">
                    <NavLink
                      activeClassName="selected-route"
                      to="/home_page"
                      id="main-header-lucidya-logo"
                    >
                      <img
                        src={Services.projectLogoMainHeaderSVG}
                        alt=""
                        srcSet=""
                      />
                    </NavLink>
                  </div>
                }
              ></Tab>

              {smRoles ? <SocialListingMenu /> : null}
              {cxmRoles ? <ChannelAnalyticsMenu /> : null}

              {handlelUserRoles("SURVEY", "VIEW_SURVEY_LIST") &&
                surveyRoles && (
                  <Tab
                    label={
                      <NavLink
                        to="/survey/list"
                        activeClassName="selected-route"
                      >
                        {CheckValueLocale("surveys", "", {}, intl)}
                      </NavLink>
                    }
                    id="main-header-survey"
                  ></Tab>
                )}

              {handlelUserRoles("CDP", "VIEW_PROFILES_LIST") && activeCDP && (
                <Tab
                  label={
                    <NavLink
                      to="/audience/list"
                      activeClassName="selected-route"
                      className={`${
                        audienceIsActive && "selected-route"
                      } audience-route `}
                      id="main-header-audience"
                    >
                      {CheckValueLocale("audience", "", {}, intl)}
                    </NavLink>
                  }
                ></Tab>
              )}
              {handlelUserRoles("ENGAGEMENTS", "ACCESS_ENGAGEMENT_SYSTEM") &&
              (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
                handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") ||
                handlelUserRoles(
                  "ENGAGEMENT_ACCESS",
                  "ENGAGEMENT_ANALYTICS",
                )) &&
              activeEngagemet ? (
                <Tab
                  label={
                    <NavLink
                      to={
                        isEngagementInboxEnabled
                          ? "/engagements/main/inbox"
                          : isEngagementAnalyticsEnabled
                            ? "/engagements/analytics"
                            : "/engagements/main/publish"
                      }
                      activeClassName={"selected-route"}
                      className={`${engagementsIsActive && "selected-route"}`}
                    >
                      {CheckValueLocale("engagement", "", {}, intl)}
                      <span className="title-beta">
                        {CheckValueLocale("beta", "", {}, intl)}
                      </span>
                    </NavLink>
                  }
                  id="main-header-report"
                ></Tab>
              ) : null}
              <Box className="utilties-divider-box">
                <Box component="span" className="utilties-divider"></Box>
              </Box>
              {smRoles ||
              cxmRoles ||
              handlelUserRoles("GENERAL", "VIEW_UTILITIES") ? (
                <UtilitiesMenu smRoles={smRoles} cxmRoles={cxmRoles} />
              ) : null}
            </Box>

            <Box className="navbar-setting">
              {Services.showThirdCompany ? (
                <Box className="resource-center-icon">
                  <CustomizedTooltip
                    title={
                      <Box className="tooltip-custom-new">
                        {CheckValueLocale("success_center", "", {}, intl)}
                      </Box>
                    }
                    arrow
                    placement="bottom"
                    id="resource-center-tooltip"
                  >
                    <a href="javascript:userpilot.trigger('resource_center:MOCMGJ8_k5')">
                      <FontAwesomeIcon
                        className="nav-bar-btn-icon"
                        icon={faQuestionCircle}
                      />
                    </a>
                  </CustomizedTooltip>
                </Box>
              ) : null}
              <Tab
                className="settingsBtn btn-group"
                label={
                  <NavLink
                    to={
                      handlelUserRoles("GENERAL", "VIEW_SETTING")
                        ? "/settings"
                        : "/settings/accounts_management"
                    }
                    activeClassName="selected-route"
                  >
                    <CustomizedTooltip
                      title={
                        <Box className="tooltip-custom-new">
                          {CheckValueLocale("settings_tooltip", "", {}, intl)}
                        </Box>
                      }
                      arrow
                      placement="bottom"
                      id="resource-center-tooltip"
                    >
                      <FontAwesomeIcon
                        className="nav-bar-btn-icon"
                        icon={faGear}
                      />
                    </CustomizedTooltip>
                  </NavLink>
                }
                id="main-header-settings-btn"
              ></Tab>

              <Tab
                className="notificationBtn btn-group"
                label={
                  <NavLink to="/notification" activeClassName="selected-route">
                    <Badge badgeContent={notificationCount}>
                      <CustomizedTooltip
                        title={
                          <Box className="tooltip-custom-new">
                            {CheckValueLocale("notify_tooltip", "", {}, intl)}
                          </Box>
                        }
                        arrow
                        placement="bottom"
                        id="resource-center-tooltip"
                      >
                        <FontAwesomeIcon
                          className="nav-bar-btn-icon"
                          icon={faBell}
                        />
                      </CustomizedTooltip>
                    </Badge>
                  </NavLink>
                }
                id="main-header-notifications-btn"
              ></Tab>
              {/* user dropdown */}
              <Box className="profile-btn-box" onMouseLeave={handleClose}>
                <Button
                  id="main-header-user-image"
                  aria-controls="demo-customized-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  disableElevation
                  className={isActiveBtn}
                  onMouseOver={handleClick}
                >
                  {!userDataLoading ? (
                    <UserAvatar
                      fullName={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
                      avatarSrc={userInfo?.avatar}
                      sizes={{ ratio: "40px", fontSize: "16px" }}
                      showStatusIndicator={isEngagementEnabled && agentStatus}
                      statusIndicatorValue={
                        isUserAvailable ? "available" : "unavailable"
                      }
                      isBgDark={true}
                    />
                  ) : (
                    <Box className="main-header-preloader-img"></Box>
                  )}
                </Button>

                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                    onMouseLeave: handleClose,
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: menuDirection,
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: menuDirection,
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className="menu-settings"
                >
                  <Box className="handle-profile-menu">
                    <Box className="handle-profile-menu-img-wrapper">
                      <UserAvatar
                        fullName={`${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`}
                        avatarSrc={userInfo?.avatar}
                        sizes={{ ratio: "40px", fontSize: "13px" }}
                      />
                    </Box>
                    <Box className="profile-details">
                      <Box className="profile-name">
                        <Box
                          className="user-name"
                          component="span"
                        >{`${userInfo.first_name} ${userInfo.last_name}`}</Box>
                      </Box>
                      <Box className="profile-email">{`${maskEmail(userInfo.email)}`}</Box>
                      <Box
                        onClick={handleClose}
                        id="main-header-profile-settings-choice"
                      >
                        <NavLink
                          className="profile-settings"
                          to="/profile"
                          activeClassName="selected-route"
                        >
                          {CheckValueLocale("profile_settings", "", {}, intl)}
                        </NavLink>
                      </Box>
                    </Box>
                  </Box>
                  {isEngagementEnabled ? (
                    <>
                      <Divider className="header-divider" />
                      <AgentStatus
                        updateAgentsStatus={updateAgentsStatus}
                        value={agentStatus}
                      />
                    </>
                  ) : null}
                  <Divider className="header-divider" />

                  <MenuItem
                    onClick={handleClose}
                    disableRipple
                    className="menu-profile-item-last"
                    id="main-header-profile-settings-change-language-choice"
                  >
                    <Box component="span" onClick={changeLang}>
                      {CheckValueLocale("change_lang", "", {}, intl)}
                      <Box component="span" className="change-lang">
                        {CheckValueLocale("change_between_lang", "", {}, intl)}
                      </Box>
                    </Box>
                  </MenuItem>
                  {Services.showThirdCompany ? (
                    <MenuItem
                      onClick={handleClose}
                      disableRipple
                      className="menu-profile-item-last"
                      id="main-header-profile-settings-privacy-policy-choice"
                    >
                      <a
                        href="https://lucidya.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {CheckValueLocale("privacy_policy", "", {}, intl)}
                      </a>
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    onClick={handleClose}
                    disableRipple
                    className="menu-profile-item-last"
                    id="main-header-profile-settings-sign-out-choice"
                  >
                    <Box
                      onClick={handleSignOut}
                      component="span"
                      className="logout-btn"
                    >
                      {CheckValueLocale("sign_out", "", {}, intl)}
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <SnackBar
        open={snackbarState?.openSnackBar}
        severity={snackbarState?.severity}
        message={CheckValueLocale(snackbarState?.message, "", {}, intl)}
        title={CheckValueLocale(snackbarState?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Box>
  );
};
export default MainHeader;
