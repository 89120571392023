import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Card,
  Divider,
  Stack,
  Button,
  Grid,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import IntegrationPart from "./integrationPart";
import CompaniesController from "services/controllers/companiesController";
import Snackbar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import { handlelUserRoles } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const ThirdPartyIntegration = (props) => {
  const intl = useIntl();
  const [thirdPartyList, setThirdPartyList] = useState([]);
  const [thirdPartyIntercom, setThirdPartyIntercom] = useState({});
  const [thirdPartyZendesk, setThirdPartyZendesk] = useState({});
  const [thirdPartyZendeskNotInPackage, setThirdPartyZendeskNotInPackage] =
    useState("");
  const [isConfigured, setIsConfigured] = useState({
    zendesk: false,
    intercom: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(null);
  const [integrationType, setIntegrationType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRequested, setIsRequested] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsgs, setErrorMsgs] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [socialListeningRequest, setSocialListeningRequest] = useState(null);
  const [socialListeningSuccess, setSocialListeningSuccess] = useState(null);

  const sendThirdPartyDemo = async (text) => {
    let queryData = {
      service_name: text,
    };
    await CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSocialListeningRequest(true);
        setSuccessMsg("addons_success");
        setServiceName(text);
        setSocialListeningSuccess(true);
      } else {
        setSocialListeningRequest(true);
        setErrorMsgs("addons_failed");
        setSocialListeningSuccess(false);
      }
    });
  };

  const handleClick = (type) => {
    let text;
    if (type == "zendesk") {
      text = "request_zendesk";
    } else if (type == "intercom") {
      text = "request_intercom";
    }
    setSuccess(null);
    setIntegrationType(type);
    if (type == "zendesk" || type == "intercom") {
      setOpenDialog(true);
    } else {
      sendThirdPartyDemo(text);
    }
  };

  const getThirdPartyList = () => {
    CompaniesController.thirdPartyList().then((result) => {
      if (!result.errorMsg) {
        setThirdPartyList(result.data.data);
        setIsLoading(false);
      }
    });
  };

  //get data intercom

  const getIntegrateIntercomData = () => {
    CompaniesController.getIntegrateIntercomData().then((result) => {
      if (!result.errorMsg) {
        setThirdPartyIntercom(result.data.data);
      }
    });
  };
  //zendesk data
  const getIntegrateZendeskData = () => {
    CompaniesController.getIntegrateZendeskData().then((result) => {
      if (!result.errorMsg) {
        setThirdPartyZendesk(result.data.data);
      } else {
        setThirdPartyZendeskNotInPackage(result.notInPackage.status == 403);
      }
    });
  };

  useEffect(() => {
    (async () => {
      await getThirdPartyList();
      await getIntegrateIntercomData();
      await getIntegrateZendeskData();
    })();
  }, []);

  const handleDialog = () => {
    if (openDialog) {
      setOpenDialog(false);
    } else {
      setSuccess(null);
      setOpenDialog(true);
    }
  };
  return (
    <>
      {isLoading === true ? (
        <CircularLoading />
      ) : (
        <>
          <Box className="party-integration">
            {["ZENDESK", "INTERCOM"].map((text, index) => (
              <Box className="party-integration-item">
                <Card className="party-card">
                  <Box className="party-content">
                    <h2>
                      {CheckValueLocale(text.toLowerCase(), "", {}, intl)}
                    </h2>
                    <Box className="subtitle-card">
                      {CheckValueLocale(
                        text.toLowerCase() + "_definiton",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  </Box>
                  {handlelUserRoles("GENERAL", "CREATE_INTEGRATION") && (
                    <Box id={text}>
                      <Divider />
                      <Box className="party-btn">
                        <LucButton
                          variant="outline"
                          type="secondary"
                          size="small"
                          onClick={() => {
                            handleClick(text?.toLowerCase());
                          }}
                          disabled={
                            thirdPartyZendeskNotInPackage &&
                            text?.toLowerCase() == "zendesk"
                              ? true
                              : false
                            // isConfigured[text.toLowerCase()]? true: false
                          }
                          id={
                            thirdPartyList.length > 0 &&
                            thirdPartyList[2 - index]?.enabled
                              ? isConfigured[text?.toLowerCase()]
                                ? `settings-third-party-integration-add-btn`
                                : `settings-third-party-integration-configure-btn`
                              : `settings-third-party-integration-request-btn`
                          }
                        >
                          {CheckValueLocale(
                            thirdPartyList.length > 0
                              ? thirdPartyList[2 - index].enabled
                                ? isConfigured[text?.toLowerCase()]
                                  ? "added"
                                  : "configure"
                                : isRequested
                                  ? "requested"
                                  : "request"
                              : "request",
                            "",
                            {},
                            intl,
                          )}
                        </LucButton>
                      </Box>
                    </Box>
                  )}
                </Card>
              </Box>
            ))}
            {openDialog ? (
              <IntegrationPart
                open={openDialog}
                handleDialog={handleDialog}
                type={integrationType}
                setSuccess={setSuccess}
                setErrorMsg={setErrorMsg}
                success={success}
                setIsConfigured={setIsConfigured}
                isConfigured={isConfigured}
                thirdPartyIntercom={thirdPartyIntercom}
                thirdPartyZendesk={thirdPartyZendesk}
              />
            ) : (
              ""
            )}
          </Box>
          <Box>
            {success != null ? (
              <Snackbar
                open={success != null ? true : false}
                handleClose={() => {
                  setSuccess(null);
                }}
                severity={success ? "success" : "error"}
                message={CheckValueLocale(
                  success
                    ? integrationType !== "hootsuite"
                      ? CheckValueLocale(
                          `${integrationType}_added_success`,
                          "",
                          {},
                          intl,
                        )
                      : CheckValueLocale(
                          `${integrationType}_requested_success`,
                          "",
                          {},
                          intl,
                        )
                    : errorMsg,
                  "",
                  {},
                  intl,
                )}
              />
            ) : (
              ""
            )}
          </Box>
          {socialListeningSuccess != null ? (
            <Snackbar
              open={socialListeningSuccess != null ? true : false}
              handleClose={() => {
                setSocialListeningSuccess(null);
              }}
              severity={socialListeningSuccess ? "success" : "error"}
              message={
                socialListeningSuccess ? (
                  <div>
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#000",
                      }}
                    >
                      {CheckValueLocale(
                        "third_party_integrations",
                        "",
                        {},
                        intl,
                      )}
                    </span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      <br />
                      {CheckValueLocale(serviceName, "", {}, intl)} &nbsp;
                      {CheckValueLocale(successMsg, "", {}, intl)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#000",
                      }}
                    >
                      {CheckValueLocale(errorMsgs, "", {}, intl)}
                    </span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000",
                      }}
                    >
                      <br />
                      {CheckValueLocale("try_again", "", {}, intl)}
                    </span>
                  </div>
                )
              }
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};
export default ThirdPartyIntegration;
