import { Box, Divider, Typography } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import PageHeader from "components/pageHeader";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const links = [
  {
    name: "settings",
    url: "settings",
    addNameLocal: true,
  },
  {
    name: "inbound_routing",
    url: "settings/inbound_routing",
    addNameLocal: true,
  },
];

export const EditRoutingHeader = ({ handleCancel }) => {
  const intl = useIntl();
  return (
    <>
      <Box className="edit-inbound-routing-header">
        <Box className="edit-inbound-routing-header-breadcrumb">
          <BreadCrumb id="breadcrumb-style-monitors" links={links} />
          <PageHeader title="edit_inbound_routing" withBreadCrumb={true} />
        </Box>
        <Box
          onClick={handleCancel}
          className="edit-inbound-routing-header-btn"
          id="edit-inbound-routing-header-btn"
        >
          <Typography
            as="span"
            className="edit-inbound-routing-header-btn-title"
          >
            {CheckValueLocale("back_to_inbound_routing", "", {}, intl)}
          </Typography>
          <ArrowForwardIcon />
        </Box>
      </Box>
      <Divider />
    </>
  );
};
