import { useIntl } from "react-intl";
import { Box, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { CheckValueLocale, handleSentiment } from "utils/helpers";
import moment from "moment";
import FetchingData from "components/fetchingData/fetchingData";
import NoDataFound from "components/no-Data/noDataFound";
import "./alertStatistics.scss";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faEmptySet } from "@fortawesome/pro-regular-svg-icons";

const AlertStatistics = ({
  alertDataSource,
  headerAlertWidget,
  headerAlertWidgetPreLoaderDataSources,
  preLoaderTrackerDataSources,
  isSL,
  isCA,
  alertDetailType,
  isPrivateChannel,
}) => {
  const intl = useIntl();

  const emptyIcon = () => {
    return (
      <Box className="alert-empty-set">
        <FontAwesomeIcon icon={faEmptySet} />
      </Box>
    );
  };

  const negativeRate = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <Box className={`stats-head-number negative-rate-head`}>
          {headerAlertWidget?.negative_rate !== null ? (
            `${headerAlertWidget?.negative_rate?.toFixed(2)}%`
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale(
            isPrivateChannel
              ? "alert_negative_rate_conversation"
              : alertDataSource === "genesys"
                ? "alert_negative_rate_calls"
                : alertDataSource === "gmail"
                  ? "alert_negative_rate_calls_gmail"
                  : alertDataSource == "googlemybusiness"
                    ? "alert_negative_rate_reviews"
                    : "alert_negative_rate",
            "",
            {},
            intl,
          )}
        </Box>
      </Box>
    );
  };

  const alertLevel = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <Box className={`stats-head-number alert-level-wrapper`}>
          {headerAlertWidget?.alert_level !== null ? (
            <>
              <FontAwesomeIcon icon={faCircleNotch} />
              {CheckValueLocale(headerAlertWidget?.alert_level, "", {}, intl)}
            </>
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale("alert_level", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const mentionInTotal = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <FontAwesomeIcon />
        <Box className={`stats-head-number`}>
          {headerAlertWidget?.mentions_in_total !== null ? (
            headerAlertWidget?.mentions_in_total
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale("mentions_in_total", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const totalReachedTweet = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <FontAwesomeIcon />
        <Box className={`stats-head-number`}>
          {headerAlertWidget?.total_reached_tweets !== null ? (
            headerAlertWidget?.total_reached_tweets
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale("alert_total_reached_tweets", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const totalPeopleWhoReached = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <FontAwesomeIcon />
        <Box className={`stats-head-number`}>
          {headerAlertWidget?.total_reached_accounts !== null ? (
            headerAlertWidget?.total_reached_accounts
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale("total_people_who_reached", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const increaseInPostInteraction = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <FontAwesomeIcon />
        <Box className={`stats-head-number`}>
          {headerAlertWidget?.increase_in_post_interactions !==
          (null || undefined) ? (
            `${headerAlertWidget?.increase_in_post_interactions?.toFixed(2)}%`
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale("alert_increase_post_interaction", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const influencersTweetsTotal = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <FontAwesomeIcon />
        <Box className={`stats-head-number`}>
          {headerAlertWidget?.influencer_authors !== null ? (
            headerAlertWidget?.influencer_authors
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale("influencers_tweets_total", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const verifiedAuthorTweet = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <FontAwesomeIcon />
        <Box className={`stats-head-number`}>
          {headerAlertWidget?.verified_authors !== null ? (
            headerAlertWidget?.verified_authors
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale(
            "alert_verified_authors_tweets_count",
            "",
            {},
            intl,
          )}
        </Box>
      </Box>
    );
  };

  const totalNewPostHour = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <FontAwesomeIcon />
        <Box className={`stats-head-number`}>
          {headerAlertWidget?.total_posts?.value !== null ? (
            headerAlertWidget?.total_posts?.value
          ) : (
            <>
              {emptyIcon()}
              <Box className="na-alert-box">N/A</Box>
            </>
          )}
        </Box>
        <Box component="div" className="stats-body">
          {CheckValueLocale(
            isPrivateChannel
              ? "alert_total_new_conversation_hours"
              : alertDataSource === "genesys"
                ? "alert_total_new_calls_hours"
                : alertDataSource === "gmail"
                  ? "alert_total_new_calls_hours_gmail"
                  : alertDataSource === "googlemybusiness"
                    ? "alert_total_new_reviews_hours"
                    : alertDataSource === "tiktok"
                      ? "alert_total_tik"
                      : "alert_total_new_posts_hours",
            "",
            {
              hours: headerAlertWidget?.total_posts?.date
                ? moment(headerAlertWidget?.total_posts?.date).format("HH")
                : "00",
            },
            intl,
          )}
        </Box>
      </Box>
    );
  };

  const sentimentType = () => {
    return (
      <Box component="div" className="stats-wrapper">
        <Box component="div" className="icon-wrapper">
          <Box>
            {headerAlertWidget?.net_sentiment ? (
              <FontAwesomeIcon
                style={{
                  color: handleSentiment(
                    headerAlertWidget?.net_sentiment?.toLowerCase(),
                  )?.color,
                }}
                icon={
                  handleSentiment(
                    headerAlertWidget?.net_sentiment?.toLowerCase(),
                  )?.label
                }
              />
            ) : null}
            <Box
              component="div"
              className={` margin-right-10`}
              style={{
                color: handleSentiment(
                  headerAlertWidget?.net_sentiment?.toLowerCase(),
                )?.color,
              }}
            >
              {headerAlertWidget?.net_sentiment ? (
                CheckValueLocale(headerAlertWidget?.net_sentiment, "", {}, intl)
              ) : (
                <>
                  {emptyIcon()}
                  <Box className="na-alert-box">N/A</Box>
                </>
              )}
            </Box>
          </Box>
          <Box component="div" className="stats-body">
            {CheckValueLocale(
              isCA
                ? alertDataSource == "tiktok"
                  ? "alert_comments_sentiment_analysis"
                  : "alert_ca_sentiment_type"
                : "alert_comments_sentiment_type",
              "",
              {},
              intl,
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  let isTotalPeopleReached =
    headerAlertWidget?.total_reached_accounts != null &&
    headerAlertWidget?.total_reached_accounts != undefined;
  let isTotalReachedTweets =
    headerAlertWidget?.total_reached_tweets != null &&
    headerAlertWidget?.total_reached_tweets != undefined;
  let isSentimentType =
    headerAlertWidget?.net_sentiment != null &&
    headerAlertWidget?.net_sentiment != undefined;
  let isAlertLevel =
    headerAlertWidget?.alert_level != null &&
    headerAlertWidget?.alert_level != undefined;
  let isNegativeRate =
    headerAlertWidget?.negative_rate != null &&
    headerAlertWidget?.negative_rate !== undefined;
  let isTotalNewPostsHour =
    headerAlertWidget?.total_posts != null &&
    headerAlertWidget?.total_posts != undefined;
  let isMentionTotal =
    headerAlertWidget?.mentions_in_total != null &&
    headerAlertWidget?.mentions_in_total != undefined;
  let isVerifiedAuthorsTweet =
    headerAlertWidget?.verified_authors != null &&
    headerAlertWidget?.verified_authors != undefined;
  let isIncreasePostsInteractions =
    headerAlertWidget?.increase_in_post_interactions != null &&
    headerAlertWidget?.increase_in_post_interactions != undefined;
  let isInfluencerTotalTweets =
    headerAlertWidget?.influencer_authors != null &&
    headerAlertWidget?.influencer_authors != undefined;
  const alertHeaderWidgets = (
    <>
      {alertDataSource === "tiktok" ? (
        <>
          <Grid container spacing={2.5} mb={2.5}>
            {isSentimentType ? (
              <Grid item xs={6}>
                {sentimentType()}
              </Grid>
            ) : null}
            {isAlertLevel ? (
              <Grid item xs={6}>
                {alertLevel()}
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2.5} mb={2.5}>
            {isNegativeRate ? (
              <Grid item xs={4}>
                {negativeRate()}
              </Grid>
            ) : null}
            {isTotalNewPostsHour ? (
              <Grid item xs={4}>
                {totalNewPostHour()}
              </Grid>
            ) : null}
            {isMentionTotal ? (
              <Grid item xs={4}>
                {mentionInTotal()}
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : null}
      {alertDataSource === "twitter" ? (
        <>
          <Grid container spacing={2.5} mb={2.5}>
            {isSentimentType ? (
              <Grid item xs={6}>
                {sentimentType()}
              </Grid>
            ) : null}
            {isAlertLevel ? (
              <Grid item xs={6}>
                {alertLevel()}
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2.5} mb={2.5}>
            {isVerifiedAuthorsTweet ? (
              <Grid item xs={3}>
                {verifiedAuthorTweet()}
              </Grid>
            ) : null}
            {isNegativeRate ? (
              <Grid item xs={3}>
                {negativeRate()}
              </Grid>
            ) : null}
            {isTotalNewPostsHour ? (
              <Grid item xs={3}>
                {totalNewPostHour()}
              </Grid>
            ) : null}
            {isMentionTotal ? (
              <Grid item xs={3}>
                {mentionInTotal()}
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2.5}>
            {isTotalReachedTweets ? (
              <Grid item xs={3}>
                {totalReachedTweet()}
              </Grid>
            ) : null}
            {isTotalPeopleReached ? (
              <Grid item xs={3}>
                {totalPeopleWhoReached()}
              </Grid>
            ) : null}
            {isIncreasePostsInteractions ? (
              <Grid item xs={3}>
                {increaseInPostInteraction()}
              </Grid>
            ) : null}
            {isInfluencerTotalTweets ? (
              <Grid item xs={3}>
                {influencersTweetsTotal()}
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : alertDataSource === "twitterPrivate" ? (
        alertDetailType !== "NegativePosts" ? (
          <>
            <Grid container spacing={2.5} mb={2.5}>
              {isTotalNewPostsHour ? (
                <Grid item xs={6}>
                  {totalNewPostHour()}
                </Grid>
              ) : null}
              {isNegativeRate ? (
                <Grid item xs={6}>
                  {negativeRate()}
                </Grid>
              ) : null}
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={2.5} mb={2.5}>
              {isSentimentType ? (
                <Grid item xs={3}>
                  {sentimentType()}
                </Grid>
              ) : null}
              {isAlertLevel ? (
                <Grid item xs={3}>
                  {alertLevel()}
                </Grid>
              ) : null}
              {isTotalNewPostsHour ? (
                <Grid item xs={3}>
                  {totalNewPostHour()}
                </Grid>
              ) : null}
              {isVerifiedAuthorsTweet ? (
                <Grid item xs={3}>
                  {verifiedAuthorTweet()}
                </Grid>
              ) : null}
            </Grid>
          </>
        )
      ) : null}
      {alertDataSource === "talkwalker" ? (
        <>
          <Grid container spacing={2.5}>
            {isTotalNewPostsHour ? (
              <Grid item xs={6}>
                {totalNewPostHour()}
              </Grid>
            ) : null}
            {isSentimentType ? (
              <Grid item xs={6}>
                {sentimentType()}
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : null}

      {alertDataSource === "facebook" || alertDataSource === "instagram" ? (
        <Box>
          <Grid container spacing={2.5} mb={2.5}>
            {isSentimentType ? (
              <Grid item xs={4}>
                {sentimentType()}
              </Grid>
            ) : null}
            {isAlertLevel ? (
              <Grid item xs={4}>
                {alertLevel()}
              </Grid>
            ) : null}
            {isNegativeRate ? (
              <Grid item xs={4}>
                {negativeRate()}
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={2.5}>
            {isTotalNewPostsHour ? (
              <Grid item xs={6}>
                {totalNewPostHour()}
              </Grid>
            ) : null}
            {isMentionTotal ? (
              <Grid item xs={6}>
                {mentionInTotal()}
              </Grid>
            ) : null}
          </Grid>
        </Box>
      ) : null}
      {alertDataSource === "linkedin" ? (
        <Box>
          <Grid container spacing={2.5} mb={2.5}>
            {isSentimentType ? (
              <Grid item xs={3}>
                {sentimentType()}
              </Grid>
            ) : null}
            {isAlertLevel ? (
              <Grid item xs={3}>
                {alertLevel()}
              </Grid>
            ) : null}
            {isTotalNewPostsHour ? (
              <Grid item xs={3}>
                {totalNewPostHour()}
              </Grid>
            ) : null}
            {isMentionTotal ? (
              <Grid item xs={3}>
                {mentionInTotal()}
              </Grid>
            ) : null}
          </Grid>
        </Box>
      ) : null}

      {alertDataSource === "facebookPrivate" ||
      alertDataSource === "genesys" ||
      alertDataSource === "whatsapp" ||
      alertDataSource === "intercom" ||
      alertDataSource === "gmail" ||
      alertDataSource === "googlemybusiness" ? (
        alertDetailType !== "NegativePosts" ? (
          <>
            <Grid container spacing={2.5} mb={2.5}>
              {isTotalNewPostsHour ? (
                <Grid item xs={6}>
                  {totalNewPostHour()}
                </Grid>
              ) : null}
              {isNegativeRate ? (
                <Grid item xs={6}>
                  {negativeRate()}
                </Grid>
              ) : null}
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={2.5} mb={2.5}>
              {isSentimentType ? (
                <Grid item xs={4}>
                  {sentimentType()}
                </Grid>
              ) : null}
              {isAlertLevel ? (
                <Grid item xs={4}>
                  {alertLevel()}
                </Grid>
              ) : null}
              {isTotalNewPostsHour ? (
                <Grid item xs={4}>
                  {totalNewPostHour()}
                </Grid>
              ) : null}
            </Grid>
          </>
        )
      ) : null}
    </>
  );

  return (
    <>
      {_.isEqual(
        headerAlertWidgetPreLoaderDataSources?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <Box>
          <FetchingData />
        </Box>
      ) : _.isEmpty(headerAlertWidget) ? (
        <NoDataFound />
      ) : (
        <Box component="div" mb={5} className="main-header-alert">
          {alertHeaderWidgets}
        </Box>
      )}
    </>
  );
};

export default AlertStatistics;
