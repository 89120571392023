import { Box, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { SelectableTabContent } from "./selectableTabContent";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import { TabContent } from "./tabContent";
import CompaniesController from "services/controllers/companiesController";
import { AddSkillPopup } from "./addSkillPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { WorkingHours } from "./workingHours";

const SkillTab = (props) => {
  return (
    <MenuItem
      id="tab-add-new-skill-option"
      className="tab-add-new-skill-option"
      onClick={() => props?.setIsAddSkillPopupOpen(true)}
    >
      <FontAwesomeIcon icon={faPlus} />
      <Typography component="span" className="add-skill">
        {props?.addNewSkill}
      </Typography>
    </MenuItem>
  );
};

const SkillWithEmptyMessage = (props) => {
  return (
    <Box className="add-skill-tab-item-empty-case">
      <SkillTab {...props} />
      <Typography className="empty-list-message">
        {props?.emptyListMessage}
      </Typography>
    </Box>
  );
};
export const AdvancedTabs = ({
  advancedTabsData,
  setAdvancedTabsData,
  tabsErrors,
  setTabsErrors,
}) => {
  const intl = useIntl();
  const isRTL = intl?.locale === "ar";
  const [currentTab, setCurrentTab] = useState(0);
  const prevTab = useRef(0);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [isLoadingSkills, setIsLoadingSkills] = useState(false);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
  const [isAddSkillPopupOpen, setIsAddSkillPopupOpen] = useState(false);
  const [lanuagesProficiencyOptions, setLanuagesProficiencyOptions] = useState(
    [],
  );
  const [skillsProficiencyOptions, setSkillsProficiencyOptions] = useState([]);
  const getCompanySkills = () => {
    setIsLoadingSkills(true);
    CompaniesController.getCompanySkills().then((res) => {
      if (!res?.errorMsg) {
        setSkillOptions(
          res?.data?.skills?.map((skill) => ({
            id: skill?.id,
            name: skill?.title,
          })) || [],
        );
        setSkillsProficiencyOptions(
          res?.data?.skill_levels?.map((proficiency) => ({
            id: proficiency,
            name: proficiency,
          })),
        );
      }
      setIsLoadingSkills(false);
    });
  };
  const getCompanyLanguages = () => {
    setIsLoadingLanguages(true);
    CompaniesController.getCompanyLanguages().then((res) => {
      if (!res?.errorMsg) {
        setLanguageOptions(res?.data?.languages);
        setLanuagesProficiencyOptions(
          res?.data?.language_proficiencies?.map((proficiency) => ({
            id: proficiency,
            name: proficiency,
          })),
        );
      }
      setIsLoadingLanguages(false);
    });
  };

  useEffect(() => {
    getCompanySkills();
    getCompanyLanguages();
  }, []);

  useEffect(() => {
    if (prevTab.current === currentTab) return;
    if (prevTab?.current === 2) {
      const workgingHoursPreError = tabsErrors?.workgingHoursPreError;
      const workingHoursError = tabsErrors?.workingHours;
      if (!workingHoursError && workgingHoursPreError) {
        setTabsErrors((prevTabsErrors) => ({
          ...prevTabsErrors,
          workingHours: true,
        }));
      }
    }
    prevTab.current = currentTab;
  }, [currentTab]);
  return (
    <Box className="tabs-section-container">
      <Box className="tabs-section-header">
        <Typography variant="h3" className="tabs-section-title">
          {CheckValueLocale("user_advanced_info", "", {}, intl)}
        </Typography>
        <Typography variant="body1" className="tabs-section-description">
          {CheckValueLocale("user_advanced_info_desc", "", {}, intl)}
        </Typography>
      </Box>
      <Box className="user-tabs-container">
        <Box className="user-tabs-header">
          <Tabs value={currentTab} onChange={(_, tab) => setCurrentTab(tab)}>
            <Tab
              className="user-tabs-item"
              label={CheckValueLocale("Languages", "", {}, intl)}
            />
            <Tab
              className="user-tabs-item"
              label={CheckValueLocale("Skills", "", {}, intl)}
            />
            <Tab
              className={classNames(
                "user-tabs-item",
                tabsErrors?.workingHours && currentTab !== 2
                  ? "user-tabs-item-error"
                  : "",
              )}
              label={CheckValueLocale("Working_hours", "", {}, intl)}
            />
          </Tabs>
        </Box>
        <Box className="user-tabs-content">
          <TabContent value={currentTab} index={0}>
            <SelectableTabContent
              tabName="languages"
              title={CheckValueLocale("languages_title", "", {}, intl)}
              mainLabel={CheckValueLocale("select_language", "", {}, intl)}
              mainOptions={languageOptions}
              mainNameKey={isRTL ? "ar_name" : "name"}
              isMainDataMultiple={false}
              subLabel={CheckValueLocale("proficiency", "", {}, intl)}
              subOptions={lanuagesProficiencyOptions}
              isSubDataMultiple={false}
              tabsData={advancedTabsData}
              setTabsData={setAdvancedTabsData}
              isLoading={isLoadingLanguages}
              mainEmptyListMessage={CheckValueLocale(
                "no_languages_options",
                "",
                {},
                intl,
              )}
              subEmptyListMessage={CheckValueLocale(
                "no_proficiency_options",
                "",
                {},
                intl,
              )}
            />
          </TabContent>
          {/* Skills Tab */}
          <TabContent value={currentTab} index={1}>
            <SelectableTabContent
              tabName="skills"
              title={CheckValueLocale("skills_title", "", {}, intl)}
              mainLabel={CheckValueLocale("select_skill", "", {}, intl)}
              mainOptions={skillOptions}
              isMainDataMultiple={false}
              subLabel={CheckValueLocale("proficiency", "", {}, intl)}
              subOptions={skillsProficiencyOptions}
              isSubDataMultiple={false}
              tabsData={advancedTabsData}
              setTabsData={setAdvancedTabsData}
              isLoading={isLoadingSkills}
              paperClassName="tab-skills-paper-box"
              mainEmptyListMessage={
                <SkillWithEmptyMessage
                  setIsAddSkillPopupOpen={setIsAddSkillPopupOpen}
                  addNewSkill={CheckValueLocale("add_new_skill", "", {}, intl)}
                  emptyListMessage={CheckValueLocale(
                    "no_skills_options",
                    "",
                    {},
                    intl,
                  )}
                />
              }
              subEmptyListMessage={CheckValueLocale(
                "no_proficiency_options",
                "",
                {},
                intl,
              )}
              groupBy={(option) => option?.typeOf}
              renderGroup={(params) => {
                return (
                  <>
                    <SkillTab
                      setIsAddSkillPopupOpen={setIsAddSkillPopupOpen}
                      addNewSkill={CheckValueLocale(
                        "add_new_skill",
                        "",
                        {},
                        intl,
                      )}
                    />
                    {params.children}
                  </>
                );
              }}
            />
          </TabContent>
          {/* Working Hours Tab */}
          <TabContent value={currentTab} index={2}>
            <WorkingHours
              tabsData={advancedTabsData}
              setTabsData={setAdvancedTabsData}
              setTabsErrors={setTabsErrors}
              tabsErrors={tabsErrors}
            />
          </TabContent>
        </Box>
      </Box>
      <AddSkillPopup
        open={isAddSkillPopupOpen}
        setOpen={setIsAddSkillPopupOpen}
        currentSkills={skillOptions}
        setCurrentSkills={setAdvancedTabsData}
        getCompanySkills={getCompanySkills}
      />
    </Box>
  );
};
