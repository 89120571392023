import { Box } from "@mui/material";
import HeadingSidebar from "pages/engagements/components/headingSidebar/headingSidebar";
import SwitchMenuList from "pages/engagements/components/navigationSidebar/switchNavigationContent";
import SetupFlow from "pages/engagements/components/setup-flow/setupFlow";
const HeaderSidebar = ({
  setOpenSetupFlow,
  openNavigationMenu,
  setOpenNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  openSetupFlowFirst,
  openSetupFlow,
  isDoneSetup,
  showReload,
  handleDateFormat,
  date,
  getCalendarData,
  assignActiveFilters,
  selectedAccoutnts,
  setOpenNewConversation,
  getAccountsDataApi,
  setCalendarData,
  setUpComingList,
  setupcomingLoader,
}) => {
  const handlerRefresh = () => {
    let { startDate, endDate } = handleDateFormat(date);
    // getCalendarData(startDate, endDate, assignActiveFilters, selectedAccoutnts);
    setCalendarData([]);
    setupcomingLoader(false);
    setUpComingList([]);
    getAccountsDataApi();
  };
  return (
    <>
      <Box className="heading-wrapper">
        <HeadingSidebar
          handleClickReload={handlerRefresh}
          handleSettings={() => setOpenSetupFlow(true)}
          setOpenNewConversation={setOpenNewConversation}
          openNavigationMenu={openNavigationMenu}
          setOpenNavigationMenu={setOpenNavigationMenu}
          navigationMenuStatus={navigationMenuStatus}
          setNavigationMenuStatus={setNavigationMenuStatus}
          showReload={showReload}
          isDoneSetup={isDoneSetup}
        />
      </Box>
      <SwitchMenuList
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        navigationMenuStatus={navigationMenuStatus}
        setNavigationMenuStatus={setNavigationMenuStatus}
      />
      {openSetupFlow ? (
        <SetupFlow
          openSetupFlowFirst={openSetupFlowFirst}
          setClose={() => setOpenSetupFlow(false)}
          isDoneSetup={isDoneSetup}
        />
      ) : null}
    </>
  );
};

export default HeaderSidebar;
