import { useEffect, useState } from "react";
import { Box, TablePagination, Button, Tooltip, Zoom } from "@mui/material";
import TopHashtagsController from "services/controllers/topHashtagsController";
import CircularLoading from "components/circularProgress";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import WordCloud from "components/echarts/WordCloud";
import { CheckValueLocale, isEmptyArray, isArabic } from "utils/helpers";
import SharedTable from "../../../shared/table/sharedTable";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import SnackBar from "components/snackBar";
import { useTopHashtagsSortingTable } from "./useTopHashtagsSortingTable";
import NoDataFoundProducts from "components/no-Data";
import LucButton from "shared/lucButton/lucButton";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const TopHashtagHourly = ({
  intl,
  noDataTextTooltip,
  appliedFilter,
  setRemoveSearch,
  setClearSearchFlag,
}) => {
  const [hourlyListData, setHourlyListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableBody, setTableBody] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalListData, setTotalListData] = useState(0);
  const history = useHistory();
  const {
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
    topHashtagsSnackBar,
    handleCloseSnackBar,
    tableHead,
    setTopHashtagsSnackbarError,
  } = useTopHashtagsSortingTable();

  const paginationFrom = page > 0 ? rowsPerPage * page + 1 : 1;
  const rowsPageCal = rowsPerPage * page + rowsPerPage;
  const paginationTo =
    page > 0
      ? rowsPageCal > totalListData
        ? totalListData
        : rowsPageCal
      : rowsPerPage;

  const getHourlyList = (appliedFilterData) => {
    if (!isSortableLoading) setLoading(true);
    getOverall(appliedFilterData, page);
  };

  const getOverall = (appliedFilterData, page) => {
    let { location, hashtag, period } = appliedFilterData;

    let startDate = moment()
      .tz("utc", true)
      .subtract(parseInt(period), "h")
      .unix();

    let endDate = moment()
      .tz("utc", true)
      .subtract(parseInt(period) - 1, "h")
      .unix();

    TopHashtagsController.getOverall(
      location,
      hashtag ? hashtag : null,
      startDate,
      endDate,
      rowsPerPage,
      page + 1,
      sortColumn,
      sortDirection,
    ).then((res) => {
      if (res?.errorMsg) {
        setHourlyListData([]);
        setTotalListData(0);
        setTopHashtagsSnackbarError();
      } else {
        setHourlyListData(res?.data?.hashtags_list || []);
        setTotalListData(res?.data?.pagination?.total || 0);
      }
      setLoading(false);
      setIsSortableLoading(false);
      if (!res?.data?.hashtags_list) setRemoveSearch(true);
    });
  };

  useEffect(() => {
    getHourlyList(appliedFilter);
  }, [appliedFilter]);

  useEffect(() => {
    if (!isSortableLoading) {
      return;
    }
    getHourlyList(appliedFilter);
  }, [sortColumn, sortDirection]);

  const topHashtagList = hourlyListData?.map((val) => ({
    name: val[0]?.hashtag,
    value: val[0]?.tweet_volume || 1,
  }));

  const showTooltipText = (text, textLength) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Button
              variant="text"
              sx={{
                padding: 0,
                margin: 0,
                borderRadius: 0,
              }}
              id="report-create-new-show-report-text-btn"
            >
              {text?.substring(0, textLength)}...
            </Button>
          </BootstrapTooltip>
        ) : (
          text
        )}
      </Box>
    );
  };
  const hashName = (val) => {
    return (
      <Box className="hash-name">
        <span
          onClick={() => {
            history.push({
              pathname: "/top-trends/view-trends",
              state: { detail: val[0] },
            });
          }}
        >
          {isArabic(val[0]?.hashtag?.replace("#", ""))
            ? showTooltipText(
                `${
                  intl.locale == "ar"
                    ? "#" + val[0]?.hashtag?.replace("#", "")
                    : val[0]?.hashtag?.replace("#", "") + "#"
                }`,
                30,
              )
            : showTooltipText(
                `${
                  intl.locale == "ar"
                    ? val[0]?.hashtag?.replace("#", "") + "#"
                    : "#" + val[0]?.hashtag?.replace("#", "")
                }`,
                30,
              )}
        </span>
      </Box>
    );
  };

  useEffect(() => {
    const tabBody =
      hourlyListData?.length == 0
        ? []
        : hourlyListData?.map((val, index) => {
            return {
              id: `hourly-${index}`,
              data: [
                {
                  body: paginationFrom + index,
                },
                {
                  body: hashName(val),
                  className: "hashtag-row",
                },
                {
                  body: val[0]?.hour_count || noDataTextTooltip(intl),
                },
                {
                  body: val[0]?.tweet_volume
                    ? intl?.formatNumber(val[0]?.tweet_volume)
                    : noDataTextTooltip(intl),
                  className: "tweets-strip-line",
                },
                {
                  body: <Box className="hash-name">{val[0]?.start_place}</Box>,
                },
              ],
            };
          });
    setTableBody(tabBody);
  }, [hourlyListData, paginationFrom, intl?.locale]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getHourlyList(appliedFilter, newPage);
  };
  const displayedPaginationRows = (from, to, count, intl) => {
    return `${from}–${to} ${CheckValueLocale("pagination_of", "", {}, intl)} ${
      count !== -1 ? count : `more than ${to}`
    }`;
  };
  const removeFilterTrend = () => {
    setClearSearchFlag(true);
  };

  return (
    <Box className="top-hashtag-overall-box-parant">
      {loading ? (
        <Box sx={{ p: 1 }}>
          <CircularLoading />
        </Box>
      ) : isEmptyArray(hourlyListData) ? (
        <Box className="empty-top-hashtag">
          <NoDataFoundProducts
            image="TopHashtagsImg"
            title="no_data_found"
            subTitle="sorry_there_is_no_data"
          />
          <LucButton
            variant="outline"
            size="small"
            id="top-trend-clear-search"
            onClick={removeFilterTrend}
          >
            {CheckValueLocale("clear_search", "", {}, intl)}
          </LucButton>
        </Box>
      ) : (
        <Box className="top-hashtag-overall-box">
          <Box className="top-hashtag-overall-table top-hashtag-table">
            <>
              <SharedTable
                tableMinWidth="600px"
                tableHeader={tableHead}
                tableBody={tableBody}
                isSharedStyle
                isHashTable
                loading={isSortableLoading}
              />
              {!isEmptyArray(hourlyListData) &&
              !isSortableLoading &&
              totalListData > 10 ? (
                <TablePagination
                  nextIconButtonProps={
                    paginationTo == totalListData
                      ? {
                          disabled: true,
                        }
                      : undefined
                  }
                  rowsPerPageOptions={false}
                  component="div"
                  count={totalListData}
                  page={page}
                  onPageChange={handleChangePage}
                  labelRowsPerPage={""}
                  labelDisplayedRows={() =>
                    displayedPaginationRows(
                      paginationFrom,
                      paginationTo,
                      totalListData,
                      intl,
                    )
                  }
                />
              ) : null}
            </>
          </Box>
          <Box className="top-hashtag-overall-view">
            <WordCloud data={topHashtagList} />
          </Box>
        </Box>
      )}
      <SnackBar
        open={topHashtagsSnackBar?.openSnackBar}
        severity={topHashtagsSnackBar?.severity}
        message={CheckValueLocale(topHashtagsSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(topHashtagsSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Box>
  );
};
export default TopHashtagHourly;
