import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import OverView from "components/widgets/overViewWidget";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import PieChartWidget from "components/widgets/pieChartWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import InteractionsList from "components/interactions-list/InteractionsList";
import { getNoOfDays } from "utils/helpers";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import LinkedInMedia from "components/media/media-linkedin/linkedInMedia";
import { CheckValueLocale } from "utils/helpers/index";
import LuciFunctions from "pages/luci/components/luciFunctions/luciFunction";
import ActiveFilters from "shared/channel-analytics-filter/activeFilters/activeFilters";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";

import _ from "lodash";

const LinkedInBody = (props) => {
  const intl = useIntl();
  let {
    response,
    startDate,
    endDate,
    setCheckAllDataFetchingExport,
    preLoaderTrackerDataSources,
  } = props;

  const linkedInIcon = (
    <>
      <LinkedInIcon className="icon-after-title linkedIn" />
    </>
  );

  const initialWidgetsState = {
    //overview widget
    overview: [],
    overViewPreLoader: [],

    //interaction widget
    interaction: [],
    totalInteraction: [],
    avgInteractions: 0,
    interactionsPreLoaderDataSources: [],

    //sentiment widget
    sentimentAnalysis: [],
    sentimentPieChart: [],
    sentimentAnalysisPreLoader: [],

    //theme widget
    themeAnalysis: [],
    themeTrend: [],
    themesXlsx: [],
    themeAnalysisPreLoaderDataSources: [],
    themeTrendPreLoaderDataSources: [],

    //channel account widget
    channelAccounts: [],
    channelAccountsPreLoaderDataSources: [],

    //content type widget
    contentType: [],
    excelContentType: [],
    contentTypePreLoaderDataSources: [],

    //dialects sub dialects widget
    dialects: [],
    subDialects: [],
    excelDialects: [],
    excelSubDialects: [],
    dialectsPreLoaderDataSources: [],

    //contact activity widget
    contactActivity: [],
    authorActivity: [],
    minValueContactActivity: [0],
    maxValueContactActivity: [0],
    contactActivityPreLoaderDataSources: [],

    //top keywords widget
    topKeywords: [],
    topKeywordsPreLoaderDataSources: [],

    //top hastags widget
    topHashtags: [],
    topHashtagsPreLoaderDataSources: [],

    //top images widget
    topImages: [],
    topImagesPreLoaderDataSources: [],

    //top videos widget
    topVideos: [],
    topVideosPreLoaderDataSources: [],

    //contact trend widget
    contactTrend: [],
    totalContactTrend: [],
    contactTrendPreLoaderDataSources: [],

    //gender widget
    genders: [],
    excelGenderDistribution: [],
    gendersPreLoaderDataSources: [],

    //reactions widget
    reactions: [],
    reactionsLegends: [],
    reactionsPreLoaderDataSources: [],

    //most engaged widget
    topEngagedAuthors: [],
    topEngagedAuthorsPreLoaderDataSources: [],

    //latest interaction widget
    latestInteractions: [],
    latestInteractionsCount: 0,
    latestInteractionsPreLoaderDataSources: [],

    negativeArray: [],
    positiveArray: [],
    neutralArray: [],
  };

  const [linkedInWidgets, setlinkedInWidgets] = useState(initialWidgetsState);

  const { checkLuciDataIsFounded, checkLuciAllDataFounded } = LuciFunctions();

  useEffect(() => {
    switch (response?.eventName) {
      case "Linkedin__CASingleDataSourceInsightsPage__overview":
        let overViewArray = Object?.entries(response?.eventData?.overview)?.map(
          (key) => {
            const condition =
              key[1] === "None" || key[1] === null || key[1] === 0;
            if (key[0] === "average_waiting_time" && condition) {
              return {
                name: key[0],
                value: 0.0,
              };
            } else
              return {
                name: key[0],
                value: key[1],
              };
          },
        );
        setlinkedInWidgets({
          ...linkedInWidgets,
          overview: [...overViewArray],
          overViewPreLoader: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__volume_overtime":
        const noOfDays = getNoOfDays(startDate, endDate);
        const avg = response?.eventData?.total_interactions / noOfDays;
        setlinkedInWidgets({
          ...linkedInWidgets,
          interaction: response?.eventData?.interactions,
          avgInteractions: avg % 1 !== 0 ? avg.toFixed(2) : avg,
          totalInteraction: response?.eventData?.total_interactions,
          interactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__sentiment_analysis":
        Object.entries(response?.eventData?.sentiment_overtime)?.forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value.map((i) => {
                  return linkedInWidgets?.negativeArray.push(i);
                });
                break;
              case "positive":
                value.map((i) => {
                  return linkedInWidgets?.positiveArray.push(i);
                });
                break;
              case "neutral":
                value.map((i) => {
                  return linkedInWidgets?.neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );
        setlinkedInWidgets({
          ...linkedInWidgets,
          sentimentAnalysis: {
            negative: linkedInWidgets?.negativeArray,
            positive: linkedInWidgets?.positiveArray,
            neutral: linkedInWidgets?.neutralArray,
          },
          sentimentPieChart: response?.eventData?.sentiment_piechart,
          sentimentAnalysisPreLoader: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__themes_and_sub_themes":
        setlinkedInWidgets({
          ...linkedInWidgets,
          themesXlsx: response?.eventData?.themes,
          themeAnalysis: response?.eventData?.themes?.theme_analysis,
          themeAnalysisPreLoaderDataSources: [response?.data_source],
          themeTrend: response?.eventData?.themes?.theme_trend,
          themeTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__channel_accounts":
        let newChannelAccounts = [];
        response?.eventData?.channel_accounts?.map((channel) => {
          newChannelAccounts?.push({
            name: channel?.name,
            value: channel?.value,
          });
        });
        setlinkedInWidgets({
          ...linkedInWidgets,
          channelAccounts: newChannelAccounts,
          channelAccountsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__content_type":
        setlinkedInWidgets({
          ...linkedInWidgets,
          contentType: response?.eventData?.content_type_piechart,
          excelContentType: _.cloneDeep([
            ...response?.eventData?.content_type_piechart,
          ]),
          contentTypePreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__dialects_and_sub_dialects":
        setlinkedInWidgets({
          ...linkedInWidgets,
          dialects: response?.eventData?.dialects,
          subDialects: response?.eventData?.sub_dialects,
          excelDialects: _.cloneDeep([...response.eventData?.dialects]),
          excelSubDialects: _.cloneDeep([...response.eventData?.sub_dialects]),
          dialectsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__contacts_activity":
        setlinkedInWidgets({
          ...linkedInWidgets,
          contactActivity: response?.eventData?.contacts_activity,
          minValueContactActivity: response?.eventData?.min_value,
          maxValueContactActivity: response?.eventData?.max_value,
          contactActivityPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__top_keywords":
        setlinkedInWidgets({
          ...linkedInWidgets,
          topKeywords: response?.eventData?.top_keywords,
          topKeywordsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__top_hashtags":
        setlinkedInWidgets({
          ...linkedInWidgets,
          topHashtags: response?.eventData?.top_hashtags,
          topHashtagsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__top_videos":
        setlinkedInWidgets({
          ...linkedInWidgets,
          topVideos: response?.eventData?.top_videos,
          topVideosPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__top_images":
        setlinkedInWidgets({
          ...linkedInWidgets,
          topImages: response?.eventData?.top_images,
          topImagesPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__engagers_volume":
        setlinkedInWidgets({
          ...linkedInWidgets,
          contactTrend: response?.eventData?.engagers_volume_over_time,
          totalContactTrend: response?.eventData?.total_engagers,
          contactTrendPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__gender_distribution":
        setlinkedInWidgets({
          ...linkedInWidgets,
          genders: response?.eventData?.gender_distribution,
          excelGenderDistribution: _.cloneDeep([
            ...response?.eventData?.gender_distribution,
          ]),
          gendersPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__reactions":
        setlinkedInWidgets({
          ...linkedInWidgets,
          reactions:
            response?.eventData?.posts_reactions?.posts_reactions
              ?.posts_reactions_over_time,
          reactionsLegends:
            response?.eventData?.posts_reactions?.posts_reactions
              ?.posts_reactions_piechart,
          reactionsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__top_engagers":
        setlinkedInWidgets({
          ...linkedInWidgets,
          topEngagedAuthors: response?.eventData?.top_engagers,
          topEngagedAuthorsPreLoaderDataSources: [response?.data_source],
        });
        break;
      case "Linkedin__CASingleDataSourceInsightsPage__top_posts_comments":
        setlinkedInWidgets({
          ...linkedInWidgets,
          latestInteractions: response?.eventData?.top_posts,
          latestInteractionsPreLoaderDataSources: [response?.data_source],
          latestInteractionsCount: response?.eventData?.interactions_count,
        });
        break;

      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setlinkedInWidgets({ ...initialWidgetsState });
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let x = checkLuciAllDataFounded(
      linkedInWidgets?.latestInteractionsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = checkLuciDataIsFounded([linkedInWidgets?.latestInteractions]);
    props.setCheckAllDataExist(x);
    props.setCheckDataIsEmpty(y);
  }, [
    linkedInWidgets?.latestInteractions,
    linkedInWidgets?.latestInteractionsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  //export excel
  useEffect(() => {
    let excelData = { ...linkedInWidgets };
    let authorActivityArr = [
      linkedInWidgets?.contactActivity,
      linkedInWidgets?.minValueContactActivity,
      linkedInWidgets?.maxValueContactActivity,
    ];
    excelData["authorActivity"] = [...authorActivityArr];
    props?.setExcelSheetData([{ ...excelData }]);
  }, [linkedInWidgets]);

  // check fetching for export excel
  useEffect(() => {
    let totalPreLoaderDataSouces = [
      linkedInWidgets?.overViewPreLoader,
      linkedInWidgets?.interactionsPreLoaderDataSources,
      linkedInWidgets?.sentimentAnalysisPreLoader,
      linkedInWidgets?.themeAnalysisPreLoaderDataSources,
      linkedInWidgets?.themeTrendPreLoaderDataSources,
      linkedInWidgets?.channelAccountsPreLoaderDataSources,
      linkedInWidgets?.contentTypePreLoaderDataSources,
      linkedInWidgets?.dialectsPreLoaderDataSources,
      linkedInWidgets?.contactActivityPreLoaderDataSources,
      linkedInWidgets?.topKeywordsPreLoaderDataSources,
      linkedInWidgets?.topHashtagsPreLoaderDataSources,
      linkedInWidgets?.topImagesPreLoaderDataSources,
      linkedInWidgets?.topVideosPreLoaderDataSources,
      linkedInWidgets?.contactTrendPreLoaderDataSources,
      linkedInWidgets?.gendersPreLoaderDataSources,
      linkedInWidgets?.reactionsPreLoaderDataSources,
      linkedInWidgets?.topEngagedAuthorsPreLoaderDataSources,
      linkedInWidgets?.latestInteractionsPreLoaderDataSources,
    ];

    let result = checkIsDataFecthing(
      totalPreLoaderDataSouces,
      preLoaderTrackerDataSources,
    );
    setCheckAllDataFetchingExport(result);
  }, [linkedInWidgets]);
  const unixDate = {
    start: startDate,
    end: endDate,
  };
  return (
    <>
      <Typography variant="h6" className="survey-title">
        {CheckValueLocale("overview_linkedin", "", {}, intl).toUpperCase()}
      </Typography>

      <OverView
        data={linkedInWidgets?.overview}
        overViewPreLoaderDataSources={linkedInWidgets?.overViewPreLoader}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        isLinkedInPage
      />

      <Typography variant="h6" className="survey-title">
        {intl
          .formatMessage({ id: "interactions_overview_linkedin" })
          .toUpperCase()}
      </Typography>

      <Box my={4}>
        <LineChartWidget
          title={"interactions"}
          titleToolTip="interaction_linkedin_tooltip"
          data={linkedInWidgets?.interaction}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          toolTipLabel={"total_interactions"}
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "total_interactions",
              value: linkedInWidgets?.totalInteraction,
            },
            {
              name: "avg_interactions_per_day",
              value: linkedInWidgets?.avgInteractions,
            },
          ]}
          redirectTo={"/interactions/social-media/linkedin-channel/"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            linkedInWidgets?.interactionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={linkedInIcon}
        />
      </Box>

      <Box my={3}>
        <StackedLineWidget
          title={"sentiment_overtime_legend"}
          titleToolTip="sentiment_analysis_tooltip_linkedin"
          data={linkedInWidgets?.sentimentAnalysis}
          dataPiechart={linkedInWidgets?.sentimentPieChart}
          hideLegend
          showGroupBy={true}
          showScales
          showAI
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={
            linkedInWidgets?.sentimentAnalysisPreLoader
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={linkedInIcon}
          isSentimentAnalysisShowCharts
        />
      </Box>

      <Box my={3}>
        <ThemesAnalysis
          title={"themes"}
          titleToolTip="themes_tooltip_linkedin"
          data={linkedInWidgets?.themeAnalysis}
          themeAnalysisPreLoaderDataSources={
            linkedInWidgets?.themeAnalysisPreLoaderDataSources
          }
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={linkedInIcon}
        />
      </Box>

      <Box my={3}>
        <ThemesTrends
          title={"themes_trend"}
          titleToolTip="themes_trend_tooltip_linkedin"
          data={linkedInWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={
            linkedInWidgets?.themeTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          iconAfterTitle={linkedInIcon}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item md={4}>
          <PieChartWidget
            title={"channel_accounts"}
            titleToolTip="channel_accounts_linkedin_tooltip"
            data={linkedInWidgets?.channelAccounts}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
            pieChartPreLoaderDataSources={
              linkedInWidgets?.channelAccountsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
            isChannelAccounts
            bigImg
            iconAfterTitle={linkedInIcon}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"content_type"}
            titleToolTip="content_type_linkedin_tooltip"
            data={linkedInWidgets?.contentType}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showDownloadIcon
            bigImg
            pieChartPreLoaderDataSources={
              linkedInWidgets?.contentTypePreLoaderDataSources
            }
            preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
            iconAfterTitle={linkedInIcon}
          />
        </Grid>
        <Grid item md={4}>
          <PieChartWidget
            title={"dialects_subdialects"}
            titleToolTip="dialects_per_subdialects_tooltip_linkedin"
            data={linkedInWidgets?.dialects}
            subData={linkedInWidgets?.subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            showAI
            bigImg
            showDownloadIcon
            pieChartPreLoaderDataSources={
              linkedInWidgets?.dialectsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            iconAfterTitle={linkedInIcon}
          />
        </Grid>
      </Grid>

      <Box my={4} className="channel-activity">
        <PunchCardWidget
          title="contact_activity"
          titleToolTip="channel_activity_linkedin_tooltip"
          clientActivity={linkedInWidgets?.contactActivity}
          minValueClient={linkedInWidgets?.minValueContactActivity}
          maxValueClient={linkedInWidgets?.maxValueContactActivity}
          showDownloadIcon
          bigImg
          authorActivityPreLoaderDataSources={
            linkedInWidgets?.contactActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={linkedInIcon}
        />
      </Box>

      <Box mt={10}>
        <Typography variant="h6" className="survey-title">
          {intl
            .formatMessage({ id: "interactions_analysis_linkedin" })
            .toUpperCase()}
        </Typography>
      </Box>

      <Grid container spacing={2} mb={2}>
        <Grid item md={6}>
          <TableWidget
            wordsList
            title={"top_keywords"}
            titleToolTip="top_kewords_linkedin_tooltip"
            leftColomnTitle={"keywords_linkedin"}
            rightColomnTitle={"interactions"}
            tableChartPreLoaderDataSources={
              linkedInWidgets?.topKeywordsPreLoaderDataSources
            }
            data={linkedInWidgets?.topKeywords}
            errorMessage={"no_keywords_found"}
            showDownloadIcon
            bigImg
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            keywords
            tablePagination
            count={linkedInWidgets?.topKeywords?.length}
            socialKeywordName
            iconAfterTitle={linkedInIcon}
          />
        </Grid>

        <Grid item md={6}>
          <TableWidget
            wordsList
            title={"top_hashtags"}
            titleToolTip="top_hashtags_linkedin_tooltip"
            leftColomnTitle={"hashtags_linkedin"}
            rightColomnTitle={"interactions"}
            tableChartPreLoaderDataSources={
              linkedInWidgets?.topHashtagsPreLoaderDataSources
            }
            data={linkedInWidgets?.topHashtags}
            errorMessage={"no_hashtags_found"}
            showDownloadIcon
            bigImg
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            keywords
            tablePagination
            count={linkedInWidgets?.topHashtags?.length}
            socialKeywordName
            iconAfterTitle={linkedInIcon}
          />
        </Grid>
      </Grid>

      <Box my={4}>
        <LinkedInMedia
          isLinkedInImage
          title="insights_top_images"
          titleToolTip="top_images_tooltip_linkedin"
          data={linkedInWidgets?.topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          mediaTwPreLoaderDataSources={
            linkedInWidgets?.topImagesPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={linkedInIcon}
          showDownloadIcon
          bigImg
        />
      </Box>

      <Box my={4}>
        <LinkedInMedia
          isLinkedInVideo
          title="insights_top_videos"
          titleToolTip="top_videos_tooltip_linkedin"
          data={linkedInWidgets?.topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          mediaTwPreLoaderDataSources={
            linkedInWidgets?.topVideosPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={linkedInIcon}
          showDownloadIcon
          bigImg
        />
      </Box>

      <Box mt={10}>
        <Typography variant="h6" className="survey-title">
          {intl
            .formatMessage({ id: "contact_overview_linkedin" })
            .toUpperCase()}
        </Typography>
      </Box>

      <Box my={4}>
        <LineChartWidget
          title={"contacts_trend_linkedin"}
          titleToolTip="conversation_interaction_tooltip_linkedin"
          totalLabel={"contacts_linkedin"}
          totalValue={linkedInWidgets?.totalContactTrend}
          data={linkedInWidgets?.contactTrend}
          labelColor={"#2AAAE2"}
          showGroupBy={true}
          toolTipLabel={"total_contacts"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            linkedInWidgets?.contactTrendPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={linkedInIcon}
        />
      </Box>

      <Grid container spacing={2} mb={2}>
        <Grid item md={6}>
          <PieChartWidget
            title={"genders_distribution"}
            titleToolTip="genders_tooltip_linkedin"
            data={linkedInWidgets?.genders}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            color={["#2AAAE2", "#FF4081"]}
            chartType={"gender"}
            bigImg
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={
              linkedInWidgets?.gendersPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            iconAfterTitle={linkedInIcon}
          />
        </Grid>
      </Grid>

      <Box mt={10}>
        <Typography variant="h6" className="survey-title">
          {intl
            .formatMessage({ id: "contacts_analysis_linkedin" })
            .toUpperCase()}
        </Typography>
      </Box>

      <Box my={3}>
        <StackedBarWidget
          isLinkedInPage
          showReportIcon
          title={"linkedIn_posts_reactions"}
          titleToolTip={"reactions_linkedin_tooltip"}
          showDownloadIcon
          isPublicAccount
          bigImg
          posts={linkedInWidgets?.reactions}
          showZoomPercentage
          postsColumnPreLoaderDataSources={
            linkedInWidgets?.reactionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          labels={[
            "like_reaction",
            "celebrate_reaction",
            "curious_reaction",
            "love_reaction",
            "insightful_reaction",
            "support_reaction",
          ]}
          hideLegend
          dataPiechart={linkedInWidgets?.reactionsLegends}
          colors={[
            "#1892F2",
            "#F7B94D",
            "#1C404E",
            "#FB5C77",
            "#EC8A92",
            "#00D7B5",
          ]}
          iconAfterTitle={linkedInIcon}
        />
      </Box>
      <Grid container spacing={2} mb={2}>
        <Grid item md={6}>
          <TableWidget
            linkedInPage
            itemsList
            title={"most_engaged_contacts"}
            titleToolTip={"most_engaged_tooltip_linkedin"}
            data={linkedInWidgets?.topEngagedAuthors}
            leftColomnTitle={"user_author"}
            rightColomnTitle={"interactions"}
            tableChartPreLoaderDataSources={
              linkedInWidgets?.topEngagedAuthorsPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
            tablePagination
            count={linkedInWidgets?.topEngagedAuthors?.length}
            iconAfterTitle={linkedInIcon}
            showDownloadIcon
            bigImg
          />
        </Grid>
      </Grid>

      <Typography variant="h6" className="linkedin-title">
        {intl
          .formatMessage({ id: "latest_interactions_linkedin" })
          .toUpperCase()}
      </Typography>

      <InteractionsList
        showProfile={true}
        unixDate={unixDate}
        data={linkedInWidgets?.latestInteractions.slice(0, 5)}
        dataSources={props?.dataSources}
        isLinkedIn
        isInteractionPage
        latestInteractionsPreLoaderDataSources={
          linkedInWidgets?.latestInteractionsPreLoaderDataSources
        }
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        showViewMoreButton={linkedInWidgets?.latestInteractionsCount > 5}
      />
    </>
  );
};

export default LinkedInBody;
