import { injectIntl, useIntl } from "react-intl";
import {
  Card,
  Stack,
  Button,
  Grid,
  CardContent,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { checkProgressExceeded, getPercentage } from "utils/helpers";
import { commasAfterDigit } from "utils/helpers";
import Snackbar from "components/snackBar";
import "./subscription.scss";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: 420,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

const LuciPackage = (props) => {
  const {
    luciInfo,
    luciActive,
    setLuciSuccess,
    setLuciSnackBar,
    luciSuccess,
    luciSnackBar,
    sendPackageUpgrade,
    isLoadingUpgrade,
  } = props;
  const intl = useIntl();

  const buttonPackageUpgrade = () => {
    return (
      <Stack direction="row" justifyContent="flex-end">
        {
          <LucButton
            onClick={() => {
              !luciInfo ? sendPackageUpgrade(true) : sendPackageUpgrade(false);
            }}
            id="settings-subscription-social-listening-package-btn"
            loading={isLoadingUpgrade}
          >
            {CheckValueLocale(
              !luciInfo ? "request_demo" : "request_upgrade",
              "",
              {},
              intl,
            )}
          </LucButton>
        }
      </Stack>
    );
  };
  return (
    <div id="social-listening">
      <h3 className="sub__head">
        {CheckValueLocale("luci_package", "", {}, intl)}
      </h3>
      <Card className="body">
        {luciActive ? (
          <>
            <Card justifyContent="center">
              <Typography>
                {CheckValueLocale("package_stats", "", {}, intl)}
              </Typography>
              <Card className="percentage">
                <CardContent>
                  <Grid container lg={12} md={12} sm={12} spacing={5}>
                    <Grid item lg={12} md={12} sm={12}>
                      {!luciInfo ? (
                        <>
                          <Typography className="luci-demo-txt">
                            {CheckValueLocale("luci_demo_txt", "", {}, intl)}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography className="head-social-list">
                            {CheckValueLocale("luci_energy", "", {}, intl)}
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <div>
                              {CheckValueLocale(
                                "luci_remaining",
                                "",
                                {
                                  num: commasAfterDigit(
                                    luciInfo?.gpt_token -
                                      luciInfo?.package_used_token,
                                  ),
                                },
                                intl,
                              )}
                            </div>
                            <div>
                              {`${getPercentage(
                                luciInfo?.package_used_token,
                                luciInfo?.gpt_token,
                              )}%`}
                            </div>
                          </Stack>
                          <BorderLinearProgress
                            variant="determinate"
                            className={
                              luciInfo?.package_used_token >=
                              luciInfo?.gpt_token
                                ? "red-color"
                                : ""
                            }
                            value={
                              checkProgressExceeded(
                                luciInfo?.package_used_token,
                                luciInfo?.gpt_token,
                              ) ??
                              getPercentage(
                                luciInfo?.package_used_token,
                                luciInfo?.gpt_token,
                              )
                            }
                            id="linear-progress-color"
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Card>
            {buttonPackageUpgrade()}
          </>
        ) : (
          <>
            <Box className="luci-msg-box">
              <Typography>
                {CheckValueLocale("luci_msg", "", {}, intl)}
              </Typography>
              {buttonPackageUpgrade()}
            </Box>
          </>
        )}
      </Card>

      <Snackbar
        open={luciSnackBar}
        handleClose={() => {
          setLuciSnackBar(false);
        }}
        severity={luciSuccess ? "success" : "error"}
        message={
          luciSuccess ? (
            <div>
              <span className="subscription-snackbar-msg">
                {CheckValueLocale(
                  !luciInfo
                    ? "luci_req_demo"
                    : "upgrade_request_sent_successfully",
                  "",
                  {},
                  intl,
                )}
              </span>
            </div>
          ) : (
            <div>
              <span className="subscription-snackbar-msg">
                {CheckValueLocale("request_failed", "", {}, intl)}
              </span>
              <span>
                <br />
                {CheckValueLocale("segments_failed_msg", "", {}, intl)}
              </span>
            </div>
          )
        }
      />
    </div>
  );
};
export default injectIntl(LuciPackage);
