import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";

/*---------------------------------------------------------*/

export const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    max-width: 220px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    position: relative;
  }
  position: fixed;
  top: 60px;
  overflow: hidden;
  height: 100%;
  width: 220px;
  left: 0 !important;
  right: 0;
  .MuiDivider-root {
    margin-left: 25px;
    margin-right: 25px;
  }
`;
