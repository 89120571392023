import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

const LiveDashBoardActions = ({
  isEditLiveDashboard,
  actionButtonDisabled,
  isActionLoading,
  editLiveDashboardHandler,
  createLiveDashboardHandler,
  setOpenPopupDelete,
  enabledDeleteEdit,
}) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Box className="create-live-dashboard-button">
      {isEditLiveDashboard ? (
        <LucButton
          variant="outline"
          type="red"
          startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
          onClick={() => setOpenPopupDelete(true)}
          id="delete-live-dashboard-btn"
          disabled={!enabledDeleteEdit}
        >
          {CheckValueLocale("delete_dashboard", "", {}, intl)}
        </LucButton>
      ) : null}

      {isEditLiveDashboard ? (
        <LucButton onClick={() => history.goBack()} variant="outline">
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
      ) : null}
      <LucButton
        disabled={
          actionButtonDisabled || (isEditLiveDashboard && !enabledDeleteEdit)
        }
        loading={isActionLoading}
        onClick={
          isEditLiveDashboard
            ? editLiveDashboardHandler
            : createLiveDashboardHandler
        }
      >
        {CheckValueLocale(
          isEditLiveDashboard ? "save_changes" : "create_live_dashboard",
          "",
          {},
          intl,
        )}
      </LucButton>
    </Box>
  );
};

export default LiveDashBoardActions;
