import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";

const SummaryMore = ({ summaryExpanded, setSummaryExpanded }) => {
  const intl = useIntl();
  return (
    <Box className="summary-more-wrapper">
      <Box className="summary-more-line" component="span"></Box>
      <LucButton
        variant="flat"
        size="small"
        type="primary"
        id="summary-widget-more-btn"
        onClick={() => {
          setSummaryExpanded(!summaryExpanded);
        }}
        startIcon={
          <FontAwesomeIcon
            icon={summaryExpanded ? faChevronUp : faChevronDown}
          />
        }
      >
        {CheckValueLocale(
          summaryExpanded ? "read_less" : "read_more",
          "",
          {},
          intl,
        )}
      </LucButton>
      <Box className="summary-more-line" component="span"></Box>
    </Box>
  );
};

export default SummaryMore;
