import React, { useState } from "react";
import EngagementsBody from "./engagementsBody";
import { MonitorContainer } from "../../../Components/sidebar/monitor.styles";
import Luci from "pages/luci";
import { useSelector } from "react-redux";
import SummaryWidget from "shared/summaryWidget/summaryWidget";
/*---------------------------------------------------------*/

const InstagramEngagement = (props) => {
  const {
    monitorId,
    assignActiveFilters,
    pageNumber,
    startDate,
    endDate,
    activeTab,
    dataSourceId,
    monitorData,
    getSummaryStaticQuestions,
    summaryStaticQuestions,
    summaryStaticQuestionsLoading,
  } = props;

  const [openLuci, setOpenLuci] = useState(false);
  const [mainQuestionNotification, setMainQuestionNotification] = useState({
    title: "",
    body: "",
    status: "",
    questionId: "",
    sectionName: "",
    monitorId: "",
    summaryWidget: false,
  });

  //  Start Redux Declarations
  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );
  //  End Redux Declarations

  const showSummaryWidget =
    summaryStaticQuestions?.questions?.length &&
    monitorData?.attributes?.auto_summary_widget &&
    monitorData?.auto_summary_package;
  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      {showSummaryWidget ? (
        <SummaryWidget
          pageParams={{
            monitorId: monitorId,
            filterParams: assignActiveFilters,
            startDate: reduxFilterStartDateUX,
            endDate: reduxFilterEndDateUX,
            sectionName: activeTab,
            productId: window.localStorage.sm_id,
            luciFeature: "Managed Account",
            dataSourceId: dataSourceId,
            dataLoaded: props.checkLuciAllDataExist,
            dataEmpty: props.checkLuciDataIsEmpty,
            monitorluciSummaryTokens:
              monitorData?.attributes?.luci_summary_tokens,
          }}
          setOpenLuci={setOpenLuci}
          getSummaryStaticQuestions={getSummaryStaticQuestions}
          summaryStaticQuestions={summaryStaticQuestions}
          summaryStaticQuestionsLoading={summaryStaticQuestionsLoading}
          mainQuestionNotification={mainQuestionNotification}
        />
      ) : null}
      <EngagementsBody {...props} />
      <Luci
        isInactive={false}
        pageParams={{
          monitorId: monitorId,
          filterParams: assignActiveFilters,
          pageNumber: pageNumber,
          startDate: reduxFilterStartDateUX,
          endDate: reduxFilterEndDateUX,
          sectionName: activeTab,
          productId: window.localStorage.sm_id,
          luciFeature: "Managed Account",
          dataSourceId: dataSourceId,
          monitorName: monitorData?.attributes?.account_name,
          monitorProduct: `Luci ${monitorData?.attributes?.data_category}`,
          dataLoaded: props.checkLuciAllDataExist,
          dataEmpty: props.checkLuciDataIsEmpty,
        }}
        openLuci={openLuci}
        setOpenLuci={setOpenLuci}
        mainQuestionNotification={mainQuestionNotification}
        setMainQuestionNotification={setMainQuestionNotification}
      />
    </MonitorContainer>
  );
};

export default InstagramEngagement;
