import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import profileImg from "images/engagements-images/profileImg.png";

import verifyIcon from "images/engagements-images/vervied.svg";
import EmptyAccountsImage from "images/engagements-images/empty-accounts-image.svg";
const AccountsSection = ({
  selectedAccoutnts,
  setSelectedAccounts,
  handleTruncateName,
  accountData,
  handleDatasourcesIcon,
  accountsLoader,
  handleDateFormat,
  date,
  getCalendarData,
  assignActiveFilters,
}) => {
  const intl = useIntl();
  const handleAccounts = (item) => {
    const isItemExist = selectedAccoutnts?.some(
      (obj) =>
        obj.reference_id == item?.reference_id &&
        obj.data_source == item?.data_source,
    );
    let accounts = [];
    if (!isItemExist) {
      accounts = [...selectedAccoutnts, item];
    } else {
      accounts = selectedAccoutnts?.filter(
        (i) =>
          !(
            i.reference_id == item?.reference_id &&
            i?.data_source == item?.data_source
          ),
      );
    }
    // cal api calender
    let { startDate, endDate } = handleDateFormat(date);
    setSelectedAccounts(accounts);
    getCalendarData(startDate, endDate, assignActiveFilters, accounts);
  };
  const getCountLimit = (verifed) => {
    return verifed ? 14 : 18;
  };
  return (
    <Box
      className={`${!accountsLoader && "accounts-sections-container-loading"}`}
    >
      {accountData?.length || !accountsLoader ? (
        <Box className="accounts-sections-container">
          <Typography component={"p"} className="accounts-publish-title">
            {CheckValueLocale("publish_accounts", "", {}, intl)}
          </Typography>
          {!accountsLoader ? null : (
            <Box className="accounts-sections-content">
              {accountData?.map((item) => (
                <Box key={item?.id} className="account-publish-item">
                  <FormControlLabel
                    value={{
                      reference_id: item?.id,
                      data_source: item?.attributes?.data_source,
                    }}
                    control={
                      <Checkbox
                        checked={selectedAccoutnts.some(
                          (val) =>
                            val.reference_id == item?.id &&
                            val.data_source == item?.attributes?.data_source,
                        )}
                      />
                    }
                    onClick={() =>
                      handleAccounts({
                        reference_id: item?.id,
                        data_source: item?.attributes?.data_source,
                      })
                    }
                  />
                  <Typography
                    component={"span"}
                    className="accounts-name-content"
                  >
                    <img
                      src={
                        item?.attributes?.image_url
                          ? item?.attributes?.image_url
                          : profileImg
                      }
                      className="accounts-publish-avatar"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = profileImg;
                      }}
                    />
                    <img
                      src={handleDatasourcesIcon(
                        item?.attributes?.data_source?.toLowerCase(),
                      )}
                      className="accounts-datasource-icon"
                    />
                    {item?.attributes?.name?.length >
                    getCountLimit(item?.attributes?.verified) ? (
                      <Tooltip
                        title={item?.attributes?.name}
                        arrow
                        placement="top"
                      >
                        <Typography
                          component={"span"}
                          className="account-publish-name"
                        >
                          {handleTruncateName(
                            item?.attributes?.name,
                            getCountLimit(item?.attributes?.verified),
                          )}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component={"span"}
                        className="account-publish-name"
                      >
                        {handleTruncateName(
                          item?.attributes?.name,
                          getCountLimit(item?.attributes?.verified),
                        )}
                      </Typography>
                    )}
                    {item?.attributes?.verified ? (
                      <img
                        src={verifyIcon}
                        alt="verify"
                        className="publish-verify-icon"
                      />
                    ) : null}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ) : (
        <Box className="empty-accounts-container">
          <Box>
            <img src={EmptyAccountsImage} className="empty-accounts-icon" />
          </Box>
          <Typography component={"p"} className="accounts-publish-desription">
            {CheckValueLocale("publish_empty_accounts_message", "", {}, intl)}
          </Typography>
        </Box>
      )}
      {accountsLoader &&
      handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") ? (
        <Box
          className={`publish-manage-accounts-container ${
            !accountData?.length && "empty-manage-accounts-content"
          }`}
        >
          <Link to="/engagements/setup" className="publish-manage-accounts">
            {CheckValueLocale("publish_manage_accounts", "", {}, intl)}
          </Link>
        </Box>
      ) : null}
    </Box>
  );
};

export default AccountsSection;
