import { useQuery } from "@tanstack/react-query";
import MonitorsController from "services/controllers/monitorsController.js";

export const useGetConfigData = ({ monitorId, DMConfigurationId }) => {
  return useQuery({
    queryKey: ["monitors", monitorId],
    queryFn: () =>
      MonitorsController.getEditConfigData(monitorId, DMConfigurationId),
    enabled: !!monitorId,
  });
};
