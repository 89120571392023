import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Button, Box, Tabs, Tab, Container } from "@mui/material";
import { Add } from "@mui/icons-material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import MainSegmentsTable, { getSegmentsTableHeader } from "./mainSegments";
import SegmentsImports, { getImportsTableHeader } from "./segmentsImports";
import DuplicateProfiles from "./duplicateProfiles";
import ManageAudienceHeader from "./componentes/manageAudienceHeader";
import AudienceController from "services/controllers/audienceController";
import NewSegmentPopup from "../components/newSegment/newSegmentPopup";
import SnackBar from "components/snackBar";
import "./manageAudience.scss";
import { faFileImport } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImportFunctions from "../components/audienceImportPopUp/components/importSharedFunction";
import AudienceImportExcelPopUp from "../components/audienceImportPopUp";
import { useSortableTable } from "hooks/useSortableTable";

const ManageAudience = (props) => {
  const intl = useIntl();
  const [tabValue, setTabValue] = useState(0);
  const [segmentsAudience, setSegmentsAudience] = useState([]);
  const [loading, setLoading] = useState(true);
  const [segmentList, setSegmentList] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [showCreateSegement, setShowCreateSegment] = useState(false);
  const [segmentData, setSegmentData] = useState({});
  const [listImport, setListImport] = useState([]);
  const [loadingCdpImports, setLoadingCdpImports] = useState(true);
  const [alertData, setAlertData] = useState({
    number: "",
    message: "",
    title: "",
    severity: "",
    openAlert: false,
  });
  const importStateVal = localStorage?.importState;
  let {
    files,
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    fileName,
    displayUploadErrorMessage,
    handleImportAudience,
    handleResetImportPopUp,
    setOpenImportPopUp,
    enableProfileAudience,
    setEnableProfileAudience,
    enableUncomplyingAudience,
    setEnableUncomplyingAudience,
    openImportPopUp,
    getTitleImportExcel,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
  } = ImportFunctions();

  const [exportSnackBar, setExportSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });

  const onSortSegmentChangeCallback = () => {
    setPage(1);
    setIsSegmentsSortableLoading(true);
  };
  const onSortImportChangeCallback = () => {
    setPage(1);
    setIsImportsSortableLoading(true);
  };
  const {
    columns: segmentsColumns,
    sortColumn: segmentsSortColumn,
    sortDirection: segmentsSortDirection,
    isSortableLoading: isSegmentsSortableLoading,
    setIsSortableLoading: setIsSegmentsSortableLoading,
  } = useSortableTable({
    initialColumns: getSegmentsTableHeader(intl),
    onSortChangeCallback: onSortSegmentChangeCallback,
    defaultSelectedColumn: "created_at",
    defaultDirection: "desc",
  });

  const {
    columns: importsColumns,
    sortColumn: importsSortColumn,
    sortDirection: importsSortDirection,
    isSortableLoading: isImportsSortableLoading,
    setIsSortableLoading: setIsImportsSortableLoading,
  } = useSortableTable({
    initialColumns: getImportsTableHeader(intl),
    onSortChangeCallback: onSortImportChangeCallback,
    defaultSelectedColumn: "created_at",
    defaultDirection: "desc",
  });
  const startSegementsLoading = () => {
    if (isSegmentsSortableLoading) return;
    setLoading(true);
  };
  const startSegmentsImportsLoading = () => {
    if (isImportsSortableLoading) return;
    setLoadingCdpImports(true);
  };
  const stopLoading = () => {
    setIsSegmentsSortableLoading(false);
    setIsImportsSortableLoading(false);
    setLoading(false);
    setLoadingCdpImports(false);
  };
  const showErrorMessages = () => {
    setExportSnackBar({
      message: CheckValueLocale("try_again_error_message", "", {}, intl),
      severity: "error",
      title: CheckValueLocale("failed_error_message", "", {}, intl),
      openSnackBar: true,
    });
  };
  const setSegment = (data) => {
    setSegmentData(data);
    setShowCreateSegment(true);
  };
  // List Of Imports

  const getListOfCdpImports = () => {
    startSegmentsImportsLoading();
    AudienceController.importCDPImports(
      page,
      importsSortColumn,
      importsSortDirection,
    ).then((result) => {
      if (result?.errorMsg) {
        setListImport([]);
        setPagination(1);
        showErrorMessages();
      } else {
        setListImport(result?.data?.data?.data);
        setPagination(result?.data?.data?.total_pages);
      }
      stopLoading();
    });
  };

  // Call List Of segments
  const getListOfSegments = () => {
    startSegementsLoading();
    AudienceController.getListOfSegments(
      page,
      segmentsSortColumn,
      segmentsSortDirection,
    ).then((result) => {
      if (result?.errorMsg) {
        setSegmentsAudience([]);
        setPagination(1);
        showErrorMessages();
      } else {
        setSegmentsAudience(result?.data?.data);
        setPagination(result?.data?.total_pages);
      }
      stopLoading();
    });
    getSegmentList();
  };

  const getSegmentList = () => {
    AudienceController.getSegmentList().then((result) => {
      setSegmentList(result?.data?.data);
    });
  };

  useEffect(() => {
    setPage(1);
  }, [tabValue]);

  useEffect(() => {
    tabValue === 0 && getListOfSegments();
    tabValue === 1 && getListOfCdpImports();
  }, [
    tabValue,
    page,
    segmentsSortColumn,
    segmentsSortDirection,
    importsSortColumn,
    importsSortDirection,
  ]);

  useEffect(() => {
    importStateVal && setTabValue(1);
  }, [importStateVal]);

  const handleSegmentAlert = (severity, msg, tit, number) => {
    setAlertData({
      number: number,
      message: msg,
      title: tit,
      severity: severity,
      openAlert: severity && msg ? true : false,
    });
    if (severity === "success" && number && number != "0") {
      getListOfSegments();
    }
  };

  const openNewSegmentPopUp = () => {
    setShowCreateSegment(true);
    setSegmentData({});
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSegmentCreate = (severity, msg, tit, number) => {
    handleSegmentAlert(severity, msg, tit, number);
  };

  const TabPanelComponent = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    );
  };

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };
  const handleCloseAudienceExportSnackBar = () => {
    setExportSnackBar({ openSnackBar: false });
  };

  const childProps = {
    files,
    setFiles,
    excelFileName,
    excelFileError,
    excelFileErrorMsg,
    isFileUploaded,
    fileName,
    displayUploadErrorMessage,
    handleImportAudience,
    handleResetImportPopUp,
    setOpenImportPopUp,
    enableProfileAudience,
    setEnableProfileAudience,
    enableUncomplyingAudience,
    setEnableUncomplyingAudience,
    openImportPopUp,
    getTitleImportExcel,
    handleExcelGuideData,
    handleCancelUploadFile,
    excelGuideData,
    exportSnackBar,
    setExportSnackBar,
  };
  return (
    <Container maxWidth="xl">
      {openImportPopUp ? (
        <AudienceImportExcelPopUp
          {...childProps}
          isManageAudiencePage
          getListOfCdpImports={getListOfCdpImports}
        />
      ) : null}

      <ManageAudienceHeader />
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        className="manage-audience-tabs"
      >
        <Tab
          className="manage-audience-tab"
          label={CheckValueLocale("segments_head", "", {}, intl)}
        />
        {handlelUserRoles("GENERAL", "VIEW_UTILITIES") ? (
          <Tab
            className="manage-audience-tab"
            label={CheckValueLocale("segments_imports", "", {}, intl)}
          />
        ) : null}
        {/* <Tab
          className="manage-audience-tab"
          label={CheckValueLocale("segments_duplicate_profile", "", {}, intl)}
        /> */}
        {handlelUserRoles("CDP", "CREATE_SEGMENT") && tabValue == 0 ? (
          <Button
            variant="contained"
            className="add-segment-btn"
            startIcon={<Add className="add-icon" />}
            onClick={openNewSegmentPopUp}
            id="manage-audience-new-segments-btn"
          >
            {CheckValueLocale("segments_new_segment", "", {}, intl)}
          </Button>
        ) : handlelUserRoles("GENERAL", "CREATE_UTILITIES") && tabValue == 1 ? (
          <Button
            variant="contained"
            className="btn-import"
            startIcon={
              <FontAwesomeIcon icon={faFileImport} className="add-icon" />
            }
            id="import-audience-btn"
            onClick={handleImportAudience}
          >
            {CheckValueLocale("segment_import", "", {}, intl)}
          </Button>
        ) : null}
      </Tabs>
      <TabPanelComponent value={tabValue} index={0}>
        <MainSegmentsTable
          getListOfSegments={getListOfSegments}
          segmentsAudience={segmentsAudience}
          loading={loading}
          pagination={pagination}
          page={page}
          handleChangePage={handleChangePage}
          setSegment={setSegment}
          tableHead={segmentsColumns}
          isTableLoading={isSegmentsSortableLoading}
        />
      </TabPanelComponent>
      <TabPanelComponent value={tabValue} index={1}>
        <SegmentsImports
          getListOfCdpImports={getListOfCdpImports}
          listImport={listImport}
          loading={loadingCdpImports}
          pagination={pagination}
          page={page}
          handleChangePage={handleChangePage}
          tableHead={importsColumns}
          isTableLoading={isImportsSortableLoading}
        />
      </TabPanelComponent>
      <TabPanelComponent value={tabValue} index={2}>
        <DuplicateProfiles />
      </TabPanelComponent>

      {showCreateSegement && (
        <NewSegmentPopup
          open={() => setShowCreateSegment(true)}
          close={() => setShowCreateSegment(false)}
          create={handleSegmentCreate}
          segmentData={segmentData}
          getListOfSegments={getListOfSegments}
          segmentList={segmentList}
        />
      )}

      <SnackBar
        open={alertData.openAlert}
        handleClose={() =>
          setAlertData({
            number: "",
            message: "",
            title: "",
            severity: "",
            openAlert: false,
          })
        }
        severity={alertData.severity}
        title={CheckValueLocale(alertData.title, "", {}, intl)}
        message={CheckValueLocale(
          alertData.message,
          "",
          {
            num: alertData.number,
          },
          intl,
        )}
      />

      <SnackBar
        open={exportSnackBar?.openSnackBar}
        severity={exportSnackBar?.severity}
        message={exportSnackBar?.message}
        title={exportSnackBar?.title}
        handleClose={handleCloseAudienceExportSnackBar}
      />
    </Container>
  );
};

export default ManageAudience;
