import React from "react";
import MonitorHeader from "pages/Monitors/Components/monitorHeader/monitorHeader";
import MonitorSideBar from "../sidebar/monitorSideBar";
import { Box } from "@mui/material";

const MonitorSideBarAndHeader = (props) => {
  return (
    <>
      <MonitorHeader {...props} />
      {/* ________________________________________________________________________ */}
      <Box className="active-filters-box"></Box>
      {/* ________________________________________________________________________ */}
      <MonitorSideBar {...props} />
    </>
  );
};

export default MonitorSideBarAndHeader;
