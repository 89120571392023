import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Typography } from "@mui/material";
import { useContext, useState } from "react";
import LucButton from "shared/lucButton/lucButton";
import { AiAgentsContext } from "../../../aiAgentsContext";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import moment from "moment";
import EditPublishedVersionModal from "./aiAgentModal/editPublishedVersionModal";
import { useQueryClient } from "@tanstack/react-query";
import UserAvatar from "shared/unifiedUserImage/userAvatar";

export const AiAgentPublishedVersionHeader = ({ aiAgentDetails }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { setSelectedAiAgentPublishedVersion } = useContext(AiAgentsContext);
  const [isConfirmEditOpen, setIsConfirmEditOpen] = useState(false);

  const selectedDateBasedOnPublishedVersion = setSelectedAiAgentPublishedVersion
    ? aiAgentDetails?.ai_agent_created_at
    : aiAgentDetails?.created_at;

  const formattedDate = moment(selectedDateBasedOnPublishedVersion)
    .tz("utc", true)
    .format("D MMM, hh:mm A");

  const handleClosePublishedVersion = () => {
    setSelectedAiAgentPublishedVersion(null);
    queryClient.invalidateQueries({
      queryKey: ["aiAgentDetails"],
    });
  };

  return (
    <>
      <Box className="ai-agents-form-header">
        <Box className="published-ai-agent-details">
          <Box className="published-ai-agents-header-name" component="h4">
            {aiAgentDetails?.name}
          </Box>
          <Box className="published-ai-agent-published-by-container">
            <UserAvatar
              avatarSrc={aiAgentDetails?.user?.avatar}
              fullName={aiAgentDetails?.user?.name}
              sizes={{ ratio: "16px", fontSize: "7px" }}
            />
            <Typography className="published-ai-agent-header-info-text">
              {CheckValueLocale(
                "ai_agent_published_by",
                "",
                {
                  name: aiAgentDetails?.user?.name,
                },
                intl,
              )}
            </Typography>
          </Box>
          <Typography className="published-ai-agent-header-info-text">
            —
          </Typography>
          <Typography className="published-ai-agent-header-info-text">
            {formattedDate}
          </Typography>
        </Box>
        <Box className="published-ai-agent-actions-container">
          {handlelUserRoles("AI_AGENT", "EDIT_AI_AGENT") ? (
            <LucButton
              variant="outline"
              startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
              onClick={() => setIsConfirmEditOpen(true)}
              id="ai-agent-button-view-published-edit"
            >
              {CheckValueLocale("edit", "", {}, intl)}
            </LucButton>
          ) : null}
          <LucButton
            onClick={handleClosePublishedVersion}
            id="ai-agent-button-view-published-close"
          >
            {CheckValueLocale("close", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
      <EditPublishedVersionModal
        onClose={() => setIsConfirmEditOpen(false)}
        open={isConfirmEditOpen}
        versionId={aiAgentDetails?.ai_agent_version_id}
      />
    </>
  );
};

export default AiAgentPublishedVersionHeader;
