import Services from "./Services.js";

var Benchmark = {};

Benchmark.getBenchmarkTypes = function (queryData) {
  return Services.getData("api/v4/utilities/benchmarks/types", queryData);
};
Benchmark.getMonitorsBenchmark = function (queryData) {
  return Services.getData("api/v4/utilities/benchmarks", queryData);
};
Benchmark.getAllMonitors = function (queryData) {
  return Services.getData("api/v4/utilities/benchmarks/monitors", queryData);
};

Benchmark.getExportBenchmark = function (queryData) {
  return Services.getDataBuffer(
    "api/v3/social_media/twitter/benchmarks/export",
    queryData,
  );
};

Benchmark.getBenchmarkInformal = function (queryData) {
  return Services.getData("api/v4/utilities/benchmarks/informal", queryData);
};

// Benchmark.generateBenchmarkPpt = function(queryData) {
//     return Services.nodeGetData('api_generate_benchmark_ppt', queryData);
// }

// Benchmark.generateBenchmarkPDF = function(queryData) {
//     return Services.getData('api_get_monitors_benchmark', queryData);
// }

Benchmark.getPolicy = (policy_type) => {
  return Services.postData("api/v4/term_policies", {
    policy_type: policy_type,
  });
};
Benchmark.getApplyPolicy = () => {
  return Services.getData("api/v4/term_policies");
};

export default Benchmark;
//
