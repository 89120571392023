import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import Services from "services/api/Services";
import { CheckValueLocale } from "utils/helpers";
const FormFooter = () => {
  const intl = useIntl();
  return (
    <Box className="form-footer-wrapper">
      {Services.showThirdCompany ? (
        <Box component="p" className="form-footer-text">
          {CheckValueLocale("copy_rights", "", {}, intl)}
          {new Date()?.getFullYear()}{" "}
          {CheckValueLocale(
            "lucidya_rights_login",
            "",
            { project_name_en: Services?.project_name_en.toUpperCase() },
            intl,
          )}
        </Box>
      ) : null}
    </Box>
  );
};
export default FormFooter;
