import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { TextField, Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import MonitorsController from "services/controllers/monitorsController";

const CreateRepliesModal = (props) => {
  const intl = useIntl();
  const {
    setShowCreateRepliesModal,
    savedReplies,
    setSavedReplies,
    setShowSnackBar,
    setSnackbarMessage,
    setSnackbarTitle,
    setSnackbarSeverity,
  } = props;
  const [repliesTitle, setRepliesTitle] = useState("");
  const [repliesText, setRepliesText] = useState("");
  const [repliesTitleError, setRepliesTitleError] = useState("");
  const [alreadyExistingTitles, setAlreadyExistingTitles] = useState([]);

  useEffect(() => {
    let titles = [];
    savedReplies?.map((repliesData) => {
      titles?.push(repliesData?.attributes?.title);
    });
    setAlreadyExistingTitles(titles);
  }, []);

  useEffect(() => {
    setRepliesTitleError("");
    if (repliesTitle?.length > 35) {
      setRepliesTitleError("saved_replies_title_error");
    } else if (caseInsensitiveIncludes(alreadyExistingTitles, repliesTitle)) {
      setRepliesTitleError("saved_replies_title_duplicate_error");
    }
  }, [repliesTitle]);

  const caseInsensitiveIncludes = (array, value) => {
    const lowercasedValue = value?.toLowerCase();
    return array.some((item) => item?.toLowerCase() === lowercasedValue);
  };

  const handleTitle = (event) => {
    setRepliesTitle(event.target.value);
  };

  const handleText = (event) => {
    setRepliesText(event.target.value);
  };

  const handleCreateReplies = () => {
    MonitorsController.createSavedReplies(
      window.localStorage.engagements_id,
      repliesTitle,
      repliesText,
    )
      .then((data) => {
        setSavedReplies([...savedReplies, data?.data?.data]);
        setRepliesTitle("");
        setRepliesText("");
        setShowSnackBar(true);
        setSnackbarSeverity("success");
        setSnackbarTitle("reply_saved_successfully");
        setShowCreateRepliesModal(false);
      })
      .catch(() => {
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("please_try_again");
        setSnackbarTitle("wrong_request_parameter");
      });
  };

  const textFieldStyle =
    repliesTitle?.length > 35 ||
    caseInsensitiveIncludes(alreadyExistingTitles, repliesTitle)
      ? "create-saved-replies-textfield"
      : "";

  const replyContentStyle =
    repliesText?.length > 280 ? "create-saved-replies-textfield" : "";

  let isDisabled =
    repliesTitle === "" ||
    repliesText === "" ||
    repliesTitle?.length > 35 ||
    repliesText?.length > 280 ||
    caseInsensitiveIncludes(alreadyExistingTitles, repliesTitle);

  const btnStyle = isDisabled
    ? "create-replies-btn-disabled"
    : "create-replies-btn";

  return (
    <Box className="create-replies-modal">
      <Box className="create-replies-heading">
        {CheckValueLocale("create_saved_replies", "", {}, intl)}
      </Box>
      <Box className="create-replies-textfield-title">
        {CheckValueLocale("reply_title", "", {}, intl)}
      </Box>
      <TextField
        placeholder={CheckValueLocale("reply_title_placeholder", "", {}, intl)}
        value={repliesTitle}
        onChange={handleTitle}
        error={
          repliesTitle?.length > 35 ||
          caseInsensitiveIncludes(alreadyExistingTitles, repliesTitle)
        }
        className={textFieldStyle}
      />
      <Box className="saved-replies-error">
        {CheckValueLocale(repliesTitleError, "", {}, intl)}
      </Box>
      <Box className="create-replies-textfield-title">
        {CheckValueLocale("reply_content", "", {}, intl)}
      </Box>
      <TextField
        placeholder={CheckValueLocale(
          "reply_content_placeholder",
          "",
          {},
          intl,
        )}
        value={repliesText}
        onChange={handleText}
        error={repliesText?.length > 280}
        className={replyContentStyle}
      />
      {repliesText?.length > 280 ? (
        <Box className="saved-replies-error">
          {CheckValueLocale("saved_replies_text_error", "", {}, intl)}
        </Box>
      ) : null}
      <Box
        className={btnStyle}
        onClick={isDisabled ? () => {} : handleCreateReplies}
      >
        {CheckValueLocale("save", "", {}, intl)}
      </Box>
    </Box>
  );
};

export default CreateRepliesModal;
