const handleXlsData = (allSheetData) => {
  var xlsheet = [];
  allSheetData?.map((i) => {
    xlsheet?.push([
      {
        data: i[0],
        name: i[1],
      },
    ]);
  });
  return xlsheet;
};

const handleAuthorActivity = (value, minValueClient, maxValueClient, x) => {
  const mapping_number_to_range = (num) => {
    let min = minValueClient;
    let max = maxValueClient;

    // Avoid Zeros case
    if (min === 0 && max === 0 && num === 0) {
      return 10;
    } else {
      return 10 + ((30 - 10) / (max - min)) * (num - min);
    }
  };
  const reverse_mapping_number_to_range = (num) => {
    // Reverse the above function
    let min = minValueClient,
      max = maxValueClient;
    return (num - 10) / ((30 - 10) / (max - min)) + min;
  };
  const hours_for_hover = [
    "12am",
    "1am",
    "2am",
    "3am",
    "4am",
    "5am",
    "6am",
    "7am",
    "8am",
    "9am",
    "10am",
    "11am",
    "12pm",
    "1pm",
    "2pm",
    "3pm",
    "4pm",
    "5pm",
    "6pm",
    "7pm",
    "8pm",
    "9pm",
    "10pm",
    "11pm",
  ];
  const days = [
    x.Friday, // Equals to 0 at channel Activity Array
    x.Thursday, // Equals to 1 at channel Activity Array
    x.Wednesday, // Equals to 2 at channel Activity Array
    x.Tuesday, // Equals to 3 at channel Activity Array
    x.Monday, // Equals to 4 at channel Activity Array
    x.Sunday, // Equals to 5 at channel Activity Array
    x.Saturday, // Equals to 6 at channel Activity Array
  ];

  let clientActivity = [];
  if (value) {
    value?.forEach((arr) => {
      let num = mapping_number_to_range(arr[2]);
      clientActivity?.push([arr[1], arr[0], num]);
    });
  }

  var authorActivity = [];
  clientActivity?.map((i) => {
    authorActivity?.push([
      days[i[1]],
      hours_for_hover[i[0]],
      Math.round(reverse_mapping_number_to_range(i[2])),
    ]);
  });
  return authorActivity;
};

const handleSortedCase = (value, cases, mainTitle, dialectValue, lang) => {
  let sortedBy = value.sort((a, b) => {
    return b.value - a.value;
  });
  sortedBy?.map((i) => {
    cases?.push({
      [mainTitle]: lang && lang[i?.name] ? lang[i?.name] : i?.name,
      [dialectValue]: i?.value,
    });
  });
};

export { handleXlsData, handleAuthorActivity, handleSortedCase };
