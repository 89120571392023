import { useState } from "react";
import { useIntl } from "react-intl";
import Dropzone from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { handlelUserRoles } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/pro-solid-svg-icons";

const DropZoneArea = (props) => {
  const { setImage, logoError, setLogoError } = props;
  const intl = useIntl();
  const [fileName, setFileName] = useState("");

  // Validate logo dimensions 400px x 400px
  const validateDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img?.width > 400 || img?.height > 400)
          reject("branding_logo_width_height_error");
        else resolve();
      };
    });
  };

  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles?.[0];

    // Validate logo file type and size less than 30 Mb
    if (!["image/png", "image/jpg", "image/jpeg"]?.includes(file?.type)) {
      setFileName(file?.name);
      return setLogoError("branding_logo_type_error");
    } else if (file?.size > 31457280)
      return setLogoError("branding_logo_size_error");

    try {
      await validateDimensions(file);
      setImage(file);
      setLogoError("");
    } catch (error) {
      setLogoError(error);
    }
  };

  const handleUploadError = (error) => {
    if (error?.[0]?.errors?.[0]?.code === "file-too-large")
      setLogoError("branding_logo_size_error");
    else setLogoError("branding_logo_type_error");
  };

  return (
    <Box className="App">
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        onDropRejected={handleUploadError}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
              ? "reject"
              : "";

          return (
            <Box
              {...getRootProps({ className: `dropzone ${additionalClass}` })}
            >
              <Box className="image-upload">
                {handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") && (
                  <input {...getInputProps()} />
                )}
                <Box>{CheckValueLocale("upload_image", "", {}, intl)}</Box>
                <FontAwesomeIcon icon={faCloudArrowDown} />
              </Box>
            </Box>
          );
        }}
      </Dropzone>
      <Typography variant="p" className="logo-error">
        {CheckValueLocale(logoError, "", { name: fileName }, intl)}
      </Typography>
    </Box>
  );
};

export default DropZoneArea;
