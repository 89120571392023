import "./hashtagsTable.scss";
import SharedTable from "shared/table/sharedTable";
import { sortedColumnTypes } from "hooks/useSortableTable";
import { CheckValueLocale } from "utils/helpers";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import DeleteItem from "./components/deleteItem";
import MonitorsCell from "./components/monitorsCell";
import NoDataFoundProducts from "components/no-Data";

export const getHashtagsListTableHeader = (intl) => {
  return [
    {
      body: "#",
      width: 50,
      name: "id",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("hashtag_hashtags", "", {}, intl),
      width: 100,
      name: "hashtag",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("requester_hashtags", "", {}, intl),
      width: 100,
      name: "user",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("account_hashtags", "", {}, intl),
      width: 100,
      name: "account",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("status_hashtags", "", {}, intl),
      width: 50,
      name: "status",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("monitors_hashtags", "", {}, intl),
      width: 150,
      name: "",
    },
    {
      body: null,
      width: 30,
    },
  ];
};

const HashtagsTable = ({
  hashtags,
  tableHead,
  loading,
  isSortableLoading,
  searchText,
  statusHashtags,
  getHashtags,
  setSnakBarDelete,
  page,
}) => {
  const intl = useIntl();

  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const getStatusLocalization = (status) => {
    const hashtag_type = {
      active: CheckValueLocale("active_hashtags", "", {}, intl),
      inactive: CheckValueLocale("inactive_hashtags", "", {}, intl),
      registering: CheckValueLocale("registering_hashtags", "", {}, intl),
      de_registering: CheckValueLocale("deRegistering_hashtags", "", {}, intl),
      not_found: CheckValueLocale("not_found_hashtags", "", {}, intl),
    };
    return hashtag_type[status];
  };
  const stateCell = (status) => {
    return (
      <>
        {getStatusLocalization(status) ? (
          <Box
            className={"status " + status?.replaceAll("_", "-")}
            id="status-hashtag"
          >
            {getStatusLocalization(status)}
          </Box>
        ) : null}
      </>
    );
  };

  const displayMoniters = (monitors) => {
    return <MonitorsCell monitors={monitors} intl={intl} />;
  };

  const deletIcon = (item) => {
    return (
      <DeleteItem
        item={item}
        getHashtags={getHashtags}
        setSnakBarDelete={setSnakBarDelete}
        hashtags={hashtags}
        page={page}
      />
    );
  };

  const getAccount = (account) => {
    return (
      <Box className="account-hashtag">
        <img src={publicInstagram} className="instagram" alt="social" />
        <Box component="span">{account}</Box>
      </Box>
    );
  };

  useEffect(() => {
    const tabBody =
      hashtags?.length == 0
        ? []
        : hashtags?.map((item) => {
            return {
              id: item?.id,
              className: "",
              data: [
                {
                  body: item?.id,
                },
                {
                  body: "#" + item?.hashtag,
                },
                {
                  body: item?.user,
                },
                {
                  body: item?.account ? getAccount(item?.account) : "_",
                },
                {
                  body: item?.status ? stateCell(item?.status) : "—",
                },
                {
                  body: item?.monitors?.length
                    ? displayMoniters(item?.monitors)
                    : "_",
                },
                {
                  body: deletIcon(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(hashtags));
  }, [hashtags, intl?.locale]);

  const emptyBody = (hashtags) => {
    return (
      <>
        {(searchText !== "" && hashtags?.length == 0) ||
        (hashtags?.length == 0 && statusHashtags?.length) ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="monitor_list_search_empty_submsg"
            />
          </Box>
        ) : hashtags?.length == 0 ? (
          <Box className="empty-item empty-item-hashtags">
            <NoDataFoundProducts
              image="instagramHashtags"
              title="no_hashtags_used"
              description="no_hashtags_used_dec"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <SharedTable
      className="hashtag-table"
      isSharedStyle
      tableHeader={tableHead}
      tableBody={tableBody}
      emptyBody={tableEmptyBody}
      loading={isSortableLoading || loading}
    />
  );
};
export default HashtagsTable;
