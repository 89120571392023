import React, { useState } from "react";
import { Box, Card, CardContent, FormControl, Typography } from "@mui/material";
import styles from "../commonStyle.module.css";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const NpsQuestion = (props) => {
  const { question, questionNo } = props;
  const intl = useIntl();
  const data = question;
  const answers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const [selectedValue, setSelectedValue] = useState();

  const handleSelection = (value) => setSelectedValue(value);

  return (
    <>
      <Card className="cardStyle">
        <CardContent>
          <Typography className={styles.questionStyle}>
            <Box
              component="span"
              className="questionNoStyle"
              style={{ fontWeight: "500" }}
            >{`${questionNo}.`}</Box>
            {data?.question?.text}
            {data?.question?.constraints?.required && (
              <Box component="span" className={styles.impQuestion}>
                *
              </Box>
            )}
          </Typography>
          <Box className={styles.subtitle} style={{ marginBottom: "15px" }}>
            {data?.question?.description}
          </Box>
          <FormControl
            className={`${styles.dropdownStyle} dropdown-question-style`}
            style={{ width: "100%" }}
          >
            <Box>
              <Box
                className="radio-item"
                style={{ justifyContent: "space-between" }}
              >
                {answers?.map((item, index) => {
                  return (
                    <label className="radio-item" key={index}>
                      <input
                        type="radio"
                        name={"colors" + index + questionNo}
                        checked={index === +selectedValue}
                        value={selectedValue}
                        onChange={() => handleSelection(item)}
                      />
                      <Box
                        component="span"
                        className={
                          data?.question?.constraints?.colored_theme
                            ? index === 7 || index === 8
                              ? "choose yellow"
                              : index === 9 || index === 10
                                ? "choose green"
                                : "choose red"
                            : "choose"
                        }
                      >
                        <Box>{item}</Box>
                      </Box>
                    </label>
                  );
                })}
              </Box>
              <Box className="most-like">
                <Typography>
                  {CheckValueLocale("unlikely", "", {}, intl)}
                </Typography>
                <Typography>
                  {CheckValueLocale("very_likely", "", {}, intl)}
                </Typography>
              </Box>
            </Box>
          </FormControl>
        </CardContent>
      </Card>
    </>
  );
};

export default NpsQuestion;
