import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Divider,
  Card,
  TextField,
  InputLabel,
  Stack,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { timeOffsets } from "./timeOffsets";
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";

const CompanyInfo = (props) => {
  const intl = useIntl();
  const { id, companyInfo, setCompanyInfo, setInfoChangesFlag } = props;
  const [companyName, setCompanyName] = useState("");
  const [time_zone, setTimeZone] = useState("");

  //Render Componenet
  useEffect(() => {
    setCompanyName(companyInfo?.name);
    setTimeZone(companyInfo?.time_zone);
  }, [companyInfo?.name, companyInfo?.time_zone]);

  const changeHandler = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
    setInfoChangesFlag(true);
  };

  return (
    <div id="company_info">
      <Card>
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <InputLabel>
            {CheckValueLocale("company_name", "", {}, intl)}
          </InputLabel>
          <TextField
            value={companyName}
            name="name"
            onChange={changeHandler}
            disabled={!handlelUserRoles("GENERAL", "EDIT_COMPANY_BASIC_INFO")}
          />
        </Stack>
        <Box
          component={"span"}
          style={{ position: "relative", bottom: "10px" }}
        >
          {CheckValueLocale("company_id", "", {}, intl)} #{id}
        </Box>
        <Divider light />
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <InputLabel>{CheckValueLocale("time_zone", "", {}, intl)}</InputLabel>
          <Select
            value={time_zone}
            onChange={changeHandler}
            name="time_zone"
            sx={{ backgroundColor: "#F1F5F9" }}
            disabled={!handlelUserRoles("GENERAL", "EDIT_COMPANY_BASIC_INFO")}
            style={intl.locale !== "en" ? { direction: "ltr" } : {}}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 250,
                  overflowY: "scroll",
                },
              },
            }}
          >
            {timeOffsets.map((time) => (
              <MenuItem
                key={time?.value}
                value={time?.value}
                style={intl.locale !== "en" ? { direction: "ltr" } : {}}
                id={`settings-timeoffset-${time?.value ?? "time-value"}`}
              >
                {time.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Card>
    </div>
  );
};

export default CompanyInfo;
