import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";

const Sidebar = (props) => {
  const intl = useIntl();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();

  var activeProducts = JSON.parse(window.localStorage.activeProducts || null);
  let lucidyaApisActive = activeProducts?.find(
    (product) => product.name == "PUBLIC_API",
  );
  let engagementActive = activeProducts?.find(
    (product) => product.name == "ENGAGEMENTS" && product?.active,
  );
  const activeEngagemetCheck = engagementActive ? true : false;
  const lucidyaApisActiveCheck = lucidyaApisActive ? true : false;

  const options = [
    handlelUserRoles("GENERAL", "VIEW_SETTING") && "CompanyInfoIndex",
    handlelUserRoles("GENERAL", "VIEW_SUBSCRIPTION") && "SubscriptionIndex",
    handlelUserRoles("GENERAL", "VIEW_USER") && "UserManagementsIndex",
    handlelUserRoles("GENERAL", "VIEW_USER") && "teams",
    "accounts_management",
    (handlelUserRoles("CXM", "CREATE_HISTORICAL_REQUEST") ||
      handlelUserRoles("SM", "CREATE_HISTORICAL_REQUEST")) &&
      "historical_request",
    // "SmartTagsIndex",
    handlelUserRoles("GENERAL", "VIEW_INTEGRATION") &&
      "ThirdPartyIntegrationsIndex",
    (!lucidyaApisActiveCheck ||
      (lucidyaApisActiveCheck &&
        handlelUserRoles("PUBLIC_API", "VIEW_PUBLIC_API"))) &&
      "lucidya_api",
    "instagram_hashtags",
    handlelUserRoles("ENGAGEMENTS", "ACCESS_ENGAGEMENT_SYSTEM") &&
      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")) &&
      activeEngagemetCheck &&
      "inbound_routing",
  ];
  const optionsList = [];
  options.map((i) => {
    if (i !== false && i != null) {
      optionsList.push(i);
    }
  });
  // Handle Selected Item
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="setting-sidebar">
      <List>
        <ListSubheader>
          {
            <h2 className="setting-title">
              {CheckValueLocale("settings", "", {}, intl)}
            </h2>
          }
        </ListSubheader>
        <Divider light />
        {optionsList.map((text, index) => (
          <ListItem
            button
            key={index}
            divider
            selected={
              (location.pathname === "/settings/" ||
                location.pathname === "/settings") &&
              index === 0
                ? true
                : location.pathname.includes(
                      `/settings/${text
                        .replace("Index", "")
                        .replace(/([a-z])([A-Z])/g, "$1_$2")
                        .replace("Third", "3rd")
                        .toLowerCase()}`,
                    )
                  ? true
                  : false
            }
            to={
              text === "CompanyInfoIndex"
                ? "/settings"
                : `/settings/${text
                    .replace("Index", "")
                    .replace(/([a-z])([A-Z])/g, "$1_$2")
                    .replace("Third", "3rd")
                    .toLowerCase()}`
            }
            component={Link}
            onClick={(event) => handleListItemClick(index)}
            id={`settings-sidebar-${text ?? "text"}`}
          >
            <ListItemText
              primary={CheckValueLocale(
                text
                  .replace("Index", "")
                  .replace(/([a-z])([A-Z])/g, "$1_$2")
                  .toLowerCase(),
                "",
                {},
                intl,
              )}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default Sidebar;
