import {
  Box,
  MenuItem,
  Select,
  Typography,
  Tooltip,
  InputLabel,
} from "@mui/material";
import _ from "lodash";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import FormControl from "@mui/material/FormControl";
import profileImg from "images/engagements-images/profileImg.png";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import XPIcon from "images/engagements-images/xp-icon-eng-round.svg";
import FBIcon from "images/engagements-images/fb-round-icon.svg";
import IGIcon from "images/engagements-images/ig-round-icon.svg";
import { sanitize } from "dompurify";

const AccountsSelect = ({
  accountsPublishSelected,
  setAccountsPublishSelected,
  setContentErrorMedia,
  convAuthAccounts,
  convAuthAllawedAccounts,
  loading,
  setRecevierAccountSelect,
  setReceiverAccountList,
  setReceiverAccountFoucs,
}) => {
  const intl = useIntl();

  const handleSelectionChange = (event) => {
    setContentErrorMedia("");
    const { value } = event.target;
    setAccountsPublishSelected(value);
    setRecevierAccountSelect("");
    setReceiverAccountFoucs(false);
    setReceiverAccountList([]);
  };

  const handleDatasourcesIcon = (datasource) => {
    let datasourcesLIst = {
      twitter: XPIcon,
      facebook: FBIcon,
      instagram: IGIcon,
    };
    return datasourcesLIst[datasource] || datasourcesLIst.default;
  };

  return (
    <Box className="publish-new-post-accounts-container">
      <Typography component={"p"} className="publish-new-post-accounts-title ">
        {CheckValueLocale("new_conv_sender_account", "", {}, intl)}
      </Typography>
      <Box>
        <Box className="input-form-control">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {CheckValueLocale(
                "new_conv_sender_account_placeholder",
                "",
                {},
                intl,
              )}
            </InputLabel>
            <Select
              id="demo-multiple-checkbox"
              className={`accounts-select-publish-items-dm ${
                (!convAuthAccounts?.length ||
                  !convAuthAllawedAccounts?.length) &&
                "accounts-select-publish-items-empty "
              }`}
              displayEmpty
              value={accountsPublishSelected?.name}
              onChange={(e) => handleSelectionChange(e)}
              disabled={loading}
              renderValue={(selected) => accountsPublishSelected?.name}
            >
              {convAuthAllawedAccounts?.length ? (
                convAuthAllawedAccounts?.map((item) => (
                  <MenuItem
                    key={`account-type-${item?.id}`}
                    className="accounts-publish-inside-item"
                    value={{
                      reference_id:
                        item?.attributes?.data_source == "TWITTER"
                          ? item?.attributes?.id
                          : item?.attributes?.account_info_id,
                      data_source: item?.attributes?.data_source,
                      imageUrl: item?.attributes?.image_url,
                      monitor_id:
                        item?.attributes?.used_in_which_private_channel,
                      name: item?.attributes?.name,
                      user_name:
                        item?.attributes?.data_source == "FACEBOOK"
                          ? item?.attributes?.name
                          : item?.attributes?.user_name,
                    }}
                    disabled={
                      item?.attributes?.status?.toLowerCase() ==
                      "re-authenticate"
                    }
                  >
                    <Box className="publish-accounts-wrapper">
                      <Box className="publish-account-wrapper-item">
                        <Typography
                          component={"span"}
                          className="accounts-name-content-pop-up"
                        >
                          <img
                            src={
                              item?.attributes?.image_url
                                ? item?.attributes?.image_url
                                : profileImg
                            }
                            alt="profile-image"
                            className="accounts-publish-avatar-inside"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = profileImg;
                            }}
                          />
                          <img
                            src={handleDatasourcesIcon(
                              item?.attributes?.data_source?.toLowerCase(),
                            )}
                            alt="datasource-icon"
                            className="accounts-datasource-icon"
                          />
                          <Box>
                            <Typography
                              component={"p"}
                              className="account-publish-name-pop-up"
                            >
                              {item?.attributes?.name}
                            </Typography>
                            <Typography
                              component={"p"}
                              className="account-publish-name-pop-up-datasource"
                            >
                              {CheckValueLocale(
                                item?.attributes?.data_source,
                                "",
                                {},
                                intl,
                              )}
                            </Typography>
                          </Box>
                        </Typography>
                      </Box>
                      {item?.attributes?.status?.toLowerCase() ==
                      "re-authenticate" ? (
                        <Tooltip
                          title={CheckValueLocale(
                            "publish_warning_icon_tooltip",
                            "",
                            {},
                            intl,
                          )}
                          placement="left"
                          arrow
                        >
                          <ErrorOutlineIcon className="publish-warning-icon" />
                        </Tooltip>
                      ) : null}
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  key={`account-type-empty`}
                  className="accounts-publish-inside-item empty"
                  disabled={true}
                  value={null}
                >
                  <Box
                    className="accounts-publish-empty"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        CheckValueLocale(
                          !convAuthAccounts?.length
                            ? "no_accounts"
                            : "no_permission",
                          "",
                          {},
                          intl,
                        ),
                      ),
                    }}
                  />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountsSelect;
