import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { CheckValueLocale } from "../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";

const SaveFooterChanges = (props) => {
  const intl = useIntl();
  const {
    updatedValuesParams,
    setUpdatedValuesParams,
    dashboardErrors,
    dashboardData,
    selectedDatasourcesMonitors,
  } = props;
  const number =
    updatedValuesParams && Object.keys(updatedValuesParams)?.length;

  const handleCancle = () => {
    setUpdatedValuesParams({});
    window.location.reload();
  };
  let checkData =
    dashboardErrors?.dashabordName !== "" ||
    dashboardData?.dashabordName === "" ||
    selectedDatasourcesMonitors?.length < 2
      ? true
      : false;

  return (
    <AppBar className="save-footer">
      <Toolbar className="save-footer-toolbar">
        <Button
          className={`save-btn ${checkData && "disabled-save-button"}`}
          onClick={() => props.setShowSaveModal(true)}
          id="dashboard-sidebar-save-dashboard-edit-btn"
        >
          {CheckValueLocale("save", "", {}, intl)}
        </Button>
        <Button
          className="cancel-btn"
          onClick={handleCancle}
          id="dashboard-sidebar-cancel-dashboard-edit-btn"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </Button>
        <Typography>
          {CheckValueLocale(
            "dashboard_you_have_changed_setting",
            "",
            {
              number: number,
            },
            intl,
          )}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default SaveFooterChanges;
