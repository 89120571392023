import { useState } from "react";
import { sanitize } from "dompurify";
import { Box, Button, CircularProgress } from "@mui/material";
import { useIntl } from "react-intl";
import star from "images/engagements-images/star.svg";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import CompaniesController from "services/controllers/companiesController";
import SnackBar from "components/snackBar";

const Cdp = () => {
  const intl = useIntl();
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const requestChannelAnalDemo = () => {
    let queryData = {
      service_name: "request_audience_demo",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result?.errorMsg) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
      setIsLoading(false);
    });
  };

  const cdpContentArray = [
    "social_media_accounts_cdp",
    "user_segments_cdp",
    "all_interactions_channels_cdp",
    "control_audience_cdp",
    "and_more_cdp",
  ];

  return (
    <>
      <Box className="profile-side-bar-cdp">
        <Box className="cdp-text">
          <img src={star} className="star-img" />
          <p className="cdp-heading">
            {CheckValueLocale("cdp_heading", "", {}, intl)}
          </p>
          <p className="cdp-description">
            {CheckValueLocale("cdp_description", "", {}, intl)}
          </p>
        </Box>
        <Box className="cdp-content">
          {cdpContentArray.map((content, i) => (
            <Box className="cdp-box" key={i}>
              <FontAwesomeIcon icon={faCheck} />
              <Box
                className="box-text"
                dangerouslySetInnerHTML={{
                  __html: sanitize(CheckValueLocale(content, "", {}, intl)),
                }}
              />
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          id="demo-cdp"
          className="demo-btn"
          onClick={requestChannelAnalDemo}
        >
          {isLoading ? (
            <span className="sending-request-btn-text">
              <CircularProgress className="circular-progress-subscription circular-progress" />
              {CheckValueLocale("sending_request", "", {}, intl)}
            </span>
          ) : (
            CheckValueLocale("cdp_request_demo", "", {}, intl)
          )}
        </Button>
      </Box>
      {success != null ? (
        <SnackBar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            <div className="cdp-snackbar-wrapper">
              <span className="cdp-heading">
                {CheckValueLocale(
                  success ? "request_successfully_sent" : "something_wrong",
                  "",
                  {},
                  intl,
                )}
              </span>
              <span className="cdp-description">
                {CheckValueLocale(
                  success
                    ? "cdp_demo_request_has_been_successfully_sent"
                    : "please_try_again_later",
                  "",
                  {},
                  intl,
                )}
              </span>
            </div>
          }
        />
      ) : (
        ""
      )}
    </>
  );
};
export default Cdp;
