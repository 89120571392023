import { useEffect, useState } from "react";
import { isConditionRowValid } from "../components/routingConditions/routingConditions";
import EngagementsController from "services/controllers/engagementsController";
import {
  SLATimeStringify,
  arrayValuesAdapter,
  inboundRoutingValuesAdapter,
} from "./inboundRouteAdapter";
import CompaniesController from "services/controllers/companiesController";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";

export const useSharedInboundRouting = () => {
  const [generalInfo, setGeneralInfo] = useState({ name: "", description: "" });
  // will holds the errors and the snackbar state for the routing page
  const [routingError, setRoutingError] = useState({
    message: "",
    severity: "",
    title: "",
    openSnakbar: false,
    routingNameError: "",
    routingDescriptionError: "",
  });
  // conditions will hold the conditions that the user will add in the first step
  // condition is a unique string value for each condition e.g. "platform", "account".
  // operator is a string that the user will select for the condition e.g. "IS", "IS_NOT"
  // value is the value that the user will enter after selecting the operator it could be the following (string, number, []string, []{id, name,})
  const [conditions, setConditions] = useState([
    { condition: "", operator: "", value: "" },
  ]);
  const [isConditionsLoading, setConditionsLoading] = useState(true);
  // conditions options will be fetched from the backend
  const [conditionsOptions, setConditionsOptions] = useState([]);
  // this state will hold the validation errors for the steps
  const [validationErrors, setValidationErrors] = useState({
    isAllConditionsValid: false,
    isTeamAndSLAValid: false,
    isGeneralInfoValid: false,
  });
  // this state will hold the selected team and sla
  const [teamAndSLA, setTeamAndSLA] = useState({
    selectedTeam: null, // will be object {id: 1, name: "Team 1"}
    selectedSLA: null, // will be object {id: 1, name: "SLA 1"}
    selectedAlgorithm: null, // will be string "round_robin" or "balance"
    teams: [], // options for the team select
    slas: [], // options for the sla select
    algorithms: [], // options for the algorithm select
    autoAssigned: false, // switch state for auto assign member
  });
  const [teamAndSLAsLoading, setTeamAndSLAsLoading] = useState({
    teams: true,
    slas: true,
    algorithms: true,
  });
  const handleGeneralInfoChange = (newGeneralInfo) => {
    // handling routing name errors
    if (newGeneralInfo?.name || newGeneralInfo?.name === "") {
      let routingNameError = "";
      if (newGeneralInfo?.name?.length > 50) {
        routingNameError = "routing_name_max_length_error";
      } else if (
        isPublicSpecialCharactersOrEmojis(
          newGeneralInfo?.name,
          undefined,
          true,
        ) ||
        removeSpecialCharacters(newGeneralInfo?.name)?.length === 0
      ) {
        routingNameError = "spam_keywords_rules";
      }
      setRoutingError((prev) => ({
        ...prev,
        routingNameError: routingNameError,
      }));
    }
    // handling routing description errors
    if (newGeneralInfo?.description || newGeneralInfo?.description === "") {
      let routingDescriptionError = "";
      if (
        isPublicSpecialCharactersOrEmojis(
          newGeneralInfo?.description,
          undefined,
          true,
        ) ||
        removeSpecialCharacters(newGeneralInfo?.description)?.length === 0
      ) {
        routingDescriptionError = "spam_keywords_rules";
      }
      setRoutingError((prev) => ({
        ...prev,
        routingDescriptionError: routingDescriptionError,
      }));
    }
    setGeneralInfo((prevGeneralInfo) => ({
      ...prevGeneralInfo,
      ...newGeneralInfo,
    }));
  };
  const getConditions = () => {
    setConditionsLoading(true);
    EngagementsController.getEnagementConditions(
      window?.localStorage?.engagements_id,
    ).then((data) => {
      if (data?.errorMsg) {
        setRoutingError({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnakbar: true,
        });
      } else {
        const conditions = data?.data;
        const desrializedConditions = conditions?.map((condition) => {
          return {
            conditionNum: condition?.id,
            name: "routing_" + condition?.filter_name,
            id: condition?.filter_name,
            label: "routing_select_" + condition?.filter_name,
            operators: arrayValuesAdapter(condition?.operator_names),
            values: inboundRoutingValuesAdapter(
              condition?.filter_name,
              condition?.values,
            ),
          };
        });
        setConditionsOptions(desrializedConditions);
      }
      setConditionsLoading(false);
    });
  };
  // get the teams is invoked whenever the user opens the teams dropdown
  const getTeams = () => {
    setTeamAndSLAsLoading((prev) => ({
      ...prev,
      teams: true,
    }));
    CompaniesController.getAllCompanyTeams().then((res) => {
      if (!res?.errorMsg) {
        const teams = res?.data?.teams;
        setTeamAndSLA((prev) => ({
          ...prev,
          teams: teams,
        }));
      }
      setTeamAndSLAsLoading((prev) => ({
        ...prev,
        teams: false,
      }));
    });
  };

  // get the slas is invoked when the user go to the second step
  // or when the user create new sla from step 2
  const getSLAs = () => {
    EngagementsController.getCompanySLAs().then((res) => {
      if (!res?.errorMsg) {
        const slas = res?.data?.data;
        setTeamAndSLA((prev) => ({
          ...prev,
          slas: slas?.map?.((sla) => {
            const slaData = sla?.attributes || {};
            return {
              ...slaData,
              name: `${slaData?.name} ${SLATimeStringify(slaData)}`,
            };
          }),
        }));
      }
      setTeamAndSLAsLoading((prev) => ({
        ...prev,
        slas: false,
      }));
    });
  };
  // initializtion of the teams and slas data
  // if the teams, sla are not fetched yet, then fetch them
  const getTeamsAndSLAsData = () => {
    // get the teams
    if (teamAndSLAsLoading?.teams) {
      getTeams();
    }
    // get the slas
    if (teamAndSLAsLoading?.slas) {
      getSLAs();
    }
    // get the algorithms
    if (teamAndSLAsLoading?.algorithms) {
      EngagementsController.getRoutingAlgorithms().then((res) => {
        if (!res?.errorMsg) {
          const algorithms = res?.data?.data;
          setTeamAndSLA((prev) => ({
            ...prev,
            algorithms: algorithms?.map?.((algo) => {
              const { name, id } = algo?.attributes || {};
              const algoName = name?.toLowerCase()?.split(" ")?.join("_");
              return {
                id: id,
                name: "routing_" + algoName,
                desc: "routing_" + algoName + "_desc",
              };
            }),
          }));
        }
        setTeamAndSLAsLoading((prev) => ({
          ...prev,
          algorithms: false,
        }));
      });
    }
  };
  useEffect(() => {
    // validate the step 1 general info
    const isGeneralInfoValid =
      generalInfo?.name?.trim()?.length > 0 &&
      generalInfo?.description?.trim()?.length > 0 &&
      !routingError?.routingNameError &&
      !routingError?.routingDescriptionError;

    setValidationErrors((prev) => ({
      ...prev,
      isGeneralInfoValid: isGeneralInfoValid,
    }));
  }, [generalInfo, routingError]);
  useEffect(() => {
    const allRowsAreValid = conditions.every(isConditionRowValid);
    setValidationErrors((prev) => ({
      ...prev,
      isAllConditionsValid: allRowsAreValid,
    }));
  }, [conditions]);

  useEffect(() => {
    // validations in the second step are following
    // 1. if the team is selected or sla is selected
    // 2. if the team is selecte and if auto assign is enabled, then the algorithm should be selected
    // otherwise, the step is not valid
    const isTeamAndSLAValid =
      teamAndSLA?.selectedTeam || teamAndSLA?.selectedSLA;
    const isAlgorithmValid =
      teamAndSLA?.selectedTeam && teamAndSLA?.autoAssigned
        ? !!teamAndSLA?.selectedAlgorithm
        : true;
    setValidationErrors((prev) => ({
      ...prev,
      isTeamAndSLAValid: isTeamAndSLAValid && isAlgorithmValid,
    }));
  }, [teamAndSLA]);
  return {
    generalInfo,
    setGeneralInfo,
    handleGeneralInfoChange,
    routingError,
    setRoutingError,
    conditions,
    setConditions,
    teamAndSLA,
    setTeamAndSLA,
    validationErrors,
    isConditionsLoading,
    conditionsOptions,
    getConditions,
    getTeamsAndSLAsData,
    teamAndSLAsLoading,
    getSLAs,
    getTeams,
  };
};
