import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./InputWithAddButton.scss";
import { sanitize } from "dompurify";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import {
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import {
  checkValue,
  handleCopyBtn,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import { useEffect, useState } from "react";

const InputWithAddButton = ({
  tooltipTitle = "",
  labelName,
  setMonitorErrors,
  monitorErrors,
  setMonitorData,
  monitorData,
  keyFeild,
  placeholder,
  handleChange,
  handleAddKeywordsTolist,
  value,
  limitCount,
}) => {
  const intl = useIntl();
  const [copyBtn, setCopyBtn] = useState("click_to_copy");
  const handleDeleteKeyword = (index) => {
    monitorData?.[keyFeild]?.splice(index, 1);
    setMonitorData({ ...monitorData });
  };

  useEffect(() => {
    setCopyBtn("click_to_copy");
  }, [monitorData?.[keyFeild]]);
  return (
    <>
      <CustomizedTooltip
        title={CheckValueLocale(tooltipTitle, "", {}, intl)}
        arrow
        placement="bottom"
        styles={{ ...customizedStyledTooltip, maxWidth: "200px" }}
      >
        <Typography
          variant="caption"
          className={`label-input-with-add-button ${tooltipTitle ? "input-label-underlined" : ""}`}
        >
          {CheckValueLocale(labelName, "", {}, intl)}
        </Typography>
      </CustomizedTooltip>
      <OutlinedInput
        variant="outlined"
        className={`input-field-with-add-button ${value ? checkDirectionLang(value) : ""}`}
        onFocus={(e) => {
          if (value) handleChange(e);
        }}
        onBlur={() => {
          // Remove Error message if string is empty
          if (!value) {
            setMonitorErrors({
              ...monitorErrors,
              [keyFeild]: "",
            });
          }
        }}
        endAdornment={
          <InputAdornment onMouseDown={handleInputMouseDown}>
            <Button
              className="add-btn-with-plus"
              onClick={handleAddKeywordsTolist}
              id={`monitor-options-tw-add-exclude-keyword-btn-${labelName}`}
            >
              <FontAwesomeIcon icon={faPlus} />
              {CheckValueLocale("add", "", {}, intl)}
            </Button>
          </InputAdornment>
        }
        placeholder={CheckValueLocale(placeholder, "", {}, intl)}
        onKeyPress={(event) => {
          if (event?.key === "Enter") handleAddKeywordsTolist();
        }}
        onChange={handleChange}
        value={value}
        name={labelName}
        error={monitorErrors?.[keyFeild]}
      />
      {monitorErrors?.[keyFeild] ? (
        <Box
          className="keyword-error-msg-form"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              CheckValueLocale(
                monitorErrors?.[keyFeild],
                "",
                {
                  limit: limitCount,
                },
                intl,
              ),
            ),
          }}
        />
      ) : null}
      <Box className="keyword-spam-wrapper">
        {checkValue(monitorData?.[keyFeild])?.map((keyword, i) => {
          return (
            <KeywordTooltipChip
              onDelete={() => handleDeleteKeyword(i)}
              dataSource={"twitter"}
              index={i}
              labels={keyword}
            />
          );
        })}
        <KeywordPopupModal
          keywords={monitorData?.[keyFeild]}
          handleDelete={handleDeleteKeyword}
          dataSourceType={"twitter"}
          keywordType={labelName}
          dataSourceIndex={0} // dataSource index to handle delete keyword
          isTitleSearch
        />
        {monitorData?.[keyFeild]?.length !== 0 && copyBtn ? (
          <Tooltip
            title={CheckValueLocale(copyBtn, "", {}, intl)}
            arrow
            placement="bottom"
          >
            <ContentCopyIcon
              className="copy-icon"
              onClick={() =>
                handleCopyBtn(
                  setCopyBtn,
                  CheckValueLocale("copied", "", {}, intl),
                  monitorData?.[keyFeild],
                )
              }
            />
          </Tooltip>
        ) : null}
      </Box>
    </>
  );
};

export default InputWithAddButton;
