import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const StepOne = (props) => {
  const {
    customDomain,
    shareSurvey,
    domainActive,
    handlelUserRoles,
    styles,
    customDomainStatus,
    setDomainActive,
    setIsLucidyaDomain,
  } = props;

  const intl = useIntl();

  const handleDomainClick = () => {
    if (domainActive == 1) setIsLucidyaDomain(true);
    else {
      setIsLucidyaDomain(false);
    }
  };

  useEffect(() => {
    if (domainActive !== null) {
      handleDomainClick(domainActive);
    }
  }, [domainActive]);

  return (
    <>
      <Box className="share-survey-container">
        <Box className="close-share-btn publish-step">1 / 3</Box>
        <Box className="how-share-survey">
          <Box className="how-share-title">
            {CheckValueLocale("how_would_to_share_your_survey", "", {}, intl)}
          </Box>

          <Box className="publish-domain-step-one">
            <RadioGroup value={domainActive}>
              {shareSurvey?.map(({ name, desc, tooltip, id }, index) => {
                return (
                  <Box key={index} className="publish-step-btn">
                    <FormControlLabel
                      control={
                        <Radio
                          value={domainActive}
                          disabled={
                            (name === "company_custom_domain" &&
                              !handlelUserRoles("GENERAL", "VIEW_SETTING")) ||
                            name === "company_custom_domain"
                              ? customDomain == "" ||
                                customDomainStatus == "pending"
                                ? true
                                : false
                              : false
                          }
                          onClick={() => {
                            setDomainActive(id);
                          }}
                          checked={domainActive === id}
                        />
                      }
                      label={
                        <>
                          <Tooltip
                            title={CheckValueLocale(
                              tooltip,
                              "",
                              { domain_name: `${customDomain}` },
                              intl,
                            )}
                            placement="right"
                            arrow
                            classes={{ tooltip: styles().tooltip }}
                          >
                            <Box>
                              <Box className="domain-name">
                                {CheckValueLocale(`${name}`, "", {}, intl)}
                              </Box>
                            </Box>
                          </Tooltip>

                          <Box className="domain-desc">
                            {CheckValueLocale(
                              `${
                                name === "company_custom_domain"
                                  ? customDomain !== "" &&
                                    customDomainStatus !== "pending"
                                    ? customDomain
                                    : customDomainStatus == "pending"
                                      ? "domain_is_not_verified"
                                      : "survey_custom_domain_description"
                                  : desc
                              }`,
                              "",
                              {},
                              intl,
                            )}

                            {name === "company_custom_domain" &&
                            handlelUserRoles(
                              "GENERAL",
                              "EDIT_COMPANY_BASIC_INFO",
                            ) &&
                            !customDomain &&
                            customDomainStatus !== "pending" ? (
                              <Box className="custom-domain-btn">
                                {handlelUserRoles("GENERAL", "VIEW_SETTING") ? (
                                  <Link to="/settings" target="_blank">
                                    {CheckValueLocale(
                                      "survey_manage_custom_domain",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  </Link>
                                ) : null}
                              </Box>
                            ) : null}
                          </Box>
                        </>
                      }
                    />
                  </Box>
                );
              })}
            </RadioGroup>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StepOne;
