import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Divider } from "@mui/material";

const InteractionBrand = ({ interactionType, brand, title }) => {
  const intl = useIntl();

  return (
    <Box className="interaction-brand">
      <Box
        className={`interaction-brand-wrapper ${title?.toLowerCase()}-brand`}
      >
        <Box>{brand}</Box>
        <span className="interaction-brand-title">{title}</span>
      </Box>

      {interactionType?.length > 0 ? (
        <Box className="interaction-type">
          {CheckValueLocale("type", "", {}, intl)}
          {": "}
          {CheckValueLocale(interactionType?.toLowerCase(), "", {}, intl)}
        </Box>
      ) : null}
    </Box>
  );
};

export default InteractionBrand;
