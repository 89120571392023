import { Chip } from "@mui/material";

const ipChip = ({ label, onDelete, index }) => {
  return (
    <Chip
      key={index}
      label={label}
      className="ip-chip-style"
      onDelete={onDelete}
    />
  );
};

export default ipChip;
