import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
// import FiltersController from "services/controllers/filtersController";
// import InteractionsController from "services/controllers/interactionsController";
import DashboardsController from "services/controllers/dashboardController";
import {
  checkKeyboardLanguage,
  isEmptyArray,
  CheckValueLocale,
} from "utils/helpers";
import CommonFunctions from "../../../../Monitors/Components/commonFunctions";

const FiltersFunctions = (
  dashboardID,
  dashboardActiveTab,
  startDate,
  endDate,
  applyFilter,
) => {
  const intl = useIntl();
  const [showSaveAndApplyModal, setShowSaveAndApplyModal] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [showSnackBar, setshowSnackBar] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [savedFilterId, setSavedFilterId] = useState("");
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});

  const handleCloseDeleteFilterModal = () => {
    setShowDeleteFilterModal(false);
  };
  const [snackBarDetails, setsnackBarDetails] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const { changeFilterParamsToString } = CommonFunctions();
  // ______________________________________________________________________________________
  //set initial  Empty filterParams
  const [filterParams, setFilterParams] = useState({});
  // ______________________________________________________________________________________
  //this function empty all filter fields
  const handleClearFilterParams = () => {
    setSavedFilterId("");
    setFilterName("");
    setFilterParams({});
  };
  // ______________________________________________________________________________________
  //this one handle the new saved filter name
  const handleFilterName = (e) => {
    setFilterName(e.target.value);
  };
  // ______________________________________________________________________________________
  //this is the save filter Name Modal Body
  const modalBody = (
    <TextField
      fullWidth
      varient="outlined"
      label={CheckValueLocale("filter_name", "", {}, intl)}
      style={{
        direction: checkKeyboardLanguage(filterName) ? "rtl" : "ltr",
      }}
      value={filterName}
      onChange={handleFilterName}
      placeholder={CheckValueLocale("filter_name", "", {}, intl)}
    />
  );
  // ______________________________________________________________________________________

  const handleShowSaveAndApplyModal = (filterParams) => {
    setShowSaveAndApplyModal(true);
    setFilterName("");
    setFilterParams(filterParams);
  };
  // ______________________________________________________________________________________

  const handleCloseApplySaveFilter = () => {
    setShowSaveAndApplyModal(false);
    setFilterName("");
  };
  // ______________________________________________________________________________________

  const handleCloseSnackBar = () => {
    setshowSnackBar(false);
  };
  // ______________________________________________________________________________________
  //Click on Save & Apply in popup Modal to create new filter
  const handleApplySaveFilter = () => {
    if (filterName === "") {
      return false;
    } else {
      DashboardsController.createDashboardSavedFilter(
        dashboardID,
        dashboardActiveTab,
        filterName,
        filterParams &&
          //format FilterParams to be string instead of array and remove empty array params
          changeFilterParamsToString(filterParams),
      ).then((data) => {
        setShowSaveAndApplyModal(false);
        setshowSnackBar(true); // show save and apply snackbar
        setFilterName(filterName); // remove old filter name
        setAssignActiveFilters(filterParams); // set selected data to be shown in active filters section
        applyFilter(startDate, endDate, filterParams); // send request to rabbit mq
        if (data.data.status !== 400) {
          getAllSavedFilters();
          setsnackBarDetails({
            severity: "success",
            message: CheckValueLocale(
              "you_can_check_saved_filters_inside_filter_panel",
              "",
              {},
              intl,
            ),
            title: CheckValueLocale("filter_saved_successfully", "", {}, intl),
          });
        } else {
          setsnackBarDetails({
            severity: "error",
            message: CheckValueLocale("repeated_name_error", "", {}, intl),
          });
        }
      });
    }
  };
  // ______________________________________________________________________________________

  const getSavedFilterData = (savedFilterId) => {
    DashboardsController.getDashboardSavedFilterInfo(
      dashboardID,
      savedFilterId,
      dashboardActiveTab,
    ).then((data) => {
      setFilterName(data?.data?.attributes?.name_filter);
      setFilterParams(data?.data?.attributes?.filter_params);
    });
  };
  // ______________________________________________________________________________________
  //this fn deletes a previously saved filter
  const handleDeleteFilter = (savedFilterId) => {
    DashboardsController.deleteDashboardSavedFilterInfo(
      dashboardID,
      savedFilterId,
      dashboardActiveTab,
    ).then((data) => {
      setShowDeleteFilterModal(false);
      setshowSnackBar(true);
      handleClearFilterParams(); //empty all selected filters
      applyFilter(startDate, endDate, {}); //request to connect with rabbit mq with empty active filters
      if (data?.data?.status === 202) {
        getAllSavedFilters();
        setsnackBarDetails({
          severity: "success",
          message: CheckValueLocale(
            "filter_deleted_successfully",
            "",
            {},
            intl,
          ),
        });
      } else {
        setsnackBarDetails({
          severity: "error",
          message: CheckValueLocale("failed_error", "", {}, intl),
        });
      }
    });
  };
  /*______________________________________________________________*/
  //This Call is to get All filter fields for active tab
  const getFiltersFileds = () => {
    DashboardsController.getDashboardEnabledFilters(
      dashboardID,
      dashboardActiveTab,
    ).then((data) => {
      setFilterFields(data?.data);
      getAllSavedFilters();
    });
  };
  // ______________________________________________________________________________________

  const getAllSavedFilters = () => {
    DashboardsController.getDashboardSavedFilters(
      dashboardID,
      dashboardActiveTab,
    ).then((data) => {
      if (data) {
        setSavedFilters(data?.data);
      }
    });
  };
  // ______________________________________________________________________________________

  return {
    modalBody,
    handleFilterName,
    handleDeleteFilter,
    getSavedFilterData,
    handleApplySaveFilter,
    handleShowSaveAndApplyModal,
    handleCloseSnackBar,
    showSaveAndApplyModal,
    setShowSaveAndApplyModal,
    showSnackBar,
    handleClearFilterParams,
    setFilterParams,
    filterParams,
    handleCloseApplySaveFilter,
    snackBarDetails,
    filterFields,
    getFiltersFileds,
    savedFilters,
    setSavedFilterId,
    savedFilterId,
    showDeleteFilterModal,
    handleCloseDeleteFilterModal,
    setShowDeleteFilterModal,
    filterName,
    assignActiveFilters,
    setAssignActiveFilters,
  };
};
export default FiltersFunctions;
