import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./spamOverlay.scss";

const SpamOverlay = (props) => {
  const {
    question,
    spamLoading,
    isSpam,
    setIsSpam,
    handleUndoSpam,
    datasourcePostsType,
  } = props;
  const intl = useIntl();
  return (
    <>
      {spamLoading && (
        <Box className="loading-overlay">
          <Box className="overlay-content">
            <CircularProgress className="loading-btn" />
          </Box>
        </Box>
      )}
      {isSpam && (
        <Box className="marked-spam-overlay">
          <Box className="overlay-content">
            <FontAwesomeIcon
              icon={faBan}
              solid
              id="marked-spam-icon"
              className="marked-spam-icon"
            />
            <h3>
              {CheckValueLocale("you_marked_this_post_as_spam", "", {}, intl)}
            </h3>
            <p>{CheckValueLocale("once_you_refresh_the_page", "", {}, intl)}</p>
            <Button
              variant="outlined"
              className="undo-btn"
              onClick={() =>
                handleUndoSpam(question, setIsSpam, datasourcePostsType)
              }
            >
              {CheckValueLocale("undo", "", {}, intl)}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SpamOverlay;
