import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { useWidgetName } from "../../../hooks/useWidgetName";
import LucButton from "shared/lucButton/lucButton";

const SidebarWidgetCard = (props) => {
  const {
    dashboardInfo,
    monitorId,
    widgetData,
    handleAddWidget,
    contentWidgets,
    isDm,
    handleWidgetImage,
  } = props;
  const intl = useIntl();

  let widgetName = useWidgetName(
    dashboardInfo?.product,
    widgetData?.name,
    widgetData?.monitor_type,
    widgetData?.dashboard_page_name,
    widgetData?.data_source_name,
  );

  const widgetImg = handleWidgetImage(
    dashboardInfo?.product === "sm" ? "SocialListening" : "Omnichannel",
    dashboardInfo?.product === "cxm" && isDm
      ? `${widgetData?.data_source_name}_DM`
      : widgetData?.data_source_name,
    widgetData?.monitor_type === "MANAGED_PAGE"
      ? "MANAGED_ACCOUNT"
      : widgetData?.monitor_type,
    widgetName,
    intl?.locale?.toLowerCase(),
  );

  let disabledWidget = contentWidgets?.some(
    (widget) =>
      widget?.monitor_id == monitorId &&
      widget?.widget_id == widgetData?.id &&
      widget?.data_source == widgetData?.data_source_name &&
      widget?.monitor_type?.name == widgetData?.monitor_type,
  );

  return (
    <Box id="sidebar-widget-card" key={widgetData?.id}>
      <Box className={`widget-card-header ${disabledWidget ? "disabled" : ""}`}>
        <Typography>
          {CheckValueLocale(
            dashboardInfo?.product === "cxm"
              ? `custom_omni_${widgetName}`
              : widgetName,
            "",
            {},
            intl,
          )}
        </Typography>
        <LucButton
          id="widgets-sidebar-add-widget-btn"
          className="widgets-sidebar-add-widget-btn"
          variant="flat"
          size="small"
          minWidth={36}
          onClick={() => {
            if (disabledWidget != true)
              handleAddWidget(monitorId, widgetData, isDm);
          }}
          disabled={disabledWidget}
        >
          <FontAwesomeIcon icon={faAdd} size="small" />
        </LucButton>
      </Box>
      {disabledWidget ? null : (
        <img
          className="widgets-sidebar-widget-img"
          src={widgetImg}
          alt="widget img"
        />
      )}
    </Box>
  );
};

export default SidebarWidgetCard;
