import { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { truncate, CheckValueLocale } from "utils/helpers";

import { Box, Tooltip, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import moment from "moment";

import XPlatformAnalysisIcon from "images/notification-icons/XPlatformAnalysisIcon.svg";
import NewsletterReportIcon from "images/notification-icons/NewsletterReport.svg";
import AlertHighVolumeIcon from "images/notification-icons/AlertHighVolume.svg";
import AlertViralTweetIcon from "images/notification-icons/AlertViralTweet.svg";
import AlertHighNegativeTweetIcon from "images/notification-icons/AlertHighNegativeTweet.svg";
import VerifiedAuthorAlertIcon from "images/notification-icons/VerifiedAuthorAlert.svg";
import InfluencerAlertIcon from "images/notification-icons/InfluencerAlert.svg";
import AlertHighReachIcon from "images/notification-icons/AlertHighReach.svg";
import HighVolumeReachIcon from "images/notification-icons/HighVolumeReach.svg";
import AlertNewPostIcon from "images/notification-icons/AlertNewPost.svg";
import ExcelExport from "images/notification-icons/exportExcel.svg";
import PublishSurveyIcon from "images/notification-icons/notification_survey_icon.svg";
import EnagementNewIcon from "images/notification-icons/enagement_new_icon.svg";
import EnagementCompleteIcon from "images/notification-icons/enagement_complete_icon.svg";
import notification from "images/notification-icons/notification.svg";

// enagement_new_icon

import "./list.scss";
import SnackBar from "components/snackBar";
import SharedTable from "shared/table/sharedTable";
import {
  handelDownload,
  handelxlsxDownload,
} from "./sharedFunctions/viewDownloadFunctions";
import ViewDownloadComponent from "./components/viewDownloadComponent";
import AlertsFunctions from "pages/alerts/components/alertsFormFunctions/alertsFunctions";
import NoDataFoundProducts from "components/no-Data";

const showNotificationType = (status, isRead, productName) => {
  return (
    <>
      {status === "single_tweet_analysis" && (
        <img src={XPlatformAnalysisIcon} alt="x-platform-logo" />
      )}
      {status === "nb_report" && <img src={NewsletterReportIcon} />}
      {status === "Spikes" && <img src={AlertHighVolumeIcon} />}
      {(status === "CustomTweetsCount" || status === "Trending") && (
        <img src={AlertHighVolumeIcon} />
      )}
      {status === "High Volume Reach" && <img src={HighVolumeReachIcon} />}
      {(status === "AutoViralTweets" || status === "Viral Tweet") && (
        <img src={AlertViralTweetIcon} />
      )}
      {status === "CustomViralTweets" && <img src={AlertViralTweetIcon} />}
      {(status === "AutoNegativeTweetsCount" ||
        status === "Negative Posts") && (
        <img src={AlertHighNegativeTweetIcon} />
      )}
      {status === "CustomNegativeTweetsCount" && (
        <img src={AlertHighNegativeTweetIcon} />
      )}
      {(status === "VerifiedAuthor" || status === "Verified Author") && (
        <img src={VerifiedAuthorAlertIcon} />
      )}
      {(status === "Influence" || status === "Influencer") && (
        <img src={InfluencerAlertIcon} />
      )}
      {status === "AutoReachCount" && <img src={AlertHighReachIcon} />}
      {status === "CustomReachCount" && <img src={AlertHighReachIcon} />}
      {productName?.toLowerCase() !== "survey" &&
        productName?.toLowerCase() !== "engagements" &&
        (status === "NewPost" || status === "New Post") && (
          <img src={AlertNewPostIcon} />
        )}
      {productName?.toLowerCase() === "engagements" &&
        (status === "NewPost" || status === "New Post") && (
          <img src={EnagementNewIcon} />
        )}
      {productName?.toLowerCase() === "engagements" &&
        (status === "Completed Conversation" ||
          status === "CompletedConversation") && (
          <img src={EnagementCompleteIcon} />
        )}
      {status === "excel_export" && <img src={ExcelExport} />}
      {productName?.toLowerCase() === "survey" && (
        <img src={PublishSurveyIcon} />
      )}
    </>
  );
};

const showNotificationMessage = (item, intl) => {
  if (item?.notification_type === "single_tweet_analysis") {
    return (
      <FormattedMessage
        id="notification_message_single_tweet"
        values={{ username: <b>[@{item?.screen_name}]</b> }}
      />
    );
  } else if (item?.notification_type === "nb_report") {
    return (
      <FormattedMessage
        id="notification_message_newsletter"
        values={{
          frequency: <b>[{item?.frequency}]</b>,
          name: <b>[{item?.name}]</b>,
        }}
      />
    );
  } else if (item?.product_name?.toLowerCase() === "engagements") {
    let message =
      item?.alert_type === "NewPost" || item?.alert_type === "New Post"
        ? "notification_message_new_post_engagements"
        : item?.alert_type === "Negative Posts"
          ? "notification_message_negative_posts_engagements"
          : item?.alert_type === "Completed Conversation"
            ? "notification_message_completed_engagements"
            : "notification_message_new_post";

    return (
      <FormattedMessage
        id={message}
        values={{
          alert_name: <b>[ #{item?.alert_name} ]</b>,
          monitor_name: <b>[ #{item?.monitor_name} ]</b>,
        }}
      />
    );
  } else if (
    item?.product_name?.toLowerCase() !== "survey" &&
    (item?.alert_type === "NewPost" || item?.alert_type === "New Post")
  ) {
    return (
      <FormattedMessage
        id={
          item?.product_name?.toLowerCase() === "engagements"
            ? "notification_message_new_post_engagements"
            : "notification_message_new_post"
        }
        values={{
          alert_name: <b>[ #{item?.alert_name} ]</b>,
          monitor_name: <b>[ #{item?.monitor_name} ]</b>,
        }}
      />
    );
  } else if (
    item?.alert_type === "CustomTweetsCount" ||
    item?.alert_type === "Spikes" ||
    item?.alert_type === "Trending"
  ) {
    return (
      <FormattedMessage
        id="notification_message_high_volume"
        values={{
          percentage: item?.percentage > 0 ? Math.round(item?.percentage) : "0",
          alert_name: <b>[ #{item?.alert_name} ]</b>,
        }}
      />
    );
  } else if (
    item?.alert_type === "AutoViralTweets" ||
    item?.alert_type === "CustomViralTweets" ||
    item?.alert_type === "Viral Tweet"
  ) {
    return (
      <FormattedMessage
        id="notification_message_viral_tweet"
        values={{
          alert_name: <b>[ #{item?.alert_name} ]</b>,
          tweets_count: <b>{item?.tweets_count}</b>,
        }}
      />
    );
  } else if (
    item?.alert_type === "AutoReachCount" ||
    item?.alert_type === "CustomReachCount" ||
    item?.alert_type === "High Volume Reach"
  ) {
    return (
      <FormattedMessage
        id="notification_message_high_reach"
        values={{
          alert_name: <b>[ #{item?.alert_name} ]</b>,
          percentage: item?.percentage > 0 ? Math.round(item?.percentage) : "0",
        }}
      />
    );
  } else if (
    item?.alert_type === "CustomNegativeTweetsCount" ||
    item?.alert_type === "AutoNegativeTweetsCount" ||
    item?.alert_type === "Negative Posts"
  ) {
    return (
      <FormattedMessage
        id="notification_message_high_negative_tweet"
        values={{
          alert_name: <b>[ #{item?.alert_name} ]</b>,
          percentage: item?.percentage > 0 ? Math.round(item?.percentage) : "0",
        }}
      />
    );
  } else if (
    item?.alert_type === "VerifiedAuthor" ||
    item?.alert_type === "Verified Author"
  ) {
    return (
      <FormattedMessage
        id="notification_message_verified_author"
        values={{
          alert_name: <b>[ #{item?.alert_name} ]</b>,
          monitor_name: <b>[ #{item?.monitor_name} ]</b>,
        }}
      />
    );
  } else if (
    item?.alert_type === "Influence" ||
    item?.alert_type === "Influencer"
  ) {
    return (
      <FormattedMessage
        id="notification_message_influence"
        values={{
          alert_name: <b>[ #{item?.alert_name} ]</b>,
          monitor_name: <b>[ #{item?.monitor_name} ]</b>,
        }}
      />
    );
  } else if (item?.notification_type === "excel_export") {
    return (
      <FormattedMessage
        id="notification_message_excel_export"
        values={{
          monitor_name: <b>[ #{item?.monitor_name} ]</b>,
        }}
      />
    );
  } else if (item?.notification_type === "published_survey") {
    return (
      <FormattedMessage
        id="notification_message_published_survey"
        values={{
          monitor_name: <b>[{item?.monitor_name}]</b>,
        }}
      />
    );
  } else if (item?.product_name?.toLowerCase() === "survey") {
    return (
      <FormattedMessage
        id="notification_message_new_response_survey"
        values={{
          monitor_name: <b>[{item?.monitor_name}]</b>,
        }}
      />
    );
  } else {
    return item?.alert_name;
  }
};

const NotificationList = ({ list, selectNotificationType }) => {
  const { alertTypeLocale } = AlertsFunctions();
  const intl = useIntl();
  const history = useHistory();
  const urlParams = useParams();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [tableBody, setTableBody] = useState([]);

  //_______________________________________________________________________________________________
  //In case user is directed to this page using a link sent to his email to download excel sheet
  //the id in url is used to get the excel sheet URL from BE call responce and download it immediatly
  useEffect(() => {
    window.location.pathname.includes("xlsx_download")
      ? urlParams.id && handelxlsxDownload(urlParams.id, history)
      : urlParams.id && handelDownload(urlParams.id, history);
  }, [urlParams.id]);

  const notificationType = (item) => {
    return (
      <Box className="notification-type-parent">
        <Box className={item?.is_read === 1 ? "type-td red-dot" : "type-td"}>
          {showNotificationType(
            item?.notification_type === "alert_v2"
              ? item?.alert_type
              : item?.notification_type,
            item?.is_read,
            item?.product_name,
          )}
        </Box>
        <Box>
          <Box className="notification-type">
            {item?.product_name?.toLowerCase() === "survey" &&
            item?.notification_type?.toLowerCase() !== "published_survey"
              ? CheckValueLocale(
                  "notification_new_response_survey",
                  "",
                  {},
                  intl,
                )
              : item?.notification_type == "alert_v2" &&
                  item?.product_name?.toLowerCase() === "engagements"
                ? alertTypeLocale(item?.alert_type, "engagements")
                : item?.notification_type == "alert_v2"
                  ? alertTypeLocale(item?.alert_type)
                  : CheckValueLocale(
                      `${item?.notification_type}`,
                      "",
                      {},
                      intl,
                    )}
          </Box>
          <Box className="notification-message">
            {showNotificationMessage(item, intl)}
          </Box>
        </Box>
      </Box>
    );
  };
  const showMonitorsName = (item) => {
    if (item?.notification_type == "nb_report") {
      var monitorsName = [];
      item?.monitors?.map((monitor) => monitorsName?.push(monitor?.name));
    }
    return (
      <Box className="sources-parant">
        <Box className="product-sources">{}</Box>
        {CheckValueLocale(item?.product_name, "--", {}, intl)}
        <Box className="monitor-sources">
          {item?.notification_type == "nb_report" ? (
            <Tooltip title={monitorsName?.join(", ")} arrow placement="bottom">
              {truncate(monitorsName?.join(", "), 40)}
            </Tooltip>
          ) : (
            truncate(item?.monitor_name, 40)
          )}
        </Box>
      </Box>
    );
  };
  const dateTime = (item) => {
    return (
      <Box className="time-parant">
        <Typography className="date">
          {item?.created_at
            ? moment(item?.created_at).format("DD-MM-YYYY")
            : "—"}
        </Typography>
        <Typography className="time">
          {item?.created_at ? moment(item?.created_at).format("hh:mm a") : "—"}
        </Typography>
      </Box>
    );
  };

  //msg that appear when Notification table is empty
  const notificationEmpty = () => {
    return (
      <>
        {selectNotificationType?.length && list?.length == 0 ? (
          <NoDataFoundProducts
            image="noDataImg"
            title="monitor_list_search_empty"
            subTitle="notification_no_data_found"
          />
        ) : list?.length == 0 ? (
          <Box className="notification-empty-box">
            <img src={notification} />
            <Typography component={"h3"} className="no-data-found">
              {CheckValueLocale("no_notifications_yet", "", {}, intl)}
            </Typography>
            <Typography component={"span"} className="try-adjust-date">
              {CheckValueLocale("didnt_receive_any_notification", "", {}, intl)}
              <a rel="noreferrer" target="_blank" href="/alerts">
                {CheckValueLocale("create_new_alert_link", "", {}, intl)}
              </a>
              {CheckValueLocale("to_receive_notification", "", {}, intl)}
            </Typography>
          </Box>
        ) : null}
      </>
    );
  };

  //table body
  useEffect(() => {
    const tabBody = list?.map((item) => {
      return {
        id: item.id,
        data: [
          {
            body: notificationType(item),
          },
          {
            className: "monitors_names_td",
            body: showMonitorsName(item),
          },
          {
            className: "date-direction",
            body: dateTime(item),
          },
          {
            body: ViewDownloadComponent(item, history, intl),
          },
        ],
      };
    });
    setTableBody(tabBody);
  }, [list, intl.locale]);

  //table head
  const tableHead = [
    {
      body: CheckValueLocale("notifications", "", {}, intl),
      width: "48.36%",
    },
    {
      body: CheckValueLocale("source", "", {}, intl),
      width: "22.62%",
    },
    {
      body: CheckValueLocale("notification_creation_date", "", {}, intl),
      width: "12.47%",
    },

    {
      body: null,
      width: "9.70%",
      className: "action-table-head",
    },
  ];

  //_______________________________________________________________________________________________

  return (
    <>
      <SharedTable
        className="notification-table"
        isSharedStyle
        tableHeader={tableHead}
        tableBody={tableBody}
        emptyBody={notificationEmpty()}
      />
      {openSnackBar && (
        <SnackBar
          open={openSnackBar}
          severity={"error"}
          message={CheckValueLocale("failed_error", "", {}, intl)}
          handleClose={setOpenSnackBar(false)}
        />
      )}
    </>
  );
};

export default NotificationList;
