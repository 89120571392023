import "../inboundRoutingSharedStyle.scss";
import { Box } from "@mui/material";
import { AutoAssignMember } from "./autoAssignMember";
import { SelectSection } from "./selectSection";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import CreateSLAPopup from "../../createSLAPopup/createSLAPopup";

export const TeamAndSLAs = ({
  teamAndSLA,
  setTeamAndSLA,
  teamAndSLAsLoading,
  getSLAs,
  getTeams,
}) => {
  const [isCreateSLAPopupOpen, setIsCreateSLAPopupOpen] = useState(false);
  useState(false);
  const onClickAddNewTeam = () => {
    window.open("/settings/teams/add", "_blank");
  };
  const onClickAddNewSLA = () => {
    setIsCreateSLAPopupOpen(true);
  };
  // when the user creates a new SLA we need to refresh the SLAs list
  const onCreateNewSLASuccess = () => {
    getSLAs();
  };
  const onOpenTeamDropdown = () => {
    getTeams();
  };
  const isTeamsLoading =
    teamAndSLAsLoading?.teams || teamAndSLAsLoading?.algorithms;
  return (
    <Box className="team-and-slas-section">
      <SelectSection
        options={isTeamsLoading ? [] : teamAndSLA?.teams}
        value={teamAndSLA?.selectedTeam}
        handleChange={(team) =>
          setTeamAndSLA({ ...teamAndSLA, selectedTeam: team })
        }
        label="routing_select_team"
        placeholder="routing_select_team_input"
        emptyListMessage="no_teams_found_to_show"
        customOptionLabel="routing_add_new_team"
        onCustomOptionClick={onClickAddNewTeam}
        icon={faExternalLink}
        isLoading={isTeamsLoading}
        onOpenDropdown={onOpenTeamDropdown}
      />
      <AutoAssignMember teamAndSLA={teamAndSLA} setTeamAndSLA={setTeamAndSLA} />
      <SelectSection
        options={teamAndSLA?.slas}
        value={teamAndSLA?.selectedSLA}
        handleChange={(sla) =>
          setTeamAndSLA({ ...teamAndSLA, selectedSLA: sla })
        }
        label="routing_select_slas"
        placeholder="routing_select_slas_input"
        emptyListMessage="no_slas_found_to_show"
        customOptionLabel="routing_add_new_slas"
        onCustomOptionClick={onClickAddNewSLA}
        isLoading={teamAndSLAsLoading?.slas}
      />
      <CreateSLAPopup
        openCreateSlaPopup={isCreateSLAPopupOpen}
        setOpenCreateSlaPopup={setIsCreateSLAPopupOpen}
        onCreateNewSLASuccess={onCreateNewSLASuccess}
      />
    </Box>
  );
};
