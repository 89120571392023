import { useMutation, useQueryClient } from "@tanstack/react-query";
import ChannelController from "services/controllers/channelController";

export const useEditCustomerCare = () => {
  const queryClient = useQueryClient();
  const cxmId = localStorage?.getItem("cxm_id");
  return useMutation({
    mutationFn: ({ channelId, customerCareVal, ignoreTweets }) =>
      ChannelController.EditCustomerCare(
        channelId,
        customerCareVal,
        ignoreTweets,
        cxmId,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["monitors"],
      });
    },
  });
};
