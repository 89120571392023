import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LaunchIcon from "@mui/icons-material/Launch";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import "./surveyPage.scss";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { truncate } from "utils/helpers";
import moment from "moment";

const SurveyPage = (props) => {
  const intl = useIntl();
  let { userProfileData, survey } = props;
  const history = useHistory();

  return (
    <Box>
      <Grid container spacing={2}>
        {survey?.data?.map((item) => {
          return (
            <Grid item xs={4}>
              <Box className="audience-survey-box">
                <Box className="audience-survey-title">
                  <Typography variant="h5">
                    {truncate(item?.survey_name, 23)}
                  </Typography>
                  <Typography variant="span">
                    {CheckValueLocale("submitted_date", "", {}, intl)}:
                    <Box className="audience-survey-date">
                      {moment
                        .unix(item?.submitted_date)
                        .utc()
                        .format("D MMM, YYYY")}
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  {handlelUserRoles(
                    "CDP",
                    "VIEW_PROFILE_INTERACTIONS_DETAILS",
                  ) && (
                    <Button
                      className="show-btn"
                      endIcon={<LaunchIcon />}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push({
                          pathname: `/audience/survey/${item?.respondant_id}`,
                          state: { detail: item, profile: userProfileData },
                        });
                      }}
                      id="audience-user-activities-show-btn"
                    >
                      {CheckValueLocale("show", "", {}, intl)}
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default SurveyPage;
