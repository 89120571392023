import ThirdPartyIntegration from "./thirdPartyIntegration";
import PageHeader from "components/pageHeader.js";

const ThirdPartyIntegrationIndex = (props) => {
  return (
    <div id="third_party_integration">
      <PageHeader
        title="third_party_integrations"
        titleId="secondary-pages-header"
      />
      <ThirdPartyIntegration />
    </div>
  );
};
export default ThirdPartyIntegrationIndex;
