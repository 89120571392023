import { Box, Button, Divider, Typography } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import PageHeader from "components/pageHeader";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { useState } from "react";
import PopupModal from "components/popupModal";
import CompaniesController from "services/controllers/companiesController";
import SnackBar from "components/snackBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { sanitize } from "dompurify";

const links = [
  {
    name: "settings",
    url: "settings",
    addNameLocal: true,
  },
  {
    name: "teams_list",
    url: "settings/teams",
    addNameLocal: true,
  },
];

export const ViewTeamHeader = ({ team, id }) => {
  const intl = useIntl();
  const history = useHistory();
  const [teamsSnackBar, setTeamsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({
    team_id: null,
    openDeleteModal: false,
  });
  const handleTeamDeletion = (id) => {
    deleteTeam(id);
  };
  const handleCloseDeleteModal = () => {
    if (isLoading) return;
    setStats({
      openDeleteModal: false,
    });
  };
  const handleDeleteUserAccount = () => {
    setStats({
      team_id: id,
      openDeleteModal: true,
    });
  };
  const handleCloseSnackBar = () => {
    setTeamsSnackBar({
      openSnackBar: false,
    });
  };
  const deleteTeam = (teamId) => {
    setIsLoading(true);
    CompaniesController.deleteTeam(teamId).then((res) => {
      if (res?.errorMsg) {
        setTeamsSnackBar({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      } else {
        setTeamsSnackBar({
          message: "team_deleted_successfully",
          severity: "success",
          openSnackBar: true,
        });
        // push to teams list
        history.push("/settings/teams?status=deleted_successfully");
      }
      setIsLoading(false);
      handleCloseDeleteModal();
    });
  };
  return (
    <>
      <Box className="view-team-header">
        <Box className="view-team-header-breadcrumb">
          <BreadCrumb id="breadcrumb-style-monitors" links={links} />
          <PageHeader title={team?.team_name} withBreadCrumb={true} />
        </Box>
        <Box className="view-team-header-description">
          <LucButton
            id="view-team-header-btn"
            variant="flat"
            onClick={handleDeleteUserAccount}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >
            <Typography as="span" className="view-team-header-btn-title">
              {CheckValueLocale("delete", "", {}, intl)}
            </Typography>
          </LucButton>

          <Link
            to={{
              pathname: `/settings/teams/edit/${id}`,
              state: { id: id, from: "teamDetails" },
            }}
            className="edit-team-header-link"
          >
            <LucButton
              variant="filled"
              id="edit-team-header-btn"
              startIcon={<FontAwesomeIcon icon={faPencil} />}
            >
              <Typography as="span" className="edit-team-header-btn-title">
                {CheckValueLocale("edit", "", {}, intl)}
              </Typography>
            </LucButton>
          </Link>
        </Box>
      </Box>
      <Divider />
      <PopupModal
        title={CheckValueLocale("delete_team", "", {}, intl)}
        body={
          <Box className="shared-dialog-body">
            <p>
              {CheckValueLocale(
                "delete_team_body",
                "",
                { team_name: team?.team_name },
                intl,
              )}
            </p>
            <h2 className="sub-text-desc">
              {CheckValueLocale("deleting_msg", "", {}, intl)}
            </h2>
          </Box>
        }
        open={stats.openDeleteModal}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
        close={handleCloseDeleteModal}
        accept={() => handleTeamDeletion(stats?.team_id)}
        warning
        classeName="delete-team-modal actions-buttons-shared"
        disabled={isLoading}
        minWidth="600px"
      />
      <SnackBar
        open={teamsSnackBar?.openSnackBar}
        severity={teamsSnackBar?.severity}
        message={CheckValueLocale(teamsSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(teamsSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};
