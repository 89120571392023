import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import OptionsDropDown from "./optionsDropDown";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Box, Zoom, Tooltip, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import AddNewAccount from "./addNewAccount";
import { getCompanyApps } from "../../../../channelAnalytics/manageChannels/manageChannels.hooks";
import {
  CheckValueLocale,
  getSocialIcon,
  handlelUserRoles,
} from "utils/helpers";
import Snackbar from "components/snackBar";
import moment from "moment";
import SharedTable from "../../../../../shared/table/sharedTable";
import GenesysPopup from "pages/channelAnalytics/manageChannels/addChannel/genesysPopup";
import { sortedColumnTypes } from "hooks/useSortableTable";
import LucButton from "shared/lucButton/lucButton";
import NoDataFoundProducts from "components/no-Data";
import { useHistory } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import Services from "services/api/Services";

export const getCompanySocialAccountsTableHeaders = (intl) => {
  return [
    {
      body: CheckValueLocale("account_identifier", "", {}, intl),
      name: "user_name",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("account_type", "", {}, intl),
      name: "account_type",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("used_in", "", {}, intl),
      name: "used_in",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("configuration_date", "", {}, intl),
      name: "configuration_date",
      type: sortedColumnTypes.date,
    },
    {
      body: CheckValueLocale("created_by", "", {}, intl),
      name: "created_by",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("status", "", {}, intl),
      name: "status",
      type: sortedColumnTypes.string,
    },
    {
      body: null,
      permission: handlelUserRoles("GENERAL", "DESTROY_COMPANY_AUTH"),
    },
  ];
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const CompanySocialAccounts = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const {
    setCompanyAccounts,
    tiktokDataSourceId,
    setOpenSnackBarAllSource,
    setFailSnackBarData,
    isSortableLoading,
    tableHead,
    searchText,
    setSearchText,
    searchInput,
    setSearchInput,
    getCompanyAccounts,
    page,
    setPage,
    isNeedsAttentionData,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [companyApps, setCompanyApps] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [accountAuthenticated, setAccountAuthenticated] = useState(false);
  const [errorMSG, setErrorMSG] = useState("");
  const [openAccountAuthSnakbar, setOpenAccountAuthSnakbar] = useState(false);
  const [openGenesysModal, setOpenGenesysModal] = useState(false);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);
  const [validationError, setValidationError] = useState(false);
  useEffect(() => {
    if (
      handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH") ||
      handlelUserRoles("GENERAL", "VIEW_USER_AUTH")
    ) {
      getCompanyApps(setCompanyApps);
    }
  }, []);

  const handleDialog = () => {
    if (openDialog) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  };
  const handleClick = (type) => {
    setOpenDialog(true);
  };
  const handleCloseSnackBar = () => {
    props?.setOpenSnackBarAllSource(false);
  };

  const changeStatus = (i, status) => {
    const temp = [...props?.companySocialAccounts];
    temp[i].attributes.status = status;
    setCompanyAccounts(temp);
  };

  const getStatusLocalization = (status) => {
    const account_type = {
      active: CheckValueLocale("active", "", {}, intl),
      deactivated: CheckValueLocale("deactivated", "", {}, intl),
      invited: CheckValueLocale("invited", "", {}, intl),
      "Re-authenticate": CheckValueLocale("Re-authenticate", "", {}, intl),
    };
    return account_type[status];
  };

  const stateCell = (account) => {
    return (
      <>
        {getStatusLocalization(account?.attributes?.status) ? (
          <Box className={"status " + account?.attributes?.status}>
            {getStatusLocalization(account?.attributes?.status)}
          </Box>
        ) : null}
      </>
    );
  };

  //timezone
  const getGmtOffsetDate = (date) => {
    const timeZone = +localStorage?.getItem("companyInfo");
    const newDate = moment(date)
      ?.add(timeZone, "hours")
      ?.format("DD/MM/YYYY hh:mm A")
      ?.split(" ");
    const today = moment()?.format("DD/MM/YYYY");

    return `${newDate?.[0] === today ? CheckValueLocale("today_at", "", {}, intl) : `${newDate?.[0]},`} ${newDate?.[1]} ${CheckValueLocale(`hour_${newDate?.[2]}`, "", {}, intl)}`;
  };

  useEffect(() => {
    if (
      window.location.href?.includes(
        "/settings/accounts_management/company_accounts?success=1",
      )
    ) {
      setAccountAuthenticated(true);
      setOpenAccountAuthSnakbar(true);
      history?.push({
        pathname: "/settings/accounts_management/company_accounts",
      });
    } else if (
      window.location.href?.includes(
        "/settings/accounts_management/company_accounts?success=0",
      ) ||
      window.location.href?.includes(
        "/settings/accounts_management/company_accounts?auth=failed",
      )
    ) {
      setAccountAuthenticated(false);
      setOpenAccountAuthSnakbar(true);
      setErrorMSG("authenticate_error_msg");
      history?.push({
        pathname: "/settings/accounts_management/company_accounts",
      });
    } else if (
      window.location.href?.includes(
        "/settings/accounts_management/company_accounts?auth=page_missing",
      )
    ) {
      setErrorMSG("authenticate_error_page_missing_msg");
      setOpenAccountAuthSnakbar(true);
      setAccountAuthenticated(false);
      history?.push({
        pathname: "/settings/accounts_management/company_accounts",
      });
    }
  }, [window.location.href]);

  const handleCloseAuthSnackBar = () => {
    setOpenAccountAuthSnakbar(false);
  };

  const showTooltipText = (text, textLength) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Box component="span">{text?.substring(0, textLength)}...</Box>
          </BootstrapTooltip>
        ) : (
          <Box component="p" variant="subtitle2">
            {text}
          </Box>
        )}
      </Box>
    );
  };

  const optionsCell = (account, index) => {
    return (
      <OptionsDropDown
        index={index}
        status={account?.attributes?.status}
        changeStatus={changeStatus}
        data_source={account?.attributes.account_type?.toLowerCase()}
        accountInfoOwner={account?.attributes?.account_info_owner}
        whatsappDisableReauth={account?.attributes?.disable_reauth}
        whatsappDisableUnauth={account?.attributes?.disable_unauth}
        id={account?.attributes?.id}
        user_id={account?.attributes?.user_id}
        accountIdentifier={account?.attributes?.user_name}
        accountUsedIn={account?.attributes?.used_in}
        companyApps={companyApps}
        accountType={account?.attributes?.account_type}
        setOpenGenesysModal={setOpenGenesysModal}
        setFailSnackBarData={setFailSnackBarData}
        tiktokDataSourceId={tiktokDataSourceId}
        setOpenSnackBarAllSource={setOpenSnackBarAllSource}
      />
    );
  };

  useEffect(() => {
    const tabBody =
      props?.companySocialAccounts?.length == 0
        ? []
        : props?.companySocialAccounts?.map((account, index) => {
            return {
              id: index,
              data: [
                {
                  body: showTooltipText(
                    account?.attributes?.user_name
                      ? account?.attributes?.user_name
                      : "—",
                    30,
                  ),
                },
                {
                  body: (
                    <Box className="account-type-cell">
                      {getSocialIcon(account?.attributes?.account_type)}
                      {account?.attributes?.account_type ? (
                        <Typography
                          className={
                            account?.attributes?.account_type !== "TIKTOK"
                              ? "company-account-type"
                              : ""
                          }
                        >
                          {CheckValueLocale(
                            account?.attributes?.account_type?.toLowerCase(),
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      ) : (
                        "—"
                      )}
                    </Box>
                  ),
                  style: {
                    verticalAlign: "middle",
                  },
                },
                {
                  body:
                    account?.attributes?.used_in +
                    " " +
                    CheckValueLocale("channels_name", "", {}, intl),
                },
                {
                  body: (
                    <Box component="span">
                      {getGmtOffsetDate(
                        account?.attributes?.configuration_date,
                      )}
                    </Box>
                  ),
                },
                {
                  body: account?.attributes?.created_by
                    ? account?.attributes?.created_by
                    : "—",
                  isCreatedBy: true,
                },
                {
                  body: stateCell(account),
                },
                {
                  body: optionsCell(account, index),
                  permission: handlelUserRoles(
                    "GENERAL",
                    "DESTROY_COMPANY_AUTH",
                  ),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(props?.companySocialAccounts));
  }, [props?.companySocialAccounts, intl?.locale, companyApps]);

  const emptyBody = (audienceData) => {
    return (
      <>
        {props?.companySocialAccounts?.length == 0 ? (
          <Box className="no-data-company-info">
            <NoDataFoundProducts
              image={isNeedsAttentionData ? "noDataImg" : "companyInfo"}
              title={
                isNeedsAttentionData
                  ? "account_not_found"
                  : "create_authenticated_account"
              }
              description={
                isNeedsAttentionData
                  ? "company_account_not_found_desc"
                  : "create_authenticated_account_dec"
              }
              linkTwo={
                isNeedsAttentionData
                  ? ""
                  : Services.showThirdCompany
                    ? "knowledge_base"
                    : ""
              }
              needsAttentionUserName={
                history?.location?.needsAttentionData?.search_account_name
                  ? history?.location?.needsAttentionData?.search_account_name
                  : history?.location?.needsAttentionData?.search
              }
            />
          </Box>
        ) : null}
      </>
    );
  };

  // input search
  const searchIconOnClick = () => {
    if (!validationError) {
      // Set redirected_monitor from history of needs attention click with NULL
      history?.push({ ...history?.location, needsAttentionData: null });
      setSearchInput(searchText);
      if (page !== 1) setPage(1);
    }
  };

  const handleEnterKeyPress = (e) => {
    if (e?.key === "Enter" && !validationError) {
      // Set redirected_monitor from history of needs attention click with NULL
      history?.push({ ...history?.location, needsAttentionData: null });
      setSearchInput(searchText);
      e.target.blur();
      if (page !== 1) setPage(1);
    }
  };

  return (
    <Box id="company-accounts-container">
      <Box
        className="tab-header"
        direction="row"
        justifyContent="space-between"
      >
        <Typography className="description">
          {CheckValueLocale("company_accounts_auth_statement", "", {}, intl)}
        </Typography>
        {handlelUserRoles("GENERAL", "CREATE_COMPANY_AUTH") && (
          <LucButton
            id="settings-new-company-account-btn"
            startIcon={<FontAwesomeIcon size="small" icon={faAdd} />}
            onClick={handleClick}
          >
            {CheckValueLocale("company_account", "", {}, intl)}
          </LucButton>
        )}
      </Box>
      <>
        <Box className="search-desblay">
          {props?.companySocialAccounts?.length > 0 || searchInput ? (
            <Box className="search-company">
              <Box className="search-box company-accounts">
                <TextField
                  className="search-field"
                  label={CheckValueLocale(
                    "search_by_account_identifier",
                    "",
                    {},
                    intl,
                  )}
                  variant="outlined"
                  value={searchText}
                  onChange={(e) => {
                    const invalidText = isPublicSpecialCharactersOrEmojis(
                      e?.target?.value,
                      undefined,
                      true,
                    );
                    setValidationError(invalidText);
                    setSearchText(e.target.value);
                  }}
                  onBlur={() => setSearchBoxClicked(false)}
                  onFocus={() => setSearchBoxClicked(true)}
                  onKeyPress={handleEnterKeyPress}
                  id="company-accounts-search-field"
                />
                <Box
                  className={
                    "search-icon-container " +
                    (searchBoxClicked ? "search-icon-container-blue" : "")
                  }
                  onClick={() => searchIconOnClick()}
                  id="social-media-search-by-name-icon-box"
                >
                  <FontAwesomeIcon
                    className="search-icon"
                    icon={faSearch}
                    id="social-media-search-by-name-icon"
                  />
                </Box>
              </Box>
              <Box
                variant="p"
                className="search-input-validation-error manage-channels"
              >
                {validationError
                  ? CheckValueLocale("spam_keywords_rules", "", {}, intl)
                  : null}
              </Box>
            </Box>
          ) : null}
        </Box>

        <SharedTable
          tableHeader={tableHead}
          className={" "}
          isSharedStyle
          tableAriaLabel="customized table"
          tableBody={tableBody}
          loading={isSortableLoading}
          emptyBody={tableEmptyBody}
        />
      </>
      {openDialog ? (
        <AddNewAccount
          open={openDialog}
          handleDialog={handleDialog}
          companySocialAccounts={props?.companySocialAccounts}
          setOpenGenesysModal={setOpenGenesysModal}
          tiktokDataSourceId={tiktokDataSourceId}
          setOpenSnackBarAllSource={setOpenSnackBarAllSource}
          setFailSnackBarData={setFailSnackBarData}
          // whatsappDisableReauth={account?.attributes?.disable_reauth}
          // whatsappDisableUnauth={account?.attributes?.disable_unauth}
        />
      ) : null}

      <Snackbar
        open={openAccountAuthSnakbar}
        severity={accountAuthenticated ? "success" : "error"}
        title={
          errorMSG === "authenticate_error_page_missing_msg"
            ? CheckValueLocale(
                "authenticate_error_page_missing_titel",
                "",
                {},
                intl,
              )
            : null
        }
        message={
          accountAuthenticated
            ? CheckValueLocale("authenticate_success_msg", "", {}, intl)
            : CheckValueLocale(errorMSG, "", {}, intl)
        }
        handleClose={handleCloseAuthSnackBar}
      />
      {props?.openSnackBarAllSource ? (
        <Snackbar
          open={props?.openSnackBarAllSource}
          severity={props?.failSnackBarData?.severity}
          message={CheckValueLocale(
            props?.failSnackBarData?.message,
            "",
            {},
            intl,
          )}
          title={CheckValueLocale(props?.failSnackBarData?.title, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      ) : null}

      {openGenesysModal ? (
        <GenesysPopup
          openGenesysModal={openGenesysModal}
          setOpenGenesysModal={setOpenGenesysModal}
          genesysDataSourceId={props?.genesysDataSourceId}
          setFailSnackBarData={props?.setFailSnackBarData}
          setOpenSnackBarAllSource={props?.setOpenSnackBarAllSource}
        />
      ) : null}
    </Box>
  );
};

const Label = styled(Box)`
  padding: 7px 17px;
  border-radius: 4px;
  text-align: center;
  width: 120px;
  max-height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;

export default CompanySocialAccounts;
