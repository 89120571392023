import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";

import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import QuestionCard from "pages/Monitors/Components/questionCard";

import "../../../monitors.scss";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import PieChartWidget from "components/widgets/pieChartWidget";

import PunchCardWidget from "components/widgets/punchCardWidget";
import { translateLocalsFromRabbitMQ } from "../../../../../utils/helpers/index";

const AuthorsAnalyticsSection = (props) => {
  const intl = useIntl();
  const { monitorType, monitorDataSource, monitorId } = useParams();
  let {
    response,
    engagersVolume,
    setEngagersVolume,
    totalEngagersVolumeCount,
    setTotalEngagersVolumeCount,
    engagersVolumePreLoader,
    setEngagersVolumePreLoader,
    genderDistribution,
    setGenderDistribution,
    genderDistributionPreLoader,
    setGenderDistributionPreLoader,
    topLanguages,
    setTopLanguages,
    topLanguagesPreLoader,
    setTopLanguagesPreLoader,
    dialects,
    setDialects,
    subDialects,
    setSubDialects,
    dialectsPreLoader,
    setDialectsPreLoader,
    topCities,
    setTopCities,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCountries,
    setTopCountries,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    accountAuthorActivity,
    setAccountAuthorActivity,
    accountAuthorActivityPreLoader,
    setAccountAuthorActivityPreLoader,
    topPosts,
    setTopPosts,
    topPostsCount,
    setTopPostsCount,
    topPostsPreLoader,
    setTopPostsPreLoader,
  } = props;

  const monitorDetails = props.monitorData;

  const [genderDistributionPieChartValue, setGenderDistributionPieChartValue] =
    useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);
  // ___________________________________________________________

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__EngagementsPage__engagers_volume":
        setEngagersVolume(
          response.eventData.engagers_volume.engagers_volume_over_time,
        );
        setTotalEngagersVolumeCount(
          response.eventData.engagers_volume.total_engagers,
        );
        setEngagersVolumePreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__gender_distribution":
        if (response.eventData.gender_distribution) {
          response.eventData.gender_distribution.map((gd) => {
            Object.keys(gd).map((val, index) => {
              setGenderDistribution((oldArray) => [
                ...oldArray,
                {
                  name: val,
                  value: Object.values(gd)[index],
                },
              ]);
            });
          });
        }
        setGenderDistributionPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        setGenderDistributionPieChartValue(response.eventData.gender_piechart);
        break;

      case "Facebook__EngagementsPage__top_languages":
        setTopLanguages(
          translateLocalsFromRabbitMQ(
            "Facebook__EngagementsPage__top_languages",
            response.eventData.top_languages,
          ),
        );

        setTopLanguagesPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__dialects_and_sub_dialects":
        setDialects(response.eventData.dialects);
        setSubDialects(response.eventData.sub_dialects);
        setDialectsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;

      case "Facebook__EngagementsPage__top_cities":
        setTopCities(response.eventData.top_cities);
        setTopCitiesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Facebook__EngagementsPage__top_countries":
        setTopCountries(response.eventData.top_countries);
        setTopCountriesPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__account_author_activity":
        setMinValueAuthorActivity(response.eventData.min_value);
        setMaxValueAuthorActivity(response.eventData.max_value);
        setAccountAuthorActivity(response.eventData.account_author_activity);
        setAccountAuthorActivityPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__top_posts_comments":
        setTopPosts(response.eventData.top_posts);
        setTopPostsCount(response.eventData.top_posts_count);
        setTopPostsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setPostsAnalyticsData([
      { engagersVolume: engagersVolume },
      { genderDistribution: genderDistribution },
      { topLanguages: topLanguages },
      { dialects: dialects },
      { subDialects: subDialects },
      { topCountries: topCountries },
      { topCities: topCities },
      {
        accountAuthorActivity: [
          accountAuthorActivity,
          minValueAuthorActivity,
          maxValueAuthorActivity,
        ],
      },
      { topPosts: topPosts },
    ]);
  }, [
    engagersVolume,
    genderDistribution,
    topLanguages,
    dialects,
    subDialects,
    topCountries,
    topCities,
    accountAuthorActivity,
    topPosts,
  ]);
  // ________________________________________________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setEngagersVolume([]);
      setTotalEngagersVolumeCount([]);
      setEngagersVolumePreLoader([]);

      setGenderDistribution([]);
      setGenderDistributionPreLoader([]);
      setGenderDistributionPieChartValue([]);

      setTopLanguages([]);
      setTopLanguagesPreLoader([]);
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);
      setTopCities([]);
      setTopCitiesPreLoader([]);
      setTopCountries([]);
      setTopCountriesPreLoader([]);

      setAccountAuthorActivity([]);
      setAccountAuthorActivityPreLoader([]);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);

      setTopPosts([]);
      setTopPostsCount(0);
      setTopPostsPreLoader([]);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        <LineChartWidget
          title={"engagers_volume"}
          titleToolTip={"engagers_volume_fb_tooltip"}
          totalLabel={"engagers"}
          totalValue={totalEngagersVolumeCount}
          data={engagersVolume}
          showGroupBy={true}
          toolTipLabel={"engagers"}
          showDownloadIcon
          bigImg
          hideLegend
          lineChartPreLoaderDataSources={engagersVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.FacebookIcon}
          labelColor={"#2AAAE2"}
        />
      </Box>
      <Box my={3}>
        <StackedBarWidget
          title={"gender_age_dist"}
          titleToolTip={"gender_distribution_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={genderDistribution}
          labels={["male", "female"]}
          hideLegend
          dataPiechart={genderDistributionPieChartValue}
          colors={["#2AAAE2", "#FF4081"]}
          showZoomPercentage
          showScales
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={genderDistributionPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showReportIcon
          reachFunnel
          isGenderAgeSorted
        />
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_languages"}
              titleToolTip={"top_languages_fb_tooltip"}
              data={topLanguages}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={topLanguagesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.FacebookIcon}
              showReportIcon
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip={"dialects_subdialects_tooltip"}
              data={dialects}
              subData={subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              iconAfterTitle={props.FacebookIcon}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={dialectsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_countries"}
              titleToolTip={"top_countries_fb_tooltip"}
              data={topCountries}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
              pieChartPreLoaderDataSources={topCountriesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.FacebookIcon}
              showReportIcon
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_cities"}
              titleToolTip={"top_cities_fb_tooltip"}
              data={topCities}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={topCitiesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.FacebookIcon}
              showReportIcon
            />
          </Grid>
        </Grid>
      </Box>
      <Box my={3}>
        <PunchCardWidget
          title="engagers_activity"
          titleToolTip={"engagers_activity_fb_tooltip"}
          clientActivity={accountAuthorActivity}
          minValueClient={minValueAuthorActivity}
          maxValueClient={maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.FacebookIcon}
          authorActivityPreLoaderDataSources={accountAuthorActivityPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      <Box my={3}>
        <Box className="top-posts-head">
          <Box>{CheckValueLocale("top_comments_mentions", "", {}, intl)}</Box>
          <Link
            to={{
              pathname: `/monitor/${monitorType}/${monitorDataSource}/comments_mentions/${monitorId}`,
              state: monitorDetails,
            }}
            className="top-posts-all"
            id={`check-comments-mentions-${
              monitorType?.toLowerCase() ?? "monitor-type"
            }-${
              monitorDataSource?.toLowerCase() ?? "monitor-data-source"
            }-comments-mentions-${monitorId ?? "monitor-id"}`}
          >
            {CheckValueLocale("check_all_comments_mentions", "", {}, intl)} (
            {topPostsCount})
          </Link>
        </Box>
        <QuestionCard
          unixDate={props.unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={topPosts.slice(0, 6)}
          latestUnanswerPreloader={topPostsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faFacebook}
          iconColor={"facebook_icon"}
          showHeart={true}
          showDefaultImg
          instagramAccount
          className={"no-data-found-question"}
          hideChartBar // hide more insights for now
        />
      </Box>
    </>
  );
};

export default AuthorsAnalyticsSection;
