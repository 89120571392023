import { Box, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const CopyIcon = (props) => {
  const intl = useIntl();
  const { handleData, checkCopyValue } = props;

  return (
    <>
      <Tooltip
        title={
          checkCopyValue ? (
            <Box component="span" className="tooltipIcon">
              {CheckValueLocale("copied", "", {}, intl)}
            </Box>
          ) : (
            <Box component="span" className="tooltipIcon">
              {CheckValueLocale("click_to_copy", "", {}, intl)}
            </Box>
          )
        }
        placement="top"
        arrow
      >
        <ContentCopyIcon className="copy-icons cpy-icon" onClick={handleData} />
      </Tooltip>
    </>
  );
};

export default CopyIcon;
