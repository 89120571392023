import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";

const NewUserAccount = (props) => {
  const { dialogOpen, setOpenDialog, handleAccountAuth } = props;
  const intl = useIntl();

  const [accountType, setAccountType] = useState("");

  const handleClose = () => setOpenDialog(false);
  const handleClickNext = () => {
    handleAccountAuth(accountType);
  };

  const accountTypes = [
    { title: "facebook", value: "FACEBOOK" },
    { title: "instagram", value: "INSTAGRAM" },
  ];

  const handleChangeType = (e) => setAccountType(e.target.value);

  useEffect(() => {
    setAccountType("");
  }, [dialogOpen]);

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <Box id="settings-new-account-dialog">
        <DialogTitle className="dialog-title">
          {CheckValueLocale("add_user_account", "", {}, intl)}
        </DialogTitle>
        <DialogContent>
          <Typography className="description">
            {CheckValueLocale("add_user_account_dialog_desc", "", {}, intl)}
          </Typography>
          <Typography className="select-title">
            {CheckValueLocale("account_type", "", {}, intl)}:
            <Box component={"span"} className="required">
              *
            </Box>
          </Typography>
          <FormControl fullWidth>
            <Select
              value={accountType}
              onChange={handleChangeType}
              size="small"
              displayEmpty
              renderValue={
                accountType !== ""
                  ? undefined
                  : () =>
                      CheckValueLocale(
                        "settings_choose_account_type",
                        "",
                        {},
                        intl,
                      )
              }
            >
              {accountTypes?.map((type, index) => {
                return (
                  <MenuItem
                    id={`settings-new-user-account-${type?.title}-item`}
                    value={type?.value}
                    key={index}
                  >
                    <Box className="new-account-icons">
                      {getSocialIcon(type?.value)}
                      <Box className={`icons-account-text ${type?.title}`}>
                        {CheckValueLocale(type?.title, "", {}, intl)}
                      </Box>
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <LucButton
            id="settings-new-user-account-cancel-btn"
            variant="flat"
            type="secondary"
            onClick={handleClose}
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            id="settings-new-user-account-next-btn"
            onClick={handleClickNext}
            disabled={accountType === ""}
          >
            {CheckValueLocale("next", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default NewUserAccount;
