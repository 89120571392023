import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Card, CardContent, Input, Typography } from "@mui/material";
import styles from "../commonStyle.module.css";

const SocialMediaQuestion = (props) => {
  const { question, questionNo } = props;

  const intl = useIntl();
  const data = question;
  const [editable, setEditable] = useState(false);
  const [answers, setAnswers] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    tiktok: "",
    snapchat: "",
  });

  useEffect(() => {
    if (question) {
      setEditable(question?.question?.editable);
    }
  }, [question]);

  const handleSocialAccount = (e, pf) =>
    setAnswers({ ...answers, [pf]: e.target.value });

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {data?.question?.text}
          {data?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{data?.question?.description}</Box>
        {data?.question?.constraints?.platforms?.map((pf) => {
          const pfImg = pf === "twitter" ? "x-platform" : pf;
          return (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`/survey_builder_icons/${pfImg}.png`}
                alt="logo"
                className={`${styles.imgStyle} ${styles.socialMediaIcons}`}
              />
              <Input
                id="standard-basic"
                placeholder={
                  pf === "twitter"
                    ? "@user_name"
                    : CheckValueLocale(
                        "write_your_username_or_profile_link",
                        "",
                        {},
                        intl,
                      )
                }
                variant="standard"
                className={`${styles.textFieldStyle} ${styles.widthClass}`}
                value={answers[pf]}
                onChange={(e) => handleSocialAccount(e, pf)}
                style={{ width: "56%", margin: "0px 10px" }}
              />
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default SocialMediaQuestion;
