import { Box, OutlinedInput } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const LiveDashBoardFields = ({
  liveDashboardName,
  handleInputChange,
  liveDashboardNameError,
}) => {
  const intl = useIntl();

  return (
    <Box className="dashboard-group-wrapper">
      <Box className="create-live-dashboard-group">
        <Box component="p" className="create-live-dashboard-label">
          {CheckValueLocale("dashboard_name", "", {}, intl)}
          <Box component="span" className="dashboard-star">
            *
          </Box>
        </Box>
        <OutlinedInput
          variant="outlined"
          className="social-media-field"
          value={liveDashboardName}
          onChange={(e) => handleInputChange(e)}
        />
        <Box component="p" className="create-live-dashboard-error">
          {CheckValueLocale(liveDashboardNameError, "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );
};

export default LiveDashBoardFields;
