import Explore from "../api/explore.js";

var ExploreController = {};

ExploreController.getExploreData = () => {
  return new Promise(function (resolve) {
    Explore.getExploreData()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ExploreController.createExplore = (queryData) => {
  return new Promise(function (resolve) {
    Explore.createExplore({
      ...queryData,
    })
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ExploreController.getExploreSavedList = (page) => {
  let queryData = {
    page,
  };
  return new Promise(function (resolve) {
    Explore.getExploreSavedList(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ExploreController.deleteExploreSavedResult = (dashboardId) => {
  return new Promise(function (resolve) {
    Explore.deleteExploreSavedResult(dashboardId)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ExploreController.getExploreLatestSearch = (page) => {
  let queryData = {
    page,
  };
  return new Promise(function (resolve) {
    Explore.getExploreLatestSearch(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ExploreController.showExplore = (id, page_number, sort_by, filters) => {
  const queryData = {
    page_number,
    sort_by,
    ...filters,
  };

  return new Promise(function (resolve) {
    Explore.showExplore(id, queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ExploreController.saveExploreResults = (id, name) => {
  let queryData = { id, name };
  return new Promise(function (resolve) {
    Explore.saveExploreResults(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          status: error?.response?.status,
        });
      });
  });
};

ExploreController.getFiltersFields = (exploreId) => {
  return new Promise(function (resolve) {
    Explore.getFiltersFields(exploreId)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          status: error?.response?.status,
          errorMSg: error,
        });
      });
  });
};

export default ExploreController;
