import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, Card, CardContent, Input, MenuItem, Select } from "@mui/material";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import "../../surveyBuilder.scss";

const YesNoQuestion = (props) => {
  const {
    filteredSections,
    rootQuestion,
    question,
    questionIndex,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    handleCardClick,
    handleChangeProceed,
    proceedError,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState({});
  const [proceedSelects, setProceedSelects] = useState([]);

  const MenuProps = { PaperProps: { style: { maxHeight: 265 } } };

  useEffect(() => {
    if (question) {
      setValue({ title: question?.text, description: question?.description });
      setEditable(question?.editable);
    }
  }, [question]);

  const optionNo = [
    CheckValueLocale("a", "", {}, intl),
    CheckValueLocale("b", "", {}, intl),
  ];

  useEffect(() => {
    if (filteredSections) {
      let selectsArray = [];
      filteredSections?.map((sec, index) => {
        if (index !== sectionIndex && index !== sectionIndex + 1)
          selectsArray?.push(sec);
      });
      setProceedSelects(selectsArray);
    }
  }, [filteredSections?.length]);

  return (
    <Card
      className={`cardStyle ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        "noError"
      } ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        proceedError === questionIndex &&
        "error"
      }`}
      onClick={() => handleCardClick(sectionIndex, questionIndex)}
    >
      <CardContent className="card-body title-question card-direction-container">
        <Box className="question-title-container">
          <Box component="span" className="questionNoStyle">
            {questionIndex + 1}.
          </Box>
          <Input
            fullWidth
            multiline
            className="questionStylee"
            style={{
              direction: checkKeyboardLanguage(value?.title) ? "rtl" : "ltr",
            }}
            id={questionIndex}
            value={value?.title}
            onChange={(e) => {
              handleQuestionTitle(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            name="title"
            placeholder={CheckValueLocale("question_title", "", {}, intl)}
            disableUnderline={true}
          />
        </Box>
        <Input
          id={questionIndex}
          variant="outlined"
          className="subtitle"
          onChange={(e) => {
            handleQuestionDesc(e, sectionIndex, questionIndex, value, setValue);
          }}
          style={{
            direction: checkKeyboardLanguage(value?.description)
              ? "rtl"
              : "ltr",
          }}
          name="subtitle"
          placeholder={CheckValueLocale("question_description", "", {}, intl)}
          disableUnderline={true}
          value={value?.description}
        />
        {question?.items?.map((val, index) => {
          return (
            <Box className="proceed-choice">
              <Box className="yes-no-option-container">
                <Box className="yes-no-options unSelected" key={index}>
                  <Box>{`${optionNo[index]}-`}</Box>
                  <Box className="yesNoValue">
                    {CheckValueLocale(val[index + 1], "", {}, intl)}
                  </Box>
                </Box>
              </Box>
              {rootQuestion?.is_proceed_on_answer && (
                <Select
                  id={`yesno-next-section-select-${sectionIndex}`}
                  className="next-section-select"
                  variant="standard"
                  MenuProps={MenuProps}
                  fullWidth
                  value={val?.next_section}
                  onChange={(e) =>
                    handleChangeProceed(e, sectionIndex, questionIndex, index)
                  }
                >
                  {sectionIndex !== filteredSections?.length - 1 && (
                    <MenuItem value={filteredSections[sectionIndex]?.order + 1}>
                      {`${CheckValueLocale(
                        "continue_next_section",
                        "",
                        {},
                        intl,
                      )} (${filteredSections[sectionIndex + 1]?.name})`}
                    </MenuItem>
                  )}
                  <MenuItem value={-1}>
                    {CheckValueLocale("submit_survey", "", {}, intl)}
                  </MenuItem>
                  {proceedSelects?.map((section, i) => {
                    return (
                      <MenuItem key={i} value={section?.order}>
                        {`${CheckValueLocale(
                          "go_to_section",
                          "",
                          {},
                          intl,
                        )} ${section?.order} (${section?.name})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default YesNoQuestion;
