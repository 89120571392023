import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { BootstrapDialog, BootstrapDialogTitle } from "loginPageComponents";
import { ChangeEvent, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

type Props = {
  readonly open: boolean;
  readonly onClose: VoidFunction;
  readonly onAccept: (param: any) => void;
};

export const AddAccountDialog: React.FC<Props> = ({
  open,
  onClose,
  onAccept,
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Lucidya Privacy Policy
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </Typography>
        <Typography gutterBottom>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </Typography>
        <Typography gutterBottom>
          Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button autoFocus onClick={onAccept} variant="contained">
          Accept
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export const InterComDialog: React.FC<Props> = ({
  open,
  onClose,
  onAccept,
}) => {
  const intl = useIntl();
  const [intercomValue, setIntercomValue] = useState("");
  const [intercomToken, setIntercomToken] = useState("");
  const handleField =
    (field: "account" | "token") => (event: ChangeEvent<HTMLInputElement>) => {
      if (field === "account") return setIntercomValue(event?.target?.value);

      if (field === "token") return setIntercomToken(event?.target?.value);
    };
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {CheckValueLocale("new_intercom_account", "", {}, intl)}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {CheckValueLocale("new_intercom_account_def", "", {}, intl)}
        </Typography>
        <Box my={2} />
        <TextField
          label={CheckValueLocale("account_name", "", {}, intl)}
          fullWidth
          value={intercomValue}
          onChange={handleField("account")}
        />
        <Box my={2} />
        <TextField
          label="Access Token"
          fullWidth
          value={intercomToken}
          onChange={handleField("token")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {CheckValueLocale("cancel", "", {}, intl)}
        </Button>
        <Button
          autoFocus
          onClick={() =>
            onAccept({
              token_secret: intercomToken,
              account_name: intercomValue,
            })
          }
          variant="contained"
        >
          {CheckValueLocale("add_account", "", {}, intl)}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
