import React, { useEffect, useState } from "react";
import { isEmptyArray, isEmptyObj } from "utils/helpers";
import OverViewSection from "./overviewSection";
import PostsAnalyticsSection from "./postAnalytics";

const AccountsBody = (props) => {
  const [postsAnalyticsData, setPostsAnalyticsData] = useState([]);
  const [overViewData, setOverViewData] = useState([]);

  const [pageViews, setPageViews] = useState([]);
  const [pageLikeUnlike, setPageLikeUnlike] = useState([]);
  const [pageLikeUnlikePreLoader, setPageLikeUnlikePreLoader] = useState([]);
  const [pageLikeUnlikeLegend, setPageLikeUnlikeLegend] = useState([]);
  const [pageReachImpression, setPageReachImpression] = useState([]);
  const [pageReachImpressionPreLoader, setPageReachImpressionPreLoader] =
    useState([]);
  const [pageReachImpressionLegend, setPageReachImpressionLegend] = useState(
    [],
  );
  const [reachArray, setReachArray] = useState([]);
  const [impressionArray, setImpressionArray] = useState([]);
  const [pageContactDetailsInteractions, setPageContactDetailsInteractions] =
    useState([]);
  const [
    pageContactDetailsInteractionsPreLoader,
    setPageContactDetailsInteractionsPreLoader,
  ] = useState([]);
  const [pageContactDetailsLegend, setPageContactDetailsLegend] = useState([]);
  const [pageActions, setPageActions] = useState([]);
  const [pageActionsPreLoader, setPageActionsPreLoader] = useState([]);
  const [pageActionsLegend, setPageActionsLegend] = useState([]);
  const [accountAuthorActivity, setAccountAuthorActivity] = useState([]);
  const [accountAuthorActivityPreLoader, setAccountAuthorActivityPreLoader] =
    useState([]);
  const [pageViewsPreLoader, setPageViewsPreLoader] = useState([]);

  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [postsVolumePreLoader, setPostsVolumePreLoader] = useState([]);
  const [videosViews, setVideosViews] = useState([]);
  const [totalVideosViewsCount, setTotalVideosViewsCount] = useState([]);
  const [videosViewsPreLoader, setVideosViewsPreLoader] = useState([]);
  const [postReachImpression, setPostReachImpression] = useState([]);
  const [totalPostReachImpression, setTotalPostReachImpression] = useState([]);
  const [postReachImpressionPreLoader, setPostReachImpressionPreLoader] =
    useState([]);
  const [reachAnalyticsArray, setReachAnalyticsArray] = useState([]);
  const [impressionAnalyticsArray, setImpressionAnalyticsArray] = useState([]);

  // ___________________________________________________________

  const [postsInteractions, setPostsInteractions] = useState([]);
  const [postsInteractionsPreLoader, setPostsInteractionsPreLoader] = useState(
    [],
  );
  const [postsInteractionsLegend, setPostsInteractionsLegend] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [reactionsPreLoader, setReactionsPreLoader] = useState([]);
  const [postsContentType, setPostsContentType] = useState([]);
  const [postsContentTypePreLoader, setPostsContentTypePreLoader] = useState(
    [],
  );
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsPreLoader, setTopPostsPreLoader] = useState([]);
  const [topVideos, setTopVideos] = useState([]);
  const [topVideosPreLoader, setTopVideosPreLoader] = useState([]);
  const [topReels, setTopReels] = useState([]);
  const [topReelsPreLoader, setTopReelsPreLoader] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topImagesPreLoader, setTopImagesPreLoader] = useState([]);

  const childProps = {
    pageViewsPreLoader,
    setPageViewsPreLoader,
    accountAuthorActivityPreLoader,
    setAccountAuthorActivityPreLoader,
    accountAuthorActivity,
    setAccountAuthorActivity,
    pageActionsLegend,
    setPageActionsLegend,
    pageActionsPreLoader,
    setPageActionsPreLoader,
    pageActions,
    setPageActions,
    pageContactDetailsLegend,
    setPageContactDetailsLegend,
    pageContactDetailsInteractionsPreLoader,
    setPageContactDetailsInteractionsPreLoader,
    pageContactDetailsInteractions,
    setPageContactDetailsInteractions,
    impressionArray,
    setImpressionArray,
    reachArray,
    setReachArray,
    pageReachImpressionLegend,
    setPageReachImpressionLegend,
    pageReachImpressionPreLoader,
    setPageReachImpressionPreLoader,
    pageReachImpression,
    setPageReachImpression,
    pageLikeUnlikeLegend,
    setPageLikeUnlikeLegend,
    pageLikeUnlikePreLoader,
    setPageLikeUnlikePreLoader,
    pageLikeUnlike,
    setPageLikeUnlike,
    pageViews,
    setPageViews,
    reachAnalyticsArray,
    setReachAnalyticsArray,
    impressionAnalyticsArray,
    setImpressionAnalyticsArray,
    postsVolumePreLoader,
    setPostsVolumePreLoader,
    totalPostsVolumeCount,
    setTotalPostsVolumeCount,
    postsVolume,
    setPostsVolume,
    setVideosViewsPreLoader,
    totalVideosViewsCount,
    setTotalVideosViewsCount,
    topImages,
    setTopImages,
    topImagesPreLoader,
    setTopImagesPreLoader,
    topVideosPreLoader,
    setTopVideosPreLoader,
    topVideos,
    setTopVideos,
    topReelsPreLoader,
    setTopReelsPreLoader,
    topReels,
    setTopReels,
    topPostsPreLoader,
    setTopPostsPreLoader,
    topHashtagsPreLoader,
    topPosts,
    setTopPosts,
    setTopHashtagsPreLoader,
    topHashtags,
    setTopHashtags,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topKeywords,
    setTopKeywords,
    postsContentTypePreLoader,
    setPostsContentTypePreLoader,
    postsContentType,
    setPostsContentType,
    reactionsPreLoader,
    setReactionsPreLoader,
    reactions,
    setReactions,
    postsInteractionsLegend,
    setPostsInteractionsLegend,
    postsInteractionsPreLoader,
    setPostsInteractionsPreLoader,
    postsInteractions,
    setPostsInteractions,
    postReachImpressionPreLoader,
    setPostReachImpressionPreLoader,
    totalPostReachImpression,
    setTotalPostReachImpression,
    postReachImpression,
    setPostReachImpression,
    videosViewsPreLoader,
    videosViews,
    setVideosViews,
  };

  useEffect(() => {
    if (!isEmptyArray(postsAnalyticsData) && !isEmptyArray(overViewData)) {
      props.setExcelSheetData(overViewData.concat(postsAnalyticsData));
    }
  }, [overViewData, postsAnalyticsData]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      accountAuthorActivityPreLoader,
      pageViewsPreLoader,
      postsVolumePreLoader,
      postReachImpressionPreLoader,
      topImagesPreLoader,
      topVideosPreLoader,
      topReelsPreLoader,
      topPostsPreLoader,
      topHashtagsPreLoader,
      topKeywordsPreLoader,
      postsContentTypePreLoader,
      reactionsPreLoader,
      postsInteractionsPreLoader,
      videosViewsPreLoader,
      pageActionsPreLoader,
      pageContactDetailsInteractionsPreLoader,
      pageReachImpressionPreLoader,
      pageLikeUnlikePreLoader,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      accountAuthorActivity,
      totalPostsVolumeCount,
      topImages,
      topVideos,
      topReels,
      topPosts,
      topHashtags,
      topKeywords,
      postsContentType,
      reactions,
      postsInteractionsLegend,
      postsInteractions,
      impressionAnalyticsArray,
      reachAnalyticsArray,
      totalPostReachImpression,
      postReachImpression,
      totalVideosViewsCount,
      videosViews,
      postsVolume,
      totalPostsVolumeCount,
      pageActionsLegend,
      pageActions,
      pageContactDetailsLegend,
      pageContactDetailsInteractions,
      impressionArray,
      reachArray,
      pageReachImpressionLegend,
      pageReachImpression,
      pageLikeUnlikeLegend,
      pageLikeUnlike,
      pageViews,
    );
  }, [
    accountAuthorActivity,
    totalPostsVolumeCount,
    topImages,
    topVideos,
    topReels,
    topPosts,
    topHashtags,
    topKeywords,
    postsContentType,
    reactions,
    postsInteractionsLegend,
    postsInteractions,
    impressionAnalyticsArray,
    reachAnalyticsArray,
    totalPostReachImpression,
    postReachImpression,
    totalVideosViewsCount,
    videosViews,
    postsVolume,
    totalPostsVolumeCount,
    pageActionsLegend,
    pageActions,
    pageContactDetailsLegend,
    pageContactDetailsInteractions,
    impressionArray,
    reachArray,
    pageReachImpressionLegend,
    pageReachImpression,
    pageLikeUnlikeLegend,
    pageLikeUnlike,
    pageViews,
    accountAuthorActivityPreLoader,
    pageViewsPreLoader,
    postsVolumePreLoader,
    postReachImpressionPreLoader,
    topImagesPreLoader,
    topVideosPreLoader,
    topReelsPreLoader,
    topPostsPreLoader,
    topHashtagsPreLoader,
    topKeywordsPreLoader,
    postsContentTypePreLoader,
    reactionsPreLoader,
    postsInteractionsPreLoader,
    videosViewsPreLoader,
    pageActionsPreLoader,
    pageContactDetailsInteractionsPreLoader,
    pageReachImpressionPreLoader,
    pageLikeUnlikePreLoader,
  ]);
  return (
    <>
      <OverViewSection
        setOverViewData={setOverViewData}
        {...props}
        {...childProps}
      />
      <PostsAnalyticsSection
        setPostsAnalyticsData={setPostsAnalyticsData}
        {...props}
        {...childProps}
      />
    </>
  );
};

export default AccountsBody;
