import { Box, Button, Typography, Zoom } from "@mui/material";
import "../manageChannels.scss";
import { BootstrapTooltip } from "./BootstrapTooltip";

export const TooltipText = ({ text, textLength }) => {
  return (
    <Box className="report-name-container">
      {text?.length > textLength ? (
        <BootstrapTooltip
          title={text}
          arrow
          TransitionComponent={Zoom}
          placement="top"
        >
          <Button
            variant="text"
            sx={{
              padding: 0,
              margin: 0,
              borderRadius: 0,
            }}
            id="report-create-new-show-report-text-btn"
          >
            {text?.substring(0, textLength)}...
          </Button>
        </BootstrapTooltip>
      ) : (
        <Typography variant="subtitle2">{text}</Typography>
      )}
    </Box>
  );
};
