import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { useIntl } from "react-intl";
import "../tableWidget/tableWidget.scss";
import WidgetHeader from "../widgetHeader";
import { Box } from "@mui/system";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import NoDataFound from "components/no-Data/noDataFound";
import "./tableWidgetAnalysis.scss";
import WordsList from "./wordsList";

const TableWidgetAnalysis = (props) => {
  const intl = useIntl();
  const {
    firstColumnTitle,
    secondColumnTitle,
    thirdColumnTitle,
    secondColumnTitleColor,
    thirdColumnTitleColor,
    firstColumnData,
    secondColumnData,
  } = props;

  return (
    <div className={"table-chart-content"} id={props?.title}>
      <WidgetHeader
        title={props.title}
        showDownloadIcon={props.showDownloadIcon}
        chartId={props.title}
        data={props.data}
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
      />
      {isEmptyArray(props?.data) ? (
        <NoDataFound />
      ) : (
        <>
          <TableContainer>
            <Table className="table-widget-genesys-wrapper">
              <TableHead>
                <TableRow className="table-widget-header-wrapper">
                  <TableCell
                    className={"table-cell-item-header table-cell-item-head"}
                  >
                    <Box className="table-head-title-wrapper">
                      {CheckValueLocale(firstColumnTitle, "", {}, intl)}
                    </Box>
                  </TableCell>
                  <TableCell
                    className={
                      "table-cell-item-header table-cell-item-sub-head"
                    }
                  >
                    <Box className="table-head-title-wrapper">
                      {CheckValueLocale(secondColumnTitle, "", {}, intl)}
                      {secondColumnTitleColor ? (
                        <p style={{ color: secondColumnTitleColor }}></p>
                      ) : null}
                    </Box>
                  </TableCell>
                  <TableCell
                    className={
                      "table-cell-item-header table-cell-item-sub-head"
                    }
                  >
                    <Box className="table-head-title-wrapper">
                      {CheckValueLocale(thirdColumnTitle, "", {}, intl)}
                      {thirdColumnTitleColor ? (
                        <p style={{ color: thirdColumnTitleColor }}></p>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <WordsList
                  name={"genesys_speaking_time"}
                  value={firstColumnData?.speaking_time}
                  subValue={secondColumnData?.speaking_time}
                />
                <WordsList
                  name={"genesys_net_sentiment"}
                  value={firstColumnData?.sentiment}
                  subValue={secondColumnData?.sentiment}
                  isSentimentContent
                />
                <WordsList
                  name={"main_dialect_filter"}
                  value={firstColumnData?.dialects}
                  subValue={secondColumnData?.dialects}
                />
                <WordsList
                  name={"sub_dialect_filter"}
                  value={firstColumnData?.sub_dialects}
                  subValue={secondColumnData?.sub_dialects}
                />
                <WordsList
                  name={"themes_filter"}
                  value={firstColumnData?.themes}
                  subValue={secondColumnData?.themes}
                />
                <WordsList
                  name={"sub_themes_filter"}
                  value={firstColumnData?.sub_themes}
                  subValue={secondColumnData?.sub_themes}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};
export default TableWidgetAnalysis;
