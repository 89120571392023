import { Box, Button, CircularProgress } from "@mui/material";
import "./lucButton.scss";
import { useEffect, useRef, useState } from "react";

function LucButton({
  type = "primary", // primary, secondary, red
  variant = "filled", // filled=containted, outline=outlined, flat=text
  size = "large", //large, small
  loading = false,
  disabled = false,
  startIcon,
  endIcon = null,
  className = "",
  minWidth = 70,
  ...props
}) {
  const [marginLoading, setMarginLoading] = useState(0);
  const getButtonvariant = (type) => {
    const Buttonvariant = {
      filled: "contained",
      outline: "outlined",
      flat: "text",
    };
    return Buttonvariant[type];
  };

  useEffect(() => {
    let marginSize = startIcon ? (size === "large" ? 1 / 2 : 5 / 16) : 0;
    marginSize += endIcon ? (size === "large" ? 1 / 2 : 5 / 16) : 0;
    setMarginLoading(marginSize);
  }, []);

  return (
    <Button
      color={type === "red" ? "error" : type}
      variant={getButtonvariant(variant)}
      size={size}
      className={"luc-Button " + className}
      disabled={disabled || loading}
      startIcon={!loading ? startIcon : null}
      endIcon={!loading ? endIcon : null}
      loading={loading}
      sx={{ opacity: disabled ? 0.2 : 1, minWidth: minWidth }}
      {...props}
    >
      {loading ? (
        <>
          <CircularProgress />
          <Box component={"span"} className={"content-Loading"}>
            {startIcon && startIcon}
            <Box component={"span"} mx={marginLoading}>
              {props?.children}
            </Box>
            {endIcon ? endIcon : null}
          </Box>
        </>
      ) : (
        props?.children
      )}
    </Button>
  );
}

export default LucButton;
