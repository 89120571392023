import { useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  faArrowToBottom,
  faFileImport,
  faTasks,
} from "@fortawesome/pro-regular-svg-icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  CheckValueLocale,
  handlelUserRoles,
  specialCharSearch,
} from "utils/helpers";
import "./audienceHeader.scss";
import PageHeader from "components/pageHeader.js";
import Filter from "shared/general-filter/filter.js";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import LucButton from "shared/lucButton/lucButton";

const useStyles = makeStyles((theme) => ({
  manageAudienceMenuPosition: {
    marginTop: 5,
  },
}));

const AudienceHeader = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  const [searchBoxClicked, setSearchBoxClicked] = useState(false);
  const [updatedError, setUpdatedError] = useState("");
  const [errorColor, setErrorColor] = useState(false);
  const [optionsButton, setOptionsButton] = useState(null);
  const openOptionsButton = Boolean(optionsButton);

  const handleClickOptionsButton = (event) => {
    setOptionsButton(event.currentTarget);
  };
  const handleCloseOptionsButton = () => {
    setOptionsButton(null);
  };

  const handleEnterKeyPress = (e, clickType) => {
    let value = e.target.value;
    if ((e.keyCode === 13 || clickType === "btn") && validateField(value)) {
      props?.setSearchActivated(true);
      e.target.blur();
      if (props?.page === 1) {
        props?.getAudience(
          props?.startDate,
          props?.endDate,
          props?.filterParams,
          props?.page,
        );
      } else {
        props?.setPage(1);
      }
    }
  };

  const handleInputMouseDown = (event) => {
    event.preventDefault();
  };

  const validateField = (value) => {
    if (value.length > 40) {
      setErrorColor(true);
      setSearchBoxClicked(false);
      setUpdatedError(CheckValueLocale("name_char", "", {}, intl));
      return false;
    }
    if (!specialCharSearch(value)) {
      setErrorColor(true);
      setSearchBoxClicked(false);
      setUpdatedError(CheckValueLocale("wrong_char", "", {}, intl));
      return false;
    }
    setErrorColor(false);
    setUpdatedError("");
    return true;
  };

  return (
    <Box id="audienceHeader" className="audience-table">
      <Box className="audience-header-wrapper">
        <Box container className="audience-title">
          <PageHeader
            title="audience"
            subTitle="audience_info"
            subTitleVariant="span"
          />
        </Box>
        <Box className="audience-date-options-wrapper">
          <Box className="audience-option-menu">
            <LucButton
              variant="outline"
              size="small"
              endIcon={<ArrowDropDownIcon />}
              id="demo-customized-button"
              aria-controls="managed-audience-menu"
              aria-haspopup="true"
              aria-expanded={openOptionsButton ? "true" : undefined}
              disableElevation
              onMouseOver={handleClickOptionsButton}
            >
              {CheckValueLocale("segment_actions", "", {}, intl)}
            </LucButton>
            <Menu
              id={
                props?.allActiveFilters?.length != 0
                  ? "managed-audience-menu-active-filter"
                  : "managed-audience-menu"
              }
              anchorEl={optionsButton}
              open={openOptionsButton}
              onClose={handleCloseOptionsButton}
              MenuListProps={{ onMouseLeave: handleCloseOptionsButton }}
              className={classes.manageAudienceMenuPosition}
            >
              {handlelUserRoles("CDP", "EXPORT_SEGMENT") && (
                <MenuItem
                  onClick={() => handleCloseOptionsButton()}
                  className="action-btn-wrapper"
                  disableRipple
                  id="managed-aduience-export"
                >
                  <Button
                    onClick={props?.handleApplyExport}
                    className="actions-header-btn"
                    id="audience-actions-export-btn"
                  >
                    <FontAwesomeIcon icon={faArrowToBottom} />
                    <Box className="audience-action-btn">
                      {CheckValueLocale("export", "", {}, intl)}
                      <Box component="span">
                        {CheckValueLocale(
                          "segment_conatins_filtered_data",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    </Box>
                  </Button>
                </MenuItem>
              )}
              {handlelUserRoles("GENERAL", "CREATE_UTILITIES") && (
                <MenuItem
                  className="action-btn-wrapper"
                  onClick={() => handleCloseOptionsButton()}
                  disableRipple
                  id="managed-aduience-import"
                >
                  <Button
                    className="actions-header-btn"
                    onClick={props?.handleImportAudience}
                    id="audience-actions-import-btn"
                  >
                    <FontAwesomeIcon icon={faFileImport} />
                    <Box className="audience-action-btn">
                      {CheckValueLocale("import", "", {}, intl)}
                      <Box component="span">
                        {CheckValueLocale(
                          "segment_import_audience_manually",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    </Box>
                  </Button>
                </MenuItem>
              )}
              {handlelUserRoles("CDP", "VIEW_SEGMENT") && (
                <MenuItem
                  className="action-btn-wrapper manage-audience"
                  disableRipple
                  id="managed-aduience-btn"
                  onClick={() => {
                    handleCloseOptionsButton();
                    localStorage.removeItem("importState");
                  }}
                >
                  <Link
                    to="/audience/manage-audience"
                    className="manage-audience-btn"
                  >
                    <FontAwesomeIcon icon={faTasks} />
                    <Box className="audience-action-btn">
                      {CheckValueLocale("manage_audience", "", {}, intl)}
                      <Box component="span">
                        {CheckValueLocale(
                          "segments_imports_duplicate",
                          "",
                          {},
                          intl,
                        )}
                      </Box>
                    </Box>
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box className="audience-date">
            <DateRangePicker
              applyFilter={props?.applyFilter}
              startDate={props?.startDate}
              endDate={props?.endDate}
              openCustomDateLeft={true}
              datePickerPosition="monitorheader-datepicker-position"
              isActive={!props?.isActive}
              id="date-range-picker-audience-header"
              monitorActiveTabId="audience-header"
            />
          </Box>
        </Box>
      </Box>
      {!props?.isActive ? (
        <Box className="header-box">
          <Box className="search-box">
            <TextField
              className="search-field"
              label={CheckValueLocale("search_by_name", "", {}, intl)}
              variant="outlined"
              value={props?.searchText}
              onChange={(e) => {
                props?.setSearchActivated(true);
                props?.setSearchText(e?.target?.value);
                props?.setSearchInput(props?.searchText);
              }}
              onBlur={() => setSearchBoxClicked(false)}
              onFocus={() => setSearchBoxClicked(true)}
              onKeyDown={handleEnterKeyPress}
              error={updatedError?.length > 0 ? true : false}
              disabled={props?.isActive}
            />

            <Box className="search-header">
              <InputAdornment onMouseDown={handleInputMouseDown}>
                <Button
                  className={
                    searchBoxClicked
                      ? "search-icon-container-blue search-icon-container"
                      : errorColor
                        ? "search-icon-container-red search-icon-container"
                        : "search-icon-container"
                  }
                  onClick={(e) => handleEnterKeyPress(e, "btn")}
                  value={props?.searchText}
                  id="audience-actions-search-btn"
                  disabled={props?.isActive}
                >
                  <FontAwesomeIcon className="search-icon" icon={faSearch} />
                </Button>
              </InputAdornment>
            </Box>

            {updatedError?.length > 0 && (
              <FormHelperText error>{updatedError}</FormHelperText>
            )}
          </Box>
          <Box className="filter-box">
            <Filter
              {...props}
              isAudiencePage
              filterButtonID={"audience-list-filter-btn"}
              disabled={props?.isActive}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default AudienceHeader;
