import { Typography } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const AddChannelPageHeader = () => {
  const intl = useIntl();
  return (
    <>
      <BreadCrumb
        links={[
          {
            name: "channel_analytics",
            url: "manage-channels/list",
          },
          {
            name: "manage_channels",
            url: "manage-channels/list",
          },
        ]}
      />
      <Typography
        variant="h5"
        component="h5"
        style={{ fontSize: "20px", marginBottom: "20px" }}
      >
        {CheckValueLocale("add_channel", "", {}, intl)}
      </Typography>
    </>
  );
};

export default AddChannelPageHeader;
