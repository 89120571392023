import React from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import EmptyDataShared from "./components/emptyDataShared";
import Services from "services/api/Services";

const NoDataFoundProducts = (props) => {
  const intl = useIntl();
  const {
    image,
    title,
    subTitle,
    description,
    showApplyFilterMsg,
    linkOne,
    linkOneHref,
    orCase,
    linkTwo,
    needsAttentionUserName,
  } = props;

  return (
    <Box className="chart-no-data">
      <EmptyDataShared
        image={image}
        title={title}
        subTitle={subTitle}
        description={
          Services.showYouCanAlsoRead
            ? description || ""
            : description
              ? `${description}_no_read`
              : ""
        }
        linkOne={Services.showThirdCompany ? linkOne : ""}
        linkOneHref={Services.showThirdCompany ? linkOneHref : ""}
        orCase={Services.showThirdCompany ? orCase : ""}
        linkTwo={Services.showThirdCompany ? linkTwo : ""}
        showApplyFilterMsg={showApplyFilterMsg}
        needsAttentionUserName={needsAttentionUserName}
      />
    </Box>
  );
};

export default NoDataFoundProducts;
