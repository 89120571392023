import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";
import StepFields from "../components/stepFields";

const StepOne = (props) => {
  const {
    stepFields,
    requiredFields,
    selectedData,
    setSelectedData,
    stepFieldsErr,
  } = props;

  const intl = useIntl();

  return (
    <Box className="luci-steps-container">
      <Box className="luci-step-one-text">
        <Typography component="h3">
          {CheckValueLocale("luci_boost_answers", "", {}, intl)}
        </Typography>
        <Typography>
          {CheckValueLocale("luci_fill_out_below", "", {}, intl)}
        </Typography>
      </Box>
      <Box>
        <Typography component="h3">
          {CheckValueLocale("luci_business_info", "", {}, intl)}
        </Typography>
        <StepFields
          stepFields={stepFields}
          requiredFields={requiredFields}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          stepFieldsErr={stepFieldsErr}
        />
      </Box>
    </Box>
  );
};

export default StepOne;
