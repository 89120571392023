import { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

export const sortedColumnTypes = {
  number: "number",
  string: "string",
  date: "date",
};

/**
 * getProperTooltipForTypewhen hovering on the icon in the column header, a tooltip should appear based on the current column type.
 * @param {*} type  type of the column (string, number, date)
 * @param {*} intl  intl object
 * @param {*} column  column name (for string type)
 * @returns
 */
const getProperTooltipForType = (type, intl, column) => {
  let tooltipText = "";
  switch (type) {
    case sortedColumnTypes.string:
      tooltipText = CheckValueLocale(
        "sort_by_string_tooltip",
        "",
        { column },
        intl,
      );
      break;
    case sortedColumnTypes.date:
      tooltipText = CheckValueLocale("sort_by_date_tooltip", "", {}, intl);
      break;
    case sortedColumnTypes.number:
      tooltipText = CheckValueLocale("sort_by_number_tooltip", "", {}, intl);
      break;
    default:
      break;
  }
  return tooltipText;
};

/**
 * useSortableTable is a hook that handles the sorting of the table columns.
 * @param {array} initialColumns  the initial columns of the table (before sorting)
 * @param {function} onSortChangeCallback  callback function to be called when the sorting changes
 * @param {string} defaultSelectedColumn  the default column to be sorted by
 * @param {string} defaultDirection  the default direction of the sorting
 * @returns {object}  returns an object containing the sorted columns, the sort direction, the sort column and a function to set the loading state
 * @example
 * const { columns, sortDirection, sortColumn, setIsSortableLoading } = useSortableTable({
 *   initialColumns: [
 *     {
 *       name: "name", // the name of the column (used to change sortColumn state)
 *       type: sortedColumnTypes.string, // the type of the column (used to change to show the proper icon tooltip)
 *       body: "Name", // the body of the column (what is shown in the table, can be anything)
 *       localColumnName: "monitor_name", // (optional) the name of the column in the locale file to be show on the string icon tooltip, if not provided, the body will be used
 *       ...rest // any other column props you wish to pass to the table
 *     },
 *   ],
 *   onSortChangeCallback: (column, direction) => {  // (optional)
 *     // do something with the new sort column and direction
 *   },
 *   defaultSelectedColumn: "name", // (optional) the default column to be sorted by
 *   defaultDirection: "desc", // (optional) the default direction of the sorting
 * });
 */
export const useSortableTable = ({
  initialColumns,
  onSortChangeCallback,
  defaultSelectedColumn = null,
  defaultDirection = null,
}) => {
  const intl = useIntl();
  const [sortDirection, setSortDirection] = useState(defaultDirection);
  const [sortColumn, setSortColumn] = useState(defaultSelectedColumn);
  const [isLoading, setIsLoading] = useState(false);

  const handleColumnClick = (column) => {
    let newSortDirection = sortDirection;
    let newSortColumn = sortColumn;
    // if the column is already sorted, change the direction, otherwise, change the column and set the direction to the default
    if (column === sortColumn) {
      newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    } else {
      newSortDirection = defaultDirection || "desc";
      newSortColumn = column;
    }
    // call the callback function if provided
    onSortChangeCallback?.(newSortColumn, newSortDirection);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  };

  // map the columns and add the proper body to the column (if the column has a name, it will be sortable)
  const columns = initialColumns.map((column) => {
    const { body, name, localColumnName, ...rest } = column;
    let finalBody = column?.body;
    if (name) {
      finalBody = (
        <SortableColumnCell
          column={name}
          direction={sortDirection}
          onSortChange={handleColumnClick}
          sortColumn={sortColumn}
          type={column?.type}
          iconTooltip={getProperTooltipForType(
            column?.type,
            intl,
            localColumnName?.length > 0 ? localColumnName : body,
          )}
        >
          {body}
        </SortableColumnCell>
      );
    }
    return {
      ...rest,
      body: finalBody,
    };
  });

  return {
    columns,
    isSortableLoading: isLoading,
    sortColumn,
    sortDirection,
    setIsSortableLoading: setIsLoading,
  };
};

const SortableColumnCell = ({
  column,
  direction,
  onSortChange,
  sortColumn,
  children,
  iconTooltip,
}) => {
  const handleClick = () => {
    onSortChange(column);
  };

  const renderProperIcon = () => {
    let icon = null;
    if (column === sortColumn) {
      if (direction === "asc") {
        icon = <ArrowUpwardIcon className="sortable-column-cell-active-icon" />;
      } else {
        icon = (
          <ArrowDownwardIcon className="sortable-column-cell-active-icon" />
        );
      }
    } else {
      icon = <SwapVertIcon />;
    }

    return (
      <Tooltip title={iconTooltip} placement="top">
        {icon}
      </Tooltip>
    );
  };
  return (
    <Box onClick={handleClick} className="sortable-column-cell">
      <Typography component="span" className="sortable-column-cell-text">
        {children}
      </Typography>
      {renderProperIcon()}
    </Box>
  );
};
