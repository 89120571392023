import { useState } from "react";
import { Menu, MenuItem, IconButton, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";
import OutlineEye from "images/shared-images/outline-eye.svg";
import OutlinePen from "images/shared-images/outline-pen.svg";
import OutlineTrash from "images/shared-images/outline-trash.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();

  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const open = Boolean(stats?.anchorEl);

  const handleClick = (event) => {
    setStats({ anchorEl: event.currentTarget });
  };
  const handleClose = () => {
    setStats({ anchorEl: null });
  };

  return (
    <Box className="user-management-menu-wrapper">
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          aria-label="more"
          id="settings-dropdown-user-management-long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
          className: "teams-options-menu",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale === "ar" ? "left" : "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale === "ar" ? "left" : "right",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        className="user-management-list"
      >
        <MenuItem disableRipple className="option-btn">
          <Link
            to={{
              pathname: `/settings/teams/view/${props?.team?.id}`,
              state: { id: props?.team?.id },
            }}
            className="options-link"
          >
            <Box className="img-wrapper">
              <img src={OutlineEye} alt="view" />
            </Box>
            <Typography as="span" className="option-title">
              {CheckValueLocale("view_team", "", {}, intl)}
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem disableRipple className="option-btn">
          <Link
            to={{
              pathname: `/settings/teams/edit/${props?.team?.id}`,
              state: { id: props?.team?.id, from: "teams" },
            }}
            className="options-link"
          >
            <Box className="img-wrapper">
              <img src={OutlinePen} alt="view" />
            </Box>
            <Typography as="span" className="option-title">
              {CheckValueLocale("edit", "", {}, intl)}
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.openDeleteModal?.(props?.team);
            handleClose();
          }}
          disableRipple
          className="option-btn"
        >
          <Box className="options-link">
            <Box className="img-wrapper">
              <img src={OutlineTrash} alt="view" />
            </Box>
            <Typography as="span" className="option-title">
              {CheckValueLocale("delete", "", {}, intl)}
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default OptionsDropDown;
