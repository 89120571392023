import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Box,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Snackbar,
} from "@mui/material";
import { isPublicSpecialCharactersOrEmojis } from "../../socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "../../socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage.js";
import { useEffect, useState } from "react";
import ReportController from "services/controllers/reportController";

const StepOne = ({
  fieldError,
  fieldsValue,
  setFieldErrors,
  setFormFields,
  setCheckDisable,
  setStep2FormFields,
  isMonitorAction,
  preSelectedData,
}) => {
  const intl = useIntl();
  const [products, setProducts] = useState([]);
  const [isRequestError, setIsRequestError] = useState(false);

  const getReportProductsList = () => {
    ReportController.getReportProducts().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.data) {
        const filteredProducts = res?.data?.filter(
          (item) =>
            item?.attributes?.name === "SM" || item?.attributes?.name === "CXM",
        );
        setProducts([...filteredProducts]);
      }
    });
  };
  const updateFieldValue = (event, input) => {
    const {
      target: { value, name },
    } = event;

    if (
      value !== "" &&
      (isPublicSpecialCharactersOrEmojis(value, undefined, true) ||
        removeSpecialCharacters(value)?.length === 0)
    ) {
      if (input === "reportName") {
        setFieldErrors({
          ...fieldError,
          reportName: CheckValueLocale("spam_keywords_rules", "", {}, intl),
        });
      } else {
        setFieldErrors({
          ...fieldError,
          platform: CheckValueLocale("spam_keywords_rules", "", {}, intl),
        });
      }
    }

    if (input === "reportName") {
      if (
        !isPublicSpecialCharactersOrEmojis(value, undefined, true) &&
        !(removeSpecialCharacters(value)?.length === 0)
      ) {
        setFieldErrors({ ...fieldError, reportName: "" });
      }
      setFormFields((val) => ({ ...val, [name]: value }));
    } else {
      if (
        !isPublicSpecialCharactersOrEmojis(value, undefined, true) &&
        !(removeSpecialCharacters(value)?.length === 0)
      ) {
        setFieldErrors({ ...fieldError, platform: "" });
      }
      const selectedProductId =
        products &&
        products?.filter((item) => item?.attributes?.name == value)[0]
          ?.attributes?.id;
      setFormFields((val) => ({
        ...val,
        [name]: value,
        productId: selectedProductId,
      }));
      setStep2FormFields({
        monitorType: "",
        reportType: "",
        dataSource: "",
        monitor: "",
        KeywordDataSource: [],
        remainingMonitorsDataSource: "",
        KeywordMonitors: [],
      });
    }
  };
  useEffect(() => {
    if (fieldsValue?.reportName == "" || fieldsValue?.platform == "") {
      setCheckDisable(true);
    } else {
      setCheckDisable(false);
    }
  }, [fieldsValue?.reportName, fieldsValue?.platform]);

  useEffect(() => {
    getReportProductsList();
  }, []);

  useEffect(() => {
    if (isMonitorAction && preSelectedData?.productName && products?.length > 0)
      products?.map((product) => {
        if (product?.attributes?.name === preSelectedData?.productName)
          setFormFields({
            ...fieldsValue,
            productId: +product?.id,
            platform: preSelectedData?.productName,
          });
      });
  }, [isMonitorAction, preSelectedData, products]);

  return (
    <>
      <Typography variant="h6" className="create-report-body-title">
        {CheckValueLocale("add_report_informations", "", {}, intl)}:
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>{CheckValueLocale("name", "", {}, intl)}</InputLabel>
            <TextField
              fullWidth
              placeholder={CheckValueLocale(
                "write_your_report_name",
                "",
                {},
                intl,
              )}
              name="reportName"
              value={fieldsValue?.reportName}
              onChange={(e) => updateFieldValue(e, "reportName")}
              error={fieldError?.reportName ? true : false}
            />
            {fieldError?.reportName && (
              <FormHelperText error>{fieldError?.reportName}</FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item lg={6} xl={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("platform", "", {}, intl)}
            </InputLabel>
            <Select
              labelId="report-platform-select-label"
              id="report-platform-simple-select"
              fullWidth
              name="platform"
              value={fieldsValue?.platform}
              onChange={(e) => updateFieldValue(e, "platform")}
              error={fieldError?.platform ? true : false}
              displayEmpty
              disabled={isMonitorAction ? true : false}
            >
              <MenuItem
                value=""
                disabled
                className="report-menu-items"
                id="report-menu-select-items"
              >
                {CheckValueLocale("select", "", {}, intl)}
              </MenuItem>
              {products.map((item) => {
                return (
                  <MenuItem
                    key={item?.attributes?.name}
                    value={item?.attributes?.name}
                    className="report-menu-items"
                    id="report-menu-sm-items"
                  >
                    {CheckValueLocale(item?.attributes?.name, "", {}, intl)}
                  </MenuItem>
                );
              })}
            </Select>
            {fieldError?.platform && (
              <FormHelperText error>{fieldError?.platform}</FormHelperText>
            )}
          </Box>
        </Grid>
      </Grid>
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </>
  );
};

export default StepOne;
