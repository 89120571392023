import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  SingleInputDateRangeField,
  DateRangePicker,
} from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarRange,
  faCircleInfo,
} from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/material";
import "./SingleInputDateRangePicker.scss";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

// Custom date format function
const formatRange = (range) => {
  const [start, end] = range;
  if (!start || !end) return "";
  // Custom format: you can adjust this to 'MM-DD-YYYY', 'DD/MM/YYYY', etc.
  return `${dayjs(start).format("DD/MM/YYYY")} - ${dayjs(end).format("DD/MM/YYYY")}`;
};

export default function SingleInputDateRangePicker({
  customDataRange,
  setCustomDataRange,
  maxDays,
  errorMaxDateRange,
  setErrorMaxDateRange,
}) {
  const intl = useIntl();

  const handleDateChange = (newRange) => {
    const [start, end] = newRange;

    if (start && end && !!maxDays) {
      const diff = dayjs(end)?.diff(dayjs(start), "day");
      if (diff > maxDays - 1) {
        setErrorMaxDateRange(true);
      } else {
        setErrorMaxDateRange(false);
      }
      setCustomDataRange(newRange);
    } else {
      end === null
        ? setCustomDataRange([start, start])
        : setCustomDataRange(newRange);
      setErrorMaxDateRange(false);
    }
  };

  return (
    <Box className="single-custom-dateRange">
      <Box className="data-range-label">
        <Box component="span" className="label">
          {CheckValueLocale("dates_range", "", {}, intl)}
        </Box>
        <Box component="span" className="required">
          *
        </Box>
        <CustomizedTooltip
          title={CheckValueLocale("date_range_tooltip", "", {}, intl)}
          arrow
          placement={"top"}
          styles={customizedStyledTooltip}
        >
          <FontAwesomeIcon icon={faCircleInfo} />
        </CustomizedTooltip>
      </Box>
      <Box className="single-input-dateRange-picker">
        <FontAwesomeIcon icon={faCalendarRange} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["SingleInputDateRangeField"]}>
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              value={customDataRange}
              name="allowedRange"
              onChange={handleDateChange}
              disableFuture
              // Custom input props to change placeholder
              slotProps={{
                field: {
                  inputProps: {
                    placeholder: "Pick a date range",
                    value: formatRange(customDataRange),
                  },
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      {errorMaxDateRange ? (
        <Box variant="caption" className="single-input-dateRange-picker-error">
          {CheckValueLocale(
            "single_input_dateRange_picker_error",
            "",
            {},
            intl,
          )}
        </Box>
      ) : null}
    </Box>
  );
}
