import { IconButton } from "@mui/material";
import ArrowUpRightFromBox from "images/interactions-details/arrow-up-right-from-box.svg";

const ForwardLinker = ({ url }) => {
  if (!url) return null;
  return (
    <IconButton
      as="a"
      href={url}
      target="_blank"
      className="interaction-forward-link"
    >
      <img src={ArrowUpRightFromBox} alt="twitter post" />
    </IconButton>
  );
};

export default ForwardLinker;
