import { Typography, TableCell, TableRow, Box, TableBody } from "@mui/material";
import moment from "moment";
import FetchingData from "../../../../../components/fetchingData/fetchingData";
import NoDataFound from "../../../../../components/no-Data/noDataFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngry, faSmile, faMeh } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import {
  dateTotext,
  dateTotextMinusLastDay,
  CheckValueLocale,
} from "utils/helpers";

const SentimentOverview = ({
  monitorPublicType,
  benchmarkHeadData,
  rmqResponse,
  intl,
  resetTablesData,
  preloaderDatasource,
  setAllDataExist,
  sentimentPdfData,
  setSentimentPdfData,
}) => {
  const [positiveSentimentData, setPositiveSentimentData] = useState([]);
  const [maxPositiveSentimentCount, setMaxPositiveSentimentCount] = useState(0);

  const [neutralSentimentData, setNeutralSentimentData] = useState([]);
  const [maxNeutralSentimentCount, setMaxNeutralSentimentCount] = useState(0);

  const [negativeSentimentData, setNegativeSentimentData] = useState([]);
  const [maxNegativeSentimentCount, setMaxNegativeSentimentCount] = useState(0);

  const [sentimentTableHeadCellData, setSentimentTableHeadCellData] = useState(
    [],
  );
  const [sentimentMonitorsID, setSentimentMonitorsID] = useState([]);
  const [sentimentData, setSentimentData] = useState([]);

  const [existData, setExistData] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);

  const monitorsID = benchmarkHeadData?.monitorData?.map(
    (x) =>
      `${x?.monitor_id}_${x?.data_source_name?.charAt(0)?.toUpperCase() + x?.data_source_name?.slice(1)?.toLowerCase()}`,
  );
  const lastPeriodDates = preloaderDatasource;

  useEffect(() => {
    setNoDataFound(false);
    setExistData(true);
    setAllDataExist(false);
    setSentimentData([]);
    setSentimentMonitorsID([]);
    setPositiveSentimentData([]);
    setNeutralSentimentData([]);
    setNegativeSentimentData([]);
    setSentimentTableHeadCellData([]);
    setSentimentPdfData({
      positiveSentimentData: [],
      neutralSentimentData: [],
      negativeSentimentData: [],
      sentimentTableHeadCellData: [],
      maxPositiveSentimentCount: 0,
      maxNeutralSentimentCount: 0,
      maxNegativeSentimentCount: 0,
    });
  }, [resetTablesData]);

  const sentimentDataPostiveTypes = (type, value) => {
    switch (type) {
      case "positive":
        positiveSentimentData?.unshift(value);
        break;
      case "negative":
        negativeSentimentData?.unshift(value);
        break;
      case "neutral":
        neutralSentimentData?.unshift(value);
        break;
    }
  };

  const sentimentDataTypes = (type, value) => {
    switch (type) {
      case "positive":
        positiveSentimentData?.push(value);
        break;
      case "negative":
        negativeSentimentData?.push(value);
        break;
      case "neutral":
        neutralSentimentData?.push(value);
        break;
    }
  };

  const setAllData = () => {
    setSentimentPdfData({
      positiveSentimentData: positiveSentimentData,
      neutralSentimentData: neutralSentimentData,
      negativeSentimentData: negativeSentimentData,
      sentimentTableHeadCellData: [],
      maxPositiveSentimentCount: Math.max(...positiveSentimentData),
      maxNeutralSentimentCount: Math.max(...neutralSentimentData),
      maxNegativeSentimentCount: Math.max(...negativeSentimentData),
    });
    setPositiveSentimentData(positiveSentimentData);
    setMaxPositiveSentimentCount(Math.max(...positiveSentimentData));

    setNegativeSentimentData(negativeSentimentData);
    setMaxNegativeSentimentCount(Math.max(...negativeSentimentData));

    setNeutralSentimentData(neutralSentimentData);
    setMaxNeutralSentimentCount(Math.max(...neutralSentimentData));
  };

  const fetchingDone = () => {
    setNoDataFound(
      positiveSentimentData?.length == 0 &&
        negativeSentimentData?.length == 0 &&
        neutralSentimentData?.length == 0,
    );
    setExistData(false);
    setAllDataExist(true);
  };

  useEffect(() => {
    switch (rmqResponse?.eventName) {
      case "Twitter__LastPeriodBenchmarkV4Page__sentiment_analysis":
      case "Facebook__LastPeriodBenchmarkV4Page__sentiment_analysis":
      case "Instagram__LastPeriodBenchmarkV4Page__sentiment_analysis":
      case "Talkwalker__LastPeriodBenchmarkV4Page__sentiment_analysis":
        setNoDataFound(false);
        setExistData(true);
        setAllDataExist(false);
        if (
          monitorsID[0] ==
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source}`
        ) {
          switch (rmqResponse?.period) {
            case lastPeriodDates[0]:
              sentimentTableHeadCellData?.unshift(
                monitorPublicType === "Time_Period_vs_Time_Period"
                  ? dateTotextMinusLastDay(rmqResponse?.period)
                  : dateTotext(rmqResponse?.period),
              );
              setSentimentTableHeadCellData(sentimentTableHeadCellData);
              rmqResponse?.eventData?.sentiment_piechart?.map((x) => {
                sentimentDataPostiveTypes(x?.name, x?.value);
              });
              setAllData();
              break;
            case lastPeriodDates[1]:
              sentimentTableHeadCellData?.push(
                monitorPublicType === "Time_Period_vs_Time_Period"
                  ? dateTotextMinusLastDay(rmqResponse?.period)
                  : dateTotext(rmqResponse?.period),
              );
              setSentimentTableHeadCellData(sentimentTableHeadCellData);
              rmqResponse?.eventData?.sentiment_piechart?.map((x) => {
                sentimentDataTypes(x?.name, x?.value);
              });
              setAllData();
              break;
          }
          if (sentimentTableHeadCellData?.length == 2) {
            fetchingDone();
          }
        }
        break;
      case "Twitter__VsAnotherBenchmarkV4Page__sentiment_analysis":
      case "Facebook__VsAnotherBenchmarkV4Page__sentiment_analysis":
      case "Instagram__VsAnotherBenchmarkV4Page__sentiment_analysis":
      case "Talkwalker__VsAnotherBenchmarkV4Page__sentiment_analysis":
        setNoDataFound(false);
        setExistData(true);
        setAllDataExist(false);
        sentimentMonitorsID?.push(
          `${rmqResponse?.monitor_id}_${rmqResponse?.data_source}`,
        );
        setSentimentMonitorsID(sentimentMonitorsID);
        sentimentData?.push(rmqResponse?.eventData?.sentiment_piechart);
        setSentimentData(sentimentData);
        if (sentimentMonitorsID?.length == monitorsID?.length) {
          let checkPreLoader = [];
          monitorsID?.map((x) => {
            sentimentMonitorsID?.map((y) =>
              y == x ? checkPreLoader?.push(y) : null,
            );
          });
          if (checkPreLoader?.length == monitorsID?.length) {
            monitorsID?.map((id1) => {
              sentimentMonitorsID?.map((id2, x) => {
                if (id1 == id2) {
                  let data = sentimentData[x];
                  data?.map((i) => sentimentDataTypes(i?.name, i?.value));
                }
              });
            });
            setAllData();
            fetchingDone();
          } else {
            setNoDataFound(true);
            setExistData(false);
            setAllDataExist(true);
          }
        }
        break;
      default:
        break;
    }
  }, [rmqResponse]);

  const handleTableHeadType = (type, icon) => {
    return (
      <TableCell>
        <FontAwesomeIcon
          icon={icon}
          className={`${
            type == "positive"
              ? "positive_icon"
              : type == "negative"
                ? "negative_icon"
                : "meh_icon"
          }`}
        />
        <Box component="span" marginX={1} className="sentiment-overtime-title">
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      </TableCell>
    );
  };

  const sentimentPer = (val, x) => {
    const percent =
      val > 0
        ? (
            (val /
              (positiveSentimentData[x] +
                negativeSentimentData[x] +
                neutralSentimentData[x])) *
            100
          )?.toFixed(2)
        : 0;
    return percent;
  };

  return (
    <>
      {monitorPublicType !== "Monitor_vs_Monitors" && (
        <TableRow className="body-tr-title" sx={{ height: "0px !important" }}>
          <TableCell
            component="td"
            sx={{ background: "#f8fafc", borderBottom: "none !important" }}
          ></TableCell>
        </TableRow>
      )}
      <TableRow className="body-tr-title">
        <TableCell
          className={`${
            monitorPublicType === "Monitor_vs_Monitors" && "sticky"
          } widget-title-benchmark`}
          colSpan={8}
        >
          {CheckValueLocale("sentiment_overview", "", {}, intl)}
        </TableCell>
      </TableRow>
      {existData ? (
        <TableRow className="body-tr-head fetching-tr">
          <TableCell className="feching-td">
            <FetchingData />
          </TableCell>
        </TableRow>
      ) : noDataFound ? (
        <TableRow className="body-tr-head fetching-tr">
          <TableCell className="feching-td">
            <NoDataFound />
          </TableCell>
        </TableRow>
      ) : (
        <TableBody>
          {monitorPublicType !== "Monitor_vs_Monitors" && (
            <TableRow className="body-tr-head">
              <TableCell component="th">
                <Typography component="span">
                  {CheckValueLocale("metrics", "", {}, intl)}
                </Typography>
              </TableCell>
              {sentimentTableHeadCellData?.map((val, index) => (
                <TableCell
                  component="th"
                  key={`customer-care-metrics-th-${index}`}
                  align={"center"}
                >
                  {monitorPublicType === "Positive_vs_Negative" && (
                    <FontAwesomeIcon
                      icon={val === "positive" ? faSmile : faAngry}
                      className={`${val}_icon`}
                    />
                  )}
                  <Typography component="span" dir="ltr">
                    {monitorPublicType === "Time_Period_vs_Time_Period"
                      ? val
                      : CheckValueLocale(val, "", {}, intl)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          )}
          <TableRow className="body-tr-data">
            {handleTableHeadType("positive", faSmile)}
            {positiveSentimentData?.map((val, index) => {
              return (
                <TableCell
                  key={`sentiment-positive-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxPositiveSentimentCount
                      ? "table-highlight-cell items-sentiment-overtime"
                      : "table-row-cell items-sentiment-overtime"
                  }
                >
                  {val != null ? (
                    <>
                      {sentimentPer(val, index)}%
                      {monitorPublicType === "Time_Period_vs_Time_Period"
                        ? ` (${positiveSentimentData[index] || 0} ${CheckValueLocale("pdf_posts", "", {}, intl)})`
                        : ""}
                    </>
                  ) : (
                    "—"
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            {handleTableHeadType("neutral", faMeh)}
            {neutralSentimentData?.map((val, index) => {
              return (
                <TableCell
                  key={`sentiment-neutral-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxNeutralSentimentCount
                      ? "table-highlight-cell items-sentiment-overtime"
                      : "table-row-cell items-sentiment-overtime"
                  }
                >
                  {val != null ? (
                    <>
                      {sentimentPer(val, index)}%
                      {monitorPublicType === "Time_Period_vs_Time_Period"
                        ? ` (${neutralSentimentData[index] || 0} ${CheckValueLocale("pdf_posts", "", {}, intl)})`
                        : ""}
                    </>
                  ) : (
                    "—"
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow className="body-tr-data">
            {handleTableHeadType("negative", faAngry)}
            {negativeSentimentData?.map((val, index) => {
              return (
                <TableCell
                  key={`sentiment-negative-${index}`}
                  align="center"
                  className={
                    val > 0 && val === maxNegativeSentimentCount
                      ? "table-highlight-cell items-sentiment-overtime"
                      : "table-row-cell items-sentiment-overtime"
                  }
                >
                  {val != null ? (
                    <>
                      {sentimentPer(val, index)}%
                      {monitorPublicType === "Time_Period_vs_Time_Period"
                        ? ` (${negativeSentimentData[index] || 0} ${CheckValueLocale("pdf_posts", "", {}, intl)})`
                        : ""}
                    </>
                  ) : (
                    "—"
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

export default SentimentOverview;
