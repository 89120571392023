import moment from "moment";
import _ from "lodash";
import { faMeh, faSmile, faAngry } from "@fortawesome/pro-regular-svg-icons";

// social icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faIntercom,
  faWhatsapp,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faTiktok,
  faFacebookMessenger,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import GmailIcon from "images/social-icons/gmail.svg";
import SurveyFilePen from "images/Survey-file-pen.png";
import publicTikTok from "images/shared-images/public-tikTok.svg";
import GoogleIcon from "images/google-icon.png";
import { ReactComponent as GoogleMyBusiness } from "images/shared-images/gmb.svg";
import SocialnetworksIcon from "images/social-icons/SOCIALNETWORKS-icon.svg";
import zendesk from "images/social-icons/zendesk.png";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import Genesys from "images/shared-images/genesys-icon.svg";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import publicTwitter from "images/shared-images/public-twitter.svg";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
import privateMessenger from "images/social-icons/facebook-private-icon.svg";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import tikTokImage from "images/social-icons/tiktok-icon.svg";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import { sanitize } from "dompurify";
import Services from "services/api/Services";
/**
 * isEmptyValue function is used to check if the provided value is empty or nor
 * For exampl: if the provided value one of [], {}, "", 0, false, null, undefined will return true.
 */
export const isEmptyValue = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 0;
  }

  return !value;
};

const isEmptyObj = (data) => {
  return JSON.stringify(data) === "{}" ? true : false;
};

const isEmptyArray = (arr) => {
  return Array.isArray(arr) && !arr.length;
};

const getTimeZone = () => {
  let timezone = new Date().getTimezoneOffset() / 60;
  return -1 * timezone;
};

const differenceBetweenDates = (startUnixDate, endUnixDate) => {
  // Convert Unix timestamps to moment objects in UTC
  const startDate = moment.unix(startUnixDate);
  const endDate = moment.unix(endUnixDate);

  // Calculate the difference in seconds
  const differenceSec = endDate.diff(startDate, "seconds");

  // Calculate the difference in other units
  const duration = moment.duration(differenceSec, "seconds");

  // Extract days, hours, minutes, seconds, and years
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const years = Math.floor(duration.asYears());

  // Return an object containing the difference in various units
  return {
    seconds,
    days,
    hours,
    minutes,
    years,
  };
};

const postTimeWithFormatAgo = (timeCreated, intl) => {
  const currentUnixDate =
    moment().unix() + parseInt(localStorage.companyInfo) * 60 * 60;
  const difference = differenceBetweenDates(timeCreated, currentUnixDate);
  let date = moment
    ?.unix(timeCreated - getTimeZone() * 60 * 60)
    ?.format("DD MMM YYYY")
    .split(" ");

  let displayDate;

  if (difference?.years) {
    displayDate = date?.join(" ");
  } else if (difference?.days) {
    if (difference?.days > 30) {
      displayDate = `${date[0]} ${date[1]}`;
    } else {
      displayDate = `${CheckValueLocale("num_days", "", { num: difference?.days }, intl)}`;
    }
  } else if (difference?.hours) {
    displayDate = `${CheckValueLocale("num_hours", "", { num: difference?.hours }, intl)}`;
  } else {
    displayDate = `${CheckValueLocale(
      "num_min",
      "",
      { num: difference?.minutes || 1 },
      intl,
    )}`;
  }

  return displayDate;
};

function convertToSeconds(obj) {
  const { value, unit } = obj;
  switch (unit) {
    case "hours":
    case "H":
      return value * 3600; // 1 hour = 3600 seconds
    case "days":
    case "D":
      return value * 24 * 3600; // 1 day = 24 hours = 24 * 3600 seconds
    case "minutes":
    case "M":
      return value * 60; // 1 minute = 60 seconds
    case "S":
      return value;
    default:
      return 0;
  }
}

const timeCounter = (UnixDate, currentUnixDate, initialValue = 0) => {
  // first we get differance between UnixDate, currentUnixDate
  const differenceFrt = differenceBetweenDates(
    currentUnixDate <= UnixDate ? currentUnixDate : UnixDate,
    currentUnixDate <= UnixDate ? UnixDate : currentUnixDate,
  );
  // calculate differance in seconds
  let secondsSum =
    differenceFrt?.years * 365 * 24 * 60 * 60 +
    differenceFrt?.days * 24 * 60 * 60 +
    differenceFrt?.hours * 60 * 60 +
    differenceFrt?.minutes * 60 +
    differenceFrt?.seconds;

  // calculate percentage of the time taken from the available time
  let percentage =
    currentUnixDate >= UnixDate
      ? 100
      : secondsSum < initialValue
        ? ((initialValue - secondsSum) / initialValue) * 100
        : 100;

  let progress =
    currentUnixDate >= UnixDate
      ? 100
      : secondsSum < initialValue
        ? (secondsSum / initialValue) * 100
        : 100;
  // default returned data
  let obj = {
    value: [0, "S"],
    timer: 0,
    percentage: percentage,
    progress: percentage,
  };

  if (differenceFrt?.years > 0) {
    // at this case when differance by years we return it as days and return time = 0 to stop run counter live.
    let value =
      currentUnixDate <= UnixDate
        ? differenceFrt?.years * 365 + differenceFrt?.days
        : -1 * (differenceFrt?.years * 365 - differenceFrt?.days);
    obj = {
      value: [value, "D"],
      timer: 0,
      percentage: percentage,
      progress: percentage,
    };
  } else if (differenceFrt?.days * 24 + differenceFrt?.hours > 36) {
    // at this case when differance by hours > 36 we return it as days and return time = 0 to stop run counter live.
    let value =
      currentUnixDate <= UnixDate
        ? differenceFrt?.days
        : -1 * differenceFrt?.days;
    obj = {
      value: [value, "D"],
      timer: 0,
      percentage: percentage,
      progress: percentage,
    };
  } else if (differenceFrt?.days * 24 + differenceFrt?.hours >= 24) {
    // at this case when differance by hours < 36 && hours >= 24 we return it as days and return time = 3600000 to run counter live every hour.
    let value = currentUnixDate <= UnixDate ? 1 : -1;
    obj = {
      value: [value, "D"],
      timer: 3600000,
      percentage: percentage,
      progress: percentage,
    };
  } else if (differenceFrt?.days * 24 + differenceFrt?.hours > 1) {
    // at this case when differance by hours < 24 && hours > 1 we return it as hours and return time = 3600000 to run counter live every hour.
    let value =
      currentUnixDate <= UnixDate
        ? differenceFrt?.days * 24 + differenceFrt?.hours
        : -1 * (differenceFrt?.days * 24 + differenceFrt?.hours);
    obj = {
      value: [value, "H"],
      timer: 3600000,
      percentage: percentage,
      progress: percentage,
    };
  } else if (differenceFrt?.hours * 60 + differenceFrt?.minutes >= 60) {
    // at this case when differance by hours <= 1 && minutes >= 60 we return it as one hour and return time = 60000 to run counter live every minute.
    let value = currentUnixDate <= UnixDate ? 1 : -1;
    obj = {
      value: [value, "H"],
      timer: 60000,
      percentage: percentage,
      progress: percentage,
    };
  } else if (differenceFrt?.minutes < 60 && differenceFrt?.minutes > 1) {
    // at this case when differance by  minutes < 60 && minutes > 1 we return it as minutes and return time = 60000 to run counter live every minute.

    let value =
      currentUnixDate <= UnixDate
        ? differenceFrt?.minutes
        : -1 * differenceFrt?.minutes;
    obj = {
      value: [value, "M"],
      timer: 60000,
      percentage: percentage,
      progress: percentage,
    };
  } else if (differenceFrt?.minutes == 1) {
    // at this case when differance by  minutes == 1 we return it as one minute and return time = 1000 to run counter live every second.
    let value = currentUnixDate <= UnixDate ? 1 : -1;
    obj = {
      value: [value, "M"],
      timer: 1000,
      percentage: percentage,
      progress: percentage,
    };
  } else if (differenceFrt?.minutes < 1) {
    // at this case when differance by  minutes < 1 we return it as seconds and return time = 1000 to run counter live every second.
    let value =
      currentUnixDate <= UnixDate
        ? differenceFrt?.seconds + differenceFrt?.minutes * 60
        : -1 * (differenceFrt?.seconds + differenceFrt?.minutes * 60);
    obj = {
      value: [value, "S"],
      timer: 1000,
      percentage: percentage,
      progress: percentage,
    };
  }
  return obj;
};
const emailValidator = (email) => {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

const isArabic = (text) => {
  const arabic = /[\u0600-\u06FF]/;
  return arabic.test(text);
};

const passwordValidator = (password) => {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/i;
  return re.test(String(password).toLowerCase());
};

const getPercentage = (used, total) => {
  return parseFloat((used / total) * 100).toFixed(1);
};

const checkProgressExceeded = (used, quota) => {
  if (used > quota) return "100%";
};

const isUserPermitted = (name, action = 1) => {
  /**
   * action is either 1 or 2
   * 1 -> view
   * 2 -> manage
   */
  if (localStorage.getItem("role_id") === "2") return true;
  return JSON.parse(localStorage.getItem("permissions"))[name] >= action;
};

//SUBTRACT TIMEZONE FROM THE DATE
//SEND toUnix===TRUE TO CHANGE THE DATE TO UNIX
//SEND fromUnix===TRUE  To Chnage unix to date
//formatStructure send the structure that should be shown to user
//if formatStructure is not sent it will return "DD-MM-YYYY HH:mm:ss"
const getGmtOffsetDate = (date, toUnix, formatStructure, fromUnix) => {
  let timeZone = getTimeZone();
  if (toUnix === true) {
    return moment(date).subtract(timeZone, "hours").unix();
  } else if (fromUnix === true) {
    return moment
      .unix(date)
      .utc()
      .subtract(timeZone, "hours")
      .format(formatStructure);
  } else {
    return formatStructure
      ? moment(date).subtract(timeZone, "hours").format(formatStructure)
      : moment(date).subtract(timeZone, "hours").format("DD-MM-YYYY HH:mm:ss");
  }
};

const HmsToSeconds = (days, average_waiting_time) => {
  const daysToSeconds = Number(days) * 24 * 3600;
  const time = average_waiting_time?.split(":");
  return (
    Number(time[0]) * 3600 +
    Number(time[1]) * 60 +
    Number(time[2]) +
    Number(daysToSeconds)
  );
};

//CHANGES NUMBER TO 0D:0H:0M:0S
const secondsToDays = (average_waiting_time) => {
  if (
    average_waiting_time === 0 ||
    average_waiting_time === null ||
    average_waiting_time === undefined ||
    average_waiting_time === "None"
  ) {
    return "0:0:0:0".split(":");
  } else {
    average_waiting_time = Number(Math.ceil(average_waiting_time));

    var d = Math.floor(average_waiting_time / (3600 * 24));
    var h = Math.floor((average_waiting_time % (3600 * 24)) / 3600);
    var m = Math.floor((average_waiting_time % 3600) / 60);
    var s = Math.floor(average_waiting_time % 60);

    var dDisplay = d > 0 ? d : "0";
    var hDisplay = h > 0 ? h : "0";
    var mDisplay = m > 0 ? m : "0";
    var sDisplay = s > 0 ? s : "0";
    let avgWaitingTime = (
      dDisplay + ":" + hDisplay + ":" + mDisplay + ":" + sDisplay || "0:0:0:0"
    ).split(":");
    return avgWaitingTime;
  }
};

//CHANGES NUMBER TO 0H:0M:0S
const secondsToHms = (average_waiting_time) => {
  if (
    average_waiting_time === 0 ||
    average_waiting_time === null ||
    average_waiting_time === undefined ||
    average_waiting_time === "None"
  ) {
    return "0:0:0".split(":");
  } else {
    average_waiting_time = Number(Math.ceil(average_waiting_time));
    var h = Math.floor(average_waiting_time / 3600);
    var m = Math.floor((average_waiting_time % 3600) / 60);
    var s = Math.floor((average_waiting_time % 3600) % 60);
    var hDisplay = h > 0 ? h : "0";
    var mDisplay = m > 0 ? m : "0";
    var sDisplay = s > 0 ? s : "0";
    let avgWaitingTime = (
      hDisplay + ":" + mDisplay + ":" + sDisplay || "0:0:0"
    ).split(":");
    return avgWaitingTime;
  }
};

const delayFunction = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

//handle user Roles
const handlelUserRoles = (type, role) => {
  let enabled;
  if (window.localStorage.user_roles || window.localStorage.advansed_roles) {
    switch (type) {
      case "GENERAL":
        enabled = JSON.parse(window.localStorage.user_roles)?.GENERAL?.includes(
          role,
        );
        break;
      case "CXM":
        enabled = JSON.parse(window.localStorage.user_roles)?.CXM?.includes(
          role,
        );
        break;
      case "SM":
        enabled = JSON.parse(window.localStorage.user_roles)?.SM?.includes(
          role,
        );
        break;
      case "SURVEY":
        enabled = JSON.parse(window.localStorage.user_roles)?.SURVEY?.includes(
          role,
        );
        break;
      case "CDP":
        enabled = JSON.parse(window.localStorage.user_roles)?.CDP?.includes(
          role,
        );
        break;
      case "ENGAGEMENTS":
        enabled = JSON.parse(
          window.localStorage.user_roles,
        )?.ENGAGEMENTS?.includes(role);
        break;
      case "PUBLIC_API":
        enabled = JSON.parse(
          window.localStorage.user_roles,
        )?.PUBLIC_API?.includes(role);
        break;
      case "ENGAGEMENT_ACCESS":
        enabled = false;
        if (window.localStorage.advansed_roles) {
          enabled = JSON.parse(
            window.localStorage.advansed_roles,
          )?.ENGAGEMENT_ACCESS?.includes(role);
        }
        break;
      case "SAVED_REPLIES":
        enabled = false;
        if (window.localStorage.advansed_roles) {
          enabled = JSON.parse(
            window.localStorage.advansed_roles,
          )?.SAVED_REPLIES?.includes(role);
        }
        break;
      case "ENGAGEMENT_TABS":
        enabled = false;
        if (window.localStorage.advansed_roles) {
          enabled = JSON.parse(
            window.localStorage.advansed_roles,
          )?.ENGAGEMENT_TABS?.includes(role);
        }
        break;
      case "AI_AGENT":
        enabled = JSON.parse(
          window.localStorage.user_roles,
        )?.AI_AGENT?.includes(role);
        break;
      default:
        break;
    }
  } else {
    enabled = false;
  }
  return enabled;
};
//handle active feature for every Company
const handleActiveFeature = (type, feature) => {
  let enabled;
  if (window?.localStorage?.active_features) {
    switch (type) {
      case "CXM":
        enabled = JSON.parse(
          window?.localStorage?.active_features,
        )?.CXM?.includes(feature);
        break;
      case "SM":
        enabled = JSON.parse(
          window?.localStorage?.active_features,
        )?.SM?.includes(feature);
        break;
      case "SURVEY":
        enabled = JSON.parse(
          window?.localStorage?.active_features,
        ).SURVEY?.includes(feature);
        break;
      case "CDP":
        enabled = JSON.parse(
          window?.localStorage?.active_features,
        )?.CDP?.includes(feature);
        break;
      default:
        break;
    }
  } else {
    enabled = false;
  }
  return enabled;
};

const commarize = (value) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(value)) >= 1.0e9
    ? Math.abs(Number(value)) / 1.0e9 + "B"
    : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
      ? Math.abs(Number(value)) / 1.0e6 + "M"
      : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? Math.abs(Number(value)) / 1.0e3 + "K"
        : Math.abs(Number(value));
};

// Remove last zeros in Decimal
const removeTrailingZeros = (num) => {
  const numStr = String(num);
  if (numStr?.endsWith(".00")) return Number(numStr?.slice(0, -3));
  else return num;
};

const checkKeyboardLanguage = (value) => {
  if (value) {
    var regex = //English letters + special chars
        /^[a-zA-Z0-9§±!^\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\.,؛؟،#_"' ]+$/g,
      a = value.match(regex);
    if (a && window.localStorage.lang === "en") {
      return false; //English dinrection , ltr
    } else if (!a && window.localStorage.lang === "en") {
      return true; //Arabic dinrection , rtl
    } else if (a && window.localStorage.lang === "ar") {
      return false; //English dinrection , ltr
    } else if (!a && window.localStorage.lang === "ar") {
      return true; //Arabic dinrection , rtl
    }
  } else {
    if (window.localStorage.lang === "en") {
      return false; //English dinrection , ltr
    } else if (window.localStorage.lang === "ar") {
      return true; //Arabic dinrection , rtl
    }
  }
};

//here this is used for get a date format like this 12/01/22
const dateFormRefactor = (date) => {
  return moment.unix(date).utc().format("DD/MM/YYYY");
};
const commasAfterDigit = (number) => {
  let res = number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return res;
};

//fun convert to D H M S

const convertDataToDhms = (value, intl) => {
  let result = "";
  if (value?.week || value?.day || value?.hour || value?.min || value?.sec) {
    if (value?.week) {
      result += ` <span> <span>${value?.week}</span>${CheckValueLocale(
        "weeks",
        "",
        {},
        intl,
      )}</span> `;
    }
    if (value?.day) {
      result += ` <span> <span>${value?.day}</span> ${CheckValueLocale(
        "response_time_day",
        "",
        {},
        intl,
      )}</span> `;
    }
    if (value?.hour) {
      result += ` <span> <span>${value?.hour}</span> ${CheckValueLocale(
        "hour",
        "",
        {},
        intl,
      )}</span> `;
    }
    if (value?.min) {
      result += `<span> <span>${value?.min}</span> ${CheckValueLocale(
        "minutes",
        "",
        {},
        intl,
      )}</span> `;
    }
    if (value?.sec) {
      result += ` <span> <span>${value?.sec}</span> ${CheckValueLocale(
        "seconds",
        "",
        {},
        intl,
      )}</span> `;
    }
  } else {
    result = 0;
  }
  return result;
};

// it is used to convert long number into abbrevated string
// & add suffix like "K", "M", "G", "T", "P", "E", "Z", "Y"

const numberFormatter = (num) => {
  const num_ABBRS = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];
  const i = 0 === num ? num : Math.floor(Math.log(num) / Math.log(1000));
  let result = parseFloat((num / Math.pow(1000, i)).toFixed(2));
  result += `${num_ABBRS[i]}`;
  return result;
};

const handleUnixFormat = (dateToConvert) => {
  const date =
    dateToConvert === ""
      ? ""
      : moment.unix(dateToConvert).utc().format("DD-MM-YYYY");
  return date;
};
const isEmojis = (string) => {
  let result =
    string.search(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u201B]|[\u201F-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ) !== -1;

  return result;
};

const truncate = (input, count) =>
  input?.length > count ? `${input?.substring(0, count)}...` : input;

// This Function built to accumlate all un known | Strange sources in one type of data reached from ReabbitMQ & assign them to Others value.
const translateLocalsFromRabbitMQ = (eventName, data) => {
  // evenName: the name of the event
  // data: Array of Object

  let sumOthers = 0,
    newArr = [];
  data.map((element) => {
    if (
      eventName.includes("languages") &&
      (element.name === "qme" ||
        element.name === "zxx" ||
        element.name === "qam" ||
        element.name === "qht" ||
        element.name === "art" ||
        element.name === "in")
    ) {
      sumOthers += element.value;
    } else {
      newArr.push({ name: element.name, value: element.value });
    }
  });

  if (sumOthers > 0) {
    newArr.push({
      name: "others",
      value: sumOthers,
    });
  }

  return newArr;
};

//convert to arabic numbers
const converToArabicNumbers = (n) =>
  n?.replace(/\d/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d])?.replace(/\./g, "٫");

//handle sentiment
const handleSentiment = (totalLabel) => {
  let icon, label, color;
  switch (totalLabel) {
    case "negative":
    case 0:
      icon = "negative";
      label = faAngry;
      color = "#E50C35";
      break;
    case "positive":
    case 1:
      icon = "positive";
      label = faSmile;
      color = "#89BB2A";
      break;
    case "neutral":
    case 2:
      icon = "neutral";
      label = faMeh;
      color = "#F9A700";
      break;
    default:
      icon = "none";
      label = faMeh;
      color = "#80868c";
      break;
  }
  return { icon, label, color };
};

// Handle Sentiment Analysis (StackedBarChart) Response
const handleSentimentResponse = (data) => {
  let negativeArray = [];
  let positiveArray = [];
  let neutralArray = [];
  if (data) {
    Object.entries(data)?.forEach(([key, value]) => {
      switch (key) {
        case "negative":
          value?.map((i) => {
            return negativeArray?.push(i);
          });
          break;
        case "positive":
          value?.map((i) => {
            return positiveArray?.push(i);
          });
          break;
        case "neutral":
          value?.map((i) => {
            return neutralArray?.push(i);
          });
          break;
        default:
          break;
      }
    });

    return {
      negative: negativeArray,
      positive: positiveArray,
      neutral: neutralArray,
    };
  }
};

// Handle Gender Age Distribution (StackedBarChart) Response
const handleGenderAgeDistResponse = (data) => {
  let newData = [];

  if (data) {
    data?.map((item) => {
      Object.keys(item)?.map((val, index) => {
        newData = [
          ...newData,
          { name: val, value: Object.values(item)?.[index] },
        ];
      });
    });
  }

  return newData;
};

// ** Any change here will affect [Custom Dashboard StackedBar (Comparison Widget)] ** //
// Get Top 5 Values + Others for StackBarChart State
const handleTopFiveForStackedBar = (dataObj, intl) => {
  const { data, pie_data } = dataObj;

  const othersName = CheckValueLocale("other", "", {}, intl);

  // Sort pie_data by value and get top 5
  const sortedPieData = [...pie_data]?.sort((a, b) => b?.value - a?.value);
  const topPieData = sortedPieData?.slice(0, 5);

  // Calculate the sum of others
  const othersSum = sortedPieData
    ?.slice(5)
    ?.reduce((acc, { value }) => acc + value, 0);

  // Check if there is "Others" or not
  let othersKey = topPieData?.find((item) =>
    ["Others", "others", "أخرى"]?.includes(item?.name),
  );
  if (othersKey) othersKey.value += othersSum;
  else topPieData?.push({ name: othersName, value: othersSum });

  // Mapping pie_data names
  const nameToIndexMap = topPieData?.reduce((acc, item, index) => {
    acc[item?.name] = index;
    return acc;
  }, {});

  // Create new value array for each value in data array
  const newData = data?.map(({ name, value, dataSource }) => {
    const newValueArray = new Array(topPieData?.length)?.fill(0);

    value?.forEach((val, i) => {
      const pieName = pie_data?.[i]?.name;
      const index =
        nameToIndexMap[pieName] !== undefined
          ? nameToIndexMap[pieName]
          : nameToIndexMap[othersName];
      newValueArray[index] += val;
    });

    return { name, value: newValueArray, dataSource };
  });

  return { data: newData, pie_data: topPieData };
};

// from {key1:value1 , key2:value2 ... } to [{name:key1,value:value1} , {name:key2,value:value2},..]
const changeFromObjectToArrayOfObject = (obj) => {
  if (obj) {
    const finalResult = [];
    Object.keys(obj)?.map((val, index) => {
      finalResult?.push({
        name: val,
        value: Object.values(obj)?.[index],
      });
    });
    return finalResult;
  }
};

//function to handle BE Locales
/*
* this function to check value if returned from BE or not to prevent error appear from intl.formatMessage
* here we pass :
 val: value that returned from BE.
 defaultValue: default value if BE not retuned it, pass "" if there is no default value
 injectedWord: if there is any key or params that need to be injected in string , it should pass as object:
 {name{name of value that need to pass}, value{value should passed}
 ex1:  {"dashboardId": ${dashabordsList.id}}
 ex2: {"activeDataSourceName": "FACEBOOK"}
 Function ex: 
 CheckValueLocale(val,"", {},intl)
*/

const CheckValueLocale = (val, defaultValue, injectedWord, intl) => {
  let defaultCaseValues = {
    project_name_en: Services?.project_name_en,
    project_name_ar: Services?.project_name_ar,
    ai_product_name_en: Services?.ai_product_name_en,
    ai_product_name_ar: Services?.ai_product_name_ar,
    companyURL: Services?.companyURL,
    productSupportEmail: Services?.productSupportEmail,
    prouctSurveyURL: Services?.prouctSurveyURL,
  };
  let result = "";
  let injectedWordObj = {
    ...defaultCaseValues,
    ...injectedWord,
  };

  result = intl?.messages?.[val || ""]
    ? intl.formatMessage({ id: val }, injectedWordObj)
    : intl?.messages?.[defaultValue || ""]
      ? intl.formatMessage({ id: defaultValue })
      : val;
  return result;
};
// check email is valid format
const emailIsValid = (email) => {
  let reg = /^\w+([\.+-]?\w+)@\w+([\.+-]?\w+)(\.\w{2,3})+$/;
  let validRegex = !reg.test(email);
  return validRegex;
};

//remove special char and numbers
const isOnlyNumbers = (input) => {
  let reg = /[`~!$%^#&*()|+\=?؟;'":,.<>\{\}\[\]\\\/0123456789]/gi;
  let numbers = !input.match(reg);
  return numbers;
};

const isValidPhone = (phone) => {
  let reg =
    /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
  let validRegex = !reg.test(phone);
  return validRegex;
};

// remove special char without + -
const charForNumbers = (input) => {
  let value =
    input.search(/[§±!^\#\@\$%&\*\(\)\=\[\]{};:\\\|~<>\/\?\.,؛؟، ]/g) !== -1;
  return value;
};

const checkSpecialCharExist = (input) => {
  let reg = /[`~!$%^#&*()|+\=?؟;'":,.<>\{\}\[\]\\\/]/gi;
  let value = !input.match(reg);
  return value;
};

const whiteSpaces = (input) => {
  return /\s/.test(input);
};

const specialCharSearch = (input) => {
  let reg = /[`~!$%^#&*()|\=?؟;'":,<>\{\}\[\]\\\/]/gi;
  let value = !input.match(reg);
  return value;
};

// Return Active Products Array
const retrieveActiveProductsArray = () => {
  let allProducts =
      localStorage?.activeProducts && JSON.parse(localStorage?.activeProducts),
    activeProducts = [];
  allProducts &&
    allProducts?.map((product) => {
      if (product?.active) {
        activeProducts.push(product?.name);
      }
    });
  return activeProducts;
};

// get social SocialIcons
const getSocialIcon = (type, style, icon) => {
  let gmailIcon = icon ? (
    <FontAwesomeIcon icon={faEnvelope} />
  ) : (
    <img src={GmailIcon} alt="social" />
  );

  const SocialIcons = {
    FACEBOOK: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={publicFacebook} className="facebook" />
      </Box>
    ),
    FACEBOOK_DM: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={publicFacebook} className="facebook" />
      </Box>
    ),
    INSTAGRAM: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={publicInstagram} className="instagram" alt="social" />
      </Box>
    ),
    INSTAGRAM_DM: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={publicInstagram} className="instagram" alt="social" />
      </Box>
    ),
    TWITTER: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={xPlatform} className="x-platform" alt="social" />
      </Box>
    ),
    TWITTER_DM: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={xPlatform} className="x-platform" alt="social" />
      </Box>
    ),
    PRIVATETWITTER: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={xPlatform} className="x-platform" alt="social" />
      </Box>
    ),
    SOCIALNETWORKS: (
      <Box className={(style ||= "social-media-icon")}>
        <img className="social" alt="social" src={SocialnetworksIcon} />
      </Box>
    ),
    GMAIL: <Box className={(style ||= "social-media-icon")}>{gmailIcon}</Box>,
    INTERCOM: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="intercom" icon={faIntercom} />
      </Box>
    ),
    TALKWALKER: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="news-blogs" icon={faNewspaper} />
      </Box>
    ),
    NEWSBLOGS: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="news-blogs" icon={faNewspaper} />
      </Box>
    ),
    WHATSAPP: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="whats" icon={faWhatsapp} />
      </Box>
    ),
    GOOGLEMYBUSINESS: (
      <Box className={(style ||= "social-media-icon")}>
        <GoogleMyBusiness className="gmb" />
      </Box>
    ),
    GOOGLE: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={GoogleIcon} alt="social" />
      </Box>
    ),
    LINKEDIN: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="linkedin" icon={faLinkedinIn} />
      </Box>
    ),
    SURVEY: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={SurveyFilePen} alt="social" />
      </Box>
    ),
    ZENDESK: (
      <Box className={(style ||= "social-media-icon")}>
        <img className="zendesk" src={zendesk} alt="social" />
      </Box>
    ),
    GENESYS: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={Genesys} alt="social" className="genesys-social-icon" />
      </Box>
    ),
    TWITTER_public: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={xPlatform} alt="social" />
      </Box>
    ),
    FACEBOOK_public: (
      <Box className={(style ||= "social-media-icon")}>
        <img
          src={publicFacebook}
          alt="social"
          className="facebook-public-icon"
        />
      </Box>
    ),
    INSTAGRAM_public: (
      <Box className={(style ||= "social-media-icon")}>
        <img src={publicInstagram} alt="social" />
      </Box>
    ),
    TWITTER_private: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="twitter-prvate-icon" icon={faEnvelope} />
      </Box>
    ),
    FACEBOOK_private: (
      <Box className={(style ||= "social-media-icon")}>
        <img
          src={privateMessenger}
          alt="social"
          className="facebook-public-icon facebook-dm-icon"
        />
      </Box>
    ),
    INSTAGRAM_private: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon
          className="instagram-prvate-icon"
          icon={faFacebookMessenger}
        />
      </Box>
    ),
    "TWITTER[DM]": (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="twitter-prvate-icon" icon={faEnvelope} />
      </Box>
    ),
    "FACEBOOK[DM]": (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon
          className="facebook-prvate-icon"
          icon={faFacebookMessenger}
        />
      </Box>
    ),
    "INSTAGRAM[DM]": (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon
          className="instagram-prvate-icon"
          icon={faFacebookMessenger}
        />
      </Box>
    ),
    PRIVATEFACEBOOK: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="facebook" icon={faFacebook} />
      </Box>
    ),
    TIKTOK: (
      <Box className={(style ||= "social-media-icon")}>
        <img
          src={tikTokImage}
          alt="social"
          className="tiktok-datasource-icon tikTok-icon"
        />
      </Box>
    ),
    TIKTOK_FW: (
      <Box className={(style ||= "social-media-icon")}>
        <FontAwesomeIcon className="tiktok" icon={faTiktok} />
      </Box>
    ),
  };
  return SocialIcons[type];
};

const getDatasourceIconName = (type) => {
  const SocialIcons = {
    FACEBOOK: faFacebook,
    INSTAGRAM: faInstagram,
    TWITTER: faTwitter,
    TWITTER_PUBLIC: faTwitter,
    TALKWALKER: faNewspaper,
    NEWSBLOGS: faNewspaper,
    XPLATFORM: faXTwitter,
  };
  return SocialIcons[type] || "";
};

const handleDates = (dateToConvert, setDate) => {
  if (dateToConvert !== "") {
    let convertedDate = handleUnixFormat(dateToConvert);
    setDate(convertedDate);
  }
};

const handleMinMaxValues = (
  valueToCheck,
  date,
  setValue,
  valueOnWhichSet,
  setDate,
  action,
) => {
  if (action === "max" && valueToCheck >= valueOnWhichSet) {
    setValue(valueToCheck);
    if (date !== undefined) handleDates(date, setDate);
  }
  if (action === "min" && valueToCheck <= valueOnWhichSet) {
    setValue(valueToCheck);
    if (date !== undefined) handleDates(date, setDate);
  }
};

//here this is used for get a date format like this MM/DD/YYYY
const refactorDateForm = (date) => {
  return moment.unix(date).utc().format("MM/DD/YYYY");
};
const datediff = (first, second) => {
  return Math.round((second - first) / (1000 * 60 * 60 * 24) + 1);
};
const parseDate = (date) => {
  let mdy = date.split("/");
  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
};
const getNoOfDays = (startDate, endDate) => {
  const noOfDays = datediff(
    parseDate(refactorDateForm(startDate)),
    parseDate(refactorDateForm(endDate)),
  );
  return noOfDays;
};

const excludeFromArray = (arrayFromWhichExclude, valuesToExclude) => {
  for (let i = 0; i < valuesToExclude?.length; i++) {
    const index = arrayFromWhichExclude?.indexOf(valuesToExclude[i]);
    if (index !== -1) arrayFromWhichExclude?.splice(index, 1);
  }
};

const dateTotext = (date) => {
  const period = date.split(" To ");
  return `${moment
    .unix(Number(period[0]))
    .utc()
    .format("D MMM YYYY")} - ${moment
    .unix(Number(period[1]))
    .utc()
    .format("D MMM YYYY")}`;
};

const dateTotextMinusLastDay = (date) => {
  // use this function to Subtract day from first period to avoid wrong calc with [ Time Period Type]
  const period = date.split(" To ");
  return `${moment
    .unix(Number(period[0]))
    .utc()
    .format("D MMM YYYY")} - ${moment
    .unix(Number(period[1]) - 1)
    .utc()
    .format("D MMM YYYY")}`;
};

// to return unix timezone recieved from backend in "dd/mm/YYYY" format
const convertUnixTimeStampToUTCFormat = (unixTimestamp) => {
  let milliseconds = unixTimestamp * 1000,
    dateObject = new Date(milliseconds),
    dateFormat = dateObject.toISOString().split("T")[0],
    finalDateFormat = dateFormat.split("-").reverse().join("/");

  return finalDateFormat;
};

// to return unix timezone recieved from backend in "hh:MM PM/AM" format
const convertUnixTimeStampToTimeFormat = (unixTimestamp) => {
  let milliseconds = unixTimestamp * 1000,
    dateObject = new Date(milliseconds),
    timeFormat = dateObject.toISOString().split("T")[1],
    hours = timeFormat.split(":")[0],
    minutes = timeFormat.split(":")[1],
    ampm = "AM";

  if (hours >= 12) {
    if (hours > 12) {
      hours -= 12;
    }
    ampm = "PM";
  }
  if (hours == 0) {
    hours = 12;
  }
  return `${hours}:${minutes} ${ampm}`;
};

const handleCAFiltersIds = (selectedFilterParams, filterFields) => {
  let monitorListIds = [];
  let dmListIds = [];
  let waitingFilterIds = [];
  let monitorListFullName = [];
  let dmMonitorListFullName = [];
  let waitingFilterFullName = [];

  let allSelectedParams = { ...selectedFilterParams };

  if (
    !isEmptyObj(allSelectedParams) &&
    !isEmptyArray(allSelectedParams?.dm_list) &&
    allSelectedParams?.dm_list[0]?.toString()?.includes("-")
  ) {
    dmMonitorListFullName = allSelectedParams.dm_list;
    allSelectedParams.dm_list?.map((i) => {
      dmListIds.push(parseInt(i.split("-")[0]));
      allSelectedParams = { ...allSelectedParams, dm_list: dmListIds };
    });
  }

  if (
    !isEmptyObj(allSelectedParams) &&
    !isEmptyArray(allSelectedParams?.monitor_list) &&
    allSelectedParams?.monitor_list[0]?.toString()?.includes("-")
  ) {
    monitorListFullName = allSelectedParams.monitor_list;
    allSelectedParams.monitor_list?.map((i) => {
      monitorListIds.push(parseInt(i.split("-")[0]));
      allSelectedParams = {
        ...allSelectedParams,
        monitor_list: monitorListIds,
      };
    });
  }

  if (
    !isEmptyObj(allSelectedParams) &&
    !isEmptyArray(allSelectedParams?.waiting_filter) &&
    allSelectedParams?.waiting_filter[0]?.toString()?.includes("-")
  ) {
    waitingFilterFullName = allSelectedParams.waiting_filter;
    allSelectedParams.waiting_filter?.map((i) => {
      filterFields.by_interactions.waiting_filter.forEach((element) => {
        if (element.name === i) {
          waitingFilterIds.push(element.id);
          return { ...allSelectedParams, waiting_filter: waitingFilterIds };
        }
      });
    });
  }

  return {
    selectedFilterParams,
    dmMonitorListFullName,
    monitorListFullName,
    waitingFilterFullName,
  };
};

const handleCAFiltersVals = (
  dmMonitorListFullName,
  monitorListFullName,
  waitingFilterFullName,
  selectedFilterParams,
) => {
  if (dmMonitorListFullName?.length > 0) {
    selectedFilterParams = {
      ...selectedFilterParams,
      dm_list: dmMonitorListFullName,
    };
  }
  if (monitorListFullName?.length > 0) {
    selectedFilterParams = {
      ...selectedFilterParams,
      monitor_list: monitorListFullName,
    };
  }
  if (waitingFilterFullName?.length > 0) {
    selectedFilterParams = {
      ...selectedFilterParams,
      waiting_filter: waitingFilterFullName,
    };
  }

  if (Array.isArray(selectedFilterParams?.parent_id)) {
    selectedFilterParams = {
      ...selectedFilterParams,
      parent_id: null,
    };
  }

  return selectedFilterParams;
};

const handleFiltersParamsIds = (selectedFilterParams, filterFields) => {
  let filtersData = {
    ...selectedFilterParams,
  };
  let monitorListIds = [];
  let dmListIds = [];
  let waitingFilterIds = [];

  if (
    !isEmptyObj(filtersData) &&
    !isEmptyArray(filtersData?.dm_list) &&
    filtersData?.dm_list[0]?.toString()?.includes("-")
  ) {
    filtersData.dm_list?.map((i) => {
      dmListIds.push(parseInt(i?.split("-")[0]));
      filtersData.dm_list = dmListIds;
    });
  }

  if (
    !isEmptyObj(filtersData) &&
    !isEmptyArray(filtersData?.monitor_list) &&
    filtersData?.monitor_list[0]?.toString()?.includes("-")
  ) {
    filtersData.monitor_list?.map((i) => {
      monitorListIds.push(parseInt(i?.split("-")[0]));
      filtersData.monitor_list = monitorListIds;
    });
  }

  if (
    !isEmptyObj(filtersData) &&
    !isEmptyArray(filtersData?.waiting_filter) &&
    (filtersData?.waiting_filter[0]?.toString()?.includes("-") ||
      filtersData?.waiting_filter[0]
        ?.toString()
        ?.includes("more than 30 mins") ||
      filtersData?.waiting_filter[0]?.toString()?.includes("More than 30 mins"))
  ) {
    filtersData?.waiting_filter?.map((i) => {
      filterFields?.by_interactions?.waiting_filter?.forEach((element) => {
        if (element?.name?.toLowerCase() === i?.toLowerCase()) {
          waitingFilterIds.push(element?.id);
          return (filtersData.waiting_filter = waitingFilterIds);
        }
      });
    });
  }

  return filtersData;
};

const extractPathfromURLForIds = (pathName) => {
  return pathName?.replaceAll("/", "-")?.toLowerCase();
};
const handleApplicableFiltersCA = (filterFields, reduxFilterParams) => {
  const allEligibleFiltersByInteractions = filterFields?.by_interactions
    ? Object.keys(filterFields?.by_interactions)
    : [];
  const allEligibleFiltersByUsers = filterFields?.by_user
    ? Object.keys(filterFields?.by_user)
    : [];
  const allFilters = [
    ...allEligibleFiltersByInteractions,
    ...allEligibleFiltersByUsers,
  ];
  const isParentIdApplicable = filterFields?.parent_id === 0;

  if (isParentIdApplicable) {
    allFilters.push("parent_id");
  }

  let applicableFilters = { ...reduxFilterParams };

  for (const key in applicableFilters) {
    if (!allFilters.includes(key)) {
      if (key === "parent_id") {
        applicableFilters[key] = isParentIdApplicable
          ? filterFields.parent_id.toString()
          : null;
      }
    }
  }

  return applicableFilters;
};

const getDatasourceId = (dataSources, sourceName) => {
  return (
    dataSources?.length &&
    dataSources?.filter((item) => item?.source === sourceName)[0]
  );
};

const checkEmptyValuesinObjectOfArrays = (obj) => {
  var allEmpty = Object.keys(obj).every(function (key) {
    return obj[key]?.length === 0;
  });
  return allEmpty;
};

const getIdsFromFilter = (obj) => {
  let finalArr = { ...obj };
  let dmListArr = [];

  obj?.dm_list?.map((dmList) => {
    dmListArr.push(`${dmList?.id}-${dmList?.name}`);
  });

  finalArr = { ...finalArr, dm_list: dmListArr };

  let monitorListArr = [];

  obj?.monitor_list?.map((monitorList) => {
    monitorListArr?.push(`${monitorList?.id}-${monitorList?.name}`);
  });

  finalArr = { ...finalArr, monitor_list: monitorListArr };

  let waitingListArr = [];

  obj?.waiting_filter?.map((waitingList) => {
    waitingListArr?.push(`${waitingList?.name}`);
  });

  finalArr = { ...finalArr, waiting_filter: waitingListArr };

  return finalArr;
};

const convertToDhmsExcelSheet = (value, lang) => {
  let resalt = "";
  if (value?.week) {
    resalt += `${value?.week} ${lang["weeks"]} `;
  }
  if (value?.day) {
    resalt += `${value?.day} ${lang["response_time_day"]} `;
  }
  if (value?.hour) {
    resalt += `${value?.hour} ${lang["hour"]} `;
  }
  if (value?.min) {
    resalt += `${value?.min} ${lang["minutes"]} `;
  }
  if (value?.minute) {
    resalt += `${value?.minute} ${lang["minutes"]} `;
  }
  if (value?.sec) {
    resalt += `${value?.sec} ${lang["seconds"]} `;
  }
  if (value?.seconds) {
    resalt += `${value?.seconds} ${lang["seconds"]} `;
  }
  return resalt;
};
const pieChartSort = (data) => {
  return _.orderBy(data, ["name"], ["desc"]);
};

// Function to remove empty strings from an array
const removeEmptyStrings = (arr) => arr?.filter((item) => item?.trim() !== "");

const handleSubThemes = (type) => {
  const color = {
    "Products & Services": "#0876B9",
    Delivery: "#9CCEEA",
    "Portal/App": "#9FEA76",
    Payment: "#D6B6E0",
    "Customer Services": "#DCDCDC",
    default: "#FFC245",
  };

  return color[type] || color["default"];
};

const handleThemes = (type) => {
  const color = {
    Questions: "#199CDC",
    Complaint: "#E8002E",
    Compliment: "#23A40F",
    complement: "#23A40F",
    Complement: "#23A40F",
    default: "#D3D3D3",
  };

  return color[type] || color["default"];
};

const getNumberOfChar = (account, intl) => {
  let finalVal = "";
  if (account?.charAt(0) === "@") {
    let accountUpdated = account?.substring(1);
    finalVal =
      accountUpdated?.length > 14
        ? intl.locale === "en"
          ? "@" + accountUpdated?.substring(0, 14) + "..."
          : "..." + accountUpdated?.substring(0, 14) + "@"
        : intl.locale === "en"
          ? "@" + accountUpdated
          : accountUpdated + "@";
  } else {
    finalVal =
      account?.length > 14
        ? intl.locale === "en"
          ? account?.substring(0, 14) + "..."
          : "..." + account?.substring(0, 14)
        : account;
  }
  return finalVal;
};

const handleDateLangFormat = (date, format, lang) => {
  let timeZone = getTimeZone();
  return moment(date)
    .locale(lang || "en")
    .subtract(timeZone, "hours")
    .format(format);
};

/**
 * formateDateToLocaleString function returns a formatted string representing the date and time in the
 * format of "MM/DD/YYYY, LT" (e.g., "10/16/2023, 10:30 AM").
 */
const formateDateToLocaleString = (date) => {
  return moment.unix(date).utc().format("MM/DD/YYYY, LT ");
};

/**
 * formatGMTString returns the time zone offset from UTC of the host e.g: (GMT +3)
 */
const formatGMTString = (intl) => {
  return getTimeZone() > 0
    ? `(${CheckValueLocale("gmt", "", {}, intl)} +${getTimeZone()})`
    : `(${CheckValueLocale("gmt", "", {}, intl)} -${getTimeZone()} )`;
};

/**
 * On Facebook, Twitter and YouTube, you must have seen 1K, 2K, 10K or 1M, 10M written
 * on the number of followers or subscribers. This is called the abbreviation of the number.
 * formatNumberForCount function is used to convert the number to the abbreviated format.
 * @param {number} number - The number to be converted.
 * @returns {string} - The converted number.
 * @example
 * formatNumberForCount(999) // 999
 * formatNumberForCount(1000) // 1K
 * formatNumberForCount(1000000) // 1M
 */
const formatNumberForCount = (number) => {
  if (typeof number !== "number") return number;
  const abbreviations = ["", "K", "M", "B", "T"];

  if (number < 1000) {
    return number.toString();
  }
  // For numbers greater than or equal to 1000, we calculate the logarithm (base 10) of the number divided by 3.
  // This gives us the order of magnitude (0 for thousands, 1 for millions, 2 for billions, and so on).
  const log = Math.floor(Math.log10(number) / 3);
  // We divide the number by 1000 raised to the power of the calculated log, and use toFixed()
  // to round it to the specified precision.
  const scaledNumber = (number / Math.pow(1000, log)).toFixed(1);
  return scaledNumber + abbreviations[log];
};

/**
 * formatNumberWithCommars function is used to convert the number to the format with commas.
 *  For example, 10000 will be converted to 10,000.
 * @param {*} number
 * @returns {string} - The converted number.
 * @example
 * formatNumberWithCommars(10000) // 10,000
 * formatNumberWithCommars(1000000) // 1,000,000
 */
const formatNumberWithCommars = (number) => {
  if (typeof number !== "number") return number;
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
export default function listenForOutsideClicks(
  listening,
  setListening,
  menuRef,
  setIsOpen,
) {
  return () => {
    if (listening) return;
    if (!menuRef?.current) return;
    setListening(true);
    [`click`, `touchstart`]?.forEach((type) => {
      document?.addEventListener(`click`, (evt) => {
        const cur = menuRef?.current;
        const node = evt?.target;
        if (cur?.contains(node)) return;
        setIsOpen(false);
      });
    });
  };
}

/**
 * extractDisplayNameFromDataSource - Extracts the display name from data source object
 * @param {object} data  the data source object
 * @param {string | undefined} defaultName  the default name to be returned if no data source is provided
 * @returns {string} - The display name
 */
export const extractDisplayNameFromDataSource = (
  data = {},
  defaultName = null,
) => {
  let displayName = defaultName;
  if (
    data?.data_source?.toLowerCase?.() === "instagram" &&
    data?.profile_name
  ) {
    return data?.profile_name;
  }
  return displayName;
};

/**
 * extractProfilePictureFromDataSource - Extracts the profile picture from data source object
 * @param {object} data  the data source object
 * @param {string | undefined} defaultPicture  the default defaultPicture to be returned if no data source is provided
 * @returns {string} - The default picture
 */
export const extractProfilePictureFromDataSource = (
  data = {},
  defaultPicture = null,
) => {
  let profilePicture = defaultPicture;
  if (
    data?.data_source?.toLowerCase?.() === "instagram" &&
    data?.profile_image_url
  ) {
    profilePicture = data?.profile_image_url;
  }
  return profilePicture;
};

/**
 * extractSubNameFromDataSource - Extracts the sub name from data source object
 * @param {object} data  the data source object
 * @param {string | undefined} defaultName  the default name to be returned if no data source is provided
 * @returns {string} - The sub name
 */
export const extractSubNameFromDataSource = (data = {}, defaultName = null) => {
  let subName = defaultName;
  // incase of instagram we want to show the profile name which is the first name + last name first
  // then the username which is the handle of the user
  // but of the profile name is not available we will show the username
  if (
    data?.data_source?.toLowerCase?.() === "instagram" &&
    data?.profile_name
  ) {
    subName = data?.user_name || data?.username;
  }
  return subName;
};

/**
 * extractDisplayInfoFromDataSource - Extracts the display information from data source object
 * @param {*} data - The data source object
 * @param {Object} defaultValues - The default values to be returned if no data source is provided
 * @returns {Object} - The display information
 * @example
 * const displayInfo = extractDisplayInfoFromDataSource(data)
 * console.log(displayInfo)
 * {
 *  profilePicture:string | null,
 *  displayName: string | null ,
 *  subName: string | null,
 * }
 */
export const extractDisplayInfoFromDataSource = (
  data,
  defaultValues = {
    displayName: null,
    profilePicture: null,
    subName: null,
  },
) => {
  if (isEmptyValue(data)) return defaultValues;
  const displayName = extractDisplayNameFromDataSource(
    data,
    defaultValues?.displayName,
  );
  const profilePicture = extractProfilePictureFromDataSource(
    data,
    defaultValues?.profilePicture,
  );
  const subName = extractSubNameFromDataSource(data, defaultValues?.subName);
  return {
    displayName,
    profilePicture,
    subName,
  };
};

const convertTwitterToXPlateform = (name) =>
  name === "TWITTER" ? "X_PLATEFORM" : name;
const convertXPlateformToTwitter = (name) =>
  name === "X_PLATEFORM" ? "TWITTER" : name;

const getSortedArray = (customOrder, orignalData) => {
  // make here custom order for orignalData
  const originalArray = [...orignalData];
  const sortedArray = originalArray?.sort((a, b) => {
    return customOrder?.indexOf(a) - customOrder?.indexOf(b);
  });
  return sortedArray;
};

const setCookie = (cookieName, cookieValue) => {
  var fdate = new Date();
  fdate.setDate(fdate.getDate() + 20 * 360);
  document.cookie = cookieName + "=" + cookieValue + "; expires=" + fdate;
};

const deleteCookie = (cookieName) => {
  var fdate = new Date();
  fdate.setDate(fdate.getDate() - 7);
  document.cookie = cookieName + "= ; expires=" + fdate;
};
/**
 * Calculates the Unix end date for a given created_at date by adding one day or more .
 *
 * @param {number} createdAt - The created_at date Unix start date in seconds.
 * @param {number} daysToAdd -days To Add to Unix start by default one
 * @returns {number} - The Unix end date in seconds.
 */
const getUnixEndDate = (createdAt, daysToAdd = 1) => {
  const createdAtDate = new Date(createdAt * 1000);
  createdAtDate.setDate(createdAtDate.getDate() + daysToAdd);
  return Math.floor(createdAtDate.getTime() / 1000);
};

// a regex to check if the string validate to a URL
const urlRegex =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

/**
 * sanitizeEngagementChatMessage function is used to sanitize the chat messages, replacing URLs with anchor tags
 * and escaping the rest of the message to render it as plain text.
 * @param {string} message  - The message to be sanitized
 * @returns {string} - The sanitized message
 */
export const sanitizeEngagementChatMessage = (message) => {
  // replace the URLs in the message with anchor tags
  message = message?.replace?.(urlRegex, (url) => {
    // check if the url does not have a scheme or which string+:+// is not present in the url then append the scheme
    let urlWithScheme = url.match(/^[a-zA-Z]+:\/\//) ? url : `https://${url}`;
    return `<a href="${urlWithScheme}" class="engagement-chat-injected-link-item" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  // Split the message by anchor tags and process parts separately
  const parts = message?.split?.(/(<a[^>]*>.*?<\/a>)/gi);

  return parts
    ?.map?.((part) => {
      // If the part is an anchor tag, sanitize it
      if (part?.match(/<a[^>]*>.*?<\/a>/gi)) {
        return sanitize(part, {
          ALLOWED_TAGS: ["a"],
          ALLOWED_ATTR: ["href", "target", "rel", "class"],
        });
      } else {
        // If the part is not an anchor tag, escape it
        // we are escaping the < and > characters to render them as plain text
        // and wehn we render the message we will use the dangerouslySetInnerHTML to render the message as HTML
        // which will parse the &lt; and &gt; to < and >
        return part?.replace(/</g, "&lt;")?.replace(/>/g, "&gt;");
      }
    })
    .join("");
};

// Utility function to convert newlines and spaces to HTML-friendly format
export const formatTextToHtml = (text) => {
  if (!text) return "";

  return text
    .replace(/(\r\n|\n\r|\r|\n)/g, "<br />") // Convert newlines to <br/>
    .replace(/  +/g, (match) => "&nbsp;".repeat(match?.length)) // Preserve multiple spaces
    .replace(/\t+/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Replace tab with four non-breaking spaces
    .trim();
};

const maskPhone = (phone) => {
  if (!phone) return phone;
  const maskedNumber = phone?.replace(/\d/g, "*");
  return maskedNumber;
};

const maskEmail = (email) => {
  if (email) {
    let [firstPart, secondPart] = email?.split("@");
    const secondPartTopLevelEmailDomain = secondPart?.split(".")[0];
    const secondPartTopLevelEmailDomainLength =
      secondPartTopLevelEmailDomain?.length;
    let slicedFirstPart = firstPart?.slice(0, -3);
    let slicedSecondPart = secondPart?.slice(
      0,
      -(2 + secondPartTopLevelEmailDomainLength),
    );

    let maskedEmail = `${slicedFirstPart}***@${slicedSecondPart}***`;
    return maskedEmail;
  }
};

const monitorErrorsNames = {
  loginErr: "auth_error_login",
  reauthErr: "account_valid_error",
  permissionErr: "auth_error_permission",
  accountTiktokErr: "account_tiktok_token_error",
};

const insertErrorListFromMonitor = (errorList, monitor, errorType) => {
  if (monitor?.[errorType] && monitor?.[errorType]?.length) {
    monitor?.[errorType]?.forEach((err) => {
      errorList.push({ errorMsg: errorType, dataSource: err });
    });
  }
};

const monitorErrors = (monitor) => {
  let accErrors = [];
  // we need to sort errors to show login errors first, then permission errors, then reauthentication errors, then other errors.
  const attributes = monitor?.attributes;
  // insert login errors first
  insertErrorListFromMonitor(
    accErrors,
    attributes,
    monitorErrorsNames.loginErr,
  );
  // insert permission errors
  insertErrorListFromMonitor(
    accErrors,
    attributes,
    monitorErrorsNames.permissionErr,
  );
  // insert reauthentication errors
  insertErrorListFromMonitor(
    accErrors,
    attributes,
    monitorErrorsNames.reauthErr,
  );
  // insert other errors in account error including tiktok token error
  if (
    monitor?.attributes?.account_error &&
    monitor?.attributes?.account_error?.length
  ) {
    monitor?.attributes?.account_error?.map((err) => {
      // if tiktok token error, we need to show it as a separate error, so we will flip the error message and data source.
      const errorMsg =
        err === monitorErrorsNames.accountTiktokErr
          ? err
          : monitorErrorsNames.reauthErr; // we used to show auth error for all errors except tiktok token error
      accErrors.push({ errorMsg, dataSource: err });
    });
  }
  return [...accErrors];
};

// Convert bytes into Bytes, KB or MB
const formatBytes = (bytes) => {
  const sizes = ["Bytes", "KB", "MB"];
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const i = Math?.floor(Math?.log(bytes) / Math?.log(k));
  const size = Math?.floor(bytes / Math?.pow(k, i));
  return `${size} ${sizes?.[i]}`;
};

const testChannelBotMessage = (value) => {
  if (!value) return true;
  return ![
    "*",
    "\u201D",
    "\u2019",
    "\\\\",
    "\\%",
    "&&",
    "||",
    "==",
    "<>",
    "><",
  ].some((char) => value.includes(char));
};

// Check if text contain arabic text or not
const checkArabicText = (postText) => {
  let arabicRegx = /[\u0600-\u06FF]/;
  let checkArabic = arabicRegx.test(postText !== null ? postText : "");
  return checkArabic;
};

// These are the types of error that is going to be returned to the user
// This function is used to get the file as a byte array
// This function is used to read the file as an array buffer
// This function is used to check if the file is valid or not based on its type and size
const isValidFile = async (event, maxFileSize) => {
  const returnMessage = {
    sizeError: "media_logo_size_limit",
    imgError: "unsupported_file_type",
    vidError: "vid_media_err",
    gifError: "gif_media_err",
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener("loadend", (e) => resolve(e.target.result));
      reader.addEventListener("error", reject);
      reader.readAsArrayBuffer(file);
    });
  };
  //byteFile is used to get the first 4 bytes of the file
  const byteFile = new Uint8Array(
    await readFile(event.target.files[0].slice(0, 4)),
  );

  const file = event.target.files[0];
  let validity = false;

  // Identify the file using magic numbers
  // These bytes double checks if the uploaded file is image or not. JPG files starts with
  // 255,216,255,224 and PNG files starts with 137,80,78,71
  const isNotJPEG =
    byteFile[0] != 255 ||
    byteFile[1] != 216 ||
    byteFile[2] != 255 ||
    byteFile[3] != 224;
  const isNotPNG =
    byteFile[0] != 137 ||
    byteFile[1] != 80 ||
    byteFile[2] != 78 ||
    byteFile[3] != 71;

  const validationMapping = {
    "gif-input": {
      type: "image/gif",
      error: returnMessage.gifError,
    },
    "video-input": {
      type: "video/mp4",
      error: returnMessage.vidError,
    },
    "image-input": {
      types: ["image/png", "image/jpeg"],
      error: returnMessage.imgError,
      magicNumberCheck: byteFile[0] === undefined || (isNotJPEG && isNotPNG),
    },
  };

  if (file) {
    if (file.size > maxFileSize) return [validity, returnMessage.sizeError];
    const validation = validationMapping[event.target.id];
    const isValidType = validation.types
      ? validation.types.includes(file.type) && !validation.magicNumberCheck
      : file.type === validation.type;
    return isValidType ? [(validity = true), ""] : [validity, validation.error];
  }
};

export const handleShowTabsCounter = (count, isMutable) => {
  if (isMutable) {
    return count < 1000 ? count : count === 1000 ? "1K" : "+1K";
  }
  return "+1K";
};

export const checkIfWeNeedShowMoreTweet = (tweetText, fullTweetText) => {
  // if tweetText or fullTweetText is empty then we don't need to show more
  if (!tweetText || !fullTweetText) return false;
  // if tweetText is equal to fullTweetText then we don't need to show more
  if (tweetText === fullTweetText) return false;
  // if tweetText is not equal to fullTweetText then we need to show more
  return true;
};

const getKnowledgeBaseFileSizeInKBOrMB = (size) => {
  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

const handleSingleDropdownMenuItem = (linksArray, history) => {
  const menuItems = linksArray?.filter((link) => link?.isActive);
  if (menuItems.length === 1) {
    history.push(menuItems[0].link);
  }
};

const validateUrlInjection = (url, maxLength) => {
  // Regex pattern to check if the URL is valid and follows a safe format
  const urlRegex =
    /^(https:\/\/)?([a-zA-Z\u0600-\u06FF0-9-]+\.)+[a-zA-Z\u0600-\u06FF]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/;

  // Forbidden patterns commonly used in XSS injection attacks
  const forbiddenPatterns = [
    /<script\b[^>]*>(.*?)<\/script>/gi, // Script tags
    /<img\b[^>]*onerror=["'].*["']/gi, // Image onerror handler
    /<a\b[^>]*javascript:.*["']/gi, // JavaScript in href
    /<iframe\b[^>]*src=["']javascript:.*["']/gi, // Iframe JavaScript src
    /<svg\b.*onload=["'].*["']/gi, // SVG with onload handler
    /<div\b[^>]*onclick=["'].*["']/gi, // Div with onclick handler
    /<meta\b[^>]*http-equiv=["']refresh["'][^>]*content=["'].*url=javascript:.*["']/gi, // Meta refresh to JS
    /<input\b[^>]*onfocus=["'].*["']/gi, // Input with onfocus handler
    /<style\b[^>]*>@import ["']javascript:.*["']/gi, // CSS import of JavaScript
    /<body\b[^>]*onload=["'].*["']/gi, // Body onload handler
    /<link\b[^>]*href=["']javascript:.*["']/gi, // Link with JavaScript href
    /<plaintext\b[^>]*>(.*?)<\/plaintext>/gi, // Plaintext XSS
    /<embed\b[^>]*src=["']javascript:.*["']/gi, // Embed with JavaScript src
    /<object\b[^>]*data=["']javascript:.*["']/gi, // Object with JavaScript data
    /data:text\/html;base64,/gi, // Base64-encoded scripts
    /on\w+=["'].*["']/gi, // Inline event handlers like onmouseover, onerror, etc.
    /eval\(/gi, // eval function usage
    /javascript:/gi, // javascript: URI usage
    /base64,/gi, // base64 encoded content
    /alert\(/gi, // Basic alert function call
    /<iframe\b[^>]*srcdoc=["'].*["']/gi, // iframe with srcdoc
    /<math\b[^>]*>(.*?)<\/math>/gi, // MathML XSS attack vectors
    /<audio\b[^>]*onerror=["'].*["']/gi, // Audio with onerror handler
    /<video\b[^>]*onerror=["'].*["']/gi, // Video with onerror handler
    /<bdo\b[^>]*onclick=["'].*["']/gi, // BDO with onclick handler
    /<marquee\b[^>]*onstart=["'].*["']/gi, // Marquee with onstart handler
    /<details\b[^>]*ontoggle=["'].*["']/gi, // Details with ontoggle handler
  ];

  if (url?.length) {
    let decodedUrl;

    // Safely decode the URL, catch malformed URI errors
    try {
      decodedUrl = decodeURIComponent(url);
    } catch (e) {
      return false;
    }

    if (maxLength !== undefined && url?.length >= maxLength)
      return { error: true, message: "url_exceeded_max_length" };

    // Check if the decoded URL matches the valid pattern
    if (!urlRegex.test(decodedUrl))
      return { error: true, message: "invalid_url_error_message" }; // Invalid URL format

    // Check if the decoded URL contains forbidden patterns
    for (let pattern of forbiddenPatterns) {
      if (pattern?.test(decodedUrl))
        return { error: true, message: "invalid_url_error_message" }; // Malicious pattern found
    }
  }

  return { error: false, message: null }; // URL passes validation
};

const isValidTwitterId = (id) => {
  return /^\d{1,19}$/.test(id);
};

const extractTwitterId = (text) => {
  const patterns = [
    /^(\d{1,19})$/,
    /twitter\.com\/\w+\/status\/(\d+)/,
    /x\.com\/\w+\/status\/(\d+)/,
    /mobile\.twitter\.com\/\w+\/status\/(\d+)/,
  ];
  for (let pattern of patterns) {
    const match = text?.match(pattern);
    if (match && match[1]) {
      return match[1];
    }
  }
  return null;
};

const isTwitterDomain = (text) => {
  try {
    const url = new URL(text);
    return (
      url.hostname === "twitter.com" ||
      url.hostname === "x.com" ||
      url.hostname === "mobile.twitter.com"
    );
  } catch {
    return false;
  }
};

const isValidURL = (text) => {
  try {
    new URL(text);
    return true;
  } catch {
    return false;
  }
};

const handleTwitterURLPaste = (pastedText) => {
  const trimmedText = pastedText.trim();
  let message = "";
  let extractedId = null;
  const numberOnlyRegex = /^[0-9]*$/;

  if (isValidURL(trimmedText) && isTwitterDomain(trimmedText)) {
    const potentialId = extractTwitterId(trimmedText);
    if (potentialId && isValidTwitterId(potentialId)) {
      extractedId = potentialId;
    } else {
      message = "twitter_parent_id_pasted_text_exceeded_max_length";
    }
  } else if (isValidTwitterId(trimmedText)) {
    extractedId = trimmedText;
  } else if (
    !isValidTwitterId(trimmedText) &&
    numberOnlyRegex.test(trimmedText)
  ) {
    message = "twitter_parent_id_pasted_text_exceeded_max_length";
  } else {
    message = "twitter_pasted_url_invalid";
  }

  return {
    extractedId,
    message,
  };
};

export {
  handleSingleDropdownMenuItem,
  handlelUserRoles,
  getGmtOffsetDate,
  isEmptyObj,
  getTimeZone,
  differenceBetweenDates,
  postTimeWithFormatAgo,
  timeCounter,
  emailValidator,
  passwordValidator,
  getPercentage,
  isEmptyArray,
  isUserPermitted,
  secondsToHms,
  secondsToDays,
  HmsToSeconds,
  delayFunction,
  commarize,
  removeTrailingZeros,
  checkKeyboardLanguage,
  commasAfterDigit,
  dateFormRefactor,
  convertDataToDhms,
  numberFormatter,
  handleUnixFormat,
  isEmojis,
  truncate,
  translateLocalsFromRabbitMQ,
  converToArabicNumbers,
  handleSentiment,
  handleSentimentResponse,
  handleGenderAgeDistResponse,
  handleActiveFeature,
  emailIsValid,
  isOnlyNumbers,
  charForNumbers,
  checkSpecialCharExist,
  whiteSpaces,
  isValidPhone,
  specialCharSearch,
  retrieveActiveProductsArray,
  CheckValueLocale,
  getSocialIcon,
  getDatasourceIconName,
  handleDates,
  handleMinMaxValues,
  getNoOfDays,
  excludeFromArray,
  isArabic,
  dateTotext,
  convertUnixTimeStampToUTCFormat,
  convertUnixTimeStampToTimeFormat,
  handleCAFiltersIds,
  handleCAFiltersVals,
  extractPathfromURLForIds,
  handleFiltersParamsIds,
  getDatasourceId,
  handleApplicableFiltersCA,
  checkEmptyValuesinObjectOfArrays,
  getIdsFromFilter,
  convertToDhmsExcelSheet,
  handleSubThemes,
  handleThemes,
  removeEmptyStrings,
  getNumberOfChar,
  pieChartSort,
  handleDateLangFormat,
  checkProgressExceeded,
  dateTotextMinusLastDay,
  classNames,
  formatGMTString,
  formateDateToLocaleString,
  formatNumberForCount,
  formatNumberWithCommars,
  listenForOutsideClicks,
  convertTwitterToXPlateform,
  convertXPlateformToTwitter,
  getSortedArray,
  setCookie,
  deleteCookie,
  getUnixEndDate,
  convertToSeconds,
  maskPhone,
  maskEmail,
  formatBytes,
  monitorErrorsNames,
  insertErrorListFromMonitor,
  monitorErrors,
  testChannelBotMessage,
  checkArabicText,
  isValidFile,
  getKnowledgeBaseFileSizeInKBOrMB,
  handleTopFiveForStackedBar,
  changeFromObjectToArrayOfObject,
  validateUrlInjection,
  handleTwitterURLPaste,
};
