import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, FormControl, Typography } from "@mui/material";
import styles from "../commonStyle.module.css";

const LinearScaleQuestion = (props) => {
  const { question, questionNo } = props;

  const [selectedValue, setSelectedValue] = useState();
  const [answers, setAnswers] = useState([]);
  useEffect(() => {
    let arr = [];
    for (
      let i = question?.question?.constraints?.min_value;
      i <= question?.question?.constraints?.max_value;
      i++
    ) {
      arr.push(i);
    }
    setAnswers(arr);
  }, [question]);

  const handleSelection = (value) => setSelectedValue(value);

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {question?.question?.text}
          {question?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle} style={{ marginBottom: "15px" }}>
          {question?.question?.description}
        </Box>
        <Box className={styles.scaleBox}>
          <FormControl className={styles.scaleFormControl}>
            <Box className={styles.scaleContainer}>
              {answers?.map((item, index) => {
                return (
                  <label
                    key={index}
                    className={`radio-item ${
                      (index === 0 || index === answers?.length - 1) &&
                      styles["valuesText"]
                    }`}
                  >
                    <input
                      type="radio"
                      checked={item === selectedValue && true}
                      value={selectedValue}
                      onChange={() => handleSelection(item)}
                    />
                    <Box component="span" className={"choose"}>
                      <Box>{item}</Box>
                    </Box>
                    {index === 0 && (
                      <Typography component={"p"}>
                        {question?.question?.constraints?.low_value}
                      </Typography>
                    )}
                    {index === answers?.length - 1 && (
                      <Typography component={"p"}>
                        {question?.question?.constraints?.high_value}
                      </Typography>
                    )}
                  </label>
                );
              })}
            </Box>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LinearScaleQuestion;
