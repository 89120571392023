import React, { useState, useRef } from "react";
import "./imageModal.scss";
import { useIntl } from "react-intl";
import Cropper from "react-easy-crop";
import { Slider, Button, Modal } from "@mui/material";
import getCroppedImg from "./cropImage";
import { CheckValueLocale } from "utils/helpers/index";

const ImageModal = (props) => {
  const intl = useIntl();
  const inputRef = useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();
  const [image, setImage] = useState(props.avatar);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };
  const onSubmit = async (event) => {
    if ((!image) instanceof File) {
      /* eslint-disable */
      image = new Blob([image], { type: "image/*" });
      image = new File([image], "image");
      /* eslint-enable */
    }
    getCroppedImg(image, croppedArea).then((result) => {
      props.setImage(result);
    });
  };

  return (
    <div id="modal">
      <Modal
        open={props.open}
        onClose={() => {
          props.handleModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="container">
          {image ? (
            <div className="container-cropper">
              <>
                <div className="cropper">
                  <Cropper
                    image={
                      image instanceof File ? URL.createObjectURL(image) : image
                    }
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>

                <div className="slider">
                  <Slider
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
              </>
            </div>
          ) : null}

          <div className="container-buttons">
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onSelectFile}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={triggerFileSelectPopup}
              style={{ marginRight: "10px" }}
              id="profile-browse-btn"
            >
              {CheckValueLocale("browse", "", {}, intl)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              id="profile-submit-btn"
            >
              {CheckValueLocale("submit", "", {}, intl)}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ImageModal;
