import React from "react";
import moment from "moment";

const handleExcelData = (props) => {
  let excelData = [];
  if (props?.monitorPublicType === "Monitor_vs_Monitors") {
    if (props?.benchmarkHeadData) {
      let tempArr = [];
      props?.benchmarkHeadData?.monitorData?.map((i) =>
        tempArr?.push(i?.monitor_name),
      );
      excelData?.push({ name: "monitors_name", value: tempArr });
    }
    if (props?.pageData)
      props?.pageData?.map((item) =>
        item?.value?.map((element) => excelData?.push(element)),
      );

    return [{ monitorVsMonitors: excelData }];
  } else if (props?.monitorPublicType === "Time_Period_vs_Time_Period") {
    if (props?.benchmarkHeadData) {
      let diff =
        86400000 *
        (moment
          .unix(props?.benchmarkHeadData?.toDate)
          .utcOffset(0)
          .diff(
            moment.unix(props?.benchmarkHeadData?.fromDate).utcOffset(0),
            `days`,
          ) +
          1);
      let tempArr = [
        `${moment
          .unix((props?.benchmarkHeadData?.fromDate * 1000 - diff) / 1000)
          .utcOffset(0)
          .format("D MMM YYYY")} - ${moment
          .unix((props?.benchmarkHeadData?.fromDate * 1000 - 86400000) / 1000)
          .utcOffset(0)
          .format("D MMM YYYY")}`,
        `${moment
          .unix(props?.benchmarkHeadData?.fromDate)
          .utcOffset(0)
          .format("D MMM YYYY")} - ${moment
          .unix(props?.benchmarkHeadData?.toDate)
          .utcOffset(0)
          .format("D MMM YYYY")}`,
      ];

      excelData?.push({ name: "time_periods", value: tempArr });
    }
    if (props?.pageData)
      props?.pageData?.map((item) =>
        item?.value?.map((element) => excelData?.push(element)),
      );
    return [{ timePeriods: excelData }];
  } else if (props?.monitorPublicType === "Positive_vs_Negative") {
    if (props?.pageData)
      props?.pageData?.map((item) =>
        item?.value?.map((element) => excelData?.push(element)),
      );
    return [{ positiveNegative: excelData }];
  }
};

export default handleExcelData;
