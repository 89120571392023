import { Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import moment from "moment";
import LucButton from "shared/lucButton/lucButton";
import HeaderAlerts from "./headerAlerts";
import { useUpgradeAiAgent } from "../../aiAgentsHooks/useUpgradeAiAgent";
import { useState } from "react";
import SnackBar from "components/snackBar";
import Services from "services/api/Services.js";

const AiAgentHeaderAlerts = ({
  alertType,
  handleCloseHeaderAlert,
  aiAgentDMReachDetails,
}) => {
  const intl = useIntl();
  const [aiAgentSnackBar, setAiAgentSnackBar] = useState({
    success: null,
    title: "",
    message: "",
  });
  const { mutate: upgradeAiAgent, isPending: upgradeAiAgentLoading } =
    useUpgradeAiAgent();

  const handleUpgradeAiAgent = () => {
    let queryData = {
      service_name: "request_ai_agent_upgrade",
    };
    upgradeAiAgent(queryData, {
      onSuccess: (data) => {
        if (!data?.errorMsg) {
          setAiAgentSnackBar({
            success: true,
            message: CheckValueLocale("request_upgrade_success", "", {}, intl),
          });
        } else {
          setAiAgentSnackBar({
            success: false,
            title: CheckValueLocale("failed_error_message", "", {}, intl),
            message: CheckValueLocale("try_again_error_message", "", {}, intl),
          });
        }
      },
    });
  };

  const headerMessage = () => {
    if (alertType === "aiAgentDown") {
      return (
        <>
          {CheckValueLocale(
            Services.showThirdCompany
              ? "ai_agent_down_header_message"
              : "ai_agent_down_header_message_no_read",
            "",
            {},
            intl,
          )}
          {Services.showThirdCompany ? (
            <a
              href="javascript:userpilot.trigger('resource_center:MOCMGJ8_k5')"
              className="ai-agent-down-support-team"
            >
              {CheckValueLocale("support_team", "", {}, intl)}
            </a>
          ) : null}
        </>
      );
    } else if (alertType === "dMLimitReached") {
      const dateInDays = moment(aiAgentDMReachDetails?.x_reset_time).format(
        "DD/MM/YYYY",
      );
      const dateInHours = moment(aiAgentDMReachDetails?.x_reset_time).format(
        "hh:mm A",
      );

      return CheckValueLocale(
        "ai_Agent_dm_limit_reached_header_message",
        "",
        {
          dateInHours,
          dateInDays,
        },
        intl,
      );
    } else if (alertType === "limitReached") {
      return CheckValueLocale(
        "ai_Agent_limit_reached_header_message",
        "",
        {},
        intl,
      );
    }
  };
  return (
    <>
      <HeaderAlerts
        message={headerMessage()}
        endSection={
          <Box className="ai-agent-limit-reached-end-section-container">
            {alertType === "limitReached" ? (
              <LucButton
                className="ai-agent-limit-reached-button"
                variant="text"
                size="small"
                onClick={handleUpgradeAiAgent}
                loading={upgradeAiAgentLoading}
                disabled={upgradeAiAgentLoading}
                id="ai-agent-button-request-upgrade"
              >
                {CheckValueLocale("request_upgrade", "", {}, intl)}
              </LucButton>
            ) : null}
            <IconButton size="small" onClick={handleCloseHeaderAlert}>
              <FontAwesomeIcon
                className="close-ai-agent-header-alert-icon"
                icon={faX}
              />
            </IconButton>
          </Box>
        }
      />
      {aiAgentSnackBar?.success != null ? (
        <SnackBar
          open={aiAgentSnackBar?.success != null ? true : false}
          handleClose={() => {
            setAiAgentSnackBar({
              success: null,
              title: "",
              message: "",
            });
          }}
          severity={aiAgentSnackBar?.success ? "success" : "error"}
          title={aiAgentSnackBar?.title}
          message={
            <Box className="ai-agent-snackBar-message">
              {aiAgentSnackBar?.message}
            </Box>
          }
        />
      ) : null}
    </>
  );
};

export default AiAgentHeaderAlerts;
