import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import "pages/Monitors/monitors.scss";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";

const CommentsMentionsBody = (props) => {
  const intl = useIntl();
  const {
    response,
    resetAllFields,
    changePagination,
    setTotalPage,
    totalPage,
    metricsValues,
    setMaxData,
    maxData,
    setPageNumber,
    pageNumber,
    monitorType,
    unixDate,
  } = props;

  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  const [commentsMentions, setCommentsMentions] = useState([]);
  const [commentsMentionsCount, setCommentsMentionsCount] = useState(0);
  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );

  const handleChangePage = (event, activePage) => {
    if (Boolean(activePage) === false) return;
    if (pageNumber === 1) {
      changePagination(activePage, totalPage, maxData);
    } else {
      changePagination(activePage);
    }
    setPageNumber(activePage);
  };

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitter__CommentsMentionsPage__top_posts_comments":
      case "Facebook__CommentsMentionsPage__top_posts_comments":
      case "Instagram__CommentsMentionsPage__top_posts_comments":
        response.eventData.top_posts?.map((post) => {
          post.datasourceTypePosts = response.data_source;
          setCommentsMentions((oldArray) => [...oldArray, post]);
        });
        if (response?.eventData?.top_posts_count !== undefined) {
          if (response.eventData.top_posts_count > maxData) {
            setMaxData(response.eventData.top_posts_count);
          }
          setTotalPage(totalPage + response.eventData.top_posts_count);
          setPageNumber(1);
        }
        setCommentsMentionsCount(
          commentsMentionsCount + response.eventData.top_posts_count,
        );
        setCommentsMentionsPreloader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (resetAllFields !== 1) {
      setCommentsMentions([]);
      setCommentsMentionsCount(0);
      setCommentsMentionsPreloader([]);
    }
  }, [resetAllFields]);

  return (
    <Box mt={2}>
      <QuestionCard
        unixDate={unixDate}
        showProfile={true}
        productId={window.localStorage?.getItem("sm_id")}
        latestUnanswer={commentsMentions}
        unAnsweredQuestion={totalPage}
        ShowPagination
        changePagination={changePagination}
        latestUnanswerPreloader={commentsMentionsPreloader}
        preLoaderTrackerDataSources={[
          ...metricsValues?.top_posts_comments?.data_sources,
        ]}
        showHeart={true}
        showDefaultImg={true}
        showScreenName={true}
        className={"no-data-found-question"}
        showRetweet={true}
        noOfChar={15}
        updateAnalysis={updateAnalysis}
        singleTweetAnalysis={props.singleTweetAnalysis}
        isAggregateDashboard
        isCommentsMentionsAggregate
        widget="comments_mentions"
        hideWhenRetweeted={monitorType === "ACCOUNT_ANALYSIS"}
      />
      {totalPage !== 0 &&
      totalPage > 1 &&
      commentsMentions?.length > 0 &&
      Math.ceil(maxData / 10) > 1 ? (
        <Pagination
          onChange={handleChangePage}
          page={pageNumber}
          className="pagination-monitor-list"
          count={Math.ceil(maxData / 10)}
          variant="outlined"
        />
      ) : (
        <div className="pagination-one-page"></div>
      )}

      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={stats?.modalTitle}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
      />
    </Box>
  );
};

export default CommentsMentionsBody;
