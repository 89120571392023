import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import {
  CustomFormControl,
  CustomSelect,
  CustomMenuItem,
  CustomListSubheader,
  CustomCheckboxMenuItem,
} from "../benchmark.styles";
import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import moment from "moment";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import { getSocialIcon, isEmptyObj } from "utils/helpers";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker.js";

// Fontawesome Icons
import TwitterIcon from "@mui/icons-material/Twitter";
import BenchmarkController from "services/controllers/benchmarkController";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

/*------------------------------------------------*/

const MenuUsers = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 195,
      overflowX: "auto",
    },
  },
};

const BenchmarkHeader = (props) => {
  const {
    type,
    comparisonTypes,
    informalList,
    setPreloaderDatasource,
    setRmqResponse,
    setResetTablesData,
    resetTablesData,
    setResetAllFields,
    setLuciDataLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setLuciMonitorType,
    setLuciComparisonType,
    setDataSourceName,
    vsTypeData,
    setVsTypeData,
    anotherTypeData,
    setAnotherTypeData,
    monitorType,
    setMonitorType,
    comparisonType,
    setComparisonType,
    filterBenchmark,
    setFilterBenchmark,
    setFilterFlag,
  } = props;
  const intl = useIntl();

  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState([]);
  const [filterError, setFilterError] = useState({});
  const [monitorTypes, setMonitorTypes] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [dataSource, setDataSource] = useState("");
  const [dataSourceMulti, setDataSourceMulti] = useState([]);
  const [monitorList, setMonitorList] = useState([]);
  useEffect(() => {
    setRmqResponse(response);
  }, [response]);

  useEffect(() => {
    setLuciMonitorType(monitorType);
  }, [monitorType]);

  const applyFilter = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (comparisonType) setLuciComparisonType(comparisonType);
    if (type === "sm" && !comparisonTypeMonitors && dataSource) {
      let dataSourceName = dataSources?.find(
        (el) => dataSource == el?.id,
      )?.name;
      setDataSourceName(dataSourceName);
    }
  }, [comparisonType, dataSource]);

  const comparisonTypeMonitors = comparisonType === "Monitor_vs_Monitors";

  const handleBenchmarkSubmit = () => {
    setLuciDataLoading(true);
    setResetTablesData(!resetTablesData);
    let filterErrors = {};
    if (!comparisonType) {
      filterErrors["comparisonType"] = CheckValueLocale(
        "benchmark_field_required",
        "",
        {},
        intl,
      );
    }
    if (!monitorType) {
      filterErrors["monitorType"] = CheckValueLocale(
        "benchmark_field_required",
        "",
        {},
        intl,
      );
    }
    if (!vsTypeData?.length) {
      if (!anotherTypeData) {
        filterErrors["monitorData"] = CheckValueLocale(
          "benchmark_field_required",
          "",
          {},
          intl,
        );
      }
    }
    if (!dataSourceMulti?.length) {
      if (!dataSource) {
        filterErrors["dataSource"] = CheckValueLocale(
          "benchmark_field_required",
          "",
          {},
          intl,
        );
      }
    }
    setFilterError({ ...filterErrors });
    if (Object.keys(filterErrors)?.length == 0) {
      setResetAllFields((resetAllFields) => resetAllFields + 1);
      let monitorData;
      let filterMonitorData;
      if (comparisonTypeMonitors) {
        monitorData = vsTypeData?.join(",");
        filterMonitorData =
          vsTypeData?.length > 0
            ? monitorList?.filter((val) =>
                vsTypeData?.includes(
                  val?.data_source_name + "+" + val?.monitor_id,
                ),
              )
            : [];
      } else {
        monitorData = anotherTypeData;
        filterMonitorData = anotherTypeData
          ? monitorList?.filter(
              (val) =>
                val?.monitor_id == anotherTypeData.split("+")[1] &&
                val?.data_source_name == anotherTypeData.split("+")[0],
            )
          : [];
      }
      const filterMonitorDataReducer = filterMonitorData?.reduce(
        (result, monitor) => {
          if (!result[monitor?.data_source_name?.toLowerCase()]) {
            result[monitor?.data_source_name?.toLowerCase()] = [];
          }
          if (
            !result[monitor?.data_source_name?.toLowerCase()]?.includes(
              monitor?.monitor_id,
            )
          ) {
            result[monitor?.data_source_name?.toLowerCase()]?.push(
              monitor?.monitor_id,
            );
          }
          return result;
        },
        {},
      );
      let monitorTypesResult;
      if (comparisonTypeMonitors) {
        const comparisonTypeArr = dataSources
          ?.filter((el) => dataSourceMulti?.includes(el?.id))
          ?.map((el) => `"${el?.monitorTypesList}"`);
        monitorTypesResult =
          "[" +
          comparisonTypeArr
            ?.filter(
              (item, index) => comparisonTypeArr?.indexOf(item) === index,
            )
            ?.join(",") +
          "]";
      } else {
        const comparisonTypeArr = dataSources?.find(
          (el) => dataSource == el?.id,
        )?.monitorTypesList;
        monitorTypesResult = '["' + comparisonTypeArr + '"]';
      }
      getMonitorBenchmark(
        filterMonitorDataReducer,
        comparisonType,
        monitorTypesResult,
      );
      props?.handleDatePickerApply(startDate, endDate);
      props?.setBenchmarkHeadData({
        monitorData: filterMonitorData,
        fromDate: startDate,
        toDate: endDate,
        monitor_type_name: monitorType,
      });
    }
  };

  const getMonitorBenchmark = (
    filterMonitorDataReducer,
    comparisonType,
    monitorTypesResult,
  ) => {
    setRmqResponse([]);
    props?.setMonitorLoading(true);
    props?.setMonitorPublicType(comparisonType);
    props?.setShowBenchmarkData(false);
    let filtersObj = {};
    Object.keys(filterBenchmark)?.map((key) => {
      if (filterBenchmark[key]?.length) {
        if (key !== "monitor_list" && key !== "hide_spam")
          filtersObj[key] = filterBenchmark?.[key]?.join(",");
        else filtersObj[key] = filterBenchmark?.[key];
      }
    });
    BenchmarkController.getMonitorBenchmark(
      startDate,
      endDate,
      filterMonitorDataReducer,
      monitorTypesResult,
      comparisonType,
      type === "sm" ? window.localStorage.sm_id : window.localStorage.cxm_id,
      !isEmptyObj(filtersObj) ? filtersObj : undefined,
    )
      .then((result) => {
        if (!isEmptyObj(result?.result)) {
          let monitorsDatasource = [];
          if (comparisonTypeMonitors) {
            let monitorsId = [];
            let monitorsIdObj = JSON.parse(result?.result?.data_sources);
            Object.keys(monitorsIdObj)?.map(
              (key) =>
                (monitorsId = [
                  ...monitorsId,
                  ...monitorsIdObj[key]?.map((id) => `${id}_${key}`),
                ]),
            );
            monitorsDatasource = [...monitorsId]?.map((id) => id?.toString());
          } else {
            monitorsDatasource = [...result?.result?.data_sources];
          }
          setPreloaderDatasource(monitorsDatasource);
          mqttRabbitMQResponce(clientMQTT, result?.result, setResponse);
        } else {
          mqttConnectionClosed.current = true;
        }
        props?.setMonitorLoading(false);
        props?.setShowBenchmarkData(true);
      })
      .then(() => {
        props?.setEmptyMsg(false);
      });
  };

  const getAllMonitors = (sources) => {
    let sourcesQuery;
    if (Array.isArray(sources)) {
      sourcesQuery = "[" + sources?.join(",") + "]";
    } else {
      sourcesQuery = "[" + sources + "]";
    }
    const productId =
      type === "sm" ? window.localStorage.sm_id : window.localStorage.cxm_id;
    let monitorTypesResult;
    if (Array.isArray(sources)) {
      const comparisonTypeArr = dataSources
        ?.filter((el) => sources?.includes(el?.id))
        ?.map((el) => `"${el?.monitorTypesList}"`);

      monitorTypesResult =
        "[" +
        comparisonTypeArr
          ?.filter((item, index) => comparisonTypeArr?.indexOf(item) === index)
          ?.join(",") +
        "]";
    } else {
      const comparisonTypeArr = dataSources?.find(
        (el) => sources == el?.id,
      )?.monitorTypesList;
      monitorTypesResult = '["' + comparisonTypeArr + '"]';
    }
    BenchmarkController.getAllMonitors(
      productId,
      sourcesQuery,
      monitorTypesResult,
    ).then((data) => {
      setMonitorList(data?.data);
    });
  };

  const handleComparisonTypeChange = (event) => {
    setComparisonType(event?.target?.value);
    setMonitorType("");
    setDataSource("");
    setDataSourceMulti([]);
    setVsTypeData([]);
    setAnotherTypeData("");
    setMonitorList([]);
  };

  const handleMonitorTypeChange = (event) => {
    setMonitorType(event?.target?.value);
    setDataSource("");
    setDataSourceMulti([]);
    setVsTypeData([]);
    setAnotherTypeData("");
    setMonitorList([]);
  };

  const handleDataSourceChange = (event) => {
    setDataSource(event?.target?.value);
    setVsTypeData([]);
    setAnotherTypeData("");
    setMonitorList([]);
    getAllMonitors(event?.target?.value);
  };

  const handleDataSourceMultiChange = (event) => {
    setDataSourceMulti(event?.target?.value);
    setVsTypeData([]);
    setAnotherTypeData("");
    setMonitorList([]);
    getAllMonitors(event?.target?.value);
  };

  const handleMonitorDataVsChange = (event) => {
    setVsTypeData(event?.target?.value);
  };

  const handleMonitorDataAnotherChange = (event) => {
    setAnotherTypeData(event?.target?.value);
  };

  const getMonitorTypesReducer = () => {
    let monitorTypesVar = informalList?.find(
      (el) => el?.benchmark_type === comparisonType,
    )?.monitor_types;
    const monitorTypesReducer = monitorTypesVar?.reduce(
      (result, monitor, i) => {
        monitor?.data_sources?.forEach((source) => {
          if (!result[source?.category]) {
            result[source?.category] = [];
          }
          if (
            !result[source?.category]?.includes({
              id: source?.id,
              name: source?.name,
              monitorTypesList: monitorTypesVar[i]?.monitor_type,
            })
          ) {
            result[source?.category]?.push({
              id: source?.id,
              name: source?.name,
              monitorTypesList: monitorTypesVar[i]?.monitor_type,
            });
          }
        });
        return result;
      },
      {},
    );
    return monitorTypesReducer;
  };
  useEffect(() => {
    if (!isEmptyObj(filterBenchmark || {})) {
      handleBenchmarkSubmit();
    }
  }, [filterBenchmark]);

  useEffect(() => {
    if (comparisonType && informalList?.length && type === "sm") {
      setMonitorTypes(
        informalList?.find((el) => el?.benchmark_type === comparisonType)
          ?.monitor_types,
      );
    }
  }, [comparisonType, informalList]);

  useEffect(() => {
    if (monitorType && monitorTypes?.length && type === "sm") {
      let dataSourcesArr =
        monitorTypes
          ?.find((el) => el?.monitor_type === monitorType)
          ?.data_sources?.map((el) => {
            return { ...el, monitorTypesList: monitorType };
          }) || [];
      if (monitorType == "MANAGED_ACCOUNT") {
        let dataSourcesPage =
          monitorTypes
            ?.find((el) => el?.monitor_type == "MANAGED_PAGE")
            ?.data_sources?.map((el) => {
              return { ...el, monitorTypesList: "MANAGED_PAGE" };
            }) || [];
        dataSourcesArr = [...dataSourcesArr, ...dataSourcesPage];
      }
      setDataSources(dataSourcesArr);
    }
  }, [monitorType, monitorTypes]);

  useEffect(() => {
    if (comparisonType && informalList?.length && type === "cxm") {
      const monitorTypesReducer = getMonitorTypesReducer();
      setMonitorTypes(Object.keys(monitorTypesReducer));
    }
  }, [comparisonType, informalList]);

  useEffect(() => {
    if (monitorType && monitorTypes?.length && type === "cxm") {
      const monitorTypesReducer = getMonitorTypesReducer();
      setDataSources(monitorTypesReducer[monitorType]);
    }
  }, [monitorType, monitorTypes]);

  const benchmarkBtnDisabled =
    comparisonType &&
    monitorType &&
    (dataSourceMulti?.length || dataSource) &&
    (vsTypeData?.length || anotherTypeData);

  return (
    <Box className="benchmark-header">
      <Typography variant="body2" className="benchmark-header-hint">
        {CheckValueLocale("overview_monitor", "", {}, intl)}
      </Typography>
      <Box className="benchmark-header-box">
        <CustomFormControl className="benchmark-container comparison-container">
          <InputLabel
            id="benchmark-comparison-type-label"
            className="select-comparison-title"
          >
            {CheckValueLocale("comparison_type", "", {}, intl)}
          </InputLabel>
          <CustomSelect
            labelId="benchmark-comparison-type-label"
            id="benchmark-comparison-type"
            value={comparisonType}
            label={`${CheckValueLocale("comparison_type", "", {}, intl)}`}
            onChange={handleComparisonTypeChange}
            error={filterError?.comparisonType ? true : false}
            className="dropdown-benchmark-items"
            disabled={!comparisonTypes?.length}
          >
            {comparisonTypes?.map((t) => (
              <CustomMenuItem
                key={`comparisonType-${t?.id}`}
                value={t?.name}
                className="benchmark-item"
                id={`benchmark-comparison-type-${t?.id ?? "comparison-type"}`}
              >
                {CheckValueLocale(t?.name + "_" + type, "", {}, intl)}
              </CustomMenuItem>
            ))}
          </CustomSelect>
          {filterError?.comparisonType && (
            <FormHelperText error>{filterError?.comparisonType}</FormHelperText>
          )}
        </CustomFormControl>
        <CustomFormControl className="benchmark-container monitor-type-container">
          <InputLabel
            id="benchmark-monitor-type-label"
            className="select-monitor-title"
          >
            {CheckValueLocale(
              type === "sm" ? "monitor_type" : "channel_type",
              "",
              {},
              intl,
            )}
          </InputLabel>
          <CustomSelect
            labelId="benchmark-monitor-type-label"
            id="benchmark-monitor-type"
            disabled={!comparisonType || !monitorTypes?.length}
            value={monitorType}
            label={`${CheckValueLocale(type === "sm" ? "monitor_type" : "channel_type", "", {}, intl)}`}
            onChange={handleMonitorTypeChange}
            error={filterError?.monitorType ? true : false}
            className="dropdown-benchmark-items"
          >
            {monitorTypes?.map((t, i) =>
              t?.monitor_type !== "MANAGED_PAGE" ? (
                <CustomMenuItem
                  key={`monitorType-${i}`}
                  value={t?.monitor_type || t}
                  className="benchmark-item"
                  id={`benchmark-monitor-type-${i ?? "monitor-type"}`}
                >
                  {CheckValueLocale(t?.monitor_type || t, "", {}, intl)}
                </CustomMenuItem>
              ) : null,
            )}
          </CustomSelect>
          {filterError?.monitorType && (
            <FormHelperText error>{filterError?.monitorType}</FormHelperText>
          )}
        </CustomFormControl>
        <CustomFormControl className="benchmark-container data-source-container">
          <InputLabel id="data-source-checkbox-label">
            {CheckValueLocale("data_source", "", {}, intl)}
          </InputLabel>
          <CustomSelect
            labelId="data-source-checkbox-label"
            id="data-source-checkbox"
            multiple={comparisonTypeMonitors}
            disabled={!monitorType || !dataSources?.length}
            value={comparisonTypeMonitors ? dataSourceMulti : dataSource}
            onChange={
              comparisonTypeMonitors
                ? handleDataSourceMultiChange
                : handleDataSourceChange
            }
            renderValue={
              comparisonTypeMonitors
                ? (selected) =>
                    selected?.length > 0
                      ? `${selected?.length} ${CheckValueLocale(
                          "benchmark_monitor_selected",
                          "",
                          {},
                          intl,
                        )}`
                      : ""
                : (selected) =>
                    CheckValueLocale(
                      dataSources?.find((source) => source?.id == selected)
                        ?.name,
                      "",
                      {},
                      intl,
                    )
            }
            MenuProps={MenuUsers}
            label={`${CheckValueLocale("data_source", "", {}, intl)}`}
            error={filterError?.dataSource ? true : false}
          >
            {dataSources?.map((source) =>
              comparisonTypeMonitors ? (
                <CustomCheckboxMenuItem
                  key={source?.id}
                  value={source?.id}
                  id={`social-media-data-source-item-${source?.id ?? "account-item"}`}
                >
                  <Checkbox
                    checked={dataSourceMulti?.indexOf(source?.id) > -1}
                    className="benchmark-checkbox"
                  />
                  {getSocialIcon(source?.name)}
                  <ListItemText
                    primary={CheckValueLocale(source?.name, "", {}, intl)}
                    className="benchmark-item-checkbox"
                  >
                    {CheckValueLocale(source?.name, "", {}, intl)}
                  </ListItemText>
                </CustomCheckboxMenuItem>
              ) : (
                <CustomMenuItem
                  key={source?.id}
                  value={source?.id}
                  className="benchmark-item"
                  id={`social-media-data-source-item-${source?.id ?? "account-item"}`}
                >
                  {getSocialIcon(source?.name)}
                  {CheckValueLocale(source?.name, "", {}, intl)}
                </CustomMenuItem>
              ),
            )}
          </CustomSelect>
          {filterError?.dataSource && (
            <FormHelperText error>{filterError?.dataSource}</FormHelperText>
          )}
        </CustomFormControl>
        {monitorList?.length ? (
          <CustomFormControl className="benchmark-container monitor-list-container last-benchmark-container">
            <InputLabel id="monitor-list-checkbox-label">
              {CheckValueLocale(
                type === "sm" ? "select_monitor" : "select_channel",
                "",
                {},
                intl,
              )}
            </InputLabel>

            <CustomSelect
              labelId="monitor-list-checkbox-label"
              id="monitor-list-checkbox"
              multiple={comparisonTypeMonitors}
              disabled={
                (comparisonTypeMonitors
                  ? !dataSourceMulti?.length
                  : !dataSource) || !monitorList?.length
              }
              value={comparisonTypeMonitors ? vsTypeData : anotherTypeData}
              onChange={
                comparisonTypeMonitors
                  ? handleMonitorDataVsChange
                  : handleMonitorDataAnotherChange
              }
              renderValue={
                comparisonTypeMonitors
                  ? (selected) =>
                      selected?.length > 0
                        ? `${selected?.length} ${CheckValueLocale(
                            "benchmark_monitor_selected",
                            "",
                            {},
                            intl,
                          )}`
                        : ""
                  : (selected) =>
                      monitorList
                        ?.find(
                          (monitor) =>
                            monitor?.data_source_name +
                              "+" +
                              monitor?.monitor_id ==
                            selected,
                        )
                        ?.monitor_name?.toLowerCase()
              }
              MenuProps={MenuUsers}
              label={`${CheckValueLocale(type === "sm" ? "select_monitor" : "select_channel", "", {}, intl)}`}
              error={filterError?.monitorData ? true : false}
            >
              {monitorList?.map((monitorItem, i) =>
                comparisonTypeMonitors ? (
                  <CustomCheckboxMenuItem
                    key={i}
                    value={
                      monitorItem?.data_source_name +
                      "+" +
                      monitorItem?.monitor_id
                    }
                    id={`social-media-monitor-list-item-${monitorItem?.monitor_id ?? "account-item"}`}
                    disabled={
                      vsTypeData?.length === 7 &&
                      !vsTypeData?.includes(
                        monitorItem?.data_source_name +
                          "+" +
                          monitorItem?.monitor_id,
                      )
                    }
                  >
                    <Checkbox
                      checked={
                        vsTypeData?.indexOf(
                          monitorItem?.data_source_name +
                            "+" +
                            monitorItem?.monitor_id,
                        ) > -1
                      }
                      className="benchmark-checkbox"
                    />
                    {getSocialIcon(monitorItem?.data_source_name)}
                    <ListItemText
                      primary={monitorItem?.monitor_name}
                      className="benchmark-item-checkbox"
                    >
                      {monitorItem?.monitor_name}
                    </ListItemText>
                  </CustomCheckboxMenuItem>
                ) : (
                  <CustomMenuItem
                    key={i}
                    value={
                      monitorItem?.data_source_name +
                      "+" +
                      monitorItem?.monitor_id
                    }
                    className="benchmark-item"
                    id={`social-media-monitor-list-item-${monitorItem?.monitor_id ?? "account-item"}`}
                  >
                    {getSocialIcon(monitorItem?.data_source_name)}
                    {monitorItem?.monitor_name}
                  </CustomMenuItem>
                ),
              )}
            </CustomSelect>
            {filterError?.monitorData && (
              <FormHelperText error>{filterError?.monitorData}</FormHelperText>
            )}
          </CustomFormControl>
        ) : (
          <CustomFormControl className="benchmark-container monitor-list-container last-benchmark-container">
            <InputLabel id="monitor-list-checkbox-label">
              {CheckValueLocale(
                type === "sm" ? "select_monitor" : "select_channel",
                "",
                {},
                intl,
              )}
            </InputLabel>
            <CustomSelect
              labelId="benchmark-comparison-type-label"
              value={comparisonTypeMonitors ? vsTypeData : anotherTypeData}
              disabled={
                comparisonTypeMonitors ? !dataSourceMulti?.length : !dataSource
              }
              id="benchmark-comparison-type"
              label={`${CheckValueLocale(type === "sm" ? "select_monitor" : "select_channel", "", {}, intl)}`}
              className="dropdown-benchmark-items"
            >
              <CustomMenuItem
                className="benchmark-item dont-have-monitors"
                disabled
              >
                {CheckValueLocale("dont_have_monitors", "", {}, intl)}
              </CustomMenuItem>
              <CustomMenuItem
                value={"Create new monitor"}
                className="benchmark-item create-monitor-link"
              >
                <a href="/social/monitors/create_monitor">
                  {CheckValueLocale("create_n_monitor", "", {}, intl)}
                </a>
              </CustomMenuItem>
            </CustomSelect>
            {filterError?.comparisonType && (
              <FormHelperText error>
                {filterError?.comparisonType}
              </FormHelperText>
            )}
          </CustomFormControl>
        )}
        <Box>
          <DateRangePicker
            applyFilter={applyFilter}
            startDate={startDate}
            endDate={endDate}
            openCustomDateLeft={true}
            isBenchMarkPage={true}
            id="date-range-picker-settings-benchmark"
            monitorActiveTabId="settings-benchmark"
          />
        </Box>
        <LucButton
          onClick={handleBenchmarkSubmit}
          id="settings-benchmark-btn"
          disabled={!benchmarkBtnDisabled}
        >
          {CheckValueLocale("benchmark_btn", "", {}, intl)}
        </LucButton>
      </Box>
    </Box>
  );
};

export default BenchmarkHeader;
