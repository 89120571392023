import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./faqs-list.scss";
import faqsList from "./faqs.json";
import ComplexFaq from "./ComplexFaq";

const FaqsList = ({
  appLang,
  handleChange,
  faqsExpanded,
  selectedfaqs,
  faqsList,
}) => {
  return (
    <>
      <div className="faqs-list">
        {faqsList[appLang].map((list, i) => (
          <Accordion
            key={i}
            expanded={
              faqsExpanded === "all" ||
              selectedfaqs.includes("panel" + list.number)
                ? true
                : false
            }
            onChange={handleChange("panel" + list.number)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${list.number}a-content`}
              id={`panel${list.number}a-header`}
            >
              <Typography variant="subtitle2">{`${list.number} . ${list.headers}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {list.type === "simple" ? (
                <Typography variant="body2" className="simple-faq">
                  {list.text}
                </Typography>
              ) : (
                <ComplexFaq data={list.data} />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default FaqsList;
