import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData } from "../excelCommonFn";

const engagements_ig_AA = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var commentsInteractions = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "commentsInteractions":
            value?.map((i) => {
              commentsInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value[0],
                [lang.likes]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData.push([commentsInteractions, lang.posts_interactions]);
            break;

          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { engagements_ig_AA };
