import Interactions from "services/api/interactions";

var InteractionsController = {};

InteractionsController.getDatasources = () => {
  return new Promise((resolve) => {
    Interactions.getDatasources()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InteractionsController.getInteractionsCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InteractionsController.getSingleInteraction = (
  id,
  tracker_id,
  data_source_id,
  product_id,
  apiVars,
  pageNumber,
  type,
  emailId,
) => {
  var queryData = {
    id,
    tracker_id,
    data_source_id,
    product_id,
  };
  if (pageNumber) {
    queryData.page_number = pageNumber;
  }
  if (type) {
    queryData.type = type;
  }
  if (emailId) {
    queryData.email_id = emailId;
  }
  return new Promise(function (resolve) {
    Interactions.getSingleInteraction(queryData, apiVars)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//whatsApp interactions
InteractionsController.getInteractionsWhatsCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsWhatsCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//intercom interactions
InteractionsController.getInteractionsIntercomCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsIntercomCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//twitter Public interactions
InteractionsController.getInteractionsTwitterPublicCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsTwitterPublicCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// twitter private credentials
InteractionsController.getInteractionsTwitterPrivateCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsTwitterPrivateCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//facebook Public interactions
InteractionsController.getInteractionsFacebookPublicCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsFacebookPublicCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// facebook Private Credentials
InteractionsController.getInteractionsFacebookPrivateCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsFacebookPrivateCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//instagram public interactions
InteractionsController.getInteractionsInstagramPublicCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsInstagramPublicCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Instagram Private Interactions
InteractionsController.getInteractionsInstagramPrivateCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsInstagramPrivateCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//linkedin interactions
InteractionsController.getInteractionsLinkedInCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsLinkedInCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//gmail interactions
InteractionsController.getInteractionsGmailCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsGmailCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//gmb interactions
InteractionsController.getInteractionsGMBCredentials = (
  start_date,
  end_date,
  filterParams,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsGMBCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InteractionsController.getSentiments = () => {
  return new Promise((resolve, reject) => {
    Interactions.getSentiments()
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
InteractionsController.getSentimentsPublic = (queryData) => {
  return new Promise((resolve, reject) => {
    Interactions.getSentimentsPublic(queryData)
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

InteractionsController.getSubThemes = () => {
  return new Promise((resolve, reject) => {
    Interactions.getSubThemes()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
InteractionsController.getSubThemesPublic = (queryData) => {
  return new Promise((resolve, reject) => {
    Interactions.getSubThemesPublic(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
InteractionsController.getThemes = () => {
  return new Promise((resolve, reject) => {
    Interactions.getThemes()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
InteractionsController.getThemesPublic = (queryData) => {
  return new Promise((resolve, reject) => {
    Interactions.getThemesPublic(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
InteractionsController.updateSingleInteractionThemes = (
  id,
  tracker_id,
  old_label,
  new_label,
  product_id,
  data_source_id,
  created_at,
  comment_id,
  media_id,
  account_id,
  location_id,
  audio_id,
  lucidya_audio_id,
  apiVars,
) => {
  var queryData = {
    id,
    tracker_id,
    old_label,
    new_label,
    product_id,
    data_source_id,
    created_at,
  };
  if (comment_id) {
    queryData.comment_id = comment_id;
  }
  if (media_id) {
    queryData.media_id = media_id;
  }
  if (account_id) {
    queryData.account_id = account_id;
  }
  if (location_id) {
    queryData.location_id = location_id;
  }
  if (audio_id) {
    queryData.audio_id = audio_id;
  }
  if (lucidya_audio_id) {
    queryData.lucidya_audio_id = lucidya_audio_id;
  }

  return new Promise(function (resolve) {
    Interactions.updateSingleInteractionThemes(queryData, apiVars)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InteractionsController.updateSingleInteractionSubThemes = (
  id,
  tracker_id,
  old_label,
  new_label,
  product_id,
  data_source_id,
  created_at,
  comment_id,
  media_id,
  account_id,
  location_id,
  audio_id,
  lucidya_audio_id,
  apiVars,
) => {
  var queryData = {
    id,
    tracker_id,
    old_label,
    new_label,
    product_id,
    data_source_id,
    created_at,
  };
  if (comment_id) {
    queryData.comment_id = comment_id;
  }
  if (media_id) {
    queryData.media_id = media_id;
  }
  if (account_id) {
    queryData.account_id = account_id;
  }
  if (location_id) {
    queryData.location_id = location_id;
  }
  if (audio_id) {
    queryData.audio_id = audio_id;
  }
  if (lucidya_audio_id) {
    queryData.lucidya_audio_id = lucidya_audio_id;
  }

  return new Promise(function (resolve) {
    Interactions.updateSingleInteractionSubThemes(queryData, apiVars)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InteractionsController.updateSingleInteractionSentiment = (
  id,
  tracker_id,
  old_label,
  new_label,
  product_id,
  data_source_id,
  created_at,
  comment_id,
  media_id,
  account_id,
  location_id,
  audio_id,
  lucidya_audio_id,
  apiVars,
) => {
  var queryData = {
    id,
    tracker_id,
    old_label,
    new_label,
    product_id,
    data_source_id,
    created_at,
  };
  if (comment_id) {
    queryData.comment_id = comment_id;
  }
  if (media_id) {
    queryData.media_id = media_id;
  }
  if (account_id) {
    queryData.account_id = account_id;
  }
  if (location_id) {
    queryData.location_id = location_id;
  }
  if (audio_id) {
    queryData.audio_id = audio_id;
  }
  if (lucidya_audio_id) {
    queryData.lucidya_audio_id = lucidya_audio_id;
  }

  return new Promise(function (resolve) {
    Interactions.updateSingleInteractionSentiment(queryData, apiVars)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//Social
InteractionsController.getInteractionsSocialCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsSocialCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
//Chat
InteractionsController.getInteractionsChatCredentials = (
  start_date,
  end_date,
  filterParams,
  custom,
  product_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    custom,
    product_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsChatCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
InteractionsController.getGenesysInteraction = (
  start_date,
  end_date,
  filterParams,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getGenesysInteraction(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InteractionsController.getInteractionsTiktokPublicCredentials = (
  start_date,
  end_date,
  filterParams,
  product_id,
  data_source_id,
  page_number,
) => {
  var queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filterParams),
    product_id,
    data_source_id,
    page_number,
  };
  return new Promise(function (resolve) {
    Interactions.getInteractionsTiktokPublicCredentials(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InteractionsController.editSentiment = (apiVars, queryData) => {
  return new Promise((resolve, reject) => {
    Interactions.editSentiment(apiVars, queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

InteractionsController.editCategories = (apiVars, queryData) => {
  return new Promise((resolve, reject) => {
    Interactions.editCategories(apiVars, queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

InteractionsController.editThemes = (apiVars, queryData) => {
  return new Promise((resolve, reject) => {
    Interactions.editThemes(apiVars, queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

export default InteractionsController;
