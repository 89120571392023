import React from "react";
import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import {
  Grid,
  Box,
  Divider,
  Tooltip,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
  TextField,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import KeywordTooltipChip from "../../../../SharedComponent/keywordTypeComponent/keywordTooltipChip";
import SnackBar from "../../../../../../../../components/snackBar";
import {
  handleInputMouseDown,
  checkDirectionLang,
} from "../../../../SharedFunctions/components/kewordsSharedFunctions";

import TopicsFuntions from "../topicsFunctions";
import { RemoveCircleOutline } from "@mui/icons-material";
import "../../topic.scss";
import { CheckValueLocale } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    marginLeft: "50px",
    marginRight: "50px",
  },
}));

const TopicCard = (props) => {
  const intl = useIntl();
  const {
    monitorTopics,
    setMonitorTopics,
    monitorSettings,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    updatedParams,
    setUpdatedParams,
    isMonitorSetting,
    isEmptyTopicsArray,
  } = props;
  const {
    keywordsFocused,
    setKeywordsFocused,
    excludeKeywordsFocused,
    setExcludeKeywordsFocused,
    keywords,
    excludedKeyword,
    handleTopicName,
    handleKeywordChange,
    handleKeywords,
    handleDeleteKeyword,
    handleRemoveTopic,
    showSnackBar,
    setShowSnackBar,
    messageSnackBar,
    severitySnackBar,
    handleTopicsData,
  } = TopicsFuntions();

  const classes = useStyles();

  useEffect(() => {
    setErrorKewyordsMsg({ i: "", errorMsg: "" });
    setExErrorKewyordsMsg({ i: "", errorMsg: "" });
  }, []);

  useEffect(() => {
    if (handleTopicsData(monitorTopics) && isMonitorSetting) {
      setDisplayNameMsgError({ i: "", errorMsg: "" });
      setErrorKewyordsMsg({ i: "", errorMsg: "" });
    }
  }, [monitorTopics]);

  return (
    <>
      {monitorTopics?.map((topicInfo, index) => {
        return (
          <li className={!isEmptyTopicsArray ? "empty-li-tree-style" : ""}>
            {
              <SnackBar
                open={showSnackBar}
                severity={severitySnackBar}
                message={CheckValueLocale(messageSnackBar, "", {}, intl)}
                handleClose={() => {
                  setShowSnackBar(false);
                }}
              />
            }
            <div className="topic-card-number">
              <p>
                {" "}
                {CheckValueLocale("topic", "", {}, intl)} -{" "}
                <span> {index + 1} </span>
              </p>
            </div>
            <Box sx={{ flexGrow: 1, p: 2, paddingRight: "0px" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  "--Grid-borderWidth": "1px",
                  borderTop: "var(--Grid-borderWidth) solid",
                  borderLeft: "var(--Grid-borderWidth) solid",
                  borderColor: "divider",
                  "& > div": {
                    borderRight: "var(--Grid-borderWidth) solid",
                    borderBottom: "var(--Grid-borderWidth) solid",
                    borderColor: "divider",
                  },
                }}
                className={
                  monitorSettings
                    ? "topic-card-shape monitor-settings-card-box"
                    : "topic-card-shape"
                }
              >
                <Grid {...{ xs: 12, sm: 6, md: 4, lg: 12 }} minHeight={160}>
                  {/* delete topic */}
                  {!isEmptyTopicsArray && (
                    <Box className="delete-topic-container">
                      <Tooltip
                        title={CheckValueLocale(
                          "remove_this_topic_icon_tooltip",
                          "",
                          {},
                          intl,
                        )}
                        placement="bottom"
                        arrow
                        classes={{ tooltip: classes.customWidth }}
                      >
                        <div
                          onClick={(event) => {
                            handleRemoveTopic(
                              event,
                              index,
                              monitorTopics,
                              setMonitorTopics,
                              isMonitorSetting,
                              updatedParams,
                              setUpdatedParams,
                            );
                          }}
                          className={`remove-topic-position ${
                            isMonitorSetting && "remove-icon-setting"
                          }`}
                        >
                          <RemoveCircleOutline className="remove-topic-icon topic-icon" />
                        </div>
                      </Tooltip>
                    </Box>
                  )}
                  <Grid
                    className={`topic-box ${
                      monitorSettings && "topic-box-edit topic-name-box-edit"
                    }`}
                    container
                    spacing={2}
                  >
                    <Grid item xs={!monitorSettings && 6}>
                      <div>
                        <Typography
                          id="topic-label-field"
                          className={`${
                            monitorTopics.length > 1
                              ? "topic-name-label-more"
                              : "topic-name-label"
                          }`}
                        >
                          {CheckValueLocale("topic_name", "", {}, intl)}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={!monitorSettings && 6}>
                      <div>
                        {!monitorSettings && (
                          <p className="topic-text-field-title">
                            {" "}
                            {CheckValueLocale(
                              "topic_display_name",
                              "",
                              {},
                              intl,
                            )}{" "}
                          </p>
                        )}
                        <TextField
                          className={`social-media-field ${
                            monitorSettings
                              ? "topic-field-edit topic-name-input"
                              : "topic-field-create"
                          }`}
                          label={
                            monitorSettings &&
                            CheckValueLocale("topic_display_name", "", {}, intl)
                          }
                          placeholder={
                            !monitorSettings &&
                            CheckValueLocale("type_display_name", "", {}, intl)
                          }
                          value={topicInfo?.name || ""}
                          onChange={(e) => {
                            handleTopicName(
                              e,
                              index,
                              monitorTopics,
                              setMonitorTopics,
                              setDisplayNameMsgError,
                              isMonitorSetting,
                              updatedParams,
                              setUpdatedParams,
                            );
                          }}
                        />

                        {displayNameMsgError?.i === index &&
                          displayNameMsgError?.errorMsg !== "" && (
                            <Box className="topics-err-msg">
                              {CheckValueLocale(
                                displayNameMsgError?.errorMsg,
                                "",
                                {},
                                intl,
                              )}
                            </Box>
                          )}
                      </div>
                    </Grid>
                  </Grid>
                  <Divider sx={{ bgcolor: "#EAEDF1" }} />
                  <Grid
                    className={`topic-keywords-box ${
                      monitorSettings && "topic-box-edit"
                    }`}
                    container
                    spacing={2}
                  >
                    <Grid item xs={!monitorSettings && 6}>
                      <Grid item xs={12} className="keywords-label-box">
                        {monitorSettings ? (
                          <>
                            <Tooltip
                              title={CheckValueLocale(
                                "topics_keywords_tooltip",
                                "",
                                {},
                                intl,
                              )}
                              arrow
                              placement="bottom"
                            >
                              <Typography
                                id="topic-label-field"
                                className="input-label-topic-keyword"
                              >
                                {CheckValueLocale(
                                  "keywords_tab_title",
                                  "",
                                  {},
                                  intl,
                                )}
                              </Typography>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Typography id="topic-label-field">
                              {CheckValueLocale(
                                "keywords_tab_title",
                                "",
                                {},
                                intl,
                              )}
                            </Typography>
                            <Tooltip
                              title={CheckValueLocale(
                                "topics_keywords_tooltip",
                                "",
                                {},
                                intl,
                              )}
                              arrow
                              placement="bottom"
                            >
                              <InfoIcon id="keywords-label-info-icon" />
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      className="topic-keywords-box"
                      item
                      xs={!monitorSettings && 6}
                    >
                      <div
                        className={`topic-keywords-div ${
                          monitorSettings && "topic-keywords-edit-container"
                        }`}
                      >
                        {!monitorSettings && (
                          <p className="topic-text-field-title">
                            {CheckValueLocale(
                              "keywords_tab_title",
                              "",
                              {},
                              intl,
                            )}
                          </p>
                        )}

                        <TextField
                          id="outlined-email-input"
                          label={
                            monitorSettings &&
                            CheckValueLocale("keywords_tab_title", "", {}, intl)
                          }
                          variant="outlined"
                          className={`topics-keywords-field social-media-field  ${
                            monitorSettings
                              ? "topic-field-edit topic-keywords-input"
                              : "topic-field-create"
                          }  ${checkDirectionLang(
                            keywords.indexNum === index ? keywords.keyword : "",
                          )}`}
                          onFocus={() => setKeywordsFocused(true)}
                          onBlur={() => setKeywordsFocused(false)}
                          InputProps={{
                            endAdornment: keywordsFocused && (
                              <InputAdornment
                                onMouseDown={handleInputMouseDown}
                              >
                                <Button
                                  className="add-btn-with-plus add-btn-edit-topic"
                                  onClick={(event) => {
                                    handleKeywords(
                                      event,
                                      index,
                                      "keywords",
                                      monitorTopics,
                                      setMonitorTopics,
                                      "btn",
                                      setErrorKewyordsMsg,
                                      setExErrorKewyordsMsg,
                                      isMonitorSetting,
                                      updatedParams,
                                      setUpdatedParams,
                                    );
                                  }}
                                  value={
                                    keywords.indexNum === index
                                      ? keywords.keyword
                                      : ""
                                  }
                                  id="monitor-options-topic-add-keyword-btn"
                                >
                                  {CheckValueLocale("add_plus", "", {}, intl)}
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                          placeholder={
                            !monitorSettings &&
                            CheckValueLocale("type_keywords", "", {}, intl)
                          }
                          onKeyDown={(event) =>
                            handleKeywords(
                              event,
                              index,
                              "keywords",
                              monitorTopics,
                              setMonitorTopics,
                              undefined,
                              setErrorKewyordsMsg,
                              setExErrorKewyordsMsg,
                              isMonitorSetting,
                              updatedParams,
                              setUpdatedParams,
                            )
                          }
                          onChange={(event) =>
                            handleKeywordChange(
                              event,
                              index,
                              "keywords",
                              monitorTopics,
                              setErrorKewyordsMsg,
                              setExErrorKewyordsMsg,
                            )
                          }
                          value={
                            keywords?.indexNum === index
                              ? keywords?.keyword
                              : ""
                          }
                          disabled={!topicInfo?.name && true}
                        />
                        {errorKeywordsMsg?.i === index &&
                          errorKeywordsMsg?.errorMsg !== "" && (
                            <Box className="topics-err-msg">
                              {CheckValueLocale(
                                errorKeywordsMsg?.errorMsg,
                                "",
                                { limit: 100 },
                                intl,
                              )}
                            </Box>
                          )}

                        <Box className="keyword-spam-wrapper">
                          {monitorTopics[index]?.keywords?.map((keyword, i) => {
                            return (
                              <KeywordTooltipChip
                                onDelete={(e) =>
                                  handleDeleteKeyword(
                                    i,
                                    index,
                                    "keywords",
                                    monitorTopics,
                                    setMonitorTopics,
                                    isMonitorSetting,
                                    updatedParams,
                                    setUpdatedParams,
                                    setErrorKewyordsMsg,
                                  )
                                }
                                dataSource={"twitter"}
                                index={i}
                                labels={keyword}
                              />
                            );
                          })}
                        </Box>
                      </div>
                      <div
                        className={`topic-keywords-div  ${
                          monitorSettings && "topic-keywords-edit-container"
                        }`}
                      >
                        {!monitorSettings && (
                          <p className="topic-text-field-title">
                            {CheckValueLocale(
                              "topic_exclude_keywords",
                              "",
                              {},
                              intl,
                            )}
                          </p>
                        )}

                        <TextField
                          id="outlined-email-input"
                          label={
                            monitorSettings &&
                            CheckValueLocale(
                              "topic_exclude_keywords",
                              "",
                              {},
                              intl,
                            )
                          }
                          variant="outlined"
                          className={`topics-keywords-field social-media-field  ${
                            monitorSettings
                              ? "topic-field-edit topic-keywords-input"
                              : "topic-field-create"
                          }  ${checkDirectionLang(
                            excludedKeyword?.indexNum === index
                              ? excludedKeyword?.keyword
                              : "",
                          )}`}
                          onFocus={() => setExcludeKeywordsFocused(true)}
                          onBlur={() => setExcludeKeywordsFocused(false)}
                          InputProps={{
                            endAdornment: excludeKeywordsFocused && (
                              <InputAdornment
                                onMouseDown={handleInputMouseDown}
                              >
                                <Button
                                  className="add-btn-with-plus add-btn-edit-topic"
                                  onClick={(event) => {
                                    handleKeywords(
                                      event,
                                      index,
                                      "exclude_keywords",
                                      monitorTopics,
                                      setMonitorTopics,
                                      "btn",
                                      setErrorKewyordsMsg,
                                      setExErrorKewyordsMsg,
                                      isMonitorSetting,
                                      updatedParams,
                                      setUpdatedParams,
                                    );
                                  }}
                                  value={
                                    excludedKeyword?.indexNum === index
                                      ? excludedKeyword?.keyword
                                      : ""
                                  }
                                  id="monitor-options-topic-add-exclude-keyword-btn"
                                >
                                  {CheckValueLocale("add_plus", "", {}, intl)}
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                          placeholder={
                            !monitorSettings &&
                            CheckValueLocale(
                              "type_excluded_keywords",
                              "",
                              {},
                              intl,
                            )
                          }
                          onKeyDown={(event) =>
                            handleKeywords(
                              event,
                              index,
                              "exclude_keywords",
                              monitorTopics,
                              setMonitorTopics,
                              undefined,
                              setErrorKewyordsMsg,
                              setExErrorKewyordsMsg,
                              isMonitorSetting,
                              updatedParams,
                              setUpdatedParams,
                            )
                          }
                          onChange={(event) =>
                            handleKeywordChange(
                              event,
                              index,
                              "exclude_keywords",
                              monitorTopics,
                              setErrorKewyordsMsg,
                              setExErrorKewyordsMsg,
                            )
                          }
                          value={
                            excludedKeyword?.indexNum === index
                              ? excludedKeyword?.keyword
                              : ""
                          }
                          disabled={!topicInfo?.name && true}
                        />
                        {errorExKeywordsMsg?.i === index &&
                          errorExKeywordsMsg?.errorMsg !== "" && (
                            <Box className="topics-err-msg">
                              {CheckValueLocale(
                                errorExKeywordsMsg?.errorMsg,
                                "",
                                { limit: 100 },
                                intl,
                              )}
                            </Box>
                          )}
                        <Box className="keyword-spam-wrapper">
                          {monitorTopics[index]?.exclude_keywords?.map(
                            (exkeyword, i) => {
                              return (
                                <KeywordTooltipChip
                                  onDelete={(e) =>
                                    handleDeleteKeyword(
                                      i,
                                      index,
                                      "exclude_keywords",
                                      monitorTopics,
                                      setMonitorTopics,
                                      isMonitorSetting,
                                      updatedParams,
                                      setUpdatedParams,
                                      setErrorKewyordsMsg,
                                    )
                                  }
                                  dataSource={"twitter"}
                                  index={i}
                                  labels={exkeyword}
                                />
                              );
                            },
                          )}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </li>
        );
      })}
    </>
  );
};
export default TopicCard;
