import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormHelperText,
  Snackbar,
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SmsIcon from "@mui/icons-material/Sms";

import MonitorsController from "services/controllers/monitorsController";
import ReportController from "services/controllers/reportController";
import "./create-report.scss";
import { isEmptyArray } from "utils/helpers";

const monitorTypeIcon = (type) => {
  if (type === "MANAGED_ACCOUNT") {
    return <AccountCircleIcon />;
  } else if (type === "ACCOUNT_ANALYSIS") {
    return <BusinessIcon />;
  } else if (type === "KEYWORD") {
    return <SmsIcon />;
  } else if (type === "REGION") {
    return <LocationOnIcon />;
  }
};

const monitorTypesKeys = {
  MANAGED_ACCOUNT: ["managed_account_title", "managed_account_text"],
  ACCOUNT_ANALYSIS: ["public_account_title", "public_account_text"],
  KEYWORD: ["keywords_title", "keywords_text"],
  REGION: ["geographical_title", "geographical_text"],
};

const reportTypesKeys = {
  monitor: "monitor_report",
  newsletter: "newsletter_reports",
};

const SocialListiningCreate = ({
  step2FormFields,
  setStep2FormFields,
  fieldError,
  updateFieldValue,
  MenuProps,
  backBtn,
  setBackBtn,
  setCheckDisable,
  isMonitorAction,
  preSelectedData,
}) => {
  const intl = useIntl();
  const [monitorTypeLists, setMonitorTypeLists] = useState([]);
  const [reportTypeLists, setReportTypeLists] = useState([]);
  const [dataSourceLists, setDataSourceLists] = useState([]);
  const [monitorLists, setMonitorLists] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const {
    monitorType,
    dataSource,
    monitor,
    reportType,
    KeywordDataSource,
    remainingMonitorsDataSource,
    KeywordMonitors,
  } = step2FormFields;
  const handelMonitorType = (type, name) => {
    setStep2FormFields((val) => ({
      ...val,
      monitorType: type,
      channelType: name,
      reportType: type !== 3 ? 1 : "",
      dataSource: "",
      monitor: isMonitorAction ? monitor : "",
      KeywordDataSource: [],
      remainingMonitorsDataSource: "",
      KeywordMonitors: [],
      talkwalkerDataSource: [],
    }));
    if (type !== 3) {
      getDataSourceListData(type);
      setMonitorLists([]);
    } else if (type === 3) {
      getReportTypeListData();
    }
  };
  const [isRequestError, setIsRequestError] = useState(false);

  const getMonitorTypeListData = () => {
    MonitorsController.getAuthorizedMonitorType(1).then((res) => {
      let monitorTypeData = [];
      setIsRequestError(!!res?.errorMsg);
      if (res?.data?.length) {
        res?.data?.map((val) => {
          if (
            val?.attributes?.monitor_type_name !== "MANAGED_PAGE" &&
            val?.attributes?.monitor_type_name !== "REGION"
          ) {
            monitorTypeData?.push({
              id: val?.attributes?.monitor_type_id,
              name: val?.attributes?.monitor_type_name,
              title: val?.attributes?.monitor_type_name
                ? monitorTypesKeys[val?.attributes?.monitor_type_name][0]
                : val?.attributes?.monitor_type_name,
              text: val?.attributes?.monitor_type_name
                ? monitorTypesKeys[val?.attributes?.monitor_type_name][1]
                : "",
            });
          }
        });

        monitorTypeData = [
          ...new Map(
            monitorTypeData?.map((item) => [item["id"], item]),
          ).values(),
        ];
      }
      setMonitorTypeLists(monitorTypeData);
    });
  };

  const getReportTypeListData = () => {
    ReportController.getReportTypes().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data &&
        setReportTypeLists([
          ...res.data?.map((val) => ({
            id: val?.attributes?.report_type,
            name: val?.attributes?.name,
            title: val?.attributes?.name
              ? reportTypesKeys[val?.attributes?.name]
              : val?.attributes?.name,
          })),
        ]);
    });
  };

  const getDataSourceListData = (monitorType, reportType) => {
    if (monitorType == 5) {
      monitorType = [5, 6];
    }
    ReportController.getReportDataSource(monitorType).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.data) {
        if (reportType == 2) {
          res.data
            ?.filter((val) => val?.attributes?.source === "TALKWALKER")
            ?.map((val) => {
              return setStep2FormFields((values) => ({
                ...values,
                dataSource: "",
                KeywordDataSource: [],
                remainingMonitorsDataSource: "",
                talkwalkerDataSource: [val?.attributes?.id],
              }));
            });
        } else {
          setDataSourceLists([
            ...res.data
              ?.filter(
                (val) =>
                  val?.attributes?.source !== "GMAIL" &&
                  val?.attributes?.source !== "INTERCOM" &&
                  val?.attributes?.source !== "WHATSAPP" &&
                  val?.attributes?.source !== "GOOGLEMYBUSINESS" &&
                  val?.attributes?.source !== "CDP" &&
                  val?.attributes?.source !== "LINKEDIN" &&
                  val?.attributes?.source !== "TIKTOK" &&
                  val?.attributes?.status === "launched",
              )
              ?.map((val) => ({
                id: val?.attributes?.id,
                name: val?.attributes?.source,
                title: val?.attributes?.source
                  ? `report_${val?.attributes?.source}`
                  : val?.attributes?.source,
              })),
          ]);
        }
      }
    });
  };

  const getMonitorListData = (monitorType, reportType, dataSourceData) => {
    setIsloading(true);
    if (monitorType == 5) {
      monitorType = [5, 6];
    }
    ReportController.getReportMonitors(
      monitorType,
      reportType,
      dataSourceData,
    ).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data &&
        setMonitorLists([
          ...res.data
            ?.filter(
              (val) =>
                val?.attributes?.name !== "" && val?.attributes?.name !== null,
            )
            ?.map((val) => ({
              id: val?.attributes?.id,
              name: val?.attributes?.name,
            })),
        ]);
      setIsloading(false);
    });
  };

  const handelDataSourceMonitorData = (dataSourceData, isKeyword) => {
    if (!isKeyword) {
      getMonitorListData(monitorType, reportType, dataSourceData);
    } else {
      getMonitorListData(monitorType, reportType, dataSourceData?.join(","));
    }
  };
  const handleTypeTwo = (monitorType) => {
    getDataSourceListData(monitorType, reportType);
    getMonitorListData(monitorType, reportType);
  };
  useEffect(() => {
    if (!backBtn) {
      setStep2FormFields((val) => ({
        ...val,
        monitor: isMonitorAction ? monitor : "",
        KeywordMonitors: [],
      }));
    }
    isEmptyArray(monitorTypeLists) && getMonitorTypeListData();
    // _________________________________________________________
    //when user choose 1st report type (monitor report) a call to get all data sources is fired
    reportType === 1 &&
      monitorType === 3 &&
      backBtn === false &&
      getDataSourceListData(monitorType);
    // _________________________________________________________
    //when user choose 2st report type (newsletter report) a call to get Monitor list is fired
    reportType === 2 && backBtn === false && handleTypeTwo(monitorType);
    // _________________________________________________________
    //in case user selected (back btn) from step 3 to step 2 all selected data should be showing
    if (backBtn === true) {
      getDataSourceListData(monitorType);
      getMonitorListData(monitorType, reportType, KeywordDataSource?.join());
      monitorType === 3 && getReportTypeListData(); // in case of keywords Monitor type
      setBackBtn(false);
    }
  }, [monitorType, reportType]);
  useEffect(() => {
    if (monitorType === 3) {
      if (reportType == "") {
        setCheckDisable(true);
      } else if (reportType === 1) {
        if (KeywordDataSource.length == 0 || monitor == "") {
          setCheckDisable(true);
        } else {
          setCheckDisable(false);
        }
      } else if (reportType === 2) {
        if (KeywordMonitors.length == 0) {
          setCheckDisable(true);
        } else {
          setCheckDisable(false);
        }
      }
    } else if (monitorType === 4 || monitorType === 5) {
      if (remainingMonitorsDataSource == "" || monitor == "") {
        setCheckDisable(true);
      } else {
        setCheckDisable(false);
      }
    } else {
      setCheckDisable(false);
    }
  }, [
    reportType,
    dataSource,
    KeywordDataSource,
    monitor,
    KeywordMonitors,
    remainingMonitorsDataSource,
  ]);
  useEffect(() => {
    if (remainingMonitorsDataSource) {
      getMonitorListData(monitorType, reportType, remainingMonitorsDataSource);
    }
    if (reportType == 2 && monitorType == 3) {
      getMonitorListData(monitorType, reportType);
    }
  }, []);

  // ---- Set pre-selected data related to monitor header action ----
  useEffect(() => {
    if (isMonitorAction && preSelectedData) {
      // Set Pre-Selected Monitor ID
      if (preSelectedData?.monitorId)
        setStep2FormFields({
          ...step2FormFields,
          monitor: preSelectedData?.monitorId,
        });

      // Set Pre-Selected Monitor Type
      if (monitorTypeLists?.length > 0) {
        monitorTypeLists?.map((type) => {
          if (
            type?.name === preSelectedData?.monitorType ||
            (type?.name === "MANAGED_ACCOUNT" &&
              preSelectedData?.monitorType === "MANAGED_PAGE")
          ) {
            setStep2FormFields({
              ...step2FormFields,
              channelType:
                preSelectedData?.monitorType === "MANAGED_PAGE"
                  ? "MANAGED_ACCOUNT"
                  : preSelectedData?.monitorType,
              monitorType: type?.id,
            });
            handelMonitorType(
              +type?.id,
              preSelectedData?.monitorType === "MANAGED_PAGE"
                ? "MANAGED_ACCOUNT"
                : preSelectedData?.monitorType,
            );
          }
        });
      }
    }
  }, [isMonitorAction, preSelectedData, monitorTypeLists]);

  // Set Pre-Selected Data-Source
  useEffect(() => {
    if (
      isMonitorAction &&
      dataSourceLists?.length > 0 &&
      preSelectedData?.dataSources?.length > 0 &&
      preSelectedData?.dataSources?.length === 1
    ) {
      dataSourceLists?.map((source) => {
        if (source?.name === preSelectedData?.dataSources[0]) {
          if (monitorType !== "" && monitorType !== 3)
            setStep2FormFields({
              ...step2FormFields,
              remainingMonitorsDataSource: source?.id,
            });
          else
            setStep2FormFields({
              ...step2FormFields,
              KeywordDataSource: [source?.id],
            });
        }
      });
    }
  }, [isMonitorAction, preSelectedData, dataSourceLists]);

  // Set Pre-Selected Report Type
  useEffect(() => {
    if (isMonitorAction && reportTypeLists?.length > 0)
      reportTypeLists?.map((type) => {
        if (type?.name === "monitor")
          setStep2FormFields({ ...step2FormFields, reportType: type?.id });
      });
  }, [isMonitorAction, reportTypeLists]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className="text-field">
          <InputLabel>
            {CheckValueLocale("choose_monitor_type", "", {}, intl)}:
          </InputLabel>
        </Box>
      </Grid>
      {monitorTypeLists?.map(
        (typeVal) =>
          typeVal?.name != "REGION" && (
            <Grid
              item
              lg={6}
              key={`monitor-type-${typeVal?.id}`}
              onClick={() => {
                if (!isMonitorAction)
                  handelMonitorType(typeVal?.id, typeVal?.name);
              }}
            >
              <Box
                className={`monitor-type-box ${
                  monitorType === typeVal?.id ? "active" : null
                } ${!isMonitorAction ? "clickable" : null}`}
              >
                <Typography variant="h5">
                  {monitorTypeIcon(typeVal?.name)}
                  {CheckValueLocale(typeVal?.title, "", {}, intl)}
                </Typography>
                <Typography variant="body1">
                  {CheckValueLocale(typeVal?.text, "", {}, intl)}
                </Typography>
              </Box>
            </Grid>
          ),
      )}
      {fieldError?.monitorType && (
        <Grid item xs={12}>
          <FormHelperText error>{fieldError?.monitorType}</FormHelperText>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: "20px" }}></Grid>
      {monitorType === 3 && (
        <Grid item lg={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("report_type", "", {}, intl)}:
            </InputLabel>
            <Select
              name="reportType"
              value={reportType}
              fullWidth
              onChange={updateFieldValue}
              error={fieldError?.reportType ? true : false}
              displayEmpty
              disabled={isMonitorAction}
            >
              <MenuItem value="" disabled className="report-menu-items">
                {CheckValueLocale("select", "", {}, intl)}
              </MenuItem>
              {reportTypeLists?.map((val) => (
                <MenuItem
                  key={val?.title}
                  value={val?.id}
                  className="report-menu-items"
                  id={`report-type-${val?.id ?? "list-id"}`}
                >
                  {val?.title && CheckValueLocale(val?.title, "", {}, intl)}
                </MenuItem>
              ))}
            </Select>
            {fieldError?.reportType && (
              <FormHelperText error>{fieldError?.reportType}</FormHelperText>
            )}
          </Box>
        </Grid>
      )}
      {monitorType === 3 && reportType === 1 && (
        <Grid item lg={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("data_source", "", {}, intl)}:
            </InputLabel>
            <Select
              fullWidth
              multiple
              name="KeywordDataSource"
              value={KeywordDataSource}
              onChange={(e) => {
                updateFieldValue(e);
                if (!isMonitorAction)
                  handelDataSourceMonitorData(e?.target?.value);
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return CheckValueLocale("select", "", {}, intl);
                }
                return dataSourceLists
                  ?.filter((val) => selected?.includes(val?.id))
                  ?.map((val) => {
                    return CheckValueLocale(val?.title, "", {}, intl);
                  })
                  ?.join(", ");
              }}
              MenuProps={MenuProps}
              error={fieldError?.dataSource ? true : false}
              displayEmpty
              disabled={
                isMonitorAction && preSelectedData?.dataSources?.length === 1
              }
            >
              <MenuItem value={""} disabled className="report-multi-menuItem">
                <Checkbox checked={false} />
                <ListItemText
                  primary={CheckValueLocale("select", "", {}, intl)}
                />
              </MenuItem>
              {dataSourceLists
                ?.filter((source) => {
                  if (isMonitorAction)
                    return preSelectedData?.dataSources?.includes(source?.name);
                  else return true;
                })
                ?.map((val) => (
                  <MenuItem
                    key={`dataSource-multi-${val.id}`}
                    value={val.id}
                    className="report-multi-menuItem"
                    id={`report-data-source-${val?.id ?? "list-id"}`}
                  >
                    <Checkbox
                      checked={KeywordDataSource?.indexOf(val?.id) > -1}
                    />
                    <ListItemText
                      primary={CheckValueLocale(val?.title, "", {}, intl)}
                    />
                  </MenuItem>
                ))}
            </Select>
            {fieldError?.dataSource && (
              <FormHelperText error>{fieldError?.dataSource}</FormHelperText>
            )}
          </Box>
        </Grid>
      )}
      {monitorType === 3 && reportType === 1 && (
        <Grid item lg={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("monitor", "", {}, intl)}:
            </InputLabel>
            <Select
              name="monitor"
              value={monitor}
              fullWidth
              onChange={updateFieldValue}
              error={fieldError?.monitor ? true : false}
              displayEmpty
              disabled={
                KeywordDataSource?.length === 0 || isLoading || isMonitorAction
              }
            >
              <MenuItem
                value=""
                disabled
                className={`report-menu-items ${
                  monitorLists?.length === 0 && "no-option-select"
                }`}
              >
                {CheckValueLocale("select", "", {}, intl)}
              </MenuItem>

              {isMonitorAction ? (
                <MenuItem
                  value={preSelectedData?.monitorId}
                  className="report-menu-items"
                >
                  {preSelectedData?.monitorName}
                </MenuItem>
              ) : (
                monitorLists?.map((val) => (
                  <MenuItem
                    key={`_monitor-${val?.id}`}
                    value={val?.id}
                    className="report-menu-items"
                    id={`reports-monitors-lists-${val?.id ?? "list-id"}`}
                  >
                    {val?.name}
                  </MenuItem>
                ))
              )}
              {monitorLists?.length === 0 && (
                <MenuItem className="no-monitors-found" disabled>
                  {CheckValueLocale("no_monitors_found", "", {}, intl)}
                </MenuItem>
              )}
            </Select>
            {fieldError?.monitor && (
              <FormHelperText error>{fieldError?.monitor}</FormHelperText>
            )}
          </Box>
        </Grid>
      )}
      {monitorType === 3 && reportType === 2 && (
        <Grid item lg={6}>
          <Box className="text-field">
            <InputLabel>
              {CheckValueLocale("monitor", "", {}, intl)}:
            </InputLabel>
            <Select
              fullWidth
              multiple
              name="KeywordMonitors"
              value={KeywordMonitors}
              onChange={updateFieldValue}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return CheckValueLocale("select", "", {}, intl);
                }
                return monitorLists
                  ?.filter((val) => selected?.includes(val?.id))
                  ?.map((val) => {
                    return val?.name;
                  })
                  ?.join(", ");
              }}
              MenuProps={MenuProps}
              error={fieldError?.monitor ? true : false}
              displayEmpty
              disabled={monitorLists?.length === 0}
            >
              <MenuItem value={""} disabled className="report-multi-menuItem">
                <Checkbox checked={false} />
                <ListItemText
                  primary={CheckValueLocale("select", "", {}, intl)}
                />
              </MenuItem>
              {monitorLists?.map((val) => (
                <MenuItem
                  key={`_monitor_multi-${val?.id}`}
                  value={val?.id}
                  className="report-multi-menuItem"
                  id={`report-monitors-list-${val?.id ?? "list-id"}`}
                >
                  <Checkbox checked={KeywordMonitors?.indexOf(val.id) > -1} />
                  <ListItemText primary={val?.name} />
                </MenuItem>
              ))}
            </Select>
            {fieldError?.monitor && (
              <FormHelperText error>{fieldError?.monitor}</FormHelperText>
            )}
          </Box>
        </Grid>
      )}
      {monitorType !== "" && monitorType !== 3 && (
        <>
          <Grid item lg={6}>
            <Box className="text-field">
              <InputLabel>
                {CheckValueLocale("data_source", "", {}, intl)}:
              </InputLabel>
              <Select
                fullWidth
                name="remainingMonitorsDataSource"
                value={remainingMonitorsDataSource}
                onChange={(e) => {
                  updateFieldValue(e);
                  if (!isMonitorAction)
                    handelDataSourceMonitorData(e?.target?.value, false);
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected === "") {
                    return CheckValueLocale("select", "", {}, intl);
                  }
                  return dataSourceLists
                    ?.filter((val) => selected === val?.id)
                    ?.map((val) => {
                      return CheckValueLocale(val?.title, "", {}, intl);
                    });
                }}
                error={fieldError?.dataSource ? true : false}
                displayEmpty
                disabled={
                  isMonitorAction && preSelectedData?.dataSources?.length === 1
                }
              >
                <MenuItem value={""} disabled className="report-menu-items">
                  {CheckValueLocale("select", "", {}, intl)}
                </MenuItem>
                {dataSourceLists
                  ?.filter((src) => {
                    if (isMonitorAction)
                      return preSelectedData?.dataSources?.includes(src?.name);
                    else return true;
                  })
                  ?.map((val) =>
                    monitorType == 5 &&
                    (val?.title == "report_GENESYS" ||
                      val?.title == "report_PUBLIC_API" ||
                      val?.title == "report_AI_API") ? null : (
                      <MenuItem
                        key={`dataSource-${val?.id}`}
                        value={val?.id}
                        className="report-menu-items"
                        id={`report-data-source-list-${val?.id ?? "list-id"}`}
                      >
                        {CheckValueLocale(val?.title, "", {}, intl)}
                      </MenuItem>
                    ),
                  )}
              </Select>
              {fieldError?.dataSource && (
                <FormHelperText error>{fieldError?.dataSource}</FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item lg={6}>
            <Box className="text-field">
              <InputLabel>
                {CheckValueLocale("monitor", "", {}, intl)}:
              </InputLabel>
              <Select
                name="monitor"
                value={monitor}
                fullWidth
                onChange={updateFieldValue}
                error={fieldError?.monitor ? true : false}
                displayEmpty
                disabled={
                  remainingMonitorsDataSource === "" ||
                  isLoading ||
                  isMonitorAction
                }
              >
                <MenuItem
                  value=""
                  disabled
                  className={`report-menu-items ${
                    monitorLists?.length === 0 && "no-option-select"
                  }`}
                >
                  {CheckValueLocale("select", "", {}, intl)}
                </MenuItem>
                {isMonitorAction ? (
                  <MenuItem
                    value={preSelectedData?.monitorId}
                    className="report-menu-items"
                  >
                    {preSelectedData?.monitorName}
                  </MenuItem>
                ) : (
                  monitorLists?.map((val) => (
                    <MenuItem
                      key={`_monitor-${val?.id}`}
                      value={val?.id}
                      className="report-menu-items"
                      id={`report-monitor-lists-${val?.id ?? "list-id"}`}
                    >
                      {val?.name}
                    </MenuItem>
                  ))
                )}

                {monitorLists?.length === 0 && (
                  <MenuItem className="no-monitors-found" disabled>
                    {CheckValueLocale("no_monitors_found", "", {}, intl)}
                  </MenuItem>
                )}
              </Select>
              {fieldError?.monitor && (
                <FormHelperText error>{fieldError?.monitor}</FormHelperText>
              )}
            </Box>
          </Grid>
        </>
      )}
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </Grid>
  );
};

export default SocialListiningCreate;
