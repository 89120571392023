import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box } from "@mui/material";
import { getTimeZone, isEmptyObj } from "utils/helpers";
import { Link, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers/index";
import InteractionsController from "services/controllers/interactionsController";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import gmail from "images/social-icons/gmail.svg";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import Luci from "pages/luci";
import { sanitize } from "dompurify";

const SingleEmailInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams?.id;
  const trackerId = urlParams?.tracker_id;
  const dataSourceId = urlParams?.data_source_id;
  const emailId = urlParams?.email_id;
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  let apiVars = "email/gmail";

  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "emails",
      url: `insights/email${
        hasDatasourceOnly === "true" ? `/gmail/${dataSourceId}` : ""
      }`,
    },
    {
      name: "gmail",
      url: "insights/email/gmail/" + dataSourceId,
    },
  ];
  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
      undefined,
      undefined,
      emailId,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result.data)) {
        setConversation(result.data);
      }
    });
  }, []);

  const { data: categoriesOptions } = useCategoriesOptions({ enabled: true });
  const { data: themesOptions } = useThemesOptions({ enabled: true });

  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();
  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();

  const preQueryData = {
    id: emailId,
    tracker_id: trackerId,
    product_id: +window.localStorage.cxm_id,
    data_source_id: dataSourceId,
  };

  const handleEditCategories = (updatedCategories) => {
    editCategories(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedCategories,
          old_label: conversation?.themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({
            ...prev,
            themes: updatedCategories,
          })),
      },
    );
  };

  const handleEditTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars: `channel_analytics/${apiVars}/interactions`,
        queryData: {
          ...preQueryData,
          new_label: updatedThemes,
          old_label: conversation?.custom_sub_themes || [],
        },
      },
      {
        onSuccess: () =>
          setConversation((prev) => ({
            ...prev,
            custom_sub_themes: updatedThemes,
          })),
      },
    );
  };

  return (
    <>
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyObj(conversation) ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="gmail" />
            <div className="single-intr">
              <Box className="single-intr-wrapper">
                <span className="single-intr__icon single-intr__icon--gmail">
                  <img src={gmail} alt="" />
                </span>
                <span className="single-intr__title">
                  {CheckValueLocale("gmail", "", {}, intl)}
                </span>

                <Box>
                  <SentimentCategoriesThemes
                    themesData={{
                      btnId: "gmail-single-interaction-themes-btn",
                      options: themesOptions,
                      selected: conversation?.custom_sub_themes,
                      handleChange: handleEditTheme,
                      loading: isThemesPending,
                    }}
                    categoriesData={{
                      btnId: "gmail-single-interaction-categories-btn",
                      options: categoriesOptions,
                      selected: conversation?.themes,
                      handleChange: handleEditCategories,
                      loading: isCategoriesPending,
                    }}
                    sentimentData={{
                      btnId: "gmail-single-interaction-sentiment-btn",
                      selected: conversation?.sentiment,
                      disabled: true,
                    }}
                    dividers={[1, 2]}
                  />
                </Box>
              </Box>
              <div className="single-intr__body">
                <div>
                  <Link
                    to={"/people/person/interaction/" + conversation.person_key}
                    className="single-intr__name"
                  >
                    {conversation.sender_name}
                  </Link>
                  <div className="single-intr-page__header row">
                    <p>
                      {moment
                        .unix(conversation.created_at)
                        .utc()
                        .format("MM/DD/YYYY, LT ")}
                      {getTimeZone() > 0
                        ? `(${CheckValueLocale(
                            "gmt",
                            "",
                            {},
                            intl,
                          )} +${getTimeZone()})`
                        : `(${CheckValueLocale(
                            "gmt",
                            "",
                            {},
                            intl,
                          )} -${getTimeZone()})`}
                    </p>
                  </div>

                  <h5>{conversation.sender_email_address}</h5>
                  <h4>{conversation.subject}</h4>
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        conversation.message
                          ?.replace(/(\r\n)+/g, "\r\n")
                          .replace(/(\n\r)+/g, "\r\n")
                          .replace(/\n+/g, "\n")
                          .replace(/\r+/g, "\r")
                          .trim(),
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleEmailInteraction;
