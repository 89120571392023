import { useQuery } from "@tanstack/react-query";
import ThemesController from "services/controllers/themesController";

export const useThemesColors = () => {
  return useQuery({
    queryKey: ["themesColors"],
    queryFn: () => ThemesController.getThemesColors(),
    select: (data) => {
      return data?.data;
    },
    retry: false,
  });
};
