import { useEffect, useState } from "react";
import { Box, Pagination, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import AudienceController from "services/controllers/audienceController";
import moment from "moment";
import { commasAfterDigit, CheckValueLocale } from "utils/helpers";
import OptionsDropDownImport from "./optionsDropDownImport";
import EmptySegments from "images/audience-images/empty-segments.svg";
import SnackBar from "components/snackBar";
import PopupModal from "components/popupModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import SharedTable from "shared/table/sharedTable";
import CircularLoading from "components/circularProgress";
import { sortedColumnTypes } from "hooks/useSortableTable";

export const getImportsTableHeader = (intl) => {
  return [
    {
      body: CheckValueLocale("aduience_name", "", {}, intl),
      width: "13.82%",
      name: "name",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("audience_import_date", "", {}, intl),
      width: "11.32%",
      name: "created_at",
      type: sortedColumnTypes.date,
    },
    {
      body: CheckValueLocale("audience_import_num_of_profiles", "", {}, intl),
      width: "13.82%",
      name: "profiles_count",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("audience_updated_profiles", "", {}, intl),
      width: "13.82%",
      name: "updated_profiles",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("audience_profiles_errors", "", {}, intl),
      width: "12.48%",
      name: "error_profiles",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("segements_uploaded_by", "", {}, intl),
      width: "14.48%",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("audience_statues", "", {}, intl),
      width: "13.82%",
      name: "status",
      type: sortedColumnTypes.string,
    },
    {
      body: null,
      width: "5.75%",
    },
  ];
};

const SegmentsImports = ({
  getListOfCdpImports,
  listImport,
  pagination,
  page,
  loading,
  handleChangePage,
  tableHead,
  isTableLoading,
}) => {
  const intl = useIntl();
  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const [success, setSuccess] = useState(null);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [showText, setShowText] = useState(true);
  const [dataViewNum, setdataViewNum] = useState({});
  const [fileName, setFileName] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const [numErors, setNumErors] = useState({
    anchorEl: null,
    openDeleteModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const popupBody = (dataViewNum, fileName) => {
    return (
      <Box className="remove-pop view-num-errors">
        <Box className="content-view-num-errors">
          <Box className="file-name-container">
            <Typography className="body-input-label">
              {CheckValueLocale("file_name", "", {}, intl)}
            </Typography>
            <Box className="file-name">{fileName}</Box>
          </Box>
          <Box className="item-view-num-errors">
            <Typography className="text-view-num">
              {CheckValueLocale(
                "audience_imported_file_contains",
                "",
                {},
                intl,
              )}
            </Typography>
            <Typography className="num-view-num">
              {dataViewNum?.total_porfile_count}
              <span>{CheckValueLocale("audience_rows", "", {}, intl)}</span>
            </Typography>
          </Box>
          <Box className="item-view-num-errors">
            <Typography className="text-view-num">
              {CheckValueLocale("audience_new_accepted_rows", "", {}, intl)}
            </Typography>
            <Typography className="num-view-num">
              {dataViewNum?.updated_profile_count}
              <span>{CheckValueLocale("audience_rows", "", {}, intl)}</span>
            </Typography>
          </Box>
          <Box className="item-view-num-errors">
            <Typography className="text-view-num">
              {CheckValueLocale(
                "audience_rows_pre_existing_profiles",
                "",
                {},
                intl,
              )}
            </Typography>
            <Typography className="num-view-num">
              {dataViewNum?.existing_porfile_count}
              <span>{CheckValueLocale("audience_rows", "", {}, intl)}</span>
            </Typography>
          </Box>
          <Box className="item-view-num-errors">
            <Typography className="text-view-num">
              {CheckValueLocale(
                "audience_rows_with_uncomplying_fields",
                "",
                {},
                intl,
              )}
            </Typography>
            <Typography className="num-view-num">
              {dataViewNum?.uncomplying_profile_count}
              <span>{CheckValueLocale("audience_rows", "", {}, intl)}</span>
            </Typography>
          </Box>
          {dataViewNum?.rows_failed?.length > 0 ? (
            <Box className="collapce-toggle-parent">
              {!showText ? (
                <Typography className="collapce-toggle">
                  {`[${dataViewNum?.rows_failed?.join(" , ")}]`}
                </Typography>
              ) : null}
              <Typography
                className={showText ? "show-div" : "show-div show-less-parent"}
                onClick={() => setShowText(!showText)}
              >
                {CheckValueLocale(
                  showText ? "audience_show_more_num" : "show_less",
                  "",
                  {},
                  intl,
                )}
                <FontAwesomeIcon
                  className={showText ? "show-less" : "show-more"}
                  icon={faAngleDown}
                />
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  };

  const handleCloseSnackBar = () => {
    setSuccess(false);
  };

  const handleOpenViewErrorss = () => {
    setNumErors({
      openDeleteModal: true,
      addClasses: "model-buttons pop-up-view-num-errors",
      title: `${CheckValueLocale("audience_view_num_errors_segment", "", {}, intl)}`,
      modalLeftBtn: CheckValueLocale("audience_close", "", {}, intl),
      warning: true,
    });
  };
  const importCDPDwonload = (importsId) => {
    AudienceController.importCDPDwonload(importsId).then((data) => {
      if (data?.data?.status === 204 || data?.data?.status === 200) {
        setSuccess(true);

        setSnackBarMsg(
          CheckValueLocale("audience_success_msg_download", "", {}, intl),
        );
        setSuccessStatus("success");
        showSnackBar("delete", "success");
      } else {
        setSuccess(true);
        setSuccessStatus("error");

        setSnackBarMsg(
          CheckValueLocale("audience_wrong_msg_download", "", {}, intl),
        );
        setSnackBarTitle(
          CheckValueLocale("audience_fail_req_msg_download", "", {}, intl),
        );
        showSnackBar("delete", "warning");
      }
    });
  };

  const importCDpViewNum = (importsId, fileName) => {
    AudienceController.importCDpViewNum(importsId).then((data) => {
      if (data?.data?.status === 204 || data?.data?.status === 200) {
        setdataViewNum(data?.data?.data?.data);
        setFileName(fileName);
        handleOpenViewErrorss();
      }
    });
  };
  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
    setNumErors({ openDeleteModal: false });
    setShowText(true);
  };
  const showSnackBar = (action, severity) => {
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      openDeleteModal: false,
      openPauseResumeModal: false,
      snackBarMsg: CheckValueLocale(
        "audience_success_msg_download",
        "",
        {},
        intl,
      ),
    });
    window.scrollTo(0, 0);
    if (action === "delete" && severity === "success") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: CheckValueLocale(
          "audience_success_msg_download",
          "",
          {},
          intl,
        ),
      });
    } else if (action === "delete" && severity === "warning") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: CheckValueLocale(
          "audience_wrong_msg_download",
          "",
          {},
          intl,
        ),
        snackBarTitle: CheckValueLocale(
          "audience_fail_req_msg_download",
          "",
          {},
          intl,
        ),
      });
    }
  };
  const dropDownImportTable = (obj) => {
    return (
      <OptionsDropDownImport
        importItem={obj}
        importCDPDwonload={importCDPDwonload}
        id={obj?.id}
        importCDpViewNum={importCDpViewNum}
        getListOfCdpImports={getListOfCdpImports}
      />
    );
  };
  const dateTime = (item) => {
    return (
      <Box>
        <Typography>{moment(item?.created_at).format("DD-MM-YYYY")}</Typography>
      </Box>
    );
  };
  const numberProfiles = (numProfiles) => {
    return (
      <>
        {numProfiles != undefined ? (
          <Typography className="num-profiles">
            {`${commasAfterDigit(numProfiles)} `}
            {CheckValueLocale("cdp_profiles", "", {}, intl)}
          </Typography>
        ) : (
          "—"
        )}
      </>
    );
  };

  const getStatus = (status) => {
    return (
      <Box className={"status " + status}>
        {CheckValueLocale(`audience_${status}`, "", {}, intl)}
      </Box>
    );
  };
  const emptyBody = (listImport) => {
    return (
      <>
        {!listImport?.length && (
          <Box className="segment-empty-item">
            <img src={EmptySegments} />
            <Typography variant={"h7"}>
              {CheckValueLocale("no_data_found", "", {}, intl)}
            </Typography>
            <Box component="p">
              {CheckValueLocale("there_are_no_segments_yet", "", {}, intl)}
            </Box>
          </Box>
        )}
      </>
    );
  };
  useEffect(() => {
    const tabBody = listImport?.map((obj) => {
      return {
        id: obj?.id,
        data: [
          {
            body: obj?.file_name ? obj?.file_name : "—",
          },
          {
            body: obj?.created_at ? dateTime(obj?.created_at) : "—",
          },
          {
            body: obj?.num_profiles ? numberProfiles(obj?.num_profiles) : "—",
          },
          {
            body: obj?.updated_profiles
              ? commasAfterDigit(obj?.updated_profiles)
              : "—",
          },
          {
            body: obj?.error_profiles
              ? commasAfterDigit(obj?.error_profiles)
              : "—",
          },
          {
            body: obj?.creator_user ? obj?.creator_user : "—",
            isCreatedBy: true,
          },
          {
            body: obj?.status ? getStatus(obj?.status) : "—",
          },
          {
            body: dropDownImportTable(obj),
            className: "audience-actions-menu",
          },
        ],
      };
    });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(listImport));
  }, [listImport, intl?.locale]);
  // ~~~~~~~~~~~~~~~~End Shared Table
  return (
    <Box className="import-table">
      {loading ? (
        <Box sx={{ p: 1 }}>
          <CircularLoading />
        </Box>
      ) : (
        <>
          <SharedTable
            tableHeader={tableHead}
            tableBody={tableBody}
            className={"table-head"}
            isSharedStyle
            emptyBody={tableEmptyBody}
            loading={isTableLoading}
          />
          {!isTableLoading ? (
            <Pagination
              onChange={handleChangePage}
              className="pagination-monitor-list"
              count={pagination > 1 ? pagination : 1}
              page={page}
              variant="outlined"
            />
          ) : null}
        </>
      )}

      <PopupModal
        title={numErors?.title}
        body={popupBody(dataViewNum, fileName)}
        leftBtn={numErors?.modalLeftBtn}
        rightBtn={numErors?.modalRightBtn}
        close={handleCloseDeleteModal}
        open={numErors?.openDeleteModal}
        warning={numErors?.warning}
        classeName="pop-up-view-num-errors actions-buttons-shared "
        leftBtnVariant="outline"
        leftBtnType="primary"
      />

      {success && (
        <SnackBar
          open={success != null ? true : false}
          onClose
          title={snackBarTitle}
          severity={successStatus}
          message={snackBarMsg}
          showCloseBtn={true}
          handleClose={handleCloseSnackBar}
        />
      )}
    </Box>
  );
};

export default SegmentsImports;
