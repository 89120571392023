import faInstagram from "images/social-icons/ig-profile-icon.svg";
import { Grid, Typography, Box } from "@mui/material";
import MediaInstagram from "components/media/media-insta/mediaInsta";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import "../../../monitors.scss";
import TopicsSection from "../../../Components/topicsSection";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";

const InstagramEngagementsBody = (props) => {
  const intl = useIntl();
  let { response } = props;
  //handle response
  const { monitorType, monitorDataSource, monitorId } = useParams();
  const monitorDetails = props.monitorData;

  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [postsVolumePreLoader, setPostsVolumePreLoader] = useState([]);

  // _____________________________________________
  const [postsInteractions, setPostsInteractions] = useState([]);
  const [postsInteractionsPreLoader, setPostsInteractionsPreLoader] = useState(
    [],
  );
  const [postsInteractionsLegend, setPostsInteractionsLegend] = useState([]);
  // _____________________________________________

  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [sentimentPieChart, setSentimentPieChart] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  // _____________________________________________

  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  const [themeXlxs, setThemeXlxs] = useState([]);
  // _____________________________________________

  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);

  // _____________________________________________

  const [topVideos, setTopVideos] = useState([]);
  const [topVideosPreLoader, setTopVideosPreLoader] = useState([]);
  // _____________________________________________
  const [topImages, setTopImages] = useState([]);
  const [topImagesPreLoader, setTopImagesPreLoader] = useState([]);
  // _____________________________________________
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  // _____________________________________________
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );
  // _____________________________________________
  const [contentStyles, setContentStyles] = useState([]);
  const [contentStylesPreLoader, setContentStylesPreLoader] = useState([]);
  // _____________________________________________
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);

  // ___________________________________________________________
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];
  // ___________________________________________________________

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (response.eventName === "Instagram__EngagementsPage__volume_overtime") {
      setPostsVolume(response?.eventData?.posts_over_time);
      setTotalPostsVolumeCount(response?.eventData?.total_posts_count);
      setPostsVolumePreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__posts_interactions"
    ) {
      setPostsInteractions(
        response?.eventData?.posts_interactions?.posts_interactions_over_time,
      );
      setPostsInteractionsLegend(
        response?.eventData?.posts_interactions?.posts_interactions_legends,
      );
      let totalInteractions = response?.eventData?.posts_interactions
        ?.total_interactions
        ? response?.eventData?.posts_interactions?.total_interactions
        : 0;

      setTotalValuePostsInteractions(totalInteractions);
      setMaxValuePostsInteractions(
        response?.eventData?.posts_interactions?.max_interactions,
      );
      let date = handleUnixFormat(
        response?.eventData?.posts_interactions?.max_interaction_date,
      );
      setMaxValueInteractionsDate(date);

      setAvgValuePostsInteractions(
        response?.eventData?.posts_interactions?.average_interactions,
      );

      setPostsInteractionsPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName ===
      "Instagram__EngagementsPage__dialects_and_sub_dialects"
    ) {
      setDialects(response.eventData.dialects);
      setSubDialects(response.eventData.sub_dialects);
      setDialectsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__sentiment_analysis"
    ) {
      Object.entries(response.eventData.sentiment_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentAnalysis({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentPieChart(response.eventData.sentiment_piechart);
      setSentimentAnalysisPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_videos"
    ) {
      response.eventData.top_videos !== null &&
        setTopVideos(response.eventData.top_videos);
      setTopVideosPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_images"
    ) {
      setTopImages(response.eventData.top_images);
      setTopImagesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_keywords"
    ) {
      setTopKeywords(response.eventData.top_keywords);
      setTopKeywordsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_hashtags"
    ) {
      setTopHashtags(response.eventData.top_hashtags);
      setTopHashtagsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__associated_topics"
    ) {
      setAssociatedTopics(response.eventData.associated_topics);
      setAssociatedTopicsPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__content_type"
    ) {
      setContentStyles(response.eventData.posts_content_types_legends);
      setContentStylesPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_posts_comments"
    ) {
      setTopPosts(response.eventData.top_posts);
      setTopPostsCount(response.eventData.top_posts_count);

      setTopPostsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response?.eventName ===
      "Instagram__EngagementsPage__themes_and_sub_themes"
    ) {
      setThemeAnalysis(response?.eventData?.themes?.theme_analysis);
      setThemeTrend(response?.eventData?.themes?.theme_trend);
      setThemeAnalysisPreLoader([response?.monitor_id]);
      setThemeTrendPreLoader([response?.monitor_id]);
      setThemeXlxs(response?.eventData?.themes);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([
      { postsVolume: postsVolume },
      { postsInteractions: postsInteractions },
      { sentimentAnalysis: sentimentAnalysis },
      { themeXlxs: themeXlxs },
      { associatedTopics: associatedTopics },
      { contentStyles: contentStyles },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      { topVideos: topVideos },
      { topImages: topImages },
      { topPosts: topPosts },
      { dialects: dialects },
      { subDialects: subDialects },
    ]);
  }, [
    postsVolume,
    postsInteractions,
    sentimentAnalysis,
    themeXlxs,
    associatedTopics,
    contentStyles,
    topKeywords,
    topHashtags,
    topVideos,
    topImages,
    topPosts,
    dialects,
    subDialects,
  ]);
  // ________________________________________________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPostsVolume([]);
      setPostsVolumePreLoader([]);
      setTotalPostsVolumeCount([]);
      setPostsInteractions([]);
      setPostsInteractionsPreLoader([]);
      setPostsInteractionsLegend([]);
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setSentimentPieChart([]);
      setThemeAnalysis([]);
      setThemeAnalysisPreLoader([]);
      setThemeTrend([]);
      setThemeTrendPreLoader([]);
      setThemeXlxs([]);
      setTopVideos([]);
      setTopVideosPreLoader([]);
      setTopImages([]);
      setTopImagesPreLoader([]);
      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);
      setContentStyles([]);
      setContentStylesPreLoader([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setTopPosts([]);
      setTopPostsPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    props.checkDataIsFounded(
      postsVolume,
      postsInteractions,
      associatedTopics,
      sentimentAnalysis,
      topKeywords,
      topHashtags,
      topPosts,
      dialects,
      subDialects,
      contentStyles,
      topVideos,
      topImages,
    );

    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      postsVolumePreLoader,
      postsInteractionsPreLoader,
      dialectsPreLoader,
      sentimentAnalysisPreLoader,
      topVideosPreLoader,
      topImagesPreLoader,
      topKeywordsPreLoader,
      topHashtagsPreLoader,
      associatedTopicsPreLoader,
      contentStylesPreLoader,
      topTopPostsPreLoaderDataSources,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );
  }, [
    postsVolumePreLoader,
    postsInteractionsPreLoader,
    dialectsPreLoader,
    sentimentAnalysisPreLoader,
    topVideosPreLoader,
    topImagesPreLoader,
    topKeywordsPreLoader,
    topHashtagsPreLoader,
    associatedTopicsPreLoader,
    contentStylesPreLoader,
    topTopPostsPreLoaderDataSources,
    postsVolume,
    postsInteractions,
    associatedTopics,
    sentimentAnalysis,
    topKeywords,
    topHashtags,
    topPosts,
    dialects,
    subDialects,
    contentStyles,
    topVideos,
    topImages,
  ]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      topTopPostsPreLoaderDataSources,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([topPosts]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    topPosts,
    topTopPostsPreLoaderDataSources,
    props.preLoaderTrackerDataSources,
  ]);

  return (
    <Box mb={5}>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"posts_volume_kw"}
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          titleToolTip={"posts_volume_fb_tooltip"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={postsVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.instagramIcon}
          labelColor={"#2AAAE2"}
        />
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          titleToolTip={"posts_interactions_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={postsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "likes"]}
          showScales
          showZoomPercentage
          iconAfterTitle={props.instagramIcon}
          postsColumnPreLoaderDataSources={postsInteractionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={postsInteractionsLegend}
          showChartDetailsWidget
          statsDetailsData={interactionsStats}
          isInteractionStatsWrapperStyle
        />
      </Box>
      {/* Sentiment_analysis Chart */}
      <Box my={3}>
        <StackedLineWidget
          title={"posts_sentiment_analysis"}
          titleToolTip={"comments_sentiment_analysis_nb_tooltip"}
          data={sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          showScales
          toolTipLabel={"toolTipLabel"}
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.instagramIcon}
          dataPiechart={sentimentPieChart}
          hideLegend
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
        />
      </Box>

      <Box mt={3}>
        <ThemeAnalysis
          title={"posts_themes"}
          titleToolTip={"themes_tooltip"}
          data={themeAnalysis ? themeAnalysis : []}
          themeAnalysisPreLoaderDataSources={themeAnalysisPreLoader}
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          iconAfterTitle={props?.instagramIcon}
          themesList={props?.themesOptions}
          isSocialListening={true}
        />
      </Box>

      <Box mt={3}>
        <ThemesTrends
          title={"posts_themes_trend"}
          titleToolTip={"themes_trend_tooltip"}
          data={themeTrend ? themeTrend : []}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={themeTrendPreLoader}
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          iconAfterTitle={props?.instagramIcon}
          widgetSection={props?.widgetSection}
          themesList={props?.themesOptions}
          isSocialListening={true}
        />
      </Box>

      {/* Content Segmentation */}
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"posts_associated_topics"}
              titleToolTip={"posts_associated_topics_ig_tooltip"}
              data={associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={associatedTopicsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.instagramIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title="posts_content_type"
              titleToolTip={"posts_content_type_tw_tooltip"}
              showDownloadIcon={false}
              data={contentStyles}
              pieChartPreLoaderDataSources={contentStylesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.instagramIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_keywords"}
              titleToolTip={"posts_top_keywords_fb_tooltip"}
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              iconAfterTitle={props.instagramIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_hashtags"}
              titleToolTip={"posts_top_hashtags_ig_tooltip"}
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_hashtags"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              iconAfterTitle={props.instagramIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <MediaInstagram
          title={CheckValueLocale("top_reels", "", {}, intl)}
          titleToolTip={"posts_top_reels_tooltip"}
          data={topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={props.instagramIcon}
          hideMoreInsights
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={topVideosPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showDownloadIcon
        />
      </Box>
      <Box mt={3}>
        <MediaInstagram
          title={CheckValueLocale("top_images", "", {}, intl)}
          titleToolTip={"top_images_ig_tooltip"}
          data={topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={props.instagramIcon}
          hideMoreInsights
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={topImagesPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showDownloadIcon
        />
      </Box>
      <Box className="top-posts-head">
        <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
        <Link
          to={{
            pathname: `/monitor/${monitorType}/${monitorDataSource}/posts/${monitorId}`,
            state: monitorDetails,
          }}
          className="top-posts-all"
          id={`check-all-posts-${
            monitorType?.toLowerCase() ?? "monitor-type"
          }-${
            monitorDataSource?.toLowerCase() ?? "monitor-data-source"
          }-posts-${monitorId ?? "monitor-id"}`}
        >
          {CheckValueLocale("check_all_posts", "", {}, intl)} ({topPostsCount})
        </Link>
      </Box>
      <Box>
        <QuestionCard
          unixDate={props.unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={topPosts}
          widget={"top_posts"}
          latestUnanswerPreloader={topTopPostsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faInstagram}
          iconColor={"instagram_icon"}
          showHeart={true}
          showDefaultImg={true}
          hideChartBar={true}
        />
      </Box>
      <Typography className="section-name">
        {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6} mt={2}>
          <PieChartWidget
            title={"dialects_subdialects"}
            data={dialects}
            subData={subDialects}
            areaStyle={true}
            toolTipLabel={"toolTipLabel"}
            titleToolTip={"dialects_subdialects_ig_tooltip"}
            showAI
            showDownloadIcon
            pieChartPreLoaderDataSources={dialectsPreLoader}
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
            iconAfterTitle={props.instagramIcon}
          />
        </Grid>
      </Grid>
      <TopicsSection
        iconToDisplay={props.instagramIcon}
        isInstagram
        {...props}
      />
    </Box>
  );
};

export default InstagramEngagementsBody;
