import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Card,
  Divider,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  Dialog,
  FormControl,
  Chip,
  Tooltip,
} from "@mui/material";
import DropZoneArea from "./dropZone";
import ModalImage from "react-modal-image";
import { SketchPicker } from "react-color";
import { handlelUserRoles, CheckValueLocale, formatBytes } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import BrandingPreviewModal from "./brandingPreviewModal";

const ReportsBranding = (props) => {
  const {
    fonts,
    companyBranding,
    setCompanyBranding,
    logoError,
    setLogoError,
    brandingChangesFlag,
    setBrandingChangesFlag,
  } = props;
  const intl = useIntl();

  const [colorPicker, openColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [previewOpened, setPreviewOpened] = useState(false);

  useEffect(() => {
    if (companyBranding) setLogoError("");
  }, [companyBranding]);

  const handleColorPicker = (e) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      if (!colorPicker) {
        openColorPicker(true);
        setSelectedColor(e.currentTarget.name);
      } else {
        openColorPicker(false);
        setBrandingChangesFlag(true);
        setSelectedColor(e.currentTarget.name);
      }
      setBrandingChangesFlag(true);
    }
  };

  const hexColorRegex = /^#[0-9A-Fa-f]{0,6}$/;

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      if (
        ["primary_color", "secondary_color", "tertiary_color"]?.includes(name)
      ) {
        if (hexColorRegex?.test(value)) {
          setCompanyBranding({ ...companyBranding, [name]: value });
          setBrandingChangesFlag(true);
        }
      } else {
        setCompanyBranding({ ...companyBranding, [name]: value });
        setBrandingChangesFlag(true);
      }
    }
  };

  const shapeStyles = (color) => {
    return {
      bgcolor: `${color}`,
      width: 31,
      height: 28,
      borderRadius: 1,
      position: "relative",
      right: 9,
    };
  };

  const setLogoImage = (img) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      setCompanyBranding({ ...companyBranding, logo: img });
      setBrandingChangesFlag(true);
    }
  };

  const removeImage = (name) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      setCompanyBranding({ ...companyBranding, [name]: null });
      setBrandingChangesFlag(true);
    }
  };

  const handleColorChange = (color) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      setCompanyBranding({ ...companyBranding, [selectedColor]: color?.hex });
      setBrandingChangesFlag(true);
    }
  };

  let opacityData = [40, 30, 20, 10];

  // convert HEX code without opacity to HEX code with opacity
  const formatHexOpacity = (hexColor, opacity) => {
    if (hexColor?.startsWith("#")) hexColor = hexColor?.slice(1);
    if (hexColor?.length === 3)
      hexColor = hexColor
        ?.split("")
        ?.map((char) => char + char)
        ?.join("");

    if (hexColor?.toLowerCase() === "ffffff") {
      // Special case for white color
      if (opacity == 40) return `#FFFFFF`;
      else if (opacity == 30) return `#F6F6F6`;
      else if (opacity == 20) return `#F3F2F1`;
      else if (opacity == 10) return `#FCFCFC`;
    } else {
      const blendWithWhite = (color, opacity) =>
        Math?.round((1 - opacity) * 255 + opacity * color);

      const hexToRgb = (hex) => [
        parseInt(hex?.slice(0, 2), 16),
        parseInt(hex?.slice(2, 4), 16),
        parseInt(hex?.slice(4), 16),
      ];

      const rgbToHex = (rgb) =>
        `#${rgb
          ?.map((x) => x?.toString(16)?.padStart(2, "0"))
          ?.join("")
          ?.toUpperCase()}`;

      const rgb = hexToRgb(hexColor);
      const decimalOpacity = opacity / 100;
      const blendedRgb = rgb?.map((color) =>
        blendWithWhite(color, decimalOpacity),
      );

      return rgbToHex(blendedRgb);
    }
  };

  const handleSelectOpacity = (opacity) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      setCompanyBranding({
        ...companyBranding,
        opacity,
        selected_tertiary_color: formatHexOpacity(
          companyBranding?.tertiary_color,
          opacity,
        ),
      });
      setBrandingChangesFlag(true);
    }
  };

  useEffect(() => {
    if (companyBranding?.tertiary_color)
      setCompanyBranding({
        ...companyBranding,
        selected_tertiary_color: formatHexOpacity(
          companyBranding?.tertiary_color,
          companyBranding?.opacity,
        ),
      });
  }, [companyBranding?.tertiary_color]);

  // to remove file extension from file name
  const handleFileName = (text) =>
    text?.substring(0, text?.lastIndexOf(".")) || text;

  return (
    <Box id="company-branding">
      <Box className="company-branding-header">
        <Box className="title">
          {CheckValueLocale("report_branding", "", {}, intl)}
          <Chip
            className="new-badge"
            color="success"
            size="small"
            label={CheckValueLocale("company_branding_new", "", {}, intl)}
          />
        </Box>
        <Box className="description">
          {CheckValueLocale("company_branding_desc", "", {}, intl)}
          {companyBranding?.updated_at ? (
            <Box component={"span"}>
              {`${CheckValueLocale("branding_last_updated", "", {}, intl)}: ${moment?.unix(companyBranding?.updated_at)?.format("DD/MM/YYYY")}`}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Card className="branding-items-container">
        {/* Logo Image */}
        <Box className="branding-item">
          <Box className="item-heading">
            <Typography variant="p" className="branding-head">
              {CheckValueLocale("company_logo", "", {}, intl)}
            </Typography>
            <Box component={"span"} className="branding-sub-head">
              {CheckValueLocale("branding_logo_desc", "", { br: <br /> }, intl)}
            </Box>
          </Box>
          <Box className="item-content">
            {companyBranding?.logo ? (
              <Box className="logo-image-container">
                <ModalImage
                  className="logo-image"
                  small={
                    companyBranding?.logo instanceof File
                      ? URL.createObjectURL(
                          companyBranding?.logo ? companyBranding?.logo : "",
                        )
                      : companyBranding?.logo
                  }
                  large={
                    companyBranding?.logo instanceof File
                      ? URL.createObjectURL(
                          companyBranding?.logo ? companyBranding?.logo : "",
                        )
                      : companyBranding?.logo
                  }
                />
                <Box className="logo-info">
                  <Typography variant="h6" className="file-name">
                    {companyBranding?.logo?.name
                      ? handleFileName(companyBranding?.logo?.name)
                      : handleFileName(
                          companyBranding?.logo_details?.file_name,
                        )}
                  </Typography>
                  <Typography variant="p" className="file-size">
                    {`${(companyBranding?.logo?.type
                      ? companyBranding?.logo?.type
                      : companyBranding?.logo_details?.file_type
                    )
                      ?.split("/")?.[1]
                      ?.toUpperCase()} image - ${formatBytes(companyBranding?.logo?.size ? companyBranding?.logo?.size : companyBranding?.logo_details?.file_size)}`}
                  </Typography>
                </Box>
                <LucButton
                  id="remove-logo-button"
                  type="secondary"
                  variant="flat"
                  onClick={() => removeImage("logo")}
                  minWidth={42}
                  disabled={
                    !handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")
                  }
                >
                  <FontAwesomeIcon icon={faX} />
                </LucButton>
              </Box>
            ) : (
              <DropZoneArea
                setImage={setLogoImage}
                logoError={logoError}
                setLogoError={setLogoError}
              />
            )}
          </Box>
        </Box>

        {/* Fonts Section */}
        <Box className="branding-item">
          <Box className="item-heading">
            <Typography variant="p" className="branding-head">
              {CheckValueLocale("branding_fonts", "", {}, intl)}
            </Typography>
            <Box component={"span"} className="branding-sub-head">
              {CheckValueLocale("font_select", "", {}, intl)}
            </Box>
          </Box>
          <Box className="item-content">
            <FormControl fullWidth>
              <Select
                className="fonts-select"
                name="font_type_id"
                value={companyBranding?.font_type_id}
                onChange={(e) => changeHandler(e)}
                disabled={!handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")}
                size="small"
                MenuProps={{ PaperProps: { style: { maxHeight: 220 } } }}
                renderValue={(selected) => {
                  let selectedFont = fonts?.find((font) => font?.id == selected)
                    ?.attributes?.font_name;
                  return (
                    <Box
                      className="fonts-rendered-value"
                      sx={{ fontFamily: `${selectedFont} !important` }}
                    >
                      {selectedFont}
                      <Box
                        component={"span"}
                        style={{ fontFamily: "inherit !important" }}
                      >
                        العربية
                      </Box>
                    </Box>
                  );
                }}
              >
                {fonts?.map((font, index) => (
                  <MenuItem
                    key={index}
                    id="branding-font-menu-item"
                    style={{ fontFamily: `${font?.attributes?.font_name}` }}
                    value={font?.id}
                  >
                    {font?.attributes?.font_name}
                    <Box component={"span"}>العربية</Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* Color section */}
        <Box className="branding-item">
          {colorPicker ? (
            <Dialog
              open={colorPicker}
              onClose={handleColorPicker}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <SketchPicker
                className="color-picker-popup"
                color={companyBranding?.[selectedColor]}
                onChange={handleColorChange}
              />
            </Dialog>
          ) : null}
          <Box className="item-heading">
            <Typography variant="p" className="branding-head">
              {CheckValueLocale("branding_colors", "", {}, intl)}
            </Typography>
            <Box component={"span"} className="branding-sub-head">
              {CheckValueLocale("enter_color", "", {}, intl)}
            </Box>
          </Box>
          <Box className="item-content colors-item">
            <Box className="color-container">
              <Typography className="title">
                {CheckValueLocale("primary", "", {}, intl)}
                <Box component={"span"}>
                  {CheckValueLocale("branding_primary_desc", "", {}, intl)}
                </Box>
              </Typography>
              <TextField
                className="color-picker-container"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        className="color-input"
                        disableRipple
                        onClick={
                          handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") &&
                          handleColorPicker
                        }
                        name="primary_color"
                      >
                        <Box className="color-rectangle">
                          <Box
                            onClick={
                              handlelUserRoles(
                                "GENERAL",
                                "EDIT_REPORT_BRANDING",
                              ) && handleColorPicker
                            }
                            sx={shapeStyles(companyBranding?.primary_color)}
                          />
                        </Box>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="primary_color"
                value={companyBranding?.primary_color}
                onChange={changeHandler}
              />
            </Box>
            <Box className="color-container">
              <Typography className="title">
                {CheckValueLocale("secondary", "", {}, intl)}
                <Box component={"span"}>
                  {CheckValueLocale("branding_secondary_desc", "", {}, intl)}
                </Box>
              </Typography>
              <TextField
                className="color-picker-container"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        className="color-input"
                        disableRipple
                        onClick={
                          handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") &&
                          handleColorPicker
                        }
                        name="secondary_color"
                      >
                        <Box className="color-rectangle">
                          <Box
                            onClick={
                              handlelUserRoles(
                                "GENERAL",
                                "EDIT_REPORT_BRANDING",
                              ) && handleColorPicker
                            }
                            sx={shapeStyles(companyBranding?.secondary_color)}
                          />
                        </Box>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="secondary_color"
                value={companyBranding?.secondary_color}
                onChange={changeHandler}
              />
            </Box>
            <Box className="color-container">
              <Typography className="title">
                {CheckValueLocale("tertiary", "", {}, intl)}
                <Box component={"span"}>
                  {CheckValueLocale("branding_tertiary_desc", "", {}, intl)}
                </Box>
              </Typography>
              <TextField
                className="color-picker-container"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        className="color-input"
                        disableRipple
                        onClick={
                          handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") &&
                          handleColorPicker
                        }
                        name="tertiary_color"
                      >
                        <Box className="color-rectangle">
                          <Box
                            onClick={
                              handlelUserRoles(
                                "GENERAL",
                                "EDIT_REPORT_BRANDING",
                              ) && handleColorPicker
                            }
                            sx={shapeStyles(companyBranding?.tertiary_color)}
                          />
                        </Box>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="tertiary_color"
                value={companyBranding?.tertiary_color}
                onChange={changeHandler}
              />
              {opacityData?.map((opacity, index) => {
                return (
                  <Box
                    key={index}
                    className="color-opacity-box"
                    style={{
                      background: `${formatHexOpacity(companyBranding?.tertiary_color, opacity)}`,
                    }}
                    onClick={() => handleSelectOpacity(opacity)}
                  >
                    {companyBranding?.opacity == opacity ? (
                      <FontAwesomeIcon className="selected" icon={faCheck} />
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Divider fullWidth />

        {/* Preview Section */}
        <Box className="branding-item">
          <Box className="item-heading">
            <Typography variant="p" className="branding-head">
              {CheckValueLocale("branding_preview_title", "", {}, intl)}
            </Typography>
            <Box component={"span"} className="branding-sub-head">
              {CheckValueLocale("branding_preview_desc", "", {}, intl)}
            </Box>
          </Box>
          <Box className="item-content">
            <Tooltip
              arrow
              placement="top"
              title={
                brandingChangesFlag
                  ? CheckValueLocale("branding_preview_tooltip", "", {}, intl)
                  : ""
              }
            >
              <Box>
                <LucButton
                  id="report-slides-sample-btn"
                  variant="outline"
                  onClick={() => setPreviewOpened(true)}
                  disabled={brandingChangesFlag}
                >
                  {CheckValueLocale("preview", "", {}, intl)}
                </LucButton>
              </Box>
            </Tooltip>
          </Box>
          {/* Preview Popup */}
          <BrandingPreviewModal
            companyBranding={companyBranding}
            fonts={fonts}
            previewOpened={previewOpened}
            setPreviewOpened={setPreviewOpened}
          />
        </Box>
      </Card>
    </Box>
  );
};
export default ReportsBranding;
