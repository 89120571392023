import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import { Grid, Box, Typography } from "@mui/material";
import { PhoneNumberInput } from "./components/phoneNumberField/phoneNumberInput";
import {
  CheckValueLocale,
  emailValidator,
  isArabic,
  maskEmail,
} from "utils/helpers";

import "./UserForm.scss";

const UserForm = ({ setStepOneData, stepOneData, isEditUser }) => {
  const { first_name, last_name, mail, phone, job_title } = stepOneData;
  const intl = useIntl();

  useEffect(() => {
    setStepOneData((prev) => ({ ...prev, Valid: isFormValid() }));
  }, [first_name, last_name, mail]);

  const handleChange = (name, value) => {
    setStepOneData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    let isValid = true;

    if (first_name === "" || last_name === "" || mail === "") {
      isValid = false;
      return isValid;
    }
    if (!emailValidator(mail) || isArabic(mail)) {
      isValid = false;
      return isValid;
    }
    return isValid;
  };

  return (
    <>
      <Box className="add-user-form">
        <Typography className="add-user-subtitle">
          {CheckValueLocale("add_user_subtitle", "", {}, intl)}:
        </Typography>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              className="add-user-field"
              required
              label={CheckValueLocale("first_name", "", {}, intl)}
              value={first_name}
              onChange={(e) => handleChange("first_name", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              className="add-user-field"
              required
              label={CheckValueLocale("last_name", "", {}, intl)}
              value={last_name}
              onChange={(e) => handleChange("last_name", e.target.value)}
            />
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              margin="none"
              className="add-user-field"
              required
              label={CheckValueLocale("email", "", {}, intl)}
              value={isEditUser ? maskEmail(mail) : mail}
              disabled={isEditUser}
              onChange={(e) => handleChange("mail", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              size="small"
              className="add-user-field"
              label={CheckValueLocale("job_title", "", {}, intl)}
              value={job_title}
              onChange={(e) => handleChange("job_title", e.target.value)}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <PhoneNumberInput
              label={CheckValueLocale("phone_number", "", {}, intl)}
              defaultValue={phone}
              onChange={(value) => handleChange("phone", value)}
              isEditUser={isEditUser}
              masked
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserForm;
